import { Component, HostBinding } from '@angular/core';
import { LaunchpointCoreClientAuthVerifyBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-auth-v1-verify',
  templateUrl: './auth-v1-verify.component.html',
  styleUrls: ['./auth-v1-verify.component.scss'],
})
export class LaunchpointCoreWebAuthV1VerifyComponent extends LaunchpointCoreClientAuthVerifyBaseComponent {
  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }

  resend() {
    //
  }
}
