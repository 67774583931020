import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopicsHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebNotificationsTopicsTagifyBaseWebComponent } from './notifications-topics-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [TopicsHTTPService],
  selector: 'launchpoint-notifications-topics-tagify',
  templateUrl: 'notifications-topics-tagify.component.html',
})
export class LaunchpointCoreWebNotificationsTopicsTagifyWebComponent extends LaunchpointCoreWebNotificationsTopicsTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
