import { Observable } from 'rxjs';
import { ICoreUserProfileParamsUpdate } from './core-profile.interface';
import { ICoreUser } from '../../../user-company-account.interface';

/**
 * @Controller('user/profile')
 */
export interface ICoreUserProfileController {
  /**
   * Put()
   * @param body
   */
  updateProfile(body: ICoreUserProfileParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
}
