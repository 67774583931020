import { Observable } from 'rxjs';
import { ICoreUserIDMeParamsUpdate, ICoreUserIDMeParamsCreateRoute, ICoreUserIDMeParamsValidate } from './id-me-auth.interface';
import { ICoreUser } from '../../user-company-account.interface';

/**
 * @Controller('user/id-me')
 */
export interface ICoreUserIDMeController {
  /**
   * Post('validate')
   * @param body
   */
  validate(body: ICoreUserIDMeParamsValidate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('init')
   * @param body
   */
  init(body: ICoreUserIDMeParamsCreateRoute): Promise<{ url: string }> | Observable<{ url: string }>;
  /**
   * Put()
   * @param body
   */
  update(body: ICoreUserIDMeParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put(':id')
   * @param user_id
   */
  remove(user_id: string): Promise<ICoreUser> | Observable<ICoreUser>;
}
