import { Component } from '@angular/core';
import { LaunchpointCoreClientActivityHistoryBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';

@Component({
  selector: 'launchpoint-activity-history-toolbar',
  templateUrl: 'activity-history-toolbar.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebActivityHistoryToolbarComponent extends LaunchpointCoreClientActivityHistoryBaseComponent {
  options: IToolbarConfig = {
    title: this.title,
    searchPlaceholder: 'Search...',
    formControl: this.form_control,
    create: false,
    queryCount: this.queryFilterCount$,
  };

  constructor(public _Store: Store) {
    super(_Store);
  }

  paginate(page) {
    this.pageChange({ pageSize: this.pagination.limit, pageIndex: page, previousPageIndex: page - 1 });
  }
}
