import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreActivityHistory,
  ICoreActivityHistoryController,
  ICoreActivityHistorySearchPayload,
  ICoreActivityHistorySearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN, LaunchpointCoreClientActivityHistoryConfig } from '../interface/interface';

@Injectable()
export class CoreActivityHistoryHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreActivityHistoryController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'activity-history';

  constructor(
    private http: HttpClient,
    @Inject(ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN) public _config: LaunchpointCoreClientActivityHistoryConfig,
    public _Store: Store
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.base_url,
      version: 1,
      route: this.BASE_URL,
    });
  }
  search(params: ICoreActivityHistorySearchPayload): Observable<ICoreActivityHistorySearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreActivityHistorySearchResults>(this.API_URL + '/search', params, { headers });
      })
    );
  }

  getById(id: string): Observable<ICoreActivityHistory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreActivityHistory>(this.API_URL + '/' + id, { headers });
      })
    );
  }

  delete(id: string): Observable<ICoreActivityHistory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreActivityHistory>(this.API_URL + '/delete/' + id, { headers });
      })
    );
  }
}
