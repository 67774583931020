import { Component, effect, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ICoreBlogAuthor } from '@launchpoint/core-types';
import { LaunchpointSEORouterService } from '@launchpoint/xplat/web/features';
import { LaunchpointBlogService } from '../../data/blog.service';

@Component({
  selector: 'sd-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrl: './blog-detail.component.scss',
})
export class BlogDetailComponent implements OnInit {
  _LaunchpointBlogService = inject(LaunchpointBlogService);
  _LaunchpointSEORouterService = inject(LaunchpointSEORouterService);
  route = inject(ActivatedRoute);

  constructor() {
    effect(() => {
      this._LaunchpointSEORouterService.updateTitleAndMeta({
        ...this._LaunchpointBlogService.selected_blog()?.seo,
        route: this._LaunchpointBlogService.selected_blog()?.title_url,
      });
    });
  }

  ngOnInit() {
    this.route.params.subscribe({
      next: (params) => this.handelRouteParams(params),
    });
    this._LaunchpointBlogService.getBlogs();
  }

  get blogTitles() {
    return this._LaunchpointBlogService.all_blogs();
  }

  getAuthor(user: ICoreBlogAuthor) {
    if (user?.profile?.full_name) {
      return user.profile.full_name;
    }

    const name = `${user?.profile?.first_name} ${user?.profile?.last_name}`;

    return name.trim();
  }

  handelRouteParams(params: Params) {
    const title_url = params['title_url'];
    // console.log('title url', title_url);

    this._LaunchpointBlogService.getBlog({ title_url });
    if (title_url) {
      this._LaunchpointBlogService.setSelectedBlog(title_url);
    }
  }

  get blog() {
    const blog = this._LaunchpointBlogService.selected_blog();

    blog.comments
      .map((comment) => {
        const replies = comment.replies.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        comment.replies = replies;

        return comment;
      })
      .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

    return blog;
  }

  get commentsAllowed() {
    return this.blog?.comments_enabled || this.blog?.comments_archived;
  }

  chat() {
    // if (Intercom) {
    //   try {
    //     Intercom('trackEvent', 'requested-info');
    //     Intercom('show');
    //   } catch (error) {
    //     return;
    //   }
    // }
  }
}
