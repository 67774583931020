import { ICoreUser } from '../../../user-company-account.interface';

export interface ICoreUserSecurityRoleParamsUpdate extends Pick<Partial<ICoreUser>, 'security_roles'> {
  user_id: string;
  // security_roles: string[];
}

export interface ICoreUserSecurityRoleParamsTransfer extends Pick<Partial<ICoreUser>, 'security_roles'> {
  current_user_id: string;
  new_user_id: string;
}
