<div class="card mb-5 mb-xl-10">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">{{ _config?.title ?? 'Address' }}</h3>
    </div>
  </div>
  <form [formGroup]="address_form">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <div class="col-lg-12">
          <div class="row">
            <!-- START: Address Line One Triggers Google Search Ahead -->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-4 col-form-label fw-bold fs-6">Street 1</label>
              <input type="text" #receive_address_text required="required" formControlName="line_one" autocomplete="off"
                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="line_one"
                aria-describedby="street line 1" [ngClass]="{
                  'is-invalid':
                    address_form.controls.line_one.invalid && (address_form.controls.line_one.dirty || address_form.controls.line_one.touched)
                }" />
              <small id="line_one" class="form-text text-muted">Start typing address for autocomplete</small>
              <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Street 1 is required',
                  control: address_form.controls.line_one
                }">
              </ng-container>
            </div>

            <!-- START: Manual Form / Google Search Ahead Will Fill These Inputs Out Or User Can Manually Input Them -->
            <!-- START: Line Two -->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-2 col-form-label fw-bold fs-6">Street 2</label>
              <small class="text-muted ps-0">(Optional)</small>
              <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Address Line 2"
                id="line_two" aria-describedby="line_two" formControlName="line_two" />
            </div>
            <!-- START: Country -->
            <!-- #country_options -->
            <div class="col-lg-6 fv-row">
              <label class="col-form-label fw-bold fs-6">Country</label>
              <select class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" role="button"
                formControlName="country">
                <option hidden>Select your country</option>
                <option *ngFor="let country of countryListFull" [value]="country.alpha_2">{{ country.alpha_2 }} - {{
                  country.name }}</option>
              </select>
              <ng-container *ngIf="country.invalid && country.touched">
                <small class="caption text-danger" *ngIf="country.errors?.required"> This is a required field. </small>
              </ng-container>
              <ng-container *ngIf="country_invalid">
                <small class="caption text-danger"> Country invalid </small>
              </ng-container>
            </div>
            <!-- START: City -->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-4 col-form-label fw-bold fs-6">City</label>
              <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" id="line_two"
                aria-describedby="city" formControlName="city" placeholder="City" [ngClass]="{
                  'is-invalid': address_form.controls.city.invalid && (address_form.controls.city.dirty || address_form.controls.city.touched)
                }" />
              <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'City is required',
                  control: address_form.controls.city
                }">
              </ng-container>
            </div>
            <!-- START: State -->
            <div class="col-lg-6 fv-row">
              <ng-container *ngIf="isCountryUnitedStates(this.country.value); else notUSA">
                <label class="col-lg-4 col-form-label fw-bold fs-6">State</label>
                <select class="form-control form-control-lg form-control-solid" role="button" formControlName="state">
                  <option *ngFor="let state of unitedStatesStates" [value]="state.abbreviation">{{ state.abbreviation }}
                    - {{ state.name }}</option>
                </select>
              </ng-container>
              <ng-template #notUSA>
                <label for="state" class="col-lg-8 col-form-label fw-bold fs-6">State / Province / Region</label>
                <input type="text" formControlName="state" class="form-control form-control-lg form-control-solid"
                  id="CapitalizeInput" [ngClass]="{
                    'is-invalid': address_form.controls.state.invalid && (address_form.controls.state.dirty || address_form.controls.state.touched)
                  }" />
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'State, Province, or Region is required',
                    control: address_form.controls.state
                  }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'maxlength',
                    message: 'State, Province, or Region must be a two-letter string',
                    control: address_form.controls.state
                  }">
                </ng-container>
              </ng-template>
            </div>
            <!-- START: Postal Code -->
            <div class="col-lg-6 fv-row">
              <label class="col-lg-4 col-form-label fw-bold fs-6">Postal Code</label>
              <input type="text" class="form-control form-control-lg form-control-solid" id="zip_code"
                aria-describedby="zip_code" formControlName="zip_code" placeholder="Postal Code" [ngClass]="{
                  'is-invalid':
                    address_form.controls.zip_code.invalid && (address_form.controls.zip_code.dirty || address_form.controls.zip_code.touched)
                }" />
              <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Postal Code is required',
                  control: address_form.controls.zip_code
                }">
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mb-4">
        <ng-content></ng-content>
      </div>
    </div>
    <!-- START: Footer -->
    <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button class="btn btn-secondary me-3" (click)="resetForm()">Cancel</button>

      <button #addressButton type="button" class="btn btn-primary"
        [disabled]="(loading$ | async) || address_form.invalid || address_form.pristine" (click)="sendAddress()">
        <ng-container *ngIf="(loading$ | async ) === false">Save</ng-container>
        <ng-container *ngIf="(loading$ | async)">
          <span class="indicator-label" [style.display]="'block'">
            Please wait...{{ ' ' }}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </ng-container>
      </button>
    </div>
  </form>
</div>

<!-- START: FormError-->
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container
    *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>