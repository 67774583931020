<tagify
  class="me-5"
  [ngModel]="companiesModel"
  (ngModelChange)="selectData($event)"
  [whitelist]="whitelistCompanies$"
  [settings]="settings"
  [inputClass]="inputClass"
  name="companies"
/>

