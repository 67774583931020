import { Observable } from 'rxjs';
import { ICoreUserAttributesParamsDelete, ICoreUserAttributesParamsUpdate } from './core-attributes.interface';
import { ICoreUser } from '../../../user-company-account.interface';

/**
 * @Controller('user/attributes')
 */
export interface ICoreUserAttributesController {
  /**
   * Put()
   * @param body
   */
  updateAttributes(body: ICoreUserAttributesParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put('delete')
   * @param body
   */
  deleteAttributes(body: ICoreUserAttributesParamsDelete): Promise<ICoreUser> | Observable<ICoreUser>;
}

export enum ECoreUserAttributesRoutes {
  ATTRIBUTES = 'user/attributes',
}
