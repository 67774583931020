import {
  Activity,
  ClosedDays,
  CreateJourneyCommandInput,
  DeleteJourneyCommandInput,
  GetJourneyCommandInput,
  JourneyChannelSettings,
  JourneyLimits,
  JourneySchedule,
  JourneyStateRequest,
  ListJourneysCommandInput,
  OpenHours,
  QuietTime,
  StartCondition,
  State,
  UpdateJourneyCommandInput,
  UpdateJourneyStateCommandInput,
  WriteJourneyRequest,
  __TimezoneEstimationMethodsElement,
} from '@aws-sdk/client-pinpoint';
import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ILaunchpointUserAccountIds } from '../../security.interface';
import {
  EJourneyState,
  IActivity,
  IClosedDays,
  IJourneyChannelSettings,
  IJourneyLimits,
  IJourneySchedule,
  IOpenHours,
  IStartCondition,
} from './aws-journey.interface';

// implements JourneyResponse
export class ILaunchpointCorePinpointJourney {
  activities?: Record<string, IActivity>;
  application_id: string;
  creation_date?: string;
  id: string;
  last_modified_date?: string;
  limits?: IJourneyLimits;
  local_time?: boolean;
  name: string;
  quiet_time?: {
    end?: string;
    start?: string;
  };
  refresh_frequency?: string;
  schedule?: IJourneySchedule;
  start_activity?: string;
  start_condition?: IStartCondition;
  state?: EJourneyState;
  tags?: Record<string, string>;
  wait_for_quiet_time?: boolean;
  refresh_on_segment_update?: boolean;
  journey_channel_settings?: IJourneyChannelSettings;
  sending_schedule?: boolean;
  open_hours?: IOpenHours;
  closed_days?: IClosedDays;
  timezone_estimation_methods?: ('PHONE_NUMBER' | 'POSTAL_CODE')[];

  //
  _id: string;
  created_at: Date;
  updated_at: Date;
  users: string[];
  accounts: string[];
}

class IWriteJourneyRequest implements WriteJourneyRequest {
  Name: string;

  Activities?: Record<string, Activity>;
  CreationDate?: string;
  LastModifiedDate?: string;
  Limits?: JourneyLimits;
  LocalTime?: boolean;
  QuietTime?: QuietTime;
  RefreshFrequency?: string;
  Schedule?: JourneySchedule;
  StartActivity?: string;
  StartCondition?: StartCondition;
  State?: State;
  WaitForQuietTime?: boolean;
  RefreshOnSegmentUpdate?: boolean;
  JourneyChannelSettings?: JourneyChannelSettings;
  SendingSchedule?: boolean;
  OpenHours?: OpenHours;
  ClosedDays?: ClosedDays;
  TimezoneEstimationMethods?: __TimezoneEstimationMethodsElement[];
}

class PinpointJourneyCreate implements CreateJourneyCommandInput {
  ApplicationId: string;
  WriteJourneyRequest: IWriteJourneyRequest;
}

export type ILaunchpointCorePinpointJourneyParamsCreate = ILaunchpointUserAccountIds & PinpointJourneyCreate;

class PinpointUpdateJourney implements UpdateJourneyCommandInput {
  ApplicationId: string;
  JourneyId: string;
  WriteJourneyRequest: WriteJourneyRequest;
}

export type ILaunchpointCorePinpointJourneyParamsUpdate = {
  _id: string;
} & PinpointUpdateJourney;

class PinpointUpdateJourneyState implements UpdateJourneyStateCommandInput {
  ApplicationId: string;
  JourneyId: string;
  JourneyStateRequest: {
    State: EJourneyState;
  };
}

export type ILaunchpointCorePinpointJourneyParamsUpdateState = {
  _id: string;
} & PinpointUpdateJourneyState;

class PinpointDeleteJourney implements DeleteJourneyCommandInput {
  ApplicationId: string;
  JourneyId: string;
}

export type ILaunchpointCorePinpointJourneyParamsDelete = {
  _id: string;
} & PinpointDeleteJourney;

class PinpointListJourney implements ListJourneysCommandInput {
  ApplicationId: string;
  PageSize?: string;
  Token?: string;
}

export type ILaunchpointCorePinpointJourneyParamsList = PinpointListJourney;

class PinpointRetrieveJourney implements GetJourneyCommandInput {
  ApplicationId: string;
  JourneyId: string;
}

export type ILaunchpointCorePinpointJourneyParamsRetrieve = PinpointRetrieveJourney;

export class ILaunchpointCorePinpointJourneySearchQuery {
  search?: string;
  id?: string[];
  tags?: string[];
  //  creation_date?: ILaunchpointSearchFilterDates;
  // last_modified_date?: ILaunchpointSearchFilterDates;
  state?: EJourneyState[];
  sending_schedule?: boolean;
}

export class ILaunchpointCorePinpointJourneySearchPayload {
  query: ILaunchpointCorePinpointJourneySearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ILaunchpointCorePinpointJourneySearchResults {
  data: ILaunchpointCorePinpointJourney[];
  pagination: IQueryPageination;
}
