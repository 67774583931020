<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">
    <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-uppercase fs-7 text-gray-700">
          <th class="min-w-150px">Title</th>
          <th class="min-w-50px text-center">Featured</th>
          <th class="min-w-50px text-center">Related Posts</th>
          <th class="max-w-50px text-center">Live</th>
          <th class="min-w-120px text-end">Release At</th>
          <th class="min-w-120px text-end">Created</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">
        <tr *ngIf="(loading$ | async) === false && (blogsEntities$ | async)?.length === 0">
          <td>No Blogs</td>
        </tr>

        <tr *ngFor="let entity of blogsEntities$ | async" [routerLink]="'./' + entity?.blog_id + '/overview'" routerLinkActive="active">
          <ng-container *ngIf="entity">
            <td>
              <a [routerLink]="'./' + entity?.blog_id + '/overview'" routerLinkActive="active" class="text-dark text-hover-primary">
                {{ entity?.blog?.title }}
              </a>

              <div class="fs-7 text-muted">{{ entity?.blog?.description }}</div>
            </td>

            <td class="text-center">
              <i *ngIf="entity?.blog?.featured" class="fas fa-star text-primary"></i>
            </td>

            <td class="text-center">
              {{ entity?.blog?.related_posts?.length }}
            </td>

            <td class="text-center">
              @if (live(entity?.blog)) {
                <i class="bi bi-check-circle text-success"></i>
              } @else {
                <i
                  class="bi bi-x-circle text-danger"
                  [ngbTooltip]="'Active: ' + (entity?.blog?.active?.toString() | titlecase) + '\nRelease: ' + getReleaseText(entity?.blog)"
                ></i>
              }
            </td>

            <td class="text-end">
              {{ entity?.blog?.release_at | date: 'medium' }}
            </td>

            <td class="text-end">
              {{ entity?.blog?.created_at | date }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
  </div>
</div>
