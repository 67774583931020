export const SECURITY_PERMISSIONS_GUARD_TOKEN = 'SECURITY_PERMISSIONS_GUARD_TOKEN';

export const SECURITY_REQUEST_PERMISSIONS_TOKEN = 'SECURITY_REQUEST_PERMISSIONS_TOKEN';

export interface ISecurityPermissionsToken {
  action: string;
  resource: string | string[];
}
/**
 * this is the default application of access when the user has no security roles or accounts/security roles.
 */
export const SECURITY_PERMISSIONS_DEFAULT_ROLE = 'default';
/**
 * this is the default keyword for Launchpoint and CASL to access/manage an entire collection
 */
export const SECURITY_PERMISSIONS_ACCESS_ALL = 'manage';

export enum ELaunchpointUserRoles {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}
export enum ELaunchpointAccountRoles{
  TEST ='test_role',
  ANOTHER ='another_role',
  ENUM="a role on enum"
}
