import { Component, HostBinding, Optional } from '@angular/core';
import { LaunchpointCoreClientAuthLogoutBaseComponent } from '@launchpoint/core-client';
import { MessagingService } from '../../../../notifications/notification.service';

@Component({
  selector: 'launchpoint-core-web-auth-v1-logout',
  templateUrl: './auth-v1-logout.component.html',
  styleUrls: ['./auth-v1-logout.component.scss'],
})
export class LaunchpointCoreWebAuthV1LogoutComponent extends LaunchpointCoreClientAuthLogoutBaseComponent {
  constructor(@Optional() public _MessagingService: MessagingService) {
    super();
    // this.isLoading$ = this.authService.isLoading$;
    // // redirect to home if already logged in
    // if (this.authService.currentUserValue) {
    //   this.router.navigate(['/']);
    // }
  }

  // d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }

  override async logOutScripts() {
    if (this._MessagingService) {
      // console.log('removing notification token...');
      await this._MessagingService.removeToken();
    }
  }
  // convenience getter for easy access to form fields
}
