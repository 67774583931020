import { Directive, Input } from '@angular/core';
import { ICoreUser } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';

@Directive()
export abstract class AccountBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() config:
    | {
        user: ICoreUser;
        edit_profile_page: string;
        menu_items: { title: string; page: string }[];
      }
    | undefined;

  constructor() {
    super();
  }
}
