import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
// import { LaunchpointUsersStateBaseComponent } from '../../../../users/+state/users-state.base-component';
//  extends LaunchpointUsersStateBaseComponent
@Directive()
export class LaunchPointAccountsBaseSmartUserComponent {
  constructor(public _Store: Store) {
    // super(_Store);
  }
  // async dispatchSave(event: Omit<ICoreUserDemographicsParamsUpdatePhone, 'user_id'>) {
  //   const user = await firstValueFrom(this.selected_user$);
  //   this._Store.dispatch(updateUserPhone({ data: { ...event, user_id: user.user._id } }));
  // }
}