import { Component, Input } from '@angular/core';

@Component({
  selector: 'launchpoint-app-lists-widget8',
  templateUrl: './lists-widget8.component.html',
})
export class ListsWidget8Component {
  @Input() cssClass = '';
  // constructor() {}
}
