import { Component } from '@angular/core';

import { LaunchpointCoreClientProfileNotificationsBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-core-web-profile-notifications',
  templateUrl: './profile-notifications.component.html',
})
export class LaunchpointCoreWebProfileNotificationsComponent extends LaunchpointCoreClientProfileNotificationsBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
}
