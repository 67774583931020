import { inject, Injectable } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { ConfirmPasswordValidator } from '../../../../forms/validators/confirm-password.validator';
import { ILaunchpointAuth } from '../../auth/interfaces/auth.interface';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../interfaces/auth-storage-service.interface';

@Injectable()
export class LaunchpointCoreClientAuthFormService {
  _config: ICoreAuthConfig = inject(APP_AUTH_CONFIG_TOKEN);
  fb = inject(UntypedFormBuilder);

  isEmail = true;

  public form: FormGroup<ControlsOf<ILaunchpointAuth>>;

  private baseValidator = [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
  ];



  // todo: how to switch validator?? form listener not working
  // public emailValidator = [...this.baseValidator, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$')];
  // public phoneValidator = [...this.baseValidator, Validators.pattern(/^\+?[0-9\s()-]+$/)];
  // public emailOrPhoneValidator = [
  //   ...this.baseValidator,
  // ];

  public passwordValidators = [Validators.required]; // basic validation, additional validators are added in configurePassword()

  constructor() {
    this.initForm();
    this.configurePassword();


    if (this._config.registration.allow_phone) {
      this.baseValidator.push(
        Validators.pattern(/^(.+@\w+\.\w{2,3}|\d{10})$/) // https://stackoverflow.com/questions/26814331/regular-expression-which-accept-both-email-address-and-10-digit-phone-number
      );
    } else {
      this.baseValidator.push(Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'));
    }


    // todo this isnt working
    // this.form.controls.email.valueChanges.subscribe({
    //   next: (value) => {
    //     if (this._config.registration?.allow_phone) {
    //       // console.log('value', value);
    //       if (this.isEmail && value.match(/^\+?[0-9\s()-]+$/)) {
    //         // console.log('is phone && match')
    //         this.isEmail = false;
    //         this.setValidator('phone');
    //       }
    //     }
    //   },
    // });
  }

  initForm() {
    this.form = this.fb.group({
      email: [this.form?.value?.email || '', this.baseValidator],
      password: ['', this.passwordValidators],
    });

    return this.form;
  }

  numberPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = /\d/; // At least one number
      const valid = regex.test(control.value);
      return valid ? null : { numberPattern: true };
    };
  }

  specialCharacterPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = /[!@#$%^&*(),.?":{}|<>]/; // At least one special character
      const valid = regex.test(control.value);
      return valid ? null : { specialCharacterPattern: true };
    };
  }

  uppercasePatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = /[A-Z]/; // At least one uppercase letter
      const valid = regex.test(control.value);
      return valid ? null : { uppercasePattern: true };
    };
  }

  /**
   * Password Validators are added from config password_setting options
   */
  configurePassword() {
    const { max_length, min_length, require_number, require_special, require_uppercase } = this._config?.set_password?.password_settings ?? {};

    if (min_length) {
      this.passwordValidators.push(Validators.minLength(min_length ?? 8));
    }
    if (max_length) {
      this.passwordValidators.push(Validators.maxLength(max_length ?? 100));
    }
    if (require_number) {
      this.passwordValidators.push(this.numberPatternValidator());
    }
    if (require_special) {
      this.passwordValidators.push(this.specialCharacterPatternValidator());
    }
    if (require_uppercase) {
      this.passwordValidators.push(this.uppercasePatternValidator());
    }
  }

  get email() {
    return this.form.controls.email;
  }

  setValidator(type: 'email' | 'phone') {
    // if (type === 'email') {
    //   this.form.controls.email.setValidators(this.emailValidator);
    // }
    // if (type === 'phone') {
    //   this.form.controls.email.setValidators(this.phoneValidator);
    // }

    // this.form.controls.email.updateValueAndValidity();
  }

  get setLoginForm() {
    this.form = this.fb.group({
      email: [this.form?.value?.email || '', this.baseValidator],
      // on login we should only validate password to be `required`
      password: ['', Validators.required],
    });
    return this.form;
  }

  get getSetPasswordForm() {
    this.form = this.fb.group(
      {
        password: ['', this.passwordValidators],
        cPassword: ['', this.passwordValidators],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
    return this.form;
  }

  get getSetVerifyForm() {
    const payload: any = {
      email: [this.form?.value?.email || '', this.baseValidator],
      token: [
        '',
        [
          Validators.required,
          Validators.minLength(this._config.verify?.code_length ?? 6),
          Validators.maxLength(this._config.verify?.code_length ?? 6),
        ],
      ],
    };

    if (this.form?.value?.password) {
      payload.password = [this.form.value.password, this.passwordValidators];
    }

    this.form = this.fb.group(payload);
    return this.form;
  }

  get getResetPasswordForm() {
    this.form = this.fb.group({
      email: [this.form?.value?.email || '', this.baseValidator],
    });
    return this.form;
  }

  setRegistrationForm(email_only = false, agreement_required: boolean) {
    if (email_only) {
      this.form = this.fb.group({
        email: [this.form?.value?.email || '', this.baseValidator],
        agreement: [false],
      });
    } else {
      this.form = this.fb.group(
        {
          email: [this.form?.value?.email || '', this.baseValidator],
          password: ['', this.passwordValidators],
          cPassword: ['', this.passwordValidators],
          agreement: [false],
        },
        {
          validator: ConfirmPasswordValidator.MatchPassword,
        }
      );
    }
    if (agreement_required === true) {
      this.form.controls.agreement.setValidators([Validators.requiredTrue]);
      this.form.updateValueAndValidity();
    }
    return this.form;
  }
}
