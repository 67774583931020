import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhook,
  ICoreActivityHistoryWebhookSearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { CoreActivityHistoryWebhookEventHTTPService } from '../services/webhook-event-http.service';

@Directive()
export abstract class LaunchpointCoreClientWebhookEventsSearchAheadBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;
  @Input() query: ICoreActivityHistoryWebhookSearchQuery;
  @Input() pagination: IQueryPageination = { limit: 15, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };
  @Output() selected = new EventEmitter<ICoreActivityHistoryWebhook>();

  constructor(public _CoreActivityHistoryEventHTTPService: CoreActivityHistoryWebhookEventHTTPService) {
    super();
  }

  ngOnInit(): void {
    this.runQuery('');
  }

  formatter = (result: ICoreActivityHistoryWebhookEvent): string => `${result._id}`;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly ICoreActivityHistoryWebhookEvent[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._CoreActivityHistoryEventHTTPService
      .search({ pagination: this.pagination, querySort: this.querySort, query: { ...this.query, search } })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  selectData(data: { item: ICoreActivityHistoryWebhook }) {
    return this.selected.emit(data.item);
  }
}
