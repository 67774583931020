/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserSelectors } from '../../user';
import { LaunchpointAbilitySecurityService } from '../services/security.service';

// *lpSecurityAccess="[ESecurityCoreUserResources.USER_EMAIL_UPDATE, LAUNCHPOINT_USER_SCHEMA_NAME]"
@Directive({
  selector: '[lpSecurityAccess]',
})
export class LaunchpointAbilitySecurityBaseDirective implements OnDestroy {
  // _security = false

  private destroy$ = new Subject<void>();
  private isViewing = false;
  constructor(
    public _viewContainer: ViewContainerRef,
    public templateRef: TemplateRef<any>,
    public _Store: Store,
    public _SecurityService: LaunchpointAbilitySecurityService
  ) {}

  @Input()
  set lpSecurityAccess([action, subject]: [string, string]) {
    this._Store
      .select(UserSelectors.selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => this._updateView(this._SecurityService.checkAbility(user, action, subject)),
      });
  }

  _updateView(accessGranted: boolean) {
    if (accessGranted && !this.isViewing) {
      // console.log(`SHOWING THIS ${permission}:${resource} ---------------------- SHOWING THIS`)
      this._viewContainer.createEmbeddedView(this.templateRef);
      this.isViewing = true;
    } else if (!accessGranted && this.isViewing) {
      // console.log(`HIDING THIS ${permission}:${resource} >>>>>>>>>>>>>>>>>>>>>>>>>> HIDING THIS `)
      this._viewContainer.clear();
      this.isViewing = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
