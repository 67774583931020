export interface EmailTemplateResponse {
  TemplateName?: string;
  HtmlPart?: string;
  TextPart?: string;
  SubjectPart?: string;
  // Additional common properties found in AWS SDK responses
  ResponseMetadata?: {
    RequestId?: string;
  };
  TemplateDescription?: string;
  DefaultSubstitutions?: string;
  TemplateType?: string;
  Version?: string;
  CreationDate?: string;
  LastModifiedDate?: string;
  Tags?: { [key: string]: string };
  // Add more properties as needed
}
