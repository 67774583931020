import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserAuthActions } from '../+state';

@Injectable()
export class LaunchpointCoreClientAuthResetPasswordGuard implements CanActivate {
  constructor(private _Store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.params.reset_password_token) {
      this._Store.dispatch(UserAuthActions.logout({ path: null }));
      return false;
    }
    return true;
  }
}
