import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  APP_MEDIA_CONFIG_TOKEN,
  CORE_MEDIA_CATEGORY_EFFECTS,
  CORE_MEDIA_CATEGORY_SERVICES,
  CORE_MEDIA_EFFECTS,
  coreMediaCategoryFeatureKey,
  coreMediaCategoryReducer,
  coreMediaFeatureKey,
  coreMediaReducer,
  ICoreMediaConfig,
  LaunchpointSecurityModule,
  CORE_MEDIA_SERVICES,
} from '@launchpoint/core-client';
import { NgbDropdownModule, NgbModalModule, NgbModule, NgbProgressbarModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ngfModule } from 'angular-file';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebBooleanFilterComponent } from '../../../components';
import { LaunchpointCoreWebEllipsisMenuComponent } from '../../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointCoreWebPaginationComponent } from '../../../components/pagination/pagination.component';
import { LaunchpointCoreWebToolbarComponent } from '../../../components/toolbar/toolbar.component';
import { MimetypeDirective } from '../components/mimetype.directive';
import { VideoPlayerModule } from '../components/video-player/video-player.module';
import { LaunchpointCoreWebMediaCategoriesModalComponent } from './categories/media-categories-modal/media-categories-modal.component';
import { LaunchpointCoreWebMediaCategoriesSearchComponent } from './categories/media-categories-search/media-categories-search.component';
import { LaunchpointCoreWebMediaCategoriesTableComponent } from './categories/media-categories-table/media-categories-table.component';
import { LaunchpointCoreMediaCategoriesTagifyComponent } from './categories/media-categories-tagify/media-categories-tagify.component';
import { LaunchpointCoreWebMediaCategoriesToolbarComponent } from './categories/media-categories-toolbar/media-categories-toolbar.component';
import { LaunchpointCoreWebMediaDetailModalComponent } from './media/media-detail-modal/media-detail-modal.component';
import { LaunchpointCoreWebMediaDetailComponent } from './media/media-detail/media-detail.component';
import { LaunchpointCoreWebMediaFilterComponent } from './media/media-filter-menu/media-filter-menu.component';
import { LaunchpointCoreWebMediaHeaderComponent } from './media/media-header/media-header.component';
import { LaunchpointCoreMediaSearchAheadComponent } from './media/media-search-ahead/media-search-ahead.component';
import { LaunchpointCoreWebMediaTableComponent } from './media/media-table/media-table.component';
import { LaunchpointCoreWebMediaToolbarComponent } from './media/media-toolbar/media-toolbar.component';
import { LaunchpointCoreWebMediaUploadModalComponent } from './media/media-upload/media-upload-modal/media-upload-modal.component';
import { LaunchpointCoreWebMediaUploadComponent } from './media/media-upload/media-upload.component';
import { LaunchpointCoreWebMediaVideoSettingsModalComponent } from './media/media-video-settings-modal/media-video-settings-modal.component';

export const MEDIA_COMPONENTS = [
  LaunchpointCoreWebMediaHeaderComponent,
  LaunchpointCoreWebMediaFilterComponent,
  LaunchpointCoreWebMediaDetailModalComponent,
  LaunchpointCoreWebMediaVideoSettingsModalComponent,
  //
  LaunchpointCoreWebMediaUploadComponent,
  LaunchpointCoreWebMediaUploadModalComponent,
  //
  LaunchpointCoreWebMediaCategoriesToolbarComponent,
  LaunchpointCoreWebMediaCategoriesTableComponent,
  LaunchpointCoreWebMediaCategoriesModalComponent,
  //
  LaunchpointCoreWebMediaToolbarComponent,
  LaunchpointCoreWebMediaTableComponent,
  LaunchpointCoreWebMediaDetailComponent,
];

const MEDIA_STATE = [
  // StoreModule.forFeature(coreMediaDashboardFeatureKey, coreMediaDashboardReducer),
  StoreModule.forFeature(coreMediaFeatureKey, coreMediaReducer),
  StoreModule.forFeature(coreMediaCategoryFeatureKey, coreMediaCategoryReducer),
  EffectsModule.forFeature([...CORE_MEDIA_EFFECTS, ...CORE_MEDIA_CATEGORY_EFFECTS]),
];

@NgModule({
  declarations: [...MEDIA_COMPONENTS, MimetypeDirective],
  imports: [
    ...MEDIA_STATE,
    TagifyModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    ngfModule,
    NgbProgressbarModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    VideoPlayerModule,
    RouterModule,
    NgbDropdownModule,
    ClipboardModule,
    LaunchpointSecurityModule,
    LaunchpointCoreMediaSearchAheadComponent,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointCoreWebPaginationComponent,
    LaunchpointCoreWebEllipsisMenuComponent,
    LaunchpointCoreWebMediaCategoriesSearchComponent,
    LaunchpointCoreMediaCategoriesTagifyComponent,
    LaunchpointCoreWebBooleanFilterComponent,
  ],
  providers: [...CORE_MEDIA_SERVICES, ...CORE_MEDIA_CATEGORY_SERVICES],
  exports: [...MEDIA_COMPONENTS, MimetypeDirective],
})
export class LaunchpointCoreWebMediaModule {
  static forRoot(_config: ICoreMediaConfig): ModuleWithProviders<LaunchpointCoreWebMediaModule> {
    return {
      ngModule: LaunchpointCoreWebMediaModule,
      providers: [
        {
          provide: APP_MEDIA_CONFIG_TOKEN,
          useValue: _config,
        },
      ],
    };
  }
}
