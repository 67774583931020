import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Basic implementation, you can customize this as needed
    if (!value) return '';

    // Remove non-numeric characters
    const numbersOnly = value.replace(/\D/g, '');

    // Format as (XXX) XXX-XXXX
    const formatted = numbersOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    return formatted;
  }
}
