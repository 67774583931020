import { Component, HostBinding, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ERROR_CONFIG_TOKEN, IErrorModuleConfig } from '@launchpoint/core-client';
import { MenuComponent } from '../../layouts/core/kt/components/MenuComponent';
import { DrawerComponent } from '../../layouts/core/kt/components/_DrawerComponent';
import { ScrollComponent } from '../../layouts/core/kt/components/_ScrollComponent';
import { ScrollTopComponent } from '../../layouts/core/kt/components/_ScrollTopComponent';
import { StickyComponent } from '../../layouts/core/kt/components/_StickyComponent';
import { ToggleComponent } from '../../layouts/core/kt/components/_ToggleComponent';

@Component({
  selector: 'launchpoint-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss'],
})
export class AppErrorsCoreComponent {
  @HostBinding('class') class = 'd-flex flex-column flex-root';
  constructor(private router: Router, @Inject(APP_ERROR_CONFIG_TOKEN) public _config: IErrorModuleConfig) {}

  routeToDashboard() {
    this.router.navigate(['/']);
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
    }, 200);
  }
}
