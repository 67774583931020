<div class="modal-content rounded">
  <div class="modal-header d-flex flex-row justify-content-between align-items-center bd-highlight py-4 flex-wrap">
    <div class="d-flex flex-stack">
      <div class="fs-3 fw-bold">
        Upload {{ allowMultiple === 'true' ? 'Files' : 'File' }}
      </div>
    </div>
    <div class="btn btn-sm btn-icon" (click)="activeModal.dismiss()">
      <i class="bi bi-x-lg m-3"></i>
    </div>
  </div>
  <div class="modal-body scroll-y p-10">
    <div class="mb-0">

      @if (allowMultiple === 'false' && files?.length === 0 || allowMultiple === 'true') {
      <div class="rounded border border-dashed border-gray-300 p-4 mb-5">
        <div class="d-flex align-items-center justify-content-center">
          <launchpoint-media-upload [multiple]="allowMultiple" (filesAdded)="push($event)"
            (fileAdded)="push([$event])" />
        </div>
      </div>
      }

      <div *ngIf="files?.length > 0" class="p-4">
        <div>
          <span class="fw-bold fs-6">{{ allowMultiple === 'true' ? 'Files' : 'File' }} Added</span>
        </div>
      </div>


      <div *ngFor="let fileE of files; let i = index" class="rounded border bg-gray-100 p-4 my-5"
        [ngClass]="{ 'border-success': fileE?.media }">
        <div class="d-flex flex-stack">
          <div class="d-flex flex-column flex-fill me-4 ms-1">
            <div class="d-flex align-items-center justify-content-between">

              <div class="d-flex align-items-center">
                <div *ngIf="!edit?.[fileE?.file?.name]?.edit" class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                  {{ fileE?.file?.name }}
                </div>

                <input *ngIf="edit?.[fileE?.file?.name]?.edit"
                  class="form-control form-control-sm form-control-solid bg-white min-w-300px" type="text"
                  [placeholder]="fileE?.file?.name" name="title" autocomplete="off"
                  [(ngModel)]="edit[fileE?.file.name].name" />

                <div (click)="edit?.[fileE?.file?.name]?.edit ? saveFile(fileE) : editFile(fileE?.file?.name)"
                  class="btn btn-icon btn-sm text-hover-primary fs-7 cursor-pointer text-end me-5">
                  <i *ngIf="!edit?.[fileE?.file?.name]?.edit" class="bi bi-pencil-square" ngbTooltip="Edit Title"></i>
                  <i *ngIf="edit?.[fileE?.file?.name]?.edit" class="bi bi-check-circle" ngbTooltip="Save"></i>
                </div>
              </div>

              @if (!media) {
              <div class="d-flex align-items-center">
                <launchpoint-media-categories-search-ahead cssClass="pe-12 form-control-sm form-control-solid bg-white"
                  [selected]="fileE?.category?._id" (selectedCategory)="setCategory($event, i)" />

                <div *ngIf="categories[i]" (click)="removeCategory(i)"
                  class="btn btn-icon btn-sm text-hover-warning fs-7 cursor-pointer text-end me-5">
                  <i class="bi bi-x-square" ngbTooltip="Reset Category"></i>
                </div>
              </div>
              }
            </div>

            <div *ngIf="fileE.uploadPercentage" class="fs-7 mt-5">
              <ngb-progressbar [type]="fileE.uploadPercentage < 100 ? 'info' : 'success'"
                [value]="fileE.uploadPercentage" [striped]="true" [animated]="false">
                {{ fileE.uploadPercentage < 100 ? fileE.uploadPercentage : 'Finishing Up' }}</ngb-progressbar>
            </div>

            <div *ngIf="fileE.error" class="fs-7 text-danger mt-2">
              <span>{{ fileE?.file?.name }} failed to upload</span>
            </div>
          </div>

          <div *ngIf="!fileE?.media" (click)="remove(i)"
            class="btn btn-icon btn-sm text-hover-danger fs-7 cursor-pointer text-end">
            <i class="bi bi-trash fs-4" ngbTooltip="Remove File"></i>
          </div>
        </div>
      </div>

      <div *ngIf="files?.length > 0" class="text-end mt-10">
        <button type="button" (click)="clear()" class="btn btn-transparent me-5"><u>Clear</u></button>
        <button type="button" (click)="uploadFile()" [disabled]="uploadDisabled" class="btn btn-primary">Upload</button>
      </div>

    </div>

  </div>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>