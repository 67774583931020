import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LaunchpointFormControlSearchAheadBaseComponent } from '@launchpoint/core-client';
import { COUNTRIES_FULL_MAP, ICountry } from '@launchpoint/core-types';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, NgbModule],
  selector: 'launchpoint-core-countries-search',
  templateUrl: 'countries-search-ahead.component.html',
})
export class CountriesSearchAheadWebComponent extends LaunchpointFormControlSearchAheadBaseComponent implements OnInit {
  loading = false;
  @Input() placeholder = 'Search Countries...';
  @Input() cssClass = 'form-control form-control-lg bg-white form-control-solid fs-6';
  @Input() style = 'width: 100%;';
  @Input() ngClass;

  countries = COUNTRIES_FULL_MAP;

  @Input() selected: string;

  @Output() selectedCountry = new EventEmitter<ICountry>();

  ngOnInit(): void {
    this.runQuery('');
    this.getById(this.selected);
  }

  getById(string: string) {
    console.log('selected string', string);
    if (!string) {
      return;
    }

    const found = this.countries.find((country) => country.abbreviation === string || country.name === string);

    if (found) {
      this.placeholder = found.name;
      this.selectedCountry.emit(found);
    }
  }

  formatter(result: ICountry): string {
    return result.name;
  }

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly ICountry[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string): Observable<readonly ICountry[]> {
    this.loading = true;
    const data = this.countries.filter(
      (country) => country.name.toLowerCase().includes(search.toLowerCase()) || country.abbreviation.toLowerCase().includes(search.toLowerCase())
    );
    this.loading = false;
    return new Observable<readonly ICountry[]>((observer) => {
      observer.next(data);
      observer.complete();
    });
  }

  selectData(data: { item: ICountry }) {
    return this.selectedCountry.emit(data.item);
  }
}
