import { IHttpException, IQueryPageination, IQuerySort, IUserNotificationSearchQuery } from '@launchpoint/core-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserNotificationActions from '../actions/user-notification.actions';
import { UserNotificationEntityState } from '../interfaces/user-notification-entity.interface';

export const UserNotificationFeatureKey = 'UserNotificationReducer';

export interface UserNotificationState extends EntityState<UserNotificationEntityState> {
  selected_user_notification_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IUserNotificationSearchQuery;
  create_modal: boolean;
}

export const UserNotificationEntityAdaptor: EntityAdapter<UserNotificationEntityState> = createEntityAdapter<UserNotificationEntityState>({
  selectId: (user_notification) => user_notification.user_notification_id,
});

export const initialUserNotificationtate: UserNotificationState = UserNotificationEntityAdaptor.getInitialState({
  // set initial required properties
  selected_user_notification_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: null },
  error: null,
  success_message: null,
  create_modal: false,
});

export const UserNotificationReducer = createReducer(
  initialUserNotificationtate,

  // Get by ID

  on(UserNotificationActions.getUserNotificationById, (state, { user_notification_id }) => {
    const entity: UserNotificationEntityState = {
      user_notification_id,
      user_notification: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return UserNotificationEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_user_notification_id: user_notification_id,
      loaded: true,
      loading: false,
    });
  }),

  on(UserNotificationActions.getUserNotificationByIdSuccess, (state, { user_notification }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: user_notification._id,
        changes: {
          user_notification,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
        selected_user_notification_id: user_notification._id,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(UserNotificationActions.getUserNotificationByIdFailure, (state, { user_notification_id, error }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: user_notification_id,
        changes: {
          error,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Search

  on(UserNotificationActions.searchAllUserNotifications, (state) => {
    return UserNotificationEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserNotificationActions.searchAllUserNotificationsSuccess, (state, { data }) => {
    const UserNotificationEntities = data.data.map((user_notification) => {
      const UserNotificationEntity: UserNotificationEntityState = {
        user_notification_id: user_notification._id,
        user_notification: user_notification,
        loaded: true,
        loading: false,
        error: null,
      };
      return UserNotificationEntity;
    });
    return UserNotificationEntityAdaptor.addMany(UserNotificationEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(UserNotificationActions.searchAllUserNotificationsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),

  // Create

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(UserNotificationActions.createUserNotification, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),

  on(UserNotificationActions.createUserNotificationSuccess, (state, { data, success_message }) => {
    const entity: UserNotificationEntityState = {
      user_notification_id: data._id,
      user_notification: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return UserNotificationEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      create_modal: false,
      selected_user_notification_id: data._id,
    });
  }),

  on(UserNotificationActions.createUserNotificationFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),

  // Update

  on(UserNotificationActions.updateUserNotification, (state, { data }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(UserNotificationActions.updateUserNotificationSuccess, (state, { user_notification, success_message }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: user_notification._id,
        changes: {
          user_notification,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(UserNotificationActions.updateUserNotificationFailure, (state, { user_notification_id, error }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: user_notification_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Delete

  on(UserNotificationActions.deleteUserNotification, (state, { id }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(UserNotificationActions.deleteUserNotificationSuccess, (state, { data, success_message }) => {
    return UserNotificationEntityAdaptor.removeOne(data._id, {
      ...state,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
    });
  }),
  on(UserNotificationActions.deleteUserNotificationFailure, (state, { user_notification_id, error }) => {
    return UserNotificationEntityAdaptor.updateOne(
      {
        id: user_notification_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  //Mecanics
  on(UserNotificationActions.updateAllUserNotificationQuery, (state, { query }) => {
    return UserNotificationEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(UserNotificationActions.updateAllUserNotificationPagination, (state, { pagination }) => {
    return UserNotificationEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(UserNotificationActions.updateAllUserNotificationPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loading: true,
    // error: null,
    success_message: null,
  })),

  on(UserNotificationActions.updateAllUserNotificationSorting, (state, { querySort }) => {
    return UserNotificationEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  })
);
