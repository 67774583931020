<div class="lauchpoint-launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <div class="pb-20">
      <div class="bg-primary mb-0 notification-header rounded">
        <div>
          <h1 class="text-white p-10">Dashboard</h1>
        </div>
      </div>

      <launchpoint-notifications-messages-carousel [selectedChannel]="selectedChannel"
        (cardClicked)="onCardClicked($event)" />

      <div class="pt-20">
        <launchpoint-toolbar [options]="messageOptions" (create)="onMessageCreate()" />
        <launchpoint-notifications-messages-table [pageLimit]="10" [query]="messageQuery" />

        <div class="d-flex justify-content-center mt-5">
          <a routerLink="/admin/notifications/messages" routerLinkActive="active" (click)="onMessageView()">View All</a>
        </div>
      </div>


      <div class="pt-20">
        <launchpoint-toolbar [options]="notificationOptions" (create)="onNotificationCreate()" />
        <launchpoint-notifications-table [pageLimit]="10" />

        <div class="d-flex justify-content-center mt-5">
          <a routerLink="/admin/notifications/notifications" routerLinkActive="active"
            (click)="onNotificationView()">View All</a>
        </div>
      </div>
    </div>
  </div>
</div>