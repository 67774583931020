import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { throwIfAlreadyLoaded } from '@launchpoint/xplat/utils';
import {
  CoreModule,
  PlatformLanguageToken,
  PlatformWindowToken,
} from '@launchpoint/xplat/core';

// bring in custom web services here...

// factories
export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/`, '.json');
}

@NgModule({ imports: [BrowserModule,
        CoreModule.forRoot([
            {
                provide: PlatformLanguageToken,
                useFactory: platformLangFactory,
            },
            {
                provide: PlatformWindowToken,
                useFactory: winFactory,
            },
        ]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class LaunchpointCoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: LaunchpointCoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'LaunchpointCoreModule');
  }
}
