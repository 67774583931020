import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationsCarouselBaseComponent } from '@launchpoint/core-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebNotificationMessageCreateComponent } from '../notifications-message-create/notifications-message-create.component';

@Component({
  selector: 'launchpoint-notifications-messages-toolbar',
  templateUrl: './notifications-messages-toolbar.component.html',
  styleUrls: ['./notifications-messages-toolbar.component.scss'],
})
export class LaunchpointCoreWebNotificationMessagesToolbarComponent extends LaunchpointCoreClientNotificationsCarouselBaseComponent {
  private _swal = inject(LaunchpointSweetAlertService);
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  options: IToolbarConfig = {
    title: 'Messages',
    formControl: this.form_control,
    create: true,
    searchPlaceholder: 'Search messages',
  };

  create() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebNotificationMessageCreateComponent, { size: 'lg', scrollable: true });
  }

  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
