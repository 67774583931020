import { IQueryPageination, IQuerySort } from '../../query.interfaces';

export const MEDIA_CATEGORIES_MODEL_NAME = 'MEDIA_CATEGORIES';

export interface IMediaCategory {
  title: string;
  //
  _id: string;
  created_at: Date;
  updated_at: Date;
}

/**
 * SEARCH QUERY
 *
 * These are the properties you can use to search for data
 *
 * @param search is a string to query the `title` property in the database
 */
export interface IMediaCategorySearchQuery {
  search?: string;
}

/**
 * SEARCH PAYLOAD
 *
 * This is the requried payload to run a search query and return values
 */
export interface IMediaCategorySearchPayload {
  query: IMediaCategorySearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

/**
 * SEARCH RESULTS
 *
 * This is the payload for the search query results.
 */
export interface IMediaCategorySearchResults {
  pagination: IQueryPageination;
  data: IMediaCategory[];
}

export interface IMediaCategoryParamsCreate {
  title: string;
}

export interface IMediaCategoryParamsUpdate {
  _id: string;
  title: string;
}
