import { Directive, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { searchAllMedia } from '../+state/actions/media.actions';
import { LaunchpointCoreClientMediaStateBaseComponent } from '../+state/media-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientMediaBaseComponent extends LaunchpointCoreClientMediaStateBaseComponent implements OnInit {
  listView = true;

  form_control = new UntypedFormControl('');

  ngOnInit() {
    this._Store.dispatch(searchAllMedia());
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery({ search }),
      error: (error) => console.log('ERROR', error),
    });
    this.success();
    this.errors();
  }
}
