import { Directive, Input } from '@angular/core';
import { ICoreActivityHistory, ICoreActivityHistorySearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { ActivityHistoryAction } from './actions';
import { ActivityHistoryEntityState } from './interfaces/activity-history.interface';
import {
  selectActivityHistory,
  selectActivityHistoryError,
  selectActivityHistoryLoading,
  selectActivityHistoryPagination,
  selectActivityHistoryQuery,
  selectActivityHistoryQuerySort,
  selectActivityHistorySuccess,
  selectActivityHistorySuccessQueryCount,
  selectSelectedActivityHistory,
  selectSelectedActivityHistoryError,
  selectSelectedActivityHistoryLoading,
  selectSelectedActivityHistorySuccess,
} from './selectors/activity-history.selectors';

@Directive()
export abstract class LaunchpointCoreClientActivityHistoryStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreActivityHistorySearchQuery>;
  queryFilterCount$: Observable<number>;

  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;

  activityHistoryEntities$: Observable<ActivityHistoryEntityState[]>;

  activityHistory$: Observable<ICoreActivityHistory>;
  activityHistoryLoading$: Observable<boolean>;
  activityHistoryError$: Observable<IHttpException>;
  activityHistorySuccess$: Observable<string>;

  @Input() admin = true;
  @Input() baseRoute = './';

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(selectActivityHistoryLoading);
    this.pagination$ = this._Store.select(selectActivityHistoryPagination);
    this.querySort$ = this._Store.select(selectActivityHistoryQuerySort);
    this.query$ = this._Store.select(selectActivityHistoryQuery);
    this.queryFilterCount$ = this._Store.select(selectActivityHistorySuccessQueryCount);

    this.error$ = this._Store.select(selectActivityHistoryError);
    this.success$ = this._Store.select(selectActivityHistorySuccess);

    this.activityHistoryEntities$ = this._Store.select(selectActivityHistory);

    // Entity
    this.activityHistory$ = this._Store.select(selectSelectedActivityHistory);
    this.activityHistoryLoading$ = this._Store.select(selectSelectedActivityHistoryLoading);
    this.activityHistoryError$ = this._Store.select(selectSelectedActivityHistoryError);
    this.activityHistorySuccess$ = this._Store.select(selectSelectedActivityHistorySuccess);
  }

  getActivityHistory(history_id: string) {
    this._Store.dispatch(ActivityHistoryAction.getActivityHistoryById({ history_id }));
  }

  deleteHistory(history_id: string) {
    this._Store.dispatch(ActivityHistoryAction.deleteActivityHistory({ history_id }));
  }

  getTooltip(statusCode: number) {
    const statusDescriptions = {
      100: 'Continue',
      101: 'Switching Protocols',
      102: 'Processing',
      200: 'OK',
      201: 'Created',
      202: 'Accepted',
      203: 'Non-Authoritative Information',
      204: 'No Content',
      205: 'Reset Content',
      206: 'Partial Content',
      207: 'Multi-Status',
      208: 'Already Reported',
      300: 'Multiple Choices',
      301: 'Moved Permanently',
      302: 'Found',
      303: 'See Other',
      304: 'Not Modified',
      305: 'Use Proxy',
      307: 'Temporary Redirect',
      308: 'Permanent Redirect',
      400: 'Bad Request',
      401: 'Unauthorized',
      402: 'Payment Required',
      403: 'Forbidden',
      404: 'Not Found',
      405: 'Method Not Allowed',
      406: 'Not Acceptable',
      407: 'Proxy Authentication Required',
      408: 'Request Timeout',
      409: 'Conflict',
      410: 'Gone',
      411: 'Length Required',
      412: 'Precondition Failed',
      413: 'Payload Too Large',
      414: 'URI Too Long',
      415: 'Unsupported Media Type',
      416: 'Range Not Satisfiable',
      417: 'Expectation Failed',
      418: "I'm a teapot",
      421: 'Misdirected Request',
      422: 'Unprocessable Entity',
      423: 'Locked',
      424: 'Failed Dependency',
      425: 'Too Early',
      426: 'Upgrade Required',
      428: 'Precondition Required',
      429: 'Too Many Requests',
      431: 'Request Header Fields Too Large',
      451: 'Unavailable For Legal Reasons',
      500: 'Internal Server Error',
      501: 'Not Implemented',
      502: 'Bad Gateway',
      503: 'Service Unavailable',
      504: 'Gateway Timeout',
      505: 'HTTP Version Not Supported',
      506: 'Variant Also Negotiates',
      507: 'Insufficient Storage',
      508: 'Loop Detected',
      510: 'Not Extended',
      511: 'Network Authentication Required',
    };

    return statusDescriptions[statusCode] || 'Unknown Status Code';
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
    this.activityHistorySuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
    this.activityHistoryError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
  }

  toast(params) {
    //
  }
}
