<input
  [ngClass]="cssClass"
  type="text"
  [placeholder]="loading ? 'Loading...' : placeholder"
  (focus)="onFocus($event)"
  [ngbTypeahead]="search"
  [resultFormatter]="formatter"
  [popupClass]="popupClass"
  [inputFormatter]="formatter"
  [editable]="false"
  (selectItem)="selectData($event)"
/>
