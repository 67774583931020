import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { BASE_TAGIFY_SETTINGS, LaunchpointCoreClientMediaBaseComponent } from '@launchpoint/core-client';
import { EMediaMimeTypes, IMedia, IMediaCategory, IMediaSearchQuery } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Component({
  selector: 'launchpoint-media-filter-menu',
  templateUrl: './media-filter-menu.component.html',
})
export class LaunchpointCoreWebMediaFilterComponent extends LaunchpointCoreClientMediaBaseComponent implements OnInit {
  public tagifyService = inject(TagifyService);
  private fb = inject(UntypedFormBuilder);

  @Input() media: IMedia;

  category: IMediaCategory[] = [];
  tags: string[] = [];

  private settings: TagifySettings = {
    ...BASE_TAGIFY_SETTINGS,
    enforceWhitelist: false,
  };

  tagSettings: TagifySettings = {
    ...this.settings,
    placeholder: 'Type to add tags',
  };
  typeSettings: TagifySettings = {
    ...this.settings,
    placeholder: 'Search by media type',
  };

  whitelistStatus$ = new BehaviorSubject<EMediaMimeTypes[]>(Object.values(EMediaMimeTypes));
  whitelistTags$: Observable<string[]>;

  form: FormGroup<{
    tags: FormControl<{ value: string }[]>;
    types: FormControl<{ value: EMediaMimeTypes }[]>;
    archived: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.buildForm();
    this.whitelistTags$ = this._MediaTagsService.distinct().pipe(map((f) => f));

    setTimeout(() => {
      const tags = this.media?.tags ?? [];
      if (tags.length) {
        (this.tagifyService.get('tags') as Tagify)?.update({ withoutChangeEvent: true });
        this.tagifyService.get('tags')?.loadOriginalValues(tags);
      }
    }, 250);
  }

  buildForm() {
    this.form = this.fb.group({
      tags: [],
      types: [],
      archived: [],
    });
  }

  change(value: boolean | null) {
    this.form.controls.archived.setValue(value);
  }

  setCategory(event: IMediaCategory[]) {
    this.category = event;
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    const query: IMediaSearchQuery = {
      tags: form.tags?.map((tag) => tag.value) ?? [],
      category: this.category?.map((cat) => cat._id) ?? [],
      archived: form.archived,
      types: form.types?.map((type) => type.value) ?? [],
    };

    this.updateQuery(query);
  }

  reset() {
    this.tags = [];
    this.category = null;
    this.tagifyService.get('tags')?.removeAllTags();
    this.tagifyService.get('categories')?.removeAllTags();

    this.form.reset();
    this.submit();
  }
}
