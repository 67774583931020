import { inject, Injectable, signal } from '@angular/core';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { UserSelectors } from '../user';
import { CoreUserNotificationV1Service } from '../user/core/core-notifications/services';

export interface IMessagingBaseService {
  tokenBS: BehaviorSubject<string>;
  token$: Observable<any>;
  messagesBS: BehaviorSubject<any>;
  message$: Observable<any>;
  showRequest: boolean;
  enabled: boolean;

  onMessageInit();
  onTokenInit();
  request();
}
@Injectable()
export class LaunchpointCoreClientMessagingBaseService {
  public _CoreUserNotificationV1Service = inject(CoreUserNotificationV1Service);
  public _Store = inject(Store);

  private token = signal<string>(null);
  private message = signal<any>(null);

  public tokenBS = new BehaviorSubject<string>(null);
  public token$: Observable<any> = this.tokenBS.asObservable();

  public messagesBS = new BehaviorSubject<any>(null);
  public message$: Observable<any> = this.messagesBS.asObservable();

  public showRequest = false;
  public enabled = false;

  public debug = false;
  /**
   * Flag to determine if the service has been initialized to prevent multiple initializations or trying to add and remove tokens multiple times.
   */
  public initialized = false;

  public user: ICoreUser;

  constructor() {
    this._Store.select(UserSelectors.selectUser).subscribe((user) => {
      this.user = user;
    });
  }

  async addTokenToUser(token: string | void, apns?: string) {
    // console.log('adding token to user', token, this.user?.email);
    if (this.user) {
      try {
        if (token) {
          // Currently not updating the user in state because it's not critical to the FE to know. I think.
          await firstValueFrom(this._CoreUserNotificationV1Service.token({ token, apns }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  async removeTokenFromUser(token: string, apns?: string) {
    // console.log('removing token from user', token, this.user?.email);
    if (this.user) {
      try {
        if (token) {
          // Currently not updating the user in state because it's not critical to the FE to know. I think.
          await firstValueFrom(this._CoreUserNotificationV1Service.tokenDelete({ token, apns }));
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // async addTopicToUser(topic: string) {
  //   await firstValueFrom(this._CoreUserNotificationV1Service.token({ token }));
  // }
}
