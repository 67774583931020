import { Component, Input } from '@angular/core';
import { LaunchpointLayoutMenu } from '../../../../../../interfaces/menu.interfaces';

@Component({
  selector: 'launchpoint-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent {
  @Input() menuConfig: LaunchpointLayoutMenu;
}
