import { Directive, signal } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '../base/base.component';

@Directive()
export abstract class LaunchpointCoreClientStepperBaseComponent extends LaunchpointCoreClientBaseComponent {
  totalSteps = 5;
  currentStep$ = signal(0);

  setStep(step: number) {
    this.currentStep$.set(step);
  }

  nextStep() {
    const nextStep = this.currentStep$() + 1;
    if (nextStep > this.totalSteps) {
      return;
    }
    this.currentStep$.set(nextStep);
  }

  prevStep() {
    const prevStep = this.currentStep$() - 1;
    if (prevStep === 0) {
      return;
    }
    this.currentStep$.set(prevStep);
  }
}
