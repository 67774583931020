import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LaunchpointCoreClientMediaCategoriesBaseComponent } from '@launchpoint/core-client';
import { IMediaCategory } from '@launchpoint/core-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-media-categories-modal',
  templateUrl: 'media-categories-modal.component.html',
})
export class LaunchpointCoreWebMediaCategoriesModalComponent extends LaunchpointCoreClientMediaCategoriesBaseComponent implements OnInit {
  public activeModal = inject(NgbActiveModal);
  private fb = inject(UntypedFormBuilder);

  _swal = inject(LaunchpointSweetAlertService);

  @Input() category: IMediaCategory;

  public form: FormGroup<{
    title: FormControl<string>;
  }>;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      title: [null, Validators.required],
    });

    if (this.category) {
      this.form.patchValue(this.category);
    }
  }

  close() {
    this.activeModal.close();
  }

  deletePopup(category: IMediaCategory) {
    this._swal
      .fireAlert({
        icon: 'warning',
        text: 'Are you sure you want to delete this category?',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteCategory(category);
          this.close();
        }
      });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    if (this.category) {
      this.update({
        _id: this.category._id,
        title: form.title,
      });
    } else {
      this.create({
        title: form.title,
      });
    }

    this.close();
  }
}
