import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBillingParentBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-company-billing-parent',
  templateUrl: 'company-billing-parent.component.html',
})
export class CompanyBillingParentComponent extends CompanyBillingParentBaseComponent {
  constructor(public _Store: Store, _router: Router, _route: ActivatedRoute) {
    super(_Store, _router, _route);
  }
}
