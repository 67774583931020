import {
  ICoreUser,
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsAddMainUserAccount,
  ICoreUserAccountsParamsAddSubUserAccount,
  ICoreUserAccountsParamsGetPendingInvitations,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  ICoreUserAccountsParamsSwapAccount,
  ICoreUserAccountsUserPendingInvitations,
  IHttpException,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

/**
 * Profile Swap
 */

// select account
export const swapAccount = createAction('[User Account Swap] swapAccount', props<{ payload: ICoreUserAccountsParamsSwapAccount }>());
export const swapAccountSuccess = createAction('[User Account Swap] swapAccountSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const swapAccountFailure = createAction('[User Account Swap] swapAccountFailure', props<{ error: IHttpException }>());

export const deselectAccount = createAction('[User Account Swap] deselectAccount', props<{ payload: ICoreUserAccountsParamsSwapAccount }>());
export const deselectAccountSuccess = createAction(
  '[User Account Swap] deselectAccountSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const deselectAccountFailure = createAction('[User Account Swap] deselectAccountFailure', props<{ error: IHttpException }>());

// get account invitations
export const getAccountInvitations = createAction(
  '[User Profile Swap] getAccountInvitations',
  props<{ payload: ICoreUserAccountsParamsGetPendingInvitations }>()
);
export const getAccountInvitationsSuccess = createAction(
  '[User Profile Swap] getAccountInvitationsSuccess',
  props<{ pending_invitations: ICoreUserAccountsUserPendingInvitations[]; success_message?: string }>()
);
export const getAccountInvitationsFailure = createAction('[User Profile Swap] getAccountInvitationsFailure', props<{ error: IHttpException }>());

// accept invitation
export const acceptInvitation = createAction('[User Profile Swap] acceptInvitation', props<{ payload: ICoreUserAccountsParamsAcceptInvitations }>());
export const acceptInvitationSuccess = createAction(
  '[User Profile Swap] acceptInvitationSuccess',
  props<{ message: { message: string }; success_message?: string }>()
);
export const acceptInvitationFailure = createAction('[User Profile Swap] acceptInvitationFailure', props<{ error: IHttpException }>());

// resend invite
export const resendInvite = createAction('[User Profile Swap] resendInvite', props<{ payload: ICoreUserAccountsParamsResendInvite }>());
export const resendInviteSuccess = createAction(
  '[User Profile Swap] resendInviteSuccess',
  props<{ message: { message: string }; success_message?: string }>()
);
export const resendInviteFailure = createAction('[User Profile Swap] resendInviteFailure', props<{ error: IHttpException }>());

// add sub user
export const addSubUserAccount = createAction(
  '[User Profile Swap] addSubUserAccount',
  props<{ payload: ICoreUserAccountsParamsAddSubUserAccount }>()
);
export const addSubUserAccountSuccess = createAction(
  '[User Profile Swap] addSubUserAccountSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const addSubUserAccountFailure = createAction('[User Profile Swap] addSubUserAccountFailure', props<{ error: IHttpException }>());

// add main user
export const addMainUserAccount = createAction(
  '[User Profile Swap] addMainUserAccount',
  props<{ payload: ICoreUserAccountsParamsAddMainUserAccount }>()
);
export const addMainUserAccountSuccess = createAction(
  '[User Profile Swap] addMainUserAccountSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const addMainUserAccountFailure = createAction('[User Profile Swap] addMainUserAccountFailure', props<{ error: IHttpException }>());

// remove account
export const removeAccount = createAction('[User Profile Swap] removeAccount', props<{ payload: ICoreUserAccountsParamsRemove }>());
export const removeAccountSuccess = createAction('[User Profile Swap] removeAccountSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const removeAccountFailure = createAction('[User Profile Swap] removeAccountFailure', props<{ error: IHttpException }>());
