<div class="navbar-area">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
      <a class="navbar-brand" routerLink="/"><img [src]="menu_config?.logo" width="200px" alt="logo" /></a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="burger-menu">
          <span class="top-bar"></span>
          <span class="middle-bar"></span>
          <span class="bottom-bar"></span>
        </span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ms-auto">
          <ng-container *ngFor="let item of items">
            <ng-container [ngTemplateOutlet]="rootMenuTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-container>
          </ng-container>
        </ul>
        <!-- Other Options  -->

        <a [routerLink]="['/']" fragment="features" class="btn btn-primary">features</a>
        <div class="others-option">
          <a *ngIf="menu_config.cart" [routerLink]="menu_config.cart_page" class="cart-wrapper-btn">
            <!-- <i-feather name="shopping-cart"></i-feather> -->

            <span>0</span></a
          >
          <a *ngIf="menu_config.call_to_action" [routerLink]="menu_config.call_to_action_page" class="btn btn-light">{{
            menu_config.call_to_action_title
          }}</a>
          <a *ngIf="menu_config.login" [routerLink]="menu_config.login_page" class="btn btn-primary">Login</a>
        </div>
        <!-- Other Options End -->
      </div>
    </nav>
  </div>
</div>

<ng-template #rootMenuTemplate let-item="item">
  <li class="nav-item">
    <!-- Root Objects  -->

    <a
      *ngIf="item?.root"
      [href]="item?.page ? item.page : item?.external_link ? item.external_link : 'javascript:void(0)'"
      class="nav-link dropdown-toggle"
      >{{ item?.title }}
      <!-- <i-feather *ngIf="item?.icon" [name]="item?.icon"></i-feather
    > -->
    </a>
    <!-- Root Objects End  -->

    <!-- SubMent Objects  -->
    <ul *ngIf="item?.submenu?.length > 0" class="dropdown-menu">
      <li class="nav-item" *ngFor="let sub of item.submenu">
        <a [routerLink]="sub.page" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" class="nav-link">{{ sub.title }}</a>
      </li>
    </ul>
    <!-- SubMent Objects End -->
  </li>
</ng-template>
