import { Component, inject } from '@angular/core';
import { CompanyCreateBaseComponent } from '@launchpoint/core-client';
import { ICoreCompanyParamsCreate } from '@launchpoint/core-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-company-create',
  templateUrl: 'company-create.component.html',
})
export class CompanyCreateComponent extends CompanyCreateBaseComponent {
  activeModal = inject(NgbActiveModal);

  close() {
    this.activeModal.close();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    const data: ICoreCompanyParamsCreate = {
      account_type: this.form.value.account_type,
      company_name: this.form.value.company_name,
      phone: this.form.value.phone,
      account_owner: this.form.value.account_owner,
      email: this.form.value.email,
      // licences: this.form.value.licences.map((f) => ({
      //   licence_type: f.licence_type,
      //   owner: f.owner,
      //   license_number: f.license_number,
      //   individual_name: f.individual_name,
      // })),
    };
    this.create(data);
    this.close();
  }
}
