import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LaunchpointFormControlSearchAheadBaseComponent } from '@launchpoint/core-client';
import { IState, STATES_FULL_NAME_MAP } from '@launchpoint/core-types';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, NgbModule],
  selector: 'launchpoint-core-states-search',
  templateUrl: 'states-search-ahead.component.html',
})
export class StatesSearchAheadWebComponent extends LaunchpointFormControlSearchAheadBaseComponent implements OnInit {
  loading = false;
  @Input() placeholder = 'Search States...';
  @Input() cssClass = 'form-control form-control-lg bg-white form-control-solid fs-6';

  states = STATES_FULL_NAME_MAP;

  @Output() selected = new EventEmitter<IState>();

  ngOnInit(): void {
    this.runQuery('');
  }

  formatter(result: IState): string {
    return result.name;
  }

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly IState[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string): Observable<readonly IState[]> {
    this.loading = true;
    const data = this.states.filter(
      (country) => country.name.toLowerCase().includes(search.toLowerCase()) || country.abbreviation.toLowerCase().includes(search.toLowerCase())
    );
    this.loading = false;
    return new Observable<readonly IState[]>((observer) => {
      observer.next(data);
      observer.complete();
    });
  }

  selectData(data: { item: IState }) {
    return this.selected.emit(data.item);
  }
}
