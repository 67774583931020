import { Directive, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { addUserRole, deleteUserRole } from '../../../../../users/+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';
import { ICoreUserSecurityRoleParamsUpdateNoUserID } from './profile-security-roles-base.component';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../../core-profile/interfaces/core-profile.interface';

interface IProfileSecurityRolesConfig {
  title: string;
  fields: {
    security: boolean;
  };
}

@Directive()
export class LaunchpointCoreWebProfileSecurityRolesSmartAdminBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  constructor(public _Store: Store, @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig) {
    super(_Store);
  }

  // PROFILE_CONFIG_TOKEN = new InjectionToken<LaunchpointCoreClientProfileConfig>('PROFILE_CONFIG_TOKEN');

  async profileSecurityAdd(role: ICoreUserSecurityRoleParamsUpdateNoUserID) {
    const user = await firstValueFrom(this.selected_user$);
    this._Store.dispatch(addUserRole({ params: { user_id: user.user._id, ...role } }));
  }
  async profileSecurityRemove(role: ICoreUserSecurityRoleParamsUpdateNoUserID) {
    const user = await firstValueFrom(this.selected_user$);
    this._Store.dispatch(deleteUserRole({ params: { user_id: user.user._id, ...role } }));
  }
}
