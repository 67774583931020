import { BaiduChannelRequest, UpdateBaiduChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointBaiduChannelParamsUpdate implements UpdateBaiduChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.BAIDU;
  BaiduChannelRequest: IBaiduChannelRequest;
}

class IBaiduChannelRequest implements BaiduChannelRequest {
  Enabled?: boolean;
  ApiKey: string | undefined;
  SecretKey: string | undefined;
}
