import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreUserV1Service, LaunchpointCoreClientBaseComponent, TopicsHTTPService } from '@launchpoint/core-client';
import { ITopic, ITopicSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebNotificationsTopicsTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;

  @Input() topics: ITopic[] = [];
  @Input() query: ITopicSearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 100, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  public topicsModel: { value: string; data: ITopic }[] = [];
  public whitelistTopics$: Observable<{ value: string; data: ITopic }[]>;

  protected tagify: Tagify;

  public settings: TagifySettings = {
    placeholder: 'Search Users...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false, // <-- if this is set to true, you CANNOT pre-load with value, but be set after the fact
    keepInvalidTags: false,
  };

  @Output() selectedTopic = new EventEmitter<ITopic[]>();

  constructor(
    public _TopicsHTTPService: TopicsHTTPService,
    public tagifyService: TagifyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.whitelistTopics$ = this._TopicsHTTPService.search({ query: this.query ?? {}, pagination: this.pagination, querySort: this.querySort }).pipe(
      map((f) =>
        f.data.map((d) => ({
          value: d.topic,
          data: d,
        }))
      )
    );
    if (this.topics?.length > 0) {
      this.topicsModel = this.topics.map((d) => ({
        value: d.topic,
        data: d,
      }));

      setTimeout(() => {
        if (this.topicsModel?.length) {
          this.tagifyService.get('topics').loadOriginalValues(this.topicsModel);
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    }
  }

  formatter = (result: ITopic): string => result.topic;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  clear() {
    this.tagifyService.get('topics').removeAllTags();
  }

  search: OperatorFunction<string, readonly ITopic[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._TopicsHTTPService.search({ pagination: this.pagination, querySort: this.querySort, query: { ...this.query, search } }).pipe(
      map((data) => {
        this.loading = false;
        return data.data;
      })
    );
  }

  selectData(data: any) {
    // console.log('selectData', data);
    return this.selectedTopic.emit(data.map((f) => f.data));
  }
}
