import { Observable } from 'rxjs';
import {
  INotificationMessage,
  INotificationMessageParamsCreate,
  INotificationMessageParamsUpdate,
  INotificationMessageSearchPayload,
  INotificationMessageSearchResults,
} from './notifications-message.interface';

/**
 * Controller('notifications/messages')
 */
export interface INotificationMessageController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<INotificationMessage> | Observable<INotificationMessage>;
  /**
   * Post('find-one')
   * @param params Partial<INotificationMessage>
   */
  findOne(params: Partial<INotificationMessage>): Promise<INotificationMessage> | Observable<INotificationMessage>;
  /**
   * Post('search')
   * @param body
   */
  search(body: INotificationMessageSearchPayload): Promise<INotificationMessageSearchResults> | Observable<INotificationMessageSearchResults>;
  /**
   * Post()
   * @param body
   */
  create(body: INotificationMessageParamsCreate): Promise<INotificationMessage> | Observable<INotificationMessage>;
  /**
   * Put()
   * @param body
   */
  update(body: INotificationMessageParamsUpdate): Promise<INotificationMessage> | Observable<INotificationMessage>;
  /**
   * Delete(':id')
   * @param id
   */
  delete(id: string): Promise<INotificationMessage> | Observable<INotificationMessage>;
}
