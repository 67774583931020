import { ICoreUser } from '../../../user-company-account.interface';

export enum ELaunchpointCoreUserAccountEvent {
  MAIN_USER_ADDED = 'Main User Added',
  SUB_USER_ADDED = 'Sub User Added',
  REMOVED = 'Removed',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export interface ILaunchpointCoreUserAccountEventBody {
  inviting_user: ICoreUser;
  invited_user: ICoreUser;
  account_id: string;
}

export type ILaunchpointCoreUserAccountEventPayload = {
  type: ELaunchpointCoreUserAccountEvent;
  body: ILaunchpointCoreUserAccountEventBody;
};
