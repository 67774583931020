<div class="w-lg-500px p-10">
  <!--begin::Form-->
  <form class="form w-100 mb-13" *ngIf="form" [formGroup]="form">
    <!--begin::Icon-->
    <!-- <div class="text-center mb-10">
      <img alt="Logo" class="mh-125px" src="/metronic8/demo1/assets/media/svg/misc/smartphone-2.svg" />
    </div> -->
    <!--end::Icon-->
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-dark mb-3">Sign In Verification</h1>
      <!--end::Title-->
      <!--begin::Sub-title-->
      <!-- <div class="text-muted fw-semibold fs-5 mb-5">Enter the verification code we sent</div> -->
      <!--end::Sub-title-->
      <!--begin::Mobile no-->
      <!-- <div class="fw-bold text-dark fs-3">******7859</div> -->
      <!--end::Mobile no-->
    </div>
    <!--end::Heading-->

    <!-- begin::Alert error-->
    <ng-container *ngIf="error$ | async as error">
      <div class="mb-lg-15 alert alert-danger" *ngIf="error.action === EnumStateUserReducerActions.VERIFY">
        <div class="alert-text font-weight-bold">{{ error?.error.message }}</div>
      </div>
    </ng-container>
    <!-- end::Alert error-->
    <!-- <ng-container *ngIf="success$ | async as success">
      <div class="mb-lg-15 alert alert-success" *ngIf="success?.action === EnumStateUserReducerActions.VERIFY">
        <div class="alert-text font-weight-bold">{{ success?.message }}</div>
      </div>
    </ng-container> -->


    <!--begin::Section-->
    <div class="mb-10">
      <!--begin::Label-->

      <div class="fw-bold text-start text-muted fs-6 mb-1 mx-15">
        {{ _config?.verify?.description ?? 'Type/Paste the security code we sent.' }}
      </div>
      <!--end::Label-->
      <!--begin::Input group-->
      <div class="d-flex flex-wrap justify-content-center flex-stack">
        <input type="text" formControlName="token" class="form-control h-60px w-350px fs-2qx text-center mx-1 my-2" />
      </div>

      <!--begin::Input group-->
    </div>
    <!--end::Section-->
    <!--begin::Submit-->
    <div class="d-flex flex-center">
      <button [routerLink]="['auth', 'login']" routerLinkActive="router-link-active" type="button"
        class="btn btn-lg btn-light fw-bold me-2">
        <span class="indicator-label">Cancel</span>
      </button>
      <button type="submit" (click)="submit()" class="btn btn-lg btn-primary fw-bold ms-2"
        [disabled]="form.controls.token.errors || form.controls.email.errors">
        <ng-container *ngIf="(loading$ | async) === false">
          <span class="indicator-label">Submit</span>
        </ng-container>
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label" [style.display]="'block'">
            Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </ng-container>
      </button>
    </div>
    <!--end::Submit-->
  </form>
  <!--end::Form-->
  <!--begin::Notice-->
  <div class="text-center fw-semibold fs-5">
    <span class="text-muted me-1">Didn't get the code?</span>
    <a class="link-primary fs-5 me-1" [ngClass]="{ 'text-muted': disabled$ | async }"
      ngbTooltip="Please wait about 30 seconds before requesting a new code" (click)="resendCode()">Resend</a>
  </div>
  <!--end::Notice-->
</div>