import { Directive, Input } from '@angular/core';
import { IQueryPageination } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../components/base/base.component';

@Directive()
export abstract class LaunchpointFormControlSearchAheadBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() cssClass = 'form-control form-control-sm form-control-solid fs-6';
  @Input() popupClass = 'fs-6';
  /**
   * Input placeholder
   */
  @Input() placeholder: string;
  @Input() pagintation: IQueryPageination = {
    count: 0,
    limit: 100,
    skip: 0,
    pageIndex: 0,
    previousPageIndex: 0,
  };
  @Input() disabled: boolean;
  @Input() loading = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChanged: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
