import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { animate_css } from '../interfaces/animate-css.type';
import { AnimateCssService } from '../services/animate-css.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[animateCssHover]',
})
export class AnimatecsshoverDirective implements OnDestroy {
  @Input() animateCssHover: animate_css | animate_css[];
  constructor(private elementRef: ElementRef, private _AnimateCssService: AnimateCssService) {}

  @HostListener('mouseover') mouseOver() {
    this._AnimateCssService.addAnimation(this.elementRef, this.animateCssHover);
  }

  @HostListener('mouseout') mouseOut() {
    this._AnimateCssService.removeAnimation(this.elementRef, this.animateCssHover);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.elementRef.nativeElement.removeEventListener('animationend', this._AnimateCssService.removeAnimation(this.elementRef, this.animateCssHover));
  }
}
