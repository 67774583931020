import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { RequireAtLeastOne } from '../../require-one';
import { ICoreCompany } from '../../user-company-account.interface';
import { ICoreActivityHistory } from '../activity-history.interface';
import { ICoreActivityHistoryWebhook } from '../webhooks';

export interface ICoreActivityHistoryWebhookEventBase {
  // user: string | ICoreUser;
  account: string | ICoreCompany;
  /**
   * _id of the `activity-history` document
   */
  history: string | ICoreActivityHistory;
  webhook: string | ICoreActivityHistoryWebhook;
  status: number;
  request: {
    /**
     * history.event_name
     */
    event: string;
    /**
     * webhook.mode
     */
    mode: string;
    /**
     * history.request
     */
    request: any;
    /**
     * history.response
     */
    response: any;
  };
  response: any;

  // job information
  job_id: string;
  finished_at: number;
  processed_at: number;
  attempts: number;
  error: string;
  //
  _id: string;
  created_at: Date;
  updated_at: Date;
}

export interface ICoreActivityHistoryWebhookEvent extends ICoreActivityHistoryWebhookEventBase {
  history: ICoreActivityHistory;
  webhook: ICoreActivityHistoryWebhook;
  // user: ICoreUser;
  account: ICoreCompany;
}

export interface ICoreActivityHistoryWebhookEventParamsCreate {
  webhook: string;
  history: string;
  account: string;

  job_id: string;
  status: number;
  attempts: number;
  response: any;
  request: ICoreActivityHistoryWebhookEventBase['request'];

  error?: string;
  finished_at?: number;
  processed_at?: number;
}

export type ICoreActivityHistoryWebhookEventParamsUpdate = {
  _id: string;
} & RequireAtLeastOne<{ status?: number; attempts?: number; response?: string; error?: string; finished_at?: number; processed_at?: number }>;

export interface ICoreActivityHistoryWebhookEventSearchQuery {
  search?: string;
  webhook_ids?: string[];
  history_ids?: string[];
  account_ids?: string[];
  events?: string[];
  created_at?: ILaunchpointSearchFilterDates;
  finished_at?: ILaunchpointSearchFilterDates;
  processed_at?: ILaunchpointSearchFilterDates;
}

export interface ICoreActivityHistoryWebhookEventSearchPayload {
  query: ICoreActivityHistoryWebhookEventSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export interface ICoreActivityHistoryWebhookEventSearchResults {
  pagination: IQueryPageination;
  data: ICoreActivityHistoryWebhookEvent[];
}
