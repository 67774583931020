import { Observable } from 'rxjs';
import {
  IAuthForgotPasswordParams,
  IAuthenticationResponse,
  ICoreUserParamsFindV3,
  ICoreUserParamsLoginPassword,
  ICoreUserParamsLoginPasswordV3,
  ICoreUserParamsVerifyV2,
  ICoreUserParamsVerifyV3,
} from './auth.user.interface';
import { ICoreUserParamsCreate, ICoreUserParamsCreateV3 } from '../user-company-account.interface';

export interface IUserAuthController {
  getUserByToken(res?: any, authorization?: string): Promise<unknown> | Observable<unknown>;

  refreshUserToken(authorization?: string): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;

  //    login(@Body() body: IAuthLoginParams)

  createUser(body: ICoreUserParamsCreate): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;

  login(body: ICoreUserParamsLoginPassword): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;

  // Password Routes
  forgotPassword(body: IAuthForgotPasswordParams): Promise<boolean> | Observable<boolean>;
}

export interface IUserAuthControllerV2 {
  /**
   * @Post('sign-up')
   * @Version('2')
   * @param body
   */
  createUserV2(body: ICoreUserParamsCreate): Promise<{ message: string }> | Observable<{ message: string }>;
  // /**
  //  * @Post('sign-up-without-email')
  //  * @Version('2')
  //  * @param body
  //  */
  // createUserWithoutEmail(body: ICoreUserParamsCreateWithoutEmail): Promise<ICoreUser> | Observable<ICoreUser>;

  /**
   *
   * @Post('verify')
   * @Version('2')
   * @param body
   */
  authEmailVerificationTokenV2(body: ICoreUserParamsVerifyV2): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;
}

export interface IUserAuthControllerV3 {
  /**
   * @Post('sign-up')
   * @Version('3')
   */
  createUserV3(body: ICoreUserParamsCreateV3): Promise<{ message: string }> | Observable<{ message: string }>;
  /**
   * @Post('verify')
   * @Version('3')
   */
  authVerificationTokenV3(body: ICoreUserParamsVerifyV3): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;

  getUserByToken(res?: any, authorization?: string): Promise<unknown> | Observable<unknown>;

  refreshUserToken(authorization?: string): Promise<IAuthenticationResponse> | Observable<IAuthenticationResponse>;

  login(
    body: ICoreUserParamsLoginPasswordV3
  ): Promise<IAuthenticationResponse | { message: string }> | Observable<IAuthenticationResponse | { message: string }>;

  forgotPassword(body: ICoreUserParamsFindV3): Promise<boolean> | Observable<boolean>;
}
