import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersTableBaseUserSmartComponent } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-users-table-smart-user',
  templateUrl: './users-table-smart-user.component.html',
})
export class UsersTableSmartUserComponent extends UsersTableBaseUserSmartComponent {
  @Input() users: ICoreUser;
  constructor(public _router: Router, public _route: ActivatedRoute, public _Store: Store) {
    super(_router, _route, _Store);
  }
}
