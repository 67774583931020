import { Component, inject } from '@angular/core';
import {
  LaunchpointCoreClientMediaDetailBaseComponent,
  removeMediaAudio,
  removeMediaSubtitle,
  setMediaTag,
  updateMedia,
} from '@launchpoint/core-client';
import { IMedia, IMediaTagParamsSet, MEDIA_MODEL_NAME } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash';
import { IEllipsisMenuOption } from '../../../../../components/ellipsis-menu/ellipsis-menu.component';
import { ISwalAlertParamsToast, LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { LaunchpointCoreWebMediaDetailModalComponent } from '../media-detail-modal/media-detail-modal.component';
import { LaunchpointCoreWebMediaUploadModalComponent } from '../media-upload/media-upload-modal/media-upload-modal.component';
import { LaunchpointCoreWebMediaVideoSettingsModalComponent } from '../media-video-settings-modal/media-video-settings-modal.component';

@Component({
  selector: 'launchpoint-media-detail',
  templateUrl: './media-detail.component.html',
  styleUrls: ['./media-detail.component.scss'],
})
export class LaunchpointCoreWebMediaDetailComponent extends LaunchpointCoreClientMediaDetailBaseComponent {
  editModal: NgbModalRef;
  modalService = inject(NgbModal);
  private _swal = inject(LaunchpointSweetAlertService);

  replaced = false;

  schemaName = MEDIA_MODEL_NAME;

  show = false;
  media: IMedia;
  src = null; //'https://launchpoint-public.s3.us-west-2.amazonaws.com/media/6207cf58928bcd4ceda6113e/6207cf58928bcd4ceda6113e.pdf';

  ellipsisOptions(media: IMedia): IEllipsisMenuOption[] {
    const options: IEllipsisMenuOption[] = [
      {
        label: 'Edit',
        action: () => this.openEditModal(media),
      },
      {
        label: 'Replace',
        action: () => this.openUploadModal(media),
      },
      {
        label: 'Download',
        action: () => this.downloadURL(media),
      },
      {
        label: media?.is_archived ? 'Activate' : 'Archive',
        action: () =>
          this._Store.dispatch(
            updateMedia({
              data: {
                _id: media._id,
                is_archived: !media.is_archived,
              },
            })
          ),
      },
      {
        label: 'Delete',
        disabled: media?.usage?.length > 0,
        action: () => this.deleteOne(media),
      },
    ];

    return options;
  }

  deleteOne(media: IMedia) {
    this._swal
      .fireAlert({
        title: 'Are you sure you want to delete this?',
        text: `This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          const url = this._router.url.split('/');
          url.pop();
          this.deleteMedia({ _id: media._id }, url);
        }
      });
  }

  openEditModal(media: IMedia) {
    this.editModal = this.modalService.open(LaunchpointCoreWebMediaDetailModalComponent, { size: 'lg' });
    this.editModal.componentInstance.media = media;
  }

  openUploadModal(media: IMedia) {
    this.replaced = false;
    this.editModal = this.modalService.open(LaunchpointCoreWebMediaUploadModalComponent, { size: 'lg' });
    this.editModal.componentInstance.media = media;
    this.editModal.componentInstance.allowMultiple = 'false'; // !! DO NOT CHANGE THIS FROM STRING

    this.editModal.result
      .then(() => {
        this.replaced = true;
      })
      .catch((err) => {
        //
      });
  }

  setTags(event) {
    const { tags } = event;

    // console.log('tags', this.media.tags);

    const data: IMediaTagParamsSet = {
      _id: this.media?._id,
      tags: tags?.map((tag: { value: string }) => tag.value) ?? [],
    };

    if (isEqual(data.tags, this.media.tags)) {
      // console.log('tags are the same');
      return;
    }

    if (!data._id) {
      return;
    }

    // console.log('setting tags', data.tags);

    this._Store.dispatch(setMediaTag({ data }));
  }

  removeTag(tags: { value: string }[]) {
    const data: IMediaTagParamsSet = {
      _id: this.media._id,
      tags: tags?.map((tag: { value: string }) => tag.value) ?? [],
    };

    // console.log('setting tags:', data.tags);

    this._Store.dispatch(setMediaTag({ data }));
  }

  m3u8(media: IMedia): { src: string; type: string } {
    if (media?.meta_data?.mimetype.startsWith('video') && media?.meta_data?.m3u8) {
      return {
        src: media.meta_data.cdn_url + media.meta_data.m3u8,
        type: 'application/x-mpegURL',
      };
    }

    return {
      src: media.meta_data.cdn_url + media.meta_data.Key,
      type: media.meta_data.mimetype,
    };
  }

  addSettings(media: IMedia, type: 'Subtitle' | 'Audio' | 'Poster') {
    this.editModal = this.modalService.open(LaunchpointCoreWebMediaVideoSettingsModalComponent, { size: 'lg' });
    this.editModal.componentInstance.media = media;
    this.editModal.componentInstance.type = type;

    this.editModal.componentInstance.selected.subscribe((data: string | string[]) => {
      switch (type) {
        case 'Subtitle':
          this.addSubtitles(media._id, data);
          break;
        case 'Audio':
          this.addAudiotracks(media._id, data);
          break;
        case 'Poster':
          this.setPoster(media._id, data);
          break;
      }
    });
  }

  removePoster(media: IMedia) {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this poster?',
        text: 'This action cannot be undone',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            updateMedia({
              data: {
                _id: media._id,
                poster: null,
              },
            })
          );
        }
      });
  }

  removeSubtitle(media: IMedia, subtitle_id: string) {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this audio track?',
        text: 'This action cannot be undone',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            removeMediaSubtitle({
              data: {
                _id: media._id,
                subtitle_id: subtitle_id,
              },
            })
          );
        }
      });
  }

  removeTrack(media: IMedia, track_id: string) {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this audio track?',
        text: 'This action cannot be undone',
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            removeMediaAudio({
              data: {
                _id: media._id,
                audiotrack_id: track_id,
              },
            })
          );
        }
      });
  }

  override toast(params: ISwalAlertParamsToast) {
    this._swal.fireToast(params);
  }
}
