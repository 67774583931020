import { Observable } from 'rxjs';
import {
  ILaunchpointCorePinpointJourney,
  ILaunchpointCorePinpointJourneyParamsCreate,
  ILaunchpointCorePinpointJourneyParamsDelete,
  ILaunchpointCorePinpointJourneyParamsList,
  ILaunchpointCorePinpointJourneyParamsUpdate,
  ILaunchpointCorePinpointJourneySearchPayload,
  ILaunchpointCorePinpointJourneySearchResults,
} from './pinpoint-journey.interface';

/**
 * Controller('pinpoint/journeys')
 */
export interface ILaunchpointCorePinpointJourneyController {
  /**
   * Post(search)
   * @param body
   */
  search(
    body: ILaunchpointCorePinpointJourneySearchPayload
  ): Promise<ILaunchpointCorePinpointJourneySearchResults> | Observable<ILaunchpointCorePinpointJourneySearchResults>;
  /**
   * Get(:id)
   * @param _id
   */
  getById(_id: string): Promise<ILaunchpointCorePinpointJourney> | Observable<ILaunchpointCorePinpointJourney>;
  /**
   * Post()
   * @param body
   */
  create(body: ILaunchpointCorePinpointJourneyParamsCreate): Promise<ILaunchpointCorePinpointJourney> | Observable<ILaunchpointCorePinpointJourney>;
  /**
   * Put()
   * @param body
   */
  update(body: ILaunchpointCorePinpointJourneyParamsUpdate): Promise<ILaunchpointCorePinpointJourney> | Observable<ILaunchpointCorePinpointJourney>;
  /**
   * Put('delete;)
   * @param body
   */
  delete(body: ILaunchpointCorePinpointJourneyParamsDelete): Promise<ILaunchpointCorePinpointJourney> | Observable<ILaunchpointCorePinpointJourney>;
  /**
   * Post('sync')
   * @param body
   */
  sync(body: ILaunchpointCorePinpointJourneyParamsList): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
