<div class="bg-background py-10 md:pt-20">
  <div class="mx-auto max-w-[1700px] px-4 text-white">
    <div class="mb-14 flex flex-wrap justify-between gap-10">
      <div class="flex flex-wrap gap-10 lg:gap-40">
        <div>
          <a [routerLink]="['/']"><img src="./assets/img/stadnick-logo.png" class="mb-10 h-24 md:mb-16" /></a>
          <p class="w-[300px]">Rob Stadnick - Tech genius. Business Guru. Husband. Father. Veteran.</p>
        </div>
        <div class="flex flex-col flex-wrap gap-10 sm:flex-row md:gap-20">
          @for (item of footerItems(); track item.order) {
          <div>
            <p class="text-primary mb-3 text-[24px]">{{ item.title }}</p>
            <ul class="space-y-4">
              @for (child of item.children; track child.order) {
              <li>
                @if (child?.route && child?.route !== null) {
                <a [routerLink]="[child.route]" class="hover:text-primary">
                  {{ child.title }}
                </a>
                } @else if (!child.route && child.href !== null) {
                <a [href]="child.href" class="hover:text-primary">
                  {{ child.title }}
                </a>
                } @else {
                <!-- TODO: Support this -->
                }
              </li>
              }
            </ul>
          </div>
          }
        </div>
      </div>
      <div class="w-full md:w-auto">
        <div class="ml-2">
          <p class="mb-2 text-center md:text-[26px]">Stay in the loop with all our Free S**t</p>
          <div class="relative w-full">
            <input [formControl]="form" type="email" placeholder="Enter your email address"
              class="w-full border border-gray-300 py-3 pl-4 pr-[130px] text-sm text-black focus:outline-none focus:ring-2 focus:ring-[#010101]" />

            <button (click)="submitEmail()"
              class="absolute right-1.5 top-1/2 -translate-y-1/2 transform bg-[#010101] px-4 py-2.5 text-sm text-white transition-colors duration-300 ease-in-out hover:bg-[#01010160]">
              Keep In Touch
            </button>
          </div>
        </div>
        <div class="flex justify-center">
          <img src="./assets/img/vet-logo.png" class="h-auto w-[215px]" alt="Veteran Owned Logo" />
        </div>
        <!-- <div class="flex justify-center space-x-4 text-background">
          @for (item of social_links; track $index) {
            @if (item.active) {
              @if (item.href) {
                <a
                  [href]="item.href"
                  target="_blank"
                  class="inline-flex h-8 w-8 items-center justify-center rounded-full bg-[#A18B6F] hover:text-primary 2xl:h-10 2xl:w-10"
                >
                  <i class="fa-brands {{ item.icon }} text-2xl"></i>
                </a>
              }
            }
          }
        </div> -->
        <div class="flex justify-center">
          <sd-social-icons></sd-social-icons>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap-reverse items-center justify-center gap-10 font-light md:justify-between">
      <p class="flex items-center gap-1">
        © {{year}} Powered by
        <a href="https://launchpoint.dev?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website"
          class="hover:text-primary flex items-center gap-2 font-semibold underline">
          Launchpoint <img src="./assets/img/lp-logo.png" class="h-6" alt="Launchpoint Logo" /></a>
      </p>
      <p class="space-x-20">
        <a href="https://launchpoint.dev/privacy-policy?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website "
          class="hover:text-primary">Privacy Policy</a>
        <a href="https://launchpoint.dev/terms-and-conditions?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website"
          class="hover:text-primary">Terms & Conditions</a>
      </p>
    </div>
  </div>
</div>