import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LaunchpointViewStateBaseComponent, ViewActions } from '@launchpoint/core-client';
import { IUserView } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { LaunchpointSweetAlertService } from '../../../components/swal.service';

@Component({
  selector: 'launchpoint-user-view-delete',
  templateUrl: './user-view-delete.component.html',
  styleUrls: ['./user-view-delete.component.scss'],
})
export class UserViewDeleteComponent extends LaunchpointViewStateBaseComponent {
  @Input() view: IUserView;
  /**
   * @default "btn btn-sm btn-danger btn-active-danger-primary ms-2"
   */
  @Input() cssClass = 'btn btn-sm btn-danger ms-2';
  @Input() buttonTitle = 'Delete View';

  @Output() selected = new EventEmitter<IUserView | null>();
  constructor(
    public _Store: Store,
    private _swalService: LaunchpointSweetAlertService
  ) {
    super(_Store);
  }

  saveViewConfirmation() {
    if (!this.view?._id) {
      console.error('View id is missing to remove this view.');
    }
    this._swalService
      .fireAlert({
        icon: 'warning',
        title: `Do you want to delete ${this.view.title}?`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
          denyButton: 'order-3',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          //  this.Swal.fire('Saved!', '', 'success');
          this.deleteView();
        } else if (result.isDenied) {
          this._swalService.Swal.fire('Changes are not saved', '', 'info');
        }
      });
  }

  deleteView() {
    if (this.view) {
      this._Store.dispatch(ViewActions.deleteView({ view_id: this.view._id }));
    }
  }
}
