import { createAction, props } from '@ngrx/store';
import { LaunchpointTopbarConfig } from '../../../../interfaces/topbar.interface';
import { MenuState } from '../reducers/menu.interface';

export const loadAsideMenu = createAction('[Menu/API] loadAsideMenu', props<{ asideMenu: MenuState['asideMenu'] }>());
export const loadHeaderMenu = createAction('[Menu/API] loadHeaderMenu', props<{ headerMenu: MenuState['headerMenu'] }>());

/**
 * This actions loads the topbar with the topbar config interface.
 * Here you can configure and adjust all the settings on met top bar dynamically though this action.
 *
 */
export const loadTopbar = createAction('[Topbar/API] loadTopbar', props<{ topbarConfig: LaunchpointTopbarConfig }>());

/**
 * Loads the footer with alternate links.
 */
export const loadFooter = createAction('[Footer/API] loadFooter', props<{ footerConfig: MenuState['footer'] }>());
