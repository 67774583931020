import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientAlertBannerBaseComponent } from '@launchpoint/core-client';
import { ICoreAlertBanner } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreWebAlertBannerModalComponent } from '../alert-banner-modal/alert-banner-modal.component';

@Component({
  selector: 'launchpoint-alert-banner-table',
  templateUrl: 'alert-banner-table.component.html',
})
export class LaunchpointCoreWebAlertBannerTableComponent extends LaunchpointCoreClientAlertBannerBaseComponent {
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  open(banner: ICoreAlertBanner) {
    this.modalRef = this.modalService.open(LaunchpointCoreWebAlertBannerModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.banner = banner;
  }
}
