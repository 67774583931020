import { ICoreCompanyAccountOwnerParamsUpdate, ICoreCompany, IHttpException } from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// UPDATE
export const updateCompanyAccountOwner = createAction(
  '[SLIS-CA-Companies] updateCompanyAccountOwner',
  props<{ data: ICoreCompanyAccountOwnerParamsUpdate }>()
);
export const updateCompanyAccountOwnerSuccess = createAction(
  '[SLIS-CA-Companies] updateCompanyAccountOwnerSuccess',
  props<{ company: ICoreCompany; success_message?: string }>()
);
export const updateCompanyAccountOwnerFailure = createAction(
  '[SLIS-CA-Companies] updateCompanyAccountOwnerFailure',
  props<{ company_id: string; error: IHttpException }>()
);
