import { Observable } from 'rxjs';
import { IDateRange } from '../../../dates.interface';
import { ICoreCreatedAtAnalytics } from '../../../user-company-account.interface';
/**
 * @Controller('user-analytics')
 */
export interface ICoreUserAnalyticsController {
  /**
   * Get()
   * @param id
   */
  signUps(params: IDateRange): Promise<ICoreCreatedAtAnalytics> | Observable<ICoreCreatedAtAnalytics>;
}
