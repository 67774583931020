<div class="bg-medium py-20">
  <div class="mx-auto max-w-[1700px] px-4">
    <div class="text-center">
      <p class="font-oswald text-[28px] font-semibold leading-tight md:text-[50px] lg:text-[60px] xl:text-[80px] 2xl:text-[100px]">
        HERE'S SOME <span class="text-primary">FREE</span> SH*T, <br />
        BECAUSE NOBODY IS TRYING <br />
        TO GATE KEEP
      </p>
    </div>
    <div class="mt-20 flex flex-col items-center gap-4 lg:flex-row">
      <div *ngFor="let resource of resources" class="flex h-[400px] flex-col justify-between border-[1px] border-black p-4 lg:w-[565px]">
        <img [src]="resource.image" [alt]="resource.title" class="h-[200px] w-auto object-cover" />
        <p class="font-oswald text-[32px] font-semibold">{{ resource.title }}</p>
        <div class="inline-flex">
          <a [href]="resource.href" class="cta flex items-center gap-2 text-[22px] font-light hover:text-black">
            <span>View</span><i class="fa-regular fa-arrow-right mt-1 text-[18px]"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
