import { SECURITY_PERMISSIONS_DEFAULT_ROLE, ELaunchpointUserRoles } from '../security.constants';
import { ISecurityConfigACLRole, ISecurityConfig } from '../security.interface';
import { MEDIA_CATEGORIES_MODEL_NAME } from './media-categories/media-category.interface';
import { MEDIA_MODEL_NAME } from './media.interface';
import { ESecurityMediaResources } from './media.permissions.interface';

export let ABAC_MEDIA_DEFAULT_ROLES: ISecurityConfigACLRole[] = [];
export let ABAC_MEDIA_MANAGE_ROLE: ISecurityConfigACLRole[] = [];
export let ABAC_MEDIA: ISecurityConfig = {
  debug: true,
  acl: {},
};

try {
  ABAC_MEDIA_DEFAULT_ROLES = [
    {
      effect: 'can',
      permissions: [
        {
          actions: [ESecurityMediaResources.MEDIA_READ],
          subject: MEDIA_MODEL_NAME,
        },
      ],
      // conditions: [ no conditions yet ],
    },
  ];
} catch (error) {
  //
}
try {
  ABAC_MEDIA_MANAGE_ROLE = [
    {
      effect: 'can',
      permissions: [
        { subject: MEDIA_MODEL_NAME, actions: ['manage'] },
        { subject: MEDIA_CATEGORIES_MODEL_NAME, actions: ['manage'] },
      ],
    },
  ];
} catch (error) {
  //
}

try {
  ABAC_MEDIA = {
    debug: false,
    acl: {
      [SECURITY_PERMISSIONS_DEFAULT_ROLE]: ABAC_MEDIA_DEFAULT_ROLES,
      [ELaunchpointUserRoles.USER]: [],
      [ELaunchpointUserRoles.SUPER_ADMIN]: ABAC_MEDIA_MANAGE_ROLE,
    },
  };
} catch (error) {
  console.error(error);
}
