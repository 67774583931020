export interface IPdfParamsCreate {
  info: {
    title: string;
    author: string;
    subject: string;
    keywords: string;
  };
  footer: {
    text: string;
    margin: number[];
    alignment: string;
  };
  background(
    currentPage: number,
    pageSize: number
  ): {
    text: string;
    margin: number[];
    alignment: string;
  };
  pageBreakBefore(currentNode: any, followingNodesOnPage: any, nodesOnNextPage: any, previousNodesOnPage: any): any;
  content: any[];
  styles: { [key: string]: any };
  defaultStyle: { [key: string]: any };
}


export interface IPdfMediaParamsCreate {
    s3: {
      bucket: string;
      region: string;
      /**
       * Typically private_prefix from the CLOUDFRONT distrubtion
       */
      prefix: string;
    };
    filename: string;
    category: string;
    description?: string;
    metadata: { [key: string]: string };
  
    doc: IPdfParamsCreate;
  }