export interface ICoreBlogParamsCommentReplyAdd {
  /**
   * blog._id
   */
  _id: string;
  /**
   * comment_id
   */
  comment_id: string;
  comment: string;
  title: string;
  /**
   * user._id
   */
  user?: string;
}

export interface ICoreBlogParamsCommentReplyIds {
  /**
   * blog._id
   */
  _id: string;
  comment_id: string;
  reply_id: string;
}
