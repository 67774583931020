import { Observable } from 'rxjs';
import {
  IMediaCategory,
  IMediaCategoryParamsCreate,
  IMediaCategorySearchPayload,
  IMediaCategorySearchResults,
  IMediaCategoryParamsUpdate,
} from './media-category.interface';

export interface IMediaCategoryController {
  /**
   * @Post('search')
   * @param body
   */
  search(body: IMediaCategorySearchPayload): Promise<IMediaCategorySearchResults> | Observable<IMediaCategorySearchResults>;

  /**
   * @Get(':id')
   * @param id
   */
  getById(id: string): Promise<IMediaCategory> | Observable<IMediaCategory>;

  /**
   * @Post()
   * @param body
   */
  create(body: IMediaCategoryParamsCreate): Promise<IMediaCategory> | Observable<IMediaCategory>;

  /**
   * @Put()
   * @param body
   */
  update(body: IMediaCategoryParamsUpdate): Promise<IMediaCategory> | Observable<IMediaCategory>;

  /**
   * @Delete(':id')
   * @param id
   */
  delete(id: string): Promise<IMediaCategory> | Observable<IMediaCategory>;
}
