export const GATEWAY_MEDIA_KEY = '/gateway/media/media';

export enum EMediaUsageEventBodyBaseObjectType {
  FITNESS_MOVEMENT = 'Fitness Movement',
  FITNESS_WORKOUT = 'Fitness Workout', // Previously Series
  FITNESS_CHALLENGE = 'Fitness Challenge',
  FITNESS_BADGE = 'Fitness Badge',
  FITNESS_TEAM = 'Fitness Team',
}

export interface IMediaUsageEventBodyBase {
  media_id: string;
  object_type: string;
  object_id: EMediaUsageEventBodyBaseObjectType | string;
  data: unknown;
}

export type IMediaUsageEventBody = ['created' | 'updated' | 'deleted', IMediaUsageEventBodyBase];
