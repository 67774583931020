import { APNSChannelRequest, UpdateApnsChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointAPNSChannelParamsUpdate implements UpdateApnsChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.APNS;
  APNSChannelRequest: IAPNSChannelRequest;
}

class IAPNSChannelRequest implements APNSChannelRequest {
  BundleId?: string | undefined;
  Certificate?: string | undefined;
  DefaultAuthenticationMethod?: string | undefined;
  Enabled?: boolean | undefined;
  PrivateKey?: string | undefined;
  TeamId?: string | undefined;
  TokenKey?: string | undefined;
  TokenKeyId?: string | undefined;
}
