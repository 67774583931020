import { ICircleEvent } from '@launchpoint/core-types';
import { ILaunchpointResourceCommunityItem, ILaunchpointResourceTrainingCard, ILaunchpointResourceTrainingInsight } from '../interfaces/service';

export const EVENTS_DATA: ICircleEvent[] = [];

export const COMMUNITY_DATA: ILaunchpointResourceCommunityItem[] = [
  {
    title: 'Collaborate with Peers',
    description: 'Find solutions and new perspectives by engaging with others who are also looking to make an impact.',
  },
  {
    title: 'Ask & Answer Questions',
    description: 'Got a question? Chances are someone here has faced it—and solved it.',
  },
  {
    title: 'Stay Inspired',
    description: 'Share successes, learn from setbacks, and keep motivated by seeing others push forward.',
  },
];

export const TRAINING_INFO_DATA: ILaunchpointResourceCommunityItem[] = [
  {
    title: 'For New Entrepreneurs Ready to Build',
    description:
      'Lay a solid foundation by mastering the essentials quickly. These classes are designed to give newcomers the clarity and confidence to launch with purpose.',
  },
  {
    title: 'For Growing Businesses Seeking an Edge',
    description:
      'Gain access to proven strategies and hands-on exercises that help you tackle real-world challenges and stay competitive as your business scales.',
  },
  {
    title: 'For Experienced Leaders Hungry for Growth',
    description:
      'Join a network of high-achievers who push boundaries, share insights, and fuel each other’s growth in an exclusive community setting.',
  },
];

export const ESSENTIALS_TRAINING_CARDS: ILaunchpointResourceTrainingCard[] = [
  {
    title: 'S: Share',
    price: 0,
    reviews: 20,
    rating: 5,
  },
  {
    title: 'C: Close',
    price: 0,
    reviews: 20,
    rating: 4.8,
  },
  {
    title: 'A: Arrange',
    price: 0,
    reviews: 102,
    rating: 4.1,
  },
];
export const TRAINING_CARDS: ILaunchpointResourceTrainingCard[] = [
  {
    title: 'Reduce Lag in the Customer...',
    price: 0,
    reviews: 20,
    rating: 5,
  },
  {
    title: 'Technology Integration 101',
    price: 0,
    reviews: 20,
    rating: 4.8,
  },
  {
    title: 'Introduction to S.C.A.L.E.',
    price: 0,
    reviews: 102,
    rating: 4.1,
  },
];

export const TRAINING_INSIGHTS: ILaunchpointResourceTrainingInsight[] = [
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
  {
    name: 'Jason T.',
    job_title: 'Car Detailer',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.',
    image: 'assets/images/resources/face.png',
  },
];
