import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-explore-main-drawer',
  templateUrl: './explore-main-drawer.component.html',
})
export class ExploreMainDrawerComponent {
  // appThemeName: string = environment.appThemeName;
  // appPurchaseUrl: string = environment.appPurchaseUrl;
  // appPreviewUrl: string = environment.appPreviewUrl;
  // appDemos = environment.appDemos;
  // constructor() {}
  // ngOnInit(): void {}
}
