import { Directive, EventEmitter, Input, OnInit, Output, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMedia, IMediaSearchQuery } from '@launchpoint/core-types';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs';
import { LaunchpointMediaBaseHTTPService } from '../services/media-base.service';
import { LaunchpointCoreClientMediaBaseComponent } from './media-base.component';
import { LaunchpointFormControlSearchAheadBaseComponent } from '../../../../forms/from-control/form-control-search-ahead-base.component';
import { ISearchAheadComponent } from '../../../../components/search-ahead/search-ahead-component.interface';

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreClientMediaBaseComponent),
      multi: true,
    },
  ],
})
export abstract class LaunchpointCoreClientMediaSearchAheadBaseComponent
  extends LaunchpointFormControlSearchAheadBaseComponent
  implements OnInit, ISearchAheadComponent
{
  _LaunchpointMediaBaseHTTPService = inject(LaunchpointMediaBaseHTTPService);
  /**
   * Starting Query of the search ahead excluding the string search.
   */
  @Input() query: IMediaSearchQuery;
  @Input() placeholder = 'Search Media....';
  /**
   * The value of the selected value
   */
  @Input() selected: string;

  @Output() selectedChange = new EventEmitter<string>();
  @Output() selectedData = new EventEmitter<IMedia>();

  ngOnInit(): void {
    this.runQuery('');
    this.getById(this.selected);
  }

  async getById(id: string) {
    if (!id) {
      return;
    }
    this.loading = true;
    this._LaunchpointMediaBaseHTTPService
      .getById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.placeholder = data.title;
        this.loading = false;
        return this.selectedData.emit(data);
      });
  }

  formatter = (result: IMedia): string => result.title;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly IMedia[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._LaunchpointMediaBaseHTTPService
      .search({
        pagination: { limit: 100, skip: 0, count: 0 },
        querySort: { created_at: -1 },
        query: { ...this.query, search },
      })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  /**
   * Search aheads use this instead of `adjustValue`
   * @param data
   * @returns
   */
  selectData(data: { item: IMedia }) {
    this.selected = data.item._id;
    this.onChanged(data.item._id);
    this.onTouched();
    return this.selectedData.emit(data.item);
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.selected = value;
      this.getById(this.selected);
    }
  }
}
