import { Directive } from '@angular/core';
import { ICoreUserDemographicsParamsUpdatePhone } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { loggedInUserUpdatePhone } from '../../../../auth/+state/actions/user/user.actions';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../../../../auth/+state/auth-base.component';

interface IProfilePhoneConfig {
  //TODO: add to config
  title: string;
  fields: {
    phone: boolean;
  };
}

@Directive()
export class LaunchpointCoreClientProfilePhoneSmartUserBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent {
  async dispatchSave(event: Omit<ICoreUserDemographicsParamsUpdatePhone, 'user_id'>) {
    const user = await firstValueFrom(this.user$);
    this._Store.dispatch(loggedInUserUpdatePhone({ data: { ...event, user_id: user._id } }));
  }
}
