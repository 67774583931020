<div ngbDropdown class="d-inline-block">
  <ng-container *ngIf="hasActions()">
    <!--begin::Menu toggle-->
    <button class="btn btn-sm btn-light-primary" id="dropdownActionMenu" ngbDropdownToggle>{{ title }}</button>
    <!--end::Menu toggle-->
    <div ngbDropdownMenu aria-labelledby="dropdownActionMenu" class="p-0">
      <!--begin::Menu 1-->
      <div
        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px show">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <div class="menu-content fs-6 text-muted fw-bold px-3 py-4">Quick Actions</div>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator mb-3 opacity-75"></div>
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <ng-container *ngFor="let action of filterActions()">
          <div class="menu-item px-3 text-dark" *ngIf="checkConditions(action, item)">
            <ng-container *lpSecurityAccess="[action.action, schemaName]">
              <a (click)="selected.emit(action)" class="menu-link px-3"> {{ action.title }} </a>
            </ng-container>
          </div>
        </ng-container>

        <!--end::Menu item-->

        <!--begin::Menu separator-->
        <div class="separator mt-3 opacity-75"></div>
        <!--end::Menu separator-->

        <!--begin::Menu item-->
        <!-- <div class="menu-item px-3">
        <div class="menu-content px-3 py-3">
          <a class="btn btn-primary btn-sm px-4" href="#"> Generate Reports </a>
        </div>
      </div> -->
        <!--end::Menu item-->
      </div>

      <!--end::Menu 1-->
    </div>
  </ng-container>

  <ng-container *ngIf="!hasActions()">
    <button class="btn btn-sm btn-light-primary" [disabled]="true">{{ title }}</button>
  </ng-container>
</div>