import { Component, inject, Input, OnInit } from '@angular/core';
import {
  ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN,
  LaunchpointCoreClientActivityHistoryBaseComponent,
  updateAllActivityHistoryQuery,
} from '@launchpoint/core-client';
import { CORE_ACTIVITY_HISTORY_EVENT_TYPES, ICoreActivityHistorySearchQuery, ICoreCompany, ICoreUser } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-activity-history-filter',
  templateUrl: './activity-history-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebActivityHistoryTableFilterComponent extends LaunchpointCoreClientActivityHistoryBaseComponent implements OnInit {
  _config = inject(ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN);
  tagifyService = inject(TagifyService);
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  active = 1;
  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 100,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  data: { key: string; value: string }[] = [];

  whitelistEvents$ = new BehaviorSubject<string[]>(null);
  whitelistMethods$ = new BehaviorSubject<string[]>(['Post', 'Get', 'Patch', 'Put', 'Delete', 'Get']);

  clearUsers() {
    this.users = [];
    this.tagifyService.get('users')?.removeAllTags();
  }

  getTypesLength() {
    return this.form?.controls?.event_name?.value?.length;
  }
  clearTypes() {
    this.form?.controls?.event_name?.setValue([]);
  }
  getMethodsLength() {
    return this.form?.controls?.method?.value?.length;
  }
  clearMethods() {
    this.form?.controls?.method?.setValue([]);
  }

  ngOnInit() {
    super.ngOnInit();

    this.whitelistEvents$ = new BehaviorSubject<string[]>(Object.values(this._config?.events ?? CORE_ACTIVITY_HISTORY_EVENT_TYPES));
    this.settings.enforceWhitelist = true;
  }

  setUser(event: ICoreUser[]) {
    this.users = event;
  }

  setAccount(event: ICoreCompany[]) {
    this.accounts = event;
  }

  addData() {
    this.data.push({ key: '', value: '' });
  }

  removeData(index: number) {
    this.data.splice(index, 1);
  }

  apply() {
    const form = this.form.value;

    const query: ICoreActivityHistorySearchQuery = {
      ...form,
      method: form.method?.map((f) => f?.value) ?? [],
      event_name: form.event_name?.map((f) => f?.value) ?? [],
      user_ids: this.admin ? this.users.map((u) => u?._id) : this.query?.user_ids,
      account_ids: this.admin ? this.accounts.map((a) => a?._id) : this.query?.account_ids,
      // ...this.query,
    };

    if (this.data?.length) {
      query.response = {};
      this.data.forEach((d) => {
        query.response[d.key] = d.value;
      });
    }
    this._Store.dispatch(updateAllActivityHistoryQuery({ query }));
  }

  clearAll() {
    this.form.reset();

    if (this.admin) {
      this.tagifyService.get('users')?.removeAllTags();
      // this.tagifyService.get('accounts')?.removeAllTags();
    }
    this.tagifyService.get('event_name')?.removeAllTags();
    this.tagifyService.get('method')?.removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();
    this.data = [];

    this._Store.dispatch(updateAllActivityHistoryQuery({ query: this.query }));
  }
}
