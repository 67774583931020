import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { checkConditions, filterActions as filter, hasActions as getActions } from '../../utils/check-conditions';
import { ILaunchpointActions } from '@launchpoint/core-types';

@Directive()
export abstract class ActionMenuClientComponent {
  @Input() title = 'Actions';
  @Input() actions: ILaunchpointActions[];
  /**
   * The item to check the conditions against
   */
  @Input() item;
  @Input() admin = false;
  @Input() debug = false;
  @Input() schemaName: string;

  @Output() selected = new EventEmitter<typeof this.item>();

  public checkConditions = checkConditions;

  filterActions() {
    return filter(this.actions, this.admin, this.debug);
  }

  hasActions() {
    const filter = this.filterActions();

    return getActions(this.item, filter, this.admin, this.debug);
  }
}
