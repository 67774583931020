import { LaunchpointLayoutMenu } from '../../../../interfaces/menu.interfaces';

export const DefaultDynamicAsideMenuConfig: LaunchpointLayoutMenu = {
  logo_dark: './assets/media/logos/launchpoint-logo-full-darkmode.svg',
  logo_light: './assets/media/logos/launchpoint-logo-full.svg',
  items: [
    {
      title: 'Dashboard',
      root: true,
      bullet: 'dot',
      svg: './assets/media/icons/duotune/art/art002.svg',
      page: 'dashboard',
      // membership_status: ['all-active'],
      // security: ['view', 'beta'],
    },
  ],
};
