import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreUserV1Service } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebUsersTagifyBaseComponent } from './users-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [CoreUserV1Service],
  selector: 'launchpoint-users-tagify',
  templateUrl: 'users-tagify.component.html',
})
export class LaunchpointCoreWebUsersTagifyComponent extends LaunchpointCoreWebUsersTagifyBaseComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
