import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { CompanyBaseComponent } from './company.base-component';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

@Directive()
export abstract class CompanyConnectionCodesBaseComponent extends CompanyBaseComponent {
  // states = STATES_ARRAY;
  // @Input() company: ICoreCompany | ICoreCompanyAccount;
  // isLoading = false;
  // form: FormGroup<{
  //   connections: FormGroup<{
  //     connection_code: FormArray<
  //       FormGroup<{
  //         format: FormControl<ECompanyConnectionCode>;
  //         value: FormControl<string | number>;
  //         length: FormControl<number>;
  //       }>
  //     >;
  //     last_code: FormControl<string>;
  //   }>;
  // }>;
  // CompanyConnectionCodeValues = Object.values(ECompanyConnectionCode);
  // code_sample = '';
  // @Output() update = new EventEmitter<ICoreCompanyParamsUpdate>();
  // constructor(public _Store: Store, public _Router: Router, public _route: ActivatedRoute, public fb: UntypedFormBuilder) {
  //   super(_Store, _Router, _route, fb);
  // }
  // ngOnInit(): void {
  //   this.initForm();
  // }
  // get connections() {
  //   return this.form.controls.connections;
  // }
  // get connection_codes() {
  //   return this.form.controls.connections.controls.connection_code;
  // }
  // initForm() {
  //   this.form = this.fb.group({
  //     connections: this.fb.group({
  //       connection_code: this.fb.array(
  //         this.company.connections.connection_code.map((f) =>
  //           this.fb.group({
  //             format: f.format,
  //             value: f.value,
  //             length: f.length,
  //           })
  //         )
  //       ),
  //       last_code: this.company?.connections?.last_code || [''],
  //     }),
  //   });
  //   this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
  //     next: (values) => {
  //       try {
  //         const custom_format = values.connections.connection_code.map((v) => ({
  //           format: v?.format,
  //           value: v?.value,
  //           length: v?.length,
  //         }));
  //         this.code_sample = generateCode(custom_format);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     },
  //   });
  // }
  // addItem() {
  //   const value: string | number | null = null;
  //   const newForm = this.fb.group({
  //     format: [ECompanyConnectionCode.STRING, Validators.required],
  //     value,
  //     length: [0],
  //   });
  //   this.connection_codes.push(newForm);
  // }
  // removeItem(index: number) {
  //   this.connection_codes.removeAt(index);
  // }
  // submit() {
  //   if (this.form.invalid) {
  //     console.log('invalid', this.form.value);
  //     return;
  //   }
  //   this.isLoading = true;
  //   const obj: ICoreCompanyParamsUpdate = {
  //     account_id: this.company._id,
  //     connections: {
  //       connection_code: this.connections.value.connection_code.map((f) => ({
  //         format: f.format,
  //         value: f.value,
  //         length: f.length,
  //       })),
  //       last_code: this.connections.value.last_code,
  //     },
  //   };
  //   return this.update.emit(obj);
  // }
}
