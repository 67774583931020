import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserSelectors } from '../+state';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../interfaces/auth-storage-service.interface';

@Injectable({ providedIn: 'root' })
export class LaunchpointCoreClientAdminAuthGuard implements CanActivate {
  rerouted = false;
  debug = false;

  private _Store = inject(Store);
  private router = inject(Router);
  public _config: ICoreAuthConfig = inject(APP_AUTH_CONFIG_TOKEN);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._Store.select(UserSelectors.selectUserInitCheckComplete).pipe(
      filter((value) => !!value),
      mergeMap(() => this._Store.select(UserSelectors.selectUser)),
      // skipUntil(this._Store.select(UserSelectors.selectUserInitCheckComplete)),
      map((user) => {
        if (user) {
          if (this._config?.registration?.require_password && !user?.password) {
            this.router.navigate(['auth', 'set-password']);
            return false;
          }
          if (user.reset_password_force) {
            this.router.navigate(['auth', 'set-password']);
            return false;
          } else {
            if (user.security_roles.includes('super_admin')) {
              return true;
            } else {
              this.router.navigate(['error']);
              return false;
            }
          }
        } else {
          // not logged in so redirect to login page with the return url
          this.router.navigate(['auth', 'logout']);
          return false;
        }
      })
    );
  }
}
