// // import * as fromRouter from '@ngrx/router-store';
// // import * as fromUser from './user.reducer';
// // import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
// // import { RouterReducerState } from '@ngrx/router-store';
// // import { StateUserReducer } from '@launchpoint/core-client';

// // import { UsersReducerState } from "./users.reducer";

// // export interface RootModuleState {
// //   routerReducer: RouterReducerState;
// //   userReducer: StateUserReducer;
// // }

// // export const USER_REDUCERS: ActionReducerMap<RootModuleState> = {
// //   routerReducer: fromRouter.routerReducer,
// //   userReducer: fromUser.userReducer,
// // };

// // export * from './user.reducer';

// // import { CompanyUsersEffects } from './company-users-account.effects';
// import { UsersReducer } from './users.reducer';

// export const USERS_REDUCERS = [UsersReducer];
// //

export * from './users.reducer';
