import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CoreAlertBannerHTTPService, LaunchpointCoreClientAlertBannerStandaloneBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-alert-banner-message-standalone',
  templateUrl: 'alert-banner-message-standalone.component.html',
  imports: [CommonModule],
  providers: [CoreAlertBannerHTTPService],
  standalone: true,
})
export class LaunchpointCoreWebAlertBannerMessageStandaloneComponent extends LaunchpointCoreClientAlertBannerStandaloneBaseComponent {}
