import { Directive } from '@angular/core';
import { IHttpException, IQueryPageination, IQuerySort, IUserViewQueryParams } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { ViewActions } from './actions';
import { ViewEntityState } from './interfaces/views.interface';
import {
  selectSelectedView,
  selectViewByTableId,
  selectViewError,
  selectViewLoading,
  selectViewPagination,
  selectViewQuery,
  selectViewQuerySort,
  selectViewSuccess,
  selectViews,
} from './selectors/views.selectors';

@Directive()
export abstract class LaunchpointViewStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<IUserViewQueryParams>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  selected_view$: Observable<ViewEntityState>;
  viewEntities$: Observable<ViewEntityState[]>;

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(selectViewLoading);
    this.pagination$ = this._Store.select(selectViewPagination);
    this.querySort$ = this._Store.select(selectViewQuerySort);
    this.query$ = this._Store.select(selectViewQuery);
    this.error$ = this._Store.select(selectViewError);
    this.success$ = this._Store.select(selectViewSuccess);
    this.selected_view$ = this._Store.select(selectSelectedView);
    this.viewEntities$ = this._Store.select(selectViews);
  }

  createView(query: Record<string, any>, table_id: string, title: string) {
    this._Store.dispatch(
      ViewActions.createView({
        view: {
          query,
          table_id,
          title,
        },
      })
    );
  }

  dispactchGetView(view_id: string) {
    this._Store.dispatch(ViewActions.getViewById({ view_id }));
  }

  selectTableView(table_id: string) {
    return this._Store.select(selectViewByTableId(table_id));
  }

  deleteview(view_id: string) {
    this._Store.dispatch(ViewActions.deleteView({ view_id }));
  }
}
