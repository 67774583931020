import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BLOG_SERVICES } from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreClientBlogCategorySearchAheadBaseComponent } from './blog-category-search-ahead-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
  providers: BLOG_SERVICES,
  selector: 'launchpoint-blog-category-search-ahead',
  templateUrl: 'blog-category-search-ahead.component.html',
  styleUrls: ['blog-category-search-ahead.component.scss'],
})
export class LaunchpointCoreWebBlogCategorySearchAheadComponent extends LaunchpointCoreClientBlogCategorySearchAheadBaseComponent {
  @Input() cssClass = 'form-control';
}
