import { IMedia } from '../media.interface';

interface IUserMediaBase {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
  completed_at?: Date;
  media?: string | IMedia;
  session_id?: number;
  current_time?: number;
  user?: {
    _id: string;
    email: string;
    profile_photo_url: string;
    first_name: string;
    last_name: string;
    stripe_id: string;
  };
}

export interface IUserMedia extends IUserMediaBase {
  _id: string;
}

export interface IUserMediaCreateParams extends Omit<IUserMediaBase, '_id' | 'created_at' | 'updated_at' | 'user'> {
  media: string;
  session_id: number;
}

// export interface IUserMediaUpdateParams extends Omit<IUserMediaBase, 'created_at' | 'updated_at' | 'user'> {
//   _id: string;
//   media: string;
//   session_id: number;
// }
