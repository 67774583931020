import { Directive } from '@angular/core';
import {
  ICoreUser,
  ICoreUserAddresses,
  ICoreUserNotificationTokens,
  ICoreUserSearchQuery,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { UsersEntityState } from './interfaces/users.interface';
import * as UsersSelectors from './selectors/users.selectors';

@Directive()
export abstract class LaunchpointCoreClientUsersStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreUserSearchQuery>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  user_entities$: Observable<UsersEntityState[]>;

  selected_user$: Observable<UsersEntityState>;
  user$: Observable<ICoreUser & { customer_id: any }>;
  selected_user_loading$: Observable<boolean>;
  selected_user_error$: Observable<IHttpException | null>;
  selected_user_success$: Observable<string | null>;
  queryFilterCount$: Observable<number>;
  userNotificationTokens$: Observable<ICoreUserNotificationTokens[]>;
  address$: Observable<ICoreUserAddresses>;
  attributes$: Observable<ICoreUser['attributes']>;

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(UsersSelectors.selectUserLoading);
    this.pagination$ = this._Store.select(UsersSelectors.selectUserPagination);
    this.querySort$ = this._Store.select(UsersSelectors.selectUserQuerySort);
    this.query$ = this._Store.select(UsersSelectors.selectUserQuery);
    this.error$ = this._Store.select(UsersSelectors.selectUserError);
    this.success$ = this._Store.select(UsersSelectors.selectUserSuccess);
    this.user_entities$ = this._Store.select(UsersSelectors.selectUsers);
    this.user$ = this._Store.select(UsersSelectors.selectSelectedUser);
    this.selected_user$ = this._Store.select(UsersSelectors.selectSelectedUserEntity);
    this.selected_user_loading$ = this._Store.select(UsersSelectors.selectSelectedUserLoading);
    this.selected_user_success$ = this._Store.select(UsersSelectors.selectSelectedUserSuccess);
    this.selected_user_error$ = this._Store.select(UsersSelectors.selectSelectedUserError);
    this.queryFilterCount$ = this._Store.select(UsersSelectors.selectUsersQueryCount);
    this.userNotificationTokens$ = this._Store.select(UsersSelectors.selectSelectedUserNotificationTokens);
    this.address$ = this._Store.select(UsersSelectors.selectSelectedUserAddress);
    this.attributes$ = this._Store.select(UsersSelectors.selectSelectedUserAttributes);
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ icon: 'success', title: success });
        }
      },
    });
    this.selected_user_success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ icon: 'success', title: success });
        }
      },
    });
  }
  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ icon: 'error', title: error });
        }
      },
    });
    this.selected_user_error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ icon: 'error', title: error });
        }
      },
    });
  }

  toast(params) {
    console.log(params);
  }
}
