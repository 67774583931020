import { GCMChannelRequest, UpdateGcmChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointGCMChannelParamsUpdate implements UpdateGcmChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.GCM;
  GCMChannelRequest: IGCMChannelRequest;
}

class IGCMChannelRequest implements GCMChannelRequest {
  Enabled?: boolean;
  ApiKey?: string | undefined;
  DefaultAuthenticationMethod?: string | undefined;
  ServiceJson?: string | undefined;
}
