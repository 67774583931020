import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientMediaBaseComponent, updateMedia } from '@launchpoint/core-client';
import { IMedia } from '@launchpoint/core-types';
import { IEllipsisMenuOption } from '../../../../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-media-table',
  templateUrl: './media-table.component.html',
  styles: [],
})
export class LaunchpointCoreWebMediaTableComponent extends LaunchpointCoreClientMediaBaseComponent implements OnInit {
  private _swal = inject(LaunchpointSweetAlertService);

  options(media: IMedia, event?: Event): IEllipsisMenuOption[] {
    event?.stopPropagation();

    const options: IEllipsisMenuOption[] = [
      {
        label: 'Download',
        action: () => this.downloadURL(media),
      },
      {
        label: media?.is_archived ? 'Activate' : 'Archive',
        action: () =>
          this._Store.dispatch(
            updateMedia({
              data: {
                _id: media._id,
                is_archived: !media.is_archived,
              },
            })
          ),
      },
      {
        label: 'Delete',
        disabled: media?.usage?.length > 0,
        action: () => this.deleteById(media._id),
      },
    ];

    return options;
  }

  deleteById(media_id: string) {
    this._swal
      .fireAlert({
        text: 'Are you sure you want to delete this media?',
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteMedia({ _id: media_id });
        }
      });
  }
}
