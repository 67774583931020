import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-feeds-widget6',
  templateUrl: './feeds-widget6.component.html',
})
export class FeedsWidget6Component {
  // constructor() {}
  // ngOnInit(): void {}
}
