<div>
  <sd-hero></sd-hero>
  <sd-story></sd-story>
  <sd-cta-banner [text]="cta2().title" [href]="cta2()?.href" [route]="cta2()?.route"></sd-cta-banner>
  <sd-metrics></sd-metrics>
  <sd-purpose></sd-purpose>
  <sd-for-businesses></sd-for-businesses>
  <sd-cta-banner [text]="cta2().title" [href]="cta2()?.href" [route]="cta2()?.route"></sd-cta-banner>
  <sd-resources></sd-resources>
  <sd-events></sd-events>
  <sd-success-stories type="dark" subtitle="WILL YOU BE ONE OF THEM?" [showScroll]="true"></sd-success-stories>
  <sd-cta-banner [text]="cta2().title" [href]="cta2()?.href" [route]="cta2()?.route"></sd-cta-banner>
  <sd-quote></sd-quote>
  <sd-tools-tips></sd-tools-tips>
  <sd-contact></sd-contact>
</div>
