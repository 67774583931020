import { Component } from '@angular/core';
import { LaunchpointCoreClientBlogsBaseComponent } from '@launchpoint/core-client';
import { ICoreBlog } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-blogs-table',
  templateUrl: 'blogs-table.component.html',
})
export class LaunchpointCoreWebBlogsTableComponent extends LaunchpointCoreClientBlogsBaseComponent {
  getRelated(posts: ICoreBlog[]) {
    return posts?.map((post) => post?.title)?.join(', ');
  }

  getReleaseText(post: ICoreBlog) {
    if (!post?.release_at) {
      return 'No Date';
    }
    if (post?.release_at < new Date()) {
      return 'Released';
    }
    return 'Scheduled';
  }
}
