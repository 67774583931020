import { ILaunchpointSearchFilterDates } from '../dates.interface';
import { IMedia } from '../media/media.interface';
import { IQueryPageination, IQuerySort, ITagifyTag } from '../query.interfaces';
import { RequireAtLeastOne } from '../require-one';
import { ISEOData } from '../seo/seo.interface';
import { ICoreUserProfile, ICoreUser } from '../user-company-account.interface';
import { ICoreBlogComment, ICoreBlogCommentBase } from './comments/blog-comments.interface';

// export enum ECoreBlogStatus {
//   DRAFT = 'draft',
//   PUBLISHED = 'published',
// }

export interface ICoreBlogBase {
  _id?: string;

  active: boolean;
  // status: ECoreBlogStatus;
  // published_date: Date;
  title: string;
  description: string;
  /**
   * This url will be used for the search functionality.
   * When title created and lowercase, trim, and replace(' ','-') and store that as the url
   */
  title_url: string;
  featured: boolean;

  release_at: Date;

  /**
   * this is the text of the blog
   */
  content: string;

  author_user: string | ICoreBlogAuthor;

  featured_image: string | IMedia;
  reading_time_min?: number; // Estimated reading time in min

  seo?: Partial<ICoreBlogSEO>;

  likes?: number;
  views?: number;

  category: string;
  tags?: string[];

  comments?: (ICoreBlogCommentBase | ICoreBlogComment)[];
  /**
   * if users can comment on this blog
   * @default true
   */
  comments_enabled?: boolean;
  /**
   * if comments are archived, no new comments can be added but existing comments can still be viewed
   * @default false
   */
  comments_archived?: boolean;
  /**
   * if users can reply to this comment
   * @default true
   */
  replies_enabled: boolean;

  related_posts?: string[] | ICoreBlog[];
  created_at?: Date;
  updated_at?: Date;
}

export interface ICoreBlog extends ICoreBlogBase {
  author_user: ICoreBlogAuthor;
  related_posts: ICoreBlog[];
  featured_image: IMedia;
  comments: ICoreBlogComment[];
}
export interface ICoreBlogParamsGetOne {
  title_url: string;
}

export type ICoreBlogTagify = ITagifyTag<ICoreBlog>;

export interface ICoreBlogSEO extends Omit<ISEOData, 'route'> {
  title: string;
  description: string;
  keywords: string;
  canonical_url: string;
  og: {
    title: string;
    description: string;
    image: string;
    url: string;
    type: string;
    site_name: string;
    locale: string;
  };
  twitter: {
    card: string;
    site: string;
    title: string;
    description: string;
    image: string;
  };
  author: string;
  theme_color: string;
  ms_tile_color: string;
  language: string;
}

export type ICoreBlogAuthorProfile = Pick<ICoreUserProfile, 'first_name' | 'last_name' | 'full_name' | 'profile_photo_url'>;

export class ICoreBlogAuthor implements Pick<ICoreUser, '_id' | 'email'> {
  _id: string;
  email: string;
  profile: ICoreBlogAuthorProfile;
}

export class ICoreBlogParamsCreate {
  title: string;
  category: string;
  featured?: boolean;
  active?: boolean;
  content?: string;
  description?: string;
  featured_image?: string; // URL to a featured image
  release_at?: Date;
}

export type ICoreBlogParamsUpdate = {
  _id: string;
} & RequireAtLeastOne<Omit<ICoreBlogBase, 'seo' | 'tags' | 'comments' | 'related_posts'>>;

export interface ICoreBlogSearchQuery {
  search?: string;
  tags?: string[];
  category?: string[];
  exclude_ids?: string[];
  active?: boolean;
  featured?: boolean;
  release_at?: ILaunchpointSearchFilterDates;
  created_at?: ILaunchpointSearchFilterDates;
  /**
   * if the blog is active and the release_at date is in the past
   */
  live?: boolean;
}

export interface ICoreBlogSearchPayload {
  query: ICoreBlogSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export interface ICoreBlogSearchResults {
  pagination: IQueryPageination;
  data: ICoreBlog[];
}
