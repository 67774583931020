<form [formGroup]="form">
  <div class="px-7 py-5 d-flex align-items-center justify-content-between">
    <div class="fs-5 text-dark fw-bold">Filter Options</div>
    <!-- <button class="btn btn-sm btn-icon btn-clear btn-active-light-primary">
      <i class="fs-4 bi bi-arrows-angle-expand"></i>
    </button> -->
  </div>

  <div class="separator border-gray-200"></div>

  <div class="px-7 mt-5">

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Account Owner</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="users?.length > 0" animateCss="tada"
            ngbTooltip="Clear all account owners." (click)="clearUsers()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-users-tagify (selectedUser)="setUser($event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Account Types</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="getTypesLength() > 0" animateCss="tada"
            ngbTooltip="Clear all account types." (click)="clearTypes()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="account_type" name="account_type" class="me-5" [settings]="settings"
          inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
      </div>
    </div>


    <div class="menu-item px-3">
      <label class="form-label fw-semibold">Created At</label>
      <launchpoint-date-range formControlName="created_at" [allOptions]="{ debug: false }"></launchpoint-date-range>
    </div>
  </div>

  <div class="rounded-bottom pb-3">
    <div class="d-flex justify-content-between align-items-center m-5 px-4">
      <div>
        <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="clearAll()">
          Clear All
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <!-- <launchpoint-user-view-save [view]="view" [query]="query$ | async"
          [table_id]="'User'"></launchpoint-user-view-save> -->

        <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="apply()">Apply</button>
      </div>
    </div>
  </div>
</form>