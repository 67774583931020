import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare global {
  interface Window {
    MC_PIXEL: any; // You can specify the exact type if you know it
  }
}

@Injectable({ providedIn: 'root' })
export class ManychatPixelService {
  private isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    this.isBrowser = isPlatformBrowser(this.platformId); // Check if it's a browser
  }

  /**
   * Tracks a conversion event in Manychat.
   * @param eventName Event name, as defined in your Manychat Pixel.
   */
  public trackConversionEvent(eventName: string) {
    if (!this.isBrowser) {
      return; // Exit early if not in the browser (SSR environment)
    }

    try {
      if (window.MC_PIXEL && typeof window.MC_PIXEL.fireLogConversionEvent === 'function') {
        window.MC_PIXEL.fireLogConversionEvent(eventName);
      } else {
        console.error('Manychat Pixel not available or fireLogConversionEvent is not a function');
      }
    } catch (error) {
      console.error('Manychat Pixel conversion tracking error:', error);
    }
  }

  /**
   * Tracks a money event in Manychat.
   * @param eventName Event name, as defined in your Manychat Pixel.
   * @param eventValue Event value (monetary amount).
   * @param currency The currency code (e.g., 'USD', 'EUR'). Defaults to 'USD' if not provided.
   */
  public trackMoneyEvent(eventName: string, eventValue: number, currency = 'USD') {
    if (!this.isBrowser) {
      return; // Exit early if not in the browser (SSR environment)
    }

    try {
      if (window.MC_PIXEL && typeof window.MC_PIXEL.fireLogMoneyEvent === 'function') {
        window.MC_PIXEL.fireLogMoneyEvent(eventName, eventValue, currency);
      } else {
        console.error('Manychat Pixel not available or fireLogMoneyEvent is not a function');
      }
    } catch (error) {
      console.error('Manychat Pixel money tracking error:', error);
    }
  }
}
