@if (IDMeError) {
<div class="alert alert-danger margin-bottom-1x" role="alert">
  {{ IDMeError?.error?.message ?? IDMeError?.error }}
</div>
}

@if (!user || !user?.profile?.id_me_uuid) {
<div class="d-flex align-items-center">
  @for (scope of scopes; track scope) {
  <div class="d-flex align-items-center flex-wrap">
    <span class="cursor-pointer fs-6 fw-semibold text-primary me-5" (click)="clickedVerify(scope)">
      {{ scope | titlecase }} verify with <strong>ID.me</strong>
      <i class="bi bi-chevron-right ms-2 text-primary"></i>
    </span>
  </div>
  }
</div>
} @else {
<span class="fs-6 fw-semibold">
  Verified with <strong>ID.me</strong>
  <i class="bi bi-check-circle ms-2 text-success"></i>
</span>
}

<!-- todo !! -->
<!-- <div class="col-sm-12 margin-top-1x margin-bottom-1x px-0">
  ID.me verifies employment.
  <span *ngIf="!user || !user.profile.id_me_uuid" class="display-contents">
    International Service Member? Please email
    <a class="display-contents" href="mailto:support@streetparking.com"
      title="support@streetparking.com">support&#64;streetparking.com</a>
    with your proof of service to get set up.
  </span>
</div> -->