import { IsOptional, IsString, IsNumber } from 'class-validator';
import { ICoreUserAddresses } from '../../../user-company-account.interface';
import { ELaunchpointGeo } from '../../../utils/geo-location.constants';

export class ICoreUserAddressParamsUpdate
  implements
    Pick<
      Partial<ICoreUserAddresses>,
      'title' | 'address_long' | 'city' | 'country' | 'line_one' | 'line_two' | 'state' | 'zip_code' | 'long' | 'lat' | 'location'
    >
{
  @IsString({ message: 'User ID must be a string' })
  user_id: string;

  @IsString({ message: 'Address ID must be a string' })
  address_id: string;

  @IsString({ message: 'Title must be a string' })
  title: string;

  @IsString({ message: 'Address line 1 must be a string' })
  line_one: string;

  @IsString({ message: 'Address line 2 must be a string' })
  @IsOptional()
  line_two: string;

  @IsString({ message: 'City must be a string' })
  city: string;

  @IsString({ message: 'State must be a string' })
  // @Length(2, 2, { message: 'State must be a two-letter string' })
  state: string;

  @IsString({ message: 'Country must be a string' })
  // @IsOptional()
  country: string;

  @IsString({ message: 'Zip code must be a string' })
  zip_code: string;

  @IsString({ message: 'Long address must be a string' })
  @IsOptional()
  address_long: string;

  @IsNumber()
  @IsOptional()
  long?: number;

  @IsNumber()
  @IsOptional()
  lat?: number;

  location?: {
    type: ELaunchpointGeo.POINT;
    /**
     * list the longitude first, and then latitude.
     */
    coordinates: [number, number] | [];
  };

  constructor(data: ICoreUserAddressParamsUpdate) {
    this.user_id = data.user_id;
    this.address_id = data.address_id;
    this.title = data.title;
    this.line_one = data.line_one;
    this.line_two = data.line_two;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.zip_code = data.zip_code;
    this.address_long = data.address_long;
    this.long = data.long;
    this.lat = data.lat;
    this.location = data.location;
  }
}
export class ICoreUserAddressParamsCreate
  implements
    Pick<
      Partial<ICoreUserAddresses>,
      'title' | 'address_long' | 'city' | 'country' | 'line_one' | 'line_two' | 'state' | 'zip_code' | 'long' | 'lat' | 'location'
    >
{
  @IsString({ message: 'User ID must be a string' })
  user_id: string;

  @IsString({ message: 'Title must be a string' })
  @IsOptional()
  title?: string;

  @IsString({ message: 'Address line 1 must be a string' })
  line_one: string;

  @IsString({ message: 'Address line 2 must be a string' })
  @IsOptional()
  line_two?: string;

  @IsString({ message: 'City must be a string' })
  city: string;

  @IsString({ message: 'State must be a string' })
  // @Length(2, 2, { message: 'State must be a two-letter string' })
  state: string;

  @IsString({ message: 'Country must be a string' })
  @IsOptional()
  country?: string;

  @IsString({ message: 'Zip code must be a string' })
  zip_code: string;

  @IsString({ message: 'Long address must be a string' })
  @IsOptional()
  address_long?: string;

  @IsNumber()
  @IsOptional()
  long?: number;

  @IsNumber()
  @IsOptional()
  lat?: number;

  location?: {
    type: ELaunchpointGeo.POINT;
    coordinates: [number, number] | [];
  };

  constructor(data: ICoreUserAddressParamsCreate) {
    this.user_id = data.user_id;
    this.title = data.title;
    this.line_one = data.line_one;
    this.line_two = data.line_two;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.zip_code = data.zip_code;
    this.address_long = data.address_long;
    this.long = data.long;
    this.lat = data.lat;
    this.location = data.location;
  }
}
