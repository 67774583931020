import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AppBaseComponent } from '../../../../../../app-base.component';
import { LaunchpointLayoutMenu } from '../../../../../../interfaces/menu.interfaces';

@Component({
  selector: 'launchpoint-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent extends AppBaseComponent {
  @Input() menuConfig: LaunchpointLayoutMenu;
  constructor(private router: Router) {
    super();
  }

  // ngOnInit(): void {}

  calculateMenuItemCssClass(url: string): string {
    return checkIsActive(this.router.url, url) ? 'active' : '';
  }
}

const getCurrentUrl = (pathname: string): string => {
  return pathname.split(/[?#]/)[0];
};

const checkIsActive = (pathname: string, url: string) => {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};
