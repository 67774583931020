import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../auth/auth.user.interface';
import { IMedia, IMediaUpdateParamsAddSubtitles, IMediaUpdateParamsRemoveSubtitles } from '../media.interface';

/**
 * @Controller('media-subtitles')
 */
export interface IMediaSubtitlesController {
  /**
   * @Post()
   */
  add(body: IMediaUpdateParamsAddSubtitles, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Put()
   */
  remove(body: IMediaUpdateParamsRemoveSubtitles, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
}
