import { Injectable, inject } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as LaunchpointDynamicFormResponseActions from '../actions/actions';
import * as LaunchpointDynamicFormResponseSelectors from '../selectors/selectors';
import { LaunchpointDynamicFormResponseV1Service } from '../../services/responses.service';

@Injectable()
export class LaunchpointDynamicFormResponseEffects {
  private actions$ = inject(Actions);
  private _Store = inject(Store);
  private _LaunchpointDynamicFormResponseV1Service = inject(LaunchpointDynamicFormResponseV1Service);

  getAllLaunchpointDynamicFormResponse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponse,
        LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponsePagination,
        LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponseQuery,
        LaunchpointDynamicFormResponseActions.updateAllLaunchpointDynamicFormResponseSorting
      ),
      withLatestFrom(
        this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponsePagination),
        this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseQuerySort),
        this._Store.select(LaunchpointDynamicFormResponseSelectors.selectLaunchpointDynamicFormResponseQuery)
      ),
      switchMap(([action, pagination, querySort, query]) => {
        return this._LaunchpointDynamicFormResponseV1Service.search({ pagination, querySort, query }).pipe(
          map((results) => {
            return LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponseSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponseFailure(error)))
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormResponseActions.searchAllLaunchpointDynamicFormResponseFailure(error)))
    );
  });

  getLaunchpointDynamicFormResponseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseById),
      switchMap((action) => {
        return this._LaunchpointDynamicFormResponseV1Service.getById(action.data_id).pipe(
          map((data) => {
            return LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdFailure({ data_id: action.data_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdFailure(error)))
    );
  });

  createLaunchpointDynamicFormResponseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponse),
      switchMap((action) => {
        return this._LaunchpointDynamicFormResponseV1Service.create(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponseSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormResponseActions.createLaunchpointDynamicFormResponseFailure({ error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdFailure(error)))
    );
  });

  updateLaunchpointDynamicFormResponseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponse),
      switchMap((action) => {
        return this._LaunchpointDynamicFormResponseV1Service.update(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponseSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormResponseActions.updateLaunchpointDynamicFormResponseFailure({ data_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormResponseActions.getLaunchpointDynamicFormResponseByIdFailure(error)))
    );
  });

  deleteLaunchpointDynamicFormResponseById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponse),
      switchMap((action) => {
        return this._LaunchpointDynamicFormResponseV1Service.delete(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponseSuccess({
              data: data,
              success_message: 'Response Deleted',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponseFailure({ data_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormResponseActions.deleteLaunchpointDynamicFormResponseFailure(error)))
    );
  });
}
