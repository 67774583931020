import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LaunchpointDemoSharedLayoutModule } from '../../demo-shared-layout/layout.module';
import { LAUCNHPOINT_DEMO_4_LAYOUT_COMPONENTS } from './components';
import { TranslationModule } from '../../i18n/translation.module';
import { ExtrasModule } from '../../core/partials/layout/extras/extras.module';
import { EngagesModule } from '../../core/partials/layout/engages/engages.module';
import { DropdownMenusModule } from '../../core/partials/content/dropdown-menus/dropdown-menus.module';
import { ThemeModeModule } from '../../core/partials/layout/theme-mode-switcher/theme-mode.module';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslationModule,
  InlineSVGModule,
  NgbDropdownModule,
  NgbProgressbarModule,
  ExtrasModule,
  EngagesModule,
  DropdownMenusModule,
  NgbTooltipModule,
  TranslateModule,
  ThemeModeModule,
  LaunchpointDemoSharedLayoutModule,
];

@NgModule({
  declarations: [...LAUCNHPOINT_DEMO_4_LAYOUT_COMPONENTS],
  imports: [...MODULES],
  exports: [...LAUCNHPOINT_DEMO_4_LAYOUT_COMPONENTS, ...MODULES],
})
export class LaunchpointDemo4LayoutModule {}
