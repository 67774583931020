<input
  class="form-control"
  type="text"
  [ngClass]="cssClass"
  [placeholder]="placeholder ?? 'Select a category'"
  (focus)="onFocus($event)"
  [ngbTypeahead]="search"
  [resultFormatter]="formatter"
  [inputFormatter]="formatter"
  [editable]="false"
  (selectItem)="selectData($event)"
/>
