import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { RequireAtLeastOne } from '../../require-one';
import { ICoreCompany, ICoreUser } from '../../user-company-account.interface';
import { ICoreActivityHistoryEventTypes } from '../event-types.interface';

export interface ICoreActivityHistoryWebhookBase {
  account_id: string | ICoreCompany;
  // user_id: string | ICoreUser;
  secret: string;
  description?: string;
  events: string[];
  /**
   * @default false
   */
  enabled: boolean;
  mode: 'test' | 'live';
  url: string;
  //
  _id: string;
  created_at: Date;
  updated_at: Date;
}

export interface ICoreActivityHistoryWebhook extends ICoreActivityHistoryWebhookBase {
  account_id: ICoreCompany;
  // user_id: ICoreUser;
}

export type ICoreActivityHistoryWebhookParamsCreate<T = ICoreActivityHistoryEventTypes> = {
  description?: string;
  events: T[];
  url: string;
  enabled?: boolean;
  mode: 'test' | 'live';
  account_id: string;
};

export type ICoreActivityHistoryWebhookParamsUpdate = {
  _id: string;
} & RequireAtLeastOne<{ description?: string; events?: string[]; url?: string; enabled?: boolean }>;

export interface ICoreActivityHistoryWebhookSearchQuery {
  search?: string;
  events?: string[];
  enabled?: boolean;
  mode?: 'test' | 'live';
  url?: string[];
  created_at?: ILaunchpointSearchFilterDates;
  account_ids?: string[];
}

/**
 * SEARCH PAYLOAD
 *
 * This is the requried payload to run a search query and return values
 */
export interface ICoreActivityHistoryWebhookSearchPayload {
  query: ICoreActivityHistoryWebhookSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

/**
 * SEARCH RESULTS
 *
 *
 * This is the payload for the search query results.
 */
export interface ICoreActivityHistoryWebhookSearchResults {
  pagination: IQueryPageination;
  data: ICoreActivityHistoryWebhook[];
}
