import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IEllipsisMenuOption, LaunchpointCoreWebEllipsisMenuComponent } from '../ellipsis-menu/ellipsis-menu.component';

export class IToolbarConfig {
  title: string;
  /**
   * @default false
   */
  autoClose?: 'outside' | 'inside' | boolean;
  description?: string;
  /**
   * form control for the search input
   * if not passed, no search input will be shown
   */
  formControl?: FormControl;
  searchPlaceholder?: string;
  /**
   * @default true
   */
  create?: boolean;
  createTooltip?: string;
  /**
   * @default false
   */
  showPivot?: boolean;
  /**
   * @default false
   */
  pivot?: boolean;
  /**
   * @default false
   */
  refresh?: boolean;
  refreshTooltip?: string;
  /**
   * @default false
   */
  sync?: boolean;
  syncTooltip?: string;
  /**
   * submenu options for sync if you want to show a dropdown of choices
   * @default [30, 60, 90, 180] days
   */
  syncOptions?: IEllipsisMenuOption[];
  /**
   * @default true
   */
  filter?: boolean;
  /**
   * query count to show on the filter button
   */
  queryCount?: Observable<number>;
  /**
   * additional options to show in the ellipsis menu
   */
  options?: IEllipsisMenuOption[];
  /**
   * tooltip for the ellipsis menu
   */
  ellipsisTooltip?: string;
  /**
   * @default false
   */
  debug?: boolean;
}

@Component({
  selector: 'launchpoint-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule, NgbModule, ReactiveFormsModule, LaunchpointCoreWebEllipsisMenuComponent],
})
export class LaunchpointCoreWebToolbarComponent implements OnInit {
  syncOptions: IEllipsisMenuOption[] = [
    {
      label: 'Last 30 Days',
      action: () => this.onSync(30),
    },
    {
      label: 'Last 60 Days',
      action: () => this.onSync(60),
    },
    {
      label: 'Last 90 Days',
      action: () => this.onSync(90),
    },
    {
      label: 'Last 6 Months',
      action: () => this.onSync(180),
    },
    // {
    //   label: 'All Time',
    //   action: () => this.sync('all'),
    // },
  ];

  @Input() options: IToolbarConfig = {
    title: 'Title',
    autoClose: false,
    searchPlaceholder: 'Search',
    create: true,
    showPivot: false,
    refresh: false,
    sync: false,
    filter: true,
    syncOptions: this.syncOptions,
    ellipsisTooltip: 'More Options',
    debug: false,
  };

  ellipsisOptions$ = signal<IEllipsisMenuOption[]>([]);
  ellipsisOptions: IEllipsisMenuOption[] = [];

  @Output() search = new EventEmitter<string>();
  @Output() create = new EventEmitter<void>();
  @Output() refresh = new EventEmitter<void>();
  @Output() sync = new EventEmitter<number>();
  @Output() pivot = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.log('options', this.options);
    this.formatOptions();
  }

  onSearch(search: string) {
    this.search.emit(search);
  }

  onCreate() {
    this.create.emit();
  }

  onRefresh() {
    this.refresh.emit();
  }

  onSync(id?: number) {
    this.sync.emit(id);
  }

  switch(value: boolean) {
    this.pivot.emit(!value);
  }

  private log(...args: any[]) {
    if (this.options?.debug) {
      console.log(...args);
    }
  }

  private formatOptions() {
    if (this.options.refresh) {
      this.ellipsisOptions.push({
        label: 'Refresh',
        tooltip: this.options.refreshTooltip ?? 'Refresh Data',
        action: () => this.onRefresh(),
      });
    }

    if (this.options.sync && !this.options.syncOptions?.length) {
      this.ellipsisOptions.push({
        label: 'Sync',
        tooltip: this.options.syncTooltip ?? '',
        action: () => this.onSync(),
      });
    }
    if (this.options.sync && this.options.syncOptions?.length) {
      this.ellipsisOptions.push({
        label: 'Sync',
        tooltip: this.options.syncTooltip ?? '',
        subOptions: this.options?.syncOptions,
      });
    }
    if (this.options.options) {
      this.ellipsisOptions.push(...this.options.options);
    }

    this.log('ellipsisOptions', this.ellipsisOptions);
  }
}
