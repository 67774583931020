import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { selectSelectedUserId } from '../../user/auth/+state/selectors/user.selectors';

@Injectable()
export class LaunchpointSecurityUserIdHeaderService {
  constructor(public _Store: Store) {}

  get getSelectedUserIdHeaders(): Observable<HttpHeaders> {
    return this._Store.select(selectSelectedUserId).pipe(map((user_id) => this.createUserIdHeader(user_id)));
  }

  getSelectedUserIdHeadersOptions(options: { [key: string]: string }): Observable<HttpHeaders> {
    return this._Store.select(selectSelectedUserId).pipe(map((user_id) => this.createUserIdHeader(user_id, options)));
  }

  public createUserIdHeader(user_id: string, options?: { [key: string]: string }): HttpHeaders {
    // console.log('GETTING USER ID HEADER');
    if (!user_id) {
      return new HttpHeaders();
    }
    const headers = new HttpHeaders({ 'user-id': user_id });
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        headers.set(key, options[key]);
      }
    }
    return headers;
  }
}
