import { Directive, inject, OnInit, signal } from '@angular/core';
import { ICoreBlog, ICoreBlogComment } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { LaunchpointCoreClientBlogsStateBaseComponent } from '../+state/blogs-state-base.component';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Directive()
export abstract class LaunchpointCoreClientBlogBaseComponent extends LaunchpointCoreClientBlogsStateBaseComponent implements OnInit {
  fb = inject(FormBuilder);
  public datePipe = inject(DatePipe);

  blog: ICoreBlog;
  comments$ = signal<ICoreBlogComment[]>([]);

  ngOnInit(): void {
    this.buildForm();
    this.blog$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (blog) => {
        if (blog) {
          this.blog = blog;
          this.comments$.set(blog.comments);
          this.loadForm();
        }
      },
    });
    this.success();
    this.error();
  }

  buildForm() {
    //
  }

  loadForm() {
    //
  }
}
