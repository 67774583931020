<div class="h-150">
  <div class="file-drop d-flex text-center align-items-center" *ngIf="!imageFileChanged">
    <ngfFormData [files]="files" postName="file"></ngfFormData>
    <div
      class="well my-drop-zone d-flex justify-content-center cursor-pointer"
      ngfDrop
      [multiple]="multiple"
      selectable="1"
      [(validDrag)]="validComboDrag"
      [accept]="accept"
      [maxSize]="maxSize"
      [(lastInvalids)]="lastInvalids"
      [(dragFiles)]="dragFiles"
      [class.invalid-drag]="validComboDrag === false"
      [class.valid-drag]="validComboDrag"
      (filesChange)="fileChangeEvent($event)"
      [fileDropDisabled]="fileDropDisabled"
      [files]="filesAddedArray"
    >
      <div class="d-flex flex-column">
        <i class="bi bi-upload fs-3x mb-10"></i>
        <span class="align-self-center fs-6">{{ description }}</span>
      </div>
    </div>
  </div>
</div>
