import { Directive, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ICoreCompanyParamsCreate, ICoreCompanyParamsCreateResults } from '@launchpoint/core-types';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createCompany, createCompanySuccess } from '../+state/actions/company.actions';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';

@Directive()
export abstract class CompanyCreateBaseComponent extends CompanyStateBaseComponent implements OnInit {
  // states = STATES_ARRAY;

  @Input() show_account_owner = true;
  @Input() show_phone = true;
  @Input() show_company_name = true;
  @Input() show_email = true;
  @Input() show_account_type = true;

  @Input() account_owner = '';

  @Input() account_types: string[] = Object.values(['default']);
  actions$ = inject(Actions);
  form: FormGroup<{
    account_owner: FormControl<string>;
    phone: FormControl<string>;
    account_type: FormControl<string>;
    company_name: FormControl<string>;
    email: FormControl<string>;
    address: FormGroup<{
      street: FormControl<string>;
      street_2: FormControl<string>;
      city: FormControl<string>;
      state: FormControl<string>;
      code: FormControl<string>;
      country: FormControl<string>;
    }>;
    // licences: FormArray<
    //   FormGroup<{
    //     licence_type: FormControl<ECompanyLicenseType>;
    //     owner: FormControl<ECompanyLicenseOwner>;
    //     // eff_date: Date;
    //     // exp_date: Date;
    //     // user: {
    //     //   user_id: string | IUser;
    //     //   first_name: string;
    //     //   last_name: string;
    //     // };
    //     license_number: FormControl<string>;
    //     individual_name: FormControl<string>;
    //   }>
    // >;
  }>;
  error$: Observable<any>; //<IHttpException>;
  @Output() created = new EventEmitter<ICoreCompanyParamsCreateResults>();

  constructor(
    private fb: UntypedFormBuilder,
    public _Store: Store
  ) {
    super();
    // this.error$ = this._Store.select(selectSkylabCompanyError);
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.buildForm();
    this.actions$.pipe(ofType(createCompanySuccess)).subscribe({
      next: (data) => this.created.emit(data.data),
    });
  }

  buildForm() {
    this.form = this.fb.group({
      company_name: [null, [Validators.required, Validators.maxLength(100)]],
      account_type: ['', [Validators.maxLength(100), Validators.required]],
      phone: [null, [Validators.minLength(7), Validators.maxLength(11), Validators.required]],
      account_owner: [this.account_owner ?? '', Validators.required],
      email: [null, [Validators.email, Validators.required]],
      // address: this.fb.group({
      //   street: ['', [Validators.required, Validators.minLength(2)]],
      //   street_2: [null],
      //   city: [null, [Validators.required, Validators.minLength(2)]],
      //   state: [null, [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      //   code: [null, [Validators.required, Validators.minLength(5)]],
      //   country: ['US'],
      // }),
    });
  }

  // userSelected(user_id: string) {
  //   this.account_owner.setValue(user_id);
  // }

  close() {
    // this.activeModal.close();
  }

  create(data: ICoreCompanyParamsCreate) {
    this._Store.dispatch(createCompany({ data }));
  }
}
