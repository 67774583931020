<div>
  <button *ngIf="canComment" class="mb-5 rounded-full border border-white px-3 py-1 text-sm" (click)="buildForm('comment')">
    <i class="fas fa-plus me-2"></i>
    Add a comment
  </button>

  @if (commentForm) {
    <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
      <form [formGroup]="form" (submit)="saveComment()">
        <div class="mb-2">
          <label for="title" class="text-xs">Title</label>
          <input type="text" formControlName="title" class="w-full rounded-md bg-gray-800 p-2" />
        </div>

        <div class="mb-2">
          <label for="comment" class="text-xs">Comment</label>
          <textarea formControlName="comment" class="w-full rounded-md bg-gray-800 p-2"></textarea>
        </div>

        <div class="flex justify-end">
          <button type="button" class="rounded-full px-3 py-1 text-end text-sm text-red-500" (click)="reset()">Cancel</button>
          <button type="submit" class="rounded-full border border-white px-3 py-1 text-end text-sm">
            <i class="fas fa-paper-plane me-2"></i>
            Submit
          </button>
        </div>
      </form>
    </div>
  }

  @if (commentLoading()) {
    <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
      <div class="flex animate-pulse items-center justify-between space-x-4">
        <div class="flex items-center space-x-2">
          <div class="h-6 w-6 rounded-full bg-gray-700"></div>

          <div class="mx-2 h-2 w-24 rounded bg-gray-700"></div>
          <div class="h-2 w-16 rounded bg-gray-700"></div>
        </div>
      </div>

      <div class="my-2 h-3 w-1/2 rounded bg-gray-700"></div>

      <div class="space-y-2">
        <div class="h-2 w-full rounded bg-gray-700"></div>
        <div class="h-2 w-5/6 rounded bg-gray-700"></div>
        <div class="h-2 w-4/6 rounded bg-gray-700"></div>
      </div>
    </div>
  }

  @for (comment of blog?.comments.slice(0, commentsEnd()); track comment?._id) {
    <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          @if (comment?.user?.profile?.profile_photo_url) {
            <img [src]="comment?.user?.profile?.profile_photo_url" alt="Author Image" class="h-8 w-8 rounded-full" />
          } @else {
            <i class="fa-solid fa-user-circle text-lg"></i>
          }
          <div class="mx-2 text-xs">{{ comment?.user?.profile?.full_name ?? 'Anonymous' }}</div>

          <div class="text-xs">{{ comment?.created_at | date: 'medium' }}</div>
        </div>
      </div>

      <div class="my-2 text-sm font-semibold">{{ comment?.title }}</div>

      <div class="text-wrap text-sm">{{ comment?.comment }}</div>

      <div class="mt-4 flex items-center gap-4">
        <div class="flex items-center rounded-full bg-gray-700 px-2 py-1">
          <i class="fas fa-chevron-up hover:text-secondary" (click)="likeComment(comment)"></i>
          <div class="mx-2 text-sm">{{ comment?.likes }}</div>
          <i class="fas fa-chevron-down hover:text-red-400" (click)="dislikeComment(comment)"></i>
        </div>

        <div class="hover:text-secondary flex items-center rounded-full bg-gray-700 px-3 py-1" (click)="showReplies(comment)">
          <i class="far fa-comment"></i>
          <div class="ms-2 text-sm">{{ comment?.replies?.length }}</div>
        </div>

        <!-- <div class="hover:text-secondary flex items-center rounded-full bg-gray-700 px-2 py-1" (click)="copyLink(comment)">
          <i class="far fa-share"></i>
        </div> -->
      </div>
    </div>

    @if (comment._id === showingReplies()?._id) {
      <div class="ms-5 md:ms-10">
        <button *ngIf="canReply" class="mb-5 rounded-full border border-white px-3 py-1 text-sm" (click)="buildForm('reply')">
          <i class="fas fa-plus mw-2"></i>
          Add a reply
        </button>

        @if (replyForm) {
          <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
            <form [formGroup]="form" (submit)="saveReply(comment)">
              <div class="mb-2">
                <label for="title" class="text-xs">Title</label>
                <input type="text" formControlName="title" class="w-full rounded-md bg-gray-800 p-2" />
              </div>

              <div class="mb-2">
                <label for="comment" class="text-xs">Comment</label>
                <textarea formControlName="comment" class="w-full rounded-md bg-gray-800 p-2"></textarea>
              </div>

              <div class="flex justify-end">
                <button type="button" class="rounded-full px-3 py-1 text-end text-sm text-red-500" (click)="reset()">Cancel</button>
                <button type="submit" class="rounded-full border border-white px-3 py-1 text-end text-sm">
                  <i class="fas fa-paper-plane me-2"></i>
                  Submit
                </button>
              </div>
            </form>
          </div>
        }

        @if (replyLoading()) {
          <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
            <div class="flex animate-pulse items-center justify-between space-x-4">
              <div class="flex items-center space-x-2">
                <div class="h-6 w-6 rounded-full bg-gray-700"></div>

                <div class="mx-2 h-2 w-24 rounded bg-gray-700"></div>
                <div class="h-2 w-16 rounded bg-gray-700"></div>
              </div>
            </div>

            <div class="my-2 h-3 w-1/2 rounded bg-gray-700"></div>

            <div class="space-y-2">
              <div class="h-2 w-full rounded bg-gray-700"></div>
              <div class="h-2 w-5/6 rounded bg-gray-700"></div>
            </div>
          </div>
        }

        @if (comment?.replies?.length === 0) {
          <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
            <div class="my-2 text-sm font-semibold">No Replies</div>
          </div>
        }

        @for (reply of comment?.replies?.slice(0, repliesEnd()); track reply?._id) {
          <div class="mb-5 w-full rounded-lg bg-gray-900 p-4">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                @if (reply?.user?.profile?.profile_photo_url) {
                  <img [src]="reply?.user?.profile?.profile_photo_url" alt="Author Image" class="h-8 w-8 rounded-full" />
                } @else {
                  <i class="fa-solid fa-user-circle text-lg"></i>
                }
                <div class="mx-2 text-xs">{{ reply?.user?.profile?.full_name ?? 'Anonymous' }}</div>

                <div class="text-xs">{{ reply?.created_at | date: 'medium' }}</div>
              </div>
            </div>

            <div class="my-2 text-sm font-semibold">{{ reply?.title }}</div>

            <div class="text-wrap text-sm">{{ reply?.comment }}</div>

            <div class="mt-4 flex items-center gap-4">
              <div class="flex items-center rounded-full bg-gray-700 px-2 py-1">
                <i class="fas fa-chevron-up hover:text-secondary" (click)="likeReplyComment(comment, reply)"></i>
                <div class="mx-2 text-sm">{{ reply?.likes }}</div>
                <i class="fas fa-chevron-down hover:text-red-400" (click)="dislikeReplyComment(comment, reply)"></i>
              </div>

              <!-- <div class="hover:text-secondary flex items-center rounded-full bg-gray-700 px-2 py-1" (click)="copyReplyLink(comment, reply)">
                <i class="far fa-share"></i>
              </div> -->
            </div>
          </div>
        }

        <button
          *ngIf="!moreRepliesDisabled(comment)"
          class="mb-5 rounded-full border border-white px-3 py-1 text-sm"
          (click)="showMoreReplies(comment)"
        >
          <i class="fas fa-chevron-down me-2"></i>
          Show More
        </button>

        <button *ngIf="!lessRepliesDisabled(comment)" class="mb-5 rounded-full border border-white px-3 py-1 text-sm" (click)="showLessReplies()">
          <i class="fas fa-chevron-up me-2"></i>
          Show Less
        </button>
      </div>
    }
  }
</div>

<button *ngIf="!moreDisabled" class="mb-5 rounded-full border border-white px-3 py-1 text-sm" [disabled]="moreDisabled" (click)="showMore()">
  <i class="fas fa-chevron-down me-2"></i>
  Show More
</button>

<!-- <button *ngIf="!lessDisabled" class="rounded-full border mb-5 border-white px-3 py-1 text-sm" [disabled]="lessDisabled" (click)="showLess()">
  <i class="fas fa-chevron-up me-2"></i>
  Show Less
</button> -->
