import { Observable } from 'rxjs';
import { IMediaDownloadResponse, IMediaParamsDownload } from './media-download.interface';

/**
 * @Controller('media-download')
 */
export interface IMediaDownloadController {
  /**
   * @Post()
   */
  getSignedURL(body: IMediaParamsDownload): Promise<IMediaDownloadResponse> | Observable<IMediaDownloadResponse>;
}
