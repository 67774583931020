import { ILaunchpointActions } from '@launchpoint/core-types';
import { get, isArray } from 'lodash';

export function filterActions(actions: ILaunchpointActions[], admin = false, debug = false): ILaunchpointActions[] {
  debug && console.log('actions', actions);

  const filtered = actions.filter((a) => {
    a.admin = a?.admin ?? false;
    if (a.admin && !admin) {
      debug && console.log(a.title, false);
      return false;
    }

    const two_actions = actions.filter((action) => action.action === a.action && action.admin !== a.admin);
    if (two_actions.length > 0) {
      return a.admin === admin;
    }

    return true;
  });

  debug && console.log('filtered', filtered);

  return filtered;
}

export function hasActions<T>(item: T, actions: ILaunchpointActions[], admin = false, debug = false) {
  const filter = filterActions(actions, admin, debug);

  const checked = filter.map((a) => checkConditions(a, item, debug)).some((a) => a);

  debug && console.log('checked', checked);

  return checked;
}

export function checkConditions(action: ILaunchpointActions, object: any, debug = false): boolean {
  if (!object || action.active === false) {
    return false;
  }
  const condition_check: boolean[] = [];
  const type = action.action;

  // if (type === 'subscriptions:remove:coupon') {
  //   debug = true;
  // }

  if (debug) console.group('action', type);
  if (debug) console.log('total conditions', Object.keys(action.conditions).length);

  for (const key in action.conditions) {
    const value = action.conditions[key];
    const data = get(object, key);

    if (debug) console.group('key', key);
    if (debug) console.log('value', value);

    if (debug) console.log('data', data);

    const hasValue = value !== undefined;
    const hasData = data !== undefined;

    if (debug) console.log('hasValue:', hasValue);
    if (debug) console.log('hasData:', hasData);

    if (debug) console.log('typeof value:', typeof value);

    // if (value && !!data) {
    //   if (debug) console.log('data is null, undefined or false');

    //   condition_check.push(false);
    // } else
    if (hasValue && typeof value !== 'object') {
      if (debug) console.log(`${value} is not object`);

      if (hasData && data === value) {
        if (debug) console.log(`${data} equals ${value}`);

        condition_check.push(true);
      } else if (value === `{ $ne: null }`) {
        if (data === null || data === undefined) {
          if (debug) console.log(`${data} is null`);

          condition_check.push(false);
        } else {
          if (debug) console.log(`${data} is not null`);

          condition_check.push(true);
        }
      } else {
        if (debug) console.log(`${data} does not equal ${value}`);

        condition_check.push(false);
      }
    } else if (isArray(value)) {
      if (debug) console.log(`${value} is array`);

      if (hasData && value.includes(data)) {
        if (debug) console.log(`${value} includes ${data}`);

        condition_check.push(true);
      } else {
        if (debug) console.log(`${value} does not include ${data}`);

        condition_check.push(false);
      }
    } else if (value === null) {
      if ([null, undefined].includes(data)) {
        if (debug) console.log(`${data} is null/undefined`);

        condition_check.push(true);
      } else {
        if (debug) console.log(`${data} is not null/undefined`);

        condition_check.push(false);
      }
    }

    if (debug) console.groupEnd();
  }
  if (debug) console.groupEnd();

  if (debug)
    console.log(
      action.title,
      condition_check,
      condition_check.every((f) => f === true)
    );

  return condition_check.every((f) => f === true);
}
