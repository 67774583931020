import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { ILaunchpointSearchFilterDates } from '@launchpoint/core-types';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ResourcesBaseComponent } from '../resources-base.component';
import { LaunchpointResourcesEventsService } from '../../../../services/resources.service';

@Component({
  selector: 'sd-event-calendar',
  templateUrl: 'event-calendar.component.html',
  styleUrls: ['event-calendar.component.scss'],
})
export class LaunchpointEventCalendarComponent extends ResourcesBaseComponent {
  _LaunchpointResourcesEventsService = inject(LaunchpointResourcesEventsService);
  calendar = inject(NgbCalendar);

  @Input() showFilter = false;

  isOpen = false;
  today = this.calendar.getToday();
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;

  @ViewChild('startDateInput') startDateInput!: ElementRef<HTMLInputElement>;
  @ViewChild('endDateInput') endDateInput!: ElementRef<HTMLInputElement>;

  get events() {
    return this._LaunchpointResourcesEventsService.filtered;
  }

  get loading() {
    return this._LaunchpointResourcesEventsService.loading;
  }

  get placeholder() {
    return this._LaunchpointResourcesEventsService.searchDates();
  }
  location(data) {
    return this._LaunchpointResourcesEventsService.getLocation(data);
  }

  get filterDates() {
    const dates = this._LaunchpointResourcesEventsService.searchDates();

    if (dates) {
      return `${dates?.start_search_date} - ${dates?.end_search_date}`;
    } else {
      return 'All';
    }
  }

  toggleFilter() {
    this.isOpen = !this.isOpen;
  }

  resetDates() {
    this.fromDate = null;
    this.toDate = null;
    this.selectOption({ start_search_date: null, end_search_date: null });
  }
  private selectOption(value: ILaunchpointSearchFilterDates) {
    this._LaunchpointResourcesEventsService.searchDates.set(value);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.selectMonthYear();
      this.isOpen = false; // Close the picker after both dates are selected
    } else {
      this.fromDate = date;
      this.toDate = null;
    }
  }

  selectMonthYear() {
    const dates: ILaunchpointSearchFilterDates = {
      start_search_date: new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
      end_search_date: new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day),
      // start_search_date: new Date(Date.UTC(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)),
      // end_search_date: new Date(Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day)),
    };
    // console.log(dates);
    this.selectOption(dates);
  }

  isRange(date: NgbDate) {
    return (
      (this.fromDate && this.toDate && date.equals(this.fromDate)) ||
      date.equals(this.toDate) ||
      (date.after(this.fromDate) && date.before(this.toDate))
    );
  }

  isFrom(date: NgbDate) {
    return this.fromDate && date.equals(this.fromDate);
  }

  isTo(date: NgbDate) {
    return this.toDate && date.equals(this.toDate);
  }

  get formattedDateRange(): string {
    if (this.fromDate && this.toDate) {
      return `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day} to ${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
    } else if (this.fromDate) {
      return `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    }
    return '';
  }
}
