import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../auth/auth.user.interface';
import { IMedia, IMediaUpdateParamsAddAudioTracks, IMediaUpdateParamsRemoveAudioTracks } from '../media.interface';

/**
 * @Controller('media-audiotracks')
 */
export interface IMediaAudiotracksController {
  /**
   * @Post()
   */
  add(body: IMediaUpdateParamsAddAudioTracks, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Put()
   */
  remove(body: IMediaUpdateParamsRemoveAudioTracks, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
}
