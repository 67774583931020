import { ICoreCompany, ICoreUser, ICoreUserSearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';

export interface LaunchpointCompanyEntityState {
  company_id: string;
  company: ICoreCompany | null | undefined;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  company_config?: {
    menu_items: {
      title: string;
      page: string;
    }[];
  };
  success_message?: string;
  company_users: ICoreUser[];
  company_user_pagination: IQueryPageination;
  company_user_sorting: IQuerySort;
  company_user_query: ICoreUserSearchQuery;
}
