import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AppSettingsCountriesService,
  GoogleAddress,
  GoogleAddressService,
  ICoreUserAddressParamsCreateNoUserID,
  ICoreUserAddressParamsUpdateNoUserID,
  LaunchpointCoreClientProfileAddressAutocompleteBaseComponent,
  UserSelectors,
} from '@launchpoint/core-client';
import { ELaunchpointGeo } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'launchpoint-core-web-profile-address-autocomplete',
  templateUrl: './profile-address-autocomplete.component.html',
  styleUrls: ['./profile-address-autocomplete.component.scss'],
})
export class LaunchpointCoreWebProfileAddressAutoCompleteComponent
  extends LaunchpointCoreClientProfileAddressAutocompleteBaseComponent
  implements AfterViewInit
{
  loading$: Observable<boolean>;
  loading = false;

  place!: google.maps.places.PlaceResult;
  @Output() setPlace = new EventEmitter<google.maps.places.PlaceResult>();
  constructor(
    public googleAddressService: GoogleAddressService,
    public formBuilder: FormBuilder,
    public _AppSettingsCountriesService: AppSettingsCountriesService,
    public _Store: Store
  ) {
    super(googleAddressService, formBuilder, _AppSettingsCountriesService);
    this.loading$ = this._Store.select(UserSelectors.selectUserLoading);
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  isCountryUnitedStates(country: string) {
    switch (country) {
      case 'US':
      case 'United States':
      case 'United States of America':
      case 'united states':
      case 'united states of america':
      case 'USA':
        return true;
      default:
        return false;
    }
  }

  private getPlaceAutocomplete() {
    // Creates a new instance of Google Maps Autocomplete with the specified input element and options.
    try {
      const autocomplete = new google.maps.places.Autocomplete(this.receive_address_text.nativeElement, {
        types: ['address'], // Configures the Autocomplete to suggest only addresses
      });
      // Adds a listener for the 'place_changed' event when the user selects a place from the Autocomplete suggestions.
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        // Retrieves the selected place from the Autocomplete widget.
        this.place = autocomplete.getPlace();
        this.formatted_address = this.googleAddressService.getFormattedAddress(this.place);
        this.setPlace.emit(this.place);
        // Formats the selected place's address and calls the patchGoogleAddress method.
        this.patchGoogleAddress();
      });
    } catch (error) {
      console.log(error);
    }
  }

  patchGoogleAddress() {
    // Extracts the street number, street, and other address details from the selected place via google address service.
    const streetNo = this.googleAddressService.getStreetNumber(this.place);
    const street = this.googleAddressService.getStreet(this.place);
    const googleAddress: GoogleAddress = {
      line_one: `${streetNo === undefined ? '' : streetNo} ${street === undefined ? '' : street}`,
      line_two: '',
      zip_code: this.googleAddressService.getPostCode(this.place),
      city: this.googleAddressService.getLocality(this.place),
      state: this.googleAddressService.getState(this.place),
      country: this.googleAddressService.getCountryShort(this.place),
      long: this.place.geometry.location.lng(),
      lat: this.place.geometry.location.lat(),
      location: {
        type: ELaunchpointGeo.POINT,
        coordinates: [this.place.geometry.location.lng(), this.place.geometry.location.lat()],
      },
    };
    // calls the patchAddress method with the extracted address details.
    this.address_form.markAllAsTouched();
    try {
      this.patchAddress(googleAddress);
    } catch (error) {
      console.log('patch');
    }
  }

  patchAddress(address: GoogleAddress) {
    // Updates the form fields with the address values retrieved from the GoogleAddress object.
    if (this.address_form !== undefined) {
      this.address_form.controls.line_one?.patchValue(address.line_one);
      this.address_form.controls.line_two?.patchValue(address.line_two);
      this.address_form.get('zip_code')?.patchValue(address.zip_code);
      this.address_form.get('city')?.patchValue(address.city);
      this.address_form.get('state')?.patchValue(address.state);
      this.address_form.get('country')?.patchValue(address.country);
      this.address_form.get('long')?.patchValue(address.long);
      this.address_form.get('lat')?.patchValue(address.lat);
      this.address_form.get('location')?.patchValue(address.location);
    }
    // Sends the address data to the appropriate method for further processing.
    this.sendAddress();
  }

  sendAddress() {
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      if (loading) {
        this.loading = loading;
      } else {
        this.loading = false;
      }
    });

    const data: ICoreUserAddressParamsCreateNoUserID = {
      title: 'billing',
      address_long: this.formatted_address,
      line_one: this.address_form.value.line_one,
      line_two: this.address_form.value.line_two,
      country: this.address_form.value.country,
      city: this.address_form.value.city,
      state: this.address_form.value.state,
      zip_code: this.address_form.value.zip_code,
      long: this.address_form.value.long,
      lat: this.address_form.value.lat,
      location: this.address_form.value.location,
    };
    this.loading = false;
    return this.profileAddressCreate.emit(data);
    // if (this.address?.length > 0) {
    //   const data: ICoreUserAddressParamsUpdateNoUserID = {
    //     address_id: this.address[0]._id,
    //     title: this.address[0].title,
    //     address_long: this.formatted_address,
    //     line_one: this.address_form.value.line_one,
    //     line_two: this.address_form.value.line_two,
    //     country: this.address_form.value.country,
    //     city: this.address_form.value.city,
    //     state: this.address_form.value.state,
    //     zip_code: this.address_form.value.zip_code,
    //     long: this.address_form.value.long,
    //     lat: this.address_form.value.lat,
    //     location: this.address_form.value.location,
    //   };
    //   // console.log('data', data);

    //   return this.profileAddressUpdate.emit(data);
    // } else {
    //   const data: ICoreUserAddressParamsCreateNoUserID = {
    //     title: 'billing',
    //     address_long: this.formatted_address,
    //     line_one: this.address_form.value.line_one,
    //     line_two: this.address_form.value.line_two,
    //     country: this.address_form.value.country,
    //     city: this.address_form.value.city,
    //     state: this.address_form.value.state,
    //     zip_code: this.address_form.value.zip_code,
    //     long: this.address_form.value.long,
    //     lat: this.address_form.value.lat,
    //     location: this.address_form.value.location,
    //   };
    //   this.loading = false;
    //   return this.profileAddressCreate.emit(data);
    // }
  }

  resetForm() {
    this.initializeAddressForm();
  }
}
