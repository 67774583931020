import { Directive, inject } from '@angular/core';
import {
  IHttpException,
  IMedia,
  IMediaMetadataACLParamsUpdate,
  IMediaParamsCreate,
  IMediaParamsDelete,
  IMediaParamsDownload,
  IMediaSearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, takeUntil } from 'rxjs';
import { uniq } from 'lodash';
import { LaunchpointMediaService } from '../services/media.service';
import { LaunchpointMediaUploadService } from '../services/media-upload.service';
import { Router } from '@angular/router';
import { LaunchpointMediaTagsService } from '../services/media-tags.service';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { IMediaEntity } from './reducers/media-entity.interface';
import * as MediaSelectors from './selectors/media.selectors';
import * as MediaActions from './actions/media.actions';

@Directive()
export abstract class LaunchpointCoreClientMediaStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public _Store = inject(Store);
  public _router = inject(Router);

  public _AdminMediaService = inject(LaunchpointMediaService);
  public _LunchPointMediaUploadService = inject(LaunchpointMediaUploadService);
  public _MediaTagsService = inject(LaunchpointMediaTagsService);

  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<IMediaSearchQuery>;
  queryFilterCount$: Observable<number>;
  error$: Observable<IHttpException>;
  success$: Observable<string>;
  mediaEntities$: Observable<IMediaEntity[]>;
  //
  media$: Observable<IMedia>;
  mediaLoading$: Observable<boolean>;
  mediaError$: Observable<IHttpException>;
  mediaSuccess$: Observable<string>;

  constructor() {
    super();

    this.loading$ = this._Store.select(MediaSelectors.selectMediaLoading);
    this.pagination$ = this._Store.select(MediaSelectors.selectMediaPagination);
    this.querySort$ = this._Store.select(MediaSelectors.selectMediaQuerySort);
    this.query$ = this._Store.select(MediaSelectors.selectMediaQuery);
    this.queryFilterCount$ = this._Store.select(MediaSelectors.selectMediaQueryFilterCount);
    this.error$ = this._Store.select(MediaSelectors.selectMediaError);
    this.success$ = this._Store.select(MediaSelectors.selectMediaSuccess);
    this.mediaEntities$ = this._Store.select(MediaSelectors.selectMediaAll);
    //
    this.media$ = this._Store.select(MediaSelectors.selectSelectedMedia);
    this.mediaLoading$ = this._Store.select(MediaSelectors.selectSelectedMediaLoading);
    this.mediaError$ = this._Store.select(MediaSelectors.selectSelectedMediaError);
    this.mediaSuccess$ = this._Store.select(MediaSelectors.selectSelectedMediaSuccess);
  }

  deleteMedia(data: IMediaParamsDelete, redirectPath?: string[]) {
    this._Store.dispatch(MediaActions.deleteMedia({ data, redirectPath }));
  }
  create(data: IMediaParamsCreate) {
    this._Store.dispatch(MediaActions.createMedia({ data }));
  }
  updateQuery(query: IMediaSearchQuery) {
    this._Store.dispatch(MediaActions.updateMediaQuery({ query }));
  }
  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(MediaActions.updateMediaSorting({ querySort: sortDataEmitter }));
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      MediaActions.updateMediaPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(MediaActions.updateMediaPagination({ pagination: { ...pagination, limit } }));
  }

  getFileName(media: IMedia) {
    const filename = media.title.split('.');

    return filename[0];
  }

  get getTypes() {
    let media: IMediaEntity[];

    this.mediaEntities$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        const values = Object.values(data);
        media = values;
      },
    });

    if (media) {
      const types = media?.map((media) => {
        let type = media?.media?.meta_data?.mimetype?.split('/')[1];

        if (type?.includes('document')) {
          type = 'doc';
        }

        return type;
      });

      return uniq(types.filter((t) => t));
    }

    return [];
  }

  formatFileSize(bytes: number): string {
    if (!bytes) {
      return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  updateALC(params: { acl: string; media: IMedia }) {
    const { acl, media } = params;
    const data: IMediaMetadataACLParamsUpdate = {
      _id: media._id,
      acl: acl,
    };
    this._Store.dispatch(MediaActions.updateMediaALC({ data }));
  }

  downloadURL(media: IMedia, event?: Event) {
    // console.log('media', media);
    event?.stopPropagation();

    // console.log('here');

    if (media.meta_data?.acl === 'private') {
      // console.log('private');
      const data: IMediaParamsDownload = {
        _id: media._id,
        Key: media.meta_data.Key,
        Bucket: media.meta_data.Bucket,
      };

      this._Store.dispatch(MediaActions.getSignedUrl({ data }));
    } else {
      // console.log('not');
      this._Store.dispatch(
        MediaActions.getSignedUrlSuccess({
          data: {
            media,
            url: media.meta_data.cdn_url + media.meta_data.Key,
          },
        })
      );
    }
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'success', title: data });
      },
    });
    this.mediaSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'success', title: data });
      },
    });
  }

  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.error });
      },
    });
    this.mediaError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.error });
      },
    });
  }
  toast(params) {
    //
  }
}
