import { ObjectCannedACL } from '@aws-sdk/client-s3';
import { IQueryPageination, IQuerySort } from '../query.interfaces';
import { RequireAtLeastOne } from '../require-one';
import { IMediaCategory } from './media-categories/media-category.interface';

export interface IMediaModuleConfig {
  cloudfront_distribution_id: string;
  debug?: boolean;
}

export const MEDIA_MODULE_CONFIG = 'MEDIA_MODULE_CONFIG';

export const MEDIA_MODEL_NAME = 'MEDIA_MODEL';

export type IMediaUserAccountIds = RequireAtLeastOne<{
  user_id: string;
  account_id: string;
}>;

export class IMediaBase {
  _id: string;
  created_at: Date;
  updated_at: Date;
  title: string;
  environment: string;
  category?: IMediaCategory | string;
  description: string;
  meta_data: IMediaMetaData;
  versions: IMediaMetaData[];
  is_archived: boolean;
  usage: IMediaUsage[];
  social: IMediaSocial[];
  created_by: {
    _id: string;
    email: string;
    profile_photo_url: string;
    first_name: string;
    last_name: string;
  };
  convert_jobs: { Id: string; CreatedAt: Date; Status: string }[];
  // Video
  poster: string | IMediaBase;
  subtitles: (string | IMediaBase)[];
  audiotracks: (string | IMediaBase)[];
  // Tags
  tags: string[];
  users: string[];
  accounts: string[];
}
export class IMedia extends IMediaBase {
  category: IMediaCategory;
  poster: IMedia;
  subtitles: IMedia[];
  audiotracks: IMedia[];
}

export class IMediaUsage {
  media_id: string;
  object_type: string; // Daily Workout | Series | Movement | User | Challenges
  object_id: string;
  data: unknown;
}

export class IMediaSocial {
  outlet: string; // Instagram
  url: string; // Youtube/Viemo Location
  total: number; // 32 "times"
  last_used: Date; // 2019-12-01 "Yesterday"
}

export class IMediaUpdateParamsAddSubtitles {
  _id: string;
  subtitles: string[]; // string of id's
}

export class IMediaUpdateParamsRemoveSubtitles {
  _id: string;
  subtitle_id: string;
}
export class IMediaUpdateParamsAddAudioTracks {
  _id: string;
  audiotracks: string[]; // string of id's
}

export class IMediaUpdateParamsRemoveAudioTracks {
  _id: string;
  audiotrack_id: string;
}

export class IMediaMetaData {
  m3u8: string;
  fieldname: string;
  originalname: string;
  encoding: string;
  mimetype: string;
  size: number;
  acl: ObjectCannedACL;
  contentType: string;
  contentDisposition?: string;
  storageClass: string;
  serverSideEncryption?: string;
  metadata: { [key: string]: string };
  cdn_url: string;
  url: string;
  ETag?: string;
  VersionId?: string;
  Location: string;
  Key: string;
  Bucket: string;
}

export type IMediaParamsCreate = Pick<Partial<IMediaBase>, 'title' | 'category' | 'description' | 'tags' | 'users' | 'accounts'> & {
  url?: string;
};

type IMediaUpdate = Pick<Partial<IMediaBase>, 'description' | 'poster' | 'social' | 'is_archived' | 'title'> & {
  url?: string;
  /**
   * new category _id
   */
  category?: string;
};

export type IMediaParamsUpdate = { _id: string } & RequireAtLeastOne<IMediaUpdate>;

export interface IMediaParamsDelete {
  _id: string;
  /**
   * @default false
   *
   * If true and media has usage, it will delete anyway.
   */
  override?: boolean;
}

export type IMediaParamsUpdateMetadata = IMediaMetaData & { _id: string };

export class IMediaSearchQuery {
  search?: string;
  tags?: string[];
  category?: string[];
  archived?: boolean;
  user_ids?: string[];
  account_ids?: string[];
  metadata?: { [k in keyof IMediaMetaData]?: string };
  types?: EMediaMimeTypes[];
}

export enum EMediaMimeTypes {
  PDF = 'pdf',
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  TEXT = 'text',
  DOCUMENT = 'document',
  FONT = 'font',
}

export class IMediaSearchPayload {
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IMediaSearchQuery;
}

export class IMediaSearchResults {
  data: IMedia[];
  pagination: IQueryPageination;
}

export class IMediaTagParamsEdit {
  _id: string;
  tag: string;
}

export class IMediaTagParamsSet {
  _id: string;
  tags: string[];
}

export class IMediaCategoryParamsChange {
  _id: string;
  category_id: string;
}

export class IMediaMetadataACLParamsUpdate {
  _id: string;
  acl: ObjectCannedACL | string;
}
