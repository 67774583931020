import { Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UsersEntityState } from '../../../../+state/interfaces/users.interface';
import { USERS_PROFILE_CONFIG } from '../../../../+state/reducers/config';
import { LaunchpointCoreClientProfileConfig } from '../../../../../user/core/core-profile/interfaces/core-profile.interface';
import { selectSelectedUserEntity } from '../../../../+state/selectors/users.selectors';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';

@Directive()
export abstract class UserUpdateBaseComponent extends LaunchpointCoreClientBaseComponent {
  form: UntypedFormGroup;
  id: string;
  message: string | null = null;
  loading = false;
  userEntity$: Observable<UsersEntityState | undefined>;

  _config: LaunchpointCoreClientProfileConfig = USERS_PROFILE_CONFIG;

  // user: IUser;
  // user$: Observable<IUser | null>;
  // unsubscribe: Subscription[] = [];

  constructor(
    public _Store: Store,
    public _route: ActivatedRoute
  ) {
    super();
    this.userEntity$ = this._Store.select(selectSelectedUserEntity);
  }

  // get rating_stage(): AbstractControl {
  //   return this.form.get('rating_stage') as FormControl;
  // }

  // get rating_title(): AbstractControl {
  //   return this.form.get('rating_title') as FormControl;
  // }
  // get active(): AbstractControl {
  //   return this.form.get('active') as FormControl;
  // }
  // get description(): AbstractControl {
  //   return this.form.get('description') as FormControl;
  // }
  // get min_value(): AbstractControl {
  //   return this.form.get('min_value') as FormControl;
  // }

  submit() {
    this.message = null;
    this.loading = true;
    if (this.form.invalid) {
      return;
    }
    // const obj: ICommercialAutoRateFactorsParamsUpdate = {
    //   _id: this.id,
    //   rating_stage: this.rating_stage.value,
    //   rating_title: this.rating_title.value,
    //   active: this.active.value,
    //   description: this.description?.value,
    //   min_value: this.min_value?.value,
    // };
    // this._Store.dispatch(())
  }
}
