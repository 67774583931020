<app-explore-main-drawer></app-explore-main-drawer>
<app-help-drawer></app-help-drawer>
<launchpoint-media-upload-drawer />


<div class="engage-toolbar d-flex position-fixed px-5 fw-bolder zindex-2 top-50 end-0 transform-90 mt-20 gap-2">

  <!--begin::Explore drawer toggle-->
  <!-- <button id="kt_engage_demos_toggle"
    class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
    title="" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click" data-bs-trigger="hover"
    data-bs-original-title="Check out 20 more demos">
    <span id="kt_engage_demos_label">Demos</span>
  </button> -->
  <!--end::Explore drawer toggle-->

  <!--begin::Help drawer toggle-->
  <!-- <button id="kt_help_toggle"
    class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
    title="Learn & Get Inspired" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click"
    data-bs-trigger="hover">
    Help
  </button> -->
  <!--end::Help drawer toggle-->

  <button id="kt_upload_toggle"
    class="engage-upload-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0"
    data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click" data-bs-trigger="hover"
    data-bs-original-title="Some other title">
    <span id="kt_upload_label">Upload Media</span>
  </button>

  <!-- <app-purchase-toolbar></app-purchase-toolbar> -->
</div>