export const LAUNCHPOINT_NOTIFICATION_SCHEMA_NAME = 'LAUNCHPOINT_NOTIFICATION_SCHEMA_NAME';
// export const NOTIFICATION_JOB_NAME = 'notification:job:';

export enum ENotifications {
  /**
   * Finds the notification and uses the payload to format the message
   */
  SEND_NOTIFICATION = 'Send Notification',
  UPSERT_NOTIFICATION = 'Upsert Notification',
  //   DELETE_NOTIFICATION = 'Delete Notification',
  /**
   * Will not use the notification to send,format the messages
   */
  FIRE_NOTIFICATION = 'Fire Notification',
  /**
   * Calls AWS SNS to send a text message
   * does not store the message in the database or read from database for formatting
   */
  SEND_TEXT_CODE = 'Send Text Code',
  SEND_EMAIL_CODE = 'Send Email Code',
}

export enum ENotificationChannelType {
  EMAIL = 'Email',
  SMS = 'SMS',
  PUSH = 'Push',
  SLACK = 'Slack',
  SYSTEM = 'System',
}
// PUSH | GCM | APNS | APNS_SANDBOX | APNS_VOIP | APNS_VOIP_SANDBOX | ADM | SMS | VOICE | EMAIL | BAIDU | CUSTOM | IN_APP;

export enum ENotificationSMSNotificationType {
  PROMOTIONAL = 'Promotional',
  TRANSACTIONAL = 'Transactional',
}
