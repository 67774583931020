<div class="card">
    <div class="card-body p-4" *ngIf="event">
        <div class="d-flex justify-content-between mb-1">

            <div>
                <h4 class="mb-2">Response</h4>
                <pre [innerHTML]="event?.response | json"></pre>
            </div>

            <div class="d-flex justify-content-end">
                <launchpoint-ellipsis-menu [options]="getOptions(event)" />
            </div>
        </div>

        <h4 class="mb-2">Request</h4>
        <pre [innerHTML]="event?.request | json"></pre>

    </div>
</div>