import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { EMediaMimeTypes } from '@launchpoint/core-types';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[Mimetype]',
})
export class MimetypeDirective implements AfterViewInit {
  @Input() Mimetype: string;

  private mimetypes = {
    // pdf
    pdf: { icon: 'fas fa-file-pdf', color: 'danger' },
    // documents
    doc: { icon: 'fas fa-file-word', color: 'dark' },
    docx: { icon: 'fas fa-file-word', color: 'dark' },
    vtt: { icon: 'fas fa-file-archive', color: 'dark' },
    zip: { icon: 'fas fa-file-archive', color: 'dark' },
    ppt: { icon: 'fas fa-file-powerpoint', color: 'dark' },
    pptx: { icon: 'fas fa-file-powerpoint', color: 'dark' },
    xls: { icon: 'fas fa-file-excel', color: 'dark' },
    xlsx: { icon: 'fas fa-file-excel', color: 'dark' },
    csv: { icon: 'fas fa-file-csv', color: 'dark' },
    // images
    png: { icon: 'fas fa-file-image', color: 'info' },
    jpg: { icon: 'fas fa-file-image', color: 'info' },
    jpeg: { icon: 'fas fa-file-image', color: 'info' },
    svg: { icon: 'fas fa-file-image', color: 'info' },
    gif: { icon: 'fas fa-file-image', color: 'info' },
    tif: { icon: 'fas fa-file-image', color: 'info' },
    ai: { icon: 'fas fa-file-image', color: 'info' },
    heic: { icon: 'fas fa-file-image', color: 'info' },
    // video
    mp4: { icon: 'fas fa-file-video', color: 'success' },
    mov: { icon: 'fas fa-file-video', color: 'success' },
    quicktime: { icon: 'fas fa-file-video', color: 'success' },
    // audio
    mp3: { icon: 'fas fa-file-audio', color: 'warning' },
    srt: { icon: 'fas fa-file-audio', color: 'warning' },
    // other
    css: { icon: 'fas fa-file-code', color: 'secondary' },
    sql: { icon: 'fas fa-file-code', color: 'secondary' },
    xml: { icon: 'fas fa-file-code', color: 'secondary' },
  };

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this?.Mimetype) {
      const category = this.Mimetype.split('/')[0];
      let filetype = this.Mimetype.split('/')[1] ?? 'doc';

      if (filetype.includes('document')) {
        filetype = 'doc';
      }
      if (filetype.includes('+')) {
        filetype = filetype.split('+')[0];
      }

      const { icon, color } = this.mimetypes[filetype] ?? this.getIcon(category as EMediaMimeTypes);

      const icons = icon.split(' ');
      this.elementRef.nativeElement.classList.add(...icons, `text-${color}`);
    } else {
      this.elementRef.nativeElement.classList.add('fas', 'fa-file', 'text-dark');
    }
  }

  private getIcon(category: EMediaMimeTypes): { icon: string; color: string } {
    switch (category) {
      case EMediaMimeTypes.IMAGE: {
        return { icon: 'fas fa-file-image', color: 'info' };
      }
      case EMediaMimeTypes.VIDEO: {
        return { icon: 'fas fa-file-video', color: 'success' };
      }
      case EMediaMimeTypes.AUDIO: {
        return { icon: 'fas fa-file-audio', color: 'warning' };
      }
      default: {
        return { icon: 'fas fa-file', color: 'dark' };
      }
    }
  }
}
