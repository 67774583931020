import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GetJobCommandOutput } from '@aws-sdk/client-mediaconvert';
import { configureURL, IMedia, IMediaConvertController, IMediaConvertWebhook } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig } from '../interface/media-service.interface';

@Injectable()
export class LaunchpointMediaConvertService extends LaunchpointSecurityUserIdHeaderService implements IMediaConvertController {
  sources: EventSource[] = [];
  API_URL = 'api/media/';
  url: string;

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig
  ) {
    super(_Store);
    this.url = `${location.origin}/${_config.environment.mediaApiUrl}/content/${_config?.route}/`;

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.environment?.mediaApiUrl,
      route: 'media-convert',
    });
  }
  createJob(body: IMedia): Promise<IMedia> | Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMedia>(`${this.API_URL}`, body, { headers });
      })
    );
  }

  // DO NOT ADD HEADERS TO THESE ---------------->>>>>>

  getJob(job_id: string): Promise<GetJobCommandOutput> | Observable<GetJobCommandOutput> {
    return this.http.get<GetJobCommandOutput>(`${this.API_URL}` + `/${job_id}`);
  }

  webhook(body: IMediaConvertWebhook): Promise<IMedia> | Observable<IMedia> {
    return this.http.post<IMedia>(`${this.API_URL}` + `/webhook`, body);
  }
}
