import {
  ICoreAlertBanner,
  ICoreAlertBannerParamsCreate,
  ICoreAlertBannerParamsUpdate,
  ICoreAlertBannerSearchQuery,
  ICoreAlertBannerSearchResults,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllAlertBanners = createAction('[AlertBanner] searchAllAlertBanners');
export const searchAllAlertBannersSuccess = createAction(
  '[AlertBanner] searchAllAlertBannersSuccess',
  props<{ data: ICoreAlertBannerSearchResults; success_message?: string }>()
);
export const searchAllAlertBannersFailure = createAction('[AlertBanner] searchAllAlertBannersFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllAlertBannerPagination = createAction(
  '[AlertBanner] updateAllAlertBannerPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllAlertBannerPaginationScroll = createAction(
  '[AlertBanner] updateAllAlertBannerPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllAlertBannerSorting = createAction('[AlertBanner] updateAllAlertBannerSorting', props<{ querySort: IQuerySort }>());
export const updateAllAlertBannerQuery = createAction('[AlertBanner] updateAllAlertBannerQuery', props<{ query: ICoreAlertBannerSearchQuery }>());
// getById
export const getAlertBannerByIdSetId = createAction('[AlertBanner] getAlertBannerByIdSetId', props<{ banner_id: string }>());
export const getAlertBannerById = createAction('[AlertBanner] getAlertBannerById', props<{ banner_id: string }>());
export const getAlertBannerByIdSuccess = createAction(
  '[AlertBanner] getAlertBannerByIdSuccess',
  props<{ data: ICoreAlertBanner; success_message?: string }>()
);
export const getAlertBannerByIdFailure = createAction(
  '[AlertBanner] getAlertBannerByIdFailure',
  props<{ banner_id: string; error: IHttpException }>()
);

// Create AlertBanner
export const createAlertBanner = createAction('[AlertBanner] createAlertBanner', props<{ data: ICoreAlertBannerParamsCreate }>());
export const createAlertBannerSuccess = createAction(
  '[AlertBanner] createAlertBannerSuccess',
  props<{ data: ICoreAlertBanner; success_message?: string }>()
);
export const createAlertBannerFailure = createAction('[AlertBanner] createAlertBannerFailure', props<{ error: IHttpException }>());
// Update AlertBanner
export const updateAlertBanner = createAction('[AlertBanner] updateAlertBanner', props<{ data: ICoreAlertBannerParamsUpdate }>());
export const updateAlertBannerSuccess = createAction(
  '[AlertBanner] updateAlertBannerSuccess',
  props<{ data: ICoreAlertBanner; success_message?: string }>()
);
export const updateAlertBannerFailure = createAction('[AlertBanner] updateAlertBannerFailure', props<{ banner_id: string; error: IHttpException }>());
// Delete AlertBanner
export const deleteAlertBanner = createAction('[AlertBanner] deleteAlertBanner', props<{ banner_id: string }>());
export const deleteAlertBannerSuccess = createAction(
  '[AlertBanner] deleteAlertBannerSuccess',
  props<{ data: ICoreAlertBanner; success_message?: string }>()
);
export const deleteAlertBannerFailure = createAction('[AlertBanner] deleteAlertBannerFailure', props<{ banner_id: string; error: IHttpException }>());
