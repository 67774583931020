<launchpoint-detail-view-header-card [object]="blog$ | async" [show_media]="false" [loading]="blogLoading$">
  <ng-container icon *ngIf="(blog$ | async)?.featured">
    <i class="fas fa-star text-primary ms-5" ngbTooltip="Featured"></i>
  </ng-container>

  <ng-container badge *ngIf="!live(blog$ | async)">
    <span class="badge badge-light-info ms-5" [ngbTooltip]="helperTooltip(blog$ | async)" placement="right"> {{ liveText(blog$ | async) }} </span>
  </ng-container>

  <ng-container badge *ngIf="live(blog$ | async)">
    <span class="badge badge-light-success ms-5" placement="right"> Live </span>
  </ng-container>

  <ng-container subtitle *ngIf="(blog$ | async)?.release_at as release_at">
    <span class="fw-semibold text-muted">{{ helperText(release_at) }}: {{ release_at | date: 'medium' }}</span>
  </ng-container>

  <ng-container block_1_value>{{ (blog$ | async)?.created_at | date }}</ng-container>
  <ng-container block_1_header>Created </ng-container>

  <ng-container block_2_value>{{ (blog$ | async)?.updated_at | date }}</ng-container>
  <ng-container block_2_header>Last Updated</ng-container>

  <ng-container ellipsis_menu>
    <launchpoint-ellipsis-menu [options]="getOptions(blog$ | async)" />
  </ng-container>

  <ng-container navigation>
    @for (item of menu_items; track item) {
      <li class="nav-item">
        <a class="nav-link text-active-primary me-6" [routerLink]="item.page" routerLinkActive="active">
          {{ item.title }}
        </a>
      </li>
    }
  </ng-container>
</launchpoint-detail-view-header-card>
