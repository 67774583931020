import { Observable } from 'rxjs';
import { ICoreUser } from '../../user-company-account.interface';
import { ICoreUserNotificationPreferencesParamsUpsert, ICoreUserNotificationTokensParamsUpsert } from './user-notification.interface';
/**
 * @Controller('notifications')
 */
export interface ICoreUserNotificationController {
  /**
   *   @Put('tokens')
   * @param body
   */
  token(body: ICoreUserNotificationTokensParamsUpsert): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   *   @Put('tokens/remove')
   * @param body
   */
  tokenDelete(body: ICoreUserNotificationTokensParamsUpsert): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * @Put('topics')
   * @param body
   */
  topic(body: ICoreUserNotificationPreferencesParamsUpsert): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * @Put('unsubscribe-all/:user_id')
   * @param user_id
   */
  unsubscribeAll(user_id: string): Promise<ICoreUser> | Observable<ICoreUser>;
}
