import { Directive, OnInit } from '@angular/core';
import { searchAllNotifications, updateAllNotificationsQuery } from '../+state/actions/notifications.actions';
import { LaunchpointCoreClientNotificationsBaseComponent } from '../notifications-base.component';
import { FormControl } from '@angular/forms';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientNotificationsTableBaseComponent extends LaunchpointCoreClientNotificationsBaseComponent implements OnInit {
  form_control = new FormControl<string>('');

  ngOnInit() {
    if (this.pageLimit) {
      this.limit(this.pageLimit);
    } else {
      this._Store.dispatch(searchAllNotifications());
    }

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery(search),
      error: (error) => console.log('ERROR', error),
    });

    this.success();
    this.error();
  }

  updateQuery(search: string) {
    this._Store.dispatch(
      updateAllNotificationsQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }
}
