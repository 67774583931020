import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ANIMATE_CSS_DIRECTIVES } from './directives';
import { AnimateCssService } from './services/animate-css.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ANIMATE_CSS_DIRECTIVES],
  providers: [AnimateCssService],
  exports: [ANIMATE_CSS_DIRECTIVES],
})
export class AnimateCSSModule {}
