import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@launchpoint/xplat/core';
import { Observable } from 'rxjs';

@Injectable()
export class ManyChatSubscribeService {
  http = inject(HttpClient);
  url = 'api/backend/' + 'v1/';

  constructor() {
    if (environment?.apiUrl && !environment?.apiUrl.endsWith('/')) {
      this.url = environment?.apiUrl + '/' + 'v1/' + 'manychat-subscriber';
    } else {
      this.url = environment?.apiUrl + 'v1/' + 'manychat-subscriber';
    }
  }

  public createSubscriber(params: { email: string; has_opt_in_email: boolean; tags?: string[] }): Observable<{ status: string; data: any }> {
    return this.http.post<{ status: string; data: any }>(this.url + '/public', params);
  }
}
