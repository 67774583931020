<!-- TODO -- filter dates -->

<!-- @if (showFilter) {
  <div class="mb-4 flex items-center justify-end">
    <div class="rounded-full bg-white px-3 py-1 text-black" (click)="toggleFilter()">
      Filter
      <i class="fas fa-chevron-down ms-2"></i>
    </div>

    <div *ngIf="isOpen" class="mt-2 rounded">
      <ngb-datepicker
        #d
        (dateSelect)="onDateSelection($event)"
        [displayMonths]="1"
        [firstDayOfWeek]="7"
        [navigation]="'select'"
        [showWeekNumbers]="false"
        [displayMonths]="2"
        [outsideDays]="'hidden'"
      >
      </ngb-datepicker>
    </div>
  </div>

  <span>{{ fromDate?.month }} {{ fromDate?.day }} - {{ toDate?.month }} {{ toDate?.day }}</span>
} -->
@if (loading() === true) {
  <div class="borer-white grid grid-cols-4 gap-10 border-t pb-5 pt-3 md:grid-cols-5">
    <div class="col-span-1">
      <div class="flex flex-row items-end">
        <div class="text-primary text-[20px]">Loading...</div>
      </div>
    </div>
  </div>
} @else if (!events()?.length && loading() === false) {
  <div class="borer-white flex flex-col items-center border-t pb-5 pt-3">
    <div class="mb-5 text-center text-lg">
      No Events Found
      @if (placeholder?.start_search_date && placeholder?.end_search_date) {
        Between {{ placeholder?.start_search_date | date }} - {{ placeholder?.end_search_date | date }}
      }
    </div>
    <sd-button text="View All Events" (click)="resetDates()" />
  </div>
} @else {
  @for (day of events(); track day?.event_setting_attributes.starts_at) {
    <div class="borer-white grid grid-cols-4 gap-10 border-t pb-5 pt-3 md:grid-cols-5">
      <div class="col-span-1">
        <div class="-mt-5 flex flex-row items-start md:-mt-10">
          <p class="font-oswald text-primary text-[50px] font-[600] md:w-28 lg:text-[80px] xl:text-[99px]">
            {{ day.event_setting_attributes.starts_at | date: 'dd' }}
          </p>
          <p class="mt-5 flex flex-col gap-1 text-sm font-light text-white md:mt-10 md:text-lg">
            {{ day.event_setting_attributes.starts_at | date: 'MMM' }}
            <span>{{ day.event_setting_attributes.starts_at | date: 'yyyy' }} </span>
          </p>
        </div>
      </div>
      <div class="col-span-3">
        <div class="text-lg font-semibold">{{ day.name }}</div>
        <div class="my-2 text-xs font-light text-gray-400" *ngIf="day?.name">{{ day.name }}</div>
        <!-- <div class="my-2 text-xs font-light text-gray-400" *ngIf="day?.location">{{ day.location }}</div> -->
        <div class="my-2 text-xs font-light text-gray-400" *ngIf="day?.author">{{ day.author.name }}</div>
        <!-- <div class="text-primary mt-3 text-xs font-light uppercase" *ngIf="day?.cost">{{ day.cost }}</div> -->

        <div class="mt-3 block md:hidden">
          <a href="https://community.launchpoint.dev/c/events/{{ day?.slug }}" target="_blank"
            ><button class="btn btn-sm flex items-center justify-end rounded-full px-3 py-1 outline outline-1 outline-white">
              <span class="text-sm font-light">View Event</span>
              <i class="fa-solid fa-chevron-right ms-2 text-xs"></i></button
          ></a>
        </div>
      </div>

      <div class="hidden md:block">
        <div class="col-span-1 flex justify-end">
          <div>
            <a href="https://community.launchpoint.dev/c/events/{{ day.slug }}" target="_blank"
              ><button class="btn btn-sm flex items-center justify-end px-3 py-1 outline outline-1 outline-white">
                <span class="text-sm font-light">View Event</span>
                <i class="fa-solid fa-chevron-right ms-2 text-xs"></i></button
            ></a>
          </div>
        </div>
      </div>
    </div>
  }
}
