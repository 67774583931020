import { COMPANY_COMPONENTS } from './company';
import { CompanyBillingParentComponent } from './company-billing-parent/company-billing-parent.component';
import { CompanyBillingComponent } from './company-billing/company-billing.component';
import { CompanyConnectionCodesComponent } from './company-connection-codes/company-connection-codes.component';
import { CompanyConnectionsComponent } from './company-connections/company-connections.component';
import { CompanyCreateModalComponent } from './company-create-modal/company-create-modal.component';
import { CompanyCreateComponent } from './company-create/company-create.component';
import { CompanyHeaderComponent } from './company-header/company-header.component';
import { CompanyOverviewComponent } from './company-overview/company-overview.component';
import { CompanyProductsComponent } from './company-products/company-products.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanySettingsParentComponent } from './company-settings-parent/company-settings-parent.component';
import { CompanyUpdateSharedComponent } from './company-update-shared/company-update-shared.component';
import { CompanyUpdateUserComponent } from './company-update-user/company-update-user.component';
import { CompanyUpdateComponent } from './company-update/company-update.component';
import { CompanyUsersAdminComponent } from './company-users-admin/company-users.component';
import { CompanyComponent } from './company/company.component';

export const COMPANY_OVERVIEW_COMPONENTS = [
  ...COMPANY_COMPONENTS,
  CompanyHeaderComponent,
  CompanyBillingComponent,
  CompanyComponent,
  CompanyUpdateComponent,
  CompanyCreateComponent,
  CompanyProfileComponent,
  CompanySettingsParentComponent,
  CompanyBillingParentComponent,
  CompanyProductsComponent,
  CompanyUsersAdminComponent,
  CompanyConnectionsComponent,
  CompanyOverviewComponent,
  CompanyUpdateSharedComponent,
  CompanyUpdateUserComponent,
  CompanyConnectionCodesComponent,
  CompanyCreateModalComponent,
];

export * from './company';
export * from './company-billing-parent/company-billing-parent.component';
export * from './company-billing/company-billing.component';
export * from './company-connection-codes/company-connection-codes.component';
export * from './company-connections/company-connections.component';
export * from './company-create-modal/company-create-modal.component';
export * from './company-create/company-create.component';
export * from './company-header/company-header.component';
export * from './company-overview/company-overview.component';
export * from './company-products/company-products.component';
export * from './company-profile/company-profile.component';
export * from './company-settings-parent/company-settings-parent.component';
export * from './company-update-shared/company-update-shared.component';
export * from './company-update-user/company-update-user.component';
export * from './company-update/company-update.component';
export * from './company-users-admin/company-users.component';
export * from './company/company.component';

// STANDALONE
export * from './company-tagify/company-tagify.component';
