import {
  IHttpException,
  IMedia,
  IMediaDownloadResponse,
  IMediaMetadataACLParamsUpdate,
  IMediaParamsCreate,
  IMediaParamsDelete,
  IMediaParamsDownload,
  IMediaParamsUpdate,
  IMediaParamsUpdateMetadata,
  IMediaSearchQuery,
  IMediaSearchResults,
  IMediaTagParamsEdit,
  IMediaTagParamsSet,
  IMediaUpdateParamsAddAudioTracks,
  IMediaUpdateParamsAddSubtitles,
  IMediaUpdateParamsRemoveAudioTracks,
  IMediaUpdateParamsRemoveSubtitles,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

export const searchAllMedia = createAction('[Admin Media] searchAllMedia');
export const searchAllMediaSuccess = createAction(
  '[Admin Media] searchAllMediaSuccess',
  props<{ data: IMediaSearchResults; success_message?: string }>()
);
export const searchAllMediaFailure = createAction('[Admin Media] getMediaFailure', props<{ error: IHttpException }>());

export const updateMediaPagination = createAction('[Admin Media] updateMediaPagination', props<{ pagination: IQueryPageination }>());
export const updateMediaSorting = createAction('[Admin Media] updateMediaSorting', props<{ querySort: IQuerySort }>());
export const updateMediaQuery = createAction('[Admin Media] updateMediaQuery', props<{ query: IMediaSearchQuery }>());

// READ
export const getMediaById = createAction('[Admin Media] getMediaById', props<{ media_id: string }>());
export const getMediaByIdSuccess = createAction('[Admin Media] getMediaByIdSuccess', props<{ data: IMedia; success_message?: string }>());
export const getMediaByIdFailure = createAction('[Admin Media] getMediaByIdFailure', props<{ media_id: string; error: IHttpException }>());
export const getMediaByIdSetId = createAction('[lp-companies] getMediaByIdSetId', props<{ media_id: string }>());

// CREATE
export const createMedia = createAction('[Admin Media] createMedia', props<{ data: IMediaParamsCreate }>());
export const createMediaSuccess = createAction('[Admin Media] createMediaSuccess', props<{ data: IMedia; success_message?: string }>());
export const createMediaFailure = createAction('[Admin Media] createMediaFailure', props<{ error: IHttpException }>());
// DELETE
export const deleteMedia = createAction('[Admin Media] deleteMedia', props<{ data: IMediaParamsDelete; redirectPath?: string[] }>());
export const deleteMediaSuccess = createAction(
  '[Admin Media] deleteMediaSuccess',
  props<{ data: IMedia; success_message?: string; redirectPath?: string[] }>()
);
export const deleteMediaFailure = createAction('[Admin Media] deleteMediaFailure', props<{ media_id: string; error: IHttpException }>());
// DOWNLOAD
export const getSignedUrl = createAction('[Admin Media] getSignedUrl', props<{ data: IMediaParamsDownload }>());
export const getSignedUrlSuccess = createAction(
  '[Admin Media] getSignedUrlSuccess',
  props<{ data: IMediaDownloadResponse; success_message?: string }>()
);
export const getSignedUrlFailure = createAction('[Admin Media] getSignedUrlFailure', props<{ media_id: string; error: IHttpException }>());

// ---- UPDATES ----
// BASE UPDATE
export const updateMedia = createAction('[Admin Media] updateMedia', props<{ data: IMediaParamsUpdate }>());
export const updateMediaSuccess = createAction('[Admin Media] updateMediaSuccess', props<{ data: IMedia; success_message?: string }>());
export const updateMediaFailure = createAction('[Admin Media] updateMediaFailure', props<{ media_id: string; error: IHttpException }>());
// METADATA
export const updateMediaMetadata = createAction('[Admin Media] updateMediaMetadata', props<{ data: IMediaParamsUpdateMetadata }>());

// ADD AUDIO TRACKS
export const createMediaAudio = createAction('[Admin Media] createMediaAudio', props<{ data: IMediaUpdateParamsAddAudioTracks }>());
// REMOVE AUDIO TRACKS
export const removeMediaAudio = createAction('[Admin Media] removeMediaAudio', props<{ data: IMediaUpdateParamsRemoveAudioTracks }>());
//  ALC
export const updateMediaALC = createAction('[Admin Media] updateMediaALC', props<{ data: IMediaMetadataACLParamsUpdate }>());
// ADD SUBTITLES
export const createMediaSubtitle = createAction('[Admin Media] createAdminMediaSubtitle', props<{ data: IMediaUpdateParamsAddSubtitles }>());
// REMOVE SUBTITLES
export const removeMediaSubtitle = createAction('[Admin Media] removeMediaSubtitle', props<{ data: IMediaUpdateParamsRemoveSubtitles }>());
// ADD TAGS
export const createMediaTag = createAction('[Admin Media] createMediaTag', props<{ data: IMediaTagParamsEdit }>());
// DELETE TAGS
export const deleteMediaTag = createAction('[Admin Media] deleteMediaTag', props<{ data: IMediaTagParamsEdit }>());
// SET TAGS
export const setMediaTag = createAction('[Admin Media] setMediaTag', props<{ data: IMediaTagParamsSet }>());
// EMITTED
export const updateMediaEmittedValue = createAction('[Admin Media] updateMediaEmittedValue', props<{ data: IMedia }>());
