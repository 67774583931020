import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { IContentUploadQueryParams, IMedia, IMediaController } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LaunchpointMediaBaseHTTPService } from './media-base.service';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig } from '../interface/media-service.interface';

@Injectable()
export class LaunchpointMediaService extends LaunchpointMediaBaseHTTPService implements IMediaController {
  constructor(
    public _Store: Store,
    public http: HttpClient,
    @Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig,
    public _zone: NgZone
  ) {
    super(_Store, http, _config);
  }

  testGet(): Observable<string> {
    return this.http.get<string>(this.API_URL + `/content`);
  }

  private getEventSource(media_id: string): EventSource {
    const url = this.API_URL + `/media/subscribe/${media_id}`;
    for (let i = 0; i < this.sources.length; i++) {
      const s = this.sources[i];
      if (s.url.endsWith(url) && s.readyState == 1) {
        console.log('Closed Old Event Source');
        this.sources[i].close();
        this.sources.splice(i, 1);
      }
    }
    const eventSource = new EventSource(url, { withCredentials: true });
    this.sources.push(eventSource);
    return eventSource;
  }

  closeGetBy(media_id: string): boolean {
    const url = this.API_URL + `/media/subscribe/${media_id}`;
    for (let i = 0; i < this.sources.length; i++) {
      const s = this.sources[i];
      if (s.url.endsWith(url) && s.readyState == 1) {
        this.sources[i].close();
        this.sources.splice(i, 1);
      }
    }
    const eventSource = new EventSource(url, { withCredentials: true });
    this.sources.push(eventSource);
    return true;
  }

  findOneById(media_id: string): Observable<IMedia> {
    return new Observable((observer) => {
      const eventSource = this.getEventSource(media_id);
      eventSource.onopen = (evOpen) => {
        console.log(evOpen);
        // console.log();
      };
      eventSource.onerror = (evError) => {
        console.log(evError);
        // observer.error(of(evError));
      };
      eventSource.onmessage = (event) => {
        this._zone.run(() => {
          // console.log(event)
          let data;
          try {
            if (event.data) {
              data = JSON.parse(event.data);
            }
          } catch (error) {
            console.log(error);
          }
          observer.next(data);
        });
      };
    });
  }

  public uploadService(file: File, media_id: string, options?: IContentUploadQueryParams): Observable<HttpEvent<number | unknown>> {
    // this will be the our resulting map
    // create a new multipart-form for every file
    if (!file) {
      throw 'File must be included';
    }
    if (!media_id) {
      throw 'Media Id is required';
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    if (options?.metadata) {
      console.log('options?.metadata', options?.metadata);
    }

    const params = new HttpParams({ fromString: JSON.stringify(options) });

    // tell it to report the upload progress
    // console.log(this.url + media_id);
    // console.log('formData', formData);

    const req = new HttpRequest<FormData>('POST', this.url + media_id, formData, {
      reportProgress: true,
      params,
    });

    // create a new progress-subject for every file
    // console.log('req.urlWithParams', req.urlWithParams);
    // send the http-request and subscribe for progress-updates
    return this.http.request(req);
  }
}
