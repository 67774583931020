import { on } from '@ngrx/store';
import * as UserAuthActions from '../actions/user/user.actions';
import { ICoreUser, IHttpException } from '@launchpoint/core-types';

export const userFeatureKey = 'userReducer';
export const authLocalStorageToken = `authLocalStorageToken`;

export enum EStateUserReducerActions {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  VERIFY = 'verify',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  LOGOUT = 'logout',
  CHANGE_EMAIL = 'change-email',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PROFILE = 'change-profile',
}
export interface StateUserReducerError {
  action: EStateUserReducerActions | string;
  error: IHttpException | null | undefined;
}

export interface StateUserReducerSuccess {
  action: EStateUserReducerActions | string;
  message: string;
}

export interface StateUserReducer {
  /**
   * This boolean is used in that APP MODULE to initialize the application.
   * It will make the http request to see if the user is authenticated and return a value.
   * This is IF that request has been completed or not.
   * ONLY USED IN: getUserByToken | getUserByTokenSuccess | getUserByTokenFailed
   */
  initCheckComplete: boolean;
  /**
   * Stores the return url
   */
  returnUrl: string[] | null | undefined;

  // resetPasswordSuccess: boolean;

  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
  expiresIn: Date | null | undefined;

  loading: boolean;
  user: ICoreUser | null | undefined;

  error: StateUserReducerError;
  success: StateUserReducerSuccess;
}

export const USER_AUTH_REDUCERS = [
  /**
   * CLOSE OUT ALERTS
   */
  on(UserAuthActions.closeSuccessMessage, (state: StateUserReducer) => ({
    ...state,
    success: null,
  })),
  on(UserAuthActions.closeErrorMessage, (state: StateUserReducer) => ({
    ...state,
    error: null,
  })),
  /**
   * GET USER TOKEN
   */
  on(UserAuthActions.getUserByToken, (state: StateUserReducer) => ({
    ...state,
    initCheckComplete: false,
    loading: true,
    error: null,
    success: null,
  })),

  on(UserAuthActions.getUserByTokenSuccess, (state: StateUserReducer, { user, returnUrl }) => ({
    ...state,
    returnUrl,
    user,
    initCheckComplete: true,
    loading: false,
    error: null,
    success: null,
  })),
  on(UserAuthActions.getUserByTokenFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    initCheckComplete: true,
    user: null,
    loading: false,
    error: null,
    success: null,
  })),
  /**
   * Create User
   */
  on(UserAuthActions.userCreate, (state: StateUserReducer, { data: user }) => ({
    ...state,
    // user: user,
    loading: true,
    error: null,
    success: null,
  })),
  on(UserAuthActions.userCreateSuccess, (state: StateUserReducer, { message }) => ({
    ...state,
    loading: false,
    error: null,
    success: {
      action: UserAuthActions.userCreateSuccess.toString(),
      message,
    },
  })),
  // on(UserAuthActions.userCreateSuccess, (state: StateUserReducer, { message, reset }) => ({
  //   ...state,
  //   loading: false,
  //   error: null,
  //   returnUrl: reset ? ['auth', 'set-password'] : null,
  //   success: {
  //     action: UserAuthActions.userCreateSuccess.toString(),
  //     message,
  //   },
  // })),
  on(UserAuthActions.userCreateFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.REGISTRATION,
      error: error,
    },
    success: null,
  })),

  /**
   * Login Send User Email
   */
  on(UserAuthActions.loginMagicSendEmail, (state: StateUserReducer, { data: user }) => ({
    ...state,
    // user: user,
    loading: true,
    error: null,
    success: null,
  })),
  on(UserAuthActions.loginMagicSendEmailSuccess, (state: StateUserReducer, { message }) => ({
    ...state,
    loading: false,
    error: null,
    success: {
      action: EStateUserReducerActions.LOGIN,
      message,
    },
  })),
  on(UserAuthActions.loginMagicSendEmailFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.LOGIN,
      error: error,
    },
    success: null,
  })),
  on(UserAuthActions.userCreateWarning, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.LOGIN,
      error: error,
    },
    success: null,
  })),
  /**
   * LOGIN
   */
  on(UserAuthActions.login, (state: StateUserReducer) => ({
    ...state,
    loading: true,
    error: null,
    success: null,
  })),
  on(UserAuthActions.loginFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.LOGIN,
      error: error,
    },
    success: null,
  })),
  on(UserAuthActions.userCodeVerifyFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.VERIFY,
      error: error,
    },
    success: null,
  })),
  /**
   * LOGOUT
   */
  on(UserAuthActions.logoutSuccess, (state: StateUserReducer) => ({
    ...state,
    returnUrl: null,
    loading: false,
    user: null,
    error: null,
    success: null,
  })),

  /**
   * FORGOT PASSWORD
   */
  on(UserAuthActions.forgotPassword, (state: StateUserReducer) => ({
    ...state,
    loading: true,
    resetPasswordSuccess: false,
    success: null,
  })),
  on(UserAuthActions.forgotPasswordSuccess, (state: StateUserReducer) => ({
    ...state,
    loading: false,
    error: null,
    success: null,
  })),
  on(UserAuthActions.forgotPasswordFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.FORGOT_PASSWORD,
      error: error,
    },
    success: null,
  })),
  /**
   * RESET PASSWORD (admin)
   */
  on(UserAuthActions.resetPassword, (state: StateUserReducer) => ({
    ...state,
    error: null,
    success: null,
    loading: true,
  })),
  on(UserAuthActions.resetPasswordSuccess, (state: StateUserReducer) => ({
    ...state,
    loading: false,
    error: null,
    success: null,
  })),
  on(UserAuthActions.resetPasswordFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.RESET_PASSWORD,
      error: error,
    },
    success: null,
  })),
  /**
   * Change Password (logged in user)
   */
  on(UserAuthActions.loggedInUserChangePassword, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserChangePasswordSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: EStateUserReducerActions.CHANGE_PASSWORD,
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserChangePasswordFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.RESET_PASSWORD,
      error: error,
    },
    success: null,
  })),
  on(UserAuthActions.loggedInUserSetNewPassword, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserSetNewPasswordSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: EStateUserReducerActions.RESET_PASSWORD,
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserSetNewPasswordFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.RESET_PASSWORD,
      error: error,
    },
    success: null,
  })),
  /**
   * Change Email (logged in user)
   */
  on(UserAuthActions.loggedInUserChangeEmail, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserChangeEmailSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: EStateUserReducerActions.CHANGE_EMAIL,
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserChangeEmailFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.CHANGE_EMAIL,
      error,
    },
    success: null,
  })),
  /**
   * CREATE USER
   */
  on(UserAuthActions.createUser, (state: StateUserReducer, action) => ({
    ...state,
    loading: true,
    initCheckComplete: true,
    error: null,
    success: null,
  })),
  on(UserAuthActions.createUserFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.REGISTRATION,
      error: error,
    },
    success: null,
  })),
  on(UserAuthActions.createUserLogin, (state: StateUserReducer, { user }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: null,
  })),
  /**
   * EMAIL VERIFICATION
   */
  on(UserAuthActions.verifyEmailSSE, (state: StateUserReducer, action) => ({
    ...state,
    loading: true,
    error: null,
    success: null,
  })),
  on(UserAuthActions.verifyEmailSSEFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.VERIFY,
      error: error,
    },
    success: null,
  })),
  /**
   * UPDATE USER
   */
  on(UserAuthActions.updateUserSuccess, (state: StateUserReducer, { user }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: null,
  })),
  // START: User Profile:
  /**
   * Update Profile (user)
   */
  on(UserAuthActions.loggedInUserUpdateProfile, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserUpdateProfileSuccess, (state: StateUserReducer, { user, success_message }) => {
    // If the returned user from server is the logged in user, then update user object completely, otherwise just update the sub account effected
    const updated_user: ICoreUser = state?.user?._id === user?._id ? user : findAccountUpdateUser(state?.user, user);
    return {
      ...state,
      user: updated_user,
      loading: false,
      error: null,
      success: {
        action: EStateUserReducerActions.CHANGE_PROFILE,
        message: success_message,
      },
    };
  }),
  on(UserAuthActions.loggedInUserUpdateProfileFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: EStateUserReducerActions.CHANGE_PROFILE,
      error: error,
    },
    success: null,
  })),
  /**
   * Create Address (user)
   */
  on(UserAuthActions.loggedInUserCreateAddress, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserCreateAddressSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: EStateUserReducerActions.RESET_PASSWORD,
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserCreateAddressFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: UserAuthActions.loggedInUserCreateAddressFailure.toString(),
      error: error,
    },
    success: null,
  })),
  /**
   * Update Address (user)
   */
  on(UserAuthActions.loggedInUserUpdateAddress, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserUpdateAddressSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: UserAuthActions.loggedInUserUpdateAddressSuccess.toString(),
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserUpdateAddressFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: UserAuthActions.loggedInUserUpdateAddressFailure.toString(),
      error: error,
    },
    success: null,
  })),
  /**
   * Update Phone (user)
   */
  on(UserAuthActions.loggedInUserUpdatePhone, (state: StateUserReducer) => ({
    ...state,
    error: null,
    loading: true,
    success: null,
  })),
  on(UserAuthActions.loggedInUserUpdatePhoneSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loading: false,
    error: null,
    success: {
      action: UserAuthActions.loggedInUserUpdatePhoneSuccess.toString(),
      message: success_message,
    },
  })),
  on(UserAuthActions.loggedInUserUpdatePhoneFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      action: UserAuthActions.loggedInUserUpdatePhoneFailure.toString(),
      error: error,
    },
    success: null,
  })),
];

export function findAccountUpdateUser(state_user_passed: ICoreUser, updated_account) {
  const state_user = { ...state_user_passed };
  try {
    if (state_user_passed?.accounts) {
      const state_user_accounts = [...state_user_passed.accounts];
      const index = state_user_passed.accounts.findIndex(
        (account: any) => account.user_id === updated_account._id || account.user_id?._id === updated_account._id
      );
      const the_account_of_user_changed = { ...state_user_accounts[index], user_id: updated_account };
      state_user_accounts[index] = the_account_of_user_changed;
      state_user.accounts = state_user_accounts;
    } else {
      console.error('No user_passed.accounts');
    }
  } catch (error) {
    console.log(error);
  }
  //  the unable to mute objects is why there are so many constants with indexes, will need to do better next round
  return state_user;
}
