import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AlertBannerEntityAdaptor, AlertBannerFeatureKey, StateAlertBannerReducer } from '../reducers/alert-banner.reducer';

const selectAllAlertBannerState = createFeatureSelector<StateAlertBannerReducer>(AlertBannerFeatureKey);

/**
 * ROOT STATE
 */
export const getAllAlertBannerState = createSelector(selectAllAlertBannerState, (state: StateAlertBannerReducer) => state);

export const selectAlertBannerPagination = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.pagination);
export const selectAlertBannerQuerySort = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.querySort);
export const selectAlertBannerQuery = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.query);
export const selectAlertBannerSuccess = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.success_message);
export const selectAlertBannerLoading = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.loading);
export const selectAlertBannerLoaded = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.loaded);
export const selectAlertBannerError = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state.error);

export const getAllAlertBannerStateEntities = createSelector(selectAllAlertBannerState, (state: StateAlertBannerReducer) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = AlertBannerEntityAdaptor.getSelectors(selectAllAlertBannerState);

// select the array of user ids
export const selectAlertBannerIds = selectIds;

// select the dictionary of user entities
export const selectAlertBannerEntities = selectEntities;

// select the array of users
export const selectAlertBanner = selectAll;

// select the total entity count
export const selectAlertBannerTotal = selectTotal;

export const selectAlertBannerId = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => state?.selected_banner_id);

export const selectSelectedAlertBanner = createSelector(
  getAllAlertBannerState,
  selectAlertBannerId,
  (state: StateAlertBannerReducer, selected_banner_id) => state.entities[selected_banner_id]?.banner
);

export const selectSelectedAlertBannerLoading = createSelector(
  getAllAlertBannerState,
  selectAlertBannerId,
  (state: StateAlertBannerReducer, selected_banner_id) => state.entities[selected_banner_id]?.loading
);

export const selectSelectedAlertBannerError = createSelector(
  getAllAlertBannerState,
  selectAlertBannerId,
  (state: StateAlertBannerReducer, selected_banner_id) => state.entities[selected_banner_id]?.error
);

export const selectSelectedAlertBannerSuccess = createSelector(
  getAllAlertBannerState,
  selectAlertBannerId,
  (state: StateAlertBannerReducer, selected_banner_id) => state.entities[selected_banner_id]?.success_message
);

export const selectAlertBannerSuccessQueryCount = createSelector(getAllAlertBannerState, (state: StateAlertBannerReducer) => {
  let count = 0;
  try {
    count += state?.query?.banner_color?.length ?? 0;
    count += state?.query?.text_color?.length ?? 0;
    count += state?.query?.active ? 1 : 0;
    count += state?.query?.created_at ? 1 : 0;
    count += state?.query?.display_range_end_date ? 1 : 0;
    count += state?.query?.display_range_start_date ? 1 : 0;
  } catch (error) {
    // console.log(error);
  }
  return count;
});
