import { Directive, Input } from '@angular/core';
import { ICoreUserAddressParamsCreate, ICoreUserAddressParamsUpdate } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { loggedInUserCreateAddress, loggedInUserUpdateAddress } from '../../../../auth/+state/actions/user';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../../../../auth/+state/auth-base.component';

@Directive()
export class LaunchpointCoreClientProfileAddressSmartUserBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent {
  @Input() type: 'manual' | 'autocomplete' = 'manual';

  async dispatchCreateSave(event: Omit<ICoreUserAddressParamsCreate, 'user_id'>) {
    // console.log('event create', event);
    const user = await firstValueFrom(this.user$);
    this._Store.dispatch(loggedInUserCreateAddress({ data: { ...event, user_id: user._id } }));
  }

  async dispatchUpdateSave(event: Omit<ICoreUserAddressParamsUpdate, 'user_id'>) {
    // console.log('event update', event);
    const user = await firstValueFrom(this.user$);
    this._Store.dispatch(loggedInUserUpdateAddress({ data: { ...event, user_id: user._id } }));
  }
}
