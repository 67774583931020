import { Directive } from '@angular/core';
import {
  IHttpException,
  ICoreBlog,
  ICoreBlogParamsCreate,
  ICoreBlogParamsUpdate,
  ICoreBlogSearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { BlogsActions } from './actions';
import { BlogsEntityState } from './interfaces/blogs.interface';
import { BlogsSelectors } from './selectors';

@Directive()
export abstract class LaunchpointCoreClientBlogsStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreBlogSearchQuery>;
  queryFilterCount$: Observable<number>;

  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;

  blogsEntities$: Observable<BlogsEntityState[]>;

  blog$: Observable<ICoreBlog>;
  blogLoading$: Observable<boolean>;
  blogError$: Observable<IHttpException>;
  blogSuccess$: Observable<string>;

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(BlogsSelectors.selectBlogsLoading);
    this.pagination$ = this._Store.select(BlogsSelectors.selectBlogsPagination);
    this.querySort$ = this._Store.select(BlogsSelectors.selectBlogsQuerySort);
    this.query$ = this._Store.select(BlogsSelectors.selectBlogsQuery);
    this.queryFilterCount$ = this._Store.select(BlogsSelectors.selectBlogsSuccessQueryCount);

    this.error$ = this._Store.select(BlogsSelectors.selectBlogsError);
    this.success$ = this._Store.select(BlogsSelectors.selectBlogsSuccess);

    this.blogsEntities$ = this._Store.select(BlogsSelectors.selectBlogs);

    // Entity
    this.blog$ = this._Store.select(BlogsSelectors.selectSelectedBlog);
    this.blogLoading$ = this._Store.select(BlogsSelectors.selectSelectedBlogLoading);
    this.blogError$ = this._Store.select(BlogsSelectors.selectSelectedBlogError);
    this.blogSuccess$ = this._Store.select(BlogsSelectors.selectSelectedBlogSuccess);
  }

  getBlogs(blog_id: string) {
    this._Store.dispatch(BlogsActions.getBlogById({ blog_id }));
  }
  createBlog(data: ICoreBlogParamsCreate) {
    this._Store.dispatch(BlogsActions.createBlog({ data }));
  }
  updateBlog(data: ICoreBlogParamsUpdate) {
    this._Store.dispatch(BlogsActions.updateBlog({ data }));
  }
  deleteBlog(blog_id: string, redirectPath?: string[]) {
    this._Store.dispatch(BlogsActions.deleteBlog({ blog_id, redirectPath }));
  }
  updateQuery(query: ICoreBlogSearchQuery) {
    this._Store.dispatch(BlogsActions.updateAllBlogsQuery({ query }));
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      BlogsActions.updateAllBlogsPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(BlogsActions.updateAllBlogsPagination({ pagination: { ...pagination, limit } }));
  }

  live(blog: ICoreBlog) {
    if (!blog) {
      return false;
    }
    if (!blog?.release_at) {
      return false;
    }
    return blog?.active && new Date(blog?.release_at)?.getTime() < new Date().getTime();
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
    this.blogSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });

    this.blogError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
  }

  toast(params) {
    //
  }
}
