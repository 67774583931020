import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookSearchQuery,
  ICoreCompany,
  ICoreUser,
  ILaunchpointSearchFilterDates,
  IQueryPageination,
  IQuerySort,
  ITagifyTag,
} from '@launchpoint/core-types';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { updateAllWebhooksPagination, updateAllWebhooksQuery, updateAllWebhooksSorting } from '../+state/actions/webhooks.actions';
import { LaunchpointCoreClientWebhooksStateBaseComponent } from '../+state/webhooks-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientWebhooksBaseComponent extends LaunchpointCoreClientWebhooksStateBaseComponent implements OnInit {
  fb = inject(UntypedFormBuilder);

  form_control = new FormControl<string>('');

  @Input() query: ICoreActivityHistoryWebhookSearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 25, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };
  @Input() admin = true;

  selectedEvent: ICoreActivityHistoryWebhookEvent;

  public form: FormGroup<{
    created_at: FormControl<ILaunchpointSearchFilterDates>;
    url: FormControl<ITagifyTag[]>;
    events: FormControl<ITagifyTag[]>;
    mode: FormControl<string>;
    enabled: FormControl<boolean>;
  }>;

  users: ICoreUser[] = [];
  accounts: ICoreCompany[] = [];

  ngOnInit(): void {
    this._Store.dispatch(updateAllWebhooksQuery({ query: this.query }));

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      events: [],
      enabled: [],
      mode: [],
      url: [],
      created_at: [],
    });

    // console.log('this.form', this.form.value);
  }

  runQuery(search?: string) {
    const query: ICoreActivityHistoryWebhookSearchQuery = {
      ...this.query,
      search,
    };

    this._Store.dispatch(updateAllWebhooksQuery({ query }));
  }

  refresh() {
    this.ngOnInit();
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllWebhooksSorting({ querySort: sortDataEmitter }));
  }

  pageChange(event: { pageSize: number; pageIndex: number; previousPageIndex: number }) {
    this.pagination.limit = event.pageSize;
    this.pagination.skip = Number(event.pageIndex) * Number(event.pageSize);
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.previousPageIndex = event.previousPageIndex;
    this._Store.dispatch(updateAllWebhooksPagination({ pagination: this.pagination }));
  }
}
