import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaunchpointBlogHTTPService, LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { startCase } from 'lodash';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientBlogCategorySearchAheadBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;

  @Input() selected: string;
  private categories$: Observable<string[]>;

  @Output() selectedCategory = new EventEmitter<string>();

  constructor(public _LaunchpointBlogHTTPService: LaunchpointBlogHTTPService) {
    super();
  }

  ngOnInit(): void {
    this.runQuery('');
  }

  formatter = (result: string): string => `${startCase(result)}`;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term: string) =>
        this.categories$.pipe(
          map((data) => {
            const val = data.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1);

            // this will add the new term to the list if it doesn't exist
            if (!val.length) {
              return [term];
            }

            return val;
          })
        )
      )

      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    this.categories$ = this._LaunchpointBlogHTTPService.distinct().pipe(
      map((data) => {
        this.loading = false;
        return data;
      })
    );
  }

  selectData(data: { item: string }) {
    return this.selectedCategory.emit(data.item);
  }
}
