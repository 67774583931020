<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <div class="d-flex row" *ngIf="media$ | async as media">
      <div class="card">
        <div class="card-header align-items-center justify-content-between">
          <div>
            <div class="d-flex align-items-center">
              <div class="fs-3 fw-bolder"> {{ media?.title }}</div>
              <button class="btn btn-sm btn-icon text-hover-primary fs-4 ms-2" ngbTooltip="Copy URL"
                [cdkCopyToClipboard]="media?.meta_data?.url">
                <i class="fal fa-copy"></i>
              </button>
            </div>
            <span class="text-muted fs-6 fw-bold mt-3">{{ media?.description }}</span>
          </div>

          <launchpoint-ellipsis-menu [options]="ellipsisOptions(media)" />
        </div>

        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="col-lg-6 justify-content-center align-items-center" [ngClass]="{
          'd-flex': !media?.meta_data?.mimetype.startsWith('video')
        }">
              <div *ngIf="media?.meta_data?.cdn_url">
                <ng-container [ngSwitch]="true">
                  <ng-container *ngSwitchCase="media?.meta_data?.mimetype.startsWith('image')">

                    <!-- <pre [innerHTML]="{ url: media?.meta_data?.url } | json"></pre> -->
                    <!-- <p>CDN</p> -->
                    <img [src]="media?.meta_data?.url" [alt]="media?.title" style="max-width: 450px" />

                    <!-- <pre [innerHTML]="{ s3: media?.meta_data?.Location } | json"></pre> -->
                    <!-- <p>S3</p>
                    <img [src]="media?.meta_data?.Location" [alt]="media?.title" style="max-width: 450px" /> -->
                  </ng-container>
                  <ng-container *ngSwitchCase="media?.meta_data?.mimetype.startsWith('video')">
                    <div class="pe-5 py-20">
                      <video-player #videoPlayerHTML [options]="{
                      autoplay: false,
                      controls: true,
                      fluid: true,
                      preload: 'auto',
                      playbackRates: [0.5, 1, 1.5, 2],
                      poster: media?.poster?.meta_data?.url,
                      sources: [m3u8(media)],
                      nativeTextTracks: ''
                    }" />

                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <i class="bi fs-5x" [Mimetype]="media?.meta_data?.mimetype"></i>
                  </ng-container>
                </ng-container>
              </div>

              <div class="symbol symbol-150px" *ngIf="!media?.meta_data?.cdn_url">
                <i class="bi fs-5x" [Mimetype]="media?.meta_data?.mimetype"></i>
              </div>
            </div>


            <div class="col-lg-6 border-start">
              <div class="ps-10">

                <div class="border-bottom">
                  <div class="d-flex align-items-center mb-4">
                    <span class="fw-bold fs-6 me-5">Tags</span>
                    <tagify class="me-5" name="tags" [settings]="settings" (add)="setTags($event)"
                      (remove)="removeTag($event)" [whitelist]="whitelistTags$"
                      inputClass="form-control form-control-sm form-control-solid ps-2 bg-white min-w-200px" />
                  </div>
                </div>

                @if(media?.meta_data?.mimetype.startsWith('video')){
                <div class="border-bottom mt-5">
                  <div class="d-flex align-items-center mb-4">
                    <span class="fw-bold fs-6 me-5">Poster</span>
                    <div class="d-flex align-items-center" *ngIf="media?.poster">
                      <img *ngIf="media?.poster?.meta_data?.url" [src]="media?.poster?.meta_data?.url"
                        style="max-height: 75px;" [alt]="media?.poster?.title" />

                      <span *ngIf="!media?.poster?.meta_data?.url">
                        {{ media.poster.title }}
                      </span>

                      <i class="fs-4 bi bi-dash-circle text-muted text-hover-danger ms-4" ngbTooltip="Remove Poster"
                        (click)="removePoster(media)"></i>
                    </div>

                    <div *ngIf="!media?.poster">
                      <i class="fs-4 bi bi-plus-circle text-muted text-hover-success" ngbTooltip="Add Poster"
                        (click)="addSettings(media, 'Poster')"></i>
                    </div>
                  </div>
                </div>
                }

                <div class="my-3">
                  <div class="pt-3 d-flex">
                    <span class="fw-bold fs-6 me-5">Category</span>
                    <span>{{ media?.category?.title }}</span>
                  </div>
                  <!-- <div class="pt-3 d-flex">
                              <span class="fw-bold fs-6 me-5">Encoding</span>
                              <span>{{ media?.meta_data?.encoding }}</span>
                            </div>
                            <div class="pt-3 d-flex">
                              <span class="fw-bold fs-6 me-5">Mimetype</span>
                              <span>{{ media?.meta_data?.mimetype }}</span>
                            </div>
                            <div class="pt-3 d-flex">
                              <span class="fw-bold fs-6 me-5">Content Type</span>
                              <span>{{ media?.meta_data?.contentType }}</span>
                            </div> -->
                  <div class="pt-3 d-flex">
                    <span class="fw-bold fs-6 me-5">Creator</span>
                    <span>{{ media?.created_by?.first_name }} {{ media?.created_by?.last_name }}</span>
                  </div>
                  <div class="pt-3 d-flex align-items-center">
                    <span class="fw-bold fs-6 me-5">References</span>
                    <span>{{ media?.usage?.length }}</span>
                  </div>
                  <div class="pt-3 d-flex align-items-center">
                    <span class="fw-bold fs-6 me-5">Active</span>
                    <i class="bi fs-4" [ngClass]="{
                  'bi-check-circle text-success': !media?.is_archived,
                  'bi-x-circle text-danger': media?.is_archived
                }"></i>
                  </div>
                  <div class="pt-3 d-flex">
                    <span class="fw-bold fs-6 me-5">Created</span>
                    <span>{{ media?.created_at | date }}</span>
                  </div>
                  <div class="pt-3 d-flex">
                    <span class="fw-bold fs-6 me-5">Last Modified</span>
                    <span>{{ media?.updated_at | date }}</span>
                  </div>


                  @if (replaced) {
                  <div class="fw-bold mt-5 alert alert-info">
                    Media Replaced!
                    <br />
                    Please wait a few minutes for the changes to take effect. To see changes, refresh the page.
                  </div>
                  }

                </div>

                @if(media?.meta_data?.mimetype.startsWith('video')){
                <div class="border-top">
                  <div class="fw-bold fs-4 mt-4">Video Settings</div>

                  <div>
                    <div class="pt-3">
                      <div class="d-flex align-items-center">
                        <span class="fw-bold fs-6 me-5">Subtitles</span>
                        <i class="fs-4 bi bi-plus-circle text-muted text-hover-success" ngbTooltip="Add Subtitle"
                          (click)="addSettings(media, 'Subtitle')"></i>
                      </div>

                      <div class="d-flex align-items-center ps-5" *ngFor="let subtitle of media?.subtitles">
                        <span>{{ subtitle.title }}</span>

                        <i class="fs-4 bi bi-dash-circle text-muted text-hover-danger ms-4" ngbTooltip="Delete Subtitle"
                          (click)="removeSubtitle(media, subtitle._id)"></i>
                      </div>
                    </div>

                    <div class="pt-3">
                      <div class="d-flex align-items-center">
                        <span class="fw-bold fs-6 me-5">Audio Tracks</span>
                        <i class="fs-4 bi bi-plus-circle text-muted text-hover-success" ngbTooltip="Add Audio Track"
                          (click)="addSettings(media, 'Audio')"></i>
                      </div>

                      <div class="d-flex align-items-center ps-5" *ngFor="let track of media?.audiotracks">
                        <span>{{ track.title }}</span>

                        <i class="fs-4 bi bi-dash-circle text-muted text-hover-danger ms-4"
                          ngbTooltip="Delete Audio Track" (click)="removeTrack(media, track._id)"></i>
                      </div>
                    </div>
                  </div>

                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-5" *lpSecurityAccess="['view', schemaName]">
        <div class="p-5">
          <div>
            <button type="button" (click)="show = !show" class="btn btn-sm btn-light">Debug</button>
          </div>
          <pre class="mt-4" *ngIf="show" [innerHTML]="media | json"></pre>
        </div>
      </div>
    </div>

  </div>
</div>