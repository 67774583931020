import { Observable } from 'rxjs';
import { ICoreBlog, ICoreBlogSearchPayload, ICoreBlogSearchResults } from './blog.interface';

export interface ICoreBlogPublicController {
  /**
   * @Post('search')
   * @param params
   */
  searchPublic(params: ICoreBlogSearchPayload): Promise<ICoreBlogSearchResults> | Observable<ICoreBlogSearchResults>;

  /**
   * @Post('find-one')
   * @params params
   */
  findOnePublic(params: Partial<ICoreBlog>): Promise<ICoreBlog> | Observable<ICoreBlog>;
}
