import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort, ITagifyTag } from '../../query.interfaces';
import { ECoreUserControlType, ICoreUser } from '../../user-company-account.interface';

export const USER_QUEUE = 'USER_QUEUE';
export type ICoreUserSearchQuery = {
  search?: string;
  account_ids?: string[];
  security_role?: ITagifyTag[];
  control_types?: ECoreUserControlType[];
  created_at?: ILaunchpointSearchFilterDates;
  last_login?: ILaunchpointSearchFilterDates;
  customers?: string[];
} & {
  /**
   * these key: value pairs will be added to the $or array
   */
  [key: string]: any;
  /**
   * MONGO FILTER QUERY
   */
  match$?: any;
};

export class ICoreUserSearchPayload {
  query: ICoreUserSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ICoreUserSearchResults {
  data: ICoreUser[];
  pagination: IQueryPageination;
}

// export class ICoreUserSearchQuery {
//   @IsString({ message: 'Search entry must be a string' })
//   @IsOptional()
//   search?: string;

//   @IsString({ message: 'ID must be a string' })
//   @IsArray()
//   @IsOptional()
//   _id?: string[];

//   @IsString({ message: 'Email must be a string' })
//   @IsOptional()
//   email?: string;

//   @IsString({ message: 'Name must be a string' })
//   @IsOptional()
//   first_name?: string;

//   @IsString({ message: 'Name must be a string' })
//   @IsOptional()
//   last_name?: string;

//   @IsString({ message: 'Customer ID must be a string' })
//   @IsArray()
//   @IsOptional()
//   customer_id?: string[];

//   @IsString({ message: 'Security role must be a string' })
//   @IsArray()
//   @IsOptional()
//   security_role?: string[];

//   constructor(data: ICoreUserSearchQuery) {
//     this.search = data.search;
//     this._id = data._id;
//     this.email = data.email;
//     this.first_name = data.first_name;
//     this.last_name = data.last_name;
//     this.customer_id = data.customer_id;
//     this.security_role = data.security_role;
//   }
// }

// export class ICoreUserSearchPayload {
//   @ValidateNested()
//   @Type(() => ICoreUserSearchQuery)
//   query: ICoreUserSearchQuery;

//   @ValidateNested()
//   @Type(() => IQueryPagination)
//   pagination: IQueryPagination;

//   @ValidateNested()
//   @Type(() => IQuerySort)
//   querySort: IQuerySort;

//   constructor(data: ICoreUserSearchPayload) {
//     this.query = data.query;
//     this.pagination = data.pagination;
//     this.querySort = data.querySort;
//   }
// }
