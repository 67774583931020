import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientAlertBannerModalBaseComponent } from '@launchpoint/core-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../components';

@Component({
  selector: 'launchpoint-alert-banner-modal',
  templateUrl: 'alert-banner-modal.component.html',
})
export class LaunchpointCoreWebAlertBannerModalComponent extends LaunchpointCoreClientAlertBannerModalBaseComponent {
  _activeModal = inject(NgbActiveModal);
  _swal = inject(LaunchpointSweetAlertService);

  override close() {
    this._activeModal.close();
  }
  dismiss() {
    this._activeModal.dismiss();
  }

  deleteBanner() {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to delete this banner?',
        text: 'This action cannot be undone.',
        confirmButtonText: 'Delete',
        showCancelButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteOne(this.banner._id);
          this.close();
        }
      });
  }
}
