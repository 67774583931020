<div class="card mb-xl-10 mb-5">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Details</h3>
    </div>
  </div>

  <form [formGroup]="form" class="form">
    <div class="card-body border-top p-9">
      <!-- START: Title -->
      <div class="row mb-6">
        <label class="col-form-label fw-bold fs-6">Title</label>
        <div class="col-lg-12 fv-row">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Title"
            name="title"
            formControlName="title"
          />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-form-label fw-bold fs-6">Description</label>
        <div class="col-lg-12 fv-row">
          <textarea
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Description"
            name="description"
            formControlName="description"
            rows="3"
          ></textarea>
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-form-label fw-bold fs-6">Author</label>
        <div class="col-lg-12 fv-row">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Author Name"
            name="author"
            formControlName="author"
          />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-form-label fw-bold fs-6">Canonical URL</label>
        <div class="col-lg-12 fv-row">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Canonical URL"
            name="canonical_url"
            formControlName="canonical_url"
          />
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-form-label fw-bold fs-6">Keywords</label>

        <tagify
          name="keywords"
          formControlName="keywords"
          inputClass="form-control form-control-sm bg-white ps-4"
          style="width: 100%"
          [settings]="settings"
        ></tagify>
      </div>

      <div class="row align-items-center">
        <div class="col-4">
          <label class="col-form-label fw-bold fs-6">Theme Color</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Theme Color"
              name="theme_color"
              formControlName="theme_color"
            />
          </div>
        </div>

        <div class="col-4">
          <label class="col-form-label fw-bold fs-6">MS Tile Color</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="MS Tile Color"
              name="ms_tile_color"
              formControlName="ms_tile_color"
            />
          </div>
        </div>

        <div class="col-4">
          <label class="col-form-label fw-bold fs-6">Language</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Language"
              name="language"
              formControlName="language"
            />
          </div>
        </div>
      </div>

      <!-- OG -->
      <div class="separator my-5"></div>

      <div class="fs-5 fw-semibold my-5">Open Graph</div>

      <div formGroupName="og">
        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Title</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Title"
              name="title"
              formControlName="title"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Description</label>
          <div class="col-lg-12 fv-row">
            <textarea
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Description"
              name="description"
              formControlName="description"
              rows="3"
            ></textarea>
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Image</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Image"
              name="image"
              formControlName="image"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">URL</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="URL"
              name="url"
              formControlName="url"
            />
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'pattern',
                message: 'Please enter a valid url',
                control: form.controls.og.get('url'),
              }"
            ></ng-container>
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Type</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Type"
              name="type"
              formControlName="type"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Site Name</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Site Name"
              name="site_name"
              formControlName="site_name"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Locale</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Locale"
              name="locale"
              formControlName="locale"
            />
          </div>
        </div>
      </div>

      <!-- Twitter -->
      <div class="separator my-5"></div>

      <div class="fs-5 fw-semibold my-5">Twitter</div>

      <div formGroupName="twitter">
        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Title</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Title"
              name="title"
              formControlName="title"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Description</label>
          <div class="col-lg-12 fv-row">
            <textarea
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Description"
              name="description"
              formControlName="description"
              rows="3"
            ></textarea>
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Image</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Image"
              name="image"
              formControlName="image"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Card</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Card"
              name="card"
              formControlName="card"
            />
          </div>
        </div>

        <div class="row mb-6">
          <label class="col-form-label fw-bold fs-6">Site</label>
          <div class="col-lg-12 fv-row">
            <input
              type="text"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Site"
              name="site"
              formControlName="site"
            />
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- START: Footer -->
  <div class="card-footer d-flex justify-content-end px-9 py-6">
    <button class="btn btn-secondary me-3" (click)="reset()">Cancel</button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="(loading$ | async) || (blogLoading$ | async) || form.invalid || form.pristine"
      (click)="submit()"
    >
      <ng-container *ngIf="((loading$ | async) || (blogLoading$ | async)) === false">Save</ng-container>
      <ng-container *ngIf="(loading$ | async) || (blogLoading$ | async)">
        <span class="indicator-label" loading="true">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>
    </button>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        {{ message }}
      </div>
    </div>
  </ng-container>
</ng-template>
