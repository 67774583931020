import { IHttpException, INotificationMessage } from '@launchpoint/core-types';

export interface MessagesEntityState {
  message_id: string;
  message: INotificationMessage;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  message_config?: any;
  success_message?: string;
}
