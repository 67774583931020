import { IQueryPageination, IQuerySort } from '../query.interfaces';

export interface IMediaActivityHistory {
  _id: string;
  created_at: Date;
  updated_ad: Date;
  activity: string; // Media created | Media added to object | Media deleted | Media removed from object
  title: string;
  media: string;
  updated_by: {
    _id: string;
    email: string;
    profile_photo_url: string;
    first_name: string;
    last_name: string;
    stripe_id: string;
  };
}

export interface IMediaActivityHistoryCreateParams {
  activity: string; // Media created | Media added to object | Media deleted | Media removed from object
  title: string;
  media: string;
  updated_by: {
    _id: string;
    email: string;
    profile_photo_url: string;
    first_name: string;
    last_name: string;
  };
}

export interface IMediaActivityHistoryQueryParams {
  search: string;
}

export interface IMediaActivityHistorySearchParams {
  query: IMediaActivityHistoryQueryParams;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export interface IQueryMediaActivityHistory {
  pagination: IQueryPageination;
  activity_history: IMediaActivityHistory[];
}

export interface IMediaActivityEventBodyBase {
  media_activity_id?: string;
  params: IMediaActivityHistoryCreateParams;
}

export type IMediaActivityEventBody = {
  type: 'create' | 'delete' | 'update';
  body: IMediaActivityEventBodyBase;
};
