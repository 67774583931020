<!--begin::Exolore drawer-->
<div id="kt_upload" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="upload" data-kt-drawer-activate="true"
  data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'350px', 'lg': '475px'}" data-kt-drawer-direction="end"
  data-kt-drawer-toggle="#kt_upload_toggle" data-kt-drawer-close="#kt_upload_close">
  <!--begin::Card-->
  <div class="card shadow-none rounded-0 w-100">
    <!--begin::Header-->
    <div class="card-header" id="kt_upload_header">
      <h3 class="card-title fw-bolder">Upload Media</h3>

      <div class="card-toolbar">
        <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_upload_close">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
    </div>
    <!--end::Header-->

    <div class="mt-20 mx-5">
      <launchpoint-media-upload-standalone />
    </div>
  </div>
  <!--end::Card-->
</div>
<!--end::Exolore drawer-->