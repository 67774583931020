import { Component, inject, Input, OnInit } from '@angular/core';
import { ICoreUser } from '@launchpoint/core-types';
import { LayoutService } from '../../../../core/layout.service';
import { LaunchpointTopbarConfig } from '../../../../../interfaces/topbar.interface';
import { getUserNotificationUnread, UserNotificationsHTTPService, UserSelectors } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { firstValueFrom, takeUntil } from 'rxjs';

@Component({
  selector: 'launchpoint-demo-1-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class LaunchpointDemo1TopbarComponent implements OnInit {
  _Store = inject(Store);
  _UserNotificationsHTTPService = inject(UserNotificationsHTTPService);

  toolbarButtonMarginClass = 'ms-1 ms-lg-3';
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px';
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';
  toolbarButtonIconSizeClass = 'svg-icon-1';
  headerLeft = 'menu';

  @Input() user: ICoreUser;
  @Input() topbarConfig: LaunchpointTopbarConfig;
  @Input() circle_background_class: string;

  constructor(private layout: LayoutService) {}

  ngOnInit(): void {
    this.headerLeft = this.layout.getProp('header.left') as string;
    // this.getUnread();
  }

  async getUnread() {
    console.log('getting unread notifications');
    const user = await firstValueFrom(this._Store.select(UserSelectors.selectUser));
    if (!user) {
      return;
    }

    const userNotifications = await firstValueFrom(
      this._UserNotificationsHTTPService.search({
        query: { users: [user._id] },
        pagination: { skip: 0, limit: 10 },
        querySort: { createdAt: -1 },
      })
    );

    if (userNotifications.data.some((notification) => !notification.is_read)) {
      const blinking_alert = document.createElement('span');
      document.getElementById('notifications-bell-alert').appendChild(blinking_alert);
      blinking_alert.className = 'bullet bullet-dot bg-info h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink';
    }
  }
}
