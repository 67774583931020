import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlogsEntityAdaptor, BlogsFeatureKey, StateBlogsReducer } from '../reducers/blogs.reducer';

const selectAllBlogsState = createFeatureSelector<StateBlogsReducer>(BlogsFeatureKey);

/**
 * ROOT STATE
 */
export const getAllBlogsState = createSelector(selectAllBlogsState, (state: StateBlogsReducer) => state);

export const selectBlogsPagination = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.pagination);
export const selectBlogsQuerySort = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.querySort);
export const selectBlogsQuery = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.query);
export const selectBlogsSuccess = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.success_message);
export const selectBlogsLoading = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.loading);
export const selectBlogsLoaded = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.loaded);
export const selectBlogsError = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state.error);

export const getAllBlogsStateEntities = createSelector(selectAllBlogsState, (state: StateBlogsReducer) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = BlogsEntityAdaptor.getSelectors(selectAllBlogsState);

// select the array of user ids
export const selectBlogsIds = selectIds;

// select the dictionary of user entities
export const selectBlogsEntities = selectEntities;

// select the array of users
export const selectBlogs = selectAll;

// select the total entity count
export const selectBlogsTotal = selectTotal;

export const selectBlogsId = createSelector(getAllBlogsState, (state: StateBlogsReducer) => state?.selected_blog_id);

export const selectSelectedBlog = createSelector(
  getAllBlogsState,
  selectBlogsId,
  (state: StateBlogsReducer, selected_blog_id) => state.entities[selected_blog_id]?.blog
);

export const selectSelectedBlogLoading = createSelector(
  getAllBlogsState,
  selectBlogsId,
  (state: StateBlogsReducer, selected_blog_id) => state.entities[selected_blog_id]?.loading
);

export const selectSelectedBlogError = createSelector(
  getAllBlogsState,
  selectBlogsId,
  (state: StateBlogsReducer, selected_blog_id) => state.entities[selected_blog_id]?.error
);

export const selectSelectedBlogSuccess = createSelector(
  getAllBlogsState,
  selectBlogsId,
  (state: StateBlogsReducer, selected_blog_id) => state.entities[selected_blog_id]?.success_message
);

export const selectBlogsSuccessQueryCount = createSelector(getAllBlogsState, (state: StateBlogsReducer) => {
  let count = 0;
  try {
    count += state?.query?.tags?.length ?? 0;
  } catch (error) {
    // console.log(error);
  }
  return count;
});
