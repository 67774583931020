import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyUpdateUserBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-connection-codes',
  templateUrl: 'company-connection-codes.component.html',
})
export class CompanyConnectionCodesComponent extends CompanyUpdateUserBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
}
