import { Component, Input } from '@angular/core';
import { ILaunchpointResourceTrainingCard } from '../../../interfaces/service';

@Component({
  selector: 'sd-training-card',
  templateUrl: 'training-card.component.html',
})
export class ResourcesTrainingCardComponent {
  @Input() card: ILaunchpointResourceTrainingCard;
  @Input() type: 'default' | 'inverted' = 'default';
}
