<!--Modal Begins-->

<div class="modal-header pb-0 border-0 justify-content-end">
    <h4 class="modal-title">Add An Account</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>

  <div class="modal-body my-6">
    <!--Form starts here-->
    <form>
      <div class="form-group" [formGroup]="form">
        <div class="d-flex justify-content-between">
          <div class="col px-1">
            <label for="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              formControlName="firstName"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.controls.firstName.invalid && form.controls.firstName.touched
              }"
            />
            <ng-container *ngIf="form.controls.firstName.valid || form.controls.firstName.pristine" class="fv-plugins-message-container">
              <span class="fv-help-block"> &nbsp;</span>
            </ng-container>

            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'First Name is required',
                control: form.controls.firstName
              }"
            >
            </ng-container>
          </div>

        </div>
        <div class="d-flex justify-content-between">
          <div class="col px-1">
            <label for="lastName">Last Name</label>
            <input
              type="text"
              name="lastName"
              formControlName="lastName"
              class="form-control"
              [ngClass]="{
                'is-invalid': form.controls.lastName.invalid && form.controls.lastName.touched
              }"
            />
            <ng-container *ngIf="form.controls.lastName.valid || form.controls.lastName.pristine" class="fv-plugins-message-container">
              <span class="fv-help-block"> &nbsp;</span>
            </ng-container>

            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'First Name is required',
                control: form.controls.lastName
              }"
            >
            </ng-container>
          </div>
          <div class="mt-2">
            <h3 class="form-label fs-6 fw-bolder mb-3">Birthday</h3>

            <div class="col-12">
              <div class="input-group">
                <input class="form-control" placeholder="yyyy-mm-dd" name="dp" formControlName="birthday" ngbDatepicker #d="ngbDatepicker" />
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"> <i class="fa-regular fa-calendar"></i></button>
              </div>
            </div>
          </div>
          <div *ngIf="form.controls.birthday.invalid && (form.controls.birthday.dirty || form.controls.birthday.touched)" class="text-danger">
            <div *ngIf="form.controls.birthday.errors?.['required']">Birthday is required.</div>
          </div>
        </div>
        <div class="d-flex justify-content-end modal-footer">
          <!-- <button type="submit" (click)="submit()" [disabled]="form.invalid" class="btn btn-primary mb-2 mt-5">
            <span *ngIf="(productLoading$ | async) === false" class="indicator-label">Save</span>
            <ng-container *ngIf="productLoading$ | async">
              <span class="indicator-label">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button> -->
          <button class="btn btn-primary" (click)="addAccount()">Add Account</button>
          <button class="btn btn-secondary mb-2 mt-5 mx-4" (click)="close()">Cancel</button>
        </div>
      </div>
    </form>
    <!--Form ends here-->
  </div>

  <!--Modal Ends-->

  <!--start::FormError-->
  <ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <span role="alert">{{ message }}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>
  <!--end::FormError-->
