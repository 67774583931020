import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ICoreUser, ICoreUserAttributesController, ICoreUserAttributesParamsDelete, ICoreUserAttributesParamsUpdate } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../core-profile/interfaces/core-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class CoreAttributesHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreUserAttributesController {
  /**
   * Only works for web.
   */
  API_URL = 'api/backend/';

  routes = {
    base: 'user/attributes',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);
    if (_LaunchpointCoreClientProfileConfig?.base_url) {
      this.API_URL = _LaunchpointCoreClientProfileConfig.base_url + 'v1/';
    }
  }
  updateAttributes(body: ICoreUserAttributesParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.base, body, { headers });
      })
    );
  }
  deleteAttributes(body: ICoreUserAttributesParamsDelete): Promise<ICoreUser> | Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.base + '/delete', body, { headers });
      })
    );
  }
}
