import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder } from '@angular/forms';
import { ICoreUserSearchQuery, IHttpException, IQueryPageination, IQuerySort, IUserView } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import { UsersEntityState } from '../../../../../+state/interfaces/users.interface';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../+state/users-state-base.component';
import { searchAllUsers, updateAllUsersPagination } from '../../../../../+state/actions/users.actions';

@Directive()
export abstract class UsersTableBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  @Input() user_entities: UsersEntityState[];
  @Input() pagination: IQueryPageination;
  @Input() querySort: IQuerySort;
  @Input() query: ICoreUserSearchQuery;
  @Input() error: IHttpException;
  @Input() loading = false;
  @Input() options = {
    actions: true,
  };
  @Input() table_id: string;
  active = 1;
  view: IUserView;
  form_control = new FormControl<string>('');
  isCollapsed = false;

  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() queryPagination = new EventEmitter<IQueryPageination>();
  @Output() queryUsers = new EventEmitter<string>();

  constructor(public _Store: Store, public fb: UntypedFormBuilder) {
    super(_Store);
  }

  ngOnInit() {
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
  }

  runQuery(search: string) {
    this.queryUsers.emit(search);
  }

  sortData(event: IQuerySort) {
    this.sortDataEmitter.emit(event);
  }

  pageChange(queryPagination: IQueryPageination) {
    const pagination: IQueryPageination = {
      count: this.pagination.count,
      limit: queryPagination.limit,
      skip: Number(queryPagination.pageIndex) * Number(queryPagination.limit),
      pageIndex: queryPagination.pageIndex,
      previousPageIndex: queryPagination.previousPageIndex,
    };
    // this.queryPagination.emit(pagination);
    this._Store.dispatch(updateAllUsersPagination({ pagination: pagination }));
  }

  setView(view: IUserView) {
    this.view = view;
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    const data: IQueryPageination = { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) };
    this._Store.dispatch(updateAllUsersPagination({ pagination: data }));
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllUsersPagination({ pagination: { ...pagination, limit } }));
  }

  refresh() {
    this._Store.dispatch(searchAllUsers());
    this.ngOnInit();
  }
}
