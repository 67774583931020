import { Directive, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { UserSelectors } from '../../user/auth/+state';
import { CompanySelectors } from './selectors';

@Directive()
export abstract class CompanyStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public form: UntypedFormGroup;
  _Store: Store = inject(Store);

  companyEntitys$ = this._Store.select(CompanySelectors.selectLaunchpointCompanies);
  query$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyQuery);
  querySort$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyQuerySort);
  queryFilterCount$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyQueryCount);
  success$ = this._Store.select(CompanySelectors.selectLaunchpointCompanySuccess);
  error$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyError);
  loading$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyLoading);
  pagination$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyPagination);

  company$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompany);
  companyAddress$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompanyAddress);
  companyAccountOwner$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompanyAccountOwner);
  companyEntity$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompanyEntity);
  companyUsers$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompanyUsersEntity);
  companyEntityLoading$ = this._Store.select(CompanySelectors.selectLaunchpointSelectedCompanyLoading);
  companyId$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyId);
  //
  userCompanyLoading$ = this._Store.select(UserSelectors.selectUserLoading);
  user$ = this._Store.select(UserSelectors.selectUser);

  success() {
    //
  }
  error() {
    //
  }
}
