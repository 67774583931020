import { HttpInterceptor } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LaunchpointCoreClientAuthInterceptor } from '@launchpoint/core-client';
import { AuthStorageService } from '../services/auth-storage.service';

@Injectable({ providedIn: 'root' })
export class LaunchpointCoreWebAuthInterceptor extends LaunchpointCoreClientAuthInterceptor implements HttpInterceptor {
  private _AuthStorageService = inject(AuthStorageService);

  override get tokens() {
    return this._AuthStorageService.tokens;
  }

  override setTokens(tokens) {
    this._AuthStorageService.setTokens(tokens);
  }

  override removeTokens(): void {
    this._AuthStorageService.removeTokens();
  }
}
