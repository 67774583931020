import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointCoreClientMediaCategoriesSearchAheadBaseComponent, LaunchpointMediaCategoriesService } from '@launchpoint/core-client';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-media-categories-search-ahead',
  templateUrl: 'media-categories-search.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbTypeaheadModule],
  providers: [LaunchpointMediaCategoriesService],
})
export class LaunchpointCoreWebMediaCategoriesSearchComponent extends LaunchpointCoreClientMediaCategoriesSearchAheadBaseComponent {}
