import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-main-modal',
  templateUrl: './main-modal.component.html',
})
export class MainModalComponent {
  // constructor() {}
  // ngOnInit(): void {}
}
