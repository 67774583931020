import { Observable } from 'rxjs';
import { ILaunchpointDynamicFormResponse } from '../response.interface';
import {
  ILaunchpointDynamicFormResponseResponsesParamsCreate,
  ILaunchpointDynamicFormResponseResponsesParamsDelete,
  ILaunchpointDynamicFormResponseResponsesParamsUpdate,
  ILaunchpointDynamicFormResponseResponsesParamsReorder,
} from './response-answer.interface';

export interface ILaunchpointDynamicFormResponseResponsesController {
  add(
    params: ILaunchpointDynamicFormResponseResponsesParamsCreate
  ): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  update(
    params: ILaunchpointDynamicFormResponseResponsesParamsUpdate
  ): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  reorder(
    params: ILaunchpointDynamicFormResponseResponsesParamsReorder
  ): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  remove(
    params: ILaunchpointDynamicFormResponseResponsesParamsDelete
  ): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;
}
