<div class="flex justify-center py-4">
  <div class="mx-4 flex w-[1200px] flex-col items-center justify-center">
    @if (_LaunchpointBlogService?.featured_blog(); as featuredBlog) {
      <a
        [routerLink]="['/blog', featuredBlog?.title_url]"
        class="hidden h-[600px] w-full transform rounded border bg-white text-black transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-[1?.02] hover:shadow-lg md:flex md:h-[500px] md:flex-row"
      >
        <div class="relative h-[300px] w-[50%] md:h-full lg:w-[60%]">
          @if (featuredBlog?.featured_image?.meta_data?.url) {
            <img [ngSrc]="featuredBlog?.featured_image?.meta_data?.url" [alt]="featuredBlog?.title" fill class="object-cover" priority />
          } @else {
            <img ngSrc="assets/images/blog-images/img6.png" [alt]="featuredBlog?.title" fill class="object-cover" />
          }
        </div>
        <div class="flex w-[50%] flex-1 flex-col justify-between p-4 md:p-10 lg:w-[40%]">
          <div>
            <h1 class="text-2xl md:text-4xl lg:text-6xl">{{ featuredBlog?.title }}</h1>
            <div class="mt-4 flex items-center justify-between">
              <div class="flex items-center">
                @if (featuredBlog?.author_user?.profile?.profile_photo_url) {
                  <img [src]="featuredBlog?.author_user?.profile?.profile_photo_url" alt="Author Image" class="mr-2 h-8 w-8 rounded-full" priority />
                } @else {
                  <i class="fa-solid fa-user-circle mr-2 text-lg"></i>
                }
                <span class="mr-4 text-sm">{{ featuredBlog?.author_user?.profile?.full_name }}</span>
              </div>

              <span class="text-xs">{{ featuredBlog?.created_at | date }}</span>
            </div>
            <div class="my-2">
              <span class="mr-2 rounded-full bg-black px-2 py-1 text-xs text-white">{{ featuredBlog?.category }}</span>

              <span class="text-xs" *ngIf="featuredBlog?.reading_time_min">{{ featuredBlog?.reading_time_min }} min read</span>
            </div>
          </div>
          <div class="">
            <button class="rounded-full border border-black px-3 py-1 text-sm">Read More <i class="fa-solid fa-angle-right"></i></button>
          </div>
        </div>
      </a>
      <a
        class="block h-[600px] w-full transform rounded border bg-white text-black transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-[1?.02] hover:shadow-lg md:hidden md:w-[375px]"
        [routerLink]="['/blog', featuredBlog?.title_url]"
      >
        <div class="relative h-[270px] w-full">
          @if (featuredBlog?.featured_image?.meta_data?.url) {
            <img [ngSrc]="featuredBlog?.featured_image?.meta_data?.url" [alt]="featuredBlog?.title" fill class="object-cover" priority />
          } @else {
            <img ngSrc="assets/images/blog-images/img6.png" [alt]="featuredBlog?.title" fill class="object-cover" />
          }
          <span class="absolute right-2 top-2 rounded bg-green-500 px-2 py-1 text-xs font-semibold text-green-100">Featured</span>
        </div>
        <div class="flex h-[55%] flex-col justify-between border px-4 py-2">
          <div>
            <div class="mt-4 flex items-center justify-between">
              <div class="flex items-center">
                @if (featuredBlog?.author_user?.profile?.profile_photo_url) {
                  <img [src]="featuredBlog?.author_user?.profile?.profile_photo_url" alt="Author Image" class="mr-2 h-8 w-8 rounded-full" priority />
                } @else {
                  <i class="fa-solid fa-user-circle mr-2 text-lg"></i>
                }
                <span class="mr-4 text-sm">{{ featuredBlog?.author_user?.profile?.full_name }}</span>
              </div>

              <span class="text-xs">{{ featuredBlog?.created_at | date }}</span>
            </div>
            <div class="py-4">
              <span class="mr-2 rounded-full bg-black px-2 py-1 text-xs text-white">{{ featuredBlog?.category }}</span>

              <span class="text-xs">{{ featuredBlog?.reading_time_min }} min read</span>
            </div>
            <div>
              <h2 class="text-2xl">{{ featuredBlog?.title }}</h2>
              <h6 class="mt-2 line-clamp-3 text-gray-500">{{ featuredBlog?.seo?.description }}</h6>
            </div>
          </div>
          <div class="pb-4">
            <button class="rounded-full border border-black px-3 py-1 text-sm">Read More <i class="fa-solid fa-angle-right"></i></button>
          </div>
        </div>
      </a>
    }
  </div>
</div>
