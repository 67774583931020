import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ICoreBlog, ICoreBlogParamsCreate, ICoreBlogParamsUpdate } from '@launchpoint/core-types';
import { LaunchpointCoreClientBlogsStateBaseComponent } from '../+state/blogs-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientBlogModalBaseComponent extends LaunchpointCoreClientBlogsStateBaseComponent implements OnInit {
  @Input() blog: ICoreBlog;

  fb = inject(FormBuilder);

  form: FormGroup<{
    title: FormControl<string>;
    description: FormControl<string>;
    active: FormControl<boolean>;
    featured: FormControl<boolean>;
    category: FormControl<string>;
  }>;

  ngOnInit(): void {
    this.buildForm();

    if (this.blog) {
      this.form.patchValue(this.blog);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      description: [''],
      active: [false],
      featured: [false],
    });
  }

  setCategory(category: string) {
    this.form.patchValue({ category });
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    if (this.blog) {
      const data: ICoreBlogParamsUpdate = {
        _id: this.blog._id,
        title: form.title,
        description: form.description,
        category: form.category,
        featured: form.featured,
        active: form.active,
      };
      this.updateBlog(data);
    } else {
      const data: ICoreBlogParamsCreate = {
        title: form.title,
        description: form.description,
        category: form.category,
        featured: form.featured,
        active: form.active,
      };

      this.createBlog(data);
    }
    this.close();
  }

  close() {
    //
  }
}
