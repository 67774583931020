import { LaunchpointCoreClientProfileConfig } from '../../../user/core/core-profile/interfaces/core-profile.interface';

export const USERS_PROFILE_CONFIG: LaunchpointCoreClientProfileConfig = {
  default_image: './assets/media/avatars/blank.png',
  // icon: 'fa fa-info-circle',
  // icon_svg: './assets/media/icons/duotune/general/gen026.svg',
  // badge: 'Upgrade To Pro',

  profile_details: {
    title: 'Profile Details',
    fields: {
      first_name: true,
      last_name: true,
      birthday: true,
      gender: true,
      marital_status: true,
    },
  },
  base_url: '',
  security_roles: ['user', 'super_admin'],
  account_security_roles: [],
};
