import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebhooksEntityAdaptor, WebhooksFeatureKey, StateWebhooksReducer } from '../reducers/webhooks.reducer';

const selectAllWebhooksState = createFeatureSelector<StateWebhooksReducer>(WebhooksFeatureKey);

/**
 * ROOT STATE
 */
export const getAllWebhooksState = createSelector(selectAllWebhooksState, (state: StateWebhooksReducer) => state);

export const selectWebhooksPagination = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.pagination);
export const selectWebhooksQuerySort = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.querySort);
export const selectWebhooksQuery = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.query);
export const selectWebhooksSuccess = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.success_message);
export const selectWebhooksLoading = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.loading);
export const selectWebhooksLoaded = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.loaded);
export const selectWebhooksError = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state.error);

export const getAllWebhooksStateEntities = createSelector(
  selectAllWebhooksState,
  (state: StateWebhooksReducer) => state.entities
);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = WebhooksEntityAdaptor.getSelectors(selectAllWebhooksState);

// select the array of user ids
export const selectWebhooksIds = selectIds;

// select the dictionary of user entities
export const selectWebhooksEntities = selectEntities;

// select the array of users
export const selectWebhooks = selectAll;

// select the total entity count
export const selectWebhooksTotal = selectTotal;

export const selectWebhooksId = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => state?.selected_webhook_id);

export const selectSelectedWebhooks = createSelector(
  getAllWebhooksState,
  selectWebhooksId,
  (state: StateWebhooksReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.webhook
);

export const selectSelectedWebhooksLoading = createSelector(
  getAllWebhooksState,
  selectWebhooksId,
  (state: StateWebhooksReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.loading
);

export const selectSelectedWebhooksError = createSelector(
  getAllWebhooksState,
  selectWebhooksId,
  (state: StateWebhooksReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.error
);

export const selectSelectedWebhooksSuccess = createSelector(
  getAllWebhooksState,
  selectWebhooksId,
  (state: StateWebhooksReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.success_message
);

export const selectWebhooksSuccessQueryCount = createSelector(getAllWebhooksState, (state: StateWebhooksReducer) => {
  let count = 0;
  try {
    count += state?.query?.events?.length ?? 0;
    count += state?.query?.url?.length ?? 0;
    count += typeof state?.query?.enabled === 'boolean' ? 1 : 0;
    count += state?.query?.mode ? 1 : 0;
    count += state?.query?.created_at ? 1 : 0;
  } catch (error) {
    // console.log(error);
  }
  return count;
});
