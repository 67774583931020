import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LaunchpointMediaCategoriesService } from '../../services/media-categories.service';
import * as MediaCategoryActions from '../actions/media-category.actions';
import * as MediaCategorySelectors from '../selectors/media-category.selector';

@Injectable()
export class LaunchpointCoreMediaCategoryEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _LaunchpointMediaCategoriesService: LaunchpointMediaCategoriesService
  ) {}

  searchAdminMediaCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MediaCategoryActions.searchAllMediaCategories,
        MediaCategoryActions.updateMediaCategoryPagination,
        MediaCategoryActions.updateMediaCategorySorting,
        MediaCategoryActions.updateMediaCategoryQuery
      ),
      withLatestFrom(this._Store.select(MediaCategorySelectors.getMediaCategoryState)),
      switchMap((action) => {
        const pagination = action[1].pagination;
        const querySort = action[1].querySort;
        const query = action[1].query;
        return this._LaunchpointMediaCategoriesService.search({ pagination, querySort, query }).pipe(
          map((data) => {
            return MediaCategoryActions.searchAllMediaCategorySuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(MediaCategoryActions.searchAllMediaCategoryFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(MediaCategoryActions.searchAllMediaCategoryFailure(error)))
    );
  });

  getMediaCategoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MediaCategoryActions.getMediaCategoryById),
      switchMap((action) => {
        return this._LaunchpointMediaCategoriesService.getById(action.media_category_id).pipe(
          map((data) => {
            return MediaCategoryActions.getMediaCategoryByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(MediaCategoryActions.getMediaCategoryByIdFailure({ media_category_id: action.media_category_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(MediaCategoryActions.getMediaCategoryByIdFailure(error)))
    );
  });

  createMediaCategoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MediaCategoryActions.createMediaCategory),
      switchMap((action) => {
        return this._LaunchpointMediaCategoriesService.create(action.data).pipe(
          map((data) => {
            return MediaCategoryActions.createMediaCategorySuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(MediaCategoryActions.createMediaCategoryFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(MediaCategoryActions.getMediaCategoryByIdFailure(error)))
    );
  });

  updateMediaCategoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MediaCategoryActions.updateMediaCategory),
      switchMap((action) => {
        return this._LaunchpointMediaCategoriesService.update(action.data).pipe(
          map((data) => {
            return MediaCategoryActions.updateMediaCategorySuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(MediaCategoryActions.updateMediaCategoryFailure({ media_category_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(MediaCategoryActions.getMediaCategoryByIdFailure(error)))
    );
  });

  deleteMediaCategoryById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MediaCategoryActions.deleteMediaCategory),
      switchMap((action) => {
        return this._LaunchpointMediaCategoriesService.delete(action.media_category_id).pipe(
          map((data) => {
            return MediaCategoryActions.deleteMediaCategorySuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(MediaCategoryActions.deleteMediaCategoryFailure({ media_category_id: action.media_category_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(MediaCategoryActions.deleteMediaCategoryFailure(error)))
    );
  });
}
