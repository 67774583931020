import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../auth/auth.user.interface';
import { ICoreBlog, ICoreBlogParamsCreate, ICoreBlogParamsUpdate, ICoreBlogSearchPayload, ICoreBlogSearchResults } from './blog.interface';

export interface ICoreBlogController {
  /**
   * @Post('search')
   * @param params
   *
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_SEARCH, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_SEARCH, LAUNCHPOINT_BLOG_SCHEMA)
   */
  search(params: ICoreBlogSearchPayload, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlogSearchResults> | Observable<ICoreBlogSearchResults>;

  /**
   * @Get(':id')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_READ, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_READ, LAUNCHPOINT_BLOG_SCHEMA)
   */
  findById(id: string, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  // /**
  //  * @Get(':id')
  //  * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_READ, [])
  //  * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_READ, LAUNCHPOINT_BLOG_SCHEMA)
  //  */
  // findOne(params: ICoreBlogSearchQuery, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Post()
   * @route 'search'
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_CREATE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_CREATE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  create(body: ICoreBlogParamsCreate, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put()
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_UPDATE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_UPDATE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  update(params: ICoreBlogParamsUpdate, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Delete(':id')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_DELETE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_DELETE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  delete(id: string, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('featured/:id')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_UPDATE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_UPDATE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  markFeatured(id: string, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
  /**
   * @Get()
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_READ, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_READ, LAUNCHPOINT_BLOG_SCHEMA)
   */
  distinct(): Promise<string[]> | Observable<string[]>;
}
