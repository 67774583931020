import { Directive } from '@angular/core';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';
import { Store } from '@ngrx/store';

@Directive()
export class LaunchpointCoreClientProfileNotificationsBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
}
