import { Directive, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import { searchAllTopics, updateAllTopicPagination, updateAllTopicQuery } from './+state';
import { LaunchpointCoreClientNotificationsTopicsStateBaseComponent } from './+state/notifications-topics-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsTopicsBaseComponent
  extends LaunchpointCoreClientNotificationsTopicsStateBaseComponent
  implements OnInit
{
  form_control = new FormControl<string>('');

  ngOnInit() {
    this._Store.dispatch(searchAllTopics());

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    this.success();
    this.error();
  }

  updateQuery(search: string) {
    this._Store.dispatch(
      updateAllTopicQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllTopicPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllTopicPagination({ pagination: { ...pagination, limit } }));
  }
}
