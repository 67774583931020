<div>
  <div class="mb-0">
    <div class="rounded border border-dashed border-gray-300 p-4 mb-5">
      <div class="d-flex align-items-center justify-content-center">


        <div class="h-150">
          <div class="file-drop d-flex text-center align-items-center" *ngIf="!imageFileChanged">

            <ngfFormData [files]="files" postName="file"></ngfFormData>

            <div class="well my-drop-zone d-flex justify-content-center cursor-pointer" ngfDrop [multiple]="multiple"
              selectable="1" [(validDrag)]="validComboDrag" [accept]="accept" [maxSize]="maxSize"
              [(lastInvalids)]="lastInvalids" [(dragFiles)]="dragFiles" [class.invalid-drag]="validComboDrag === false"
              [class.valid-drag]="validComboDrag" (filesChange)="fileChangeEvent($event)"
              [fileDropDisabled]="fileDropDisabled" [files]="filesAddedArray">

              <div class="d-flex flex-column">
                <i class="bi bi-upload fs-3x mb-10"></i>
                <span class="align-self-center fs-6">{{ description }}</span>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="fileEntities?.length > 0" class="p-4">
      <div>
        <span class="fw-bold fs-6">Files Uploaded</span>
      </div>
    </div>

    <div *ngFor="let fileE of fileEntities; let i = index" class="rounded border bg-gray-100 p-4 my-5"
      [ngClass]="{ 'border-success': fileE?.media }">
      <div class="d-flex flex-stack">
        <div class="d-flex flex-column flex-fill me-4 ms-1">
          <div>

            <div class="d-flex align-items-center">
              <div *ngIf="!edit?.[fileE?.file?.name]?.edit" class="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
                {{ fileE?.file?.name }}
              </div>

              <input *ngIf="edit?.[fileE?.file?.name]?.edit"
                class="form-control form-control-sm form-control-solid bg-white min-w-300px" type="text"
                [placeholder]="fileE?.file?.name" name="title" autocomplete="off"
                [(ngModel)]="edit[fileE?.file.name].name" />

              <div (click)="edit?.[fileE?.file?.name]?.edit ? saveFile(fileE) : editFile(fileE?.file?.name)"
                class="btn btn-icon btn-sm text-hover-primary fs-7 cursor-pointer text-end me-5">
                <i *ngIf="!edit?.[fileE?.file?.name]?.edit" class="bi bi-pencil-square" ngbTooltip="Edit Title"></i>
                <i *ngIf="edit?.[fileE?.file?.name]?.edit" class="bi bi-check-circle" ngbTooltip="Save"></i>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <launchpoint-media-categories-search-ahead cssClass="pe-12 form-control-sm form-control-solid bg-white"
                [selected]="fileE?.category?._id" (selectedCategory)="setCategory($event, i)" />

              <div (click)="removeCategory(i)"
                class="btn btn-icon btn-sm text-hover-warning fs-7 cursor-pointer text-end me-5">
                <i class="bi bi-x-square" ngbTooltip="Reset Category"></i>
              </div>
            </div>
          </div>

          <div *ngIf="fileE.uploadPercentage" class="fs-7 mt-5">
            <ngb-progressbar [type]="fileE.uploadPercentage < 100 ? 'info' : 'success'" [value]="fileE.uploadPercentage"
              [striped]="true" [animated]="false">
              {{ fileE.uploadPercentage < 100 ? fileE.uploadPercentage : 'Finishing Up' }}</ngb-progressbar>
          </div>

          <div *ngIf="fileE.error" class="fs-7 text-danger mt-2">
            <span>{{ fileE?.file?.name }} failed to upload</span>
          </div>
        </div>

        <div *ngIf="!fileE?.media" (click)="remove(i)"
          class="btn btn-icon btn-sm text-hover-danger fs-7 cursor-pointer text-end">
          <i class="bi bi-trash fs-4" ngbTooltip="Remove File"></i>
        </div>

      </div>
    </div>

    <div *ngIf="fileEntities?.length > 0" class="text-end mt-10">
      <button type="button" (click)="clear()" class="btn btn-transparent me-5"><u>Clear</u></button>
      <button type="button" (click)="upload()" [disabled]="uploadDisabled" class="btn btn-primary">Upload</button>
    </div>
  </div>
</div>