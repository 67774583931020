import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-core-web-accounts',
  templateUrl: './accounts.component.html',
})
export class LaunchpointCoreWebAccountsComponent {
  // @Input() user: ICoreUser;
  // @Input() _config: LaunchpointCoreProfileConfig;
  // ngOnInit(): void {
  //   // console.log('is this even hit');
  //   if (!this._config) {
  //     throw new Error('Configuration file required.');
  //   }
  //   return;
  // }
}
