import { Component, inject } from '@angular/core';
import { StadnickDevConfigService } from '../../services/config.service';

@Component({
  selector: 'sd-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  _StadnickDevConfigService = inject(StadnickDevConfigService);

  get cta() {
    return this._StadnickDevConfigService.primaryCallToAction;
  }

  get cta2() {
    return this._StadnickDevConfigService.secondaryCallToAction;
  }
}
