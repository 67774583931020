import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientAuthStateBaseComponent, UserSelectors } from '@launchpoint/core-client';
import { Router } from '@angular/router';
import { ICoreUser } from '@launchpoint/core-types';
import { Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'launchpoint-core-web-auth-v1',
  templateUrl: './auth-v1.component.html',
  styleUrls: ['./auth-v1.component.scss'],
})
export class LaunchpointCoreWebAuthV1Component extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit{
  _router = inject(Router);

  today: Date = new Date();
  user$: Observable<ICoreUser>;
  return_url$: Observable<string[]>;
  navigate_url: string;

  imageRight = true;

  constructor() {
    super();
    this.user$ = this._Store.select(UserSelectors.selectUser) as Observable<ICoreUser>;

    if (this._config?.auth.image_side && this._config.auth.image_side === 'left') {
      this.imageRight = false;
    }
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user?.email || user?.demographics?.phone?.phone_number_long) {
        if (this.navigate_url) {
          this._router.navigate([this.navigate_url]);
        } else {
          this._router.navigate(['/']);
        }
      }
    });
  }
}
