import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ITopic,
  ITopicParamsCreate,
  ITopicParamsUpdate,
  ITopicSearchPayload,
  ITopicSearchResults,
  ITopicsController,
  configureURL,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../interfaces';

@Injectable()
export class TopicsHTTPService extends LaunchpointSecurityUserIdHeaderService implements ITopicsController {
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  routes = {
    findOne: '/find-one',
    search: '/search',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'notifications/topics',
    });
  }
  getById(id: string): Observable<ITopic> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ITopic>(this.API_URL + '/' + id, { headers });
      })
    );
  }
  findOne(body: Partial<ITopic>): Observable<ITopic> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ITopic>(this.API_URL + this.routes.findOne, body, { headers });
      })
    );
  }
  search(body: ITopicSearchPayload): Observable<ITopicSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ITopicSearchResults>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
  create(body: ITopicParamsCreate): Observable<ITopic> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ITopic>(this.API_URL, body, { headers });
      })
    );
  }
  update(body: ITopicParamsUpdate): Observable<ITopic> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ITopic>(this.API_URL, body, { headers });
      })
    );
  }
  delete(id: string): Observable<ITopic> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<ITopic>(this.API_URL + '/' + id, { headers });
      })
    );
  }
}
