import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreUser,
  ICoreUserSecurityRoleController,
  ICoreUserSecurityRoleParamsTransfer,
  ICoreUserSecurityRoleParamsUpdate,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../core-profile/interfaces/core-profile.interface';
import { ICoreUserRolesConfigRoutes } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserSecurityRoleHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreUserSecurityRoleController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';

  routes: ICoreUserRolesConfigRoutes = {
    base: 'user/security-roles',
    addRole: '/add',
    removeRole: '/remove',
    transferRole: '/transfer',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientProfileConfig?.base_url,
      version: 1,
      route: this.routes.base,
    });
  }

  addRole(body: ICoreUserSecurityRoleParamsUpdate): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.addRole, body, { headers });
      })
    );
  }
  removeRole(body: ICoreUserSecurityRoleParamsUpdate): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.removeRole, body, { headers });
      })
    );
  }
  transferRole(body: ICoreUserSecurityRoleParamsTransfer): Observable<{ success: boolean }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<{ success: boolean }>(this.API_URL + this.routes.transferRole, body, { headers });
      })
    );
  }
}
