import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlogsActions, LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { ICoreBlog } from '@launchpoint/core-types';
import { IEllipsisMenuOption } from '../../../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog-header',
  templateUrl: 'blog-header.component.html',
})
export class LaunchpointCoreWebBlogHeaderComponent extends LaunchpointCoreClientBlogBaseComponent implements OnInit {
  _swal = inject(LaunchpointSweetAlertService);
  router = inject(Router);

  menu_items: { title: string; page: string }[] = [
    { title: 'Overview', page: 'overview' },
    { title: 'Content', page: 'content' },
    { title: 'Tags', page: 'tags' },
    { title: 'SEO', page: 'seo' },
    { title: 'Media', page: 'media' },
    { title: 'Related Posts', page: 'related-posts' },
    { title: 'Comments', page: 'comments' },
  ];

  helperTooltip(blog: ICoreBlog) {
    if (!blog) {
      return;
    }
    if (!blog.active && !blog?.release_at) {
      return 'This blog is not active and not scheduled to be released.';
    }
    if (!blog?.active) {
      return 'This blog is not active.';
    }
    if (!blog?.release_at) {
      return 'This blog is not scheduled to be released.';
    }
  }

  liveText(blog: ICoreBlog) {
    if (!blog) {
      return;
    }
    if (blog.active && !blog.release_at) {
      return 'Release Date Required';
    }
    if (blog.active && new Date(blog.release_at).getTime() < new Date().getTime()) {
      return 'Live';
    }
    if (blog.active && new Date(blog.release_at).getTime() > new Date().getTime()) {
      return 'Scheduled';
    }
    if (!blog.active && new Date(blog.release_at).getTime() < new Date().getTime()) {
      return 'Inactive';
    }
    if (!blog.active && new Date(blog.release_at).getTime() > new Date().getTime()) {
      return 'Inactive';
    }
  }

  helperText(release_at: Date) {
    if (!release_at) {
      return;
    }
    if (new Date(release_at).getTime() > new Date().getTime()) {
      return 'Releases On';
    } else {
      return 'Released At';
    }
  }

  getOptions(blog: ICoreBlog): IEllipsisMenuOption[] {
    const options = [
      {
        label: blog.featured ? 'Defeature' : 'Make Featured',
        action: () => {
          this.flipFeatured(blog);
        },
      },
      {
        label: blog.active ? 'Deactivate' : 'Activate',
        action: () => {
          this.flipEnable(blog);
        },
      },
      {
        label: 'Delete',
        action: () => {
          this.deleteBlogAlert(blog._id);
        },
      },
    ];

    return options;
  }

  deleteBlogAlert(blog_id: string): void {
    this._swal
      .fireAlert({
        title: 'Are you sure you want to delete this blog?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const url = this.router.url.split('/').slice(0, -2);
          this.deleteBlog(blog_id, url);
        }
      });
  }

  flipFeatured(blog: ICoreBlog): void {
    this._swal
      .fireAlert({
        title: `Are you sure you want to make this blog ${blog.featured ? 'not featured' : 'featured'}? `,
        text: blog?.featured ? 'There will now be no featured blogs.' : 'This will be the only featured blog.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (blog.featured) {
            this.updateBlog({ _id: blog._id, featured: false });
          } else {
            this._Store.dispatch(BlogsActions.setFeatured({ blog_id: blog._id }));
          }
        }
      });
  }

  flipEnable(blog: ICoreBlog): void {
    this._swal
      .fireAlert({
        title: `Are you sure you want to ${blog.active ? 'deactivate' : 'activate'} this blog? ${blog.active && new Date(blog.release_at).getTime() < new Date().getTime() ? 'It will no longer be visible to the public.' : ''}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.updateBlog({
            _id: blog._id,
            active: !blog.active,
          });
        }
      });
  }
}
