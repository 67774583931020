import { ICoreUserJWTFlat } from '../auth';
import { ELaunchpointUserRoles, SECURITY_PERMISSIONS_DEFAULT_ROLE } from '../security.constants';
import { ISecurityConfig } from '../security.interface';
import { LAUNCHPOINT_ALERT_BANNER_SCHEMA } from './alert-banner.constants';
import { ICoreAlertBannerBase } from './alert-banner.interface';

export enum ESecurityAlertBannerResources {
  ALERT_BANNER_READ = 'alert-banner:read',
  ALERT_BANNER_SEARCH = 'alert-banner:search',
  ALERT_BANNER_CREATE = 'alert-banner:create',
  ALERT_BANNER_UPDATE = 'alert-banner:update',
  ALERT_BANNER_DELETE = 'alert-banner:delete',
  ALERT_BANNER_ARCHIVE = 'alert-banner:archive',
}

export const ALERT_BANNER_ABAC_BASE: ISecurityConfig<ICoreUserJWTFlat, ICoreAlertBannerBase> = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [
      {
        effect: 'can',
        permissions: [
          {
            subject: LAUNCHPOINT_ALERT_BANNER_SCHEMA,
            actions: [ESecurityAlertBannerResources.ALERT_BANNER_READ, ESecurityAlertBannerResources.ALERT_BANNER_SEARCH],
          },
        ],
        conditions: [{ security_roles: { $in: ['${security_roles}'] } }, { security_roles: [] }],
      },
    ],
    [ELaunchpointUserRoles.USER]: [],
    [ELaunchpointUserRoles.SUPER_ADMIN]: [
      {
        effect: 'can',
        permissions: [{ subject: LAUNCHPOINT_ALERT_BANNER_SCHEMA, actions: ['manage'] }],
      },
    ],
  },
};
