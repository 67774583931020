<div class="flex justify-center">
  <div class="mx-4 w-[1200px]">
    <div class="-mx-2 flex flex-wrap">
      @if (paginated?.length === 0) {
        <div class="w-full text-center">
          <h3 class="text-4xl">
            @if (loading) {
              Loading...
            } @else {
              No Blogs. Come Back Soon!
            }
          </h3>
        </div>
      }

      @for (blog of paginated; track blog?._id; let i = $index) {
        <div class="mb-4 w-full px-2 sm:w-1/2 lg:w-1/3">
          <sd-blog-card [blog]="blog" />
        </div>
      }
    </div>

    <div class="w-full">
      <div class="mt-10 flex items-center justify-center space-x-4">
        <button
          [disabled]="!hasPrevPage"
          (click)="_LaunchpointBlogService.prevPage()"
          class="flex h-10 w-10 items-center justify-center rounded-sm bg-white text-gray-600 disabled:hover:opacity-70"
          [ngClass]="{
            'pointer-events-none opacity-70': !hasPrevPage,
          }"
        >
          <i class="fa-solid fa-angle-left"></i>
        </button>

        <button
          *ngFor="let page of pages"
          (click)="_LaunchpointBlogService.setPage(page)"
          class="flex h-10 w-10 items-center justify-center rounded-sm bg-white text-gray-600"
          [ngClass]="{
            'text-primary bg-blue-100': currentPage === page,
          }"
        >
          {{ page }}
        </button>

        <button
          (click)="_LaunchpointBlogService.nextPage()"
          [disabled]="!hasNextPage"
          class="flex h-10 w-10 items-center justify-center rounded-sm bg-white text-gray-600 disabled:hover:opacity-70"
          [ngClass]="{
            'pointer-events-none opacity-70': !hasNextPage,
          }"
        >
          <i class="fa-solid fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
