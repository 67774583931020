import { Directive, Input } from '@angular/core';
import { ICoreUserParamsChangeEmail } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { loggedInUserChangeEmail, loggedInUserChangePassword } from '../../../../auth';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../../../../auth/+state/auth-base.component';
import { ICoreUserParamsChangePasswordNOUserId } from './update-email-password-base.component';

@Directive()
export class LaunchpointCoreClientUpdateEmailPasswordSmartUserBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent {
  // constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig, public _Store: Store) {
  //   super(_config, _Store);
  // }

  @Input() showSeparators = true;

  dispatchEmailChange(event: ICoreUserParamsChangeEmail) {
    this._Store.dispatch(
      loggedInUserChangeEmail({
        data: event,
      })
    );
  }
  async dispatchPasswordChange(event: ICoreUserParamsChangePasswordNOUserId) {
    const user = await firstValueFrom(this.user$);
    this._Store.dispatch(loggedInUserChangePassword({ data: { ...event, user_id: user._id } }));
  }
}
