<div class="card shadow min-h-500px w-lg-500px w-md-400px w-300px">

  <button [routerLink]="['auth', 'login']" routerLinkActive="router-link-active" type="button"
    class="btn btn-icon text-hover-primary ps-10 pt-7" ngbTooltip="Back">
    <i class="bi bi-arrow-left fs-3 me-2"></i>
  </button>

  <div class="p-15 p-md-20 p-lg-20 pt-10">


    <form class="form w-100 mb-13" *ngIf="form" [formGroup]="form">

      <div class="text-center mb-15">
        <h1 class="fw-bold mb-3">{{ _config?.verify?.title || 'One Time Code' }}</h1>

        <div class="text-muted fs-6" *ngIf="_config?.verify?.description">
          {{ _config?.verify?.description }}
          <span class="text-dark fw-semibold">{{ email ?? phone }}</span>
        </div>
      </div>

      <ng-container *ngIf="error$ | async as error">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">{{ error?.error?.message }}</div>
        </div>
      </ng-container>

      <div class="mb-10">
        <launchpoint-otp-input [size]="_config?.verify?.code_length ?? 6" (changed)="changed($event)"
          (pasteEvent)="paste($event)" (enterEvent)="enter($event)" />
      </div>

      <div class="d-flex flex-center mt-10">
        <button type="submit" (click)="submit()" [disabled]="form.controls.token.errors || form.controls.email.errors"
          class="btn btn-lg btn-primary w-100 fw-bold ms-2">
          <ng-container *ngIf="(loading$ | async) === false">
            <span class="indicator-label text-white">{{ _config?.verify?.button ?? 'Verify'}}</span>
          </ng-container>
          <ng-container *ngIf="loading$ | async">
            <span class="indicator-label" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
        </button>
      </div>

    </form>

    <div class="text-center fw-semibold fs-6">
      <span class="text-muted me-1">Didn't get the code?</span>
      <a class="link-primary me-1" [ngClass]="{ 'text-muted': disabled$ | async }"
        ngbTooltip="Please wait about 30 seconds before requesting a new code" (click)="resendCode()">
        Resend
      </a>
    </div>

  </div>
</div>