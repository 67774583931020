import { Injectable } from '@angular/core';
import { authLocalStorageToken, IMediaService } from '@launchpoint/core-client';
import { IAuthenticationResponse } from '@launchpoint/core-types';

@Injectable({ providedIn: 'root' })
export class AuthStorageService implements IMediaService {
  // constructor() {}

  get tokens(): IAuthenticationResponse | null {
    try {
      const check: string = localStorage.getItem(authLocalStorageToken) || '';
      if (check != '') {
        const data = JSON.parse(check);
        return data;
      } else return null;
    } catch (error) {
      console.error('AuthStorageService', error);
      return error;
    }
  }

  setTokens(auth: IAuthenticationResponse) {
    return localStorage.setItem(authLocalStorageToken, JSON.stringify(auth));
  }

  removeTokens() {
    localStorage.removeItem(authLocalStorageToken);
  }
}
