<div class="modal-header justify-content-end">
  <h4 class="modal-title">{{ blog ? 'Update' : 'Create' }} Blog</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>

<div class="modal-body">
  <div class="d-flex justify-content-between flex-column">
    <form class="form-group" [formGroup]="form">
      <div class="mb-5 px-1">
        <label for="title" class="fw-semibold required mb-1 me-5">Title</label>

        <input type="text" name="title" formControlName="title" class="form-control" placeholder="Title" />
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Title is required',
            control: form.controls.title,
          }"
        ></ng-container>
      </div>

      <div class="mb-5 px-1">
        <div class="d-flex align-items-center mb-1">
          <label for="description" class="fw-semibold mb-1 me-5">Description</label>
        </div>
        <input type="description" name="description" formControlName="description" class="form-control" />
      </div>

      <div class="mb-5 px-1">
        <div class="d-flex align-items-center mb-1">
          <label for="category" class="fw-semibold required mb-1 me-5">Category</label>
        </div>

        <launchpoint-blog-category-search-ahead [selected]="blog?.category" (selectedCategory)="setCategory($event)" />
      </div>

      <div class="mb-5 px-1">
        <label class="form-label fw-semibold me-5">Active</label>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" formControlName="active" />
        </div>
      </div>
    </form>

    <div class="d-flex align-items-center justify-content-end modal-footer px-0 pb-0">
      <button class="btn btn-secondary mx-4 mb-2 mt-5" (click)="close()">Cancel</button>
      <button type="submit" (click)="submit()" [disabled]="form.invalid || form.pristine" class="btn btn-primary mb-2 mt-5">
        <span *ngIf="(loading$ | async) === false" class="indicator-label">
          {{ blog ? 'Save' : 'Create' }}
        </span>
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label">
            Please wait...
            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </ng-container>
      </button>
    </div>
  </div>

  <ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <span role="alert">{{ message }}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
