import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoreUserSearchQuery } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';

@Directive()
export abstract class UserTableHeaderBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() query: ICoreUserSearchQuery;

  public form: UntypedFormGroup;

  // accountTypes = Object.values(EUserAccountTypes)

  @Output() runQueries = new EventEmitter<ICoreUserSearchQuery>();

  constructor(private fb: UntypedFormBuilder, public _router: Router, public _route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      search: [this.query.search || ''],
      // account_types: [this.query.account_types],
      // account_ids: [this.query.account_ids || []],
    });
  }

  get search() {
    return this.form.get('search') as UntypedFormControl;
  }

  // get account_types() {
  //   return this.form.get('account_types') as UntypedFormControl;
  // }

  // get account_ids() {
  //   return this.form.get('account_ids') as UntypedFormControl;
  // }

  // resetFilters(){
  //   // if(this.account_types?.value){
  //   //   console.log('this.account_types?.value',this.account_types?.value)
  //   // }
  //   if(this.search.value){
  //     console.log('this.account_types?.value',this.account_types?.value)
  //   }
  //   if(this.account_types?.value || this.search.value){
  //     this.account_types.setValue(null);
  //     this.search.setValue('')
  //     this.runQuery();
  //   }
  // }

  // accountTypeSelected(account_type: EUserAccountTypes): boolean{
  //   if(this.query.account_types){
  //    return this.query?.account_types.includes(account_type)
  //   }
  //   return false
  // }

  // setAccountType(account_type: string | null) {
  //   // if(account_type){
  //   //   this.account_types.setValue([account_type]);
  //   // } else {
  //   //   this.account_types.setValue(null);
  //   // }
  //   this.runQuery();
  // }

  runQuery() {
    const userQuery: ICoreUserSearchQuery = { search: this.search.value };
    this.runQueries.emit(userQuery);
  }
}
