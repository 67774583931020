import { Injectable, OnDestroy, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointStepperTabService implements OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  /**
   * This will be set with the init method
   */
  totalSteps = 5;
  currentStep$ = signal(1);
  // action = signal<{ action: 'saveAndNext' }>(null);
  firstStep = 1;
  route = inject(ActivatedRoute);
  router = inject(Router);
  params = false;

  /**
   * @optional
   * @default 1
   * @param totalSteps = 1 total number of steps so the counter wont excceed the total number
   *
   *
   * @optional
   * @default 1
   * @param currentStep  Set the current step on init to something other than 1
   *
   * @optional
   * @default 1
   * @param firstStep  Set the first step to be 0 or 1 depending on your needs
   */
  init({ totalSteps = 1, currentStep = 1, firstStep = 1, params = false }) {
    this.totalSteps = totalSteps;
    this.currentStep$.set(currentStep);
    this.firstStep = firstStep;
    this.params = params;
    if (params === true) {
      this.initParams();
    }
  }

  initParams() {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (params) => {
        let step = params?.step;
        // console.log('params', params);
        if (step) {
          step = Number(step);
          // Make sure isn't Nan
          if (typeof step === 'number') {
            //
            if (step <= this.totalSteps && step >= this.firstStep) {
              //
              this.setStep(step);
            }
          }
        }
      },
    });
  }

  setStep(step: number) {
    if (this.params && this.currentStep$() !== step) {
      this.router.navigate([], { relativeTo: this.route, queryParams: { step }, queryParamsHandling: 'merge' });
    }
    this.currentStep$.set(step);
  }

  nextStep() {
    const nextStep = this.currentStep$() + 1;
    if (nextStep > this.totalSteps) {
      return;
    }
    if (this.params) {
      this.router.navigate([], { relativeTo: this.route, queryParams: { step: nextStep }, queryParamsHandling: 'merge' });
    } else {
      this.setStep(nextStep);
    }
  }

  prevStep() {
    const prevStep = this.currentStep$() - 1;
    if (prevStep === this.currentStep$()) {
      return;
    }
    if (this.params) {
      this.router.navigate([], { relativeTo: this.route, queryParams: { step: prevStep } });
    } else {
      this.setStep(prevStep);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
