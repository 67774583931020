export enum ELaunchpointFormTrendIndicator {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  NEUTRAL = 'Neutral',
  VARIABLE = 'Variable',
}

// Define the TypeScript interface for the PerformanceMetrics.
export interface ILaunchpointDynamicFormPerformanceMetrics {
  /**
   * Provides detailed guidance on how the trend should be interpreted in terms of business impact
   *
   * @default ELaunchpointFormTrendIndicator.POSITIVE
   */
  trend_indicator: ELaunchpointFormTrendIndicator;
  /**
   * Number is positive or negative based on the over/under of this number
   * i.e. anything over 1 is considered positve, anything under 1 is negative.
   * @default 0
   */
  threshold_value: number;
  // Uncomment the following lines if you decide to include impact_level in your schema.
  // impact_level: number;
}
