<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ topic ? 'Update' : 'Create' }} Topic</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body px-10">
  <form [formGroup]="form">
    <div class="mb-10 form-group">
      <label class="fw-bold required">Title</label>
      <div class="input-group">
        <input type="text" class="form-control" formControlName="topic" placeholder="Title" />
      </div>
    </div>
    <div class="mb-10 form-group">
      <label class="fw-bold">Description</label>
      <div class="input-group">
        <textarea type="text" class="form-control min-h-150px" formControlName="description"
          placeholder="Description"></textarea>
      </div>
    </div>

    <div class="mb-5 px-3 d-flex align-items-center">
      <label class="form-label fw-semibold me-5">Public</label>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" formControlName="topic_type" />
      </div>
    </div>

  </form>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button *ngIf="this.topic" type="button" class="btn btn-sm btn-icon me-3 underline text-danger fw-bold"
    (click)="remove()">Delete</button>
  <div>
    <button type="button" class="btn btn-sm btn-light me-3" (click)="close()">Cancel</button>
    <button type="button" class="btn btn-sm btn-primary" (click)="save()">Save</button>
  </div>
</div>