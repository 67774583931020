import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LaunchpointCoreClientProfilePhoneBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-phone',
  templateUrl: './profile-phone.component.html',
})
export class LaunchpointCoreWebProfilePhoneComponent extends LaunchpointCoreClientProfilePhoneBaseComponent {
  constructor(public fb: FormBuilder) {
    super(fb);
  }
}
