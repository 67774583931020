import { Type as TypeTransformer } from 'class-transformer';
import { ArrayNotEmpty, IsArray, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ELaunchpointFormsLogicType } from '../../dynamic-forms.constants';
import { ILaunchpointDynamicFormGroupQuestion } from './questions/form-groups-questions.interface';

/**
 * Represents a group of form questions that are displayed together on a single page.
 */
export class ILaunchpointDynamicFormGroup {
  _id?: string;
  /** The title of the group, often used as a heading in the form. */
  title: string;
  /** A description or instructions for the group of questions. */
  description?: string;
  /** An array of questions that belong to this group. */
  questions?: ILaunchpointDynamicFormGroupQuestion[];
  /** Condition that determines when to transition to the next group of questions. */
  next_group_condition?: ILaunchpointDynamicFormGroupNextGroupCondition;
}

/**
 * Defines the condition for transitioning between groups in a multi-step form.
 */
export interface ILaunchpointDynamicFormGroupNextGroupCondition {
  /** The type of condition to check before moving to the next group. */
  condition_type: ELaunchpointFormsLogicType;
  /** The value that triggers the transition to the next group. */
  condition_value: any;
  /** The ID of the group to transition to if the condition is met. */
  target_group_id: string;
}
/**
 * This object is to set the params to create one object. Required feilds need to be listed.
 */
export class ILaunchpointDynamicFormGroupParamsCreateOne implements Partial<ILaunchpointDynamicFormGroup> {
  _id?: never;
  @IsString({ message: 'title must be a string' })
  title!: string | undefined;
  @IsOptional()
  @IsString({ message: 'description must be a string' })
  description?: string | undefined;
}

/**
 * This object is to set the params to update one object, it mandates the _id so it can be updated. Required feilds need to be listed.
 */
export class ILaunchpointDynamicFormGroupParamsUpdateOne implements Partial<ILaunchpointDynamicFormGroup> {
  @IsString({ message: '_id must be a string' })
  _id!: string;

  /**
   * Title is required so that we don't overwrite any info.
   */
  @IsString({ message: 'title must be a string' })
  title!: string | undefined;
  @IsOptional()
  @IsString({ message: 'description must be a string' })
  description?: string | undefined;
}

/**
 * Create one will just pass an array with one object
 */
export class ILaunchpointDynamicFormGroupParamsCreate {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;
  @IsArray({ message: 'groups must be an array' })
  @ArrayNotEmpty({ message: 'groups array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormGroupParamsCreateOne' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupParamsCreateOne) // Use the correct type for your logic
  groups!: ILaunchpointDynamicFormGroupParamsCreateOne[];
}
/**
 * Update one will just pass an array with one object
 */
export class ILaunchpointDynamicFormGroupParamsUpdate {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;
  @IsArray({ message: 'groups must be an array' })
  @ArrayNotEmpty({ message: 'groups array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormGroupParamsUpdateOne' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupParamsUpdateOne) // Use the correct type for your logic
  groups!: ILaunchpointDynamicFormGroupParamsUpdateOne[];
}

export class ILaunchpointDynamicFormGroupParamsReorder {
  /**
   * _id of the rate schedule you want to add this deductible to.
   */
  @IsString({ message: '_id must be a string' })
  _id: string;

  /**
   * Added _id required to the group so that we don't lose it when reordering. We need the _ids to remain the same for
   * people who have already answered the questions.
   */
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormGroup)
  group: ILaunchpointDynamicFormGroup & { _id: string };
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  previousIndex: number;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  currentIndex: number;
}

/**
 * Delete one will just pass an array with one _id
 */
export class ILaunchpointDynamicFormGroupParamsDelete {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;
  @IsArray({ message: 'group_ids must be an array' })
  @ArrayNotEmpty({ message: 'group_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the group_ids array must be a string' })
  group_ids!: string[];
}
