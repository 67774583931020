<div class="post d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-xxl px-0">
    <!--begin::Connections App- View Connection-->
    <div class="row g-7 px-0">
      <!--begin::Connection groups-->
      <div class="col-lg-6 col-xl-3 px-0">
        <!--begin::Connection group wrapper-->
        <!-- <launchpoint-connections></launchpoint-connections> -->
        <!--end::Connection group wrapper-->
      </div>
      <!--end::Connection groups-->
      <!--begin::Search-->
      <div class="col-lg-6 col-xl-3 px-0">
        <!--begin::Connections-->
        <!-- <launchpoint-connections-search></launchpoint-connections-search> -->
        <!--end::Connections-->
      </div>
      <!--end::Search-->
      <!--begin::Content-->
      <div class="col-xl-6">
        <!--begin::Connections-->
        <router-outlet></router-outlet>
        <!--end::Connections-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Connections App- View Connection-->
  </div>
  <!--end::Container-->
</div>
