import { Observable } from 'rxjs';
import {
  ICoreUser,
  IUserAccountsParamsCreate,
  IUserAccountsParamsDelete,
  IUserAccountsParamsInvite,
  IUserAccountsParamsSet,
  IUserAccountsParamsUpdate,
} from '../../user-company-account.interface';

export interface IUserAccountsController {
  /**
   * @Post()
   * @Version('1')
   * @param body
   *
   * Takes the body and adds access to an account to a user.
   */
  createAccounts(body: IUserAccountsParamsCreate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * @Post()
   * @Version('1')
   * @param body
   *
   * Takes the body and adds access to an account to a user.
   *
   * @deprecated
   */
  createAccountsAdmin(body: IUserAccountsParamsCreate): Promise<ICoreUser> | Observable<ICoreUser>;

  /**
   *
   *  @Post('invite')
   * @Version('1')
   * @param body
   */
  inviteUserToCompany(body: IUserAccountsParamsInvite): Promise<ICoreUser> | Observable<ICoreUser>;

  /**
   * @Put()
   * @Version('1')
   *
   * Updates a user's access to an account.
   */
  updateAccounts(body: IUserAccountsParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * @Put()
   * @Version('1')
   *
   * Updates a user's access to an account.
   */
  updateUserSelectedAccount(body: IUserAccountsParamsSet): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * @Put('remove')
   * @Version('1')
   *
   * Removes an account from a user so they can no longer access it.
   */
  removeAccount(body: IUserAccountsParamsDelete): Promise<ICoreUser> | Observable<ICoreUser>;
}
