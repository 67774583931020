import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ILaunchpointCorePinpointApplication,
  ILaunchpointCorePinpointApplicationController,
  ILaunchpointCorePinpointApplicationParamsCreate,
  ILaunchpointCorePinpointApplicationParamsDelete,
  ILaunchpointCorePinpointApplicationParamsList,
  ILaunchpointCorePinpointApplicationParamsUpdateSettings,
  ILaunchpointCorePinpointApplicationSearchPayload,
  ILaunchpointCorePinpointApplicationSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../../notifications/interfaces/core-notifications.interface';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';

@Injectable()
export class PinpointApplicationHTTPService extends LaunchpointSecurityUserIdHeaderService implements ILaunchpointCorePinpointApplicationController {
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  routes = {
    delete: '/delete',
    search: '/search',
    sync: '/sync',
  };

  constructor(
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig,
    public _Store: Store
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'pinpoint/application',
    });
  }
  search(body: ILaunchpointCorePinpointApplicationSearchPayload): Observable<ILaunchpointCorePinpointApplicationSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointApplicationSearchResults>(this.API_URL + this.routes.search, body, {
          headers,
        });
      })
    );
  }
  getById(_id: string): Observable<ILaunchpointCorePinpointApplication> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointCorePinpointApplication>(this.API_URL + '/' + _id, { headers });
      })
    );
  }
  create(body: ILaunchpointCorePinpointApplicationParamsCreate): Observable<ILaunchpointCorePinpointApplication> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointApplication>(this.API_URL, body, { headers });
      })
    );
  }
  update(body: ILaunchpointCorePinpointApplicationParamsUpdateSettings): Observable<ILaunchpointCorePinpointApplication> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointApplication>(this.API_URL, body, { headers });
      })
    );
  }
  delete(body: ILaunchpointCorePinpointApplicationParamsDelete): Observable<ILaunchpointCorePinpointApplication> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointApplication>(this.API_URL + this.routes.delete, body, { headers });
      })
    );
  }
  sync(body: ILaunchpointCorePinpointApplicationParamsList): Observable<{ success: boolean }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<{ success: boolean }>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
}
