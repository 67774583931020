import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LaunchpointSecurityModule, UserNotificationsHTTPService } from '@launchpoint/core-client';
import { NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LaunchpointCircleInitialsModule } from '../../../features/components/circle-initials/launchpoint-circle-initials.module';
import { LaunchpointDemoSharedLayoutModule } from '../../demo-shared-layout/layout.module';
import { LAUCNHPOINT_DEMO_1_LAYOUT_COMPONENTS } from './components';
import { LaunchpointDemo1LayoutComponent } from './layout.component';
import { TranslationModule } from '../../i18n/translation.module';
import { ExtrasModule } from '../../core/partials/layout/extras/extras.module';
import { EngagesModule } from '../../core/partials/layout/engages/engages.module';
import { DropdownMenusModule } from '../../core/partials/content/dropdown-menus/dropdown-menus.module';
import { ThemeModeModule } from '../../core/partials/layout/theme-mode-switcher/theme-mode.module';

const MODULES = [
  CommonModule,
  RouterModule,
  TranslationModule,
  InlineSVGModule,
  NgbDropdownModule,
  NgbProgressbarModule,
  ExtrasModule,
  EngagesModule,
  DropdownMenusModule,
  NgbTooltipModule,
  TranslateModule,
  ThemeModeModule,
  LaunchpointDemoSharedLayoutModule,
  LaunchpointSecurityModule,
  LaunchpointCircleInitialsModule,
];

@NgModule({
  declarations: [...LAUCNHPOINT_DEMO_1_LAYOUT_COMPONENTS, LaunchpointDemo1LayoutComponent],
  imports: [...MODULES],
  exports: [...LAUCNHPOINT_DEMO_1_LAYOUT_COMPONENTS, LaunchpointDemo1LayoutComponent, ...MODULES],
  providers: [UserNotificationsHTTPService],
})
export class LaunchpointDemo1LayoutModule {
  //  {
  //     provide: APPLICATION_CONFIG_TOKEN,
  //     useValue: {
  //       environment,
  //       layoutConfig: {
  //         logo_dark: './assets/media/logos/launchpoint-logo-full-darkmode.svg',
  //         logo_light: './assets/media/logos/launchpoint-logo-full.svg',
  //       },
  //     },
  //   }
}
