import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageFeatureKey, MessagesEntityAdaptor, MessagesState } from '../reducers/messages.reducer';

const selectAllMessagesState = createFeatureSelector<MessagesState>(MessageFeatureKey);

/**
 * ROOT STATE
 */
export const getAllMessagesState = createSelector(selectAllMessagesState, (state: MessagesState) => state);

export const selectMessagesPagination = createSelector(getAllMessagesState, (state: MessagesState) => state.pagination);
export const selectMessagesQuerySort = createSelector(getAllMessagesState, (state: MessagesState) => state.querySort);
export const selectMessagesQuery = createSelector(getAllMessagesState, (state: MessagesState) => state.query);
export const selectMessagesSuccess = createSelector(getAllMessagesState, (state: MessagesState) => state.success_message);
export const selectMessagesLoading = createSelector(getAllMessagesState, (state: MessagesState) => state.loading);
export const selectMessagesLoaded = createSelector(getAllMessagesState, (state: MessagesState) => state.loaded);
export const selectMessagesError = createSelector(getAllMessagesState, (state: MessagesState) => state.error);

export const selectMessagesAnalytics = createSelector(getAllMessagesState, (state: MessagesState) => state.analytics);

export const getAllMessagesStateEntities = createSelector(selectAllMessagesState, (state: MessagesState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = MessagesEntityAdaptor.getSelectors(selectAllMessagesState);

// select the array of Messages ids
export const selectMessagesIds = selectIds;

// select the dictionary of Messages entities
export const selectMessagesEntities = selectEntities;

// select the array of Messagess
export const selectMessages = selectAll;

// select the total entity count
export const selectMessagesTotal = selectTotal;

export const selectMessagesId = createSelector(getAllMessagesState, (state: MessagesState) => state?.selected_message_id);

export const selectSelectedMessage = createSelector(
  getAllMessagesState,
  selectMessagesId,
  (state: MessagesState, selected_message_id) => state.entities[selected_message_id]
);
export const selectSelectedMessageSuccess = createSelector(
  getAllMessagesState,
  selectMessagesId,
  (state: MessagesState, selected_message_id) => state.entities[selected_message_id]?.success_message
);
export const selectSelectedMessageError = createSelector(
  getAllMessagesState,
  selectMessagesId,
  (state: MessagesState, selected_message_id) => state.entities[selected_message_id]?.error
);
export const selectSelectedMessageLoading = createSelector(
  getAllMessagesState,
  selectMessagesId,
  (state: MessagesState, selected_message_id) => state.entities[selected_message_id]?.loading
);
