import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ILaunchpointSearchFilterDates } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../components/base/base.component';

@Directive()
export abstract class LaunchpointFormControlDateRangeBaseComponent extends LaunchpointCoreClientBaseComponent {
  /**
   * The selected checkboxes.
   */
  @Input() selected: ILaunchpointSearchFilterDates;

  /**
   * This is not required for the form control BUT it does give the parent access to more data from the selected object if needed.
   * Pattern is to add `Changed` to the end of the value
   */
  @Output() selectedChanged = new EventEmitter<ILaunchpointSearchFilterDates>();

  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChanged: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
