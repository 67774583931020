import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';

@Directive()
export abstract class CompanyBillingBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  form: UntypedFormGroup;
  @Input() customer_id: { id: string; name: string; email: string; default_source: any };
  @Input() name: string;

  @Output() addBankAccount = new EventEmitter<{ stripe_customer_id: string; source: string }>();

  constructor(public fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      company_name: [this.customer_id.name, Validators.compose([Validators.required])],
      email: [this.customer_id.email, [Validators.required, Validators.email, Validators.maxLength(255)]],
      // phone: [this.clientEntity?.client.phone, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
    });
  }

  get company_name(): AbstractControl {
    return this.form.get('company_name') as UntypedFormControl;
  }

  get email(): AbstractControl {
    return this.form.get('email') as UntypedFormControl;
  }

  updateCustomer(token: string) {
    this.addBankAccount.emit({ stripe_customer_id: this.customer_id.id, source: token });
  }

  submit() {
    // this.message = null;
    // this.loading = true;
    // if (this.form.invalid) {
    //   return;
    // }
    // const obj: IClientParamsUpdate = {
    //   account_id: this?.id,
    //   company_name: this.company_name.value,
    //   account_owner: this.account_owner.value,
    //   phone: this.phone.value,
    // };
    // this._Store.dispatch(updateClient({data: obj}))
  }
}
