import { Injectable } from '@angular/core';
import { ICoreUser } from '@launchpoint/core-types';
import { BehaviorSubject } from 'rxjs';
import { LaunchpointLayoutMenu } from '../../interfaces/menu.interfaces';
import { LaunchpointTopbarConfig } from '../../interfaces/topbar.interface';

@Injectable({
  providedIn: 'root',
})
export class LayoutDataService {
  private asideMenu: BehaviorSubject<LaunchpointLayoutMenu> = new BehaviorSubject<LaunchpointLayoutMenu>(undefined);
  public asideMenu$ = this.asideMenu.asObservable();

  private headerMenu: BehaviorSubject<LaunchpointLayoutMenu> = new BehaviorSubject<LaunchpointLayoutMenu>(undefined);
  public headerMenu$ = this.headerMenu.asObservable();

  private topbarConfig: BehaviorSubject<LaunchpointTopbarConfig> = new BehaviorSubject<LaunchpointTopbarConfig>(undefined);
  public topbarConfig$ = this.topbarConfig.asObservable();

  private user: BehaviorSubject<ICoreUser> = new BehaviorSubject<ICoreUser>(undefined);
  public user$ = this.user.asObservable();

  init(data: { setAside: LaunchpointLayoutMenu; setHeader: LaunchpointLayoutMenu; setTopbar: LaunchpointTopbarConfig; setUser: ICoreUser }) {
    const { setAside, setHeader, setTopbar, setUser } = data;
    this.setAside(setAside);
    this.setHeader(setHeader);
    this.setTopbar(setTopbar);
    this.setUser(setUser);
  }

  setAside(config: LaunchpointLayoutMenu) {
    this.asideMenu.next(config);
  }

  setHeader(config: LaunchpointLayoutMenu) {
    this.headerMenu.next(config);
  }

  setTopbar(config: LaunchpointTopbarConfig) {
    this.topbarConfig.next(config);
  }

  setUser(user: ICoreUser) {
    this.user.next(user);
  }
}
