import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoreCompanySearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { updateAllCompaniesPagination, updateAllCompaniesQuery } from '../../+state/actions/company.actions';
import { CompanyStateBaseComponent } from '../../+state/company-state.base-component';
import { LaunchpointCompanyEntityState } from '../../+state/interfaces/company.interface';

@Directive()
export abstract class CompanyTableBaseComponent extends CompanyStateBaseComponent {
  @Input() query: ICoreCompanySearchQuery;
  @Input() pagination: IQueryPageination = { limit: 100000, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() delete = new EventEmitter<string>();

  constructor(
    public _Store: Store,
    public _router: Router,
    public _route: ActivatedRoute
  ) {
    super();
  }

  account_type(entity: LaunchpointCompanyEntityState) {
    return entity?.company?.account_type as string;
  }

  updateQuery(search: string) {
    this._Store.dispatch(updateAllCompaniesQuery({ query: { search } }));
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllCompaniesPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllCompaniesPagination({ pagination: { ...pagination, limit } }));
  }

  sortData(event) {
    const sortedData: IQuerySort = {
      active: event.active,
      direction: event.direction,
    };
    this.sortDataEmitter.emit(sortedData);
  }

  deleteById(id: string) {
    this.delete.emit(id);
  }
}
