import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import * as WebhooksActions from '../actions/webhooks.actions';
import * as WebhooksSelectors from '../selectors/webhooks.selectors';

@Injectable()
export class GetSelectedWebhookGuard implements CanActivate {
  constructor(private _Store: Store, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.setSelectedWebhookId(route.params.id).pipe(
      switchMap((loaded) => this.selectedWebhookInState(route.params.id)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          WebhooksActions.getWebhookByIdFailure({
            webhook_id: route.params.id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }

  setSelectedWebhookId(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(WebhooksSelectors.selectWebhooksId).pipe(
      switchMap((selected) => {
        if (selected !== _id) {
          this._Store.dispatch(WebhooksActions.getWebhookById({ webhook_id: _id }));
          return of(false);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  selectedWebhookInState(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(WebhooksSelectors.selectWebhooksEntities).pipe(
      switchMap((entities) => {
        if (!entities[_id]) {
          return this.dispatchWebhook(_id);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  dispatchWebhook(_id: string): Observable<boolean> {
    if (!_id) {
      return of(false);
    }
    this._Store.dispatch(WebhooksActions.getWebhookById({ webhook_id: _id }));
    return of(false);
  }
}
