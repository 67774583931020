import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookEventController,
  ICoreActivityHistoryWebhookEventSearchPayload,
  ICoreActivityHistoryWebhookEventSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN, LaunchpointCoreClientActivityHistoryConfig } from '../../activity-history/interface/interface';

@Injectable()
export class CoreActivityHistoryWebhookEventHTTPService
  extends LaunchpointSecurityUserIdHeaderService
  implements ICoreActivityHistoryWebhookEventController
{
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'activity-history/wh-events';

  constructor(
    private http: HttpClient,
    @Inject(ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN) public _config: LaunchpointCoreClientActivityHistoryConfig,
    public _Store: Store
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.base_url,
      version: 1,
      route: this.BASE_URL,
    });
  }
  search(params: ICoreActivityHistoryWebhookEventSearchPayload): Observable<ICoreActivityHistoryWebhookEventSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreActivityHistoryWebhookEventSearchResults>(this.API_URL + '/search', params, { headers });
      })
    );
  }
  getById(id: string): Observable<ICoreActivityHistoryWebhookEvent> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreActivityHistoryWebhookEvent>(this.API_URL + '/' + id, { headers });
      })
    );
  }
  delete(id: string): Observable<ICoreActivityHistoryWebhookEvent> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreActivityHistoryWebhookEvent>(this.API_URL + '/delete/' + id, { headers });
      })
    );
  }
  // create(params: ICoreActivityHistoryWebhookEventParamsCreate): Observable<ICoreActivityHistoryWebhookEvent> {
  //   return this.getSelectedUserIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.post<ICoreActivityHistoryWebhookEvent>(this.API_URL, params, { headers });
  //     })
  //   );
  // }
  // update(params: ICoreActivityHistoryWebhookEventParamsUpdate): Observable<ICoreActivityHistoryWebhookEvent> {
  //   return this.getSelectedUserIdHeaders.pipe(
  //     switchMap((headers) => {
  //       return this.http.put<ICoreActivityHistoryWebhookEvent>(this.API_URL, params, { headers });
  //     })
  //   );
  // }
}
