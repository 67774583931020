import { Directive, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ICoreAlertBanner } from '@launchpoint/core-types';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { searchAllAlertBanners } from '../+state/actions/alert-banner.actions';
import { LaunchpointCoreClientAlertBannerStateBaseComponent } from '../+state/alert-banner-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerBaseComponent extends LaunchpointCoreClientAlertBannerStateBaseComponent implements OnInit {
  fb = inject(FormBuilder);

  form_control = new FormControl('');

  ngOnInit(): void {
    this.buildForm();
    this._Store.dispatch(searchAllAlertBanners());
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery({ search }),
      error: (error) => console.log('ERROR', error),
    });
  }

  buildForm() {
    // console.log('override me')
  }

  refresh() {
    this.ngOnInit();
  }

  isDisplaying(banner: ICoreAlertBanner): boolean {
    const now = new Date(new Date().toUTCString()).getTime();
    const start = new Date(banner?.display_range_start_date).getTime();
    const end = new Date(banner?.display_range_end_date).getTime();

    return now >= start && now <= end && banner.active;
  }

  getBannerCreatedBy(banner: ICoreAlertBanner): string {
    const user = banner?.user_id;

    let message = user?.profile?.first_name + ' ' + user?.profile?.last_name;

    if (!message) {
      message = user?.email;
    }

    return message;
  }
}
