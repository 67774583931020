import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LaunchpointCoreClientProfileConfig, LaunchpointCoreClientProfileDetailsBaseComponent, PROFILE_CONFIG_TOKEN } from '@launchpoint/core-client';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-core-web-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class LaunchpointCoreWebProfileDetailsComponent extends LaunchpointCoreClientProfileDetailsBaseComponent {
  constructor(public fb: FormBuilder, @Inject(PROFILE_CONFIG_TOKEN) public _config: LaunchpointCoreClientProfileConfig, public _Store: Store, ) {
    super(fb, _config, _Store);
  }
  determineBirthday(birthday_form_value:NgbDateStruct): Date {
    const dateTEst =new Date(birthday_form_value.year, (birthday_form_value.month-1), birthday_form_value.day);
    return dateTEst
  }
}
