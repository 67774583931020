<form [formGroup]="form">
  <div class="d-flex align-items-center justify-content-between px-7 py-5">
    <div class="fs-5 text-dark fw-bold">Filter Options</div>
  </div>

  <div class="separator border-gray-200"></div>

  <div class="mt-5 px-7">
    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Tags</label>
        <div>
          <i
            class="fs-4 bi bi-x-square cursor-pointer"
            *ngIf="form?.controls?.tags?.value?.length > 0"
            animateCss="tada"
            ngbTooltip="Clear tags"
            (click)="clearTags()"
          ></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-blog-tags-tagify (selectedTags)="setTags($event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Categories</label>
        <div>
          <i
            class="fs-4 bi bi-x-square cursor-pointer"
            *ngIf="form?.controls?.category?.value?.length > 0"
            animateCss="tada"
            ngbTooltip="Clear categories"
            (click)="clearCategories()"
          ></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-blog-category-tagify (selectedCategories)="setCategories($event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <label class="form-label fw-semibold">Active</label>

      <div class="menu-item mb-3">
        <launchpoint-boolean-filter (selected)="setValue('active', $event)" />
      </div>
    </div>
    <div class="mb-2 px-3">
      <label class="form-label fw-semibold">Featured</label>

      <div class="menu-item mb-3">
        <launchpoint-boolean-filter (selected)="setValue('featured', $event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <label class="form-label fw-semibold">Live</label>

      <div class="menu-item mb-3">
        <launchpoint-boolean-filter (selected)="setValue('live', $event)" />
      </div>
    </div>

    <div class="menu-item p-3">
      <label class="form-label fw-semibold">Release At</label>
      <launchpoint-date-range formControlName="release_at" [allOptions]="{ debug: false }" />
    </div>

    <div class="menu-item p-3">
      <label class="form-label fw-semibold">Created At</label>
      <launchpoint-date-range formControlName="created_at" [allOptions]="{ debug: false }" />
    </div>
  </div>

  <div class="rounded-bottom pb-3">
    <div class="d-flex justify-content-between align-items-center m-5 px-4">
      <div>
        <a class="text-dark text-hover-primary text-decoration-underline cursor-pointer" (click)="clearAll()"> Clear All </a>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="apply()">Apply</button>
      </div>
    </div>
  </div>
</form>
