import { ICoreUserJWTFlat } from '../auth/auth.user.interface';
import { ELaunchpointUserRoles, SECURITY_PERMISSIONS_DEFAULT_ROLE } from '../security.constants';
import { ISecurityConfig } from '../security.interface';
import { ICoreUser } from '../user-company-account.interface';
import { ESecurityCoreUserResources } from '../user/core/core-user.permissions.interface';
import { LAUNCHPOINT_USER_SCHEMA_NAME } from '../user/model/user.constants';

let userActions: string[] = [];
try {
  userActions = Object.values(ESecurityCoreUserResources).filter(
    (action) => ![ESecurityCoreUserResources.ADMIN_USER_EMAIL_SET, ESecurityCoreUserResources.ADMIN_USER_EMAIL_VERIFY].includes(action)
  );
} catch (error) {
  // console.error(error);
}

export let ABAC_AUTH_MODULE: ISecurityConfig<ICoreUserJWTFlat, ICoreUser> = { debug: true, acl: {} };

try {
  ABAC_AUTH_MODULE = {
    debug: false,
    acl: {
      [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [
        {
          effect: 'can',
          permissions: [
            {
              actions: userActions,
              subject: LAUNCHPOINT_USER_SCHEMA_NAME,
            },
          ],
          conditions: [{ _id: '${user_id}' }, { 'accounts.user_id': '${user_id}' }],
        },
      ],
      [ELaunchpointUserRoles.USER]: [],
      [ELaunchpointUserRoles.SUPER_ADMIN]: [
        {
          effect: 'can',
          permissions: [{ subject: LAUNCHPOINT_USER_SCHEMA_NAME, actions: ['manage'] }],
        },
      ],
    },
  };
} catch (error) {
  //
}
