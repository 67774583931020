import { Component, HostBinding, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  APP_AUTH_CONFIG_TOKEN,
  ICoreAuthConfig,
  LaunchpointCoreClientAuthFormService,
  LaunchpointCoreClientAuthRegisterBaseComponent,
} from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-auth-v1-registration',
  templateUrl: './auth-v1-registration.component.html',
  styleUrls: ['./auth-v1-registration.component.scss'],
})
export class LaunchpointCoreWebAuthV1RegistrationComponent extends LaunchpointCoreClientAuthRegisterBaseComponent {
  constructor(
    @Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig,
    public _LaunchpointCoreClientAuthFormService: LaunchpointCoreClientAuthFormService,
    public _route: ActivatedRoute
  ) {
    super(_config, _LaunchpointCoreClientAuthFormService, _route);
  }

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }
}
