import { AbstractControl } from '@angular/forms';

export class ConfirmEmailValidator {
  /**
   * Check matching Email with confirm Email
   * @param control AbstractControl
   */
  static MatchEmail(control: AbstractControl) {
    const email = control.get('new_email').value;
    const confirmEmail = control.get('confirm_new_email').value;

    if (email !== confirmEmail) {
      control.get('confirm_new_email').setErrors({ ConfirmEmail: true });
    } else {
      return null;
    }
  }
}
