import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LaunchpointSecurityModule, UserNotificationsHTTPService } from '@launchpoint/core-client';
import { NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LAYOUT_FEATURE_KEY, layoutReducers } from '../+state/reducers';
import { LaunchpointCircleInitialsModule } from '../../../features/components/circle-initials/launchpoint-circle-initials.module';
import { DropdownMenusModule } from '../../core/partials/content/dropdown-menus/dropdown-menus.module';
import { DrawersModule } from '../../core/partials/layout/drawers/drawers.module';
import { ExtrasModule } from '../../core/partials/layout/extras/extras.module';
import { ModalsModule } from '../../core/partials/layout/modals/modals.module';
import { ThemeModeModule } from '../../core/partials/layout/theme-mode-switcher/theme-mode.module';
import { AppLaunchpointBaseLayoutComponent } from './app-layout-core-base.component';
import { AsideMenuComponent } from './components/aside/aside-menu/aside-menu.component';
import { AppLaunchpointBaseAsideComponent } from './components/aside/aside.component';
import { AppLaunchpointBaseContentComponent } from './components/content/content.component';
import { AppLaunchpointBaseFooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header/header-menu/header-menu.component';
import { AppLaunchpointBaseHeaderComponent } from './components/header/header.component';
import { PageTitleComponent } from './components/header/page-title/page-title.component';
import { ScriptsInitComponent } from './components/scripts-init/scripts-init.component';
import { AppLaunchpointBaseToolbarComponent } from './components/toolbar/toolbar.component';
import { AppLaunchpointBaseTopbarComponent } from './components/topbar/topbar.component';
import { EngagesModule } from '../../core/partials/layout/engages/engages.module';
import { TranslationModule } from '../../i18n/translation.module';
import { LaunchpointDemoSharedLayoutModule } from '../../demo-shared-layout/layout.module';

const WIDGETS = [LaunchpointCircleInitialsModule];
@NgModule({
  declarations: [
    // LayoutComponent,
    AppLaunchpointBaseAsideComponent,
    AppLaunchpointBaseHeaderComponent,
    AppLaunchpointBaseContentComponent,
    AppLaunchpointBaseFooterComponent,
    ScriptsInitComponent,
    AppLaunchpointBaseToolbarComponent,
    AsideMenuComponent,
    AppLaunchpointBaseTopbarComponent,
    PageTitleComponent,
    HeaderMenuComponent,
    AppLaunchpointBaseLayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule, //.forChild(routes),
    LaunchpointSecurityModule,
    StoreModule.forFeature(LAYOUT_FEATURE_KEY, layoutReducers),
    InlineSVGModule.forRoot(),
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    ExtrasModule,
    ModalsModule,
    DrawersModule,
    DropdownMenusModule,
    TranslateModule,
    ThemeModeModule,
    TranslationModule,
    EngagesModule,
    LaunchpointDemoSharedLayoutModule,
    // LaunchpointSecurityModule,
    // LaunchpointCircleInitialsModule,
    ...WIDGETS,
  ],
  exports: [
    AppLaunchpointBaseAsideComponent,
    AppLaunchpointBaseHeaderComponent,
    AppLaunchpointBaseContentComponent,
    AppLaunchpointBaseFooterComponent,
    ScriptsInitComponent,
    AppLaunchpointBaseToolbarComponent,
    AsideMenuComponent,
    AppLaunchpointBaseTopbarComponent,
    PageTitleComponent,
    HeaderMenuComponent,
    AppLaunchpointBaseLayoutComponent,
  ],
  providers: [UserNotificationsHTTPService],
})
export class AppLayoutCoreWebModule {
  // /**
  //  *
  //  * @param _securityConfig
  //  * @returns
  //  */
  // static forRoot(_securityConfig: ILaunchpointSecurityModuleConfig): ModuleWithProviders<AppLayoutCoreWebModule> {
  //   return {
  //     ngModule: AppLayoutCoreWebModule,
  //     providers: [LayoutInitService, LayoutService, PageInfoService, AuthHTTPService],
  //   };
  // }
}
