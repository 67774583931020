import { ICoreActivityHistoryWebhook, IHttpException } from '@launchpoint/core-types';

export interface WebhooksEntityState {
webhook_id: string;
  webhook: ICoreActivityHistoryWebhook;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string;
}
