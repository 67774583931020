import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICoreUserProfileParamsUpdate, IHttpException } from '@launchpoint/core-types';
import { ofType } from '@ngrx/effects';
import { firstValueFrom, takeUntil } from 'rxjs';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { loggedInUserUpdateProfile, loggedInUserUpdateProfileSuccess } from '../../../user/auth/+state/actions/user/user.actions';
import { CompanySignUpStateBaseComponent } from './base/company-sign-up-state.base-component';

@Directive()
export abstract class LaunchpointCoreSignUpProfileDetailsBaseComponent extends CompanySignUpStateBaseComponent implements OnInit {
  public form: FormGroup<ControlsOf<Pick<ICoreUserProfileParamsUpdate, 'first_name' | 'last_name'>>>;
  completed = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() updated = new EventEmitter<any>();
  constructor() {
    super();
    this.form = this.fb.group({
      first_name: [],
      last_name: [],
    });
    // effect(() => this.submit(this._stepper.action()));
  }
  /**
   * Call on initForm from web/mobile components
   */
  ngOnInit() {
    this.initForm();
    this.actions$.pipe(ofType(loggedInUserUpdateProfileSuccess), takeUntil(this.destroy$)).subscribe({
      next: () => this._stepper.nextStep(),
    });
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        this.onError(error);
      },
    });
  } //loggedInUserUpdateProfileSuccess
  onError(error: IHttpException) {
    console.log('Override this onError, error, in the nativescript or web component');
  }
  /**
   * Call on init from web/mobile components
   */
  initForm() {
    this.userProfile$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user_profile) => {
        // if (user_profile) console.log({ user_profile });
        this.form.patchValue({
          first_name: user_profile?.first_name ?? null,
          last_name: user_profile?.last_name ?? null,
        });
      },
    });
  }

  submit(action?) {
    if (this._stepper.currentStep$() === 1 && this.completed === false) {
      // console.log(action);
      this.saveSettings();
    }
  }

  async saveSettings() {
    if (this.form.invalid) {
      console.log('this.form.invalid');
      return;
    } else {
      const { first_name, last_name } = this.form.value;
      // console.log('this.form.value', this.form.value);
      const profile_data: Pick<ICoreUserProfileParamsUpdate, 'first_name' | 'last_name'> = {
        first_name,
        last_name,
      };
      const user_id = await firstValueFrom(this.user_id$);
      if (user_id) {
        const user_being_edited_id = user_id;
        const data = { ...profile_data, user_id: user_being_edited_id };
        this._Store.dispatch(loggedInUserUpdateProfile({ data }));
        this.completed = true;
      }
    }
  }
}
