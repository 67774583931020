import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-feeds-widget4',
  templateUrl: './feeds-widget4.component.html',
})
export class FeedsWidget4Component {
  // constructor() {}
  // ngOnInit(): void {}
}
