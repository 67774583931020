import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationCreateBaseComponent, UserSelectors } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { NgbActiveModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { RawEditorOptions } from 'tinymce';
import { TIME_ZONE } from './time-zone';

@Component({
  selector: 'launchpoint-notifications-notification-create',
  templateUrl: './notification-create.component.html',
  styleUrls: ['./notification-create.component.scss'],
})
export class LaunchpointCoreWebNotificationCreateComponent extends LaunchpointCoreClientNotificationCreateBaseComponent {
  activeModal = inject(NgbActiveModal);

  active;
  disabled = true;
  timeZone = TIME_ZONE;

  apiKey = '1rbnh61kwd51fi6qluvod5exn2wsxmz2myg6cn9ilay6p8i2';
  config: RawEditorOptions = {
    selector: 'textarea',
    placeholder: 'Type here...',
    plugins: 'anchor autolink link lists image code advcode mergetags wordcount tinydrive visualchars',
    toolbar: 'mergetags | undo redo | blocks | bold italic strikethrough | link image | align bullist numlist | code visualchars', //backcolor insertfile
    quickbars_insert_toolbar: 'quickimage quicktable | mergetags',
    height: 250,
    skin: 'naked',
    icons: 'small',
    menubar: false,
    statusbar: false,
    mergetags_prefix: '{{',
    mergetags_suffix: '}}',
    mergetags_list: [
      {
        title: 'User',
        menu: [
          {
            value: 'User.UserId',
            title: 'A unique identifier for the user.',
          },
        ],
      },
      {
        title: 'Location',
        menu: [
          {
            value: 'Location.PostalCode',
            title: 'The postal or ZIP code for the area where the endpoint is located.',
          },
        ],
      },
    ],

    // tinydrive_skin: 'oxide-dark',
  };
  user$ = this._Store.select<ICoreUser>(UserSelectors.selectUser);
  accessToken$ = this._Store.select(UserSelectors.selectUserToken);

  // ngOnInit() {
  //   // this.accessToken$.subscribe({
  //   //   next: (accessToken) => {
  //   //     console.log({ accessToken });
  //   //     this.config;
  //   //   },
  //   // });
  // }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 3) {
      changeEvent.preventDefault();
    }
  }

  toggleDisabled() {
    this.disabled = !this.disabled;
    if (this.disabled) {
      this.active = 1;
    }
  }

  close() {
    this.activeModal.close();
  }
}
