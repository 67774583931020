import { CompanyCreateSmartWebComponent } from './company-create-smart-component/company-create-smart-component.component';
import { CompanySignUpWebComponent } from './company-sign-up.component';
import { LaunchpointCoreWebSignUpProfileDetailsComponent } from './profile-details-smart-user/launchpoint-sign-up-user-profile.component';

export const COMPANY_SIGNUP_COMPONENTS = [
  //
  CompanySignUpWebComponent,
  LaunchpointCoreWebSignUpProfileDetailsComponent,
  CompanyCreateSmartWebComponent,
];

export * from './company-sign-up.component';
export * from './profile-details-smart-user/launchpoint-sign-up-user-profile.component';
export * from './company-create-smart-component/company-create-smart-component.component';
