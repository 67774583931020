import { Component } from '@angular/core';
import { LaunchpointCoreClientProfileAddressSmartUserBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-address-smart-user',
  templateUrl: './profile-address-smart-user.component.html',
})
export class LaunchpointCoreWebProfileAddressSmartUserComponent extends LaunchpointCoreClientProfileAddressSmartUserBaseComponent {
  // constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig, public _Store: Store) {
  //   super(_config, _Store);
  // }
}
