import { SMSChannelRequest, UpdateSmsChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointSMSChannelParamsUpdate implements UpdateSmsChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.SMS;
  SMSChannelRequest: ISMSChannelRequest;
}

class ISMSChannelRequest implements SMSChannelRequest {
  Enabled?: boolean;
  SenderId?: string;
  ShortCode?: string;
}
