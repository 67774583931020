import { Component, inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@launchpoint/xplat/core';
import { LaunchpointBlogService } from '../../data/blog.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'sd-blog-hero',
  templateUrl: './blog-hero.component.html',
  styleUrl: './blog-hero.component.scss',
})
export class BlogHeroComponent extends BaseComponent implements OnInit {
  _LaunchpointBlogService = inject(LaunchpointBlogService);
  blogSearch = new FormControl('');

  ngOnInit() {
    this.blogSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this._LaunchpointBlogService.searchString.set(search),
    });
  }

  selectCategory(category: string, event: Event) {
    event.preventDefault();
    if (this._LaunchpointBlogService.selectedCategory() !== category) {
      this._LaunchpointBlogService.selectedCategory.set(category);
    }
  }

  get SelectedCategory() {
    return this._LaunchpointBlogService.selectedCategory();
  }

  clearSearch(): void {
    this._LaunchpointBlogService.searchString.set('');
  }
}
