import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LaunchpointCoreClientProfileAddressBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-address-manual-form',
  templateUrl: './profile-address-manual-form.component.html',
})
export class LaunchpointCoreWebProfileAddressManuelComponent extends LaunchpointCoreClientProfileAddressBaseComponent {
  constructor(public fb: FormBuilder) {
    super(fb);
  }
}
