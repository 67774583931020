import { InjectionToken } from '@angular/core';
import { ICoreActivityHistory, IHttpException } from '@launchpoint/core-types';

export interface ActivityHistoryEntityState {
  history_id: string;
  activity_history: ICoreActivityHistory;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string;
}

export interface LaunchpointCoreActivityHistoryConfig {
  base_url: string;
}
export const USER_ACTIVITY_HISTORY_CONFIG_TOKEN = new InjectionToken<LaunchpointCoreActivityHistoryConfig>('ACTIVITY_HISTORY_CONFIG_TOKEN');
