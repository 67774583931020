import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { HeaderStyleOneComponent } from './header-style-one/header-style-one.component';
// import { StickyNavModule } from 'ng2-sticky-nav';
// import { TabsModule } from 'ngx-tabset';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CountUpModule } from 'ngx-countup';
import { RouterModule } from '@angular/router';
import { HeaderStyleAnchorComponent } from './header-style-anchor';
import { CommonModule } from '@angular/common';
import { NgxScrollTopModule } from '../../components/scroll-top/ngx-scrolltop.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxScrollTopModule,
    InlineSVGModule,
    CountUpModule,
    // StickyNavModule,
    // TabsModule.forRoot(),
  ],
  declarations: [HeaderStyleOneComponent, HeaderStyleAnchorComponent],
  exports: [HeaderStyleOneComponent, HeaderStyleAnchorComponent],
  providers: [],
})
export class LandingHeadersCoreModule {}
