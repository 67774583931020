import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-messenger-drawer',
  templateUrl: './messenger-drawer.component.html',
})
export class MessengerDrawerComponent {
  // constructor() {}
  // ngOnInit(): void {}
}
