import { Observable } from 'rxjs';
import { ICoreUser } from '../../../user-company-account.interface';
import { ICoreUserDemographicsParamsUpdate, ICoreUserDemographicsParamsUpdatePhone } from './core-demographics.interface';
/**
 * @Controller('user/demographics')
 */
export interface ICoreUserDemographicsController {
  /**
   * Put(phone)
   * @param body
   */
  updatePhone(body: ICoreUserDemographicsParamsUpdatePhone): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put()
   * @param body
   */
  updateDemographics(body: ICoreUserDemographicsParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
}
