import { Component } from '@angular/core';
import { COMMUNITY_DATA } from '../../data/resources-data';
import { ResourcesBaseComponent } from '../resources-base.component';

@Component({
  selector: 'sd-community',
  templateUrl: 'community.component.html',
  styleUrls: ['community.component.scss'],
})
export class ResourcesCommunityComponent extends ResourcesBaseComponent {
  items = COMMUNITY_DATA;
}
