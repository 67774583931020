import { ICoreUser } from '../../user-company-account.interface';
import { ICoreUserIDMeResponseAttribute, ICoreUserIDMeResponseStatus } from './id-me-api.interface';

export class ICoreUserIDMeParamsUpdate {
  user_id: string;
  id_me_uuid: string;
  id_me_groups: ICoreUserIDMeResponseStatus[];
}

export class ICoreUserIDMeParamsValidate {
  user_id: string;
  access_token: string;
}

export class ICoreUserIDMeBase {
  attributes: ICoreUserIDMeResponseAttribute[];
  status: ICoreUserIDMeResponseStatus[];
  user: string | ICoreUser;
}

export class ICoreUserIDMe extends ICoreUserIDMeBase {
  user: ICoreUser;
}

export class ICoreUserIDMeParamsCreateRoute {
  scope: EIDMeScopes;
  redirect_uri: string;
}

export class ICoreUserIDMeConfig {
  client_id: string;
  scopes: EIDMeScopes[];
}

export enum EIDMeScopes {
  GOVERNMENT = 'government',
  MILITARY = 'military',
  NURSE = 'nurse',
  RESPONDER = 'responder',
  ALUMNI = 'alumni',
  STUDENT = 'student',
  TEACHER = 'teacher',
  EMPLOYEE = 'employee',
}
