import { ICoreUser } from '../../../user-company-account.interface';

export type ICoreUserReportingParamsLocation = {
  state?: string[];
  country?: string[];
  zip_code?: string[];
};

export class ICoreUserReportingParamsNotificationTopics {
  topic_ids: string[];
  /**
   * @default true
   */
  subscribed?: boolean;
}

export type ICoreUserReportingParamsDemographics = {
  country?: string[]; //iso2
  language?: string[];
  longitude?: number[];
  latitude?: number[];
};

export class ICoreUserReportingResponse {
  data: {
    email: string;
    customer: string;
    profile: ICoreUser['profile'];
    accounts: ICoreUser['accounts'];
    addresses: ICoreUser['addresses'][0];
    demographics: ICoreUser['demographics'];
    security_roles: ICoreUser['security_roles'];
    notification_topics: ICoreUser['notification_topics'];
  }[];
  total: number;
}

export class ICoreUserReportingAccountsResponse {
  data: {
    numberOfAccounts: number;
    count: number;
  }[];
  totalUsersWithAccounts: number;
  totalAccounts: number;
}
