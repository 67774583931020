import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaunchpointCoreClientProfileConfig, LibCoreClientPipesModule, PROFILE_CONFIG_TOKEN } from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CardsModule } from '../../../layouts/core/partials/content/cards/cards.module';
import { DropdownMenusModule } from '../../../layouts/core/partials/content/dropdown-menus/dropdown-menus.module';
import { WidgetsModule } from '../../../layouts/core/partials/content/widgets/widgets.module';
import { LaunchpointCoreWebProfileModule } from '../profile/profile.module';
import { LaunchpointCoreWebAccountsComponent } from './accounts.component';
import { LaunchpointCoreWebAccountsAddSmartAdminComponent } from './components/accounts-add/accounts-add-smart-admin/accounts-add-smart-admin.component';
import { LaunchpointCoreWebAccountsChangeSecurityRolesAdminComponent } from './components/accounts-change-security-roles-admin/accounts-change-security-roles-admin.component';
import { LaunchpointCoreWebAccountsTableSmartAdminComponent } from './components/accounts-table/accounts-table-smart-admin/accounts-table-smart-admin.component';
import { LaunchpointCoreWebAccountsTableSmartUserComponent } from './components/accounts-table/accounts-table-smart-user/accounts-table-smart-user.component';
import { LaunchpointCoreWebAccountsTableComponent } from './components/accounts-table/accounts-table/accounts-table.component';

const WEB_ACCOUNTS_COMPONENTS = [
  LaunchpointCoreWebAccountsComponent,
  LaunchpointCoreWebAccountsTableSmartUserComponent,
  LaunchpointCoreWebAccountsTableComponent,
  LaunchpointCoreWebAccountsTableSmartAdminComponent,
  LaunchpointCoreWebAccountsAddSmartAdminComponent,
  LaunchpointCoreWebAccountsChangeSecurityRolesAdminComponent,
];

@NgModule({
  declarations: [...WEB_ACCOUNTS_COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    DropdownMenusModule,
    WidgetsModule,
    CardsModule,
    RouterModule,
    NgbModule,
    LibCoreClientPipesModule,
    LaunchpointCoreWebProfileModule,
  ],
  providers: [],
  exports: [...WEB_ACCOUNTS_COMPONENTS],
})
export class LaunchpointCoreWebUserAccountsModule {
  static forRoot(_profileConfig: LaunchpointCoreClientProfileConfig): ModuleWithProviders<LaunchpointCoreWebUserAccountsModule> {
    return {
      ngModule: LaunchpointCoreWebUserAccountsModule,
      providers: [
        {
          provide: PROFILE_CONFIG_TOKEN,
          useValue: _profileConfig,
        },
      ],
    };
  }
}
