import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaunchpointViewStateBaseComponent, ViewActions } from '@launchpoint/core-client';
import { IUserView } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { LaunchpointSweetAlertService } from '../../../components/swal.service';

@Component({
  selector: 'launchpoint-user-view-save',
  templateUrl: './user-view-save.component.html',
  styleUrls: ['./user-view-save.component.scss'],
})
export class UserViewSaveComponent extends LaunchpointViewStateBaseComponent implements OnInit {
  @Input() view: IUserView;
  @Input() table_id: string;
  @Input() query: any;

  @Input() cssClass = 'btn btn-sm btn-secondary btn-active-success-primary ms-2';
  @Input() buttonTitle = 'Save Filter';

  @Output() selected = new EventEmitter<IUserView | null>();
  constructor(
    public _Store: Store,
    private _swalService: LaunchpointSweetAlertService
  ) {
    super(_Store);
  }

  ngOnInit(): void {
    if (!this.table_id) {
      console.error('Include the table id.');
    }
    return;
  }

  saveViewConfirmation() {
    this._swalService
      .fireAlert({
        icon: 'warning',
        title: this.view ? 'Update View' : 'Create View',
        html: `<input type="text" id="title" class="swal2-input" value="${this.view?.title ?? ''}" placeholder="${
          this.view?.title ?? 'Name This View...'
        }">`,
        confirmButtonText: 'Save View',
        focusConfirm: false,
        buttonsStyling: true,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        preConfirm: () => {
          const title = (this._swalService.Swal.getPopup().querySelector('#title') as any).value;
          if (!title) {
            this._swalService.Swal.showValidationMessage(`Please enter a title to save this view.`);
          }
          return { title };
        },
      })
      .then((result) => {
        console.log({ result });
        if (result.isConfirmed) {
          this.saveView(result.value.title);
        }
      });
  }

  saveView(title: string) {
    if (this.view) {
      const update = { view: { _id: this.view._id, query: this.query, title: title ?? this.view.title, table_id: this.table_id } };

      this._Store.dispatch(ViewActions.updateView(update));
    } else {
      if (title) {
        // console.log('Query for the view', this.query);
        this._Store.dispatch(
          ViewActions.createView({
            view: {
              query: this.query,
              table_id: this.table_id,
              title: title,
            },
          })
        );
      } else {
        console.error('No title');
      }
    }
  }
}
