export interface ISEOOpenGraph {
  title: string;
  description: string;
  image: string;
  url: string;
  type?: string;
  site_name?: string;
  locale?: string;
}

export interface ISEOTwitterCard {
  card: string;
  site?: string;
  title: string;
  description: string;
  image: string;
}

export interface ISEOData {
  title: string;
  route: string;
  description: string;
  keywords?: string;
  canonical_url?: string;
  og?: ISEOOpenGraph;
  twitter?: ISEOTwitterCard;
  author?: string;
  robots?: string;
  published_time?: string;
  modified_time?: string;
  language?: string;
  theme_color?: string;
  ms_tile_color?: string;
}

export interface ISEOOptions {
  [key: string]: ISEOData;
}

export interface ISEOToken {
  seo: ISEOOptions;
  debug?: boolean;
  // other options here
}
