<div>

  @if (loading$()) {

  <span class="indicator-label m-5" [style.display]="'block'">
    Please wait...
    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
  </span>

  }

  @for (notification of notifications$(); track notification) {
  <div class="d-flex justify-content-between align-items-center my-5 px-2">

    <div class="d-flex align-items-center">

      <i class="fas fa-circle fs-9 me-5" [ngClass]="{'text-primary': !notification?.is_read }"></i>

      <div>
        <div class="fw-semibold fs-6">{{ notification?.title }}</div>
        <div class="d-flex flex-wrap whitespace-pre-line text-muted fs-7">{{ notification?.body }}</div>
      </div>
    </div>

    <div class="text-end">
      <div class="text-muted fs-7">{{ notification?.created_at | date:'short' }}</div>
    </div>


  </div>
  }

  <launchpoint-pagination [pageOptions]="[5, 10, 15]" [pagination]="pagination" (paginate)="paginate($event)"
    (limit)="limit($event)" />
</div>