import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CircleEventHTTPService } from '@launchpoint/xplat/web/features';
import { CtaBannerComponent } from '../../components/common/cta-banner/cta-banner.component';
import { SocialIconsComponent } from '../../components/common/social-icons/social-icons.component';
import { StadnickSuccessStoriesComponent } from '../../components/success-stories/success-stories.component';
import { LaunchpointResourcesEventsService } from '../../services/resources.service';
import { HOME_COMPONENTS } from './components';
import { ContactComponent } from './components/contact/contact.component';
import { EventsComponent } from './components/events/events.component';
import { ForBusinessesComponent } from './components/for-businesses/for-businesses.component';
import { HeroComponent } from './components/hero/hero.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { PurposeComponent } from './components/purpose/purpose.component';
import { QuoteComponent } from './components/quote/quote.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { StoryComponent } from './components/story/story.component';
import { ToolsTipsComponent } from './components/tools-tips/tools-tips.component';
import { HomeComponent } from './home.component';

const STANDALONE_COMPONENTS = [CtaBannerComponent, SocialIconsComponent, StadnickSuccessStoriesComponent];

@NgModule({
  imports: [CommonModule, NgOptimizedImage, RouterModule, ...STANDALONE_COMPONENTS, SocialIconsComponent],
  declarations: [
    ...HOME_COMPONENTS,
    HomeComponent,
    HeroComponent,
    StoryComponent,
    PurposeComponent,
    ForBusinessesComponent,
    ResourcesComponent,
    EventsComponent,
    QuoteComponent,
    ToolsTipsComponent,
    ContactComponent,
    MetricsComponent,
  ],
  providers: [CircleEventHTTPService, LaunchpointResourcesEventsService],
})
export class HomePageModule {}
