import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { ICoreBlogParamsUpdate } from '@launchpoint/core-types';
import { LaunchpointSweetAlertService } from '../../../../components';
import { UtilityDatesService } from '../../../../components/utility-dates.service';

@Component({
  selector: 'launchpoint-blog-overview',
  templateUrl: './blog-overview.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogOverviewComponent extends LaunchpointCoreClientBlogBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);
  _UtilityDatesService = inject(UtilityDatesService);

  form: FormGroup<{
    title: FormControl<string>;
    description: FormControl<string>;
    active: FormControl<boolean>;
    featured: FormControl<boolean>;
    category: FormControl<string>;
    reading_time_min: FormControl<number>;
    release_at: FormControl<Date | string>;
    comments_enabled: FormControl<boolean>;
    comments_archived: FormControl<boolean>;
    replies_enabled: FormControl<boolean>;
  }>;

  warning: string = null;

  get TZ() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  buildForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      description: [''],
      active: [false],
      featured: [false],
      reading_time_min: [],
      release_at: [],
      comments_enabled: [false],
      comments_archived: [false],
      replies_enabled: [false],
    });
  }
  loadForm() {
    const { release_at, ...rest } = this.blog;
    this.form.patchValue(rest);
    this.form.controls.release_at.setValue(this.datePipe.transform(release_at, 'yyyy-MM-ddTHH:mm', Intl.DateTimeFormat().resolvedOptions().timeZone));
  }

  setCategory(category: string) {
    this.form.get('category').setValue(category);
    this.form.markAsDirty();
  }

  reset() {
    this.form.reset();
    this.loadForm();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const { comments_archived, comments_enabled, ...form } = this.form.value;

    if (!comments_enabled && !comments_archived) {
      this.warning = 'You must select at least one of the following options: Comments Enabled or Comments Archived';
      return;
    }

    const data: ICoreBlogParamsUpdate = {
      _id: this.blog._id,
      title: form.title,
      description: form.description,
      category: form.category,
      featured: form.featured,
      active: form.active,
      reading_time_min: form.reading_time_min,
      release_at: form.release_at
        ? this._UtilityDatesService.factorTimezoneToDate(form.release_at, Intl.DateTimeFormat().resolvedOptions().timeZone, true)
        : null,
      replies_enabled: form.replies_enabled,
      comments_enabled: comments_enabled,
      comments_archived,
    };

    if (comments_archived) {
      data.comments_enabled = false;
      data.replies_enabled = false;
    } else {
      data.comments_enabled = true;
    }

    this.updateBlog(data);
    this.warning = null;
  }
}
