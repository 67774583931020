import { Observable } from 'rxjs';
import {
  ICoreActivityHistoryWebhook,
  ICoreActivityHistoryWebhookParamsCreate,
  ICoreActivityHistoryWebhookParamsUpdate,
  ICoreActivityHistoryWebhookSearchPayload,
  ICoreActivityHistoryWebhookSearchResults,
} from './activity-history-webhooks.interface';

/**
 * @Controller('activity-history/endpoints')
 */
export interface ICoreActivityHistoryWebhookController {
  /**
   * Post ('search')
   */
  search(
    params: ICoreActivityHistoryWebhookSearchPayload
  ): Promise<ICoreActivityHistoryWebhookSearchResults> | Observable<ICoreActivityHistoryWebhookSearchResults>;
  /**
   * Get(':id')
   */
  getById(id: string): Promise<ICoreActivityHistoryWebhook> | Observable<ICoreActivityHistoryWebhook>;
  /**
   * Post()
   */
  create(params: ICoreActivityHistoryWebhookParamsCreate): Promise<ICoreActivityHistoryWebhook> | Observable<ICoreActivityHistoryWebhook>;
  /**
   * Put()
   */
  update(params: ICoreActivityHistoryWebhookParamsUpdate): Promise<ICoreActivityHistoryWebhook> | Observable<ICoreActivityHistoryWebhook>;
  /**
   * Put ('delete/:id')
   */
  delete(id: string): Promise<ICoreActivityHistoryWebhook> | Observable<ICoreActivityHistoryWebhook>;
}
