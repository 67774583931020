import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LaunchpointDynamicFormEntityAdaptor, LaunchpointDynamicFormFeatureKey, LaunchpointDynamicFormState } from '../reducers/reducer';

const selectAllLaunchpointDynamicFormState = createFeatureSelector<LaunchpointDynamicFormState>(LaunchpointDynamicFormFeatureKey);

/**
 * ROOT STATE
 */
export const getAllLaunchpointDynamicFormState = createSelector(selectAllLaunchpointDynamicFormState, (state: LaunchpointDynamicFormState) => state);

export const selectLaunchpointDynamicFormPagination = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.pagination
);
export const selectLaunchpointDynamicFormQuerySort = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.querySort
);
export const selectLaunchpointDynamicFormQuery = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.query
);
export const selectLaunchpointDynamicFormLoading = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.loading
);
export const selectLaunchpointDynamicFormLoaded = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.loaded
);
export const selectLaunchpointDynamicFormError = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.error
);
export const selectLaunchpointDynamicFormSuccess = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.success_message
);

export const getAllLaunchpointDynamicFormStateEntities = createSelector(
  selectAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state.entities
);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = LaunchpointDynamicFormEntityAdaptor.getSelectors(selectAllLaunchpointDynamicFormState);

// select the array of ids
export const selectLaunchpointDynamicFormIds = selectIds;

// select the dictionary of entities
export const selectLaunchpointDynamicFormEntities = selectEntities;

// select the array of entities
export const selectLaunchpointDynamicForm = selectAll;

// select the total entity count
export const selectLaunchpointDynamicFormTotal = selectTotal;

export const selectLaunchpointDynamicFormId = createSelector(
  getAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => state?.selected_dynamic_form_id
);

/**
 * Entire entity, includes loading/loaded state to be used on tables and other data.
 */
export const selectLaunchpointDynamicFormEntity = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]
);

/**
 * Selected entity object, no entity data
 */
export const selectSelectedLaunchpointDynamicForm = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.dynamic_form
);
/**
 * Selected entity object, no entity data
 */
export const selectSelectedLaunchpointDynamicFormGroups = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.dynamic_form?.groups
);
/**
 * Selected entity object, no entity data
 */
export const selectSelectedLaunchpointDynamicFormGroup = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => {
    if (!state.entities[selected_dynamic_form_id]) return null;
    console.log('Group UPDATING IN SELECTOR');
    const selected_group_id = state.entities[selected_dynamic_form_id]?.selected_group_id;
    if (!selected_group_id) {
      return state.entities[selected_dynamic_form_id].dynamic_form.groups[0];
    }
    console.log('SELECTED GROUP ID', selected_group_id);
    return state.entities[selected_dynamic_form_id]?.dynamic_form?.groups?.find((group) => group._id === selected_group_id);
  }
);
/**
 * Selected entity loading state
 */
export const selectSelectedLaunchpointDynamicFormLoading = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.loading
);
/**
 * Selected entity loaded state
 * This is useful if you want to insure that while loading might not be true the data doesn't exist and an attempt was made to get it
 */
export const selectSelectedLaunchpointDynamicFormLoaded = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.loaded
);
/**
 * Selected entity error message
 */
export const selectSelectedLaunchpointDynamicFormError = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.error
);
/**
 * Selected entity success message
 */
export const selectSelectedLaunchpointDynamicFormSuccessMessage = createSelector(
  getAllLaunchpointDynamicFormState,
  selectLaunchpointDynamicFormId,
  (state: LaunchpointDynamicFormState, selected_dynamic_form_id) => state.entities[selected_dynamic_form_id]?.success_message
);
/**
 * Selected entity count for what queries are applied.
 * @default 0
 * Should be no queries at init if possible.
 */
export const selectSelectedLaunchpointDynamicFormQueryCount = createSelector(
  selectAllLaunchpointDynamicFormState,
  (state: LaunchpointDynamicFormState) => {
    let count = 0;
    try {
      // TODO: Add other query objects here
      // count = count + state.query?.ARRAY.length ?? 0;

      // query object string, if not empty | '', then count 1
      if (state.query?.search && state.query?.search.length > 0) {
        count++;
      }
    } catch (error) {
      console.log(error);
    }
    return count;
  }
);
