<div
  class="scroll-y me-n5 pe-5"
  [ngClass]="!isDrawer ? 'h-300px h-lg-auto' : ''"
  data-kt-element="messages"
  data-kt-scroll="true"
  data-kt-scroll-activate="{default: false, lg: true}"
  data-kt-scroll-max-height="auto"
  [attr.data-kt-scroll-dependencies]="
    isDrawer
      ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
      : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
  "
  [attr.data-kt-scroll-wrappers]="
    isDrawer
      ? '#kt_drawer_chat_messenger_body'
      : '#kt_content, #kt_chat_messenger_body'
  "
  [attr.data-kt-scroll-offset]="isDrawer ? '0px' : '-2px'"
>
  <ng-container *ngFor="let message of messagesObs | async">
    <div
      [ngClass]="{
        'd-flex': !isDrawer,
        'd-none': message.template,
        'justify-content-start': message.type === 'in',
        'justify-content-end': message.type !== 'in'
      }"
      [attr.data-kt-element]="'template-' + message.type"
      class="mb-10"
    >
      <div
        class="d-flex flex-column align-items"
        [ngClass]="'align-items-' + (message.type === 'in' ? 'start' : 'end')"
      >
        <div class="d-flex align-items-center mb-2">
          <ng-container *ngIf="message.type === 'in'">
            <div div class="symbol symbol-35px symbol-circle">
              <img
                alt="Pic"
                [src]="'./assets/media/' + getUser(message.user).avatar"
              />
            </div>
            <div class="ms-3">
              <a
                href="#"
                class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
              >
                {{ getUser(message.user).name }}
              </a>
              <span class="text-muted fs-7 mb-1">{{ message.time }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="message.type !== 'in'">
            <div class="me-3">
              <span class="text-muted fs-7 mb-1">{{ message.time }}</span>
              <a
                href="#"
                class="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1"
              >
                You
              </a>
            </div>
            <div class="symbol symbol-35px symbol-circle">
              <img
                alt="Pic"
                [src]="'./assets/media/' + getUser(message.user).avatar"
              />
            </div>
          </ng-container>
        </div>

        <div
          [ngClass]="getMessageCssClass(message)"
          data-kt-element="message-text"
          [innerHTML]="message.text"
        ></div>
      </div>
    </div>
  </ng-container>
</div>

<div
  class="card-footer pt-4"
  [id]="
    isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'
  "
>
  <textarea
    #messageInput
    class="form-control form-control-flush mb-3"
    rows="1"
    data-kt-element="input"
    placeholder="Type a message"
  ></textarea>

  <div class="d-flex flex-stack">
    <div class="d-flex align-items-center me-2">
      <button
        class="btn btn-sm btn-icon btn-active-light-primary me-1"
        type="button"
        ngbTooltip="Coming soon"
      >
        <i class="bi bi-paperclip fs-3"></i>
      </button>
      <button
        class="btn btn-sm btn-icon btn-active-light-primary me-1"
        type="button"
        ngbTooltip="Coming soon"
      >
        <i class="bi bi-upload fs-3"></i>
      </button>
    </div>
    <button
      class="btn btn-primary"
      type="button"
      data-kt-element="send"
      (click)="submitMessage()"
    >
      Send
    </button>
  </div>
</div>
