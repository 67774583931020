import { Directive, inject, Input, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchpointCoreClientAlertBannerStateBaseComponent } from '../+state/alert-banner-state-base.component';
import { ICoreAlertBanner, ITagifyTag } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BASE_TAGIFY_SETTINGS } from '../../../components/tagify/tagify-component.interface';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerModalBaseComponent
  extends LaunchpointCoreClientAlertBannerStateBaseComponent
  implements OnInit
{
  fb = inject(FormBuilder);
  tagifyService = inject(TagifyService);

  @Input() banner: ICoreAlertBanner;

  form: FormGroup<{
    message: FormControl<string>;
    active: FormControl<boolean>;
    display_range_start_date: FormControl<Date>;
    display_range_end_date: FormControl<Date>;
    banner_color: FormControl<string>;
    text_color: FormControl<string>;
    security_roles: FormControl<ITagifyTag[]>; // { value: string; data: <T> }
  }>;

  settings: TagifySettings = BASE_TAGIFY_SETTINGS;

  endDateError$ = signal<string>(null);

  ngOnInit(): void {
    this.buildForm();
    this.loadForm();
  }

  buildForm() {
    this.form = this.fb.group({
      message: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]],
      active: [false],
      display_range_start_date: [null, Validators.required],
      display_range_end_date: [null, Validators.required],
      banner_color: ['light-danger'],
      text_color: ['danger'],
      security_roles: [],
    }) as any;
  }

  loadForm() {
    if (this.banner) {
      const { security_roles, display_range_end_date, display_range_start_date, ...rest } = this.banner;

      const map_security_roles = security_roles?.map((role) => ({ value: role, data: role })) ?? [];

      this.form.patchValue({
        ...rest,
        display_range_end_date: this.formatDateTime(new Date(display_range_end_date)) as any,
        display_range_start_date: this.formatDateTime(new Date(display_range_start_date)) as any,
        security_roles: map_security_roles,
      });
      this.settings.enforceWhitelist = false;

      setTimeout(() => {
        this.tagifyService.get('security_roles')?.loadOriginalValues(map_security_roles);
        this.settings.enforceWhitelist = true;
      }, 50);
    }
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const { security_roles, ...rest } = this.form.value;

    if (new Date(rest.display_range_start_date) > new Date(rest.display_range_end_date)) {
      this.endDateError$.set('End date must be after start date');
      return;
    }

    if (this.banner) {
      this.update({
        ...rest,
        message: rest.message?.trim(),
        _id: this.banner._id,
        security_roles: security_roles?.map((tag) => tag?.value) ?? [],
      });
    } else {
      this.create({
        ...rest,
        message: rest.message.trim(),
        active: rest.active,
        display_range_start_date: rest.display_range_start_date,
        display_range_end_date: rest.display_range_end_date,
        security_roles: security_roles?.map((tag) => tag?.value) ?? [],
      });
    }

    this.close();
  }

  close() {
    //
  }

  private formatDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // The required format is 'YYYY-MM-DDTHH:MM'
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  get minDateTime() {
    return this.formatDateTime(new Date(this.form.controls.display_range_start_date.value));
  }

  get formMessage() {
    return this.form.controls.message.value;
  }
  get getDemoClass() {
    const bannerColor = this.form.controls.banner_color.value;
    const textColor = this.form.controls.text_color.value;

    return 'p-4 mb-5 fs-6 text-center bg-' + bannerColor + ' text-' + textColor;
  }
}
