import { Component } from '@angular/core';
import { SkeletonLoadersBaseComponent } from '../skeleton-loaders.base-component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SkeletonLoadersComponent } from '../skeleton-loaders/skeleton-loaders.component';

@Component({
  selector: 'launchpoint-skeleton-card',
  templateUrl: 'skeleton-card.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SkeletonLoadersComponent],
})
export class SkeletonCardComponent extends SkeletonLoadersBaseComponent {
  constructor() {
    super();
  }
}
