import { on } from '@ngrx/store';
import {
  createCompanyOnUser,
  createCompanyOnUserFailure,
  createCompanyOnUserSuccess,
  deleteCompanyOnUser,
  deleteCompanyOnUserFailure,
  deleteCompanyOnUserSuccess,
} from '../../actions/company-users/company-users.actions';
import { LaunchpointCompanyEntityState } from '../../interfaces/company.interface';
import { LaunchpointCompanyState, LaunchpointCompanyEntityAdaptor } from '../interface';

export const COMPANY_USERS_REDUCER_FUNCTIONS = [
  on(createCompanyOnUser, (state: LaunchpointCompanyState, { data }) => ({
    ...state,
    error: null,
  })),
  on(createCompanyOnUserSuccess, (state: LaunchpointCompanyState, { user, company_id, success_message }) => {
    const companyEntity: LaunchpointCompanyEntityState = state.entities[company_id];
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error: null,
          loaded: true,
          loading: false,
          company_users: [...companyEntity.company_users, user],
        },
      },
      {
        ...state,
        error: null,
      }
    );
  }),
  on(createCompanyOnUserFailure, (state: LaunchpointCompanyState, { error }) => ({
    ...state,
    error: error,
  })),
  on(deleteCompanyOnUser, (state: LaunchpointCompanyState, { data }) => ({
    ...state,
    error: null,
  })),
  on(deleteCompanyOnUserSuccess, (state: LaunchpointCompanyState, { user, company_id, success_message }) => {
    const companyEntity: LaunchpointCompanyEntityState = state.entities[company_id];
    const companyUsers = [...companyEntity.company_users];
    // console.log('companyEntity', companyUsers);
    const index = companyUsers.findIndex((f) => f._id === user._id);
    // console.log('companyEntity:index', index);
    companyUsers.splice(index, 1);
    // console.log('companyUser', companyUsers);
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error: null,
          loaded: true,
          loading: false,
          company_users: companyUsers,
        },
      },
      {
        ...state,
        error: null,
      }
    );
  }),
  on(deleteCompanyOnUserFailure, (state: LaunchpointCompanyState, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
];
