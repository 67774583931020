import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ILaunchpointCorePinpointSegment,
  ILaunchpointCorePinpointSegmentController,
  ILaunchpointCorePinpointSegmentParamsCreate,
  ILaunchpointCorePinpointSegmentParamsDelete,
  ILaunchpointCorePinpointSegmentParamsList,
  ILaunchpointCorePinpointSegmentParamsUpdate,
  ILaunchpointCorePinpointSegmentSearchPayload,
  ILaunchpointCorePinpointSegmentSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../../notifications/interfaces/core-notifications.interface';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';

@Injectable()
export class PinpointSegmentsHTTPService extends LaunchpointSecurityUserIdHeaderService implements ILaunchpointCorePinpointSegmentController {
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  routes = {
    delete: '/delete',
    search: '/search',
    sync: '/sync',
  };

  constructor(
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig,
    public _Store: Store
  ) {
    super(_Store);
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'pinpoint/segments',
    });
  }
  search(body: ILaunchpointCorePinpointSegmentSearchPayload): Observable<ILaunchpointCorePinpointSegmentSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointSegmentSearchResults>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
  getById(_id: string): Observable<ILaunchpointCorePinpointSegment> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointCorePinpointSegment>(this.API_URL + '/' + _id, { headers });
      })
    );
  }
  create(body: ILaunchpointCorePinpointSegmentParamsCreate): Observable<ILaunchpointCorePinpointSegment> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointSegment>(this.API_URL, body, { headers });
      })
    );
  }
  update(body: ILaunchpointCorePinpointSegmentParamsUpdate): Observable<ILaunchpointCorePinpointSegment> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointSegment>(this.API_URL, body, { headers });
      })
    );
  }
  delete(body: ILaunchpointCorePinpointSegmentParamsDelete): Observable<ILaunchpointCorePinpointSegment> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointSegment>(this.API_URL + this.routes.delete, body, { headers });
      })
    );
  }
  sync(body: ILaunchpointCorePinpointSegmentParamsList): Observable<{ success: boolean }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<{ success: boolean }>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
}
