import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientAlertBannerFilterBaseComponent } from '@launchpoint/core-client';
import { LaunchpointFormControlDateRangeComponent } from '../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { IBooleanFilter } from '../../../components/boolean-filter/boolean-filter.component';

@Component({
  selector: 'launchpoint-alert-banner-filter',
  templateUrl: './alert-banner-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebAlertBannerTableFilterComponent extends LaunchpointCoreClientAlertBannerFilterBaseComponent {
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  options: IBooleanFilter = {
    allLabel: 'Both',
    falseLabel: 'No',
    trueLabel: 'Yes',
  };

  clearAll() {
    this._LaunchpointFormControlDateRangeComponent.resetDates();
    this.reset();
  }
}
