<div class="d-flex flex-center flex-column flex-lg-row-fluid">
  <!--begin::Wrapper-->
  <div class="card shadow min-h-400px w-lg-500px w-md-400px w-300px">

    <button [routerLink]="['auth', 'login']" routerLinkActive="router-link-active" type="button"
      class="btn btn-icon text-hover-primary ps-10 pt-7" ngbTooltip="Back">
      <i class="bi bi-arrow-left fs-3 me-2"></i>
    </button>

    <div class="p-15 p-md-20 p-lg-20 pt-10">
      <!--begin::Form-->
      <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form">

        <div class="text-center" [ngClass]="{
          'mb-10': !_config?.set_password?.description,
          'mb-5': _config?.set_password?.description
        }">
          <h1 class="fw-bold mb-3">
            {{ user?.reset_password_force ? 'Reset Password' : _config?.set_password?.title || 'Create Password' }}
          </h1>

          <div class="text-muted fs-6" *ngIf="_config?.set_password?.description">
            {{ _config?.set_password?.description }}
          </div>
        </div>

        <ng-container *ngIf="error$ | async as error">
          <div class="mb-lg-15 alert alert-danger">
            <div class="alert-text font-weight-bold">{{ error?.error.message }}</div>
          </div>
        </ng-container>


        <div class="fv-row mb-8 fv-plugins-icon-container">
          <!--begin::Wrapper-->
          <div class="mb-1">
            <div class="form-floating d-flex align-items-center mb-4">
              <input [type]="pVisible ? 'text' : 'password'" placeholder="Password" formControlName="password"
                class="form-control" />
              <span class="text-hover-primary position-absolute translate-middle top-50 end-0 me-2"
                (click)="togglePass()">
                <i class="fs-5 bi" [ngClass]="{ 'bi-eye-slash': !pVisible, 'bi-eye': pVisible }"></i>
              </span>
              <label for="floatingInput">Password</label>
            </div>
            <!--end::Input wrapper-->

            <!--begin::Meter-->

            <div class="h-8px w-100 rounded mb-5" *ngIf="form?.dirty">
              <ngb-progressbar class="h-8px" [type]="passwordMeterType" [value]="passwordMeterValue" [max]="100"
                [striped]="false" [animated]="true" />
            </div>
          </div>
          <!--end::Wrapper-->

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Password is required',
            control: form.controls.password
          }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Password cannot have more than ' + (_config?.set_password?.password_settings?.max_length ?? 100 ) + ' characters',
            control: form.controls.password
          }"></ng-container>

          <div id="password-validation" class="d-flex flex-column text-danger text-sm" *ngIf="form?.dirty">
            <div class="d-flex align-items-center" *ngIf="isSamePassword()">
              <i class="me-1 bi bi-x text-danger"></i>
              Password cannot be the same as your previous password.
            </div>
            <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.min_length"
              [ngClass]="{'text-success':passwordMatch('min-length')}">
              <i class="me-1 bi" [ngClass]="{
                'bi-check text-success': passwordMatch('min-length'),
                'bi-x text-danger': !passwordMatch('min-length'),
              }"></i>
              Password must have at least {{ _config.set_password?.password_settings?.min_length ?? 8 }} characters.
            </div>
            <!-- <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.max_length"
              [ngClass]="{'text-success':passwordMatch('max-length')}">
              <i class="me-1 bi" [ngClass]="{
                'bi-check text-success': passwordMatch('max-length'),
                'bi-x text-danger': !passwordMatch('max-length'),
              }"></i>
              Password must have at most {{ _config.set_password?.password_settings?.max_length ?? 8 }} characters.
            </div> -->
            <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_uppercase"
              [ngClass]="{'text-success':passwordMatch('uppercase')}">
              <i class="me-1 bi" [ngClass]="{
                'bi-check text-success': passwordMatch('uppercase'),
                'bi-x text-danger': !passwordMatch('uppercase'),
                }"></i>
              Password must have at least one uppercase letter.
            </div>
            <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_number"
              [ngClass]="{'text-success':passwordMatch('number')}">
              <i class="me-1 bi" [ngClass]="{
                'bi-check text-success': passwordMatch('number'),
                'bi-x text-danger': !passwordMatch('number'),
              }"></i>
              Password must have at least one number.
            </div>
            <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_special"
              [ngClass]="{'text-success':passwordMatch('special')}">
              <i class="me-1 bi" [ngClass]="{
                'bi-check text-success': passwordMatch('special'),
                'bi-x text-danger': !passwordMatch('special'),
              }"></i>
              Password must have at least special character.
            </div>
          </div>
        </div>
        <!--end::Input group=-->
        <!-- *ngIf="form.controls.password.valid" [animateCss]="'fadeInDown'" -->
        <div class="fv-row mb-8 fv-plugins-icon-container">

          <div class="form-floating d-flex align-items-center mb-4">
            <input [type]="cVisible ? 'text' : 'password'" placeholder="Confirm Password" formControlName="cPassword"
              class="form-control" />
            <span class="text-hover-primary position-absolute translate-middle top-50 end-0 me-2"
              (click)="toggleConfirm()">
              <i class="fs-5 bi" [ngClass]="{ 'bi-eye-slash': !cVisible, 'bi-eye': cVisible }"></i>
            </span>
            <label for="floatingInput">Confirm Password</label>
          </div>
          <!--end::Repeat Password-->
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Confirm Password is required',
            control: form.controls.cPassword
          }"></ng-container>
          <ng-container
            *ngIf="form.controls.cPassword.value && form.controls.cPassword.errors && form.controls.cPassword.errors.ConfirmPassword">
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">Passwords do not match</div>
            </div>
          </ng-container>
        </div>

        <div class="d-grid">
          <!--begin::Indicator label-->
          <button type="submit" [disabled]="disabled" (click)="submit()" class="btn btn-lg btn-primary w-100 mb-5">
            <ng-container *ngIf="(loading$ | async) === false">
              <span class="indicator-label text-white">
                {{ user?.reset_password_force ? 'Save' : _config?.set_password?.button ?? 'Sign Up' }}
              </span>
            </ng-container>
            <ng-container *ngIf="loading$ | async">
              <span class="indicator-label" [style.display]="'block'">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
          <!--end::Indicator label-->
        </div>
        <!--end::Action-->
      </form>
      <!--end::Form-->
    </div>
    <!--end::Wrapper-->
  </div>
</div>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>