<div class="fadeInDown fixed z-50 w-full text-white backdrop-blur-md" [ngClass]="{ 'bg-background': open, 'bg-[#14141480]': !open }">
  <div class="mx-auto max-w-[1700px] px-4">
    <nav class="w-full border-b border-white/50">
      <!-- Desktop header -->
      <div class="hidden items-center justify-between py-4 lg:flex">
        <a [routerLink]="['/']"><img src="./assets/img/stadnick-logo.png" class="h-16" /></a>

        <ul class="flex flex-row space-x-8 p-4 font-medium">
          <!-- Iterate over header menu items -->
          @for (item of headerMenu(); track item.order) {
            @if (item.active) {
              <li class="group relative">
                @if (item?.children?.length) {
                  <a
                    (click)="toggleDropdown(item)"
                    class="hover:text-primary flex items-center text-white"
                    aria-current="page"
                    (mouseenter)="hovered = item.title"
                  >
                    {{ item.title }}
                    <!-- Chevron icon -->
                    @if (item.children.length) {
                      <i
                        class="fa-solid fa-arrow-down ml-2"
                        (click)="toggleDropdown(item); toggleHover(item.title)"
                        [ngClass]="{
                          'rotate-0 duration-300 ease-in-out': !$any(item).isDropdownOpen,
                          'rotate-180': $any(item).isDropdownOpen || hovered === item.title,
                        }"
                      ></i>
                    }
                  </a>

                  <!-- Dropdown -->
                  <ul
                    *ngIf="item.children.length && hovered === item.title"
                    class="bg-background absolute grid min-w-[300px] items-center gap-2 overflow-hidden rounded-lg p-6 text-white shadow-lg transition-all duration-300 ease-in-out"
                    style="z-index: 9999; width: max-content"
                    (mouseleave)="hovered = null"
                    [ngClass]="{
                      'grid-cols-1': item.children.length <= 8,
                      'grid-cols-2': item.children.length > 8 && item.children.length <= 16,
                      'grid-cols-3': item.children.length > 16,
                      'max-h-0 opacity-0': !$any(item).isDropdownOpen && !hovered,
                      'max-h-screen opacity-100': $any(item).isDropdownOpen || hovered === item.title,
                    }"
                  >
                    @for (child of item.children; track $index) {
                      @if (child?.active) {
                        <li>
                          <a
                            [routerLink]="[child.route]"
                            class="hover:text-primary w-fit whitespace-nowrap p-2 font-normal"
                            (click)="hovered = null; $any(item).isDropdownOpen = false"
                          >
                            {{ child.title }}
                          </a>
                        </li>
                      }
                    }
                  </ul>
                } @else {
                  @if (item.route) {
                    <a
                      [routerLink]="[item.route]"
                      [routerLinkActive]="['active-nav']"
                      [routerLinkActiveOptions]="item.route === '/' ? { exact: true } : { exact: false }"
                      (click)="toggleMenu()"
                      class="hover:text-primary text-white"
                      aria-current="page"
                    >
                      {{ item.title }}
                    </a>
                  }

                  @if (item.href) {
                    <a [href]="item.href" target="_blank" (click)="toggleMenu()" class="hover:text-primary text-white" aria-current="page">
                      {{ item.title }}
                    </a>
                  }
                }
              </li>
            }
          }
        </ul>

        @if (cta()?.route) {
          <sd-button [text]="cta().title" [routerLink]="[cta()?.route]" />
        } @else if (cta().href) {
          <a [href]="cta().href">
            <sd-button [text]="cta().title" />
          </a>
        }
      </div>

      <!-- Mobile header -->
      <div class="flex items-center justify-between py-4 lg:hidden">
        <a [routerLink]="['/']"><img src="./assets/img/stadnick-logo.png" class="h-12" /></a>
        <button class="relative h-6 w-6 text-white focus:outline-none lg:hidden" (click)="toggleMenu()">
          <div class="absolute top-1/2 block w-5 transform">
            <span
              aria-hidden="true"
              [ngClass]="{
                'absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out': true,
                'rotate-45': open,
                '-translate-y-1.5': !open,
              }"
            ></span>
            <span
              aria-hidden="true"
              [ngClass]="{
                'absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out': true,
                'opacity-0': open,
              }"
            ></span>
            <span
              aria-hidden="true"
              [ngClass]="{
                'absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out': true,
                '-rotate-45': open,
                'translate-y-1.5': !open,
              }"
            ></span>
          </div>
        </button>
      </div>
    </nav>
  </div>

  <!-- Mobile Menu -->
  <div
    [ngClass]="{ hidden: !open }"
    class="top-30 absolute left-0 right-0 transform overflow-hidden transition-[height] duration-500 ease-in-out lg:hidden"
  >
    <div class="bg-background flex h-screen flex-col items-center px-4 pt-10">
      <div class="flex w-full max-w-xs flex-col space-y-10">
        <ul class="flex flex-col space-y-12 px-10 text-center text-2xl">
          @for (item of headerMenu(); track item.order) {
            @if (item.active) {
              <li>
                @if (item?.children?.length) {
                  <a
                    (click)="toggleDropdown(item)"
                    class="hover:text-primary flex items-center justify-center text-white"
                    aria-current="page"
                    [ngClass]="{
                      'pb-2': $any(item).isDropdownOpen,
                    }"
                  >
                    {{ item.title }}
                    <!-- Chevron Icon for Accordion -->
                    <i
                      *ngIf="item.children?.length"
                      class="fa-solid fa-arrow-down ml-2"
                      [ngClass]="{
                        'rotate-0 duration-300 ease-in-out': !$any(item).isDropdownOpen,
                        'rotate-180': $any(item).isDropdownOpen,
                      }"
                    ></i>
                  </a>

                  <!-- Accordion for mobile when item has children -->
                  <ul
                    *ngIf="item.children?.length"
                    class="space-y-2 overflow-hidden rounded-lg text-white shadow-lg transition-all duration-300 ease-in-out"
                    [ngClass]="{
                      'max-h-0 opacity-0': !$any(item).isDropdownOpen,
                      'max-h-[500px] opacity-100': $any(item).isDropdownOpen,
                    }"
                    style="transition-property: max-height, opacity"
                  >
                    @for (child of item.children; track child) {
                      @if (child?.active) {
                        <li>
                          <a [routerLink]="[child.route]" class="hover:text-primary text-md block py-2 font-normal" (click)="toggleMenu()">
                            {{ child.title }}
                          </a>
                        </li>
                      }
                    }
                  </ul>
                } @else {
                  <a
                    [routerLink]="[item.route]"
                    [routerLinkActive]="['active-nav']"
                    [routerLinkActiveOptions]="item.route === '/' ? { exact: true } : { exact: false }"
                    (click)="toggleMenu()"
                    class="hover:text-primary text-white"
                    aria-current="page"
                  >
                    {{ item.title }}
                  </a>
                }
              </li>
            }
          }
        </ul>
      </div>

      <!-- NEW BUTTON -->
      <div class="pt-20">
        @if (cta()?.route) {
          <sd-button [text]="cta().title" [routerLink]="[cta()?.route]" />
        } @else if (cta().href) {
          <a [href]="cta().href">
            <sd-button [text]="cta().title" />
          </a>
        }
      </div>
    </div>
  </div>
</div>
