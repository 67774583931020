import { createFeatureSelector, createSelector } from '@ngrx/store';
import { coreMediaFeatureKey, IMediaEntityState } from '../reducers/media-entity.interface';
import { MediaEntityAdaptor } from '../reducers/media.reducer';

export const selectAdminMediaState = createFeatureSelector<IMediaEntityState>(coreMediaFeatureKey);

export const getAllMediaState = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state);

export const selectMediaLoaded = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.loaded);

export const selectMediaLoading = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.loading);
// OBJECTS
export const selectMedia = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.entities);
export const selectMediaId = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.selected_media_id);
export const selectedMediaEntityCount = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.ids.length);
// export const selectMediaSelectedMedia = createSelector(
//   getMediaState,
//   selectMediaId,
//   (state: IMediaEntityState, selected_media_id) => state.entities[selected_media_id]
// );
export const selectMediaPagination = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.pagination);
export const selectMediaQuerySort = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.querySort);
export const selectMediaQuery = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.query);

export const selectMediaQueryFilterCount = createSelector(selectAdminMediaState, (state: IMediaEntityState) => {
  let count = 0;
  try {
    count += state.query?.account_ids?.length ?? 0;
    count += state.query?.category?.length ?? 0;
    count += state.query?.tags?.length ?? 0;
    count += state.query?.user_ids?.length ?? 0;
    count += state.query?.archived ? 1 : 0;
  } catch (error) {
    console.log(error);
  }
  return count;
});

export const selectMediaError = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.error);

export const selectMediaSuccess = createSelector(selectAdminMediaState, (state: IMediaEntityState) => state.success_message);
const { selectIds, selectEntities, selectAll, selectTotal } = MediaEntityAdaptor.getSelectors(selectAdminMediaState);
// select the array of user ids
export const selectMediaIds = selectIds;

// select the dictionary of user entities
export const selectMediaEntities = selectEntities;

// select the array of users
export const selectMediaAll = selectAll;

// select the total entity count
export const selectMediaTotal = selectTotal;

export const selectSelectedMediaId = createSelector(getAllMediaState, (state: IMediaEntityState) => state?.selected_media_id);

export const selectSelectedMedia = createSelector(
  getAllMediaState,
  selectMediaId,
  (state: IMediaEntityState, selected_id) => state.entities[selected_id]?.media
);

export const selectSelectedMediaLoading = createSelector(
  getAllMediaState,
  selectMediaId,
  (state: IMediaEntityState, selected_id) => state.entities[selected_id]?.loading
);

export const selectSelectedMediaError = createSelector(
  getAllMediaState,
  selectMediaId,
  (state: IMediaEntityState, selected_id) => state.entities[selected_id]?.error
);

export const selectSelectedMediaSuccess = createSelector(
  getAllMediaState,
  selectMediaId,
  (state: IMediaEntityState, selected_id) => state.entities[selected_id]?.success_message
);
