import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ENotificationChannelType } from '@launchpoint/core-types';
import { getMessageAnalytics } from '../+state/actions/messages.actions';
import { LaunchpointCoreClientNotificationsMessagesBaseComponent } from '../notifications-messages-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsCarouselBaseComponent
  extends LaunchpointCoreClientNotificationsMessagesBaseComponent
  implements OnInit
{
  @Input() selectedChannel: ENotificationChannelType;
  @Output() cardClicked = new EventEmitter();

  ngOnInit() {
    this._Store.dispatch(getMessageAnalytics());
    this.success();
    this.error();
  }

  onCardClicked(channel: ENotificationChannelType) {
    this.cardClicked.emit(channel);
  }
}
