import { InjectionToken } from '@angular/core';
import { IAuthenticationResponse } from '@launchpoint/core-types';
import { IEnvironmentApp } from '../../../../config/environment.interface';

export interface ICoreMediaConfig {
  environment: IEnvironmentApp;
  /**
   * Sets up the URL
   *
   * `${location.origin}/${_config.environment.mediaApiUrl}content/${_config?.route}/`
   */
  route: 'upload' | 'account' | 'users';
  /**
   * WEB ONLY
   *
   * File path to where the assets for the PDFtron core/ui modules are.
   */
  pdftron?: string;
}

export const APP_MEDIA_CONFIG_TOKEN = new InjectionToken<ICoreMediaConfig>('APP_MEDIA_SERVICE_CONFIG_TOKEN');

export interface IMediaService {
  tokens: IAuthenticationResponse;
  setTokens(auth: IAuthenticationResponse): unknown;
  removeTokens(): void;
}
