<div class="bg-background flex justify-center pt-24 text-white">
  <div class="mx-4 flex w-full py-20 md:w-[1400px]">
    <div class="mt-10 hidden h-[1000px] w-[25%] border-r-2 border-white pr-10 text-sm md:block">
      <div class="flex flex-col gap-2">
        <p class="py-4 text-xl font-semibold">More Blogs</p>
        @for (blog of blogTitles; track $index) {
          <a [routerLink]="'/blog' + '/' + blog.title_url" class="text-md hover:text-primary mb-4 hover:underline">{{ blog.title }}</a>
        }
      </div>
      <div
        class="to-background mt-6 flex flex-col items-center rounded-md border-[1px] border-white/10 bg-gradient-to-t from-[#0E1330] from-0% to-100% px-4 py-4 opacity-75"
      >
        <p class="mb-4 text-center text-lg font-semibold text-white">Learn how you can implement with your company?</p>
        <sd-button text="Contact" size="small" (click)="chat()" />
      </div>

      <div class="mt-10 flex justify-center gap-4 text-lg text-white">
        <a href="https://www.facebook.com/profile.php?id=61567135375134" target="_blank" class="hover:text-primary">
          <i class="fa-brands fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/launchpointdev/" target="_blank" class="hover:text-primary">
          <i class="fa-brands fa-instagram"></i>
        </a>
        <a href="https://www.youtube.com/channel/UC8MN0pb2al4tp15pjIxaTFA" target="_blank" class="hover:text-primary">
          <i class="fa-brands fa-youtube"></i>
        </a>
        <a href="https://www.linkedin.com/company/thelaunchpoints/" target="_blank" class="hover:text-primary">
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
        <a href="https://x.com/LaunchpointDev" target="_blank" class="hover:text-primary">
          <i class="fa-brands fa-x-twitter"></i>
        </a>
      </div>
    </div>

    <div class="mx-2 w-full md:pl-10">
      @if (!blog) {
        <div class="w-full text-center">
          <h1 class="mt-20 text-2xl">Blog Not Found</h1>
        </div>
      }
      @if (blog?.content) {
        <div class="mb-5 flex items-center gap-2 text-xs">
          <a [routerLink]="['/blog']" class="hover:text-primary whitespace-nowrap">Blog</a>
          <i class="fa-solid fa-angle-right"></i>
          <div class="overflow-hidden">
            <h6 class="text-primary max-w-[300px] truncate underline sm:max-w-[300px] md:max-w-[400px] lg:max-w-[500px]">
              {{ blog?.title }}
            </h6>
          </div>
        </div>
        <div class="relative h-[270px] w-full md:h-[600px]">
          @if (blog?.featured_image?.meta_data?.url) {
            <img [ngSrc]="blog?.featured_image?.meta_data?.url" [alt]="blog?.title" fill class="rounded object-cover" />
          } @else {
            <img ngSrc="assets/images/blog-images/img6.png" [alt]="blog?.title" fill class="rounded object-cover" />
          }
        </div>

        <div class="pb-5 pt-10">
          <h1 class="text-[40px] leading-9 md:text-start">{{ blog?.title }}</h1>
          <div class="mt-10 flex flex-col-reverse items-start justify-between gap-4 md:flex-row md:items-center">
            <div>
              <span class="mr-4 rounded-full bg-white px-3 py-1 text-xs text-black">{{ blog?.category }}</span>
              <span class="text-xs" *ngIf="blog?.reading_time_min">{{ blog?.reading_time_min }} min read</span>
            </div>
            <div class="flex items-center">
              @if (blog?.author_user.profile?.profile_photo_url) {
                <img [src]="blog?.author_user.profile?.profile_photo_url" alt="Author Image" class="mr-2 h-8 w-8 rounded-full" priority />
              } @else {
                <i class="fa-solid fa-user-circle mr-2 text-lg"></i>
              }

              <span class="mr-2 text-xs">{{ getAuthor(blog?.author_user) }}</span>
              <span class="text-xs">{{ blog?.created_at | date }}</span>
            </div>
          </div>
        </div>

        <div class="content rounded bg-white px-4 pb-40 pt-10 text-black md:px-20 md:pt-20" [innerHTML]="blog.content"></div>
      }

      @if (commentsAllowed) {
        <div class="mb-10 mt-20">
          <h3 class="mb-5 text-2xl font-semibold">Comments</h3>

          <sd-blog-comments [blog]="blog" />
        </div>
      }

      @if (blog?.related_posts?.length) {
        <div class="mb-10 mt-20">
          <h3 class="text-2xl font-semibold">Related Blogs</h3>
        </div>
        <div class="grid grid-cols-1 gap-4 md:flex md:overflow-x-scroll">
          @for (blog of blog?.related_posts; track blog?._id) {
            <div class="mb-4 w-full sm:w-1/2 lg:w-1/3">
              <sd-blog-card [blog]="blog" />
            </div>
          }
        </div>
      }
    </div>
  </div>
</div>
