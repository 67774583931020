import { Component, effect, inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { StadnickDevConfigService } from '../../../../services/config.service';
import { ResourcesBaseComponent } from '../resources-base.component';

@Component({
  selector: 'sd-event-detail',
  templateUrl: 'event-detail.component.html',
  styleUrls: ['event-detail.component.scss'],
})
export class ResourcesEventDetailComponent extends ResourcesBaseComponent implements OnInit {
  _LaunchpointConfigService = inject(StadnickDevConfigService);

  _Title = inject(Title);

  constructor() {
    super();
    effect(() => {
      this._Title.setTitle(`Launchpoint | ${this.event.name}`);
    });
  }

  get event() {
    return this._LaunchpointResourcesEventsService.selected_event();
  }

  get cta() {
    return this._LaunchpointConfigService.primaryCallToAction;
  }

  get cta2() {
    return this._LaunchpointConfigService.secondaryCallToAction;
  }

  ngOnInit() {
    this.route.params.subscribe({
      next: (params) => this.handelRouteParams(params),
    });
  }

  handelRouteParams(params: Params) {
    const title_url = params['event_id'];
    if (title_url) {
      this._LaunchpointResourcesEventsService.setSelectedByTitle(title_url);
    }
  }
}
