import {
  IHttpException,
  IUserNotification,
  IUserNotificationParamsCreate,
  IUserNotificationParamsUpdate,
  IUserNotificationSearchResults,
  IQueryPageination,
  IQuerySort,
  IQuerySortMulti,
  IUserNotificationSearchQuery,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// getById
export const getUserNotificationById = createAction(
  '[LP-Notification-UserNotification] getUserNotificationById',
  props<{ user_notification_id: string }>()
);
export const getUserNotificationByIdSuccess = createAction(
  '[LP-Notification-UserNotification] getUserNotificationByIdSuccess',
  props<{ user_notification: IUserNotification; success_message?: string }>()
);
export const getUserNotificationByIdFailure = createAction(
  '[LP-Notification-UserNotification] getUserNotificationByIdFailure',
  props<{ user_notification_id: string; error: IHttpException }>()
);

// get user unread
export const getUserNotificationUnread = createAction('[LP-Notification-UserNotification] getUserNotificationUnread', props<{ user_id: string }>());
export const getUserNotificationUnreadSuccess = createAction(
  '[LP-Notification-UserNotification] getUserNotificationUnreadSuccess',
  props<{ data: number; success_message?: string }>()
);
export const getUserNotificationUnreadFailure = createAction(
  '[LP-Notification-UserNotification] getUserNotificationUnreadFailure',
  props<{ user_id: string; error: IHttpException }>()
);

// Search
export const searchAllUserNotifications = createAction('[LP-Notification-UserNotification] searchAllUserNotifications');
export const searchAllUserNotificationsSuccess = createAction(
  '[LP-Notification-UserNotification] searchAllUserNotificationsSuccess',
  props<{ data: IUserNotificationSearchResults; success_message?: string }>()
);
export const searchAllUserNotificationsFailure = createAction(
  '[LP-Notification-UserNotification] searchAllUserNotificationsFailure',
  props<{ error: IHttpException }>()
);

// CREATE
export const createUserNotification = createAction(
  '[LP-Notification-UserNotification] createUserNotification',
  props<{ data: IUserNotificationParamsCreate; redirectPath?: string[] }>()
);

export const createUserNotificationSuccess = createAction(
  '[LP-Notification-UserNotification] createUserNotificationSuccess',
  props<{ data: IUserNotification; redirectPath?: string[]; success_message?: string }>()
);
export const createUserNotificationFailure = createAction(
  '[LP-Notification-UserNotification] createUserNotificationFailure',
  props<{ error: IHttpException }>()
);

// UPDATE
export const updateUserNotification = createAction(
  '[LP-Notification-UserNotification] updateUserNotification',
  props<{ data: IUserNotificationParamsUpdate }>()
);
export const updateUserNotificationSuccess = createAction(
  '[LP-Notification-UserNotification] updateUserNotificationSuccess',
  props<{ user_notification: IUserNotification; success_message?: string }>()
);
export const updateUserNotificationFailure = createAction(
  '[LP-Notification-UserNotification] updateUserNotificationFailure',
  props<{ user_notification_id: string; error: IHttpException }>()
);

// DELETE
export const deleteUserNotification = createAction('[LP-Notification-UserNotification] deleteUserNotification', props<{ id: string }>());
export const deleteUserNotificationSuccess = createAction(
  '[LP-Notification-UserNotification] deleteUserNotificationSuccess',
  props<{ data: IUserNotification; success_message?: string }>()
);
export const deleteUserNotificationFailure = createAction(
  '[LP-Notification-UserNotification] deleteUserNotificationFailure',
  props<{ user_notification_id: string; error: IHttpException }>()
);

// Mechanics
export const updateAllUserNotificationPagination = createAction(
  '[LP-Notification-UserNotification] updateAllUserNotificationPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllUserNotificationPaginationScroll = createAction(
  '[LP-Notification-UserNotification] updateAllUserNotificationPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllUserNotificationSorting = createAction(
  '[LP-Notification-UserNotification] updateAllUserNotificationSorting',
  props<{ querySort: IQuerySort }>()
);
export const updateAllUserNotificationQuery = createAction(
  '[LP-Notification-UserNotification] updateAllUserNotificationQuery',
  props<{ query: IUserNotificationSearchQuery }>()
);
