import { Directive, OnInit } from '@angular/core';
import { ICoreActivityHistory } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { LaunchpointCoreClientActivityHistoryStateBaseComponent } from '../+state/activity-history-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientActivityHistoryDetailBaseComponent
  extends LaunchpointCoreClientActivityHistoryStateBaseComponent
  implements OnInit
{
  activityHistory: ICoreActivityHistory;

  ngOnInit() {
    this.activityHistory$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.activityHistory = data;
      },
    });
  }
}
