import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ISearchAheadComponent, LaunchpointFormControlSearchAheadBaseComponent, UserViewV1Service } from '@launchpoint/core-client';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { IUserView, IUserViewQueryParams } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-user-views-search-ahead',
  templateUrl: './user-views-search-ahead.component.html',
  styleUrls: ['./user-views-search-ahead.component.scss'],
})
export class UserViewsSearchAheadComponent extends LaunchpointFormControlSearchAheadBaseComponent implements ISearchAheadComponent, OnInit {
  @Input() query: IUserViewQueryParams;
  @Input() selected: IUserView;
  @Input() cssClass = 'form-control';
  @Input() popupClass = '';

  @Output() selectedData = new EventEmitter<IUserView>();

  constructor(public _UserViewV1Service: UserViewV1Service) {
    super();
  }

  ngOnInit(): void {
    if (!this.query.table_id) {
      console.error('Table id is required to run this search.');
    } else {
      this.runQuery('');
    }
  }

  formatter = (result: IUserView): string => result.title;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly IUserView[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._UserViewV1Service.search({ pagination: this.pagintation, querySort: { created_at: -1 }, query: { ...this.query, search } }).pipe(
      map((data) => {
        this.loading = false;
        return data.data;
      })
    );
  }

  selectData(data: { item: IUserView }) {
    this.selected = data.item;
    return this.selectedData.emit(data.item);
  }
}
