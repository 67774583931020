export interface IEmailData {
  email: string;
  name?: string;
}

export interface ISentEmail {
  created_at: Date;
  updated_at: Date;
  processed: boolean;
  dropped: boolean;
  delivered: boolean;
  deferred: boolean;
  bounced: boolean;
  opened: boolean;
  clicked: boolean;
  spam_report: boolean;
  unsubscribe: boolean;

  // Properties related to the sent email
  to: IEmailData[];
  cc: IEmailData[];
  bcc: IEmailData[];
  from: IEmailData;
  reply_to: IEmailData;

  send_at: number;

  subject: string;
  text: string;
  html: string;
  content: [
    {
      type: string;
      value: string;
    }
  ];
  templateId: string;

  personalizations: [
    {
      to: IEmailData[];
      cc: IEmailData[];
      bcc: IEmailData[];
      subject: string;
      headers: Record<string, string>;
      substitutions: Record<string, string>;
      dynamicTemplateData: Record<string, unknown>;
      customArgs: Record<string, string>;
      sendAt: number;
    }
  ];

  attachments: IEmailerAttachment[];

  ipPoolName: string;
  batchId: string;

  sections: Record<string, string>;
  headers: Record<string, string>;

  categories: [string];
  category: string;

  // Empty object means any type
  customArgs: Record<string, unknown>;
  asm: {
    groupId: number;
    groupsToDisplay: [number];
  };

  mailSettings: {
    bcc: {
      enable: boolean;
      email: string;
    };
    bypassListManagement: {
      enable: boolean;
    };
    footer: {
      enable: boolean;
      text: string;
      html: string;
    };
    sandboxMode: {
      enable: boolean;
    };
    spamCheck: {
      enable: boolean;
      threshold: number;
      postToUrl: string;
    };
  };

  trackingSettings: {
    click_tracking: {
      enable: boolean;
      enable_text: boolean;
    };
    open_tracking: {
      enable: boolean;
      substitution_tag: string;
    };
    subscription_tracking: {
      enable: boolean;
      text: string;
      html: string;
      substitution_tag: string;
    };
    ganalytics: {
      enable: boolean;
      utm_source: string;
      utm_medium: string;
      utm_term: string;
      utm_content: string;
      utm_campaign: string;
    };
  };

  substitutions: Record<string, string>;
  substitutionWrappers: [string];

  isMultiple: boolean;
  dynamicTemplateData: Record<string, unknown>;

  hideWarnings: boolean;

  replyToList: IEmailData[];
}

export interface IEmailerAttachment {
  /**
   * The Base64 encoded content of the attachment
   */
  content: string;
  /**
   * The MIME type of the content you are attaching
   */
  type: string;
  /**
   * The attachment's filename.
   */
  filename: string;
  /**
   * Inline means the attachment is shown in the email, must include the content id
   * attachment means the file is shown as an attachment in the email
   */
  disposition: 'inline' | 'attachment';
  /**
   * The attachment's content ID. This is used when the disposition is set to “inline” and the attachment is an image, allowing the file to be displaye within the body of your email.
   */
  content_id?: string;
}
