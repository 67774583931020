import { Directive, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createCompany } from '../+state/actions/company.actions';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { ICoreCompanyParamsCreate } from '@launchpoint/core-types';

@Directive()
export abstract class CompanyCreateModalBaseComponent extends LaunchpointCoreClientBaseComponent {
  close() {
    // this.activeModal.close();
  }
}
