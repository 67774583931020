import { Observable } from 'rxjs';
import { IDateRange } from '../../../dates.interface';
import { ILaunchpointDynamicFormResponseStatusAnalytics } from '../response.interface';
import { ICoreCreatedAtAnalytics } from '../../../user-company-account.interface';

/**
 * @route `response-analytics`
 */
export interface ILaunchpointDynamicFormResponseAnalyticsController {
  created(params: IDateRange): Promise<ICoreCreatedAtAnalytics> | Observable<ICoreCreatedAtAnalytics>;

  status(): Promise<ILaunchpointDynamicFormResponseStatusAnalytics[]> | Observable<ILaunchpointDynamicFormResponseStatusAnalytics[]>;
}
