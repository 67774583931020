import { Observable, OperatorFunction } from 'rxjs';

export interface ISearchAheadComponent {
  /**
   * This function should perform a search
   */
  search: OperatorFunction<string, readonly unknown[]>;
  /***
   * Allows for focus event to pull up results
   */
  onFocus(e: Event): void;
  /**
   * Should format the output to a string
   * @param result defined by the result of your query
   */
  formatter?(result: unknown): string;
  runQuery(search: string): Observable<unknown>;
  /**
   * Should emit an output event of the data selected
   * @param data
   */
  selectData(data: { item: unknown }): void;
}
