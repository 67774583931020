<div class="card">
  <div class="card-body">
    <div class="table-responsive" [style.display]="(loading$ | async) ? 'block' : ''">
      <table class="table-row-dashed table-hover fs-6 gy-5 table align-middle">
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label m-5" [style.display]="'block'">
            Please wait...
            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </ng-container>

        <thead>
          <tr class="fw-bold fs-7 text-uppercase gs-0 text-gray-700">
            <th class="min-w-200px sorting">Title</th>
            <th class="min-w-200px sorting">Description</th>
            <th class="min-w-100px sorting">Type</th>
            <th class="min-w-70px sorting">Users</th>
            <th class="min-w-100px text-end">Created</th>
          </tr>
        </thead>

        <tbody class="fw-semibold fs-6">
          <tr *ngIf="(loading$ | async) === false && (messagesEntity$ | async)?.length === 0">
            <td>No Messages</td>
          </tr>

          <tr *ngFor="let entity of messagesEntity$ | async">
            <td>
              {{ entity?.message?.title ?? 'No Title' }}
            </td>
            <td>
              {{ entity?.message?.description }}
            </td>
            <td>
              {{ entity?.message?.job_type }}
            </td>
            <td>
              {{ entity?.message?.user_count }}
            </td>
            <td class="text-end">
              {{ entity?.message?.created_at | date }}
            </td>
          </tr>
        </tbody>
      </table>

      <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
    </div>
  </div>
</div>
