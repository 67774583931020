import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../../core/layout.service';
import { LaunchpointLayoutMenu } from '../../../../../interfaces/menu.interfaces';
import { MenuComponent } from '../../../../core/kt/components/MenuComponent';
import { DrawerComponent } from '../../../../core/kt/components/_DrawerComponent';
import { ToggleComponent } from '../../../../core/kt/components/_ToggleComponent';
import { ScrollComponent } from '../../../../core/kt/components/_ScrollComponent';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'launchpoint-demo-1-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class LaunchpointDemo1AsideComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnDestroy {
  asideTheme = 'light';
  asideMinimize = false;
  asideMenuCSSClasses = '';
  @Input() user: ICoreUser;
  @Input() menuConfig: LaunchpointLayoutMenu;
  @ViewChild('ktAsideScroll', { static: true }) ktAsideScroll: ElementRef;

  private unsubscribe: Subscription[] = [];

  constructor(private layout: LayoutService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.asideTheme = this.layout.getProp('aside.theme') as string;
    this.asideMinimize = this.layout.getProp('aside.minimize') as boolean;
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('asideMenu');
    this.routingChanges();


  }

  routingChanges() {
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.menuReinitialization();
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  menuReinitialization() {
    setTimeout(() => {
      MenuComponent.reinitialization();
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (this.ktAsideScroll && this.ktAsideScroll.nativeElement) {
        this.ktAsideScroll.nativeElement.scrollTop = 0;
      }
    }, 50);
  }

  minimize() {
    const asideRef = document.getElementById('kt_aside');
    asideRef?.classList.add('animating');

    setTimeout(() => {
      asideRef?.classList.remove('animating');
    }, 300);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
