import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyBaseComponent, updateAllCompaniesQuery } from '@launchpoint/core-client';
import { ELaunchpointAccountRoles, ICoreCompanySearchQuery, ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-companies-filter',
  templateUrl: './company-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class CompanyTableFilterComponent extends CompanyBaseComponent implements OnInit {
  active = 1;
  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistAccountTypes$ = new BehaviorSubject<string[]>(Object.values(ELaunchpointAccountRoles));

  constructor(
    public _Store: Store<object>,
    public _Router: Router,
    public _route: ActivatedRoute,
    public fb: UntypedFormBuilder,
    private tagifyService: TagifyService,
    private _LaunchpointFormControlDateRangeComponent: LaunchpointFormControlDateRangeComponent
  ) {
    super(_Store, _Router, _route, fb);
  }

  clearUsers() {
    this.users = [];
    this.tagifyService.get('users')?.removeAllTags();
  }

  getTypesLength() {
    return this.form.controls?.account_type?.value?.length ?? 0;
  }
  clearTypes() {
    this.form.controls.account_type.setValue([]);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // this.settings.enforceWhitelist = true;
  }

  setUser(event: ICoreUser[]) {
    this.users = event;
  }

  apply() {
    const value = this.form.value;

    // console.log('this.users', this.users);

    const query: ICoreCompanySearchQuery = {
      created_at: value.created_at,
      account_type: value.account_type?.map((f) => f?.value) ?? [],
      account_owner: this.users.map((f) => f?._id) ?? [],
    };

    // console.log('query', query);

    this._Store.dispatch(updateAllCompaniesQuery({ query: query }));
  }

  clearAll() {
    this.form.reset();
    this.tagifyService.get('users')?.removeAllTags();
    this.tagifyService.get('account_type')?.removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    this._Store.dispatch(updateAllCompaniesQuery({ query: {} }));
  }
}
