import { Directive, inject, Input, OnInit } from '@angular/core';
import { IQueryPageination, IQuerySort, ITagifyTag } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../components/base/base.component';
import { BASE_TAGIFY_SETTINGS, ITagifyComponent } from '../../components/tagify/tagify-component.interface';

@Directive()
export abstract class LaunchpointTagifyBaseComponent extends LaunchpointCoreClientBaseComponent implements ITagifyComponent, OnInit {
  protected tagify: Tagify;
  public tagifyService = inject(TagifyService);

  @Input() pagination: IQueryPageination = { limit: 100, skip: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  settings: TagifySettings = BASE_TAGIFY_SETTINGS;

  protected searchSubject: Subject<string> = new Subject<string>();

  protected firstLoad = true;

  ngOnInit(): void {
    this.updateWhitelist();
    this.initializeModel();
    this.searchSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchTerm) => {
      this.performSearch(searchTerm);
    });
  }

  selectData(data): void {
    //
  }

  initializeModel() {
    //
  }

  updateWhitelist(search?: string): void {
    //
  }

  formatTags(data): ITagifyTag {
    return;
  }

  onInput(search: string) {
    this.searchSubject.next(search);
  }

  performSearch(search: string) {
    this.updateWhitelist(search);
  }

  getTagify(name: string): Tagify {
    return this.tagifyService.get(name);
  }

  get tagifyElement(): Tagify {
    return;
  }
}
