import { Action, combineReducers } from '@ngrx/store';
import { menuTopbarReducer } from './menu.reducer';
import { MenuState } from './menu.interface';

export const LAYOUT_FEATURE_KEY = 'layoutReducers';

export interface ILayoutState {
  menuReducer: MenuState;
}

export const layoutReducers = combineReducers({
  menuReducer: menuTopbarReducer,
});

export * from './menu.interface';
