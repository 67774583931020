<div class="d-flex flex-wrap flex-stack mb-6">
  <h3 class="fw-bolder my-2">
    My Documents
    <span class="fs-6 text-gray-400 fw-bold ms-1">100+ resources</span>
  </h3>

  <div class="d-flex my-2">
    <div class="d-flex align-items-center position-relative me-4">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
        class="svg-icon svg-icon-3 position-absolute ms-3"></span>
      <input type="text" id="kt_filter_search" class="form-control form-control-white form-control-sm w-150px ps-9"
        placeholder="Search" />
    </div>

    <a class="btn btn-primary btn-sm cursor-pointer"> File Manager </a>
  </div>
</div>

<div class="row g-6 g-xl-9 mb-6 mb-xl-9">
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/folder-document.svg" title="Finance"
      description="7 files"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/folder-document.svg" title="Customers"
      description="3 files"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/folder-document.svg" title="CRM Project"
      description="25 files"></launchpoint-app-card4>
  </div>
</div>

<div class="row g-6 g-xl-9 mb-6 mb-xl-9">
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/pdf.svg" title="Project Reqs.."
      description="3 days ago"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/doc.svg" title="CRM App Docs.."
      description="3 days ago"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/css.svg" title="User CRUD Styles"
      description="4 days ago"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/ai.svg" title="Metronic Logo"
      description="5 days ago"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/sql.svg" title="Orders backup"
      description="1 week ago"></launchpoint-app-card4>
  </div>
</div>

<div class="row g-6 g-xl-9 mb-6 mb-xl-9">
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/xml.svg" title="UTAIR CRM API Co.."
      description="2 week ago"></launchpoint-app-card4>
  </div>
  <div class="col-12 col-sm-12 col-xl">
    <launchpoint-app-card4 icon="./assets/media/svg/files/tif.svg" title="Tower Hill App.."
      description="3 week ago"></launchpoint-app-card4>
  </div>
</div>