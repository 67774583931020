import { Directive } from '@angular/core';
import { ICoreUserProfileParamsUpdate } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { updateUserProfile } from '../../../../../users/+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';
import { Store } from '@ngrx/store';

@Directive()
export class LaunchpointCoreClientProfileDetailsSmartAdminBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
  async dispatchUpdateSave(event: Omit<ICoreUserProfileParamsUpdate, 'user_id'>) {
    const user = await firstValueFrom(this.selected_user$);
    // console.log('user', user);
    this._Store.dispatch(updateUserProfile({ data: { ...event, user_id: user.user._id } }));
  }
}
