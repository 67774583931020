export interface IConfigureURLParams {
  baseUrl: string;
  configUrl?: string;
  version?: number;
  route?: string;
  debug?: boolean;
}

/**
 * Constructs a configuration URL based on the provided parameters.
 *
 * @param {IConfigureURLParams} params - The parameters for configuring the URL.
 * @param {string} params.baseUrl - The base URL to use if configUrl is not provided.
 * @param {string} [params.configUrl] - An optional specific configuration URL.
 * @param {number} [params.version] - The version number to append to the URL.
 * @param {string} [params.route] - An optional route to append to the URL.
 * @param {boolean} [params.debug] - An optional flag to enable debug mode.
 * @returns {string} The constructed configuration URL with `NO TRAILING SLASH`.
 *
 * @example
 * // Basic usage with baseUrl
 * const url = configureURL({ baseUrl: 'http://example.com' });
 * // Returns 'http://example.com'
 *
 * @example
 * // Basic usage with baseUrl and version
 * const url = configureURL({ baseUrl: 'http://example.com', version: 1 });
 * // Returns 'http://example.com/v1/'
 *
 * @example
 * // Usage with configUrl, version, and route
 * const url = configureURL({ baseUrl: 'http://example.com', configUrl: 'http://config.example.com', version: 2 });
 * // Returns 'http://config.example.com/v2/'
 * @example
 * // Usage with configUrl, version, and route
 * const url = configureURL({ baseUrl: 'http://example.com', configUrl: 'http://config.example.com', route: 'path/to/resource' });
 * // Returns 'http://config.example.com/path/to/resource'
 *
 * @example
 * // Usage with configUrl, version, and route
 * const url = configureURL({ baseUrl: 'http://example.com', configUrl: 'http://config.example.com', version: 2, route: 'path/to/resource' });
 * // Returns 'http://config.example.com/v2/path/to/resource'
 */
export function configureURL(params: IConfigureURLParams): string {
  const { baseUrl, configUrl, version, route, debug = false } = params;

  debug && console.log(params);

  // jam together the URL with excessive slashes
  let url = `${configUrl || baseUrl}${version ? `/v${version}/` : ''}${route ? `/${route}/` : ''}`;

  debug && console.log('before cleaning', url);

  // Replace multiple slashes with a single slash, but preserve "http://" or "https://"
  url = url.replace(/([^:]\/)\/+/g, '$1');

  debug && console.log('after cleaning', url);

  // Remove trailing slash unless end of route is a version
  if (!url.match(/\/v[^/]+\/?$/) && url.endsWith('/')) {
    url = url.slice(0, -1);
  }

  debug && console.log('final URL', url);

  return url;
}
