<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <!-- <div class="me-7 mb-4">
            <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <img src="/assets/media/avatars/blank.png" alt="Metronic" />
              <div
                class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px">
              </div>
            </div>
          </div> -->

          <div class="flex-grow-1">
            <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div class="d-flex flex-column">
                <div class="d-flex align-items-center mb-2">
                  <div class="text-dark fs-2 fw-bolder me-1 cursor-pointer">
                    {{ primary_header }}
                  </div>
                  <ng-content select="[profile_status]"></ng-content>
                </div>


                <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <div class="d-flex align-items-center text-gray-700 mb-2 cursor-pointer">
                    {{ secondary_header }}
                    <!-- <span
                      class="badge ms-3"
                      [ngClass]="{ 'badge-success': user.email_verification.email_verified, 'badge-danger': !user.email_verification.email_verified }"
                      >Verified</span
                    > -->
                  </div>
                </div>
              </div>
              <div class="d-flex my-4">
                <ng-content select="[profile_buttons]"></ng-content>
              </div>
            </div>

            <div class="d-flex flex-wrap flex-stack">
              <div class="d-flex flex-column flex-grow-1 pe-8">
                <div class="d-flex flex-wrap">
                  <div
                    class="profile_block_1 border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span> <ng-content select="[profile_block_1_icon]"></ng-content></span>
                      <div class="fs-4 fw-bold" id="one-content">
                        <ng-content select="[profile_block_1_value]"></ng-content>
                      </div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-700"><ng-content select="[profile_block_1_header]"></ng-content>
                    </div>
                  </div>

                  <div
                    class="profile_block_2 border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span> <ng-content select="[profile_block_2_icon]"></ng-content></span>
                      <div class="fs-4 fw-bold" id="two-content">
                        <ng-content select="[profile_block_2_value]"></ng-content>
                      </div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-700"><ng-content select="[profile_block_2_header]"></ng-content>
                    </div>
                  </div>

                  <div
                    class="profile_block_3 border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex align-items-center">
                      <span> <ng-content select="[profile_block_3_icon]"></ng-content></span>
                      <div class="fs-4 fw-bold" id="three-content">
                        <ng-content select="[profile_block_3_value]"></ng-content>
                      </div>
                    </div>

                    <div class="fw-bold fs-6 text-gray-700"><ng-content select="[profile_block_3_header]"></ng-content>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                  <span class="fw-bold fs-6 text-gray-700"><ng-content
                      select="[profile_completion_title]"></ng-content></span>
                  <span class="fw-bold fs-6"><ng-content select="[profile_completion_amount]"></ng-content></span>
                </div>
                <ng-content select="[profile_completion_visual]"> </ng-content>
              </div>
            </div>
          </div>
        </div>

        <div class="separator"></div>

        <div class="d-flex overflow-auto h-55px">
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <ng-content select="[profile_navigation]"></ng-content>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>