import {
  CampaignCustomMessage,
  CampaignEmailMessage,
  CampaignInAppMessage,
  CampaignLimits,
  CampaignSmsMessage,
  InAppMessageBodyConfig,
  InAppMessageButton,
  InAppMessageHeaderConfig,
  MessageConfiguration,
  Schedule,
  TemplateConfiguration,
} from '@aws-sdk/client-pinpoint';

export enum ESegmentComparisonOperator {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
  EQUAL = 'EQUAL',
}

export enum ESegmentAttributeType {
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  BETWEEN = 'BETWEEN',
  // NOT_BETWEEN = 'NOT_BETWEEN', // ??
  CONTAINS = 'CONTAINS',
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
  ON = 'ON',
}

export enum EDimensionType {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE',
}

export enum EEndpointTypesElement {
  ADM = 'ADM',
  APNS = 'APNS',
  APNS_SANDBOX = 'APNS_SANDBOX',
  APNS_VOIP = 'APNS_VOIP',
  APNS_VOIP_SANDBOX = 'APNS_VOIP_SANDBOX',
  BAIDU = 'BAIDU',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  GCM = 'GCM',
  IN_APP = 'IN_APP',
  PUSH = 'PUSH',
  SMS = 'SMS',
  VOICE = 'VOICE',
}

export enum ECampaignStatus {
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  EXECUTING = 'EXECUTING',
  INVALID = 'INVALID',
  PAUSED = 'PAUSED',
  PENDING_NEXT_RUN = 'PENDING_NEXT_RUN',
  SCHEDULED = 'SCHEDULED',
}

// implements Layout
export enum ELayout {
  BOTTOM_BANNER = 'BOTTOM_BANNER',
  CAROUSEL = 'CAROUSEL',
  MIDDLE_BANNER = 'MIDDLE_BANNER',
  MOBILE_FEED = 'MOBILE_FEED',
  OVERLAYS = 'OVERLAYS',
  TOP_BANNER = 'TOP_BANNER',
}

export enum EFrequency {
  DAILY = 'DAILY',
  EVENT = 'EVENT',
  HOURLY = 'HOURLY',
  IN_APP_EVENT = 'IN_APP_EVENT',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE',
  WEEKLY = 'WEEKLY',
}

export interface ICampaignState {
  /**
   * @public
   * <p>The current status of the campaign, or the current status of a treatment that belongs to an A/B test campaign.</p> <p>If a campaign uses A/B testing, the campaign has a status of COMPLETED only if all campaign treatments have a status of COMPLETED. If you delete the segment that's associated with a campaign, the campaign fails and has a status of DELETED.</p>
   */
  campaign_status?: ECampaignStatus;
}

// implements TreatmentResource
export class ITreatmentResource {
  custom_delivery_configuration?: ICustomDeliveryConfiguration;
  id: string;
  message_configuration?: MessageConfiguration;
  schedule?: Schedule;
  size_percent: number;
  state?: ICampaignState;
  template_configuration?: TemplateConfiguration;
  treatment_description?: string;
  treatment_name?: string;
}

// implements CustomDeliveryConfiguration
export class ICustomDeliveryConfiguration {
  delivery_url: string;
  endpoint_types?: EEndpointTypesElement[];
}

// implements CampaignHook
export class ICampaignHook {
  lambda_function_name?: string;
  mode?: 'DELIVERY' | 'FILTER';
  web_url?: string;
}

// implements CampaignLimits
export class ICampaignLimits {
  daily?: number;
  maximum_duration?: number;
  messages_per_second?: number;
  total?: number;
}

// implements Message
export class IMessage {
  action?: 'DEEP_LINK' | 'OPEN_APP' | 'URL';
  body?: string;
  image_small_icon_url?: string;
  image_icon_url?: string;
  image_url?: string;
  json_body?: string;
  media_url?: string;
  raw_content?: string;
  silent_push?: boolean;
  time_to_live?: number;
  title?: string;
  url?: string;
}

// implements CampaignCustomMessage
export class ICampaignCustomMessage {
  data?: string;
}

// implements CampaignEmailMessage
export class ICampaignEmailMessage {
  body?: string;
  from_address?: string;
  html_body?: string;
  title?: string;
}

// implements InAppMessageContent
export class IInAppMessageContent {
  background_color?: string;
  body_config?: InAppMessageBodyConfig;
  header_config?: InAppMessageHeaderConfig;
  image_url?: string;
  primary_btn?: InAppMessageButton;
  secondary_btn?: InAppMessageButton;
}

// implements CampaignInAppMessage
export class ICampaignInAppMessage {
  body?: string;
  content?: IInAppMessageContent[];
  custom_config?: Record<string, string>;
  layout?: ELayout;
}

// implements CampaignSmsMessage
export class ICampaignSmsMessage {
  body?: string;
  message_type?: 'TRANSACTIONAL' | 'PROMOTIONAL';
  sender_id?: string;
  entity_id?: string;
  origination_number?: string;
}

//implements MessageConfiguration
export class IMessageConfiguration {
  adm_message?: IMessage;
  apns_message?: IMessage;
  baidu_message?: IMessage;
  custom_message?: CampaignCustomMessage;
  default_message?: IMessage;
  email_message?: CampaignEmailMessage;
  gcm_message?: IMessage;
  in_app_message?: CampaignInAppMessage;
  sms_message?: CampaignSmsMessage;
}

//implements CampaignEventFilter
export class ICampaignEventFilter {
  dimensions: IEventDimensions;
  filter_type: 'ENDPOINT' | 'SYSTEM';
}

// implements EventDimensions
export class IEventDimensions {
  attributes?: Record<string, IAttributeDimension>;
  event_type?: ISetDimension;
  metrics?: Record<string, IMetricDimension>;
}

// implements AttributeDimension
export class IAttributeDimension {
  attribute_type?: ESegmentAttributeType;
  values: string[];
}

// implements SetDimension
export class ISetDimension {
  dimension_type?: EDimensionType;
  values: string[];
}

// implements MetricDimension
export class IMetricDimension {
  comparison_operator: string;
  value: number;
}

// implements Schedule
export class ISchedule {
  end_time?: string;
  event_filter?: ICampaignEventFilter;
  frequency?: EFrequency;
  is_local_time?: boolean;
  quiet_time?: IQuietTime;
  start_time: string;
  timezone?: string;
}

//  implements TemplateConfiguration
export class ITemplateConfiguration {
  email_template?: ITemplate;
  in_app_template?: ITemplate;
  push_template?: ITemplate;
  sms_template?: ITemplate;
  voice_template?: ITemplate;
}

//  implements Template
export class ITemplate {
  name?: string;
  version?: string;
}

export class IQuietTime {
  end?: string;
  start?: string;
}
