import { InjectionToken } from '@angular/core';
import { EStadnickDevPages } from './pages';
import { ISEOOptions } from '@launchpoint/core-types';

const STANDARD_IMAGE = 'https://launchpoint.dev/assets/seo/launchpoint-every-business-has-lag.png';

export const SEO_CONFIG: ISEOOptions = {
  home: {
    route: EStadnickDevPages.HOME,
    title: 'Launchpoint Dev | Reducing Lag In Your Crucial Business Activities',
    description:
      "Empower your business with Launchpoint's automations and technology. Streamline operations, drive growth, and keep quality connections. Start Today!",
    keywords: 'Launchpoint Dev, business lag, business efficiency, reduce costs, tech consulting, automation, business tech solutions',
    canonical_url: 'https://launchpoint.dev/',
    og: {
      title: 'Launchpoint | Reducing Lag In Your Crucial Business Activities',
      description:
        "Empower your business with Launchpoint's automations and technology. Streamline operations, drive growth, and keep quality connections. Start Today!",
      image: STANDARD_IMAGE,
      url: 'https://launchpoint.dev',
      type: 'website',
      site_name: 'Launchpoint',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'Launchpoint',
      description:
        "Empower your business with Launchpoint's automations and technology. Streamline operations, drive growth, and keep quality connections. Start Today!",
      image: STANDARD_IMAGE,
    },
    author: 'Launchpoint Dev Inc.',
    theme_color: '#000323',
    ms_tile_color: '#da532c',
    language: 'en',
  },
  resources: {
    route: EStadnickDevPages.RESOURCES,
    title: 'Resources | Access Free Resources',
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.RESOURCES + '/',
    description: 'Join our community page, events, workshops, free courses, and more!',
  },
  community: {
    route: EStadnickDevPages.RESOURCES + '/' + EStadnickDevPages.COMMUNITY,
    title: 'Community | Join Today!',
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.COMMUNITY + '/',
    description: 'Join our community page to gain access to our taleted team for support, free cources, and workshops.',
  },

  blog: {
    route: EStadnickDevPages.BLOG,
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.BLOG + '/',
    title: 'Blog | Learn How to Solve Your Business Lag',
    description:
      'Explore our blog at Launchpoint, where we provide insights, strategies, and practical advice on leveraging technology and automation to solve business lag, enhance efficiency, and drive growth, all while maintaining a human-centric approach.',
    og: {
      title: 'Blog | Learn How to Solve Your Business Lag',
      description:
        'Explore our blog at Launchpoint, where we provide insights, strategies, and practical advice on leveraging technology and automation to solve business lag, enhance efficiency, and drive growth, all while maintaining a human-centric approach.',
      image: 'https://media.launchpoint.dev/media/66b1925da4686289cd18b043/66b1925da4686289cd18b043.png',
      url: 'https://launchpoint.dev/blog',
      type: 'website',
      site_name: 'Blog | Learn How to Solve Your Business Lag',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'Launchpoint',
      description:
        'Explore our blog at Launchpoint, where we provide insights, strategies, and practical advice on leveraging technology and automation to solve business lag, enhance efficiency, and drive growth, all while maintaining a human-centric approach.',
      image: 'https://media.launchpoint.dev/media/66b1925da4686289cd18b043/66b1925da4686289cd18b043.png',
    },
  },
  privacy_policy: {
    route: EStadnickDevPages.PRIVACY,
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.PRIVACY + '/',
    title: 'Privacy Policy | Launchpoint Legal',
    description: "Read Launchpoint's Privacy Policy to understand how we handle your data and protect your privacy while providing our services.",
    og: {
      title: 'Privacy Policy | Launchpoint Legal',
      description: "Read Launchpoint's Privacy Policy to understand how we handle your data and protect your privacy while providing our services.",
      image: STANDARD_IMAGE,
      url: 'https://launchpoint.dev/' + EStadnickDevPages.PRIVACY,
      type: 'website',
      site_name: 'Launchpoint',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'Privacy Policy | Launchpoint Legal',
      description: "Read Launchpoint's Privacy Policy to understand how we handle your data and protect your privacy while providing our services.",
      image: STANDARD_IMAGE,
    },
  },
  terms_and_conditions: {
    route: EStadnickDevPages.TERMS_AND_CONDITIONS,
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.TERMS_AND_CONDITIONS + '/',
    title: 'Terms and Conditions | Launchpoint Legal',
    description:
      "Review the Terms and Conditions for using Launchpoint's services and products, outlining the rules and regulations that apply to all users.",
    og: {
      title: 'Terms and Conditions | Launchpoint Legal',
      description:
        "Review the Terms and Conditions for using Launchpoint's services and products, outlining the rules and regulations that apply to all users.",
      image: STANDARD_IMAGE,
      url: 'https://launchpoint.dev/' + EStadnickDevPages.TERMS_AND_CONDITIONS,
      type: 'website',
      site_name: 'Launchpoint',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'Terms and Conditions | Launchpoint Legal',
      description:
        "Review the Terms and Conditions for using Launchpoint's services and products, outlining the rules and regulations that apply to all users.",
      image: STANDARD_IMAGE,
    },
  },
  user_agreement: {
    route: EStadnickDevPages.USER_AGREEMENT,
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.USER_AGREEMENT + '/',
    title: 'User Agreement | Launchpoint Legal',
    description:
      'Understand the User Agreement at Launchpoint, detailing the responsibilities and expectations for both the users and the company in providing and using our services.',
    og: {
      title: 'User Agreement | Launchpoint Legal',
      description:
        'Understand the User Agreement at Launchpoint, detailing the responsibilities and expectations for both the users and the company in providing and using our services.',
      image: STANDARD_IMAGE,
      url: 'https://launchpoint.dev/' + EStadnickDevPages.USER_AGREEMENT,
      type: 'website',
      site_name: 'Launchpoint',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'User Agreement | Launchpoint Legal',
      description:
        'Understand the User Agreement at Launchpoint, detailing the responsibilities and expectations for both the users and the company in providing and using our services.',
      image: STANDARD_IMAGE,
    },
  },
  veteran_owned: {
    route: EStadnickDevPages.VETERAN_OWNED,
    canonical_url: 'https://launchpoint.dev/' + EStadnickDevPages.VETERAN_OWNED + '/',
    title: 'Veteran Owned Business | Launchpoint Supporting Veterans',
    description: 'Our mission is to support fellow veterans by providing custom solutions that simplify and remove your business lag.',
    og: {
      title: 'Veteran Owned Business | Launchpoint Supporting Veterans',
      description: 'Our mission is to support fellow veterans by providing custom solutions that simplify and remove your business lag.',
      image: STANDARD_IMAGE,
      url: 'https://launchpoint.dev/' + EStadnickDevPages.VETERAN_OWNED,
      type: 'website',
      site_name: 'Launchpoint',
      locale: 'en_US',
    },
    twitter: {
      card: 'summary_large_image',
      site: '@robstadnick',
      title: 'Veteran Owned Business | Launchpoint Supporting Veterans',
      description: 'Our mission is to support fellow veterans by providing custom solutions that simplify and remove your business lag.',
      image: STANDARD_IMAGE,
    },
  },
};
