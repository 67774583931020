<div class="bg-background mx-auto px-10 pb-14 pt-24 md:max-w-[1200px] md:pt-24">
  <div class="fadeInDown relative mx-auto px-4 text-center">
    <div class="z-20">
      <h1 class="font-oswald font-oswald mt-20 text-center text-5xl font-medium text-white">BLOG</h1>
    </div>
  </div>

  <!-- @if (_LaunchpointBlogService.blogCategories().length > 0) {
    <div
      class="no-scrollbar mt-6 flex w-[90%] items-center justify-between space-x-4 overflow-x-scroll rounded-full border border-white/15 md:w-[40%]"
    >
      <div
        (click)="selectCategory('all', $event)"
        class="category-span flex-shrink-0 flex-grow-0 basis-24 text-center"
        [ngClass]="{ 'bg-[#CAECF1]/15': SelectedCategory === 'all' }"
      >
        All
      </div>
      <ng-container *ngFor="let category of _LaunchpointBlogService.blogCategories()">
        <div
          (click)="selectCategory(category, $event)"
          class="category-span flex-shrink-0 flex-grow-0 basis-24 text-center"
          [ngClass]="{ 'bg-[#CAECF1]/15': SelectedCategory === category }"
        >
          {{ category | titlecase }}
        </div>
      </ng-container>
    </div>
  }

  <div class="mt-10 flex w-full justify-end">
    <div class="relative w-full sm:w-auto sm:min-w-[320px] md:w-80 xl:w-[400px]">
      <i class="fa-solid fa-magnifying-glass absolute left-4 top-1/2 -translate-y-1/2 text-gray-400"></i>
      <input type="search" [formControl]="blogSearch" class="h-10 w-full rounded-full bg-white py-2 pl-10 pr-5 text-black" placeholder="Search" />
    </div>
  </div> -->
</div>
