export interface LaunchpointLayoutFooters {
  width: 'fixed' | 'fluid';
  title: string;
  links: {
    title: string;
    href?: string;
    target?: '_blank' | '_self' | '_top' | '_blank';
    page?: string;
  }[];
}
