import { Component } from '@angular/core';
import { LaunchpointCoreClientAccountsBaseComponent } from '@launchpoint/core-client';
import { ICoreUserAccount, ICoreUserAccountsParamsRemove, ICoreUserAccountsParamsResendInvite } from '@launchpoint/core-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreWebAccountsAddSmartAdminComponent } from '../../accounts-add/accounts-add-smart-admin/accounts-add-smart-admin.component';
import { LaunchpointCoreWebAccountsChangeSecurityRolesAdminComponent } from '../../accounts-change-security-roles-admin/accounts-change-security-roles-admin.component';
import { LaunchpointSweetAlertService } from '../../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss'],
})
export class LaunchpointCoreWebAccountsTableComponent extends LaunchpointCoreClientAccountsBaseComponent {
  constructor(public modalService: NgbModal, private _swalService: LaunchpointSweetAlertService) {
    super();
  }

  removeAccountV1(account: ICoreUserAccount) {
    this._swalService
      .fireAlert({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: ' #d33',
        cancelButtonColor: '#CACACA',
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._swalService
            .fireAlert({
              html: `To remove please type <b class="text-danger">${account.name}</b>, to confirm removal.`,
              input: 'text',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: ' #d33',
              cancelButtonColor: '#CACACA',
              confirmButtonText: 'Remove it!',
              customClass: {
                validationMessage: 'my-validation-message',
              },
              preConfirm: (value) => {
                if (!value) {
                  this._swalService.Swal.showValidationMessage(' Their name is required');
                }
                if (value != account.name) {
                  this._swalService.Swal.showValidationMessage(' Name not entered correctly, please try again.');
                }
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                this._swalService.Swal.fire('Working on it!', 'This account is being removed.');
                const data: Omit<ICoreUserAccountsParamsRemove, 'requesting_user_id'> = {
                  /**
                   * _id of the ACCOUNT OBJECT
                   * It should be account._id
                   * NOT account.account_id
                   */

                  _id: account._id,
                };
                this.emitRemoveAccount.emit(data);
              }
            });
        }
      });
  }

  addAccount() {
    const modalRef = this.modalService.open(LaunchpointCoreWebAccountsAddSmartAdminComponent);
  }

  resendInvite(account_id: string) {
    this._swalService
      .fireAlert({
        title: 'Sending Email?',
        text: 'This email cannot be unsent, are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, send it it.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          const data: Omit<ICoreUserAccountsParamsResendInvite, 'user_id'> = {
            /**
             * account._id NOT account.account_id, this is so I
             * know which account from the parent user to resennd
             * the invite for
             */

            account_id,
          };
          console.log('emitting resending account invite');
          this.emitResendAccountInvite.emit(data);
        }
      });
  }

  immediatelyAddAccount(account_id: string) {
    this._swalService
      .fireAlert({
        title: 'Are you sure?',
        text: 'Immediately adding an account will not give the account being added a choice.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, add it.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          // const data: ICoreUserAccountsParamsAcceptInvitations = {
          //   /**
          //    * _id of the user who wants to accept the invitations
          //    */
          //   _id: account_id,
          //   /**
          //    * A list of only the invitations we want
          //    */
          //   invitations: [], //ICoreUserAccountsUserInvitationsDecision[];
          // };
          // this.emitAcceptAccountInvitation.emit(data);
        }
      });
  }

  changeAccountSecurityRoles(account: ICoreUserAccount) {
    // Account security roles  are different than the 'base' security roles for the platform like super admin.
    const modalRef = this.modalService.open(LaunchpointCoreWebAccountsChangeSecurityRolesAdminComponent);
    modalRef.componentInstance.account = account;
    modalRef.componentInstance.user_id = this.user_id;
  }
}
