import { createReducer, on } from '@ngrx/store';
import { DefaultDynamicAsideMenuConfig } from '../../config/default-menus/default-dynamic-aside-menu.config';
import { DefaultDynamicHeaderMenuConfig } from '../../config/default-menus/default-dynamic-header-menu.config';
import * as MenuActions from '../actions/layout.actions';
import { MenuState } from './menu.interface';

export const initialState: MenuState = {
  // set initial required properties
  asideMenu: DefaultDynamicAsideMenuConfig,
  headerMenu: DefaultDynamicHeaderMenuConfig,
  loaded: true,
  topbar: {
    logo_dark: './assets/media/logos/logo-2.svg',
    logo_light: './assets/media/logos/logo-2.svg',
    search: false,
    activities: true,
    notifications: true,
    chat: false,
    quick_links: false,
    explore_drawer: false,
    theme_switch: true,
    user_inner: {
      active: true,
      default_image: './assets/media/avatars/150-2.jpg',
      menu: {
        items: [
          {
            title: 'CUSTOM',
            badge: null,
            page: '/dashboard',
          },
        ],
      },
    },
  },
  footer: {
    title: 'Launchpoint',
    width: 'fluid',
    links: [
      {
        title: 'About',
        href: '/about',
      },
    ],
  },
};

export const menuTopbarReducer = createReducer(
  initialState,
  on(MenuActions.loadAsideMenu, (state, { asideMenu }) => ({ ...state, asideMenu, loaded: true, error: null })),
  on(MenuActions.loadHeaderMenu, (state, { headerMenu }) => ({ ...state, headerMenu, loaded: true, error: null })),
  on(MenuActions.loadTopbar, (state, { topbarConfig }) => ({ ...state, topbar: topbarConfig, loaded: true, error: null })),
  on(MenuActions.loadFooter, (state, { footerConfig }) => ({ ...state, footer: footerConfig, loaded: true, error: null }))
  // on(MenuActions.loadMenuFailure, (state, { error }) => ({ ...state, error }))
);
