import { IEventDimensions } from '../pinpoint-campaign/aws-campaign.interface';
import { ISegmentDimensions } from '../pinpoint-segment/aws-segment.interface';

export enum EJourneyState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  PAUSED = 'PAUSED',
}

export enum EDayOfWeek {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum EJourneyEndpointTypes {
  ADM = 'ADM',
  APNS = 'APNS',
  APNS_SANDBOX = 'APNS_SANDBOX',
  APNS_VOIP = 'APNS_VOIP',
  APNS_VOIP_SANDBOX = 'APNS_VOIP_SANDBOX',
  BAIDU = 'BAIDU',
  CUSTOM = 'CUSTOM',
  EMAIL = 'EMAIL',
  GCM = 'GCM',
  IN_APP = 'IN_APP',
  PUSH = 'PUSH',
  SMS = 'SMS',
  VOICE = 'VOICE',
}

// implements Activity
export class IActivity {
  custom?: ICustomMessageActivity;
  conditional_split?: IConditionalSplitActivity;
  contact_center?: { next_activity: string };
  description?: string;
  email?: IEmailMessageActivity;
  holdout?: {
    next_activity?: string;
    percentage: number;
  };
  multi_condition?: IMultiConditionalSplitActivity;
  push?: IPushMessageActivity;
  random_split?: IRandomSplitActivity;
  sms?: ISMSMessageActivity;
  wait?: IWaitActivity;
}

// implements WaitActivity
export class IWaitActivity {
  next_activity?: string;
  wait_time?: IWaitTime;
}

//  implements SMSMessageActivity
export class ISMSMessageActivity {
  message_config?: IJourneySMSMessage;
  next_activity?: string;
  template_name?: string;
  template_version?: string;
}

//  implements JourneySMSMessage
export class IJourneySMSMessage {
  entity_id?: string;
  message_type?: 'PROMOTIONAL' | 'TRANSACTIONAL';
  origination_number?: string;
  sender_id?: string;
  template_id?: string;
}

//  implements RandomSplitActivity
export class IRandomSplitActivity {
  branches?: {
    next_activity?: string;
    percentage?: number;
  };
}

// implements PushMessageActivity
export class IPushMessageActivity {
  message_config?: { time_to_live?: string };
  next_activity?: string;
  template_name?: string;
  template_version?: string;
}

//  implements MultiConditionalSplitActivity
export class IMultiConditionalSplitActivity {
  branches?: IMultiConditionalBranch[];
  default_activity?: string;
  evaluation_wait_time?: IWaitTime;
}

// implements MultiConditionalBranch
export class IMultiConditionalBranch {
  condition?: ISimpleCondition;
  next_activity?: string;
}

// implements EmailMessageActivity
export class IEmailMessageActivity {
  message_config?: { from_address?: string };
  next_activity?: string;
  template_name?: string;
  template_version?: string;
}

// implements CustomMessageActivity
export class ICustomMessageActivity {
  delivery_uri?: string;
  endpoint_types?: EJourneyEndpointTypes[];
  message_config?: { data?: string };
  next_activity?: string;
  template_name?: string;
  template_version?: string;
}

// implements ConditionalSplitActivity
export class IConditionalSplitActivity {
  condition?: ICondition;
  evaluation_wait_time?: IWaitTime;
  false_activity?: string;
  true_activity?: string;
}

// implements Condition
export class ICondition {
  conditions?: ISimpleCondition[];
  operator?: 'ALL' | 'ANY';
}

// implements SimpleCondition
export class ISimpleCondition {
  event_condition?: IEventCondition;
  segment_condition?: { segment_id: string };
  segment_dimensions?: ISegmentDimensions;
}

// implements EventCondition
export class IEventCondition {
  dimensions?: IEventDimensions;
  message_activity?: string;
}

// implements JourneyLimits
export class IJourneyLimits {
  daily_cap?: number;
  endpoint_reentry_cap?: number;
  messages_per_second?: number;
  endpoint_reentry_interval?: string;
  timeframe_cap?: IJourneyTimeframeCap;
  total_cap?: number;
}

//  implements JourneyTimeframeCap
export class IJourneyTimeframeCap {
  cap?: number;
  days?: number;
}

// implements JourneySchedule
export class IJourneySchedule {
  end_time?: Date;
  start_time?: Date;
  timezone?: string;
}

//  implements StartCondition
export class IStartCondition {
  description?: string;
  event_start_condition?: IEventStartCondition;
  segment_start_condition?: {
    segment_id: string;
  };
}

// implements EventStartCondition
export class IEventStartCondition {
  event_filter?: IEventFilter;
  segment_id?: string;
}

// implements EventFilter
export class IEventFilter {
  dimensions: IEventDimensions;
  filter_type: 'ENDPOINT' | 'SYSTEM';
}

// implements JourneyChannelSettings
export class IJourneyChannelSettings {
  connect_campaign_arn?: string;
  connect_campaign_execution_role_arn?: string;
}

//  implements OpenHours
export class IOpenHours {
  custom?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
  default?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
  email?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
  push?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
  sms?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
  voice?: Partial<Record<EDayOfWeek, IOpenHoursRule[]>>;
}

// implements OpenHoursRule
export class IOpenHoursRule {
  end_time?: string;
  start_time?: string;
}

// implements ClosedDays
export class IClosedDays {
  custom?: IClosedDaysRule[];
  email?: IClosedDaysRule[];
  push?: IClosedDaysRule[];
  sms?: IClosedDaysRule[];
  voice?: IClosedDaysRule[];
}

// implements ClosedDaysRule
export class IClosedDaysRule {
  end_date_time?: string;
  start_date_time?: string;
  name?: string;
}

// implements WaitTime
export class IWaitTime {
  wait_for?: string;
  wait_until?: string;
}
