import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActionMenuClientComponent, LaunchpointSecurityModule } from '@launchpoint/core-client';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule, LaunchpointSecurityModule, NgbDropdownModule, NgbTooltipModule],
  selector: 'launchpoint-core-web-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss'],
})
export class LaunchpointCoreWebActionsMenuWebComponent extends ActionMenuClientComponent {}
