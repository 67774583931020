import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationsTopicsBaseComponent } from '@launchpoint/core-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebNotificationTopicModalComponent } from '../notifications-topic-modal/notifications-topics-modal.component';

@Component({
  selector: 'launchpoint-notifications-topics-toolbar',
  templateUrl: './notifications-topics-toolbar.component.html',
  styleUrls: ['./notifications-topics-toolbar.component.scss'],
})
export class LaunchpointCoreWebNotificationTopicsToolbarComponent extends LaunchpointCoreClientNotificationsTopicsBaseComponent {
  modalRef: NgbModalRef;
  private modalService = inject(NgbModal);
  private _swal = inject(LaunchpointSweetAlertService);

  options: IToolbarConfig = {
    title: 'Topics',
    formControl: this.form_control,
    create: true,
    searchPlaceholder: 'Search topics',
  };

  open() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebNotificationTopicModalComponent, { size: 'md' });
  }

  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
