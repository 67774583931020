<div class="card-header border-0 py-5" [ngClass]="'bg-' + chartColor">
  <h3 class="card-title fw-bolder text-white">Sales Statistics</h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button type="button" class="
        btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-
        border-0
        me-n3
      " data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'" class="svg-icon svg-icon-2"></span>
    </button>
    <launchpoint-app-dropdown-menu1></launchpoint-app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body p-0">
  <!-- begin::Chart -->
  <div #chartRef class="mixed-widget-2-chart card-rounded-bottom" [ngClass]="'bg-' + chartColor">
    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend" [fill]="chartOptions.fill" [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip" [colors]="chartOptions.colors" [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"></apx-chart>
  </div>
  <!-- end::Chart -->
  <!-- begin::Stats -->
  <div class="card-p mt-n20 position-relative">
    <!-- begin::Row -->
    <div class="row g-0">
      <!-- begin::Col -->
      <div class="col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7">
        <span [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'"
          class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"></span>
        <a href="#" class="text-warning fw-bold fs-6"> Weekly Sales </a>
      </div>
      <!-- end::Col -->
      <!-- begin::Col -->
      <div class="col bg-light-primary px-6 py-8 rounded-2 mb-7">
        <span [inlineSVG]="'./assets/media/icons/duotune/finance/fin006.svg'"
          class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"></span>
        <a href="#" class="text-primary fw-bold fs-6"> New Projects </a>
      </div>
      <!-- end::Col -->
    </div>
    <!-- end::Row -->
    <!-- begin::Row -->
    <div class="row g-0">
      <!-- begin::Col -->
      <div class="col bg-light-danger px-6 py-8 rounded-2 me-7">
        <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs027.svg'"
          class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"></span>
        <a href="#" class="text-danger fw-bold fs-6 mt-2"> Item Orders </a>
      </div>
      <!-- end::Col -->
      <!-- begin::Col -->
      <div class="col bg-light-success px-6 py-8 rounded-2">
        <span [inlineSVG]="'./assets/media/icons/duotune/communication/com010.svg'"
          class="svg-icon svg-icon-3x svg-icon-success d-block my-2"></span>
        <a href="#" class="text-success fw-bold fs-6 mt-2"> Bug Reports </a>
      </div>
      <!-- end::Col -->
    </div>
    <!-- end::Row -->
  </div>
  <!-- end::Stats -->
</div>