import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  ECoreUserAccountStatus,
  ICoreCompany,
  ICoreUser,
  ICoreUserAccount,
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  ICoreUserSearchQuery,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

export interface ICoreUserAccountsFE extends Omit<ICoreUserAccount, 'user_id' | 'account_id'> {
  user_id: ICoreUser;
  account_id: ICoreCompany;
}

@Directive()
export class LaunchpointCoreClientAccountsBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  @Input() user_id: string;
  @Input() users_accounts: ICoreUserAccountsFE[];
  @Input() isAdmin = false;
  @Input() pagination: IQueryPageination;
  @Input() querySort: IQuerySort;
  @Input() query: ICoreUserSearchQuery;
  @Input() error: IHttpException;
  @Input() loading = false;
  @Input() options = {
    actions: true,
  };

  form_control = new FormControl<string>('');
  ENUMCoreUserAccountStatus = ECoreUserAccountStatus; // needed for web html at least do not remove
  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() queryPagination = new EventEmitter<IQueryPageination>();
  @Output() queryUsers = new EventEmitter<string>();
  @Output() emitRemoveAccount = new EventEmitter<Omit<ICoreUserAccountsParamsRemove, 'requesting_user_id'>>();
  @Output() emitResendAccountInvite = new EventEmitter<Omit<ICoreUserAccountsParamsResendInvite, 'user_id'>>();
  @Output() emitAcceptAccountInvitation = new EventEmitter<ICoreUserAccountsParamsAcceptInvitations>();
  @Output() emitChangeAccountRoles = new EventEmitter<any>();

  // constructor() {
  //   super();
  // }users_accounts

  ngOnInit() {
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
  }

  sortData(event: IQuerySort) {
    this.sortDataEmitter.emit(event);
  }

  runQuery(search: string) {
    this.queryUsers.emit(search);
  }

  pageChange(queryPagination: IQueryPageination) {
    const pagination: IQueryPageination = {
      count: this.pagination.count,
      limit: queryPagination.limit,
      skip: Number(queryPagination.pageIndex) * Number(queryPagination.limit),
      pageIndex: queryPagination.pageIndex,
      previousPageIndex: queryPagination.previousPageIndex,
    };
    this.queryPagination.emit(pagination);
  }

  // async paginate(page: number) {
  //   const pagination = await firstValueFrom(this.pagination$);
  //   if (pagination.pageIndex === page - 1) {
  //     return;
  //   }
  //   const data: IQueryPageination = {
  //     ...pagination,
  //     pageIndex: page - 1,
  //     skip: pagination.limit * (page - 1),
  //   };
  //   this._Store.dispatch(updateAllUsersPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  // }

  // async limit(limit: number) {
  //   const pagination = await firstValueFrom(this.pagination$);
  //   this._Store.dispatch(updateAllUsersPagination({ pagination: { ...pagination, limit } }));
  // }
}
