export interface ICLOUDFRONT_1 {
  /**
   * this is the cloudfront domain given
   * @example 'https://d3nefsjxfzaoni.cloudfront.net/',
   */
  domain: string;
  /**
   * This is the url to your CDN. Convention is
   * @example  media.DOMAIN.com
   */
  url: string;
  bucket: string;
  acl: 'public-read' | 'private';
  public_prefix: string;
  private_prefix: string;
  region: string;
}
