import { ObjectCannedACL } from '@aws-sdk/client-s3';
export interface IContentUploadQueryParams {
  acl?: ObjectCannedACL; // 'private' | 'public-read' | 'public-read-write' | string;
  /**
   * Key value pairs for AWS account to store on object
   * Limit is 10 pairs
   * @default user_id from JWT is automatically added
   */
  metadata?: { [key: string]: string };
  /**
   * Key value pairs for AWS account to store on object
   * Limit is 10 pairs
   * @default user_id from JWT is automatically added
   */
  tags?: { [key: string]: string };
}
