<div class="modal-header  justify-content-end">
    <h4 class="modal-title">{{banner ? 'Update' : 'Create'}} Banner</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>

<div class="modal-body">


    <div [class]="getDemoClass">
        {{ formMessage || 'This is a test message' }}
    </div>

    <div class="d-flex justify-content-between flex-column">
        <!--Form starts here-->
        <form class="form-group" [formGroup]="form">

            <div class="px-1 mb-5">
                <label for="message" class="mb-1 fw-semibold me-5 required">Message</label>
                <textarea name="message" formControlName="message" class="form-control" placeholder="Type Here...."
                    rows="3"></textarea>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Message is required',
                    control: form.controls.message
                }"></ng-container>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'minlength',
                    message: 'Message must be at least 3 characters',
                    control: form.controls.message
                }"></ng-container>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'maxlength',
                    message: 'Message cannot be more than 250 characters',
                    control: form.controls.message
                }"></ng-container>
            </div>


            <div class="px-1 mb-5 d-flex flex-row gap-5">
                <div class="col-5">
                    <label class="mb-1 fw-semibold me-5 required">Display Start Date</label>
                    <div class="d-flex flex-row justify-content-between">
                        <input type="datetime-local" formControlName="display_range_start_date" class="form-control" />
                    </div>

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Start date is required',
                        control: form.controls.display_range_start_date
                    }"></ng-container>
                </div>

                <div class="col-5">
                    <label class="mb-1 fw-semibold me-5 required">Display End Date</label>
                    <div class="d-flex flex-row justify-content-between">
                        <input type="datetime-local" formControlName="display_range_end_date" [min]="minDateTime"
                            class="form-control" />
                    </div>
                    @if (endDateError$()){
                    <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                            <span role="alert">{{ endDateError$() }}</span>
                        </div>
                    </div>
                    }
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'End date is required',
                        control: form.controls.display_range_end_date
                    }"></ng-container>
                </div>
            </div>


            @if (admin) {
            <div class="px-1 mb-5 d-flex flex-row gap-5">
                <div class="col-5">
                    <label class="mb-1 fw-semibold me-5 required">Banner Color</label>
                    <select formControlName="banner_color" class="form-select">
                        <option disabled [value]="null">Select a color</option>
                        <option *ngFor="let color of BANNER_TEXT_COLORS" [value]="color">{{ color }}</option>
                    </select>
                </div>

                <div class="col-5">
                    <label class="mb-1 fw-semibold me-5 required">Text Color</label>
                    <select formControlName="text_color" class="form-select">
                        <option disabled [value]="null">Select a color</option>
                        <option *ngFor="let color of BANNER_TEXT_COLORS" [value]="color">{{ color }}</option>
                    </select>
                </div>
            </div>


            <div class="px-1 mb-5">
                <div class="d-flex align-items-center">
                    <label for="security_roles" class="mb-1 fw-semibold">Security Roles</label>
                    <i class="bi bi-info-circle fs-5 ms-5"
                        ngbTooltip="Message will only be visible to users with these security roles. If empty, everyone can see it."
                        placement="right"></i>
                </div>
                <tagify [settings]="settings" [whitelist]="whitelistSecurityRoles$" formControlName="security_roles"
                    name="security_roles" class="me-5" inputClass="form-control form-control-sm ps-4 bg-white" />
            </div>
            }


            <div class="px-1 mb-5">
                <label class="form-label fw-semibold me-5">Active</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                        formControlName="active" />
                </div>
            </div>


            <div class="d-flex align-items-center modal-footer px-0 pb-0" [ngClass]="{
                'justify-content-between': banner,
                'justify-content-end': !banner,
            }">
                @if (banner) {
                <button class="btn btn-icon text-danger text-decoration-underline mb-2 mt-5 mx-4"
                    (click)="deleteBanner()">Delete</button>
                }

                <div>
                    <button class="btn btn-secondary mb-2 mt-5 mx-4" (click)="dismiss()">Cancel</button>
                    <button type="submit" (click)="submit()" [disabled]="form.invalid || form.pristine"
                        class="btn btn-primary mb-2 mt-5">

                        @if ((loading$ | async) === false){
                        <span class="indicator-label">
                            {{ banner ? 'Save' : 'Create' }}
                        </span>
                        } @else {
                        <span class="indicator-label">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                        }

                    </button>
                </div>
            </div>
        </form>
        <!--Form ends here-->
    </div>

    <!--Modal Ends-->

    <!--start::FormError-->
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
        <ng-container
            *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
            <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                    <span role="alert">{{ message }}</span>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <!--end::FormError-->