import { Directive, OnInit } from '@angular/core';
import { BlogsActions } from '../+state/actions';
import { LaunchpointCoreClientBlogsStateBaseComponent } from '../+state/blogs-state-base.component';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientBlogsBaseComponent extends LaunchpointCoreClientBlogsStateBaseComponent implements OnInit {
  form_control = new FormControl<string>('');

  ngOnInit(): void {
    this._Store.dispatch(BlogsActions.searchAllBlogs());
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery({ search }),
      error: (error) => console.log('ERROR', error),
    });
    this.success();
    this.error();
  }
}
