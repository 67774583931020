import { Directive, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ELaunchpointGeo, ICoreUserAddresses } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../components/base/base.component';
import { EUserAddressTitle } from '../../features/user/core/core-address/interfaces/core-address.interface';
import { AppSettingsCountriesService, ICountries } from '../../features/user/core/core-address/services/autocomplete/app-settings-countries.service';
import { GoogleAddressService } from '../../features/user/core/core-address/services/autocomplete/address-autocomplete.service';
import { IUSState, UsStates } from '../../features/user/core/core-address/services/autocomplete/states.constants';

interface IProfileAddressConfig {
  title: string;
  fields?: {
    address: boolean;
    google_maps_api_key: string; // I don't think this is needed since each app while have in their index.html their api key for google maps
  };
}

interface GoogleAddress {
  line_one: string;
  line_two: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  long: number;
  lat: number;
  location: {
    type: ELaunchpointGeo.POINT;
    /**
     * list the longitude first, and then latitude.
     */
    coordinates: [number, number];
  };
}

@Directive()
export abstract class LaunchpointCoreWebAddressAutoCompleteBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  @Input() _config: IProfileAddressConfig = {
    title: 'Billing Address',
    fields: {
      address: true,
      google_maps_api_key: '', // I don't think this is needed since each app while have in their index.html their api key for google maps
    },
  };
  @Input() address: ICoreUserAddresses;
  @Input() address_type: EUserAddressTitle;
  fb = inject(UntypedFormBuilder);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('receive_address_text') receive_address_text: any;

  place!: unknown;
  country_invalid = false;
  formatted_address: string;
  address_form!: FormGroup;
  google_address!: GoogleAddress;
  unitedStatesStates: IUSState[] = UsStates;
  countryListFull: ICountries[];
  countryList = ['United States', 'Mexico', 'Type for more options'];
  isLoading = false;
  countryObject;

  @Output() addressChange = new EventEmitter<Omit<ICoreUserAddresses, '_id'>>();

  constructor(
    public googleAddressService: GoogleAddressService,
    public formBuilder: FormBuilder,
    public _AppSettingsCountriesService: AppSettingsCountriesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeAddressForm();
    this.countryListFull = this._AppSettingsCountriesService.getCountries();
  }

  initializeAddressForm() {
    // console.log({ address: this.address });
    const initialAddress: GoogleAddress = {
      line_one: null,
      line_two: null,
      city: null,
      state: null,
      country: null,
      zip_code: null,
      lat: null,
      long: null,
      location: null,
    };
    this.google_address = initialAddress;
    this.address_form = this.fb.group({
      line_one: new FormControl(this.address?.line_one, [Validators.required, Validators.maxLength(200)]),
      line_two: new FormControl(this.address?.line_two),
      city: new FormControl(this.address?.city, [Validators.required, Validators.maxLength(100)]),
      state: new FormControl(this.address?.state, [Validators.required]),
      zip_code: new FormControl(this.address?.zip_code, [Validators.required, Validators.maxLength(15)]),
      country: new FormControl(this.address?.country, [Validators.required, Validators.maxLength(2)]),
      long: new FormControl(this.address?.long),
      lat: new FormControl(this.address?.lat),
      location: new FormControl(this.address?.location),
    });
  }
  get state() {
    return this.address_form.get('state');
  }
  get country() {
    return this.address_form.get('country');
  }
  get line_one() {
    return this.address_form.get('line_one');
  }
}
