import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CompanyV2Service } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { CompanyTagifyBaseWebComponent } from './company-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [CompanyV2Service],
  selector: 'launchpoint-company-tagify',
  templateUrl: 'company-tagify.component.html',
})
export class CompanyTagifyWebComponent extends CompanyTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
