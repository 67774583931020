import {
  ICoreCompany,
  ICoreCompanyParamsCreate,
  ICoreCompanyParamsCreateResults,
  ICoreCompanyParamsSignUp,
  ICoreCompanyParamsSignUpResults,
  ICoreCompanyParamsUpdate,
  ICoreCompanySearchQuery,
  ICoreCompanySearchResults,
  ICoreUserSearchQuery,
  ICoreUserSearchResults,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllCompanies = createAction('[lp-companies] searchAllCompanies');
export const searchAllCompaniesSuccess = createAction(
  '[lp-companies] searchAllCompaniesSuccess',
  props<{ data: ICoreCompanySearchResults; success_message?: string }>()
);

export const searchAllCompaniesFailure = createAction('[lp-companies] searchAllCompaniesFailure', props<{ error: IHttpException }>());

// Search USERS
export const searchAllCompanyUsers = createAction('[lp-companies] searchAllCompanyUsers', props<{ query: ICoreUserSearchQuery }>());
export const searchAllCompanyUsersSuccess = createAction(
  '[lp-companies] searchAllCompanyUsersSuccess',
  props<{ data: ICoreUserSearchResults; company_id: string; success_message?: string }>()
);
export const searchAllCompanyUsersFailure = createAction('[lp-companies] searchAllCompanyUsersFailure', props<{ error: IHttpException }>());
export const updateAllCompanyUsersPagination = createAction(
  '[lp-companies] updateAllCompanyUsersPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllCompanyUsersSorting = createAction('[lp-companies] updateAllCompanyUsersSorting', props<{ querySort: IQuerySort }>());

// Mechanics
export const updateAllCompaniesPagination = createAction('[lp-companies] updateAllCompaniesPagination', props<{ pagination: IQueryPageination }>());
export const updateAllCompaniesPaginationScroll = createAction(
  '[lp-companies] updateAllCompaniesPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllCompaniesSorting = createAction('[lp-companies] updateAllCompaniesSorting', props<{ querySort: IQuerySort }>());
export const updateAllCompaniesQuery = createAction('[lp-companies] updateAllCompaniesQuery', props<{ query: ICoreCompanySearchQuery }>());

// getById
export const getCompanyById = createAction('[lp-companies] getCompanyById', props<{ company_id: string }>());
export const getCompanyByIdSuccess = createAction(
  '[lp-companies] getCompanyByIdSuccess',
  props<{ company: ICoreCompany; success_message?: string }>()
);
export const getCompanyByIdFailure = createAction('[lp-companies] getCompanyByIdFailure', props<{ company_id: string; error: IHttpException }>());

export const getCompanyByIdSetId = createAction('[lp-companies] getCompanyByIdSetId', props<{ company_id: string }>());

// CREATE
/**
 * This is for ADMIN
 */
export const createCompany = createAction('[lp-companies] createCompany', props<{ data: ICoreCompanyParamsCreate }>());
export const createCompanySuccess = createAction(
  '[lp-companies] createCompanySuccess',
  props<{ data: ICoreCompanyParamsCreateResults; success_message?: string }>()
);
export const createCompanyFailure = createAction('[lp-companies] createCompanyFailure', props<{ error: IHttpException }>());

/**
 * This creates a company and updates the AUTH user with the company on their account. Used for sign up flows.
 * DISPATCHES;
 *
 *
 *  UserAuthActions.updateUserSuccess({ user: data.user }),
 *  UserAuthActions.getUserNewUserFromRefreshToken(),
 */
export const createCompanySignUp = createAction('[lp-companies] createCompanySignUp', props<{ data: ICoreCompanyParamsSignUp }>());
export const createCompanySignUpSuccess = createAction(
  '[lp-companies] createCompanySignUpSuccess',
  props<{ data: ICoreCompanyParamsSignUpResults; success_message?: string }>()
);
export const createCompanySignUpFailed = createAction('[lp-companies] createCompanySignUpFailed', props<{ error: IHttpException }>());

// UPDATE
export const updateCompany = createAction('[lp-companies] updateCompany', props<{ data: ICoreCompanyParamsUpdate }>());
export const updateCompanySuccess = createAction('[lp-companies] updateCompanySuccess', props<{ company: ICoreCompany; success_message?: string }>());
export const updateCompanyFailure = createAction('[lp-companies] updateCompanyFailure', props<{ company_id: string; error: IHttpException }>());

// DEACTIVATE
export const deactivateCompany = createAction('[lp-companies] deactivateCompany', props<{ data: ICoreCompanyParamsUpdate }>());

// DELETE
export const deleteCompany = createAction('[lp-companies] deleteCompany', props<{ company_id: string }>());
export const deleteCompanySuccess = createAction('[lp-companies] deleteCompanySuccess', props<{ data: ICoreCompany; success_message?: string }>());
export const deleteCompanyFailure = createAction('[lp-companies] deleteCompanyFailure', props<{ company_id: string; error: IHttpException }>());

export const deleteCompanyInfo = createAction('[lp-companies] deleteCompanyInfo', props<{ company_id: string }>());

// ADD BANK ACCOUNT
// export const addCompanyBankAccount = createAction(
//   '[lp-companies] addCompanyBankAccount',
//   props<{ data: ICoreCompanyStripeCustomerSourceParamsCreate }>()
// );

// export const addCompanyBankAccountSuccess = createAction(
//   '[lp-companies] addCompanyBankAccountSuccess',
//   props<{ company: ICoreCompany; success_message?: string }>()
// );

// export const addCompanyBankAccountFailure = createAction(
//   '[lp-companies] addCompanyBankAccountFailure',
//   props<{ company_id: string; error: IHttpException }>()
// );

/**
 * CLOSE ALERT MESSAGES
 */
export const closeCompanySuccessMessage = createAction('[lp-companies] closeCompanySuccessMessage');

export const closeCompanyErrorMessage = createAction('[lp-companies] closeCompanyErrorMessage');
