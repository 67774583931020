import { Component } from '@angular/core';
import { SkeletonLoadersBaseComponent } from '../skeleton-loaders.base-component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  selector: 'launchpoint-skeleton-loaders',
  templateUrl: 'skeleton-loaders.component.html',
  styleUrls: ['skeleton-loaders.scss'],
})
export class SkeletonLoadersComponent extends SkeletonLoadersBaseComponent {
  constructor() {
    super();
  }
}
