import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import * as BlogsActions from '../actions/blogs.actions';
import * as BlogsSelectors from '../selectors/blogs.selectors';

@Injectable()
export class GetSelectedBlogGuard implements CanActivate {
  constructor(
    private _Store: Store,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.setSelectedBlogId(route.params.id).pipe(
      switchMap((loaded) => this.selectedBlogInState(route.params.id)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          BlogsActions.getBlogByIdFailure({
            blog_id: route.params.id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }

  setSelectedBlogId(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(BlogsSelectors.selectBlogsId).pipe(
      switchMap((selected) => {
        if (selected !== _id) {
          this._Store.dispatch(BlogsActions.getBlogById({ blog_id: _id }));
          return of(false);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  selectedBlogInState(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(BlogsSelectors.selectBlogsEntities).pipe(
      switchMap((entities) => {
        if (!entities[_id]) {
          return this.dispatchBlog(_id);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  dispatchBlog(_id: string): Observable<boolean> {
    if (!_id) {
      return of(false);
    }
    this._Store.dispatch(BlogsActions.getBlogById({ blog_id: _id }));
    return of(false);
  }
}
