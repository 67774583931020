import {
  IHttpException,
  ITopic,
  ITopicParamsCreate,
  ITopicParamsUpdate,
  ITopicSearchResults,
  IQueryPageination,
  IQuerySort,
  ITopicSearchQuery,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// getById
export const getTopicById = createAction('[LP-Notification-Topic] getTopicById', props<{ topic_id: string }>());
export const getTopicByIdSuccess = createAction('[LP-Notification-Topic] getTopicByIdSuccess', props<{ topic: ITopic; success_message?: string }>());
export const getTopicByIdFailure = createAction('[LP-Notification-Topic] getTopicByIdFailure', props<{ topic_id: string; error: IHttpException }>());

// Search
export const searchAllTopics = createAction('[LP-Notification-Topic] searchAllTopics');
export const searchAllTopicsSuccess = createAction(
  '[LP-Notification-Topic] searchAllTopicsSuccess',
  props<{ data: ITopicSearchResults; success_message?: string }>()
);
export const searchAllTopicsFailure = createAction('[LP-Notification-Topic] searchAllTopicsFailure', props<{ error: IHttpException }>());

// CREATE
export const createTopic = createAction('[LP-Notification-Topic] createTopic', props<{ data: ITopicParamsCreate; redirectPath?: string[] }>());

export const createTopicSuccess = createAction(
  '[LP-Notification-Topic] createTopicSuccess',
  props<{ data: ITopic; redirectPath?: string[]; success_message?: string }>()
);
export const createTopicFailure = createAction('[LP-Notification-Topic] createTopicFailure', props<{ error: IHttpException }>());

// UPDATE
export const updateTopic = createAction('[LP-Notification-Topic] updateTopic', props<{ data: ITopicParamsUpdate }>());
export const updateTopicSuccess = createAction('[LP-Notification-Topic] updateTopicSuccess', props<{ topic: ITopic; success_message?: string }>());
export const updateTopicFailure = createAction('[LP-Notification-Topic] updateTopicFailure', props<{ topic_id: string; error: IHttpException }>());

// DELETE
export const deleteTopic = createAction('[LP-Notification-Topic] deleteTopic', props<{ id: string }>());
export const deleteTopicSuccess = createAction('[LP-Notification-Topic] deleteTopicSuccess', props<{ data: ITopic; success_message?: string }>());
export const deleteTopicFailure = createAction('[LP-Notification-Topic] deleteTopicFailure', props<{ topic_id: string; error: IHttpException }>());

// Mechanics
export const updateAllTopicPagination = createAction('[LP-Notification-Topic] updateAllTopicPagination', props<{ pagination: IQueryPageination }>());
export const updateAllTopicPaginationScroll = createAction(
  '[LP-Notification-Topic] updateAllTopicPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllTopicSorting = createAction('[LP-Notification-Topic] updateAllTopicSorting', props<{ querySort: IQuerySort }>());
export const updateAllTopicQuery = createAction('[LP-Notification-Topic] updateAllTopicQuery', props<{ query: ITopicSearchQuery }>());
