import { Component, Input } from '@angular/core';

@Component({
  selector: 'launchpoint-app-lists-widget4',
  templateUrl: './lists-widget4.component.html',
})
export class ListsWidget4Component {
  @Input() items = 6;
  // constructor() {}
}
