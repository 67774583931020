import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LaunchpointCoreClientBlogsBaseComponent, updateAllBlogsQuery } from '@launchpoint/core-client';
import { ICoreBlogSearchQuery, ILaunchpointSearchFilterDates, ITagifyTag } from '@launchpoint/core-types';
import { TagifyService } from 'ngx-tagify';
import { LaunchpointFormControlDateRangeComponent } from '../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-blogs-filter',
  templateUrl: './blogs-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebBlogsTableFilterComponent extends LaunchpointCoreClientBlogsBaseComponent implements OnInit {
  fb = inject(FormBuilder);
  tagifyService = inject(TagifyService);
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  form: FormGroup<{
    live: FormControl<boolean>;
    active: FormControl<boolean>;
    featured: FormControl<boolean>;
    tags: FormControl<ITagifyTag[]>;
    category: FormControl<ITagifyTag[]>;
    release_at: FormControl<ILaunchpointSearchFilterDates>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
  }>;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      live: [],
      active: [],
      featured: [],
      tags: [],
      category: [],
      release_at: [],
      created_at: [],
    });
  }

  clearTags() {
    this.form?.controls?.tags?.setValue([]);
    this.tagifyService.get('blog-tags')?.removeAllTags();
  }
  clearCategories() {
    this.form?.controls?.category?.setValue([]);
    this.tagifyService.get('blog-categories')?.removeAllTags();
  }

  setTags(tags: ITagifyTag[]) {
    this.form?.controls?.tags?.setValue(tags);
  }
  setCategories(tags: ITagifyTag[]) {
    this.form?.controls?.category?.setValue(tags);
  }

  setValue(control: string, value: boolean | null) {
    this.form.controls[control].setValue(value);
  }

  apply() {
    const form = this.form.value;
    const query: ICoreBlogSearchQuery = {
      ...form,
      tags: form.tags?.map((t) => t?.value) ?? [],
      category: form.category?.map((t) => t?.value) ?? [],
    };

    this.removeNullUndefined(query);
    this._Store.dispatch(updateAllBlogsQuery({ query }));
  }

  clearAll() {
    this.form.reset();

    this.tagifyService.get('blog-tags')?.removeAllTags();
    this.tagifyService.get('blog-categories')?.removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();
    this._Store.dispatch(updateAllBlogsQuery({ query: {} }));
  }
}
