import { Observable } from 'rxjs';
import {
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseParamsArchive,
  ILaunchpointDynamicFormResponseParamsCreate,
  ILaunchpointDynamicFormResponseParamsDelete,
  ILaunchpointDynamicFormResponseParamsUpdate,
  ILaunchpointDynamicFormResponseSearchPayload,
  ILaunchpointDynamicFormResponseSearchResults,
} from './response.interface';

/**
 * @route ``
 */
export interface ILaunchpointDynamicFormResponseController {
  search(
    params: ILaunchpointDynamicFormResponseSearchPayload
  ): Promise<ILaunchpointDynamicFormResponseSearchResults> | Observable<ILaunchpointDynamicFormResponseSearchResults>;

  getById(id: string): Promise<ILaunchpointDynamicFormResponse | null> | Observable<ILaunchpointDynamicFormResponse | null>;

  create(params: ILaunchpointDynamicFormResponseParamsCreate): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  update(params: ILaunchpointDynamicFormResponseParamsUpdate): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  archive(
    params: ILaunchpointDynamicFormResponseParamsArchive
  ): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;

  delete(params: ILaunchpointDynamicFormResponseParamsDelete): Promise<ILaunchpointDynamicFormResponse> | Observable<ILaunchpointDynamicFormResponse>;
}
