import { ICoreUserJWTToken } from '../auth/auth.user.interface';
import { ILaunchpointSearchFilterDates } from '../dates.interface';
import { IQueryPageination, IQuerySort } from '../query.interfaces';
import { RequireAtLeastOne } from '../require-one';
import { ICoreCompany, ICoreUser } from '../user-company-account.interface';

export interface ICoreActivityHistoryConfig<T = any> {
  /**
   * Enable or disable activity history
   * @default false
   */
  enabled?: boolean;
  /**
   * Exclude `specific events` from the activity history
   * @default []
   */
  excluded_events?: T[];
  /**
   * Include `ONLY specific events` in the activity history
   * @default null
   */
  included_events?: T[];
  /**
   * fields in the request object that should be sanitized
   * @default ['password']
   */
  sanitize_properties?: string[];
  /**
   * config for how long histories are stored in the database
   * @default { interval: 1, unit: 'years' }
   */
  expiration_config?: {
    /**
     * @default 1
     */
    interval: number;
    /**
     * @default 'years'
     */
    unit: 'hours' | 'days' | 'weeks' | 'months' | 'years';
  };
  /**
   *
   * Enable or disable sending webhooks for activity history events
   * @default false
   */
  webhooks_enabled?: boolean;
  /**
   * @default false
   */
  debug?: boolean;
}

export interface ICoreActivityHistoryBase {
  request: any;
  response: any;
  event_name: string;
  /**
   * schema name of the object where the document is
   *
   * always lowercase
   */
  object: string;

  /**
   * jwt token of the user
   */
  user: any;

  user_id: string | ICoreUser;
  account_id: string | ICoreCompany;

  origin: string;
  method: string;
  original_url: string;

  status: number;
  message: string;
  error: string;
  //
  _id: string;
  created_at: Date;
  updated_at: Date;
}

export interface ICoreActivityHistory extends ICoreActivityHistoryBase {
  account_id: ICoreCompany;
  user_id: ICoreUser;
}

export type ICoreActivityHistoryParamsCreate<T = string> = {
  request: any;
  response: any;
  event_name: T;
  /**
   * schema name of the object where the document is
   */
  object: string;
  populate?: any;

  user: ICoreUserJWTToken;

  origin: string;
  method: string;
  original_url: string;

  status: number;
  message?: string;
  error?: string;
} & RequireAtLeastOne<{ account_id: string; user_id: string }>;

export interface ICoreActivityHistorySearchQuery {
  search?: string;
  event_name?: string[];
  object?: string[];
  status?: number[];
  user_ids?: string[];
  account_ids?: string[];
  created_at?: ILaunchpointSearchFilterDates;
  method?: string[];
  original_url?: string[];
  /**
   * filter by the _id of the response object
   */
  object_ids?: string[];
  response?: {
    [key: string]: any;
  };
}

export interface ICoreActivityHistorySearchPayload {
  query: ICoreActivityHistorySearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export interface ICoreActivityHistorySearchResults {
  pagination: IQueryPageination;
  data: ICoreActivityHistory[];
}
