<div class="d-flex flex-column flex-root">
  <div class="page d-flex flex-row flex-column-fluid">
    <launchpoint-demo-1-aside
      *ngIf="asideDisplay"
      id="kt_aside"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      class="aside"
      [ngClass]="asideCSSClasses"
    >
      <launchpoint-demo-1-aside-menu id="asideMenu"></launchpoint-demo-1-aside-menu>

      <!-- <launchpoint-aside-menu id="asideMenu" [menuConfig]="items"></launchpoint-aside-menu> -->
    </launchpoint-demo-1-aside>

    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <launchpoint-demo-1-header id="kt_header" [ngClass]="headerCSSClasses" class="header align-items-stretch" #ktHeader></launchpoint-demo-1-header>

      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <ng-container *ngIf="toolbarDisplay">
          <launchpoint-demo-1-toolbar class="toolbar" id="kt_toolbar"></launchpoint-demo-1-toolbar>
        </ng-container>

        <div class="post d-flex flex-column-fluid" id="kt_post">
          <launchpoint-layout-content id="kt_content_container" [ngClass]="contentContainerClasses"></launchpoint-layout-content>
        </div>
      </div>
      <launchpoint-layout-footer class="footer py-4 d-flex flex-lg-column" id="kt_footer"></launchpoint-layout-footer>
    </div>
  </div>
</div>

<launchpoint-layout-scripts-init></launchpoint-layout-scripts-init>
<app-scroll-top></app-scroll-top>

<!-- end:: Engage -->
<app-engages></app-engages>
<!-- end:: Engage -->
