import { SECURITY_PERMISSIONS_DEFAULT_ROLE, ELaunchpointUserRoles } from '../security.constants';
import { ISecurityConfig } from '../security.interface';
import { LAUNCHPOINT_BLOG_SCHEMA } from './blog.constants';

export enum ESecurityLaunchpointBlogResources {
  BLOG_READ = 'blog:read',
  BLOG_UPDATE = 'blog:update',
  BLOG_CREATE = 'blog:create',
  BLOG_DELETE = 'blog:delete',
  BLOG_SEARCH = 'blog:search',
  BLOG_SEARCH_PUBLIC = 'blog:search-public',

  // Tags
  BLOG_TAG_ADD = 'blog:tag:add',
  BLOG_TAG_REMOVE = 'blog:tag:remove',
  BLOG_TAG_SET = 'blog:tag:set',

  // Comments
  BLOG_COMMENT_ADD = 'blog:comment:add',
  BLOG_COMMENT_REMOVE = 'blog:comment:remove',
  BLOG_COMMENT_LIKE = 'blog:comment:like',
  BLOG_COMMENT_DISLIKE = 'blog:comment:dislike',

  // Comment Replies
  BLOG_COMMENT_REPLY_ADD = 'blog:comment-reply:add',
  BLOG_COMMENT_REPLY_REMOVE = 'blog:comment-reply:remove',
  BLOG_COMMENT_REPLY_LIKE = 'blog:comment-reply:like',
  BLOG_COMMENT_REPLY_DISLIKE = 'blog:comment-reply:dislike',

  // Related Posts
  BLOG_RELATED_POST_ADD = 'blog:related-post:add',
  BLOG_RELATED_POST_REMOVE = 'blog:related-post:remove',
  BLOG_RELATED_POST_SET = 'blog:related-post:set',

  // Seo
  BLOG_SEO_UPDATE = 'blog:seo-update',
}

export const LAUNCHPOINT_BLOG_ABAC_BASE: ISecurityConfig = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [
      // {
      //   effect: 'can',
      //   permissions: [{ subject: LAUNCHPOINT_BLOG_SCHEMA, actions: ['manage'] }],
      // },
      {
        /**
         * User should be able to do anything to their OWN blogs
         */
        effect: 'can',
        permissions: [
          {
            subject: LAUNCHPOINT_BLOG_SCHEMA,
            actions: [
              ESecurityLaunchpointBlogResources.BLOG_CREATE,
              ESecurityLaunchpointBlogResources.BLOG_UPDATE,
              ESecurityLaunchpointBlogResources.BLOG_DELETE,
              ESecurityLaunchpointBlogResources.BLOG_TAG_ADD,
              ESecurityLaunchpointBlogResources.BLOG_TAG_REMOVE,
            ],
          },
        ],
        conditions: [{ author_user: '${user_id}' }],
      },
      {
        /**
         * User should be able to remove their OWN comments
         */
        effect: 'can',
        permissions: [
          {
            subject: LAUNCHPOINT_BLOG_SCHEMA,
            actions: [ESecurityLaunchpointBlogResources.BLOG_COMMENT_REMOVE],
          },
        ],
        conditions: [{ 'comment.user': '${user_id}' }],
      },
      {
        /**
         * User should be able to remove their OWN comments
         */
        effect: 'can',
        permissions: [
          {
            subject: LAUNCHPOINT_BLOG_SCHEMA,
            actions: [ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_REMOVE],
          },
        ],
        conditions: [{ 'comment.replies.user': '${user_id}' }],
      },
      {
        effect: 'can',
        permissions: [
          {
            subject: LAUNCHPOINT_BLOG_SCHEMA,
            actions: [
              ESecurityLaunchpointBlogResources.BLOG_READ,
              ESecurityLaunchpointBlogResources.BLOG_SEARCH,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_ADD,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_LIKE,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_DISLIKE,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_ADD,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_LIKE,
              ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_DISLIKE,
            ],
          },
        ],
        conditions: [{ release_at: { $lte: new Date() }, active: true }],
      },
    ],
    [ELaunchpointUserRoles.SUPER_ADMIN]: [
      {
        effect: 'can',
        permissions: [{ subject: LAUNCHPOINT_BLOG_SCHEMA, actions: ['manage'] }],
      },
    ],
  },
};
