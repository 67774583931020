import { Injectable } from '@angular/core';
import { ICoreUser, IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { RouterActions } from '../../../../../router/+state';
import { AddressHTTPService } from '../../../../core/core-address/services/core-address-http.service';
import { DemographicsHTTPService } from '../../../../core/core-demographics/services/core-demographics-http.service';
import { CoreProfileHTTPService } from '../../../../core/core-profile/services/core-profile-http.service';
import { LaunchpointCoreClientAuthHTTPService } from '../../../services';
import * as UserActions from '../../actions/user/user.actions';
import { selectUserToken } from '../../selectors/user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private _LaunchpointCoreClientAuthHTTPService: LaunchpointCoreClientAuthHTTPService,
    private _CoreProfileHTTPService: CoreProfileHTTPService,
    private _AddressHTTPService: AddressHTTPService,
    private _DemographicsHTTPService: DemographicsHTTPService,
    private _Store: Store
  ) {}

  // this._AuthRegistrationService.createUserV2({ email: this.form.value.email })

  createUserEmailOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userCreate),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.createUserV3(action.data).pipe(
          map((res) => {
            return UserActions.userCreateSuccess({ message: res.message, path: action.path, reset: action?.reset });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.userCreateFailure({ error: error.error }));
          })
        )
      )
    )
  );

  createUserEmailOnlySuccess$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.userCreateSuccess),
      switchMap((action) => {
        let navTo = action.path;
        if (!navTo) {
          navTo = ['/auth', 'verify'];
        }
        return of(RouterActions.Go({ payload: { path: navTo } }));
      })
    );
  });

  createUserEmailOnlyVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userCodeVerify),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.authVerificationTokenV3(action.data).pipe(
          map((res) => {
            return UserActions.userCodeVerifySuccess({ auth: res, returnUrl: action.returnUrl });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.userCodeVerifyFailure({ error: error.error }));
          })
        )
      )
    )
  );

  createUserEmailOnlyVerifySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.userCodeVerifySuccess),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.getUserByToken().pipe(
          map((data) => {
            return UserActions.getUserByTokenSuccess({ user: data, returnUrl: action.returnUrl });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loginFailure({ error: error.error }));
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.login(action.data).pipe(
          map((auth: any) => {
            // login returns a message if user has 2FA turned on
            if (auth?.message) {
              return UserActions.userCreateSuccess({ message: auth.message, path: ['/auth', 'verify'] });
            }

            return UserActions.loginSuccess({ auth, returnUrl: action.returnUrl });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loginFailure({ error: error.error }));
          })
        )
      )
    )
  );

  loginMagicSendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginMagicSendEmail),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.createUserV3(action.data).pipe(
          map((res) => {
            return UserActions.loginMagicSendEmailSuccess({ message: res.message, path: action.path });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loginMagicSendEmailFailure({ error: error.error }));
          })
        )
      )
    )
  );

  loginMagicSendEmailSuccess$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loginMagicSendEmailSuccess),
      switchMap((action) => {
        let navTo = action.path;
        if (!navTo) {
          navTo = ['/auth', 'verify'];
        }
        return of(RouterActions.Go({ payload: { path: navTo } }));
      })
    );
  });

  // loginSuccess$: any = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(UserActions.loginSuccess),
  //     switchMap((action: any) => {
  //       const navTo = action.returnUrl ?? ['/'];

  //       if (action.auth?.message) {
  //         return of(RouterActions.Go({ payload: { path: navTo } }));
  //       }
  //       return action;
  //     }),
  //     exhaustMap((action: any) =>
  //       this._LaunchpointCoreClientAuthHTTPService.getUserByToken().pipe(
  //         map((data) => {
  //           return UserActions.getUserByTokenSuccess({ user: data, returnUrl: action.returnUrl });
  //         }),
  //         catchError((error: IHttpRequestError) => of(UserActions.loginFailure({ error: error.error })))
  //       )
  //     )
  //   );
  // });

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      exhaustMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.getUserByToken().pipe(
          map((data) => {
            return UserActions.getUserByTokenSuccess({ user: data, returnUrl: action.returnUrl });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.loginFailure({ error: error.error })))
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      map((action) => {
        // console.log('--- IN THE LOGOUT EFFECT ---', action?.path);
        return UserActions.logoutSuccess({ path: action?.path });
      })
    )
  );

  logoutSuccess$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.logoutSuccess),
      switchMap((action) => {
        // console.log('--- IN THE LOGOUT SUCCESS EFFECT ---');
        let navTo = action.path;
        if (!navTo) {
          navTo = ['/auth', 'login'];
        }
        return of(RouterActions.Go({ payload: { path: navTo } }));
      })
    );
  });

  getUserByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserByToken),
      withLatestFrom(this._Store.select(selectUserToken)),
      concatMap(([action, token]) => {
        if (!token) {
          return of(UserActions.getUserByTokenFailure({ error: null }));
        }
        return this._LaunchpointCoreClientAuthHTTPService.getUserByToken().pipe(
          map((data) => {
            return UserActions.getUserByTokenSuccess({ user: data });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.getUserByTokenFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserActions.loginFailure({ error: error })))
    )
  );

  getUserByRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserNewUserFromRefreshToken),
      // withLatestFrom(this._Store.select(selectUserRefreshToken)),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.refreshUserToken().pipe(
          map((auth) => {
            return UserActions.getUserNewUserFromRefreshTokenSuccess({ auth });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.getUserByTokenFailure({ error: error.error })))
        )
      ),
      catchError((error) => of(UserActions.loginFailure({ error: error })))
    )
  );

  getUserByTokenFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.getUserByTokenFailure),
        map((data) => {
          return UserActions.logout({ path: ['/auth', 'login'] });
        })
      ),
    { dispatch: false }
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.forgotPassword),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.forgotPassword({ email: action.email }).pipe(
          map((data) => {
            return UserActions.forgotPasswordSuccess();
          }),
          catchError((error: IHttpRequestError) => of(UserActions.forgotPasswordFailure({ error: error.error })))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.resetPassword(action.data).pipe(
          map((data) => {
            return UserActions.resetPasswordSuccess({ success_redirect: action.success_redirect });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.resetPasswordFailure({ error: error.error })))
        )
      )
    )
  );

  resetPasswordSuccess$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.resetPasswordSuccess),
      switchMap((action) => {
        let path = action.success_redirect;
        if (!path) {
          path = ['auth', 'login'];
        }
        return of(RouterActions.Go({ payload: { path } }));
      })
    );
  });

  loggedInUserSetNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loggedInUserSetNewPassword),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.setPassword(action.data).pipe(
          map((data) => {
            return UserActions.loggedInUserSetNewPasswordSuccess({ user: data, success_message: 'Password Reset Successfully' });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.loggedInUserSetNewPasswordFailure({ error: error.error })))
        )
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUser),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.createUser(action.user).pipe(
          map((auth) => {
            return UserActions.createUserSuccess({ auth });
          }),
          catchError((error: IHttpRequestError) => {
            // console.log(error);
            return of(UserActions.createUserFailure({ error: error.error }));
          })
        )
      )
    )
  );

  createUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUserSuccess),
      switchMap((action) => {
        return this._LaunchpointCoreClientAuthHTTPService.getUserByToken().pipe(
          map((data) => {
            return UserActions.createUserLogin({ user: data });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loginFailure({ error: error.error }));
          })
        );
      }),
      catchError((error) => of(UserActions.loginFailure({ error: error })))
    )
  );

  // createUserLogin$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.createUserLogin),
  //     map((data) => {
  //       if (!data.user.email_verification.email_verified) {
  //         return RouterActions.Go({ payload: { path: [`/verify`] } });
  //       } else {
  //         return RouterActions.Go({ payload: { path: [`/sign-up`] } });
  //       }
  //     })
  //   )
  // );

  // verifyEmailSSE$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.verifyEmailSSE),
  //     mergeMap((action) =>
  //       this._EmailVerificationSSEService.SseIfVerified(action._id).pipe(
  //         // timeout(15000),
  //         map((data) => {
  //           return UserActions.getUserByToken();
  //         }),
  //         catchError((error: IHttpRequestError) => of(UserActions.verifyEmailSSEFailure({ error: error.error })))
  //       )
  //     )
  //   )
  // );

  /**
   * User Profile
   */
  loggedInUserUpdateProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loggedInUserUpdateProfile),
      switchMap((action) => {
        return this._CoreProfileHTTPService.updateProfile(action.data).pipe(
          map((user: ICoreUser) => {
            return UserActions.loggedInUserUpdateProfileSuccess({ user: user, success_message: 'Profile Updated' });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loggedInUserUpdateProfileFailure({ error: error.error }));
          })
        );
      })
    );
  });

  /**
   * User Profile Address
   */
  loggedInUserUpdateAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loggedInUserUpdateAddress),
      switchMap((action) => {
        return this._AddressHTTPService.update(action.data).pipe(
          map((user: ICoreUser) => {
            return UserActions.loggedInUserUpdateAddressSuccess({ user: user, success_message: 'Address Updated' });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loggedInUserUpdateAddressFailure({ error: error.error }));
          })
        );
      })
    );
  });
  /**
   * User Profile Address
   */
  loggedInUserCreateAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loggedInUserCreateAddress),
      switchMap((action) => {
        return this._AddressHTTPService.create(action.data).pipe(
          map((user: ICoreUser) => {
            return UserActions.loggedInUserCreateAddressSuccess({ user: user, success_message: 'Address Created' });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loggedInUserCreateAddressFailure({ error: error.error }));
          })
        );
      })
    );
  });
  /**
   * User Profile Address
   */
  loggedInUserUpdatePhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loggedInUserUpdatePhone),
      switchMap((action) => {
        // console.log('action', action.data);
        return this._DemographicsHTTPService.updatePhone(action.data).pipe(
          map((user: ICoreUser) => {
            return UserActions.loggedInUserUpdatePhoneSuccess({ user: user, success_message: 'Phone Updated' });
          }),
          catchError((error: IHttpRequestError) => {
            console.log(error);
            return of(UserActions.loggedInUserUpdatePhoneFailure({ error: error.error }));
          })
        );
      })
    );
  });
  /**
   * User Profile Phone
  //  */
  // loggedInUserUpdatePhone$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(UserActions.loggedInUserUpdatePhone),
  //     switchMap((action) =>
  //       this._DemographicsHTTPService.updatePhone(action.data).pipe(
  //         map((user: ICoreUser) => {
  //           console.log('update phone map', user);
  //           return UserActions.loggedInUserUpdatePhoneSuccess({ user: user });
  //         }),
  //         catchError((error: IHttpRequestError) => {
  //           console.log(error);
  //           return of(UserActions.loggedInUserUpdatePhoneFailure({ error: error.error }));
  //         })
  //       )
  //     )
  //   );
  // });

  loggedInUserChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loggedInUserChangePassword),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.changePassword(action.data).pipe(
          map((data) => {
            return UserActions.loggedInUserChangePasswordSuccess({
              user: data,
              success_message: 'Password has been updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.loggedInUserChangePasswordFailure({ error: error.error })))
        )
      )
    )
  );

  loggedInUserChangeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loggedInUserChangeEmail),
      switchMap((action) =>
        this._LaunchpointCoreClientAuthHTTPService.changeEmail(action.data).pipe(
          map((data) => {
            // console.log('Change Email Response', data);
            return UserActions.loggedInUserChangeEmailSuccess({
              user: data,
              success_message: 'Email has been updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserActions.loggedInUserChangeEmailFailure({ error: error.error })))
        )
      )
    )
  );
}
