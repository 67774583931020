import { createAction, props } from '@ngrx/store';

export const setLaunchpointDynamicFormGroupById = createAction(
  '[f-LaunchpointDynamicForm] getLaunchpointDynamicFormGroupById',
  props<{ _id: string; group_id: string }>()
);

// export const getLaunchpointDynamicFormGroupById = createAction(
//   '[f-LaunchpointDynamicForm] getLaunchpointDynamicFormGroupById',
//   props<{ error: IHttpException }>()
// );
