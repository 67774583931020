import { Directive } from '@angular/core';
// import { FormControl, UntypedFormGroup } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { LaunchpointCoreClientProfileConfig } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
// import {
//   EUserAccountTypes,
//   IUser,
//   IUserAccounts,
//   IUserAccountsParamsDelete,
//   IUserAccountsSecurityRolesParamsCreate,
//   IUserAccountsSecurityRolesParamsDelete,
//   SKYLAB_ACCESS_CONTROL_LIST,
//   SKYLAB_ACCESS_CONTROL_LIST_DESCRIPTIONS,
//   SKYLAB_ACCESS_CONTROL_LIST_PERMISSION_DESCRIPTIONS,
// } from '@skylabsolutions/user-types';
// import { firstValueFrom, Observable, skipWhile } from 'rxjs';
// import { addUserRole, deleteUserAccount, deleteUserRole, updateAllUsersPagination, updateAllUsersQuery } from '../../+state/actions/user.actions';
// import { selectSkylabSelectedUserAccount } from '../../+state/selectors/user-accounts.selectors';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../+state/users-state-base.component';
// import { getUserAccountsById } from '../../../account';

@Directive()
export abstract class CompanyUsersBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  // page = 1;
  // pageSize = 10;
  // filter = new FormControl('', { nonNullable: true });

  // acl = SKYLAB_ACCESS_CONTROL_LIST;
  // role_descriptions = SKYLAB_ACCESS_CONTROL_LIST_DESCRIPTIONS;
  // desciriptions = SKYLAB_ACCESS_CONTROL_LIST_PERMISSION_DESCRIPTIONS;

  constructor(public _Store: Store) {
    super(_Store);
    // this.selectedAccount$ = this._Store.select(selectSkylabSelectedUserAccount).pipe(skipWhile((f) => !f));
  }

  // ngOnInit(): void {
  //   this.selectedAccount$.subscribe({
  //     next: (accountEntity) => {
  //       this._Store.dispatch(updateAllUsersQuery({ query: { account_ids: [accountEntity.account_id] } }));
  //     },
  //   });
  // }

  // isString(val): boolean {
  //   return typeof val === 'string';
  // }

  // async paginate(page: number) {
  //   const pagination = await firstValueFrom(this.pagination$);
  //   if (pagination.pageIndex === page - 1) {
  //     return;
  //   }
  //   this._Store.dispatch(updateAllUsersPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  // }

  // async limit(limit: number) {
  //   const pagination = await firstValueFrom(this.pagination$);
  //   this._Store.dispatch(updateAllUsersPagination({ pagination: { ...pagination, limit } }));
  // }

  // securityRoles(account_type: EUserAccountTypes) {
  //   return Object.keys(SKYLAB_ACCESS_CONTROL_LIST[account_type]);
  // }

  // getUserAccountsById(user: IUser, account: IUserAccounts) {
  //   return user.accounts.find((f) => f.account_id === account.account_id);
  // }

  // checkboxChange(value: boolean, role: string, user_account: IUserAccounts, user_id: string) {
  //   if (value) {
  //     const obj: IUserAccountsSecurityRolesParamsCreate = {
  //       account_id: user_account.account_id,
  //       security_roles: [role],
  //       user_id,
  //     };
  //     console.log('Dispactch or emit create');
  //     console.log({ obj });
  //     this._Store.dispatch(addUserRole({ params: obj }));
  //   } else {
  //     if (user_account.security_roles.length > 1) {
  //       const obj: IUserAccountsSecurityRolesParamsDelete = {
  //         account_id: user_account.account_id,
  //         security_roles: [role],
  //         user_id,
  //       };
  //       this._Store.dispatch(deleteUserRole({ params: obj }));
  //     } else {
  //       console.log('This is the users last role, can not remove');
  //     }
  //   }
  // }

  // removeUserFromAccount(user_id: string, account: IUserAccounts) {
  //   const params: IUserAccountsParamsDelete = {
  //     user_id,
  //     account,
  //   };
  //   this._Store.dispatch(deleteUserAccount({ params }));
  // }
}
