import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICoreBlog, ICoreBlogRelatedPostParams, ICoreBlogRelatedPostsController, configureURL } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security';

@Injectable()
export class LaunchpointBlogRelatedPostsHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreBlogRelatedPostsController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'blog-related-posts';

  constructor(
    public _Store: Store,
    private http: HttpClient
    // @Optional() @Inject(ALERT_BANNER_CLIENT_CONFIG_TOKEN) public _config: LaunchpointCoreClientAlertBannerConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      // configUrl: _config?.base_url,
      version: 1,
      route: this.BASE_URL,
    });
  }

  add(params: ICoreBlogRelatedPostParams): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/add', params, { headers });
      })
    );
  }

  remove(params: ICoreBlogRelatedPostParams): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/remove', params, { headers });
      })
    );
  }

  set(params: ICoreBlogRelatedPostParams): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/set', params, { headers });
      })
    );
  }
}
