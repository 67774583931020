<div class="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10">

  <div class="card-header pt-10">
    <div class="d-flex align-items-center">


      <div class="d-flex flex-column">
        <h2 class="mb-1">Media Manager</h2>
        <div class="text-muted fw-bold">
          <span *ngIf="queryFilterCount$ | async as count">{{ count || '0' }} items</span>
        </div>
      </div>
    </div>
  </div>

  <div class="card-body pb-0">
    <div class="d-flex overflow-auto h-55px">
      <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap">
        <li class="nav-item">
          <a class="nav-link text-active-primary me-6 active">
            Files
          </a>
        </li>

      </ul>
    </div>
  </div>
</div>