import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICoreBlog, ICoreBlogParamsCommentIds, ICoreBlogParamsCommentAdd, ICoreBlogCommentsController, configureURL } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security';

@Injectable()
export class LaunchpointBlogCommentsHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreBlogCommentsController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'blog-comments';

  constructor(
    public _Store: Store,
    private http: HttpClient
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      version: 1,
      route: this.BASE_URL,
    });
  }

  add(params: ICoreBlogParamsCommentAdd): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/add', params, { headers });
      })
    );
  }
  remove(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/remove', params, { headers });
      })
    );
  }
  set(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/set', params, { headers });
      })
    );
  }
  like(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/like', params, { headers });
      })
    );
  }
  dislike(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/dislike', params, { headers });
      })
    );
  }
}
