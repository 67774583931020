import { ELaunchpointUserRoles, SECURITY_PERMISSIONS_DEFAULT_ROLE } from '../security.constants';
import { ISecurityConfig, ISecurityConfigACLRole } from '../security.interface';
import { LAUNCHPOINT_ACTIVITY_HISTORY_SCHEMA } from './activity-history.constants';
import { LAUNCHPOINT_ACTIVITY_HISTORY_WEBHOOK_EVENTS_SCHEMA } from './webhook-events/activity-history-webhook-events.constants';
import { LAUNCHPOINT_ACTIVITY_HISTORY_WEBHOOKS_SCHEMA } from './webhooks/activity-history-webhooks.constants';

export const ACTIVITY_HISTORY_ABAC_MANAGE_ROLE: ISecurityConfigACLRole[] = [
  {
    effect: 'can',
    permissions: [
      { subject: LAUNCHPOINT_ACTIVITY_HISTORY_SCHEMA, actions: ['manage'] },
      { subject: LAUNCHPOINT_ACTIVITY_HISTORY_WEBHOOK_EVENTS_SCHEMA, actions: ['manage'] },
      { subject: LAUNCHPOINT_ACTIVITY_HISTORY_WEBHOOKS_SCHEMA, actions: ['manage'] },
    ],
    // conditions: [{ account_id: '${accounts._id}' }],
  },
];

export const ACTIVITY_HISTORY_ABAC_BASE: ISecurityConfig = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [],
    [ELaunchpointUserRoles.USER]: [],
    [ELaunchpointUserRoles.SUPER_ADMIN]: ACTIVITY_HISTORY_ABAC_MANAGE_ROLE,
  },
};
