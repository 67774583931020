import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientBlogModalBaseComponent } from '@launchpoint/core-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog-modal',
  templateUrl: 'blog-modal.component.html',
})
export class LaunchpointCoreWebBlogModalComponent extends LaunchpointCoreClientBlogModalBaseComponent implements OnInit {
  _swal = inject(LaunchpointSweetAlertService);
  _activeModal = inject(NgbActiveModal);

  override toast(params) {
    this._swal.fireToast(params);
  }

  override close() {
    this._activeModal.close();
  }

  dismiss() {
    this._activeModal.dismiss();
  }
}
