import { Directive, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import { updateAllMessagesPagination, updateAllMessagesQuery } from './+state/actions/messages.actions';
import { LaunchpointCoreClientNotificationsMessagesStateBaseComponent } from './+state/notifications-messages-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsMessagesBaseComponent
  extends LaunchpointCoreClientNotificationsMessagesStateBaseComponent
  implements OnInit
{
  form_control = new FormControl<string>('');
  ngOnInit() {
    if (this.pageLimit) {
      this.limit(this.pageLimit);
    }
    this._Store.dispatch(updateAllMessagesQuery({ query: this.query }));

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    this.success();
    this.error();
  }

  updateQuery(search: string) {
    this._Store.dispatch(
      updateAllMessagesQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllMessagesPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllMessagesPagination({ pagination: { ...pagination, limit } }));
  }
}
