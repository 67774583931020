import { IHttpException, IMediaCategory } from '@launchpoint/core-types';

export interface IMediaCategoryEntity {
  media_category_id: string;
  media_category: IMediaCategory;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string | null;
}
