<div>
  <div class="px-7 py-5 d-flex align-items-center justify-content-between">
    <div class="fs-5 text-dark fw-bold">Filter Options</div>
    <!-- <button class="btn btn-sm btn-icon btn-clear btn-active-light-primary">
    <i class="fs-4 bi bi-arrows-angle-expand"></i>
  </button> -->
  </div>

  <div class="separator border-gray-200"></div>

  <div class="px-7 mt-5">
    <form [formGroup]="form">
      <div class="mb-2 px-3" *ngIf="this.admin">
        <div class="d-flex justify-content-between me-4">
          <label class="form-label fw-semibold">Users</label>
          <div>
            <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="users?.length > 0" animateCss="tada"
              ngbTooltip="Clear all users" (click)="clearUsers()"></i>
          </div>
        </div>
        <div class="menu-item mb-3">
          <launchpoint-users-tagify (selectedUser)="setUser($event)" />
        </div>
      </div>

      <div class="mb-2 px-3">
        <div class="d-flex justify-content-between me-4">
          <label class="form-label fw-semibold">Event Types</label>
          <div>
            <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="getTypesLength() > 0" animateCss="tada"
              ngbTooltip="Clear all types." (click)="clearTypes()"></i>
          </div>
        </div>
        <div class="menu-item mb-3">
          <tagify formControlName="event_name" name="event_name" class="me-5" [settings]="settings"
            [whitelist]="whitelistEvents$" inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
        </div>
      </div>

      <div class="mb-2 px-3">
        <div class="d-flex justify-content-between me-4">
          <label class="form-label fw-semibold">Methods</label>
          <div>
            <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="getTypesLength() > 0" animateCss="tada"
              ngbTooltip="Clear all types." (click)="clearMethods()"></i>
          </div>
        </div>
        <div class="menu-item mb-3">
          <tagify formControlName="method" name="method" class="me-5" [settings]="settings"
            [whitelist]="whitelistMethods$" inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
        </div>
      </div>


      <div class="menu-item p-3">
        <label class="form-label fw-semibold">Created At</label>
        <launchpoint-date-range formControlName="created_at" [allOptions]="{ debug: false }"></launchpoint-date-range>
      </div>
    </form>

    <div class="mb-2 px-3">
      <div class="mb-5">
        <div class="d-flex justify-content-between">
          <label class="form-label fw-semibold">Response Data</label>
          <i class="fs-4 bi bi-plus-square cursor-pointer" (click)="addData()"></i>
        </div>

        <div class="d-flex flex-column">
          <div class="d-flex align-items-center justify-content-between mb-2" *ngFor="let input of data;let i = index">
            <div class="d-flex col-11">
              <input class="form-control form-control-md me-3" type="string" placeholder="Key"
                [(ngModel)]="data[i].key" />
              <input class="form-control form-control-md me-3" type="string" placeholder="Value"
                [(ngModel)]="data[i].value" />
            </div>
            <div class="d-flex col-1 justify-content-end">
              <i class="fs-4 bi bi-x-square cursor-pointer" (click)="removeData(i)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="rounded-bottom pb-3">
      <div class="d-flex justify-content-between align-items-center m-5 px-4">
        <div>
          <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="clearAll()">
            Clear All
          </a>
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="apply()">Apply</button>
        </div>
      </div>
    </div>
  </div>