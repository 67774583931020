import { Component } from '@angular/core';

@Component({
  selector: 'sd-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent {
  resources = [
    {
      image: 'assets/img/community-resource.webp',
      title: 'COMMUNITY',
      href: 'https://launchpoint.dev/resources/community?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
    },
    {
      image: 'assets/img/lessons-resource.webp',
      title: 'EDUCATION',
      href: 'https://launchpoint.dev/resources?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
    },
    {
      image: 'assets/img/mobile-resource.webp',
      title: 'MOBILE APP',
      href: 'https://launchpoint.dev/get-started?utm_campaign=stadnick-dev&utm_medium=affiliate&utm_source=website',
    },
  ];
}
