import { RequireOnlyOne } from '../require-one';
import { EUserSendCodeModes, ICoreUser, ICoreUserAccount } from '../user-company-account.interface';

export const DEFAULT_ACCESS_REJECTED = 'Access Rejected';
export const DEFAULT_JWT_EXPIRED = 'jwt expired';

export type ICoreUserJWTAccount = Pick<
  ICoreUserAccount,
  '_id' | 'account_id' | 'account_type' | 'security_roles' | 'selected' | 'invited' | 'status' | 'user_id' | 'name' | 'connect_account'
>;

export class ICoreUserJWTToken implements Pick<ICoreUser, '_id' | 'email' | 'reset_password_force' | 'security_roles' | 'location'> {
  _id: string;
  email: string;
  email_verified: boolean;
  phone?: number;
  phone_verified?: boolean;
  first_name: string;
  last_name: string;
  customer_id: string | { id: string };
  profile_photo_url: string;
  reset_password_force: boolean;
  security_roles: string[];
  accounts?: ICoreUserJWTAccount[];
  location: string;
}

export interface ICoreUserJWTDecorator {
  jwt_user: ICoreUserJWTToken;
  account?: ICoreUserJWTAccount;
  development?: boolean;
}

export class ILaunchpointCoreParamsUsers {
  // subscription document id
  _id: string;
  user_ids: string[];
}

export class ILaunchpointCoreParamsAccounts {
  // subscription document id
  _id: string;
  account_ids: string[];
}

/**
 * `user_id` is either the `jwt_user._id` or the `account.user_id` (selected)
 *
 * `account_id` is the `account.account_id` (selected)
 */
export type ICoreUserJWTFlat = ICoreUserJWTToken & ICoreUserJWTAccount;

export interface IAuthForgotPasswordParams {
  email: string;
}

export interface IAuthenticationResponse {
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;
}

export enum EThirdPartyStrategies {
  GOOGLE = 'Google',
  APPLE = 'Apple',
  FACEBOOK = 'Facebook',
}

export interface ICoreUserParamsSetPasswordSMS {
  password: string;
  confirmPassword: string;
  token: string;
  email: string;
}

export interface ICoreUserParamsChangePasswordWithCurrent {
  user_id: string;
  current_password?: string;
  new_password: string;
  confirm_password: string;
}

export interface ICoreUserParamsResetPassword {
  user_id: string;
  password: string;
  confirm_password: string;
}

export interface ICoreUserParamsSetPassword {
  user_id: string;
  new_password: string;
  confirm_new_password: string;
}

export interface ICoreUserParamsChangePassword {
  user_id: string;
  new_password: string;
  confirm_new_password: string;
}

export interface ICoreUserParamsChangeEmail {
  new_email: string;
  confirm_new_email: string;
  password: string;
}

export interface ICoreUserParamsSetEmail {
  user_id: string;
  new_email: string;
  confirm_new_email: string;
  verified: boolean;
}

export interface ICoreUserParamsVerifyEmail {
  user_id: string;
  email: string;
}

export class ICoreUserParamsLoginPassword {
  email: string;
  password: string;
}

export type ICoreUserParamsLoginPasswordV3 = {
  password: string;
} & RequireOnlyOne<{ email: string; phone: number }>;

export type ICoreUserParamsLoginCodeV3 = {
  code: string;
} & RequireOnlyOne<{ email: string; phone: number }>;

export type ICoreUserParamsVerificationCode = { user: ICoreUser; two_factor?: boolean; reset_password_force?: boolean } & RequireOnlyOne<{
  email: string;
  phone: number;
}>;

export type ICoreUserParamsGetCode = {
  user: ICoreUser;
  mode: EUserSendCodeModes;
  two_factor?: boolean;
};

export class ICoreUserParamsLoginMagic {
  email: string;
}

export class ICoreUserParamsVerifyV2 {
  email: string;
  code: string;
}

export class ICoreUserParamsVerifyV3Phone {
  phone: number;
  code: string;
}

export type ICoreUserParamsFindV3 = RequireOnlyOne<{ email: string; phone: number }>;

export type ICoreUserParamsVerifyV3 = {
  code: string;
  two_factor?: boolean;
  reset_password_force?: boolean;
} & RequireOnlyOne<{ email: string; phone: number }>;

export type ICoreUserResetPasswordV3 = {
  expiration_date: Date;
  token: string;
} & RequireOnlyOne<{ email: string; phone: number }>;

export type ICoreUserParamsSetRedisV3 = {
  _id: string;
} & Pick<ICoreUser, 'email' | 'demographics'>;
