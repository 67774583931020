import { IHttpException, ITopic } from '@launchpoint/core-types';

export interface TopicsEntityState {
  topic_id: string;
  topic: ITopic;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  topic_config?: any;
  success_message?: string;
}
