import { ArrayNotEmpty, IsArray, IsBoolean, IsInt, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ELaunchpointFormsLogicType, ELaunchpointFormsInputType } from '../../../dynamic-forms.constants';
import { Type as TypeTransformer } from 'class-transformer';
import { ILaunchpointDynamicFormPerformanceMetrics } from '../../form.shared.interface';

/**
 * Represents a question within a form group.
 */
export interface ILaunchpointDynamicFormGroupQuestionBase {
  _id?: string;
  /** The text of the question to be displayed. */
  question_text: string;
  /** The type of input control for this question. */
  question_type: ELaunchpointFormsInputType;
  description: string;
  /**
   * Used as the key to pass into the form questions dynamically based on the value of the answer,
   */
  question_merge: string;
  /** An array of options for select, radio, or checkbox input types. */
  options: ILaunchpointDynamicFormGroupQuestionOption[];
  /** Validation rules for the question. */
  validations: ILaunchpointDynamicFormGroupQuestionValidation;
  /** TODO: Conditional logic that applies to this question. */
  // conditional_logic: ILaunchpointDynamicFormGroupQuestionConditionalLogic[];
  /** TODO: Additional metadata related to the question. */
  // metadata?: any;
  performance_metrics: ILaunchpointDynamicFormPerformanceMetrics;
}
/**
 * Represents a question within a form group.
 */
export interface ILaunchpointDynamicFormGroupQuestion extends ILaunchpointDynamicFormGroupQuestionBase {
  _id: string;
}

/**
 * Represents media associated with a form question, like an image or video.
 */
export interface ILaunchpointDynamicFormGroupQuestionMedia {
  /** The type of media. */
  type: 'image' | 'video';
  /** The URL where the media is hosted. */
  url: string;
}

/**
 * Represents a selectable option in a form question.
 */
export class ILaunchpointDynamicFormGroupQuestionOption {
  @IsString({ message: 'pattern must be a string' })
  /** Display text of the option. */
  text: string;
  @IsString({ message: 'pattern must be a string' })
  /** Value of the option, which may be submitted with the form response. */
  value: string;
}
/**
 * Defines the validation rules for a form question.
 */
export class ILaunchpointDynamicFormGroupQuestionValidation {
  @IsOptional()
  @IsBoolean({ message: 'required must be a boolean' })
  /** Indicates if the question is mandatory. */
  required?: boolean;
  @IsOptional()
  @IsInt({ message: 'min must be a number' })
  /** Minimum length for a text input. */
  min_length?: number;
  @IsOptional()
  @IsInt({ message: 'min must be a number' })
  /** Maximum length for a text input. */
  max_length?: number;
  @IsOptional()
  @IsInt({ message: 'min must be a number' })
  /** Minimum value for numeric input types. */
  min?: number;
  @IsOptional()
  @IsInt({ message: 'min must be a number' })
  /** Maximum value for numeric input types. */
  max?: number;
  @IsOptional()
  @IsString({ message: 'pattern must be a string' })
  /** Regular expression pattern that the input must match. */
  pattern?: string;
}

/**
 * Specifies the condition under which a form question should be shown or hidden.
 */
export interface ILaunchpointDynamicFormGroupQuestionConditionalLogic {
  /** Type of condition to evaluate (e.g., equals, greater than). */
  condition: ELaunchpointFormsLogicType;
  /** The value to compare against when evaluating the condition. */
  value: any;
  /** The action to take when the condition is met ('show' or 'hide'). */
  action: 'show' | 'hide';
  /** The ID of the question that this logic targets. */
  target_question_id: string;
}

/**
 * This object is to set the params to create one object. Required fields need to be listed.
 */
export class ILaunchpointDynamicFormGroupQuestionParamsCreateOne implements Partial<ILaunchpointDynamicFormGroupQuestionBase> {
  _id?: never;
  @IsString({ message: 'question_text must be a string' })
  question_text: string;
  @IsString({ message: 'question_type must be a string' })
  question_type: ELaunchpointFormsInputType;

  @IsString()
  question_merge: string;
  @IsOptional()
  @IsArray({ message: 'options must be an array' })
  @ArrayNotEmpty({ message: 'options array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormGroupQuestionOption' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionOption) // Use the correct type for your logic
  options: ILaunchpointDynamicFormGroupQuestionOption[];
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionValidation)
  validations: ILaunchpointDynamicFormGroupQuestionValidation;
}

/**
 * This object is to set the params to create one object. Required fields need to be listed.
 */
export class ILaunchpointDynamicFormGroupQuestionParamsUpdateOne implements Partial<ILaunchpointDynamicFormGroupQuestionBase> {
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'question_text must be a string' })
  question_text: string;
  @IsString({ message: 'question_type must be a string' })
  question_type: ELaunchpointFormsInputType;
  @IsString({ message: 'question_merge must be a string' })
  question_merge: string;
  @IsOptional()
  @IsArray({ message: 'options must be an array' })
  @ArrayNotEmpty({ message: 'options array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormGroupQuestionOption' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionOption) // Use the correct type for your logic
  options?: ILaunchpointDynamicFormGroupQuestionOption[];
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionValidation)
  validations: ILaunchpointDynamicFormGroupQuestionValidation;
}

export class ILaunchpointDynamicFormGroupQuestionParamsCreate {
  /**
   * Program _id
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'group_id must be a string' })
  group_id: string;
  @ValidateNested()
  @IsArray({ message: 'questions must be an array' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionParamsCreateOne)
  questions: Omit<ILaunchpointDynamicFormGroupQuestionParamsCreateOne, '_id'>[];
}

export class ILaunchpointDynamicFormGroupQuestionParamsUpdate {
  /**
   * Program _id
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'group_id must be a string' })
  group_id: string;
  @ValidateNested()
  @IsArray({ message: 'questions must be an array' })
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionParamsUpdateOne)
  question: ILaunchpointDynamicFormGroupQuestionParamsUpdateOne;
}

export class ILaunchpointDynamicFormGroupQuestionParamsReorder {
  /**
   * _id of the rate schedule you want to add this deductible to.
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'group_id must be a string' })
  group_id: string;
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionParamsUpdateOne)
  question: ILaunchpointDynamicFormGroupQuestionParamsUpdateOne;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  previousIndex: number;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  currentIndex: number;
}

export class ILaunchpointDynamicFormGroupQuestionParamsChangeGroup {
  /**
   * Form _id
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'current_group_id must be a string' })
  current_group_id: string;
  @IsString({ message: 'new_group_id must be a string' })
  new_group_id: string;
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormGroupQuestionParamsUpdateOne)
  question: ILaunchpointDynamicFormGroupQuestionParamsUpdateOne;
}

export class ILaunchpointDynamicFormGroupQuestionParamsDelete {
  /**
   * program._id
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  @IsString({ message: 'group_id must be a string' })
  group_id: string;
  /**
   * question._ids
   */
  @IsArray({ message: 'question_ids must be an array' })
  @ArrayNotEmpty({ message: 'question_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the question_ids array must be a string' })
  question_ids: string[];
}
