import { Component, Input, OnInit } from '@angular/core';
import { LaunchpointCoreClientProfileConfig } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-core-web-profile',
  templateUrl: './profile.component.html',
})
export class LaunchpointCoreWebProfileComponent implements OnInit {
  @Input() user: ICoreUser;
  @Input() _config: LaunchpointCoreClientProfileConfig;

  ngOnInit(): void {
    // console.log('is this even hit');
    if (!this._config) {
      throw new Error('Configuration file required.');
    }
    return;
  }
}
