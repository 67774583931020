<div class="card mb-xl-10 mb-5">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Details</h3>
    </div>
  </div>

  <form [formGroup]="form" class="form">
    <div class="card-body border-top p-9">
      <!-- START: Title -->
      <div class="row mb-6">
        <label class="col-form-label fw-bold required fs-6">Title</label>
        <div class="col-lg-12 fv-row">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Title"
            name="title"
            formControlName="title"
          />
          <ng-container
            [ngTemplateOutlet]="formError"
            [ngTemplateOutletContext]="{
              validation: 'required',
              message: 'Title is required',
              control: form.controls.title,
            }"
          >
          </ng-container>
        </div>
      </div>

      <div class="row">
        <label class="col-form-label fw-bold fs-6">Description</label>
        <div class="col-lg-12 fv-row">
          <textarea
            class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
            placeholder="Description"
            name="description"
            formControlName="description"
            rows="3"
          ></textarea>
        </div>
      </div>

      <div class="row align-items-center mb-10">
        <div class="col-4">
          <label class="col-form-label fw-bold required fs-6">Category</label>
          <div class="col-lg-12 fv-row">
            <launchpoint-blog-category-search-ahead
              cssClass="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              [selected]="blog?.category"
              (selectedCategory)="setCategory($event)"
            />
          </div>
        </div>

        <div class="col-4">
          <label class="col-form-label fw-bold fs-6">Time to Read</label>
          <div class="col-lg-12 fv-row">
            <input
              type="number"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              placeholder="Minutes to read"
              name="reading_time_min"
              formControlName="reading_time_min"
              [min]="0"
            />
          </div>
        </div>

        <div class="col-4">
          <label class="col-form-label fw-bold fs-6">Release At</label>
          <div class="col-lg-12 fv-row">
            <input
              type="datetime-local"
              class="form-control form-control-lg form-control-solid mb-lg-0 mb-3"
              name="release_at"
              formControlName="release_at"
            />

            <div class="fs-7 text-muted mt-1" *ngIf="form.controls.release_at.value">
              {{ TZ | removeUnderscore }}: {{ form.controls.release_at.value | date: 'medium' }}
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-center">
          <label class="col-form-label fw-bold fs-6 me-5">Comments Allowed</label>
          <input type="checkbox" class="form-check-input form-check-input-solid" name="comments_enabled" formControlName="comments_enabled" />
        </div>

        <div class="col-4 d-flex align-items-center">
          <label class="col-form-label fw-bold fs-6 me-5">Replies Allowed</label>
          <input type="checkbox" class="form-check-input form-check-input-solid" name="replies_enabled" formControlName="replies_enabled" />
        </div>

        <div class="col-4 d-flex align-items-center">
          <div class="d-flex align-items-center me-5">
            <label class="col-form-label fw-bold fs-6">Comments Archived</label>
            <i class="bi bi-info-circle ps-3" ngbTooltip="All previous comments are viewable, but no new comments can be created"></i>
          </div>
          <input type="checkbox" class="form-check-input form-check-input-solid" name="comments_archived" formControlName="comments_archived" />
        </div>
      </div>

      <div class="text-danger fw-semibold mt-6" *ngIf="warning">
        {{ warning }}
      </div>
    </div>
  </form>

  <!-- START: Footer -->
  <div class="card-footer d-flex justify-content-end px-9 py-6">
    <button class="btn btn-secondary me-3" (click)="reset()">Cancel</button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="(loading$ | async) || (blogLoading$ | async) || form.invalid || form.pristine"
      (click)="submit()"
    >
      <ng-container *ngIf="((loading$ | async) || (blogLoading$ | async)) === false">Save</ng-container>
      <ng-container *ngIf="(loading$ | async) || (blogLoading$ | async)">
        <span class="indicator-label" loading="true">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>
    </button>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        {{ message }}
      </div>
    </div>
  </ng-container>
</ng-template>
