import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sd-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  bgImage = [
    {
      img: `assets/img/oops-bg.jpg`,
    },
  ];
  constructor() {
    //
  }

  ngOnInit(): void {
    return;
  }
}
