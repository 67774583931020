import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICoreBlog, ICoreBlogCommentsController, ICoreBlogParamsCommentAdd, ICoreBlogParamsCommentIds, configureURL } from '@launchpoint/core-types';
import { Observable } from 'rxjs';

@Injectable()
export class LaunchpointBlogCommentsHTTPServicePublic implements ICoreBlogCommentsController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'resources-public/comments';

  constructor(private http: HttpClient) {
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      version: 1,
      route: this.BASE_URL,
    });
  }

  add(params: ICoreBlogParamsCommentAdd): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/add', params);
  }
  remove(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/remove', params);
  }
  set(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/set', params);
  }
  like(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/like', params);
  }
  dislike(params: ICoreBlogParamsCommentIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/dislike', params);
  }
}
