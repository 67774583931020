import { Observable } from 'rxjs';
import {
  ILaunchpointCorePinpointSegment,
  ILaunchpointCorePinpointSegmentParamsCreate,
  ILaunchpointCorePinpointSegmentParamsDelete,
  ILaunchpointCorePinpointSegmentParamsList,
  ILaunchpointCorePinpointSegmentParamsUpdate,
  ILaunchpointCorePinpointSegmentSearchPayload,
  ILaunchpointCorePinpointSegmentSearchResults,
} from './pinpoint-segment.interface';

/**
 * Controller('pinpoint/segments')
 */
export interface ILaunchpointCorePinpointSegmentController {
  /**
   * Post(search)
   * @param body
   */
  search(
    body: ILaunchpointCorePinpointSegmentSearchPayload
  ): Promise<ILaunchpointCorePinpointSegmentSearchResults> | Observable<ILaunchpointCorePinpointSegmentSearchResults>;
  /**
   * Get(:id)
   * @param _id
   */
  getById(_id: string): Promise<ILaunchpointCorePinpointSegment> | Observable<ILaunchpointCorePinpointSegment>;
  /**
   * Post()
   * @param body
   */
  create(body: ILaunchpointCorePinpointSegmentParamsCreate): Promise<ILaunchpointCorePinpointSegment> | Observable<ILaunchpointCorePinpointSegment>;
  /**
   * Put()
   * @param body
   */
  update(body: ILaunchpointCorePinpointSegmentParamsUpdate): Promise<ILaunchpointCorePinpointSegment> | Observable<ILaunchpointCorePinpointSegment>;
  /**
   * Put('delete;)
   * @param body
   */
  delete(body: ILaunchpointCorePinpointSegmentParamsDelete): Promise<ILaunchpointCorePinpointSegment> | Observable<ILaunchpointCorePinpointSegment>;
  /**
   * Post('sync')
   * @param body
   */
  sync(body: ILaunchpointCorePinpointSegmentParamsList): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
