import { ESecurityCoreCompanyResources } from '../company/company.permissions.interface';
import { IMedia, IMediaCategory } from '../media';
import { ESecurityMediaCategoryResources } from '../media/media-categories/media-category.permissions.interface';
import { ESecurityMediaResources } from '../media/media.permissions.interface';
import { ITopic } from '../notifications/topics/topics.interface';
import { ESecurityTopicsResources } from '../notifications/topics/topics.permissions.interface';
import { IUserNotification } from '../notifications/user-notifications/user-notifications.interface';
import { ESecurityUserNotificationResources } from '../notifications/user-notifications/user-notifications.permissions.interface';
import { ILaunchpointCorePinpointApplication } from '../pinpoint/pinpoint-application/pinpoint-application.interface';
import { ESecurityPinpointApplicationResources } from '../pinpoint/pinpoint-application/pinpoint-application.permissions.interface';
import { ILaunchpointCorePinpointCampaign } from '../pinpoint/pinpoint-campaign/pinpoint-campaign.interface';
import { ESecurityPinpointCampaignResources } from '../pinpoint/pinpoint-campaign/pinpoint-campaign.permissions.interface';
import { ILaunchpointCorePinpointChannel } from '../pinpoint/pinpoint-channel/pinpoint-channel.interface';
import { ESecurityPinpointChannelResources } from '../pinpoint/pinpoint-channel/pinpoint-channel.permissions.interface';
import { ILaunchpointCorePinpointJourney } from '../pinpoint/pinpoint-journey/pinpoint-journey.interface';
import { ESecurityPinpointJourneyResources } from '../pinpoint/pinpoint-journey/pinpoint-journey.permissions.interface';
import { ILaunchpointCorePinpointSegment } from '../pinpoint/pinpoint-segment/pinpoint-segment.interface';
import { ESecurityPinpointSegmentResources } from '../pinpoint/pinpoint-segment/pinpoint-segment.permissions.interface';
import { ICoreCompany, ICoreUser } from '../user-company-account.interface';
import { ESecurityCoreUserRoleResources } from '../user/core/core-security-roles/core-security-roles.permissions.interface';
import { ESecurityCoreUserResources } from '../user/core/core-user.permissions.interface';

export const EXCLUDED_HISTORY_EVENTS = ['read', 'search', 'list', 'sync', 'retrieve', 'reporting', 'analytics', 'find', 'get'] as const;

export const CORE_ACTIVITY_HISTORY_EVENT_TYPES = [
  ...Object.values(ESecurityCoreCompanyResources),
  ...Object.values(ESecurityMediaResources),
  ...Object.values(ESecurityMediaCategoryResources),
  ...Object.values(ESecurityUserNotificationResources),
  ...Object.values(ESecurityTopicsResources),
  ...Object.values(ESecurityPinpointApplicationResources),
  ...Object.values(ESecurityPinpointCampaignResources),
  ...Object.values(ESecurityPinpointChannelResources),
  ...Object.values(ESecurityPinpointJourneyResources),
  ...Object.values(ESecurityPinpointSegmentResources),
  ...Object.values(ESecurityCoreUserResources),
  ...Object.values(ESecurityCoreUserRoleResources),
].filter((v) => !EXCLUDED_HISTORY_EVENTS.some((substring) => v.includes(substring)));

/**
 * Utility type to create a union of template literal types with excluded substrings
 * ExcludedSubstringUnion<['read', 'search']> = never
 */
type ExcludedSubstringUnion<T extends readonly string[]> = T[number] extends infer U ? (U extends string ? `${string}${U}${string}` : never) : never;

/**
 * Main type to filter out strings containing any excluded substrings
 * FilteredUnion<'read', ['read', 'search']> = never
 */
type FilteredUnion<T, U extends readonly string[]> = T extends ExcludedSubstringUnion<U> ? never : T;

// turn the EXCLUDED_HISTORY_EVENTS into a union type
type ExcludedHistoryEvents = typeof EXCLUDED_HISTORY_EVENTS;

export type FormatEventTypes<T extends string, U, V extends readonly string[] = ExcludedHistoryEvents> = {
  type: FilteredUnion<`${T}`, V>;
  body: U;
};

type ICoreCompanyEventTypes = FormatEventTypes<ESecurityCoreCompanyResources, ICoreCompany>;
type IMediaEventTypes = FormatEventTypes<ESecurityMediaResources, IMedia>;
type IMediaCategoryEventTypes = FormatEventTypes<ESecurityMediaCategoryResources, IMediaCategory>;
type IUserNotificationEventTypes = FormatEventTypes<ESecurityUserNotificationResources, IUserNotification>;
type ITopicsEventTypes = FormatEventTypes<ESecurityTopicsResources, ITopic>;
type IPinpointApplicationEventTypes = FormatEventTypes<ESecurityPinpointApplicationResources, ILaunchpointCorePinpointApplication>;
type IPinpointCampaignEventTypes = FormatEventTypes<ESecurityPinpointCampaignResources, ILaunchpointCorePinpointCampaign>;
type IPinpointChannelEventTypes = FormatEventTypes<ESecurityPinpointChannelResources, ILaunchpointCorePinpointChannel>;
type IPinpointJourneyEventTypes = FormatEventTypes<ESecurityPinpointJourneyResources, ILaunchpointCorePinpointJourney>;
type IPinpointSegmentEventTypes = FormatEventTypes<ESecurityPinpointSegmentResources, ILaunchpointCorePinpointSegment>;
type ICoreUserEventTypes = FormatEventTypes<ESecurityCoreUserResources, ICoreUser>;
type ICoreUserRoleEventTypes = FormatEventTypes<ESecurityCoreUserRoleResources, ICoreUser>;

export type ICoreActivityHistoryEventBody =
  | ICoreCompanyEventTypes
  | IMediaEventTypes
  | IMediaCategoryEventTypes
  | IUserNotificationEventTypes
  | ITopicsEventTypes
  | IPinpointApplicationEventTypes
  | IPinpointCampaignEventTypes
  | IPinpointChannelEventTypes
  | IPinpointJourneyEventTypes
  | IPinpointSegmentEventTypes
  | ICoreUserEventTypes
  | ICoreUserRoleEventTypes;

export type ICoreActivityHistoryEventTypes = `${ICoreActivityHistoryEventBody['type']}`;
