import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LaunchpointViewStateBaseComponent, ViewActions, updateAllUsersQuery } from '@launchpoint/core-client';
import { IUserView, IUserViewParamsUpdate, IUserViewQueryParams } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { LaunchpointSweetAlertService } from '../../../components/swal.service';

@Component({
  selector: 'launchpoint-user-views',
  templateUrl: './user-views.component.html',
  styleUrls: ['./user-views.component.scss'],
})
export class UserViewsComponent extends LaunchpointViewStateBaseComponent implements OnInit {
  @Input() viewQuery: IUserViewQueryParams;
  selectedView: IUserView;
  form_control = new FormControl<string>('');
  @Output() selected = new EventEmitter<IUserView | null>();
  @Output() queryViews = new EventEmitter<string>();
  @Output() selectedViewTitle = new EventEmitter<string>();

  viewId: string;
  expand = false;
  activeRow = -1;
  activeRows = new Set();

  constructor(public _Store: Store, private _swalService: LaunchpointSweetAlertService) {
    super(_Store);
  }

  ngOnInit(): void {
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    if (this.viewQuery) {
      this._Store.dispatch(ViewActions.updateAllViewQuery({ query: this.viewQuery }));
    }
  }

  typeOfValue(input: any): 'string' | 'array' | 'number' | 'any' {
    switch (typeof input) {
      case 'string':
        return 'string';
      case 'object':
        if (Array.isArray(input)) {
          return 'array';
        } else {
          return 'any';
        }
      case 'number':
        return 'number';
      default:
        return 'any';
    }
  }

  rowSelected(view: IUserView) {
    this.selectedView = view;
    this.viewId = this.selectedView._id;
  }
  rowSelect(index: number) {
    if (this.activeRows.has(index)) {
      this.activeRows.delete(index);
    } else {
      this.activeRows.add(index);
    }
  }

  apply() {
    this._Store.dispatch(updateAllUsersQuery({ query: this.selectedView.query }));
    this.selected.emit(this.selectedView);
    this.selectedViewTitle.emit(this.selectedView.title);
  }

  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this._swalService.fireToast({
          icon: 'error',
          title: data.message,
        });
      },
    });
  }

  runQuery(search: string) {
    this._Store.dispatch(ViewActions.updateAllViewQuery({ query: { search: search } }));
  }

  deleteViewConfirmation(view: IUserView) {
    this._swalService
      .fireAlert({
        icon: 'warning',
        title: 'Delete view',
        text: `Are you sure you want to delete ${view.title}? This can not be undone.`,
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        focusConfirm: false,
        buttonsStyling: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          denyButton: 'btn btn-info',
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteview(view._id);
        }
      });
  }

  renameView(view: IUserView) {
    this._swalService
      .fireAlert({
        icon: 'warning',
        title: 'Rename Filter',
        html: `<input type="text" id="title" class="swal2-input" value="${view?.title ?? ''}" placeholder="${view?.title ?? 'Name This Filter...'}">`,
        showDenyButton: true,
        showCancelButton: false,
        denyButtonText: 'Cancel',
        confirmButtonText: 'Save',
        focusConfirm: false,
        buttonsStyling: true,
        customClass: {
          confirmButton: 'btn btn-success',
          denyButton: 'btn btn-danger',
        },
        preConfirm: () => {
          const title = (this._swalService.Swal.getPopup().querySelector('#title') as any).value;
          if (!title) {
            this._swalService.Swal.showValidationMessage(`Please enter a title to save this view.`);
          }
          return { title };
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          const data: IUserViewParamsUpdate = {
            _id: view._id,
            query: view.query,
            table_id: view.table_id,
            title: result.value.title,
          };
          this._Store.dispatch(ViewActions.updateView({ view: data }));
        }
      });
  }
}
