import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../../auth/auth.user.interface';
import {
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsAddMainUserAccount,
  ICoreUserAccountsParamsAddSubUserAccount,
  ICoreUserAccountsParamsFindWhereUserInAccounts,
  ICoreUserAccountsParamsGetPendingInvitations,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  ICoreUserAccountsParamsSwapAccount,
  ICoreUserAccountsParamsUpgradeSubUserToMainUser,
  ICoreUserAccountsUserPendingInvitations,
} from './core-accounts.interface';
import { ICoreUser } from '../../../user-company-account.interface';

/**
 * @Controller('user/account')
 */
export interface ICoreUserAccountController {
  /**
   * Post('sub-user')
   * @param body
   */
  addSubUserAccount(body: ICoreUserAccountsParamsAddSubUserAccount, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('main-user')
   * @param body
   */
  addMainUserAccount(body: ICoreUserAccountsParamsAddMainUserAccount, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('get-invitations')
   * @param body
   */
  getAccountInvitations(
    body: ICoreUserAccountsParamsGetPendingInvitations,
    user?: ICoreUserJWTDecorator
  ): Promise<ICoreUserAccountsUserPendingInvitations[]> | Observable<ICoreUserAccountsUserPendingInvitations[]>;
  /**
   * Post('accept-invitation')
   * @param body
   */
  acceptInvitation(
    body: ICoreUserAccountsParamsAcceptInvitations,
    user?: ICoreUserJWTDecorator
  ): Promise<{ message: string }> | Observable<{ message: string }>;
  /**
   * Put('remove')
   * @param body
   */
  removeAccount(body: ICoreUserAccountsParamsRemove, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('select')
   * @param body
   */
  selectAccount(body: ICoreUserAccountsParamsSwapAccount, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('deselect')
   * @param body
   */
  deselectAccount(body: ICoreUserAccountsParamsSwapAccount, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('resend-invite')
   * @param body
   */
  resendInvite(
    body: ICoreUserAccountsParamsResendInvite,
    user?: ICoreUserJWTDecorator
  ): Promise<{ message: string }> | Observable<{ message: string }>;

  /**
   * Post('user-in-accounts')
   * @param body
   */
  findWhereUserIsInAccounts(
    body: ICoreUserAccountsParamsFindWhereUserInAccounts,
    user?: ICoreUserJWTDecorator
  ): Promise<string[]> | Observable<string[]>;
  /**
   * Put('upgrade-sub-user')
   * @param body
   */
  upgradeSubUser(body: ICoreUserAccountsParamsUpgradeSubUserToMainUser, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
}

export enum ECoreUserAccountsRoutes {
  ACCOUNT = 'user/account',
}

export enum ECoreUserAccountsCustomActions {
  ADD_SUB_USER = 'add-sub-user',
  ADD_MAIN_USER = 'add-main-user',
  ACCEPT_INVITATIONS = 'accept-invitation',
  GET_INVITATIONS = 'get-invitations',
  SELECT = 'select',
  REMOVE = 'remove',
  RESEND_INVITE = 'resend-invite',
}

/**
 * IMPORTANT:
 * Not yet used anywhere because currently we have ESecurityCoreUserResources that has all the security stuff for core
 * and I'm not yet sure of a way to extend these into that.
 */
export enum ECoreUserAccountsActions {
  USER_ACCOUNT_ADD_SUB_USER = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.ADD_SUB_USER,
  USER_ACCOUNT_ADD_MAIN_USER = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.ADD_MAIN_USER,
  USER_ACCOUNT_REMOVE = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.REMOVE,
  USER_ACCOUNT_ACCEPT_INVITATION = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.ACCEPT_INVITATIONS,
  USER_ACCOUNT_GET_PENDING_INVITES = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.GET_INVITATIONS,
  USER_ACCOUNT_SELECT = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.SELECT,
  USER_ACCOUNT_RESEND_INVITE = ECoreUserAccountsRoutes.ACCOUNT + '/' + ECoreUserAccountsCustomActions.RESEND_INVITE,
}
