@if (activityHistory) {
  <div class="app-container container-xxl">
    <div class="card mb-xl-9 mb-6 shadow-sm">
      <div class="card-header align-items-center justify-content-between border-0">
        <div class="d-flex fs-2 fw-bold align-items-center me-3">
          <span>{{ activityHistory?.event_name }}</span>
          <span
            class="badge me-auto ms-3"
            [ngClass]="{
              'badge-light-success': activityHistory?.status < 400,
              'badge-light-danger': activityHistory?.status >= 400,
            }"
            [ngbTooltip]="getTooltip(activityHistory?.status)"
            placement="right"
          >
            {{ activityHistory?.status }}
          </span>
        </div>
        <div>
          {{ activityHistory?.created_at | date: 'medium' }}
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between flex-row gap-5">
      <div class="col-5">
        <div class="d-flex flex-column gap-3">
          <div>
            <div class="h2">Request</div>
            <div class="card mb-xl-9 mb-6 p-4 shadow-sm">
              <pre class="mb-0" [innerHTML]="activityHistory?.request | json"></pre>
            </div>
          </div>

          <div *ngIf="activityHistory?.user && admin">
            <div class="h2">JWT User</div>
            <div class="card mb-xl-9 mb-6 p-4 shadow-sm">
              <pre class="mb-0" [innerHTML]="activityHistory?.user | json"></pre>
            </div>
          </div>
        </div>
      </div>

      <div class="col-7">
        <div class="h2">Response</div>
        <div class="card mb-xl-9 mb-6 p-4 shadow-sm">
          <pre class="mb-0" [innerHTML]="activityHistory?.response | json"></pre>
        </div>
      </div>
    </div>
  </div>
}

@if ((loading$ | async) || (activityHistoryLoading$ | async)) {
  <div class="app-container container-xxl">
    <div class="card mb-xl-9 mb-6 shadow-sm">
      <div class="card-header align-items-center justify-content-between border-0">
        <launchpoint-skeleton-loaders
          [count]="1"
          [theme]="{
            margin: 0,
            'border-radius': '5px',
            height: '40px',
            width: '160px',
          }"
        />

        <launchpoint-skeleton-loaders
          [count]="1"
          [theme]="{
            margin: 0,
            'border-radius': '5px',
            height: '20px',
            width: '80px',
          }"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between flex-row gap-5">
      <div class="col-5">
        <div class="d-flex flex-column gap-3">
          <div>
            <div class="h2">Request</div>
            <launchpoint-skeleton-loaders
              [count]="1"
              [theme]="{
                margin: 0,
                'border-radius': '5px',
                height: '160px',
                width: '300px',
                border: '1px solid white',
              }"
            />
          </div>

          <div *ngIf="admin">
            <div class="h2">JWT User</div>
            <launchpoint-skeleton-loaders
              [count]="1"
              [theme]="{
                margin: 0,
                'border-radius': '5px',
                height: '160px',
                width: '300px',
                border: '1px solid white',
              }"
            />
          </div>
        </div>
      </div>

      <div class="col-7">
        <div class="h2">Response</div>
        <launchpoint-skeleton-loaders
          [count]="1"
          [theme]="{
            margin: 0,
            'border-radius': '5px',
            height: '160px',
            width: '300px',
            border: '1px solid white',
          }"
        />
      </div>
    </div>
  </div>
}
