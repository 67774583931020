import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../../auth/auth.user.interface';
import {
  ICoreUserInvitation,
  ICoreUserInvitationParamsCreate,
  ICoreUserInvitationParamsUpdate,
  ICoreUserInvitationsSearchPayload,
  ICoreUserInvitationsSearchResults,
} from './core-user-invitations.interface';

/**
 * @Controller('user/invitations')
 */
export interface ICoreUserInvitationsController {
  /**
   * Get(:id)
   */
  findById(_id: string, user?: ICoreUserJWTDecorator): Promise<ICoreUserInvitation> | Observable<ICoreUserInvitation>;
  /**
   * Post('search')
   * @param body
   */
  search(
    body: ICoreUserInvitationsSearchPayload,
    user?: ICoreUserJWTDecorator
  ): Promise<ICoreUserInvitationsSearchResults> | Observable<ICoreUserInvitationsSearchResults>;

  /**
   * Post()
   * @param body
   */
  create(body: ICoreUserInvitationParamsCreate, user?: ICoreUserJWTDecorator): Promise<ICoreUserInvitation> | Observable<ICoreUserInvitation>;

  /**
   * Put()
   * @param body
   */

  update(body: ICoreUserInvitationParamsUpdate, user?: ICoreUserJWTDecorator): Promise<ICoreUserInvitation> | Observable<ICoreUserInvitation>;
  /**
   * Delete()
   */
  delete(_id: string, user?: ICoreUserJWTDecorator): Promise<ICoreUserInvitation> | Observable<ICoreUserInvitation>;
}
