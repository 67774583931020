import { ELaunchpointGeo } from './geo-location.constants';

export class ILaunchpointAddress {
  _id?: string;
  title?: string;
  address_long: string;
  country: string;
  line_one: string;
  line_two: string;
  city: string;
  state: string;
  zip_code: string;
  zip_code_ext?: string;
  long?: number;
  lat?: number;
  location?: ILaunchpointAddressLocation;
}

export class ILaunchpointAddressLocation {
  type: ELaunchpointGeo.POINT;
  /**
   * list the longitude first, and then latitude.
   */
  coordinates: [number, number] | [];
}
