import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LaunchpointCoreClientNotificationsUserNotificationStandaloneBaseComponent, UserNotificationsHTTPService } from '@launchpoint/core-client';
import { LaunchpointCoreWebPaginationComponent } from '../../../../../components/pagination/pagination.component';

@Component({
  selector: 'launchpoint-notifications-user-notifications-standalone',
  templateUrl: './notifications-user-notifications-standalone.component.html',
  styleUrls: ['./notifications-user-notifications-standalone.component.scss'],
  standalone: true,
  providers: [UserNotificationsHTTPService],
  imports: [CommonModule, LaunchpointCoreWebPaginationComponent],
})
export class LaunchpointCoreWebNotificationUserNotificationsStandaloneComponent
  extends LaunchpointCoreClientNotificationsUserNotificationStandaloneBaseComponent
  implements OnInit
{
  ngOnInit() {
    super.ngOnInit();
    this.getUnread();
  }

  async getUnread() {
    if (this.notifications$()?.some((notification) => !notification.is_read)) {
      const blinking_alert = document.createElement('span');
      document.getElementById('notifications-bell-alert').appendChild(blinking_alert);
      blinking_alert.className = 'bullet bullet-dot bg-info h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink';
    }
  }
}
