import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { LayoutService, LayoutType } from '../../../core/layout.service';
import { PageInfoService } from '../../../core/page-info.service';
import { ToggleComponent } from '../../../core/kt/components/_ToggleComponent';
import { ScrollTopComponent } from '../../../core/kt/components/_ScrollTopComponent';
import { DrawerComponent } from '../../../core/kt/components/_DrawerComponent';
import { StickyComponent } from '../../../core/kt/components/_StickyComponent';
import { MenuComponent } from '../../../core/kt/components/MenuComponent';
import { ScrollComponent } from '../../../core/kt/components/_ScrollComponent';

@Component({
  selector: 'launchpoint-layout-scripts-init',
  templateUrl: './scripts-init.component.html',
})
export class LaunchpointLayoutScriptsInitComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];
  private layoutConfig$: Observable<LayoutType>;
  constructor(private layout: LayoutService, private pageInfo: PageInfoService, private router: Router) {
    const initPageInfo = () => {
      setTimeout(() => {
        this.pageInfo.calculateTitle();
        this.pageInfo.calculateBreadcrumbs();
      }, 10);
    };

    initPageInfo();
    // subscribe to router events
    this.router.events.pipe(filter((event) => event instanceof ResolveEnd)).subscribe(initPageInfo);
  }

  ngOnInit(): void {
    this.layoutConfig$ = this.layout.layoutConfigSubject.asObservable();
    const layoutUpdateSubscription = this.layoutConfig$.subscribe(() => {
      this.pluginsInitialization();
    });
    this.unsubscribe.push(layoutUpdateSubscription);
  }

  pluginsInitialization() {
    setTimeout(() => {
      ToggleComponent.bootstrap();
      ScrollTopComponent.bootstrap();
      DrawerComponent.bootstrap();
      StickyComponent.bootstrap();
      MenuComponent.bootstrap();
      ScrollComponent.bootstrap();
    }, 200);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
