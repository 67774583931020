import { IsInt, IsNumber, IsOptional, IsString } from 'class-validator';
import { ICoreUserDemographics, ICoreUserDemographicsPhone } from '../../../user-company-account.interface';

export class ICoreUserDemographicsParamsUpdatePhone implements Pick<Partial<ICoreUserDemographicsPhone>, 'phone_country_code' | 'phone_number_long'> {
  @IsString()
  user_id: string;

  @IsInt({ message: 'Phone number country code should be a number' })
  @IsOptional()
  phone_country_code: number;

  @IsInt({ message: 'Phone number should be a number' })
  @IsOptional()
  phone_number_long: number;

  constructor(data: ICoreUserDemographicsParamsUpdatePhone) {
    this.user_id = data.user_id;
    this.phone_country_code = data.phone_country_code;
    this.phone_number_long = data.phone_number_long;
  }
}

export class ICoreUserDemographicsParamsUpdate
  implements Pick<Partial<ICoreUserDemographics>, 'country' | 'country_id' | 'language' | 'latitude' | 'longitude' | 'country_iso3'>
{
  @IsString()
  user_id: string;

  @IsString()
  @IsOptional()
  country?: string;

  @IsNumber()
  @IsOptional()
  country_id?: string | undefined;

  @IsString()
  @IsOptional()
  language?: string;

  @IsNumber({ allowNaN: false, allowInfinity: false })
  @IsOptional()
  latitude?: number;

  @IsNumber({ allowNaN: false, allowInfinity: false })
  @IsOptional()
  longitude?: number;

  @IsString()
  @IsOptional()
  country_iso3?: string;

  constructor(data: ICoreUserDemographicsParamsUpdate) {
    this.user_id = data.user_id;
    this.country = data.country;
    this.country_id = data.country_id;
    this.language = data.language;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.country_iso3 = data.country_iso3;
  }
}
