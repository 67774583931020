import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as ActivityHistoryActions from '../actions/activity-history.actions';
import * as ActivityHistorySelectors from '../selectors/activity-history.selectors';
import { CoreActivityHistoryHTTPService } from '../../services/activity-history-http.service';

@Injectable()
export class ActivityHistoryEffects {
  constructor(private actions$: Actions, private _Store: Store, private _CoreActivityHistoryHTTPService: CoreActivityHistoryHTTPService) {}

  getAllActivityHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ActivityHistoryActions.searchAllActivityHistory,
        ActivityHistoryActions.updateAllActivityHistoryPagination,
        ActivityHistoryActions.updateAllActivityHistoryQuery,
        ActivityHistoryActions.updateAllActivityHistorySorting
      ),
      withLatestFrom(this._Store.select(ActivityHistorySelectors.getAllActivityHistoryState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._CoreActivityHistoryHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((data) => {
            return ActivityHistoryActions.searchAllActivityHistorySuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(ActivityHistoryActions.searchAllActivityHistoryFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(ActivityHistoryActions.searchAllActivityHistoryFailure(error)))
    );
  });

  getUserById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityHistoryActions.getActivityHistoryById),
      switchMap((action) => {
        return this._CoreActivityHistoryHTTPService.getById(action.history_id).pipe(
          map((data) => {
            return ActivityHistoryActions.getActivityHistoryByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(ActivityHistoryActions.getActivityHistoryByIdFailure({ history_id: action.history_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(ActivityHistoryActions.getActivityHistoryByIdFailure(error)))
    );
  });

  deleteActivityHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityHistoryActions.deleteActivityHistory),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreActivityHistoryHTTPService.delete(action.history_id).pipe(
          map((data) => {
            return ActivityHistoryActions.deleteActivityHistorySuccess({
              data,
              success_message: 'Activity History Deleted Successfully',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(ActivityHistoryActions.deleteActivityHistoryFailure({ history_id: action.history_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(ActivityHistoryActions.deleteActivityHistoryFailure(error)))
    );
  });
}
