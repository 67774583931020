import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { configureURL, IMediaDownloadController, IMediaDownloadResponse, IMediaParamsDownload } from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig } from '../interface/media-service.interface';

@Injectable()
export class LaunchpointMediaDownloadService extends LaunchpointSecurityUserIdHeaderService implements IMediaDownloadController {
  sources: EventSource[] = [];
  API_URL = 'api/media/';
  url: string;

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig
  ) {
    super(_Store);
    this.url = `${location.origin}/${_config.environment.mediaApiUrl}/content/${_config?.route}/`;

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.environment?.mediaApiUrl,
      route: 'media-download',
    });
  }

  getSignedURL(body: IMediaParamsDownload): Observable<IMediaDownloadResponse> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMediaDownloadResponse>(this.API_URL, body, { headers });
      })
    );
  }
}
