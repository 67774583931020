import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationsMessagesFilterBaseComponent, updateAllMessagesQuery } from '@launchpoint/core-client';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-notifications-messages-filter',
  templateUrl: './notifications-messages-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebNotificationsMessagesFilterComponent extends LaunchpointCoreClientNotificationsMessagesFilterBaseComponent {
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  clearTypes() {
    this.form.controls.job_types.setValue([]);
    this.tagifyService.get('job_types').removeAllTags();
  }
  clearUsers() {
    this.form.controls.users.setValue([]);
    this.tagifyService.get('users').removeAllTags();
  }
  clearAccounts() {
    this.form.controls.accounts.setValue([]);
    this.tagifyService.get('accounts').removeAllTags();
  }
  clearChannels() {
    this.form.controls.channels.setValue([]);
    this.tagifyService.get('channels').removeAllTags();
  }

  override reset() {
    this.form.reset();
    this.tagifyService.get('job_types').removeAllTags();
    this.tagifyService.get('channels').removeAllTags();
    this.tagifyService.get('users').removeAllTags();
    this.tagifyService.get('accounts').removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    this._Store.dispatch(updateAllMessagesQuery({ query: {} }));
  }
}
