import { Observable } from 'rxjs';
import { ITopic, ITopicParamsCreate, ITopicParamsUpdate, ITopicSearchPayload, ITopicSearchResults } from './topics.interface';

/**
 * Controller('notifications/topics')
 */
export interface ITopicsController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<ITopic> | Observable<ITopic>;
  /**
   * Post('find-one')
   * @param params Partial<ITopic>
   */
  findOne(params: Partial<ITopic>): Promise<ITopic> | Observable<ITopic>;
  /**
   * Post('search')
   * @param body
   */
  search(body: ITopicSearchPayload): Promise<ITopicSearchResults> | Observable<ITopicSearchResults>;
  /**
   * Post()
   * @param body
   */
  create(body: ITopicParamsCreate): Promise<ITopic> | Observable<ITopic>;
  /**
   * Put()
   * @param body
   */
  update(body: ITopicParamsUpdate): Promise<ITopic> | Observable<ITopic>;
  /**
   * Delete(':id')
   * @param id
   */
  delete(id: string): Promise<ITopic> | Observable<ITopic>;
}
