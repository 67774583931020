import {
  ICoreActivityHistory,
  ICoreActivityHistorySearchQuery,
  ICoreActivityHistorySearchResults,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllActivityHistory = createAction('[ActivityHistory] searchAllActivityHistory');
export const searchAllActivityHistorySuccess = createAction(
  '[ActivityHistory] searchAllActivityHistorySuccess',
  props<{ data: ICoreActivityHistorySearchResults; success_message?: string }>()
);
export const searchAllActivityHistoryFailure = createAction('[ActivityHistory] searchAllActivityHistoryFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllActivityHistoryPagination = createAction(
  '[ActivityHistory] updateAllActivityHistoryPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllActivityHistoryPaginationScroll = createAction(
  '[ActivityHistory] updateAllActivityHistoryPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllActivityHistorySorting = createAction('[ActivityHistory] updateAllActivityHistorySorting', props<{ querySort: IQuerySort }>());
export const updateAllActivityHistoryQuery = createAction(
  '[ActivityHistory] updateAllActivityHistoryQuery',
  props<{ query: ICoreActivityHistorySearchQuery }>()
);
// getById
export const getActivityHistoryById = createAction('[ActivityHistory] getActivityHistoryById', props<{ history_id: string }>());
export const getActivityHistoryByIdSuccess = createAction(
  '[ActivityHistory] getActivityHistoryByIdSuccess',
  props<{ data: ICoreActivityHistory; success_message?: string }>()
);
export const getActivityHistoryByIdFailure = createAction(
  '[ActivityHistory] getActivityHistoryByIdFailure',
  props<{ history_id: string; error: IHttpException }>()
);

// Delete ActivityHistory
export const deleteActivityHistory = createAction('[ActivityHistory] deleteActivityHistory', props<{ history_id: string }>());
export const deleteActivityHistorySuccess = createAction(
  '[ActivityHistory] deleteActivityHistorySuccess',
  props<{ data: ICoreActivityHistory; success_message?: string }>()
);
export const deleteActivityHistoryFailure = createAction(
  '[ActivityHistory] deleteActivityHistoryFailure',
  props<{ history_id: string; error: IHttpException }>()
);

// Close Alerts - //TODO: is this needed anymore?
export const closeActivityHistorySuccessMessage = createAction('[ActivityHistory] closeActivityHistorySuccessMessage');
export const closeActivityHistoryErrorMessage = createAction('[ActivityHistory] closeActivityHistoryErrorMessage');
