import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppBaseComponent } from '../../../../../app-base.component';
import { DrawerComponent } from '../../../../core/kt/components';
@Component({
  selector: 'launchpoint-app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class AppLaunchpointBaseContentComponent extends AppBaseComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = [];

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.routingChanges();
  }

  routingChanges() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        DrawerComponent.hideAll();
      }
    });
  }
}
