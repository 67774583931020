import { ITagifyTag } from '@launchpoint/core-types';
import { TagifySettings } from 'ngx-tagify';

/**
 * Base settings for Tagify
 * @type {TagifySettings}
 * @default
 * ```
 * placeholder: 'Search...',
 * enforceWhitelist: true,
 * keepInvalidTags: false,
 * maxItems: 100,
 * ```
 */
export const BASE_TAGIFY_SETTINGS: TagifySettings = {
  placeholder: 'Search...',
  blacklist: ['fucking', 'shit'],
  dropdown: {
    maxItems: 100, // <- mixumum allowed rendered suggestions
    classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
    enabled: 0, // <- show suggestions on focus
    closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
  },
  enforceWhitelist: true, // <-- if this is set to true, you CANNOT pre-load with value, but be set after the fact
  keepInvalidTags: false,
};

export interface ITagifyComponent {
  /**
   * Emits the selected tags
   * @param data
   */
  selectData(data): void;
  /**
   * initializes the ngModel with input data
   */
  initializeModel(): void;
  /**
   * Hits the API to search the base service for a response, will asynchronously update the whitelist
   * @param search
   */
  updateWhitelist(search?: string): void;
  /**
   * Helper function to format the API response to the tagify format
   * @param data
   */
  formatTags(data): ITagifyTag;
  /**
   * Event Listener for Tagify Component on type
   * @param search
   */
  onInput(search: string): void;
  /**
   * Function that runs off a subscription to the input event emitter
   * @param search
   */
  performSearch(search: string): void;

  get tagifyElement(): Tagify;
}
