<div>
  <div class="table">

    <table class="table table-row-dashed table-hover align-middle fs-6 gy-5">

      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5 fw-semibold" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-gray-700 fs-7 text-uppercase">
          <th class="min-w-150px">Title</th>
          <th class="min-w-150px text-end">Created</th>
        </tr>
      </thead>

      <tbody class="fw-semibold fs-6">
        <tr *ngIf="(loading$ | async) === false && (mediaCategoryEntities$ | async)?.length === 0">
          <td>No Media Categories</td>
        </tr>

        <tr *ngFor="let entity of (mediaCategoryEntities$ | async)" (click)="open(entity?.media_category)">
          <td>
            {{ entity?.media_category?.title }}
          </td>

          <td class="text-end">
            {{ entity?.media_category?.created_at | date }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />

</div>