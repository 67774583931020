import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CompanyV2Service, LaunchpointTagifyBaseComponent } from '@launchpoint/core-client';
import { ICoreCompany, ICoreCompanySearchQuery, ICoreCompanyTagify } from '@launchpoint/core-types';
import { map, Observable, takeUntil } from 'rxjs';

@Directive()
export abstract class CompanyTagifyBaseWebComponent extends LaunchpointTagifyBaseComponent implements OnInit {
  public _CompanyV2Service = inject(CompanyV2Service);

  @Input() companies: ICoreCompany[] = [];
  @Input() query: ICoreCompanySearchQuery = {};

  public companiesModel: ICoreCompanyTagify[] = [];
  public whitelistCompanies$: Observable<ICoreCompanyTagify[]>;

  @Output() selectedCompany = new EventEmitter<ICoreCompany[]>();

  selectData(data: ICoreCompanyTagify[]) {
    return this.selectedCompany.emit(data.map((d) => d.data));
  }

  initializeModel() {
    if (this.companies?.length > 0) {
      this.companiesModel = this.companies.map((company) => this.formatTags(company));

      setTimeout(() => {
        if (this.companiesModel?.length) {
          this.tagifyService.get('companies').loadOriginalValues(this.companiesModel);
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    } else {
      this.settings.enforceWhitelist = true;
    }
  }

  updateWhitelist(search?: string): void {
    const query = this.query;

    if (search) {
      query.search = search;
    }

    if (this.tagifyService.get('companies')) {
      this.tagifyService.get('companies').loading(true);
    }

    this.whitelistCompanies$ = this._CompanyV2Service
      .search({ query, pagination: this.pagination, querySort: this.querySort })
      .pipe(map((f) => f.data.map((d) => this.formatTags(d))));

    this.whitelistCompanies$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (companies) => {
        this.settings.whitelist = companies;
        this.tagifyService.get('companies').settings.whitelist = this.settings.whitelist;
        this.tagifyService.get('companies').loading(false);
      },
    });
  }

  formatTags(company: ICoreCompany): ICoreCompanyTagify {
    return {
      value: company?.company_name,
      data: company,
    };
  }
}
