import { INotificationChannel } from '../notifications.interface';

export type INotificationChannelParamsCreate = {
  _id: string;
  channel: INotificationChannel;
};

export class INotificationChannelParamsUpdate {
  _id: string;
  channel_id: string;
  channel: INotificationChannel;
}

export class INotificationChannelParamsDelete {
  _id: string;
  channel_id: string;
}
