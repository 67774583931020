<div class="d-flex flex-center flex-column flex-lg-row-fluid">

  <div class="w-lg-500px p-10">

    <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form">

      <div class="text-center mb-10">
        <h1 class="text-dark fw-bolder mb-3">
          {{ user?.reset_password_force ? 'Reset Password' : _config?.set_password?.title || 'Create Password' }}
        </h1>
      </div>

      <ng-container *ngIf="error$ | async as error">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">{{ error?.error.message }}</div>
        </div>
      </ng-container>

      <div class="fv-row mb-8 fv-plugins-icon-container">

        <div class="mb-1">

          <div class="form-floating mb-4">
            <input [type]="pVisible ? 'text' : 'password'" placeholder="Password" formControlName="password"
              class="form-control" />
            <span class="text-hover-primary position-absolute translate-middle top-50 end-0 me-2"
              (click)="togglePass()">
              <i class="fs-5 bi" [ngClass]="{ 'bi-eye-slash': !pVisible, 'bi-eye': pVisible }"></i>
            </span>
            <label for="floatingInput">Password</label>
          </div>

          <div class="d-flex align-items-center mb-3" *ngIf="form">
            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
              [ngClass]="{ active: passwordMeterValue > 0 }"></div>
            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
              [ngClass]="{ active: passwordMeterValue > 1 }"></div>
            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
              [ngClass]="{ active: passwordMeterValue > 2 }"></div>
            <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
              [ngClass]="{ active: passwordMeterValue > 3 }"></div>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'Password is required',
          control: form.controls.password
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'maxlength',
          message: 'Password cannot have more than ' + (_config?.set_password?.password_settings?.max_length ?? 100 ) + ' characters',
          control: form.controls.password
        }"></ng-container>

        <div id="password-validation" class="d-flex flex-column text-danger text-sm" *ngIf="form?.dirty">
          <div class="d-flex align-items-center" *ngIf="isSamePassword()">
            <i class="me-1 bi bi-x text-danger"></i>
            Password cannot be the same as your previous password.
          </div>
          <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.min_length"
            [ngClass]="{'text-success':passwordMatch('min-length')}">
            <i class="me-1 bi" [ngClass]="{
              'bi-check text-success': passwordMatch('min-length'),
              'bi-x text-danger': !passwordMatch('min-length'),
            }"></i>
            Password must have at least {{ _config.set_password?.password_settings?.min_length ?? 8 }} characters.
          </div>
          <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_uppercase"
            [ngClass]="{'text-success':passwordMatch('uppercase')}">
            <i class="me-1 bi" [ngClass]="{
              'bi-check text-success': passwordMatch('uppercase'),
              'bi-x text-danger': !passwordMatch('uppercase'),
              }"></i>
            Password must have at least one uppercase letter.
          </div>
          <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_number"
            [ngClass]="{'text-success':passwordMatch('number')}">
            <i class="me-1 bi" [ngClass]="{
              'bi-check text-success': passwordMatch('number'),
              'bi-x text-danger': !passwordMatch('number'),
            }"></i>
            Password must have at least one number.
          </div>
          <div class="d-flex align-items-center" *ngIf="_config.set_password.password_settings.require_special"
            [ngClass]="{'text-success':passwordMatch('special')}">
            <i class="me-1 bi" [ngClass]="{
              'bi-check text-success': passwordMatch('special'),
              'bi-x text-danger': !passwordMatch('special'),
            }"></i>
            Password must have at least special character.
          </div>
        </div>
      </div>

      <div class="fv-row mb-8 fv-plugins-icon-container">

        <div class="form-floating mb-4">
          <input [type]="cVisible ? 'text' : 'password'" placeholder="Confirm Password" formControlName="cPassword"
            class="form-control" />
          <span class="text-hover-primary position-absolute translate-middle top-50 end-0 me-2"
            (click)="toggleConfirm()">
            <i class="fs-5 bi" [ngClass]="{ 'bi-eye-slash': !cVisible, 'bi-eye': cVisible }"></i>
          </span>
          <label for="floatingInput">Confirm Password</label>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Confirm Password is required',
            control: form.controls.cPassword
          }"></ng-container>
        <ng-container *ngIf="form.controls.cPassword.errors && form.controls.cPassword.errors.ConfirmPassword">
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">Passwords do not match</div>
          </div>
        </ng-container>
      </div>

      <div class="d-grid mb-10">

        <button type="submit" [disabled]="disabled" (click)="submit()" class="btn btn-lg btn-primary w-100 mb-5">
          <ng-container *ngIf="(loading$ | async) === false">
            <span class="indicator-label">Submit</span>
          </ng-container>
          <ng-container *ngIf="loading$ | async">
            <span class="indicator-label" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
        </button>

      </div>

    </form>

  </div>

</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>