import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ALERT_BANNER_CLIENT_CONFIG_TOKEN, APP_AUTH_CONFIG_TOKEN, AUTH_CORE_SERVICES, AUTH_GUARDS, ICoreAuthConfig } from '@launchpoint/core-client';
import { NgbModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OtpInputComponent } from '../../../components/otp-input/otp-input.component';
import { AnimateCSSModule } from '../../../vendors/animate-css/animate-css.module';
import { LaunchpointCoreWebAlertBannerMessageStandaloneComponent } from '../../alert-banner/alert-banner-message-standalone/alert-banner-message-standalone.component';
import { AuthRoutingV2Module } from './auth-v2-routing.module';
import { LaunchpointCoreWebAuthV2Component } from './components/base/auth-v2.component';
import { LaunchpointCoreWebAuthV2LoginComponent } from './components/login/auth-v2-login.component';
import { LaunchpointCoreWebAuthV2LogoutComponent } from './components/logout/auth-v2-logout.component';
import { LaunchpointCoreWebAuthV2RegistrationComponent } from './components/registration/auth-v2-registration.component';
import { LaunchpointCoreWebAuthV2SetPasswordComponent } from './components/set-password/auth-v2-set-password.component';
import { LaunchpointCoreWebAuthV2VerifyComponent } from './components/verify/auth-v2-verify.component';

export const WEB_AUTH_COMPONENTS_V2 = [
  LaunchpointCoreWebAuthV2Component,
  LaunchpointCoreWebAuthV2LoginComponent,
  LaunchpointCoreWebAuthV2LogoutComponent,
  LaunchpointCoreWebAuthV2RegistrationComponent,
  LaunchpointCoreWebAuthV2VerifyComponent,
  LaunchpointCoreWebAuthV2SetPasswordComponent,
];

@NgModule({
  declarations: [...WEB_AUTH_COMPONENTS_V2],
  exports: [...WEB_AUTH_COMPONENTS_V2],
  imports: [
    CommonModule,
    AnimateCSSModule,
    AuthRoutingV2Module,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbModule,
    NgbProgressbarModule,
    OtpInputComponent,
    LaunchpointCoreWebAlertBannerMessageStandaloneComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class LaunchpointCoreWebAuthV2Module {
  static forRoot(_authConfig: ICoreAuthConfig): ModuleWithProviders<LaunchpointCoreWebAuthV2Module> {
    return {
      ngModule: LaunchpointCoreWebAuthV2Module,
      providers: [
        {
          provide: APP_AUTH_CONFIG_TOKEN,
          useValue: _authConfig,
        },
        {
          provide: ALERT_BANNER_CLIENT_CONFIG_TOKEN,
          useValue: { base_url: _authConfig.auth.base_url },
        },
        ...AUTH_GUARDS,
        ...AUTH_CORE_SERVICES,
      ],
    };
  }
}
