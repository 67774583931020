import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientUsersStateBaseComponent, searchAllUsers, updateAllUsersPagination } from '../../../../../+state';

@Directive()
export abstract class UsersTableBaseUserSmartComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  constructor(public _router: Router, public _route: ActivatedRoute, public _Store: Store) {
    super(_Store);
  }

  ngOnInit() {
    this._Store.dispatch(searchAllUsers());
  }

  pageChange(paginate) {
    this._Store.dispatch(updateAllUsersPagination({ pagination: paginate }));
  }
}
