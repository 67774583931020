import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as WebhooksActions from '../actions/webhooks.actions';
import * as WebhooksSelectors from '../selectors/webhooks.selectors';
import { CoreActivityHistoryWebhookHTTPService } from '../../services/webhook-webhook-http.service';
import { RouterActions } from '../../../../router/+state';

@Injectable()
export class WebhooksEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _CoreActivityHistoryWebhookHTTPService: CoreActivityHistoryWebhookHTTPService
  ) {}

  getAllWebhooks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WebhooksActions.searchAllWebhooks,
        WebhooksActions.updateAllWebhooksPagination,
        WebhooksActions.updateAllWebhooksQuery,
        WebhooksActions.updateAllWebhooksSorting
      ),
      withLatestFrom(this._Store.select(WebhooksSelectors.getAllWebhooksState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._CoreActivityHistoryWebhookHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((data) => {
            return WebhooksActions.searchAllWebhooksSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(WebhooksActions.searchAllWebhooksFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(WebhooksActions.searchAllWebhooksFailure(error)))
    );
  });

  getUserById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhooksActions.getWebhookById),
      switchMap((action) => {
        return this._CoreActivityHistoryWebhookHTTPService.getById(action.webhook_id).pipe(
          map((data) => {
            return WebhooksActions.getWebhookByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(WebhooksActions.getWebhookByIdFailure({ webhook_id: action.webhook_id, error: error.error })))
        );
      }),
      catchError((error) => of(WebhooksActions.getWebhookByIdFailure(error)))
    );
  });

  createWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhooksActions.createWebhook),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreActivityHistoryWebhookHTTPService.create(action.data).pipe(
          map((data) => {
            return WebhooksActions.createWebhookSuccess({
              data,
              success_message: 'Webhook Created Successfully',
            });
          }),
          catchError((error: IHttpRequestError) => of(WebhooksActions.createWebhookFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(WebhooksActions.createWebhookFailure(error)))
    );
  });

  updateWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhooksActions.updateWebhook),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreActivityHistoryWebhookHTTPService.update(action.data).pipe(
          map((data) => {
            return WebhooksActions.updateWebhookSuccess({
              data,
              success_message: 'Webhook Updated Successfully',
            });
          }),
          catchError((error: IHttpRequestError) => of(WebhooksActions.updateWebhookFailure({ webhook_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(WebhooksActions.updateWebhookFailure(error)))
    );
  });

  deleteWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhooksActions.deleteWebhook),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreActivityHistoryWebhookHTTPService.delete(action.webhook_id).pipe(
          map((data) => {
            return WebhooksActions.deleteWebhookSuccess({
              data,
              success_message: 'Webhook Deleted Successfully',
              redirectPath: action.redirectPath,
            });
          }),
          catchError((error: IHttpRequestError) => of(WebhooksActions.deleteWebhookFailure({ webhook_id: action.webhook_id, error: error.error })))
        );
      }),
      catchError((error) => of(WebhooksActions.deleteWebhookFailure(error)))
    );
  });

  deleteWebhookSuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(WebhooksActions.deleteWebhookSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: data.redirectPath } }));
          }
          return of();
        }),
        catchError((error) => of(WebhooksActions.deleteWebhookFailure(error)))
      );
    }
    // { dispatch: false }
  );
}
