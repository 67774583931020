import { Component } from '@angular/core';

import { CompanyProductsBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-products',
  templateUrl: 'company-products.component.html',
})
export class CompanyProductsComponent extends CompanyProductsBaseComponent {
  constructor() {
    super();
  }
}
