import {
  CreateSegmentCommandInput,
  DeleteSegmentCommandInput,
  GetSegmentCommandInput,
  GetSegmentsCommandInput,
  SegmentDimensions,
  SegmentGroupList,
  UpdateSegmentCommandInput,
  WriteSegmentRequest,
} from '@aws-sdk/client-pinpoint';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { RequireAtLeastOne } from '../../require-one';
import { ILaunchpointUserAccountIds } from '../../security.interface';
import { ESegmentType, ISegmentDimensions, ISegmentGroupList, ISegmentImportResource } from './aws-segment.interface';

// implements SegmentResponse
export class ILaunchpointCorePinpointSegment {
  application_id: string;
  arn: string;
  creation_date: string;
  id: string;
  dimensions?: ISegmentDimensions;
  import_definition?: ISegmentImportResource;
  last_modified_date?: string;
  name?: string;
  segment_groups?: ISegmentGroupList;
  segment_type: ESegmentType;
  tags?: Record<string, string>;
  version?: number;

  //
  _id: string;
  created_at: Date;
  updated_at: Date;
  users: string[];
  accounts: string[];
}

class IWriteSegmentRequest implements WriteSegmentRequest {
  Dimensions?: SegmentDimensions;
  SegmentGroups?: SegmentGroupList;
  Name?: string;
  tags?: Record<string, string>;
}

class PinpointSegmentCreate implements CreateSegmentCommandInput {
  ApplicationId: string;
  WriteSegmentRequest: RequireAtLeastOne<IWriteSegmentRequest>;
}

export type ILaunchpointCorePinpointSegmentParamsCreate = ILaunchpointUserAccountIds & PinpointSegmentCreate;

class PinpointUpdateSegment implements UpdateSegmentCommandInput {
  ApplicationId: string;
  SegmentId: string;
  WriteSegmentRequest: RequireAtLeastOne<IWriteSegmentRequest>;
}

export type ILaunchpointCorePinpointSegmentParamsUpdate = {
  _id: string;
} & PinpointUpdateSegment;

class PinpointDeleteSegment implements DeleteSegmentCommandInput {
  ApplicationId: string;
  SegmentId: string;
}

export type ILaunchpointCorePinpointSegmentParamsDelete = {
  _id: string;
} & PinpointDeleteSegment;

class PinpointListSegment implements GetSegmentsCommandInput {
  ApplicationId: string;
  PageSize?: string;
  Token?: string;
}

export type ILaunchpointCorePinpointSegmentParamsList = PinpointListSegment;

class PinpointRetrieveSegment implements GetSegmentCommandInput {
  ApplicationId: string;
  SegmentId: string;
}

export type ILaunchpointCorePinpointSegmentParamsRetrieve = PinpointRetrieveSegment;

export class ILaunchpointCorePinpointSegmentSearchQuery {
  search?: string;
  id?: string[];
  version?: number[];
  tags?: string[];
}

export class ILaunchpointCorePinpointSegmentSearchPayload {
  query: ILaunchpointCorePinpointSegmentSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ILaunchpointCorePinpointSegmentSearchResults {
  data: ILaunchpointCorePinpointSegment[];
  pagination: IQueryPageination;
}
