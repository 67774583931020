import { Component } from '@angular/core';
import { CompanyOverviewBaseComponent } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-company-overview',
  templateUrl: 'company-overview.component.html',
  styles: ['.dropdown-toggle::after {display:none;}'],
})
export class CompanyOverviewComponent extends CompanyOverviewBaseComponent {
  constructor(
    public _Store: Store,
    public _Router: Router,
    public _route: ActivatedRoute,
    public fb: UntypedFormBuilder,
    private _swalService: LaunchpointSweetAlertService
  ) {
    super(_Store, fb);
  }

  confirmAccountOwnerChange(event: ICoreUser) {
    //
    this._swalService
      .fireAlert({
        text: `Are you sure you want to change owner to ${event?.profile?.first_name} ${event?.profile?.last_name}?`,
        icon: 'error',
        // buttonsStyling: false,
        confirmButtonText: 'Yes, change owner',
        cancelButtonText: 'No, close this!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light',
        },
      })
      .then((res) => {
        if (res.isConfirmed) {
          this.changeAccountOwner(event);
        }
      });
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this._swalService.fireToast({ icon: 'success', title: data });
      },
    });
  }

  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this._swalService.fireToast({ icon: 'error', title: data.message });
      },
    });
  }
}
