import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig, IMediaEntity } from '@launchpoint/core-client';
// import { AdminMediaPDFMakeService } from '../../services/pdf-make.service';
// import WebViewer, { WebViewerInstance, Core } from '@pdftron/webviewer';

@Component({
  selector: 'launchpoint-media-pdf-card',
  templateUrl: './media-pdf-card.component.html',
  styleUrls: ['./media-pdf-card.component.scss'],
})
export class CoreMediaPdfCardComponent implements AfterViewInit {
  @Input() mediaDetail: IMediaEntity;

  jsonReplacementData: { [key: string]: any } = {
    policy_number: 'Pn: 21423lksdf',
    pol_eff_date: '02-26-2022',
    insured_name: 'Test Name',
    company_name: 'TEST COMPANY',
    company_address: '611 W. Fort Scott Street, Butler, MO 64730',
    agent: 'AGENT NAME',
    agent_address: 'AGENT ADDRESS',
    um1: 'X',
    um2: '',
    p1: 'X',
    p2: '',
    p3: 'X',
    d1: 'X',
    d2: '',
    d3: '',
    d4: '',
    limits: [
      {
        limit: '$1,000,000',
        selection: 'X',
      },
      {
        limit: '$750,000',
        selection: '',
      },
      {
        limit: '$500,000',
        selection: '',
      },
      {
        limit: '$250,000',
        selection: '',
      },
    ],
    date: '02-19-2022',
  };

  @ViewChild('viewer') viewer: ElementRef;
  //   wvInstance: WebViewerInstance;
  wvInstance: any;
  @Output() coreControlsEvent: EventEmitter<string> = new EventEmitter();
  @Output() saveFile: EventEmitter<File> = new EventEmitter();

  private documentLoaded$: Subject<void>;

  @Input() src: string;
  constructor(@Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig) {
    this.documentLoaded$ = new Subject<void>();
  }

  // ngOnInit(): void {
  // }

  ngAfterViewInit(): void {
    if (this.mediaDetail?.media?.meta_data?.url) {
      console.log(this.mediaDetail.media.meta_data.url);
      this.loadDocument(this.mediaDetail.media.meta_data.url);
    }
    return;
  }

  loadDocument(url) {
    // WebViewer(
    //   {
    //     path: this._config.pdftron || './assets/pdftron',
    //     initialDoc: url,
    //     preloadWorker: 'office',
    //     fullAPI: true,
    //   },
    //   this.viewer.nativeElement
    // ).then(async (instance) => {
    //   this.wvInstance = instance;
    //   // this.wvInstance.UI.enableElements(['contentEditButton']);
    //   // this.wvInstance.UI.disableFeatures(['Annotations']);
    //   this.coreControlsEvent.emit(this.wvInstance.UI.LayoutMode.Single);
    //   const { documentViewer, Annotations, annotationManager } = this.wvInstance.Core;
    //   // instance.UI.openElements(['notesPanel']);
    //   documentViewer.addEventListener('annotationsLoaded', () => {
    //     console.log('annotations loaded');
    //   });
    //   annotationManager.addEventListener('annotationChanged', (data) => {
    //     console.log('data', data);
    //   });
    //   // await documentViewer.getDocument().applyTemplateValues(options);
    //   documentViewer.addEventListener('documentLoaded', async () => {
    //     this.documentLoaded$.next();
    //   });
    // });
  }

  async loadData() {
    const { documentViewer, Annotations, annotationManager } = this.wvInstance.Core;

    const doc = documentViewer.getDocument();
    const keys = await doc.getTemplateKeys();
    console.log('doc.getTemplateKeys();', keys);

    const autofillMap: { [key: string]: any } = {};

    for (let i = 0, element; (element = this.jsonReplacementData[i++]); ) {
      if (element.className === 'value-field' && element.value.length > 0) {
        try {
          const json = JSON.parse(element.value);
          autofillMap[element.name] = json;
        } catch (e) {
          autofillMap[element.name] = element.value;
        }
      }
    }
    console.log(this.jsonReplacementData);

    await documentViewer.getDocument().applyTemplateValues(this.jsonReplacementData);
    this.replaceData();
  }

  async replaceData() {
    const { documentViewer, PDFNet } = this.wvInstance.Core;
    await PDFNet.initialize();
    const doc = documentViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();

    // Run PDFNet methods with memory management
    await PDFNet.runWithCleanup(async () => {
      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      // try {
      //   doc.lock();
      // } catch (error) {
      //   console.log(error);
      // }

      const replacer = await PDFNet.ContentReplacer.create();

      // replace a text placeholder
      const replacementData = this.codePDF(this.jsonReplacementData);
      for (const key in replacementData) {
        if (Object.prototype.hasOwnProperty.call(replacementData, key)) {
          try {
            console.log('replacer.addString [{{${key}}}]', `{{${key}}}`, replacementData[key]);
            await replacer.addString(`[{{${key}}}]`, replacementData[key]);

            console.log(' await replacer.addString  key', key, replacementData[key]);
            await replacer.addString(key, replacementData[key]);
            for (let ind = 1; ind <= documentViewer.getPageCount(); ++ind) {
              const page = await pdfDoc.getPage(ind);
              await replacer.process(page);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }, '');

    // clear the cache (rendered) data with the newly updated document
    documentViewer.refreshAll();

    // Update viewer to render with the new document
    documentViewer.updateView();

    // Refresh searchable and selectable text data with the new document
    documentViewer.getDocument().refreshTextData();
  }

  getDocumentLoadedObservable() {
    return this.documentLoaded$.asObservable();
  }

  // async getAnnotations(annotationManager: Core.AnnotationManager) {
  //   console.log('getAnnotations(annotationManager)');
  //   const data = await annotationManager.exportAnnotations({ widgets: false, links: false, fields: false, generateInlineAppearances: false });
  //   console.log('getAnnotations(annotationManager): DATA >>>>>>>>>>>>>', data.valueOf());
  // }

  async flattenAnnotations() {
    const { documentViewer, PDFNet, annotationManager } = this.wvInstance.Core;

    await PDFNet.initialize();
    const doc = await documentViewer.getDocument().getPDFDoc();

    // export annotations from the document
    const annots = await annotationManager.exportAnnotations();

    // Run PDFNet methods with memory management
    await PDFNet.runWithCleanup(async () => {
      // lock the document before a write operation
      // runWithCleanup will auto unlock when complete
      doc.lock();

      // import annotations to PDFNet
      const fdf_doc = await PDFNet.FDFDoc.createFromXFDF(annots);
      await doc.fdfUpdate(fdf_doc);

      // flatten all annotations in the document
      await doc.flattenAnnotations();

      // or optionally only flatten forms
      // await doc.flattenAnnotations(true);

      // clear the original annotations
      annotationManager.deleteAnnotations(annotationManager.getAnnotationsList());

      // optionally only clear widget annotations if forms were only flattened
      // const widgetAnnots = annots.filter(a => a instanceof Annotations.WidgetAnnotation);
      // annotationManager.deleteAnnotations(widgetAnnots);
    }, '');

    // clear the cache (rendered) data with the newly updated document
    documentViewer.refreshAll();

    // Update viewer to render with the new document
    documentViewer.updateView();

    // Refresh searchable and selectable text data with the new document
    documentViewer.getDocument().refreshTextData();
  }

  codePDF(jsonReplacementData: { [key: string]: any }) {
    let limit_keys: { [key: string]: string } = {};
    const limits = [
      {
        limit: '$1,000,000',
        selection: 'X',
      },
      {
        limit: '$750,000',
        selection: '',
      },
      {
        limit: '$500,000',
        selection: '',
      },
      {
        limit: '$250,000',
        selection: '',
      },
    ];
    for (let i = 0; i < limits.length; i++) {
      const limit = limits[i];
      console.log("['l' + (i + 1)]: limit.selection", { ['l' + (i + 1)]: limit.selection });
      if (limit.selection === 'X') {
        limit_keys = { ...limit_keys, ['limit_selected']: limit.limit };
      }
      limit_keys = { ...limit_keys, ['l' + (i + 1)]: limit.selection };
    }
    return { ...jsonReplacementData, ...limit_keys };
  }

  async save() {
    const { documentViewer, annotationManager } = this.wvInstance.Core;
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const options = { xfdfString };
    const data = await doc.getFileData(options);
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: 'application/pdf' });
    const file = new File([blob], this.mediaDetail.media.meta_data.Key, { lastModified: Date.now() });
    this.saveFile.emit(file);
  }
}
