import { ModuleWithProviders, NgModule } from '@angular/core';
import { LaunchpointAbilitySecurityBaseDirective } from './directives/security-base.directive';
import { LaunchpointSecurityUserIdHeaderService } from './header-services/user-id-security-header.service';
import { LaunchpointSecurityAccountIdHeaderService } from './header-services/account-id-security-header.service';
import { ILaunchpointSecurityModuleConfig, LAUNCHPOINT_SECURITY_CONFIG_TOKEN } from './interfaces/security.interface';
import { LpSecurityPipe } from './pipes/lpsecurity.pipe';
import { LaunchpointAbilitySecurityService } from './services/security.service';

@NgModule({
  declarations: [LaunchpointAbilitySecurityBaseDirective, LpSecurityPipe],
  exports: [LaunchpointAbilitySecurityBaseDirective],
})
export class LaunchpointSecurityModule {
  /**
   *
   * @param _securityConfig ACCESS CONTROL LIST
   * @returns
   */
  static register(_securityConfig: ILaunchpointSecurityModuleConfig): ModuleWithProviders<LaunchpointSecurityModule> {
    return {
      ngModule: LaunchpointSecurityModule,
      providers: [
        {
          provide: LAUNCHPOINT_SECURITY_CONFIG_TOKEN,
          useValue: _securityConfig,
        },
        LaunchpointSecurityAccountIdHeaderService,
        LaunchpointSecurityUserIdHeaderService,
        LaunchpointAbilitySecurityService,
      ],
    };
  }
}
