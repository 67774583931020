import { InjectionToken } from '@angular/core';
import { Actions } from '@ngrx/effects';

export interface IIntercomConfig {
  app_id: string;
  token: string;
  debug?: boolean;
  actions$?: Actions;
}
export const INTERCOM_CONFIG_TOKEN = new InjectionToken<IIntercomConfig>('INTERCOM_CONFIG_TOKEN');

export type IIntercomSpace = 'home' | 'messages' | 'help' | 'news' | 'tasks' | 'tickets';

export interface IIntercomService {
  init(): void;
  //
  show(): void;
  hide(): void;
  update(): void;
  shutdown(): void;
  showNewMessage(content: string): void;
  showMessages(): void;
  getVisitorId(): void;
  startTour(tourId: number): void;
  showArticle(articleId: number): void;
  showNews(newsItemId: number): void;
  startSurvey(surveyId: number): void;
  startChecklist(checklistId: number): void;
  showTicket(ticketId: number): void;
  showConversation(conversationId: number): void;
  showSpace(space: IIntercomSpace): void;
  trackEvent(event: string, metadata?: any): void;
  onHide(callback: () => void): void;
  onShow(callback: () => void): void;
  onUnreadCountChange(callback: (unreadCount: number) => void): void;
  onUserEmailSupplied(callback: (email: string) => void): void;
}
