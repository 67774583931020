import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';

import { Observable, switchMap } from 'rxjs';
import {
  ELaunchpointDynamicFormResponse,
  ELPDefaultActions,
  ILaunchpointDynamicFormResponse,
  ILaunchpointDynamicFormResponseController,
  ILaunchpointDynamicFormResponseParamsCreate,
  ILaunchpointDynamicFormResponseParamsUpdate,
  ILaunchpointDynamicFormResponseSearchPayload,
  ILaunchpointDynamicFormResponseSearchResults,
  ILaunchpointDynamicFormResponseParamsArchive,
  ILaunchpointDynamicFormResponseParamsDelete,
  configureURL,
} from '@launchpoint/core-types';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '../../../security/header-services/account-id-security-header-v2.service';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../../../user/auth/interfaces/auth-storage-service.interface';

@Injectable()
export class LaunchpointDynamicFormResponseV1Service
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormResponseController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicFormResponse.RESPONSE,
    });
  }
  public search(params: ILaunchpointDynamicFormResponseSearchPayload): Observable<ILaunchpointDynamicFormResponseSearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicFormResponseSearchResults>(this.API_URL + '/' + ELPDefaultActions.SEARCH, params, { headers });
      })
    );
  }

  public getById(id: string): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + id, { headers });
      })
    );
  }

  public create(params: ILaunchpointDynamicFormResponseParamsCreate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public update(params: ILaunchpointDynamicFormResponseParamsUpdate): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL, params, { headers });
      })
    );
  }

  public archive(params: ILaunchpointDynamicFormResponseParamsArchive): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.ARCHIVE, params, { headers });
      })
    );
  }

  public delete(params: ILaunchpointDynamicFormResponseParamsDelete): Observable<ILaunchpointDynamicFormResponse> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicFormResponse>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }
}
