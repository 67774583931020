<div class="d-flex flex-nowrap gap-5 mt-n20">

  <div class="card shadow mx-5 cursor-pointer" *ngFor="let data of analytics$ | async"
    (click)="onCardClicked(data?.channel)" [ngClass]="{
      'selected-card': data?.channel === selectedChannel,
    }">

    <div class="card-body">
      <div class="d-flex justify-content-between">
        <div class="fw-bold divt-2">{{ data?.channel }}</div>

        <div class="h-35px w-35px rounded d-flex justify-content-center align-items-center" [ngClass]="{
            'web-icon': data?.channel === NotificationChannelType.SYSTEM || data?.channel === NotificationChannelType.SLACK,
            'text-icon': data?.channel === NotificationChannelType.SMS,
            'email-icon': data?.channel === NotificationChannelType.EMAIL,
            'push-icon': data?.channel === NotificationChannelType.PUSH,
            }">
          <i class="bi" [ngClass]="{
            'bi-laptop': data?.channel === NotificationChannelType.SYSTEM || data?.channel === NotificationChannelType.SLACK,
            'bi-chat': data?.channel === NotificationChannelType.SMS,
            'bi-envelope': data?.channel === NotificationChannelType.EMAIL,
            'bi-bullseye': data?.channel === NotificationChannelType.PUSH
            }"></i>
        </div>
      </div>

      <div>
        <h1 class="current-notification mb-4 pt-4">{{ data?.totalCount }}</h1>
      </div>
      <div *ngFor="let job of data?.jobStates">
        <p class="text-muted fs-9" *ngIf="job?.state === 'completed'">{{ job?.count ?? 0 }}</p>
      </div>
    </div>

  </div>
</div>