<tagify
  class="me-5"
  [ngModel]="messagesModel"
  (ngModelChange)="selectData($event)"
  [whitelist]="whitelistMessages$"
  [settings]="settings"
  [inputClass]="inputClass"
  name="messages"
/>

