<div class="d-flex flex-column flex-root" id="kt_app_root" style="height: 100vh;">

  <!--begin::Body-->
  <div class="d-flex flex-row h-50" [ngClass]="{
    'bg-primary': !_config?.auth?.background_image && !_config?.auth?.background_color,
  }" [style]="setupStyle">

    <div class="p-5">
      <ng-container *ngIf="_config?.auth?.logo">
        <img [src]="_config.auth.logo" alt="Logo" style="height: 40px; width: auto;" />
      </ng-container>
    </div>

  </div>


  <div class="p-absolute">
    <router-outlet></router-outlet>
  </div>

  <!--begin::Footer-->
  <div class="d-flex h-50 bg-white justify-content-center pb-5">
    <!--begin::Links-->
    <div class="d-flex align-items-end fw-semibold text-primary fs-base" *ngIf="_config?.footers">
      <a *ngFor="let footer of _config?.footers" [href]="footer.href" class="px-5" target="_blank">
        {{ footer.title }}
      </a>
    </div>
    <!--end::Links-->
  </div>
  <!--end::Footer-->
</div>