// import { CompanyStripeCustomerV1Service } from './company-stripe-customer.service';
// import { CompanyStripePaymentMethodV1Service } from './company-stripe-payment-method.service';
import { CompanyAccountOwnerV2Service } from './company-account-owner.service';
import { CompanyAddressV2Service } from './company-address.service';
import { CompanyV2Service } from './company.service';

export const COMPANY_SERVICES = [CompanyV2Service, CompanyAddressV2Service, CompanyAccountOwnerV2Service];

export * from './company.service';
export * from './company-address.service';
export * from './company-account-owner.service';
// export * from './company-stripe-customer.service';
// export * from './company-stripe-payment-method.service';
