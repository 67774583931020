/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/component-selector */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'video-player',
  template: ` <video #target class="video-js  vjs-big-play-centered" controls preload="none"></video>`,
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;

  @Input() options: {
    autoplay: boolean;
    controls: boolean;
    fluid: boolean;
    preload: string;
    playbackRates: [0.5, 1, 1.5, 2];
    poster: string;
    nativeTextTracks: string;
    // inactivityTimeout: 500;
    // techOrder: ['html5'];
    // html5: {
    //   vhs: {
    //     overrideNative: true;
    //   };
    // };
    sources: {
      src: string;
      type: string;
    }[];
  };

  @Input() debug = false;

  player: videojs.Player;

  @Output() playing = new EventEmitter<number>();
  @Output() paused = new EventEmitter<number>();
  @Output() ended = new EventEmitter<number>();
  @Output() timeupdate = new EventEmitter<number>();
  @Output() error = new EventEmitter<unknown>();

  ngOnInit() {
    this.initVideoPlayer();
  }

  ngOnDestroy() {
    if (this.player) {
      // TODO: POST REQUEST with current time.
      this.player.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const sources = this.options.sources.map((source) => source.src);
    const changesSources = changes.options.currentValue.sources.filter((source) => !sources.includes(source.src));

    if (this.debug) {
      console.log('sources', sources);
      console.log('changesSources', changesSources);
    }

    if (changes.firstChange) {
      return;
    }
    if (this.player) {
      // check that the sources have changed to avoid reloading the same video
      if (changesSources.length) {
        this.player.src(changes.options.currentValue.sources.src);
        this.player.load();
      }
    } else {
      this.initVideoPlayer();
    }
  }

  initVideoPlayer() {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      // console.log('onPlayerReady');
    });

    this.player.on('play', () => this.playing.emit(this.currentTime));
    this.player.on('pause', () => this.paused.emit(this.currentTime));
    this.player.on('timeupdate', () => this.timeupdate.emit(this.currentTime));
    this.player.on('ended', () => this.ended.emit(this.currentTime));
    this.player.on('error', (data) => this.error.emit(data));
  }

  get currentTime(): number {
    if (!this.player) {
      return 0;
    }

    if (this.debug) {
      console.log('currentTime', this.player.currentTime());
    }

    return this.player.currentTime();
  }
}
