import { Directive, OnInit } from '@angular/core';
import { UserAuthActions } from '../+state';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../+state/auth-base.component';
import { firstValueFrom } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientAuthLogoutBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  email: string;

  ngOnInit(): void {
    this.logoutDispatch();
  }

  private async logoutDispatch() {
    try {
      const user = await firstValueFrom(this.user$);
      // console.log('user', user.email);
      if (user) {
        // console.log('yes user');1
        await this.logOutScripts();
      }
      await this.logout();
    } catch (error) {
      console.log(error);
    }
  }

  async logOutScripts() {
    console.log('logOutScripts');
  }

  /**
   * DO NOT CHANGE THIS FROM `ASYNC` !!
   *
   * the logout scripts need to run before the logout action is dispatched
   * if you change this to a sync function, the logout action will be dispatched before the scripts run
   */
  private async logout() {
    this._Store.dispatch(UserAuthActions.logout({ path: ['auth', 'login'] }));
  }
}
