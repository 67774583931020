<div class="d-flex flex-wrap flex-stack mb-6">
  <h3 class="fw-bolder my-2">
    My Campaigns
    <span class="fs-6 text-gray-400 fw-bold ms-1">30 Days</span>
  </h3>

  <div class="d-flex align-items-center my-2">
    <div class="w-100px me-5">
      <select name="status" data-control="select2" data-hide-search="true"
        class="form-select form-select-white form-select-sm">
        <option value="1" selected>30 Days</option>
        <option value="2">90 Days</option>
        <option value="3">6 Months</option>
        <option value="4">1 Year</option>
      </select>
    </div>
    <button class="btn btn-primary btn-sm" data-bs-toggle="tooltip" title="Coming soon">Add Campaign</button>
  </div>
</div>

<div class="row g-6 g-xl-9">
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/twitch.svg" title="Twitch Posts" description="$500.00"
      status="down" [statusValue]="40.5" statusDesc="more impressions" [progress]="0.5"
      progressType="MRR"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/twitter.svg" title="Twitter Followers"
      description="807k" status="up" [statusValue]="17.62" statusDesc="Followers growth" [progress]="5"
      progressType="New trials"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/spotify.svg" title="Spotify Listeners"
      description="1,073" status="down" [statusValue]="10.45" statusDesc="Less comments than usual" [progress]="40"
      progressType="Impressions"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/pinterest-p.svg" title="Pinterest Posts"
      description="97" status="up" [statusValue]="26.1" statusDesc="More posts" [progress]="10"
      progressType="Spend"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/github.svg" title="Github Contributes"
      description="4,109" status="down" [statusValue]="32.8" statusDesc="Less contributions" [progress]="40"
      progressType="Dispute"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/youtube-play.svg" title="Youtube Subscribers"
      description="354" status="up" [statusValue]="29.45" statusDesc="Subscribers growth" [progress]="40"
      progressType="Subscribers"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/telegram.svg" title="Telegram Posts" description="566"
      status="up" [statusValue]="11.4" statusDesc="more clicks" [progress]="40"
      progressType="Profit"></launchpoint-app-card5>
  </div>
  <div class="col-sm-6 col-xl-4">
    <launchpoint-app-card5 image="./assets/media/svg/brand-logos/reddit.svg" title="Reddit Awards" description="2.1M"
      status="up" [statusValue]="46.7" statusDesc="more adds" [progress]="0.0"
      progressType="Retention"></launchpoint-app-card5>
  </div>
</div>

<div class="d-flex flex-stack flex-wrap pt-10">
  <div class="fs-6 fw-bold text-gray-700">Showing 1 to 10 of 50 entries</div>

  <ul class="pagination">
    <li class="page-item previous">
      <a class="page-link cursor-pointer">
        <i class="previous"></i>
      </a>
    </li>

    <li class="page-item active">
      <a class="page-link cursor-pointer"> 1 </a>
    </li>

    <li class="page-item">
      <a class="page-link cursor-pointer"> 2 </a>
    </li>

    <li class="page-item">
      <a class="page-link cursor-pointer"> 3 </a>
    </li>

    <li class="page-item">
      <a class="page-link cursor-pointer"> 4 </a>
    </li>

    <li class="page-item">
      <a class="page-link cursor-pointer"> 5 </a>
    </li>

    <li class="page-item">
      <a class="page-link cursor-pointer"> 6 </a>
    </li>

    <li class="page-item next">
      <a class="page-link cursor-pointer">
        <i class="next"></i>
      </a>
    </li>
  </ul>
</div>