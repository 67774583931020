import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../../../auth';
import { ICoreUser } from '../../../../user-company-account.interface';
import { ICoreUserAccountsSecurityRolesParamsUpdate } from './core-accounts-security-roles.interface';

/**
 * @Controller('user/account/security-roles')
 */
export interface ICoreUserAccountSecurityRolesController {
  /**
   * Put('add')
   * @param body
   */
  addRoles(body: ICoreUserAccountsSecurityRolesParamsUpdate, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put('remove')
   * @param body
   */
  removeRoles(body: ICoreUserAccountsSecurityRolesParamsUpdate, user?: ICoreUserJWTDecorator): Promise<ICoreUser> | Observable<ICoreUser>;
}
