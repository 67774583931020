import { LaunchpointCoreWebWebhookModalComponent } from './webhook-modal/webhook-modal.component';
import { LaunchpointCoreWebWebhookDetailComponent } from './webhook/webhook.component';
import { LaunchpointCoreWebWebhooksTableFilterComponent } from './webhooks-table-filter/webhooks-table-filter.component';
import { LaunchpointCoreWebWebhooksTableComponent } from './webhooks-table/webhooks-table.component';
import { LaunchpointCoreWebWebhooksToolbarComponent } from './webhooks-toolbar/webhooks-toolbar.component';

export const WEBHOOKS_COMPONENTS = [
  //
  LaunchpointCoreWebWebhooksToolbarComponent,
  LaunchpointCoreWebWebhooksTableComponent,
  LaunchpointCoreWebWebhookDetailComponent,
  LaunchpointCoreWebWebhooksTableFilterComponent,
  LaunchpointCoreWebWebhookModalComponent,
];

export * from './webhook-modal/webhook-modal.component';
export * from './webhook/webhook.component';
export * from './webhooks-table-filter/webhooks-table-filter.component';
export * from './webhooks-table/webhooks-table.component';
export * from './webhooks-toolbar/webhooks-toolbar.component';
