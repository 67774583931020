export * from './mixed-widget1/mixed-widget1.component';
export * from './mixed-widget10/mixed-widget10.component';
export * from './mixed-widget11/mixed-widget11.component';
export * from './mixed-widget2/mixed-widget2.component';
export * from './mixed-widget3/mixed-widget3.component';
export * from './mixed-widget4/mixed-widget4.component';
export * from './mixed-widget5/mixed-widget5.component';
export * from './mixed-widget6/mixed-widget6.component';
export * from './mixed-widget7/mixed-widget7.component';
export * from './mixed-widget8/mixed-widget8.component';
export * from './mixed-widget9/mixed-widget9.component';
