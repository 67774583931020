import { InjectionToken } from "@angular/core";


export interface IErrorModuleConfig {
  logo: string;
  background_image: string;
  return_route_button_title: string 
  footers: { title: string; href: string }[];
  pages_400?: { title: string; description: string };
  pages_500?: { title: string; description: string };
  route: 'error' | string;
}

export const APP_ERROR_CONFIG_TOKEN = new InjectionToken<IErrorModuleConfig>('ErrorServiceInjectable');

