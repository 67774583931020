<div
  class="bg-background bg-contain bg-right bg-no-repeat py-14 text-white lg:bg-[url('./assets/img/story-logo.webp')] lg:py-40"
  style="background-size: 300px"
>
  <div class="mx-auto flex max-w-[1700px] flex-col justify-around lg:flex-row xl:px-40">
    <div class="font-oswald hidden space-y-16 text-8xl font-[600] uppercase text-white/30 lg:block">
      <p class="text-white">Rob's</p>
      <p>Story</p>
      <p>Approach</p>
      <p>& Purpose</p>
    </div>
    <div class="font-oswald mb-10 px-4 text-4xl font-[600] uppercase text-white/30 lg:hidden">
      <p><span class="text-white">Rob's</span> Story</p>
      <p>Approach & Purpose</p>
    </div>
    <div class="flex flex-col justify-between space-y-10 px-4 md:w-[80%] lg:w-[500px]">
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">HOW IT STARTED</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Inspired by my grandfather's service and the events of 9/11, I set my sights on joining the Marines. I graduated early, enlisted, and
          immersed myself in tech to build a solid foundation for the future.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 text-[24px] lg:text-[30px]">WHY I DO WHAT I DO</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          I'm bringing big technology solutions to small businesses because they deserve better.
        </p>
      </div>
      <div>
        <h1 class="font-oswald mb-3 pr-14 text-[24px] lg:pr-0 lg:text-[30px]">OPENED LAUNCHPOINT, A VETERAN OWNED BUSINESS</h1>
        <p class="text-sm font-[400] leading-relaxed lg:text-base">
          Created to bring high-powered tech solutions to businesses of all sizes, with a special focus on empowering small and mid-sized companies
          with tools typically reserved for industry giants.
        </p>
      </div>
    </div>
  </div>
</div>
