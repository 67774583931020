export interface IDynamicFormOpenAiParamsPostMessage {
  prompt: string;
  thread_id: string;
}

export interface IDynamicFormOpenAiParamsGetMessageResults {
  thread_id: string;
}

export interface IDynamicFormOpenAiMessageThreadId {
  thread_id: string;
}

export interface IDynamicFormResponseOpenAiRunData {
  status: 'message_completed' | 'processing' | 'run_ended';
  message: string;
}
