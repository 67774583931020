import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreUserV1Service, LaunchpointCoreClientBaseComponent, NotificationsHTTPService } from '@launchpoint/core-client';
import { INotification, INotificationSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebNotificationsTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;

  @Input() notifications: INotification[] = [];
  @Input() query: INotificationSearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 100, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  public notificationsModel: { value: string; data: INotification }[] = [];
  public whitelistNotifications$: Observable<{ value: string; data: INotification }[]>;

  protected tagify: Tagify;

  public settings: TagifySettings = {
    placeholder: 'Search Notifications...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false, // <-- if this is set to true, you CANNOT pre-load with value, but be set after the fact
    keepInvalidTags: false,
  };

  @Output() selectedNotification = new EventEmitter<INotification<any>[]>();

  constructor(
    public _NotificationsHTTPService: NotificationsHTTPService,
    public tagifyService: TagifyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.whitelistNotifications$ = this._NotificationsHTTPService
      .search({ query: this.query ?? {}, pagination: this.pagination, querySort: this.querySort })
      .pipe(
        map((f) =>
          f.data.map((d) => ({
            value: d?.subject ?? d?._id,
            data: d,
          }))
        )
      );
    if (this.notifications?.length > 0) {
      this.notificationsModel = this.notifications.map((d) => ({
        value: d?.subject ?? d?._id,
        data: d,
      }));

      setTimeout(() => {
        if (this.notificationsModel?.length) {
          this.tagifyService.get('notifications').loadOriginalValues(this.notificationsModel);
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    }
  }

  formatter = (result: INotification): string => result?.subject ?? result?._id;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  clear() {
    this.tagifyService.get('notifications').removeAllTags();
  }

  search: OperatorFunction<string, readonly INotification[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._NotificationsHTTPService.search({ pagination: this.pagination, querySort: this.querySort, query: { ...this.query, search } }).pipe(
      map((data) => {
        this.loading = false;
        return data.data;
      })
    );
  }

  selectData(data: any) {
    // console.log('selectData', data);
    return this.selectedNotification.emit(data.map((f) => f.data));
  }
}
