<div class="bg-medium py-10 md:py-20">
  <div class="mx-auto max-w-[1700px] px-4">
    <div class="space-y-10 text-center">
      <p class="font-oswald px-10 text-[30px] font-semibold md:px-20 md:text-[38px]">MORE TOOLS AND TIPS ON SOCIAL | Rob
        Stadnick</p>
      <p class="mb-4 text-[18px] font-semibold">&#64;robstadnick</p>
      <!-- <div class="space-x-4 text-[#2F5142]">
        @for (item of social_links; track $index) {
          @if (item.active) {
            @if (item.href) {
              <a
                [href]="item.href"
                target="_blank"
                class="inline-flex h-8 w-8 items-center justify-center rounded-full border-[1px] border-[#2F5142] hover:border-primary hover:text-primary 2xl:h-10 2xl:w-10"
              >
                <i class="fa-brands {{ item.icon }} text-2xl"></i>
              </a>
            }
          }
        }
      </div> -->
      <sd-social-icons></sd-social-icons>
    </div>

    <!-- <div class="mt-20 flex justify-center">
      <div class="hidden h-[450px] w-[800px] overflow-hidden lg:block">
        <img src="./assets/img/social-media/1.png" class="h-full w-full object-cover object-top" />
      </div>
      <div class="h-[162px] w-[148px] overflow-hidden md:h-[306px] md:w-[306px] lg:h-[450px] lg:w-[463px]">
        <img src="./assets/img/social-media/2.png" class="h-full w-full object-cover object-top" />
      </div>
      <div class="h-[162px] w-[148px] overflow-hidden md:h-[306px] md:w-[306px] lg:h-[450px] lg:w-[463px]">
        <img src="./assets/img/social-media/3.png" class="h-full w-full object-cover object-top" />
      </div>
    </div>
    <div class="flex justify-center">
      <div class="hidden h-[450px] w-[463px] overflow-hidden lg:block">
        <img src="./assets/img/social-media/4.png" class="h-full w-full object-cover object-top" />
      </div>
      <div class="hidden h-[450px] w-[463px] overflow-hidden lg:block">
        <img src="./assets/img/social-media/5.png" class="h-full w-full object-cover object-top" />
      </div>
      <div class="h-[164px] w-[296px] overflow-hidden md:h-[300px] md:w-[612px] lg:h-[450px] lg:w-[800px]">
        <img src="./assets/img/social-media/6.png" class="h-full w-full object-cover object-top" />
      </div>
    </div> -->


    <div class="flex mt-20 justify-center">
      <blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/robstadnick/"
        data-instgrm-version="14"
        style=" background:transparent; border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:800; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);">

      </blockquote>
    </div>



  </div>
</div>