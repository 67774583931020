import { Directive, Input } from '@angular/core';
import { CompanyStateBaseComponent } from '../../+state/company-state.base-component';

@Directive()
export abstract class CompanyHeaderBaseComponent extends CompanyStateBaseComponent {
  @Input() menu_items: {
    title: string;
    page: string;
  }[];
  // companyEntity$: Observable<SkylabCompanyEnitityState>;companyEntity: SkylabCompanyEnitityState
  // @Input() connections: IConnection[];
  // statusOptions = ECompanyStatus;
  // accountTypes = EUserAccountTypes;
  // constructor(public _Store: Store) {
  //   super();
  // }
}
