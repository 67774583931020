<div class="card border-hover-primary">
  <!--begin::Card header-->
  <div class="card-header border-0 pt-9">
    <!--begin::Card Title-->
    <div class="card-title m-0">
      <!--begin::Avatar-->
      <div class="symbol symbol-50px w-50px bg-light">
        <launchpoint-skeleton-loaders appearance="circle" count="1" [theme]="{
            'border-radius': '5px',
            height: '50px',
            border: '1px solid white'
          }"></launchpoint-skeleton-loaders>
      </div>
      <!--end::Avatar-->
    </div>
    <!--end::Car Title-->
    <!--begin::Card toolbar-->
    <div class="card-toolbar">
      <launchpoint-skeleton-loaders appearance="circle" count="1" [theme]="{
          'border-radius': '5px',
          height: '25px',
          width: '100px',
          border: '1px solid white'
        }"></launchpoint-skeleton-loaders>
    </div>
    <!--end::Card toolbar-->
  </div>
  <!--end:: Card header-->
  <!--begin:: Card body-->
  <div class="card-body p-9">
    <!--begin::Name-->
    <div class="fs-3 fw-bolder text-dark">
      <launchpoint-skeleton-loaders appearance="line"></launchpoint-skeleton-loaders>
    </div>
    <!--end::Name-->
    <!--begin::Description-->
    <p class="text-gray-700 fw-bold fs-5 mt-1 mb-7">
      <launchpoint-skeleton-loaders appearance="line"></launchpoint-skeleton-loaders>
    </p>
    <!--end::Description-->
    <!--begin::Info-->
    <div class="d-flex flex-wrap mb-5">
      <!--begin::Due-->
      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
        <div class="symbol symbol-50px w-50px bg-light">
          <launchpoint-skeleton-loaders appearance="circle" count="1" [theme]="{
              'border-radius': '5px',
              height: '25px',
              border: '1px solid white'
            }"></launchpoint-skeleton-loaders>
        </div>
      </div>
      <!--end::Due-->
      <!--begin::Budget-->
      <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
        <div class="symbol symbol-50px w-50px bg-light">
          <launchpoint-skeleton-loaders appearance="circle" count="1" [theme]="{
              'border-radius': '5px',
              height: '25px',
              border: '1px solid white'
            }"></launchpoint-skeleton-loaders>
        </div>
      </div>
      <!--end::Budget-->
    </div>
    <!--end::Info-->
    <!--begin::Progress-->
    <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title=""
      data-bs-original-title="This project 50% completed">
      <launchpoint-skeleton-loaders appearance="line" count="1" [theme]="{
          'border-radius': '5px',
          height: '4px',
          border: '1px solid white'
        }"></launchpoint-skeleton-loaders>
    </div>
    <!--end::Progress-->
  </div>
  <!--end:: Card body-->
</div>