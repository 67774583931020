import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LaunchpointCoreClientNotificationConfig,
  MessageFeatureKey,
  MessageReducer,
  MessagesEffects,
  NOTIFICATION_CONFIG_TOKEN,
  NOTIFICATION_SERVICES,
  NotificationEffects,
  NotificationFeatureKey,
  NotificationReducer,
  TopicEffects,
  TopicFeatureKey,
  TopicReducer,
  UserNotificationEffects,
  UserNotificationFeatureKey,
  UserNotificationReducer,
} from '@launchpoint/core-client';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { FormControlCheckBoxesComponent } from '../components/from-control/check-boxes/form-control-check-boxes.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { LaunchpointCoreWebNotificationMessageCreateComponent } from './components/messages/notifications-message-create/notifications-message-create.component';
import { LaunchpointCoreWebNotificationMessagesCarouselComponent } from './components/messages/notifications-messages-carousel/notifications-messages-carousel.component';
import { LaunchpointCoreWebNotificationsMessagesFilterComponent } from './components/messages/notifications-messages-filter/notifications-messages-filter.component';
import { LaunchpointCoreWebNotificationMessagesToolbarComponent } from './components/messages/notifications-messages-toolbar/notifications-messages-toolbar.component';
import { LaunchpointCoreWebNotificationMessagesTableComponent } from './components/messages/notifications-messages-table/notifications-messages-table.component';
import { LaunchpointCoreWebNotificationDashboardComponent } from './components/notifications-dashboard/notifications-dashboard.component';
import { LaunchpointCoreWebNotificationCreateComponent } from './components/notifications/notification-create/notification-create.component';
import { LaunchpointCoreWebNotificationsFilterComponent } from './components/notifications/notifications-filter/notifications-filter.component';
import { LaunchpointCoreWebNotificationToolbarComponent } from './components/notifications/notifications-toolbar/notifications-toolbar.component';
import { LaunchpointCoreWebNotificationTableComponent } from './components/notifications/notifications-table/notifications-table.component';
import { LaunchpointCoreWebNotificationTopicModalComponent } from './components/topics/notifications-topic-modal/notifications-topics-modal.component';
import { LaunchpointCoreWebNotificationsTopicsFilterComponent } from './components/topics/notifications-topics-filter/notifications-topics-filter.component';
import { LaunchpointCoreWebNotificationTopicsToolbarComponent } from './components/topics/notifications-topics-toolbar/notifications-topics-toolbar.component';
import { LaunchpointCoreWebNotificationTopicsSearchAheadComponent } from './components/topics/notifications-topics-search-ahead/notifications-topics-search-ahead.component';
import { LaunchpointCoreWebNotificationTopicsComponent } from './components/topics/notifications-topics/notifications-topics.component';
import { LaunchpointCoreWebNotificationUserNotificationsToolbarComponent } from './components/user-notifications/notifications-user-notifications-toolbar/notifications-user-notifications-toolbar.component';
import { CompanyTagifyWebComponent } from '../company/components/company-tagify/company-tagify.component';
import { LaunchpointCoreWebNotificationUserNotificationsComponent } from './components/user-notifications/notifications-user-notifications/notifications-user-notifications.component';
import { LaunchpointCoreWebUserNotificationsFilterComponent } from './components/user-notifications/notifications-user-notifications-filter/notifications-user-notifications-filter.component';
import { LaunchpointCoreWebNotificationMessagesTagifyWebComponent } from './components/messages/notifications-messages-tagify/notifications-messages-tagify.component';
import { LaunchpointCoreWebNotificationsTagifyWebComponent } from './components/notifications/notifications-tagify/notifications-tagify.component';
import { LaunchpointCoreWebNotificationsTopicsTagifyWebComponent } from './components/topics/notifications-topics-tagify/notifications-topics-tagify.component';
import { LaunchpointCoreWebBooleanFilterComponent } from '../../components/boolean-filter/boolean-filter.component';
import { LaunchpointCoreWebUsersTagifyComponent } from '../users/users-tagify/users-tagify.component';
import { LaunchpointCoreWebUserSearchAheadComponent } from '../users/user-search-ahead-standalone/user-search-ahead.component';

const NOTIFICATION_COMPONENTS = [
  LaunchpointCoreWebNotificationDashboardComponent,
  //
  LaunchpointCoreWebNotificationTableComponent,
  LaunchpointCoreWebNotificationToolbarComponent,
  LaunchpointCoreWebNotificationCreateComponent,
  LaunchpointCoreWebNotificationsFilterComponent,
  //
  LaunchpointCoreWebNotificationMessagesTableComponent,
  LaunchpointCoreWebNotificationMessageCreateComponent,
  LaunchpointCoreWebNotificationMessagesToolbarComponent,
  LaunchpointCoreWebNotificationMessagesCarouselComponent,
  LaunchpointCoreWebNotificationsMessagesFilterComponent,
  //
  LaunchpointCoreWebNotificationTopicsComponent,
  LaunchpointCoreWebNotificationTopicModalComponent,
  LaunchpointCoreWebNotificationTopicsToolbarComponent,
  LaunchpointCoreWebNotificationsTopicsFilterComponent,
  //
  LaunchpointCoreWebNotificationUserNotificationsToolbarComponent,
  LaunchpointCoreWebNotificationUserNotificationsComponent,
  LaunchpointCoreWebUserNotificationsFilterComponent,
];

@NgModule({
  declarations: [...NOTIFICATION_COMPONENTS],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    TagifyModule,
    LaunchpointFormControlDateRangeComponent,
    StoreModule.forFeature(NotificationFeatureKey, NotificationReducer),
    EffectsModule.forFeature(NotificationEffects),
    StoreModule.forFeature(TopicFeatureKey, TopicReducer),
    EffectsModule.forFeature(TopicEffects),
    StoreModule.forFeature(MessageFeatureKey, MessageReducer),
    EffectsModule.forFeature(MessagesEffects),
    StoreModule.forFeature(UserNotificationFeatureKey, UserNotificationReducer),
    EffectsModule.forFeature(UserNotificationEffects),
    EditorModule,
    LaunchpointCoreWebUsersTagifyComponent,
    CompanyTagifyWebComponent,
    FormControlCheckBoxesComponent,
    LaunchpointCoreWebPaginationComponent,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointCoreWebNotificationTopicsSearchAheadComponent,
    LaunchpointCoreWebUserSearchAheadComponent,
    LaunchpointCoreWebBooleanFilterComponent,
    LaunchpointCoreWebNotificationMessagesTagifyWebComponent,
    LaunchpointCoreWebNotificationsTagifyWebComponent,
    LaunchpointCoreWebNotificationsTopicsTagifyWebComponent,
  ],
  exports: [...NOTIFICATION_COMPONENTS],
  providers: [...NOTIFICATION_SERVICES, LaunchpointFormControlDateRangeComponent],
})
export class LaunchpointCoreWebNotificationsModule {
  static forRoot(_config: LaunchpointCoreClientNotificationConfig): ModuleWithProviders<LaunchpointCoreWebNotificationsModule> {
    return {
      ngModule: LaunchpointCoreWebNotificationsModule,
      providers: [
        {
          provide: NOTIFICATION_CONFIG_TOKEN,
          useValue: _config,
        },
      ],
    };
  }
}
