import { IHttpException, IMediaCategorySearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as AdminMediaCategoryActions from '../actions/media-category.actions';
import { IMediaCategoryEntity } from './media-category-entity.interface';

export const coreMediaCategoryFeatureKey = 'coreMediaCategoryFeatureKey';

export interface IMediaCategoryEntityState extends EntityState<IMediaCategoryEntity> {
  selected_media_category_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IMediaCategorySearchQuery;
}

export const AdminMediaCategoryAdaptor: EntityAdapter<IMediaCategoryEntity> = createEntityAdapter<IMediaCategoryEntity>({
  selectId: (media_category) => {
    return media_category?.media_category_id;
  },
});

export const initialMediaCategoryState: IMediaCategoryEntityState = AdminMediaCategoryAdaptor.getInitialState({
  selected_media_category_id: '',
  loaded: false,
  loading: false,
  error: null,
  success_message: null,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
});

export const coreMediaCategoryReducer = createReducer(
  initialMediaCategoryState,
  on(AdminMediaCategoryActions.searchAllMediaCategories, (state) => {
    return AdminMediaCategoryAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(AdminMediaCategoryActions.searchAllMediaCategorySuccess, (state, { data, success_message }) => {
    const mediaEntities = data.data.map((media_category) => {
      const mediaEntity: IMediaCategoryEntity = {
        media_category_id: media_category._id,
        media_category,
        loaded: true,
        loading: false,
        error: null,
        success_message: success_message ?? null,
      };
      return mediaEntity;
    });
    return AdminMediaCategoryAdaptor.setAll(mediaEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
      success_message,
    });
  }),
  on(AdminMediaCategoryActions.searchAllMediaCategoryFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(AdminMediaCategoryActions.updateMediaCategoryPagination, (state, { pagination }) => {
    return AdminMediaCategoryAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(AdminMediaCategoryActions.updateMediaCategorySorting, (state, { querySort }) => {
    return AdminMediaCategoryAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(AdminMediaCategoryActions.updateMediaCategoryQuery, (state, { query }) => {
    return AdminMediaCategoryAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(AdminMediaCategoryActions.getMediaCategoryById, (state, { media_category_id }) => ({
    ...state,
    selected_media_category_id: media_category_id,
    loaded: false,
    loading: true,
    error: null,
    success_message: null,
  })),
  on(AdminMediaCategoryActions.getMediaCategoryByIdSuccess, (state, { data, success_message }) => {
    const entity: IMediaCategoryEntity = {
      media_category_id: data._id,
      media_category: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return AdminMediaCategoryAdaptor.upsertOne(entity, {
      ...state,
      selected_media_category_id: data._id,
      loaded: true,
      loading: false,
    });
  }),
  on(AdminMediaCategoryActions.getMediaCategoryByIdFailure, (state, { media_category_id, error }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: media_category_id,
        changes: {
          error,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  on(AdminMediaCategoryActions.createMediaCategory, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AdminMediaCategoryActions.createMediaCategorySuccess, (state, { data, success_message }) => {
    const entity: IMediaCategoryEntity = {
      media_category_id: data._id,
      media_category: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return AdminMediaCategoryAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
      selected_media_category_id: data._id,
    });
  }),
  on(AdminMediaCategoryActions.createMediaCategoryFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),
  on(AdminMediaCategoryActions.updateMediaCategory, (state, { data }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaCategoryActions.updateMediaCategorySuccess, (state, { data, success_message }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          media_category: data,
          loading: false,
          loaded: true,
          error: null,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaCategoryActions.updateMediaCategoryFailure, (state, { media_category_id, error }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: media_category_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaCategoryActions.deleteMediaCategory, (state, { media_category_id }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: media_category_id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaCategoryActions.deleteMediaCategorySuccess, (state, { data, success_message }) => {
    return AdminMediaCategoryAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(AdminMediaCategoryActions.deleteMediaCategoryFailure, (state, { media_category_id, error }) => {
    return AdminMediaCategoryAdaptor.updateOne(
      {
        id: media_category_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  })
);
