import { Directive, Input } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { ICoreUser } from '@launchpoint/core-types';

@Directive()
export class LaunchpointCoreClientDeactivateAccountBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() user: ICoreUser;
  constructor() {
    super();
  }
}
