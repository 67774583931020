import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientMediaBaseComponent, deleteMedia, searchAllMedia } from '@launchpoint/core-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ISwalAlertParamsToast, LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { LaunchpointCoreWebMediaUploadModalComponent } from '../media-upload/media-upload-modal/media-upload-modal.component';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';

@Component({
  selector: 'launchpoint-media-toolbar',
  templateUrl: './media-toolbar.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebMediaToolbarComponent extends LaunchpointCoreClientMediaBaseComponent {
  createModal: NgbModalRef;
  private _swal = inject(LaunchpointSweetAlertService);
  public modalService = inject(NgbModal);

  options: IToolbarConfig = {
    title: 'Media',
    searchPlaceholder: 'Search by title or description',
    formControl: this.form_control,
    queryCount: this.queryFilterCount$,
    createTooltip: 'Upload',
    refresh: true,
  };

  refresh() {
    this.ngOnInit();
  }

  upload() {
    this.createModal = this.modalService.open(LaunchpointCoreWebMediaUploadModalComponent, { size: 'lg' });
    this.createModal.result
      .then(() => {
        this._Store.dispatch(searchAllMedia());
      })
      .catch((error) => {
        //
      });
  }

  deleteById(media_id: string, event?: Event) {
    event?.stopPropagation();

    this._swal
      .fireAlert({
        text: 'Are you sure you want to delete this media?',
        icon: 'warning',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
        showCancelButton: true,
        showCloseButton: true,
        showConfirmButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteMedia({ _id: media_id });
        }
      });
  }

  override toast(params: ISwalAlertParamsToast) {
    this._swal.fireToast(params);
  }
}
