import { Directive } from '@angular/core';
import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookEventSearchQuery,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { WebhookEventActions } from './actions';
import { WebhookEventsEntityState } from './interfaces/webhook-events.interface';
import {
  selectSelectedWebhookEvents,
  selectSelectedWebhookEventsError,
  selectSelectedWebhookEventsLoading,
  selectSelectedWebhookEventsSuccess,
  selectWebhookEvents,
  selectWebhookEventsError,
  selectWebhookEventsLoading,
  selectWebhookEventsPagination,
  selectWebhookEventsQuery,
  selectWebhookEventsQuerySort,
  selectWebhookEventsSuccess,
  selectWebhookEventsSuccessQueryCount,
} from './selectors/webhooks.selectors';

@Directive()
export abstract class LaunchpointCoreClientWebhookEventsStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreActivityHistoryWebhookEventSearchQuery>;
  queryFilterCount$: Observable<number>;

  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;

  webhookEventsEntities$: Observable<WebhookEventsEntityState[]>;

  webhookEvent$: Observable<ICoreActivityHistoryWebhookEvent>;
  webhookEventLoading$: Observable<boolean>;
  webhookEventError$: Observable<IHttpException>;
  webhookEventSuccess$: Observable<string>;

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(selectWebhookEventsLoading);
    this.pagination$ = this._Store.select(selectWebhookEventsPagination);
    this.querySort$ = this._Store.select(selectWebhookEventsQuerySort);
    this.query$ = this._Store.select(selectWebhookEventsQuery);
    this.queryFilterCount$ = this._Store.select(selectWebhookEventsSuccessQueryCount);

    this.error$ = this._Store.select(selectWebhookEventsError);
    this.success$ = this._Store.select(selectWebhookEventsSuccess);

    this.webhookEventsEntities$ = this._Store.select(selectWebhookEvents);

    // Entity
    this.webhookEvent$ = this._Store.select(selectSelectedWebhookEvents);
    this.webhookEventLoading$ = this._Store.select(selectSelectedWebhookEventsLoading);
    this.webhookEventError$ = this._Store.select(selectSelectedWebhookEventsError);
    this.webhookEventSuccess$ = this._Store.select(selectSelectedWebhookEventsSuccess);
  }

  getWebhooks(event_id: string) {
    this._Store.dispatch(WebhookEventActions.getWebhookEventById({ event_id }));
  }
  deleteWebhookEvent(event_id: string) {
    this._Store.dispatch(WebhookEventActions.deleteWebhookEvent({ event_id }));
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
    this.webhookEventSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
    this.webhookEventError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
  }

  toast(params) {
    //
  }
}
