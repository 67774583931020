import { Directive, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ICoreCompany,
  ICoreCompanyAddressParamsUpdate,
  ICoreCompanyParamsUpdate,
  ICoreUser,
  ICoreUserAccount,
  ICoreUserAddressParamsCreate,
  ICoreUserAddresses,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, firstValueFrom, takeUntil } from 'rxjs';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';
import { updateCompany } from '../+state';
import { updateCompanyAddress } from '../+state/actions/address/company-address.actions';
import { updateCompanyAccountOwner } from '../+state/actions/account-owner/account-owner.actions';

@Directive()
export abstract class CompanyOverviewBaseComponent extends CompanyStateBaseComponent implements OnInit {
  // states = STATES_ARRAY;
  company: ICoreCompany;

  constructor(public _Store: Store, public fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.success();
    this.errors();
  }

  initForm(company) {
    this.company = company;
  }

  success() {
    //
  }

  errors() {
    //
  }

  async updateAddress(event: Omit<ICoreUserAddresses, '_id'>) {
    try {
      const company = await firstValueFrom(this.company$);
      const data: ICoreCompanyAddressParamsUpdate = {
        _id: company._id,
        address: event,
      };
      this._Store.dispatch(updateCompanyAddress({ data }));
    } catch (error) {
      console.error(error);
    }
  }

  async changeAccountOwner(event: ICoreUser) {
    try {
      const company = await firstValueFrom(this.company$);
      this._Store.dispatch(updateCompanyAccountOwner({ data: { _id: company._id, account_owner: event._id } }));
    } catch (error) {
      console.error(error);
    }
  }

  // create and update methods will upate the form, then call the submit method

  submit() {
    if (this.form.invalid) {
      return 'invalid';
    }
    const data: ICoreCompanyParamsUpdate = {
      account_id: this.form.value.account_id,
      company_name: this.form.value.company_name,
      phone: this.form.value.phone,
      account_type: this.form.value.account_type,
      email: this.form.value.email,
    };
    const valid_type = Object.values(['default']).includes(data.account_type);
    if (!valid_type) {
      this.form.controls.account_type.setErrors({ invalid: 'Invalid Account type' });
    }
    this._Store.dispatch(updateCompany({ data }));
  }
}
