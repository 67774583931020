// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourcesComponent } from './resources.component';
import { EStadnickDevPages } from '../../config/pages';
import { ResourcesLessonsComponent } from './components/lessons/lessons.component';
import { ResourcesCommunityComponent } from './components/community/community.component';
import { ResourcesEventsComponent } from './components/events/events.component';
import { ResourcesEventDetailComponent } from './components/event-detail/event-detail.component';

const routes: Routes = [
  //
  { path: '', component: ResourcesComponent, title: 'Rob Stadnick | Services Provided by Rob Stadnick' },
  { path: EStadnickDevPages.LESSONS, component: ResourcesLessonsComponent, title: 'Rob Stadnick | Lessons' },
  { path: EStadnickDevPages.COMMUNITY, component: ResourcesCommunityComponent, title: 'Rob Stadnick | Community' },
  {
    path: EStadnickDevPages.EVENTS,
    component: ResourcesEventsComponent,
    title: 'Rob Stadnick | Events',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourcesRoutingModule {}
