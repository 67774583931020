import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AUTH_HEADER, AUTH_REFRESH, IAuthenticationResponse } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { UserAuthActions } from '../auth/+state';
import { AUTH_INTERCEPTOR_PROVIDER } from '../auth/interfaces/auth-storage-service.interface';
import { LaunchpointCoreClientAuthHTTPService } from '../auth/services/auth-http-v3.service';

@Injectable({ providedIn: 'root' })
export class LaunchpointCoreClientAuthInterceptor implements HttpInterceptor {
  private debug = false;

  private isRefreshing = false;
  private refreshTokenSubject: Subject<string | null> = new Subject<string | null>();
  private cancelPendingRequests$ = new Subject<void>();

  constructor(
    private _Store: Store,
    private _LaunchpointCoreClientAuthHTTPService: LaunchpointCoreClientAuthHTTPService,
    @Optional() @Inject(AUTH_INTERCEPTOR_PROVIDER) private config?: { debug: boolean }
  ) {
    this.debug = this.config?.debug || false;
  }

  get tokens(): IAuthenticationResponse {
    console.log('OVERRIDE ME: Getting for the tokens in the local store');
    return;
  }

  setTokens(tokens) {
    console.log('OVERRIDE ME: Setting the tokens in the local store', tokens);
  }

  removeTokens() {
    console.log('OVERRIDE ME: Removing the tokens from the local store');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.log('Intercepting request:', req.url);

    const headers = req.headers
      .set(AUTH_HEADER, `Bearer ${this.tokens?.accessToken || ''}`)
      .set(AUTH_REFRESH, `Bearer ${this.tokens?.refreshToken || ''}`);

    this.log('Request headers set');

    const authReq = req.clone({ headers });

    return next.handle(authReq).pipe(
      takeUntil(this.cancelPendingRequests$), // Cancel requests if the token refresh fails
      catchError((error) => this.handleAuthError(error, req, next))
    );
  }

  private handleAuthError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.log('HTTP Error:', err.status, err.message);

    if (err.status === 401) {
      this.log('JWT expired, attempting token refresh...');

      if (!this.isRefreshing) {
        this.log('No refresh in progress. Initiating token refresh.');

        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);

        return this._LaunchpointCoreClientAuthHTTPService.refreshUserToken().pipe(
          tap(() => this.log('Token refresh initiated...')),
          switchMap((refresh: IAuthenticationResponse) => {
            this.log('Token refresh successful');

            this.isRefreshing = false;
            this.setTokens(refresh);
            this.refreshTokenSubject.next(refresh.accessToken);

            this._Store.dispatch(UserAuthActions.getUserNewUserFromRefreshTokenSuccess({ auth: refresh }));

            const headers = req.headers
              .set(AUTH_HEADER, `Bearer ${refresh.accessToken || ''}`)
              .set(AUTH_REFRESH, `Bearer ${refresh.refreshToken || ''}`);

            this.log('Updated headers with new tokens');

            const authReq = req.clone({ headers });
            return next.handle(authReq);
          }),
          catchError((error) => {
            console.error('Token refresh failed:', error);
            this.isRefreshing = false;

            this.cancelPendingRequests$.next(); // Cancel pending requests

            this.removeTokens();
            this._Store.dispatch(UserAuthActions.logout({ path: ['auth', 'login'] }));
            return throwError(() => error);
          })
        );
      } else {
        this.log('Refresh already in progress. Waiting for new token...');

        return this.refreshTokenSubject.pipe(
          filter((token) => token != null),
          take(1),
          timeout(3500), // Optional: Cancel if no token within 5 seconds
          switchMap((newToken) => {
            this.log('New token received, retrying original request.');

            const headers = req.headers.set(AUTH_HEADER, `Bearer ${newToken}`);
            const authReq = req.clone({ headers });

            return next.handle(authReq);
          }),
          catchError((error) => {
            this.log('Token refresh timed out or failed; canceling request');
            this.cancelPendingRequests$.next();
            return throwError(() => error);
          })
        );
      }
    }

    return throwError(() => err);
  }

  private log(...args: any[]) {
    if (this.debug) {
      console.log(...args);
    }
  }
}
