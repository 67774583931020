import { ElementRef, Injectable } from '@angular/core';
import { animate_css } from '../interfaces/animate-css.type';

@Injectable()
export class AnimateCssService {
  // constructor() {}

  addAnimation(element: ElementRef, animation_suffix: animate_css | animate_css[]) {
    const animation = `animate__` + animation_suffix;
    element.nativeElement.classList.add(`animate__animated`, animation);
    // element.classList.add('animate__animated', 'animate__bounceOutLeft');
    element.nativeElement.addEventListener('animationend', () => {
      this.removeAnimation(element, animation);
    });
  }

  removeAnimation(element: ElementRef, animation) {
    // console.log('handleAnimationEnd(event)');
    element.nativeElement.classList.remove('animate__animated', animation);
  }
}
