import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { getCompanyById, getCompanyByIdFailure, getCompanyByIdSetId } from '../actions/company.actions';
import { getAllLaunchpointCompanyState } from '../selectors/company.selectors';

@Injectable()
export class GetSelectedCompanyLoadedGuard implements CanActivate {
  constructor(
    private _Store: Store,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._Store.select(getAllLaunchpointCompanyState).pipe(
      map((state) => {
        if (state?.entities) {
          return Object.keys(state.entities);
        } else return [];
      }),
      switchMap((entityIds: string[]) => {
        if (entityIds.includes(route.params.company_id)) {
          this._Store.dispatch(getCompanyByIdSetId({ company_id: route.params.company_id }));

          return of(true);
        } else {
          this._Store.dispatch(getCompanyById({ company_id: route.params.company_id }));
          return of(true);
        }
      }),

      switchMap(() => of(true)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          getCompanyByIdFailure({
            company_id: route.params.company_id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }
}
