import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { CoreActivityHistoryWebhookEventHTTPService } from '../../services/webhook-event-http.service';
import * as WebhookEventActions from '../actions/webhook-events.actions';
import * as WebhooksSelectors from '../selectors/webhooks.selectors';

@Injectable()
export class WebhookEventsEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _CoreActivityHistoryEventHTTPService: CoreActivityHistoryWebhookEventHTTPService
  ) {}

  getAllWebhookEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        WebhookEventActions.searchAllWebhookEvents,
        WebhookEventActions.updateAllWebhookEventsPagination,
        WebhookEventActions.updateAllWebhookEventsQuery,
        WebhookEventActions.updateAllWebhookEventsSorting
      ),
      withLatestFrom(this._Store.select(WebhooksSelectors.getAllWebhookEventsState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._CoreActivityHistoryEventHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((data) => {
            return WebhookEventActions.searchAllWebhookEventsSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(WebhookEventActions.searchAllWebhookEventsFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(WebhookEventActions.searchAllWebhookEventsFailure(error)))
    );
  });

  getEventById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookEventActions.getWebhookEventById),
      switchMap((action) => {
        return this._CoreActivityHistoryEventHTTPService.getById(action.event_id).pipe(
          map((data) => {
            return WebhookEventActions.getWebhookEventByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(WebhookEventActions.getWebhookEventByIdFailure({ event_id: action.event_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(WebhookEventActions.getWebhookEventByIdFailure(error)))
    );
  });

  deleteWebhook$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(WebhookEventActions.deleteWebhookEvent),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreActivityHistoryEventHTTPService.delete(action.event_id).pipe(
          map((data) => {
            return WebhookEventActions.deleteWebhookEventSuccess({
              data,
              success_message: 'Webhook Event Deleted Successfully',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(WebhookEventActions.deleteWebhookEventFailure({ event_id: action.event_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(WebhookEventActions.deleteWebhookEventFailure(error)))
    );
  });
}
