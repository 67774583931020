import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LaunchpointMediaService } from '../../services/media.service';
import { LaunchpointMediaSubtitlesService } from '../../services/media-subtitles.service';
import { LaunchpointMediaAudiotracksService } from '../../services/media-audiotracks.service';
import { LaunchpointMediaTagsService } from '../../services/media-tags.service';
import { LaunchpointMediaDownloadService } from '../../services/media-download.service';
import * as CoreMediaActions from '../actions/media.actions';
import { getAllMediaState } from '../selectors/media.selectors';
import { RouterActions } from '../../../../router/+state';

@Injectable()
export class LaunchpointCoreMediaEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _LaunchpointMediaService: LaunchpointMediaService,
    private _SubtitleService: LaunchpointMediaSubtitlesService,
    private _AudioService: LaunchpointMediaAudiotracksService,
    private _TagService: LaunchpointMediaTagsService,
    private _DownloadService: LaunchpointMediaDownloadService
  ) {}

  updateMediaSearch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CoreMediaActions.searchAllMedia,
        CoreMediaActions.updateMediaQuery,
        CoreMediaActions.updateMediaSorting,
        CoreMediaActions.updateMediaPagination
      ),
      withLatestFrom(this._Store.select(getAllMediaState)),
      switchMap((action) => {
        const pagination = action[1].pagination;
        const querySort = action[1].querySort;
        const query = action[1].query;
        return this._LaunchpointMediaService.search({ pagination, querySort, query }).pipe(
          map((data) => {
            return CoreMediaActions.searchAllMediaSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.searchAllMediaFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.searchAllMediaFailure(error)))
    );
  });

  getMediaById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.getMediaById),
      switchMap((action) => {
        return this._LaunchpointMediaService.getById(action.media_id).pipe(
          map((data) => {
            return CoreMediaActions.getMediaByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.getMediaByIdFailure({ media_id: action?.media_id, error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.getMediaByIdFailure(error));
      })
    );
  });

  createMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.createMedia),
      switchMap((action) => {
        return this._LaunchpointMediaService.create(action.data).pipe(
          map((data) => {
            return CoreMediaActions.createMediaSuccess({
              data,
              success_message: 'Upload Successful',
            });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.createMediaFailure({ error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.createMediaFailure(error));
      })
    );
  });

  updateMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.updateMedia),
      switchMap((action) => {
        return this._LaunchpointMediaService.update(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `${data.title} Updated Successfully`,
            });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.updateMediaFailure({ media_id: action?.data._id, error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.updateMediaFailure(error));
      })
    );
  });

  updateMediaALC$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.updateMediaALC),
      switchMap((action) => {
        return this._LaunchpointMediaService.putACL(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `${data.title} ALC Updated Successfully`,
            });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.updateMediaFailure({ media_id: action?.data._id, error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.updateMediaFailure(error));
      })
    );
  });

  updateMediaMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.updateMediaMetadata),
      switchMap((action) => {
        return this._LaunchpointMediaService.metadata(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `${data.title} Metadata Updated Successfully`,
            });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.updateMediaFailure({ media_id: action?.data._id, error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.updateMediaFailure(error));
      })
    );
  });

  deleteMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.deleteMedia),
      switchMap((action) => {
        return this._LaunchpointMediaService.delete(action.data).pipe(
          map((data) => {
            return CoreMediaActions.deleteMediaSuccess({
              data,
              success_message: `${data.title} Deleted`,
              redirectPath: action?.redirectPath,
            });
          }),
          catchError((error: IHttpRequestError) => {
            return of(CoreMediaActions.deleteMediaFailure({ media_id: action.data._id, error: error.error }));
          })
        );
      }),
      catchError((error) => {
        return of(CoreMediaActions.deleteMediaFailure(error));
      })
    );
  });

  deleteMediaRouting$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.deleteMediaSuccess),
      switchMap((data) => {
        if (data?.redirectPath) {
          return of(RouterActions.Go({ payload: { path: data.redirectPath } }));
        }
        return of();
      }),
      catchError((error) => of(CoreMediaActions.deleteMediaSuccess(error)))
    );
  });

  getSignedUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.getSignedUrl),
      switchMap((action) => {
        return this._DownloadService.getSignedURL(action.data).pipe(
          map((data) => {
            return CoreMediaActions.getSignedUrlSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.getSignedUrlFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.getSignedUrlFailure(error)))
    );
  });

  getSignedUrlSuccess$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.getSignedUrlSuccess),
      switchMap((data) => {
        if (data?.data?.url) {
          const link = document.createElement('a');
          link.href = data.data.url;
          link.download = data?.data?.media?.title;
          link.click();
        }

        return of();
      }),
      catchError((error) => of(CoreMediaActions.getSignedUrlFailure(error)))
    );
  });

  // Tags -----------------------------
  setAdminMediaTagDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.setMediaTag),
      switchMap((action) => {
        return this._TagService.set(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: 'Media Tags Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  createAdminMediaTagDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.createMediaTag),
      switchMap((action) => {
        return this._TagService.add(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `${action.data.tag} Added to Media`,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  // Delete - for search ahead
  deleteAdminMediaTagDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.deleteMediaTag),
      switchMap((action) => {
        return this._TagService.remove(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              success_message: `${action.data.tag} Removed`,
              data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  // Subtitles ---------------------------
  createAdminMediaSubtitleDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.createMediaSubtitle),
      switchMap((action) => {
        return this._SubtitleService.add(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `Subtitle Added`,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  deleteAdminMediaSubtitleDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.removeMediaSubtitle),
      switchMap((action) => {
        return this._SubtitleService.remove(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `Subtitle Removed`,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  // Audio ---------------------------
  createAdminMediaAudioDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.createMediaAudio),
      switchMap((action) => {
        return this._AudioService.add(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              data,
              success_message: `Audio Track Added`,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });

  deleteAdminMediaAudioDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreMediaActions.removeMediaAudio),
      switchMap((action) => {
        return this._AudioService.remove(action.data).pipe(
          map((data) => {
            return CoreMediaActions.updateMediaSuccess({
              success_message: `Audio Track Removed`,
              data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CoreMediaActions.updateMediaFailure({ media_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(CoreMediaActions.updateMediaFailure(error)))
    );
  });
}
