import { Component, HostBinding, inject, Inject } from '@angular/core';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import {
  APP_AUTH_CONFIG_TOKEN,
  ICoreAuthConfig,
  LaunchpointCoreClientAuthFormService,
  LaunchpointCoreClientAuthRegisterBaseComponent,
} from '@launchpoint/core-client';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'launchpoint-core-web-auth-v2-registration',
  templateUrl: './auth-v2-registration.component.html',
  styleUrls: ['./auth-v2-registration.component.scss'],
})
export class LaunchpointCoreWebAuthV2RegistrationComponent extends LaunchpointCoreClientAuthRegisterBaseComponent {
  _swalService = inject(LaunchpointSweetAlertService);

  constructor(
    @Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig,
    public _LaunchpointCoreClientAuthFormService: LaunchpointCoreClientAuthFormService,
    public _route: ActivatedRoute
  ) {
    super(_config, _LaunchpointCoreClientAuthFormService, _route);
  }

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }

  override toast(params) {
    this._swalService.fireToast(params);
  }
}
