import { Observable } from 'rxjs';
import { ICoreBlogTagParams } from './blog-tags.interface';
import { ICoreBlog } from '../blog.interface';
import { ICoreUserJWTDecorator } from '../../auth';

/**
 * @Controller('blog-tags')
 */
export interface ICoreBlogTagsController {
  /**
   * @Post('add')
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_TAG_ADD, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_TAG_ADD, LAUNCHPOINT_BLOG_SCHEMA)
   */
  add(params: ICoreBlogTagParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('remove')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_TAG_REMOVE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_TAG_REMOVE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  remove(params: ICoreBlogTagParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('set')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_TAG_SET, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_TAG_SET, LAUNCHPOINT_BLOG_SCHEMA)
   */
  set(params: ICoreBlogTagParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Get()
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_READ, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_READ, LAUNCHPOINT_BLOG_SCHEMA)
   */
  distinct(): Promise<string[]> | Observable<string[]>;
}
