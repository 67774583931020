<div class="d-flex flex-column bg-primary rounded-top">

    <h3 class="text-white fw-bold m-8">
        Notifications <span class="fs-8 opacity-75 ps-3">{{ totalAlerts$() }} unread</span>
    </h3>
</div>

<div class="tab-content">

    <div class="tab-pane fade" id="kt_topbar_notifications_2" role="tabpanel"
        [ngClass]="activeTabId === 'kt_topbar_notifications_2' ? 'show active' : ''">

        <div class="scroll-y mh-500px my-5 px-8">



            <launchpoint-notifications-user-notifications-standalone [query]="{ is_read: false }"
                (total)="setTotal($event)" />
        </div>
    </div>

</div>