import { Component, inject, Input, OnInit } from '@angular/core';
import {
  LaunchpointCoreClientNotificationsUserNotificationBaseComponent,
  UserNotificationsHTTPService,
  UserSelectors,
} from '@launchpoint/core-client';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'launchpoint-notifications-user-notifications',
  templateUrl: './notifications-user-notifications.component.html',
  styleUrls: ['./notifications-user-notifications.component.scss'],
})
export class LaunchpointCoreWebNotificationUserNotificationsComponent
  extends LaunchpointCoreClientNotificationsUserNotificationBaseComponent
  implements OnInit
{
  _UserNotificationsHTTPService = inject(UserNotificationsHTTPService);
  @Input() showUser = true;

  ngOnInit() {
    super.ngOnInit();

    this.getUnread();
  }

  async getUnread() {
    const user = await firstValueFrom(this._Store.select(UserSelectors.selectUser));
    if (!user) {
      return;
    }

    const userNotifications = await firstValueFrom(
      this._UserNotificationsHTTPService.search({
        query: { users: [user._id] },
        pagination: { skip: 0, limit: 10 },
        querySort: { createdAt: -1 },
      })
    );

    if (userNotifications.data.some((notification) => !notification.is_read)) {
      const blinking_alert = document.createElement('span');
      document.getElementById('notifications-bell-alert').appendChild(blinking_alert);
      blinking_alert.className = 'bullet bullet-dot bg-info h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink';
    }
  }
}
