import { ICoreUserJWTFlat } from './auth/auth.user.interface';
import { DeepFlatten } from './deep-flatten';
import { RequireAtLeastOne } from './require-one';

export type ILaunchpointUserAccountIds = RequireAtLeastOne<{ user_id?: string; account_id?: string }>;

/**
 * Defines the mode to be used for checking access permissions.
 * 'user' mode checks user security roles for access to an object,
 * while 'account' mode checks the header for an account_id and uses the user.accounts.security_roles for access.
 */
export type IUserSecurityMode = 'user' | 'account';

/**
 * Defines the effect of a security rule, which is either 'can' or 'cannot'.
 */
export type ISecurityConfigEffect = 'can' | 'cannot';

/**
 * Defines the security configuration object.
 *
 * Type `T` is the type of the `jwt object` that is being used to **determine access**.
 *
 * Type `P` is the type of the `object` that is being **checked for access**. Defaults to `undefined` which will result in any `string` being the key.
 */
export interface ISecurityConfig<T = ICoreUserJWTFlat, P = undefined> {
  /**
   * The security mode to be used for checking access permissions.
   * @deprecated
   */
  mode?: IUserSecurityMode;
  /**
   * A flag indicating whether to enable debug logging.
   */
  debug: boolean;
  /**
   * The access control list (ACL) of security roles and their permissions.
   */
  acl: {
    /**
     * The name of the role.
     */
    [role: string]: ISecurityConfigACLRole<T, P>[];
  };
}

/**
 * Flattens the JWT object to a single level with dot notation and no indices for arrays.
 *
 * Also allows for any string value for `enums` or `constants` that are used in the conditions.
 */
type FlattenJWT<T = ICoreUserJWTFlat> = `$\{${string & keyof DeepFlatten<T>}}` | Omit<string | boolean, `$\{${string & keyof DeepFlatten<T>}}`>;
/**
 * Type `T` is the type of the jwt object that is being checked for access.
 *
 * returns types for the conditions with string or mongo query selector
 */
type Condition<T = ICoreUserJWTFlat> = FlattenJWT<T> | ILaunchpointQuerySelector<FlattenJWT<T>>;

/**
 * Type `T` is the type of the `jwt object` that is being used to **determine access**.
 *
 * Type `P` is the type of the `object` that is being **checked for access**. Defaults to `undefined` which will result in any `string` being the key.
 */
export type ISecurityConfigACLRoleConditions<T = ICoreUserJWTFlat, P = undefined> = {
  [K in keyof DeepFlatten<P>]?: Condition<T> | Condition<T>[];
};

/**
 * Defines the access control list (ACL) for a security role.
 *
 * Type `T` is the type of the `jwt object` that is being used to **determine access**.
 *
 * Type `P` is the type of the `object` that is being **checked for access**. Defaults to `undefined` which will result in any `string` being the key.
 */
export interface ISecurityConfigACLRole<T = ICoreUserJWTFlat, P = undefined> {
  /**
   * The effect of the rule, which is either 'can' or 'cannot'.
   */
  effect: ISecurityConfigEffect;
  /**
   * The permissions associated with the rule.
   */
  permissions: {
    /**
     * The actions associated with the permission.
     * Use 'manage' to include all actions, or specify a single action or array of actions.
     */
    actions: string | string[] | 'manage';
    /**
     * The name of the schema associated with the permission, as defined in the module imports.
     * Should be the const string "..._SCHEMA" as a string.
     */
    subject?: string;
  }[];
  /**
   * The conditions associated with the permission.
   */
  conditions?: ISecurityConfigACLRoleConditions<T, P>[];
  /**
   * BETA: WIP
   * Fields or field user has access to.
   *
   */
  fields?: string | string[];
  /**
   * Work in progress to let the service know to pull a cached object in order to check the conditions.
   */
  cache_objects?: any[];
}

export interface ISecurityConfigDynamic<T> {
  /**
   * The security mode to be used for checking access permissions.
   */
  mode: IUserSecurityMode;
  /**
   * A flag indicating whether to enable debug logging.
   */
  debug: boolean;
  /**
   * The access control list (ACL) of security roles and their permissions.
   */
  acl: {
    /**
     * The name of the role.
     */
    [role: string]: ISecurityConfigACLDynamic<T>[];
  };
}
export type WrappedDynamicValues<T> = {
  [K in keyof T]: `$\{${string & K}}`;
};

export type ConditionsWithWrappedValues<T> = { [key: string]: WrappedDynamicValues<T> }[];

/**
 * Defines the access control list (ACL) for a security role.
 */
export interface ISecurityConfigACLDynamic<T> {
  /**
   * The effect of the rule, which is either 'can' or 'cannot'.
   */
  effect: ISecurityConfigEffect;
  /**
   * The permissions associated with the rule.
   */
  permissions: {
    /**
     * The actions associated with the permission.
     * Use 'manage' to include all actions, or specify a single action or array of actions.
     */
    actions: string | string[] | 'manage';
    /**
     * The name of the schema associated with the permission, as defined in the module imports.
     * Should be the const string "..._SCHEMA" as a string.
     */
    subject: string;
  }[];
  /**
   * The conditions associated with the permission.
   */
  conditions?: ConditionsWithWrappedValues<T>[];
  /**
   * BETA: WIP
   * Fields or field user has access to.
   *
   */
  fields?: string | string[];
  /**
   * Work in progress to let the service know to pull a cached object in order to check the conditions.
   */
  cache_objects?: any[];
}

/**
 * Type `T` must be the deep flattened type of the object that is being checked for access.
 * @default FlattenJWT<ICoreUserJWTFlat>
 */
type ILaunchpointQuerySelector<T = FlattenJWT<ICoreUserJWTFlat>> = {
  // Comparison
  $eq?: T | string;
  $gt?: T | Date;
  $gte?: T | Date;
  $in?: T[] | string[];
  $lt?: T | Date;
  $lte?: T | Date;
  $ne?: T | string;
  $nin?: T[] | string[];
  // Logical
  $not?: ILaunchpointQuerySelector<T>;
  // Element
  $and?: Array<ILaunchpointQuerySelector<T>>;
  $nor?: Array<ILaunchpointQuerySelector<T>>;
  $or?: Array<ILaunchpointQuerySelector<T>>;
  /**
   * When `true`, `$exists` matches the documents that contain the field,
   * including documents where the field value is null.
   */
  $exists?: boolean;
};
