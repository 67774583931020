import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LaunchpointCoreClientBaseComponent } from '../../components/base/base.component';

@Directive()
export abstract class LaunchpointFormControlCheckBoxesBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() cssClass = 'form-check form-check-inline';
  @Input() cssClassInput = 'form-check form-check-inline';
  @Input() cssClassLabel = 'form-check-label my-1';

  /**
   * Form field disabled boolean
   */
  @Input() disabled: boolean;

  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChanged: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
