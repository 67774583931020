import {
  ICoreActivityHistoryWebhook,
  ICoreActivityHistoryWebhookParamsCreate,
  ICoreActivityHistoryWebhookParamsUpdate,
  ICoreActivityHistoryWebhookSearchQuery,
  ICoreActivityHistoryWebhookSearchResults,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllWebhooks = createAction('[Webhooks] searchAllWebhooks');
export const searchAllWebhooksSuccess = createAction(
  '[Webhooks] searchAllWebhooksSuccess',
  props<{ data: ICoreActivityHistoryWebhookSearchResults; success_message?: string }>()
);
export const searchAllWebhooksFailure = createAction('[Webhooks] searchAllWebhooksFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllWebhooksPagination = createAction('[Webhooks] updateAllWebhooksPagination', props<{ pagination: IQueryPageination }>());
export const updateAllWebhooksPaginationScroll = createAction(
  '[Webhooks] updateAllWebhooksPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllWebhooksSorting = createAction('[Webhooks] updateAllWebhooksSorting', props<{ querySort: IQuerySort }>());
export const updateAllWebhooksQuery = createAction('[Webhooks] updateAllWebhooksQuery', props<{ query: ICoreActivityHistoryWebhookSearchQuery }>());
// getById
export const getWebhookById = createAction('[Webhooks] getWebhookById', props<{ webhook_id: string }>());
export const getWebhookByIdSuccess = createAction(
  '[Webhooks] getWebhookByIdSuccess',
  props<{ data: ICoreActivityHistoryWebhook; success_message?: string }>()
);
export const getWebhookByIdFailure = createAction('[Webhooks] getWebhookByIdFailure', props<{ webhook_id: string; error: IHttpException }>());

// create
export const createWebhook = createAction('[Webhooks] createWebhook', props<{ data: ICoreActivityHistoryWebhookParamsCreate }>());
export const createWebhookSuccess = createAction(
  '[Webhooks] createWebhookSuccess',
  props<{ data: ICoreActivityHistoryWebhook; success_message?: string }>()
);
export const createWebhookFailure = createAction('[Webhooks] createWebhookFailure', props<{ webhook_id?: string; error: IHttpException }>());

// update
export const updateWebhook = createAction('[Webhooks] updateWebhook', props<{ data: ICoreActivityHistoryWebhookParamsUpdate }>());
export const updateWebhookSuccess = createAction(
  '[Webhooks] updateWebhookSuccess',
  props<{ data: ICoreActivityHistoryWebhook; success_message?: string }>()
);
export const updateWebhookFailure = createAction('[Webhooks] updateWebhookFailure', props<{ webhook_id: string; error: IHttpException }>());

// Delete Webhooks
export const deleteWebhook = createAction('[Webhooks] deleteWebhook', props<{ webhook_id: string; redirectPath?: string[] }>());
export const deleteWebhookSuccess = createAction(
  '[Webhooks] deleteWebhookSuccess',
  props<{ data: ICoreActivityHistoryWebhook; success_message?: string; redirectPath?: string[] }>()
);
export const deleteWebhookFailure = createAction('[Webhooks] deleteWebhookFailure', props<{ webhook_id: string; error: IHttpException }>());
// Close Alerts - //TODO: is this needed anymore?
export const closeWebhooksSuccessMessage = createAction('[Webhooks] closeWebhooksSuccessMessage');
export const closeWebhooksErrorMessage = createAction('[Webhooks] closeWebhooksErrorMessage');
