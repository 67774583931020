import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-feeds-widget5',
  templateUrl: './feeds-widget5.component.html',
})
export class FeedsWidget5Component {
  // constructor() {}
  // ngOnInit(): void {}
}
