import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ALERT_BANNER_CLIENT_CONFIG_TOKEN, APP_AUTH_CONFIG_TOKEN, AUTH_CORE_SERVICES, AUTH_GUARDS, ICoreAuthConfig } from '@launchpoint/core-client';
import { NgbModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AnimateCSSModule } from '../../../vendors/animate-css/animate-css.module';
import { LaunchpointCoreWebAlertBannerMessageStandaloneComponent } from '../../alert-banner/alert-banner-message-standalone/alert-banner-message-standalone.component';
import { AuthRoutingV1Module } from './auth-v1-routing.module';
import { LaunchpointCoreWebAuthV1Component } from './components/base/auth-v1.component';
import { LaunchpointCoreWebAuthV1LoginComponent } from './components/login/auth-v1-login.component';
import { LaunchpointCoreWebAuthV1LogoutComponent } from './components/logout/auth-v1-logout.component';
import { LaunchpointCoreWebAuthV1RegistrationComponent } from './components/registration/auth-v1-registration.component';
import { LaunchpointCoreWebAuthV1SetPasswordComponent } from './components/set-password/auth-v1-set-password.component';
import { LaunchpointCoreWebAuthV1VerifyComponent } from './components/verify/auth-v1-verify.component';

export const WEB_AUTH_COMPONENTS_V1 = [
  LaunchpointCoreWebAuthV1Component,
  LaunchpointCoreWebAuthV1LoginComponent,
  LaunchpointCoreWebAuthV1LogoutComponent,
  LaunchpointCoreWebAuthV1RegistrationComponent,
  LaunchpointCoreWebAuthV1VerifyComponent,
  LaunchpointCoreWebAuthV1SetPasswordComponent,
];

@NgModule({
  declarations: [...WEB_AUTH_COMPONENTS_V1],
  exports: [...WEB_AUTH_COMPONENTS_V1],
  imports: [
    CommonModule,
    AnimateCSSModule,
    AuthRoutingV1Module,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgbModule,
    NgbProgressbarModule,
    LaunchpointCoreWebAlertBannerMessageStandaloneComponent,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class LaunchpointCoreWebAuthV1Module {
  static forRoot(_authConfig: ICoreAuthConfig): ModuleWithProviders<LaunchpointCoreWebAuthV1Module> {
    return {
      ngModule: LaunchpointCoreWebAuthV1Module,
      providers: [
        {
          provide: APP_AUTH_CONFIG_TOKEN,
          useValue: _authConfig,
        },
        {
          provide: ALERT_BANNER_CLIENT_CONFIG_TOKEN,
          useValue: { base_url: _authConfig.auth.base_url },
        },
        ...AUTH_GUARDS,
        ...AUTH_CORE_SERVICES,
      ],
    };
  }
}
