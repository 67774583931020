import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICoreBlog, ICoreBlogSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { LaunchpointBlogHTTPService } from '../services/blog-http.service';

@Directive()
export abstract class LaunchpointCoreClientBlogsSearchAheadBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;

  @Input() query: ICoreBlogSearchQuery;
  @Input() pagination: IQueryPageination = { limit: 15, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  @Output() selected = new EventEmitter<ICoreBlog>();

  constructor(public _LaunchpointBlogHTTPService: LaunchpointBlogHTTPService) {
    super();
  }

  ngOnInit(): void {
    this.runQuery('');
  }

  formatter = (result: ICoreBlog): string => `${result.title}`;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly ICoreBlog[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._LaunchpointBlogHTTPService.search({ pagination: this.pagination, querySort: this.querySort, query: { ...this.query, search } }).pipe(
      map((data) => {
        this.loading = false;
        return data.data;
      })
    );
  }

  selectData(data: { item: ICoreBlog }) {
    return this.selected.emit(data.item);
  }
}
