import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TopicFeatureKey, TopicEntityAdaptor, TopicState } from '../reducers/topics.reducer';

const selectAllTopicsState = createFeatureSelector<TopicState>(TopicFeatureKey);

/**
 * ROOT STATE
 */
export const getAllTopicsState = createSelector(selectAllTopicsState, (state: TopicState) => state);

export const selectTopicsPagination = createSelector(getAllTopicsState, (state: TopicState) => state.pagination);
export const selectTopicsQuerySort = createSelector(getAllTopicsState, (state: TopicState) => state.querySort);
export const selectTopicsQuery = createSelector(getAllTopicsState, (state: TopicState) => state.query);
export const selectTopicsSuccess = createSelector(getAllTopicsState, (state: TopicState) => state.success_message);
export const selectTopicsLoading = createSelector(getAllTopicsState, (state: TopicState) => state.loading);
export const selectTopicsLoaded = createSelector(getAllTopicsState, (state: TopicState) => state.loaded);
export const selectTopicsError = createSelector(getAllTopicsState, (state: TopicState) => state.error);

export const getAllTopicssStateEntities = createSelector(selectAllTopicsState, (state: TopicState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = TopicEntityAdaptor.getSelectors(selectAllTopicsState);

// select the array of Topics ids
export const selectTopicsIds = selectIds;

// select the dictionary of Topics entities
export const selectTopicsEntities = selectEntities;

// select the array of Topicss
export const selectTopics = selectAll;

// select the total entity count
export const selectTopicsTotal = selectTotal;

export const selectTopicsId = createSelector(getAllTopicsState, (state: TopicState) => state?.selected_topic_id);

export const selectSelectedTopic = createSelector(
  getAllTopicsState,
  selectTopicsId,
  (state: TopicState, selected_topic_id) => state.entities[selected_topic_id]
);

export const selectSelectedTopicLoading = createSelector(
  getAllTopicsState,
  selectTopicsId,
  (state: TopicState, selected_topic_id) => state.entities[selected_topic_id].loading
);

export const selectSelectedTopicError = createSelector(
  getAllTopicsState,
  selectTopicsId,
  (state: TopicState, selected_topic_id) => state.entities[selected_topic_id].error
);

export const selectSelectedTopicSuccess = createSelector(
  getAllTopicsState,
  selectTopicsId,
  (state: TopicState, selected_topic_id) => state.entities[selected_topic_id].success_message
);
