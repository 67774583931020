import { IsString } from 'class-validator';
import 'reflect-metadata';

export class ICoreUserAccountsSecurityRolesParamsUpdate {
  /**
   * _id of the user who has the accounts
   */
  @IsString()
  user_id: string;

  /**
   * user.accounts[index]._id
   * _id of the account to add roles to
   */
  @IsString()
  account_id: string;

  /**
   * List of roles to add OR remove
   */
  security_roles: string[];
}
