import { LaunchpointCoreClientAuthFormService } from './auth-form-service';
import { LaunchpointCoreClientAuthHTTPService } from './auth-http-v3.service';
import { LaunchpointCoreClientAccountsHTTPService } from './core-accounts-http.service';

export const AUTH_CORE_SERVICES = [
  LaunchpointCoreClientAuthHTTPService,
  LaunchpointCoreClientAuthFormService,
  LaunchpointCoreClientAccountsHTTPService,
];

export * from './auth-form-service';
export * from './auth-http-v3.service';
export * from './core-accounts-http.service';
