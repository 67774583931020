import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-purchase-toolbar',
  templateUrl: './purchase-toolbar.component.html',
})
export class PurchaseToolbarComponent {
  @Input() appPurchaseUrl = '';

  // constructor() {}
  // ngOnInit(): void {}
}
