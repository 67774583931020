import { Component, ElementRef, HostBinding, inject, ViewChild } from '@angular/core';
import { LaunchpointCoreClientAuthLoginBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-auth-v2-login',
  templateUrl: './auth-v2-login.component.html',
  styleUrls: ['./auth-v2-login.component.scss'],
})
export class LaunchpointCoreWebAuthV2LoginComponent extends LaunchpointCoreClientAuthLoginBaseComponent {
  @ViewChild('password', { static: false }) password: ElementRef;
  _swalService = inject(LaunchpointSweetAlertService);

  pVisible = false;

  togglePass() {
    this.pVisible = !this.pVisible;
  }

  confirm() {
    if (!this._config.registration.require_password) {
      this.sendLoginCode();
      return;
    }

    this.confirmEmail = true;
    if (this.confirmEmail) {
      setTimeout(() => {
        this.password?.nativeElement?.focus();
      }, 50);
    }
  }

  override toast(params) {
    this._swalService.fireToast(params);
  }

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }
}
