import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsCountriesService {
  private Countries = [
    { name: 'Afghanistan', alpha_2: 'AF', alpha_3: 'AFG' },
    { name: 'Albania', alpha_2: 'AL', alpha_3: 'ALB' },
    { name: 'Algeria', alpha_2: 'DZ', alpha_3: 'DZA' },
    { name: 'Andorra', alpha_2: 'AD', alpha_3: 'AND' },
    { name: 'Angola', alpha_2: 'AO', alpha_3: 'AGO' },
    { name: 'Antigua and Barbuda', alpha_2: 'AG', alpha_3: 'ATG' },
    { name: 'Argentina', alpha_2: 'AR', alpha_3: 'ARG' },
    { name: 'Armenia', alpha_2: 'AM', alpha_3: 'ARM' },
    { name: 'Australia', alpha_2: 'AU', alpha_3: 'AUS' },
    { name: 'Austria', alpha_2: 'AT', alpha_3: 'AUT' },
    { name: 'Azerbaijan', alpha_2: 'AZ', alpha_3: 'AZE' },
    // * START: B
    { name: 'Bahamas', alpha_2: 'BS', alpha_3: 'BHS' },
    { name: 'Bahrain', alpha_2: 'BH', alpha_3: 'BHR' },
    { name: 'Bangladesh', alpha_2: 'BD', alpha_3: 'BGD' },
    { name: 'Barbados', alpha_2: 'BB', alpha_3: 'BRB' },
    { name: 'Belarus', alpha_2: 'BY', alpha_3: 'BLR' },
    { name: 'Belgium', alpha_2: 'BE', alpha_3: 'BEL' },
    { name: 'Belize', alpha_2: 'BZ', alpha_3: 'BLZ' },
    { name: 'Benin', alpha_2: 'BJ', alpha_3: 'BEN' },
    { name: 'Bhutan', alpha_2: 'BT', alpha_3: 'BTN' },
    { name: 'Bolivia', alpha_2: 'BO', alpha_3: 'BOL' },
    { name: 'Bosnia and Herzegovina', alpha_2: 'BA', alpha_3: 'BIH' },
    { name: 'Botswana', alpha_2: 'BW', alpha_3: 'BWA' },
    { name: 'Brazil', alpha_2: 'BR', alpha_3: 'BRA' },
    { name: 'Brunei Darussalam', alpha_2: 'BN', alpha_3: 'BRN' },
    { name: 'Bulgaria', alpha_2: 'BG', alpha_3: 'BGR' },
    { name: 'Burkina Faso', alpha_2: 'BF', alpha_3: 'BFA' },
    { name: 'Burundi', alpha_2: 'BI', alpha_3: 'BDI' },
    // * START: C
    { name: 'Cambodia', alpha_2: 'KH', alpha_3: 'KHM' },
    { name: 'Cameroon', alpha_2: 'CM', alpha_3: 'CMR' },
    { name: 'Canada', alpha_2: 'CA', alpha_3: 'CAN', numeric: '124' },
    { name: 'Cape Verde', alpha_2: 'CV', alpha_3: 'CPV', numeric: '132' },
    { name: 'Cayman Islands', alpha_2: 'KY', alpha_3: 'CYM', numeric: '136' },
    { name: 'Central African Republic', alpha_2: 'CF', alpha_3: 'CAF', numeric: '140' },
    { name: 'Chad', alpha_2: 'TD', alpha_3: 'TCD', numeric: '148' },
    { name: 'Chile', alpha_2: 'CL', alpha_3: 'CHL', numeric: '152' },
    { name: 'China', alpha_2: 'CN', alpha_3: 'CHN', numeric: '156' },
    { name: 'Christmas Island', alpha_2: 'CX', alpha_3: 'CXR', numeric: '162' },
    { name: 'Cocos (Keeling) Islands', alpha_2: 'CC', alpha_3: 'CCK', numeric: '166' },
    { name: 'Colombia', alpha_2: 'CO', alpha_3: 'COL', numeric: '170' },
    { name: 'Comoros', alpha_2: 'KM', alpha_3: 'COM', numeric: '174' },
    { name: 'Congo', alpha_2: 'CG', alpha_3: 'COG', numeric: '178' },
    { name: 'Congo, Democratic Republic of the', alpha_2: 'CD', alpha_3: 'COD', numeric: '180' },
    { name: 'Cook Islands', alpha_2: 'CK', alpha_3: 'COK', numeric: '184' },
    { name: 'Costa Rica', alpha_2: 'CR', alpha_3: 'CRI', numeric: '188' },
    { name: "Côte d'Ivoire", alpha_2: 'CI', alpha_3: 'CIV', numeric: '384' },
    { name: 'Croatia', alpha_2: 'HR', alpha_3: 'HRV', numeric: '191' },
    { name: 'Cuba', alpha_2: 'CU', alpha_3: 'CUB', numeric: '192' },
    { name: 'Curaçao', alpha_2: 'CW', alpha_3: 'CUW', numeric: '531' },
    { name: 'Cyprus', alpha_2: 'CY', alpha_3: 'CYP', numeric: '196' },
    { name: 'Czechia', alpha_2: 'CZ', alpha_3: 'CZE', numeric: '203' },
    { name: "Côte d'Ivoire", alpha_2: 'CI', alpha_3: 'CIZ', numeric: '384' },
    // * START: D
    { name: 'Denmark', alpha_2: 'DK', alpha_3: 'DNK', numeric: '208' },
    { name: 'Djibouti', alpha_2: 'DJ', alpha_3: 'DJI', numeric: '262' },
    { name: 'Dominica', alpha_2: 'DM', alpha_3: 'DMA', numeric: '212' },
    { name: 'Dominican Republic', alpha_2: 'DO', alpha_3: 'DOM', numeric: '214' },
    // * START: E
    { name: 'East Timor', alpha_2: 'TL', alpha_3: 'TLS', numeric: '626' },
    { name: 'Ecuador', alpha_2: 'EC', alpha_3: 'ECU', numeric: '218' },
    { name: 'Egypt', alpha_2: 'EG', alpha_3: 'EGY', numeric: '818' },
    { name: 'El Salvador', alpha_2: 'SV', alpha_3: 'SLV', numeric: '222' },
    { name: 'Equatorial Guinea', alpha_2: 'GQ', alpha_3: 'GNQ', numeric: '226' },
    { name: 'Eritrea', alpha_2: 'ER', alpha_3: 'ERI', numeric: '232' },
    { name: 'Estonia', alpha_2: 'EE', alpha_3: 'EST', numeric: '233' },
    { name: 'Ethiopia', alpha_2: 'ET', alpha_3: 'ETH', numeric: '231' },
    //* START: F
    { name: 'Falkland Islands (Malvinas)', alpha_2: 'FK', alpha_3: 'FLK', numeric: '238' },
    { name: 'Faroe Islands', alpha_2: 'FO', alpha_3: 'FRO', numeric: '234' },
    { name: 'Fiji', alpha_2: 'FJ', alpha_3: 'FJI', numeric: '242' },
    { name: 'Finland', alpha_2: 'FI', alpha_3: 'FIN', numeric: '246' },
    { name: 'France', alpha_2: 'FR', alpha_3: 'FRA', numeric: '250' },
    { name: 'French Guiana', alpha_2: 'GF', alpha_3: 'GUF', numeric: '254' },
    { name: 'French Polynesia', alpha_2: 'PF', alpha_3: 'PYF', numeric: '258' },
    { name: 'French Southern Territories', alpha_2: 'TF', alpha_3: 'ATF', numeric: '260' },
    //* START: G
    { name: 'Gabon', alpha_2: 'GA', alpha_3: 'GAB', numeric: '266' },
    { name: 'Gambia', alpha_2: 'GM', alpha_3: 'GMB', numeric: '270' },
    { name: 'Georgia', alpha_2: 'GE', alpha_3: 'GEO', numeric: '268' },
    { name: 'Germany', alpha_2: 'DE', alpha_3: 'DEU', numeric: '276' },
    { name: 'Ghana', alpha_2: 'GH', alpha_3: 'GHA', numeric: '288' },
    { name: 'Gibraltar', alpha_2: 'GI', alpha_3: 'GIB', numeric: '292' },
    { name: 'Greece', alpha_2: 'GR', alpha_3: 'GRC', numeric: '300' },
    { name: 'Greenland', alpha_2: 'GL', alpha_3: 'GRL', numeric: '304' },
    { name: 'Grenada', alpha_2: 'GD', alpha_3: 'GRD', numeric: '308' },
    { name: 'Guadeloupe', alpha_2: 'GP', alpha_3: 'GLP', numeric: '312' },
    { name: 'Guam', alpha_2: 'GU', alpha_3: 'GUM', numeric: '316' },
    { name: 'Guatemala', alpha_2: 'GT', alpha_3: 'GTM', numeric: '320' },
    { name: 'Guernsey', alpha_2: 'GG', alpha_3: 'GGY', numeric: '831' },
    { name: 'Guinea', alpha_2: 'GN', alpha_3: 'GIN', numeric: '324' },
    { name: 'Guinea-Bissau', alpha_2: 'GW', alpha_3: 'GNB', numeric: '624' },
    { name: 'Guyana', alpha_2: 'GY', alpha_3: 'GUY', numeric: '328' },
    //* START: H
    { name: 'Haiti', alpha_2: 'HT', alpha_3: 'HTI', numeric: '332' },
    { name: 'Heard Island and McDonald Islands', alpha_2: 'HM', alpha_3: 'HMD', numeric: '334' },
    { name: 'Holy See (Vatican City State)', alpha_2: 'VA', alpha_3: 'VAT', numeric: '336' },
    { name: 'Honduras', alpha_2: 'HN', alpha_3: 'HND', numeric: '340' },
    { name: 'Hong Kong', alpha_2: 'HK', alpha_3: 'HKG', numeric: '344' },
    { name: 'Hungary', alpha_2: 'HU', alpha_3: 'HUN', numeric: '348' },
    //* START: I
    { name: 'Iceland', alpha_2: 'IS', alpha_3: 'ISL', numeric: '352' },
    { name: 'India', alpha_2: 'IN', alpha_3: 'IND', numeric: '356' },
    { name: 'Indonesia', alpha_2: 'ID', alpha_3: 'IDN', numeric: '360' },
    { name: 'Iran, Islamic Republic of', alpha_2: 'IR', alpha_3: 'IRN', numeric: '364' },
    { name: 'Iraq', alpha_2: 'IQ', alpha_3: 'IRQ', numeric: '368' },
    { name: 'Ireland', alpha_2: 'IE', alpha_3: 'IRL', numeric: '372' },
    { name: 'Israel', alpha_2: 'IL', alpha_3: 'ISR', numeric: '376' },
    { name: 'Italy', alpha_2: 'IT', alpha_3: 'ITA', numeric: '380' },
    // START: J
    { name: 'Jamaica', alpha_2: 'JM', alpha_3: 'JAM', numeric: '388' },
    { name: 'Japan', alpha_2: 'JP', alpha_3: 'JPN', numeric: '392' },
    { name: 'Jersey', alpha_2: 'JE', alpha_3: 'JEY', numeric: '832' },
    { name: 'Jordan', alpha_2: 'JO', alpha_3: 'JOR', numeric: '400' },
    // START: K
    { name: 'Kazakhstan', alpha_2: 'KZ', alpha_3: 'KAZ', numeric: '398' },
    { name: 'Kenya', alpha_2: 'KE', alpha_3: 'KEN', numeric: '404' },
    { name: 'Kiribati', alpha_2: 'KI', alpha_3: 'KIR', numeric: '296' },
    { name: "Korea, Democratic People's Republic of", alpha_2: 'KP', alpha_3: 'PRK', numeric: '408' },
    { name: 'Korea, Republic of', alpha_2: 'KR', alpha_3: 'KOR', numeric: '410' },
    { name: 'Kuwait', alpha_2: 'KW', alpha_3: 'KWT', numeric: '414' },
    { name: 'Kyrgyzstan', alpha_2: 'KG', alpha_3: 'KGZ', numeric: '417' },
    // START: L
    { name: "Lao People's Democratic Republic", alpha_2: 'LA', alpha_3: 'LAO', numeric: '418' },
    { name: 'Latvia', alpha_2: 'LV', alpha_3: 'LVA', numeric: '428' },
    { name: 'Lebanon', alpha_2: 'LB', alpha_3: 'LBN', numeric: '422' },
    { name: 'Lesotho', alpha_2: 'LS', alpha_3: 'LSO', numeric: '426' },
    { name: 'Liberia', alpha_2: 'LR', alpha_3: 'LBR', numeric: '430' },
    { name: 'Libyan Arab Jamahiriya', alpha_2: 'LY', alpha_3: 'LBY', numeric: '434' },
    { name: 'Liechtenstein', alpha_2: 'LI', alpha_3: 'LIE', numeric: '438' },
    { name: 'Lithuania', alpha_2: 'LT', alpha_3: 'LTU', numeric: '440' },
    { name: 'Luxembourg', alpha_2: 'LU', alpha_3: 'LUX', numeric: '442' },
    // START: M
    { name: 'Macao', alpha_2: 'MO', alpha_3: 'MAC', numeric: '446' },
    { name: 'Macedonia, the former Yugoslav Republic of', alpha_2: 'MK', alpha_3: 'MKD', numeric: '807' },
    { name: 'Madagascar', alpha_2: 'MG', alpha_3: 'MDG', numeric: '450' },
    { name: 'Malawi', alpha_2: 'MW', alpha_3: 'MWI', numeric: '454' },
    { name: 'Malaysia', alpha_2: 'MY', alpha_3: 'MYS', numeric: '458' },
    { name: 'Maldives', alpha_2: 'MV', alpha_3: 'MDV', numeric: '462' },
    { name: 'Mali', alpha_2: 'ML', alpha_3: 'MLI', numeric: '466' },
    { name: 'Malta', alpha_2: 'MT', alpha_3: 'MLT', numeric: '470' },
    { name: 'Marshall Islands', alpha_2: 'MH', alpha_3: 'MHL', numeric: '584' },
    { name: 'Martinique', alpha_2: 'MQ', alpha_3: 'MTQ', numeric: '474' },
    { name: 'Mauritania', alpha_2: 'MR', alpha_3: 'MRT', numeric: '478' },
    { name: 'Mauritius', alpha_2: 'MU', alpha_3: 'MUS', numeric: '480' },
    { name: 'Mayotte', alpha_2: 'YT', alpha_3: 'MYT', numeric: '175' },
    { name: 'Mexico', alpha_2: 'MX', alpha_3: 'MEX', numeric: '484' },
    { name: 'Micronesia, Federated States of', alpha_2: 'FM', alpha_3: 'FSM', numeric: '583' },
    { name: 'Moldova, Republic of', alpha_2: 'MD', alpha_3: 'MDA', numeric: '498' },
    { name: 'Monaco', alpha_2: 'MC', alpha_3: 'MCO', numeric: '492' },
    { name: 'Mongolia', alpha_2: 'MN', alpha_3: 'MNG', numeric: '496' },
    { name: 'Montenegro', alpha_2: 'ME', alpha_3: 'MNE', numeric: '499' },
    { name: 'Montserrat', alpha_2: 'MS', alpha_3: 'MSR', numeric: '500' },
    { name: 'Morocco', alpha_2: 'MA', alpha_3: 'MAR', numeric: '504' },
    { name: 'Mozambique', alpha_2: 'MZ', alpha_3: 'MOZ', numeric: '508' },
    { name: 'Myanmar', alpha_2: 'MM', alpha_3: 'MMR', numeric: '104' },
    // START: N
    { name: 'Namibia', alpha_2: 'NA', alpha_3: 'NAM', numeric: '516' },
    { name: 'Nauru', alpha_2: 'NR', alpha_3: 'NRU', numeric: '520' },
    { name: 'Nepal', alpha_2: 'NP', alpha_3: 'NPL', numeric: '524' },
    { name: 'Netherlands', alpha_2: 'NL', alpha_3: 'NLD', numeric: '528' },
    { name: 'Netherlands Antilles', alpha_2: 'AN', alpha_3: 'ANT', numeric: '530' },
    { name: 'New Caledonia', alpha_2: 'NC', alpha_3: 'NCL', numeric: '540' },
    { name: 'New Zealand', alpha_2: 'NZ', alpha_3: 'NZL', numeric: '554' },
    { name: 'Nicaragua', alpha_2: 'NI', alpha_3: 'NIC', numeric: '558' },
    { name: 'Niger', alpha_2: 'NE', alpha_3: 'NER', numeric: '562' },
    { name: 'Nigeria', alpha_2: 'NG', alpha_3: 'NGA', numeric: '566' },
    { name: 'Niue', alpha_2: 'NU', alpha_3: 'NIU', numeric: '570' },
    { name: 'Norfolk Island', alpha_2: 'NF', alpha_3: 'NFK', numeric: '574' },
    { name: 'Northern Mariana Islands', alpha_2: 'MP', alpha_3: 'MNP', numeric: '580' },
    { name: 'Norway', alpha_2: 'NO', alpha_3: 'NOR', numeric: '578' },
    // START: O
    { name: 'Oman', alpha_2: 'OM', alpha_3: 'OMN', numeric: '512' },
    // START: P
    { name: 'Pakistan', alpha_2: 'PK', alpha_3: 'PAK', numeric: '586' },
    { name: 'Palau', alpha_2: 'PW', alpha_3: 'PLW', numeric: '585' },
    { name: 'Palestinian Territory, Occupied', alpha_2: 'PS', alpha_3: 'PSE', numeric: '275' },
    { name: 'Panama', alpha_2: 'PA', alpha_3: 'PAN', numeric: '591' },
    { name: 'Papua New Guinea', alpha_2: 'PG', alpha_3: 'PNG', numeric: '598' },
    { name: 'Paraguay', alpha_2: 'PY', alpha_3: 'PRY', numeric: '600' },
    { name: 'Peru', alpha_2: 'PE', alpha_3: 'PER', numeric: '604' },
    { name: 'Philippines', alpha_2: 'PH', alpha_3: 'PHL', numeric: '608' },
    { name: 'Pitcairn', alpha_2: 'PN', alpha_3: 'PCN', numeric: '612' },
    { name: 'Poland', alpha_2: 'PL', alpha_3: 'POL', numeric: '616' },
    { name: 'Portugal', alpha_2: 'PT', alpha_3: 'PRT', numeric: '620' },
    { name: 'Puerto Rico', alpha_2: 'PR', alpha_3: 'PRI', numeric: '630' },
    // START: Q
    { name: 'Qatar', alpha_2: 'QA', alpha_3: 'QAT', numeric: '634' },
    // START: R
    { name: 'Reunion', alpha_2: 'RE', alpha_3: 'REU', numeric: '638' },
    { name: 'Romania', alpha_2: 'RO', alpha_3: 'ROU', numeric: '642' },
    { name: 'Russian Federation', alpha_2: 'RU', alpha_3: 'RUS', numeric: '643' },
    { name: 'Rwanda', alpha_2: 'RW', alpha_3: 'RWA', numeric: '646' },
    // START: S
    { name: 'Saint Kitts and Nevis', alpha_2: 'KN', alpha_3: 'KNA', numeric: '659' },
    { name: 'Saint Lucia', alpha_2: 'LC', alpha_3: 'LCA', numeric: '662' },
    { name: 'Saint Vincent and the Grenadines', alpha_2: 'VC', alpha_3: 'VCT', numeric: '670' },
    { name: 'Samoa', alpha_2: 'WS', alpha_3: 'WSM', numeric: '882' },
    { name: 'San Marino', alpha_2: 'SM', alpha_3: 'SMR', numeric: '674' },
    { name: 'Sao Tome and Principe', alpha_2: 'ST', alpha_3: 'STP', numeric: '678' },
    { name: 'Saudi Arabia', alpha_2: 'SA', alpha_3: 'SAU', numeric: '682' },
    { name: 'Senegal', alpha_2: 'SN', alpha_3: 'SEN', numeric: '686' },
    { name: 'Serbia', alpha_2: 'RS', alpha_3: 'SRB', numeric: '688' },
    { name: 'Seychelles', alpha_2: 'SC', alpha_3: 'SYC', numeric: '690' },
    { name: 'Sierra Leone', alpha_2: 'SL', alpha_3: 'SLE', numeric: '694' },
    { name: 'Singapore', alpha_2: 'SG', alpha_3: 'SGP', numeric: '702' },
    { name: 'Slovakia', alpha_2: 'SK', alpha_3: 'SVK', numeric: '703' },
    { name: 'Slovenia', alpha_2: 'SI', alpha_3: 'SVN', numeric: '705' },
    { name: 'Solomon Islands', alpha_2: 'SB', alpha_3: 'SLB', numeric: '090' },
    { name: 'Somalia', alpha_2: 'SO', alpha_3: 'SOM', numeric: '706' },
    { name: 'South Africa', alpha_2: 'ZA', alpha_3: 'ZAF', numeric: '710' },
    { name: 'South Sudan', alpha_2: 'SS', alpha_3: 'SSD', numeric: '728' },
    { name: 'Spain', alpha_2: 'ES', alpha_3: 'ESP', numeric: '724' },
    { name: 'Sri Lanka', alpha_2: 'LK', alpha_3: 'LKA', numeric: '144' },
    { name: 'Sudan', alpha_2: 'SD', alpha_3: 'SDN', numeric: '729' },
    { name: 'Suriname', alpha_2: 'SR', alpha_3: 'SUR', numeric: '740' },
    { name: 'Sweden', alpha_2: 'SE', alpha_3: 'SWE', numeric: '752' },
    { name: 'Switzerland', alpha_2: 'CH', alpha_3: 'CHE', numeric: '756' },
    { name: 'Syrian Arab Republic', alpha_2: 'SY', alpha_3: 'SYR', numeric: '760' },
    // START: T
    { name: 'Taiwan, Province of China', alpha_2: 'TW', alpha_3: 'TWN', numeric: '158' },
    { name: 'Tajikistan', alpha_2: 'TJ', alpha_3: 'TJK', numeric: '762' },
    { name: 'Tanzania, United Republic of', alpha_2: 'TZ', alpha_3: 'TZA', numeric: '834' },
    { name: 'Thailand', alpha_2: 'TH', alpha_3: 'THA', numeric: '764' },
    { name: 'Timor-Leste', alpha_2: 'TL', alpha_3: 'TLS', numeric: '626' },
    { name: 'Togo', alpha_2: 'TG', alpha_3: 'TGO', numeric: '768' },
    { name: 'Tokelau', alpha_2: 'TK', alpha_3: 'TKL', numeric: '772' },
    { name: 'Tonga', alpha_2: 'TO', alpha_3: 'TON', numeric: '776' },
    { name: 'Trinidad and Tobago', alpha_2: 'TT', alpha_3: 'TTO', numeric: '780' },
    { name: 'Tunisia', alpha_2: 'TN', alpha_3: 'TUN', numeric: '788' },
    { name: 'Turkey', alpha_2: 'TR', alpha_3: 'TUR', numeric: '792' },
    { name: 'Turkmenistan', alpha_2: 'TM', alpha_3: 'TKM', numeric: '795' },
    { name: 'Turks and Caicos Islands', alpha_2: 'TC', alpha_3: 'TCA', numeric: '796' },
    { name: 'Tuvalu', alpha_2: 'TV', alpha_3: 'TUV', numeric: '798' },
    // START: U
    { name: 'Uganda', alpha_2: 'UG', alpha_3: 'UGA', numeric: '800' },
    { name: 'Ukraine', alpha_2: 'UA', alpha_3: 'UKR', numeric: '804' },
    { name: 'United Arab Emirates', alpha_2: 'AE', alpha_3: 'ARE', numeric: '784' },
    { name: 'United Kingdom of Great Britain and Northern Ireland', alpha_2: 'GB', alpha_3: 'GBR', numeric: '826' },
    { name: 'United States', alpha_2: 'US', alpha_3: 'USA', numeric: '840' },
    { name: 'Uruguay', alpha_2: 'UY', alpha_3: 'URY', numeric: '858' },
    { name: 'Uzbekistan', alpha_2: 'UZ', alpha_3: 'UZB', numeric: '860' },
    // START: V
    { name: 'Vanuatu', alpha_2: 'VU', alpha_3: 'VUT', numeric: '548' },
    { name: 'Venezuela (Bolivarian Republic of)', alpha_2: 'VE', alpha_3: 'VEN', numeric: '862' },
    { name: 'Viet Nam', alpha_2: 'VN', alpha_3: 'VNM', numeric: '704' },
    { name: 'Virgin Islands (British)', alpha_2: 'VG', alpha_3: 'VGB', numeric: '092' },
    { name: 'Virgin Islands (U.S.)', alpha_2: 'VI', alpha_3: 'VIR', numeric: '850' },
    // START: W
    { name: 'Wallis and Futuna', alpha_2: 'WF', alpha_3: 'WLF', numeric: '876' },
    { name: 'Western Sahara', alpha_2: 'EH', alpha_3: 'ESH', numeric: '732' },
    // START: Y
    { name: 'Yemen', alpha_2: 'YE', alpha_3: 'YEM', numeric: '887' },
    // START: Z
    { name: 'Zambia', alpha_2: 'ZM', alpha_3: 'ZMB', numeric: '894' },
    { name: 'Zimbabwe', alpha_2: 'ZW', alpha_3: 'ZWE', numeric: '716' },
  ] as ICountries[];
  // constructor() {},

  getCountries() {
    return this.Countries;
  }
  countryToAbbreviation(country: string) {
    const countryItem = this.Countries.find((item) => item.name === country);
    return countryItem.alpha_2;
  }
  isCountryUnitedStates(country: string) {
    switch (country) {
      case 'US':
      case 'United States':
      case 'United States of America':
      case 'united states':
      case 'united states of america':
      case 'USA':
        return true;
      default:
        return false;
    }
  }
}
export interface ICountries {
  name: string;
  alpha_2: string;
  alpha_3: string;
  numeric: string;
}
