import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

interface GoPayload {
  path: any[];
  // query?: object;
  query?: Record<string, unknown>;
  extras?: NavigationExtras;
}

export const Go = createAction('[Router] Go', props<{ payload: GoPayload }>());

export const Back = createAction('[Router] Back');

export const Forward = createAction('[Router] Forward');

// export const GO = '[Router] Go';
// export const BACK = '[Router] Back';
// export const FORWARD = '[Router] Forward';

// export class Go implements Action {
//   readonly type = GO;
//   constructor(
//     public payload: {
//       path: any[];
//       query: object;
//       extras: NavigationExtras;
//     }
//   ) {}
// }

// export class Back implements Action {
//     readonly type = BACK
// }

// export class Forward implements Action {
//     readonly type = FORWARD
// }

// export type Actions = Go | Back | Forward
