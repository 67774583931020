import { ICoreCompany, ICoreCompanyAddressParamsUpdate, IHttpException } from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// UPDATE
export const updateCompanyAddress = createAction('[SLIS-CA-Companies] updateCompanyAddress', props<{ data: ICoreCompanyAddressParamsUpdate }>());
export const updateCompanyAddressSuccess = createAction(
  '[SLIS-CA-Companies] updateCompanyAddressSuccess',
  props<{ company: ICoreCompany; success_message?: string }>()
);
export const updateCompanyAddressFailure = createAction(
  '[SLIS-CA-Companies] updateCompanyAddressFailure',
  props<{ company_id: string; error: IHttpException }>()
);
