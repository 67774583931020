import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LaunchpointAbilitySecurityService } from '../services/security.service';
import { Store } from '@ngrx/store';
import { UserSelectors } from '../../user';

/**
 * A custom pipe to check security access using the LaunchpointAbilitySecurityService.
 *
 * @example
 * <!-- Display content only if security access is granted -->
 * <div *ngIf="[action, subject, data] | lpSecurityAccess | async">
 *   <!-- Content visible if security access is granted -->
 * </div>
 */
@Pipe({
  name: 'lpSecurityAccess',
})
export class LpSecurityPipe implements PipeTransform {
  /**
   * Creates an instance of LpSecurityPipe.
   *
   * @param _Store - The NgRx Store.
   * @param _SecurityService - The LaunchpointAbilitySecurityService for checking security access.
   */
  constructor(private _Store: Store, private _SecurityService: LaunchpointAbilitySecurityService) {}

  /**
   * Transforms the input value to check security access using the LaunchpointAbilitySecurityService.
   *
   * @param value - The input value containing [action, subject, data].
   * @returns An Observable that emits a boolean indicating whether security access is granted.
   */
  transform([action, subject, data]: [string, string, any?]): Observable<boolean> {
    return this._Store.select(UserSelectors.selectUser).pipe(map((user) => this._SecurityService.checkAbility(user, action, subject, data)));
  }
}
