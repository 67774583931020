import { Directive, OnInit } from '@angular/core';
import {
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { adminAcceptAccountInvitation, adminResendAccountInvite } from '../../../../../users/+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';

@Directive()
export class LaunchpointAccountsSmartAdminBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  constructor(public _Store: Store) {
    super(_Store);
  }

  ngOnInit() {
    // console.log('K dispatch search users?');
    // this._Store.dispatch(searchAllUsers());
    return;
  }

  pageChange(paginate) {
    // console.log('k page change');
    // this._Store.dispatch(updateAllUsersPagination({ pagination: paginate }));
  }

  runQuery(search_string) {
    // console.log('k query');
    // this._Store.dispatch(updateAllUsersQuery({ query: { search: search_string } }));
  }

  sortData(sortDataEmitter: IQuerySort) {
    // console.log('k sort data');
    // this._Store.dispatch(updateAllUsersSorting({ querySort: sortDataEmitter }));
  }

  removeAccount(data: Omit<ICoreUserAccountsParamsRemove, 'requesting_user_id'>) {
    // this._Store.dispatch(removeAccount({ data }));
  }

  async resendAccountInvite(data: Omit<ICoreUserAccountsParamsResendInvite, 'user_id'>) {
    const user_id = await (await firstValueFrom(this.selected_user$)).user_id;
    // console.log('firstvalue from', user_id);
    this._Store.dispatch(adminResendAccountInvite({ data: { ...data, user_id } }));
  }
  acceptAccountInvite(data: ICoreUserAccountsParamsAcceptInvitations) {
    this._Store.dispatch(adminAcceptAccountInvitation({ data }));
  }
  changeAccountRoles(data: any) {
    console.log('todo, change account roles action');
  }
}
