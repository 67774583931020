import { LaunchpointCoreWebBlogCommentsComponent } from './blog-comments/blog-comments.component';
import { LaunchpointCoreWebBlogContentComponent } from './blog-content/blog-content.component';
import { LaunchpointCoreWebBlogHeaderComponent } from './blog-header/blog-header.component';
import { LaunchpointCoreWebBlogMediaComponent } from './blog-media/blog-media.component';
import { LaunchpointCoreWebBlogOverviewComponent } from './blog-overview/blog-overview.component';
import { LaunchpointCoreWebBlogRelatedPostsComponent } from './blog-related-posts/blog-related-posts.component';
import { LaunchpointCoreWebBlogSEOComponent } from './blog-seo/blog-seo.component';
import { LaunchpointCoreWebBlogTagsComponent } from './blog-tags/blog-tags.component';
import { LaunchpointCoreWebBlogComponent } from './blog/blog.component';

export const BLOG_DETAIL_COMPONENTS = [
  LaunchpointCoreWebBlogComponent,
  LaunchpointCoreWebBlogContentComponent,
  LaunchpointCoreWebBlogHeaderComponent,
  LaunchpointCoreWebBlogTagsComponent,
  LaunchpointCoreWebBlogMediaComponent,
  LaunchpointCoreWebBlogOverviewComponent,
  LaunchpointCoreWebBlogSEOComponent,
  LaunchpointCoreWebBlogRelatedPostsComponent,
  LaunchpointCoreWebBlogCommentsComponent,
];

export * from './blog-comments/blog-comments.component';
export * from './blog-content/blog-content.component';
export * from './blog-header/blog-header.component';
export * from './blog-media/blog-media.component';
export * from './blog-overview/blog-overview.component';
export * from './blog-related-posts/blog-related-posts.component';
export * from './blog-seo/blog-seo.component';
export * from './blog-tags/blog-tags.component';
export * from './blog/blog.component';
