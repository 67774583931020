import { Component, inject } from '@angular/core';
import { LaunchpointBlogService } from '../../data/blog.service';

@Component({
  selector: 'sd-featured-blog',
  templateUrl: './featured-blog.component.html',
  styleUrl: './featured-blog.component.scss',
})
export class FeaturedBlogComponent {
  _LaunchpointBlogService = inject(LaunchpointBlogService);

  loading() {
    return this._LaunchpointBlogService.loading;
  }
}
