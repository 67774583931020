import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AppSettingsCountriesService,
  GoogleAddressService,
  LaunchpointCoreClientProfileConfig,
  LibCoreClientPipesModule,
  PROFILE_CONFIG_TOKEN,
} from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CardsModule } from '../../../layouts/core/partials/content/cards/cards.module';
import { DropdownMenusModule } from '../../../layouts/core/partials/content/dropdown-menus/dropdown-menus.module';
import { WidgetsModule } from '../../../layouts/core/partials/content/widgets/widgets.module';
import { LaunchpointCoreWebProfileCampaignsComponent } from './campaigns/profile-campaigns.component';
import { LaunchpointCoreWebProfileConnectionsComponent } from './connections/profile-connections.component';
import { LaunchpointCoreWebProfileDocumentsComponent } from './documents/profile-documents.component';
import { LaunchpointCoreWebProfileAddressAutoCompleteComponent } from './profile-address/profile-address-autocomplete/profile-address-autocomplete.component';
import { LaunchpointCoreWebProfileAddressManuelComponent } from './profile-address/profile-address-manual/profile-address-manual-form.component';
import { LaunchpointCoreWebProfileAddressSmartAdminComponent } from './profile-address/profile-address-smart-admin/profile-address-smart-admin.component';
import { LaunchpointCoreWebProfileAddressSmartUserComponent } from './profile-address/profile-address-smart-user/profile-address-smart-user.component';
import { LaunchpointCoreWebDeactivateAccountComponent } from './profile-deactivate-account/deactivate-account.component';
import { LaunchpointCoreWebProfileDetailsSmartAdminComponent } from './profile-details/profile-details-smart-admin/profile-details-smart-admin.component';
import { LaunchpointCoreWebProfileDetailsSmartUserComponent } from './profile-details/profile-details-smart-user/profile-details-smart-user.component';
import { LaunchpointCoreWebProfileDetailsComponent } from './profile-details/profile-details/profile-details.component';
import { LaunchpointCoreWebProfileHeaderCardComponent } from './profile-header-card/profile-header-card.component';
import { LaunchpointCoreWebProfileNotificationsComponent } from './profile-notifications/profile-notifications.component';
import { LaunchpointCoreWebProfilePhoneSmartAdminComponent } from './profile-phone/profile-phone-smart-admin/profile-phone-smart-admin.component';
import { LaunchpointCoreWebProfilePhoneSmartUserComponent } from './profile-phone/profile-phone-smart-user/profile-phone-smart-user.component';
import { LaunchpointCoreWebProfilePhoneComponent } from './profile-phone/profile-phone/profile-phone.component';
import { LaunchpointCoreWebProfileSecurityRolesSmartAdminComponent } from './profile-security-roles/profile-security-roles-smart-admin/profile-security-roles-smart-admin.component';
import { LaunchpointCoreWebProfileSecurityRolesComponent } from './profile-security-roles/profile-security-roles/profile-security-roles.component';
import { LaunchpointCoreWebProfileUpdateEmailPasswordWithCurrentComponent } from './profile-update-email-password-with-current/update-email-password.component';
import { LaunchpointCoreWebProfileUpdateEmailPasswordSmartAdminComponent } from './profile-update-email-password/profile-update-email-password-smart-admin/update-email-password-smart-admin.component';
import { LaunchpointCoreWebProfileUpdateEmailPasswordSmartUserComponent } from './profile-update-email-password/profile-update-email-password-smart-user/update-email-password-smart-user.component';
import { LaunchpointCoreWebProfileUpdateEmailPasswordComponent } from './profile-update-email-password/profile-update-email-password/update-email-password.component';
import { LaunchpointCoreWebProfileComponent } from './profile.component';
import { LaunchpointCoreWebProfileService } from './profile.service';

export const WEB_PROFILE_COMPONENTS = [
  LaunchpointCoreWebProfileComponent,
  LaunchpointCoreWebProfileCampaignsComponent,
  LaunchpointCoreWebProfileConnectionsComponent,
  LaunchpointCoreWebProfileDocumentsComponent,
  LaunchpointCoreWebProfileAddressAutoCompleteComponent,
  LaunchpointCoreWebProfileAddressManuelComponent,
  LaunchpointCoreWebProfileAddressSmartAdminComponent,
  LaunchpointCoreWebProfileAddressSmartUserComponent,
  LaunchpointCoreWebDeactivateAccountComponent,
  LaunchpointCoreWebProfileDetailsSmartAdminComponent,
  LaunchpointCoreWebProfileDetailsSmartUserComponent,
  LaunchpointCoreWebProfileDetailsComponent,
  LaunchpointCoreWebProfileHeaderCardComponent,
  LaunchpointCoreWebProfilePhoneSmartAdminComponent,
  LaunchpointCoreWebProfilePhoneSmartUserComponent,
  LaunchpointCoreWebProfilePhoneComponent,
  LaunchpointCoreWebProfileSecurityRolesSmartAdminComponent,
  LaunchpointCoreWebProfileSecurityRolesComponent,
  LaunchpointCoreWebProfileUpdateEmailPasswordSmartAdminComponent,
  LaunchpointCoreWebProfileUpdateEmailPasswordSmartUserComponent,
  LaunchpointCoreWebProfileUpdateEmailPasswordComponent,
  LaunchpointCoreWebProfileNotificationsComponent,
  LaunchpointCoreWebProfileUpdateEmailPasswordWithCurrentComponent,
];

@NgModule({
  declarations: [...WEB_PROFILE_COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule,
    DropdownMenusModule,
    WidgetsModule,
    CardsModule,
    RouterModule,
    NgbModule,
    LibCoreClientPipesModule,
  ],
  providers: [LaunchpointCoreWebProfileService, GoogleAddressService, AppSettingsCountriesService],
  exports: [...WEB_PROFILE_COMPONENTS],
})
export class LaunchpointCoreWebProfileModule {
  static forRoot(_profileConfig: LaunchpointCoreClientProfileConfig): ModuleWithProviders<LaunchpointCoreWebProfileModule> {
    return {
      ngModule: LaunchpointCoreWebProfileModule,
      providers: [
        {
          provide: PROFILE_CONFIG_TOKEN,
          useValue: _profileConfig,
        },
      ],
    };
  }
}
