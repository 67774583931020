export interface ICoreUserAccountsConfigRoutes {
  base: string;
  addSubUserAccount: string;
  addMainUserAccount: string;
  removeAccount: string;
  getPendingInvites: string;
  acceptInvites: string;
  selectAccount: string;
  deselectAccount: string;
  resendInvite: string;
  findWhereUserInAccounts: string;
  upgradeSubUser: string;
}
