<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">
    <launchpoint-toolbar [options]="options"></launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="table-responsive">
            <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
              <ng-container *ngIf="blogLoading$ | async">
                <span class="indicator-label m-5" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
                </span>
              </ng-container>

              <thead>
                <tr class="fw-bold text-uppercase fs-7 text-gray-700">
                  <th class="min-w-150px">Title</th>
                  <th class="min-w-150px">Comment</th>
                  <th class="min-w-150px">Replies</th>
                  <th class="min-w-150px text-end">User</th>
                  <th class="min-w-150px text-end">Created</th>
                  <th class="min-w-120px text-end">Actions</th>
                </tr>
              </thead>

              <tbody class="fs-6 fw-semibold">
                <ng-container>
                  <tr *ngIf="(blogLoading$ | async) === false && !filteredBlogs()?.length">
                    <td colspan="4" class="text-center">No Comments</td>
                  </tr>

                  <ng-container *ngFor="let comment of filteredBlogs()">
                    <tr (click)="expandComment(comment)">
                      <td>
                        {{ comment?.title }}
                      </td>

                      <td>
                        <div class="d-flex flex-wrap">
                          {{ comment?.comment }}
                        </div>
                      </td>

                      <td>
                        {{ comment?.replies?.length }}
                      </td>

                      <td class="text-end">
                        {{ comment?.user?.profile?.full_name ?? 'Anonymous' }}
                      </td>

                      <td class="text-end">
                        {{ comment?.created_at | date: 'medium' }}
                      </td>

                      <td class="text-end">
                        <button
                          type="button"
                          class="btn btn-sm btn-icon text-hover-danger"
                          ngbTooltip="Delete"
                          (click)="deleteCommentPopup(comment, $event)"
                        >
                          <i class="bi bi-trash"></i>
                        </button>
                      </td>
                    </tr>

                    <tr *ngIf="expanded?._id === comment?._id && expanded?.expanded">
                      <td colspan="6">
                        <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
                          <thead>
                            <tr class="fw-bold text-uppercase fs-7 text-gray-700">
                              <th class="min-w-150px">Title</th>
                              <th class="min-w-150px">Reply</th>
                              <th class="min-w-150px text-end">User</th>
                              <th class="min-w-150px text-end">Created</th>
                              <th class="min-w-120px text-end">Actions</th>
                            </tr>
                          </thead>

                          <tbody class="fs-6 fw-normal">
                            <tr *ngIf="!comment?.replies?.length">
                              <td>No Replies</td>
                            </tr>

                            <tr *ngFor="let reply of comment?.replies">
                              <td>
                                {{ reply?.title }}
                              </td>

                              <td>
                                <div class="d-flex flex-wrap">
                                  {{ reply?.comment }}
                                </div>
                              </td>

                              <td class="text-end">
                                {{ reply?.user?.profile?.full_name ?? 'Anonymous' }}
                              </td>

                              <td class="text-end">
                                {{ reply?.created_at | date: 'medium' }}
                              </td>

                              <td class="text-end">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-icon text-hover-danger"
                                  ngbTooltip="Delete"
                                  (click)="deleteReply(reply, comment, $event)"
                                >
                                  <i class="bi bi-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
