export enum ESecurityCoreCompanyResources {
  COMPANY_READ = 'company:read',
  COMPANY_UPDATE = 'company:update',
  COMPANY_DEACTIVATE = 'company:deactivate',
  COMPANY_CREATE = 'company:create',
  COMPANY_DELETE = 'company:delete',
  COMPANY_DELETE_INFO = 'company-info:delete',
  COMPANY_SEARCH = 'company:search',

  COMPANY_UPSERT_TOKEN = 'company:upsert-token',
  COMPANY_UPSERT_TOPIC = 'company:upsert-topic',
  COMPANY_UNSUBSCRIBE_ALL = 'company:unsubscribe-all',

  COMPANY_REPORTING = 'company:reporting',
  //
  COMPANY_SIGN_UP = 'company:sign-up',
  COMPANY_UPDATE_ADDRESS = 'company:update:address',
}
