<form [formGroup]="form">
  <div class="px-7 pt-5 mt-5">
    <div class="mb-2 px-3">
      <!--begin::Label-->
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Security Roles</label>
        <div>
          <i class="fs-4 bi bi-plus-square cursor-pointer me-2" animateCss="tada" ngbTooltip="Search all status roles"
            (click)="securityRolesAll()"></i>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form.controls?.security_role?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all status roles"
            (click)="form.controls.security_role.setValue([])"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="security_role" class="me-5 customTag" name="security_role"
          [whitelist]="whitelistSecurityRole$" [settings]="settings"
          inputClass="form-control form-control-sm form-control-solid ps-6 bg-white">
        </tagify>
      </div>
    </div>
    <div class="mb-2 px-3">
      <!--begin::Label-->
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Control Types</label>
        <div>
          <i class="fs-4 bi bi-plus-square cursor-pointer me-2" animateCss="tada" ngbTooltip="Search all control types"
            (click)="controlTypesAll()"></i>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form.controls?.control_types?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all control types"
            (click)="form.controls.control_types.setValue([])"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="control_types" class="me-5" name="control_types" [whitelist]="whitelistControlTypes$"
          [settings]="settings" inputClass="form-control form-control-sm form-control-solid ps-6 bg-white">
        </tagify>
      </div>
    </div>
    <div class="menu-item px-3">
      <label class="form-label fw-semibold">Created At</label>
      <launchpoint-date-range formControlName="created_at" [allOptions]="{ debug: false }"></launchpoint-date-range>
    </div>
    <div class="menu-item">
      <label class="form-label fw-semibold">Last Login</label>
      <launchpoint-date-range formControlName="last_login" [allOptions]="{ debug: false }"></launchpoint-date-range>
    </div>
  </div>
  <div class="rounded-bottom">
    <div class="d-flex justify-content-between align-items-center m-5 px-4">
      <div>
        <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="clearAll()">Clear
          All</a>
      </div>
      <div class="d-flex justify-content-end">
        <launchpoint-user-view-save [view]="view" [query]="query$ | async"
          [table_id]="'User'"></launchpoint-user-view-save>

        <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="apply()">Apply</button>
      </div>
    </div>
  </div>
</form>