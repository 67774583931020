import { Component, inject, OnInit } from '@angular/core';
import { FileEntity, LaunchpointCoreClientMediaUploadBaseComponent, searchAllMedia } from '@launchpoint/core-client';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-media-upload-modal',
  templateUrl: './media-upload-modal.component.html',
})
export class LaunchpointCoreWebMediaUploadModalComponent extends LaunchpointCoreClientMediaUploadBaseComponent {
  createModal: NgbModalRef;
  public activeModal = inject(NgbActiveModal);

  files: FileEntity[] = [];
  edit: {
    [key: string]: {
      edit: boolean;
      name: string;
    };
  }[] = [];

  editFile(name: string) {
    this.edit[name] = {
      edit: this.edit[name]?.edit ? false : true,
      name,
    };
  }

  saveFile(file: FileEntity) {
    const editPath = this.edit[file.file.name];
    file.file = new File([file.file], editPath.name, { type: file.file.type });

    editPath.edit = false;
  }

  async uploadFile() {
    await this.upload();

    if (!this.files.some((file) => file.error)) {
      this.close();
    }
  }

  close() {
    this.activeModal.close();
  }
}
