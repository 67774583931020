import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientMediaCategoriesBaseComponent } from '@launchpoint/core-client';
import { IMediaCategory } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebMediaCategoriesModalComponent } from '../media-categories-modal/media-categories-modal.component';

@Component({
  selector: 'launchpoint-media-categories-toolbar',
  templateUrl: './media-categories-toolbar.component.html',
})
export class LaunchpointCoreWebMediaCategoriesToolbarComponent extends LaunchpointCoreClientMediaCategoriesBaseComponent implements OnInit {
  createModal: NgbModalRef;
  modalService = inject(NgbModal);

  options: IToolbarConfig = {
    title: 'Media Categories',
    formControl: this.form_control,
    filter: false,
  };

  open(category?: IMediaCategory) {
    this.createModal = this.modalService.open(LaunchpointCoreWebMediaCategoriesModalComponent, { size: 'md' });
    if (category) {
      this.createModal.componentInstance.category = category;
    }
  }
}
