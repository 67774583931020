import { Observable } from 'rxjs';
import {
  ICoreCompany,
  ICoreCompanyAddressParamsUpdate,
  ICoreCompanyParamsCreate,
  ICoreCompanyParamsCreateResults,
  ICoreCompanyParamsSignUp,
  ICoreCompanyParamsSignUpResults,
  ICoreCompanyParamsUpdate,
  ICoreCompanySearchPayload,
  ICoreCompanySearchResults,
} from '../user-company-account.interface';

export interface ICoreCompanyController {
  /**
   * @POST('search)
   * @param params
   *
   * @AccountRoles([EUserAccountTypes.ADMIN], ['super_admin'])
   */
  search(params: ICoreCompanySearchPayload): Promise<ICoreCompanySearchResults> | Observable<ICoreCompanySearchResults>;

  /**
   * @Get(':id')
   * @AccountRoles([EUserAccountTypes.ADMIN], ['super_admin'])
   */
  getById(id: string): Promise<ICoreCompany> | Observable<ICoreCompany>;

  /**
   * @Get('email/:email')
   * @Version('1')
   */
  searchOneByEmail(email: string): Promise<ICoreCompany> | Observable<ICoreCompany>;

  /**
   * POST
   * @route 'search'
   * @param params
   */
  create(body: ICoreCompanyParamsCreate): Promise<ICoreCompanyParamsCreateResults> | Observable<ICoreCompanyParamsCreateResults>;

  /**
   *  @Post('sign-up')
   * @Version('1')
   * @param body
   */
  signUp(body: ICoreCompanyParamsSignUp): Promise<ICoreCompanyParamsSignUpResults> | Observable<ICoreCompanyParamsSignUpResults>;

  /**
   * @Put()
   * @Version('1')
   */
  update(params: ICoreCompanyParamsUpdate): Promise<ICoreCompany> | Observable<ICoreCompany>;

  /**
   * @Put('deactivate')
   * @Version('1')
   * @param params
   */
  deactivate(params: ICoreCompanyParamsUpdate): Promise<ICoreCompany> | Observable<ICoreCompany>;

  /**
   * @Delete(':id')
   * @Version('1')
   */
  delete(id: string): Promise<ICoreCompany> | Observable<ICoreCompany>;
  /**
   * @Put('delete-info/:id')
   * @Version('1')
   */
  deleteInfo(id: string): Promise<ICoreCompany> | Observable<ICoreCompany>;
}

export interface ICoreCompanyStripeCustomerController {
  // /**
  //  * @Get(':id')
  //  * @Version('1')
  //  * @param id
  //  */
  // getStripeCustomer(id: string): Promise<ICoreCompany['customer_id']> | Observable<ICoreCompany['customer_id']>;
  // /**
  //  * @Put('bank-account')
  //  * @Version('1')
  //  * @param body
  //  */
  // addBankAccount(body: { stripe_customer_id: string; source: string }): Promise<ICoreCompany> | Observable<ICoreCompany>;
  /**
   *  @Put('sync')
   * @Version('1')
   * @param body
   */
  syncStripe(body: { id: string }): Promise<ICoreCompany> | Observable<ICoreCompany>;
}

export interface ICoreCompanyAddressController {
  /**
   * @Put()
   * @Version('1')
   */
  update(params: ICoreCompanyAddressParamsUpdate): Promise<ICoreCompany> | Observable<ICoreCompany>;
}
export interface ICoreCompanyAccountOwnerController {
  /**
   * @Put()
   * @Version('1')
   */
  update(params: ICoreCompanyAddressParamsUpdate): Promise<ICoreCompany> | Observable<ICoreCompany>;
}
