import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { animate_css } from '../interfaces/animate-css.type';
import { AnimateCssService } from '../services/animate-css.service';

/**
 * EXAMPLE: animateCss="animate__flipInX"
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[animateCss]',
})
export class AnimateCssDirective implements AfterViewInit, OnDestroy {
  @Input() animateCss: animate_css | animate_css[];
  constructor(private elementRef: ElementRef, private _AnimateCssService: AnimateCssService) {}

  ngAfterViewInit() {
    if (this.animateCss) {
      this._AnimateCssService.addAnimation(this.elementRef, this.animateCss);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.elementRef.nativeElement.removeEventListener('animationend', this._AnimateCssService.removeAnimation(this.elementRef, this.animateCss));
  }
}
