import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaunchpointCoreClientNotificationsTopicsSearchAheadBaseComponent, TopicsHTTPService } from '@launchpoint/core-client';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbTypeaheadModule];

@Component({
  standalone: true,
  selector: 'launchpoint-notifications-topics-search-ahead',
  templateUrl: 'notifications-topics-search-ahead.component.html',
  imports: MODULES,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreWebNotificationTopicsSearchAheadComponent),
      multi: true,
    },
    TopicsHTTPService,
  ],
})
export class LaunchpointCoreWebNotificationTopicsSearchAheadComponent extends LaunchpointCoreClientNotificationsTopicsSearchAheadBaseComponent {}
