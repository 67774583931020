<div class="d-flex rounded-topoverflow-auto h-55px">
  <ul class="nav nav-stretch nav-line-tabs border-transparent fs-7 fw-bold flex-nowrap px-10">
    <li class="nav-item">
      <a class="nav-link text-active-primary me-6" [ngClass]="{ active: active === 1 }" (click)="active = 1">Filter </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-active-primary me-6" [ngClass]="{ active: active === 2 }" (click)="active = 2">Saved </a>
    </li>
  </ul>
</div>
<div class="separator"></div>
<div [hidden]="active !== 1">
  <ng-content> </ng-content>
</div>
<div [hidden]="active !== 2">
  <launchpoint-user-views [viewQuery]="{ table_id }" (selected)="setView($event)"></launchpoint-user-views>
</div>