import { Component } from '@angular/core';
import { CompanyUpdateSharedBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-update-shared',
  templateUrl: 'company-update-shared.component.html',
})
export class CompanyUpdateSharedComponent extends CompanyUpdateSharedBaseComponent {
  // constructor(public _Store: Store, public _Router: Router, public _route: ActivatedRoute, public fb: UntypedFormBuilder) {
  //   super(_Store, _Router, _route, fb);
  // }
}
