import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointMediaCategoriesService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebMediaCategoriesTagifyBaseComponent } from './media-categories-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [LaunchpointMediaCategoriesService],
  selector: 'launchpoint-media-categories-tagify',
  templateUrl: 'media-categories-tagify.component.html',
})
export class LaunchpointCoreMediaCategoriesTagifyComponent extends LaunchpointCoreWebMediaCategoriesTagifyBaseComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
