<div class="relative flex justify-center py-10 md:py-20">
  <div class="w-[1200px] max-w-full px-6 text-white">
    EVENT DETAIL

    <pre [innerHTML]="event | json"></pre>

    <div>{{ event?.display_title }}</div>
    <div>{{ event?.name }}</div>
  </div>
</div>
