import { IQueryPageination, IQuerySort } from '../query.interfaces';
import { ICoreUser } from '../user-company-account.interface';

export interface ICoreUsersActivityHistory {
  _id: string;
  created_at: Date;
  updated_at: Date;
  activity: string;
  related: {
    related_name: EUsersActivityHistoryRelatedName;
    related_id: string;
  };
  user: {
    user_id: string;
    profile: {
      first_name: string;
      last_name: string;
    };
  };
  location: string;
}

export interface IQueryUsersActivityHistory {
  pagination: IQueryPageination;
  history: ICoreUsersActivityHistory[];
}

export interface ICoreUsersActivityHistoryQueryParams {
  user_id: string;
  search?: string;
}

export interface ICoreUsersActivityHistorySearchParams {
  query: ICoreUsersActivityHistoryQueryParams;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

/**
 * This defines what the id is realted to for a searchback if needed. The id of what. Should be the object name on the user
 */
export enum EUsersActivityHistoryRelatedName {
  MEMBERSHIP = 'Membership',
  STRIPE_MEMBERSHIP = 'Stripe Membership',
  USER = 'User',
  SOCIAL_MEDIA = 'Social Media',
  USER_NOTIFICATION = 'User Notification',
}

export interface ICoreUsersActivityHistoryCreateParams {
  user: ICoreUser;
  activity: string;
  related_name: EUsersActivityHistoryRelatedName;
  related_id: string;
}
