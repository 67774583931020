import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientNotificationsTopicsBaseComponent } from '@launchpoint/core-client';
import { ITopic } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebNotificationTopicModalComponent } from '../notifications-topic-modal/notifications-topics-modal.component';

@Component({
  selector: 'launchpoint-notifications-topics',
  templateUrl: './notifications-topics.component.html',
  styleUrls: ['./notifications-topics.component.scss'],
})
export class LaunchpointCoreWebNotificationTopicsComponent extends LaunchpointCoreClientNotificationsTopicsBaseComponent implements OnInit {
  modalRef: NgbModalRef;
  private modalService = inject(NgbModal);
  private _swal = inject(LaunchpointSweetAlertService);

  options: IToolbarConfig = {
    title: 'Topics',
    formControl: this.form_control,
    create: false,
    searchPlaceholder: 'Search topics',
  };

  ngOnInit() {
    super.ngOnInit();
    this.success();
    this.error();
  }

  open(topic: ITopic) {
    this.modalRef = this.modalService.open(LaunchpointCoreWebNotificationTopicModalComponent, { size: 'md' });
    this.modalRef.componentInstance.topic = topic;
  }

  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
