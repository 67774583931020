import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminMediaCategoryAdaptor, coreMediaCategoryFeatureKey, IMediaCategoryEntityState } from '../reducers/media-category.reducer';

export const selectAdminMediaCategoryState = createFeatureSelector<IMediaCategoryEntityState>(coreMediaCategoryFeatureKey);
/**
 * ROOT STATE
 */
export const getMediaCategoryState = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state);

export const selectMediaCategoryPagination = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.pagination);
export const selectMediaCategoryQuerySort = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.querySort);
export const selectMediaCategoryQuery = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.query);
export const selectMediaCategoryLoaded = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.loaded);
export const selectMediaCategoryLoading = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.loading);
export const selectMediaCategoryError = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.error);
export const selectMediaCategorySuccess = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.success_message);

export const getAllMediaCategoryStateEntities = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.entities);

export const selectCategories = createSelector(selectAdminMediaCategoryState, (state: IMediaCategoryEntityState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = AdminMediaCategoryAdaptor.getSelectors(selectAdminMediaCategoryState);

// select the array of category ids
export const selectMediaCategoryIds = selectIds;

// select the dictionary of category entities
export const selectMediaCategoryEntities = selectEntities;

// select the array of categories
export const selectMediaCategories = selectAll;

// select the total entity count
export const selectMediaCategoryTotal = selectTotal;

export const selectMediaCategoryId = createSelector(getMediaCategoryState, (state: IMediaCategoryEntityState) => state?.selected_media_category_id);

export const selectSelectedMediaCategory = createSelector(
  getMediaCategoryState,
  selectMediaCategoryId,
  (state: IMediaCategoryEntityState, selected_id) => state.entities[selected_id]?.media_category
);

export const selectSelectedMediaCategoryLoading = createSelector(
  getMediaCategoryState,
  selectMediaCategoryId,
  (state: IMediaCategoryEntityState, selected_id) => state.entities[selected_id]?.loading
);

export const selectSelectedMediaCategoryError = createSelector(
  getMediaCategoryState,
  selectMediaCategoryId,
  (state: IMediaCategoryEntityState, selected_id) => state.entities[selected_id]?.error
);

export const selectSelectedMediaCategorySuccess = createSelector(
  getMediaCategoryState,
  selectMediaCategoryId,
  (state: IMediaCategoryEntityState, selected_id) => state.entities[selected_id]?.success_message
);
