import { ICoreUserAccount } from '../../../user-company-account.interface';

export interface IUserAccountsSecurityRolesParamsCreate extends Pick<ICoreUserAccount, 'security_roles'> {
  user_id: string;
  account_id: string; // Pick<IUserAccount, 'account_id'>;
  security_roles: string[];
}

export interface IUserAccountsSecurityRolesParamsDelete extends Pick<Partial<ICoreUserAccount>, 'security_roles'> {
  user_id: string;
  account_id: string; //Pick<IUserAccounts, 'account_id'>;
}
