import { IsBoolean, IsDate, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ICoreUserIDMeResponseStatus } from './user/id-me-auth/id-me-api.interface';
import { ICoreUserNotificationPreferences, ICoreUserNotificationTokens } from './user/notifications/user-notification.interface';
import { ILaunchpointAddress } from './utils/address.interface';
import { ELaunchpointGeo } from './utils/geo-location.constants';
import { Type } from 'class-transformer';
import { ILaunchpointSearchFilterDates } from './dates.interface';
import { IQueryPageination, IQuerySort, ITagifyTag } from './query.interfaces';
import { RequireOnlyOne } from './require-one';

export class ICoreUserBase {
  _id: string;
  created_at: Date;
  updated_at: Date;
  email: string;
  /**
   * Email verification object is used to monitor and send emails to real emails so our email reputation stays high
   */
  email_verification: {
    /**
     * This is a temporary holding position for an email that is currently being validated.
     * (When a user chances their email we validate it first, then save it to the ^^ email field)
     */
    email: string;
    /**
     * Boolean value if email is verified
     */
    email_verified: boolean;
    /**
     * Unique token sent if email is verified
     * TOKEN DOES remain the same if requested multiple times before the expiration date
     */
    email_verified_token: string;
    /**
     * Date/Timestamp for with the application will have the user request a new token.
     */
    email_verified_token_expires: Date;
    /**
     * Date/timestamp for when the email was verified
     */
    email_verified_date: Date;
  };
  /**
   * Email tags are used to set and send scheduled emails. Push a tag into the array and the scheduler can grab all users with the tag
   */
  // email_tags: [
  //   {
  //     type: String,
  //     enum: Object.values(EMAIL_TAGS),
  //   },
  // ],
  third_party_signup: string;
  /**
   * Stores the current password for a user
   */
  password: string;
  /**
   * Stores previously used password to be evaluated after they have been hashed
   */
  passwords: ICoreUserPasswords[];
  /**
   * Stores the token emailed or SMS'd to the user to verify
   */
  reset_password_token: string;
  /**
   * If true the user will be prompted to reset their password on next login or session
   */
  reset_password_force: boolean;
  /**
   * When the token expires
   */
  reset_password_expires: Date;

  two_factor_auth: {
    enabled: boolean;
    verification_token: string;
    verification_token_expires: Date;
  };
  demographics: ICoreUserDemographics;
  profile: ICoreUserProfile;
  admin: {
    electronic_consent: boolean;
    electronic_consent_ts: Date;
    electronic_consent_ip: string;
  };
  security_roles: string[];
  last_login: Date;
  addresses: ICoreUserAddresses[];
  location: string;
  notification_tokens: ICoreUserNotificationTokens[];
  notification_topics: ICoreUserNotificationPreferences[];
  accounts: ICoreUserAccountBase[] | ICoreUserAccount[];
  control_type: ECoreUserControlType;
  address: ICoreUserAddresses;
  attributes: {
    [key: string]: (string | number | boolean | Date)[];
  };
  __v: number;
}

export class ICoreUser extends ICoreUserBase {
  accounts: ICoreUserAccount[];
}

export type ICoreUserTagify = ITagifyTag<ICoreUser>;

export interface ICoreUserAccountOwner extends Pick<ICoreUser, '_id' | 'profile' | 'email'> {
  email: string;
}

export class ICoreUserProfile {
  full_name: string;
  first_name: string;
  last_name: string;
  profile_photo_url: string;
  birthday: Date;
  gender: EUserProfileGender;
  marital_status: EUserProfileMaritalStatus;
  id_me_uuid?: string;
  id_me_groups?: ICoreUserIDMeResponseStatus[];
}

export class ICoreUserDemographics {
  country_id: string;
  country_iso3: string;
  country: string; //iso2
  language: string;
  phone: ICoreUserDemographicsPhone;
  longitude: number;
  latitude: number;
}

export class ICoreUserDemographicsPhone {
  phone_country_code: number;
  /**
   * area code + phone number
   */
  phone_number_long: number;
  /**
   * @default false
   */
  phone_verified?: boolean;
  phone_verified_date?: Date;
  phone_verified_token?: string;
  phone_verified_token_expires?: Date;
  /**
   * @deprecated
   */
  phone_area_code?: number;
  /**
   * @deprecated
   */
  phone_number?: number;
}

export enum EUserProfileGender {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
  PREFER_NOT_TO_SAY = 'Prefer Not To Say',
}

export enum EUserThirdPartySignup {
  SMS = 'SMS',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
}

export enum EUserSendCodeModes {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export enum EUserProfileMaritalStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
}

export class ICoreUserAdmin {
  @IsBoolean()
  electronic_consent: boolean;

  @IsDate({})
  electronic_consent_ts: Date;

  @IsString()
  @IsOptional()
  electronic_consent_ip?: string;
}

export class ICoreUserAddresses implements ILaunchpointAddress {
  _id?: string;
  title?: string;
  address_long: string;
  country: string;
  line_one: string;
  line_two: string;
  city: string;
  state: string;
  zip_code: string;
  long?: number;
  lat?: number;
  location?: {
    type: ELaunchpointGeo.POINT;
    coordinates: [number, number] | [];
  };
}

export class ICoreUserPasswords {
  _id: string;
  password: string;
  date: Date;
}

export enum ECoreUserControlType {
  MAIN_USER = 'Main User',
  SUB_USER = 'Sub User',
}

export class ICoreUserParamsCreateWithoutEmailProfile implements Partial<ICoreUserProfile> {
  first_name: string;
  last_name?: string;
  full_name?: string;
}
export class ICoreUserParamsCreateWithoutEmail {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;
  /**
   * we leave in the requirement for consent forms
   */
  @ValidateNested()
  @Type(() => ICoreUserAdmin)
  admin: ICoreUserAdmin;
  /**
   * Each client can have different account types
   * so just a string here but may be passed from
   * an emum in the client.
   */
  @IsString()
  account_type: string;

  @ValidateNested()
  @Type(() => ICoreUserParamsCreateWithoutEmailProfile)
  profile: ICoreUserParamsCreateWithoutEmailProfile;
}

export type ICoreUserCreateByUser = Pick<ICoreUser, '_id' | 'profile' | 'email'>;
export interface ICoreUserCreateBy {
  user: string | ICoreUserCreateByUser;
  full_name: string;
}

type ICoreUserParamsCreateBase = {
  password?: string;
  profile?: {
    full_name: string;
    first_name: string;
    last_name: string;
  };
  admin?: {
    electronic_consent: boolean;
    electronic_consent_ts: Date;
    electronic_consent_ip?: string;
  };
  password_confirm?: string;
  third_party_signup?: string;
};

export type ICoreUserParamsCreate = ICoreUserParamsCreateBase & RequireOnlyOne<{ email: string; phone: number }>;

export class ICoreUserParamsCreateV2 {
  email: string;
  admin: {
    electronic_consent: boolean;
    electronic_consent_ts: Date;
    electronic_consent_ip?: string;
  };
}

export type ICoreUserParamsCreateV3 = {
  admin: {
    electronic_consent: boolean;
    electronic_consent_ts: Date;
    electronic_consent_ip?: string;
  };
  reset_password_force?: boolean;
} & RequireOnlyOne<{ email: string; phone: number }>;

/**
 * Picked up from ACCOUNT_OWNER_PROJECTION
 */

export interface IUserAccountOwner extends Pick<ICoreUser, '_id' | 'profile' | 'email'> {
  email: string;
}

export interface ICoreCompanyProfile {
  company_name: string;
  account_owner: string | ICoreUser;
  phone: string;
  email: string;
}

export interface ICoreCompanyBase {
  _id: string;
  created_at: Date;
  updated_at: Date;
  company_name: string;
  account_owner: string | ICoreUser | ICoreUserAccountOwner;
  phone?: string;
  email?: string;
  // status: ECompanyStatus;
  account_type?: string | unknown;
  address?: ICoreUserAddresses;
  connect_account?: string | unknown;
  year_established?: number;
  industry?: string;
  business_scale?: number;
  pinpoint_application_id?: string | undefined;
}

export interface ICoreCompany extends ICoreCompanyBase {
  _id: string;
  created_at: Date;
  updated_at: Date;
  company_name: string;
  account_owner: ICoreUser | ICoreUserAccountOwner;
  phone?: string;
  email?: string;
  // status: ECompanyStatus;
  account_type?: string | unknown;
  address?: ICoreUserAddresses;
  connect_account?: string | unknown;
  pinpoint_application_id?: string | undefined;
}

export type ICoreCompanyTagify = ITagifyTag<ICoreCompany>;

export interface ICoreCompanyAccount extends ICoreCompany {
  customer_id: string | unknown;
}

export class ICoreCompanyParamsCreate
  implements
    Pick<
      ICoreCompanyBase,
      'company_name' | 'account_owner' | 'phone' | 'email' | 'address' | 'account_type' | 'year_established' | 'business_scale' | 'industry'
    >
{
  account_id?: never;
  account_owner: string;
  company_name: string;
  phone?: string;
  account_type: unknown;
  email?: string;
  address?: ICoreUserAddresses;
  connect_account?: string;
  /**
   * If true, will create a connect account for the company
   * @default false
   */
  create_connect_account?: boolean;
}

export class ICoreCompanyParamsSignUp
  implements
    Pick<
      ICoreCompanyBase,
      'company_name' | 'account_owner' | 'phone' | 'email' | 'address' | 'account_type' | 'year_established' | 'business_scale' | 'industry'
    >
{
  account_id?: never;
  account_owner: string;
  phone: string;
  company_name: string;
  account_type?: unknown;
  email?: string;
  address?: ICoreUserAddresses;
  connect_account?: string;
  /**
   * If true, will create a connect account for the company
   * @default false
   */
  create_connect_account?: boolean;
  year_established?: number | undefined;
  business_scale?: number | undefined;
  industry?: string | undefined;
}

export interface ICoreCompanyParamsCreateResults {
  company: ICoreCompany;
  user: ICoreUser;
}

export interface ICoreCompanyParamsSignUpResults {
  company: ICoreCompany;
  user: ICoreUser;
}

export interface ISecurityAccountGuard {
  account_id: string;
}

export class ICoreCompanyParamsUpdate
  implements
    Pick<
      Partial<ICoreCompanyBase>,
      | 'company_name'
      | 'account_owner'
      | 'phone'
      | 'email'
      | 'account_type'
      | 'business_scale'
      | 'year_established'
      | 'industry'
      | 'connect_account'
      | 'pinpoint_application_id'
    >,
    ISecurityAccountGuard
{
  /**
   * Id of the company to update data on
   */
  account_id: string;
  account_owner?: string;
  company_name?: string;
  phone?: string;
  // status?: ECompanyStatus;
  account_type?: string;
  email?: string | undefined;
  business_scale?: number | undefined;
  year_established?: number | undefined;
  industry?: string | undefined;
  connect_account?: string | unknown;
  pinpoint_application_id?: string | undefined;
}

export class ICoreCompanyAddressParamsUpdate implements Pick<Partial<ICoreCompanyBase>, '_id' | 'address'> {
  /**
   * Id of the company to update data on
   */
  _id: string;
  address?: ICoreUserAddresses;
}
export class ICoreCompanyAccountOwnerParamsUpdate implements Pick<Partial<ICoreCompanyBase>, '_id' | 'account_owner'> {
  /**
   * Id of the company to update data on
   */
  _id: string;
  account_owner?: string;
}

/**
 * SEARCH QUERY
 *
 * These are the properties you can use to search for data
 *
 * @param search is a string to query the `rating_factor` property in the database
 * @param active will filter for true/false values on the `active` property
 */
export interface ICoreCompanySearchQuery {
  search?: string;
  account_owner?: string[];
  created_at?: ILaunchpointSearchFilterDates;
  account_type?: string[];
}

/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */
export interface ICoreCompanySearchPayload {
  query: ICoreCompanySearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

/**
 * SEARCH RESULTS
 *
 * This is the payload for the search query results.
 */
export interface ICoreCompanySearchResults {
  pagination: IQueryPageination;
  data: ICoreCompany[];
}

export interface ICoreUserAccountBase {
  /**
   * Mongo's ID for the array item generated on create
   */
  _id?: string;

  /**
   * The display name for this account
   */
  name?: string;

  user_id?: string | unknown;

  /**
   * Id of the account, related to the account type. This allows this id to exists from multiple collections.
   * The account type is what tells you what collection the document is in.
   */
  account_id?: string | unknown;
  account_type: string | unknown;

  /**
   * For now just 'Active' or 'Pending Invitation'
   */
  status?: ECoreUserAccountStatus;
  /**
   * A string array list of security roles the user has for this account
   */
  security_roles: string[];
  /**
   * This allows the user to have more than one account and labels which one is either default or selected.
   * When they login this is the one they will be directed to first.
   */
  selected: boolean;

  invited?: ECoreUserAccountInvited;
  /**
   * If the user has a connect account, this is the id of that account
   */
  connect_account?: string;
}

export interface ICoreUserAccount extends ICoreUserAccountBase {
  account_id: ICoreCompany | unknown;
  user_id?: ICoreUserAccountUserIDPopulated | unknown;
}
export interface ICoreUserAccountUserIDPopulated {
  _id: string;
}
export enum ECoreUserAccountStatus {
  ACTIVE = 'Active',
  PENDING_INVITATION = 'Pending Invitation',
  REJECTED = 'Rejected',
}

export enum ECoreUserAccountInvited {
  SENT = 'sent',
  ACCEPTED = 'accepted',
}

/**
 * @example
 *
 * `const exampleObj: IUserAccountsParamsCreate = {
account: {
  account_id: '',
  account_type: EUserAccountTypes.ADMIN,
  security_roles:[ ''],
  selected: false
},
user_id: ''
}`
 *
 */
export class IUserAccountsParamsCreate {
  account: Pick<ICoreUserAccountBase, 'name' | 'account_id' | 'account_type' | 'security_roles' | 'selected'>;
  user_id: string;
}

export class IUserAccountsParamsInvite {
  account: Pick<ICoreUserAccountBase, 'account_id' | 'account_type' | 'security_roles'>;
  email: string;
}

export class IUserAccountsParamsUpdate {
  account: Pick<ICoreUserAccountBase, '_id' | 'account_id' | 'account_type' | 'security_roles' | 'selected'>;
  user_id: string;
}

export class IUserAccountsParamsSet {
  account_id: string;
  user_id: string;
}

export class IUserAccountsParamsDelete {
  account: Pick<ICoreUserAccountBase, '_id' | 'account_id'>;
  user_id: string;
}

export type IUserCompanyAccountsDetail = ICoreUserAccount;
// export interface IUserCompanyAccountsDetail extends IUserAccounts {
//   account: ICoreCompanyAccount | ICoreCompany
// }

export interface ICoreCreatedAtAnalytics {
  last: number;
  current: number;
  change: number;
}
