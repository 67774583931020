import { Observable } from 'rxjs';
import { ILaunchpointDynamicFormResponse } from '../response.interface';
import { IsArray, ArrayNotEmpty, IsString, IsNotEmpty, ValidateNested, IsEnum, IsOptional, IsNumber } from 'class-validator';
import { Type as TypeTransformer } from 'class-transformer';

/**
 * Represents an individual response to a form question.
 */
export class ILaunchpointDynamicFormResponseResponses {
  _id?: string;
  /** The ID of the question being answered. */
  question_id?: string;
  /** The ID of the computed field being answered. */
  computed_field_id?: string;
  /** The respondent's answer. */
  answer_value: any;
}

export class ILaunchpointDynamicFormResponseResponsesParamsCreateOne implements Partial<ILaunchpointDynamicFormResponseResponses> {
  _id?: never;

  @IsString({ message: 'question_id must be a string' })
  question_id: string;

  answer_value: any;
}

export class ILaunchpointDynamicFormResponseResponsesParamsUpdateOne implements Partial<ILaunchpointDynamicFormResponseResponses> {
  @IsString({ message: '_id must be a string' })
  _id!: string;

  /**
   * Leaving this for now but I don't think they should be able to update the question or computed field
   */
  // @IsString({ message: 'question_id must be a string' })
  // question_id?: string;
  // @IsString({ message: 'computed_field_id must be a string' })
  // computed_field_id?: string;
  answer_value: any;
}

export class ILaunchpointDynamicFormResponseResponsesParamsCreate implements Pick<ILaunchpointDynamicFormResponse, '_id'> {
  @IsString({ message: '_id must be a string' })
  _id!: string;

  @IsArray({ message: 'responses must be an array' })
  @ArrayNotEmpty({ message: 'responses array cannot be empty' })
  @ValidateNested({
    each: true,
    message: 'Each responses must be a valid ILaunchpointDynamicFormResponseResponsesParamsCreateOne',
  })
  @TypeTransformer(() => ILaunchpointDynamicFormResponseResponsesParamsCreateOne)
  responses!: ILaunchpointDynamicFormResponseResponsesParamsCreateOne[];

  options?: {
    upsert?: boolean;
  };
}

export class ILaunchpointDynamicFormResponseResponsesParamsReorder {
  /**
   * _id of the rate schedule you want to add this deductible to.
   */
  _id: string;
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormResponseResponses)
  response: ILaunchpointDynamicFormResponseResponses;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  previousIndex: number;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  currentIndex: number;
}

export class ILaunchpointDynamicFormResponseResponsesParamsUpdate implements Pick<ILaunchpointDynamicFormResponse, '_id'> {
  @IsString({ message: '_id must be a string' })
  _id!: string;
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormResponseResponsesParamsUpdateOne)
  response!: ILaunchpointDynamicFormResponseResponsesParamsUpdateOne;
}

export class ILaunchpointDynamicFormResponseResponsesParamsDelete implements Pick<ILaunchpointDynamicFormResponse, '_id'> {
  @IsString({ message: '_id must be a string' })
  _id!: string;
  @IsArray({ message: 'response_ids must be an array' })
  @ArrayNotEmpty({ message: 'response_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each response_id in the array must be a string' })
  response_ids!: string[];
}
