import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LaunchpointCoreClientUpdateEmailPasswordSmartAdminBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-core-web-profile-update-email-password-smart-admin',
  templateUrl: './update-email-password-smart-admin.component.html',
  styleUrls: ['./update-email-password-smart-admin.component.scss'],
})
export class LaunchpointCoreWebProfileUpdateEmailPasswordSmartAdminComponent extends LaunchpointCoreClientUpdateEmailPasswordSmartAdminBaseComponent {
  constructor(public fb: FormBuilder, _Store: Store) {
    super(_Store);
  }
}
