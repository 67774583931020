/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { IContentUploadQueryParams, IMedia, IMediaCategory } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class LaunchpointCoreWebMediaUploadComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  @Input() media: IMedia = null;
  @Input() multiple = 'true'; // has to be a string for the ngf module
  /**
   * 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
   */
  @Input() accept = '*';
  @Input() description = 'Choose a file or drag it here';
  @Input() options?: IContentUploadQueryParams;
  // TODO: add category search ahead to media uploader so that don't have to hard code the category id in
  @Input() category?: IMediaCategory = null;

  files: File[] = [];
  loading = true;
  confirmation = false;
  mediaLoading = false;

  uploadPercentage = 0;
  confirm: string;
  cancel: string;
  noText: string;
  issueLast: boolean;

  imageChangedEvent: any = '';
  imageFileChanged: any = null;
  croppedImage: any = '';
  ratio: any = '1 / 1';

  dragFiles: any;
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  maxSize: any;
  baseDropValid: any;

  selectedItem: any;
  // url = `${location.origin}/api/media/content/upload/`;
  body: any;
  filesAddedArray: File[] = [];
  test: string = null;

  @Output() uploaded = new EventEmitter<IMedia>();
  @Output() filesAdded = new EventEmitter<File[]>();
  @Output() fileAdded = new EventEmitter<File>();

  ngOnInit() {
    if (this.media) {
      this.loading = false;
    }
  }

  fileChangeEvent(files: File | File[]) {
    // console.log('fileChangeEvent', files);
    if (this.multiple === 'true') {
      this.filesAdded.emit(files as File[]);
    } else {
      this.fileAdded.emit(files[0] as File);
    }
    this.filesAddedArray = [];
  }

  selectedConfirmation() {
    this.confirmation = true;
  }

  cancelModal() {
    this.confirmation = false;
  }

  handleError(error) {
    if (error) {
      console.log(error);
      this.uploadPercentage = null;
      this.loading = false;
      return;
    }
  }
}
