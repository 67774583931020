import { IMedia } from '../media.interface';

export class IMediaParamsDownload {
  _id: string;
  Bucket: string;
  Key: string;
}

export class IMediaDownloadResponse {
  url: string;
  media: IMedia;
}
