export enum ELaunchpointGeo {
  POINT = 'Point',
  LINE_STRING = 'LineString',
  POLYGON = 'Polygon',
  MULTI_POINT = 'MultiPoint',
}
export interface PropertyFeature {
  featureAreaSqFt: string;
  areaIndicator: string;
  description: string;
}

export interface Owner {
  ownerId: string;
  firstName: string;
  lastName: string;
}

export interface PropertyAttributesValue {
  code: string;
  value: string;
}

export interface PropertyAttributes {
  buildgSqFt: string;
  geometry: {
    type: string;
    coordinates: [number, number];
  };
  roofCoverType: PropertyAttributesValue;
  builtYear: string;
  bedrooms: string;
  baths: string;
  partialBaths: string;
  mobileHome: string;
  marketValue: string;
  salesDate: string;
  livingSqFt: string;
  buildgFeaturesSqFt: PropertyFeature[];
  owners: Owner[];
  exteriorWalls: PropertyAttributesValue;
  garageType: PropertyAttributesValue;
  lastUpdated: string;
  parkingSqFt: string;
  ownerType: string;
}

export interface MatchedAddress {
  formattedAddress: string;
  mainAddressLine: string;
  addressLastLine: string;
  areaName1: string;
  areaName2: string;
  areaName3: string;
  postCode: string;
  postCodeExt: string;
  country: string;
  addressNumber: string;
  streetName: string;
  unitType: string;
  unitValue: string;
}

export interface PropertyData {
  propertyAttributes: PropertyAttributes;
  matchedAddress: MatchedAddress;
}

export interface PropertyDataRes {
  propertyAttributes: PropertyAttributes;
  matchedAddress: MatchedAddress;
  quote: number;
}
