import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ICoreCompanyParamsUpdate, ICoreUserAccount } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';

@Directive()
export abstract class CompanyUpdateUserBaseComponent extends LaunchpointCoreClientBaseComponent {
  selectedAccount$: Observable<ICoreUserAccount>;

  constructor(public _Store: Store) {
    super();
  }

  updateCompanyData(data: ICoreCompanyParamsUpdate) {
    // this._Store.dispatch(updateUserCompany({ data }));
    console.log({ data, action: 'Needs to be wired up to an action' });
  }
}
