import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationMessageHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebNotificationMessagesTagifyBaseWebComponent } from '../notifications-messages-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [NotificationMessageHTTPService],
  selector: 'launchpoint-notifications-messages-tagify',
  templateUrl: 'notifications-messages-tagify.component.html',
})
export class LaunchpointCoreWebNotificationMessagesTagifyWebComponent extends LaunchpointCoreWebNotificationMessagesTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
