import { LaunchpointCoreConfig } from '../../../user/core/core-profile/interfaces/core-profile.interface';

export const COMPANY_CONFIG: LaunchpointCoreConfig = {
  default_image: './assets/media/avatars/blank.png',
  // icon: 'fa fa-info-circle',
  // icon_svg: './assets/media/icons/duotune/general/gen026.svg',
  // badge: 'Upgrade To Pro',
  buttons: null,
  menu_items: [
    {
      page: 'overview',
      title: 'Overview',
    },
    {
      page: 'users',
      title: 'Users',
    },
    // {
    //   page: 'connections',
    //   title: 'Connections',
    // },
    {
      page: 'products',
      title: 'Products',
    },
    {
      page: 'billing',
      title: 'Payments / Billing',
    },
    {
      page: 'settings',
      title: 'Settings',
    },
  ],
  //   profile_completion: {
  //     title: 'Profile Completion',
  //     value: 0,
  //   },
  // profile_blocks: [
  // ],
};
