import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { configureURL, ICoreUser, ICoreUserProfileController, ICoreUserProfileParamsUpdate } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../../security/header-services/user-id-security-header.service';
import { ICoreUserProfileConfigRoutes } from '../interfaces';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../interfaces/core-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class CoreProfileHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreUserProfileController {
  /**
   * Only works for web.
   */
  API_URL = 'api/backend/';

  routes: ICoreUserProfileConfigRoutes = {
    base: 'user/profile',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientProfileConfig?.base_url,
      version: 1,
      route: this.routes.base,
    });
  }
  updateProfile(body: ICoreUserProfileParamsUpdate): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL, body, { headers });
      })
    );
  }
}
