import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientUserNotificationsFilterBaseComponent, updateAllMessagesQuery } from '@launchpoint/core-client';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { IBooleanFilter } from '../../../../../components/boolean-filter/boolean-filter.component';

@Component({
  selector: 'launchpoint-notifications-user-notifications-filter',
  templateUrl: './notifications-user-notifications-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebUserNotificationsFilterComponent extends LaunchpointCoreClientUserNotificationsFilterBaseComponent {
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  options: IBooleanFilter = {
    allLabel: 'Both',
    falseLabel: 'No',
    trueLabel: 'Yes',
  };

  clearEvents() {
    this.form.controls.events.setValue([]);
    this.tagifyService.get('events').removeAllTags();
  }
  clearUsers() {
    this.form.controls.users.setValue([]);
    this.tagifyService.get('users').removeAllTags();
  }
  clearNotifications() {
    this.form.controls.notifications.setValue([]);
    this.tagifyService.get('notifications').removeAllTags();
  }
  clearMessages() {
    this.form.controls.messages.setValue([]);
    this.tagifyService.get('messages').removeAllTags();
  }

  override reset() {
    this.form.reset();
    this.tagifyService.get('events').removeAllTags();
    this.tagifyService.get('notifications').removeAllTags();
    this.tagifyService.get('users').removeAllTags();
    this.tagifyService.get('messages').removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    this._Store.dispatch(updateAllMessagesQuery({ query: {} }));
  }
}
