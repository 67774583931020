import { Directive, inject, Input } from '@angular/core';
import { IHttpException, IQueryPageination, IQuerySort, IUserNotificationSearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { UserNotificationEntityState } from './interfaces/user-notification-entity.interface';
import {
  selectSelectedUserNotificationUnread,
  selectUserNotifications,
  selectUserNotificationsError,
  selectUserNotificationsLoading,
  selectUserNotificationsPagination,
  selectUserNotificationsQuery,
  selectUserNotificationsQuerySort,
  selectUserNotificationsSuccess,
} from './selectors/user-notification.selectors';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../../../interfaces/core-notifications.interface';
import { updateAllUserNotificationPagination } from './actions/user-notification.actions';

@Directive()
export abstract class LaunchpointCoreClientNotificationsUserNotificationStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public _Store = inject(Store);
  public _config: LaunchpointCoreClientNotificationConfig = inject(NOTIFICATION_CONFIG_TOKEN);

  @Input() query: IUserNotificationSearchQuery = {};

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<IUserNotificationSearchQuery>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  userNotificationEntity$: Observable<UserNotificationEntityState[]>;
  userNotificationEntityError$: Observable<IHttpException | null>;
  userNotificationEntitySuccess$: Observable<string | null>;
  userNotificationEntityLoading$: Observable<boolean>;
  userNotificationEntityUnread$: Observable<number>;

  constructor() {
    super();

    this.pagination$ = this._Store.select(selectUserNotificationsPagination);
    this.loading$ = this._Store.select(selectUserNotificationsLoading);
    this.loaded$ = this._Store.select(selectUserNotificationsLoading);
    this.querySort$ = this._Store.select(selectUserNotificationsQuerySort);
    this.query$ = this._Store.select(selectUserNotificationsQuery);
    this.error$ = this._Store.select(selectUserNotificationsError);
    this.success$ = this._Store.select(selectUserNotificationsSuccess);
    this.userNotificationEntity$ = this._Store.select(selectUserNotifications);
    this.userNotificationEntityError$ = this._Store.select(selectUserNotificationsError);
    this.userNotificationEntitySuccess$ = this._Store.select(selectUserNotificationsSuccess);
    this.userNotificationEntityLoading$ = this._Store.select(selectUserNotificationsLoading);
    this.userNotificationEntityUnread$ = this._Store.select(selectSelectedUserNotificationUnread);
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      updateAllUserNotificationPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllUserNotificationPagination({ pagination: { ...pagination, limit } }));
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
    this.userNotificationEntitySuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
    this.userNotificationEntityError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
  }
  toast(params: { icon; title }) {
    // extend
  }
}
