// ADD COMPANY TO A USER (CREATE)

import { ICoreUser, IHttpException, IUserAccountsParamsCreate, IUserAccountsParamsDelete } from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

export const createCompanyOnUser = createAction('[SLIS-CA-Users] createCompanyOnUser', props<{ data: IUserAccountsParamsCreate }>());
export const createCompanyOnUserSuccess = createAction(
  '[SLIS-CA-Users] createCompanyOnUserSuccess',
  props<{ company_id: string; user: ICoreUser; success_message?: string }>()
);
export const createCompanyOnUserFailure = createAction('[SLIS-CA-Users] createCompanyOnUserFailure', props<{ error: IHttpException }>());

export const deleteCompanyOnUser = createAction('[SLIS-CA-Users] deleteCompanyOnUser', props<{ data: IUserAccountsParamsDelete }>());
export const deleteCompanyOnUserSuccess = createAction(
  '[SLIS-CA-Users] deleteCompanyOnUserSuccess',
  props<{ company_id: string; user: ICoreUser; success_message?: string }>()
);
export const deleteCompanyOnUserFailure = createAction('[SLIS-CA-Users] deleteCompanyOnUserFailure', props<{ error: IHttpException }>());
