import { Directive, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ELaunchpointUserRoles, ENotificationChannelType, ILaunchpointSearchFilterDates, INotificationSearchQuery } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { updateAllNotificationsQuery } from '../+state/actions/notifications.actions';
import { LaunchpointCoreClientNotificationsBaseComponent } from '../notifications-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsFilterBaseComponent
  extends LaunchpointCoreClientNotificationsBaseComponent
  implements OnInit
{
  public fb = inject(UntypedFormBuilder);
  tagifyService = inject(TagifyService);

  form: FormGroup<{
    events: FormControl<{ value: string; data: string }[]>;
    security_roles: FormControl<{ value: string; data: string }[]>;
    channels: FormControl<{ value: ENotificationChannelType; data: ENotificationChannelType }[]>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
  }>;

  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistSecurityRoles$ = new BehaviorSubject<ELaunchpointUserRoles[]>(Object.values(ELaunchpointUserRoles));
  whitelistChannels$ = new BehaviorSubject<ENotificationChannelType[]>(Object.values(ENotificationChannelType));
  whiteListEvents$ = new BehaviorSubject<string[]>(this._config?.events ?? []);

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      events: [],
      security_roles: [],
      channels: [],
      created_at: [],
    });
  }

  reset() {
    this.form.reset();
  }

  submit() {
    const data = this.form.value;

    const query: INotificationSearchQuery = {
      channels: data?.channels?.map((t) => t?.value),
      created_at: data?.created_at,
      events: data?.events?.map((t) => t?.value),
      security_roles: data?.security_roles?.map((t) => t?.value),
    };

    this.removeNullUndefined(query);

    this._Store.dispatch(updateAllNotificationsQuery({ query }));
  }
}
