import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { IAuthenticationResponse } from '@launchpoint/core-types';
import { Observable } from 'rxjs';

export interface ILaunchpointAuthInterceptor {
  /**
   *
   * Getting for the tokens in the local store
   *
   * get tokens() {
   * return this._AuthStorageService.tokens;
   * }
   */
  tokens: IAuthenticationResponse;

  /**
   * This method will catch a 401 or other errors by status and attempt methods to resolve or notify the user.
   *
   * Currently used for auth/refresh token to auto refresh
   * @param err
   * @param req
   * @param next
   */
  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>;
  /**
   *
   * @param err
   * Should remove the tokens from the local store and then log out the user
   * this._AuthStorageService.removeTokens();
   * this._Store.dispatch(UserAuthActions.logout({ path: ['auth', 'login'] }));
   * @returns
   */
  addAuthenticationToken(err: HttpRequest<any>): Observable<never>;
}
