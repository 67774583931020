<div class="post d-flex flex-column-fluid">
  <!--begin::Container-->
  <div class="container-xxl">
    <div class="card">
      <!--begin::Card body-->
      <div class="card-body">
        <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
          <div
            class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
            <div class="px-6 px-lg-10 px-xxl-15 py-20">
              <div class="stepper-nav">
                <div class="stepper-item" *ngFor="let item of steps; let i = index" [ngClass]="{
                    current: currentStep$() === i + 1
                  }">
                  <!-- <div class="stepper-item" [ngClass]="{
                    current: currentStep$() === 1,
                    completed: currentStep$() > 1
                  }"> -->
                  <div class="stepper-wrapper cursor-pointer" (click)="_stepper.setStep(i + 1)">
                    <!--begin::Icon-->
                    <div class="stepper-icon w-40px h-40px">
                      <i class="fs-2 stepper-check"></i>
                      <span class="stepper-number">{{i + 1}}</span>
                    </div>
                    <!--end::Icon-->

                    <!--begin::Label-->
                    <div class="stepper-label">
                      <h3 class="stepper-title">{{item.title}}</h3>

                      <div class="stepper-desc fw-semibold">{{item?.description}}</div>
                    </div>
                    <!--end::Label-->
                  </div>
                  <!--end::Wrapper-->
                  <!--begin::Line-->
                  <div class="stepper-line h-40px" *ngIf="i + 1 < totalSteps"></div>
                </div>

              </div>
            </div>
          </div>

          <div class="d-flex flex-row-fluid flex-center bg-white rounded">
            <form class="py-20 w-100 w-xl-700px px-9" *ngIf="user_id$ | async as user_id">
              <div class="current">
                <ng-container *ngIf="currentStep$() === 1">
                  <launchpoint-sign-up-user-profile></launchpoint-sign-up-user-profile>
                </ng-container>

                <ng-container *ngIf="currentStep$() === 2">

                  <launchpoint-company-create-smart-component></launchpoint-company-create-smart-component>

                </ng-container>

                <ng-container *ngIf="currentStep$() === 3">
                  {{ currentStep$() }}
                  {{ totalSteps }}
                  <!-- <app-step3
            class="w-100"
            [updateParentModel]="updateAccount"
            [defaultValues]="account$.value"
          ></app-step3> -->
                </ng-container>

                <ng-container *ngIf="currentStep$() === 4">
                  {{ currentStep$() }}
                  {{ totalSteps }}
                  <!-- <app-step4
            class="w-100"
            [updateParentModel]="updateAccount"
            [defaultValues]="account$.value"
          ></app-step4> -->
                </ng-container>

                <ng-container *ngIf="currentStep$() === 5">
                  {{ currentStep$() }}
                  {{ totalSteps }}
                  <!-- <app-step5 class="w-100"></app-step5> -->
                </ng-container>
              </div>

              <!-- <div class="d-flex flex-stack pt-10">
                <div class="mr-2">
                  <ng-container *ngIf="currentStep$() !== 1">
                    <button type="button" class="btn btn-lg btn-light-primary me-3" (click)="_stepper.prevStep()">
                      <span class="svg-icon svg-icon-4 me-1"></span>
                      Back
                    </button>
                  </ng-container>
                </div>
                <button type="button" class="btn btn-lg btn-primary me-3" *ngIf="currentStep$() < totalSteps "
                  (click)="_stepper.nextStep()">
                  Next
                </button>
                <button type="button" class="btn btn-lg btn-primary me-3" *ngIf="currentStep$() === totalSteps "
                  (click)="submit()">
                  Submit
                </button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
      <!--end::Card body-->
    </div>
  </div>
  <!--end::Container-->
</div>