<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">
    <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-uppercase fs-7 text-gray-700">
          <th class="min-w-150px">Url</th>
          <th class="min-w-150px">Events</th>
          <th class="max-w-50px">Enabled</th>
          <th class="min-w-120px">Mode</th>
          <th class="min-w-120px">Created</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">
        <tr *ngIf="(loading$ | async) === false && (webhooksEntities$ | async)?.length === 0">
          <td>No Webhooks</td>
        </tr>

        <tr *ngFor="let entity of webhooksEntities$ | async" [routerLink]="'./' + entity?.webhook_id" routerLinkActive="active">
          <ng-container *ngIf="entity">
            <td>
              <a [routerLink]="'./' + entity?.webhook_id" routerLinkActive="active" class="text-dark text-hover-primary">
                {{ entity?.webhook?.url }}
              </a>
            </td>

            <td>
              {{ entity?.webhook?.events?.length ?? 0 }}
            </td>

            <td>
              <i class="bi bi-check-circle text-success" *ngIf="entity?.webhook?.enabled"></i>
              <i class="bi bi-x-circle text-danger" *ngIf="!entity?.webhook?.enabled"></i>
            </td>

            <td>
              {{ entity?.webhook?.mode | titlecase }}
            </td>

            <td>
              {{ entity?.webhook?.created_at | date }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
  </div>
</div>
