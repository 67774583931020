<div class="card">
  <div class="card-body">
    <div class="table-responsive" [style.display]="(loading$ | async) ? 'block' : ''">
      <table class="table-row-dashed table-hover fs-6 gy-5 table align-middle">
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label m-5" [style.display]="'block'">
            Please wait...
            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </ng-container>

        <thead>
          <tr class="fw-bold fs-7 text-uppercase gs-0 text-gray-700">
            <th class="min-w-100px" *ngIf="showUser">User</th>
            <th class="min-w-100px">Title</th>
            <th class="min-w-100px">Body</th>
            <th class="min-w-50px">Event</th>
            <th class="min-w-50px">Topic</th>
            <!-- <th class="max-w-50px">Read</th>
            <th class="max-w-50px">Delivered</th> -->
            <th class="min-w-50px text-end">Created</th>
          </tr>
        </thead>

        <tbody class="fw-semibold fs-6">
          <tr *ngIf="(loading$ | async) === false && (userNotificationEntity$ | async)?.length === 0">
            <td>No User Notifications</td>
          </tr>

          <tr *ngFor="let entity of userNotificationEntity$ | async">
            <td *ngIf="showUser">
              {{ entity?.user_notification?.user?.email }}
            </td>
            <td>
              {{ entity?.user_notification?.title }}
            </td>
            <td>
              {{ entity?.user_notification?.body | slice: 0 : 30 }}
            </td>
            <td>
              {{ entity?.user_notification?.notification?.event }}
            </td>
            <td>
              {{ entity?.user_notification?.notification?.topic?.topic }}
            </td>

            <!-- todo! -->
            <!-- <td>
              <i class="bi bi-check-circle text-success" *ngIf="entity?.user_notification?.is_read"></i>
              <i class="bi bi-x-circle text-danger" *ngIf="!entity?.user_notification?.is_read"></i>
            </td>
            <td>
              <i class="bi bi-check-circle text-success" *ngIf="entity?.user_notification?.delivered"></i>
              <i class="bi bi-x-circle text-danger" *ngIf="!entity?.user_notification?.delivered"></i>
            </td> -->
            <td class="text-end">
              {{ entity?.user_notification?.created_at | date }}
            </td>
          </tr>
        </tbody>
      </table>

      <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
    </div>
  </div>
</div>
