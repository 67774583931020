import { PinpointApplicationHTTPService } from './pinpoint-application-http.service';
import { PinpointCampaignsHTTPService } from './pinpoint-campaigns-http.service';
import { PinpointJourneysHTTPService } from './pinpoint-journeys-http.service';
import { PinpointSegmentsHTTPService } from './pinpoint-segments-http.service';

export const PINPOINT_SERVICES = [
  //
  PinpointApplicationHTTPService,
  PinpointCampaignsHTTPService,
  PinpointJourneysHTTPService,
  PinpointSegmentsHTTPService,
];

export * from './pinpoint-application-http.service';
export * from './pinpoint-campaigns-http.service';
export * from './pinpoint-journeys-http.service';
export * from './pinpoint-segments-http.service';
