import { Component, inject, Input } from '@angular/core';
import { LaunchpointCoreClientWebhookEventsBaseComponent } from '@launchpoint/core-client';
import { ICoreActivityHistoryWebhookEvent } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IEllipsisMenuOption } from '../../../../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-webhook-event',
  templateUrl: 'webhook-event.component.html',
})
export class LaunchpointCoreWebWebhookEventDetailComponent extends LaunchpointCoreClientWebhookEventsBaseComponent {
  _swalService = inject(LaunchpointSweetAlertService);
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  @Input() event: ICoreActivityHistoryWebhookEvent;

  getOptions(event: ICoreActivityHistoryWebhookEvent): IEllipsisMenuOption[] {
    const options = [
      {
        label: 'Delete',
        action: () => {
          this.deleteWebhookAlert(event._id);
        },
      },
    ];

    return options;
  }

  deleteWebhookAlert(event_id: string): void {
    this._swalService
      .fireAlert({
        title: 'Are you sure you want to delete this webhook event?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.deleteWebhookEvent(event_id);
        }
      });
  }
}
