import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  COMPANY_EFFECTS,
  COMPANY_SERVICES,
  LaunchpointCompanyFeatureKey,
  LaunchpointCompanyReducer,
  LibCoreClientPipesModule,
} from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebEllipsisMenuComponent } from '../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebAddressAutoCompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { LaunchpointCoreWebUserSearchAheadComponent } from '../users/user-search-ahead-standalone/user-search-ahead.component';
import { LaunchpointCoreWebUsersModule } from '../users/users-core.module';
import { LaunchpointCoreWebUsersTagifyComponent } from '../users/users-tagify/users-tagify.component';
import { COMPANY_OVERVIEW_COMPONENTS } from './components';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule];

const IMPORTS = [LaunchpointCoreWebAddressAutoCompleteComponent];
@NgModule({
  imports: [
    ...MODULES,
    ...IMPORTS,
    LibCoreClientPipesModule,
    TagifyModule,
    LaunchpointCoreWebUsersTagifyComponent,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointCoreWebUserSearchAheadComponent,
    LaunchpointCoreWebUsersModule,
    LaunchpointFormControlDateRangeComponent,
    LaunchpointCoreWebEllipsisMenuComponent,
    LaunchpointCoreWebPaginationComponent,
    StoreModule.forFeature(LaunchpointCompanyFeatureKey, LaunchpointCompanyReducer),
    EffectsModule.forFeature(COMPANY_EFFECTS),
  ],

  exports: [...COMPANY_OVERVIEW_COMPONENTS],
  declarations: [...COMPANY_OVERVIEW_COMPONENTS],
  providers: [...COMPANY_SERVICES, LaunchpointFormControlDateRangeComponent, LaunchpointCoreWebToolbarComponent],
})
export class LaunchpointCompanyWebModule {}
