<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">
    <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-uppercase fs-7 text-gray-700">
          <th class="min-w-150px">User</th>
          <th class="min-w-150px">Event Name</th>
          <th class="min-w-150px">Status</th>
          <th class="min-w-120px">Method</th>
          <th class="min-w-120px">URL</th>
          <th class="min-w-120px">Created</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">
        <tr *ngIf="(loading$ | async) === false && (activityHistoryEntities$ | async)?.length === 0">
          <td>No Activity Histories</td>
        </tr>

        <tr *ngFor="let entity of activityHistoryEntities$ | async" [routerLink]="baseRoute + entity?.history_id" routerLinkActive="active">
          <ng-container *ngIf="entity">
            <td>
              <a [routerLink]="baseRoute + entity?.history_id" routerLinkActive="active" class="text-dark text-hover-primary">
                {{ entity?.activity_history?.user?.email ?? entity?.activity_history?.request?.email ?? 'N/A' }}
              </a>
            </td>

            <td>
              {{ entity?.activity_history?.event_name }}
            </td>

            <td>
              <span
                class="badge"
                [ngClass]="{
                  'badge-light-success': entity?.activity_history?.status < 400,
                  'badge-light-danger': entity?.activity_history?.status >= 400,
                }"
                [ngbTooltip]="getTooltip(entity?.activity_history?.status)"
                placement="right"
              >
                {{ entity?.activity_history?.status }}
              </span>
            </td>

            <td>
              {{ entity?.activity_history?.method }}
            </td>

            <td>
              {{ entity?.activity_history?.original_url }}
            </td>

            <td>
              {{ entity?.activity_history?.created_at | date: 'medium' }}
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
  </div>
</div>
