<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <launchpoint-toolbar [options]="options" (create)="upload()" (refresh)="refresh()">
      <launchpoint-media-filter-menu />
    </launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <launchpoint-media-table />
      </div>
    </div>
  </div>
</div>