import { Component, Input } from '@angular/core';

//* reusable component for displaying a user's initials inside a circle

@Component({
  selector: 'launchpoint-web-circle-initials',
  templateUrl: './circle-initials.component.html',
  styleUrls: ['./circle-initials.component.scss'],
})
// extends UserProfileIconBaseComponent
export class LaunchpointCircleInitialsComponent {
  @Input() first_initial: string;
  @Input() second_initial: string;
  @Input() background_class: string;
}
