import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientBaseComponent implements OnDestroy {
  private _destroy$?: Subject<unknown>;

  public maxUnixTimestamp = 2147483647; // max unix timestamp in seconds

  get destroy$() {
    if (!this._destroy$) {
      // Perf optimization:
      // since this is likely used as base component everywhere
      // only construct a Subject instance if actually used
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }

  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  public removeNullUndefined(obj, debug = false) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const element = obj[key];

        debug && console.log(key, typeof element, element);

        if (element instanceof Date) {
          debug && console.log(key, 'is a date');
          continue;
        }

        // If element is an object, recurse
        if (typeof element === 'object' && element !== null) {
          debug && console.log('recursing', key);
          this.removeNullUndefined(element);

          // If the object is now empty, delete it
          if (Object.keys(element).length === 0) {
            debug && console.log('deleting', key);
            delete obj[key];
          }
        } else if (element === null || element === undefined || element === '') {
          // Delete if the element is null, undefined, or an empty string
          debug && console.log(key, ' is null, undefined, or empty string');
          delete obj[key];
        }
      }
    }
  }
}
