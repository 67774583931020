import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ALERT_BANNER_CLIENT_CONFIG_TOKEN,
  ALERT_BANNER_EFFECTS,
  AlertBannerFeatureKey,
  CoreAlertBannerHTTPService,
  LaunchpointAlertBannerReducer,
  LaunchpointCoreClientAlertBannerConfig,
  LibCoreClientPipesModule,
} from '@launchpoint/core-client';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebEllipsisMenuComponent } from '../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { LaunchpointCoreWebAlertBannerModalComponent } from './alert-banner-modal/alert-banner-modal.component';
import { LaunchpointCoreWebAlertBannerTableFilterComponent } from './alert-banner-table-filter/alert-banner-table-filter.component';
import { LaunchpointCoreWebAlertBannerTableComponent } from './alert-banner-table/alert-banner-table.component';
import { LaunchpointCoreWebAlertBannerToolbarComponent } from './alert-banner-toolbar/alert-banner-toolbar.component';
import { LaunchpointCoreWebBooleanFilterComponent } from '../../components';

const ALERT_BANNER_COMPONENTS = [
  LaunchpointCoreWebAlertBannerToolbarComponent,
  LaunchpointCoreWebAlertBannerTableComponent,
  LaunchpointCoreWebAlertBannerTableFilterComponent,
  LaunchpointCoreWebAlertBannerModalComponent,
];

@NgModule({
  imports: [
    NgbModule,
    FormsModule,
    RouterModule,
    CommonModule,
    TagifyModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    // Components
    LibCoreClientPipesModule,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointFormControlDateRangeComponent,
    LaunchpointCoreWebEllipsisMenuComponent,
    LaunchpointCoreWebPaginationComponent,
    LaunchpointCoreWebBooleanFilterComponent,
    // State
    StoreModule.forFeature(AlertBannerFeatureKey, LaunchpointAlertBannerReducer),
    EffectsModule.forFeature(ALERT_BANNER_EFFECTS),
  ],
  exports: [...ALERT_BANNER_COMPONENTS],
  providers: [CoreAlertBannerHTTPService, LaunchpointFormControlDateRangeComponent],
  declarations: [...ALERT_BANNER_COMPONENTS],
})
export class LaunchpointCoreWebAlertBannerModule {
  static forRoot(config: LaunchpointCoreClientAlertBannerConfig): ModuleWithProviders<LaunchpointCoreWebAlertBannerModule> {
    return {
      ngModule: LaunchpointCoreWebAlertBannerModule,
      providers: [
        CoreAlertBannerHTTPService,
        LaunchpointFormControlDateRangeComponent,
        {
          provide: ALERT_BANNER_CLIENT_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
