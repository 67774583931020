// angular
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { environment } from '@launchpoint/xplat/core';
import { EStadnickDevPages } from './config/pages';

// app
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { BookStrategyCallComponent } from './pages/book-strategy-call/book-strategy-call.component';

const routes: Routes = [
  { path: EStadnickDevPages.HOME, component: HomeComponent, title: 'Rob Stadnick | Out Study. Out Work. Outlast-For Leaders Ready to Win.' },
  { path: EStadnickDevPages.BOOK_STRATEGY_CALL, component: BookStrategyCallComponent },
  // {
  //   path: EStadnickDevPages.BLOG,
  //   loadChildren: () => import('./pages/blog/blog.module').then((m) => m.BlogPageModule),
  // },
  {
    path: EStadnickDevPages.RESOURCES,
    loadChildren: () => import('./pages/resources/resources.module').then((m) => m.ResourcesPageModule),
  },
  // Here add new pages component
  { path: '**', component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

let routerOptions: ExtraOptions = {};

if (environment.environmentName === 'prod') {
  routerOptions = {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 90], // [x, y]
  };
}

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
