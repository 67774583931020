import { Observable } from 'rxjs';
import { ICoreUser } from '../../../user-company-account.interface';
import { ICoreUserAddressParamsCreate, ICoreUserAddressParamsUpdate } from './core-address.interface';
/**
 * @Controller('user/address')
 */
export interface ICoreUserAddressController {
  /**
   * Put()
   * @param body
   */
  update(body: ICoreUserAddressParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post()
   * @param body
   */
  create(body: ICoreUserAddressParamsCreate): Promise<ICoreUser> | Observable<ICoreUser>;
}
