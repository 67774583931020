import { Observable } from 'rxjs';
import {
  INotification,
  INotificationParamsCreate,
  INotificationParamsUpdate,
  INotificationSearchPayload,
  INotificationSearchResults,
} from './notifications.interface';

/**
 * Controller('notifications')
 */
export interface INotificationsController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<INotification> | Observable<INotification>;
  /**
   * Post('find-one')
   * @param params Partial<INotification>
   */
  findOne(params: Partial<INotification>): Promise<INotification> | Observable<INotification>;
  /**
   * Post('search')
   * @param body
   */
  search(body: INotificationSearchPayload): Promise<INotificationSearchResults> | Observable<INotificationSearchResults>;
  /**
   * Post()
   * @param body
   */
  create(body: INotificationParamsCreate): Promise<INotification> | Observable<INotification>;
  /**
   * Put()
   * @param body
   */
  update(body: INotificationParamsUpdate): Promise<INotification> | Observable<INotification>;
  /**
   * Delete(':id')
   * @param id
   */
  delete(id: string): Promise<INotification> | Observable<INotification>;
}
