import { ICoreActivityHistoryWebhookSearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as WebhookActions from '../actions/webhooks.actions';
import { WebhooksEntityState } from '../interfaces/webhooks.interface';

export const WebhooksFeatureKey = 'WebhooksReducer';

export interface StateWebhooksReducer extends EntityState<WebhooksEntityState> {
  selected_webhook_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreActivityHistoryWebhookSearchQuery;
}

export const WebhooksEntityAdaptor: EntityAdapter<WebhooksEntityState> = createEntityAdapter<WebhooksEntityState>({
  selectId: (data) => data.webhook_id,
});

export const initialWebhooksState: StateWebhooksReducer = WebhooksEntityAdaptor.getInitialState({
  // set initial required properties
  selected_webhook_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
  entities: {},
  ids: [],
});

export const LaunchpointWebhooksReducer = createReducer(
  initialWebhooksState,
  on(WebhookActions.searchAllWebhooks, (state) => {
    return WebhooksEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(WebhookActions.searchAllWebhooksSuccess, (state, { data }) => {
    const userEntities = data.data.map((data) => {
      const entity: WebhooksEntityState = {
        webhook_id: data._id,
        webhook: data,
        loaded: true,
        loading: false,
        error: null,
        // profile_config: USERS_PROFILE_CONFIG,
      };
      return entity;
    });
    return WebhooksEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(WebhookActions.searchAllWebhooksFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(WebhookActions.updateAllWebhooksQuery, (state, { query }) => {
    return WebhooksEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(WebhookActions.updateAllWebhooksPagination, (state, { pagination }) => {
    return WebhooksEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(WebhookActions.updateAllWebhooksPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(WebhookActions.updateAllWebhooksSorting, (state, { querySort }) => {
    return WebhooksEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(WebhookActions.getWebhookById, (state, { webhook_id }) => ({
    ...state,
    selected_webhook_id: webhook_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(WebhookActions.getWebhookByIdSuccess, (state, { data }) => {
    const entity: WebhooksEntityState = {
      webhook_id: data._id,
      webhook: data,
      loaded: true,
      loading: false,
      error: null,
      // profile_config: USERS_PROFILE_CONFIG,
    };
    return WebhooksEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_webhook_id: data._id,
    });
  }),
  on(WebhookActions.getWebhookByIdFailure, (state, { webhook_id, error }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: webhook_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(WebhookActions.createWebhook, (state, { data }) => ({
    ...state,
    loading: true,
    success_message: null,
    error: null,
  })),

  on(WebhookActions.createWebhookSuccess, (state, { data, success_message }) => {
    const entity: WebhooksEntityState = {
      webhook_id: data._id,
      webhook: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return WebhooksEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      selected_webhook_id: data._id,
    });
  }),

  on(WebhookActions.createWebhookFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),

  // Update

  on(WebhookActions.updateWebhook, (state, { data }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(WebhookActions.updateWebhookSuccess, (state, { data, success_message }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          webhook: data,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(WebhookActions.updateWebhookFailure, (state, { webhook_id, error }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: webhook_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(WebhookActions.deleteWebhook, (state, { webhook_id }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: webhook_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(WebhookActions.deleteWebhookSuccess, (state, { data, success_message }) => {
    return WebhooksEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(WebhookActions.deleteWebhookFailure, (state, { webhook_id, error }) => {
    return WebhooksEntityAdaptor.updateOne(
      {
        id: webhook_id,
        changes: {
          loading: false,
          loaded: true,
          error,
        },
      },
      {
        ...state,
      }
    );
  })
);
