<div class="app-container d-flex flex-stack my-6 container-xxl">

    <div>
        <div class="d-flex">
            <div class="page-title d-flex justify-content-start flex-wrap me-3">
                <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                    {{ options?.title }}
                </h1>
            </div>

            <div class="d-flex align-items-center position-relative my-1 mx-4" *ngIf="options?.formControl">
                <i class="bi bi-search fs-3 position-absolute ms-4"></i>
                <input type="text" class="form-control form-control-sm border-body bg-body w-250px ps-10 search-bar"
                    [placeholder]="options?.searchPlaceholder ?? 'Search'" [formControl]="options?.formControl" />
            </div>
        </div>

        <div class="fw-semibold fs-7 mt-1 text-gray-600" *ngIf="options?.description">
            {{ options?.description }}
        </div>
    </div>



    <div class="d-flex align-items-center gap-2 gap-lg-3 right-toolbar">
        <button type="button" class="btn btn-sm btn-icon" *ngIf="options?.showPivot ?? false"
            (click)="switch(options?.pivot)">
            <i class="bi p-0 cursor-pointer text-hover-primary" [ngClass]="{
            'bi-list': options?.pivot,
            'bi-grid': !options?.pivot
            }" [ngbTooltip]="options?.pivot ? 'Table' : 'Pivot'" style="font-size: 18px"></i>
        </button>

        <button type="button" class="btn btn-sm btn-icon" *ngIf="options?.create ?? true">
            <i class="bi bi-plus-circle p-0 cursor-pointer text-hover-primary" style="font-size: 18px"
                (click)="onCreate()" [ngbTooltip]="options?.createTooltip ?? 'Create'"></i>
        </button>

        <div class="m-0" *ngIf="options?.filter ?? true">
            <div ngbDropdown class="d-inline-block dropdown" #myDrop="ngbDropdown"
                [autoClose]="options?.autoClose ?? false" placement="bottom-end">
                <button type="button" class="btn btn-sm btn-primary text-white position-relative"
                    ngbDropdownToggle>Filter</button>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                    *ngIf="(options?.queryCount | async)">
                    {{ options?.queryCount | async }}
                </span>
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold"
                    ngbDropdownMenu aria-labelledby="filterDropdown">
                    <!-- begin :: filter component -->
                    <ng-content></ng-content>
                    <!-- end :: filter component -->
                </div>
            </div>
        </div>

        <ng-container *ngIf="ellipsisOptions?.length > 0">
            <launchpoint-ellipsis-menu [options]="ellipsisOptions" [tooltip]="options?.ellipsisTooltip" />
        </ng-container>
    </div>
</div>