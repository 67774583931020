import { Component } from '@angular/core';
import { LaunchpointCoreClientDeactivateAccountBaseComponent } from '@launchpoint/core-client';


@Component({
  selector: 'launchpoint-core-web-deactivate-account',
  templateUrl: './deactivate-account.component.html',
})
export class LaunchpointCoreWebDeactivateAccountComponent extends LaunchpointCoreClientDeactivateAccountBaseComponent {
  constructor() {
    super();
  }
}
