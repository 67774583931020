export enum ESecurityMediaResources {
  MEDIA_CREATE = 'media:create',
  MEDIA_READ = 'media:read',
  MEDIA_UPDATE = 'media:update',
  MEDIA_DELETE = 'media:delete',
  MEDIA_SEARCH = 'media:search',
  MEDIA_ARCHIVE = 'media:archive',
  MEDIA_CONVERT = 'media:convert',
  MEDIA_DOWNLOAD = 'media:download',
  //
  MEDIA_TAG_ADD = 'media:tag:add',
  MEDIA_TAG_REMOVE = 'media:tag:remove',
  MEDIA_TAG_SET = 'media:tag:set',
  MEDIA_TAG_READ = 'media:tag:read',
  //
  MEDIA_AUDIO_ADD = 'media:audio:add',
  MEDIA_AUDIO_REMOVE = 'media:audio:remove',
  //
  MEDIA_SUBTITLES_ADD = 'media:subtitles:add',
  MEDIA_SUBTITLES_REMOVE = 'media:subtitles:remove',
}
