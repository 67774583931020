import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { BackgroundColorModel, FontColorModel, ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'launchpoint-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogContentComponent extends LaunchpointCoreClientBlogBaseComponent {
  form: FormGroup<{
    content: FormControl<string>;
  }>;

  public tools: ToolbarSettingsModel = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'FontColor',
      'BackgroundColor',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      '|',
      'CreateLink',
      '|',
      'SourceCode',
      '|',
      'Undo',
      'Redo',
    ],
  };
  public bgColor: BackgroundColorModel = {
    modeSwitcher: true,
  };
  public fontColor: FontColorModel = {
    modeSwitcher: true,
  };

  // todo
  // public insertImageSettings: ImageSettingsModel = {
  //   saveUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Save',
  //   removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
  // };

  override buildForm(): void {
    this.form = this.fb.group({
      content: ['', Validators.required],
    });
  }

  override loadForm(): void {
    this.form.patchValue({
      content: this.blog.content,
    });
  }

  reset() {
    this.form.reset();
    this.loadForm();
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    this.updateBlog({
      _id: this.blog._id,
      content: form.content,
    });
  }
}
