import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LaunchpointCoreWebAuthV1Component } from './components/base/auth-v1.component';
import { LaunchpointCoreWebAuthV1LoginComponent } from './components/login/auth-v1-login.component';
import { LaunchpointCoreWebAuthV1LogoutComponent } from './components/logout/auth-v1-logout.component';
import { LaunchpointCoreWebAuthV1RegistrationComponent } from './components/registration/auth-v1-registration.component';
import { LaunchpointCoreWebAuthV1SetPasswordComponent } from './components/set-password/auth-v1-set-password.component';
import { LaunchpointCoreWebAuthV1VerifyComponent } from './components/verify/auth-v1-verify.component';

const routes: Routes = [
  {
    path: '',
    component: LaunchpointCoreWebAuthV1Component,
    children: [
      {
        path: '',
        redirectTo: 'registration',
        pathMatch: 'full',
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'login',
        component: LaunchpointCoreWebAuthV1LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'verify',
        component: LaunchpointCoreWebAuthV1VerifyComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'registration',
        component: LaunchpointCoreWebAuthV1RegistrationComponent,
      },
      // {
      //   path: 'forgot-password',
      //   component: LaunchpointForgotPasswordV1Component,
      // },
      {
        path: 'set-password',
        component: LaunchpointCoreWebAuthV1SetPasswordComponent,
        // canActivate: [ResetPasswordGuard],
      },
      {
        path: 'logout',
        component: LaunchpointCoreWebAuthV1LogoutComponent,
      },
      { path: '**', redirectTo: 'login', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingV1Module {}
