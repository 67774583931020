import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookEventSearchQuery,
  ICoreActivityHistoryWebhookSearchQuery,
  ICoreCompany,
  ICoreUser,
  ILaunchpointSearchFilterDates,
  IQueryPageination,
  IQuerySort,
  ITagifyTag,
} from '@launchpoint/core-types';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import {
  updateAllWebhookEventsPagination,
  updateAllWebhookEventsQuery,
  updateAllWebhookEventsSorting,
} from '../+state/actions/webhook-events.actions';
import { LaunchpointCoreClientWebhookEventsStateBaseComponent } from '../+state/webhook-events-state-base.component';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class LaunchpointCoreClientWebhookEventsBaseComponent extends LaunchpointCoreClientWebhookEventsStateBaseComponent implements OnInit {
  fb = inject(UntypedFormBuilder);
  activatedRoute = inject(ActivatedRoute);

  form_control = new FormControl<string>('');

  @Input() query: ICoreActivityHistoryWebhookEventSearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 25, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };
  @Input() admin = true;

  event: ICoreActivityHistoryWebhookEvent;
  @Output() selectedEvent = new EventEmitter<ICoreActivityHistoryWebhookEvent>();

  public form: FormGroup<{
    created_at: FormControl<ILaunchpointSearchFilterDates>;
    finished_at: FormControl<ILaunchpointSearchFilterDates>;
    processed_at: FormControl<ILaunchpointSearchFilterDates>;
    events: FormControl<ITagifyTag[]>;
  }>;

  users: ICoreUser[] = [];
  accounts: ICoreCompany[] = [];

  ngOnInit(): void {
    this.buildForm();
    this.getQuery();
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });

    this._Store.dispatch(updateAllWebhookEventsQuery({ query: this.query }));
  }

  async getQuery() {
    const { query } = await firstValueFrom(this.activatedRoute.data);
    if (query) {
      this.query = JSON.parse(query);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      created_at: [],
      finished_at: [],
      processed_at: [],
      events: [],
    });

    // console.log('this.form', this.form.value);
  }

  runQuery(search?: string) {
    const query: ICoreActivityHistoryWebhookEventSearchQuery = {
      ...this.query,
      search,
    };

    this._Store.dispatch(updateAllWebhookEventsQuery({ query }));
  }

  refresh() {
    this.ngOnInit();
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllWebhookEventsSorting({ querySort: sortDataEmitter }));
  }

  pageChange(event: { pageSize: number; pageIndex: number; previousPageIndex: number }) {
    this.pagination.limit = event.pageSize;
    this.pagination.skip = Number(event.pageIndex) * Number(event.pageSize);
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.previousPageIndex = event.previousPageIndex;
    this._Store.dispatch(updateAllWebhookEventsPagination({ pagination: this.pagination }));
  }

  setSelected(event: ICoreActivityHistoryWebhookEvent) {
    if (this.event?._id === event?._id) {
      this.event = null;
      this.selectedEvent.emit(null);
    } else {
      this.event = event;
      this.selectedEvent.emit(event);
    }
  }
}
