import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  LaunchpointCoreClientProfileConfig,
  LaunchpointCoreClientProfileHeaderCardComponentBaseComponent,
  PROFILE_CONFIG_TOKEN,
} from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-header-card',
  templateUrl: './profile-header-card.component.html',
  styleUrls: ['./profile-header-card.component.scss'],
})
export class LaunchpointCoreWebProfileHeaderCardComponent extends LaunchpointCoreClientProfileHeaderCardComponentBaseComponent {
  constructor(public fb: FormBuilder, @Inject(PROFILE_CONFIG_TOKEN) public _config: LaunchpointCoreClientProfileConfig) {
    super(fb, _config);
  }
}
