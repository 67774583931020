import { Observable } from 'rxjs';
import { ICoreActivityHistory, ICoreActivityHistorySearchPayload, ICoreActivityHistorySearchResults } from './activity-history.interface';

/**
 * @Controller('activity-history')
 */
export interface ICoreActivityHistoryController {
  /**
   * Post ('search')
   */
  search(params: ICoreActivityHistorySearchPayload): Promise<ICoreActivityHistorySearchResults> | Observable<ICoreActivityHistorySearchResults>;
  /**
   * Get (':id')
   */
  getById(id: string): Promise<ICoreActivityHistory> | Observable<ICoreActivityHistory>;
  /**
   * Put ('delete/:id')
   */
  delete(id: string): Promise<ICoreActivityHistory> | Observable<ICoreActivityHistory>;
}
