<div class="bg-[#E1E2DF] py-10 md:py-20">
  <div class="mx-auto max-w-[1700px] px-4">
    <div class="flex flex-col lg:flex-row">
      <div class="mb-10 inline-flex flex-col items-center space-y-8 lg:w-[25%] lg:items-start xl:w-[30%] 2xl:w-[35%]">
        <p class="font-oswald pr-0 text-[30px] font-semibold lg:pr-10 lg:text-[45px] lg:font-medium xl:pr-20 xl:text-[60px] 2xl:text-[70px]">
          UPCOMING EVENTS
        </p>
        <button
          class="font-courierNew bg-background hidden w-[150px] items-center justify-center gap-2 px-6 py-3 text-sm font-semibold text-white transition-colors duration-300 ease-in-out hover:bg-[#14141475] lg:flex"
        >
          View All <i class="fa-solid fa-arrow-right"></i>
        </button>
      </div>
      <div class="lg:w-[75%] xl:w-[70%] 2xl:w-[65%]">
        @if (loading() === true) {
          <div class="borer-white grid grid-cols-4 gap-10 border-t pb-5 pt-3 md:grid-cols-5">
            <div class="col-span-1">
              <div class="flex flex-row items-end">
                <div class="text-secondary text-[20px]">Loading...</div>
              </div>
            </div>
          </div>
        } @else if (!events()?.length && loading() === false) {
        } @else {
          @for (day of events(); track day?.event_setting_attributes.starts_at) {
            <div class="flex items-center justify-between border-t-[1px] border-black py-4">
              <div class="flex items-center gap-4">
                <p class="font-oswald text-[50px] font-[600] text-[#2F5142] md:w-28 lg:text-[80px] xl:text-[99px]">
                  {{ day.event_setting_attributes.starts_at | date: 'dd' }}
                </p>
                <p class="flex flex-col gap-1 text-[18px] font-[400] text-[#282828]">
                  {{ day.event_setting_attributes.starts_at | date: 'MMM' }}
                  <span>{{ day.event_setting_attributes.starts_at | date: 'yyyy' }} </span>
                </p>
                <p class="flex w-[130px] flex-col overflow-hidden leading-loose md:w-full md:leading-10 lg:pl-10 xl:pl-20">
                  <span
                    class="overflow-hidden text-ellipsis whitespace-nowrap text-[14px] font-[600] md:text-[18px] lg:text-[25px] xl:text-[30px] 2xl:text-[40px]"
                  >
                    {{ day.display_title }}
                  </span>
                  <span class="text-[12px] font-[400] text-[#28282850] md:text-[18px]">
                    @if (day.event_setting_attributes.hide_location_from_non_attendees === false) {
                      {{ location(day.event_setting_attributes.in_person_location)?.name }}
                    }
                  </span>
                </p>
              </div>
              <a href="https://community.launchpoint.dev/c/events/{{ day?.slug }}" target="_blank"
                ><button
                  class="font-courierNew hidden w-[180px] items-center justify-center gap-2 border-[1px] border-black px-6 py-3 text-black md:flex"
                >
                  View Event <i class="fa-solid fa-arrow-right"></i>
                </button>
                <div class="mr-4 block md:hidden"><i class="fa-solid fa-arrow-right"></i></div>
              </a>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
