<!--begin::Form-->
<div class="d-flex justify-content-between flex-column flex-lg-row-fluid" style="padding-top: 30%">
  <!--begin::Wrapper-->
  <div class="w-lg-500px p-10">
    <!--begin::Form-->
    <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form" (ngSubmit)="submit()">
      <!--begin::Heading-->
      <div class="text-center mb-11">
        <!--begin::Title-->
        <h1 class="text-dark fw-bolder mb-3">{{ _config?.registration?.title || 'Create an Account' }}</h1>
        <!--end::Title-->
        <!--begin::Subtitle-->
        <!-- <div class="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div> -->
        <!--end::Subtitle=-->
      </div>

      <ng-container *ngIf="error$ | async as error">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">{{ error?.error?.message }}</div>
        </div>
      </ng-container>

      <launchpoint-alert-banner-message-standalone [private]="false" cssClass="mb-15" />

      <!-- <ng-container *ngIf="success$ | async as success">
        <div class="mb-lg-15 alert alert-success">
          <div class="alert-text font-weight-bold">{{ success?.message }}</div>
        </div>
      </ng-container> -->

      <!--begin::Heading-->
      <!--begin::Login options-->
      <div class="row g-3 mb-9">
        <div class="col-md-6" *ngIf="_config?.third_party?.google.active">
          <a href="#"
            class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/google-icon.svg"
              class="h-15px me-3" />Sign up with Google</a>
        </div>
        <!-- <div class="col-md-6">
          <a href="#" class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/apple-black.svg" class="theme-light-show h-15px me-3" />
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/apple-black-dark.svg" class="theme-dark-show h-15px me-3" />Sign up
            with Magic</a
          >
        </div> -->
      </div>
      <!--end::Login options-->
      <!--begin::Separator-->
      <!-- <div class="separator separator-content my-14">
        <span class="w-125px text-gray-500 fw-semibold fs-7">With email</span>
      </div> -->
      <!--end::Separator-->
      <!--begin::Input group=-->
      <div class="fv-row mb-8 fv-plugins-icon-container">
        <div class="form-floating mb-4">
          <input type="text" class="form-control" formControlName="email" [ngClass]="{
              'is-valid': form.controls.email.valid
            }" />
          <label for="floatingInput">{{ _config?.registration?.input_label || 'Email' }}</label>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Email is required',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'pattern',
            message: 'Email is invalid',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'minlength',
            message: 'Email should have at least 3 symbols',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Email should have maximum 360 symbols',
            control: form.controls.email
          }"></ng-container>
      </div>
      <!--begin::Submit button-->
      <div class="fv-row mb-8 fv-plugins-icon-container register-check"
        *ngIf="_config.registration.primary_agreement_href ">
        <input type="checkbox" name="agreement" id="agreement" formControlName="agreement" style="margin-right: 8px;" />
        <span>
          {{_config.registration.prefix_agreement_text}}
          <a [href]="_config.registration.primary_agreement_href"
            target="_blank">{{_config.registration.primary_language}}</a>
          <span *ngIf="_config.registration.transition_text_1"> {{_config.registration.transition_text_1}}</span>
          <a [href]="_config.registration.secondary_agreement_href"
            target="_blank">{{_config.registration.secondary_language}}</a>

          <span *ngIf="_config.registration.transition_text_2"> {{_config.registration.transition_text_2}}</span>
          <a [href]="_config.registration.terciary_agreement_href"
            target="_blank">{{_config.registration.terciary_language}}</a>
        </span>
      </div>
      <div class="d-grid mb-10">
        <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-primary w-100 mb-5">
          <ng-container *ngIf="(loading$ | async) === false">
            <span class="indicator-label">Submit</span>
          </ng-container>
          <ng-container *ngIf="loading$ | async">
            <span class="indicator-label" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
        </button>
      </div>
      <!--end::Submit button-->
    </form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->

  <div class="text-gray-600 text-center fw-semibold fs-6 my-20" [animateCss]="'fadeInDown'">
    Already have an account?
    <a routerLink="/auth/login" class="text-dark text-hover-gray-800 fw-semibold">
      Sign In
    </a>
  </div>
</div>
<!--end::Form-->
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>