import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LaunchpointCoreClientActivityHistoryTableBaseComponent } from '@launchpoint/core-client';
import { LaunchpointCoreWebPaginationComponent } from '../../../../../components/pagination/pagination.component';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-activity-history-table',
  templateUrl: 'activity-history-table.component.html',
  standalone: true,
  imports: [CommonModule, LaunchpointCoreWebPaginationComponent, RouterModule, NgbTooltipModule],
})
export class LaunchpointCoreWebActivityHistoryTableComponent extends LaunchpointCoreClientActivityHistoryTableBaseComponent {}
