<launchpoint-users-table
  [user_entities]="user_entities$ | async"
  [loading]="loading$ | async"
  [pagination]="pagination$ | async"
  (paginate)="pageChange($event)"
  (queryUsers)="runQuery($event)"
  [querySort]="querySort$ | async"
  [query]="query$ | async"
  [error]="error$ | async"
>
</launchpoint-users-table>
