import { Component, inject } from '@angular/core';
import { LaunchpointBlogService } from '../../data/blog.service';

@Component({
  selector: 'sd-blog-cards',
  templateUrl: './blog-cards.component.html',
  styleUrl: './blog-cards.component.scss',
})
export class BlogCardsComponent {
  _LaunchpointBlogService = inject(LaunchpointBlogService);

  get loading() {
    return this._LaunchpointBlogService.loading();
  }
  get paginated() {
    return this._LaunchpointBlogService.paginatedBlogs();
  }
  get pages() {
    return this._LaunchpointBlogService.pages();
  }
  get currentPage() {
    return this._LaunchpointBlogService.currentPage();
  }
  get hasPrevPage() {
    return this.pages?.includes(this.currentPage - 1);
  }
  get hasNextPage() {
    return this.pages?.includes(this.currentPage + 1);
  }
}
