import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import * as AlertBannerActions from '../actions/alert-banner.actions';
import * as AlertBannerSelectors from '../selectors/alert-banner.selectors';

@Injectable()
export class GetSelectedAlertBannerGuard implements CanActivate {
  constructor(
    private _Store: Store,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.setSelectedUserId(route.params.banner_id).pipe(
      switchMap((loaded) => this.selectedUserInState(route.params.banner_id)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          AlertBannerActions.getAlertBannerByIdFailure({
            banner_id: route.params.banner_id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }

  setSelectedUserId(ah_id: string) {
    if (!ah_id) {
      return of(false);
    }
    return this._Store.select(AlertBannerSelectors.selectAlertBannerId).pipe(
      switchMap((selected) => {
        if (selected !== ah_id) {
          this._Store.dispatch(AlertBannerActions.getAlertBannerById({ banner_id: ah_id }));
          return of(false);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  selectedUserInState(banner_id: string) {
    if (!banner_id) {
      return of(false);
    }
    return this._Store.select(AlertBannerSelectors.selectAlertBannerEntities).pipe(
      switchMap((entities) => {
        if (!entities[banner_id]) {
          return this.dispatchUser(banner_id);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  dispatchUser(banner_id: string): Observable<boolean> {
    if (!banner_id) {
      return of(false);
    }
    this._Store.dispatch(AlertBannerActions.getAlertBannerById({ banner_id: banner_id }));
    return of(false);
  }
}
