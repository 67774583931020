import { Directive, inject } from '@angular/core';
import {
  IHttpException,
  IMediaCategory,
  IMediaCategoryParamsCreate,
  IMediaCategoryParamsUpdate,
  IMediaCategorySearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import * as MediaCategoryActions from './actions/media-category.actions';
import { IMediaCategoryEntity } from './reducers/media-category-entity.interface';
import * as MediaCategorySelectors from './selectors/media-category.selector';

@Directive()
export abstract class LaunchpointCoreClientMediaCategoriesStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  _Store = inject(Store);

  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<IMediaCategorySearchQuery>;
  error$: Observable<IHttpException>;
  success$: Observable<string>;
  mediaCategoryEntities$: Observable<IMediaCategoryEntity[]>;
  //
  mediaCategory$: Observable<IMediaCategory>;
  mediaCategoryLoading$: Observable<boolean>;
  mediaCategoryError$: Observable<IHttpException>;
  mediaCategorySuccess$: Observable<string>;

  constructor() {
    super();

    this.loading$ = this._Store.select(MediaCategorySelectors.selectMediaCategoryLoading);
    this.pagination$ = this._Store.select(MediaCategorySelectors.selectMediaCategoryPagination);
    this.querySort$ = this._Store.select(MediaCategorySelectors.selectMediaCategoryQuerySort);
    this.query$ = this._Store.select(MediaCategorySelectors.selectMediaCategoryQuery);
    this.error$ = this._Store.select(MediaCategorySelectors.selectMediaCategoryError);
    this.success$ = this._Store.select(MediaCategorySelectors.selectMediaCategorySuccess);
    this.mediaCategoryEntities$ = this._Store.select(MediaCategorySelectors.selectMediaCategories);
    //
    this.mediaCategory$ = this._Store.select(MediaCategorySelectors.selectSelectedMediaCategory);
    this.mediaCategoryLoading$ = this._Store.select(MediaCategorySelectors.selectSelectedMediaCategoryLoading);
    this.mediaCategoryError$ = this._Store.select(MediaCategorySelectors.selectSelectedMediaCategoryError);
    this.mediaCategorySuccess$ = this._Store.select(MediaCategorySelectors.selectSelectedMediaCategorySuccess);
  }

  deleteCategory(category: IMediaCategory) {
    this._Store.dispatch(MediaCategoryActions.deleteMediaCategory({ media_category_id: category._id }));
  }
  update(data: IMediaCategoryParamsUpdate) {
    this._Store.dispatch(MediaCategoryActions.updateMediaCategory({ data }));
  }
  create(data: IMediaCategoryParamsCreate) {
    this._Store.dispatch(MediaCategoryActions.createMediaCategory({ data }));
  }
  updateQuery(query: IMediaCategorySearchQuery) {
    this._Store.dispatch(MediaCategoryActions.updateMediaCategoryQuery({ query }));
  }
  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(MediaCategoryActions.updateMediaCategorySorting({ querySort: sortDataEmitter }));
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      MediaCategoryActions.updateMediaCategoryPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(MediaCategoryActions.updateMediaCategoryPagination({ pagination: { ...pagination, limit } }));
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'success', title: data });
      },
    });
    this.mediaCategorySuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'success', title: data });
      },
    });
  }

  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.error });
      },
    });
    this.mediaCategoryError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.error });
      },
    });
  }
  toast(params) {
    //
  }
}
