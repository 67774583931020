<ng-container *ngIf="form">
  <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" [formGroup]="form">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder text-dark">Update Company</span>
        </h3>
      </div>
      <div class="card-body">
        <!--begin::Row-->
        <div class="row mb-8">
          <!--begin::Col-->
          <div class="col-xl-3">
            <div class="fs-6 fw-semibold mt-2 mb-3">Company Name</div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-xl-9">
            <input class="form-control form-control-lg form-control-solid" type="text" formControlName="company_name"
              placeholder="Company Name" name="company_name" autocomplete="off" [ngClass]="{
                'is-invalid': form.controls.company_name.invalid,
                'is-valid': form.controls.company_name.valid
              }" />

            <ng-container *ngIf="form.controls.company_name?.valid || form.controls.company_name?.pristine"
              class="fv-plugins-message-container">
              <span class="fv-help-block"> &nbsp;</span>
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'Company Name is required',
                control: form.controls.company_name
              }">
            </ng-container>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row mb-8">
          <!--begin::Col-->
          <div class="col-xl-3">
            <div class="fs-6 fw-semibold mt-2 mb-3">Email</div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-xl-9">
            <input class="form-control form-control-lg form-control-solid" type="email" formControlName="email"
              placeholder="Company email..." name="email" autocomplete="off" [ngClass]="{
                'is-invalid': form.controls.email.invalid,
                'is-valid': form.controls.email.valid
              }" />

            <ng-container *ngIf="form.controls.email.valid || form.controls.email.pristine"
              class="fv-plugins-message-container">
              <span class="fv-help-block"> &nbsp;</span>
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'Company Email is required.',
                control: form.controls.email
              }">
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'email',
                message: 'Company Email should be a real address.',
                control: form.controls.email
              }">
            </ng-container>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row mb-8">
          <!--begin::Col-->
          <div class="col-xl-3">
            <div class="fs-6 fw-semibold mt-2 mb-3">Phone</div>
          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-xl-9">
            <input class="form-control form-control-lg form-control-solid" type="phone" formControlName="phone"
              placeholder="Phone" name="phone" autocomplete="off" [ngClass]="{
                'is-invalid': form.controls.phone.invalid,
                'is-valid': form.controls.phone.valid
              }" />

            <ng-container *ngIf="form.controls.phone?.valid || form.controls.phone?.pristine"
              class="fv-plugins-message-container">
              <span class="fv-help-block"> &nbsp;</span>
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'Phone is required',
                control: form.controls.phone
              }">
            </ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'minlength',
                message: '10 digits should have 10 digits',
                control: form.controls.phone
              }">
            </ng-container>
            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                validation: 'maxlength',
                message: '10 digits should have 10 digits',
                control: form.controls.phone
              }">
            </ng-container>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>

      <div class="card-footer d-flex justify-content-end py-6 px-9" *ngIf="form">
        <button (click)="submit()" type="submit" id="create_submit" [disabled]="form.invalid"
          class="btn btn-lg btn-primary fw-bolder">
          <ng-container>
            <span class="indicator-label">Submit</span>
          </ng-container>
        </button>
      </div>
    </div>
  </form>

  <ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <span role="alert">{{ message }}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>