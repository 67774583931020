import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ETopicType, ITopic, ITopicParamsCreate, ITopicParamsUpdate } from '@launchpoint/core-types';
import { createTopic, updateTopic } from '../+state/actions/topics.actions';
import { LaunchpointCoreClientNotificationsTopicsBaseComponent } from '../notifications-topics-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsTopicModalBaseComponent
  extends LaunchpointCoreClientNotificationsTopicsBaseComponent
  implements OnInit
{
  public fb = inject(FormBuilder);

  @Input() public topic: ITopic;

  form: FormGroup<{
    topic: FormControl<string>;
    description: FormControl<string>;
    topic_type: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      topic: ['', Validators.required],
      description: [''],
      topic_type: [false],
    });

    if (this.topic) {
      const { topic_type, ...rest } = this.topic;
      this.form.patchValue({
        ...rest,
        topic_type: topic_type === ETopicType.PUBLIC,
      });
    }
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    if (this.topic) {
      const data: ITopicParamsUpdate = {
        _id: this.topic._id,
        topic: this.form.controls.topic.value,
        description: this.form.controls.description.value,
        topic_type: this.form.controls.topic_type.value ? ETopicType.PUBLIC : ETopicType.PRIVATE,
      };

      this._Store.dispatch(updateTopic({ data }));
    } else {
      const data: ITopicParamsCreate = {
        topic: this.form.controls.topic.value,
        description: this.form.controls.description.value,
        topic_type: this.form.controls.topic_type.value ? ETopicType.PUBLIC : ETopicType.PRIVATE,
      };

      this._Store.dispatch(createTopic({ data }));
    }

    this.close();
  }

  close() {
    //
  }
}
