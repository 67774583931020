import { LaunchpointDemo4Component } from '../layout.component';
import { LaunchpointDemo4AsideMenuComponent } from './aside/aside-menu/aside-menu.component';
import { LaunchpointDemo4AsideComponent } from './aside/aside.component';
import { LaunchpointDemo4HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { LaunchpointDemo4HeaderComponent } from './header/header.component';
import { LaunchpointDemo4PageTitleComponent } from './header/page-title/page-title.component';
import { LaunchpointDemo4ToolbarComponent } from './toolbar/toolbar.component';
import { LaunchpointDemo4TopbarComponent } from './topbar/topbar.component';

export const LAUCNHPOINT_DEMO_4_LAYOUT_COMPONENTS = [
  LaunchpointDemo4Component,
  LaunchpointDemo4AsideComponent,
  LaunchpointDemo4HeaderComponent,
  LaunchpointDemo4ToolbarComponent,
  LaunchpointDemo4AsideMenuComponent,
  LaunchpointDemo4TopbarComponent,
  LaunchpointDemo4PageTitleComponent,
  LaunchpointDemo4HeaderMenuComponent,
];

export * from './aside';
export * from './header';
export * from './toolbar/toolbar.component';
export * from './topbar/topbar.component';
