export * from './tables-widget1/tables-widget1.component';
export * from './tables-widget10/tables-widget10.component';
export * from './tables-widget11/tables-widget11.component';
export * from './tables-widget12/tables-widget12.component';
export * from './tables-widget13/tables-widget13.component';
export * from './tables-widget14/tables-widget14.component';
export * from './tables-widget2/tables-widget2.component';
export * from './tables-widget3/tables-widget3.component';
export * from './tables-widget4/tables-widget4.component';
export * from './tables-widget5/tables-widget5.component';
export * from './tables-widget6/tables-widget6.component';
export * from './tables-widget7/tables-widget7.component';
export * from './tables-widget8/tables-widget8.component';
export * from './tables-widget9/tables-widget9.component';
