<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div class="card mb-5 mb-xl-10" *ngIf="form">
      <div class="card-header border-0">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ _config.title }}</h3>
        </div>
      </div>
      <form [formGroup]="form" class="form">
        <div class="card-body border-top p-9">
          <!-- START: Country Code -->
          <div class="row mb-6">
            <div class="col-lg-8">
              <div class="row">
                <div class="col-lg-3 fv-row">
                  <label class="col-form-label fw-bold fs-6">Country Code</label>
                  <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Country Code" name="phone_country_code" formControlName="phone_country_code" [ngClass]="{
                      'is-invalid': form.controls.phone_country_code.invalid,
                      'is-valid': form.controls.phone_country_code.valid && form.controls.phone_country_code.touched
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Country code is required',
                      control: form.controls.phone_country_code
                    }">
                  </ng-container>
                </div>
                <!-- START: Phone Number -->
                <div class="col-lg-6 fv-row">
                  <label class="col-form-label fw-bold fs-6">Phone Number</label>
                  <input type="number" class="form-control form-control-lg form-control-solid"
                    placeholder="Phone Number" name="phone_number_long" formControlName="phone_number_long" [ngClass]="{
                      'is-invalid': form.controls.phone_number_long.invalid && form.controls.phone_number_long.touched,
                      'is-valid': form.controls.phone_number_long.valid && form.controls.phone_number_long.touched
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Phone number is required',
                      control: form.controls.phone_number_long
                    }">
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START: Footer -->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button class="btn btn-secondary me-3" (click)="resetForm()">Cancel</button>
          <button type="button" class="btn btn-primary" [disabled]="isLoading || form.invalid || form.pristine"
            (click)="saveSettings()">
            <ng-container *ngIf="!isLoading">Save</ng-container>
            <ng-container *ngIf="isLoading">
              <span class="indicator-label" [style.display]="'block'">
                Please wait...{{ ' ' }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>
      </form>
    </div>

    <!-- START: FormError-->
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
      <ng-container
        *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <span role="alert">{{ message }}</span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>