import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LaunchpointBlogHTTPService, LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { ITagifyTag } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { Observable, map } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebBlogCategoryTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnChanges {
  @Input() categories: string[] = [];

  categoryModel: ITagifyTag[] = [];
  public whitelistBlogCategories$: Observable<string[]>;

  public settings: TagifySettings = {
    placeholder: 'Search Blog Categories...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  @Output() selectedCategories = new EventEmitter<ITagifyTag[]>();

  constructor(
    public _LaunchpointBlogHTTPService: LaunchpointBlogHTTPService,
    public tagifyService: TagifyService
  ) {
    super();
    this.whitelistBlogCategories$ = this._LaunchpointBlogHTTPService.distinct().pipe(map((f) => f));
  }

  ngOnInit(): void {
    if (this.categories?.length > 0) {
      setTimeout(async () => {
        this.categoryModel = this.categories.map((d) => {
          return {
            value: d,
            data: d,
          };
        });

        if (this.categoryModel?.length) {
          this.tagifyService.get('blog-categories').loadOriginalValues(this.categoryModel);
        }
      }, 250);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categories?.currentValue?.length > 0 && !changes?.categories?.firstChange) {
      setTimeout(async () => {
        this.categoryModel = this.categories.map((d) => {
          return {
            value: d,
            data: d,
          };
        });

        if (this.categoryModel?.length) {
          this.tagifyService.get('blog-categories').loadOriginalValues(this.categoryModel);
        }
      }, 50);
    }
  }

  selectData(changes: ITagifyTag[]) {
    // console.log({ changes });
    return this.selectedCategories.emit(changes);
  }
}
