import { Directive, inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionsSubject, Store } from '@ngrx/store';
import * as CompanySelectors from '../../../+state/selectors/company.selectors';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { LaunchpointStepperTabService } from '../../../../../components/stepper/stepper.base-service';
import * as UserAuthActions from '../../../../user/auth/+state/actions/user/user.actions';
import * as UserSelectors from '../../../../user/auth/+state/selectors/user.selectors';

@Directive()
export abstract class CompanySignUpStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  fb = inject(UntypedFormBuilder);
  _Store = inject(Store);
  _stepper = inject(LaunchpointStepperTabService);
  actions$ = inject(ActionsSubject);
  _router = inject(Router);
  _ActivatedRoute = inject(ActivatedRoute);

  user_id$ = this._Store.select(UserSelectors.selectSelectedUserId);
  userProfile$ = this._Store.select(UserSelectors.selectSelectedUserProfile);
  userLoading$ = this._Store.select(UserSelectors.selectUserLoading);
  accounts$ = this._Store.select(UserSelectors.selectUserAccounts);
  selectedUserAccount$ = this._Store.select(UserSelectors.selectSelectedUserAccount);

  loading$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyLoading);
  error$ = this._Store.select(CompanySelectors.selectLaunchpointCompanyError);
  success$ = this._Store.select(CompanySelectors.selectLaunchpointCompanySuccess);

  logout() {
    this._Store.dispatch(UserAuthActions.logout({ path: ['/auth/login'] }));
  }

  previous() {
    this._stepper.prevStep();
  }
}
