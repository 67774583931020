import { Directive } from '@angular/core';
import { takeUntil } from 'rxjs';
import { LaunchpointCoreClientAlertBannerStandaloneBaseComponent } from './alert-banner-standalone-base.component';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerPublicStandaloneBaseComponent extends LaunchpointCoreClientAlertBannerStandaloneBaseComponent {
  override getMessages(): void {
    this._CoreAlertBannerHTTPService
      .getAllForDatePublic()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => this.setBanners(data),
      });
  }
}
