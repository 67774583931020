import { ICoreBlogAuthor } from '../blog.interface';

export interface ICoreBlogParamsCommentAdd {
  /**
   * blog._id
   */
  _id: string;
  /**
   * user._id
   */
  user?: string;
  comment: string;
  title: string;
}
// export interface ICoreBlogParamsCommentUpdate {
//   /**
//    * blog._id
//    */
//   _id: string;
//   /**
//    * comment._id
//    */
//   comment_id: string;
//   comment: string;
// }

export interface ICoreBlogParamsCommentIds {
  /**
   * blog._id
   */
  _id: string;
  comment_id: string;
}

export interface ICoreBlogCommentBase {
  user: string | ICoreBlogAuthor;
  title: string;
  comment: string;
  likes: number;
  replies: ICoreBlogCommentReplyBase[];

  _id: string;
  created_at: Date;
  updated_at: Date;
}
export interface ICoreBlogComment extends ICoreBlogCommentBase {
  user: ICoreBlogAuthor;
  replies: ICoreBlogCommentReply[];
}

export type ICoreBlogCommentReplyBase = Omit<ICoreBlogCommentBase, 'replies'>;
export type ICoreBlogCommentReply = Omit<ICoreBlogComment, 'replies'>;
