import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-invite-users-modal',
  templateUrl: './invite-users-modal.component.html',
})
export class InviteUsersModalComponent {
  // constructor() {}
  // ngOnInit(): void {}
}
