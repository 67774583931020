import {
  ApplicationSettingsJourneyLimits,
  CampaignHook,
  CampaignLimits,
  CreateAppCommandInput,
  CreateApplicationRequest,
  DeleteAppCommandInput,
  GetAppCommandInput,
  GetAppsCommandInput,
  QuietTime,
  UpdateApplicationSettingsCommandInput,
  WriteApplicationSettingsRequest,
} from '@aws-sdk/client-pinpoint';
import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ILaunchpointUserAccountIds } from '../../security.interface';
import { ICampaignHook, ICampaignLimits, IQuietTime } from '../pinpoint-campaign/aws-campaign.interface';
import { IApplicationSettingsJourneyLimits } from './aws-application.interface';

// implements ApplicationResponse
export class ILaunchpointCorePinpointApplication {
  arn: string;
  creation_date?: string;
  id: string;
  name: string;
  tags?: Record<string, string>;
  //
  settings: ILaunchpointCorePinpointApplicationSettings;
  //
  _id: string;
  deleted: boolean;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  users: string[];
  accounts: string[];
}

// implements ApplicationSettingsResource
export class ILaunchpointCorePinpointApplicationSettings {
  campaign_hook: ICampaignHook;
  last_modified_date: string;
  limits: ICampaignLimits;
  quiet_time: IQuietTime;
  journey_limits: IApplicationSettingsJourneyLimits;
}

class ICreateApplicationRequest implements CreateApplicationRequest {
  Name: string;
  tags?: Record<string, string>;
}

class PinpointAppCreate implements CreateAppCommandInput {
  CreateApplicationRequest: ICreateApplicationRequest;
}

export type ILaunchpointCorePinpointApplicationParamsCreate = ILaunchpointUserAccountIds & PinpointAppCreate;

class IWriteApplicationSettingsRequest implements WriteApplicationSettingsRequest {
  CampaignHook?: CampaignHook;
  CloudWatchMetricsEnabled?: boolean;
  EventTaggingEnabled?: boolean;
  JourneyLimits?: ApplicationSettingsJourneyLimits;
  Limits?: CampaignLimits;
  QuietTime?: QuietTime;
}

class PinpointAppUpdateSettings implements UpdateApplicationSettingsCommandInput {
  ApplicationId: string;
  WriteApplicationSettingsRequest: IWriteApplicationSettingsRequest;
}

export type ILaunchpointCorePinpointApplicationParamsUpdateSettings = {
  _id: string;
} & PinpointAppUpdateSettings;

class PinpointDeleteApp implements DeleteAppCommandInput {
  ApplicationId: string;
  AppId: string;
}

export type ILaunchpointCorePinpointApplicationParamsDelete = {
  _id: string;
} & PinpointDeleteApp;

class PinpointListApp implements GetAppsCommandInput {
  ApplicationId: string;
  PageSize?: string;
  Token?: string;
}

export type ILaunchpointCorePinpointApplicationParamsList = PinpointListApp;

class PinpointRetrieveApp implements GetAppCommandInput {
  ApplicationId: string;
  AppId: string;
}

export type ILaunchpointCorePinpointApplicationParamsRetrieve = PinpointRetrieveApp;

export class ILaunchpointCorePinpointApplicationSearchQuery {
  search?: string;
  id?: string[];
  tags?: Record<string, string>;
  //  creation_date?: ILaunchpointSearchFilterDates;
  deleted?: boolean;
}

export class ILaunchpointCorePinpointApplicationSearchPayload {
  query: ILaunchpointCorePinpointApplicationSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ILaunchpointCorePinpointApplicationSearchResults {
  data: ILaunchpointCorePinpointApplication[];
  pagination: IQueryPageination;
}
