import { LaunchpointLayoutFooters } from '../../../../interfaces/footer.interface';
import { LaunchpointLayoutMenu } from '../../../../interfaces/menu.interfaces';
import { LaunchpointTopbarConfig } from '../../../../interfaces/topbar.interface';

export const MENU_FEATURE_KEY = 'menuReducer';

export interface MenuState {
  logo_dark?: string;
  logo_light?: string;
  asideMenu: LaunchpointLayoutMenu;
  headerMenu: LaunchpointLayoutMenu;
  loaded: boolean; // has the Menu list been loaded
  error?: string | null; // last known error (if any)
  topbar: LaunchpointTopbarConfig;
  footer: LaunchpointLayoutFooters;
}

export interface MenuPartialState {
  readonly [MENU_FEATURE_KEY]: MenuState;
}
