import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UsersTableFilterBaseComponent, updateAllUsersQuery } from '@launchpoint/core-client';
import { ECoreUserControlType, ELaunchpointUserRoles, ICoreUserSearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { LaunchpointFormControlDateRangeComponent } from '../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-users-filter',
  templateUrl: './users-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class UsersTableFilterComponent extends UsersTableFilterBaseComponent {
  active = 1;
  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: true,
    keepInvalidTags: false,
  };

  whitelistSecurityRole$ = new BehaviorSubject<string[]>(Object.values(ELaunchpointUserRoles));
  whitelistControlTypes$ = new BehaviorSubject<{ value: ECoreUserControlType }[]>(
    Object.values(ECoreUserControlType).map((f) => ({ value: f }))
  ).asObservable();
  // @ViewChild('tagifyInput') tagifyInput!: ElementRef;
  // tagify: Tagify;
  constructor(
    public _Store: Store,
    public fb: UntypedFormBuilder,
    private tagifyService: TagifyService,
    private _LaunchpointFormControlDateRangeComponent: LaunchpointFormControlDateRangeComponent
  ) {
    super(_Store, fb);

    // this.whitelistSecurityRole$.subscribe((whitelist) => {
    //   this.tagify = new Tagify(this.tagifyInput.nativeElement, {
    //     whitelist: whitelist,
    //     enforceWhitelist: true,
    //   });
    // });
    // this.updateWhitelist();
  }
  // updateWhitelist(): void {
  //   const customValue = 'All';
  //   this.whitelistSecurityRole$.next([...Object.values(ELaunchpointUserRoles), customValue]);
  // }

  apply() {
    const value = this.form.value;
    const query: ICoreUserSearchQuery = {
      security_role: value.security_role,
      control_types: value.control_types.map((f) => f.value),
      created_at: value.created_at,
      last_login: value.last_login,
    };
    // console.log(query);

    this.tagifyService.get('security_role')?.removeAllTags();
    this.tagifyService.get('control_types')?.removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    this._Store.dispatch(updateAllUsersQuery({ query: query }));
  }

  controlTypesAll() {
    this.form.controls.control_types.setValue(Object.values(ECoreUserControlType).map((f) => ({ value: f })));
  }
  securityRolesAll() {
    this.form.controls.security_role.setValue(Object.values(ELaunchpointUserRoles).map((f) => ({ value: f, data: f })));
  }

  clearAll() {
    const query: ICoreUserSearchQuery = {
      security_role: [],
      control_types: [],
      created_at: null,
    };
    this.form.patchValue({
      security_role: [],
      control_types: [],
      created_at: null,
    });

    this._Store.dispatch(updateAllUsersQuery({ query: query }));
  }
}
