import { Observable } from 'rxjs';
import {
  ILaunchpointCorePinpointChannel,
  ILaunchpointCorePinpointChannelParamsDelete,
  ILaunchpointCorePinpointChannelParamsList,
  ILaunchpointCorePinpointChannelParamsUpdate,
  ILaunchpointCorePinpointChannelSearchPayload,
  ILaunchpointCorePinpointChannelSearchResults,
} from './pinpoint-channel.interface';

/**
 * Controller('pinpoint/channels')
 */
export interface ILaunchpointCorePinpointChannelController {
  /**
   * Post(search)
   * @param body
   */
  search(
    body: ILaunchpointCorePinpointChannelSearchPayload
  ): Promise<ILaunchpointCorePinpointChannelSearchResults> | Observable<ILaunchpointCorePinpointChannelSearchResults>;
  /**
   * Get(:id)
   * @param _id
   */
  getById(_id: string): Promise<ILaunchpointCorePinpointChannel> | Observable<ILaunchpointCorePinpointChannel>;
  /**
   * Post()
   * @param body
   */
  update(body: ILaunchpointCorePinpointChannelParamsUpdate): Promise<ILaunchpointCorePinpointChannel> | Observable<ILaunchpointCorePinpointChannel>;
  /**
   * Put('delete;)
   * @param body
   */
  delete(body: ILaunchpointCorePinpointChannelParamsDelete): Promise<ILaunchpointCorePinpointChannel> | Observable<ILaunchpointCorePinpointChannel>;
  /**
   * Post('sync')
   * @param body
   */
  sync(body: ILaunchpointCorePinpointChannelParamsList): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
