import { Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StadnickDevConfigService } from '../../services/config.service';
import { StadnickButtonComponent } from '../../components/button/button.component';

@Component({
  selector: 'sd-header',
  standalone: true,
  imports: [CommonModule, RouterModule, StadnickButtonComponent],
  providers: [StadnickDevConfigService],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  open = false;
  _StadnickDevConfigService = inject(StadnickDevConfigService);

  hovered: string | null = null;

  get headerMenu() {
    return this._StadnickDevConfigService.headerMenu;
  }

  get cta() {
    return this._StadnickDevConfigService.primaryCallToAction;
  }

  // get cta2() {
  //   return this._StadnickDevConfigService.secondaryCallToAction;
  // }

  toggleMenu() {
    this.open = !this.open;
  }

  toggleHover(item: any) {
    if (this.hovered === item) {
      this.hovered = null;
    } else {
      this.hovered = item;
    }
  }

  closeMenu() {
    this.open = false;
  }

  // Function to toggle the dropdown open/close state
  toggleDropdown(item: any) {
    this._StadnickDevConfigService.headerMenu().map((menuItem) => {
      if (menuItem.title !== item.title) {
        (menuItem as any).isDropdownOpen = false;
      }
    });

    item.isDropdownOpen = !item.isDropdownOpen;
  }
}
