import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { CompanyAccountOwnerV2Service } from '../../../services/company-account-owner.service';
import * as CompanyActions from '../../actions/account-owner/account-owner.actions';
import { getCompanyByIdFailure } from '../../actions/company.actions';

@Injectable()
export class CompanyAccountOwnerEffects {
  constructor(private actions$: Actions, private _CompanyAccountOwnerV2Service: CompanyAccountOwnerV2Service) {}

  updateCompanyAccountOwner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.updateCompanyAccountOwner),
      switchMap((action) => {
        return this._CompanyAccountOwnerV2Service.update(action.data).pipe(
          map((data) => {
            return CompanyActions.updateCompanyAccountOwnerSuccess({
              company: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(CompanyActions.updateCompanyAccountOwnerFailure({ company_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(getCompanyByIdFailure(error)))
    );
  });
}
