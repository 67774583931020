import { LaunchpointDemo1AsideMenuComponent } from './aside/aside-menu/aside-menu.component';
import { LaunchpointDemo1AsideComponent } from './aside/aside.component';
import { LaunchpointDemo1HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { LaunchpointDemo1HeaderComponent } from './header/header.component';
import { LaunchpointDemo1PageTitleComponent } from './header/page-title/page-title.component';
import { LaunchpointDemo1ToolbarComponent } from './toolbar/toolbar.component';
import { LaunchpointDemo1TopbarComponent } from './topbar/topbar.component';

export const LAUCNHPOINT_DEMO_1_LAYOUT_COMPONENTS = [
  LaunchpointDemo1AsideComponent,
  LaunchpointDemo1HeaderComponent,
  LaunchpointDemo1ToolbarComponent,
  LaunchpointDemo1AsideMenuComponent,
  LaunchpointDemo1TopbarComponent,
  LaunchpointDemo1PageTitleComponent,
  LaunchpointDemo1HeaderMenuComponent,
];

export * from './aside/aside-menu/aside-menu.component';
export * from './aside/aside.component';
export * from './header/header-menu/header-menu.component';
export * from './header/header.component';
export * from './header/page-title/page-title.component';
export * from './toolbar/toolbar.component';
export * from './topbar/topbar.component';
