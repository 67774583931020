export enum ESecurityCoreUserResources {
  USER_READ = 'user:read',
  USER_SEARCH = 'user:search',
  USER_CHANGE_PASSWORD = 'user-password:change',
  USER_EMAIL_UPDATE = 'user-email:update',
  USER_PROFILE_UPDATE = 'user-profile:update',
  USER_DEMOGRAPHICS_UPDATE = 'user-demographics:update',
  USER_PHONE_UPDATE = 'user-phone:update',
  USER_ADDRESS_CREATE = 'user-address:create',
  USER_ADDRESS_UPDATE = 'user-address:update',
  USER_DELETE = 'user-info:delete',

  USER_ATTRIBUTES_UPDATE = 'user-attributes:update',
  USER_ATTRIBUTES_DELETE = 'user-attributes:delete',

  USER_SUBSCRIPTION_CREATE = 'user-subscription:create',

  USER_UPSERT_TOKEN = 'user:upsert-token',
  USER_UPSERT_TOPIC = 'user:upsert-topic',

  USER_ACCOUNT_SELECT = 'user-account:select',
  USER_ACCOUNT_DESELECT = 'user-account:deselect',
  USER_ACCOUNT_CREATE = 'user-account:create',
  USER_ACCOUNT_UPDATE = 'user-account:update',
  USER_ACCOUNT_REMOVE = 'user-account:remove',
  USER_ACCOUNT_ADD_SUB_USER = 'user-account:add-sub-user',
  USER_ACCOUNT_ADD_MAIN_USER = 'user-account:add-main-user',
  USER_ACCOUNT_SEND_INVITATION = 'user-account:send-invitation',
  USER_ACCOUNT_ACCEPT_INVITATION = 'user-account:accept-invitation',
  USER_ACCOUNT_GET_PENDING_INVITES = 'user-account:get-pending-invites',
  USER_ACCOUNT_RESEND_INVITE = 'user-account:resend-invite',
  USER_ACCOUNT_UPGRADE_SUB_USER = 'user-account:upgrade-sub-user',
  USER_ACCOUNT_FIND_WHERE_USER_IN_ACCOUNTS = 'user-account:find-where-user-in-accounts',
  USER_ACCOUNT_UPDATE_SECURITY_ROLES = 'user-account:update-security-roles',

  /**
   * permissions to delete all user accounts for a specific account_id
   */
  USER_ACCOUNTS_DELETE = 'user-accounts:delete',

  // NOTIFICATIONS
  USER_UNSUBSCRIBE_ALL = 'user:unsubscribe-all',

  //admin only - not exposed to user or default roles
  ADMIN_USER_EMAIL_VERIFY = 'admin:user-email:verify',
  ADMIN_USER_EMAIL_SET = 'admin:user-email:set',
  //
  USER_REPORTING = 'user:reporting',

  // for history
  USER_SIGN_UP = 'user:sign-up',
  USER_LOGIN = 'user:login',
  USER_LOGOUT = 'user:logout',
  USER_REQUEST_PASSWORD = 'user:request-password',
  USER_VERIFY_CODE = 'user:verify-code',
  USER_SET_PASSWORD = 'user-password:set',
  USER_RESET_PASSWORD = 'user-password:reset',
}

export enum ECoreUserRoutes {
  BASE = 'user',
  ACCOUNT = 'user/account',
  REPORTING = 'user/reporting',
  ATTRIBUTES = 'user/attributes',
  INVITATIONS = 'user/invitations',
  NOTIFICATIONS = 'user/notifications',
  PROFILE = 'user/profile',
  SECURITY_ROLES = 'user/security-roles',
  DEMOGRAPHICS = 'user/demographics',
  ADDRESS = 'user/address',
  ACCOUNT_SECURITY_ROLES = 'user/account/security-roles',
}
