import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ICoreUser,
  ICoreUserNotificationPreferencesParamsUpsert,
  ICoreUserNotificationTokensParamsUpsert,
  ICoreUserNotificationController,
  configureURL,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../core-profile/interfaces/core-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class CoreUserNotificationV1Service extends LaunchpointSecurityUserIdHeaderService implements ICoreUserNotificationController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';

  routes = {
    base: 'user/notifications',
    token: '/tokens',
    tokenDelete: '/tokens/remove',
    topic: '/topics',
    unsubscribeAll: '/unsubscribe-all',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientProfileConfig?.base_url,
      version: 1,
      route: this.routes.base,
    });
  }

  token(body: ICoreUserNotificationTokensParamsUpsert): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.token, body, { headers });
      })
    );
  }

  tokenDelete(body: ICoreUserNotificationTokensParamsUpsert): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.tokenDelete, body, { headers });
      })
    );
  }

  topic(body: ICoreUserNotificationPreferencesParamsUpsert): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.topic, body, { headers });
      })
    );
  }

  unsubscribeAll(user_id: string): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.unsubscribeAll + '/' + user_id, { headers });
      })
    );
  }
}
