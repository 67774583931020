<div class="card">
  <div class="card-body">
    <div class="table-responsive" [style.display]="(loading$ | async) ? 'block' : ''">
      <table class="table-row-dashed table-hover fs-6 gy-5 table align-middle">
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label m-5" [style.display]="'block'">
            Please wait...
            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </ng-container>
        <thead>
          <tr class="fw-bold fs-7 text-uppercase gs-0 text-gray-700">
            <th class="min-w-200px sorting">Event</th>
            <th class="min-w-200px sorting">Topic</th>
            <th class="min-w-70px sorting">Channels</th>
            <th class="min-w-70px sorting">Security Roles</th>
            <th class="min-w-100px sorting">Version</th>
            <th class="min-w-100px sorting">Active</th>
            <th class="min-w-100px sorting">Created</th>
          </tr>
        </thead>

        <tbody class="fw-semibold fs-6">
          <tr *ngIf="(loading$ | async) === false && (notificationEntity$ | async)?.length === 0">
            <td>No Notifications</td>
          </tr>

          <tr *ngFor="let entity of notificationEntity$ | async">
            <td>
              {{ entity?.notification?.event }}
            </td>
            <td>
              {{ entity?.notification?.topic?.topic }}
            </td>
            <td>
              {{ entity?.notification?.channels?.length }}
            </td>
            <td>
              {{ entity?.notification?.security_roles?.length }}
            </td>
            <td>
              {{ entity?.notification?.version }}
            </td>
            <td>
              <i class="bi bi-check-circle text-success" *ngIf="entity?.notification?.is_active"></i>
              <i class="bi bi-x-circle text-danger" *ngIf="!entity?.notification?.is_active"></i>
            </td>
            <td>{{ entity?.notification?.created_at | date }}</td>
          </tr>
        </tbody>
      </table>

      <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
    </div>
  </div>
</div>

<!-- <div *ngIf="!overview" class="d-flex justify-content-center mt-5">
  <a routerLink="/admin/notifications/notifications">View All Projects</a>
</div> -->
