<div class="w-lg-500px p-10">
  <!--begin::Heading-->
  <div class="text-center mb-10">
    <!--begin::Title-->
    <h1 class="text-dark mb-3">Logging Out...</h1>
    <!--end::Title-->
    <!--begin::Sub-title-->
    <div class="text-muted fw-semibold fs-5 mb-5">You have been logged out.</div>
    <!--end::Sub-title-->
    <!--begin::Mobile no-->
    <!-- <div class="fw-bold text-dark fs-3">******7859</div> -->
    <!--end::Mobile no-->
  </div>
  <!--end::Heading-->
</div>