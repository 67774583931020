import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ILaunchpointDynamicForm } from '../form/form.interface';
import { ILaunchpointDynamicFormResponseResponses } from './responses/response-answer.interface';
export const LAUNCHPOINT_DYNAMIC_FORMS_RESPONSE_SCHEMA = 'LAUNCHPOINT_DYNAMIC_FORMS_RESPONSE_SCHEMAS';
import { IsArray, ArrayNotEmpty, IsString, IsNotEmpty, IsOptional, ValidateNested, IsEnum } from 'class-validator';
import { Type as TypeTransformer } from 'class-transformer';

/**
 * Represents the status of a respondent's completion of the form.
 */
export enum ELaunchpointResponseStatus {
  COMPLETE = 'Complete',
  PARTIAL = 'Partial',
  DRAFT = 'Draft', // TODO: build this to support form testing.
}

/**
 * Represents the entire response to a form, including metadata and the status of completion.
 */
export class ILaunchpointDynamicFormResponseBase {
  _id?: string | undefined;
  /** The datethe form was completed, if applicable. */
  completed_date?: Date;

  /** Reference to the form that this response corresponds to. */
  form: string | ILaunchpointDynamicForm;
  /** The completion status of the form by the respondent.
   * @default ELaunchpointResponseStatus.PARTIAL
   */
  response_status?: ELaunchpointResponseStatus;
  /** An array of answers provided by the respondent. */
  responses: ILaunchpointDynamicFormResponseResponses[];
  /** Array of IDs referencing the associated account(s) for this response. */
  accounts?: string[];
  /** Array of IDs referencing the associated user(s) for this response. */
  users?: string[];
  /** TODO: Array of strings representing the URLs or IDs of attachments included in the response. */
  attachments?: string[];
  /** Timestamp of when the response was first created. */
  created_at?: Date;
  /** Timestamp of when the response was last updated. */
  updated_at?: Date;
}

export class ILaunchpointDynamicFormResponse implements ILaunchpointDynamicFormResponseBase {
  _id?: string;
  completed_date?: Date;
  form: ILaunchpointDynamicForm;
  response_status?: ELaunchpointResponseStatus;
  responses: ILaunchpointDynamicFormResponseResponses[];
  accounts?: string[];
  users?: string[];
  attachments?: string[];
  created_at?: Date;
  updated_at?: Date;
}

export class ILaunchpointDynamicFormResponseParamsCreate implements Partial<Omit<ILaunchpointDynamicFormResponseBase, '_id'>> {
  // _id: never;
  form: string;
  accounts?: string[] | undefined;
  responses?: ILaunchpointDynamicFormResponseResponses[];
}

export class ILaunchpointDynamicFormResponseParamsUpdate
  implements Partial<Omit<ILaunchpointDynamicFormResponseBase, 'response' | 'accounts' | 'users' | 'attachments'>>
{
  @IsString({ message: '_id array must be a string' })
  _id: string;

  @IsOptional()
  @IsEnum(ELaunchpointResponseStatus, { message: 'Response Status must be a valid value of type ELaunchpointResponseStatus' })
  response_status?: ELaunchpointResponseStatus;
}

export class ILaunchpointDynamicFormResponseParamsUpdateBulk {
  @IsArray({ message: 'Updates must be an array' })
  @ArrayNotEmpty({ message: 'Updates array cannot be empty' })
  @ValidateNested({
    each: true,
    message: 'Each update must be a valid ILaunchpointDynamicFormResponseParamsUpdate or ILaunchpointDynamicFormResponseParamsCreate',
  })
  @TypeTransformer(() => ILaunchpointDynamicFormResponseParamsUpdate) // Use the correct type for your logic
  updates: ILaunchpointDynamicFormResponseParamsUpdate[] | ILaunchpointDynamicFormResponseParamsCreate[];
}

export class ILaunchpointDynamicFormResponseSearchQuery {
  @IsOptional()
  @IsString({ message: 'Search must be a string' })
  search?: string;
  @IsOptional()
  @IsString({ message: 'form_id must be a string' })
  form_id?: string;
  @IsOptional()
  @IsEnum(ELaunchpointResponseStatus, { message: 'Status filter must be of type ELaunchpointResponseStatus' })
  response_status?: ELaunchpointResponseStatus;

  completed_date?: {
    start_date: Date;
    end_date?: Date;
  };

  question_filter?: {
    // @IsOptional()
    // @IsString({ message: 'question_id must be a string' })
    question_id: string;
    /**
     * For filtering for specific answer values to a question
     */
    response_params?: {
      type: '$gt' | '$gte' | '$lt' | '$lte' | '$eq';
      value: string | number;
    };
  };
}
/**
 * SEARCH PAYLOAD
 *
 * This is the required payload to run a search query and return values
 */

export class ILaunchpointDynamicFormResponseSearchPayload {
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormResponseSearchQuery)
  query: ILaunchpointDynamicFormResponseSearchQuery;

  @ValidateNested()
  @TypeTransformer(() => IQueryPageination)
  pagination: IQueryPageination;

  @IsOptional()
  querySort?: IQuerySort;
}

/**
 * OPTIONAL SEARCH RESULTS OVERRIDE //:LINK
 *
 * This is the payload for the search query results.
 */
// export type ILaunchpointDynamicFormResponseData = ILaunchpointDynamicFormResponse & {
//   distance: number;
//   location: { type: string; coordinates: [number, number] };
// };

export class ILaunchpointDynamicFormResponseSearchResults {
  pagination: IQueryPageination;
  data: ILaunchpointDynamicFormResponse[]; // OR ILaunchpointDynamicFormResponseData[]; //:LINK
}

export class ILaunchpointDynamicFormResponseParamsDelete {
  @IsString({ message: '_id must be a string' })
  @IsNotEmpty({ message: '_id cannot be empty' })
  _id: string;
}

export class ILaunchpointDynamicFormResponseParamsDeleteBulk {
  @IsArray({ message: '_ids must be an array' })
  @ArrayNotEmpty({ message: '_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the _ids array must be a string' })
  _ids: string[];
}

export class ILaunchpointDynamicFormResponseParamsArchive {
  @IsString({ message: '_id must be a string' })
  @IsNotEmpty({ message: '_id cannot be empty' })
  _id: string;
}

export class ILaunchpointDynamicFormResponseParamsArchiveBulk {
  @IsArray({ message: '_ids must be an array' })
  @ArrayNotEmpty({ message: '_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the _ids array must be a string' })
  _ids: string[];
}

export interface ILaunchpointDynamicFormResponseStatusAnalytics {
  status: string;
  count: number;
}
