import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchpointCoreClientAuthStateBaseComponent, UserSelectors } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { Observable, takeUntil } from 'rxjs';
import { ISwalAlertParamsToast, LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-auth-v2',
  templateUrl: './auth-v2.component.html',
  styleUrls: ['./auth-v2.component.scss'],
})
export class LaunchpointCoreWebAuthV2Component extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  _swalService = inject(LaunchpointSweetAlertService);

  _router = inject(Router);

  today: Date = new Date();
  user$: Observable<ICoreUser>;
  return_url$: Observable<string[]>;
  navigate_url: string;

  imageRight = true;

  ngOnInit(): void {
    this.user$ = this._Store.select(UserSelectors.selectUser) as Observable<ICoreUser>;

    if (this._config?.auth.image_side && this._config.auth.image_side === 'left') {
      this.imageRight = false;
    }

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user?.email || user?.demographics?.phone?.phone_number_long) {
        if (this.navigate_url) {
          this._router.navigate([this.navigate_url]);
        } else {
          this._router.navigate(['/']);
        }
      }
    });

    this.success();
    // this.error(); // todo -- we want inline errors
  }

  get setupStyle() {
    if (!this?._config?.auth?.background_color && !this?._config?.auth?.background_image) {
      return;
    }

    if (this?._config?.auth?.background_image) {
      return `background-image: url(${this._config.auth.background_image}); background-size: cover; background-repeat: no-repeat;`;
    }

    return `background-color: ${this._config.auth.background_color}`;
  }

  override toast(params: ISwalAlertParamsToast) {
    this._swalService.fireToast(params);
  }
}
