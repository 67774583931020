import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sd-cta-banner',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './cta-banner.component.html',
  styleUrl: './cta-banner.component.scss',
})
export class CtaBannerComponent {
  @Input() text: string;
  @Input() href: string;
  @Input() route: string;
}
