import { Component } from '@angular/core';
import { ESSENTIALS_TRAINING_CARDS, TRAINING_CARDS, TRAINING_INFO_DATA, TRAINING_INSIGHTS } from '../../data/resources-data';
import { ResourcesBaseComponent } from '../resources-base.component';

@Component({
  selector: 'sd-lessons',
  templateUrl: 'lessons.component.html',
  styleUrls: ['lessons.component.scss'],
})
export class ResourcesLessonsComponent extends ResourcesBaseComponent {
  items = TRAINING_INFO_DATA;
  insights = TRAINING_INSIGHTS;
  essentials = ESSENTIALS_TRAINING_CARDS;
  trainings = TRAINING_CARDS;

  cards: { title: string; description: string }[] = [
    {
      title: '1,000+',
      description: 'Courses Downloaded',
    },
    {
      title: '50+',
      description: 'Industries Helped',
    },
    {
      title: '98%',
      description: 'Satisfaction Guaranteed',
    },
  ];
}
