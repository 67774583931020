import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ManyChatSubscribeService } from '@launchpoint/xplat/web/features';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'sd-newsletter',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule],
  providers: [ManyChatSubscribeService],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss',
})
export class StadnickNewsletterComponent {
  _ManyChatSubscribeService = inject(ManyChatSubscribeService);
  form = new FormControl('', [Validators.email, Validators.maxLength(244)]);

  @Input() cssClass = '';

  async submitEmail() {
    if (this.form.valid) {
      try {
        console.log(this.form.value);
        const subscriber = {
          email: this.form.value,
          has_opt_in_email: true,
          tags: ['new_lead'],
        };
        // this.StadnickDevConfigService.fireEvent(
        //   EApplicationEvents.SUBSCRIBE_EMAIL,
        //   'Submitted email on Launchpoint blog',
        //   EApplicationCategories.CONTACT_US
        // );
        const data = await firstValueFrom(this._ManyChatSubscribeService.createSubscriber(subscriber));
        console.log({ data });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
