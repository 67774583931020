import {
  IHttpException,
  IMediaCategory,
  IMediaCategoryParamsCreate,
  IMediaCategorySearchQuery,
  IMediaCategoryParamsUpdate,
  IQueryPageination,
  IQuerySort,
  IMediaCategorySearchResults,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllMediaCategories = createAction('[Admin MediaCategory] searchAllMediaCategories');
export const searchAllMediaCategorySuccess = createAction(
  '[Admin MediaCategory] searchAllMediaCategorySuccess',
  props<{ data: IMediaCategorySearchResults; success_message?: string }>()
);
export const searchAllMediaCategoryFailure = createAction('[Admin MediaCategory] searchAllMediaCategoryFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateMediaCategoryPagination = createAction(
  '[Admin MediaCategory] updateMediaCategoryPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateMediaCategorySorting = createAction('[Admin MediaCategory] updateMediaCategorySorting', props<{ querySort: IQuerySort }>());
export const updateMediaCategoryQuery = createAction('[Admin MediaCategory] updateMediaCategoryQuery', props<{ query: IMediaCategorySearchQuery }>());

// getById
export const getMediaCategoryById = createAction('[Admin MediaCategory] getMediaCategoriesById', props<{ media_category_id: string }>());
export const getMediaCategoryByIdSuccess = createAction(
  '[Admin MediaCategory] getMediaCategoriesByIdSuccess',
  props<{ data: IMediaCategory; success_message?: string }>()
);
export const getMediaCategoryByIdFailure = createAction(
  '[Admin MediaCategory] getMediaCategoriesByIdFailure',
  props<{ media_category_id: string; error: IHttpException }>()
);

// CREATE
export const createMediaCategory = createAction('[Admin MediaCategory] createMediaCategory', props<{ data: IMediaCategoryParamsCreate }>());
export const createMediaCategorySuccess = createAction(
  '[Admin MediaCategory] createMediaCategorySuccess',
  props<{ data: IMediaCategory; success_message?: string }>()
);
export const createMediaCategoryFailure = createAction('[Admin MediaCategory] createMediaCategoryFailure', props<{ error: IHttpException }>());

// UPDATE
export const updateMediaCategory = createAction('[Admin MediaCategory] updateMediaCategory', props<{ data: IMediaCategoryParamsUpdate }>());
export const updateMediaCategorySuccess = createAction(
  '[Admin MediaCategory] updateMediaCategorySuccess',
  props<{ data: IMediaCategory; success_message?: string }>()
);
export const updateMediaCategoryFailure = createAction(
  '[Admin MediaCategory] updateMediaCategoryFailure',
  props<{ media_category_id: string; error: IHttpException }>()
);

// DELETE
export const deleteMediaCategory = createAction('[Admin MediaCategory] deleteMediaCategory', props<{ media_category_id: string }>());
export const deleteMediaCategorySuccess = createAction(
  '[Admin MediaCategory] deleteMediaCategorySuccess',
  props<{ data: IMediaCategory; success_message?: string }>()
);
export const deleteMediaCategoryFailure = createAction(
  '[Admin MediaCategory] deleteMediaCategoryFailure',
  props<{ media_category_id: string; error: IHttpException }>()
);
