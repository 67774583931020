import { ChangeDetectorRef, Directive, inject, Input, OnInit } from '@angular/core';
import { IMedia, IMediaCategory } from '@launchpoint/core-types';
import { TagifySettings } from 'ngx-tagify';
import { Observable, takeUntil } from 'rxjs';
import { createMediaSuccess, getMediaById, updateMediaSuccess } from '../+state/actions/media.actions';
import { FileEntity } from '../interface/file-entity.interface';
import { LaunchpointCoreClientMediaBaseComponent } from './media-base.component';
import { BASE_TAGIFY_SETTINGS } from '../../../../components';

@Directive()
export class LaunchpointCoreClientMediaUploadBaseComponent extends LaunchpointCoreClientMediaBaseComponent implements OnInit {
  public cd = inject(ChangeDetectorRef);

  @Input() media: IMedia;
  @Input() allowMultiple: 'true' | 'false' = 'true';

  settings: TagifySettings = {
    ...BASE_TAGIFY_SETTINGS,
    enforceWhitelist: false,
  };

  whitelistTags$: Observable<string[]>;

  files: FileEntity[] = [];
  categories: IMediaCategory[] = [];

  ngOnInit(): void {
    // console.log('this.media', this.media);
    if (this.media) {
      this.categories = [this.media?.category];
      // console.log(this.categories);
      this.cd.detectChanges();
    }

    this.success();
    this.errors();
  }

  get uploadDisabled() {
    // console.log('this.files', this.files);

    const disabled = this.files.some((file) => !file.category);

    // console.log({ disabled });

    return disabled;
  }

  clear() {
    this.files = [];
  }

  push(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      this.files.push({
        file: files[i],
        uploadPercentage: null,
        loading: false,
        error: null,
        category: this.categories[i],
        description: '',
        media: null,
      });
    }
  }

  async upload() {
    if (!this.files.length) {
      return;
    }

    for (let i = 0; i < this.files.length; i++) {
      /**
       * Already been uploaded so we continue to the others
       */
      if (this.files[i]?.media) {
        continue;
      }
      /**
       * This is required for the media create service
       */
      if (!this.files[i]?.category) {
        this.files[i].error = 'Please select a category.';
      }
      try {
        if (this.media) {
          await this.replaceUploadServiceV2(this.files[i], i);
        } else {
          await this.uploadServiceV2(this.files[i], i);
        }
      } catch (error) {
        console.log('upload error', error);

        this.files[i].uploadPercentage = 0;
        this.files[i].error = error?.error?.message ?? error?.message ?? error;

        // if (this.files?.[i]?.media?._id) {
        //   this._Store.dispatch(deleteMedia({ id: this.files[i]?.media._id }));
        // }
      }
      continue;
    }
  }

  public async uploadServiceV2(file: FileEntity, i: number) {
    return new Promise((res, rej) => {
      this._LunchPointMediaUploadService
        .uploadMediaService(file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (updatedFileEntity) => {
            this.files[i] = updatedFileEntity;
            this.cd.detectChanges();
          },
          complete: () => {
            // console.log('uploadServiceV2 complete');
            if (this.files?.[i]?.media) {
              this._Store.dispatch(createMediaSuccess({ data: this.files[i]?.media }));
            }
            return res(i);
          },
          error: (error) => {
            // console.log('uploadServiceV2 error', error);
            return rej(error);
          },
        });
    });
  }
  public async replaceUploadServiceV2(file: FileEntity, i: number) {
    return new Promise((res, rej) => {
      this._LunchPointMediaUploadService
        .replaceMediaService(this.media._id, file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (updatedFileEntity) => {
            this.files[i] = updatedFileEntity;
            this.cd.detectChanges();
          },
          complete: () => {
            // console.log('uploadServiceV2 complete');
            if (this.files?.[i]?.media) {
              this._Store.dispatch(getMediaById({ media_id: this.files[i]?.media._id }));
              this._Store.dispatch(updateMediaSuccess({ data: this.files[i]?.media, success_message: 'Media Replaced' }));
            }
            return res(i);
          },
          error: (error) => {
            // console.log('uploadServiceV2 error', error);
            return rej(error);
          },
        });
    });
  }

  remove(i: number) {
    this.files.splice(i, 1);
  }

  setCategory(category: IMediaCategory, i: number) {
    this.files[i].category = category;
  }

  removeCategory(i: number) {
    if (this.files[i]?.media) {
      return;
    }
    this.files[i].category = null;
  }
}
