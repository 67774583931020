<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div>
      <!---: Change Email Password Main -->
      <div class="card mb-5 mt-10 mb-xl-10" id="email-password-body" *ngIf="changeEmailPasswordMainCard">
        <div class="card-header border-0 cursor-pointer">
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Change Email/Password</h3>
          </div>
        </div>
        <div class="collapse show">
          <div class="card-body border-top p-9">
            <div class="d-flex flex-wrap align-items-center">
              <div class="false">
                <div class="fs-6 fw-bolder mb-1">Email Address</div>
                <div class="fw-bold text-gray-600">{{ user_email }}</div>
              </div>
              <div class="ms-auto false">
                <button class="btn btn-light btn-active-light-primary" (click)="showChangeEmailForm()">Change
                  Email</button>
              </div>
            </div>
            <div class="separator separator-dashed my-6"></div>
            <div class="d-flex flex-wrap align-items-center">
              <div class="false">
                <div class="fs-6 fw-bolder mb-1">Password</div>
                <div class="fw-bold text-gray-600">************</div>
              </div>
              <div class="ms-auto false">
                <button class="btn btn-light btn-active-light-primary" (click)="showChangePasswordForm()">Change
                  Password</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!---: Change Email -->
      <div class="card mb-5 mb-xl-10" id="change-email" *ngIf="changeEmailCard">
        <div class="card-header border-0 cursor-pointer">
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Change Email</h3>
          </div>
        </div>
        <div class="card-body border-top p-9">
          <div class="flex-row-fluid">
            <form class="form" [formGroup]="emailForm">
              <div class="row mb-6">
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="new_email" class="form-label fs-6 fw-bolder mb-3">Enter New Email Address</label>
                    <input type="email" placeholder="Email Address" formControlName="new_email" name="new_email"
                      value="" class="form-control form-control-lg form-control-solid" [ngClass]="{
                        'is-invalid': emailForm.controls.new_email.touched && emailForm.controls.new_email.invalid,
                        'is-valid': emailForm.controls.new_email.touched && emailForm.controls.new_email.valid
                      }" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Email is required',
                        control: emailForm.controls.new_email
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'email',
                        message: 'Email is invalid',
                        control: emailForm.controls.new_email
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'Email should have at least 3 characters',
                        control: emailForm.controls.new_email
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxlength',
                        message: 'Email should have maximum 360 characters',
                        control: emailForm.controls.new_email
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'ConfirmEmail',
                        message: 'Emails do not match',
                        control: emailForm.controls.new_email
                      }">
                    </ng-container>
                    <div *ngIf="error$ | async as error">
                      <div *ngIf="error.error.message.includes('email')">
                        <div class="fv-plugins-message-container">
                          <div class="fv-help-block alert-block">
                            <span role="alert">{{ error.error.message }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="confirm_new_email" class="form-label fs-6 fw-bolder mb-3">Confirm New Email
                      Address</label><input type="email" placeholder="Email Address" formControlName="confirm_new_email"
                      name="confirm_new_email" value="" class="form-control form-control-lg form-control-solid"
                      [ngClass]="{
                        'is-invalid': emailForm.controls['confirm_new_email'].touched && emailForm.controls['confirm_new_email'].invalid,
                        'is-valid': emailForm.controls['confirm_new_email'].touched && emailForm.controls['confirm_new_email'].valid
                      }" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Email is required',
                        control: emailForm.controls['confirm_new_email']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'email',
                        message: 'Email is invalid',
                        control: emailForm.controls['confirm_new_email']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'Email should have at least 3 characters',
                        control: emailForm.controls['confirm_new_email']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxlength',
                        message: 'Email should have maximum 360 characters',
                        control: emailForm.controls['confirm_new_email']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'ConfirmEmail',
                        message: 'Emails do not match',
                        control: emailForm.controls['confirm_new_email']
                      }">
                    </ng-container>
                  </div>
                </div>
                <div *ngIf="!admin" class="col-lg-4">
                  <div class="fv-row mb-0">
                    <label for="password" class="form-label fs-6 fw-bolder mb-3">Current Password</label>
                    <div class="input-group">
                      <input type="password" name="password" formControlName="password" value=""
                        class="form-control form-control-lg form-control-solid" autocomplete="new-password" [ngClass]="{
                          'is-invalid': emailForm.controls.password.touched && emailForm.controls.password.invalid
                        }" id="current-password" />
                      <div class="input-group-append eye-icon">
                        <button class="btn font-weight-bolder font-size-sm" style="cursor: default" type="button"
                          (click)="showPassword('current-password', $event)">
                          <i class="fas fa-eye" style="cursor: pointer"></i>
                        </button>
                      </div>
                    </div>

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Password is required',
                        control: emailForm.controls.password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'Password should have at least 3 characters',
                        control: emailForm.controls.password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxlength',
                        message: 'Password should have maximum 100 characters',
                        control: emailForm.controls.password
                      }">
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row-reverse mt-10 button-group">
                <ng-container>
             
                  <button type="submit" class="btn btn-primary me-2 px-6"
                    [disabled]="(loading$ | async) || (emailForm.invalid)" (click)="updateEmail()">
                    <ng-container *ngIf="loading$ | async; else loaded">
                      <span class="indicator-label" [style.display]="'block'">
                        Please wait...{{ ' ' }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </ng-container>
                    <ng-template #loaded>Save</ng-template>
                  </button>
                </ng-container>
                <button type="button" class="btn btn-light btn-active-light-primary px-6 me-4"
                  (click)="cancelEmailPassword()">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!---: Change Email Ends -->

      <!---: Change Password -->

      <div class="card mb-5 mb-xl-10" *ngIf="changePasswordCard">
        <div class="card-header border-0 cursor-pointer">
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Change Password</h3>
          </div>
        </div>
        <div class="card-body border-top p-9">
          <div class="d-flex flex-wrap align-items-center">
            <div class="flex-row-fluid false">
              <form class="form" [formGroup]="passwordForm" autocomplete="off">
                <div class="row mb-1">
                  <!---: Current Password -->

                  <!---: Current Password Ends -->

                  <!---: New Password -->
                  <div class="col-lg-6">
                    <label for="new_password" class="form-label fs-6 fw-bolder mb-3">New Password</label>
                    <div class="input-group">
                      <input type="password" name="new_password" formControlName="new_password" value=""
                        class="form-control form-control-lg form-control-solid" [ngClass]="{
                          'is-invalid': passwordForm.controls['new_password'].touched && passwordForm.controls['new_password'].invalid
                        }" id="new-password" />
                      <div class="input-group-append eye-icon">
                        <button class="btn font-weight-bolder font-size-sm" type="button" style="cursor: default"
                          (click)="showPassword('new-password', $event)">
                          <i class="fas fa-eye" style="cursor: pointer"></i>
                        </button>
                      </div>
                    </div>

                    <!---: New Password Validation -->

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'pattern',
                        message: 'Please enter valid data',
                        control: passwordForm.controls['new_password']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Password is required',
                        control: passwordForm.controls['new_password']
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'Password should have at least 8 characters',
                        control: passwordForm.controls.new_password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxlength',
                        message: 'Password should have maximum 100 characters',
                        control: passwordForm.controls.new_password
                      }">
                    </ng-container>
                  </div>
                  <!---: Confirm New Password  -->
                  <div class="col-lg-6">
                    <label for="confirm_new_password" class="form-label fs-6 fw-bolder mb-3">Confirm New
                      Password</label>
                    <div class="input-group">
                      <input type="password" name="confirm_new_password" formControlName="confirm_new_password" value=""
                        class="form-control form-control-lg form-control-solid" [ngClass]="{
                          'is-invalid': passwordForm.controls.confirm_new_password.touched && passwordForm.controls.confirm_new_password.invalid
                        }" id="confirm-new-password" />
                      <div class="input-group-append eye-icon">
                        <button class="btn font-weight-bolder font-size-sm" type="button" style="cursor: default"
                          (click)="showPassword('confirm-new-password', $event)">
                          <i class="fas fa-eye" style="cursor: pointer"></i>
                        </button>
                      </div>
                    </div>

                    <!-- Confirm New Password Validation Messages -->
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'required',
                        message: 'Password is required',
                        control: passwordForm.controls.confirm_new_password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'pattern',
                        message: 'Please enter correct data',
                        control: passwordForm.controls.confirm_new_password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'minlength',
                        message: 'Password should have at least 8 characters',
                        control: passwordForm.controls.confirm_new_password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'maxlength',
                        message: 'Password should have maximum 100 characters',
                        control: passwordForm.controls.confirm_new_password
                      }">
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'ConfirmPassword',
                        message: 'Passwords do not match',
                        control: passwordForm.controls.confirm_new_password
                      }">
                    </ng-container>
                  </div>

                  <!---: Confirm New Password Ends -->
                </div>
                <div class="d-flex flex-row-reverse button-group mt-10">
                  <ng-container>
                    <button type="button" class="btn btn-primary me-2 px-6"
                      [disabled]="(loading$ | async) || passwordForm.invalid" (click)="updatePassword()">
                      <ng-container *ngIf="loading$ | async; else loaded">
                        <span class="indicator-label" [style.display]="'block'">
                          Please wait...{{ ' ' }}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </ng-container>
                      <ng-template #loaded>Save</ng-template>
                    </button>
                  </ng-container>
                  <button type="button" class="btn btn-light btn-active-light-primary px-6 me-4"
                    (click)="cancelEmailPassword()">
                    Cancel
                  </button>
                </div>
              </form>
            </div>
            <div class="ms-auto d-none">
              <button class="btn btn-light btn-active-light-primary">Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block alert-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>