import { Directive, EventEmitter, forwardRef, inject, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITopic, ITopicSearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap, takeUntil } from 'rxjs';
import { ISearchAheadComponent } from '../../../../../components/search-ahead/search-ahead-component.interface';
import { LaunchpointFormControlSearchAheadBaseComponent } from '../../../../../forms/from-control/form-control-search-ahead-base.component';
import { TopicsHTTPService } from '../../../services/notification-topics-http.service';

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreClientNotificationsTopicsSearchAheadBaseComponent),
      multi: true,
    },
  ],
})
export abstract class LaunchpointCoreClientNotificationsTopicsSearchAheadBaseComponent
  extends LaunchpointFormControlSearchAheadBaseComponent
  implements ISearchAheadComponent, OnInit
{
  public _NotificationsTopicsHTTPService = inject(TopicsHTTPService);
  public _Store: Store = inject(Store);

  @Input() query: ITopicSearchQuery;
  @Input() placeholder = 'Search topics...';
  /**
   * This is the user id of the selected user.
   */
  @Input() selected: string;

  @Output() selectedTopic = new EventEmitter<ITopic>();

  ngOnInit(): void {
    this.runQuery('');
  }

  async getById(user_id: string) {
    if (!user_id) {
      return;
    }
    this.loading = true;
    this._NotificationsTopicsHTTPService
      .getById(user_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        (this.placeholder = data.topic), (this.loading = false);
        return this.selectedTopic.emit(data);
      });
  }

  formatter = (result: ITopic): string => `${result.topic}`;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly ITopic[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._NotificationsTopicsHTTPService
      .search({ pagination: { limit: 100, skip: 0, count: 0 }, querySort: { created_at: -1 }, query: { ...this.query, search } })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  /**
   * Search aheads use this instead of `adjustValue`
   * @param data
   * @returns
   */
  selectData(data: { item: ITopic }) {
    this.selected = data.item._id;
    this.onChanged(data.item._id);
    this.onTouched();
    return this.selectedTopic.emit(data.item);
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.selected = value;
      this.getById(this.selected);
    }
  }
}
