import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { UserViewV1Service } from '../../services/user-view-http.service';
import * as UserViewActions from '../actions/views.actions';
import * as UserViewSelectors from '../selectors/views.selectors';

@Injectable()
export class ViewEffects {
  constructor(private actions$: Actions, private _Store: Store, private _UserViewService: UserViewV1Service) {}

  getAllView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserViewActions.searchAllView,
        UserViewActions.updateAllViewPagination,
        UserViewActions.updateAllViewQuery,
        UserViewActions.updateAllViewSorting
      ),
      withLatestFrom(this._Store.select(UserViewSelectors.getAllViewState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._UserViewService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            // console.log('results for update all users query', results);
            return UserViewActions.searchAllViewSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserViewActions.searchAllViewFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserViewActions.searchAllViewFailure(error)))
    );
  });

  getUserById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserViewActions.getViewById),
      switchMap((action) => {
        return this._UserViewService.findById(action.view_id).pipe(
          map((view) => {
            return UserViewActions.getViewByIdSuccess({
              view,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserViewActions.getViewByIdFailure({ view_id: action.view_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserViewActions.getViewByIdFailure(error)))
    );
  });

  createView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserViewActions.createView),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._UserViewService.create(action.view).pipe(
          map((view) => {
            return UserViewActions.createViewSuccess({
              view,
              // success_message: 'User has been updated.',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserViewActions.createViewFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserViewActions.createViewFailure(error)))
    );
  });

  updateView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserViewActions.updateView),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._UserViewService.update(action.view).pipe(
          map((view) => {
            return UserViewActions.updateViewSuccess({
              view,
              // success_message: 'User has been updated.',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserViewActions.updateViewFailure({ view_id: action.view._id, error: error.error })))
        );
      }),
      catchError((error) => of(UserViewActions.updateViewFailure(error)))
    );
  });

  deleteView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserViewActions.deleteView),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._UserViewService.delete(action.view_id).pipe(
          map((view) => {
            return UserViewActions.deleteViewSuccess({
              view,
              // success_message: 'User has been updated.',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserViewActions.deleteViewFailure({ view_id: action.view_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserViewActions.deleteViewFailure(error)))
    );
  });
}
