import { ICoreActivityHistoryWebhookEvent, IHttpException } from '@launchpoint/core-types';

export interface WebhookEventsEntityState {
  event_id: string;
  webhook_event: ICoreActivityHistoryWebhookEvent;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string;
}
