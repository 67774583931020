import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientNotificationsUserNotificationBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';

@Component({
  selector: 'launchpoint-notifications-user-notifications-toolbar',
  templateUrl: './notifications-user-notifications-toolbar.component.html',
  styleUrls: ['./notifications-user-notifications-toolbar.component.scss'],
})
export class LaunchpointCoreWebNotificationUserNotificationsToolbarComponent extends LaunchpointCoreClientNotificationsUserNotificationBaseComponent {
  private _swal = inject(LaunchpointSweetAlertService);

  options: IToolbarConfig = {
    title: 'User Notifications',
    formControl: this.form_control,
    create: false,
    searchPlaceholder: 'Search by title, body, or event',
  };
  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
