import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationsHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebNotificationsTagifyBaseWebComponent } from '../notifications-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [NotificationsHTTPService],
  selector: 'launchpoint-notifications-tagify',
  templateUrl: 'notifications-tagify.component.html',
})
export class LaunchpointCoreWebNotificationsTagifyWebComponent extends LaunchpointCoreWebNotificationsTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
