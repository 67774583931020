import { IAttributeDimension, IMetricDimension, ISetDimension } from '../pinpoint-campaign/aws-campaign.interface';

export enum ESegmentSourceType {
  ALL = 'ALL',
  ANY = 'ANY',
  NONE = 'NONE',
}

export enum ESegmentBehaviorRecencyType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ESegmentBehaviorDuration {
  DAY_14 = 'DAY_14',
  DAY_30 = 'DAY_30',
  DAY_7 = 'DAY_7',
  HR_24 = 'HR_24',
}

// implements SegmentBehaviors
export class ISegmentBehaviors {
  recency?: {
    duration: ESegmentBehaviorDuration;
    recency_type: ESegmentBehaviorRecencyType;
  };
}

// implements SegmentDemographics
export class ISegmentDemographics {
  app_version?: ISetDimension;
  channel?: ISetDimension;
  device_type?: ISetDimension;
  make?: ISetDimension;
  model?: ISetDimension;
  platform?: ISetDimension;
}
// implements SegmentLocation
export class ISegmentLocation {
  country?: ISetDimension;
  gps_point?: {
    coordinates?: {
      latitude: number;
      longitude: number;
    };
    range_in_kilometers?: number;
  };
}

//  implements SegmentDimensions
export class ISegmentDimensions {
  attributes?: Record<string, IAttributeDimension>;
  behavior?: ISegmentBehaviors;
  demographic?: ISegmentDemographics;
  location?: ISegmentLocation;
  metrics?: Record<string, IMetricDimension>;
  user_attributes?: Record<string, IAttributeDimension>;
}

// implements SegmentReference
export class ISegmentReference {
  id: string;
  version?: number;
}

// implements SegmentGroup
export class ISegmentGroup {
  source_type?: ESegmentSourceType;
  dimensions?: ISegmentDimensions[];
  source_segments?: ISegmentReference[];
  type?: ESegmentSourceType;
}

// implements SegmentGroupList
export class ISegmentGroupList {
  groups: ISegmentGroup[];
  include?: ESegmentSourceType;
}

// implements SegmentImportResource
export class ISegmentImportResource {
  channel_counts?: Record<string, number>;
  external_id: string;
  format: 'CSV' | 'JSON';
  role_arn: string;
  size: number;
  s3_url: string;
}

export enum ESegmentType {
  DIMENSIONAL = 'DIMENSIONAL',
  IMPORT = 'IMPORT',
}
