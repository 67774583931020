import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICoreUser, ICoreUserIDMeParamsUpdate, ICoreUserIDMeResponseStatus } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-id-me-admin-override',
  templateUrl: './id-me-admin-override.component.html',
})
export class AdminCoreUserIDMeComponent {
  @Input() user: ICoreUser;
  @Output() override = new EventEmitter<ICoreUserIDMeParamsUpdate>();
  forcedIDme: ICoreUserIDMeParamsUpdate;
  confirmResponder: boolean;
  confirmMilitary: boolean;
  /**
   *
   * @param overrideType is group assigned, function applies group assigned and generates fake idme id with users name information
   * @param event is element clicked so button can change text to added
   */
  overrideIDme(overrideType: string, event: any): void {
    const IDMeGroups = {
      group: overrideType,
      subgroups: [`admin ${overrideType}`], // Subgroup is made up admin military, admin responder so can filter it.
      verified: false, // false because admin forced
    } as ICoreUserIDMeResponseStatus;

    const fakeUUID = `${this.user?.profile?.last_name}${this.user._id}`;

    const forcedIDme: ICoreUserIDMeParamsUpdate = {
      user_id: this.user._id,
      id_me_uuid: fakeUUID,
      id_me_groups: [IDMeGroups],
    };

    const button = document.getElementById(overrideType) as HTMLElement;
    button.classList.remove('status-primary');
    button.classList.add('status-success');

    event.target.innerText = `${overrideType} Added`;
    this.override.emit(forcedIDme);
  }
}
