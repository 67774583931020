import { Directive, EventEmitter, Output } from '@angular/core';
import { IQuerySort } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { updateAllWebhookEventsPagination, updateAllWebhookEventsQuery } from '../+state/actions/webhook-events.actions';
import { LaunchpointCoreClientWebhookEventsBaseComponent } from './webhook-events.base-component';

@Directive()
export abstract class LaunchpointCoreClientWebhookEventsTableBaseComponent extends LaunchpointCoreClientWebhookEventsBaseComponent {
  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() delete = new EventEmitter<string>();

  updateQuery(search: string) {
    this._Store.dispatch(
      updateAllWebhookEventsQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      updateAllWebhookEventsPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllWebhookEventsPagination({ pagination: { ...pagination, limit } }));
  }

  sortData(event) {
    const sortedData: IQuerySort = {
      active: event.active,
      direction: event.direction,
    };
    this.sortDataEmitter.emit(sortedData);
  }

  deleteById(id: string) {
    this.delete.emit(id);
  }
}
