import { Component } from '@angular/core';
import { LaunchpointSweetAlertService } from '../../../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-app-tables-widget14',
  templateUrl: './tables-widget14.component.html',
  styleUrls: ['./tables-widget14.component.scss'],
})
export class TablesWidget14Component {
  constructor(private _swalService: LaunchpointSweetAlertService) {
    //
  }
  // ngOnInit(): void {}
}
