<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">
    <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-uppercase fs-7 text-gray-700">
          <th class="min-w-150px">Company Name</th>
          <th class="min-w-150px">Company Email</th>
          <th class="min-w-120px">Company Phone</th>
          <th class="min-w-120px">Account Owner</th>
          <th class="min-w-100px">Account Owner Email</th>
          <th class="min-w-120px">Account Type</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">
        <tr *ngIf="(loading$ | async) === false && (companyEntitys$ | async)?.length === 0">
          <td>No Companies</td>
        </tr>

        <tr *ngFor="let entity of companyEntitys$ | async" [routerLink]="'./' + entity?.company?._id" routerLinkActive="active">
          <ng-container *ngIf="entity">
            <!-- Company Info -->
            <td>
              <a [routerLink]="'./' + entity?.company?._id" routerLinkActive="active" class="text-dark text-hover-primary">
                {{ entity?.company?.company_name }}
              </a>
            </td>

            <td>
              {{ entity?.company?.email }}
            </td>
            <td>
              {{ entity?.company?.phone | phoneFormat }}
            </td>

            <!-- Account Owner Info -->
            <td>
              {{ entity?.company?.account_owner?.profile?.full_name }}
            </td>
            <!-- Email -->
            <td>
              {{ entity?.company?.account_owner?.email }}
            </td>

            <td>
              {{ account_type(entity) | titlecase }}
            </td>
          </ng-container>
        </tr>
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->

    <!---: Pagination Begins -->
    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
    <!---: Pagination Ends -->
  </div>
</div>
