<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header border-0">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ _config.title }}</h3>
        </div>
      </div>

      <div class="card-body border-top p-9">
        <div *ngFor="let role of security_roles; let i = index" class="row mb-6">
          <div class="col-lg-8">
            <div class="row">
              <div class="col">
                <label class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6">
                  <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                    <input (change)="setUserRole($event, role)" [checked]="userHasRole(role)" class="form-check-input"
                      type="checkbox" [id]="'role' + i" name="security_roles" [value]="role" />
                  </span>

                  <span class="ms-5">
                    <span class="fs-4 fw-bold text-gray-800 d-block">{{ role | removeUnderscore | titlecase }}</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>