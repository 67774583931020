<ng-container *ngIf="user.profile.id_me_uuid; else notMilitary">
  <p class="float-left"><i class="fas fa-ribbon text-primary"></i> Military or First Responder</p>
</ng-container>
<ng-template #notMilitary>
  <ng-container *ngIf="!confirmMilitary">
    <button
      id="military"
      class="btn btn-outline-primary mr-4"
      (click)="confirmMilitary = true"
      [disabled]="forcedIDme && forcedIDme.id_me_groups[0].subgroups[0] === 'admin responder'"
    >
      <i class="fas fa-ribbon"></i>
      Add Military
    </button>
  </ng-container>
  <ng-container *ngIf="confirmMilitary">
    <button
      id="military"
      class="btn btn-success mr-4"
      (click)="overrideIDme('military', $event)"
      [disabled]="forcedIDme && forcedIDme.id_me_groups[0].subgroups[0] === 'admin responder'"
    >
      <i class="fas fa-ribbon"></i>
      Confirm add Military
    </button>
  </ng-container>
  <ng-container *ngIf="!confirmResponder">
    <button
      id="responder"
      class="btn btn-outline-primary"
      (click)="confirmResponder = true"
      [disabled]="forcedIDme && forcedIDme.id_me_groups[0].subgroups[0] === 'admin military'"
    >
      <i class="fas fa-first-aid"></i>
      Add First Responder
    </button>
  </ng-container>
  <ng-container *ngIf="confirmResponder">
    <button
      id="responder"
      class="btn btn-success"
      (click)="overrideIDme('responder', $event)"
      [disabled]="forcedIDme && forcedIDme.id_me_groups[0].subgroups[0] === 'admin military'"
    >
      <i class="fas fa-first-aid"></i>
      Confirm add Responder
    </button>
  </ng-container>
</ng-template>
