import { Directive, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  ENotificationMessageType,
  ICoreUser,
  ILaunchpointSearchFilterDates,
  INotification,
  INotificationMessage,
  IUserNotificationSearchQuery,
} from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { updateAllUserNotificationQuery } from '../+state';
import { LaunchpointCoreClientNotificationsUserNotificationBaseComponent } from './notifications-user-notification-base.component';

@Directive()
export abstract class LaunchpointCoreClientUserNotificationsFilterBaseComponent
  extends LaunchpointCoreClientNotificationsUserNotificationBaseComponent
  implements OnInit
{
  public fb = inject(UntypedFormBuilder);
  tagifyService = inject(TagifyService);

  form: FormGroup<{
    notifications: FormControl<{ value: string; data: INotification }[]>;
    messages: FormControl<{ value: string; data: INotificationMessage }[]>;
    events: FormControl<{ value: ENotificationMessageType; data: ENotificationMessageType }[]>;
    users: FormControl<{ value: string; data: ICoreUser }[]>;
    delivered: FormControl<boolean>;
    is_read: FormControl<boolean>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
  }>;

  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistEvents$ = new BehaviorSubject<string[]>(Object.values(this._config.events));

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      notifications: [],
      messages: [],
      events: [],
      users: [],
      delivered: [],
      is_read: [],
      created_at: [],
    });
  }

  setUser(event: ICoreUser[]) {
    const values = event.map((e) => ({ value: e._id, data: e }));
    this.form.controls.users.setValue(values);
  }
  setNotification(event: INotification<any>[]) {
    const values = event.map((e) => ({ value: e._id, data: e }));
    this.form.controls.notifications.setValue(values);
  }
  setMessage(event: INotificationMessage[]) {
    const values = event.map((e) => ({ value: e._id, data: e }));
    this.form.controls.messages.setValue(values);
  }
  setRead(event: boolean | null) {
    this.form.controls.is_read.setValue(event);
  }
  setDelivered(event: boolean | null) {
    this.form.controls.delivered.setValue(event);
  }

  reset() {
    this.form.reset();
  }

  submit() {
    const data = this.form.value;

    const query: IUserNotificationSearchQuery = {
      users: data?.users?.map((t) => t?.value),
      events: data?.events?.map((t) => t?.value),
      messages: data?.messages?.map((t) => t?.value),
      notifications: data?.notifications?.map((t) => t?.value),
      delivered: data?.delivered,
      is_read: data?.is_read,
      created_at: data?.created_at,
    };

    this.removeNullUndefined(query);

    this._Store.dispatch(updateAllUserNotificationQuery({ query }));
  }
}
