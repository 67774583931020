import { Directive, Input } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '../base/base.component';

@Directive()
export abstract class ToolbarBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() title = 'Documents';
  @Input() subtitle = '';

  constructor() {
    super();
  }
}
