import { Component, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BlogsActions, LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { ICoreBlog, ICoreBlogRelatedPostParams, ICoreBlogSearchQuery } from '@launchpoint/core-types';
import { TagifyService } from 'ngx-tagify';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog-related-posts',
  templateUrl: './blog-related-posts.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogRelatedPostsComponent extends LaunchpointCoreClientBlogBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);
  _tagifyService = inject(TagifyService);

  query: ICoreBlogSearchQuery;

  form: FormGroup<{
    related_posts: FormControl<string[]>;
  }>;

  override buildForm(): void {
    this.form = this.fb.group({
      related_posts: [],
    });
  }

  override loadForm(): void {
    const posts = this.blog.related_posts?.map((post) => ({
      value: post.title,
      data: post,
    }));

    this.query = {
      exclude_ids: [this.blog._id],
    };

    const related_posts = this.blog.related_posts?.map((post) => post._id);

    this.form.patchValue({ related_posts });

    setTimeout(() => {
      this._tagifyService.get('blogs').loadOriginalValues(posts);
    }, 200);
  }

  setBlogs(data: ICoreBlog[]): void {
    const _ids = data.map((post) => post._id);
    this.form.controls.related_posts.setValue(_ids);
  }

  reset() {
    this.form.reset();
    this.loadForm();
  }

  save() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;
    const data: ICoreBlogRelatedPostParams = {
      _id: this.blog._id,
      related_post_ids: form.related_posts,
    };

    this._Store.dispatch(BlogsActions.setRelatedPosts({ data }));
  }

  deleteRelatedPopup(post: ICoreBlog) {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this related post from this blog?',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, close this.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            BlogsActions.removeRelatedPosts({
              data: {
                _id: this.blog._id,
                related_post_ids: [post._id],
              },
            })
          );
        }
      });
  }
}
