<div class="row" *ngIf="pagination">

  <pre *ngIf="debug" [innerHTML]="pagination | json"></pre>

  <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start mt-4">
    <div>
      <label>
        <select class="form-select form-select-sm form-select-solid" [ngModel]="pagination.limit"
          (ngModelChange)="setLimit($event)">
          <option *ngFor="let limit of pageOptions" [value]="limit">{{ limit }}</option>
        </select>
      </label>
    </div>
  </div>
  <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-4">
    <ngb-pagination [collectionSize]="pagination.count" [page]="pagination.pageIndex + 1" [pageSize]="pagination.limit"
      (pageChange)="setPage($event)" [maxSize]="5" [rotate]="true" [ellipses]="false"
      [boundaryLinks]="true"></ngb-pagination>
  </div>
</div>