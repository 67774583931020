import { IHttpException, IQueryPageination, IQuerySort, ITopicSearchQuery } from '@launchpoint/core-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as TopicActions from '../actions/topics.actions';
import { TopicsEntityState } from '../interfaces/topics-entity.interface';

export const TopicFeatureKey = 'TopicReducer';

export interface TopicState extends EntityState<TopicsEntityState> {
  selected_topic_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ITopicSearchQuery;
  create_modal: boolean;
}

export const TopicEntityAdaptor: EntityAdapter<TopicsEntityState> = createEntityAdapter<TopicsEntityState>({
  selectId: (topic) => topic.topic_id,
});

export const initialTopicState: TopicState = TopicEntityAdaptor.getInitialState({
  // set initial required properties
  selected_topic_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: null },
  error: null,
  success_message: null,
  create_modal: false,
});

export const TopicReducer = createReducer(
  initialTopicState,

  // Get by ID

  on(TopicActions.getTopicById, (state, { topic_id }) => {
    const entity: TopicsEntityState = {
      topic_id,
      topic: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return TopicEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_topic_id: topic_id,
      loaded: true,
      loading: false,
    });
  }),

  on(TopicActions.getTopicByIdSuccess, (state, { topic }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: topic._id,
        changes: {
          topic,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
        selected_topic_id: topic._id,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(TopicActions.getTopicByIdFailure, (state, { topic_id, error }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: topic_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
        loading: false,
        loaded: true,
      }
    );
  }),

  // Search

  on(TopicActions.searchAllTopics, (state) => {
    return TopicEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_Topic: null,
    });
  }),
  on(TopicActions.searchAllTopicsSuccess, (state, { data }) => {
    const TopicEntities = data.data.map((topic) => {
      const TopicEntity: TopicsEntityState = {
        topic_id: topic._id,
        topic: topic,
        loaded: true,
        loading: false,
        error: null,
      };
      return TopicEntity;
    });
    return TopicEntityAdaptor.addMany(TopicEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(TopicActions.searchAllTopicsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_Topic: null,
  })),

  // Create

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(TopicActions.createTopic, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),

  on(TopicActions.createTopicSuccess, (state, { data, success_message }) => {
    const entity: TopicsEntityState = {
      topic_id: data._id,
      topic: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return TopicEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      create_modal: false,
      selected_topic_id: data._id,
    });
  }),

  on(TopicActions.createTopicFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),

  // Update

  on(TopicActions.updateTopic, (state, { data }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(TopicActions.updateTopicSuccess, (state, { topic, success_message }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: topic._id,
        changes: {
          topic,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(TopicActions.updateTopicFailure, (state, { topic_id, error }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: topic_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Delete

  on(TopicActions.deleteTopic, (state, { id }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
        loading: true,
        loaded: false,
      }
    );
  }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(TopicActions.deleteTopicSuccess, (state, { data, success_message }) => {
    return TopicEntityAdaptor.removeOne(data._id, {
      ...state,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
    });
  }),
  on(TopicActions.deleteTopicFailure, (state, { topic_id, error }) => {
    return TopicEntityAdaptor.updateOne(
      {
        id: topic_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  //Mecanics

  on(TopicActions.updateAllTopicQuery, (state, { query }) => {
    return TopicEntityAdaptor.removeAll({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(TopicActions.updateAllTopicPagination, (state, { pagination }) => {
    return TopicEntityAdaptor.removeAll({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(TopicActions.updateAllTopicPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loading: true,
    loaded: false,
    error: null,
    success_message: null,
  })),

  on(TopicActions.updateAllTopicSorting, (state, { querySort }) => {
    return TopicEntityAdaptor.removeAll({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      success_message: null,
      querySort,
    });
  })
);
