<div class="modal-header  justify-content-end">
  <h4 class="modal-title">Create Message</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div ngbAccordion #accordion="ngbAccordion" [closeOthers]="true">
    <div ngbAccordionItem="first" #first="ngbAccordionItem" [collapsed]="false">
      <div ngbAccordionHeader class="accordion-button custom-header">
        <p ngbAccordionToggle class="m-0 fw-bold fs-2">
          <span class="rounded-circle px-4 py-2 bg-primary me-4"><span class="text-white">1</span></span>Admin Details
        </p>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="px-10 py-10">
              <div class="px-10">
                <form [formGroup]="form">
                  <div class="form-group mb-10">
                    <label class="fw-bold mb-2 required">Title</label>
                    <input type="text" class="form-control" placeholder="Name" formControlName="title" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Title is required',
                      control: form.controls.title
                    }"></ng-container>
                  </div>
                  <div class="form-group mb-10">
                    <label class="fw-bold mb-2">Description</label>
                    <textarea type="text" class="form-control min-h-150px" placeholder="Internal Details"
                      formControlName="description"></textarea>
                  </div>
                  <div class="form-group d-flex justify-content-between mb-5">
                    <label class="fw-bold mb-2 required">Message Type</label>
                    <div>
                      <input type="checkbox" class="form-check-input" [checked]="selectAll"
                        (change)="selectAllChanged($event)" />
                      <label class="form-check-label ms-4" for="selectAll">Select All</label>
                    </div>
                  </div>
                </form>

                <div class="d-flex justify-content-around flex-wrap mb-10">
                  @for (messageType of messageTypes; track messageType ) {
                  <input type="checkbox" class="btn-check" [disabled]="!messageType.active" [id]="messageType.id"
                    [(ngModel)]="messageType.checked" />
                  <label class="btn btn-outline-primary border h-125px w-125px" [for]="messageType.id">
                    <div class="mt-2">
                      <div class="mt-5 ms-2"><i class="bi bi-{{ messageType.icon }} fs-1 mb-1"></i></div>
                      <div class="mt-2">
                        <span class="fw-light" style="font-size: 12px">{{ messageType.id }}</span>
                      </div>
                    </div>
                  </label>
                  }

                </div>

                <div class="d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary" [disabled]="form.invalid || form.pristine"
                    (click)="submitTest(); accordion.toggle('second')" [disabled]="!atLeastOneMessageTypeSelected()">
                    Next
                  </button>
                </div>
              </div>
            </div>

          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem="second">
      <div ngbAccordionHeader class="accordion-button custom-header">
        <p ngbAccordionToggle class="m-0 fw-bold fs-2">
          <span class="rounded-circle px-4 py-2 bg-primary me-4"><span class="text-white">2</span></span>Message
          Content
        </p>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="px-10 py-10">
              <div class="px-10">
                <div class="d-flex gap-5 mb-10">
                  @for (selectedMessageType of getSelectedMessageTypes(); track selectedMessageType) {
                  <ng-container *ngIf="smsForm.invalid">
                    <div class="btn btn-outline-primary border h-125px w-125px position-relative"
                      [ngClass]="selectedMessageType.value === selectedChannel ? 'border-primary' : ''"
                      (click)="activeChannel(selectedMessageType.value)">
                      <i class="bi bi-circle rounded bg-white position-absolute top-0 end-0 fs-3"
                        style="transform: translate(50%, -50%)"></i>

                      <div class="mt-2">
                        <div class="mt-5 ms-2"><i class="bi bi-{{ selectedMessageType.icon }} fs-1 mb-1"></i>
                        </div>
                        <div class="mt-2">
                          <span class="fw-light" style="font-size: 12px">{{ selectedMessageType.id }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="smsForm.valid">
                    <div class="btn btn-outline-success border h-125px w-125px position-relative"
                      [ngClass]="selectedMessageType.value === selectedChannel ? 'border-success' : ''"
                      (click)="activeChannel(selectedMessageType.value)">
                      <i class="bi bi-check-circle-fill rounded bg-white position-absolute top-0 end-0 fs-3"
                        style="transform: translate(50%, -50%)"></i>

                      <div class="mt-2">
                        <div class="mt-5 ms-2"><i class="bi bi-{{ selectedMessageType.icon }} fs-1 mb-1"></i>
                        </div>
                        <div class="mt-2">
                          <span class="fw-light" style="font-size: 12px">{{ selectedMessageType.id }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  }
                </div>
                @switch (selectedChannel) {
                @case (NotificationChannelType.SMS) {
                <form [formGroup]="smsForm">
                  <div class="form-group mb-10">
                    <label class="fw-bold mb-2">Title <span class="fw-light fst-italic">(Admin View
                        Only)</span></label>
                    <input type="text" class="form-control" placeholder="Name" formControlName="topic" />
                  </div>
                  <div class="form-group mb-5">
                    <label class="fw-bold mb-2">Description <span class="fw-light fst-italic">(Admin View
                        Only)</span></label>

                    <textarea type="text" class="form-control min-h-150px" placeholder="Message..."
                      formControlName="message"></textarea>

                  </div>
                  <div class="d-flex">
                    <div class="mb-20 pb-20 me-10">
                      <a role="button"><i class="bi bi-plus-circle-fill fs-3"></i> <span class="ms-3">User
                          Property</span></a>
                    </div>
                    <div class="mb-20 pb-20">
                      <a role="button"><i class="bi bi-plus-circle-fill fs-3"></i> <span
                          class="ms-3">Username</span></a>
                    </div>
                  </div>
                  <ng-container *ngIf="smsForm.valid">Valid</ng-container>
                  <ng-container *ngIf="smsForm.invalid">inValid</ng-container>
                </form>
                }
                @case (NotificationChannelType.SLACK) {
                <div class="form-group mb-5">
                  <label class="fw-bold mb-2">Slack Message</label>
                  <div class="message-box">
                    <textarea class="message-input" placeholder="Type a message..."></textarea>
                    <div class="attachments">
                      <label for="attachment" class="attachment-label bg-primary">
                        <i class="bi bi-paperclip text-white"></i> Add Attachment
                      </label>
                      <input id="attachment" type="file" class="attachment-input bg-primary" accept="image/*" />
                    </div>
                  </div>
                </div>
                }
                @case (NotificationChannelType.PUSH) {
                <form [formGroup]="pushForm">
                  <div class="form-group mb-10">
                    <label class="fw-bold mb-2">Title</label>
                    <input type="text" class="form-control" placeholder="Title" formControlName="title" />
                  </div>
                </form>
                }
                @case (NotificationChannelType.EMAIL) {
                <form [formGroup]="emailForm">
                  <div class="form-group mb-5">
                    <label class="fw-bold mb-2 required">Topic</label>
                    <launchpoint-notifications-topics-search-ahead (selectedTopic)="setTopic($event)" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Topic is required',
                      control: emailForm.controls.topic
                    }"></ng-container>
                  </div>
                  <div class="form-group mb-5">
                    <label class="fw-bold mb-2 required">From</label>
                    <input type="email" class="form-control" placeholder="From" formControlName="from" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'From Email Address is required',
                      control: emailForm.controls.from
                    }"></ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'email',
                        message: 'From must be a valid email',
                        control: emailForm.controls.from
                    }"></ng-container>
                  </div>

                  <div class="form-group mb-5">
                    <label class="fw-bold mb-2 required">Reply To</label>
                    <input type="email" class="form-control" placeholder="Reply To" formControlName="replyTo" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Reply To is required',
                      control: emailForm.controls.replyTo
                    }"></ng-container>
                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                        validation: 'email',
                        message: 'Reply To must be a valid email',
                        control: emailForm.controls.replyTo
                    }"></ng-container>


                    <div class="cc-bcc-labels mb-2 ms-1">
                      <a class="me-1" role="button" (click)="isBccVisible = !isBccVisible">BCC</a>
                      <a class="ms-1" role="button" (click)="isCcVisible =!isCcVisible">CC</a>
                    </div>
                    <input *ngIf="isBccVisible" type="text" class="form-control mb-2" placeholder="BCC"
                      formControlName="bcc" />
                    <input *ngIf="isCcVisible" type="text" class="form-control" placeholder="CC" formControlName="cc" />
                  </div>
                  <div class="form-group mb-10">
                    <label class="fw-bold mb-2 required">Subject</label>
                    <input type="text" class="form-control" placeholder="Subject" formControlName="subject" />

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Subject is required',
                      control: emailForm.controls.subject
                    }"></ng-container>
                  </div>
                  <div class="form-group mb-5">
                    <label class="fw-bold mb-2 required">Email</label>
                    <editor formControlName="text" [apiKey]="apiKey" [init]="config"></editor>

                    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Email message is required',
                      control: emailForm.controls.text
                    }"></ng-container>
                  </div>
                </form>
                }
                }

                <div class="d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary" [disabled]="emailForm.invalid"
                    (click)="accordion.toggle('third')">Next</button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem="third" #third="ngbAccordionItem">
      <div ngbAccordionHeader class="accordion-button custom-header">
        <p ngbAccordionToggle class="m-0 fw-bold fs-2">
          <span class="rounded-circle px-4 py-2 bg-primary me-4"><span class="text-white">3</span></span>Select
          Time/Date
        </p>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="px-10 py-10">
              <div class="d-flex justify-content-center px-20">
                <div class="form-group mb-10 btn-group btn-group-toggle border border-primary">
                  <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off" checked
                    (click)="nav.select(1)" />
                  <label class="btn btn-outline-primary" for="option1">Publish Now</label>

                  <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off"
                    (click)="nav.select(2)" />
                  <label class="btn btn-outline-primary" for="option2">Schedule Later</label>
                </div>
              </div>

              <ul ngbNav #nav="ngbNav" [(activeId)]="active" (navChange)="onNavChange($event)">
                <li [ngbNavItem]="1">
                  <ng-template ngbNavContent>
                    <div>
                      <div class="form-group my-10 px-20">
                        <label class="fw-bold mb-2">When would you like the message to post?</label>
                        <select class="form-select" aria-label="Default select example">
                          <option value="Right Away">Right Away</option>
                          <option value="Custom">Custom</option>
                        </select>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <ng-template ngbNavContent>
                    <div class="px-10 py-10">
                      <div class="px-10">
                        <div class="form-group mb-10">
                          <div class="d-flex justify-content-between">
                            <label class="fw-bold mb-2">Date Selector</label>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                              <label class="form-check-label" for="flexCheckDefault"> No End Date </label>
                            </div>
                          </div>
                          <!-- <input type="text" class="form-control" placeholder="Name" /> -->
                          <launchpoint-date-range></launchpoint-date-range>
                        </div>
                        <div class="form-group mb-10 d-flex justify-content-between">
                          <div style="width: 45%">
                            <label class="fw-bold mb-2">Select Time</label>
                            <input type="time" class="form-control" placeholder="Name" />
                          </div>
                          <div style="width: 45%">
                            <label class="fw-bold mb-2">Time Zone</label>
                            <select class="form-select" aria-label="Default select example">
                              <option *ngFor="let tz of timeZone" value="{{ tz.label }}">{{ tz.label }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group mb-10" style="width: 45%">
                          <label class="fw-bold mb-2">Date Selector</label>
                          <select class="form-select" aria-label="Default select example">
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-primary" (click)="accordion.toggle('fourth')">Next</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div ngbAccordionItem="fourth" #fourth="ngbAccordionItem">
      <div ngbAccordionHeader class="accordion-button custom-header">
        <p ngbAccordionToggle class="m-0 fw-bold fs-2">
          <span class="rounded-circle px-4 py-2 bg-primary me-4"><span class="text-white">4</span></span>Select
          Audience
        </p>
        <p *ngIf="third.disabled" class="text-muted m-0 small">[I'm&nbsp;disabled]</p>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div class="px-10 py-10">
              <div class="px-10">
                <div class="form-check mb-5">
                  <input class="form-check-input me-5" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label class="form-check-label" for="flexRadioDefault1"> Dynamic (people who match rules now and
                    in the future) </label>
                </div>
                <div class="form-check mb-10">
                  <input class="form-check-input me-5" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                    checked />
                  <label class="form-check-label" for="flexRadioDefault2"> Fixed (only people who match rules right
                    now) </label>
                </div>

                <div class="form-group d-flex justify-content-between mb-4">
                  <div style="width: 45%">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Users</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div style="width: 45%">
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Select Rule</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="mb-20 pb-20">
                  <button type="button" class="btn btn-sm"><i class="bi bi-plus-circle-fill fs-3"
                      (click)="addRule()"></i>
                    <span class="ms-3">Add Rule</span>
                  </button>
                </div>
                <div class="bg-light d-flex align-content-center mt-20 mb-10">
                  <p class="ps-10 mt-4">You will reach <strong>0</strong> users</p>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="submit" class="btn btn-primary">Preview Message</button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>


<!--start::FormError-->
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container
    *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
<!--end::FormError-->