import { Directive } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';

@Directive()
export abstract class CompanyProductsBaseComponent extends LaunchpointCoreClientBaseComponent {
  public text = 'CompanyProducts';

  constructor() {
    super();
  }
}
