<div class="d-flex flex-column flex-root" id="kt_app_root" style="height: 100vh;">
  <!--begin::Authentication - Sign-up -->
  <div class="d-flex flex-column justify-content-between flex-lg-row flex-column-fluid">
    <!--begin::Body-->
    <div class="d-flex flex-column justify-content-between flex-lg-row-fluid h-100 w-lg-50 p-10" [ngClass]="{
      'order-2 order-lg-1': imageRight,
      'order-1 order-lg-2': !imageRight
    }">
      <router-outlet></router-outlet>
      <!--begin::Footer-->
      <div class="d-flex flex-center flex-wrap px-5">
        <!--begin::Links-->
        <div class="d-flex fw-semibold text-primary fs-base" *ngIf="_config?.footers">
          <a *ngFor="let footer of _config?.footers" [href]="footer.href" class="px-5" target="_blank">{{ footer.title
            }}</a>
        </div>
        <!--end::Links-->
      </div>
      <!--end::Footer-->
    </div>

    <!--end::Body-->
    <!--begin::Aside-->
    <div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center" [ngClass]="{
      'order-1 order-lg-2': imageRight,
      'order-2 order-lg-1': !imageRight
    }" [style.background-image]="'url(' + _config.auth.background_image + ')'">
      <!--begin::Content-->
      <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
        <!--begin::Logo-->
        <a routerLink="/" class="mb-0 mb-lg-12" *ngIf="_config?.logo_dark || _config?.logo_light">
          <img alt="Logo" [src]="_config?.logo_light" class="theme-light-show h-60px h-lg-75px" />
          <img alt="Logo" [src]="_config?.logo_dark" class="theme-dark-show h-60px h-lg-75px" />
        </a>
        <!--end::Logo-->
        <!--begin::Image-->
        <ng-container *ngIf="_config?.auth?.logo">
          <img class="d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" [src]="_config?.auth?.logo"
            alt="Authentication" />
        </ng-container>
        <!--end::Image-->
        <!--begin::Title-->
        <h1 class="d-lg-block text-white fs-2qx fw-bolder text-center mb-7">{{ _config?.auth?.header }}</h1>
        <!--end::Title-->
        <!--begin::Text-->
        <div class="d-lg-block text-white fs-base text-center">
          {{ _config?.auth?.description }}
        </div>
        <!--end::Text-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Aside-->
  </div>
  <!--end::Authentication - Sign-up-->
</div>