<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">


    <launchpoint-toolbar [options]="options" (create)="open()" />


    <div class="card">
      <div class="card-body">
        <launchpoint-media-categories-table />
      </div>

    </div>
  </div>
</div>