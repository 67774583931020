import { StateUserReducer, USER_AUTH_REDUCERS, USER_PROFILE_SWAP_REDUCERS, UserAuthActions, authLocalStorageToken } from '@launchpoint/core-client';
import { IAuthenticationResponse } from '@launchpoint/core-types';
import { createReducer, on } from '@ngrx/store';

export const initialUserState: StateUserReducer = {
  initCheckComplete: false,

  accessToken: localStorage.getItem(authLocalStorageToken)
    ? (JSON.parse(localStorage.getItem(authLocalStorageToken)) as IAuthenticationResponse).accessToken
    : null,
  refreshToken: localStorage.getItem(authLocalStorageToken)
    ? (JSON.parse(localStorage.getItem(authLocalStorageToken)) as IAuthenticationResponse).refreshToken
    : null,
  expiresIn: localStorage.getItem(authLocalStorageToken)
    ? (JSON.parse(localStorage.getItem(authLocalStorageToken)) as IAuthenticationResponse).expiresIn
    : null,

  loading: false,
  returnUrl: null,
  user: null,

  error: {
    action: null,
    error: null,
  },
  success: {
    action: null,
    message: null,
  },
};

export const USER_AUTH_LOCAL_REDUCERS: any = [
  ...USER_AUTH_REDUCERS,
  ...USER_PROFILE_SWAP_REDUCERS,
  on(UserAuthActions.loginSuccess, (state: StateUserReducer, { returnUrl, auth }) => loginSuccess(state, auth, returnUrl)),
  on(UserAuthActions.userCodeVerifySuccess, (state: StateUserReducer, { auth, returnUrl }) => loginSuccess(state, auth, returnUrl)),
  on(UserAuthActions.getUserNewUserFromRefreshTokenSuccess, (state: StateUserReducer, { auth }) => loginSuccess(state, auth, null)),
  on(UserAuthActions.logout, (state: StateUserReducer) => logout(state)),
  on(UserAuthActions.createUserSuccess, (state: StateUserReducer, { auth, returnUrl }) => createUserSuccess(state, auth, returnUrl)),
];

export const userReducer = createReducer(initialUserState, ...USER_AUTH_LOCAL_REDUCERS);
/**
 * FUNCTIONS
 * These functions abstract the localStorage api
 * Eventually I'm hoping someone smarter than me can inject these, maybe from `environment`
 * so this file can be shared with the nativescript version. Which would inject the setString api
 * instead of localStorage.
 */

/**
 * Removes Item from Local Storage On action
 * function name matches action name
 */
function logout(state: StateUserReducer): StateUserReducer {
  try {
    localStorage.removeItem(authLocalStorageToken);
  } catch (e) {
    console.error('Error removing auth token from local storage', e);
  }
  return {
    ...state,
    loading: true,
    error: null,
  };
}

/**
 * Adds Item from Local Storage On action
 * function name matches action name
 */
function loginSuccess(state: StateUserReducer, auth: IAuthenticationResponse | { message: string }, returnUrl?: string[] | null): StateUserReducer {
  if ((auth as { message: string })?.message) {
    // console.log('(auth as { message: string })?.message');
    return;
  }

  localStorage.removeItem(authLocalStorageToken);
  // console.log(' localStorage.removeItem');
  localStorage.setItem(authLocalStorageToken, JSON.stringify(auth));
  // console.log('localStorage.setItem');
  return {
    ...state,
    returnUrl,
    accessToken: (auth as IAuthenticationResponse).accessToken,
    refreshToken: (auth as IAuthenticationResponse).refreshToken,
    expiresIn: (auth as IAuthenticationResponse).expiresIn,
    error: null,
    success: null,
    loading: false,
  };
}

/**
 * Adds Item from Local Storage On action
 * function name matches action name
 */
function createUserSuccess(state: StateUserReducer, auth: IAuthenticationResponse, returnUrl?: string[] | null): StateUserReducer {
  localStorage.removeItem(authLocalStorageToken);
  localStorage.setItem(authLocalStorageToken, JSON.stringify(auth));

  return {
    ...state,
    accessToken: auth.accessToken,
    refreshToken: auth.refreshToken,
    expiresIn: auth.expiresIn,
    initCheckComplete: true,
    error: null,
    success: null,
    returnUrl,
    loading: false,
  };
}
