import { ICoreUser, IHttpException } from '@launchpoint/core-types';
import { LaunchpointCoreClientProfileConfig } from '../../../user/core/core-profile/interfaces/core-profile.interface';

export interface UsersEntityState {
  user_id: string;
  user: ICoreUser | any;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  profile_config?: LaunchpointCoreClientProfileConfig;
  success_message?: string;
}
