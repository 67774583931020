<ng-container>
  <div [ngClass]="footerContainerCssClasses" class="d-flex flex-column flex-md-row align-items-center justify-content-between">
    <!-- begin::Copyright  -->
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted fw-bold me-2">{{ currentDateStr }} &copy;</span>
      <a class="text-gray-800 text-hover-primary cursor-pointer"> {{ footerConfig?.title || 'Launchpoint' }} </a>
    </div>
    <!-- end::Copyright  -->

    <!-- begin::Nav  -->
    <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
      <li class="menu-item" *ngFor="let link of footerConfig?.links">
        <ng-container *ngIf="link?.href">
          <a [href]="link?.href" [target]="link?.target ? link?.target : '_blank'" class="menu-link ps-0 pe-2 cursor-pointer"> {{ link.title }} </a>
        </ng-container>
        <ng-container *ngIf="link?.page">
          <a [routerLink]="link.page" class="menu-link ps-0 pe-2 cursor-pointer"> {{ link.title }} </a>
        </ng-container>
      </li>
    </ul>
    <!-- end::Nav  -->
  </div>
</ng-container>
