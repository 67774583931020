import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as MessagesActions from '../actions/messages.actions';
import { RouterActions } from '../../../../../router/+state';
import { NotificationMessageHTTPService } from '../../../../services/notification-messages-http.service';
import { getAllMessagesState } from '../selectors';
import { NotificationMessageAnalyticsHTTPService } from '../../../../services/notification-messages-analytics-http.service';

// import * as MessageSelectors from '../selectors/messages.selectors';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _NotificationMessageHTTPService: NotificationMessageHTTPService,
    private _NotificationMessageAnalyticsHTTPService: NotificationMessageAnalyticsHTTPService
  ) {}

  getAllMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        MessagesActions.searchAllMessages,
        MessagesActions.updateAllMessagesPagination,
        MessagesActions.updateAllMessagesQuery,
        MessagesActions.updateAllMessagesSorting
      ),
      withLatestFrom(this._Store.select(getAllMessagesState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._NotificationMessageHTTPService.search({ pagination, querySort, query }).pipe(
          map((results) => {
            return MessagesActions.searchAllMessagesSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.searchAllMessagesFailure(error)))
        );
      }),
      catchError((error) => of(MessagesActions.searchAllMessagesFailure(error)))
    );
  });

  getMessageAnalytics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.getMessageAnalytics),
      switchMap((action) => {
        return this._NotificationMessageAnalyticsHTTPService.getLast30Days().pipe(
          map((data) => {
            return MessagesActions.getMessageAnalyticsSuccess({
              data,
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.getMessageAnalyticsFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(MessagesActions.getMessageByIdFailure(error)))
    );
  });

  getMessageById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.getMessageById),
      switchMap((action) => {
        return this._NotificationMessageHTTPService.getById(action.message_id).pipe(
          map((data) => {
            return MessagesActions.getMessageByIdSuccess({
              message: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.getMessageByIdFailure({ message_id: action.message_id, error: error.error })))
        );
      }),
      catchError((error) => of(MessagesActions.getMessageByIdFailure(error)))
    );
  });

  createMessageById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.createMessage),
      switchMap((action) => {
        return this._NotificationMessageHTTPService.create(action.data).pipe(
          map((data) => {
            return MessagesActions.createMessageSuccess({
              data: data,
              redirectPath: action.redirectPath,
              success_message: 'Message Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.createMessageFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(MessagesActions.getMessageByIdFailure(error)))
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createMessageSuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MessagesActions.createMessageSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: [...data.redirectPath, data.data._id] } }));
          }
          return of();
        }),
        catchError((error) => of(MessagesActions.getMessageByIdFailure(error)))
      );
    }
    // { dispatch: false }
  );

  updateMessageById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.updateMessage),
      switchMap((action) => {
        return this._NotificationMessageHTTPService.update(action.data).pipe(
          map((data) => {
            return MessagesActions.updateMessageSuccess({
              message: data,
              success_message: 'Message Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.updateMessageFailure({ message_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(MessagesActions.getMessageByIdFailure(error)))
    );
  });

  deleteMessageById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessagesActions.deleteMessage),
      switchMap((action) => {
        return this._NotificationMessageHTTPService.delete(action.id).pipe(
          map((data) => {
            return MessagesActions.deleteMessageSuccess({
              data: data,
              success_message: 'Message Deleted',
            });
          }),
          catchError((error: IHttpRequestError) => of(MessagesActions.deleteMessageFailure({ message_id: action.id, error: error.error })))
        );
      }),
      catchError((error) => of(MessagesActions.deleteMessageFailure(error)))
    );
  });
}
