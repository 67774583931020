import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ILaunchpointCorePinpointJourney,
  ILaunchpointCorePinpointJourneyController,
  ILaunchpointCorePinpointJourneyParamsCreate,
  ILaunchpointCorePinpointJourneyParamsDelete,
  ILaunchpointCorePinpointJourneyParamsList,
  ILaunchpointCorePinpointJourneyParamsUpdate,
  ILaunchpointCorePinpointJourneySearchPayload,
  ILaunchpointCorePinpointJourneySearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../../notifications/interfaces/core-notifications.interface';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';

@Injectable()
export class PinpointJourneysHTTPService extends LaunchpointSecurityUserIdHeaderService implements ILaunchpointCorePinpointJourneyController {
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  routes = {
    delete: '/delete',
    search: '/search',
    sync: '/sync',
  };

  constructor(
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig,
    public _Store: Store
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'pinpoint/journeys',
    });
  }
  search(body: ILaunchpointCorePinpointJourneySearchPayload): Observable<ILaunchpointCorePinpointJourneySearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointJourneySearchResults>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
  getById(_id: string): Observable<ILaunchpointCorePinpointJourney> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointCorePinpointJourney>(this.API_URL + '/' + _id, { headers });
      })
    );
  }
  create(body: ILaunchpointCorePinpointJourneyParamsCreate): Observable<ILaunchpointCorePinpointJourney> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointCorePinpointJourney>(this.API_URL, body, { headers });
      })
    );
  }
  update(body: ILaunchpointCorePinpointJourneyParamsUpdate): Observable<ILaunchpointCorePinpointJourney> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointJourney>(this.API_URL, body, { headers });
      })
    );
  }
  delete(body: ILaunchpointCorePinpointJourneyParamsDelete): Observable<ILaunchpointCorePinpointJourney> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointCorePinpointJourney>(this.API_URL + this.routes.delete, body, { headers });
      })
    );
  }
  sync(body: ILaunchpointCorePinpointJourneyParamsList): Observable<{ success: boolean }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<{ success: boolean }>(this.API_URL + this.routes.search, body, { headers });
      })
    );
  }
}
