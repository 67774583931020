import { Observable } from 'rxjs';
import { INotification } from '../notifications.interface';
import {
  INotificationChannelParamsCreate,
  INotificationChannelParamsDelete,
  INotificationChannelParamsUpdate,
} from './notifications-channels.interface';

/**
 * Controller('notifications/channel')
 */
export interface INotificationChannelController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<INotification> | Observable<INotification>;
  /**
   * Post()
   * @param body
   */
  create(body: INotificationChannelParamsCreate): Promise<INotification> | Observable<INotification>;
  /**
   * Put()
   * @param body
   */
  update(body: INotificationChannelParamsUpdate): Promise<INotification> | Observable<INotification>;
  /**
   * Put('delete')
   * @param body
   */
  delete(body: INotificationChannelParamsDelete): Promise<INotification> | Observable<INotification>;
}
