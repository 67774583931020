import { Observable } from 'rxjs';
import { ILaunchpointDynamicForm } from '../form.interface';
import {
  ILaunchpointDynamicFormGroupParamsCreate,
  ILaunchpointDynamicFormGroupParamsUpdate,
  ILaunchpointDynamicFormGroupParamsDelete,
  ILaunchpointDynamicFormGroupParamsReorder,
} from './form-groups.interface';

export interface ILaunchpointDynamicFormGroupController {
  add(params: ILaunchpointDynamicFormGroupParamsCreate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  update(params: ILaunchpointDynamicFormGroupParamsUpdate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  reorder(params: ILaunchpointDynamicFormGroupParamsReorder): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  remove(params: ILaunchpointDynamicFormGroupParamsDelete): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
}
