<div class="app-container container-xxl" *ngIf="webhook$ | async as webhook">
    <div class="card mb-6 mb-xl-9 shadow-sm">
        <div class="card-body pt-9 pb-0">
            <div class="d-flex fs-2 fw-bold me-3 justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="">{{ webhook?.url }}</span>
                    <div>
                        <i *ngIf="webhook?.enabled" class="bi bi-check-circle text-success ms-3"
                            ngbTooltip="Enabled"></i>
                        <i *ngIf="!webhook?.enabled" class="bi bi-x-circle text-danger ms-3" ngbTooltip="Disabled"></i>
                    </div>

                </div>

                <launchpoint-ellipsis-menu [options]="getOptions(webhook)" />
            </div>

            <div class="mb-5">
                <div class="fs-7 fw-semibold text-muted mb-3" *ngIf="webhook?.description">
                    {{ webhook?.description }}
                </div>
                <span class="badge" [ngClass]="{
                    'badge-light-success': webhook?.mode === 'live',
                    'badge-light-info': webhook?.mode === 'test'
                }">
                    {{ webhook?.mode | titlecase }}
                </span>
            </div>

            <!-- <div class="separator mt-1"></div>

            <div class="mt-4 mb-5">
                <div class="d-flex">
                    <span *ngFor="let event of webhook?.events" class="badge bg-light-info me-2">{{ event }}</span>
                </div>
            </div> -->

            <!-- <div class="separator"></div>

            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                <li class="nav-item" *ngFor="let event of webhook?.events">
                    <a class="nav-link text-active-primary py-5 me-6" [routerLink]="['./' + event]"
                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        {{ event }}
                    </a>
                </li>
            </ul> -->
        </div>
    </div>

    <div [ngClass]="{ 'd-flex flex-row gap-3': selectedEvent }">
        <div [ngClass]="{ 'col-5': selectedEvent }">
            <launchpoint-webhook-events-toolbar [query]="getQuery(webhook)" (selectedEvent)="setEvent($event)" />
        </div>

        <div *ngIf="selectedEvent" class="d-flex col-7 card card-flush card-body">
            <!-- <pre [innerHTML]="selectedEvent | json"></pre> -->
            <launchpoint-webhook-event [event]="selectedEvent" />
        </div>
    </div>
</div>