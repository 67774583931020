import { LaunchpointDynamicFormV1Service } from './forms.service';
import { LaunchpointDynamicFormComputedFieldService } from './groups-computed-fields.service';
import { LaunchpointDynamicFormGroupQuestionV1Service } from './groups-questions.service';
import { LaunchpointDynamicFormGroupV1Service } from './groups.service';

export * from './forms.service';
export * from './groups.service';
export * from './groups-computed-fields.service';
export * from './groups-questions.service';

export const DYNAMIC_FORMS_SERVICES = [
  LaunchpointDynamicFormGroupV1Service,
  LaunchpointDynamicFormV1Service,
  LaunchpointDynamicFormGroupQuestionV1Service,
  LaunchpointDynamicFormComputedFieldService,
];
