import { Component } from '@angular/core';
import { LaunchpointCoreClientUpdateEmailPasswordBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-update-email-password',
  templateUrl: './update-email-password.component.html',
  styleUrls: ['./update-email-password.component.scss'],
})
export class LaunchpointCoreWebProfileUpdateEmailPasswordComponent extends LaunchpointCoreClientUpdateEmailPasswordBaseComponent {
  // constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig, public fb: FormBuilder, _Store: Store) {
  //   super(_config, _Store, fb);
  // }
  // ngOnInit(): void {
  /**
   * COMMENTING THESE OUT BECAUSE WE NEED TO CONSIDER MAKING THE ALERTS GLOBAL TO THE FEATURE
   * Which means we would create a base component that has these subscriptions
   * Since they are for any action not just the update email and password action like suggested in this component
   * OR we could offer something like this by adding to state. Pass the action along with the error so this component could select it's own errors instead of any.
   */
  // this.errors();
  // this.success();
  // this.buildForm();
  // }
}
