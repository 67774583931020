<div>
  <!-- CALENDAR -->
  <div class="bg-background relative flex justify-center py-24">
    <div class="w-[1200px] max-w-full px-6 text-white">
      <div class="my-20 grid grid-cols-1 items-center gap-y-5 md:my-20 md:grid-cols-4 md:items-start md:gap-x-10 md:gap-y-0">
        <div class="col-span-1 mb-5 flex flex-col items-center gap-y-4 md:mb-0 md:items-start">
          <div class="font-oswald text-5xl uppercase text-white">Upcoming Events</div>
          <div class="text-sm">Join us for impactful online and in-person events, where connections and insights fuel your next level.</div>

          <sd-button text="View All" [routerLink]="LaunchpointPages.EVENTS" />
        </div>

        <sd-event-calendar class="col-span-3 md:me-10" />
      </div>
    </div>
  </div>

  <!-- NEWSLETTER -->
  <sd-newsletter></sd-newsletter>

  <!-- COMMUNUTY -->
  <div class="bg-light relative flex justify-center text-black">
    <div class="w-[1200px] max-w-full px-6 text-black">
      <div class="my-20">
        <div class="font-oswald mb-20 text-center text-3xl uppercase md:text-left">the community</div>

        <div class="grid grid-cols-1 gap-5 md:grid-cols-3 md:gap-10">
          <div class="col-span-1 flex flex-col items-start justify-between">
            @for (item of items; track item) {
              <div class="my-4">
                <div class="font-oswald text-2xl uppercase">{{ item.title }}</div>
                <div class="text-sm">{{ item.description }}</div>
              </div>
            }

            <div class="my-4">
              <sd-button text="Join Now" type="dark" size="small" [routerLink]="LaunchpointPages.COMMUNITY" />
            </div>
          </div>

          <div class="flex items-center md:col-span-2">
            <img src="assets/img/community.png" alt="Community" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
