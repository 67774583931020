import { Directive, EventEmitter, Output } from '@angular/core';
import { ICoreCompany, ICoreCompanySearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { firstValueFrom, map } from 'rxjs';
import { CompanyV2Service } from '../services/company.service';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';

@Directive()
export abstract class CompanySearchBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading = false;
  query: ICoreCompanySearchQuery;
  pagination: IQueryPageination = { limit: 15, skip: 0, count: 0 };
  querySort: IQuerySort = { created_at: -1 };

  company: ICoreCompany = null;
  search: string;

  @Output() selectedCompany = new EventEmitter<{ email: string; company: ICoreCompany }>();
  constructor(public _CompanyV1Service: CompanyV2Service) {
    super();
  }

  async runQuery() {
    try {
      this.loading = true;
      const data = await firstValueFrom(this._CompanyV1Service.searchOneByEmail(this.search));
      this.loading = false;
      this.company = data;
      this.selectedCompany.emit({ email: this.search, company: data });
    } catch (error) {
      console.log(error);
    }
  }
}
