export enum ELPDefaultActions {
  SEARCH = 'search',
  GET_BY_ID = 'get-by-id',
  CREATE = 'create',
  REORDER = 'reorder',
  UPDATE = 'update',
  UPDATE_BULK = 'update/bulk',
  ARCHIVE = 'archive',
  ARCHIVE_BULK = 'archive/bulk',
  DELETE = 'delete',
  DELETE_BULK = 'delete/bulk',
}
