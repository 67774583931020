import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookEventSearchQuery,
  ICoreActivityHistoryWebhookEventSearchResults,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllWebhookEvents = createAction('[WebhookEvents] searchAllWebhookEvents');
export const searchAllWebhookEventsSuccess = createAction(
  '[WebhookEvents] searchAllWebhookEventsSuccess',
  props<{ data: ICoreActivityHistoryWebhookEventSearchResults; success_message?: string }>()
);
export const searchAllWebhookEventsFailure = createAction('[WebhookEvents] searchAllWebhookEventsFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllWebhookEventsPagination = createAction(
  '[WebhookEvents] updateAllWebhookEventsPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllWebhookEventsPaginationScroll = createAction(
  '[WebhookEvents] updateAllWebhookEventsPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllWebhookEventsSorting = createAction('[WebhookEvents] updateAllWebhookEventsSorting', props<{ querySort: IQuerySort }>());
export const updateAllWebhookEventsQuery = createAction(
  '[WebhookEvents] updateAllWebhookEventsQuery',
  props<{ query: ICoreActivityHistoryWebhookEventSearchQuery }>()
);
// getById
export const getWebhookEventById = createAction('[WebhookEvents] getWebhookEventById', props<{ event_id: string }>());
export const getWebhookEventByIdSuccess = createAction(
  '[WebhookEvents] getWebhookEventByIdSuccess',
  props<{ data: ICoreActivityHistoryWebhookEvent; success_message?: string }>()
);
export const getWebhookEventByIdFailure = createAction(
  '[WebhookEvents] getWebhookEventByIdFailure',
  props<{ event_id: string; error: IHttpException }>()
);

// Delete WebhookEvents
export const deleteWebhookEvent = createAction('[WebhookEvents] deleteWebhookEvent', props<{ event_id: string }>());
export const deleteWebhookEventSuccess = createAction(
  '[WebhookEvents] deleteWebhookEventSuccess',
  props<{ data: ICoreActivityHistoryWebhookEvent; success_message?: string }>()
);
export const deleteWebhookEventFailure = createAction(
  '[WebhookEvents] deleteWebhookEventFailure',
  props<{ event_id: string; error: IHttpException }>()
);
