import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { getMediaById, getMediaByIdFailure, getMediaByIdSetId } from '../actions/media.actions';
import { getAllMediaState } from '../selectors/media.selectors';

@Injectable()
export class GetSelectedMediaLoadedGuard implements CanActivate {
  constructor(
    private _Store: Store,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._Store.select(getAllMediaState).pipe(
      map((state) => {
        if (state?.entities) {
          return Object.keys(state.entities);
        } else return [];
      }),
      switchMap((entityIds: string[]) => {
        if (entityIds.includes(route.params.media_id)) {
          this._Store.dispatch(getMediaByIdSetId({ media_id: route.params.media_id }));

          return of(true);
        } else {
          this._Store.dispatch(getMediaById({ media_id: route.params.media_id }));
          return of(true);
        }
      }),

      switchMap(() => of(true)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          getMediaByIdFailure({
            media_id: route.params.media_id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }
}
