import { Injectable } from '@angular/core';
import { ICoreUser, IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { LaunchpointCoreClientAuthHTTPService } from '../../../user/auth';
import { LaunchpointCoreClientAccountsHTTPService } from '../../../user/auth/services/core-accounts-http.service';
import { CoreAccountsSecurityHTTPService } from '../../../user/core/core-account/services/core-accounts-security-http.service';
import { AddressHTTPService } from '../../../user/core/core-address/services/core-address-http.service';
import { CoreUserV1Service } from '../../../user/core/core-base/services/core-base-http.service';
import { DemographicsHTTPService } from '../../../user/core/core-demographics/services/core-demographics-http.service';
import { CoreProfileHTTPService } from '../../../user/core/core-profile/services/core-profile-http.service';
import { UserSecurityRoleHTTPService } from '../../../user/core/core-security-roles/services/core-security-roles-http.service';
import * as UserAuthActions from '../actions/users.actions';
import * as UserSelectors from '../selectors/users.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _CoreUserHTTPService: CoreUserV1Service,
    private _CoreProfileHTTPService: CoreProfileHTTPService,
    private _AddressHTTPService: AddressHTTPService,
    private _DemographicsHTTPService: DemographicsHTTPService,
    private _LaunchpointCoreClientAccountsHTTPService: LaunchpointCoreClientAccountsHTTPService,
    private _CoreAccountsSecurityHTTPService: CoreAccountsSecurityHTTPService,
    private _UserSecurityRoleHTTPService: UserSecurityRoleHTTPService, // private _UserAccountsV1Service: UserAccountsV1Service,
    private _LaunchpointCoreClientAuthHTTPService: LaunchpointCoreClientAuthHTTPService
  ) {}

  getAllUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserAuthActions.searchAllUsers,
        UserAuthActions.updateAllUsersPagination,
        UserAuthActions.updateAllUsersQuery,
        UserAuthActions.updateAllUsersSorting
      ),
      withLatestFrom(this._Store.select(UserSelectors.getAllUsersState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._CoreUserHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return UserAuthActions.searchAllUsersSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.searchAllUsersFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.searchAllUsersFailure(error)))
    );
  });

  searchAllUsersApplyView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.searchAllUsersApplyView),
      withLatestFrom(this._Store.select(UserSelectors.getAllUsersState)),
      switchMap(([action, state]) => {
        const pagination = action?.pagination ?? state.pagination;
        const querySort = action?.querySort ?? state.querySort;
        const query = action?.query ?? state.query;
        const search = { pagination: pagination, querySort: querySort, query: query };

        return this._CoreUserHTTPService.search(search).pipe(
          map((results) => {
            return UserAuthActions.searchAllUsersSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.searchAllUsersFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.searchAllUsersFailure(error)))
    );
  });

  getUserById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.getUserById),
      switchMap((action) => {
        return this._CoreUserHTTPService.getById(action.user_id).pipe(
          map((data) => {
            return UserAuthActions.getUserByIdSuccess({
              user: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.getUserByIdFailure({ user_id: action.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.getUserByIdFailure(error)))
    );
  });

  updateUserProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.updateUserProfile),
      switchMap((action) => {
        return this._CoreProfileHTTPService.updateProfile(action.data).pipe(
          map((data) => {
            return UserAuthActions.updateUserProfileSuccess({
              user: data,
              success_message: 'Profile Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.updateUserProfileFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.updateUserProfileFailure(error)))
    );
  });
  //*Create Address
  createUserAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.createUserAddress),
      switchMap((action) => {
        // console.log('action', action);
        return this._AddressHTTPService.create(action.data).pipe(
          map((data) => {
            // console.log('data', data);
            return UserAuthActions.createUserAddressSuccess({
              user: data,
              success_message: 'Address Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.createUserAddressFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.createUserAddressFailure(error)))
    );
  });
  //*Update Address
  updateUserAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.updateUserAddress),
      switchMap((action) => {
        // console.log('action', action);
        return this._AddressHTTPService.update(action.data).pipe(
          map((data) => {
            // console.log('data', data);
            return UserAuthActions.updateUserAddressSuccess({
              user: data,
              success_message: 'Address Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.updateUserAddressFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.updateUserAddressFailure(error)))
    );
  });
  //* Update Phone
  updateUserPhone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.updateUserPhone),
      switchMap((action) => {
        return this._DemographicsHTTPService.updatePhone(action.data).pipe(
          map((data) => {
            return UserAuthActions.updateUserPhoneSuccess({
              user: data,
              success_message: 'Phone Number Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.updateUserPhoneFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.updateUserPhoneFailure(error)))
    );
  });
  //* Update Email
  setUserEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.setUserEmail),
      switchMap((action) => {
        return this._LaunchpointCoreClientAuthHTTPService.setEmail(action.data).pipe(
          map((data) => {
            return UserAuthActions.setUserEmailSuccess({
              user: data,
              success_message: 'Email Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.setUserEmailFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.setUserEmailFailure(error)))
    );
  });
  updateUserPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.updateUserPassword),
      switchMap((action) => {
        return this._LaunchpointCoreClientAuthHTTPService.setPassword(action.data).pipe(
          map((data) => {
            return UserAuthActions.updateUserPasswordSuccess({
              user: data,
              success_message: 'Password Updated',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(UserAuthActions.updateUserPasswordFailure({ user_id: action.data.user_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(UserAuthActions.updateUserPasswordFailure(error)))
    );
  });
  //* Add Security Roles
  addUsersSecurityRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.addUserRole),
      switchMap((action) => {
        return this._UserSecurityRoleHTTPService.addRole(action.params).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.addUserRoleSuccess({
              user: data,
              success_message: 'Security Role Added',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.addUserRoleFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.addUserRoleFailure(error)))
    );
  });
  //* Remove Security Roles
  removeUsersSecurityRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.deleteUserRole),
      switchMap((action) => {
        return this._UserSecurityRoleHTTPService.removeRole(action.params).pipe(
          map((data) => {
            return UserAuthActions.deleteUserRoleSuccess({
              user: data,
              success_message: 'Security Role Removed',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.deleteUserRoleFailure({ user_id: action.params.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });

  // Accounts actions
  // Remove

  // export const removeAccount = createAction(`[Users] removeAccount`, props<{data:ICoreUserAccountsParamsRemove}>());
  adminRemoveAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminRemoveAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.removeAccount(action.data).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.accountSuccess({
              user: data,
              success_message: 'Account Removed',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                //user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });

  // Resend Invite
  // export const resendAccountInvite = createAction(`[Users] removeAccount`, props<{data:ICoreUserAccountsParamsResendInvite}>());
  adminResendAccountInvite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminResendAccountInvite),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.resendInvite(action.data).pipe(
          map((data: { message: string }) => {
            console.log('response to resend invite', data);
            return UserAuthActions.accountSuccess({
              // user: data,
              success_message: 'Invite Resent',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserAuthActions.accountFailure({ user_id: action.data.user_id, error: error.error })))
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });

  // Add without Invite
  // export const acceptAccountInvitation = createAction(`[Users] removeAccount`, props<{data:ICoreUserAccountsParamsAcceptInvitations}>());
  adminAcceptAccountInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminAcceptAccountInvitation),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.acceptInvitation(action.data).pipe(
          map((data: { message: string }) => {
            console.log('response to accept invite', data);
            return UserAuthActions.accountSuccess({
              // user: data,
              success_message: 'Invite Accepted',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                //user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });
  // Account role change TBD TODO:
  // export const changeAccountRoles = createAction(`[Users] removeAccount`, props<{data:any}>());

  // Add Sub user account
  // addSubUserAccount = createAction(`[Users] removeAccount`, props<{ data: ICoreUserAccountsParamsAddSubUserAccount }>());
  adminAddSubUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminAddSubUserAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.addSubUserAccount(action.data).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.accountSuccess({
              // user: data,
              success_message: 'Sub Account Added',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                //user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });
  //  add main user account
  // addMainUserAccount = createAction(`[Users] removeAccount`, props<{ data: ICoreUserAccountsParamsAddMainUserAccount }>());
  adminAddMainUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminAddMainUserAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.addMainUserAccount(action.data).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.accountSuccess({
              user: data,
              success_message: 'Main Account Added',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                // user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });
  adminAddAccountRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminAddAccountRoles),
      switchMap((action) => {
        return this._CoreAccountsSecurityHTTPService.addRoles(action.data).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.accountSuccess({
              user: data,
              success_message: 'Security Roles Added to Account',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                // user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });
  adminRemoveAccountRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserAuthActions.adminRemoveAccountRoles),
      switchMap((action) => {
        return this._CoreAccountsSecurityHTTPService.removeRoles(action.data).pipe(
          map((data: ICoreUser) => {
            return UserAuthActions.accountSuccess({
              user: data,
              success_message: 'Security Roles Removed from Account',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(
              UserAuthActions.accountFailure({
                // user_id: action.data.user_id,
                error: error.error,
              })
            )
          )
        );
      }),
      catchError((error) => of(UserAuthActions.deleteUserRoleFailure(error)))
    );
  });
}
