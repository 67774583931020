import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

export interface IBooleanFilter {
  tooltip?: string;
  // securityRoles?: any[];
  allLabel?: string;
  trueLabel?: string;
  falseLabel?: string;
}

@Component({
  selector: 'launchpoint-boolean-filter',
  templateUrl: './boolean-filter.component.html',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, FormsModule, ReactiveFormsModule],
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebBooleanFilterComponent {
  @Input() active: boolean | null = null;
  @Input() label = '';
  @Input() options: IBooleanFilter = {
    allLabel: 'All',
    trueLabel: 'Include',
    falseLabel: 'Exclude',
  };
  @Input() cssClass = 'mb-5';
  @Input() labelClass = 'form-label fw-semibold';

  selectedLabel: boolean | null = null;

  @Output() selected = new EventEmitter();

  change(type: boolean | null) {
    this.selectedLabel = type;
    this.selected.emit(type);
  }
}
