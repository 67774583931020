import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingHeadersCoreModule, NgxScrollTopModule } from '@launchpoint/core-web';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CountUpModule } from 'ngx-countup';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { CtaBannerComponent } from './components/common/cta-banner/cta-banner.component';
import { IconsService } from './components/common/icons/icons.service';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { SocialIconsComponent } from './components/common/social-icons/social-icons.component';
import { CoreModule } from './core/core.module';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { SharedModule } from './features/shared/shared.module';
import { STANDALONE_PAGES } from './pages';
import { BlogPageModule } from './pages/blog/blog.module';
import { HomePageModule } from './pages/home/home.module';
import { ResourcesPageModule } from './pages/resources/resources.module';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { LaunchpointSEORouterService, SEO_CONFIG_TOKEN } from '@launchpoint/xplat/web/features';
import { SEO_CONFIG } from './config/seo';

const STANDALONE = [HeaderComponent, FooterComponent, CtaBannerComponent, SocialIconsComponent, ...STANDALONE_PAGES];

const PAGE_MODULES = [HomePageModule, BlogPageModule, ResourcesPageModule];
@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    LandingHeadersCoreModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxScrollTopModule,
    InlineSVGModule.forRoot(),
    IconsService,
    CountUpModule,

    ...STANDALONE,
    ...PAGE_MODULES,
  ],
  declarations: [AppComponent, NotFoundComponent],
  bootstrap: [AppComponent],
  providers: [
    provideHttpClient(withFetch()),
    LaunchpointSEORouterService,
    {
      provide: SEO_CONFIG_TOKEN,
      useValue: {
        seo: SEO_CONFIG,
        debug: false,
      },
    },
  ],
})
export class AppModule {}
