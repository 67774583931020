import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import {
  configureURL,
  ICoreAlertBanner,
  ICoreAlertBannerController,
  ICoreAlertBannerFE,
  ICoreAlertBannerParamsCreate,
  ICoreAlertBannerParamsUpdate,
  ICoreAlertBannerPublicController,
  ICoreAlertBannerSearchPayload,
  ICoreAlertBannerSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';
import { ALERT_BANNER_CLIENT_CONFIG_TOKEN, LaunchpointCoreClientAlertBannerConfig } from '../interface/interface';

@Injectable()
export class CoreAlertBannerHTTPService
  extends LaunchpointSecurityUserIdHeaderService
  implements ICoreAlertBannerController, ICoreAlertBannerPublicController
{
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  PUBLIC_API_URL = '/api/backend/';
  BASE_URL = 'alert-banner';
  PUBLIC_BASE_URL = 'get-banner';

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Optional() @Inject(ALERT_BANNER_CLIENT_CONFIG_TOKEN) public _config: LaunchpointCoreClientAlertBannerConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.base_url,
      version: 1,
      route: this.BASE_URL,
    });

    this.PUBLIC_API_URL = configureURL({
      baseUrl: this.PUBLIC_API_URL,
      configUrl: _config?.base_url,
      version: 1,
      route: this.PUBLIC_BASE_URL,
    });
  }

  // PUBLIC -----------------------------------------------------

  getAllForDatePublic(): Observable<ICoreAlertBannerFE[]> {
    return this.http.get<ICoreAlertBannerFE[]>(this.PUBLIC_API_URL);
  }

  // ADMIN -----------------------------------------------------
  search(params: ICoreAlertBannerSearchPayload): Observable<ICoreAlertBannerSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreAlertBannerSearchResults>(this.API_URL + '/search', params, { headers });
      })
    );
  }
  getById(id: string): Observable<ICoreAlertBanner> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreAlertBanner>(this.API_URL + '/' + id, { headers });
      })
    );
  }
  getAllForDate(): Observable<ICoreAlertBannerFE[]> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreAlertBannerFE[]>(this.API_URL, { headers });
      })
    );
  }
  create(params: ICoreAlertBannerParamsCreate): Observable<ICoreAlertBanner> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreAlertBanner>(this.API_URL, params, { headers });
      })
    );
  }
  update(params: ICoreAlertBannerParamsUpdate): Observable<ICoreAlertBanner> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreAlertBanner>(this.API_URL, params, { headers });
      })
    );
  }
  archive(id: string): Observable<ICoreAlertBanner> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreAlertBanner>(this.API_URL + '/archive/' + id, { headers });
      })
    );
  }

  delete(id: string): Observable<ICoreAlertBanner> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreAlertBanner>(this.API_URL + '/delete/' + id, { headers });
      })
    );
  }
}
