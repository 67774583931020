import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ManyChatSubscribeService } from '@launchpoint/xplat/web/features';
import { firstValueFrom } from 'rxjs';

import { SocialIconsComponent } from '../../components/common/social-icons/social-icons.component';
import { SOCIAL_LINKS } from '../../config/config';
import { StadnickDevConfigService } from '../../services/config.service';

@Component({
  selector: 'sd-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, SocialIconsComponent],
  providers: [StadnickDevConfigService, ManyChatSubscribeService],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  form = new FormControl('', [Validators.email, Validators.maxLength(244)]);
  social_links = SOCIAL_LINKS;
  _StadnickDevConfigService = inject(StadnickDevConfigService);
  _ManyChatSubscribeService = inject(ManyChatSubscribeService);
  year = new Date().getFullYear();
  get footerMenu() {
    return this._StadnickDevConfigService.footerMenu;
  }

  get footerItems() {
    return this._StadnickDevConfigService.getFooterItems;
  }

  async submitEmail() {
    if (this.form.valid) {
      try {
        console.log(this.form.value);
        const subscriber = {
          email: this.form.value,
          has_opt_in_email: true,
          tags: ['new_lead'],
        };
        // this.StadnickDevConfigService.fireEvent(
        //   EApplicationEvents.SUBSCRIBE_EMAIL,
        //   'Submitted email on Launchpoint blog',
        //   EApplicationCategories.CONTACT_US
        // );
        const data = await firstValueFrom(this._ManyChatSubscribeService.createSubscriber(subscriber));
        console.log({ data });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
