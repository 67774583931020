import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientBlogsBaseComponent } from '@launchpoint/core-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../components/swal.service';
import { IToolbarConfig } from '../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebBlogModalComponent } from '../blog-modal/blog-modal.component';

@Component({
  selector: 'launchpoint-blogs-toolbar',
  templateUrl: 'blogs-toolbar.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebBlogsToolbarComponent extends LaunchpointCoreClientBlogsBaseComponent {
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);
  _swal = inject(LaunchpointSweetAlertService);

  options: IToolbarConfig = {
    title: 'Blogs',
    searchPlaceholder: 'Search by title or tags',
    formControl: this.form_control,
    create: true,
    queryCount: this.queryFilterCount$,
  };

  open() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebBlogModalComponent, { size: 'lg' });
  }

  override toast(params) {
    this._swal.fireToast(params);
  }
}
