import { ICoreBlogSearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as BlogActions from '../actions/blogs.actions';
import { BlogsEntityState } from '../interfaces/blogs.interface';

export const BlogsFeatureKey = 'BlogsReducer';

export interface StateBlogsReducer extends EntityState<BlogsEntityState> {
  selected_blog_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreBlogSearchQuery;
}

export const BlogsEntityAdaptor: EntityAdapter<BlogsEntityState> = createEntityAdapter<BlogsEntityState>({
  selectId: (data) => data.blog_id,
});

export const initialBlogsState: StateBlogsReducer = BlogsEntityAdaptor.getInitialState({
  // set initial required properties
  selected_blog_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
  entities: {},
  ids: [],
});

export const LaunchpointBlogsReducer = createReducer(
  initialBlogsState,
  on(BlogActions.searchAllBlogs, (state) => {
    return BlogsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(BlogActions.searchAllBlogsSuccess, (state, { data }) => {
    const userEntities = data.data.map((data) => {
      const entity: BlogsEntityState = {
        blog_id: data._id,
        blog: data,
        loaded: true,
        loading: false,
        error: null,
        // profile_config: USERS_PROFILE_CONFIG,
      };
      return entity;
    });
    return BlogsEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(BlogActions.searchAllBlogsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(BlogActions.updateAllBlogsQuery, (state, { query }) => {
    return BlogsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(BlogActions.updateAllBlogsPagination, (state, { pagination }) => {
    return BlogsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(BlogActions.updateAllBlogsPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(BlogActions.updateAllBlogsSorting, (state, { querySort }) => {
    return BlogsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(BlogActions.getBlogById, (state, { blog_id }) => ({
    ...state,
    selected_blog_id: blog_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(BlogActions.getBlogByIdSuccess, (state, { data }) => {
    const entity: BlogsEntityState = {
      blog_id: data._id,
      blog: data,
      loaded: true,
      loading: false,
      error: null,
      // profile_config: USERS_PROFILE_CONFIG,
    };
    return BlogsEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_blog_id: data._id,
    });
  }),
  on(BlogActions.getBlogByIdFailure, (state, { blog_id, error }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: blog_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.createBlog, (state, { data }) => ({
    ...state,
    loading: true,
    success_message: null,
    error: null,
  })),
  on(BlogActions.createBlogSuccess, (state, { data, success_message }) => {
    const entity: BlogsEntityState = {
      blog_id: data._id,
      blog: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return BlogsEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      selected_blog_id: data._id,
    });
  }),
  on(BlogActions.createBlogFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),
  on(BlogActions.updateBlog, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.updateBlogSuccess, (state, { data, success_message }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          blog: data,
          loading: false,
          loaded: true,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.updateBlogFailure, (state, { blog_id, error }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: blog_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.deleteBlog, (state, { blog_id }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: blog_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.deleteBlogSuccess, (state, { data, success_message }) => {
    return BlogsEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(BlogActions.deleteBlogFailure, (state, { blog_id, error }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: blog_id,
        changes: {
          loading: false,
          loaded: true,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.updateBlogSEO, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.setFeatured, (state, { blog_id }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: blog_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.setTags, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.addRelatedPosts, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.removeRelatedPosts, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.setRelatedPosts, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.addComment, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.removeComment, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.likeComment, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.dislikeComment, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.addCommentReply, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.removeCommentReply, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.likeCommentReply, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(BlogActions.dislikeCommentReply, (state, { data }) => {
    return BlogsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  })
);
