<div class="flex flex-wrap justify-center border-[1px] border-black bg-[#E1E2DF]">
  <div
    class="flex h-[200px] w-1/2 flex-col items-center justify-center border-b-[1px] border-r-[1px] border-black md:h-[300px] lg:h-[450px] lg:w-1/4 lg:border-b-0"
  >
    <p class="font-oswald text-[40px] font-medium md:text-[60px] lg:text-[80px] xl:text-[100px]">10</p>
    <p class="text-sm uppercase lg:text-base">Years of Experience</p>
  </div>
  <div
    class="flex h-[200px] w-1/2 flex-col items-center justify-center border-b-[1px] border-black md:h-[300px] lg:h-[450px] lg:w-1/4 lg:border-b-0 lg:border-r-[1px]"
  >
    <p class="font-oswald text-[40px] font-medium md:text-[60px] lg:text-[80px] xl:text-[100px]">100+</p>
    <p class="text-sm uppercase lg:text-base">Businesses Helped</p>
  </div>
  <div class="flex h-[200px] w-1/2 flex-col items-center justify-center border-r-[1px] border-black md:h-[300px] lg:h-[450px] lg:w-1/4">
    <p class="font-oswald text-[40px] font-medium md:text-[60px] lg:text-[80px] xl:text-[100px]">$1B</p>
    <p class="text-sm uppercase lg:text-base">Revenue Generated</p>
  </div>
  <div class="flex h-[200px] w-1/2 flex-col items-center justify-center md:h-[300px] lg:h-[450px] lg:w-1/4">
    <p class="font-oswald text-[40px] font-medium md:text-[60px] lg:text-[80px] xl:text-[100px]">40+</p>
    <p class="text-sm uppercase lg:text-base">Industries Touched</p>
  </div>
</div>
