<div class="input-group mb-5">
  <div class="input-group dp-hidden">
    <input 
      class="form-control rounded-start" 
      ngbDatepicker 
      #datepicker="ngbDatepicker" 
      [maxDate]="allOptions.maxDate"
      [minDate]="allOptions.minDate" 
      [autoClose]="allOptions.autoClose" 
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="allOptions.displayMonths" 
      [footerTemplate]="footerTemplate" [dayTemplate]="t"
      [outsideDays]="allOptions.outsideDays" 
      [startDate]="allOptions.startDate" 
      tabindex="-1"
    />
    <ng-template #t let-date let-focused="focused">
      <span 
        class="custom-day" 
        [class.focused]="focused" 
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" 
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      >
        {{ date.day }}
      </span>
    </ng-template>
  </div>
  <input 
    #dpFromDate 
    class="form-control bg-white rounded-start" 
    placeholder="yyyy-mm-dd"
    [value]="formatter.format(fromDate)" 
    (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
    [readonly]="allOptions.readonly" 
    />
  <button class="input-group-text" (click)="datepicker.toggle()" type="button">
    <i class="bi bi-calendar"></i>
  </button>
  <input 
    #dpToDate class="form-control bg-white" 
    placeholder="yyyy-mm-dd" 
    name="dpToDate"
    [value]="formatter.format(toDate)" 
    (input)="toDate = validateInput(toDate, dpToDate.value)"
    [readonly]="allOptions.readonly" 
    />
  <button class="input-group-text rounded-end border-end" (click)="datepicker.toggle()" type="button"
    style="border-right: 1px solid lightgray !important">
    <i class="bi bi-calendar"></i>
  </button>
</div>

<ng-template #footerTemplate>
  <div class="d-flex justify-content-between align-items-center m-2">
    <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="resetDates()">Clear</a>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-flush float-end" (click)="datepicker.close()">Close</button>
    </div>
  </div>
</ng-template>
