import { Component, OnInit } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../../core/layout.service';
import { PageInfoService, PageLink } from '../../../../../core/page-info.service';

@Component({
  selector: 'launchpoint-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  showTitle = true;
  showBC = true;
  title$: Observable<string>;
  description$: Observable<string>;
  bc$: Observable<Array<PageLink>>;
  pageTitleCssClass = '';
  pageTitleDirection = 'row';

  constructor(private pageInfo: PageInfoService, private layout: LayoutService) {
    super();
  }

  ngOnInit(): void {
    this.title$ = this.pageInfo.title.asObservable();
    this.description$ = this.pageInfo.description.asObservable();
    this.bc$ = this.pageInfo.breadcrumbs.asObservable();
    this.showTitle = this.layout.getProp('pageTitle.display') as boolean;
    this.showBC = this.layout.getProp('pageTitle.breadCrumbs') as boolean;
    this.pageTitleCssClass = this.layout.getStringCSSClasses('pageTitle');
    this.pageTitleDirection = this.layout.getProp('pageTitle.direction') as string;
    //   try {
    //   this.bc$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
    //     console.log('this.bc$.subscribe', data);
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
  }
}
