import { Injectable } from '@angular/core';

declare const gtag: any;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {
  /**
   * trackEvent
   * @param eventName event Type - example: 'SCROLL_TO_TOP_CLICKED'
   * @param eventDetails the label that will show up in the dashboard as the events name
   * @param eventCategory  a short description of what happened
   */
  public trackEvent(eventName: string, eventDetails: string, eventCategory: string) {
    try {
      gtag('event', eventName, {
        // event Type - example: 'SCROLL_TO_TOP_CLICKED'
        event_category: eventCategory,
        // the label that will show up in the dashboard as the events name
        event_label: eventName,
        // a short description of what happened
        value: eventDetails,
      });
    } catch (error) {
      console.log(error);
    }
  }
}
