import { Directive, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICoreUserParamsCreateV3, ICoreUserParamsVerifyV3 } from '@launchpoint/core-types';
import { BehaviorSubject, takeUntil, tap, timer } from 'rxjs';
import isEmail from 'validator/lib/isEmail';
import { UserAuthActions } from '../+state';
import { userCodeVerify, userCreateWarning } from '../+state/actions';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../+state/auth-base.component';
import { EStateUserReducerActions } from '../+state/reducers/user.reducer';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { ILaunchpointAuth } from '../interfaces/auth.interface';
import { LaunchpointCoreClientAuthFormService } from '../services/auth-form-service';

@Directive()
export abstract class LaunchpointCoreClientAuthVerifyBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  form: FormGroup<ControlsOf<ILaunchpointAuth>>;

  EnumStateUserReducerActions = EStateUserReducerActions;
  return_url: string[];

  _LaunchpointCoreClientAuthFormService = inject(LaunchpointCoreClientAuthFormService);
  _route = inject(ActivatedRoute);
  email: string;
  phone: number;
  password: string;

  disabled$ = new BehaviorSubject<boolean>(false);

  /**
   * this.email = this._LaunchpointCoreClientAuthFormService?.form?.value?.email;
   */
  ngOnInit(): void {
    this.form = this._LaunchpointCoreClientAuthFormService.getSetVerifyForm;
    // check there is an @
    const verifyEmail = isEmail(this.form.value.email);

    this.email = verifyEmail && this._LaunchpointCoreClientAuthFormService?.form?.value?.email;
    this.phone = !verifyEmail && +this.formatPhone(this._LaunchpointCoreClientAuthFormService?.form?.value?.email);
    this.password = this._LaunchpointCoreClientAuthFormService?.form?.value?.password ?? null;

    this.return_url$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        this.return_url = data;
      },
    });
  }

  debounce() {
    this.disabled$.next(true);

    timer(15000)
      .pipe(tap(() => this.disabled$.next(false)))
      .subscribe();
  }

  resendCode() {
    const disabled = this.disabled$.value;

    if (disabled) {
      return;
    }

    const verifyEmail = isEmail(this.form.value.email);

    const data = {
      ...(verifyEmail ? { email: this.email } : { phone: this.phone }),
    } as ICoreUserParamsCreateV3;

    this._Store.dispatch(
      UserAuthActions.userCreate({
        data,
        path: ['/auth', 'verify'],
      })
    );

    this.debounce();
  }

  async submit() {
    if (!this.form.value.email || !this.form.value.token) {
      this._Store.dispatch(
        userCreateWarning({
          error: { error: 'Please fill in all fields', message: 'Please fill in all fields' },
        })
      );
    }

    const verifyEmail = isEmail(this.form.value.email);

    this.email = verifyEmail && this._LaunchpointCoreClientAuthFormService?.form?.value?.email;
    this.phone = !verifyEmail && +this.formatPhone(this._LaunchpointCoreClientAuthFormService?.form?.value?.email);
    this.password = this._LaunchpointCoreClientAuthFormService?.form?.value?.password ?? null;

    // console.log('submit', this.form.value);

    let returnUrl = this._route.snapshot.queryParams['returnUrl'.toString()]?.split('/').filter((e) => e) || ['/'];

    if (this.return_url) {
      // console.log('there is state url');
      returnUrl = this.return_url;
    }
    // console.log({ returnUrl });

    const data: ICoreUserParamsVerifyV3 = {
      code: this.form.value.token,
      ...(verifyEmail ? { email: this.email } : { phone: this.phone }),
    };

    if (!this._config.registration.require_password) {
      returnUrl = ['/'];
    }

    // console.log('submit', returnUrl);
    this._Store.dispatch(userCodeVerify({ data, returnUrl }));

    this.debounce();
  }
}
