import { Component, OnInit } from '@angular/core';
import { LaunchpointCoreClientUpdateEmailPasswordSmartUserBaseComponent } from '@launchpoint/core-client';
import { Subscription, takeUntil } from 'rxjs';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-update-email-password-smart-user',
  templateUrl: './update-email-password-smart-user.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebProfileUpdateEmailPasswordSmartUserComponent
  extends LaunchpointCoreClientUpdateEmailPasswordSmartUserBaseComponent
  implements OnInit
{
  changeEmailPasswordMainCard = true;
  changeEmailCard = false;
  changePasswordCard = false;
  subscriptions: Subscription[] = [];
  constructor(private _swalService: LaunchpointSweetAlertService) {
    super();
  }
  ngOnInit(): void {
    this.error();
    this.success();
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this._swalService.fireToast({ icon: 'success', title: data.message });
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this._swalService.fireToast({ icon: 'error', title: data.error.message });
      },
    });
  }
}
