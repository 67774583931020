import { Directive, EventEmitter, Input, OnInit, Output, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMediaCategory, IMediaCategorySearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs';
import { ISearchAheadComponent } from '../../../../components/search-ahead/search-ahead-component.interface';
import { LaunchpointFormControlSearchAheadBaseComponent } from '../../../../forms/from-control/form-control-search-ahead-base.component';
import { LaunchpointMediaCategoriesService } from '../services/media-categories.service';

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreClientMediaCategoriesSearchAheadBaseComponent),
      multi: true,
    },
  ],
})
export abstract class LaunchpointCoreClientMediaCategoriesSearchAheadBaseComponent
  extends LaunchpointFormControlSearchAheadBaseComponent
  implements ISearchAheadComponent, OnInit
{
  _LaunchpointMediaCategoriesService = inject(LaunchpointMediaCategoriesService);
  public _Store: Store = inject(Store);

  @Input() query: IMediaCategorySearchQuery;
  @Input() selected: string;
  @Input() placeholder: string;

  @Output() selectedChange = new EventEmitter<string>();
  @Output() selectedCategory = new EventEmitter<IMediaCategory>();

  ngOnInit(): void {
    this.runQuery('');
    this.getById(this.selected);
  }

  async getById(_id: string) {
    if (!_id) {
      return;
    }
    this.loading = true;
    this._LaunchpointMediaCategoriesService
      .getById(_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.placeholder = data.title;
        this.loading = false;
        return this.selectedCategory.emit(data);
      });
  }

  formatter = (result: IMediaCategory): string => result.title;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly IMediaCategory[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._LaunchpointMediaCategoriesService
      .search({ pagination: { limit: 100, skip: 0, count: 0 }, querySort: { created_at: -1 }, query: { ...this.query, search } })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  /**
   * Search aheads use this instead of `adjustValue`
   * @param data
   * @returns
   */
  selectData(data: { item: IMediaCategory }) {
    this.selected = data.item._id;
    this.onChanged(data.item._id);
    this.onTouched();
    return this.selectedCategory.emit(data.item);
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.selected = value;
      this.getById(this.selected);
    }
  }
}
