import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  configureURL,
  ELaunchpointUserTableView,
  ELaunchpointUserViewResources,
  IQueryUserView,
  IUserView,
  IUserViewParamsCreate,
  IUserViewParamsSearch,
  IUserViewParamsUpdate,
  IUserViewsController,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointUserViewsConfig, USER_VIEWS_CONFIG_TOKEN } from '../+state/interfaces/views.interface';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';

export const USER_TABLE_PREFERENCES_URL_TOKEN = new InjectionToken<string>('USER_TABLE_PREFERENCES_URL_TOKEN');

@Injectable({
  providedIn: 'root',
})
export class UserViewV1Service extends LaunchpointSecurityUserIdHeaderService implements IUserViewsController {
  API_URL = '/api/backend/';

  constructor(
    public _Store: Store,
    public http: HttpClient,
    @Inject(USER_VIEWS_CONFIG_TOKEN) public _LaunchpointUserViewsConfig: LaunchpointUserViewsConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointUserViewsConfig?.base_url,
      version: 1,
    });
  }

  search(body: IUserViewParamsSearch): Observable<IQueryUserView> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IQueryUserView>(this.API_URL + ELaunchpointUserViewResources.USER_VIEW_SEARCH, body, { headers });
      })
    );
  }

  findById(_id: string): Observable<IUserView> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<IUserView>(this.API_URL + ELaunchpointUserTableView.USER_VIEW + '/' + _id, { headers });
      })
    );
  }

  create(body: IUserViewParamsCreate): Observable<IUserView> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IUserView>(this.API_URL + ELaunchpointUserTableView.USER_VIEW, body, { headers });
      })
    );
  }

  update(body: IUserViewParamsUpdate): Observable<IUserView> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IUserView>(this.API_URL + ELaunchpointUserTableView.USER_VIEW, body, { headers });
      })
    );
  }

  delete(_id: string): Observable<IUserView> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<IUserView>(this.API_URL + ELaunchpointUserTableView.USER_VIEW + '/' + _id, { headers });
      })
    );
  }
}
