import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  INotification,
  INotificationChannelController,
  INotificationChannelParamsCreate,
  INotificationChannelParamsDelete,
  INotificationChannelParamsUpdate,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../interfaces/core-notifications.interface';

@Injectable()
export class NotificationChannelsHTTPService extends LaunchpointSecurityUserIdHeaderService implements INotificationChannelController {
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  routes = {
    delete: '/delete',
  };

  //TODO @FE -- setup config file for this
  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'notifications/channels',
    });
  }
  getById(id: string): Observable<INotification> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<INotification>(this.API_URL + '/' + id, { headers });
      })
    );
  }
  create(body: INotificationChannelParamsCreate): Observable<INotification> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<INotification>(this.API_URL, body, { headers });
      })
    );
  }
  update(body: INotificationChannelParamsUpdate): Observable<INotification> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<INotification>(this.API_URL, body, { headers });
      })
    );
  }
  delete(body: INotificationChannelParamsDelete): Observable<INotification> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<INotification>(this.API_URL + this.routes.delete, body, { headers });
      })
    );
  }
}
