<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <launchpoint-toolbar [options]="options" (refresh)="refresh()">
      <launchpoint-activity-history-filter [admin]="admin" [query]="query" [pagination]="pagination"
        [querySort]="querySort" />
    </launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <launchpoint-activity-history-table [admin]="admin" [query]="query" [pagination]="pagination"
          [querySort]="querySort" (sortDataEmitter)="sortData($event)" />
      </div>
    </div>
  </div>
</div>