import { createReducer, on } from '@ngrx/store';
import * as CompanyActions from '../actions/company.actions';
import { LaunchpointCompanyEntityState } from '../interfaces/company.interface';
import { COMPANY_USERS_REDUCER_FUNCTIONS } from './company-users/company-users.reducer-functions';
import { COMPANY_CONFIG } from './config';
import { LaunchpointCompanyEntityAdaptor, initialCompanyState } from './interface';

export const LaunchpointCompanyReducer = createReducer(
  initialCompanyState,
  ...COMPANY_USERS_REDUCER_FUNCTIONS,
  on(CompanyActions.searchAllCompanies, (state) => {
    return LaunchpointCompanyEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(CompanyActions.searchAllCompaniesSuccess, (state, { data }) => {
    const companyEntities = data.data.map((company) => {
      const companyEntity: LaunchpointCompanyEntityState = {
        company_id: company._id,
        company: company,
        loaded: true,
        loading: false,
        error: null,
        company_config: COMPANY_CONFIG,
        company_user_pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
        company_user_sorting: { created_at: -1 },
        company_user_query: { search: '' },
        company_users: null,
      };
      return companyEntity;
    });
    return LaunchpointCompanyEntityAdaptor.addMany(companyEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(CompanyActions.searchAllCompaniesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(CompanyActions.updateAllCompaniesQuery, (state, { query }) => {
    return LaunchpointCompanyEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(CompanyActions.searchAllCompanyUsersSuccess, (state, { data, company_id }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          company_users: data.data,
          company_user_pagination: data.pagination,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.searchAllCompanyUsersFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),

  on(CompanyActions.updateAllCompaniesPagination, (state, { pagination }) => {
    return LaunchpointCompanyEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(CompanyActions.updateAllCompaniesPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(CompanyActions.updateAllCompaniesSorting, (state, { querySort }) => {
    return LaunchpointCompanyEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(CompanyActions.getCompanyById, (state, { company_id }) => ({
    ...state,
    selected_company_id: company_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(CompanyActions.getCompanyByIdSuccess, (state, { company }) => {
    const entity: LaunchpointCompanyEntityState = {
      company_id: company._id,
      company: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      company_users: [],
      company_user_pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
      company_user_sorting: { created_at: -1 },
      company_user_query: { search: '' },
    };
    return LaunchpointCompanyEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_company_id: company._id,
      loaded: true,
      loading: false,
    });
  }),
  on(CompanyActions.getCompanyByIdFailure, (state, { company_id, error }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  on(CompanyActions.getCompanyByIdSetId, (state, { company_id }) => ({
    ...state,
    selected_company_id: company_id,
  })),

  on(CompanyActions.createCompany, (state) => ({
    ...state,
    loading: true,
    error: null,
    success_message: null,
  })),
  on(CompanyActions.createCompanySuccess, (state, { data: company, success_message }) => {
    const entity: LaunchpointCompanyEntityState = {
      company_id: company.company._id,
      company: company.company,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
      company_user_pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
      company_user_sorting: { created_at: -1 },
      company_user_query: { search: '' },
      company_users: null,
    };
    return LaunchpointCompanyEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      error: null,
      success_message: null,
      selected_company_id: company.company._id,
    });
  }),
  on(CompanyActions.createCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
    success_message: null,
  })),
  on(CompanyActions.createCompanySignUp, (state) => ({
    ...state,
    loading: true,
    error: null,
    success_message: null,
  })),

  on(CompanyActions.createCompanySignUpSuccess, (state, { data, success_message }) => {
    const { company } = data;
    const entity: LaunchpointCompanyEntityState = {
      company_id: company._id,
      company: company,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
      company_user_pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
      company_user_sorting: { created_at: -1 },
      company_user_query: { search: '' },
      company_users: null,
    };
    return LaunchpointCompanyEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      error: null,
      success_message: success_message,
      selected_company_id: company._id,
    });
  }),
  on(CompanyActions.createCompanyFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
    success_message: null,
  })),
  on(CompanyActions.deactivateCompany, (state, { data }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: data.account_id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(CompanyActions.updateCompany, (state, { data }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: data.account_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(CompanyActions.updateCompanySuccess, (state, { company, success_message }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company._id,
        changes: {
          company,
          loading: false,
          loaded: true,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.updateCompanyFailure, (state, { company_id, error }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.deleteCompany, (state, { company_id }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.deleteCompanySuccess, (state, { data, success_message }) => {
    return LaunchpointCompanyEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(CompanyActions.deleteCompanyFailure, (state, { company_id, error }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.deleteCompanyInfo, (state, { company_id }) => {
    return LaunchpointCompanyEntityAdaptor.updateOne(
      {
        id: company_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CompanyActions.closeCompanySuccessMessage, (state) => ({
    ...state,
    success_message: null,
  })),
  // CLOSE ERROR MESSAGE
  on(CompanyActions.closeCompanyErrorMessage, (state) => ({
    ...state,
    // error: null,
  })),
  ...COMPANY_USERS_REDUCER_FUNCTIONS
);
