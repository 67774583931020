import {
  ICoreUser,
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsAddMainUserAccount,
  ICoreUserAccountsParamsAddSubUserAccount,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  ICoreUserAccountsSecurityRolesParamsUpdate,
  ICoreUserAddressParamsCreate,
  ICoreUserAddressParamsUpdate,
  ICoreUserDemographicsParamsUpdatePhone,
  ICoreUserParamsChangePassword,
  ICoreUserParamsSetEmail,
  ICoreUserProfileParamsUpdate,
  ICoreUserSearchQuery,
  ICoreUserSearchResults,
  ICoreUserSecurityRoleParamsUpdate,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllUsers = createAction('[Users] searchAllUsers');
export const searchAllUsersSuccess = createAction(
  '[Users] searchAllUsersSuccess',
  props<{ data: ICoreUserSearchResults; success_message?: string }>()
);
export const searchAllUsersFailure = createAction('[Users] searchAllUsersFailure', props<{ error: IHttpException }>());
// Mechanics
export const searchAllUsersApplyView = createAction(
  '[Users] searchAllUsersApplyView',
  props<{ pagination?: IQueryPageination; querySort?: IQuerySort; query: ICoreUserSearchQuery }>()
);
export const updateAllUsersPagination = createAction('[Users] updateAllUsersPagination', props<{ pagination: IQueryPageination }>());
export const updateAllUsersPaginationScroll = createAction('[Users] updateAllUsersPaginationScroll', props<{ pagination: IQueryPageination }>());
export const updateAllUsersSorting = createAction('[Users] updateAllUsersSorting', props<{ querySort: IQuerySort }>());
export const updateAllUsersQuery = createAction('[Users] updateAllUsersQuery', props<{ query: ICoreUserSearchQuery }>());
// getById
export const getUserById = createAction('[Users] getUserById', props<{ user_id: string }>());
export const getUserByIdSetId = createAction('[Users] getUserById', props<{ user_id: string }>());
export const getUserByIdSuccess = createAction('[Users] getUserByIdSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const getUserByIdFailure = createAction('[Users] getUserByIdFailure', props<{ user_id: string; error: IHttpException }>());
// Update User Profile
export const updateUserProfile = createAction('[Users] updateUserProfile', props<{ data: ICoreUserProfileParamsUpdate }>());
export const updateUserProfileSuccess = createAction('[Users] updateUserProfileSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const updateUserProfileFailure = createAction('[Users] updateUserProfileFailure', props<{ user_id: string; error: IHttpException }>());
// Create User Address
export const createUserAddress = createAction('[Users] createUserAddress', props<{ data: ICoreUserAddressParamsCreate }>());
export const createUserAddressSuccess = createAction('[Users] createUserAddressSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const createUserAddressFailure = createAction('[Users] createUserAddressFailure', props<{ user_id: string; error: IHttpException }>());
// Update User Address
export const updateUserAddress = createAction('[Users] updateUserAddress', props<{ data: ICoreUserAddressParamsUpdate }>());
export const updateUserAddressSuccess = createAction('[Users] updateUserAddressSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const updateUserAddressFailure = createAction('[Users] updateUserAddressFailure', props<{ user_id: string; error: IHttpException }>());
// Update User Phone
export const updateUserPhone = createAction('[Users] updateUserPhone', props<{ data: ICoreUserDemographicsParamsUpdatePhone }>());
export const updateUserPhoneSuccess = createAction('[Users] updateUserPhoneSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const updateUserPhoneFailure = createAction('[Users] updateUserPhoneFailure', props<{ user_id: string; error: IHttpException }>());
// Update User Password
export const updateUserPassword = createAction('[Users] updateUserPassword', props<{ data: ICoreUserParamsChangePassword }>());
export const updateUserPasswordSuccess = createAction('[Users] updateUserPasswordSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const updateUserPasswordFailure = createAction('[Users] updateUserPasswordFailure', props<{ user_id: string; error: IHttpException }>());
// Update User Email
export const setUserEmail = createAction('[Users] setUserEmail', props<{ data: ICoreUserParamsSetEmail }>());
export const setUserEmailSuccess = createAction('[Users] setUserEmailSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const setUserEmailFailure = createAction('[Users] setUserEmailFailure', props<{ user_id: string; error: IHttpException }>());
// Create Security Roles
export const addUserRole = createAction('[Users] addUserRole', props<{ params: ICoreUserSecurityRoleParamsUpdate }>());
export const addUserRoleSuccess = createAction('[Users] addUserRoleSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const addUserRoleFailure = createAction('[Users] addUserRoleFailure', props<{ error: IHttpException }>());
// Remove Security Roles
export const deleteUserRole = createAction('[Users] deleteUserRole', props<{ params: ICoreUserSecurityRoleParamsUpdate }>());
export const deleteUserRoleSuccess = createAction('[Users] deleteUserRoleSuccess', props<{ user: ICoreUser; success_message?: string }>());
export const deleteUserRoleFailure = createAction('[Users] deleteUserRoleFailure', props<{ user_id: string; error: IHttpException }>());
// Close Alerts - //TODO: is this needed anymore?
export const closeUserSuccessMessage = createAction('[Users] closeUserSuccessMessage');
export const closeUserErrorMessage = createAction('[Users] closeUserErrorMessage');

// Accounts actions
// Remove

export const adminRemoveAccount = createAction(`[Users] adminRemoveAccount`, props<{ data: ICoreUserAccountsParamsRemove }>());
// Resend Invite
export const adminResendAccountInvite = createAction(`[Users] adminResendAccountInvite`, props<{ data: ICoreUserAccountsParamsResendInvite }>());
// Add without Invite
export const adminAcceptAccountInvitation = createAction(
  `[Users] adminAcceptAccountInvitation`,
  props<{ data: ICoreUserAccountsParamsAcceptInvitations }>()
);
// Account role change
export const adminAddAccountRoles = createAction(`[Users] adminAddAccountRoles`, props<{ data: ICoreUserAccountsSecurityRolesParamsUpdate }>());
export const adminRemoveAccountRoles = createAction(`[Users] adminRemoveAccountRoles`, props<{ data: ICoreUserAccountsSecurityRolesParamsUpdate }>());
// Add Sub user account
export const adminAddSubUserAccount = createAction(`[Users] adminAddSubUserAccount`, props<{ data: ICoreUserAccountsParamsAddSubUserAccount }>());

//  add main user account
export const adminAddMainUserAccount = createAction(`[Users] adminAddMainUserAccount`, props<{ data: ICoreUserAccountsParamsAddMainUserAccount }>());
// Account manipulation error and success
export const accountSuccess = createAction('[Users] accountSuccess', props<{ user?: ICoreUser; success_message?: string }>());
export const accountFailure = createAction('[Users] accountFailure', props<{ user_id?: string; error: IHttpException }>());
