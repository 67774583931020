<input
  type="text"
  [class]="cssClass"
  [placeholder]="placeholder"
  (focus)="onFocus($event)"
  [ngbTypeahead]="search"
  [resultFormatter]="formatter"
  [inputFormatter]="formatter"
  [editable]="true"
  (selectItem)="selectData($event)"
/>
