import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InviteUsersModalComponent } from './invite-users-modal/invite-users-modal.component';
import { MainModalComponent } from './main-modal/main-modal.component';
import { ModalComponent } from './modal/modal.component';
import { UpgradePlanModalComponent } from './upgrade-plan-modal/upgrade-plan-modal.component';

@NgModule({
  declarations: [InviteUsersModalComponent, MainModalComponent, UpgradePlanModalComponent, ModalComponent],
  imports: [CommonModule, InlineSVGModule, RouterModule],
  exports: [InviteUsersModalComponent, MainModalComponent, UpgradePlanModalComponent, ModalComponent],
})
export class ModalsModule {}
