import { Directive, inject } from '@angular/core';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../interfaces/auth-storage-service.interface';
import * as UserAuthActions from './actions/user/user.actions';
import { EStateUserReducerActions } from './reducers/user.reducer';
import * as UserSelectors from './selectors/user.selectors';

@Directive()
export abstract class LaunchpointCoreClientAuthStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public _Store = inject(Store);
  public _config: ICoreAuthConfig = inject(APP_AUTH_CONFIG_TOKEN);

  EnumStateUserReducerActions = EStateUserReducerActions;
  return_url$ = this._Store.select(UserSelectors.selectReturnUrl);
  initCheckComplete$ = this._Store.select(UserSelectors.selectUserInitCheckComplete);
  userToken$ = this._Store.select(UserSelectors.selectUserToken);
  userRefreshToken$ = this._Store.select(UserSelectors.selectUserRefreshToken);
  loading$ = this._Store.select(UserSelectors.selectUserLoading);
  error$ = this._Store.select(UserSelectors.selectUserError);
  userEmailVerified$ = this._Store.select(UserSelectors.selectUserEmailVerified);
  success$ = this._Store.select(UserSelectors.selectUserSuccessMessage);

  user$ = this._Store.select<ICoreUser>(UserSelectors.selectUser);
  // userErrorActions$ = (actions: string[]) => this._Store.select(UserSelectors.selectUserErrorActions(actions));
  userAccounts$ = this._Store.select(UserSelectors.selectUserAccounts);
  userAddress$ = this._Store.select(UserSelectors.selectUserAddress);
  selectedAccountProfile$ = this._Store.select(UserSelectors.selectSelectedUserProfile);
  selectedAccountID$ = this._Store.select(UserSelectors.selectSelectedAccountId);
  selectedUserAccount$ = this._Store.select(UserSelectors.selectSelectedUserAccount);
  selectedUserID$ = this._Store.select(UserSelectors.selectSelectedUserId);
  userSecurityRoles$ = this._Store.select(UserSelectors.selectSelectedUserSecurityRoles);

  public formatPhone(phone: string | number): string {
    return phone
      .toString()
      .replace(/[^\d]+/g, '')
      .trim();
  }

  closeSuccessMessage() {
    this._Store.dispatch(UserAuthActions.closeSuccessMessage());
  }

  closeErrorMessage() {
    this._Store.dispatch(UserAuthActions.closeErrorMessage());
  }

  /**Success and Error Toasts */
  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (data) {
          this.toast({ title: data?.message, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (data) {
          this.toast({ title: data.error, icon: 'error' });
        }
      },
    });
  }

  toast(params) {
    //
  }
}
