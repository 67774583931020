import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-activity-drawer',
  templateUrl: './activity-drawer.component.html',
})
export class ActivityDrawerComponent {
  // constructor() {}
  // ngOnInit(): void {}
}
