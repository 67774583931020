import { Directive } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
// import { IClientParamsUpdate } from '@skylabsolutions/user-types';
import { Observable, takeUntil } from 'rxjs';
import { updateCompany } from '../+state/actions/company.actions';
import { LaunchpointCompanyEntityState } from '../+state/interfaces';
import { COMPANY_CONFIG } from '../+state/reducers/config';
import { selectLaunchpointCompanyError, selectLaunchpointSelectedCompanyEntity } from '../+state/selectors/company.selectors';
import { ICoreCompanyParamsUpdate } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';

@Directive()
export abstract class CompanyUpdateBaseComponent extends CompanyStateBaseComponent {
  constructor(public _Store: Store) {
    super();
  }

  submit(obj: ICoreCompanyParamsUpdate) {
    this._Store.dispatch(updateCompany({ data: obj }));
  }
}
