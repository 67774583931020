import { computed, inject, Injectable, signal } from '@angular/core';
import { FOOTER_MENU, HEADER_MENU, PRIMARY_CTA, SECONDARY_CTA } from '../config/config';

import { Router } from '@angular/router';
import { GoogleAnalyticsService, ManychatPixelService } from '@launchpoint/core-web';

@Injectable({ providedIn: 'root' })
export class StadnickDevConfigService {
  // Signals to store menu data
  headerMenu = signal(this.sortMenuByOrder(HEADER_MENU));
  footerMenu = signal(this.sortMenuByOrder(FOOTER_MENU));

  primaryCallToAction = signal(PRIMARY_CTA);
  secondaryCallToAction = signal(SECONDARY_CTA);
  _GoogleAnalyticsService = inject(GoogleAnalyticsService);
  _ManychatPixelService = inject(ManychatPixelService);
  route = inject(Router);
  /**
   * Sorts the menu based on the 'order' field, both at the root and children level
   * @param menu The menu to be sorted
   * @returns The sorted menu
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortMenuByOrder(menu: any[]): any[] {
    return menu
      .map((item) => ({
        ...item,
        children: item.children ? this.sortMenuByOrder(item.children) : item.children,
      }))
      .sort((a, b) => a.order - b.order);
  }

  getFooterItems = computed(() => {
    return this.footerMenu()
      .filter((item) => item?.active) // Filter active items
      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)) // Sort parent items by order
      .map((item) => ({
        ...item,
        children: item?.children
          ?.filter((child) => child?.active) // Filter active children
          .sort((c, d) => (c?.order ?? 0) - (d?.order ?? 0)), // Sort children by order
      }))
      .filter((item) => item.children?.length);
  });
}
