import * as RouterActions from './actions/router.actions';

export * from './reducer/router.reducer';
export * from './effects';
export { RouterActions };

// This is supposed to be supported as of ES2020
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#export--as-ns-syntax
// export * as UserActions from './actions/user'
// export * as RouterActions from './actions/router'
