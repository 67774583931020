import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ICoreUserSecurityRoleParamsUpdate } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';

interface IProfileSecurityRolesConfig {
  title: string;
  fields: {
    security: boolean;
  };
}

export type ICoreUserSecurityRoleParamsUpdateNoUserID = Omit<ICoreUserSecurityRoleParamsUpdate, 'user_id'>;

@Directive()
export class LaunchpointCoreWebProfileSecurityRolesBaseComponent {
  @Input() _config: IProfileSecurityRolesConfig = {
    title: 'Security Roles',
    fields: {
      security: true,
    },
  };
  @Input() user_security_roles: string[];
  @Input() isLoading = false;

  @Input() security_roles: string[] = [];

  @Output() profileSecurityAdd = new EventEmitter<ICoreUserSecurityRoleParamsUpdateNoUserID>();
  @Output() profileSecurityRemove = new EventEmitter<ICoreUserSecurityRoleParamsUpdateNoUserID>();

  // constructor() {

  // }

  setUserRole($event, role: string) {
    const data: ICoreUserSecurityRoleParamsUpdateNoUserID = {
      security_roles: [role],
    };
    if ($event.target.checked) {
      return this.profileSecurityAdd.emit(data);
    }
    if (!$event.target.checked) {
      return this.profileSecurityRemove.emit(data);
    }
  }

  userHasRole(role: string) {
    const has_role = this.user_security_roles?.includes(role);
    if (has_role) {
      return true;
    } else {
      return false;
    }
  }
}
