<div class="card">
  <div class="card-body">
    <div class="table-responsive" [style.display]="(loading$ | async) ? 'block' : ''">
      <table class="table-row-dashed table-hover fs-6 gy-5 table align-middle">
        <ng-container *ngIf="loading$ | async">
          <span class="indicator-label m-5" [style.display]="'block'">
            Please wait...
            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
          </span>
        </ng-container>

        <thead>
          <tr class="fw-bold fs-7 text-uppercase gs-0 text-gray-700">
            <th class="min-w-100px sorting">Name</th>
            <th class="min-w-100px sorting">Description</th>
            <th class="min-w-50px sorting">Public</th>
            <th class="min-w-100px text-end">Created</th>
          </tr>
        </thead>

        <tbody class="fw-semibold fs-6">
          <tr *ngIf="(loading$ | async) === false && (topicEntity$ | async)?.length === 0">
            <td>No Topics</td>
          </tr>

          <tr *ngFor="let entity of topicEntity$ | async" (click)="open(entity?.topic)">
            <td>
              {{ entity?.topic?.topic }}
            </td>
            <td>
              {{ entity?.topic?.description }}
            </td>
            <td>
              <i
                class="bi fs-6"
                [ngClass]="{
                  'bi-check-circle text-success': entity?.topic?.topic_type === 'public',
                  'bi-x-circle text-danger': entity?.topic?.topic_type === 'private',
                }"
              ></i>
            </td>
            <td class="text-end">{{ entity?.topic?.created_at | date }}</td>
          </tr>
        </tbody>
      </table>

      <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
    </div>
  </div>
</div>
