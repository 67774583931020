import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LaunchpointFormControlDropdownBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-drop-down',
  templateUrl: 'form-control-drop-down.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlDropDownComponent),
      multi: true,
    },
  ],
})
export class FormControlDropDownComponent extends LaunchpointFormControlDropdownBaseComponent {
  @Input() options: string[] | any[];
  @Input() selected: string | any;

  // @Output() selectedChange = new EventEmitter<ECommercialAutoCoverageOptionsStatus>();
  constructor() {
    super();
  }
  /**
   * This is the initial value from the from. If your form has a default value here is where it's initialized. You can extend this method to update other changes.
   * @param value
   */
  writeValue(value: string) {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  /**
   * Anytime your template is adjusting the value this is the method for it. You can also emit this if you need to extend features to the top.
   * @param value
   */
  adjustValue(value: string) {
    this.selected = value;
    // this.ratingText = rating.text;
    this.onChanged(value);
    this.onTouched();
  }
}
