import { Directive, Input } from '@angular/core';

import {
  ENotificationChannelType,
  IHttpException,
  ILaunchpointNotificationAnalytics,
  INotificationMessageSearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { MessagesEntityState } from './interfaces/messages-entity.interface';
import {
  selectMessages,
  selectMessagesAnalytics,
  selectMessagesError,
  selectMessagesLoading,
  selectMessagesPagination,
  selectMessagesQuery,
  selectMessagesQuerySort,
  selectMessagesSuccess,
  selectSelectedMessageError,
  selectSelectedMessageLoading,
  selectSelectedMessageSuccess,
} from './selectors/messages.selectors';

@Directive()
export abstract class LaunchpointCoreClientNotificationsMessagesStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  NotificationChannelType = ENotificationChannelType;
  NotificationChannelTypeValues = Object.values(ENotificationChannelType);

  @Input() pageLimit: number;
  @Input() query: INotificationMessageSearchQuery = {};

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<INotificationMessageSearchQuery>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  messages$: Observable<MessagesEntityState>;
  messagesEntity$: Observable<MessagesEntityState[]>;
  messagesEntityLoading$: Observable<boolean>;
  messagesEntityError$: Observable<IHttpException | null>;
  messagesEntitySuccess$: Observable<string | null>;
  analytics$: Observable<ILaunchpointNotificationAnalytics[]>;

  constructor(public _Store: Store) {
    super();

    this.pagination$ = this._Store.select(selectMessagesPagination);
    this.loading$ = this._Store.select(selectMessagesLoading);
    this.loaded$ = this._Store.select(selectMessagesLoading);
    this.querySort$ = this._Store.select(selectMessagesQuerySort);
    this.query$ = this._Store.select(selectMessagesQuery);
    this.error$ = this._Store.select(selectMessagesError);
    this.success$ = this._Store.select(selectMessagesSuccess);
    this.messagesEntity$ = this._Store.select(selectMessages);
    this.messagesEntityLoading$ = this._Store.select(selectSelectedMessageLoading);
    this.messagesEntitySuccess$ = this._Store.select(selectSelectedMessageSuccess);
    this.messagesEntityError$ = this._Store.select(selectSelectedMessageError);
    this.analytics$ = this._Store.select(selectMessagesAnalytics);
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
    this.messagesEntitySuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
    this.messagesEntityError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
  }
  toast(params: { icon; title }) {
    // extend
  }
}
