<div class="card mb-6 mb-xl-9">
  <div class="card-body pt-9 pb-0">
    <!--begin::Details-->
    <div class="d-flex flex-wrap flex-sm-nowrap mb-6">
      <!--begin::Wrapper-->
      <div class="flex-grow-1" *ngIf="company$ | async as company">
        <!--begin::Head-->
        <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
          <!--begin::Details-->
          <div class="d-flex flex-column">
            <!--begin::Status-->
            <div class="d-flex align-items-center mb-1">
              <a class="text-gray-800 fs-2 fw-bolder me-3">{{ company.company_name }}</a>
              <!-- <span
                    class="badge me-auto"
                    [ngClass]="{
                      'badge-light-warning': company.status === statusOptions.TRIALING,
                      'badge-light-success': company.status === statusOptions.ACTIVE,
                      'badge-light-danger': company.status === statusOptions.INACTIVE
                    }"
                    >{{ company.status }}</span
                  > -->
            </div>
            <!--end::Status-->
            <!--begin::Description-->

            <!-- Conditional if account owner full name exists it will display Owner: and name, if no full name, it will be blank -->
            <!-- <ng-container *ngIf="company?.account_owner?.profile?.full_name">
                  <div class="d-flex flex-wrap fw-bold mb-4 fs-5 text-gray-700">Owner: {{ company?.account_owner?.profile?.full_name }}</div>
                </ng-container> -->

            <!--end::Description-->
          </div>
          <!--end::Details-->

          <!--begin::Actions-->
          <div class="d-flex mb-4">
            <!-- <button class="btn btn-sm btn-primary me-3" [disabled]="true" placement="bottom"
              ngbTooltip="Complete all tasks to activate">
              Activate
            </button> -->

            <launchpoint-ellipsis-menu [options]="options" [cssClass]="'m-0 p-0'"></launchpoint-ellipsis-menu>

          </div>
          <!--end::Actions-->
        </div>
        <!--end::Head-->
        <!--begin::Info-->
        <div class="d-flex flex-wrap justify-content-start">
          <!--begin::Stats-->
          <div class="d-flex flex-wrap">
            <!--begin::Stat-->
            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              <!--begin::Number-->
              <div class="d-flex align-items-center">
                <div class="fs-4 fw-bolder">{{ company.created_at | date }}</div>
              </div>
              <!--end::Number-->
              <!--begin::Label-->
              <div class="fw-bold fs-6 text-gray-700">Start Date</div>
              <!--end::Label-->
            </div>
            <!--end::Stat-->
            <!--begin::Stat-->
            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
              <!--begin::Number-->
              <div class="d-flex align-items-center">
                <div class="fs-4 fw-bolder counted">{{ company.account_type }}</div>
              </div>
              <!--end::Number-->
              <!--begin::Label-->
              <div class="fw-bold fs-6 text-gray-700" placement="bottom" ngbTooltip="Complete all tasks to activate">
                Account Type</div>
              <!--end::Label-->
            </div>
            <!--end::Stat-->
          </div>
          <!--end::Stats-->
          <!--begin::Users-->
          <!-- <div class="symbol-group symbol-hover mb-3" *ngIf="connections">
                <div class="symbol symbol-35px symbol-circle" *ngFor="let connection of connections | slice: 0:7">
                  <span
                    class="symbol-label fw-bolder"
                    [ngClass]="{
                      'bg-success text-inverse-success': connection.connected_account_type === accountTypes.CLIENT,
                      'bg-primary text-inverse-primary': connection.connected_account_type === accountTypes.CARRIER,
                      'bg-warning text-inverse-warning ': connection.connected_account_type === accountTypes.REINSURER,
                      'bg-info text-inverse-info ': connection.connected_account_type === accountTypes.AGENCY
                    }"
                    [routerLink]="['./connections']"
                  >
                    {{ connection.connected_account_company_name | slice: 0:1 }}
                  </span>
                </div>
                <a [routerLink]="['./connections']" class="symbol symbol-35px symbol-circle" *ngIf="connections.length > 7">
                  <span class="symbol-label bg-dark text-gray-300 fs-8 fw-bolder">+{{ connections.length - 7 }}</span>
                </a>
              </div> -->
          <!--end::Users-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Details-->
    <div class="separator"></div>
    <!--begin::Nav-->
    <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
      <!--begin::Nav item-->
      <li *ngFor="let menu of menu_items" class="nav-item">
        <a class="nav-link text-active-primary me-6 py-5" [routerLink]="'./' + menu.page" routerLinkActive="active"> {{
          menu.title }} </a>
      </li>
      <!--end::Nav item-->
    </ul>
    <!--end::Nav-->
  </div>
</div>