import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as AlertBannerActions from '../actions/alert-banner.actions';
import * as AlertBannerSelectors from '../selectors/alert-banner.selectors';
import { CoreAlertBannerHTTPService } from '../../services/alert-banner-http.service';

@Injectable()
export class AlertBannerEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _CoreAlertBannerHTTPService: CoreAlertBannerHTTPService
  ) {}

  getAllAlertBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        AlertBannerActions.searchAllAlertBanners,
        AlertBannerActions.updateAllAlertBannerPagination,
        AlertBannerActions.updateAllAlertBannerQuery,
        AlertBannerActions.updateAllAlertBannerSorting
      ),
      withLatestFrom(this._Store.select(AlertBannerSelectors.getAllAlertBannerState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._CoreAlertBannerHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((data) => {
            return AlertBannerActions.searchAllAlertBannersSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(AlertBannerActions.searchAllAlertBannersFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(AlertBannerActions.searchAllAlertBannersFailure(error)))
    );
  });

  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AlertBannerActions.getAlertBannerById),
      switchMap((action) => {
        return this._CoreAlertBannerHTTPService.getById(action.banner_id).pipe(
          map((data) => {
            return AlertBannerActions.getAlertBannerByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) =>
            of(AlertBannerActions.getAlertBannerByIdFailure({ banner_id: action.banner_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(AlertBannerActions.getAlertBannerByIdFailure(error)))
    );
  });

  createAlertBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AlertBannerActions.createAlertBanner),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreAlertBannerHTTPService.create(action.data).pipe(
          map((data) => {
            return AlertBannerActions.createAlertBannerSuccess({
              data,
              success_message: 'Banner Created Successfully',
            });
          }),
          catchError((error: IHttpRequestError) => of(AlertBannerActions.createAlertBannerFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(AlertBannerActions.createAlertBannerFailure(error)))
    );
  });
  updateAlertBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AlertBannerActions.updateAlertBanner),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreAlertBannerHTTPService.update(action.data).pipe(
          map((data) => {
            return AlertBannerActions.updateAlertBannerSuccess({
              data,
              success_message: 'Banner Updated Successfully',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(AlertBannerActions.updateAlertBannerFailure({ banner_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(AlertBannerActions.updateAlertBannerFailure(error)))
    );
  });

  deleteAlertBanner$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AlertBannerActions.deleteAlertBanner),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._CoreAlertBannerHTTPService.delete(action.banner_id).pipe(
          map((data) => {
            return AlertBannerActions.deleteAlertBannerSuccess({
              data,
              success_message: 'Banner Deleted Successfully',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(AlertBannerActions.deleteAlertBannerFailure({ banner_id: action.banner_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(AlertBannerActions.deleteAlertBannerFailure(error)))
    );
  });
}
