import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ICoreBlog,
  ICoreBlogCommentsController,
  ICoreBlogParamsCommentReplyAdd,
  ICoreBlogParamsCommentReplyIds,
  configureURL,
} from '@launchpoint/core-types';
import { Observable } from 'rxjs';

@Injectable()
export class LaunchpointBlogCommentRepliesHTTPServicePublic implements ICoreBlogCommentsController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'resources-public/comment-replies';

  constructor(private http: HttpClient) {
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      version: 1,
      route: this.BASE_URL,
    });
  }

  add(params: ICoreBlogParamsCommentReplyAdd): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/add', params);
  }
  remove(params: ICoreBlogParamsCommentReplyIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/remove', params);
  }
  like(params: ICoreBlogParamsCommentReplyIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/like', params);
  }
  dislike(params: ICoreBlogParamsCommentReplyIds): Observable<ICoreBlog> {
    return this.http.put<ICoreBlog>(this.API_URL + '/dislike', params);
  }
}
