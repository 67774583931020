import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';

export interface ITopicSNS {
  added: boolean;
  topicArn?: string;
}

export interface ITopicFirebase {
  added: boolean;
  topicId?: string;
  addedToFirebase: boolean;
  successOperation: boolean;
  numberOfFailures: number;
}

export enum ETopicType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export class ITopic {
  _id: string;
  topic: string;
  topic_type: ETopicType;
  created_at?: Date;
  updated_at?: Date;
  description?: string;
  sns: ITopicSNS;
  firebase: ITopicFirebase;
}

export class ITopicParamsCreate implements Partial<ITopic> {
  // _id: never;
  topic: string;
  description?: string;
  topic_type?: ETopicType;
}

export class ITopicParamsUpdate {
  _id: string;
  topic?: string;
  topic_type?: ETopicType;
  description?: string;
  sns?: ITopicSNS;
  firebase?: ITopicFirebase;
}

export class ITopicParamsDelete {
  _id: string;
  topic: string;
}

export class ITopicSearchQuery {
  search?: string;
  topic_type?: ETopicType[];
  created_at?: ILaunchpointSearchFilterDates;
}

export class ITopicSearchPayload {
  query: ITopicSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ITopicSearchResults {
  data: ITopic[];
  pagination: IQueryPageination;
}
