import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LaunchpointCoreClientBaseComponent, NotificationMessageHTTPService } from '@launchpoint/core-client';
import { INotificationMessage, INotificationMessageSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebNotificationMessagesTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  loading = false;

  @Input() messages: INotificationMessage[] = [];
  @Input() query: INotificationMessageSearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 100, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  public messagesModel: { value: string; data: INotificationMessage }[] = [];
  public whitelistMessages$: Observable<{ value: string; data: INotificationMessage }[]>;

  protected tagify: Tagify;

  public settings: TagifySettings = {
    placeholder: 'Search Messages...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false, // <-- if this is set to true, you CANNOT pre-load with value, but be set after the fact
    keepInvalidTags: false,
  };

  @Output() selectedMessage = new EventEmitter<INotificationMessage[]>();

  constructor(
    public _NotificationMessageHTTPService: NotificationMessageHTTPService,
    public tagifyService: TagifyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.whitelistMessages$ = this._NotificationMessageHTTPService
      .search({ query: this.query ?? {}, pagination: this.pagination, querySort: this.querySort })
      .pipe(
        map((f) =>
          f.data.map((d) => ({
            value: d?.title ?? d?._id,
            data: d,
          }))
        )
      );
    if (this.messages?.length > 0) {
      this.messagesModel = this.messages.map((d) => ({
        value: d?.title ?? d?._id,
        data: d,
      }));

      setTimeout(() => {
        if (this.messagesModel?.length) {
          this.tagifyService.get('messages').loadOriginalValues(this.messagesModel);
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    }
  }

  formatter = (result: INotificationMessage): string => result?.title ?? result?._id;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  clear() {
    this.tagifyService.get('messages').removeAllTags();
  }

  search: OperatorFunction<string, readonly INotificationMessage[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._NotificationMessageHTTPService
      .search({ pagination: this.pagination, querySort: this.querySort, query: { ...this.query, search } })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  selectData(data: any) {
    // console.log('selectData', data);
    return this.selectedMessage.emit(data.map((f) => f.data));
  }
}
