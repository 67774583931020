import { get, isArray } from 'lodash';
import { SECURITY_PERMISSIONS_ACCESS_ALL, SECURITY_PERMISSIONS_DEFAULT_ROLE } from '../security.constants';
import { ISecurityConfigACLRole } from '../security.interface';
import { ICoreUserJWTToken, ICoreUserJWTAccount } from '../auth/auth.user.interface';
import { ICoreCompany, ICoreUser } from '../user-company-account.interface';

export class LaunchpointSecurity {
  private blacklist_roles = ['true'];
  securityGetJWTUserRoles(jwt_user: ICoreUserJWTToken, options?: { account_id?: string; user_id?: string; account?: ICoreUserJWTAccount }): string[] {
    // Create a new Set to store unique user roles
    const SECURITY_ROLES = new Set<string>();
    jwt_user?.security_roles?.forEach((role) => SECURITY_ROLES.add(role));

    try {
      // Check if jwt_user.accounts is an array
      if (options?.account) {
        if (options?.account?.security_roles?.length > 0) {
          // Add the security roles from the account to the Set
          options?.account?.security_roles?.forEach((role) => SECURITY_ROLES.add(role));
        } else {
          options.account.security_roles = [];
        }
      } else if (isArray(jwt_user.accounts)) {
        // Check if account_id or user_id is provided in options
        if (options?.account_id || options?.user_id) {
          // Find the matching account based on account_id or user_id
          const account = jwt_user?.accounts?.find(
            (f) => (f?.account_id as string)?.toString() === options?.account_id || (f.user_id as string)?.toString() === options?.user_id
          );

          if (account) {
            // Add the security roles from the account to the Set
            account?.security_roles?.forEach((role) => SECURITY_ROLES.add(role));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }

    // Convert the Set of roles to an array and return it
    return Array.from(SECURITY_ROLES).concat([SECURITY_PERMISSIONS_DEFAULT_ROLE]);
  }

  securityGetUserRoles(jwt_user: Partial<ICoreUser>, options?: { account_id?: string; user_id?: string }): string[] {
    // Create a new Set to store unique user roles

    const SECURITY_ROLES = new Set<string>();
    jwt_user?.security_roles?.forEach((role) => SECURITY_ROLES.add(role));

    try {
      // Check if jwt_user.accounts is an array
      if (isArray(jwt_user?.accounts)) {
        // Check if account_id or user_id is provided in options
        if (options?.account_id || options?.user_id) {
          // Find the matching account based on account_id or user_id
          const account = jwt_user?.accounts?.find((f) => {
            if (f?.account_id) {
              if (typeof f?.account_id === 'string') {
                return f?.account_id === options?.account_id;
              } else {
                return (f?.account_id as ICoreCompany)?._id === options?.account_id;
              }
            }
            if (f?.user_id) {
              if (typeof f?.user_id === 'string') {
                return f?.user_id === options?.user_id;
              } else {
                return (f?.user_id as ICoreUser)._id === options?.user_id;
              }
            }
            return false;
          });

          if (account) {
            // Add the security roles from the account to the Set
            account?.security_roles?.forEach((role) => SECURITY_ROLES.add(role));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
    return Array.from(SECURITY_ROLES).concat([SECURITY_PERMISSIONS_DEFAULT_ROLE]);
  }

  /**
   * Checks if the user with the given roles has permissions for the requested action.
   * It compares the requested action against the permissions defined in the ACL (Access Control List).
   * Returns true if the user has permissions, false otherwise.
   *
   * @param {string[]} roles - The roles of the user.
   * @param {string | string[]} action - The requested action(s) to check permissions for.
   * @param {Object.<string, ISecurityConfigACLRole[]>} acl - The Access Control List (ACL) containing permissions for each role.
   * @returns {boolean} - True if the user has permissions for the requested action, false otherwise.
   */
  securityCheckPermissions(roles: string[], action: string | string[], acl: Record<string, ISecurityConfigACLRole[]>): boolean {
    // Check permissions for the user's roles
    for (const role of [...roles, SECURITY_PERMISSIONS_DEFAULT_ROLE]) {
      const permissions: ISecurityConfigACLRole[] = acl[role];
      if (permissions) {
        // Iterate over the permissions for the current role
        for (const permission of permissions) {
          if (permission.effect === 'can') {
            if (
              permission.permissions.some((p) => p.actions.includes(SECURITY_PERMISSIONS_ACCESS_ALL) || this.securityMatchesAction(p.actions, action))
            ) {
              // Check if any permission allows the requested action
              return true; // User has permission, breaks loop and return true
            }
          } else if (permission.effect === 'cannot') {
            // No access keep in loop to see if any true
          } else {
            console.error('securityCheckPermissions not set up to handel ' + permission.effect + ': Effect.');
            // No access keep in loop to see if any true but should not hit this.
          }
        }
      }
    }

    return false; // No permission found, return false
  }

  /**
   * Checks if the given value matches the provided pattern.
   * The pattern can be a string or an array of strings.
   * Returns true if the value matches any of the patterns, false otherwise.
   *
   * @param {string | string[]} pattern - The pattern(s) to match against.
   * @param {string | string[]} value - The value to compare with the pattern(s).
   * @returns {boolean} - True if the value matches any of the patterns, false otherwise.
   */
  securityMatchesAction(pattern: string | string[], value: string | string[]): boolean {
    // Check if the value matches any of the patterns in the action, which may be a string or an array of strings
    if (Array.isArray(pattern)) {
      for (const p of pattern) {
        if (p === value || (typeof p === 'string' && p === value)) {
          return true;
        }
      }
      return false;
    } else {
      return pattern === value || (typeof pattern === 'string' && pattern === value);
    }
  }

  securityAbilityPermissionCheck(role: ISecurityConfigACLRole, data: any, builder: any) {
    for (let i = 0; i < role.permissions.length; i++) {
      const p = role.permissions[i];
      if (Array.isArray(role.conditions)) {
        role.conditions.forEach((condition) => {
          const con: any = this.securityReplace(condition, data);
          // this.log('QUERY', con);
          if (role.fields) {
            if (role.effect === 'can') {
              builder.can(p.actions, p.subject, role.fields, con);
            } else {
              builder.cannot(p.actions, p.subject, role.fields, con);
            }
          } else {
            //   this.log('p.subject', p.subject);
            if (role.effect === 'can') {
              builder.can(p.actions, p.subject, con);
            } else {
              builder.cannot(p.actions, p.subject, con);
            }
          }
        });
      } else {
        const con: any = this.securityReplace(role.conditions, data);
        //   this.log('QUERY', con);
        if (role.fields) {
          if (role.effect === 'can') {
            builder.can(p.actions, p.subject, role.fields, con);
          } else {
            builder.cannot(p.actions, p.subject, role.fields, con);
          }
        } else {
          if (role.effect === 'can') {
            builder.can(p.actions, p.subject, con);
          } else {
            builder.cannot(p.actions, p.subject, con);
          }
        }
      }
    }
  }

  /**
   * Parallel file libs/core/modules/src/lib/security/casl-abac/launchpoint-casl.factory.ts
   *
   * @param condition
   * @param object
   * @returns
   */
  securityReplace(condition: any, object: any): any {
    if (typeof condition === 'string') {
      const matches = condition.match(/\${(.*?)}/g);
      if (matches) {
        for (const match of matches) {
          const key = match.slice(2, -1);
          const value = get(object, key);
          if (value !== undefined) {
            condition = JSON.parse(condition.replace(match, JSON.stringify(value)));
          } else {
            // console.error(`Value for key ${key} not found in object`);
            // throw new Error(`Value for key ${key} not found in object ${JSON.stringify(object)}`);
          }
        }
      }
    } else if (Array.isArray(condition)) {
      condition = condition.map((elem) => this.securityReplace(elem, object));
    } else if (typeof condition === 'object') {
      condition = Object.fromEntries(Object.entries(condition).map(([key, value]) => [key, this.securityReplace(value, object)]));
    }
    return condition;
  }
}
