import { IsArray, IsBoolean, IsDate, IsOptional, IsString } from 'class-validator';

export class INotificationPreferences {
  _id: string;

  @IsDate()
  created_at: Date;

  @IsDate()
  updated_at: Date;

  @IsString()
  @IsOptional()
  title?: string;

  @IsArray()
  @IsString({ each: true })
  events: string[];

  @IsArray()
  @IsString({ each: true })
  channels: string[];

  @IsString()
  @IsOptional()
  subject?: string;

  @IsString()
  @IsOptional()
  sub_title?: string;

  @IsString()
  @IsOptional()
  message?: string;

  @IsBoolean()
  is_active: boolean;

  @IsBoolean()
  opt_in: boolean;
}
