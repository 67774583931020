import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LaunchpointCoreClientBaseComponent, LaunchpointMediaCategoriesService } from '@launchpoint/core-client';
import { IMediaCategory, IMediaCategorySearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { map, Observable } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebMediaCategoriesTagifyBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  public _LaunchpointMediaCategoriesService = inject(LaunchpointMediaCategoriesService);
  public tagifyService = inject(TagifyService);

  @Input() categories: IMediaCategory[] = [];
  @Input() query: IMediaCategorySearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 100, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  public categoriesModel: { value: string; data: IMediaCategory }[] = [];
  public whitelistCategories$: Observable<{ value: string; data: IMediaCategory }[]>;

  protected tagify: Tagify;

  public settings: TagifySettings = {
    placeholder: 'Search Categories...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 100, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false, // <-- if this is set to true, you CANNOT pre-load with value, but be set after the fact
    keepInvalidTags: false,
  };

  @Output() selected = new EventEmitter<IMediaCategory[]>();

  ngOnInit(): void {
    this.whitelistCategories$ = this._LaunchpointMediaCategoriesService
      .search({ query: this.query ?? {}, pagination: this.pagination, querySort: this.querySort })
      .pipe(
        map((f) =>
          f.data.map((d) => ({
            value: d.title,
            data: d,
          }))
        )
      );
    if (this.categories?.length > 0) {
      this.categoriesModel = this.categories.map((d) => ({
        value: d.title,
        data: d,
      }));

      setTimeout(() => {
        if (this.categoriesModel?.length) {
          this.tagifyService.get('categories')?.loadOriginalValues(this.categoriesModel);
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    }
  }

  selectData(data: any) {
    // console.log('selectData', data);
    return this.selected.emit(data.map((f) => f.data));
  }
}
