<div class="modal-header  justify-content-end">
    <h4 class="modal-title">{{webhook ? 'Update' : 'Create'}} Webhook</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>

<div class="modal-body">

    <div class="d-flex justify-content-between flex-column">
        <!--Form starts here-->
        <form class="form-group" [formGroup]="form">

            <div class="px-1 mb-5">
                <div class="d-flex align-items-center mb-1">
                    <label for="url" class="mb-1 fw-semibold me-5 required">Url</label>
                    <i class="bi bi-info-circle fs-5" ngbTooltip="Endpoint to post request to."></i>
                </div>
                <input type="text" name="url" formControlName="url" class="form-control"
                    placeholder="https://www.someurl.com/endpoint" />
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Url is required',
                    control: form.controls.url
                }"></ng-container>
                <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message: 'Please enter a valid url',
                    control: form.controls.url
                }"></ng-container>
            </div>

            <div class="px-1 mb-5">
                <div class="d-flex align-items-center mb-1">
                    <label for="description" class="mb-1 fw-semibold me-5">Description</label>
                </div>
                <input type="description" name="description" formControlName="description" class="form-control" />
            </div>

            <div class="px-1 mb-5">
                <div class="d-flex align-items-center">
                    <label for="events" class="mb-1 fw-semibold">Events</label>
                    <i class="bi bi-info-circle fs-5 ms-5" ngbTooltip="Events to include for this endpoint"></i>
                </div>
                <tagify [settings]="settings" [whitelist]="whitelistEvents$" formControlName="events" name="events"
                    class="me-5" inputClass="form-control form-control-sm ps-4 bg-white" />
            </div>


            <div class="px-1 mb-5">
                <label class="form-label fw-semibold">Mode</label>
                <div class="d-flex">
                    <button type="button" class="btn btn-sm btn-active-light-primary" [ngClass]="{
                      'btn-light-primary': form.controls.mode.value === 'live'
                    }" (click)="change('live')">Live</button>
                    <button type="button" class="btn btn-sm btn-active-light-primary" [ngClass]="{
                      'btn-light-primary': form.controls.mode.value === 'test'
                    }" (click)="change('test')">Test</button>
                </div>
            </div>

            <div class="px-1 mb-5">
                <label class="form-label fw-semibold me-5">Enabled</label>
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                        formControlName="enabled" />
                </div>
            </div>


            <div class="d-flex align-items-center justify-content-end modal-footer px-0 pb-0">
                <button class="btn btn-secondary mb-2 mt-5 mx-4" (click)="close()">Cancel</button>
                <button type="submit" (click)="submit()" [disabled]="form.invalid || form.pristine"
                    class="btn btn-primary mb-2 mt-5">
                    <span *ngIf="(loading$ | async) === false" class="indicator-label">
                        {{ webhook ? 'Save' : 'Create' }}
                    </span>
                    <ng-container *ngIf="loading$ | async">
                        <span class="indicator-label">
                            Please wait...
                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </ng-container>
                </button>
            </div>
        </form>
        <!--Form ends here-->
    </div>

    <!--Modal Ends-->

    <!--start::FormError-->
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
        <ng-container
            *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
            <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                    <span role="alert">{{ message }}</span>
                </div>
            </div>
        </ng-container>
    </ng-template>
    <!--end::FormError-->