import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { layoutSelectors } from '../../../+state';
import { LayoutService } from '../../../../core/layout.service';
import { LaunchpointLayoutFooters } from '../../../../../interfaces/footer.interface';

@Component({
  selector: 'launchpoint-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AppLaunchpointBaseFooterComponent implements OnInit {
  footerContainerCssClasses = '';
  currentDateStr: string = new Date().getFullYear().toString();
  footerConfig$: Observable<LaunchpointLayoutFooters>;
  constructor(private layout: LayoutService, private _Store: Store) {
    this.footerConfig$ = this._Store.select(layoutSelectors.getFooterConfig);
  }

  ngOnInit(): void {
    this.footerContainerCssClasses = this.layout.getStringCSSClasses('footerContainer');
    // getFooterConfig
  }
}
