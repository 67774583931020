import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { combineLatestWith, exhaustMap, filter, map, switchMap } from 'rxjs/operators';
import { UserSelectors } from '../+state';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../interfaces/auth-storage-service.interface';

@Injectable({ providedIn: 'root' })
export class LaunchpointCoreClientAuthGuard implements CanActivate {
  rerouted = false;
  debug = false;

  private _Store = inject(Store);
  private router = inject(Router);
  public _config: ICoreAuthConfig = inject(APP_AUTH_CONFIG_TOKEN);

  canActivate(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.log('[AuthGuard] Checking authentication state...');

    return this._Store.select(UserSelectors.selectUserInitCheckComplete).pipe(
      filter((initCheckComplete) => !!initCheckComplete),
      switchMap(() =>
        this._Store.select(UserSelectors.selectUser).pipe(
          combineLatestWith(this._Store.select(UserSelectors.selectReturnUrl)) // Use combineLatestWith for RxJS 7+
        )
      ),
      map(([user, returnUrl]: [ICoreUser, string[]]) => {
        const resolvedReturnUrl = returnUrl?.join('/') || state.url; // Convert returnUrl array to string path
        this.log(`[AuthGuard] Resolved Return URL: ${resolvedReturnUrl}`);

        return this.handleUserAuth(user, resolvedReturnUrl);
      })
    );
  }

  private handleUserAuth(user: ICoreUser, returnUrl: string): boolean {
    if (user) {
      const route = this._config.registration?.require_password ? this._config?.redirects?.set_password ?? ['auth', 'set-password'] : ['/'];

      if (this._config.registration.require_password && !user.password) {
        this.log('[AuthGuard] Password required. Redirecting to set password.');
        this.router.navigate(route, { queryParams: { returnUrl } });
        return false;
      } else if (user.reset_password_force) {
        this.log('[AuthGuard] Reset password required. Redirecting to set password.');
        this.router.navigate(route, { queryParams: { returnUrl } });
        return false;
      } else if (returnUrl && returnUrl !== '/' && !this.rerouted) {
        this.log('[AuthGuard] User authenticated. Redirecting to:', returnUrl);
        this.router.navigateByUrl(returnUrl); // Route them to the resolved return URL
        this.rerouted = true;
        return false; // Prevent further navigation since we already routed
      } else {
        this.log('[AuthGuard] User authenticated. Proceeding to route.');
        this.rerouted = false;
        return true; // Allow navigation to continue
      }
    } else {
      this.log('[AuthGuard] No user found. Redirecting to login.');

      const logoutRoute = this._config?.redirects?.logout ?? ['auth', 'login'];
      this.router.navigate(logoutRoute, { queryParams: { returnUrl } });

      this.rerouted = false;
      return false;
    }
  }

  private log(...args: any[]): void {
    if (this.debug) {
      console.log('[AuthGuard]', ...args);
    }
  }
}
