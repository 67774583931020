import { CommonModule } from '@angular/common';
import { Component, Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { LaunchpointSortEvent, SortDirection } from '@launchpoint/core-client';
import { IQuerySort } from '@launchpoint/core-types';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'th[sortme]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.asc]': 'direction === "-1"',
    '[class.desc]': 'direction === "1"',
    '(click)': 'rotate()',
  },
})
export class LaunchpointTableSortHeaderDirective {
  @Input() sortme = 'created_at';
  @Input() direction: SortDirection = null;
  @Output() sort = new EventEmitter<LaunchpointSortEvent>();

  rotate() {
    if (this.direction === null) {
      this.direction = -1;
    } else if (this.direction === -1) {
      this.direction = 1;
    } else {
      this.direction = null;
    }
    this.sort.emit({ column: this.sortme, direction: this.direction });
  }
}

@Component({
  selector: 'launchpoint-table-sort-header',
  templateUrl: 'header.component.html',
  standalone: true,
  imports: [CommonModule, LaunchpointTableSortHeaderDirective],
})
export class LaunchpointTableSortHeaderComponent {
  @ViewChildren(LaunchpointTableSortHeaderDirective) headers: QueryList<LaunchpointTableSortHeaderDirective>;
  @Input() column = '';
  @Input() inputClass = 'min-w-200px';
  @Input() nullFilterIcon = 'bi bi-filter fs-3';
  @Input() upFilterIcon = 'bi bi-sort-up fs-3';
  @Input() downFilterIcon = 'bi bi-sort-down fs-3';
  @Input() title: string;
  @Input() direction: number = null;
  @Input() querySort: IQuerySort = null;
  @Output() sort = new EventEmitter<LaunchpointSortEvent>();

  onSort({ column, direction }: LaunchpointSortEvent) {
    this.direction = direction;
    this.sort.emit({ column, direction });
  }
}
