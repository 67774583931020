@if (href) {
  <a [href]="href" target="_blank">
    <div class="font-courierNew bg-primary flex w-full items-center justify-center gap-2 py-4 text-sm font-semibold md:text-xl">
      {{ text }} <i class="fa-regular fa-arrow-right"></i>
    </div>
  </a>
} @else if (route) {
  <div
    [routerLink]="[route]"
    class="font-courierNew bg-primary flex w-full cursor-pointer items-center justify-center gap-2 py-4 text-sm font-semibold md:text-xl"
  >
    {{ text }} <i class="fa-regular fa-arrow-right"></i>
  </div>
} @else {
  <div class="font-courierNew bg-primary flex w-full items-center justify-center gap-2 py-4 text-sm font-semibold md:text-xl">
    {{ text }} <i class="fa-regular fa-arrow-right"></i>
  </div>
}
