import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ICoreUser } from '../../user-company-account.interface';
import { INotificationMessage } from '../messages/notifications-message.interface';
import { INotification } from '../notifications/notifications.interface';

export class IUserNotificationBase {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
  notification: string | INotification;
  message: string | INotificationMessage;
  /**
   * If the user has opened/read the message
   */
  is_read?: boolean;
  delivered?: boolean;
  user: string | ICoreUser;
  account?: string; // | ICoreCompany
  /**
   * String that represents the event/s that this notification is
   */
  event: string;
  /**
   * If the message is a simple string.
   */
  title: string;
  /**
   * If the message is a simple string.
   */
  body: string;
  error_message?: string;
}

export class IUserNotification extends IUserNotificationBase {
  notification: INotification;
  message: INotificationMessage;
  user: ICoreUser;
}

export class IUserNotificationParamsCreate {
  user_id: string;
  account_id?: string;
  notification_id: string;
  message_id: string;
  event?: string;
  subject?: string;
  body?: string;
  error_message?: string;
}

export class IUserNotificationParamsUpdate {
  _id: string;
  notification_id?: string;
  message_id?: string;
  event?: string;
  subject?: string;
  body?: string;
  error_message?: string;
  is_read?: boolean;
  delivered?: boolean;
}

export class IUserNotificationSearchQuery {
  search?: string;
  is_read?: boolean;
  delivered?: boolean;
  notifications?: string[];
  messages?: string[];
  users?: string[];
  events?: string[];
  created_at?: ILaunchpointSearchFilterDates;
}

export class IUserNotificationSearchPayload {
  query: IUserNotificationSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class IUserNotificationSearchResults {
  data: IUserNotification[];
  pagination: IQueryPageination;
}
