import { IsDate, IsEnum, IsOptional, IsString } from 'class-validator';
import { EUserProfileGender, EUserProfileMaritalStatus, ICoreUserProfile } from '../../../user-company-account.interface';

export class ICoreUserProfileParamsUpdate
  implements Pick<Partial<ICoreUserProfile>, 'first_name' | 'last_name' | 'profile_photo_url' | 'gender' | 'marital_status' | 'birthday'>
{
  @IsString({ message: 'User ID must be a string' })
  user_id: string;

  @IsString({ message: 'First name must be a string' })
  @IsOptional()
  first_name?: string;

  @IsString({ message: 'Last name must be a string' })
  @IsOptional()
  last_name?: string;

  @IsString({ message: 'Profile photo URL must be a string' })
  @IsOptional()
  profile_photo_url?: string;

  @IsEnum(EUserProfileGender, { message: 'Gender must be a valid value from the EUserProfileGender enum' })
  @IsOptional()
  gender?: EUserProfileGender;

  @IsEnum(EUserProfileMaritalStatus, { message: 'Marital status must be a valid value from the EUserProfileMaritalStatus enum' })
  @IsOptional()
  marital_status?: EUserProfileMaritalStatus;

  @IsDate({ message: 'Birthday must be a date' })
  @IsOptional()
  birthday?: Date;

  constructor(data: ICoreUserProfileParamsUpdate) {
    this.user_id = data.user_id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.profile_photo_url = data.profile_photo_url;
    this.gender = data.gender;
    this.marital_status = data.marital_status;
    this.birthday = data.birthday ? new Date(data.birthday) : undefined;
  }
}

export interface ICoreUserProfileParamsUpdateProfilePhoto extends Pick<Partial<ICoreUserProfile>, 'profile_photo_url'> {
  user_id: string;
}
