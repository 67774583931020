import { Directive, inject, OnInit } from '@angular/core';
import { IMedia } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { map, Observable, takeUntil } from 'rxjs';
import { createMediaAudio, createMediaSubtitle, updateMedia } from '../+state/actions/media.actions';
import { LaunchpointCoreClientMediaStateBaseComponent } from '../+state/media-state-base.component';
import { BASE_TAGIFY_SETTINGS } from '../../../../components/tagify/tagify-component.interface';

@Directive()
export abstract class LaunchpointCoreClientMediaDetailBaseComponent extends LaunchpointCoreClientMediaStateBaseComponent implements OnInit {
  tagifyService = inject(TagifyService);

  media: IMedia;

  settings: TagifySettings = { ...BASE_TAGIFY_SETTINGS, enforceWhitelist: false };

  whitelistTags$: Observable<string[]>;

  ngOnInit() {
    this.buildForm();
    this.media$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (media) => {
        this.media = media;
        this.whitelistTags$ = this._MediaTagsService.distinct().pipe(map((f) => f));

        setTimeout(() => {
          const tags = this.media?.tags ?? [];
          if (tags.length) {
            (this.tagifyService.get('tags') as Tagify)?.update({ withoutChangeEvent: true });
            this.tagifyService.get('tags')?.loadOriginalValues(tags);
          }
        }, 250);

        this.loadForm();
      },
    });

    this.success();
    this.errors();
  }

  buildForm() {
    //
  }
  loadForm() {
    //
  }

  setPoster(media_id: string, poster: string | string[]) {
    if (!poster) {
      return;
    }

    if (Array.isArray(poster)) {
      poster = poster[0];
    }

    this._Store.dispatch(
      updateMedia({
        data: {
          _id: media_id,
          poster,
        },
      })
    );
  }

  addSubtitles(media_id: string, subtitles: string | string[]) {
    if (!subtitles) {
      return;
    }
    if (!Array.isArray(subtitles)) {
      subtitles = [subtitles];
    }
    this._Store.dispatch(
      createMediaSubtitle({
        data: {
          _id: media_id,
          subtitles,
        },
      })
    );
  }

  addAudiotracks(media_id: string, audiotracks: string | string[]) {
    if (!audiotracks) {
      return;
    }
    if (!Array.isArray(audiotracks)) {
      audiotracks = [audiotracks];
    }
    this._Store.dispatch(
      createMediaAudio({
        data: {
          _id: media_id,
          audiotracks,
        },
      })
    );
  }
}
