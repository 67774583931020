import { CommonModule } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointFormControlCheckBoxesBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-check-boxes',
  templateUrl: 'form-control-check-boxes.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlCheckBoxesComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class FormControlCheckBoxesComponent extends LaunchpointFormControlCheckBoxesBaseComponent {
  /**
   * The selected checkboxes.
   */
  @Input() selected: string[] = [];
  /**
   * The list of checkbox items.
   *
   * As an input it also allows the values to be written over by to parent component
   */
  @Input() allOptions: string[];

  /**
   * This is not required for the form control BUT it does give the parent access to more data from the selected object if needed.
   * Pattern is to add `Changed` to the end of the value
   */
  @Output() selectedChanged = new EventEmitter<string[]>();

  constructor() {
    super();
  }

  checkIfIncluded(stage: string): boolean {
    return this.selected.includes(stage);
  }

  writeValue(value: string) {
    // console.log('selector:::::writeValue', value);
    if (value) {
      this.selected.push(value);
    }
  }

  adjustValue(isChecked: boolean, value: string) {
    if (isChecked) {
      this.selected.push(value);
    } else {
      this.selected.splice(this.selected.indexOf(value), 1);
    }
    this.onChanged(this.selected);
    this.onTouched();
    this.selectedChanged.emit(this.selected);
  }
}
