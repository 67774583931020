import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointBlogHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebBlogCategoryTagifyBaseWebComponent } from './blog-category-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [LaunchpointBlogHTTPService],
  selector: 'launchpoint-blog-category-tagify',
  templateUrl: 'blog-category-tagify.component.html',
})
export class LaunchpointCoreWebBlogCategoryTagifyWebComponent extends LaunchpointCoreWebBlogCategoryTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
