import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-tables-widget11',
  templateUrl: './tables-widget11.component.html',
})
export class TablesWidget11Component {
  // constructor() {}
  // ngOnInit(): void {}
}
