import { IsInt, IsNumber, IsOptional } from 'class-validator';

export interface ITagifyTag<T = any, U = string> {
  value: U;
  data?: T;
}

/**
 * @deprecated
 * Use {IQuerySort} instead
 */
export interface IQuerySortMulti {
  [key: string]: 'asc' | 'desc' | -1 | 1;
}

/**
 * Sorting directions for 'prop1' properties.
 *
 * @type {IQuerySort<'prop1' | 'prop2'>}
 */
export type IQuerySort<T extends string = string> = {
  [K in T]?: -1 | 1;
};

/**
 * Launchpoints's Standard Pageination class/interface.
 */
export class IQueryPageination {
  @IsInt({ message: 'Count must be a integer' })
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  count?: number;

  @IsInt({ message: 'Limit must be an integer' })
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  limit: number;

  @IsInt({ message: 'Page index must be an integer' })
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  @IsOptional()
  pageIndex?: number;

  @IsInt({ message: 'Skip must be an integer' })
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  skip: number;

  @IsInt({ message: 'Previous page index must be an integer' })
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  @IsOptional()
  previousPageIndex?: number;

  constructor(data: IQueryPageination) {
    this.count = data.count;
    this.limit = data.limit;
    this.pageIndex = data.pageIndex;
    this.skip = data.skip;
    this.previousPageIndex = data.previousPageIndex;
  }
}
export class IQueryUpdateBulkResult {
  insertedCount: number;
  /** Number of documents matched for update. */
  matchedCount: number;
  /** Number of documents modified. */
  modifiedCount: number;
  /** Number of documents deleted. */
  deletedCount: number;
  /** Number of documents upserted. */
  upsertedCount: number;
  /** Upserted document generated Id's, hash key is the index of the originating operation */
  upsertedIds: {
    [key: number]: any;
  };
  /** Inserted document generated Id's, hash key is the index of the originating operation */
  insertedIds: {
    [key: number]: any;
  };
}

export class IQueryArchiveBulkResult {
  /** Indicates whether this write result was acknowledged. If not, then all other members of this result will be undefined */
  acknowledged: boolean;
  /** The number of documents that matched the filter */
  matchedCount: number;
  /** The number of documents that were modified */
  modifiedCount: number;
  /** The number of documents that were upserted */
  upsertedCount: number;
  /** The identifier of the inserted document if an upsert took place */
  upsertedId: string | null;
  /** Id's from the original request */
  _ids?: string[];
}
export class IQueryDeleteBulkResult {
  /** The number of documents that were deleted */
  deletedCount: number;
  /** Indicates whether this write result was acknowledged. If not, then all other members of this result will be undefined. */
  acknowledged: boolean;
  /** Id's from the original request */
  _ids?: string[];
}

export class IQuerySortt {
  [key: string]: -1 | 1;

  constructor(data: IQuerySortt) {
    for (const key in data) {
      this[key] = data[key];
    }
  }
}

// export interface IQuerySort {
//   active: string;
//   direction: string;
// }
// export interface IQuerySortMultiAggragate {
//   [key: string]: -1 | 1;
// }
// export type IQuerySortMulti<T extends { [key: string]: string }[]> = [...T];

// export interface IQueryEventEmitter {
//   pagination: IQueryPageination;
//   querySort: IQuerySort;
// }
type FilterCondition = {
  filterType: 'text' | 'number' | 'date' | 'set';
  type: string;
  filter?: string;
  filterTo?: string;
  dateFrom?: string;
  dateTo?: string;
  condition1?: FilterCondition;
  condition2?: FilterCondition;
  operator?: 'AND' | 'OR';
  values?: any[];
};

type FilterModel = {
  [key: string]: FilterCondition;
};

export class LaunchpointAGGridToMongoMapper {
  private handleTextFilter(filter: FilterCondition, key: string): { [key: string]: any } {
    const query: { [key: string]: any } = {};
    switch (filter.type) {
      case 'contains':
        query[key] = { $regex: filter.filter, $options: 'i' };
        break;
      case 'notContains':
        query[key] = { $not: { $regex: filter.filter, $options: 'i' } };
        break;
      case 'equals':
        query[key] = filter.filter;
        break;
      case 'notEqual':
        query[key] = { $ne: filter.filter };
        break;
      case 'startsWith':
        query[key] = { $regex: '^' + filter.filter, $options: 'i' };
        break;
      case 'endsWith':
        query[key] = { $regex: filter.filter + '$', $options: 'i' };
        break;
    }
    return query;
  }

  private handleNumberFilter(filter: FilterCondition, key: string): { [key: string]: any } {
    const query: { [key: string]: any } = {};
    switch (filter.type) {
      case 'contains':
        query[key] = { $regex: filter.filter, $options: 'i' };
        break;
      case 'notContains':
        query[key] = { $not: { $regex: filter.filter, $options: 'i' } };
        break;
      case 'equals':
        query[key] = filter.filter;
        break;
      case 'notEqual':
        query[key] = { $ne: filter.filter };
        break;
      case 'startsWith':
        query[key] = { $regex: '^' + filter.filter, $options: 'i' };
        break;
      case 'endsWith':
        query[key] = { $regex: filter.filter + '$', $options: 'i' };
        break;
    }
    return query;
  }

  private handleDateFilter(filter: FilterCondition, key: string, timezone = 'UTC'): { [key: string]: any } {
    const query: { [key: string]: any } = {};

    // Convert string to UTC Date
    const toUTCDate = (dateString: string | undefined): Date => {
      const localDate = dateString ? new Date(dateString) : new Date();
      if (timezone === 'UTC') {
        return new Date(
          Date.UTC(
            localDate.getFullYear(),
            localDate.getMonth(),
            localDate.getDate(),
            localDate.getHours(),
            localDate.getMinutes(),
            localDate.getSeconds()
          )
        );
      } else {
        // If you're dealing with various timezones other than UTC, you'll need
        // more sophisticated logic or a library like 'luxon' to handle this.
        throw new Error('Non-UTC timezones are not yet supported.');
      }
    };

    switch (filter.type) {
      case 'equals':
        query[key] = toUTCDate(filter.dateFrom);
        break;
      case 'notEqual':
        query[key] = { $ne: toUTCDate(filter.dateFrom) };
        break;
      case 'lessThan':
        query[key] = { $lt: toUTCDate(filter.dateFrom) };
        break;
      case 'lessThanOrEqual':
        query[key] = { $lte: toUTCDate(filter.dateFrom) };
        break;
      case 'greaterThan':
        query[key] = { $gt: toUTCDate(filter.dateFrom) };
        break;
      case 'greaterThanOrEqual':
        query[key] = { $gte: toUTCDate(filter.dateFrom) };
        break;
      case 'inRange':
        query[key] = { $gte: toUTCDate(filter.dateFrom), $lte: toUTCDate(filter.dateTo) };
        break;
    }
    return query;
  }

  public agGridFilterToMongo(filterModel: FilterModel): { [key: string]: any } {
    const query: { [key: string]: any } = {};

    for (const key in filterModel) {
      const filter = filterModel[key];

      if (filter.condition1 && filter.condition2 && filter.operator) {
        query['$and'] = [this.agGridFilterToMongo({ [key]: filter.condition1 }), this.agGridFilterToMongo({ [key]: filter.condition2 })];
        if (filter.operator === 'OR') {
          query['$or'] = [this.agGridFilterToMongo({ [key]: filter.condition1 }), this.agGridFilterToMongo({ [key]: filter.condition2 })];
        }
        continue;
      }

      switch (filter.filterType) {
        case 'text':
          Object.assign(query, this.handleTextFilter(filter, key));
          break;
        case 'number':
          Object.assign(query, this.handleNumberFilter(filter, key));
          break;
        case 'date':
          Object.assign(query, this.handleDateFilter(filter, key));
          break;
        // handle other filter types accordingly...
      }
    }

    return query;
  }
}
