import { startCase } from "lodash";

const getValue = (fields: any[], obj: any) => {
    return fields.reduce((acc: { [x: string]: any; }, curr: string | number) => (acc && acc[curr] ? acc[curr] : ''), obj);
};

/**
 * valueAccessor to get dot.notation values from an object
 */
export function getFieldValue(field: string, data: any) {
    const fields = field.split('.');
    return getValue(fields, data);
}

/**
 * Will convert a string to start case
 */
export function toStartCase(field: string, data: any): string {

  let value = field.includes('.') ? getFieldValue(field, data) : data[field] || '';

  value = Array.isArray(value)
    ? value.map(v => startCase(v.toLowerCase())).join(', ')
    : startCase(value.toLowerCase());

  return value;
}

/**
 * Formats a boolean value to Yes or No
 */
export function boolean(field: string, data: any) {
    if (data[field]) {
      return 'Yes';
    } else {
      return 'No';
    }
}