<ng-container *ngFor="let item of menuConfig.items">
  <!-- if section use section template -->
  <ng-container *ngIf="item.section" [ngTemplateOutlet]="menuItemSectionTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>

  <!-- if item is a separator, use separator template to show line -->
  <ng-container *ngIf="item?.separator" [ngTemplateOutlet]="menuItemSeparatorTemplate"></ng-container>

  <!-- if item has external url assume no submenu, use external link template -->
  <ng-container *ngIf="item.external_url" [ngTemplateOutlet]="menu1LevelTemplateIconLinkExternal"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>

  <!-- if title and no external use root template -->
  <ng-container *ngIf="item.title && !item.external_url" [ngTemplateOutlet]="rootMenuTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-container>

<ng-template #rootMenuTemplate let-item="item">
  <!-- there is security, no external link check security then use menu1LevelTemplate -->
  <ng-container *ngIf="item?.security && item.title && !item.external_url">
    <ng-container *lpSecurityAccess="[item.security.action, item.security.resource]">
      <ng-container *ngIf="item.title && !item.external_url" [ngTemplateOutlet]="menu1LevelTemplate"
        [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-container>
  </ng-container>
  <!-- no security for menu item, and internal link use menu1LevelTemplate -->
  <ng-container *ngIf="!item.security && item.title && !item.external_url" [ngTemplateOutlet]="menu1LevelTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<!-- begin:: external link -->
<ng-template #menu1LevelTemplateIconLinkExternal let-item="item">
  <ng-container *ngIf="item.external_url">
    <div class="menu-item">
      <a target="_blank" class="menu-link" [href]="item.external_url">
        <span class="menu-icon">
          <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
          <i *ngIf="item?.icon" [class]="item.icon"></i>
        </span>
        <span class="menu-title">
          {{ item.title }}
        </span>
      </a>
    </div>
  </ng-container>
</ng-template>

<!-- begin::Section -->
<ng-template #menuItemSectionTemplate let-item="item">
  <div class="menu-item">
    <div class="menu-content">
      <div class="menu-section fw-semibold fs-7">{{ item?.section | uppercase }}</div>
    </div>
  </div>
</ng-template>

<!-- begin::Separator -->
<ng-template #menuItemSeparatorTemplate let-item="item">
  <div class="menu-item">
    <div class="menu-content">
      <div class="separator mx-1 my-4"></div>
    </div>
  </div>
</ng-template>

<ng-template #menuItemSeparatorTemplateSub let-item="item">
  <div class="menu-item">
    <div class="menu-content">
      <div class="separator mx-4 my-1"></div>
    </div>
  </div>
</ng-template>

<!-- begin::menu level 1 'parent' -->
<ng-template #menu1LevelTemplate let-item="item">

  @if (item?.separator) {
  <ng-container [ngTemplateOutlet]="menuItemSeparatorTemplate"></ng-container>
  } @else if(item?.section) {
  <ng-container [ngTemplateOutlet]="menuItemSectionTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
  } @else {
  <!-- menu item doesn't have submenu, so show data -->
  <div *ngIf="!item.submenu" class="menu-item">
    <a class="menu-link without-sub" routerLinkActive="active" [routerLink]="item.page">
      <span class="menu-icon">
        <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
        <i *ngIf="item?.icon" [class]="item.icon"></i>
      </span>
      <span class="menu-title">
        {{ item.title }}
      </span>
    </a>
  </div>

  <!-- menu item has submenu, do some looping and logic -->
  <div *ngIf="item?.submenu" class="menu-item menu-accordion" data-kt-menu-trigger="click" routerLinkActive="here show">
    <span class="menu-link">
      <span class="menu-icon">
        <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
        <i *ngIf="item?.icon" [class]="item.icon"></i>
      </span>
      <span class="menu-title" [attr.data-link]="item.page">
        {{ item.title }}
      </span>
      <span class="menu-arrow"></span>
    </span>
    <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">

      <ng-container *ngFor="let sub of item.submenu">
        @if (sub?.separator) {
        <ng-container [ngTemplateOutlet]="menuItemSeparatorTemplateSub"></ng-container>
        } @else if(sub?.section) {
        <ng-container [ngTemplateOutlet]="menuItemSectionTemplate"
          [ngTemplateOutletContext]="{ item: sub }"></ng-container>
        } @else {
        <div class="menu-item" *ngIf="!sub?.submenu">
          <ng-container *ngIf="sub.external_url; else showInternalUrl">
            <a target="_blank" class="menu-link text-hover-primary" [href]="sub.external_url">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">
                {{ sub.title }}
                <i class="ms-4" [class]="sub?.icon ?? 'fas fa-link'"></i>
              </span>
            </a>
          </ng-container>
          <ng-template #showInternalUrl>
            <a class="menu-link without-sub" routerLinkActive="active" [routerLink]="sub?.page">
              <span class="menu-bullet">
                <span class="bullet bullet-dot"></span>
              </span>
              <span class="menu-title">
                {{ sub.title }}
              </span>
            </a>
          </ng-template>
        </div>
        <div *ngIf="sub?.submenu" class="menu-item menu-accordion" routerLinkActive="here show"
          data-kt-menu-trigger="click">

          <span class="menu-link">
            <span class="menu-icon">
              <span *ngIf="sub?.svg" [inlineSVG]="sub.svg" class="svg-icon svg-icon-2"></span>
              <i *ngIf="sub?.icon" [class]="sub.icon"></i>
            </span>
            <span class="menu-title" [attr.data-link]="sub.page">
              {{ sub?.title }}
            </span>
            <span class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
            <ng-container *ngFor="let superSub of sub.submenu" [ngTemplateOutlet]="menu2LevelTemplateSecurity"
              [ngTemplateOutletContext]="{ item: superSub }"></ng-container>
          </div>

        </div>
        }
      </ng-container>
    </div>
  </div>
  }
</ng-template>

<!-- begin::menu level 2 'child' -->
<ng-template #menu2LevelTemplateSecurity let-item="item">

  <!-- there is security, no external link check security then use menu2LevelTemplate -->
  <ng-container *ngIf="item?.security && item.title && !item.external_url">
    <ng-container *lpSecurityAccess="[item.security.action, item.security.resource]">
      <ng-container *ngIf="item.title && !item.external_url" [ngTemplateOutlet]="menu2LevelTemplate"
        [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-container>
  </ng-container>
  <!-- no security for menu item, and internal link use menu2LevelTemplate -->
  <ng-container *ngIf="!item.security && item.title && !item.external_url" [ngTemplateOutlet]="menu2LevelTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>


  <ng-template #menu2LevelTemplate let-item="item">

    @if (item?.separator) {
    <ng-container [ngTemplateOutlet]="menuItemSeparatorTemplateSub"></ng-container>
    }@else if(item?.section) {
    <ng-container [ngTemplateOutlet]="menuItemSectionTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
    } @else {
    <div class="menu-item" *ngIf="!item?.submenu && !item?.external_url">
      <a class="menu-link without-sub" routerLinkActive="active" [routerLink]="item.page">
        <span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">
          {{ item.title }}
        </span>
      </a>
    </div>

    <div class="menu-item" *ngIf="!item?.submenu && item?.external_url">
      <a class="menu-link without-sub" [href]="item?.external_url" target="_blank">
        <span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">
          {{ item.title }}
        </span>
        <span>
          <i class="ms-4" [class]="item?.class ?? 'fas fa-link'"></i>
        </span>
      </a>
    </div>

    <div *ngIf="item?.submenu" class="menu-item menu-accordion" routerLinkActive="here show"
      data-kt-menu-trigger="click">
      <span class="menu-link">
        <span class="menu-icon">
          <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
          <i *ngIf="item?.icon" [class]="item.icon"></i>
        </span>
        <span class="menu-title" [attr.data-link]="item.page" routerLinkActive="active">
          {{ item?.title }}
        </span>
        <span class="menu-arrow"></span>
      </span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <ng-container *ngFor="let sub of item.submenu" [ngTemplateOutlet]="menu2LevelTemplate"
          [ngTemplateOutletContext]="{ item: sub }"></ng-container>
      </div>
    </div>
    }
  </ng-template>
</ng-template>

<!-- begin::menu level 3 'grandchild' -->
<ng-template #menu3LevelTemplate let-item="item">

  <ng-container *ngIf="!item.submenu">
    @if (item?.separator) {
    <ng-container [ngTemplateOutlet]="menuItemSeparatorTemplateSub"></ng-container>
    } @else {
    <div class="menu-item">
      <a class="menu-link without-sub" routerLinkActive="active" [routerLink]="item.page">
        <span class="menu-bullet">
          <span class="bullet bullet-dot"></span>
        </span>
        <span class="menu-title">
          {{ item.title }}
        </span>
      </a>
    </div>
    }
  </ng-container>
  <ng-container *ngIf="item.submenu">
    <!--
      TODO: Not supporting a 3rd submenu
      <div class="menu-item menu-accordion" routerLinkActive="here show"  data-kt-menu-trigger="click">
      <span class="menu-link"
        ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span
        ><span class="menu-title" data-link="/crafted/pages/profile">Profile</span><span class="menu-arrow"></span
      ></span>
      <div class="menu-sub menu-sub-accordion" routerLinkActive="menu-active-bg">
        <div class="menu-item">
          <a class="menu-link without-sub" routerLinkActive="active" routerLink="/crafted/pages/profile/overview"
            ><span class="menu-bullet"><span class="bullet bullet-dot"></span></span><span class="menu-title">Overview</span></a
          >
        </div>
      </div>
    </div> -->
  </ng-container>
</ng-template>



<!-- Changelog  -->
<div class="menu-item" *ngIf="menuConfig.footers">
  <a target="_blank" class="menu-link" [href]="appPreviewChangelogUrl">
    <span class="menu-icon">
      <span class="svg-icon svg-icon-2" [inlineSVG]="'./assets/media/icons/duotune/general/gen005.svg'"></span>
    </span>
    <span class="menu-title">Changelog {{ appAngularVersion }}</span>
  </a>
</div>