import { CommonModule } from '@angular/common';
import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreUserV1Service, LaunchpointCoreClientAuthHTTPService, LaunchpointCoreClientUserSearchAheadBaseComponent } from '@launchpoint/core-client';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbTypeaheadModule];

@Component({
  standalone: true,
  selector: 'launchpoint-user-search-ahead',
  templateUrl: 'user-search-ahead.component.html',
  imports: MODULES,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreWebUserSearchAheadComponent),
      multi: true,
    },
    CoreUserV1Service,
    LaunchpointCoreClientAuthHTTPService,
  ],
})
export class LaunchpointCoreWebUserSearchAheadComponent extends LaunchpointCoreClientUserSearchAheadBaseComponent {}
