<div class="card mb-xl-10 mb-5">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Related Posts</h3>
    </div>
  </div>

  <div class="card-body border-top p-9">
    <label class="col-lg-4 col-form-label fw-bold fs-6">Search Other Blogs</label>
    <div class="row mb-6">
      <launchpoint-blogs-tagify [query]="query" (selectedBlog)="setBlogs($event)" />
    </div>
  </div>

  <div class="card-footer d-flex justify-content-end px-9 py-6" let some="false">
    <button class="btn btn-secondary me-3" (click)="reset()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="(loading$ | async) || (blogLoading$ | async)" (click)="save()">
      <ng-container *ngIf="((loading$ | async) || (blogLoading$ | async)) === false">Save</ng-container>

      <ng-container *ngIf="(loading$ | async) || (blogLoading$ | async)">
        <span class="indicator-label" [style.display]="'block'" loading="true">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>
    </button>
  </div>
</div>
