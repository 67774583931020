import { Observable } from 'rxjs';
import {
  ICircleEventParamsList,
  ICircleEventListResponse,
  ICircleEventAttendeeParamsList,
  ICircleEventAttendeeListResponse,
  ICircleEventRecurringEventParamsList,
  ICircleEventRecurringEventListResponse,
  ICircleEventAttendeeParamsCreate,
  ICircleEventAttendee,
  ICircleEventRecurringEventParamsUpdateRSVP,
} from './circle-events.interface';

/**
 * @Controller('circle-events')
 */
export interface ICircleEventsController {
  /**
   * Post('list')
   * @param params
   */
  listEvents(params?: ICircleEventParamsList): Promise<ICircleEventListResponse | null> | Observable<ICircleEventListResponse | null>;

  /**
   * Post('list-attendees')
   * @param params
   */
  listEventAttendees(
    params: ICircleEventAttendeeParamsList
  ): Promise<ICircleEventAttendeeListResponse | null> | Observable<ICircleEventAttendeeListResponse | null>;

  /**
   * Post('list-recurring')
   * @param params
   */
  listRecurringEvents(
    params: ICircleEventRecurringEventParamsList
  ): Promise<ICircleEventRecurringEventListResponse | null> | Observable<ICircleEventRecurringEventListResponse | null>;

  /**
   * Post('attendee')
   * @param params
   */
  createEventAttendee(params: ICircleEventAttendeeParamsCreate): Promise<ICircleEventAttendee | null> | Observable<ICircleEventAttendee | null>;

  /**
   * Delete('attendee')
   */
  deleteEventAttendee(event_id: string): Promise<ICircleEventAttendee | null> | Observable<ICircleEventAttendee | null>;

  /**
   * Put('recurring-rsvp')
   * @param params
   */
  updateRecurringEventRSVP(params: ICircleEventRecurringEventParamsUpdateRSVP): Promise<string | null> | Observable<string | null>;
}
