<div class="bg-[#E1E2DF] py-10 md:py-20">
  <div class="mx-auto flex max-w-[1700px] flex-col gap-2 px-4 lg:flex-row">
    <div class="flex flex-col items-center px-10 lg:w-[30%] lg:items-start">
      <p
        class="font-oswald border-b-[1.5px] border-black pb-4 text-[30px] lg:text-[45px] xl:text-[60px] 2xl:text-[70px]">
        LET'S GO...</p>
      <p class="my-4 text-[12px] font-light md:my-10 lg:text-[16px] xl:text-[18px] 2xl:text-[20px]">
        Looking to scale your business? Seeking expert guidance or mentorship? Want to uncover hidden inefficiencies or
        explore custom solutions?
      </p>
      <p class="mb-6 text-[12px] font-light lg:text-[16px] xl:text-[18px] 2xl:text-[20px]">
        Whatever your goals, I'm here to help you unlock your business's full potential.
      </p>
    </div>
    <div class="lg:w-[70%] flex justify-center items-center">
      <div class="calendly-inline-widget object-contain object-right md:block"
        data-url="https://calendly.com/lp-account-executives/discovery-call?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=010424"
        data-resize="true" style="min-width: 320px; min-height: 700px; height: 700px; width: 100%; max-width: 600px;">
      </div>
    </div>
  </div>
</div>