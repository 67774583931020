import { IQueryPageination } from '../query.interfaces';

export interface IQueryUserNotification {
  notifications: IUserNotificationV1[];
  pagination: IQueryPageination;
}

export interface IUserNotificationV1 {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
  user_id: string;
  first_name: string;
  last_name: string;
  payload: {
    payload: any;
    template: {
      internal_app_feature_name: string;
    };
  };
  mandril_response: {
    _id?: string;
    response_id: string;
    email: string;
    status: string;
    reject_reason: string;
  };
  email: string;
  department?: string;
  subject?: string;
  message?: string;
  type: EUserNotificationType;
  show?: boolean;
  read?: boolean;
}

export enum EUserNotificationType {
  EMAIL = 'Email',
  TEXT = 'Text',
  APP = 'App',
  PHONE = 'Phone',
}
