import {
  IHttpException,
  IQueryPageination,
  IQuerySort,
  ICoreActivityHistoryWebhookEventSearchQuery,
  ICoreActivityHistorySearchQuery,
} from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserActivityHistoryActions from '../actions/activity-history.actions';
import { ActivityHistoryEntityState } from '../interfaces/activity-history.interface';

export const ActivityHistoryFeatureKey = 'ActivityHistoryReducer';

export interface StateActivityHistoryReducer extends EntityState<ActivityHistoryEntityState> {
  selected_history_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreActivityHistorySearchQuery;
}

export const ActivityHistoryEntityAdaptor: EntityAdapter<ActivityHistoryEntityState> = createEntityAdapter<ActivityHistoryEntityState>({
  selectId: (data) => data.history_id,
});

export const initialActivityHistoryState: StateActivityHistoryReducer = ActivityHistoryEntityAdaptor.getInitialState({
  // set initial required properties
  selected_history_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
  entities: {},
  ids: [],
});

export const LaunchpointActivityHistoryReducer = createReducer(
  initialActivityHistoryState,
  on(UserActivityHistoryActions.searchAllActivityHistory, (state) => {
    return ActivityHistoryEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserActivityHistoryActions.searchAllActivityHistorySuccess, (state, { data }) => {
    const userEntities = data.data.map((data) => {
      const entity: ActivityHistoryEntityState = {
        history_id: data._id,
        activity_history: data,
        loaded: true,
        loading: false,
        error: null,
        // profile_config: USERS_PROFILE_CONFIG,
      };
      return entity;
    });
    return ActivityHistoryEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(UserActivityHistoryActions.searchAllActivityHistoryFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(UserActivityHistoryActions.updateAllActivityHistoryQuery, (state, { query }) => {
    return ActivityHistoryEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(UserActivityHistoryActions.updateAllActivityHistoryPagination, (state, { pagination }) => {
    return ActivityHistoryEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(UserActivityHistoryActions.updateAllActivityHistoryPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(UserActivityHistoryActions.updateAllActivityHistorySorting, (state, { querySort }) => {
    return ActivityHistoryEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(UserActivityHistoryActions.getActivityHistoryById, (state, { history_id }) => ({
    ...state,
    selected_history_id: history_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(UserActivityHistoryActions.getActivityHistoryByIdSuccess, (state, { data }) => {
    const entity: ActivityHistoryEntityState = {
      history_id: data._id,
      activity_history: data,
      loaded: true,
      loading: false,
      error: null,
    };
    return ActivityHistoryEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_history_id: data._id,
      loaded: true,
      loading: false,
    });
  }),
  on(UserActivityHistoryActions.getActivityHistoryByIdFailure, (state, { history_id, error }) => {
    return ActivityHistoryEntityAdaptor.updateOne(
      {
        id: history_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(UserActivityHistoryActions.deleteActivityHistory, (state, { history_id }) => {
    return ActivityHistoryEntityAdaptor.updateOne(
      {
        id: history_id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserActivityHistoryActions.deleteActivityHistorySuccess, (state, { data, success_message }) => {
    return ActivityHistoryEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(UserActivityHistoryActions.deleteActivityHistoryFailure, (state, { history_id, error }) => {
    return ActivityHistoryEntityAdaptor.updateOne(
      {
        id: history_id,
        changes: {
          loading: false,
          loaded: true,
          error,
        },
      },
      {
        ...state,
      }
    );
  })
);
