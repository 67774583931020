import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { configureURL, ILaunchpointNotificationAnalytics, INotificationMessageAnalyticsController } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../interfaces';

@Injectable()
export class NotificationMessageAnalyticsHTTPService
  extends LaunchpointSecurityUserIdHeaderService
  implements INotificationMessageAnalyticsController
{
  /**
   * Only works for web.
   */
  API_URL = '/api/notifications/';

  constructor(
    private http: HttpClient,
    @Inject(NOTIFICATION_CONFIG_TOKEN) public _LaunchpointCoreClientNotificationConfig: LaunchpointCoreClientNotificationConfig,
    public _Store: Store
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientNotificationConfig?.base_url,
      version: 1,
      route: 'notifications-analytics',
    });
  }
  getLast30Days(): Observable<ILaunchpointNotificationAnalytics[]> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ILaunchpointNotificationAnalytics[]>(this.API_URL, { headers });
      })
    );
  }
}
