import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IQuerySort } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import {
  LaunchpointCoreClientUsersStateBaseComponent,
  searchAllUsers,
  updateAllUsersPagination,
  updateAllUsersQuery,
  updateAllUsersSorting,
} from '../../../../../+state';

@Directive()
export abstract class UsersTableBaseAdminSmartComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  constructor(public _router: Router, public _route: ActivatedRoute, public _Store: Store) {
    super(_Store);
  }

  ngOnInit() {
    this._Store.dispatch(searchAllUsers());
  }

  pageChange(paginate) {
    this._Store.dispatch(updateAllUsersPagination({ pagination: paginate }));
  }

  runQuery(search_string) {
    this._Store.dispatch(updateAllUsersQuery({ query: { search: search_string } }));
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllUsersSorting({ querySort: sortDataEmitter }));
  }
}
