import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-feeds-widget3',
  templateUrl: './feeds-widget3.component.html',
})
export class FeedsWidget3Component {
  // constructor() {}
  // ngOnInit(): void {}
}
