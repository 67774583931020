import { IHttpException, INotification } from '@launchpoint/core-types';

export interface NotificationsEntityState {
  notification_id: string;
  notification: INotification;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  notification_config?: any;
  success_message?: string;
}
