import { Observable } from 'rxjs';
import {
  ICoreUserReportingAccountsResponse,
  ICoreUserReportingParamsDemographics,
  ICoreUserReportingParamsLocation,
  ICoreUserReportingParamsNotificationTopics,
  ICoreUserReportingResponse,
} from './core-user-reporting.interface';

/**
 * Controller('user/reporting')
 */
export interface ICoreUserReportingController {
  /**
   * @Post('locations')
   */
  locations(body: ICoreUserReportingParamsLocation): Promise<ICoreUserReportingResponse> | Observable<ICoreUserReportingResponse>;
  /**
   * @Post('demographics')
   */
  demographics(body: ICoreUserReportingParamsDemographics): Promise<ICoreUserReportingResponse> | Observable<ICoreUserReportingResponse>;
  /**
   * @Post('notifications')
   */
  notifications(body: ICoreUserReportingParamsNotificationTopics): Promise<ICoreUserReportingResponse> | Observable<ICoreUserReportingResponse>;
  /**
   * @Post('accounts')
   */
  accounts(): Promise<ICoreUserReportingAccountsResponse> | Observable<ICoreUserReportingAccountsResponse>;
}
