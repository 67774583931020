import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss'],
})
export class Error500Component {
  //constructor() {}
  // ngOnInit(): void {}
}
