import { Observable } from 'rxjs';
import { IUserAccountsSecurityRolesParamsCreate, IUserAccountsSecurityRolesParamsDelete } from './accounts-security-roles.interface';
import { ICoreUser } from '../../../user-company-account.interface';

export interface IUserAccountsSecurityRolesController {
  /**
   * @Post()
   * @Version('1')
   * @param params
   *
   * Add security roles to a user's account.
   */
  createAccountSecurityRoles(params: IUserAccountsSecurityRolesParamsCreate): Promise<ICoreUser> | Observable<ICoreUser>;

  /**
   * @Put('delete')
   * @Version('1')
   *
   * Removes account security roles
   */
  deleteAccountSecurityRoles(params: IUserAccountsSecurityRolesParamsDelete): Promise<ICoreUser> | Observable<ICoreUser>;
}
