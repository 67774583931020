import { IEnvironment } from './environment.interface';

/**
 * Workspace shared environment for ease of use across all shared code and apps
 * You can ignore this file - values here only used for unit testing
 * Real Values are filled in by each runtime/platform/app
 */
export const environment: IEnvironment = {
  environmentName: 'local',
  production: false,
  appVersion: 'v8.0.27',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'api/backend/',
  apiMediaUrl: 'api/media/',
  notificationsApiUrl: 'api/notifications/',
  appThemeName: '',
  appPurchaseUrl: '',
  appHTMLIntegration: '',
  appPreviewUrl: '',
  appPreviewAngularUrl: '',
  appPreviewDocsUrl: '',
  appPreviewChangelogUrl: '',
};
