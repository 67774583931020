<div class="modal-content rounded">
  <div class="modal-header d-flex flex-row justify-content-between align-items-center bd-highlight py-4 flex-wrap">
    <div class="d-flex flex-stack">
      <div class="fs-3 fw-bold">
        {{ category ? 'Edit': 'Create' }} Category
      </div>
    </div>
    <div class="btn btn-sm btn-icon" (click)="activeModal.dismiss()">
      <i class="bi bi-x-lg m-3"></i>
    </div>
  </div>
  <div class="modal-body scroll-y p-10">
    <div class="pe-5">

      <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" [formGroup]="form"
        (ngSubmit)="submit()">

        <input autofocus style="display: none" />

        <div class="fv-row mb-10">
          <label class="form-label fw-semibold required">Title</label>

          <input class="form-control form-control-lg form-control-solid" type="text" formControlName="title"
            placeholder="title" name="title" autocomplete="off" [ngClass]="{
                'is-invalid': form.controls['title'].invalid,
                'is-valid': form.controls['title'].valid
              }" />
          <ng-container *ngIf="form.controls['title'].valid || form.controls['title'].pristine"
            class="fv-plugins-message-container">
          </ng-container>

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: 'Title is required',
                  control: form.controls['title']
                }">
          </ng-container>

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'maxlength',
                  message: 'Title can have maximum 100 characters',
                  control: form.controls['title']
                }">
          </ng-container>
        </div>
      </form>

      <div class="d-flex flex-wrap" [ngClass]="{
        'justify-content-between': category,
        'justify-content-end': !category
      }">

        @if (category) {
        <button type="button" (click)="deletePopup(category)" class="btn btn-outline-danger">
          Delete
        </button>
        }

        <div>
          <button type="button" (click)="activeModal.dismiss()" class="btn btn-light me-5">
            Close
          </button>

          <button type="submit" id="create_submit" [disabled]="form.invalid || form.pristine" class="btn btn-primary"
            (click)="submit()">
            <span class="indicator-label" *ngIf="(loading$ | async ) === false">
              {{ category ? 'Save' : 'Create' }}
            </span>
            <ng-container *ngIf="(loading$ | async)">
              <span class="indicator-label">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>