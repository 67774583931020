<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">
    <launchpoint-toolbar [options]="options" (create)="open()">
      <launchpoint-blogs-filter />
    </launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <launchpoint-blogs-table />
      </div>
    </div>
  </div>
</div>
