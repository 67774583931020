import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { EMediaMimeTypes, IMedia, IMediaSearchQuery } from '@launchpoint/core-types';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog-media',
  templateUrl: './blog-media.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogMediaComponent extends LaunchpointCoreClientBlogBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);
  query: IMediaSearchQuery = {
    types: [EMediaMimeTypes.IMAGE],
  };

  receiveMediaSelection(event: IMedia) {
    this.updateBlog({
      _id: this.blog._id,
      featured_image: event._id,
    });
  }

  deleteMediaPopup() {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove the featured image from this blog?',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, close this.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.updateBlog({
            _id: this.blog._id,
            featured_image: null,
          });
        }
      });
  }
}
