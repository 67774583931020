<!-- if the type is a message - show a div with the text -->
@if (banners$()?.length > 0) {
<div [class]="getBannerClass" [ngClass]="{
    'min-h-40px rounded p-4 position-relative': !private,
    'd-flex flex-column toolbar shadow-none align-items-center min-h-40px p-2': private
}">

    <div class="d-flex flex-row w-100 align-items-center" [ngClass]="{
        'justify-content-between': banners$()?.length > 1,
        'justify-content-center': banners$()?.length === 1
    }">

        <!-- Left Arrow Column (first column) -->
        @if (banners$()?.length > 1) {
        <button type="button" (click)="onPrevious($event)" class="btn btn-icon cursor-pointer" [class]="getArrowClass">
            <i class="fas fa-arrow-left"></i>
        </button>
        }
        <!-- Message Column (center column) -->
        <div class="d-flex flex-wrap whitespace-break-spaces px-4 text-center">
            {{ currentBanner$()?.message }}
        </div>

        <!-- Right Arrow Column (third column) -->
        @if (banners$()?.length > 1) {
        <button type="button" (click)="nextMessage()" class="btn btn-icon cursor-pointer" [class]="getArrowClass">
            <i class="fas fa-arrow-right"></i>
        </button>
        }

    </div>

    <!-- Dots Indicator (if more than 1 banner) -->
    @if (banners$()?.length > 1) {
    <div class="d-flex position-absolute w-100 justify-content-center" style="bottom: 3px;" [ngClass]="{
        'ms-n4': !private,
    }">
        @for (banner of banners$(); track banner) {
        @if (banner?.message === currentBanner$()?.message) {
        <i class="fas fa-circle pe-1 fs-10" [class]="'text-'+banner?.text_color"></i>
        } @else {
        <i class="fas fa-circle pe-1 fs-10"></i>
        }
        }
    </div>
    }
</div>
}