import { GetJobCommandOutput } from '@aws-sdk/client-mediaconvert';
import { Observable } from 'rxjs';
import { IMedia } from '../media.interface';
import { IMediaConvertWebhook } from './media-convert.interface';

/**
 * @Controller('media-convert')
 */
export interface IMediaConvertController {
  /**
   * @Get('id')
   */
  getJob(job_id: string): Promise<GetJobCommandOutput> | Observable<GetJobCommandOutput>;
  /**
   * @Post()
   */
  createJob(body: IMedia): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Post('webhook')
   */
  webhook(body: IMediaConvertWebhook): Promise<IMedia> | Observable<IMedia>;
}
