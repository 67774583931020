import { ELaunchpointFormsInputType } from '../../dynamic-forms.constants';
import { Type as TypeTransformer } from 'class-transformer';
import { ArrayNotEmpty, IsArray, IsEnum, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator';
import { ILaunchpointDynamicFormPerformanceMetrics } from '../form.shared.interface';

export enum ELaunchpointDynamicFormComputedFieldOperation {
  ADD = 'add',
  SUBTRACT = 'subtract',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
}

export interface ILaunchpointDynamicFormComputedFieldBase {
  _id?: string;
  field_name: string;
  description?: string; // Optional because of `required: false`
  field_type: ELaunchpointFormsInputType;
  equation: ILaunchpointDynamicFormComputedFieldEquation;
  compute_logic?: string; // Optional because of `required: false`
  question_merge: string;
  performance_metrics?: ILaunchpointDynamicFormPerformanceMetrics;
}

export interface ILaunchpointDynamicFormComputedField extends ILaunchpointDynamicFormComputedFieldBase {
  _id?: string;
}

export class ILaunchpointDynamicFormComputedFieldEquation {
  @IsEnum(ELaunchpointDynamicFormComputedFieldOperation, {
    message: 'Operation must be valid ELaunchpointDynamicFormComputedFieldOperation enum value',
  })
  operation: ELaunchpointDynamicFormComputedFieldOperation;
  operands: Array<ILaunchpointDynamicFormComputedFieldOperand>;
}

export type ILaunchpointDynamicFormComputedFieldOperand =
  | ILaunchpointDynamicFormComputedFieldQuestionOperand
  | ILaunchpointDynamicFormComputedFieldOperationOperand
  | ILaunchpointDynamicFormComputedFieldValueOperand;

export interface BaseOperand {
  type: 'question' | 'operation' | 'value';
}

export interface ILaunchpointDynamicFormComputedFieldQuestionOperand extends BaseOperand {
  type: 'question';
  question_id: string;
}

export interface ILaunchpointDynamicFormComputedFieldOperationOperand extends BaseOperand {
  type: 'operation';
  operation: ILaunchpointDynamicFormComputedFieldEquation;
}

export interface ILaunchpointDynamicFormComputedFieldValueOperand extends BaseOperand {
  type: 'value';
  value: number;
}

/**
 * This object is to set the params to create one object. Required feilds need to be listed.
 */
export class ILaunchpointDynamicFormComputedFieldParamsCreateOne implements Partial<ILaunchpointDynamicFormComputedField> {
  _id?: never;
  // TODO: Add required Feilds
  @IsString({ message: 'field name must be a string' })
  field_name: string;
  @IsString({ message: 'question_merge must be a string' })
  question_merge: string;
  @IsString({ message: 'description must be a string' })
  @IsOptional()
  description?: string; // Optional because of `required: false`
  @IsEnum(ELaunchpointFormsInputType, { message: 'field type must be a valid ELaunchpointFormsInputType enum value' })
  field_type: ELaunchpointFormsInputType;
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormComputedFieldEquation)
  equation: ILaunchpointDynamicFormComputedFieldEquation;
  @IsString({ message: 'compute logic must be a string' })
  @IsOptional()
  compute_logic?: string; // Optional because of `required: false`
}

/**
 * This object is to set the params to update one object, it mandates the _id so it can be updated. Required feilds need to be listed.
 */
export class ILaunchpointDynamicFormComputedFieldParamsUpdateOne implements Partial<ILaunchpointDynamicFormComputedField> {
  @IsString({ message: '_id must be a string' })
  _id!: string;

  @IsOptional()
  @IsString({ message: 'question_merge must be a string' })
  question_merge?: string;
  @IsString({ message: 'field name must be a string' })
  @IsOptional()
  field_name?: string;
  @IsString({ message: 'description must be a string' })
  @IsOptional()
  description?: string; // Optional because of `required: false`
  @IsEnum(ELaunchpointFormsInputType, { message: 'field type must be a valid ELaunchpointFormsInputType enum value' })
  @IsOptional()
  field_type?: ELaunchpointFormsInputType;
  @IsOptional()
  @ValidateNested()
  @TypeTransformer(() => ILaunchpointDynamicFormComputedFieldEquation)
  equation?: ILaunchpointDynamicFormComputedFieldEquation;
  @IsString({ message: 'compute logic must be a string' })
  @IsOptional()
  compute_logic?: string; // Optional because of `required: false`
}

/**
 * Create one will just pass an array with one object
 */
export class ILaunchpointDynamicFormComputedFieldParamsCreate {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;

  @IsArray({ message: 'computed fields must be an array' })
  @ArrayNotEmpty({ message: 'computed fields array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormComputedFieldParamsCreateOne' })
  @TypeTransformer(() => ILaunchpointDynamicFormComputedFieldParamsCreateOne) // Use the correct type for your logic
  computed_fields!: ILaunchpointDynamicFormComputedFieldParamsCreateOne[];
}

/**
 * Update one will just pass an array with one object
 */
export class ILaunchpointDynamicFormComputedFieldParamsUpdate {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;

  @IsArray({ message: 'computed fields must be an array' })
  @ArrayNotEmpty({ message: 'computed fields array cannot be empty' })
  @ValidateNested({ each: true, message: 'Each update must be a valid ILaunchpointDynamicFormComputedFieldParamsUpdateOne' })
  @TypeTransformer(() => ILaunchpointDynamicFormComputedFieldParamsUpdateOne) // Use the correct type for your logic
  computed_fields!: ILaunchpointDynamicFormComputedFieldParamsUpdateOne[];
}
export class ILaunchpointDynamicFormComputedFieldParamsReorder {
  /**
   * _id of the rate schedule you want to add this deductible to.
   */
  @IsString({ message: '_id must be a string' })
  _id: string;
  // @ValidateNested()
  // @TypeTransformer(() => ILaunchpointDynamicFormComputedField)
  computed_field: ILaunchpointDynamicFormComputedField;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  previousIndex: number;
  @IsNumber({ allowNaN: false, allowInfinity: false, maxDecimalPlaces: 0 })
  currentIndex: number;
}
/**
 * Delete one will just pass an array with one _id
 */
export class ILaunchpointDynamicFormComputedFieldParamsDelete {
  /**
   * _id of the document
   */
  @IsString({ message: '_id must be a string' })
  _id!: string;

  @IsArray({ message: 'computed_field_ids must be an array' })
  @ArrayNotEmpty({ message: 'computed_field_ids array cannot be empty' })
  @IsString({ each: true, message: 'Each _id in the computed_field_ids array must be a string' })
  computed_field_ids!: string[];
}
