import { Observable } from 'rxjs';
import {
  ICoreActivityHistoryWebhookEvent,
  ICoreActivityHistoryWebhookEventSearchPayload,
  ICoreActivityHistoryWebhookEventSearchResults,
} from './activity-history-webhook-events.interface';

/**
 * @Controller('activity-history/wh-events')
 */
export interface ICoreActivityHistoryWebhookEventController {
  /**
   * Post ('search')
   */
  search(
    params: ICoreActivityHistoryWebhookEventSearchPayload
  ): Promise<ICoreActivityHistoryWebhookEventSearchResults> | Observable<ICoreActivityHistoryWebhookEventSearchResults>;

  /**
   * Get(':id')
   */
  getById(id: string): Promise<ICoreActivityHistoryWebhookEvent> | Observable<ICoreActivityHistoryWebhookEvent>;
  /**
   * Put ('delete/:id')
   */
  delete(id: string): Promise<ICoreActivityHistoryWebhookEvent> | Observable<ICoreActivityHistoryWebhookEvent>;
}
