<div style="min-width: 500px;">
  <div class="px-7 py-5">
    <div class="fs-5 text-dark fw-bolder">Filter Options</div>
  </div>

  <div class="separator border-gray-200"></div>

  <form class="form px-7 py-5" [formGroup]="form">
    <div class="mb-5">
      <label class="form-label fw-bold">Media Types</label>

      <tagify formControlName="types" class="me-5" name="types" [whitelist]="whitelistStatus$" [settings]="typeSettings"
        inputClass="form-control form-control-sm form-control-solid ps-2 min-w-200px" />
    </div>

    <div class="mb-5">
      <label class="form-label fw-bold">Category</label>

      <launchpoint-media-categories-tagify (selected)="setCategory($event)" />
    </div>

    <div class="mb-5">
      <label class="form-label fw-bold">Tags</label>

      <tagify formControlName="tags" class="me-5" name="tags" [settings]="tagSettings" [whitelist]="whitelistTags$"
        inputClass="form-control form-control-sm form-control-solid ps-2 min-w-200px" />
    </div>

    <div class="mb-5">
      <label class="form-label fw-semibold">Archived</label>
      <launchpoint-boolean-filter [active]="form?.controls?.archived?.value" (selected)="change( $event)" />
    </div>

    <div class="d-flex justify-content-end">
      <button type="reset" class="btn btn-sm btn-transparent text-active-primary me-2" data-kt-menu-dismiss="true"
        (click)="reset()"><u>Reset</u></button>

      <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" (click)="submit()">Apply</button>
    </div>
  </form>
</div>