import {
  IHttpException,
  IQueryPageination,
  IQuerySort,
  IQueryUserView,
  IUserView,
  IUserViewParamsCreate,
  IUserViewParamsUpdate,
  IUserViewQueryParams,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllView = createAction('[Views] searchAllView');
export const searchAllViewSuccess = createAction('[Views] searchAllViewSuccess', props<{ data: IQueryUserView; success_message?: string }>());
export const searchAllViewFailure = createAction('[Views] searchAllViewFailure', props<{ error: IHttpException }>());
// Mechanics
export const updateAllViewPagination = createAction('[Views] updateAllViewPagination', props<{ pagination: IQueryPageination }>());
export const updateAllViewPaginationScroll = createAction('[Views] updateAllViewPaginationScroll', props<{ pagination: IQueryPageination }>());
export const updateAllViewSorting = createAction('[Views] updateAllViewSorting', props<{ querySort: IQuerySort }>());
export const updateAllViewQuery = createAction('[Views] updateAllViewQuery', props<{ query: IUserViewQueryParams }>());
// getById
export const getViewById = createAction('[Views] getViewById', props<{ view_id: string }>());
export const getViewByIdSuccess = createAction('[Views] getViewByIdSuccess', props<{ view: IUserView; success_message?: string }>());
export const getViewByIdFailure = createAction('[Views] getViewByIdFailure', props<{ view_id: string; error: IHttpException }>());
// Clear
export const clearView = createAction('[Views] clearView');
// Update View
export const createView = createAction('[Views] createView', props<{ view: IUserViewParamsCreate }>());
export const createViewSuccess = createAction('[Views] createViewSuccess', props<{ view: IUserView; success_message?: string }>());
export const createViewFailure = createAction('[Views] createViewFailure', props<{ error: IHttpException }>());
// Update View
export const updateView = createAction('[Views] updateView', props<{ view: IUserViewParamsUpdate }>());
export const updateViewSuccess = createAction('[Views] updateViewSuccess', props<{ view: IUserView; success_message?: string }>());
export const updateViewFailure = createAction('[Views] updateViewFailure', props<{ view_id: string; error: IHttpException }>());
// Delete View
export const deleteView = createAction('[Views] deleteView', props<{ view_id: string }>());
export const deleteViewSuccess = createAction('[Views] deleteViewSuccess', props<{ view: IUserView; success_message?: string }>());
export const deleteViewFailure = createAction('[Views] deleteViewFailure', props<{ view_id: string; error: IHttpException }>());
// Create View Address
// Close Alerts - //TODO: is this needed anymore?
export const closeViewSuccessMessage = createAction('[Views] closeViewSuccessMessage');
export const closeViewErrorMessage = createAction('[Views] closeViewErrorMessage');
