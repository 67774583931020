import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanySettingsParentBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-settings-parent',
  templateUrl: 'company-settings-parent.component.html',
})
export class CompanySettingsParentComponent extends CompanySettingsParentBaseComponent {
  constructor(_Store: Store) {
    super(_Store);
  }
}
