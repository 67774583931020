import { IHttpException, IQueryPageination, IQuerySort, IUserViewQueryParams } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserViewActions from '../actions/views.actions';
import { ViewEntityState } from '../interfaces/views.interface';

export const ViewFeatureKey = 'ViewReducer';

export interface StateViewReducer extends EntityState<ViewEntityState> {
  selected_view_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IUserViewQueryParams;
}

export const ViewEntityAdaptor: EntityAdapter<ViewEntityState> = createEntityAdapter<ViewEntityState>({
  selectId: (user) => user.view_id,
});

export const initialViewState: StateViewReducer = ViewEntityAdaptor.getInitialState({
  // set initial required properties
  selected_view_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 1000, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '', table_id: null },
  error: null,
  success_message: null,
});

export const ViewReducer = createReducer(
  initialViewState,
  on(UserViewActions.searchAllView, (state) => {
    return ViewEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserViewActions.searchAllViewSuccess, (state, { data }) => {
    const userEntities = data.data.map((view) => {
      const entity: ViewEntityState = {
        view_id: view._id,
        view: view,
        loaded: true,
        loading: false,
        error: null,
        // profile_config: USERS_PROFILE_CONFIG,
      };
      return entity;
    });
    return ViewEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(UserViewActions.searchAllViewFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(UserViewActions.updateAllViewQuery, (state, { query }) => {
    return ViewEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(UserViewActions.updateAllViewPagination, (state, { pagination }) => {
    return ViewEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(UserViewActions.updateAllViewPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(UserViewActions.updateAllViewSorting, (state, { querySort }) => {
    return ViewEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(UserViewActions.getViewById, (state, { view_id }) => ({
    ...state,
    selected_view_id: view_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(UserViewActions.clearView, (state) => ({
    ...state,
    selected_view_id: null,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(UserViewActions.getViewByIdSuccess, (state, { view }) => {
    const entity: ViewEntityState = {
      view_id: view._id,
      view: view,
      loaded: true,
      loading: false,
      error: null,
    };
    return ViewEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_view_id: view._id,
      loaded: true,
      loading: false,
    });
  }),
  on(UserViewActions.getViewByIdFailure, (state, { view_id, error }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view_id,
        changes: {
          error,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  // Create
  on(UserViewActions.createView, (state, { view }) => ({
    ...state,
    loading: true,
    loaded: false,
    success_message: null,
    error: null,
  })),

  on(UserViewActions.createViewSuccess, (state, { view, success_message }) => {
    const entity: ViewEntityState = {
      view_id: view._id,
      view: view,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return ViewEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      selected_view_id: view._id,
    });
  }),
  on(UserViewActions.createViewFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
  })),
  on(UserViewActions.updateView, (state, { view }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view._id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserViewActions.updateViewSuccess, (state, { view, success_message }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view._id,
        changes: {
          view,
          error: null,
          loaded: false,
          loading: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserViewActions.updateViewFailure, (state, { view_id, error }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserViewActions.deleteView, (state, { view_id }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserViewActions.deleteViewSuccess, (state, { view, success_message }) => {
    return ViewEntityAdaptor.removeOne(view._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(UserViewActions.deleteViewFailure, (state, { view_id, error }) => {
    return ViewEntityAdaptor.updateOne(
      {
        id: view_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  })
);
