import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LaunchpointBlogTagsHTTPService, LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { ITagifyTag } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { Observable, map } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebBlogTagsTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnChanges {
  @Input() tags: string[] = [];

  tagsModel: ITagifyTag[] = [];
  public whitelistBlogTags$: Observable<string[]>;

  public settings: TagifySettings = {
    placeholder: 'Search Blog Tags...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  @Output() selectedTags = new EventEmitter<ITagifyTag[]>();

  constructor(
    public _LaunchpointBlogTagsHTTPService: LaunchpointBlogTagsHTTPService,
    public tagifyService: TagifyService
  ) {
    super();
    this.whitelistBlogTags$ = this._LaunchpointBlogTagsHTTPService.distinct().pipe(map((f) => f));
  }

  ngOnInit(): void {
    if (this.tags?.length > 0) {
      setTimeout(async () => {
        this.tagsModel = this.tags.map((d) => {
          return {
            value: d,
            data: d,
          };
        });

        if (this.tagsModel?.length) {
          this.tagifyService.get('blog-tags').loadOriginalValues(this.tagsModel);
        }
      }, 250);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tags?.currentValue?.length > 0 && !changes?.tags?.firstChange) {
      setTimeout(async () => {
        this.tagsModel = this.tags.map((d) => {
          return {
            value: d,
            data: d,
          };
        });

        if (this.tagsModel?.length) {
          this.tagifyService.get('blog-tags').loadOriginalValues(this.tagsModel);
        }
      }, 50);
    }
  }

  selectData(changes: ITagifyTag[]) {
    // console.log({ changes });
    return this.selectedTags.emit(changes);
  }
}
