import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  configureURL,
  ICoreBlog,
  ICoreBlogParamsGetOne,
  ICoreBlogPublicController,
  ICoreBlogSearchPayload,
  ICoreBlogSearchResults,
} from '@launchpoint/core-types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointBlogPublicHTTPService implements ICoreBlogPublicController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'resources-public';

  constructor(private http: HttpClient) {
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      version: 1,
      route: this.BASE_URL,
    });
  }

  searchPublic(params?: ICoreBlogSearchPayload): Observable<ICoreBlogSearchResults | null> {
    return this.http.post<ICoreBlogSearchResults>(this.API_URL + '/search', params);
  }

  findOnePublic(params?: ICoreBlogParamsGetOne): Observable<ICoreBlog | null> {
    return this.http.post<ICoreBlog>(this.API_URL + '/find-one', params);
  }
}
