import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BLOG_SERVICES, BLOGS_EFFECTS, BlogsFeatureKey, LaunchpointBlogsReducer, LibCoreClientPipesModule } from '@launchpoint/core-client';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  AudioService,
  HtmlEditorService,
  ImageService,
  LinkService,
  PasteCleanupService,
  QuickToolbarService,
  RichTextEditorModule,
  TableService,
  ToolbarService,
  VideoService,
} from '@syncfusion/ej2-angular-richtexteditor';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebBooleanFilterComponent } from '../../components/boolean-filter/boolean-filter.component';
import { LaunchpointCoreWebDetailViewHeaderCardComponent } from '../../components/detail-view-header-card/detail-view-header-card.component';
import { LaunchpointCoreWebEllipsisMenuComponent } from '../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';
import { SkeletonLoadersComponent } from '../../components/skeletons/skeleton-loaders/skeleton-loaders.component';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { UtilityDatesService } from '../../components/utility-dates.service';
import { CompanyTagifyWebComponent } from '../company/components/company-tagify/company-tagify.component';
import { LaunchpointCoreWebAddressAutoCompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { LaunchpointCoreMediaSearchAheadComponent } from '../media/features/media/media-search-ahead/media-search-ahead.component';
import { LaunchpointCoreWebUserSearchAheadComponent } from '../users/user-search-ahead-standalone/user-search-ahead.component';
import { LaunchpointCoreWebUsersModule } from '../users/users-core.module';
import { LaunchpointCoreWebUsersTagifyComponent } from '../users/users-tagify/users-tagify.component';
import { LaunchpointCoreWebBlogCategorySearchAheadComponent } from './blog-category-search-ahead/blog-category-search-ahead.component';
import { LaunchpointCoreWebBlogCategoryTagifyWebComponent } from './blog-category-tagify/blog-category-tagify.component';
import { BLOG_DETAIL_COMPONENTS } from './blog-detail';
import { LaunchpointCoreWebBlogModalComponent } from './blog-modal/blog-modal.component';
import { LaunchpointCoreWebBlogTagsTagifyWebComponent } from './blog-tags-tagify/blog-tags-tagify.component';
import { LaunchpointCoreWebBlogsTableFilterComponent } from './blogs-table-filter/blogs-table-filter.component';
import { LaunchpointCoreWebBlogsTableComponent } from './blogs-table/blogs-table.component';
import { LaunchpointCoreWebBlogTagifyWebComponent } from './blogs-tagify/blogs-tagify.component';
import { LaunchpointCoreWebBlogsToolbarComponent } from './blogs-toolbar/blogs-toolbar.component';

const BLOG_COMPONENTS = [
  //
  ...BLOG_DETAIL_COMPONENTS,
  LaunchpointCoreWebBlogsToolbarComponent,
  LaunchpointCoreWebBlogsTableComponent,
  LaunchpointCoreWebBlogModalComponent,
  LaunchpointCoreWebBlogsTableFilterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TagifyModule,
    NgbTooltipModule,
    LaunchpointCoreWebUsersTagifyComponent,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointCoreWebUserSearchAheadComponent,
    LaunchpointCoreWebUsersModule,
    LaunchpointFormControlDateRangeComponent,
    LaunchpointCoreWebEllipsisMenuComponent,
    LaunchpointCoreWebPaginationComponent,
    LaunchpointCoreWebAddressAutoCompleteComponent,
    LibCoreClientPipesModule,
    CompanyTagifyWebComponent,
    SkeletonLoadersComponent,
    LaunchpointCoreWebDetailViewHeaderCardComponent,
    LaunchpointCoreMediaSearchAheadComponent,
    LaunchpointCoreWebBooleanFilterComponent,
    // Syncfusion
    RichTextEditorModule,
    // standalone
    LaunchpointCoreWebBlogTagifyWebComponent,
    LaunchpointCoreWebBlogTagsTagifyWebComponent,
    LaunchpointCoreWebBlogCategorySearchAheadComponent,
    LaunchpointCoreWebBlogCategoryTagifyWebComponent,
    // Store
    StoreModule.forFeature(BlogsFeatureKey, LaunchpointBlogsReducer),
    EffectsModule.forFeature(BLOGS_EFFECTS),
  ],
  exports: [...BLOG_COMPONENTS],
  declarations: [...BLOG_COMPONENTS],
  providers: [
    ...BLOG_SERVICES,
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    QuickToolbarService,
    PasteCleanupService,
    VideoService,
    AudioService,
    TableService,
    UtilityDatesService,
    DatePipe,
  ],
})
export class LaunchpointCoreWebBlogModule {}
