import { Observable } from 'rxjs';
import { ILaunchpointDynamicForm } from '../form.interface';
import {
  ILaunchpointDynamicFormComputedFieldParamsCreate,
  ILaunchpointDynamicFormComputedFieldParamsUpdate,
  ILaunchpointDynamicFormComputedFieldParamsDelete,
  ILaunchpointDynamicFormComputedFieldParamsReorder,
} from './computed-fields.interface';

export interface ILaunchpointDynamicFormComputedFieldController {
  add(params: ILaunchpointDynamicFormComputedFieldParamsCreate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  update(params: ILaunchpointDynamicFormComputedFieldParamsUpdate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  reorder(params: ILaunchpointDynamicFormComputedFieldParamsReorder): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  remove(params: ILaunchpointDynamicFormComputedFieldParamsDelete): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
}
