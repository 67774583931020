import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreUser,
  ICoreUserAddressController,
  ICoreUserAddressParamsCreate,
  ICoreUserAddressParamsUpdate,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../../security/header-services/user-id-security-header.service';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../core-profile/interfaces/core-profile.interface';
import { ICoreUserAddressConfigRoutes } from '../interfaces/core-address.interface';

@Injectable({
  providedIn: 'root',
})
export class AddressHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreUserAddressController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';

  routes: ICoreUserAddressConfigRoutes = {
    base: 'user/address',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientProfileConfig?.base_url,
      version: 1,
      route: 'user/address',
    });
  }

  update(body: ICoreUserAddressParamsUpdate): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL, body, { headers });
      })
    );
  }

  create(body: ICoreUserAddressParamsCreate): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL, body, { headers });
      })
    );
  }
}
