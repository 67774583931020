import { Observable } from 'rxjs';
import { ENotificationChannelType } from '../notifications/notifications.constants';
import { ILaunchpointJobsJobState } from '../../redis/bullmq-job.interface';

export interface ILaunchpointNotificationAnalytics {
  channel: ENotificationChannelType;
  totalCount: number;
  jobStates: {
    state: ILaunchpointJobsJobState;
    count: number;
  }[];
}
/**
 * Controller('notifications/messages')
 */
export interface INotificationMessageAnalyticsController {
  /**
   * Get(':id')
   * @param id
   */
  getLast30Days(): Promise<ILaunchpointNotificationAnalytics[]> | Observable<ILaunchpointNotificationAnalytics[]>;
}
