import { Directive } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';

@Directive()
export abstract class CompanyBillingParentBaseComponent extends LaunchpointCoreClientBaseComponent {
  // companyEntity$: Observable<LaunchpointCompanyEnitityState | undefined>;
  // env_url = environment.environmentName === 'prod' ? '' : '/test';
  show_json = false;

  constructor(public _Store: Store, public _router: Router, public _route: ActivatedRoute) {
    super();
    // this.companyEntity$ = this._Store.select(selectSkylabSelectedCompany);
  }

  // addBankAccount(params: any, company_id: string) {
  //   // { stripe_customer_id: string; source: string }
  //   // @Output() addBankAccount = new EventEmitter< { stripe_customer_id: string; source: string; }>();
  //   // { company_id: string; stripe_customer_id: string; source: string; }
  //   const data = {
  //     ...params,
  //     company_id,
  //   };
  //   this._Store.dispatch(addCompanyBankAccount({ data }));
  // }

  // removePaymentMethod(data) {
  //   console.log(data);
  // }
}
