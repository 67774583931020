<a routerLink="/crafted/pages/profile/overview" class="card border border-2 border-gray-300 border-hover">
  <div class="card-header border-0 pt-9">
    <div class="card-title m-0">
      <div class="symbol symbol-50px w-50px bg-light">
        <img [src]="icon" alt="card2" class="p-3" />
      </div>
    </div>

    <div class="card-toolbar">
      <span class="badge fw-bolder me-auto px-4 py-3" [ngClass]="'badge-light-' + badgeColor">
        {{ status }}
      </span>
    </div>
  </div>

  <div class="card-body p-9">
    <div class="fs-3 fw-bolder text-dark">{{ title }}</div>

    <p class="text-gray-400 fw-bold fs-5 mt-1 mb-7">{{ description }}</p>

    <div class="d-flex flex-wrap mb-5">
      <div class="
          border border-gray-300 border-dashed
          rounded
          min-w-125px
          py-3
          px-4
          me-7
          mb-3
        ">
        <div class="fs-6 text-gray-800 fw-bolder">{{ date }}</div>
        <div class="fw-bold text-gray-400">Due Date</div>
      </div>

      <div class="
          border border-gray-300 border-dashed
          rounded
          min-w-125px
          py-3
          px-4
          mb-3
        ">
        <div class="fs-6 text-gray-800 fw-bolder">{{ budget }}</div>
        <div class="fw-bold text-gray-400">Budget</div>
      </div>
    </div>

    <div class="h-4px w-100 bg-light mb-5" ngbTooltip="This project completed">
      <div class="rounded h-4px" [ngClass]="'bg-' + statusColor" role="progressbar" [style.width]="progress + '%'">
      </div>
    </div>

    <launchpoint-app-user-list [users]="users"></launchpoint-app-user-list>
  </div>
</a>