<div class="bg-medium relative flex justify-center px-6 py-24 md:px-0" [class]="cssClass">
  <div class="w-[1200px] max-w-full text-black md:px-6">
    <div class="flex flex-col justify-between md:flex-row">
      <div class="text-center md:text-left">
        <div class="mb-2 md:text-[26px]">Stay in the loop with all our Free S**t</div>
        <div class="text-sm">Subscribe for Updates: Stay informed about the latest...</div>
      </div>

      <div class="mt-10 flex items-center md:mt-0 md:min-w-[30%]">
        <div class="relative w-full">
          <input
            [formControl]="form"
            type="email"
            placeholder="Enter your email address"
            class="w-full border border-gray-300 py-3 pl-4 pr-[130px] text-sm text-black focus:outline-none focus:ring-2 focus:ring-[#010101]"
          />

          <button
            (click)="submitEmail()"
            class="absolute right-1.5 top-1/2 -translate-y-1/2 transform bg-[#010101] px-4 py-2.5 text-sm text-white transition-colors duration-300 ease-in-out hover:bg-[#01010160]"
          >
            Keep In Touch
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
