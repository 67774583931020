import { Component, Input } from '@angular/core';

interface MenuInit {
  title: string;
  root: boolean;
  bullet?: string;
  icon?: string;
  page?: string;
  membership_status?: string[];
  security?: string[];
  external_link?: string;
  submenu?: { title: string; page: string }[];
}
interface MenuConfig {
  logo: string;
  cart: boolean;
  cart_page: string;
  call_to_action: boolean;
  call_to_action_title: string;
  call_to_action_page: string;
  login: boolean;
  login_page: string;
}
@Component({
  selector: 'launchpoint-header-style-anchor',
  templateUrl: './header-style-anchor.component.html',
  styleUrls: ['./header-style-anchor.component.scss'],
})
export class HeaderStyleAnchorComponent {
  @Input() menu_config: MenuConfig = {
    logo: 'assets/img/logo.png',
    cart: true,
    cart_page: '/cart',
    call_to_action: true,
    call_to_action_title: 'Support',
    call_to_action_page: '/support',
    login: true,
    login_page: '/',
  };

  @Input() items: MenuInit[] = [
    {
      title: 'Home',
      root: true,
      bullet: 'dot',
      icon: 'chevron-down',
      membership_status: ['all-active'],
      security: ['view', 'beta'],
      submenu: [
        {
          title: 'Home Demo - One',
          page: '/',
        },
        {
          title: 'Home Demo - Two',
          page: '/index-2',
        },
        {
          title: 'Home Demo - Three',
          page: '/index-3',
        },
        {
          title: 'Home Demo - Four',
          page: '/index-4',
        },
        {
          title: 'Home Demo - Five',
          page: '/index-5',
        },
        {
          title: 'Home Demo - Six',
          page: '/index-6',
        },
        {
          title: 'Home Demo - Seven',
          page: '/index-7',
        },
        {
          title: 'Home Demo - Eight',
          page: '/index-8',
        },
        {
          title: 'Home Demo - Nine',
          page: '/index-9',
        },
        {
          title: 'Home Demo - Ten',
          page: '/index-10',
        },
        {
          title: 'Home Demo - Static Image',
          page: '/index-11',
        },
      ],
    },
    {
      title: 'Pages',
      root: true,
      bullet: 'dot',
      icon: 'chevron-down',
      // membership_status: ['all-active'],
      security: ['view', 'beta'],
      submenu: [
        {
          title: 'Features',
          page: '/features',
        },
        {
          title: 'Services',
          page: '/services',
        },
        {
          title: 'Services Details',
          page: '/services-details',
        },
        {
          title: 'Projects',
          page: '/projects',
        },
        {
          title: 'Project Details',
          page: '/projects-details',
        },
        {
          title: 'Pricing',
          page: '/pricing',
        },
        {
          title: 'FAQ',
          page: '/faq',
        },
        {
          title: 'Coming Soon',
          page: '/coming-soon',
        },
        {
          title: '404 Error Page',
          page: '/404-error',
        },
      ],
    },
    {
      title: 'Shop',
      root: true,
      bullet: 'dot',
      icon: 'chevron-down',
      // membership_status: ['all-active'],
      security: ['view', 'beta'],
      submenu: [
        {
          title: 'Products',
          page: '/products',
        },
        {
          title: 'Products Details',
          page: '/products-details',
        },
        {
          title: 'Cart',
          page: '/cart',
        },
        {
          title: 'Checkout',
          page: '/checkout',
        },
      ],
    },
    {
      title: 'Blog',
      root: true,
      bullet: 'dot',
      icon: 'chevron-down',
      // membership_status: ['all-active'],
      security: ['view', 'beta'],
      submenu: [
        {
          title: 'Blog Grid',
          page: '/blog-grid',
        },
        {
          title: 'Blog Right Sidebar',
          page: '/blog-right-sidebar',
        },
        {
          title: 'Blog Details',
          page: '/blog-details',
        },
      ],
    },
    {
      title: 'Contact',
      root: true,
      page: '/contact',
      security: ['view', 'admin'],
    },
  ];

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
}
