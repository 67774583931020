import { Directive, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { searchAllUserNotifications, updateAllUserNotificationQuery } from '../+state/actions/user-notification.actions';
import { LaunchpointCoreClientNotificationsUserNotificationStateBaseComponent } from '../+state/notifications-user-notification-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsUserNotificationBaseComponent
  extends LaunchpointCoreClientNotificationsUserNotificationStateBaseComponent
  implements OnInit
{
  form_control = new FormControl<string>('');

  ngOnInit() {
    this._Store.dispatch(searchAllUserNotifications());

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery(search),
      error: (error) => console.log('ERROR', error),
    });

    this.success();
    this.error();
  }

  updateQuery(search: string) {
    this._Store.dispatch(
      updateAllUserNotificationQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }
}
