import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoreUser, ICoreUserSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';

@Directive()
export abstract class CompanyUsersTableBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() pagination: IQueryPageination;
  @Input() querySort: IQuerySort;
  @Input() query: ICoreUserSearchQuery;

  @Input() loading = false;
  @Input() companyUsers: ICoreUser[];
  @Input() id: string;
  // @Input() users: ICoreUserSearchResults[];

  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() paginate = new EventEmitter<IQueryPageination>();
  @Output() runQueries = new EventEmitter<ICoreUserSearchQuery>();

  constructor(public _router: Router, public _route: ActivatedRoute) {
    super();
  }

  // ngOnInit() {
  //   console.log("users", this.companyUsers)
  //   console.log("id", this.id)
  // }

  // runQuery() {
  //   const userQuery: ICoreUserSearchQuery = { account_ids: [this.id] };
  //   this.runQueries.emit(userQuery);
  // }

  sortData(event: IQuerySort) {
    this.sortDataEmitter.emit(event);
  }

  pageChange(event: any) {
    const pagination: IQueryPageination = {
      count: this.pagination.count,
      limit: event.pageSize,
      skip: Number(event.pageIndex) * Number(event.pageSize),
      pageIndex: event.pageIndex,
      previousPageIndex: event.previousPageIndex,
    };
    this.paginate.emit(pagination);
  }
}
