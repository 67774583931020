import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { UsersTableBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { TagifyService } from 'ngx-tagify';

@Component({
  selector: 'launchpoint-users-table-filter-card',
  templateUrl: './users-table-filter-card.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class UsersTableFilterCardComponent extends UsersTableBaseComponent {
  constructor(public _Store: Store, public fb: UntypedFormBuilder, private tagifyService: TagifyService) {
    super(_Store, fb);
  }
}
