import { Observable } from 'rxjs';
import { ICoreBlog } from '../blog.interface';
import { ICoreUserJWTDecorator } from '../../auth';
import { ICoreBlogParamsCommentIds, ICoreBlogParamsCommentAdd } from './blog-comments.interface';

/**
 * @Controller('blog-comments')
 */
export interface ICoreBlogCommentsController {
  /**
   * @Post('add')
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_ADD, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_ADD, LAUNCHPOINT_BLOG_SCHEMA)
   */
  add(params: ICoreBlogParamsCommentAdd, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('remove')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REMOVE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REMOVE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  remove(params: ICoreBlogParamsCommentIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
  /**
   * @Put('like')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_LIKE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_LIKE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  like(params: ICoreBlogParamsCommentIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
  /**
   * @Put('dislike')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_DISLIKE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_DISLIKE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  dislike(params: ICoreBlogParamsCommentIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
}
