import { Directive, inject, Input } from '@angular/core';
import { IHttpException, INotificationSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { NotificationsEntityState } from './interfaces/notifications-entity.interface';
import {
  selectNotifications,
  selectNotificationsError,
  selectNotificationsLoading,
  selectNotificationsPagination,
  selectNotificationsQuery,
  selectNotificationsQuerySort,
  selectNotificationsSuccess,
} from './selectors/notifications.selectors';
import { LaunchpointCoreClientNotificationConfig, NOTIFICATION_CONFIG_TOKEN } from '../../../interfaces/core-notifications.interface';

@Directive()
export abstract class LaunchpointCoreClientNotificationsStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public _Store = inject(Store);
  public _config: LaunchpointCoreClientNotificationConfig = inject(NOTIFICATION_CONFIG_TOKEN);

  @Input() pagination: IQueryPageination = { pageIndex: 0, limit: 25, skip: 0 };
  @Input() query: INotificationSearchQuery = {};

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<INotificationSearchQuery>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  notificationEntity$: Observable<NotificationsEntityState[]>;

  constructor() {
    super();

    this.pagination$ = this._Store.select(selectNotificationsPagination);
    this.loading$ = this._Store.select(selectNotificationsLoading);
    this.loaded$ = this._Store.select(selectNotificationsLoading);
    this.querySort$ = this._Store.select(selectNotificationsQuerySort);
    this.query$ = this._Store.select(selectNotificationsQuery);
    this.error$ = this._Store.select(selectNotificationsError);
    this.success$ = this._Store.select(selectNotificationsSuccess);
    this.notificationEntity$ = this._Store.select(selectNotifications);
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
  }
  toast(params: { icon; title }) {
    // extend
  }
}
