<ng-container *ngIf="customer_id">
  <!--start::Form-->
  <form [formGroup]="form" class="form">
    <div class="card-body border-top p-9">
      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Billing Name</label>
        <div class="col-lg-8 fv-row">
          <input class="form-control form-control-lg form-control-solid" type="text" formControlName="company_name"
            placeholder="Company Name" name="company_name" autocomplete="off" [ngClass]="{
              'is-invalid': form.controls['company_name'].invalid,
              'is-valid': form.controls['company_name'].valid
            }" />

          <ng-container *ngIf="form.controls['company_name']?.valid || form.controls['company_name']?.pristine"
            class="fv-plugins-message-container">
            <span class="fv-help-block"> &nbsp;</span>
          </ng-container>

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'required',
              message: 'Company Name is required',
              control: form.controls['company_name']
            }">
          </ng-container>
          <!-- <div class="fv-plugins-message-container">
            <div class="fv-help-block">Gender is required</div>
          </div> -->
        </div>
      </div>

      <div class="row mb-6">
        <label class="col-lg-4 col-form-label fw-bold fs-6">Billing Email</label>
        <div class="col-lg-8 fv-row">
          <input class="form-control form-control-lg form-control-solid" type="email" formControlName="email"
            placeholder="Company Name" name="email" autocomplete="off" [ngClass]="{
              'is-invalid': form.controls['email'].invalid,
              'is-valid': form.controls['email'].valid
            }" />

          <ng-container *ngIf="form.controls['email'].valid || form.controls['email'].pristine"
            class="fv-plugins-message-container">
            <span class="fv-help-block"> &nbsp;</span>
          </ng-container>

          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'required',
              message: 'Account Owner is required',
              control: form.controls['email']
            }"></ng-container>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'email',
              message: 'Account Owner is required',
              control: form.controls['email']
            }"></ng-container>
        </div>
      </div>
    </div>
    <!-- <div class="card-footer d-flex justify-content-end py-6 px-9">
      <button type="button" class="btn btn-primary" [disabled]="isLoading" (click)="saveSettings()">
        <ng-container *ngIf="!isLoading">Save</ng-container>
        <ng-container *ngIf="isLoading">
          <span class="indicator-label" [style.display]="'block'">
            Please wait...{{ ' ' }}
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </ng-container>
      </button>
    </div> -->
  </form>
  <!--end::Form-->
</ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>