import { Directive, OnInit } from '@angular/core';
import { ICoreUser, ICoreUserAccount, IQueryPageination, IQuerySort, IUserAccountsParamsDelete } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { deleteCompanyOnUser } from '../+state/actions/company-users/company-users.actions';
import { searchAllCompanyUsers, updateAllCompanyUsersPagination, updateAllCompanyUsersSorting } from '../+state/actions/company.actions';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';

@Directive()
export abstract class CompanyUsersAdminBaseComponent extends CompanyStateBaseComponent implements OnInit {
  account_id: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.company$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (account) => {
        if (account) {
          this.account_id = account._id;
          this._Store.dispatch(searchAllCompanyUsers({ query: { account_ids: [this.account_id] } }));
        }
      },
    });
  }

  // ngOnInit(): void {
  //   this.selectedCompanyId$
  //   .pipe(
  //     skipWhile((value) => !value),
  //     takeUntil(this.destroy$)
  //   )
  //   .subscribe((id)=>{
  //   this.account_id = id
  //   // this.runQuery()
  //   });
  //   // this.runQuery(this.query)

  // }

  // ngOnInit(): void {
  //   this.selectedCompanyId$
  //     .pipe(
  //       // skipWhile((value) => !value),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe({
  //       next: (id) =>
  //         this.runQuery({
  //           account_ids: [id],
  //           // account_types: [],
  //           // account_types: [EUserAccountTypes.COMPANY],
  //         }),
  //     });
  // }

  // runQuery(runQueries: ICoreUserSearchQuery) {
  //   this._Store.dispatch(searchAllCompanyUsers({ query: runQueries }));
  // }

  // runQuery() {
  //   this._Store.dispatch(searchAllCompanyUsers({ query: { account_ids: [this.account_id]} }));
  // }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllCompanyUsersSorting({ querySort: sortDataEmitter }));
  }

  pageChange(event) {
    const pagination: IQueryPageination = {
      count: event.pageSize,
      limit: event.pageSize,
      skip: Number(event.pageIndex) * Number(event.pageSize),
      pageIndex: event.pageIndex,
      previousPageIndex: event.previousPageIndex,
    };
    this._Store.dispatch(updateAllCompanyUsersPagination({ pagination: pagination }));
  }

  accountSecurityRoles(accounts: ICoreUserAccount[], account_id: string) {
    if (accounts && account_id) {
      return accounts?.find((f) => f.account_id === account_id)?.security_roles.join(',');
    } else {
      //
      return '';
    }
  }

  removeUserFromCompany(account_id: string, user: ICoreUser) {
    const obj: IUserAccountsParamsDelete = {
      account: {
        _id: user.accounts.find((f) => f.account_id === account_id)._id,
        account_id,
      },
      user_id: user._id,
    };
    this._Store.dispatch(deleteCompanyOnUser({ data: obj }));
  }
}
