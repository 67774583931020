import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-engages',
  templateUrl: './engages.component.html',
  styleUrls: ['./engages.component.scss'],
})
export class EngagesComponent {
  // // constructor() {}
  // ngOnInit(): void {}
}
