import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FormControlCheckBoxesComponent } from './check-boxes/form-control-check-boxes.component';
import { FormControlDropDownComponent } from './ngb-dropdown/form-control-drop-down.component';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, InlineSVGModule, NgbModule];

const COMPONENTS = [FormControlDropDownComponent];

@NgModule({
  imports: [...MODULES],
  declarations: [...COMPONENTS],
  exports: [...MODULES, ...COMPONENTS],
  providers: [],
})
export class LaunchpointFormControlModule {}
