import { LaunchpointCoreWebActivityHistoryTableFilterComponent } from './activity-history-table-filter/activity-history-table-filter.component';
import { LaunchpointCoreWebActivityHistoryToolbarComponent } from './activity-history-toolbar/activity-history-toolbar.component';
import { LaunchpointCoreWebActivityHistoryDetailComponent } from './activity-history/activity-history.component';

export const ACTIVITY_HISTORY_COMPONENTS = [
  //
  LaunchpointCoreWebActivityHistoryToolbarComponent,
  LaunchpointCoreWebActivityHistoryDetailComponent,
  LaunchpointCoreWebActivityHistoryTableFilterComponent,
];

export * from './activity-history-table-filter/activity-history-table-filter.component';
export * from './activity-history-toolbar/activity-history-toolbar.component';
export * from './activity-history/activity-history.component';

// STAND ALONE
export * from './activity-history-table/activity-history-table.component';
