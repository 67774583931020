<ng-container *ngFor="let item of menuConfig?.items">
  <!-- begin::1 Level Link && ICON setup Assuming no submenu-->
  <!-- <ng-container
    *ngIf="item.external_url"
    [ngTemplateOutlet]="menu1LevelTemplateIconLinkExternal"
    [ngTemplateOutletContext]="{ item: item }"
  ></ng-container> -->
  <!-- begin::1 Level Link && ICON setup Assuming no submenu-->

  <!-- begin::1 Level -->
  <ng-container *ngIf="item.title && item.page && !item.external_url" [ngTemplateOutlet]="rootMenuTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
  <!-- end::1 Level -->
</ng-container>

<ng-template #rootMenuTemplate let-item="item">
  <ng-container *ngIf="item.security && item.title && !item.external_url">
    <ng-container *securityAccess="[item.security[0], item.security[1]]">
      <ng-container *ngIf="!item.security && item.title && !item.external_url" [ngTemplateOutlet]="menu1LevelTemplate"
        [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!item.security && item.title && !item.external_url" [ngTemplateOutlet]="menu1LevelTemplate"
    [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ng-template>

<!-- begin::1 Level -->
<ng-template #menu1LevelTemplate let-item="item">
  <ng-container *ngIf="!item.submenu">
    <div class="menu-item me-lg-1">
      <a class="menu-link py-3" routerLinkActive="active" [routerLink]="item.page"><span class="menu-title">{{
          item.title }}</span></a>
    </div>
  </ng-container>

  <ng-container *ngIf="item?.submenu">
    <div class="menu-item menu-lg-down-accordion me-lg-1 menu-dropdown" data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-start">
      <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass(item.page)"><span class="menu-title">{{
          item.title }}</span><span class="menu-arrow"></span></span>

      <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px"
        data-kt-menu-dismiss="true">
        <div class="menu-item menu-lg-down-accordion me-lg-1" data-kt-menu-trigger="{default:'click', lg: 'hover'}"
          data-kt-menu-placement="right-start">
          <!-- <ng-container
            *ngFor="let sub of item.submenu"
            [ngTemplateOutlet]="menu2LevelTemplate"
            [ngTemplateOutletContext]="{ item: sub }"
          ></ng-container> -->
          <ng-container *ngFor="let sub of item.submenu">
            <ng-container *ngIf="!sub.submenu">
              <div class="menu-item me-lg-1">
                <a class="menu-link py-3" routerLinkActive="active" [routerLink]="sub.page">
                  <span class="menu-icon">
                    <ng-container *ngIf="sub?.svg">
                      <span [inlineSVG]="sub.svg" class="svg-icon svg-icon-2"></span>
                    </ng-container>
                    <ng-container *ngIf="sub?.icon">
                      <i [class]="sub.icon"></i>
                    </ng-container>
                  </span>

                  <span class="menu-title" [attr.data-link]="sub.page">{{ sub.title }}</span></a>
              </div>
            </ng-container>

            <ng-container *ngIf="sub.submenu">
              <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass(sub.page)">
                <span class="menu-icon">
                  <ng-container *ngIf="item?.svg">
                    <span [inlineSVG]="sub.svg" class="svg-icon svg-icon-2"></span>
                  </ng-container>
                  <ng-container *ngIf="item?.icon">
                    <i [class]="sub.icon"></i>
                  </ng-container>
                </span>

                <span class="menu-title" [attr.data-link]="sub.page">{{ sub.title }}</span><span
                  class="menu-arrow"></span></span>

              <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px"
                data-kt-menu-dismiss="true">
                <ng-container *ngFor="let sub of item.submenu" [ngTemplateOutlet]="menu2LevelTemplate"
                  [ngTemplateOutletContext]="{ item: sub }">
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<!-- end::1 Level -->

<!-- begin::2 Level -->
<ng-template #menu2LevelTemplate let-item="item">
  <ng-container *ngIf="!item.submenu">
    <div class="menu-item me-lg-1">
      <a class="menu-link py-3" routerLinkActive="active" [routerLink]="item.page">
        <span class="menu-icon">
          <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
          <i *ngIf="item?.icon" [class]="item.icon"></i>
        </span>

        <span class="menu-title">{{ item.title }}</span></a>
    </div>
  </ng-container>

  <ng-container *ngIf="item.submenu">
    <span class="menu-link py-3" [ngClass]="calculateMenuItemCssClass(item.page)">
      <span class="menu-icon">
        <span *ngIf="item?.svg" [inlineSVG]="item.svg" class="svg-icon svg-icon-2"></span>
        <i *ngIf="item?.icon" [class]="item.icon"></i>
      </span>

      <span class="menu-title" [attr.data-link]="item.page">{{ item.title }}</span><span
        class="menu-arrow"></span></span>

    <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px"
      data-kt-menu-dismiss="true">
      <ng-container *ngFor="let sub of item.submenu" [ngTemplateOutlet]="menu2LevelTemplate"
        [ngTemplateOutletContext]="{ item: sub }">
      </ng-container>
    </div>
  </ng-container>
</ng-template>
<!-- end::2 Level -->