import { Observable } from 'rxjs';
import { INotification } from '../notifications.interface';
import {
  INotificationPropertyParamsCreate,
  INotificationPropertyParamsDelete,
  INotificationPropertyParamsUpdate,
} from './notifications-properties.interface';

/**
 * Controller('notifications/properties')
 */
export interface INotificationPropertyController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<INotification> | Observable<INotification>;
  /**
   * Post()
   * @param body
   */
  create(body: INotificationPropertyParamsCreate): Promise<INotification> | Observable<INotification>;
  /**
   * Put()
   * @param body
   */
  update(body: INotificationPropertyParamsUpdate): Promise<INotification> | Observable<INotification>;
  /**
   * Put('delete')
   * @param id
   */
  delete(body: INotificationPropertyParamsDelete): Promise<INotification> | Observable<INotification>;
}
