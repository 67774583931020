import {
  IHttpException,
  ILaunchpointNotificationAnalytics,
  INotificationMessage,
  INotificationMessageParamsCreate,
  INotificationMessageParamsUpdate,
  INotificationMessageSearchQuery,
  INotificationMessageSearchResults,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// getById
export const getMessageById = createAction('[LP-Notification-Messages] getMessageById', props<{ message_id: string }>());
export const getMessageByIdSuccess = createAction(
  '[LP-Messages-Messages] getMessagesByIdSuccess',
  props<{ message: INotificationMessage; success_message?: string }>()
);
export const getMessageByIdFailure = createAction(
  '[LP-Messages-Messages] getMessagesByIdFailure',
  props<{ message_id: string; error: IHttpException }>()
);

export const getMessageAnalytics = createAction('[LP-Notification-Messages] getMessageAnalytics');
export const getMessageAnalyticsSuccess = createAction(
  '[LP-Messages-Messages] getMessageAnalyticsSuccess',
  props<{ data: ILaunchpointNotificationAnalytics[]; success_message?: string }>()
);
export const getMessageAnalyticsFailure = createAction('[LP-Notification-Messages] getMessageAnalyticsFailure', props<{ error: IHttpException }>());

// Search
export const searchAllMessages = createAction('[LP-Notification-Messages] searchAllMessages');
export const searchAllMessagesSuccess = createAction(
  '[LP-Messages-Messages] searchAllMessagesSuccess',
  props<{ data: INotificationMessageSearchResults; success_message?: string }>()
);
export const searchAllMessagesFailure = createAction('[LP-Notification-Messages] searchAllMessagesFailure', props<{ error: IHttpException }>());

// CREATE
export const createMessage = createAction(
  '[LP-Notification-Messages] createMessage',
  props<{ data: INotificationMessageParamsCreate; redirectPath?: string[] }>()
);

export const createMessageSuccess = createAction(
  '[LP-Notification-Messages] createMessageSuccess',
  props<{ data: INotificationMessage; redirectPath?: string[]; success_message?: string }>()
);
export const createMessageFailure = createAction('[LP-Notification-Messages] createMessagesFailure', props<{ error: IHttpException }>());

// UPDATE
export const updateMessage = createAction('[LP-Notification-Messages] updateMessage', props<{ data: INotificationMessageParamsUpdate }>());
export const updateMessageSuccess = createAction(
  '[LP-Notification-Messages] updateMessageSuccess',
  props<{ message: INotificationMessage; success_message?: string }>()
);
export const updateMessageFailure = createAction(
  '[LP-Notification-Messages] updateMessageFailure',
  props<{ message_id: string; error: IHttpException }>()
);

// DELETE
export const deleteMessage = createAction('[LP-Messages-Messages] deleteMessage', props<{ id: string }>());
export const deleteMessageSuccess = createAction(
  '[LP-Notification-Messages] deleteMessageSuccess',
  props<{ data: INotificationMessage; success_message?: string }>()
);
export const deleteMessageFailure = createAction(
  '[LP-Notification-Messages] deleteMessageFailure',
  props<{ message_id: string; error: IHttpException }>()
);

// Mechanics
export const updateAllMessagesQuery = createAction(
  '[LP-Notification-Messages] updateAllMessagesQuery',
  props<{ query: INotificationMessageSearchQuery }>()
);
export const updateAllMessagesPagination = createAction(
  '[LP-Notification-Messages] updateAllMessagesPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllMessagesPaginationScroll = createAction(
  '[LP-Notification-Messages] updateAllMessagesPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllMessagesSorting = createAction('[LP-Notification-Messages] updateAllMessagesSorting', props<{ querySort: IQuerySort }>());
