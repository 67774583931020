import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserNotificationFeatureKey, UserNotificationEntityAdaptor, UserNotificationState } from '../reducers/user-notification.reducer';

const selectAllUserNotificationsState = createFeatureSelector<UserNotificationState>(UserNotificationFeatureKey);

/**
 * ROOT STATE
 */
export const getAllUserNotificationsState = createSelector(selectAllUserNotificationsState, (state: UserNotificationState) => state);

export const selectUserNotificationsPagination = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.pagination);
export const selectUserNotificationsQuerySort = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.querySort);
export const selectUserNotificationsQuery = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.query);
export const selectUserNotificationsSuccess = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.success_message);
export const selectUserNotificationsLoading = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.loading);
export const selectUserNotificationsLoaded = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.loaded);
export const selectUserNotificationsError = createSelector(getAllUserNotificationsState, (state: UserNotificationState) => state.error);

export const getAllUserNotificationssStateEntities = createSelector(
  selectAllUserNotificationsState,
  (state: UserNotificationState) => state.entities
);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = UserNotificationEntityAdaptor.getSelectors(selectAllUserNotificationsState);

// select the array of UserNotifications ids
export const selectUserNotificationsIds = selectIds;

// select the dictionary of UserNotifications entities
export const selectUserNotificationsEntities = selectEntities;

// select the array of UserNotificationss
export const selectUserNotifications = selectAll;

// select the total entity count
export const selectUserNotificationsTotal = selectTotal;

export const selectUserNotificationsId = createSelector(
  getAllUserNotificationsState,
  (state: UserNotificationState) => state?.selected_user_notification_id
);

export const selectSelectedUserNotification = createSelector(
  getAllUserNotificationsState,
  selectUserNotificationsId,
  (state: UserNotificationState, selected_user_notification_id) => state.entities[selected_user_notification_id]
);
export const selectSelectedUserNotificationLoading = createSelector(
  getAllUserNotificationsState,
  selectUserNotificationsId,
  (state: UserNotificationState, selected_user_notification_id) => state.entities[selected_user_notification_id]?.loading
);
export const selectSelectedUserNotificationSuccess = createSelector(
  getAllUserNotificationsState,
  selectUserNotificationsId,
  (state: UserNotificationState, selected_user_notification_id) => state.entities[selected_user_notification_id]?.success_message
);
export const selectSelectedUserNotificationError = createSelector(
  getAllUserNotificationsState,
  selectUserNotificationsId,
  (state: UserNotificationState, selected_user_notification_id) => state.entities[selected_user_notification_id]?.error
);
export const selectSelectedUserNotificationUnread = createSelector(
  getAllUserNotificationsState,
  selectUserNotificationsId,
  (state: UserNotificationState, selected_user_notification_id) => state.entities[selected_user_notification_id]?.total_unread
);
