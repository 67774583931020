import { Directive, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICoreUserParamsChangeEmail, ICoreUserParamsChangePassword } from '@launchpoint/core-types';
import { ControlsOf } from '../../../../../../forms/from-control/form-control-convert.interface';
import { ConfirmEmailValidator, ConfirmPasswordValidator } from '../../../../../../forms/validators';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../../../../auth/+state/auth-base.component';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../../../../auth/interfaces/auth-storage-service.interface';

export type ICoreUserParamsChangePasswordNOUserId = Omit<ICoreUserParamsChangePassword, 'user_id'>;
@Directive()
export class LaunchpointCoreClientUpdateEmailPasswordBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  passwordForm: FormGroup<ControlsOf<ICoreUserParamsChangePassword>>;
  emailForm: FormGroup<ControlsOf<ICoreUserParamsChangeEmail>>;
  changeEmailPasswordMainCard = true;
  changeEmailCard = false;
  changePasswordCard = false;
  success;

  @Input() admin = false;
  @Input() user_email: string;
  @Input() text_class = '';
  @Input() showSeparators = true;

  @Output() updatedEmail = new EventEmitter<ICoreUserParamsChangeEmail>();
  @Output() updatedPassword = new EventEmitter<ICoreUserParamsChangePasswordNOUserId>();

  constructor(
    @Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig,
    public fb: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    if (this.admin) {
      // EMAIL FORM ----------
      this.emailForm = this.fb.group(
        {
          new_email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
          confirm_new_email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
        },
        {
          validator: ConfirmEmailValidator.MatchEmail,
        }
      );

      // PASSWORD FORM --------
      this.passwordForm = this.fb.group(
        {
          new_password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])],
          confirm_new_password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])],
        },
        {
          validator: ConfirmPasswordValidator.MatchUpdatePassword,
        }
      );
    } else {
      // EMAIL FORM ----------
      this.emailForm = this.fb.group(
        {
          new_email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
          confirm_new_email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(320)])],
          password: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(100)])],
        },
        {
          validator: ConfirmEmailValidator.MatchEmail,
        }
      );

      // PASSWORD FORM --------
      this.passwordForm = this.fb.group(
        {
          // current_password: ['', Validators.required],
          new_password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])],
          confirm_new_password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(100)])],
        },
        {
          validator: ConfirmPasswordValidator.MatchUpdatePassword,
        }
      );
    }
  }

  updateEmail() {
    const newEmail: ICoreUserParamsChangeEmail = {
      new_email: this.emailForm.controls.new_email.value,
      confirm_new_email: this.emailForm.controls.confirm_new_email.value,
      password: !this.admin ? this.emailForm.controls.password.value : null,
    };
    return this.updatedEmail.emit(newEmail);
  }

  updatePassword() {
    const newPassword: ICoreUserParamsChangePasswordNOUserId = {
      // current_password: !this.admin ? this.passwordForm.controls.current_password.value : null,
      new_password: this.passwordForm.controls.new_password.value,
      confirm_new_password: this.passwordForm.controls.confirm_new_password.value,
    };
    return this.updatedPassword.emit(newPassword);
  }

  showPassword(id: string, event) {
    const x = document.getElementById(id) as HTMLElement;
    event.target.classList.contains('fa-eye')
      ? event.target.classList.replace('fa-eye', 'fa-eye-slash')
      : event.target.classList.replace('fa-eye-slash', 'fa-eye');
    event.target.classList.contains('fas') && x['type'] === 'password' ? (x['type'] = 'text') : (x['type'] = 'password');
  }

  cancelEmailPassword() {
    this.changeEmailPasswordMainCard = true;
    this.changeEmailCard = false;
    this.changePasswordCard = false;
    this.passwordForm.reset();
    this.emailForm.reset();
  }

  showChangeEmailForm() {
    this.changeEmailPasswordMainCard = false;
    this.changeEmailCard = true;
    this.changePasswordCard = false;
  }

  showChangePasswordForm() {
    this.changeEmailPasswordMainCard = false;
    this.changeEmailCard = false;
    this.changePasswordCard = true;
  }
}
