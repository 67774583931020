<div
  class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
  [style.background-image]="'url(' + _config.background_image + ')'"
>
  <div class="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
    <a (click)="routeToDashboard()" class="mb-10 pt-lg-20 cursor-pointer">
      <img alt="Logo" [src]="_config.logo" class="h-50px mb-5" />
    </a>
    <div class="pt-lg-10">
      <router-outlet></router-outlet>

      <div class="text-center">
        <a (click)="routeToDashboard()" class="btn btn-lg btn-primary fw-bolder cursor-pointer"> {{ _config.return_route_button_title }} </a>
      </div>
    </div>
  </div>

  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="d-flex align-items-center fw-bold fs-6">
      <a *ngFor="let item of _config.footers" [href]="item.href" class="text-muted text-hover-primary px-2"> {{ item.title }} </a>
    </div>
  </div>
</div>
