import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import * as ActivityHistoryActions from '../actions/activity-history.actions';
import * as ActivityHistorySelectors from '../selectors/activity-history.selectors';

@Injectable()
export class GetSelectedActivityHistoryGuard implements CanActivate {
  constructor(
    private _Store: Store,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.setSelectedId(route.params.id).pipe(
      switchMap((loaded) => this.selectIdFromState(route.params.id)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          ActivityHistoryActions.getActivityHistoryByIdFailure({
            history_id: route.params.id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }

  setSelectedId(ah_id: string) {
    if (!ah_id) {
      return of(false);
    }
    return this._Store.select(ActivityHistorySelectors.selectActivityHistoryId).pipe(
      switchMap((selected) => {
        if (selected !== ah_id) {
          this._Store.dispatch(ActivityHistoryActions.getActivityHistoryById({ history_id: ah_id }));
          return of(false);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  selectIdFromState(ah_id: string) {
    if (!ah_id) {
      return of(false);
    }
    return this._Store.select(ActivityHistorySelectors.selectActivityHistoryEntities).pipe(
      switchMap((entities) => {
        if (!entities[ah_id]) {
          return this.dispatchFindById(ah_id);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  dispatchFindById(ah_id: string): Observable<boolean> {
    if (!ah_id) {
      return of(false);
    }
    this._Store.dispatch(ActivityHistoryActions.getActivityHistoryById({ history_id: ah_id }));
    return of(false);
  }
}
