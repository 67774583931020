import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { configureURL, ICoreCompany, ICoreCompanyAccountOwnerController, ICoreCompanyAccountOwnerParamsUpdate } from '@launchpoint/core-types';
import { Observable } from 'rxjs';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../../user/auth/interfaces/auth-storage-service.interface';

@Injectable()
export class CompanyAccountOwnerV2Service implements ICoreCompanyAccountOwnerController {
  API_URL = '/api/backend/';

  routes = {
    update: 'company-account-owner',
  };

  constructor(
    private http: HttpClient,
    @Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig
  ) {
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 2,
    });

    if (_CoreAuthConfig?.routes) {
      for (const key in _CoreAuthConfig.routes) {
        if (Object.prototype.hasOwnProperty.call(_CoreAuthConfig.routes, key)) {
          if (this.routes[key]) {
            this.routes[key] = _CoreAuthConfig.routes[key];
          } else {
            console.error('The route you are trying to set does not exist in the service. Please check your version if there have been updates');
          }
        }
      }
    }
  }

  public update(params: ICoreCompanyAccountOwnerParamsUpdate): Observable<ICoreCompany> {
    return this.http.put<ICoreCompany>(this.API_URL + this.routes.update, params);
  }
}
