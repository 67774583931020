<!-- begin::Header -->
<div class="card-header align-items-center border-0 mt-4">
  <h3 class="card-title align-items-start flex-column">
    <span class="fw-bolder text-dark">Latest Media</span>
    <span class="text-muted mt-1 fw-bold fs-7">Articles and publications</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'" class="svg-icon svg-icon-2"></span>
    </button>
    <launchpoint-app-dropdown-menu1></launchpoint-app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body pt-3">
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div class="symbol-label" [style.background-image]="
          'url(./assets/media/stock/600x400/img-20.jpg)'
        "></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Title -->
    <div class="d-flex flex-row-fluid flex-wrap align-items-center">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Cup &amp; Green
        </a>
        <span class="text-muted fw-bold d-block pt-1">Size: 87KB</span>
      </div>
      <span class="badge badge-light-success fs-8 fw-bolder my-2">Approved</span>
    </div>
    <!-- end::Title -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div class="symbol-label" [style.background-image]="
          'url(./assets/media/stock/600x400/img-19.jpg)'
        "></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Title -->
    <div class="d-flex flex-row-fluid flex-wrap align-items-center">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Yellow Background
        </a>
        <span class="text-muted fw-bold d-block pt-1">Size: 1.2MB</span>
      </div>
      <span class="badge badge-light-warning fs-8 fw-bolder my-2">In Progress</span>
    </div>
    <!-- end::Title -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center mb-7">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div class="symbol-label" [style.background-image]="
          'url(./assets/media/stock/600x400/img-25.jpg)'
        "></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Title -->
    <div class="d-flex flex-row-fluid flex-wrap align-items-center">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Nike &amp; Blue
        </a>
        <span class="text-muted fw-bold d-block pt-1">Size: 87KB</span>
      </div>
      <span class="badge badge-light-success fs-8 fw-bolder my-2">Success</span>
    </div>
    <!-- end::Title -->
  </div>
  <!-- end::Item -->
  <!-- begin::Item -->
  <div class="d-flex align-items-sm-center">
    <!-- begin::Symbol -->
    <div class="symbol symbol-60px symbol-2by3 me-4">
      <div class="symbol-label" [style.background-image]="
          'url(./assets/media/stock/600x400/img-24.jpg)'
        "></div>
    </div>
    <!-- end::Symbol -->
    <!-- begin::Title -->
    <div class="d-flex flex-row-fluid flex-wrap align-items-center">
      <div class="flex-grow-1 me-2">
        <a href="#" class="text-gray-800 fw-bolder text-hover-primary fs-6">
          Red Boots
        </a>
        <span class="text-muted fw-bold d-block pt-1">Size: 345KB</span>
      </div>
      <span class="badge badge-light-danger fs-8 fw-bolder my-2">Rejected</span>
    </div>
    <!-- end::Title -->
  </div>
  <!-- end::Item -->
</div>
<!-- end::Body -->