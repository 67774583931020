import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'sd-success-stories',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './success-stories.component.html',
  styleUrl: './success-stories.component.scss',
})
export class StadnickSuccessStoriesComponent {
  @Input() title = 'SUCCESS STORIES';
  @Input() subtitle;
  @Input() showScroll = false;
  @Input() type: 'light' | 'medium' | 'dark' = 'light';

  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  scrollByAmount = 200;

  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: -this.scrollByAmount, behavior: 'smooth' });
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    container.scrollBy({ left: this.scrollByAmount, behavior: 'smooth' });
  }

  stories: {
    title: string;
    date: Date;
    img: string;
    href?: string;
  }[] = [
    {
      title: 'Transforming a Local Event Business with Event and Marketing Stack',
      date: new Date('2024-02-21'),
      img: 'assets/img/success-stories/computer.png',
    },
    {
      title: 'Revamping an Insurance Firm’s Tech Infrastructure With AI',
      date: new Date('2018-06-30'),
      img: 'assets/img/success-stories/tv.png',
    },
    {
      title: 'Helping 20 Veteran Businesses This Year',
      date: new Date('2024-11-21'),
      img: 'assets/img/success-stories/ropes.png',
    },
  ];
}
