import { Component, inject } from '@angular/core';
import { BlogsActions, LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { ICoreBlogSeoParamsUpdate, ITagifyTag } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-blog-seo',
  templateUrl: './blog-seo.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogSEOComponent extends LaunchpointCoreClientBlogBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);
  _tagifyService = inject(TagifyService);

  form: FormGroup<{
    title: FormControl<string>;
    description: FormControl<string>;
    keywords: FormControl<ITagifyTag[]>;
    canonical_url: FormControl<string>;
    author: FormControl<string>;
    theme_color: FormControl<string>;
    ms_tile_color: FormControl<string>;
    language: FormControl<string>;

    // og
    og: FormGroup<{
      title: FormControl<string>;
      description: FormControl<string>;
      image: FormControl<string>;
      url: FormControl<string>;
      type: FormControl<string>;
      site_name: FormControl<string>;
      locale: FormControl<string>;
    }>;

    // twitter
    twitter: FormGroup<{
      card: FormControl<string>;
      site: FormControl<string>;
      title: FormControl<string>;
      description: FormControl<string>;
      image: FormControl<string>;
    }>;
  }>;

  public settings: TagifySettings = {
    placeholder: 'Type any keywords',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  override buildForm(): void {
    this.form = this.fb.group({
      title: [],
      description: [],
      keywords: [],
      canonical_url: [],
      author: [],
      theme_color: [],
      ms_tile_color: [],
      language: [],
      og: this.fb.group({
        title: [],
        description: [],
        image: [],
        url: [
          '',
          // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
          Validators.pattern(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          ),
        ],
        type: [],
        site_name: [],
        locale: [],
      }),
      twitter: this.fb.group({
        card: [],
        site: [],
        title: [],
        description: [],
        image: [],
      }),
    });
  }

  override loadForm(): void {
    const keywords = this.blog?.seo?.keywords?.split(',')?.map((keyword) => ({ value: keyword })) ?? [];

    this.form.patchValue({
      ...this.blog?.seo,
      keywords,
    });

    setTimeout(() => {
      this._tagifyService.get('keywords').loadOriginalValues(keywords);
    }, 100);
  }

  reset() {
    this.form.reset();
    this.loadForm();
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    const data: ICoreBlogSeoParamsUpdate = {
      _id: this.blog._id,
      ...form,
      keywords: form.keywords?.map((keyword) => keyword?.value)?.join(',') ?? '',
    };

    this.removeNullUndefined(data);
    // console.log('data', data);

    this._Store.dispatch(BlogsActions.updateBlogSEO({ data }));
  }
}
