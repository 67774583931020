import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebhookEventsEntityAdaptor, WebhookEventsFeatureKey, StateWebhookEventsReducer } from '../reducers/webhook-events.reducer';

const selectAllWebhookEventsState = createFeatureSelector<StateWebhookEventsReducer>(WebhookEventsFeatureKey);

/**
 * ROOT STATE
 */
export const getAllWebhookEventsState = createSelector(selectAllWebhookEventsState, (state: StateWebhookEventsReducer) => state);

export const selectWebhookEventsPagination = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.pagination);
export const selectWebhookEventsQuerySort = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.querySort);
export const selectWebhookEventsQuery = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.query);
export const selectWebhookEventsSuccess = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.success_message);
export const selectWebhookEventsLoading = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.loading);
export const selectWebhookEventsLoaded = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.loaded);
export const selectWebhookEventsError = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.error);

export const getAllWebhookEventsStateEntities = createSelector(selectAllWebhookEventsState, (state: StateWebhookEventsReducer) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = WebhookEventsEntityAdaptor.getSelectors(selectAllWebhookEventsState);

// select the array of user ids
export const selectWebhookEventsIds = selectIds;

// select the dictionary of user entities
export const selectWebhookEventsEntities = selectEntities;

// select the array of users
export const selectWebhookEvents = selectAll;

// select the total entity count
export const selectWebhookEventsTotal = selectTotal;

export const selectWebhookEventsId = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => state?.selected_event_id);

export const selectSelectedWebhookEvents = createSelector(
  getAllWebhookEventsState,
  selectWebhookEventsId,
  (state: StateWebhookEventsReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.webhook_event
);

export const selectSelectedWebhookEventsLoading = createSelector(
  getAllWebhookEventsState,
  selectWebhookEventsId,
  (state: StateWebhookEventsReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.loading
);

export const selectSelectedWebhookEventsError = createSelector(
  getAllWebhookEventsState,
  selectWebhookEventsId,
  (state: StateWebhookEventsReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.error
);

export const selectSelectedWebhookEventsSuccess = createSelector(
  getAllWebhookEventsState,
  selectWebhookEventsId,
  (state: StateWebhookEventsReducer, selected_webhook_id) => state.entities[selected_webhook_id]?.success_message
);

export const selectWebhookEventsSuccessQueryCount = createSelector(getAllWebhookEventsState, (state: StateWebhookEventsReducer) => {
  let count = 0;
  try {
    count += state?.query?.account_ids?.length ?? 0;
    count += state?.query?.history_ids?.length ?? 0;

    count += state?.query?.created_at ? 1 : 0;
    count += state?.query?.created_at ? 1 : 0;
    count += state?.query?.processed_at ? 1 : 0;
    count += state?.query?.finished_at ? 1 : 0;
  } catch (error) {
    // console.log(error);
  }
  return count;
});
