import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'sd-purpose',
  templateUrl: './purpose.component.html',
  styleUrl: './purpose.component.scss',
})
export class PurposeComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;

  ngOnInit() {
    this.screenSize = window.innerWidth >= 768 ? 'desktop' : 'mobile';
    window.addEventListener('resize', () => {
      this.screenSize = window.innerWidth >= 768 ? 'desktop' : 'mobile';
    });
  }

  scrollByAmountDesktop = 700;
  scrollByAmountMobile = 280;
  screenSize: 'desktop' | 'mobile' = 'desktop';

  scrollLeft() {
    const container = this.scrollContainer.nativeElement;
    const scrollByAmount = this.screenSize === 'desktop' ? this.scrollByAmountDesktop : this.scrollByAmountMobile;
    container.scrollBy({ left: -scrollByAmount, behavior: 'smooth' });
  }

  scrollRight() {
    const container = this.scrollContainer.nativeElement;
    const scrollByAmount = this.screenSize === 'desktop' ? this.scrollByAmountDesktop : this.scrollByAmountMobile;
    container.scrollBy({ left: scrollByAmount, behavior: 'smooth' });
  }

  purposeData = [
    {
      title: 'Business',
      description: 'Business is where I bring my skills and values together.',
      image: './assets/img/purpose/rob-stadnick-business.png',
    },
    {
      title: 'Family',
      description: "Family is why I work hard, and it's the cornerstone of everything I do.",
      image: './assets/img/purpose/2.png',
    },
    {
      title: 'Cars',
      description: 'Cars are a passion of precision and engineering, just like great tech.',
      image: './assets/img/purpose/rob-stadnick-296gtb.png',
    },
    // {
    //   title: 'Faith',
    //   description: 'Living my life under a sprit filled authority, giving purpose to everything.',
    //   image: './assets/img/purpose/2.png',
    // },
    {
      title: 'Fitness',
      description: 'Train your body to push the limits, and you will be prepared for any challenge',
      image: './assets/img/purpose/rob-stadnick-fitness.jpg',
    },
  ];
}
