import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { LaunchpointCoreClientAuthLoginBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-auth-v1-login',
  templateUrl: './auth-v1-login.component.html',
  styleUrls: ['./auth-v1-login.component.scss'],
})
export class LaunchpointCoreWebAuthV1LoginComponent extends LaunchpointCoreClientAuthLoginBaseComponent {
  @ViewChild('password', { static: false }) password: ElementRef;
  pVisible = false;

  togglePass() {
    this.pVisible = !this.pVisible;
  }

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }

  confirm() {
    if (!this._config.registration.require_password) {
      this.sendLoginCode();
      return;
    }

    this.confirmEmail = true;
    if (this.confirmEmail) {
      setTimeout(() => {
        this.password?.nativeElement?.focus();
      }, 50);
    }
  }
}
