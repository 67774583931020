import { IHttpException, IQueryPageination, IQuerySort, ICoreCompanySearchQuery } from '@launchpoint/core-types';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LaunchpointCompanyEntityState } from '../interfaces';

export const LaunchpointCompanyFeatureKey = 'LaunchpointCompanyReducer';

export interface LaunchpointCompanyState extends EntityState<LaunchpointCompanyEntityState> {
  selected_company_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreCompanySearchQuery;
}

export const LaunchpointCompanyEntityAdaptor: EntityAdapter<LaunchpointCompanyEntityState> = createEntityAdapter<LaunchpointCompanyEntityState>({
  selectId: (company) => company.company_id,
});

export const initialCompanyState: LaunchpointCompanyState = LaunchpointCompanyEntityAdaptor.getInitialState({
  // set initial required properties
  selected_company_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
});
