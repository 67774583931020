import { Directive, inject, Input } from '@angular/core';
import {
  ELaunchpointUserRoles,
  ICoreAlertBanner,
  ICoreAlertBannerParamsCreate,
  ICoreAlertBannerParamsUpdate,
  ICoreAlertBannerSearchQuery,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom, Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { AlertBannerAction } from './actions';
import { AlertBannerEntityState } from './interfaces/alert-banner.interface';
import {
  selectAlertBanner,
  selectAlertBannerError,
  selectAlertBannerLoading,
  selectAlertBannerPagination,
  selectAlertBannerQuery,
  selectAlertBannerQuerySort,
  selectAlertBannerSuccess,
  selectAlertBannerSuccessQueryCount,
  selectSelectedAlertBanner,
  selectSelectedAlertBannerError,
  selectSelectedAlertBannerLoading,
  selectSelectedAlertBannerSuccess,
} from './selectors/alert-banner.selectors';
import { ALERT_BANNER_CLIENT_CONFIG_TOKEN, LaunchpointCoreClientAlertBannerConfig } from '../interface/interface';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  public _Store = inject(Store);
  public _config: LaunchpointCoreClientAlertBannerConfig = inject(ALERT_BANNER_CLIENT_CONFIG_TOKEN);

  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreAlertBannerSearchQuery>;
  queryFilterCount$: Observable<number>;

  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;

  bannerEntities$: Observable<AlertBannerEntityState[]>;

  banner$: Observable<ICoreAlertBanner>;
  bannerLoading$: Observable<boolean>;
  bannerError$: Observable<IHttpException>;
  bannerSuccess$: Observable<string>;

  whitelistSecurityRoles$ = new BehaviorSubject<string[]>(null);

  BANNER_TEXT_COLORS = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'light',
    'dark',
    'white',
    'light-primary',
    'light-secondary',
    'light-success',
    'light-danger',
    'light-warning',
  ];

  admin = false;

  constructor() {
    super();
    this.loading$ = this._Store.select(selectAlertBannerLoading);
    this.pagination$ = this._Store.select(selectAlertBannerPagination);
    this.querySort$ = this._Store.select(selectAlertBannerQuerySort);
    this.query$ = this._Store.select(selectAlertBannerQuery);
    this.queryFilterCount$ = this._Store.select(selectAlertBannerSuccessQueryCount);

    this.error$ = this._Store.select(selectAlertBannerError);
    this.success$ = this._Store.select(selectAlertBannerSuccess);

    this.bannerEntities$ = this._Store.select(selectAlertBanner);

    // Entity
    this.banner$ = this._Store.select(selectSelectedAlertBanner);
    this.bannerLoading$ = this._Store.select(selectSelectedAlertBannerLoading);
    this.bannerError$ = this._Store.select(selectSelectedAlertBannerError);
    this.bannerSuccess$ = this._Store.select(selectSelectedAlertBannerSuccess);

    this.whitelistSecurityRoles$ = new BehaviorSubject<string[]>(Object.values(this._config?.security_roles ?? ELaunchpointUserRoles));

    this.admin = this._config?.admin_controls ?? false;
  }

  create(data: ICoreAlertBannerParamsCreate) {
    this._Store.dispatch(AlertBannerAction.createAlertBanner({ data }));
  }
  update(data: ICoreAlertBannerParamsUpdate) {
    this._Store.dispatch(AlertBannerAction.updateAlertBanner({ data }));
  }
  deleteOne(banner_id: string) {
    this._Store.dispatch(AlertBannerAction.deleteAlertBanner({ banner_id }));
  }
  updateQuery(query: ICoreAlertBannerSearchQuery) {
    this._Store.dispatch(AlertBannerAction.updateAllAlertBannerQuery({ query }));
  }

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);

    if (pagination.pageIndex === page - 1) {
      return;
    }

    this._Store.dispatch(
      AlertBannerAction.updateAllAlertBannerPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);

    this._Store.dispatch(AlertBannerAction.updateAllAlertBannerPagination({ pagination: { ...pagination, limit } }));
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
    this.bannerSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
    this.bannerError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
  }
  toast(params) {
    //
  }
}
