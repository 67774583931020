import { InjectionToken } from '@angular/core';

export interface LaunchpointCoreClientNotificationConfig {
  base_url: string;
  /**
   * events to filter for in user_notifications
   */
  events: string[];
}

export const NOTIFICATION_CONFIG_TOKEN = new InjectionToken<LaunchpointCoreClientNotificationConfig>('NOTIFICATION_CONFIG_TOKEN');
