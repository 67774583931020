import { IHttpException, IUserNotification } from '@launchpoint/core-types';

export interface UserNotificationEntityState {
  user_notification_id: string;
  user_notification: IUserNotification;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  user_notification_config?: any;
  success_message?: string;
  total_unread?: number;
}
