import { Component, Input, OnInit } from '@angular/core';
import { LaunchpointLayoutFooters } from '../../../../interfaces/footer.interface';
import { LayoutService } from '../../../core/layout.service';

@Component({
  selector: 'launchpoint-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class LaunchpointLayoutFooterComponent implements OnInit {
  footerContainerCssClasses = '';
  currentDateStr: string = new Date().getFullYear().toString();

  @Input() footerConfig: LaunchpointLayoutFooters;
  constructor(private layout: LayoutService) {}

  ngOnInit(): void {
    this.footerContainerCssClasses = this.layout.getStringCSSClasses('footerContainer');
  }
}
