import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LaunchpointCircleInitialsComponent } from './circle-initials.component';

@NgModule({
  declarations: [LaunchpointCircleInitialsComponent],
  imports: [CommonModule],
  exports: [LaunchpointCircleInitialsComponent],
})
export class LaunchpointCircleInitialsModule {}
