import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from '../actions/router.actions';
import { RouterToken } from '../../../../tokens';

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    @Inject(RouterToken)
    private _router: any,
    private _ngRouter: Router,
    private location: Location
  ) {}

  navigate$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Go),
        // map((action) => {
        //   return action.payload;
        // }),
        tap({
          next: (action) => {
            const { path, query: queryParams, extras } = action.payload;
            if (!this._router) {
              // on web we just rely on injecting @angular/router
              // on mobile, this will be defined as RouterExtensions
              this._router = this._ngRouter;
            }
            this._router.navigate(path, { queryParams, queryParamsHandling: 'preserve', ...extras });
          },
        })
      );
    },
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Back),
        tap({
          next: () => {
            this.location.back();
          },
        })
      );
    },
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RouterActions.Forward),
        tap({
          next: () => {
            this.location.forward();
          },
        })
      );
    },
    { dispatch: false }
  );
}
