<div class="app-content flex-column-fluid container-xxl">
  <div class="app-container container-fluid">
    <launchpoint-blog-header />

    <div class="app-content flex-column-fluid container-xxl">
      <div class="app-container container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
