import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IQueryPageination } from '@launchpoint/core-types';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule],
})
export class LaunchpointCoreWebPaginationComponent {
  @Input() debug = false;
  @Input() pagination: IQueryPageination;
  @Input() pageOptions: number[] = [5, 10, 25, 50, 75, 100];

  @Output() paginate = new EventEmitter<number>();
  @Output() limit = new EventEmitter<number>();

  setPage(page: number) {
    this.paginate.emit(page);
  }

  setLimit(limit: number) {
    this.limit.emit(limit);
  }
}
