import { Component, HostBinding, inject } from '@angular/core';
import { LaunchpointCoreClientAuthVerifyBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-auth-v2-verify',
  templateUrl: './auth-v2-verify.component.html',
  styleUrls: ['./auth-v2-verify.component.scss'],
})
export class LaunchpointCoreWebAuthV2VerifyComponent extends LaunchpointCoreClientAuthVerifyBaseComponent {
  _swalService = inject(LaunchpointSweetAlertService);

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  method() {
    //
  }

  resend() {
    //
  }

  paste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');

    this.form.controls.token.setValue(pastedText);

    if (this._config.verify?.auto_submit ?? false) {
      this.submit();
    }
  }

  enter(event: string) {
    this.form.controls.token.setValue(event);

    this.submit();
  }

  changed(value: string) {
    this.form.controls.token.setValue(value);
  }

  override toast(params) {
    this._swalService.fireToast(params);
  }
}
