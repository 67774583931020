import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfileSwapActions, UserSelectors, LaunchpointCoreClientUsersStateBaseComponent, UsersEntityState } from '@launchpoint/core-client';
import { ICoreUser, ICoreUserAccountsParamsAddMainUserAccount, ICoreUserAccountsParamsAddSubUserAccount } from '@launchpoint/core-types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'launchpoint-accounts-add-smart-admin',
  templateUrl: './accounts-add-smart-admin.component.html',
  styleUrls: ['./accounts-add-smart-admin.component.scss'],
})
//extend the base
export class LaunchpointCoreWebAccountsAddSmartAdminComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit{
  form: UntypedFormGroup;
  selected_user: UsersEntityState;
  selected_user$: Observable<UsersEntityState>;
  form_type: 'child' | 'link' | 'new' | 'adult' | null = 'child';

  //   public form: FormGroup<ControlsOf<IUserAccountsParamsInvite>>;

  constructor(public fb: UntypedFormBuilder, public activeModal: NgbActiveModal, public _Store:Store) {
    super(_Store);

    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      birthday: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.selected_user$.subscribe((user_admin_picked) => {
      this.selected_user = user_admin_picked;
    });
  }


  addAccount() {
    const formData = this.form.value;

    const payload_child: ICoreUserAccountsParamsAddSubUserAccount = {
      requesting_user_id: this.selected_user.user_id,// update to be user the admin is viewing
      account_name: formData.firstName + ' ' + formData.lastName,
      admin: {
        electronic_consent: true,
        electronic_consent_ts: new Date(),
      },
      profile: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        full_name: formData.firstName + ' ' + formData.lastName,
        birthday: new Date(formData.birthday.year, formData.birthday.month, formData.birthday.day),
      },

      account_type: this.form_type === 'child' ? 'child' : 'adult',
    };
    const payload_adult: ICoreUserAccountsParamsAddMainUserAccount = {
      requesting_user_id: this.selected_user.user_id,
      account_name: formData.firstName + ' ' + formData.lastName,
      profile: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        full_name: formData.firstName + ' ' + formData.lastName,
        birthday: new Date(formData.birthday.year, formData.birthday.month, formData.birthday.day),
      },
      account_type: this.form_type === 'child' ? 'child' : 'adult',
      user_to_add_email: formData.email,
    };

    if (this.form.valid) {
      if (this.form_type === 'child') {
        this._Store.dispatch(UserProfileSwapActions.addSubUserAccount({ payload: payload_child }));
        this.close()
      } else {

        this._Store.dispatch(UserProfileSwapActions.addMainUserAccount({ payload: payload_adult }));
        this.close()
      }
    }
  }
  close() {
    // dispatch close modal or just close it?
    this.activeModal.close({ confirm: false, data: null });
  }
}
