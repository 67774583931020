import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, timeout } from 'rxjs/operators';
import * as WebhookEventActions from '../actions/webhook-events.actions';
import * as WebhooksSelectors from '../selectors/webhooks.selectors';

@Injectable()
export class GetSelectedWebhookEventGuard implements CanActivate {
  constructor(private _Store: Store, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(route.params);
    let id = route.params.id;

    if (route.params?.event_id) {
      id = route.params.event_id;
    }

    // console.log(id);

    return this.setSelectedWebhookEventId(id).pipe(
      switchMap((loaded) => this.selectedWebhookEventInState(id)),
      timeout(30000),
      catchError((error) => {
        console.error(error);
        this._router.navigate(['']);
        this._Store.dispatch(
          WebhookEventActions.getWebhookEventByIdFailure({
            event_id: id,
            error: { message: 'We ran into an error, please try again later', statusCode: '500', error },
          })
        );
        return of(error);
      })
    );
  }

  setSelectedWebhookEventId(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(WebhooksSelectors.selectWebhookEventsId).pipe(
      switchMap((selected) => {
        if (selected !== _id) {
          this._Store.dispatch(WebhookEventActions.getWebhookEventById({ event_id: _id }));
          return of(false);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  selectedWebhookEventInState(_id: string) {
    if (!_id) {
      return of(false);
    }
    return this._Store.select(WebhooksSelectors.selectWebhookEventsEntities).pipe(
      switchMap((entities) => {
        if (!entities[_id]) {
          return this.dispatchWebhookEvent(_id);
        }
        return of(true);
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }

  dispatchWebhookEvent(_id: string): Observable<boolean> {
    if (!_id) {
      return of(false);
    }
    this._Store.dispatch(WebhookEventActions.getWebhookEventById({ event_id: _id }));
    return of(false);
  }
}
