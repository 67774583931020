import { Component, inject, OnInit } from '@angular/core';
import { LaunchpointCoreClientWebhookModalBaseComponent } from '@launchpoint/core-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagifyService } from 'ngx-tagify';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-webhook-modal',
  templateUrl: 'webhook-modal.component.html',
})
export class LaunchpointCoreWebWebhookModalComponent extends LaunchpointCoreClientWebhookModalBaseComponent implements OnInit {
  _swalService = inject(LaunchpointSweetAlertService);
  _tagifyService = inject(TagifyService);
  _activeModal = inject(NgbActiveModal);

  ngOnInit(): void {
    super.ngOnInit();

    if (this.webhook) {
      const events = this.webhook.events.map((event) => ({ value: event, data: event }));
      if (events.length) {
        this.form.controls.events.setValue(events);
        setTimeout(() => {
          this._tagifyService.get('events')?.loadOriginalValues(events);
        }, 50);
      }
    }
    this.settings.enforceWhitelist = true;
  }

  change(type: 'live' | 'test') {
    this.form.controls.mode.setValue(type);
  }

  override close() {
    this._activeModal.close();
  }
}
