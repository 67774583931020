<div class="card-header border-0 pt-5">
  <!-- begin::Title-->
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Recent Statistics</span>

    <span class="text-muted fw-bold fs-7">More than 400 new members</span>
  </h3>
  <!-- end::Title-->

  <!-- begin::Toolbar-->
  <div class="card-toolbar">
    <!-- begin::Menu-->
    <button type="button" class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-flip="top-end">
      <span [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'" class="svg-icon svg-icon-2"></span>
    </button>
    <launchpoint-app-dropdown-menu1></launchpoint-app-dropdown-menu1>

    <!-- end::Menu-->
  </div>
  <!-- end::Toolbar-->
</div>
<!-- end::Header-->

<!-- begin::Body-->
<div class="card-body">
  <!-- begin::Chart-->
  <div #chartRef id="kt_charts_widget_1_chart" [style.height]="'350px'">
    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [stroke]="chartOptions.stroke"
      [legend]="chartOptions.legend" [fill]="chartOptions.fill" [states]="chartOptions.states"
      [tooltip]="chartOptions.tooltip" [colors]="chartOptions.colors" [markers]="chartOptions.markers"
      [plotOptions]="chartOptions.plotOptions"></apx-chart>
  </div>
  <!-- end::Chart-->
</div>