import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointBlogCommentRepliesHTTPServicePublic, LaunchpointBlogCommentsHTTPServicePublic } from '@launchpoint/core-client';
import { LaunchpointSEORouterService, ManyChatSubscribeService } from '@launchpoint/xplat/web/features';
import { StadnickButtonComponent } from '../../components/button/button.component';
import { BlogPageComponent } from './blog.component';
import { BlogRoutingModule } from './blog.routing';
import { BLOG_COMPONENTS } from './components';
import { LaunchpointBlogService } from './data/blog.service';
import { BLOG_PAGE_COMPONENTS } from './pages';
import { StadnickNewsletterComponent } from '../../components/newsletter/newsletter.component';
import { BlogHTTPService } from '../../services/blog-http.service';

const STANDALONE_COMPONENTS = [StadnickButtonComponent, StadnickNewsletterComponent];

@NgModule({
  imports: [CommonModule, BlogRoutingModule, NgOptimizedImage, FormsModule, ReactiveFormsModule, ...STANDALONE_COMPONENTS],
  declarations: [BlogPageComponent, ...BLOG_COMPONENTS, ...BLOG_PAGE_COMPONENTS],
  providers: [
    LaunchpointBlogService,
    BlogHTTPService,
    ManyChatSubscribeService,
    LaunchpointBlogCommentsHTTPServicePublic,
    LaunchpointBlogCommentRepliesHTTPServicePublic,
    LaunchpointSEORouterService,
  ],
})
export class BlogPageModule {}
