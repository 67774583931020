import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ICoreUserDemographics, ICoreUserDemographicsParamsUpdatePhone } from '@launchpoint/core-types';
import { ControlsOf } from '../../../../../../forms/from-control/form-control-convert.interface';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';

interface IProfilePhoneConfig {
  title: string;
  fields: {
    phone: boolean;
  };
}
type ICoreUserDemographicsParamsUpdatePhoneNoUserID = Omit<ICoreUserDemographicsParamsUpdatePhone, 'user_id'>;
@Directive()
export class LaunchpointCoreClientProfilePhoneBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  public form: FormGroup<ControlsOf<ICoreUserDemographicsParamsUpdatePhoneNoUserID>>;

  @Input() _config: IProfilePhoneConfig = {
    //TODO: put in the config
    title: 'Phone',
    fields: {
      phone: true,
    },
  };
  @Input() user_demographics: ICoreUserDemographics;
  @Input() isLoading = false;

  @Output() profileChanges = new EventEmitter<ICoreUserDemographicsParamsUpdatePhoneNoUserID>();

  constructor(public fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      phone_country_code: [1],
      phone_number_long: [0],
    });
    if (this.user_demographics) {
      this.form.patchValue({
        phone_country_code: this.user_demographics.phone.phone_country_code ?? 1,
        phone_number_long: this.user_demographics.phone.phone_number_long ?? 0,
      });
    }
  }

  saveSettings() {
    if (this.form.invalid) {
      return;
    } else {
      this.isLoading = true;
      const data: ICoreUserDemographicsParamsUpdatePhoneNoUserID = {
        phone_country_code: this.form.controls.phone_country_code.value,
        phone_number_long: this.form.controls.phone_number_long.value,
      };
      setTimeout(() => {
        this.isLoading = false;
        return this.profileChanges.emit(data);
      }, 1000);
    }
  }

  resetForm() {
    this.initForm();
  }
}
