import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BlogsActions, LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { ICoreBlogComment, ICoreBlogCommentReply } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { IToolbarConfig } from '../../../../components';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog-comments',
  templateUrl: './blog-comments.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogCommentsComponent extends LaunchpointCoreClientBlogBaseComponent implements OnInit {
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);
  _swal = inject(LaunchpointSweetAlertService);

  form_control = new FormControl<string>('');
  searchString = signal('');

  options: IToolbarConfig = {
    title: 'Comments',
    searchPlaceholder: 'Search by comment',
    formControl: this.form_control,
    create: false,
    filter: false,
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search: string) => {
        return this.searchString.set(search);
      },
    });
  }

  filteredBlogs = computed(() => {
    const searchRegex = new RegExp(this.searchString(), 'i'); // Create a case-insensitive regex
    return this.comments$().filter((f) => {
      const matchesTitle = searchRegex.test(f.comment); // Test title against the regex
      return matchesTitle;
    });
  });

  expanded: ICoreBlogComment & { expanded: boolean } = null;

  expandComment(comment: ICoreBlogComment) {
    if (this.expanded?._id === comment._id) {
      this.expanded.expanded = !this.expanded.expanded;
    } else {
      this.expanded = { ...comment, expanded: true };
    }
  }

  deleteReply(reply: ICoreBlogCommentReply, comment: ICoreBlogComment, event: Event) {
    event.stopPropagation();

    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this reply?',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, close this.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            BlogsActions.removeCommentReply({
              data: {
                _id: this.blog._id,
                comment_id: comment._id,
                reply_id: reply._id,
              },
            })
          );
        }
      });
  }

  deleteCommentPopup(comment: ICoreBlogComment, event: Event) {
    event.stopPropagation();
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to remove this comment?',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        cancelButtonText: 'No, close this.',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(
            BlogsActions.removeComment({
              data: {
                _id: this.blog._id,
                comment_id: comment._id,
              },
            })
          );
        }
      });
  }
}
