import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ILayoutMenuItem } from '../../../../../../interfaces/menu.interfaces';
import { Store } from '@ngrx/store';
import { UserAuthActions } from '@launchpoint/core-client';
import { ELaunchpointTopbarActions } from '../../../../../../interfaces/topbar.interface';

@Component({
  selector: 'launchpoint-demo-1-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class LaunchpointDemo1HeaderMenuComponent {
  LaunchpointTopbarActions = ELaunchpointTopbarActions;
  @Input() menuItems: Partial<ILayoutMenuItem>[];
  _Store = inject(Store);
  router = inject(Router);
  // constructor(private router: Router) {}

  // ngOnInit(): void {}

  calculateMenuItemCssClass(url: string): string {
    return checkIsActive(this.router.url, url) ? 'active' : '';
  }

  signOut() {
    this._Store.dispatch(UserAuthActions.logout({ path: ['auth', 'login'] }));
  }
}

const getCurrentUrl = (pathname: string): string => {
  return pathname.split(/[?#]/)[0];
};

const checkIsActive = (pathname: string, url: string) => {
  const current = getCurrentUrl(pathname);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
};
