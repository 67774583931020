import { Directive } from '@angular/core';
import { ENotificationChannelType } from '@launchpoint/core-types';
import { updateAllMessagesQuery } from '../messages/+state/actions/messages.actions';
import { LaunchpointCoreClientNotificationsBaseComponent } from '../notifications/notifications-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsDashboardBaseComponent extends LaunchpointCoreClientNotificationsBaseComponent {
  selectedChannel: ENotificationChannelType = null;

  onCardClicked(channel: ENotificationChannelType) {
    if (this.selectedChannel === channel) {
      this.selectedChannel = null;
    } else {
      this.selectedChannel = channel;
    }

    const query = {
      ...this.messageQuery,
      channels: this.selectedChannel ? [this.selectedChannel] : [],
    };

    this._Store.dispatch(updateAllMessagesQuery({ query }));
  }
}
