<div class="app-content flex-column-fluid container-xxl">
  <div class="app-container container-fluid">
    @if (object) {
      <div class="card mb-xl-10 mb-5">
        <div class="card-body d-flex justify-content-between pb-0 pt-9">
          <div class="d-flex flex-sm-nowrap mb-3 flex-wrap">
            <!-- Trophy detail image -->
            @if (show_media && header_image) {
              <div class="mb-4 me-7">
                <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                  <img [src]="header_image?.meta_data.url" [alt]="header_image?.description" />
                </div>
              </div>
            }

            <div class="d-flex flex-column justify-content-between">
              <!-- START: Title and Status Badge -->
              <div class="d-flex justify-content-between align-items-center mb-2 flex-wrap">
                <div class="d-flex flex-column">
                  <div *ngIf="object">
                    <div class="d-flex align-items-center mb-2">
                      <div class="fs-2 fw-bolder me-1">
                        {{ object?.title || 'No Title' }}
                      </div>

                      @if (checkActive(activeValue)) {
                        <div class="d-flex align-items-center ms-5">
                          <i class="bi bi-check-circle fs-5 text-success" ngbTooltip="Active" *ngIf="activeValue"></i>
                          <i class="bi bi-x-circle fs-5 text-danger" ngbTooltip="Inactive" *ngIf="!activeValue"></i>
                        </div>
                      }

                      <ng-content select="[icon]"></ng-content>

                      <ng-content select="[badge]"></ng-content>

                      @if (loading | async) {
                        <div class="d-flex align-items-center ms-5">
                          <span class="indicator-label">
                            Loading...
                            <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
                          </span>
                        </div>
                      }
                    </div>

                    <ng-content select="[subtitle]"></ng-content>
                  </div>
                </div>
                <!-- START: Activate/Delete Buttons -->
                <div class="d-flex my-4">
                  <ng-content select="[buttons]"></ng-content>
                </div>
              </div>

              <div class="d-flex flex-stack mt-2 flex-wrap">
                <!-- START: Data Blocks -->
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <div class="d-flex flex-wrap">
                    <div class="min-w-125px mb-3 me-6 rounded border border-dashed border-gray-300 px-4 py-3">
                      <div class="d-flex align-items-center">
                        <span>
                          <ng-content select="[block_1_icon]"></ng-content>
                        </span>
                        <div class="fs-3 fw-bolder">
                          <ng-content select="[block_1_value]"></ng-content>
                        </div>
                      </div>

                      <div class="fw-bold fs-6 mt-1 text-gray-400">
                        <ng-content select="[block_1_header]"></ng-content>
                      </div>
                    </div>

                    <div class="min-w-125px mb-3 me-6 rounded border border-dashed border-gray-300 px-4 py-3">
                      <div class="d-flex align-items-center">
                        <span>
                          <ng-content select="[block_2_icon]"></ng-content>
                        </span>
                        <div class="fs-3 fw-bolder">
                          <ng-content select="[block_2_value]"></ng-content>
                        </div>
                      </div>

                      <div class="fw-bold fs-6 mt-1 text-gray-400">
                        <ng-content select="[block_2_header]"></ng-content>
                      </div>
                    </div>

                    <!-- <div class="min-w-125px mb-3 me-6 rounded border border-dashed border-gray-300 px-4 py-3">
                      <div class="d-flex align-items-center">
                        <span>
                          <ng-content select="[block_3_icon]"></ng-content>
                        </span>
                        <div class="fs-3 fw-bolder">
                          <ng-content select="[block_3_value]"></ng-content>
                        </div>
                      </div>

                      <div class="fw-bold fs-6 mt-1 text-gray-400">
                        <ng-content select="[block_3_header]"></ng-content>
                      </div>
                    </div> -->
                  </div>
                </div>

                <div class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                  <div class="d-flex justify-content-between w-100 mb-2 mt-auto">
                    <span class="fw-bold fs-6 text-gray-400">
                      <ng-content select="[completion_title]"></ng-content>
                    </span>
                    <span class="fw-bolder fs-6">
                      <ng-content select="[completion_amount]"></ng-content>
                    </span>
                  </div>
                  <ng-content select="[completion_visual]"> </ng-content>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <ng-content select="[ellipsis_menu]"></ng-content>
          </div>
        </div>

        <div class="card-footer h-55px m-0 py-0">
          <div class="d-flex h-55px overflow-auto">
            <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap border-transparent">
              <ng-content select="[navigation]"></ng-content>
            </ul>
          </div>
        </div>
      </div>
    } @else {
      <div class="card mb-xl-9 mb-6">
        <div class="card-body pb-0 pt-9">
          <div class="d-flex flex-sm-nowrap align-item-center mb-6 flex-wrap">
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start mb-2 flex-wrap">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-1">
                    <launchpoint-skeleton-loaders
                      appearance="circle"
                      count="1"
                      [theme]="{
                        'border-radius': '5px',
                        height: '30px',
                        width: '150px',
                        border: '1px solid white',
                      }"
                    />
                  </div>
                  <div class="d-flex fw-bold fs-5 mb-4 flex-wrap text-gray-700">
                    <launchpoint-skeleton-loaders
                      appearance="circle"
                      count="1"
                      [theme]="{
                        'border-radius': '1px',
                        height: '20px',
                        border: '1px solid white',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-start flex-wrap">
                <launchpoint-skeleton-loaders
                  appearance="circle"
                  count="2"
                  [theme]="{
                    'border-radius': '5px',
                    height: '40px',
                    border: '1px solid white',
                  }"
                />
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder border-transparent">
            <li class="nav-item">
              <a class="nav-link text-active-primary me-6 py-5">Loading...</a>
            </li>
          </ul>
        </div>
      </div>
    }
  </div>
</div>
