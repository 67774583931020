import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveUnderscorePipe } from './remove-underscore.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { PhoneFormatPipe } from './format-phone.pipe';

const PIPES = [RemoveUnderscorePipe, YesNoPipe, PhoneFormatPipe];

@NgModule({
  declarations: [...PIPES],
  imports: [CommonModule],
  exports: [...PIPES],
})
export class LibCoreClientPipesModule {}
