import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../auth/auth.user.interface';
import { IMedia, IMediaTagParamsEdit, IMediaTagParamsSet } from '../media.interface';

/**
 * @Controller('media-tags')
 */
export interface IMediaTagsController {
  /**
   *     @Post()
   */
  add(body: IMediaTagParamsEdit, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   *    @Put()
   */
  remove(body: IMediaTagParamsEdit, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   *    @Put()
   */
  set(body: IMediaTagParamsSet, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   *    @Get()
   */
  distinct(): Promise<string[]> | Observable<string[]>;
}
