import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileEntity, LaunchpointCoreClientBaseComponent, LaunchpointMediaService, LaunchpointMediaUploadService } from '@launchpoint/core-client';
import { IContentUploadQueryParams, IMedia, IMediaCategory } from '@launchpoint/core-types';
import { NgbModule, NgbProgressbarModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { ngfModule } from 'angular-file';
import { takeUntil } from 'rxjs';
import { LaunchpointSweetAlertService } from '../../../../../../components/swal.service';
import { LaunchpointCoreWebMediaCategoriesSearchComponent } from '../../../categories/media-categories-search/media-categories-search.component';
import { LaunchpointCoreWebMediaUploadComponent } from '../media-upload.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ngfModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgbModule,
    LaunchpointCoreWebMediaCategoriesSearchComponent,
  ],
  providers: [LaunchpointMediaUploadService, LaunchpointMediaService, LaunchpointCoreWebMediaUploadComponent],
  selector: 'launchpoint-media-upload-standalone',
  templateUrl: './media-upload-stand-alone.component.html',
})
export class LaunchpointCoreWebMediaUploadStandaloneComponent extends LaunchpointCoreClientBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);
  public _AdminMediaService = inject(LaunchpointMediaService);
  public _LunchPointMediaUploadService = inject(LaunchpointMediaUploadService);
  cd = inject(ChangeDetectorRef);

  @Input() media: IMedia = null;
  @Input() multiple = 'true'; // has to be a string for the ngf module
  /**
   * 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf'
   */
  @Input() accept = '*';
  @Input() description = 'Choose a file or drag it here';
  @Input() options?: IContentUploadQueryParams;

  files: File[] = [];
  loading = true;
  confirmation = false;
  mediaLoading = false;

  uploadPercentage = 0;
  confirm: string;
  cancel: string;
  noText: string;
  issueLast: boolean;

  imageChangedEvent: any = '';
  imageFileChanged: any = null;
  croppedImage: any = '';
  ratio: any = '1 / 1';

  dragFiles: any;
  validComboDrag: any;
  lastInvalids: any;
  fileDropDisabled: any;
  maxSize: any;
  baseDropValid: any;

  selectedItem: any;
  // url = `${location.origin}/api/media/content/upload/`;
  body: any;
  filesAddedArray: File[] = [];
  test: string = null;

  fileEntities: FileEntity[] = [];
  categories: IMediaCategory[] = [];
  edit: {
    [key: string]: {
      edit: boolean;
      name: string;
    };
  }[] = [];

  editFile(name: string) {
    this.edit[name] = {
      edit: this.edit[name]?.edit ? false : true,
      name,
    };
  }

  saveFile(file: FileEntity) {
    const editPath = this.edit[file.file.name];
    file.file = new File([file.file], editPath.name, { type: file.file.type });

    editPath.edit = false;
  }

  get uploadDisabled() {
    return this.fileEntities.some((file) => !file.category);
  }

  clear() {
    this.fileEntities = [];
    this.cd.detectChanges();
  }

  push(files: File[]) {
    for (const file of files) {
      this.fileEntities.push({
        file,
        uploadPercentage: null,
        loading: false,
        error: null,
        category: this.categories[0],
        description: '',
        media: null,
      });
    }
  }

  async upload() {
    if (!this.fileEntities.length) {
      return;
    }

    for (let i = 0; i < this.fileEntities.length; i++) {
      /**
       * Already been uploaded so we continue to the others
       */
      if (this.fileEntities[i]?.media) {
        continue;
      }
      /**
       * This is required for the media create service
       */
      if (!this.fileEntities[i]?.category) {
        this.fileEntities[i].error = 'Please select a category.';
        this.cd.detectChanges();
      }
      try {
        await this.uploadServiceV2(this.fileEntities[i], i);

        // clear all from uploader on complete only if all files have finished
        if (i === this.fileEntities.length - 1) {
          this._swal.fireToast({ icon: 'success', title: 'Upload Successful' });
          this.clear();
        }
      } catch (error) {
        console.log('upload error', error);

        this.fileEntities[i].uploadPercentage = 0;
        this.fileEntities[i].error = error?.error?.message ?? error?.message ?? error;
        this.cd.detectChanges();
      }
      continue;
    }
  }

  remove(i: number) {
    this.fileEntities.splice(i, 1);
  }

  setCategory(category: IMediaCategory, i: number) {
    this.fileEntities[i].category = category;
  }

  removeCategory(i: number) {
    if (this.fileEntities[i]?.media) {
      return;
    }
    this.fileEntities[i].category = null;
  }

  async uploadServiceV2(file: FileEntity, i: number) {
    return new Promise((res, rej) => {
      this._LunchPointMediaUploadService
        .uploadMediaService(file)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (updatedFileEntity) => {
            this.fileEntities[i] = updatedFileEntity;
            this.cd.detectChanges();
          },
          complete: () => {
            return res(i);
          },
          error: (error) => {
            this._swal.fireToast({ icon: 'error', title: 'Upload Failed' });
            return rej(error);
          },
        });
    });
  }

  fileChangeEvent(files: File | File[]) {
    if (this.multiple === 'true') {
      this.push(files as File[]);
    } else {
      this.push([files[0] as File]);
    }
    this.filesAddedArray = [];
  }

  selectedConfirmation() {
    this.confirmation = true;
  }

  cancelModal() {
    this.confirmation = false;
  }

  handleError(error) {
    if (error) {
      this.uploadPercentage = null;
      this.loading = false;
      return;
    }
  }
}
