import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { BASE_TAGIFY_SETTINGS, CoreUserV1Service, LaunchpointTagifyBaseComponent } from '@launchpoint/core-client';
import { ICoreUser, ICoreUserSearchQuery, ICoreUserTagify } from '@launchpoint/core-types';
import { TagifySettings } from 'ngx-tagify';
import { map, Observable, takeUntil } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebUsersTagifyBaseComponent extends LaunchpointTagifyBaseComponent implements OnInit {
  public _CoreUserV1Service = inject(CoreUserV1Service);

  @Input() users: ICoreUser[] = [];
  @Input() query: ICoreUserSearchQuery = {};

  public usersModel: ICoreUserTagify[] = [];
  public whitelistUsers$: Observable<ICoreUserTagify[]>;

  public settings: TagifySettings = {
    ...BASE_TAGIFY_SETTINGS,
    placeholder: 'Search Users...',
  };

  @Output() selectedUser = new EventEmitter<ICoreUser[]>();

  selectData(data: ICoreUserTagify[]) {
    return this.selectedUser.emit(data.map((f) => f.data));
  }

  get tagifyElement() {
    return this.getTagify('users');
  }

  initializeModel() {
    if (this.users?.length > 0) {
      this.usersModel = this.users.map((user) => this.formatTags(user));

      setTimeout(() => {
        if (this.usersModel?.length) {
          this.tagifyElement.loadOriginalValues(this.usersModel.map((f) => f.value));
          this.settings.enforceWhitelist = true;
        }
      }, 100);
    } else {
      this.settings.enforceWhitelist = true;
    }
  }

  updateWhitelist(search?: string): void {
    const query = this.query;

    if (search) {
      query.search = search;
    }

    this.tagifyElement?.loading(true);

    this.whitelistUsers$ = this._CoreUserV1Service
      .search({
        query,
        pagination: this.pagination,
        querySort: this.querySort,
      })
      .pipe(map((f) => f.data.map((user) => this.formatTags(user))));

    this.whitelistUsers$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (users) => {
        this.settings.whitelist = users;
        this.tagifyElement.settings.whitelist = this.settings.whitelist;

        if (this.firstLoad) {
          this.tagifyElement.loading(false);
          this.firstLoad = false;
        } else {
          this.tagifyElement.loading(false).dropdown.show();
        }
      },
    });
  }

  formatTags(user: ICoreUser): ICoreUserTagify {
    return {
      value: user?.email ?? user?.demographics?.phone?.phone_number_long?.toString(),
      data: user,
    };
  }
}
