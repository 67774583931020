import { Injectable, inject } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as LaunchpointDynamicFormActions from '../actions/actions';
import * as LaunchpointDynamicFormSelectors from '../selectors/selectors';
import { LaunchpointDynamicFormV1Service } from '../../services/forms.service';

@Injectable()
export class LaunchpointDynamicFormEffects {
  private actions$ = inject(Actions);
  private _Store = inject(Store);
  private _LaunchpointDynamicFormV1Service = inject(LaunchpointDynamicFormV1Service);

  getAllLaunchpointDynamicForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        LaunchpointDynamicFormActions.searchAllLaunchpointDynamicForm,
        LaunchpointDynamicFormActions.updateAllLaunchpointDynamicFormPagination,
        LaunchpointDynamicFormActions.updateAllLaunchpointDynamicFormQuery,
        LaunchpointDynamicFormActions.updateAllLaunchpointDynamicFormSorting
      ),
      withLatestFrom(
        this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormPagination),
        this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormQuerySort),
        this._Store.select(LaunchpointDynamicFormSelectors.selectLaunchpointDynamicFormQuery)
      ),
      switchMap(([action, pagination, querySort, query]) => {
        return this._LaunchpointDynamicFormV1Service.search({ pagination, querySort, query }).pipe(
          map((results) => {
            return LaunchpointDynamicFormActions.searchAllLaunchpointDynamicFormSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(LaunchpointDynamicFormActions.searchAllLaunchpointDynamicFormFailure(error)))
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormActions.searchAllLaunchpointDynamicFormFailure(error)))
    );
  });

  getLaunchpointDynamicFormById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormActions.getLaunchpointDynamicFormById),
      switchMap((action) => {
        return this._LaunchpointDynamicFormV1Service.getById(action.data_id).pipe(
          map((data) => {
            return LaunchpointDynamicFormActions.getLaunchpointDynamicFormByIdSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormActions.getLaunchpointDynamicFormByIdFailure({ data_id: action.data_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormActions.getLaunchpointDynamicFormByIdFailure(error)))
    );
  });

  createLaunchpointDynamicFormById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormActions.createLaunchpointDynamicForm),
      switchMap((action) => {
        return this._LaunchpointDynamicFormV1Service.create(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormActions.createLaunchpointDynamicFormSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(LaunchpointDynamicFormActions.createLaunchpointDynamicFormFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormActions.getLaunchpointDynamicFormByIdFailure(error)))
    );
  });

  updateLaunchpointDynamicFormById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormActions.updateLaunchpointDynamicForm),
      switchMap((action) => {
        return this._LaunchpointDynamicFormV1Service.update(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormActions.updateLaunchpointDynamicFormSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormActions.updateLaunchpointDynamicFormFailure({ data_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormActions.getLaunchpointDynamicFormByIdFailure(error)))
    );
  });

  deleteLaunchpointDynamicFormById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LaunchpointDynamicFormActions.deleteLaunchpointDynamicForm),
      switchMap((action) => {
        return this._LaunchpointDynamicFormV1Service.delete(action.data).pipe(
          map((data) => {
            return LaunchpointDynamicFormActions.deleteLaunchpointDynamicFormSuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(LaunchpointDynamicFormActions.deleteLaunchpointDynamicFormFailure({ data_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(LaunchpointDynamicFormActions.deleteLaunchpointDynamicFormFailure(error)))
    );
  });
}
