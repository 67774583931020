import { Observable } from 'rxjs';
import {
  ILaunchpointCorePinpointCampaign,
  ILaunchpointCorePinpointCampaignParamsCreate,
  ILaunchpointCorePinpointCampaignParamsDelete,
  ILaunchpointCorePinpointCampaignParamsList,
  ILaunchpointCorePinpointCampaignParamsUpdate,
  ILaunchpointCorePinpointCampaignSearchPayload,
  ILaunchpointCorePinpointCampaignSearchResults,
} from './pinpoint-campaign.interface';

/**
 * Controller('pinpoint/campaigns')
 */
export interface ILaunchpointCorePinpointCampaignController {
  /**
   * Post(search)
   * @param body
   */
  search(
    body: ILaunchpointCorePinpointCampaignSearchPayload
  ): Promise<ILaunchpointCorePinpointCampaignSearchResults> | Observable<ILaunchpointCorePinpointCampaignSearchResults>;
  /**
   * Get(:id)
   * @param _id
   */
  getById(_id: string): Promise<ILaunchpointCorePinpointCampaign> | Observable<ILaunchpointCorePinpointCampaign>;
  /**
   * Post()
   * @param body
   */
  create(
    body: ILaunchpointCorePinpointCampaignParamsCreate
  ): Promise<ILaunchpointCorePinpointCampaign> | Observable<ILaunchpointCorePinpointCampaign>;
  /**
   * Put()
   * @param body
   */
  update(
    body: ILaunchpointCorePinpointCampaignParamsUpdate
  ): Promise<ILaunchpointCorePinpointCampaign> | Observable<ILaunchpointCorePinpointCampaign>;
  /**
   * Put('delete;)
   * @param body
   */
  delete(
    body: ILaunchpointCorePinpointCampaignParamsDelete
  ): Promise<ILaunchpointCorePinpointCampaign> | Observable<ILaunchpointCorePinpointCampaign>;
  /**
   * Post('sync')
   * @param body
   */
  sync(body: ILaunchpointCorePinpointCampaignParamsList): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
