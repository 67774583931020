import { Directive, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { firstValueFrom, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { UserSelectors } from '../../../../user';
import { UserNotificationsHTTPService } from '../../../services/user-notifications-http.service';
import {
  IQueryPageination,
  IQuerySort,
  IUserNotification,
  IUserNotificationSearchPayload,
  IUserNotificationSearchQuery,
} from '@launchpoint/core-types';

@Directive()
export abstract class LaunchpointCoreClientNotificationsUserNotificationStandaloneBaseComponent
  extends LaunchpointCoreClientBaseComponent
  implements OnInit
{
  _Store = inject(Store);
  _UserNotificationsHTTPService = inject(UserNotificationsHTTPService);

  @Input() query: IUserNotificationSearchQuery;
  @Input() pagination: IQueryPageination = { limit: 10, skip: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  loading$ = signal<boolean>(false);

  notifications$ = signal<IUserNotification[]>([]);

  @Output() total = new EventEmitter<number>();

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.loading$.set(true);
    const user = await firstValueFrom(this._Store.select(UserSelectors.selectUser));
    if (!user) {
      return;
    }

    this.query = { ...this.query, users: [user._id] };
    this.search({
      query: { ...this.query, users: [user._id] },
      pagination: { skip: 0, limit: 10 },
      querySort: { createdAt: -1 },
    });
  }

  paginate(page: number) {
    if (this.pagination.pageIndex === page - 1) {
      return;
    }
    this.search({
      query: this.query,
      querySort: this.querySort,
      pagination: { ...this.pagination, pageIndex: page - 1, skip: this.pagination.limit * (page - 1) },
    });
  }

  limit(limit: number) {
    this.search({ pagination: { ...this.pagination, limit }, query: this.query, querySort: this.querySort });
  }

  private search(params: IUserNotificationSearchPayload) {
    this.loading$.set(true);

    this._UserNotificationsHTTPService
      .search(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.notifications$.set(data.data);
          this.pagination = data.pagination;
          this.total.emit(data.pagination.count);
          this.loading$.set(false);
        },
      });
  }
}
