import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { deactivateCompany, deleteCompany } from '../+state/actions/company.actions';
import { LaunchpointCompanyEntityState } from '../+state/interfaces/company.interface';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { ICoreCompanyParamsUpdate } from '@launchpoint/core-types';

@Directive()
export abstract class CompanySettingsParentBaseComponent extends LaunchpointCoreClientBaseComponent {
  company_id: string;
  companyId$: Observable<string>;
  companyEntity$: Observable<LaunchpointCompanyEntityState>;

  constructor(public _Store: Store) {
    super();
    // this.companyEntity$ = this._Store.select(selectSkylabSelectedCompany);
    // this.companyId$ = this._Store.select(selectSkylabCompanyId);
    // this.companyId$.pipe(takeUntil(this.destroy$)).subscribe((company_id) => {
    //   this.company_id = company_id;
    // });
  }

  receiveDeactivate(saveSettings: ICoreCompanyParamsUpdate) {
    this._Store.dispatch(deactivateCompany({ data: saveSettings }));
  }
  receiveDelete(company_id: string) {
    this._Store.dispatch(deleteCompany({ company_id: company_id }));
  }
}
