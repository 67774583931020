import { Directive, EventEmitter, forwardRef, inject, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICoreUser, ICoreUserSearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, Observable, OperatorFunction, switchMap, takeUntil } from 'rxjs';
import { ISearchAheadComponent } from '../../../../../components/search-ahead/search-ahead-component.interface';
import { LaunchpointFormControlSearchAheadBaseComponent } from '../../../../../forms/from-control/form-control-search-ahead-base.component';
import { LaunchpointCoreClientAuthHTTPService } from '../../../../user/auth';
import { CoreUserV1Service } from '../../../../user/core/core-base/services/core-base-http.service';

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointCoreClientUserSearchAheadBaseComponent),
      multi: true,
    },
  ],
})
export abstract class LaunchpointCoreClientUserSearchAheadBaseComponent
  extends LaunchpointFormControlSearchAheadBaseComponent
  implements ISearchAheadComponent, OnInit
{
  public _CoreUserV1Service: CoreUserV1Service = inject(CoreUserV1Service);
  public _LaunchpointCoreClientAuthHTTPService: LaunchpointCoreClientAuthHTTPService = inject(LaunchpointCoreClientAuthHTTPService);
  public _Store: Store = inject(Store);

  @Input() query: ICoreUserSearchQuery;
  /**
   * This is the user id of the selected user.
   */
  @Input() selected: string;

  @Output() selectedChange = new EventEmitter<string>();
  @Output() selectedUser = new EventEmitter<ICoreUser>();

  ngOnInit(): void {
    this.runQuery('');
  }

  async getById(user_id: string) {
    if (!user_id) {
      return;
    }
    this.loading = true;
    this._CoreUserV1Service
      .getById(user_id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.placeholder = data.profile?.full_name;
        this.loading = false;
        return this.selectedUser.emit(data);
      });
  }

  formatter = (result: ICoreUser): string => `${result.profile?.first_name} ${result.profile?.last_name || ''} (${result.email})`;

  public onFocus(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  search: OperatorFunction<string, readonly ICoreUser[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      // tap(() => this.searching = true),
      switchMap((term) => this.runQuery(term))
      // tap(() => this.searching = false)
    );

  runQuery(search: string) {
    this.loading = true;
    return this._CoreUserV1Service
      .search({ pagination: { limit: 100, skip: 0 }, querySort: { created_at: -1 }, query: { ...this.query, search } })
      .pipe(
        map((data) => {
          this.loading = false;
          return data.data;
        })
      );
  }

  /**
   * Search aheads use this instead of `adjustValue`
   * @param data
   * @returns
   */
  selectData(data: { item: ICoreUser }) {
    this.selected = data.item._id;
    this.onChanged(data.item._id);
    this.onTouched();
    return this.selectedUser.emit(data.item);
  }

  writeValue(value: string) {
    if (value !== undefined) {
      this.selected = value;
      this.getById(this.selected);
    }
  }
}
