import { Directive, Input } from '@angular/core';
import { IHttpException, IQueryPageination, IQuerySort, ITopicSearchQuery } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';
import { TopicsEntityState } from './interfaces/topics-entity.interface';
import {
  selectTopics,
  selectTopicsError,
  selectTopicsLoading,
  selectTopicsPagination,
  selectTopicsQuery,
  selectTopicsQuerySort,
  selectTopicsSuccess,
} from './selectors/topics.selectors';

@Directive()
export abstract class LaunchpointCoreClientNotificationsTopicsStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() query: ITopicSearchQuery = {};

  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ITopicSearchQuery>;
  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;
  topic$: Observable<TopicsEntityState>;
  topicEntity$: Observable<TopicsEntityState[]>;
  topicEntityLoading$: Observable<boolean>;
  topicEntityError$: Observable<IHttpException | null>;
  topicEntitySuccess$: Observable<string | null>;

  constructor(public _Store: Store) {
    super();

    this.pagination$ = this._Store.select(selectTopicsPagination);
    this.loading$ = this._Store.select(selectTopicsLoading);
    this.loaded$ = this._Store.select(selectTopicsLoading);
    this.querySort$ = this._Store.select(selectTopicsQuerySort);
    this.query$ = this._Store.select(selectTopicsQuery);
    this.error$ = this._Store.select(selectTopicsError);
    this.success$ = this._Store.select(selectTopicsSuccess);
    this.topicEntity$ = this._Store.select(selectTopics);
    this.topicEntityError$ = this._Store.select(selectTopicsError);
    this.topicEntityLoading$ = this._Store.select(selectTopicsLoading);
    this.topicEntitySuccess$ = this._Store.select(selectTopicsSuccess);
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
    this.topicEntitySuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this.toast({ icon: 'success', title: data });
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
    this.topicEntityError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this.toast({ icon: 'error', title: data.message });
      },
    });
  }
  toast(params: { icon; title }) {
    // extend
  }
}
