import { Component, inject, Input, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LaunchpointBlogCommentRepliesHTTPServicePublic, LaunchpointBlogCommentsHTTPServicePublic } from '@launchpoint/core-client';
import {
  ICoreBlog,
  ICoreBlogComment,
  ICoreBlogCommentReply,
  ICoreBlogParamsCommentAdd,
  ICoreBlogParamsCommentReplyAdd,
} from '@launchpoint/core-types';
import { LaunchpointBlogService } from '../../data/blog.service';

@Component({
  selector: 'sd-blog-comments',
  templateUrl: './blog-comments.component.html',
  styleUrl: './blog-comments.component.scss',
})
export class BlogCommentsComponent {
  @Input() blog: ICoreBlog;

  fb = inject(FormBuilder);
  _LaunchpointBlogService = inject(LaunchpointBlogService);
  commentService = inject(LaunchpointBlogCommentsHTTPServicePublic);
  replyService = inject(LaunchpointBlogCommentRepliesHTTPServicePublic);

  commentIncrement = 5;
  commentsEnd = signal<number>(this.commentIncrement);

  replyIncrement = 3;
  repliesEnd = signal<number>(this.replyIncrement);

  showingReplies = signal<ICoreBlogComment | null>(null);

  commentLoading = signal<boolean>(false);
  replyLoading = signal<boolean>(false);

  form: FormGroup<{
    title: FormControl<string>;
    comment: FormControl<string>;
  }>;

  commentForm = false;
  replyForm = false;

  buildForm(type: 'comment' | 'reply') {
    this.form = this.fb.group({
      title: ['', Validators.required],
      comment: ['', Validators.required],
    });

    if (type === 'reply') {
      this.replyForm = true;
    }
    if (type === 'comment') {
      this.commentForm = true;
    }
  }

  get moreDisabled() {
    return this.commentsEnd() >= this.blog?.comments?.length;
  }
  get lessDisabled() {
    return this.commentsEnd() <= this.commentIncrement;
  }
  get canComment() {
    const form = this.commentForm;
    const enabled = this.blog?.comments_enabled;
    const archived = this.blog?.comments_archived;

    if (form) {
      return false;
    }
    if (archived) {
      return false;
    }
    return enabled;
  }

  get canReply() {
    const form = this.replyForm;
    const enabled = this.blog?.comments_enabled;
    const archived = this.blog?.comments_archived;
    const enabledR = this.blog?.replies_enabled;

    if (form) {
      return false;
    }
    if (archived) {
      return false;
    }
    if (!enabled) {
      return false;
    }
    if (!enabledR) {
      return false;
    }
    return true;
  }
  showMore() {
    this.commentsEnd.set(this.blog.comments.length);
  }
  showLess() {
    this.commentsEnd.set(this.commentIncrement);
  }

  moreRepliesDisabled(comment: ICoreBlogComment) {
    return this.repliesEnd() >= comment?.replies?.length;
  }
  lessRepliesDisabled(comment: ICoreBlogComment) {
    return this.repliesEnd() !== comment?.replies?.length;
  }
  showMoreReplies(comment: ICoreBlogComment) {
    const min = Math.min(comment.replies.length, this.repliesEnd() + this.replyIncrement);
    this.repliesEnd.set(min);
  }
  showLessReplies() {
    this.repliesEnd.set(this.replyIncrement);
  }

  showReplies(comment: ICoreBlogComment) {
    if (this.showingReplies()?._id === comment._id) {
      this.showingReplies.set(null);
    } else {
      this.showingReplies.set(comment);
    }
  }

  addComment(params: ICoreBlogParamsCommentAdd) {
    this.commentLoading.set(true);
    this.commentService.add(params).subscribe({
      next: (blog) => {
        this.blog = this._LaunchpointBlogService.formatBlog(blog);
        this.commentLoading.set(false);
      },
    });
  }
  likeComment(comment: ICoreBlogComment) {
    this.commentLoading.set(true);
    this.commentService.like({ _id: this.blog._id, comment_id: comment._id }).subscribe({
      next: (blog) => {
        this.blog = this._LaunchpointBlogService.formatBlog(blog);
        this.commentLoading.set(false);
      },
    });
  }
  dislikeComment(comment: ICoreBlogComment) {
    this.commentLoading.set(true);
    this.commentService.dislike({ _id: this.blog._id, comment_id: comment._id }).subscribe({
      next: (blog) => {
        this.blog = this._LaunchpointBlogService.formatBlog(blog);
        this.commentLoading.set(false);
      },
    });
  }
  addReply(params: ICoreBlogParamsCommentReplyAdd) {
    this.replyLoading.set(true);
    this.replyService.add(params).subscribe({
      next: (blog) => {
        this.blog = this._LaunchpointBlogService.formatBlog(blog);
        this.replyLoading.set(false);
      },
    });
  }
  likeReplyComment(comment: ICoreBlogComment, reply: ICoreBlogCommentReply) {
    this.replyLoading.set(true);
    this.replyService.like({ _id: this.blog._id, comment_id: comment._id, reply_id: reply._id }).subscribe({
      next: (blog) => {
        this.blog = blog;
        this.replyLoading.set(false);
      },
    });
  }
  dislikeReplyComment(comment: ICoreBlogComment, reply: ICoreBlogCommentReply) {
    this.replyLoading.set(true);
    this.replyService.dislike({ _id: this.blog._id, comment_id: comment._id, reply_id: reply._id }).subscribe({
      next: (blog) => {
        this.blog = blog;
        this.replyLoading.set(false);
      },
    });
  }

  copyLink(comment: ICoreBlogComment) {
    console.log('copy link');
  }

  copyReplyLink(comment: ICoreBlogComment, reply: ICoreBlogCommentReply) {
    console.log('copy link');
  }

  reset() {
    this.commentForm = false;
    this.replyForm = false;
    this.form.reset();
  }

  saveComment() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    const params: ICoreBlogParamsCommentAdd = {
      _id: this.blog._id,
      title: form.title,
      comment: form.comment,
    };

    this.addComment(params);
    this.commentForm = false;
  }

  saveReply(comment: ICoreBlogComment) {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    const params: ICoreBlogParamsCommentReplyAdd = {
      _id: this.blog._id,
      comment_id: comment._id,
      title: form.title,
      comment: form.comment,
    };

    this.addReply(params);
    this.replyForm = false;
  }
}
