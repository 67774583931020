import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchpointCoreClientWebhooksBaseComponent } from '@launchpoint/core-client';
import { ICoreActivityHistoryWebhook, ICoreActivityHistoryWebhookEvent, ICoreActivityHistoryWebhookEventSearchQuery } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IEllipsisMenuOption } from '../../../../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { LaunchpointCoreWebWebhookModalComponent } from '../webhook-modal/webhook-modal.component';

@Component({
  selector: 'launchpoint-webhook',
  templateUrl: 'webhook.component.html',
})
export class LaunchpointCoreWebWebhookDetailComponent extends LaunchpointCoreClientWebhooksBaseComponent {
  _swalService = inject(LaunchpointSweetAlertService);
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);
  router = inject(Router);

  eventQuery: ICoreActivityHistoryWebhookEventSearchQuery = {};

  getOptions(webhook: ICoreActivityHistoryWebhook): IEllipsisMenuOption[] {
    const options = [
      {
        label: 'Edit',
        action: () => {
          this.editWebhook(webhook);
        },
      },
      {
        label: webhook.enabled ? 'Disable' : 'Enable',
        action: () => {
          this.flipEnable(webhook);
        },
      },
      {
        label: 'Delete',
        action: () => {
          this.deleteWebhookAlert(webhook._id);
        },
      },
    ];

    return options;
  }
  setEvent(event: ICoreActivityHistoryWebhookEvent) {
    this.selectedEvent = event;
  }

  getQuery(webhook: ICoreActivityHistoryWebhook): ICoreActivityHistoryWebhookEventSearchQuery {
    const query: ICoreActivityHistoryWebhookEventSearchQuery = {
      webhook_ids: [webhook?._id],
      // events: [this.selectedEvent?.history?.event_name],
    };

    // console.log(query);

    return query;
  }

  editWebhook(webhook: ICoreActivityHistoryWebhook) {
    this.modalRef = this.modalService.open(LaunchpointCoreWebWebhookModalComponent, { size: 'lg' });
    this.modalRef.componentInstance.webhook = webhook;
  }

  deleteWebhookAlert(webhook_id: string): void {
    this._swalService
      .fireAlert({
        title: 'Are you sure you want to delete this webhook?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          const url = this.router.url.split('/').slice(0, -1);
          this.deleteWebhook(webhook_id, url);
        }
      });
  }

  flipEnable(webhook: ICoreActivityHistoryWebhook): void {
    this._swalService
      .fireAlert({
        title: `Are you sure you want to ${webhook.enabled ? 'disable' : 'enable'} this webhook?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.updateWebhook({
            _id: webhook._id,
            enabled: !webhook.enabled,
          });
        }
      });
  }
}
