import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { APP_ERROR_CONFIG_TOKEN, IErrorModuleConfig } from '@launchpoint/core-client';
import { AppErrorsCoreComponent } from './errors.component';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { ErrorsRoutingModule } from './errors-routing.module';

@NgModule({
  declarations: [AppErrorsCoreComponent, Error404Component, Error500Component],
  imports: [CommonModule, ErrorsRoutingModule],
})
export class AppErrorsCoreModule {
  static forRoot(configJSON: IErrorModuleConfig): ModuleWithProviders<AppErrorsCoreModule> {
    return {
      ngModule: AppErrorsCoreModule,
      providers: [
        {
          provide: APP_ERROR_CONFIG_TOKEN,
          useValue: configJSON,
        },
      ],
    };
  }
}
