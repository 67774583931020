import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationsTableBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';

@Component({
  selector: 'launchpoint-notifications-toolbar',
  templateUrl: './notifications-toolbar.component.html',
  styleUrls: ['./notifications-toolbar.component.scss'],
})
export class LaunchpointCoreWebNotificationToolbarComponent extends LaunchpointCoreClientNotificationsTableBaseComponent {
  private _swal = inject(LaunchpointSweetAlertService);

  options: IToolbarConfig = {
    title: 'Notifications',
    formControl: this.form_control,
    create: false,
    searchPlaceholder: 'Search notifications',
  };

  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
