import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ELaunchpointGeo, ICoreUserAddressParamsCreate, ICoreUserAddressParamsUpdate, ICoreUserAddresses } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { ControlsOf } from '../../../../../../forms/from-control/form-control-convert.interface';

interface IProfileAddressConfig {
  title: string;
  fields: {
    address: boolean;
  };
}
type ICoreUserAddressParamsUpdateNoUserID = Omit<ICoreUserAddressParamsUpdate, 'user_id'>;
type ICoreUserAddressParamsCreateNoUserID = Omit<ICoreUserAddressParamsCreate, 'user_id'>;

@Directive()
export class LaunchpointCoreClientProfileAddressBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnChanges {
  public form: FormGroup<ControlsOf<ICoreUserAddressParamsUpdateNoUserID>>;
  @Input() _config: IProfileAddressConfig = {
    title: 'Billing Address',
    fields: {
      address: true,
    },
  };
  @Input() user_address: ICoreUserAddresses;
  @Input() isLoading = false;

  @Output() profileAddressUpdate = new EventEmitter<ICoreUserAddressParamsUpdateNoUserID>();
  @Output() profileAddressCreate = new EventEmitter<ICoreUserAddressParamsCreateNoUserID>();

  constructor(public fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.user_address.previousValue != changes.user_address.currentValue) {
      this.initForm();
    }
  }

  initForm() {
    this.form = this.fb.group({
      address_id: [''],
      title: [''],
      line_one: ['', Validators.required],
      line_two: [''],
      country: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      zip_code: ['', Validators.required],
      address_long: [''],
    });

    this.loadForm();
  }

  loadForm() {
    if (this.user_address) {
      this.form.patchValue({
        address_id: this.user_address._id ?? null,
        title: this.user_address.title ?? null,
        line_one: this.user_address.line_one ?? null,
        line_two: this.user_address.line_two ?? null,
        country: this.user_address.country ?? null,
        city: this.user_address.city ?? null,
        state: this.user_address.state ?? null,
        zip_code: this.user_address.zip_code ?? null,
        address_long: this.user_address.address_long ?? null,
      });
    }
  }

  saveSettings() {
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;

    const form = this.form.value;

    const data: ICoreUserAddressParamsCreateNoUserID = {
      title: form.title,
      address_long: form.address_long,
      line_one: form.line_one,
      line_two: form.line_two,
      country: form.country,
      city: form.city,
      state: form.state,
      zip_code: form.zip_code,
    };

    if (form.location?.coordinates?.length) {
      data.location = {
        type: ELaunchpointGeo.POINT,
        coordinates: form.location?.coordinates ? [form.location?.coordinates?.[0], form.location?.coordinates?.[1]] : [],
      };
    }

    setTimeout(() => {
      this.isLoading = false;
      return this.profileAddressCreate.emit(data);
    }, 1000);
    // if (this.user_address?.length > 0) {
    //   this.isLoading = true;
    //   const data: ICoreUserAddressParamsUpdateNoUserID = {
    //     address_id: this.form.controls.address_id.value,
    //     title: this.form.controls.title.value,
    //     address_long: this.form.controls.address_long.value,
    //     line_one: this.form.controls.line_one.value,
    //     line_two: this.form.controls.line_two.value,
    //     country: this.form.controls.country.value,
    //     city: this.form.controls.city.value,
    //     state: this.form.controls.state.value,
    //     zip_code: this.form.controls.zip_code.value,
    //   };
    //   setTimeout(() => {
    //     this.isLoading = false;
    //     return this.profileAddressUpdate.emit(data);
    //   }, 1000);
    // } else {
    //   this.isLoading = true;
    //   const data: ICoreUserAddressParamsCreateNoUserID = {
    //     title: this.form.controls.title.value,
    //     address_long: this.form.controls.address_long.value,
    //     line_one: this.form.controls.line_one.value,
    //     line_two: this.form.controls.line_two.value,
    //     country: this.form.controls.country.value,
    //     city: this.form.controls.city.value,
    //     state: this.form.controls.state.value,
    //     zip_code: this.form.controls.zip_code.value,
    //   };
    //   setTimeout(() => {
    //     this.isLoading = false;
    //     return this.profileAddressCreate.emit(data);
    //   }, 1000);
    // }
  }
  resetForm() {
    this.initForm();
  }
}
