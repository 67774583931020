import { Directive, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { searchAllMediaCategories } from '../+state/actions/media-category.actions';
import { LaunchpointCoreClientMediaCategoriesStateBaseComponent } from '../+state/media-categories-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientMediaCategoriesBaseComponent
  extends LaunchpointCoreClientMediaCategoriesStateBaseComponent
  implements OnInit
{
  form_control = new UntypedFormControl('');

  ngOnInit(): void {
    this._Store.dispatch(searchAllMediaCategories());
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.updateQuery({ search }),
      error: (error) => console.log('ERROR', error),
    });
  }
}
