import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  ICoreActivityHistorySearchQuery,
  ICoreCompany,
  ICoreCompanyTagify,
  ICoreUser,
  ICoreUserTagify,
  ILaunchpointSearchFilterDates,
  IQueryPageination,
  IQuerySort,
  ITagifyTag,
} from '@launchpoint/core-types';
import { debounceTime, distinctUntilChanged, firstValueFrom, takeUntil } from 'rxjs';
import {
  updateAllActivityHistoryPagination,
  updateAllActivityHistoryQuery,
  updateAllActivityHistorySorting,
} from '../+state/actions/activity-history.actions';
import { LaunchpointCoreClientActivityHistoryStateBaseComponent } from '../+state/activity-history-state-base.component';
import { ActivatedRoute } from '@angular/router';

@Directive()
export abstract class LaunchpointCoreClientActivityHistoryBaseComponent
  extends LaunchpointCoreClientActivityHistoryStateBaseComponent
  implements OnInit
{
  fb = inject(UntypedFormBuilder);
  activatedRoute = inject(ActivatedRoute);

  form_control = new FormControl<string>('');

  @Input() query: ICoreActivityHistorySearchQuery = {};
  @Input() pagination: IQueryPageination = { limit: 25, skip: 0, count: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  title = 'Activity History';

  public form: FormGroup<{
    account_ids: FormControl<ICoreCompanyTagify[]>;
    user_ids: FormControl<ICoreUserTagify[]>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
    event_name: FormControl<ITagifyTag[]>;
    method: FormControl<ITagifyTag[]>;
    status: FormControl<number[]>;
    // object: FormControl<string[]>;
    // original_url: FormControl<string[]>;
  }>;

  users: ICoreUser[] = [];
  accounts: ICoreCompany[] = [];

  ngOnInit() {
    this.getQuery();
    this.buildForm();
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    this._Store.dispatch(updateAllActivityHistoryQuery({ query: this.query }));
  }

  async getQuery() {
    const { query, title, admin } = await firstValueFrom(this.activatedRoute.data);
    if (query) {
      this.query = JSON.parse(query);
    }
    if (title) {
      this.title = title;
    }
    if (typeof admin === 'boolean') {
      this.admin = admin;
    }
  }

  buildForm() {
    this.form = this.fb.group({
      account_ids: [],
      user_ids: [],
      event_name: [],
      created_at: [],
      method: [],
      status: [],
      // object: [],
      // original_url: [],
    });

    if (this.query?.account_ids) {
      this.form.get('account_ids').patchValue(this.query.account_ids.map((id) => ({ value: id, data: null })));
    }
    if (this.query?.user_ids) {
      this.form.get('user_ids').patchValue(this.query.user_ids.map((id) => ({ value: id, data: null })));
    }

    // console.log('this.form', this.form.value);
  }

  runQuery(search?: string) {
    const query: ICoreActivityHistorySearchQuery = {
      ...this.query,
      search,
    };

    this._Store.dispatch(updateAllActivityHistoryQuery({ query }));
  }

  refresh() {
    this.ngOnInit();
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllActivityHistorySorting({ querySort: sortDataEmitter }));
  }

  pageChange(event: { pageSize: number; pageIndex: number; previousPageIndex: number }) {
    this.pagination.limit = event.pageSize;
    this.pagination.skip = Number(event.pageIndex) * Number(event.pageSize);
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.previousPageIndex = event.previousPageIndex;
    this._Store.dispatch(updateAllActivityHistoryPagination({ pagination: this.pagination }));
  }
}
