<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div class="card mb-5">
      <div class="card-header border-0">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ _config.title }}</h3>
        </div>
      </div>
      <form [formGroup]="form" class="form">
        <div class="card-body border-top p-9">
          <!-- START: Line One -->
          <div class="row mb-6">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <label class="col-lg-4 col-form-label fw-bold fs-6">Street 1</label>
                  <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Address Line 1" name="line_one" formControlName="line_one" [ngClass]="{
                      'is-invalid': form.controls.line_one.invalid && (form.controls.line_one.dirty || form.controls.line_one.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Street 1 is required',
                      control: form.controls.line_one
                    }">
                  </ng-container>
                </div>
                <!-- START: Line Two -->
                <div class="col-lg-6 fv-row">
                  <label class="col-lg-2 col-form-label fw-bold fs-6">Street 2 </label>
                  <small class="text-muted ps-0">(Optional)</small>
                  <input type="text" class="form-control form-control-lg form-control-solid"
                    placeholder="Address Line 2" name="line_two" formControlName="line_two" />
                </div>
              </div>
            </div>
          </div>

          <!-- START: City -->
          <div class="row mb-6">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <label class="col-lg-4 col-form-label fw-bold fs-6">City</label>
                  <input type="text" class="form-control form-control-lg form-control-solid" placeholder="City"
                    name="city" formControlName="city" [ngClass]="{
                      'is-invalid': form.controls.city.invalid && (form.controls.city.dirty || form.controls.city.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'City is required',
                      control: form.controls.city
                    }">
                  </ng-container>
                </div>

                <!-- START: State -->
                <div class="col-lg-3 fv-row">
                  <label class="col-form-label fw-bold fs-6">State</label>
                  <input type="text" class="form-control form-control-lg form-control-solid" placeholder="State"
                    name="state" formControlName="state" [ngClass]="{
                      'is-invalid': form.controls.state.invalid && (form.controls.state.dirty || form.controls.state.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'State is required',
                      control: form.controls.state
                    }">
                  </ng-container>
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'maxlength',
                      message: 'State should be 2 characters',
                      control: form.controls.state
                    }">
                  </ng-container>
                </div>

                <!-- START: Postal Code -->
                <div class="col-lg-3 fv-row">
                  <label class="col-form-label fw-bold fs-6">Postal Code</label>
                  <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Postal Code"
                    name="zip_code" formControlName="zip_code" [ngClass]="{
                      'is-invalid': form.controls.zip_code.invalid && (form.controls.zip_code.dirty || form.controls.zip_code.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Postal code is required',
                      control: form.controls.zip_code
                    }">
                  </ng-container>
                </div>
                <!-- START: Country -->
                <div class="col-lg-6 fv-row">
                  <label class="col-form-label fw-bold fs-6">Country</label>
                  <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Country"
                    name="country" formControlName="country" [ngClass]="{
                      'is-invalid': form.controls.country.invalid && (form.controls.country.dirty || form.controls.country.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Country is required',
                      control: form.controls.country
                    }">
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- START: Footer -->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button class="btn btn-secondary me-3" (click)="resetForm()">Cancel</button>

          <button type="button" class="btn btn-primary" [disabled]="isLoading || form.invalid || form.pristine"
            (click)="saveSettings()">
            <ng-container *ngIf="!isLoading">Save</ng-container>
            <ng-container *ngIf="isLoading">
              <span class="indicator-label" [style.display]="'block'">
                Please wait...{{ ' ' }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>
      </form>
    </div>

    <!-- START: FormError-->
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
      <ng-container
        *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <span role="alert">{{ message }}</span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>