export class ICoreUserIDMeDataAttributes {
  uuid: string;
  fname: string;
  lname: string;
  email: string;
  zip: string;
  street?: string;
  city?: string;
  state?: string;
  birthdate?: string;
}

export class ICoreUserIDMeResponseAttribute {
  handle: keyof ICoreUserIDMeDataAttributes;
  name: string;
  value: string;
}

export class ICoreUserIDMeResponseStatus {
  group: string;
  subgroups: string[];
  verified: boolean;
}

export class ICoreUserIDMeResponse {
  attributes: ICoreUserIDMeResponseAttribute[];
  status: ICoreUserIDMeResponseStatus[];
}
