import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SOCIAL_LINKS } from '../../../config/config';

@Component({
  selector: 'sd-social-icons',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './social-icons.component.html',
  styleUrl: './social-icons.component.scss',
})
export class SocialIconsComponent {
  social_links = SOCIAL_LINKS;
}
