import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN,
  ACTIVITY_HISTORY_EFFECTS,
  ACTIVITY_HISTORY_SERVICES,
  ActivityHistoryFeatureKey,
  LaunchpointActivityHistoryReducer,
  LaunchpointCoreClientActivityHistoryConfig,
  LaunchpointWebhookEventsReducer,
  LaunchpointWebhooksReducer,
  LibCoreClientPipesModule,
  WEBHOOK_EVENTS_EFFECTS,
  WEBHOOK_EVENTS_SERVICES,
  WebhookEventsFeatureKey,
  WEBHOOKS_EFFECTS,
  WEBHOOKS_SERVICES,
  WebhooksFeatureKey,
} from '@launchpoint/core-client';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebEllipsisMenuComponent } from '../../components/ellipsis-menu/ellipsis-menu.component';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';
import { SkeletonLoadersComponent } from '../../components/skeletons/skeleton-loaders/skeleton-loaders.component';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { CompanyTagifyWebComponent } from '../company/components/company-tagify/company-tagify.component';
import { LaunchpointCoreWebAddressAutoCompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { LaunchpointCoreWebUserSearchAheadComponent } from '../users/user-search-ahead-standalone/user-search-ahead.component';
import { LaunchpointCoreWebUsersModule } from '../users/users-core.module';
import { LaunchpointCoreWebUsersTagifyComponent } from '../users/users-tagify/users-tagify.component';
import { ACTIVITY_HISTORY_COMPONENTS } from './features/activity-history';
import { LaunchpointCoreWebActivityHistoryTableComponent } from './features/activity-history/activity-history-table/activity-history-table.component';
import { WEBHOOK_EVENTS_COMPONENTS } from './features/webhook-events';
import { WEBHOOKS_COMPONENTS } from './features/webhooks';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TagifyModule,
    NgbTooltipModule,
    LaunchpointCoreWebUsersTagifyComponent,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointCoreWebUserSearchAheadComponent,
    LaunchpointCoreWebUsersModule,
    LaunchpointFormControlDateRangeComponent,
    LaunchpointCoreWebEllipsisMenuComponent,
    LaunchpointCoreWebPaginationComponent,
    LaunchpointCoreWebActivityHistoryTableComponent,
    LaunchpointCoreWebAddressAutoCompleteComponent,
    LibCoreClientPipesModule,
    CompanyTagifyWebComponent,
    SkeletonLoadersComponent,
    // Activity History
    StoreModule.forFeature(ActivityHistoryFeatureKey, LaunchpointActivityHistoryReducer),
    EffectsModule.forFeature(ACTIVITY_HISTORY_EFFECTS),
    // Webhooks
    StoreModule.forFeature(WebhooksFeatureKey, LaunchpointWebhooksReducer),
    EffectsModule.forFeature(WEBHOOKS_EFFECTS),
    // Webhook Events
    StoreModule.forFeature(WebhookEventsFeatureKey, LaunchpointWebhookEventsReducer),
    EffectsModule.forFeature(WEBHOOK_EVENTS_EFFECTS),
  ],
  exports: [...ACTIVITY_HISTORY_COMPONENTS, ...WEBHOOKS_COMPONENTS, ...WEBHOOK_EVENTS_COMPONENTS],
  declarations: [...ACTIVITY_HISTORY_COMPONENTS, ...WEBHOOKS_COMPONENTS, ...WEBHOOK_EVENTS_COMPONENTS],
})
export class LaunchpointCoreWebActivityHistoryModule {
  static forRoot(config: LaunchpointCoreClientActivityHistoryConfig): ModuleWithProviders<LaunchpointCoreWebActivityHistoryModule> {
    return {
      ngModule: LaunchpointCoreWebActivityHistoryModule,
      providers: [
        ...ACTIVITY_HISTORY_SERVICES,
        ...WEBHOOKS_SERVICES,
        ...WEBHOOK_EVENTS_SERVICES,
        {
          provide: ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
