<form [formGroup]="form">
  <div class="px-7 py-5 d-flex align-items-center justify-content-between">
    <div class="fs-5 text-dark fw-bold">Filter Options</div>
  </div>

  <div class="separator border-gray-200"></div>

  <div class="px-7 mt-5">


    <div class="mb-5">
      <label class="form-label fw-semibold">Displaying Now</label>
      <launchpoint-boolean-filter [options]="options" [active]="form?.controls?.is_displaying?.value"
        (selected)="change('is_displaying', $event)" />
    </div>


    @if (admin) {
    <div class="mb-5">
      <label class="form-label fw-semibold">Active</label>
      <launchpoint-boolean-filter [active]="form?.controls?.active?.value" (selected)="change('active', $event)" />
    </div>


    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Banner Color</label>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="banner_color" name="banner_color" class="me-5" [settings]="settings"
          [whitelist]="whitelistColors$" inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Text Color</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form.controls.text_color.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all colors" (click)="form.controls.text_color.setValue([])"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="text_color" name="text_color" class="me-5" [settings]="settings"
          [whitelist]="whitelistColors$" inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Security Roles</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form.controls.security_roles.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all colors" (click)="form.controls.security_roles.setValue([])"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="security_roles" name="security_roles" class="me-5" [settings]="settings"
          [whitelist]="whitelistSecurityRoles$" inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
      </div>
    </div>
    }


    <div class="menu-item p-3">
      <label class="form-label fw-semibold">Created At</label>
      <launchpoint-date-range formControlName="created_at"
        [allOptions]="{ debug: false, displayMonths: 2 }" />
    </div>
  </div>

  <div class="rounded-bottom pb-3">
    <div class="d-flex justify-content-between align-items-center m-5 px-4">
      <div>
        <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="clearAll()">
          Clear All
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="apply()">Apply</button>
      </div>
    </div>
  </div>
</form>