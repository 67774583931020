import { Directive, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ETopicType, ILaunchpointSearchFilterDates, ITopicSearchQuery } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { updateAllTopicQuery } from '../+state/actions/topics.actions';
import { LaunchpointCoreClientNotificationsTopicsBaseComponent } from '../notifications-topics-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsTopicsFilterBaseComponent
  extends LaunchpointCoreClientNotificationsTopicsBaseComponent
  implements OnInit
{
  public fb = inject(UntypedFormBuilder);
  tagifyService = inject(TagifyService);

  query: ITopicSearchQuery;
  form: FormGroup<{
    topic_type: FormControl<{ value: ETopicType; data: ETopicType }[]>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
  }>;

  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistTypes$ = new BehaviorSubject<ETopicType[]>(Object.values(ETopicType));

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      created_at: [],
      topic_type: [],
    });
  }

  reset() {
    this.form.reset();
  }

  submit() {
    const data = this.form.value;

    const query: ITopicSearchQuery = {
      created_at: data.created_at,
      topic_type: data.topic_type.map((t) => t.value),
    };

    this._Store.dispatch(updateAllTopicQuery({ query }));
  }
}
