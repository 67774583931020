import { Directive, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { LaunchpointBlogHTTPService, LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { ICoreBlog, ICoreBlogSearchQuery, ICoreBlogTagify, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { map, Observable } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreWebBlogTagifyBaseWebComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  public _LaunchpointBlogHTTPService = inject(LaunchpointBlogHTTPService);
  public tagifyService = inject(TagifyService);

  @Input() blogs: ICoreBlog[] = [];

  @Input() query: ICoreBlogSearchQuery;
  @Input() pagination: IQueryPageination = { limit: 100, skip: 0 };
  @Input() querySort: IQuerySort = { created_at: -1 };

  blogsModel: ICoreBlogTagify[] = [];
  public whitelistBlogs$: Observable<ICoreBlogTagify[]>;

  public settings: TagifySettings = {
    placeholder: 'Search Blogs...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  @Output() selectedBlog = new EventEmitter<ICoreBlog[]>();

  ngOnInit(): void {
    if (this.blogs?.length > 0) {
      setTimeout(async () => {
        this.blogsModel = this.blogs.map((d) => {
          return {
            value: d.title,
            data: d,
          };
        });

        if (this.blogsModel?.length) {
          this.tagifyService.get('blogs').loadOriginalValues(this.blogsModel);
          this.settings.enforceWhitelist = true;
        }
      }, 250);
    }

    this.whitelistBlogs$ = this._LaunchpointBlogHTTPService
      .search({ query: this.query ?? {}, pagination: this.pagination, querySort: this.querySort })
      .pipe(
        map((f) => {
          const data = f.data.map((d) => {
            return {
              value: d.title,
              data: d,
            };
          });
          return data;
        })
      );
  }

  selectData(changes: ICoreBlogTagify[]) {
    // console.log({ changes });
    return this.selectedBlog.emit(changes.map((f) => f.data));
  }
}
