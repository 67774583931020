<div class="modal-content rounded">
  <div class="modal-header d-flex flex-row justify-content-between align-items-center bd-highlight py-4 flex-wrap">
    <div class="d-flex flex-stack">
      <div class="fs-3 fw-bold">
        Edit Media
      </div>
    </div>
    <div class="btn btn-sm btn-icon" (click)="activeModal.dismiss()">
      <i class="bi bi-x-lg m-3"></i>
    </div>
  </div>
  <div class="modal-body scroll-y p-10">
    <div class="pe-5">

      <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" [formGroup]="form">
        <div class="mb-0">

          <input autofocus style="display: none" />

          <div class="fv-row mb-10">
            <label class="form-label fw-bolder text-gray-900 fs-6 mb-5">Title</label>

            <input class="form-control form-control-lg form-control-solid" type="text" formControlName="title"
              [placeholder]="media?.title ?? 'Title'" name="title" autocomplete="off" [ngClass]="{
                'is-invalid': form.controls?.['title'].invalid,
                'is-valid': form.controls?.['title'].valid
              }" />
            <ng-container *ngIf="form.controls?.['title'].valid || form.controls?.['title'].pristine"
              class="fv-plugins-message-container">
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  message: 'Title is required',
                  control: form.controls?.['title']
                }">
            </ng-container>
          </div>

          <div class="fv-row mb-10">
            <label class="form-label fw-bolder text-gray-900 fs-6 mb-5">Description</label>

            <textarea class="form-control form-control-lg form-control-solid" type="text" formControlName="description"
              [placeholder]="media?.description ?? 'Description'" name="description" autocomplete="off"></textarea>

            <ng-container *ngIf="form.controls?.['description'].valid || form.controls?.['description'].pristine"
              class="fv-plugins-message-container">
            </ng-container>

            <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                message: 'Description is required',
                control: form.controls?.['description']
              }">
            </ng-container>
          </div>

          <div class="fv-row mb-10">
            <label class="form-label fw-bolder text-gray-900 fs-6 mb-5">Category</label>

            <launchpoint-media-categories-search-ahead cssClass="form-control form-control-lg form-control-solid fs-6"
              [selected]="media?.category?._id" (selectedCategory)="setFormCategory($event)" />
          </div>


          <div class="fv-row mb-10">
            <label class="form-label fw-bolder text-gray-900 fs-6 mb-5">Active</label>

            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
              <input class="form-check-input" type="checkbox" name="active" formControlName="active" />
              <label class="form-check-label ms-5">Active</label>
            </div>
          </div>


          <div class="d-flex align-items-center justify-content-end modal-footer px-0 pb-0">
            <button class="btn btn-secondary mb-2 mt-5 mx-4" (click)="close()">Cancel</button>
            <button type="button" (click)="submit()" [disabled]="form.invalid || form.pristine"
              class="btn btn-primary mb-2 mt-5">
              <span *ngIf="(mediaLoading$ | async) === false" class="indicator-label">
                Save
              </span>
              <ng-container *ngIf="mediaLoading$ | async">
                <span class="indicator-label">
                  Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </ng-container>
            </button>
          </div>


        </div>
      </form>
    </div>

  </div>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>