import { IHttpException, INotificationSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as NotificationActions from '../actions/notifications.actions';
import { NotificationsEntityState } from '../interfaces/notifications-entity.interface';

export const NotificationFeatureKey = 'NotificationReducer';

export interface NotificationsState extends EntityState<NotificationsEntityState> {
  selected_notification_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: INotificationSearchQuery;
  create_modal: boolean;
}

export const NotificationsEntityAdaptor: EntityAdapter<NotificationsEntityState> = createEntityAdapter<NotificationsEntityState>({
  selectId: (notification) => notification.notification_id,
});

export const initialNotificationState: NotificationsState = NotificationsEntityAdaptor.getInitialState({
  // set initial required properties
  selected_notification_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: null },
  error: null,
  success_message: null,
  create_modal: false,
});

export const NotificationReducer = createReducer(
  initialNotificationState,

  // Get by ID

  on(NotificationActions.getNotificationById, (state, { notification_id }) => {
    const entity: NotificationsEntityState = {
      notification_id,
      notification: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return NotificationsEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_notification_id: notification_id,
      loaded: true,
      loading: false,
    });
  }),

  on(NotificationActions.getNotificationByIdSuccess, (state, { notification }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: notification._id,
        changes: {
          notification,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
        selected_notification_id: notification._id,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(NotificationActions.getNotificationByIdFailure, (state, { notification_id, error }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: notification_id,
        changes: {
          error,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Search

  on(NotificationActions.searchAllNotifications, (state) => {
    return NotificationsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(NotificationActions.searchAllNotificationsSuccess, (state, { data }) => {
    const NotificationEntities = data.data.map((notification) => {
      const NotificationEntity: NotificationsEntityState = {
        notification_id: notification._id,
        notification: notification,
        loaded: true,
        loading: false,
        error: null,
      };
      return NotificationEntity;
    });
    return NotificationsEntityAdaptor.addMany(NotificationEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(NotificationActions.searchAllNotificationsFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),

  // Create

  on(NotificationActions.createNotification, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
    success_message: null,
  })),
  on(NotificationActions.createNotificationSuccess, (state, { data, success_message }) => {
    const entity: NotificationsEntityState = {
      notification_id: data._id,
      notification: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return NotificationsEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      create_modal: false,
      selected_notification_id: data._id,
    });
  }),

  on(NotificationActions.createNotificationFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),

  // Update

  on(NotificationActions.updateNotification, (state, { data }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(NotificationActions.updateNotificationSuccess, (state, { notification, success_message }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: notification._id,
        changes: {
          notification,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(NotificationActions.updateNotificationFailure, (state, { notification_id, error }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: notification_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Delete

  on(NotificationActions.deleteNotification, (state, { id }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(NotificationActions.deleteNotificationSuccess, (state, { data, success_message }) => {
    return NotificationsEntityAdaptor.removeOne(data._id, {
      ...state,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
    });
  }),
  on(NotificationActions.deleteNotificationFailure, (state, { notification_id, error }) => {
    return NotificationsEntityAdaptor.updateOne(
      {
        id: notification_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  //Mecanics

  on(NotificationActions.updateAllNotificationsQuery, (state, { query }) => {
    return NotificationsEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(NotificationActions.updateAllNotificationsPagination, (state, { pagination }) => {
    return NotificationsEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(NotificationActions.updateAllNotificationsPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loading: true,
    // error: null,
    success_message: null,
  })),

  on(NotificationActions.updateAllNotificationsSorting, (state, { querySort }) => {
    return NotificationsEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  })
);
