import { ELaunchpointFormsInputType } from '../dynamic-forms.constants';
import { ILaunchpointDynamicFormComputedField } from '../form/computed-fields/computed-fields.interface';
import { ILaunchpointDynamicForm } from '../form/form.interface';
import { ILaunchpointDynamicFormGroupQuestion } from '../form/groups/questions/form-groups-questions.interface';
import { ELaunchpointResponseStatus, ILaunchpointDynamicFormResponse } from './response.interface';
import { ILaunchpointDynamicFormResponseResponses } from './responses';

export interface ILaunchpointDynamicFormResponseSelectorResult {
  computed_field?: ILaunchpointDynamicFormComputedField;
  question_field?: ILaunchpointDynamicFormGroupQuestion;
  chart_data: {
    index: number;
    year: number;
    value: number;
  }[];
  year_1: ILaunchpointDynamicFormResponseResponses;
  year_2: ILaunchpointDynamicFormResponseResponses;
  comparison: {
    year_1: number | null;
    year_2: number | null;
    value_year_1: number | null;
    value_year_2: number | null;
    difference: number | null;
    percentage_change: number | null;
  };
  responses: ILaunchpointDynamicFormResponse[];
}

interface YearValueArray {
  year: number;
  value: number;
}

interface DataByField {
  [key: string]: YearValueArray[];
}

export class DynamicFormResponseComputeService {
  private defaultQuestionId: string;

  constructor(questionId = '6615e673a3e3bd051156b30f') {
    this.defaultQuestionId = questionId;
  }

  findResponsesByYear(year: number, response_data: ILaunchpointDynamicFormResponse[]): ILaunchpointDynamicFormResponse | null {
    if (!response_data) return null;
    const matchedResponse = response_data.find((b) =>
      b.responses.find((r) => {
        return r.question_id === this.defaultQuestionId && Number(r?.answer_value) === year;
      })
    );
    if (matchedResponse) {
      return matchedResponse;
    }
    return null; // return undefined if no matching response is found
  }

  findComputedValuesById(computed_field_id: string, response_data: ILaunchpointDynamicFormResponse[]) {
    const results = response_data
      .map((response) => ({
        year: response.responses.find((r) => r.question_id === this.defaultQuestionId)?.answer_value as string,
        value: response.responses.find((r) => r.computed_field_id === computed_field_id)?.answer_value as number,
      }))
      .filter((result) => result.year && result.value !== undefined) // Ensure both year and value are properly defined
      .sort((a, b) => a.year?.localeCompare?.(b.year)) // Sort by year descending
      .map((item, index) => ({
        index: index,
        year: parseInt(item.year),
        value: item.value,
      }));

    return results;
  }

  findQuestionValuesById(question_field_id: string, response_data: ILaunchpointDynamicFormResponse[]) {
    const results = response_data
      .map((response) => ({
        year: response.responses.find((r) => r.question_id === this.defaultQuestionId)?.answer_value as string,
        value: response.responses.find((r) => r.question_id === question_field_id)?.answer_value as number,
      }))
      .filter((result) => result.year && result.value !== undefined) // Ensure both year and value are properly defined
      .sort((a, b) => a.year?.localeCompare?.(b.year)) // Sort by year descending
      .map((item, index) => ({
        index: index,
        year: parseInt(item.year),
        value: item.value,
      }));

    return results;
  }

  // interface ILaunchpointDynamicFormResponse {
  //   responses: {
  //     computed_field_id?: string;
  //     question_id?: string;
  //     answer_value?: number;
  //   }[];
  // }

  compareResponses(
    field_id: string,
    year1Response?: Pick<ILaunchpointDynamicFormResponse, 'responses'>,
    year2Responses?: Pick<ILaunchpointDynamicFormResponse, 'responses'>
  ): {
    value_year_1: number | null;
    value_year_2: number | null;
    difference: number | null;
    percentage_change: number | null;
  } {
    const res: {
      value_year_1: number | null;
      value_year_2: number | null;
      difference: number | null;
      percentage_change: number | null;
    } = {
      value_year_1: null,
      value_year_2: null,
      difference: null,
      percentage_change: null,
    };
    try {
      const value_year_1: number | null =
        year1Response?.responses.find((f) => f?.computed_field_id === field_id || f?.question_id === field_id)?.answer_value ?? 0;
      // console.log({ value_year_1 });
      const value_year_2: number | null =
        year2Responses?.responses.find((f) => f?.computed_field_id === field_id || f?.question_id === field_id)?.answer_value ?? 0;
      // console.log({ value_year_2 });

      if (value_year_1 !== null && value_year_2 !== null) {
        const difference = value_year_1 !== null && value_year_2 !== null ? value_year_2 - value_year_1 : null;
        const percentage_change = difference !== null && value_year_1 !== null && value_year_1 !== 0 ? difference / value_year_1 : null;
        res.difference = difference;
        res.percentage_change = percentage_change;
      }

      res.value_year_1 = value_year_1;
      res.value_year_2 = value_year_2;

      return res;
    } catch (error) {
      console.log(error);
      return {
        value_year_1: null,
        value_year_2: null,
        difference: null,
        percentage_change: null,
      };
    }
  }

  private isNumericQuestion(question: ILaunchpointDynamicFormGroupQuestion): boolean {
    return [ELaunchpointFormsInputType.NUMBER, ELaunchpointFormsInputType.CURRENCY, ELaunchpointFormsInputType.PERCENTAGE].includes(
      question.question_type
    ); // Assuming 'Select' could have numeric-like values
  }

  private isNumeric(value: string): boolean {
    return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
  }

  private generateRandomId(): string {
    return Math.random().toString(36).substring(2, 15);
  }

  predictNextYearResponse(
    responses: ILaunchpointDynamicFormResponse[],
    form: ILaunchpointDynamicForm,
    latestYear: number
  ): ILaunchpointDynamicFormResponse {
    // Extract relevant data for prediction
    const dataByField = this.extractDataForPrediction(responses, form);

    // Predict values for the next year
    const nextYearResponses: ILaunchpointDynamicFormResponseResponses[] = form.groups
      .flatMap(
        (group) =>
          group?.questions?.map((question) => {
            const data: YearValueArray[] = dataByField[question._id] || [];
            if (data.length > 1 && this.isNumericQuestion(question)) {
              const predictedValue = this.predictNextYearValue(data);
              return {
                question_id: question._id,
                answer_value: predictedValue?.toString(),
                conditional_logic_applied: [],
                _id: null, // Simulate generating a new ID
              };
            }
            return null;
          }) || []
      )
      .filter((response) => response !== null) as any[];

    // Construct the new response object
    return {
      _id: '',
      form: form,
      response_status: ELaunchpointResponseStatus.COMPLETE,
      responses: nextYearResponses,
      attachments: [],
      accounts: [],
      users: [],
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  private extractDataForPrediction(responses: ILaunchpointDynamicFormResponse[], form: ILaunchpointDynamicForm): DataByField {
    const dataByField: DataByField = {};
    form.groups.forEach((group) => {
      group?.questions?.forEach((question) => {
        responses.forEach((response) => {
          const resp = response.responses.find((r) => r.question_id === question._id);
          if (resp && this.isNumeric(resp.answer_value)) {
            if (!dataByField[question._id]) {
              dataByField[question._id] = [];
            }
            dataByField[question._id].push({
              year: parseInt(response.responses.find((r) => r.question_id === this.defaultQuestionId)?.answer_value),
              value: parseFloat(resp.answer_value),
            });
          }
        });
      });
    });
    return dataByField;
  }

  predictNextYearValue(data: { year: number; value: number }[]): number | null {
    if (data.length < 2) {
      // Not enough data to make a prediction
      return null;
    }
    // console.log({ data });
    // Calculate the coefficients for a simple linear regression: y = mx + b
    const { slope, intercept } = this.linearRegression(data);

    // Predict the next year's value
    const latestYear = Math.max(...data.map((item) => item.year));
    // console.log({ latestYear });
    const predictedValue = (latestYear + 1) * slope + intercept;
    // console.log({ predictedValue });
    return predictedValue;
  }

  private linearRegression(data: { year: number; value: number }[]): { slope: number; intercept: number } {
    const n = data.length;
    const sumX = data.reduce((acc, curr) => acc + curr.year, 0);
    const sumY = data.reduce((acc, curr) => acc + curr.value, 0);
    const sumXx = data.reduce((acc, curr) => acc + curr.year * curr.year, 0);
    const sumXy = data.reduce((acc, curr) => acc + curr.year * curr.value, 0);

    const slope = (n * sumXy - sumX * sumY) / (n * sumXx - sumX * sumX);
    const intercept = (sumY - slope * sumX) / n;

    return { slope, intercept };
  }
}
