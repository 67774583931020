import { SECURITY_PERMISSIONS_DEFAULT_ROLE } from '../../security.constants';
import { ISecurityConfig } from '../../security.interface';
import { LAUNCHPOINT_NOTIFICATION_MESSAGES_SCHEMA_NAME } from '../messages/notifications-message.constants';
import { LAUNCHPOINT_NOTIFICATION_SCHEMA_NAME } from '../notifications/notifications.constants';
import { LAUNCHPOINT_TOPIC_SCHEMA_NAME } from '../topics/topics.constants';
import { LAUNCHPOINT_USER_NOTIFICATIONS_SCHEMA_NAME } from '../user-notifications/user-notifications.constants';

export enum ELaunchpointNotificationChannel {
  Email = 'Email',
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'Push Notification',
  SLACK = 'Slack',
}

export const NOTIFICATIONS_PRIMARY_REDIS = 'NOTIFICATIONS_PRIMARY_REDIS';
export const NOTIFICATIONS_MESSAGE_JOB_QUEUE = 'NOTIFICATIONS_MESSAGE_JOB_QUEUE';
export const NOTIFICATIONS_MESSAGE_CHILD_JOB_QUEUE = 'NOTIFICATIONS_MESSAGE_CHILD_JOB_QUEUE';

export const NOTIFICATIONS_DEFAULT_ABAC: ISecurityConfig = {
  debug: false,
  acl: {
    [SECURITY_PERMISSIONS_DEFAULT_ROLE]: [
      {
        effect: 'can',
        permissions: [
          {
            actions: 'manage',
            subject: LAUNCHPOINT_NOTIFICATION_MESSAGES_SCHEMA_NAME,
          },
          {
            actions: 'manage',
            subject: LAUNCHPOINT_NOTIFICATION_SCHEMA_NAME,
          },
          {
            actions: 'manage',
            subject: LAUNCHPOINT_TOPIC_SCHEMA_NAME,
          },
          {
            actions: 'manage',
            subject: LAUNCHPOINT_USER_NOTIFICATIONS_SCHEMA_NAME,
          },
        ],
      },
    ],
  },
};
