<div class="w-100">
  <form [formGroup]="form" class="form">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <!--begin::Title-->
      <h2 class="fw-bold text-dark">Complete Profile</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-muted fw-semibold fs-6">
        Let's Get Started
        <!-- <a href="#" class="link-primary fw-bold">Help Page</a>. -->
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="fv-row mb-10 fv-plugins-icon-container">
      <!--begin::Label-->
      <label class="form-label required">Company Name</label>
      <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Company Name"
        name="company_name" formControlName="company_name" [ngClass]="{
          'is-invalid': form.controls.company_name.invalid && (form.controls.company_name.dirty || form.controls.company_name.touched),
          'is-valid': form.controls.company_name.valid && (form.controls.company_name.dirty || form.controls.company_name.touched)
        }" />
      <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'required',
          message: 'First name is required',
          control: form.controls.company_name
        }">
      </ng-container>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="fv-row mb-10 fv-plugins-icon-container">
      <!--begin::Input group-->
      <div class="fv-row mb-0 fv-plugins-icon-container">
        <!--begin::Label-->
        <label class="fs-6 fw-semibold form-label required">Phone</label>
        <!--end::Label-->
        <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Last name" name="phone"
          formControlName="phone" [ngClass]="{
            'is-invalid': form.controls.phone.invalid && (form.controls.phone.dirty || form.controls.phone.touched),
            'is-valid': form.controls.phone.valid && (form.controls.phone.dirty || form.controls.phone.touched)
          }" />
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Last name is required',
            control: form.controls.phone
          }">
        </ng-container>
      </div>
      <!--end::Input group-->
    </div>
  </form>
</div>
<div class="d-flex flex-stack pt-10">
  <button type="button" class="btn btn-lg btn-primary me-3" (click)="submit()">
    Next
  </button>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container
    *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>