<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">
    <launchpoint-toolbar [options]="toolbarOptions" (refresh)="refresh()">
      <launchpoint-users-table-filter-card table_id="User">
        <launchpoint-users-filter />
      </launchpoint-users-table-filter-card>
    </launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div class="table-responsive">
            <table class="table-row-dashed table-hover table-row-gray-100 gs-0 gy-5 table align-middle">
              <ng-container *ngIf="loading$ | async">
                <span class="indicator-label m-5" [style.display]="'block'">
                  Please wait...
                  <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
                </span>
              </ng-container>

              <thead>
                <tr class="fw-bold fs-7 text-uppercase text-start text-gray-700">
                  <th class="min-w-100px sorting">Name</th>
                  <th class="min-w-100px sorting">Email / Phone</th>
                  <th class="min-w-100px sorting">Email Verified</th>
                  <th class="min-w-100px sorting">Phone Verified</th>
                  <th class="min-w-100px sorting">Last Login</th>
                  <th class="min-w-100px sorting">Created</th>
                  <th class="min-w-100px sorting">Security Roles</th>
                </tr>
              </thead>

              <tbody class="fs-6 fw-semibold">
                <tr *ngIf="(loading$ | async) === false && user_entities?.length === 0">
                  <td>No Users</td>
                </tr>

                <tr *ngFor="let entity of user_entities" [routerLink]="'./' + entity?.user?._id" routerLinkActive="router-link-active">
                  <ng-container *ngIf="entity">
                    <td>
                      <a [routerLink]="'./' + entity?.user?._id" routerLinkActive="router-link-active" class="text-dark text-hover-primary">
                        {{ entity?.user.profile?.full_name }}
                      </a>
                    </td>

                    <td>
                      {{ entity?.user?.email ?? entity?.user?.demographics?.phone?.phone_number_long }}
                    </td>

                    <td>
                      <i class="fs-4 bi bi-check-circle text-success" *ngIf="entity?.user?.email_verification?.email_verified"></i>
                      <i class="fs-4 bi bi-x-circle text-danger" *ngIf="!entity?.user?.email_verification?.email_verified"></i>
                    </td>

                    <td>
                      <i class="fs-4 bi bi-check-circle text-success" *ngIf="entity?.user?.demographics?.phone?.phone_verified"></i>
                      <i class="fs-4 bi bi-x-circle text-danger" *ngIf="!entity?.user?.demographics?.phone?.phone_verified"></i>
                    </td>

                    <td>
                      {{ entity?.user?.last_login | date }}
                    </td>

                    <td>
                      {{ entity?.user?.created_at | date }}
                    </td>

                    <td>
                      <div class="d-flex align-items-center me-3">
                        <span class="badge badge-light-info" *ngFor="let role of entity?.user?.security_roles">
                          {{ role }}
                        </span>
                      </div>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>

          <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
