import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointBlogTagsHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebBlogTagsTagifyBaseWebComponent } from './blog-tags-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [LaunchpointBlogTagsHTTPService],
  selector: 'launchpoint-blog-tags-tagify',
  templateUrl: 'blog-tags-tagify.component.html',
})
export class LaunchpointCoreWebBlogTagsTagifyWebComponent extends LaunchpointCoreWebBlogTagsTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
