<a
  class="block h-full transform rounded text-white transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-[1?.02] hover:shadow-lg"
  [routerLink]="['/blog', blog?.title_url]"
>
  <div class="relative h-[270px] w-full">
    @if (blog?.featured_image?.meta_data?.url) {
      <img [ngSrc]="blog?.featured_image?.meta_data?.url" [alt]="blog?.title" fill class="object-cover" priority />
    } @else {
      <img ngSrc="assets/images/blog-images/img6.png" alt="Placeholder Image" fill class="object-cover" />
    }
  </div>
  <div class="flex flex-col justify-between px-4 py-2">
    <div>
      <div class="mt-4 flex items-center justify-between">
        <div class="flex items-center">
          @if (blog?.author_user?.profile?.profile_photo_url) {
            <img [src]="blog?.author_user?.profile?.profile_photo_url" alt="Author Image" class="mr-2 h-8 w-8 rounded-full" priority />
          } @else {
            <i class="fa-solid fa-user-circle mr-2 text-lg"></i>
          }
          <span class="mr-4 text-sm">{{ getAuthor(blog?.author_user) }}</span>
        </div>

        <span class="text-xs">{{ blog?.created_at | date }}</span>
      </div>
      <div class="py-4">
        <span class="mr-2 rounded-full bg-white px-2 py-1 text-xs text-black">{{ blog?.category }}</span>
        <span class="text-xs" *ngIf="blog?.reading_time_min">{{ blog?.reading_time_min }} min read</span>
      </div>
      <div>
        <h2 class="text-2xl">{{ blog?.title | titlecase }}</h2>
        <h6 class="mt-2 line-clamp-3 text-gray-500">{{ blog?.description }}</h6>
      </div>
    </div>
  </div>
</a>
