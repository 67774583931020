import { ICoreUserSearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserAuthActions from '../actions/users.actions';
import { UsersEntityState } from '../interfaces/users.interface';
import { USERS_PROFILE_CONFIG } from './config';

export const UsersFeatureKey = 'UsersReducer';

export interface StateUsersReducer extends EntityState<UsersEntityState> {
  selected_user_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreUserSearchQuery;
}

export const UsersEntityAdaptor: EntityAdapter<UsersEntityState> = createEntityAdapter<UsersEntityState>({
  selectId: (user) => user.user_id,
});

export const initialUsersState: StateUsersReducer = UsersEntityAdaptor.getInitialState({
  // set initial required properties
  selected_user_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '', security_role: [], control_types: [], created_at: null },
  error: null,
  success_message: null,
});

export const UsersReducer = createReducer(
  initialUsersState,
  on(UserAuthActions.searchAllUsers, (state) => {
    return UsersEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserAuthActions.searchAllUsersApplyView, (state) => {
    return UsersEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserAuthActions.searchAllUsersSuccess, (state, { data }) => {
    const userEntities = data.data.map((user) => {
      const entity: UsersEntityState = {
        user_id: user._id,
        user: user,
        loaded: true,
        loading: false,
        error: null,
        profile_config: USERS_PROFILE_CONFIG,
      };
      return entity;
    });
    return UsersEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(UserAuthActions.searchAllUsersFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(UserAuthActions.updateAllUsersQuery, (state, { query }) => {
    return UsersEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(UserAuthActions.updateAllUsersPagination, (state, { pagination }) => {
    return UsersEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(UserAuthActions.updateAllUsersPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(UserAuthActions.updateAllUsersSorting, (state, { querySort }) => {
    return UsersEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(UserAuthActions.getUserByIdSetId, (state, { user_id }) => ({
    ...state,
    selected_user_id: user_id,
  })),
  on(UserAuthActions.getUserById, (state, { user_id }) => ({
    ...state,
    selected_user_id: user_id,
    loaded: false,
    loading: true,
    // error: null,
    success_message: null,
  })),
  on(UserAuthActions.getUserByIdSuccess, (state, { user }) => {
    const entity: UsersEntityState = {
      user_id: user._id,
      user: user,
      loaded: true,
      loading: false,
      error: null,
      profile_config: USERS_PROFILE_CONFIG,
    };
    return UsersEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_user_id: user._id,
      loaded: true,
      loading: false,
    });
  }),
  on(UserAuthActions.getUserByIdFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  on(UserAuthActions.updateUserProfile, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserProfileSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserProfileFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Create Address
  on(UserAuthActions.createUserAddress, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.createUserAddressSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.createUserAddressFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Update Address
  on(UserAuthActions.updateUserAddress, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserAddressSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserAddressFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          loading: false,
          loaded: true,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Email
  on(UserAuthActions.setUserEmail, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.setUserEmailSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.setUserEmailFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Password
  on(UserAuthActions.updateUserPassword, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserPasswordSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserPasswordFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Phone
  on(UserAuthActions.updateUserPhone, (state, { data }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: data.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserPhoneSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.updateUserPhoneFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          loading: false,
          error,
        },
      },
      {
        ...state,
      }
    );
  }),
  // Security Roles
  on(UserAuthActions.addUserRole, (state, { params }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: params.user_id,
        changes: {
          loading: true,
          loaded: true,
          error: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.addUserRoleSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.addUserRoleFailure, (state, { error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: state.selected_user_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.deleteUserRole, (state, { params }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: params.user_id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.deleteUserRoleSuccess, (state, { user, success_message }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user._id,
        changes: {
          user,
          loaded: true,
          loading: false,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAuthActions.deleteUserRoleFailure, (state, { user_id, error }) => {
    return UsersEntityAdaptor.updateOne(
      {
        id: user_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),
  // ACCOUNT MANIPULATION
  // on(UserAuthActions.accountSuccess, (state, { user, success_message }) => {
  //   return UsersEntityAdaptor.updateOne(
  //     {
  //       id: user._id,
  //       changes: {
  //         user,
  //         loading: false,
  //         success_message: success_message ?? null,
  //       },
  //     },
  //     {
  //       ...state,
  //     }
  //   );
  // }),
  // on(UserAuthActions.accountFailure, (state, { user_id, error }) => {
  //   return UsersEntityAdaptor.updateOne(
  //     {
  //       id: user_id,
  //       changes: {
  //         error,
  //         loading: false,
  //       },
  //     },
  //     {
  //       ...state,
  //     }
  //   );
  // }),
  // close alerts
  on(UserAuthActions.closeUserSuccessMessage, (state) => ({
    ...state,
    success_message: null,
  })),
  on(UserAuthActions.closeUserErrorMessage, (state) => ({
    ...state,
    // error: null,
  }))
);

// TODO: if we utilize accounts in the future
// on(UserAuthActions.updateUserAccount, (state, { params }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: params.account.account_id,
//       changes: {
//         error: null,
//         loaded: true,
//         loading: true,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
// on(UserAuthActions.updateUserAccountSuccess, (state, { user, success_message }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: user._id,
//       changes: {
//         user,
//         loading: false,
//         success_message: success_message ?? null,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
// on(UserAuthActions.updateUserAccountFailure, (state, { user_account_id, error }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: user_account_id,
//       changes: {
//         error,
//         loading: false,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
// on(UserAuthActions.deleteUserAccount, (state, { params }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: params.account.account_id,
//       changes: {
//         error: null,
//         loaded: true,
//         loading: true,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
// on(UserAuthActions.deleteUserAccountSuccess, (state, { user, success_message }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: user._id,
//       changes: {
//         user,
//         loading: false,
//         success_message: success_message ?? null,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
// on(UserAuthActions.deleteUserAccountFailure, (state, { user_account_id, error }) => {
//   return UsersEntityAdaptor.updateOne(
//     {
//       id: user_account_id,
//       changes: {
//         error,
//         loading: false,
//       },
//     },
//     {
//       ...state,
//     }
//   );
// }),
