import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICoreUserSearchQuery, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { searchAllUsers, updateAllUsersPagination, updateAllUsersQuery, updateAllUsersSorting } from '../../../../+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../+state/users-state-base.component';
import { LaunchpointCoreClientAuthHTTPService } from '../../../../../user/auth';

@Directive()
export abstract class UsersBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  constructor(
    public _Store: Store,
    public _router: Router,
    public _route: ActivatedRoute,
    public _LaunchpointCoreClientAuthHTTPService: LaunchpointCoreClientAuthHTTPService
  ) {
    super(_Store);
  }

  ngOnInit(): void {
    this._Store.dispatch(searchAllUsers());
  }

  runQuery(runQueries: ICoreUserSearchQuery) {
    this._Store.dispatch(updateAllUsersQuery({ query: runQueries }));
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllUsersSorting({ querySort: sortDataEmitter }));
  }

  pageChange(event) {
    const pagination: IQueryPageination = {
      count: event.pageSize,
      limit: event.pageSize,
      skip: Number(event.pageIndex) * Number(event.pageSize),
      pageIndex: event.pageIndex,
      previousPageIndex: event.previousPageIndex,
    };
    this._Store.dispatch(updateAllUsersPagination({ pagination: pagination }));
  }
}
