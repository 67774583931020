<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">

    <table class="table table-row-dashed table-hover table-row-gray-100 align-middle gs-0 gy-5">

      @if (loading$ | async){
      <span class="indicator-label m-5" [style.display]="'block'">
        Please wait...
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
      }

      <thead>
        <tr class="fw-bold text-gray-700 text-uppercase fs-7">
          <th class="max-w-200px">Message</th>
          <th class="min-w-150px">Display Range</th>
          <th class="max-w-50px">Displayed</th>
          <!-- <th class="max-w-50px">Active</th> -->
          <th class="min-w-125px text-end">Created</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">

        @if ((loading$ | async) === false && (bannerEntities$ | async)?.length === 0){
        <tr>
          <td>No Banners</td>
        </tr>
        }

        @for (entity of (bannerEntities$ | async); track entity) {
        <tr (click)="open(entity?.banner)">
          @if (entity){
          <td>
            <div class="d-flex flex-wrap" style="overflow-wrap: anywhere;">
              {{ entity?.banner?.message }}
            </div>

            <div class="mt-2" *ngIf="admin">
              <span *ngFor="let role of entity?.banner?.security_roles" class="badge badge-light me-1">
                {{ role }}
              </span>
            </div>
          </td>

          <td>
            {{ entity?.banner?.display_range_start_date | date: 'short' }} - {{ entity?.banner?.display_range_end_date |
            date: 'short' }}
          </td>

          <td>
            @if (isDisplaying(entity?.banner)){
            <span class="badge badge-light-success">On</span>
            } @else {
            <span class="badge badge-light-danger">Off</span>
            }
          </td>

          <!-- <td>
            @if (entity?.banner?.active){
            <i class="bi bi-check-circle fs-6 text-success"></i>
            } @else {
            <i class="bi bi-x-circle fs-6 text-danger"></i>
            }
          </td> -->

          <td class="text-end">
            {{ entity?.banner?.created_at | date }}

            <div class="text-muted fs-7">
              {{ getBannerCreatedBy(entity?.banner) }}
            </div>
          </td>
          }
        </tr>
        }
      </tbody>
    </table>

    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />

  </div>
</div>