import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LaunchpointCoreClientAccountsHTTPService } from '../../../services/core-accounts-http.service';
import * as profileSwapActions from '../../actions/user/profile-swap/profile-swap.actions';
@Injectable()
export class UserProfileSwapEffects {
  constructor(
    private actions$: Actions,
    private _LaunchpointCoreClientAccountsHTTPService: LaunchpointCoreClientAccountsHTTPService
  ) {}

  // select account
  selectAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.swapAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.selectAccount(action.payload).pipe(
          map((user) => {
            return profileSwapActions.swapAccountSuccess({
              user: user,
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.swapAccountFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.swapAccountFailure(error)))
    );
  });

  deselectAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.deselectAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.deselectAccount(action.payload).pipe(
          map((user) => {
            return profileSwapActions.swapAccountSuccess({
              user: user,
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.swapAccountFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.swapAccountFailure(error)))
    );
  });

  //  Get invitations
  getAccountInvitations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.getAccountInvitations),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.getAccountInvitations(action.payload).pipe(
          map((pending_invitations) => {
            return profileSwapActions.getAccountInvitationsSuccess({
              pending_invitations: pending_invitations,
              success_message: 'Invitations retrieved.',
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.getAccountInvitationsFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.getAccountInvitationsFailure(error)))
    );
  });

  //  Get invitations
  acceptInvitation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.acceptInvitation),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.acceptInvitation(action.payload).pipe(
          map((message) => {
            return profileSwapActions.acceptInvitationSuccess({
              message: message,
              success_message: 'Invitation accepted.',
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.acceptInvitationFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.acceptInvitationFailure(error)))
    );
  });

  //  Resend invitations
  resendInvite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.resendInvite),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.resendInvite(action.payload).pipe(
          map((message) => {
            return profileSwapActions.resendInviteSuccess({
              message: message,
              success_message: 'Invitation sent.',
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.resendInviteFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.resendInviteFailure(error)))
    );
  });

  // add sub user
  addSubUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.addSubUserAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.addSubUserAccount(action.payload).pipe(
          map((user) => {
            return profileSwapActions.addSubUserAccountSuccess({
              user: user,
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.addSubUserAccountFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.addSubUserAccountFailure(error)))
    );
  });
  // add main user
  addMainUserAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.addMainUserAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.addMainUserAccount(action.payload).pipe(
          map((user) => {
            return profileSwapActions.addMainUserAccountSuccess({
              user: user,
              success_message: 'Main user added.',
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.addMainUserAccountFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.addMainUserAccountFailure(error)))
    );
  });

  // remove user
  removeAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profileSwapActions.removeAccount),
      switchMap((action) => {
        return this._LaunchpointCoreClientAccountsHTTPService.removeAccount(action.payload).pipe(
          map((user) => {
            return profileSwapActions.removeAccountSuccess({
              user: user,
              success_message: 'User removed.',
            });
          }),
          catchError((error: IHttpRequestError) => of(profileSwapActions.removeAccountFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(profileSwapActions.removeAccountFailure(error)))
    );
  });
}
