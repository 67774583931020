import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CompanyBaseComponent } from '@launchpoint/core-client';
import { CompanyCreateModalComponent } from '../company-create-modal/company-create-modal.component';
import { IToolbarConfig } from '../../../../components/toolbar/toolbar.component';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'launchpoint-company',
  templateUrl: 'company.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class CompanyComponent extends CompanyBaseComponent implements OnInit {
  createModal: NgbModalRef;

  options: IToolbarConfig = {
    title: 'Companies',
    searchPlaceholder: 'Search by name or email',
    formControl: this.form_control,
    createTooltip: 'Create Company',
    queryCount: this.queryFilterCount$,
  };

  constructor(
    public _Store: Store,
    _router: Router,
    _route: ActivatedRoute,
    private modalService: NgbModal,
    fb: UntypedFormBuilder,
    private _swal: LaunchpointSweetAlertService
  ) {
    super(_Store, _router, _route, fb);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.success();
    this.errors();
  }

  paginate(page) {
    this.pageChange({ pageSize: this.pagination.limit, pageIndex: page, previousPageIndex: page - 1 });
  }

  open() {
    this.createModal = this.modalService.open(CompanyCreateModalComponent, { size: 'lg' });
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }
        this._swal.fireToast({ icon: 'success', title: data });
      },
    });
  }

  errors() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (data) => {
        if (!data) {
          return;
        }

        this._swal.fireToast({ icon: 'error', title: data.message });
      },
    });
  }
}
