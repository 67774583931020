import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../../auth';
import { ICoreBlog } from '../blog.interface';
import { ICoreBlogParamsCommentReplyIds, ICoreBlogParamsCommentReplyAdd } from './blog-comment-replies.interface';

/**
 * @Controller('blog-comment-replies')
 */
export interface ICoreBlogCommentRepliesController {
  /**
   * @Post('add')
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_ADD, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_ADD, LAUNCHPOINT_BLOG_SCHEMA)
   */
  add(params: ICoreBlogParamsCommentReplyAdd, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('remove')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_REMOVE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_REMOVE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  remove(params: ICoreBlogParamsCommentReplyIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
  /**
   * @Put('like')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_LIKE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_LIKE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  like(params: ICoreBlogParamsCommentReplyIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
  /**
   * @Put('dislike')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_DISLIKE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_COMMENT_REPLY_DISLIKE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  dislike(params: ICoreBlogParamsCommentReplyIds, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
}
