import { Component } from '@angular/core';
import { COMMUNITY_DATA } from '../../data/resources-data';
import { ResourcesBaseComponent } from '../resources-base.component';

@Component({
  selector: 'sd-events',
  templateUrl: 'events.component.html',
  styleUrls: ['events.component.scss'],
})
export class ResourcesEventsComponent extends ResourcesBaseComponent {
  items = COMMUNITY_DATA;
}
