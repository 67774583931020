<div [class]="cssClass">
  <div class="d-flex">
    <label [class]="labelClass">{{ label }}</label>
    <i class="ms-5 bi bi-info-circle" *ngIf="options?.tooltip" [ngbTooltip]="options?.tooltip"></i>
  </div>

  <div class="d-flex">
    <button type="button" class="btn btn-sm btn-active-light-primary" [ngClass]="{
      'btn-light-primary': selectedLabel === null
    }" (click)="change(null)">{{ options.allLabel }}</button>
    <button type="button" class="btn btn-sm btn-active-light-primary" [ngClass]="{
      'btn-light-primary': selectedLabel === true
    }" (click)="change(true)">{{ options.trueLabel }}</button>
    <button type="button" class="btn btn-sm btn-active-light-primary" [ngClass]="{
      'btn-light-primary': selectedLabel === false
    }" (click)="change(false)">{{ options.falseLabel }}</button>
  </div>
</div>