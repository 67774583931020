import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as TopicActions from '../actions/topics.actions';
import { RouterActions } from '../../../../../router/+state';
import { TopicsHTTPService } from '../../../../services/notification-topics-http.service';
import { getAllTopicsState } from '../selectors/topics.selectors';

@Injectable()
export class TopicEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _TopicsHTTPService: TopicsHTTPService
  ) {}

  getAllTopics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        TopicActions.searchAllTopics,
        TopicActions.updateAllTopicPagination,
        TopicActions.updateAllTopicQuery,
        TopicActions.updateAllTopicSorting
      ),
      withLatestFrom(this._Store.select(getAllTopicsState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._TopicsHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return TopicActions.searchAllTopicsSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(TopicActions.searchAllTopicsFailure(error)))
        );
      }),
      catchError((error) => of(TopicActions.searchAllTopicsFailure(error)))
    );
  });

  getTopicById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicActions.getTopicById),
      switchMap((action) => {
        return this._TopicsHTTPService.getById(action.topic_id).pipe(
          map((data) => {
            return TopicActions.getTopicByIdSuccess({
              topic: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(TopicActions.getTopicByIdFailure({ topic_id: action.topic_id, error: error.error })))
        );
      }),
      catchError((error) => of(TopicActions.getTopicByIdFailure(error)))
    );
  });

  createTopicById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicActions.createTopic),
      switchMap((action) => {
        return this._TopicsHTTPService.create(action.data).pipe(
          map((data) => {
            return TopicActions.createTopicSuccess({
              data: data,
              redirectPath: action.redirectPath,
              success_message: 'Topic Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(TopicActions.createTopicFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(TopicActions.getTopicByIdFailure(error)))
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createTopicuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(TopicActions.createTopicSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: [...data.redirectPath, data.data._id] } }));
          }
          return of();
        }),
        catchError((error) => of(TopicActions.getTopicByIdFailure(error)))
      );
    }
    // { dispatch: false }
  );

  updateTopicById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicActions.updateTopic),
      switchMap((action) => {
        return this._TopicsHTTPService.update(action.data).pipe(
          map((data) => {
            return TopicActions.updateTopicSuccess({
              topic: data,
              success_message: 'Topic Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(TopicActions.updateTopicFailure({ topic_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(TopicActions.getTopicByIdFailure(error)))
    );
  });

  deleteTopicById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(TopicActions.deleteTopic),
      switchMap((action) => {
        return this._TopicsHTTPService.delete(action.id).pipe(
          map((data) => {
            return TopicActions.deleteTopicSuccess({
              data: data,
              success_message: 'Topic Deleted',
            });
          }),
          catchError((error: IHttpRequestError) => of(TopicActions.deleteTopicFailure({ topic_id: action.id, error: error.error })))
        );
      }),
      catchError((error) => of(TopicActions.deleteTopicFailure(error)))
    );
  });
}
