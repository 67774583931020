import { Directive, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICoreUser } from '@launchpoint/core-types';
import { firstValueFrom, takeUntil } from 'rxjs';
import { loggedInUserSetNewPassword } from '../+state/actions';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../+state/auth-base.component';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { ILaunchpointAuth } from '../interfaces/auth.interface';
import { LaunchpointCoreClientAuthFormService } from '../services/auth-form-service';

@Directive()
export abstract class LaunchpointCoreClientAuthSetPasswordBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  _LaunchpointCoreClientAuthFormService = inject(LaunchpointCoreClientAuthFormService);

  form: FormGroup<ControlsOf<ILaunchpointAuth>>;
  email: string;
  passwordMeterValue = 0;
  user: ICoreUser;

  ngOnInit(): void {
    this.form = this._LaunchpointCoreClientAuthFormService.getSetPasswordForm;
    this.passwordMeter();

    this.user$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        this.user = user;
      },
    });
  }

  get disabled() {
    return this.form.invalid || this.isSamePassword();
  }

  passwordMeter() {
    const { max_length, min_length, require_number, require_special, require_uppercase } = this._config?.set_password?.password_settings ?? {};
    const values = Object.values(this._config?.set_password?.password_settings).filter((val) => val);
    const increment = 100 / values.length;

    this.form.controls.password.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
      next: (value) => {
        this.passwordMeterValue = 0;
        if (this.isSamePassword(value)) {
          return (this.passwordMeterValue = 0);
        }
        if (min_length && value.length >= min_length) {
          this.passwordMeterValue += increment;
        }
        if (min_length && value.length < min_length) {
          this.passwordMeterValue -= increment;
          this.passwordMeterValue = Math.max(0, this.passwordMeterValue);
        }
        if (max_length && value.length <= max_length && value.length >= min_length) {
          this.passwordMeterValue += increment;
        }
        if (max_length && value.length > max_length) {
          this.passwordMeterValue -= increment;
          this.passwordMeterValue = Math.max(0, this.passwordMeterValue);
        }
        if (require_number && value.match('^(?=.*[0-9])')) {
          this.passwordMeterValue += increment;
        }
        if (require_special && value.match(/^(?=.*[!@#$%^&*_\-()+=<>,./?[\]{}])/)) {
          this.passwordMeterValue += increment;
        }
        if (require_uppercase && value.match('^(?=.*[A-Z])')) {
          this.passwordMeterValue += increment;
        }
        if (values.length === 0) {
          this.passwordMeterValue = 0;
        }
      },
    });
  }

  isSamePassword(value?: string) {
    // todo -- how to client side compare hashed password?
    return false;
  }

  passwordMatch(type: 'uppercase' | 'number' | 'special' | 'min-length' | 'max-length') {
    switch (type) {
      case 'min-length': {
        return this.form.controls.password.value.match(`.{${this._config.set_password.password_settings.min_length},}`);
      }
      case 'max-length': {
        return this.form.controls.password.value.length <= this._config.set_password.password_settings.max_length;
      }
      case 'uppercase': {
        return this.form.controls.password.value.match('^(?=.*[A-Z])');
      }
      case 'number': {
        return this.form.controls.password.value.match('^(?=.*[0-9])');
      }
      case 'special': {
        return this.form.controls.password.value.match('^(?=.*[!@#$%^&*])');
      }
    }
  }

  async submit() {
    if (this.form.invalid || this.isSamePassword()) {
      return;
    }
    const user = await firstValueFrom(this.user$);

    this._Store.dispatch(
      loggedInUserSetNewPassword({
        data: { user_id: user._id, new_password: this.form.value.password, confirm_new_password: this.form.value.cPassword },
      })
    );
  }
}
