import { Component, signal } from '@angular/core';
import { BlogsActions, LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { ITagifyTag } from '@launchpoint/core-types';

@Component({
  selector: 'launchpoint-blog-tags',
  templateUrl: './blog-tags.component.html',
  styleUrls: [],
})
export class LaunchpointCoreWebBlogTagsComponent extends LaunchpointCoreClientBlogBaseComponent {
  tags$ = signal<string[]>([]);

  override loadForm(): void {
    this.tags$.set(this.blog.tags);
  }

  setTags(tags: ITagifyTag[]) {
    const values = tags?.map((tag) => tag?.value);
    this.tags$.set(values);
  }

  resetTags() {
    this.tags$.set(this.blog.tags ?? []);
  }

  saveTags() {
    this._Store.dispatch(
      BlogsActions.setTags({
        data: {
          _id: this.blog._id,
          tags: this.tags$(),
        },
      })
    );
  }
}
