import { Directive, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import {
  ENotificationChannelType,
  ENotificationMessageType,
  ICoreCompany,
  ICoreUser,
  ILaunchpointSearchFilterDates,
  INotificationMessageSearchQuery,
} from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { updateAllMessagesQuery } from '../+state/actions/messages.actions';
import { LaunchpointCoreClientNotificationsMessagesBaseComponent } from '../notifications-messages-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationsMessagesFilterBaseComponent
  extends LaunchpointCoreClientNotificationsMessagesBaseComponent
  implements OnInit
{
  public fb = inject(UntypedFormBuilder);
  tagifyService = inject(TagifyService);

  form: FormGroup<{
    channels: FormControl<{ value: ENotificationChannelType; data: ENotificationChannelType }[]>;
    job_types: FormControl<{ value: ENotificationMessageType; data: ENotificationMessageType }[]>;
    users: FormControl<{ value: string; data: ICoreUser }[]>;
    accounts: FormControl<{ value: string; data: ICoreCompany }[]>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
  }>;

  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistTypes$ = new BehaviorSubject<ENotificationMessageType[]>(Object.values(ENotificationMessageType));
  whitelistChannels$ = new BehaviorSubject<ENotificationChannelType[]>(Object.values(ENotificationChannelType));

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      created_at: [],
      job_types: [],
      channels: [],
      users: [],
      accounts: [],
    });
  }

  setUser(event: ICoreUser[]) {
    const values = event.map((e) => ({ value: e._id, data: e }));
    this.form.controls.users.setValue(values);
  }
  setAccounts(event: ICoreCompany[]) {
    const values = event.map((e) => ({ value: e._id, data: e }));
    this.form.controls.accounts.setValue(values);
  }

  reset() {
    this.form.reset();
  }

  submit() {
    const data = this.form.value;

    const query: INotificationMessageSearchQuery = {
      created_at: data?.created_at,
      job_types: data?.job_types?.map((t) => t?.value),
      channels: data?.channels?.map((t) => t?.value),
      users: data?.users?.map((t) => t?.value),
      accounts: data?.accounts?.map((t) => t?.value),
    };

    this.removeNullUndefined(query);

    this._Store.dispatch(updateAllMessagesQuery({ query }));
  }
}
