import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersEntityAdaptor, UsersFeatureKey, StateUsersReducer } from '../reducers/users.reducer';
import { ICoreUser } from '@launchpoint/core-types';

const selectAllUsersState = createFeatureSelector<StateUsersReducer>(UsersFeatureKey);

/**
 * ROOT STATE
 */
export const getAllUsersState = createSelector(selectAllUsersState, (state: StateUsersReducer) => state);

export const selectUserPagination = createSelector(getAllUsersState, (state: StateUsersReducer) => state.pagination);
export const selectUserQuerySort = createSelector(getAllUsersState, (state: StateUsersReducer) => state.querySort);
export const selectUserQuery = createSelector(getAllUsersState, (state: StateUsersReducer) => state.query);
export const selectUserSuccess = createSelector(getAllUsersState, (state: StateUsersReducer) => state.success_message);
export const selectUserLoading = createSelector(getAllUsersState, (state: StateUsersReducer) => state.loading);
export const selectUserLoaded = createSelector(getAllUsersState, (state: StateUsersReducer) => state.loaded);
export const selectUserError = createSelector(getAllUsersState, (state: StateUsersReducer) => state.error);

export const getAllUsersStateEntities = createSelector(selectAllUsersState, (state: StateUsersReducer) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = UsersEntityAdaptor.getSelectors(selectAllUsersState);

// select the array of user ids
export const selectUsersIds = selectIds;

// select the dictionary of user entities
export const selectUsersEntities = selectEntities;

// select the array of users
export const selectUsers = selectAll;

// select the total entity count
export const selectUsersTotal = selectTotal;

export const selectUserId = createSelector(getAllUsersState, (state: StateUsersReducer) => state?.selected_user_id);

export const selectUsersQueryCount = createSelector(getAllUsersState, (state: StateUsersReducer) => {
  let count = 0;
  try {
    count = count + (state.query?.security_role?.length ?? 0);
    count = count + (state.query?.control_types?.length ?? 0);
  } catch (error) {
    // console.log(error);
  }
  return count;
});

export const selectSelectedUserEntity = createSelector(
  getAllUsersState,
  selectUserId,
  (state: StateUsersReducer, selected_user_id) => state.entities[selected_user_id]
);

export const selectSelectedUser = createSelector(
  getAllUsersState,
  selectUserId,
  (state: StateUsersReducer, selected_user_id) => state.entities[selected_user_id]?.user
);

export const selectSelectedUserAddress = createSelector(selectSelectedUser, (user: ICoreUser) => user?.address);
export const selectSelectedUserAttributes = createSelector(selectSelectedUser, (user: ICoreUser) => user?.attributes);
export const selectSelectedUserNotificationTokens = createSelector(selectSelectedUser, (user: ICoreUser) => user?.notification_tokens);

export const selectSelectedUserLoading = createSelector(
  getAllUsersState,
  selectUserId,
  (state: StateUsersReducer, selected_user_id) => state.entities[selected_user_id]?.loading
);
export const selectSelectedUserSuccess = createSelector(
  getAllUsersState,
  selectUserId,
  (state: StateUsersReducer, selected_user_id) => state.entities[selected_user_id]?.success_message
);
export const selectSelectedUserError = createSelector(
  getAllUsersState,
  selectUserId,
  (state: StateUsersReducer, selected_user_id) => state.entities[selected_user_id]?.error
);
