import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ICoreCompany, ICoreCompanyAccount, ICoreCompanyParamsUpdate } from '@launchpoint/core-types';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base';

@Directive()
export abstract class CompanyUpdateSharedBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit {
  @Input() company$: Observable<ICoreCompany | ICoreCompanyAccount>;
  company: ICoreCompany | ICoreCompanyAccount;
  @Input() accountTypes: string[];

  form: FormGroup<{
    phone: FormControl<string>;
    account_type: FormControl<string>;
    company_name: FormControl<string>;
    email: FormControl<string>;
  }>;

  @Output() companyChanges = new EventEmitter<ICoreCompanyParamsUpdate>();

  constructor(public fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    if (!this.company$) {
      throw new Error('Company data must be included.');
    }
    if (!this.accountTypes) {
      throw new Error('Account types must be included.');
    }
    this.company$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (company) => {
        this.company = company;
        this.initForm(company);
      },
    });
  }

  initForm(company) {
    if (this.form) {
      this.form.patchValue(company);
    } else {
      this.form = this.fb.group({
        company_name: [company?.company_name || '', Validators.required],
        phone: [company?.phone || '', [Validators.required, Validators.minLength(10), Validators.maxLength(11), Validators.pattern('^[0-9]*$')]],
        account_type: [company?.account_type || '', [Validators.required, Validators.maxLength(100)]],
        email: [company?.email || '', Validators.email],
      });
    }
  }

  submit() {
    if (this.form.invalid) {
      throw new Error(this.form.errors[0]);
    }
    const obj: ICoreCompanyParamsUpdate = {
      account_id: this.company._id,
      company_name: this.form.value.company_name,
      phone: this.form.value.phone,
      account_type: this.form.value.account_type,
      email: this.form.value.email,
    };
    const valid_type = Object.values(this.accountTypes).includes(obj.account_type);
    if (!valid_type) {
      this.form.controls.account_type.setErrors({ invalid: 'Invalid Account type' });
    }

    return this.companyChanges.emit(obj);
  }
}
