import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebMediaUploadStandaloneComponent } from '../../../../../features/media/features/media/media-upload/media-upload-stand-alone/media-upload-stand-alone.component';
import { EngagesComponent } from './engages.component';
import { ExploreMainDrawerComponent } from './explore-main-drawer/explore-main-drawer.component';
import { HelpDrawerComponent } from './help-drawer/help-drawer.component';
import { MediaUploadDrawerComponent } from './media-upload-drawer/media-upload-drawer.component';
import { PurchaseToolbarComponent } from './purchase-toolbar/purchase-toolbar.component';

const COMPONENTS = [EngagesComponent, ExploreMainDrawerComponent, HelpDrawerComponent, PurchaseToolbarComponent, MediaUploadDrawerComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    InlineSVGModule,
    RouterModule,
    TagifyModule,
    NgbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LaunchpointCoreWebMediaUploadStandaloneComponent,
  ],
  exports: [...COMPONENTS],
})
export class EngagesModule {}
