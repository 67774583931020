<div class="d-flex flex-column flex-root">
  <div class="page d-flex flex-row flex-column-fluid">
    <launchpoint-demo-4-aside *ngIf="asideDisplay" id="kt_aside" data-kt-drawer="true" data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
      data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_toggle" class="aside bg-primary"
      [ngClass]="asideCSSClasses">
      <launchpoint-demo-4-aside-menu id="kt_aside_menu" data-kt-menu="true"
        class="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6">
      </launchpoint-demo-4-aside-menu>
    </launchpoint-demo-4-aside>
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <launchpoint-demo-4-header id="kt_header" [ngClass]="headerCSSClasses" class="header bg-body align-items-stretch"
        #ktHeader></launchpoint-demo-4-header>

      <div class="card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover"
        style="background-color: #444444; background-size: auto 100%; background-image: url(assets/media/misc/taieri.svg)">
        <!--begin::body-->
        <div class="card-body container-xxl pt-10 pb-8">
          <!--begin::Title-->
          <div class="d-flex align-items-center">
            <h1 class="fw-bold me-3 text-white">Launchpoint Demo</h1>
            <span class="fw-bold text-white opacity-50">Management System</span>
          </div>
          <!--end::Title-->
          <!--begin::Wrapper-->
          <div class="d-flex flex-column">
            <!--begin::Block-->
            <div class="d-lg-flex align-lg-items-center">
              <!--begin::Simple form-->
              <div
                class="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 w-xxl-850px h-lg-60px me-lg-10 my-5">
                <!--begin::Row-->
                <div class="row flex-grow-1 mb-5 mb-lg-0">
                  <!--begin::Col-->
                  <div class="col-lg-4 d-flex align-items-center mb-3 mb-lg-0">
                    <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'"
                      class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"></span>
                    <!--end::Svg Icon-->
                    <!--begin::Input-->
                    <input type="text" class="form-control form-control-flush flex-grow-1" name="search" value=""
                      placeholder="Your Search" />
                    <!--end::Input-->
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                    <!--begin::Desktop separator-->
                    <div class="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                    <!--end::Desktop separator-->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'"
                      class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"></span>
                    <!--end::Svg Icon-->
                    <!--begin::Select-->
                    <select class="form-select border-0 flex-grow-1" data-placeholder="Category"
                      data-hide-search="true">
                      <option value=""></option>
                      <option value="1" selected="selected" data-select2-id="select2-data-9-g0zo">Category</option>
                      <option value="2">In Progress</option>
                      <option value="3">Done</option>
                    </select>
                    <!--end::Select-->
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-4 d-flex align-items-center">
                    <!--begin::Desktop separator-->
                    <div class="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                    <!--end::Desktop separator-->
                    <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
                    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen018.svg'"
                      class="svg-icon svg-icon-5 svg-icon-gray-500 me-1"></span>
                    <!--end::Svg Icon-->
                    <!--begin::Link-->
                    <a href="#" class="btn btn-color-muted px-0 text-start rounded-0 py-1"
                      id="kt_modal_select_location_target">Location</a>
                    <!--end::Link-->
                  </div>
                </div>
                <!--end::Row-->
                <!--begin::Action-->
                <div class="min-w-150px text-end">
                  <button type="submit" class="btn btn-dark" id="kt_advanced_search_button_1">Search</button>
                </div>
                <!--end::Action-->
              </div>
              <!--end::Simple form-->
              <!--begin::Action-->
              <div class="d-flex align-items-center">
                <a class="fw-bold link-white fs-5" href="#">Advanced Search</a>
              </div>
              <!--end::Action-->
            </div>
            <!--end::Block-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::body-->
      </div>

      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <div class="post d-flex flex-column-fluid" id="kt_post">
          <launchpoint-layout-content id="kt_content_container"
            [ngClass]="contentContainerClasses"></launchpoint-layout-content>
        </div>
      </div>

      <launchpoint-layout-footer class="footer py-4 d-flex flex-lg-column" id="kt_footer"></launchpoint-layout-footer>
    </div>
  </div>
</div>

<launchpoint-layout-scripts-init></launchpoint-layout-scripts-init>
<app-scroll-top></app-scroll-top>

<!-- end:: Engage -->
<app-engages></app-engages>
<!-- end:: Engage -->