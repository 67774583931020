import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  configureURL,
  ICoreBlog,
  ICoreBlogController,
  ICoreBlogParamsCreate,
  ICoreBlogParamsUpdate,
  ICoreBlogSearchPayload,
  ICoreBlogSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../security';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointBlogHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreBlogController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';
  BASE_URL = 'blog';

  constructor(
    public _Store: Store,
    private http: HttpClient
    // @Optional() @Inject(ALERT_BANNER_CLIENT_CONFIG_TOKEN) public _config: LaunchpointCoreClientAlertBannerConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      // configUrl: _config?.base_url,
      version: 1,
      route: this.BASE_URL,
    });
  }

  search(params: ICoreBlogSearchPayload): Observable<ICoreBlogSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreBlogSearchResults>(this.API_URL + '/search', params, { headers });
      })
    );
  }

  findById(id: string): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreBlog>(this.API_URL + '/' + id, { headers });
      })
    );
  }
  create(params: ICoreBlogParamsCreate): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreBlog>(this.API_URL, params, { headers });
      })
    );
  }
  update(params: ICoreBlogParamsUpdate): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL, params, { headers });
      })
    );
  }
  markFeatured(id: string): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/featured/' + id, { headers });
      })
    );
  }
  delete(id: string): Observable<ICoreBlog> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreBlog>(this.API_URL + '/delete/' + id, { headers });
      })
    );
  }
  distinct(): Observable<string[]> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<string[]>(this.API_URL, { headers });
      })
    );
  }
}
