import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreUserIDMeV1Service, updateUserProfile } from '@launchpoint/core-client';
import { EIDMeScopes, ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom, map } from 'rxjs';
import { LaunchpointSweetAlertService } from '../../components';

@Component({
  selector: 'launchpoint-core-id-me',
  templateUrl: './id-me.component.html',
})
export class CoreUserIDMeComponent implements OnInit {
  _Store = inject(Store);
  _swal = inject(LaunchpointSweetAlertService);

  @Input() user: ICoreUser;
  @Input() scopes: EIDMeScopes[];
  @Input() redirectUrl?: string;
  @Input() debug = false;

  IDMeError: any;
  EIDMeScopes = EIDMeScopes;
  redirect_uri: string = window.location.href;

  access_token: string;
  expires_in: number;
  date = new Date().getTime();

  @Output() validatedUser = new EventEmitter<ICoreUser>();
  @Output() scope = new EventEmitter<EIDMeScopes>();

  constructor(
    private _CoreUserIDMeV1Service: CoreUserIDMeV1Service,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.ifHashSplit();
    this.checkUser();

    if (this.debug) console.log({ idme: this.user });
  }

  checkUser() {
    if (!this.user) {
      // console.error('IDMe -- No User!');
      return;
    }
    if (this.user && this.user.profile?.id_me_uuid) {
      return this.validatedUser.emit(this.user);
    }

    if (!this.access_token || (this.access_token && this.date + this.expires_in < new Date().getTime())) {
      return this.getToken();
    }
  }

  ifHashSplit() {
    const check = window.location.href.split('#');
    this.redirect_uri = this.redirectUrl ?? check[0];
  }

  async clickedVerify(scope: EIDMeScopes) {
    try {
      const { url } = await firstValueFrom(this._CoreUserIDMeV1Service.init({ scope, redirect_uri: this.redirect_uri }));
      this.scope.emit(scope);

      window.location.href = url;
      // window.open(url);
    } catch (error) {
      this.IDMeError = error;
      console.error(error);
      this._swal.fireToast({ icon: 'error', title: error });
    }
  }

  async validateUser(token: string) {
    if (!token) {
      console.log('No ID Me Access Token!');
      return;
    }

    if (!this.user) {
      console.log('No User!');
      return;
    }

    try {
      const user = await firstValueFrom(
        this._CoreUserIDMeV1Service.validate({
          user_id: this.user._id,
          access_token: token,
        })
      );

      if (!user) {
        throw new Error('Unable to validate user');
      }

      this._Store.dispatch(
        updateUserProfile({
          data: {
            user_id: user._id,
            ...user.profile,
          },
        })
      );

      this.debug && console.log('emitting validated idme user', user);

      this.validatedUser.emit(user);
    } catch (error) {
      this.IDMeError = error;
      console.error(error);
      this._swal.fireToast({ icon: 'error', title: error });
    }
  }

  getToken(): void {
    this._route.fragment
      .pipe(
        map((fragment) => new URLSearchParams(fragment)),
        map((params) => ({
          access_token: params.get('access_token'),
          token_type: params.get('token_type'),
          expires_in: params.get('expires_in'),
        }))
      )
      .subscribe((res) => {
        if (res.access_token) {
          this.access_token = res.access_token;
          this.expires_in = +res.expires_in;

          this.validateUser(this.access_token);
        }
      });
  }
}
