import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  IAuthenticationResponse,
  ICoreUser,
  ICoreUserParamsChangeEmail,
  ICoreUserParamsChangePassword,
  ICoreUserParamsCreateV3,
  ICoreUserParamsCreateWithoutEmail,
  ICoreUserParamsFindV3,
  ICoreUserParamsLoginPasswordV3,
  ICoreUserParamsResetPassword,
  ICoreUserParamsSetEmail,
  ICoreUserParamsSetPassword,
  ICoreUserParamsVerifyEmail,
  ICoreUserParamsVerifyV3,
  IUserAuthControllerV3,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig, ICoreAuthConfigRoutes } from '../interfaces/auth-storage-service.interface';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointCoreClientAuthHTTPService extends LaunchpointSecurityUserIdHeaderService implements IUserAuthControllerV3 {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';

  routes: ICoreAuthConfigRoutes = {
    public: {
      getUserByToken: 'auth',
      login: 'auth/log-in',
      logout: 'auth/log-out',
      createUser: 'auth/sign-up',
      createUserWithoutEmail: 'auth/sign-up-without-email',
      forgotPassword: 'auth/request-pass',
      refreshUserToken: 'auth/refresh',
      verify: 'auth/verify',
    },
    protected: {
      resetPassword: 'user-password/reset-pass',
      changePassword: 'user-password/change-pass',
      setPassword: 'user-password/set-pass',
      changeEmail: 'user-email/update',
      setEmail: 'user-email/set',
      verifyEmail: 'user-email/verify',
    },
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 3,
    });

    if (_CoreAuthConfig?.routes) {
      for (const key in _CoreAuthConfig.routes) {
        if (Object.prototype.hasOwnProperty.call(_CoreAuthConfig.routes, key)) {
          if (this.routes[key]) {
            this.routes[key] = _CoreAuthConfig.routes[key];
          } else {
            console.error('The route you are trying to set does not exist in the service. Please check your version if there have been updates');
          }
        }
      }
    }
  }

  // public methods
  login(body: ICoreUserParamsLoginPasswordV3): Observable<IAuthenticationResponse | { message: string }> {
    return this.http.post<IAuthenticationResponse | { message: string }>(this.API_URL + this.routes.public.login, body);
  }

  // public methods
  logout(email: string, password: string): Observable<IAuthenticationResponse> {
    return this.http.post<IAuthenticationResponse>(this.API_URL + this.routes.public.logout, { email, password });
  }

  /** CREATE =>  POST: add a new user to the server  */
  createUser(user: ICoreUserParamsCreateV3): Observable<IAuthenticationResponse> {
    return this.http.post<IAuthenticationResponse>(this.API_URL + this.routes.public.createUser, user);
  }

  /**
   * createUser_v3: `/v3/auth/sign-up`,
   *
   * authEmailVerificationToken_v2: `/v3/auth/verify`,
   *
   * CREATE =>  POST: add a new user to the server
   */
  createUserV3(user: ICoreUserParamsCreateV3): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(this.API_URL + this.routes.public.createUser, user);
  }

  createUserWithoutEmail(user: ICoreUserParamsCreateWithoutEmail): Observable<ICoreUser> {
    return this.http.post<ICoreUser>(this.API_URL + this.routes.public.createUserWithoutEmail, user);
  }

  /** Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false  */
  authVerificationTokenV3(body: ICoreUserParamsVerifyV3): Observable<IAuthenticationResponse> {
    return this.http.post<IAuthenticationResponse>(this.API_URL + this.routes.public.verify, body);
  }

  /** Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false  */
  forgotPassword(body: ICoreUserParamsFindV3): Observable<boolean> {
    return this.http.post<boolean>(this.API_URL + this.routes.public.forgotPassword, body);
  }

  getUserByToken(): Observable<ICoreUser> {
    return this.http.get<ICoreUser>(this.API_URL + this.routes.public.getUserByToken);
  }

  refreshUserToken(): Observable<IAuthenticationResponse> {
    return this.http.get<IAuthenticationResponse>(this.API_URL + this.routes.public.refreshUserToken);
  }

  /**
   * USER-* ROUTES
   */

  /**
   * Change password for user after 2FA verification code is sent to user. Code is valid for 5 minutes.
   */
  resetPassword(data: ICoreUserParamsResetPassword): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.protected.resetPassword, data, { headers });
      })
    );
  }

  /**
   * Sets password for user after account creation OR admin can set password for user
   */
  setPassword(data: ICoreUserParamsSetPassword): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.protected.setPassword, data, { headers });
      })
    );
  }

  /**
   * Change user password using current password as account verification.
   */
  changePassword(data: ICoreUserParamsChangePassword): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.protected.changePassword, data, { headers });
      })
    );
  }

  // loggedInUserSetNewPassword(data: ICoreUserPasswordsSetNewV2): Observable<ICoreUser> {
  //   return this.http.post<ICoreUser>(this.API_URL + this.routes.user.resetPassword, data);
  // }

  changeEmail(data: ICoreUserParamsChangeEmail): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.protected.changeEmail, data, { headers });
      })
    );
  }

  setEmail(data: ICoreUserParamsSetEmail): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.protected.setEmail, data, { headers });
      })
    );
  }

  verifyEmail(data: ICoreUserParamsVerifyEmail): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.protected.verifyEmail, data, { headers });
      })
    );
  }
}
