import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { CompanyUpdateBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-update',
  templateUrl: 'company-update.component.html',
})
export class CompanyUpdateComponent extends CompanyUpdateBaseComponent {
  // constructor(fb: UntypedFormBuilder, _Store: Store, _route: ActivatedRoute) {
  //   super(fb, _Store, _route);
  // }
}
