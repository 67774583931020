import { Directive, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ILaunchpointSearchFilterDates, ITagifyTag } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { BASE_TAGIFY_SETTINGS } from '../../../components';
import { LaunchpointCoreClientAlertBannerBaseComponent } from './alert-banner-base.component';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerFilterBaseComponent extends LaunchpointCoreClientAlertBannerBaseComponent {
  tagifyService = inject(TagifyService);

  form: FormGroup<{
    active: FormControl<boolean>;
    is_displaying: FormControl<boolean>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
    display_range_start_date: FormControl<ILaunchpointSearchFilterDates>;
    display_range_end_date: FormControl<ILaunchpointSearchFilterDates>;
    //
    banner_color: FormControl<ITagifyTag[]>;
    text_color: FormControl<ITagifyTag[]>; // { value: string; data: <T> }
    security_roles: FormControl<ITagifyTag[]>; // { value: string; data: <T> }
  }>;

  settings: TagifySettings = BASE_TAGIFY_SETTINGS;

  whitelistColors$ = new BehaviorSubject<string[]>(this.BANNER_TEXT_COLORS);

  override buildForm() {
    this.form = this.fb.group({
      active: [],
      is_displaying: [],
      created_at: [],
      display_range_start_date: [],
      display_range_end_date: [],
      banner_color: [],
      text_color: [],
      security_roles: [],
    });
  }

  change(controlName: string, value: boolean | null) {
    this.form.controls[controlName].setValue(value);
  }

  apply() {
    if (this.form.invalid) {
      return;
    }

    const { banner_color, text_color, ...rest } = this.form.value;

    this.updateQuery({
      ...rest,
      banner_color: banner_color?.map((tag) => tag?.value) ?? [],
      text_color: text_color?.map((tag) => tag?.value) ?? [],
      security_roles: text_color?.map((tag) => tag?.value) ?? [],
    });
  }

  reset() {
    this.tagifyService.get('banner_color')?.removeAllTags();
    this.tagifyService.get('text_color')?.removeAllTags();
    this.form.reset();
    this.apply();
  }
}
