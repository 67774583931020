import { Observable } from 'rxjs';
import { ICoreBlog } from '../blog.interface';
import { ICoreUserJWTDecorator } from '../../auth';
import { ICoreBlogSeoParamsUpdate } from './blog-seo.interface';

/**
 * @Controller('blog-seo')
 */
export interface ICoreBlogSeoController {
  /**
   * @Put('')
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_SEO_UPDATE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_SEO_UPDATE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  update(params: ICoreBlogSeoParamsUpdate, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
}
