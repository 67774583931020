import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { CoreUserV1Service, UserAuthActions } from '../../../user';
import { CompanyV2Service } from '../../services/company.service';
import * as CompanyActions from '../actions/company.actions';
import * as CompanySelectors from '../selectors/company.selectors';

@Injectable()
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _CompanyV1Service: CompanyV2Service,
    private _UserV1Service: CoreUserV1Service
  ) {}

  getAllCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CompanyActions.searchAllCompanies,
        CompanyActions.updateAllCompaniesPagination,
        CompanyActions.updateAllCompaniesQuery,
        CompanyActions.updateAllCompaniesSorting
      ),
      withLatestFrom(this._Store.select(CompanySelectors.getAllLaunchpointCompanyState)),
      switchMap((action) => {
        const pagination = action[1].pagination;
        const querySort = action[1].querySort;
        const query = action[1].query;
        return this._CompanyV1Service.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return CompanyActions.searchAllCompaniesSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.searchAllCompaniesFailure(error)))
        );
      }),
      catchError((error) => of(CompanyActions.searchAllCompaniesFailure(error)))
    );
  });

  getAllCompaniesUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CompanyActions.searchAllCompanyUsers
        // CompanyActions.updateAllCompanyUsersPagination,
        // CompanyActions.updateAllCompanyUsersSorting
      ),
      withLatestFrom(this._Store.select(CompanySelectors.getAllLaunchpointCompanyState)),
      switchMap(([action, company]) => {
        const entity = company.entities[action.query.account_ids[0]];
        if (!entity) {
          throw '!entity';
        }
        const pagination = entity.company_user_pagination;
        const querySort = entity.company_user_sorting;
        const query = action.query;
        return this._UserV1Service.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return CompanyActions.searchAllCompanyUsersSuccess({
              data: results,
              company_id: entity.company_id,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.searchAllCompanyUsersFailure(error)))
        );
      }),
      catchError((error) => of(CompanyActions.searchAllCompanyUsersFailure(error)))
    );
  });

  getCompanyById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.getCompanyById),
      switchMap((action) => {
        return this._CompanyV1Service.getById(action.company_id).pipe(
          map((data) => {
            return CompanyActions.getCompanyByIdSuccess({
              company: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.getCompanyByIdFailure({ company_id: action.company_id, error: error.error })))
        );
      }),
      catchError((error) => of(CompanyActions.getCompanyByIdFailure(error)))
    );
  });

  createCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.createCompany),
      switchMap((action) => {
        return this._CompanyV1Service.create(action.data).pipe(
          map((data) => {
            return CompanyActions.createCompanySuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.createCompanyFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(CompanyActions.getCompanyByIdFailure(error)))
    );
  });

  createCompanyAuthUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.createCompanySignUp),
      exhaustMap((action) =>
        this._CompanyV1Service.signUp(action.data).pipe(
          mergeMap((data) => {
            return [
              CompanyActions.createCompanySignUpSuccess({ data: data }),
              UserAuthActions.updateUserSuccess({ user: data.user }),
              UserAuthActions.getUserNewUserFromRefreshToken(),
            ];
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.createCompanySignUpFailed({ error: error.error })))
        )
      )
    );
  });

  updateCompanyById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.updateCompany),
      switchMap((action) => {
        return this._CompanyV1Service.update(action.data).pipe(
          map((data) => {
            return CompanyActions.updateCompanySuccess({
              company: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(CompanyActions.updateCompanyFailure({ company_id: action.data.account_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(CompanyActions.getCompanyByIdFailure(error)))
    );
  });

  deactivateCompanyById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.deactivateCompany),
      switchMap((action) => {
        return this._CompanyV1Service.deactivate(action.data).pipe(
          map((data) => {
            return CompanyActions.updateCompanySuccess({
              company: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(CompanyActions.updateCompanyFailure({ company_id: action.data.account_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(CompanyActions.getCompanyByIdFailure(error)))
    );
  });

  deleteCompanyById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.deleteCompany),
      switchMap((action) => {
        return this._CompanyV1Service.delete(action.company_id).pipe(
          map((data) => {
            return CompanyActions.deleteCompanySuccess({
              data: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.deleteCompanyFailure({ company_id: action.company_id, error: error.error })))
        );
      }),
      catchError((error) => of(CompanyActions.deleteCompanyFailure(error)))
    );
  });

  deleteCompanyInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyActions.deleteCompanyInfo),
      switchMap((action) => {
        return this._CompanyV1Service.delete(action.company_id).pipe(
          map((data) => {
            return CompanyActions.updateCompanySuccess({
              company: data,
            });
          }),
          catchError((error: IHttpRequestError) => of(CompanyActions.updateCompanyFailure({ company_id: action.company_id, error: error.error })))
        );
      }),
      catchError((error) => of(CompanyActions.updateCompanyFailure(error)))
    );
  });
}
