import { Observable } from 'rxjs';
import {
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormParamsCreate,
  ILaunchpointDynamicFormParamsUpdate,
  ILaunchpointDynamicFormParamsDelete,
  ILaunchpointDynamicFormSearchPayload,
  ILaunchpointDynamicFormSearchResults,
} from './form.interface';

/**
 * @route `forms`
 */
export interface ILaunchpointDynamicFormController {
  search(
    params: ILaunchpointDynamicFormSearchPayload
  ): Promise<ILaunchpointDynamicFormSearchResults> | Observable<ILaunchpointDynamicFormSearchResults>;

  getById(id: string): Promise<ILaunchpointDynamicForm | null> | Observable<ILaunchpointDynamicForm | null>;

  findByIdPublic(id: string): Promise<ILaunchpointDynamicForm | null> | Observable<ILaunchpointDynamicForm | null>;

  create(params: ILaunchpointDynamicFormParamsCreate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  update(params: ILaunchpointDynamicFormParamsUpdate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  archive(params: ILaunchpointDynamicFormParamsDelete): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  delete(params: ILaunchpointDynamicFormParamsDelete): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
}
