import { Directive } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../+state/users-state-base.component';

@Directive()
export abstract class UserCreateAccountBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  // public form: FormGroup<{
  //   account_id: FormControl<string>;
  //   user_id: FormControl<string>;
  //   account_type: FormControl<EUserAccountTypes>;
  //   selected: FormControl<boolean>;
  //   security_roles: FormControl<string[]>;
  // }>;

  // selectedCompany: SkylabCompanyEntityState;
  // query: IUserSearchQuery;

  // accountTypes = Object.values(EUserAccountTypes);
  // securityRoles: string[] = [];

  constructor(public fb: UntypedFormBuilder, public _Store: Store, public _router: Router) {
    super(_Store);
  }

  // ngOnInit(): void {
  //   //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //   //Add 'implements OnInit' to the class.
  //   this.selectedAccount$.pipe(takeUntil(this.destroy$)).subscribe({
  //     next: (account) => {
  //       this.buildForm(account);
  //       this.securityRoles = Object.keys(SKYLAB_ACCESS_CONTROL_LIST[account.account_type]);
  //     },
  //   });
  // }

  // buildForm(company: IUserAccounts) {
  //   this.form = this.fb.group({
  //     // account_id: [null, [Validators.required, Validators.maxLength(100)]],
  //     account_id: [company._id, [Validators.required]],
  //     user_id: [null, [Validators.required]],
  //     account_type: [company.account_type, [Validators.required, Validators.maxLength(100)]],
  //     selected: [false],
  //     security_roles: [null],
  //   });
  // }

  // get account_id() {
  //   return this.form.controls.account_id;
  // }

  // get user_id() {
  //   return this.form.controls.user_id;
  // }
  // get account_type() {
  //   return this.form.controls.account_type;
  // }
  // get selected() {
  //   return this.form.controls.selected;
  // }

  // get security_roles() {
  //   return this.form.controls.security_roles;
  // }

  // close() {
  //   return;
  // }

  // submit() {
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   const data: IUserAccountsParamsCreate = {
  //     user_id: this.user_id.value,
  //     account: {
  //       account_id: this.account_id.value,
  //       account_type: this.account_type.value,
  //       selected: false,
  //       security_roles: this.security_roles.value,
  //     },
  //   };
  //   this._Store.dispatch(createCompanyOnUser({ data }));
  //   this.close();
  // }
}
