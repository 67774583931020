import { Observable } from 'rxjs';
import { ICoreUser } from '../../../user-company-account.interface';
import { ICoreUserParamsDelete } from './core-delete.interface';

/**
 * @Controller('user-delete')
 */
export interface ICoreUserDeleteController {
  /**
   * Put()
   * @param body
   */
  delete(body: ICoreUserParamsDelete): Promise<ICoreUser> | Observable<ICoreUser>;
}
