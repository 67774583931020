import { Directive } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UsersEntityState } from '../../../+state/interfaces/users.interface';
import { USERS_PROFILE_CONFIG } from '../../../+state/reducers/config';
import { selectSelectedUserEntity } from '../../../+state/selectors/users.selectors';
import { LaunchpointCoreClientProfileConfig } from '../../../../user/core/core-profile/interfaces/core-profile.interface';
import { LaunchpointCoreClientBaseComponent } from '../../../../../components/base/base.component';

@Directive()
export abstract class UserAccountsBaseComponent extends LaunchpointCoreClientBaseComponent {
  form: UntypedFormGroup;
  userEntity$: Observable<UsersEntityState | undefined>;
  // ENUMEUserAccountTypes = EUserAccountTypes;
  _config: LaunchpointCoreClientProfileConfig = USERS_PROFILE_CONFIG;

  constructor(
    public _Store: Store,
    public _route: ActivatedRoute,
    public router: Router
  ) {
    super();
    this.userEntity$ = this._Store.select(selectSelectedUserEntity);
  }

  // securityRoles(account_type: EUserAccountTypes) {
  //   return Object.keys(SKYLAB_ACCESS_CONTROL_LIST[account_type]);
  // }

  // deleteAccount(account: IUserAccounts, user_id: string) {
  //   this._Store.dispatch(deleteUserAccount({ params: { account: account, user_id: user_id } }));
  // }
}
