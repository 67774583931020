import { Directive, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ICoreCompanySearchQuery,
  ICoreUser,
  ILaunchpointSearchFilterDates,
  IQueryPageination,
  IQuerySort,
  ITagifyTag,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  deleteCompany,
  searchAllCompanies,
  updateAllCompaniesPagination,
  updateAllCompaniesQuery,
  updateAllCompaniesSorting,
} from '../+state/actions/company.actions';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';

@Directive()
export abstract class CompanyBaseComponent extends CompanyStateBaseComponent implements OnInit {
  query: ICoreCompanySearchQuery;
  form_control = new FormControl<string>('');

  form: FormGroup<{
    account_owner: FormControl<string[]>;
    created_at: FormControl<ILaunchpointSearchFilterDates>;
    account_type: FormControl<ITagifyTag[]>;
  }>;

  public pagination: IQueryPageination = {
    skip: 0,
    pageIndex: 0,
    previousPageIndex: 0,
    limit: 25,
    count: 0,
  };

  users: ICoreUser[] = [];

  constructor(
    public _Store: Store,
    public _Router: Router,
    public _route: ActivatedRoute,
    public fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this._Store.dispatch(searchAllCompanies());
    this.buildForm();

    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
  }

  buildForm() {
    this.form = this.fb.group({
      account_owner: [this?.query?.account_owner || []],
      account_type: [],
      created_at: [],
    });
  }

  get account_owner() {
    return this.form.get('account_owner') as UntypedFormControl;
  }

  setAccountOwner(account_owner: string) {
    this.account_owner.setValue([account_owner]);
    this.runQuery();
  }

  runQuery(search?: string) {
    // console.log('runQuery', search, this?.account_owner?.value);
    const companyQuery: ICoreCompanySearchQuery = { search: search, account_owner: this?.account_owner?.value };
    // console.log('companyQuery', companyQuery);
    this._Store.dispatch(updateAllCompaniesQuery({ query: companyQuery }));
  }

  refresh() {
    this.ngOnInit();
  }

  sortData(sortDataEmitter: IQuerySort) {
    this._Store.dispatch(updateAllCompaniesSorting({ querySort: sortDataEmitter }));
  }

  pageChange(event: { pageSize: number; pageIndex: number; previousPageIndex: number }) {
    this.pagination.limit = event.pageSize;
    this.pagination.skip = Number(event.pageIndex) * Number(event.pageSize);
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.previousPageIndex = event.previousPageIndex;
    this._Store.dispatch(updateAllCompaniesPagination({ pagination: this.pagination }));
    // this._Store.dispatch(searchAllCompanies()); // TODO: I don't think we need this.
  }

  deleteCompany(deleteById: string) {
    this._Store.dispatch(deleteCompany({ company_id: deleteById }));
  }
}
