import { ICoreUser, ICoreUserAccount } from '@launchpoint/core-types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StateUserReducer, userFeatureKey } from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<StateUserReducer>(userFeatureKey);

/**
 * INIT
 */
export const selectUserInitCheckComplete = createSelector(selectUserState, (state: StateUserReducer) => state.initCheckComplete);
/**
 * Authentication
 */
export const selectUserToken = createSelector(selectUserState, (state: StateUserReducer) => state.accessToken);
export const selectUserRefreshToken = createSelector(selectUserState, (state: StateUserReducer) => state.refreshToken);
export const selectReturnUrl = createSelector(selectUserState, (state: StateUserReducer) => state.returnUrl as string[]);
// this will utilize who the person logged in when you have an account selected - this will never change even if you are switched over to someone else

export const selectUserLoading = createSelector(selectUserState, (state: StateUserReducer) => state.loading);
export const selectUserError = createSelector(selectUserState, (state: StateUserReducer) => state.error);
export const selectUserErrorActions = (actions: string[]) =>
  createSelector(selectUserState, (state: StateUserReducer) => {
    if (actions.includes(state.error.action)) {
      return state.error;
    }
  });
export const selectUserSuccessMessage = createSelector(selectUserState, (state: StateUserReducer) => state.success);

export const selectUserUpdateAddressLoading = createSelector(selectUserState, (state: StateUserReducer) => state.loading);

/**
 * USER
 */
export const selectUser = createSelector(selectUserState, (state: StateUserReducer) => state.user as any);
export const selectUserProfile = createSelector(selectUserState, (state: StateUserReducer) => state.user?.profile);
export const selectUserEmailVerified = createSelector(selectUserState, (state: StateUserReducer) => state?.user.email_verification?.email_verified);

export const selectUserAccounts = createSelector(selectUserState, (state: StateUserReducer) => state?.user?.accounts ?? []);

export const selectUserAddress = createSelector(selectUser, (user: ICoreUser) => user?.address);
/**
 * @description returns entire account - defaults to first account
 * this won't work if user has no accounts
 * skylab uses this bc everything is account based
 */
export const selectSelectedUserAccount = createSelector(selectUserState, (state: StateUserReducer) => {
  const user = state.user as ICoreUser;

  // console.log(user);
  if (!user?.accounts || user.accounts.length === 0) {
    return null;
  }
  // console.log('selectSelectedUserAccount', user.accounts);
  let account: ICoreUserAccount = user.accounts?.find((a) => a.selected === true);
  account = user?.accounts.find((a) => a.selected === true);
  // console.log(account);
  // This is also done when logging in to the user on the server, returning a user.
  if (!account) {
    account = user.accounts[0];
    console.warn('User has no selected account. Defaulting to first account. ');
  }
  // console.log({ account });
  return account;
});

/**
 * @description if user has accounts, and user_id is populated return profile w/ user_id otherwise
 * defaults to logged in user if they don't have accounts
 */
// IE This one is used in SP for profile card
export const selectSelectedUserProfile = createSelector(selectUserState, (state: StateUserReducer) => {
  const user = state?.user as ICoreUser;
  if (!user) {
    return null;
  }
  // check if user has account selected
  const account: ICoreUserAccount = user?.accounts?.find((a) => a.selected === true);
  // if account and user id is populated return profile w/ accounts
  try {
    if (account) {
      if (account?.user_id) {
        if (typeof account?.user_id !== 'string') {
          return (account.user_id as ICoreUser).profile as any; //ICoreUserProfile;
        } else {
          throw new Error('account.user_id is not populated');
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return user.profile;
});

/**
 *  selects user id
 */
export const selectSelectedUserId = createSelector(selectUserState, (state: StateUserReducer): string => {
  if (!state?.user?._id) {
    return null;
  }
  const user = state?.user as ICoreUser;

  let user_id = user._id;
  try {
    const account: any = user.accounts?.find((a) => a.selected === true); // have to type to any because interface for account.user_id is unkown|string, but we have to notate further in 97 in clients defined populate user
    if (account?.user_id) {
      user_id = account?.user_id._id as string;
    }
  } catch (error) {
    console.error(error);
  }

  // This is also done when logging in to the user on the server, returning a user.
  return user_id;
});

/**
 *  selects users profile
 */
export const selectSelectedAccountId = createSelector(selectUserState, (state: StateUserReducer): string => {
  const user = state.user as ICoreUser;
  let account_id = user.accounts[0].account_id as string;
  // console.log('selectSkylabSelectedUserAccount', user.accounts);
  try {
    const account = user.accounts?.find((a) => a.selected === true);
    if (account?.account_id) {
      account_id = account?.account_id as string;
    }
  } catch (error) {
    console.error(error);
  }

  // This is also done when logging in to the user on the server, returning a user.

  // console.log({ account });
  return account_id;
});

export const selectSelectedUserSecurityRoles = createSelector(selectUserState, (state: StateUserReducer) => {
  return state.user.security_roles;
});

// export const selectUserError = createSelector(selectUserState, (state: StateUserReducer) => state.userError);

/**
 * User Objects
 */
// export const selectUserId = createSelector(selectUserState, (state: StateUserReducer) =>
// state.user?._id
// );
