<tagify
  class="me-5"
  [ngModel]="topicsModel"
  (ngModelChange)="selectData($event)"
  [whitelist]="whitelistTopics$"
  [settings]="settings"
  [inputClass]="inputClass"
  name="topics"
/>

