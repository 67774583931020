import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointCoreClientMediaSearchAheadBaseComponent, LaunchpointMediaBaseHTTPService } from '@launchpoint/core-client';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-media-search-ahead',
  templateUrl: 'media-search-ahead.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbTypeaheadModule],
  providers: [LaunchpointMediaBaseHTTPService],
})
export class LaunchpointCoreMediaSearchAheadComponent extends LaunchpointCoreClientMediaSearchAheadBaseComponent {}
