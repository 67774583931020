import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContentUploadQueryParams, IMedia } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { FileEntity } from '../interface/file-entity.interface';
import { LaunchpointMediaService } from './media.service';

@Injectable()
export class LaunchpointMediaUploadService extends LaunchpointSecurityUserIdHeaderService {
  private unsubscribe = new Subject();

  constructor(
    public _Store: Store,
    private _AdminMediaService: LaunchpointMediaService
  ) {
    super(_Store);
  }

  public uploadMediaService(fileEntity: FileEntity, options?: IContentUploadQueryParams) {
    const updatedFileEntity = fileEntity;
    const tags: string[] = [];
    if (options?.metadata) {
      tags.push(...Object.values(options?.metadata));
    }
    return new Observable<FileEntity>((updatedFile$) => {
      this._AdminMediaService
        .create({
          title: fileEntity.file.name,
          category: fileEntity?.category?._id ?? null,
          url: null,
        })
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap((media) => {
            return this._AdminMediaService.uploadService(fileEntity.file, media._id, options).pipe(
              map((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                  // calculate the progress percentage
                  const percentDone = Math.round((100 * event.loaded) / event.total);
                  // pass the percentage into the progress-stream
                  updatedFileEntity.uploadPercentage = percentDone;
                  updatedFileEntity.loading = true;
                  updatedFileEntity.media = media;
                  updatedFile$.next(updatedFileEntity);
                } else if (event instanceof HttpResponse) {
                  // Close the progress-stream if we get an answer form the API
                  // The upload is complete
                  updatedFileEntity.media = event.body as IMedia;
                  updatedFileEntity.uploadPercentage = null;
                  updatedFileEntity.loading = false;
                  updatedFile$.next(updatedFileEntity);
                  updatedFile$.complete();
                  this.unsubscribe.next(null);
                  this.unsubscribe.complete();
                }
              }),
              catchError((error) =>
                // if upload fails, delete the media obj that was created on 27
                this._AdminMediaService.delete({ _id: media._id }).pipe(
                  map(() => {
                    console.log('in error return from delete pipe');
                    return error;
                  })
                )
              )
            );
          }),
          catchError((error) => {
            updatedFileEntity.uploadPercentage = 0;
            updatedFileEntity.loading = false;
            updatedFileEntity.error = error;
            updatedFile$.next(updatedFileEntity);
            updatedFile$.complete();
            this.unsubscribe.next(null);
            this.unsubscribe.complete();

            return of(error);
          })
        )
        .subscribe();
    });
  }

  public replaceMediaService(media_id: string, fileEntity: FileEntity, options?: IContentUploadQueryParams) {
    const updatedFileEntity = fileEntity;
    const tags: string[] = [];
    if (options?.metadata) {
      tags.push(...Object.values(options?.metadata));
    }
    return new Observable<FileEntity>((updatedFile$) => {
      this._AdminMediaService
        .getById(media_id)
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap((media) => {
            return this._AdminMediaService.uploadService(fileEntity.file, media._id, options).pipe(
              map((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                  // calculate the progress percentage
                  const percentDone = Math.round((100 * event.loaded) / event.total);
                  // pass the percentage into the progress-stream
                  updatedFileEntity.uploadPercentage = percentDone;
                  updatedFileEntity.loading = true;
                  updatedFileEntity.media = media;
                  updatedFile$.next(updatedFileEntity);
                } else if (event instanceof HttpResponse) {
                  // Close the progress-stream if we get an answer form the API
                  // The upload is complete
                  updatedFileEntity.media = event.body as IMedia;
                  updatedFileEntity.uploadPercentage = null;
                  updatedFileEntity.loading = false;
                  updatedFile$.next(updatedFileEntity);
                  updatedFile$.complete();
                  this.unsubscribe.next(null);
                  this.unsubscribe.complete();
                }
              }),
              catchError((error) =>
                // if upload fails, delete the media obj that was created on 27
                this._AdminMediaService.delete({ _id: media._id }).pipe(
                  map(() => {
                    console.log('in error return from delete pipe');
                    return error;
                  })
                )
              )
            );
          }),
          catchError((error) => {
            updatedFileEntity.uploadPercentage = 0;
            updatedFileEntity.loading = false;
            updatedFileEntity.error = error;
            updatedFile$.next(updatedFileEntity);
            updatedFile$.complete();
            this.unsubscribe.next(null);
            this.unsubscribe.complete();

            return of(error);
          })
        )
        .subscribe();
    });
  }
}
