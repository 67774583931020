import { ICoreAlertBannerSearchQuery, IHttpException, IQueryPageination, IQuerySort } from '@launchpoint/core-types';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as UserAlertBannerActions from '../actions/alert-banner.actions';
import { AlertBannerEntityState } from '../interfaces/alert-banner.interface';

export const AlertBannerFeatureKey = 'AlertBannerReducer';

export interface StateAlertBannerReducer extends EntityState<AlertBannerEntityState> {
  selected_banner_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: ICoreAlertBannerSearchQuery;
}

export const AlertBannerEntityAdaptor: EntityAdapter<AlertBannerEntityState> = createEntityAdapter<AlertBannerEntityState>({
  selectId: (data) => data.banner_id,
});

export const initialAlertBannerState: StateAlertBannerReducer = AlertBannerEntityAdaptor.getInitialState({
  // set initial required properties
  selected_banner_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  error: null,
  success_message: null,
  entities: {},
  ids: [],
});

export const LaunchpointAlertBannerReducer = createReducer(
  initialAlertBannerState,
  on(UserAlertBannerActions.searchAllAlertBanners, (state) => {
    return AlertBannerEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(UserAlertBannerActions.searchAllAlertBannersSuccess, (state, { data }) => {
    const userEntities = data.data.map((data) => {
      const userEntity: AlertBannerEntityState = {
        banner_id: data._id,
        banner: data,
        loaded: true,
        loading: false,
        error: null,
        // profile_config: USERS_PROFILE_CONFIG,
      };
      return userEntity;
    });
    return AlertBannerEntityAdaptor.addMany(userEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(UserAlertBannerActions.searchAllAlertBannersFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(UserAlertBannerActions.updateAllAlertBannerQuery, (state, { query }) => {
    return AlertBannerEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(UserAlertBannerActions.updateAllAlertBannerPagination, (state, { pagination }) => {
    return AlertBannerEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(UserAlertBannerActions.updateAllAlertBannerPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loaded: false,
    loading: true,
    success_message: null,
  })),
  on(UserAlertBannerActions.updateAllAlertBannerSorting, (state, { querySort }) => {
    return AlertBannerEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(UserAlertBannerActions.getAlertBannerByIdSetId, (state, { banner_id }) => ({
    ...state,
    selected_banner_id: banner_id,
  })),
  on(UserAlertBannerActions.getAlertBannerById, (state, { banner_id }) => ({
    ...state,
    selected_banner_id: banner_id,
    loaded: false,
    loading: false,
    // error: null,
    success_message: null,
  })),
  on(UserAlertBannerActions.getAlertBannerByIdSuccess, (state, { data }) => {
    const userEntity: AlertBannerEntityState = {
      banner_id: data._id,
      banner: data,
      loaded: true,
      loading: false,
      error: null,
      // profile_config: USERS_PROFILE_CONFIG,
    };
    return AlertBannerEntityAdaptor.upsertOne(userEntity, {
      selected_banner_id: data._id,
      loaded: true,
      loading: false,
      ...state,
    });
  }),
  on(UserAlertBannerActions.getAlertBannerByIdFailure, (state, { banner_id, error }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: banner_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  on(UserAlertBannerActions.createAlertBanner, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(UserAlertBannerActions.createAlertBannerSuccess, (state, { data, success_message }) => {
    const entity: AlertBannerEntityState = {
      banner_id: data._id,
      banner: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };

    return AlertBannerEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      selected_banner_id: data._id,
    });
  }),
  on(UserAlertBannerActions.createAlertBannerFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),
  on(UserAlertBannerActions.updateAlertBanner, (state, { data }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          success_message: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(UserAlertBannerActions.updateAlertBannerSuccess, (state, { data, success_message }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          banner: data,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(UserAlertBannerActions.updateAlertBannerFailure, (state, { banner_id, error }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: banner_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAlertBannerActions.deleteAlertBanner, (state, { banner_id }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: banner_id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(UserAlertBannerActions.deleteAlertBannerSuccess, (state, { data, success_message }) => {
    return AlertBannerEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message: success_message ?? null,
    });
  }),
  on(UserAlertBannerActions.deleteAlertBannerFailure, (state, { banner_id, error }) => {
    return AlertBannerEntityAdaptor.updateOne(
      {
        id: banner_id,
        changes: {
          loading: false,
          loaded: true,
          error,
        },
      },
      {
        ...state,
      }
    );
  })
);
