import { Injectable } from '@angular/core';

import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { RouterActions } from '../../../../../router/+state';
import { NotificationsHTTPService } from '../../../../services/notifications-http.service';
import * as NotificationsActions from '../actions/notifications.actions';
import { getAllNotificationsState } from '../selectors';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _NotificationsHTTPService: NotificationsHTTPService
  ) {}

  getAllNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        NotificationsActions.searchAllNotifications,
        NotificationsActions.updateAllNotificationsPagination,
        NotificationsActions.updateAllNotificationsQuery,
        NotificationsActions.updateAllNotificationsSorting
      ),
      withLatestFrom(this._Store.select(getAllNotificationsState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._NotificationsHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return NotificationsActions.searchAllNotificationsSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(NotificationsActions.searchAllNotificationsFailure(error)))
        );
      }),
      catchError((error) => of(NotificationsActions.searchAllNotificationsFailure(error)))
    );
  });

  getNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.getNotificationById),
      switchMap((action) => {
        return this._NotificationsHTTPService.getById(action.notification_id).pipe(
          map((data) => {
            return NotificationsActions.getNotificationByIdSuccess({
              notification: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(NotificationsActions.getNotificationByIdFailure({ notification_id: action.notification_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(NotificationsActions.getNotificationByIdFailure(error)))
    );
  });

  createNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.createNotification),
      switchMap((action) => {
        return this._NotificationsHTTPService.create(action.data).pipe(
          map((data) => {
            return NotificationsActions.createNotificationSuccess({
              data: data,
              redirectPath: action.redirectPath,
              success_message: 'Notification Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(NotificationsActions.createNotificationFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(NotificationsActions.getNotificationByIdFailure(error)))
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createNotificationSuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NotificationsActions.createNotificationSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: [...data.redirectPath, data.data._id] } }));
          }
          return of();
        }),
        catchError((error) => of(NotificationsActions.getNotificationByIdFailure(error)))
      );
    }
    // { dispatch: false }
  );

  updateNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.updateNotification),
      switchMap((action) => {
        return this._NotificationsHTTPService.update(action.data).pipe(
          map((data) => {
            return NotificationsActions.updateNotificationSuccess({
              notification: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(NotificationsActions.updateNotificationFailure({ notification_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(NotificationsActions.getNotificationByIdFailure(error)))
    );
  });

  deleteNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationsActions.deleteNotification),
      switchMap((action) => {
        return this._NotificationsHTTPService.delete(action.id).pipe(
          map((data) => {
            return NotificationsActions.deleteNotificationSuccess({
              data: data,
              success_message: 'Notification Deleted',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(NotificationsActions.deleteNotificationFailure({ notification_id: action.id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(NotificationsActions.deleteNotificationFailure(error)))
    );
  });
}
