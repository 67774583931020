import { ILaunchpointSearchFilterDates } from '../dates.interface';
import { IQueryPageination, IQuerySort } from '../query.interfaces';
import { RequireAtLeastOne } from '../require-one';
import { ICoreUser } from '../user-company-account.interface';

export class ICoreAlertBannerFE {
  message: string;
  banner_color: string;
  text_color: string;
}

export class ICoreAlertBannerBase {
  /**
   * limit 250 characters
   */
  message: string;
  active: boolean;
  display_range_start_date: Date;
  display_range_end_date: Date;
  banner_color: string;
  text_color: string;

  /**
   * security roles of users allowed to view this banner
   */
  security_roles: string[];

  //
  _id: string;
  created_at: Date;
  update_at: Date;
  user_id: string | ICoreUser;
}

export class ICoreAlertBanner extends ICoreAlertBannerBase {
  user_id: ICoreUser;
}

export class ICoreAlertBannerParamsCreate {
  message: string;
  active: boolean;
  display_range_start_date: Date;
  display_range_end_date: Date;
  banner_color?: string;
  text_color?: string;
  security_roles?: string[];
}
export type ICoreAlertBannerParamsUpdate = {
  _id: string;
} & RequireAtLeastOne<{
  message: string;
  active: boolean;
  display_range_start_date: Date;
  display_range_end_date: Date;
  banner_color: string;
  text_color: string;
  security_roles: string[];
}>;

export class ICoreAlertBannerParamsDelete {
  _id: string;
}

export interface ICoreAlertBannerSearchQuery {
  search?: string;
  active?: boolean;
  banner_color?: string[];
  text_color?: string[];
  security_roles?: string[];
  display_range_start_date?: ILaunchpointSearchFilterDates;
  display_range_end_date?: ILaunchpointSearchFilterDates;
  created_at?: ILaunchpointSearchFilterDates;
  is_displaying?: boolean;
}

export interface ICoreAlertBannerSearchPayload {
  query: ICoreAlertBannerSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export interface ICoreAlertBannerSearchResults {
  pagination: IQueryPageination;
  data: ICoreAlertBanner[];
}
