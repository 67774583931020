import { IMedia, IMediaCategory } from '@launchpoint/core-types';

export interface FileEntity {
  file: File;
  uploadPercentage: number;
  loading: boolean;
  error: string | Error;
  description: string;
  category: IMediaCategory;
  media: IMedia;
}
