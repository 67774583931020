import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CompanyBillingBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-billing',
  templateUrl: 'company-billing.component.html',
})
export class CompanyBillingComponent extends CompanyBillingBaseComponent {
  constructor(fb: UntypedFormBuilder) {
    super(fb);
  }
}
