import { IQueryPageination } from "@launchpoint/core-types";

export interface ICoreUserAddressConfigRoutes {
  base: string;
}

export interface LaunchpointAddressCoreConfig {
  default_image: string;
  badge?: string;
  icon_svg?: string;
  icon?: string;
  buttons: {
    title: string;
    page: string;
  }[];
  menu_items: {
    title: string;
    page: string;
  }[];
  address_completion?: {
    title: string;
    value: number;
  };
  address_blocks?: {
    title: string;
    value: string;
    icon?: string;
    icon_svg?: string;
    icon_svg_class?: 'svg-icon-success' | 'svg-icon-danger';
  }[];
}

export interface IAppSettingsCountries {
  _id: string;
  created_at: Date;
  updated_at: Date;
  name: string;
  languages: Array<string>;
  iso2: string;
  iso3: string;
  unicode: string;
  dial: string;
  currency: string;
  capital: string;
  continent: string;
  sort: number;
}

export interface IAppSettingsCountriesDTOCreate {
  name: string;
  languages: Array<string>;
  iso2: string;
  iso3: string;
  unicode?: string;
  dial?: string;
  currency: string;
  capital: string;
  continent: string;
  sort?: number;
}

export interface IQueryAppSettingsCountries {
  countries: IAppSettingsCountries[];
  pagination: IQueryPageination;
}

export enum EUserAddressTitle {
  BILLING = 'Billing',
  MAILING = 'Mailing',
}
