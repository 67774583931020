import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stripePaymentDates' })
export class StripePaymentDatesPipe implements PipeTransform {
  private maxUnixTimestamp = 2147483647; // max unix timestamp in seconds
  transform(value: number): string | null {
    if (!value) {
      return null;
    }

    if (value > 0 && value <= this.maxUnixTimestamp) {
      value = value * 1000;
    }

    try {
      const date = new DatePipe('en-US').transform(new Date(Number(value)), 'MMM d, y');

      return date ?? null;
    } catch (error) {
      //
    }
  }
}
