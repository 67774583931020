import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointBlogHTTPService } from '@launchpoint/core-client';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebBlogTagifyBaseWebComponent } from './blogs-tagify-base.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TagifyModule],
  providers: [LaunchpointBlogHTTPService],
  selector: 'launchpoint-blogs-tagify',
  templateUrl: 'blogs-tagify.component.html',
})
export class LaunchpointCoreWebBlogTagifyWebComponent extends LaunchpointCoreWebBlogTagifyBaseWebComponent {
  @Input() inputClass = 'form-control form-control-sm ps-4 bg-white';
}
