export interface LaunchpointCoreUserConfig {
  default_image: string;
  badge?: string;
  icon_svg?: string;
  icon?: string;
  buttons: {
    title: string;
    page: string;
  }[];
  menu_items: {
    title: string;
    page: string;
  }[];
  user_completion?: {
    title: string;
    value: number;
  };
  user_blocks?: {
    title: string;
    value: string;
    icon?: string;
    icon_svg?: string;
    icon_svg_class?: 'svg-icon-success' | 'svg-icon-danger';
  }[];
}
