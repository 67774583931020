import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';
import { IMedia } from '@launchpoint/core-types';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SkeletonLoadersComponent } from '../skeletons/skeleton-loaders/skeleton-loaders.component';

@Component({
  selector: 'launchpoint-detail-view-header-card',
  templateUrl: './detail-view-header-card.component.html',
  standalone: true,
  imports: [CommonModule, SkeletonLoadersComponent, NgbTooltipModule],
})
export class LaunchpointCoreWebDetailViewHeaderCardComponent extends LaunchpointCoreClientBaseComponent {
  @Input() object: any;
  @Input() header_image: IMedia;
  @Input() show_media = true;

  @Input() loading: Observable<boolean>;

  getActive(value: boolean | null) {
    if (typeof value === 'boolean') {
      return value;
    }

    return null;
  }

  checkActive(value: boolean) {
    return typeof value === 'boolean';
  }

  get activeValue() {
    if (typeof this.object.active === 'boolean') {
      return this.object.active;
    }
    if (typeof this.object.archived === 'boolean') {
      return !this.object.archived;
    }
    if (typeof this.object.enabled === 'boolean') {
      return this.object.enabled;
    }

    return null;
  }
}
