import { IsArray, IsObject, IsString } from 'class-validator';
import { ICoreUser } from '../../../user-company-account.interface';

export class ICoreUserAttributesParamsUpdate implements Pick<Partial<ICoreUser>, 'attributes'> {
  @IsString({ message: 'User ID must be a string' })
  user_id: string;

  @IsObject({ message: 'Attributes must be an object' })
  attributes: {
    [key: string]: (number | string | boolean | Date)[];
  };

  constructor(data: ICoreUserAttributesParamsUpdate) {
    this.user_id = data.user_id;
    this.attributes = data.attributes;
  }
}

export class ICoreUserAttributesParamsDelete {
  @IsString({ message: 'User ID must be a string' })
  user_id: string;

  @IsArray({ message: 'Attributes must be an array of the keys you want to remove' })
  attributes: string;

  constructor(data: ICoreUserAttributesParamsDelete) {
    this.user_id = data.user_id;
    this.attributes = data.attributes;
  }
}
