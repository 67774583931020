import {
  ICoreBlog,
  ICoreBlogParamsCommentIds,
  ICoreBlogParamsCommentAdd,
  ICoreBlogParamsCommentReplyIds,
  ICoreBlogParamsCommentReplyAdd,
  ICoreBlogParamsCreate,
  ICoreBlogParamsUpdate,
  ICoreBlogRelatedPostParams,
  ICoreBlogSearchQuery,
  ICoreBlogSearchResults,
  ICoreBlogSeoParamsUpdate,
  ICoreBlogTagParams,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllBlogs = createAction('[Blogs] searchAllBlogs');
export const searchAllBlogsSuccess = createAction(
  '[Blogs] searchAllBlogsSuccess',
  props<{ data: ICoreBlogSearchResults; success_message?: string }>()
);
export const searchAllBlogsFailure = createAction('[Blogs] searchAllBlogsFailure', props<{ error: IHttpException }>());

// Mechanics
export const updateAllBlogsPagination = createAction('[Blogs] updateAllBlogsPagination', props<{ pagination: IQueryPageination }>());
export const updateAllBlogsPaginationScroll = createAction('[Blogs] updateAllBlogsPaginationScroll', props<{ pagination: IQueryPageination }>());
export const updateAllBlogsSorting = createAction('[Blogs] updateAllBlogsSorting', props<{ querySort: IQuerySort }>());
export const updateAllBlogsQuery = createAction('[Blogs] updateAllBlogsQuery', props<{ query: ICoreBlogSearchQuery }>());
// getById
export const getBlogById = createAction('[Blogs] getBlogById', props<{ blog_id: string }>());
export const getBlogByIdSuccess = createAction('[Blogs] getBlogByIdSuccess', props<{ data: ICoreBlog; success_message?: string }>());
export const getBlogByIdFailure = createAction('[Blogs] getBlogByIdFailure', props<{ blog_id: string; error: IHttpException }>());

// getById
export const findOneBlog = createAction('[Blogs] findOneBlog', props<{ title: string }>());
export const findOneBlogSuccess = createAction('[Blogs] findOneBlogSuccess', props<{ data: ICoreBlog; success_message?: string }>());
export const findOneBlogFailure = createAction('[Blogs] findOneBlogFailure', props<{ error: IHttpException }>());

// create
export const createBlog = createAction('[Blogs] createBlog', props<{ data: ICoreBlogParamsCreate }>());
export const createBlogSuccess = createAction('[Blogs] createBlogSuccess', props<{ data: ICoreBlog; success_message?: string }>());
export const createBlogFailure = createAction('[Blogs] createBlogFailure', props<{ error: IHttpException }>());

// update
export const updateBlog = createAction('[Blogs] updateBlog', props<{ data: ICoreBlogParamsUpdate }>());
export const updateBlogSuccess = createAction('[Blogs] updateBlogSuccess', props<{ data: ICoreBlog; success_message?: string }>());
export const updateBlogFailure = createAction('[Blogs] updateBlogFailure', props<{ blog_id: string; error: IHttpException }>());

// Delete Blogs
export const deleteBlog = createAction('[Blogs] deleteBlog', props<{ blog_id: string; redirectPath?: string[] }>());
export const deleteBlogSuccess = createAction(
  '[Blogs] deleteBlogSuccess',
  props<{ data: ICoreBlog; success_message?: string; redirectPath?: string[] }>()
);
export const deleteBlogFailure = createAction('[Blogs] deleteBlogFailure', props<{ blog_id: string; error: IHttpException }>());

// featured
export const setFeatured = createAction('[Blogs] setFeatured', props<{ blog_id: string }>());
// tags
export const setTags = createAction('[Blogs] setTags', props<{ data: ICoreBlogTagParams }>());

// related posts
export const addRelatedPosts = createAction('[Blogs] addRelatedPosts', props<{ data: ICoreBlogRelatedPostParams }>());
export const removeRelatedPosts = createAction('[Blogs] removeRelatedPosts', props<{ data: ICoreBlogRelatedPostParams }>());
export const setRelatedPosts = createAction('[Blogs] setRelatedPosts', props<{ data: ICoreBlogRelatedPostParams }>());

// comments
export const addComment = createAction('[Blogs] addComment', props<{ data: ICoreBlogParamsCommentAdd }>());
export const removeComment = createAction('[Blogs] removeComment', props<{ data: ICoreBlogParamsCommentIds }>());
export const likeComment = createAction('[Blogs] likeComment', props<{ data: ICoreBlogParamsCommentIds }>());
export const dislikeComment = createAction('[Blogs] dislikeComment', props<{ data: ICoreBlogParamsCommentIds }>());

export const addCommentReply = createAction('[Blogs] addCommentReply', props<{ data: ICoreBlogParamsCommentReplyAdd }>());
export const removeCommentReply = createAction('[Blogs] removeCommentReply', props<{ data: ICoreBlogParamsCommentReplyIds }>());
export const likeCommentReply = createAction('[Blogs] likeCommentReply', props<{ data: ICoreBlogParamsCommentReplyIds }>());
export const dislikeCommentReply = createAction('[Blogs] dislikeCommentReply', props<{ data: ICoreBlogParamsCommentReplyIds }>());

// SEO
export const updateBlogSEO = createAction('[Blogs] updateBlogSEO', props<{ data: ICoreBlogSeoParamsUpdate }>());
