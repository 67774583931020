/**
 * Takes an interface and flattens it to a single level with dot notation and no indices for arrays
 *
 * if no interface is provided, it will return a string index signature
 *
 * https://stackoverflow.com/questions/69095054/how-to-deep-flatten-a-typescript-interface-with-union-types-and-keep-the-full-ob
 */
export type DeepFlatten<T = undefined> = T extends undefined ? { [key: string]: string } : Collapse<Explode<T>>;

type Entry = { key: string; value: any; optional: boolean };

// gets first item from array or returns the type if not an array
type Explode<T> = _Explode<T extends readonly any[] ? T[number] : T>;

type _Explode<T> = T extends object
  ? {
      [K in keyof T]-?: K extends string
        ? Explode<T[K]> extends infer E
          ? E extends Entry
            ? {
                key: `${K}${E['key'] extends '' ? '' : '.'}${E['key']}`;
                value: E['value'];
                optional: E['key'] extends '' ? (object extends Pick<T, K> ? true : false) : E['optional'];
              }
            : never
          : never
        : never;
    }[keyof T]
  : { key: ''; value: T; optional: false };

type Collapse<T extends Entry> = { [E in Extract<T, { optional: false }> as E['key']]: E['value'] } & Partial<{
  [E in Extract<T, { optional: true }> as E['key']]: E['value'];
}> extends infer O
  ? { [K in keyof O]: O[K] }
  : never;

// TODO -- use a tracker to prevent circular references
// todo -- it loads really slow for large obj so need some help

// type Entry = { key: string; value: any; optional: boolean };

// // Base DeepFlatten type with tracker to prevent circular references
// export type DeepFlatten<T = undefined, Tracker extends any[] = []> = T extends undefined ? { [key: string]: string } : Collapse<Explode<T, Tracker>>;

// // Explode type with tracker
// type Explode<T, Tracker extends any[]> = _Explode<T extends readonly any[] ? T[number] : T, Tracker>;

// // Internal explode type with tracker
// type _Explode<T, Tracker extends any[]> = T extends object
//   ? [T] extends [Tracker[number]]
//     ? never
//     : {
//         [K in keyof T]-?: Explode<T[K], [T, ...Tracker]> extends infer E
//           ? E extends Entry
//             ? {
//                 key: `${Extract<K, string>}${E['key'] extends '' ? '' : '.'}${E['key']}`;
//                 value: E['value'];
//                 optional: E['key'] extends '' ? (object extends Pick<T, K> ? true : false) : E['optional'];
//               }
//             : never
//           : never;
//       }[keyof T]
//   : { key: ''; value: T; optional: false };

// // Collapse type remains unchanged
// type Collapse<T extends Entry> = {
//   [E in Extract<T, { optional: false }> as E['key']]: E['value'];
// } & Partial<{
//   [E in Extract<T, { optional: true }> as E['key']]: E['value'];
// }> extends infer O
//   ? { [K in keyof O]: O[K] }
//   : never;
