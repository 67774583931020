import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as AdminMediaActions from '../actions/media.actions';
import { IMediaEntity, IMediaEntityState } from './media-entity.interface';

export const MediaEntityAdaptor: EntityAdapter<IMediaEntity> = createEntityAdapter<IMediaEntity>({
  selectId: (entity) => entity.media_id,
});

export const initialMediaEntityState: IMediaEntityState = MediaEntityAdaptor.getInitialState({
  selected_media_id: '',
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: '' },
  loaded: false,
  loading: false,
  error: null,
  success_message: null,
});

export const coreMediaReducer = createReducer(
  initialMediaEntityState,
  on(AdminMediaActions.searchAllMedia, (state) => {
    return MediaEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(AdminMediaActions.updateMediaPagination, (state, { pagination }) => {
    return MediaEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(AdminMediaActions.updateMediaSorting, (state, { querySort }) => {
    return MediaEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(AdminMediaActions.updateMediaQuery, (state, { query }) => {
    return MediaEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),
  on(AdminMediaActions.searchAllMediaSuccess, (state, { data, success_message }) => {
    const mediaEntities: IMediaEntity[] = data.data.map((m) => {
      const entity: IMediaEntity = {
        media_id: m._id,
        media: m,
        loaded: true,
        loading: false,
        error: null,
        success_message: success_message ?? null,
      };
      return entity;
    });
    return MediaEntityAdaptor.setAll(mediaEntities, {
      ...state,
      pagination: data.pagination,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    });
  }),
  on(AdminMediaActions.getMediaByIdSetId, (state, { media_id }) => ({
    ...state,
    selected_media_id: media_id,
  })),
  on(AdminMediaActions.getMediaById, (state, { media_id }) => ({
    ...state,
    selected_media_id: media_id,
    loaded: false,
    loading: true,
    error: null,
    success_message: null,
  })),
  on(AdminMediaActions.getMediaByIdSuccess, (state, { data, success_message }) => {
    const entity: IMediaEntity = {
      media_id: data._id,
      media: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return MediaEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_media_id: data._id,
      loaded: true,
      loading: false,
    });
  }),
  on(AdminMediaActions.getMediaByIdFailure, (state, { media_id, error }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: media_id,
        changes: {
          error,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),
  on(AdminMediaActions.createMedia, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(AdminMediaActions.createMediaSuccess, (state, { data, success_message }) => {
    const entity: IMediaEntity = {
      media_id: data._id,
      media: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return MediaEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      selected_media_id: data._id,
      success_message: success_message ?? null,
    });
  }),
  on(AdminMediaActions.createMediaFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error: error,
  })),
  on(AdminMediaActions.updateMedia, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.updateMediaSuccess, (state, { data, success_message }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          media: data,
          loading: false,
          loaded: true,
          error: null,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.updateMediaFailure, (state, { media_id, error }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: media_id,
        changes: {
          error,
          success_message: null,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.deleteMedia, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.deleteMediaSuccess, (state, { data, success_message }) => {
    return MediaEntityAdaptor.removeOne(data._id, {
      ...state,
      loaded: true,
      loading: false,
      success_message,
    });
  }),
  on(AdminMediaActions.deleteMediaFailure, (state, { media_id, error }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: media_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.getSignedUrl, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.getSignedUrlSuccess, (state, { data, success_message }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data.media._id,
        changes: {
          loading: false,
          loaded: true,
          error: null,
          success_message: success_message ?? null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.getSignedUrlFailure, (state, { media_id, error }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: media_id,
        changes: {
          error,
          success_message: null,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.createMediaAudio, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.removeMediaAudio, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.updateMediaALC, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.createMediaSubtitle, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.removeMediaSubtitle, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.createMediaTag, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.deleteMediaTag, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.setMediaTag, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(AdminMediaActions.updateMediaEmittedValue, (state, { data }) => {
    return MediaEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          loaded: false,
          loading: true,
          error: null,
          success_message: null,
        },
      },
      {
        ...state,
      }
    );
  })
);
