import { Observable } from 'rxjs';
import {
  ICoreAlertBanner,
  ICoreAlertBannerFE,
  ICoreAlertBannerParamsCreate,
  ICoreAlertBannerParamsUpdate,
  ICoreAlertBannerSearchPayload,
  ICoreAlertBannerSearchResults,
} from './alert-banner.interface';

/**
 * @Controller('alert-banner')
 */
export interface ICoreAlertBannerController {
  /**
   * Post ('search')
   */
  search(params: ICoreAlertBannerSearchPayload): Promise<ICoreAlertBannerSearchResults> | Observable<ICoreAlertBannerSearchResults>;
  /**
   * Get (':id')
   */
  getById(id: string): Promise<ICoreAlertBanner> | Observable<ICoreAlertBanner>;
  /**
   * Get ()
   */
  getAllForDate(): Promise<ICoreAlertBannerFE[]> | Observable<ICoreAlertBannerFE[]>;
  /**
   * Post
   */
  create(params: ICoreAlertBannerParamsCreate): Promise<ICoreAlertBanner> | Observable<ICoreAlertBanner>;
  /**
   * Put
   */
  update(params: ICoreAlertBannerParamsUpdate): Promise<ICoreAlertBanner> | Observable<ICoreAlertBanner>;
  /**
   * Put ('archive/:id')
   */
  archive(id: string): Promise<ICoreAlertBanner> | Observable<ICoreAlertBanner>;
  /**
   * Put ('delete/:id')
   */
  delete(id: string): Promise<ICoreAlertBanner> | Observable<ICoreAlertBanner>;
}

/**
 * @Controller('get-banner')
 */
export interface ICoreAlertBannerPublicController {
  /**
   * Get ()
   */
  getAllForDatePublic(): Promise<ICoreAlertBannerFE[]> | Observable<ICoreAlertBannerFE[]>;
}
