import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientBlogBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';

@Component({
  selector: 'launchpoint-blog',
  templateUrl: './blog.component.html',
  styles: [],
})
export class LaunchpointCoreWebBlogComponent extends LaunchpointCoreClientBlogBaseComponent {
  _swal = inject(LaunchpointSweetAlertService);

  override toast(params: any): void {
    this._swal.fireToast(params);
  }
}
