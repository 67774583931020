<tagify
  name="users"
  class="me-5"
  [ngModel]="usersModel"
  [whitelist]="whitelistUsers$"
  [settings]="settings"
  [inputClass]="inputClass"
  (ngModelChange)="selectData($event)"
  (tInput)="onInput($event)"
/>
