import {
  ENotificationChannelType,
  IHttpException,
  ILaunchpointNotificationAnalytics,
  INotificationMessageSearchQuery,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as MessageActions from '../actions/messages.actions';
import { MessagesEntityState } from '../interfaces/messages-entity.interface';

export const MessageFeatureKey = 'MessageReducer';

export interface MessagesState extends EntityState<MessagesEntityState> {
  selected_message_id: string;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: INotificationMessageSearchQuery;
  create_modal: boolean;
  analytics: ILaunchpointNotificationAnalytics[];
}

export const MessagesEntityAdaptor: EntityAdapter<MessagesEntityState> = createEntityAdapter<MessagesEntityState>({
  selectId: (message) => message.message_id,
});

export const initialMessageState: MessagesState = MessagesEntityAdaptor.getInitialState({
  // set initial required properties
  selected_message_id: '',
  loaded: false,
  loading: false,
  pagination: { skip: 0, pageIndex: 0, previousPageIndex: 0, limit: 25, count: 0 },
  querySort: { created_at: -1 },
  query: { search: null },
  error: null,
  success_message: null,
  create_modal: false,
  analytics: [
    { channel: ENotificationChannelType.EMAIL, totalCount: 0, jobStates: [] },
    { channel: ENotificationChannelType.SMS, totalCount: 0, jobStates: [] },
    { channel: ENotificationChannelType.PUSH, totalCount: 0, jobStates: [] },
    { channel: ENotificationChannelType.SLACK, totalCount: 0, jobStates: [] },
    { channel: ENotificationChannelType.SYSTEM, totalCount: 0, jobStates: [] },
  ],
});

export const MessageReducer = createReducer(
  initialMessageState,
  on(MessageActions.searchAllMessages, (state) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(MessageActions.searchAllMessagesSuccess, (state, { data }) => {
    const MessageEntities = data.data.map((message) => {
      const MessageEntity: MessagesEntityState = {
        message_id: message._id,
        message: message,
        loaded: true,
        loading: false,
        error: null,
      };
      return MessageEntity;
    });
    return MessagesEntityAdaptor.addMany(MessageEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),

  on(MessageActions.updateAllMessagesQuery, (state, { query }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      query,
    });
  }),

  on(MessageActions.updateAllMessagesPagination, (state, { pagination }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loading: true,
      error: null,
      success_message: null,
      pagination,
    });
  }),
  on(MessageActions.updateAllMessagesPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loading: true,
    // error: null,
    success_message: null,
  })),

  on(MessageActions.updateAllMessagesSorting, (state, { querySort }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  on(MessageActions.searchAllMessagesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  on(MessageActions.getMessageAnalyticsSuccess, (state, { data }) => ({
    ...state,
    loaded: true,
    loading: false,
    success_message: null,
    analytics: data,
  })),
  on(MessageActions.getMessageById, (state, { message_id }) => {
    const entity: MessagesEntityState = {
      message_id,
      message: null,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    };
    return MessagesEntityAdaptor.upsertOne(entity, {
      ...state,
      selected_message_id: message_id,
      loaded: true,
      loading: false,
    });
  }),

  on(MessageActions.getMessageByIdSuccess, (state, { message }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: message._id,
        changes: {
          message,
          loaded: true,
          loading: false,
          error: null,
        },
      },
      {
        ...state,
        selected_message_id: message._id,
        loaded: true,
        loading: false,
      }
    );
  }),

  on(MessageActions.getMessageByIdFailure, (state, { message_id, error }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: message_id,
        changes: {
          error,
        },
      },
      {
        ...state,
        loaded: true,
        loading: false,
      }
    );
  }),

  // Search

  on(MessageActions.searchAllMessages, (state) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
    });
  }),
  on(MessageActions.searchAllMessagesSuccess, (state, { data }) => {
    const MessageEntities = data.data.map((message) => {
      const MessageEntity: MessagesEntityState = {
        message_id: message._id,
        message: message,
        loaded: true,
        loading: false,
        error: null,
      };
      return MessageEntity;
    });
    return MessagesEntityAdaptor.addMany(MessageEntities, {
      ...state,
      loaded: true,
      loading: false,
      pagination: data.pagination,
    });
  }),
  on(MessageActions.searchAllMessagesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loaded: true,
    success_message: null,
  })),
  // Create
  on(MessageActions.createMessage, (state, { data }) => ({
    ...state,
    loading: true,
    loaded: false,
    success_message: null,
    error: null,
  })),

  on(MessageActions.createMessageSuccess, (state, { data, success_message }) => {
    const entity: MessagesEntityState = {
      message_id: data._id,
      message: data,
      loaded: true,
      loading: false,
      error: null,
      success_message: success_message ?? null,
    };
    return MessagesEntityAdaptor.setAll([entity, ...Object.values(state.entities)], {
      ...state,
      loading: false,
      loaded: true,
      create_modal: false,
      selected_message_id: data._id,
    });
  }),

  on(MessageActions.createMessageFailure, (state, { error }) => ({
    ...state,
    loading: false,
    loaded: true,
    error,
  })),

  // Update

  on(MessageActions.updateMessage, (state, { data }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: data._id,
        changes: {
          error: null,
          loaded: true,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(MessageActions.updateMessageSuccess, (state, { message, success_message }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: message._id,
        changes: {
          message,
          loading: false,
          loaded: true,
          success_message,
        },
      },
      {
        ...state,
      }
    );
  }),

  on(MessageActions.updateMessageFailure, (state, { message_id, error }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: message_id,
        changes: {
          error,
          loaded: true,
          loading: false,
        },
      },
      {
        ...state,
      }
    );
  }),

  // Delete

  on(MessageActions.deleteMessage, (state, { id }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: id,
        changes: {
          error: null,
          loaded: false,
          loading: true,
        },
      },
      {
        ...state,
      }
    );
  }),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  on(MessageActions.deleteMessageSuccess, (state, { data, success_message }) => {
    return MessagesEntityAdaptor.removeOne(data._id, {
      ...state,
      loading: false,
      loaded: true,
      success_message: success_message ?? null,
    });
  }),
  on(MessageActions.deleteMessageFailure, (state, { message_id, error }) => {
    return MessagesEntityAdaptor.updateOne(
      {
        id: message_id,
        changes: {
          error,
          loading: false,
          loaded: true,
        },
      },
      {
        ...state,
      }
    );
  }),

  //Mechanics
  on(MessageActions.updateAllMessagesQuery, (state, { query }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      query: query,
    });
  }),
  on(MessageActions.updateAllMessagesPagination, (state, { pagination }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loading: true,
      loaded: false,
      error: null,
      success_message: null,
      pagination: pagination,
    });
  }),
  on(MessageActions.updateAllMessagesPaginationScroll, (state, { pagination }) => ({
    ...state,
    pagination: pagination,
    loading: true,
    loaded: false,
    error: null,
    success_message: null,
  })),

  on(MessageActions.updateAllMessagesSorting, (state, { querySort }) => {
    return MessagesEntityAdaptor.removeAll({
      ...state,
      loaded: false,
      loading: true,
      error: null,
      success_message: null,
      querySort,
    });
  }),
  // Analytics
  on(MessageActions.getMessageAnalytics, (state) => ({
    ...state,
    error: null,
    loading: true,
    loaded: false,
    success_message: null,
    analytics: null,
  })),
  on(MessageActions.getMessageAnalyticsSuccess, (state, { data }) => ({
    ...state,
    loaded: true,
    loading: false,
    success_message: null,
    analytics: data,
  })),
  on(MessageActions.getMessageAnalyticsFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    loading: false,
    error,
  }))
);
