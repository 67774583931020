import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientNotificationsTopicsFilterBaseComponent, updateAllTopicQuery } from '@launchpoint/core-client';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-notifications-topics-filter',
  templateUrl: './notifications-topics-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebNotificationsTopicsFilterComponent extends LaunchpointCoreClientNotificationsTopicsFilterBaseComponent {
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  clearTypes() {
    this.form.controls.topic_type.setValue([]);
    this.tagifyService.get('topic_type').removeAllTags();
  }

  override reset() {
    this.form.reset();
    this.tagifyService.get('topic_type').removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    this._Store.dispatch(updateAllTopicQuery({ query: {} }));
  }
}
