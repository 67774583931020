import { RequireAtLeastOne } from './require-one';

export enum EDateRangeUnit {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}
export interface IDateRange {
  unit: EDateRangeUnit;
  value: number;
  /**
   * date to calculate the range from
   * @default new Date()
   */
  date?: Date;
  debug?: boolean;
}

export interface IDateWrapperParamsCreateDateInZone {
  year?: number;
  month?: number;
  day?: number;
  ordinal?: number;
  weekYear?: number;
  weekNumber?: number;
  weekday?: number;
  hour?: number;
  minute?: number;
  second?: number;
  millisecond?: number;
}

export interface IRawTimeZone {
  name: string;
  alternativeName: string;
  group: string[];
  continentCode: string;
  continentName: string;
  countryName: string;
  countryCode: string;
  mainCities: string[];
  rawOffsetInMinutes: number;
  abbreviation: string;
  rawFormat: string;
}

export interface ITimeZone extends IRawTimeZone {
  currentTimeOffsetInMinutes: number;
  currentTimeFormat: string;
}
export enum ELaunchpointPresetDateRange {
  THIS_MONTH = 'This Month',
  THIS_YEAR = 'This Year',
  THIS_QUARTER = 'This Quarter',
  NEXT_MONTH = 'Next Month',
  // NEXT_QUARTER = 'Next Quarter',
  LAST_MONTH = 'Last Month',
  LAST_QUARTER = 'Last Quarter',
  LAST_YEAR = 'Last Year',
  YEAR_TO_DATE = 'Year to Date',
}

export type ILaunchpointSearchFilterDates = RequireAtLeastOne<{ start_search_date: Date; end_search_date: Date }>;

type ILaunchpointSearchFilterDatesFormat = { $gte?: Date | number; $lte?: Date | number };
export function formatSearchFilterDates(searchParam: ILaunchpointSearchFilterDates, format: 'date' | 'number'): ILaunchpointSearchFilterDatesFormat {
  const formatDate = (date: Date) => {
    if (format === 'number') {
      return Math.floor(new Date(date).getTime() / 1000);
    } else {
      return date;
    }
  };

  const match: ILaunchpointSearchFilterDatesFormat = {};
  if (searchParam?.start_search_date) {
    match.$gte = formatDate(searchParam.start_search_date);
  }
  if (searchParam?.end_search_date) {
    match.$lte = formatDate(searchParam.end_search_date);
  }

  return match;
}

export class LaunchpointDateClass {
  static getThisMonth(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0));
    return { start_search_date, end_search_date };
  }

  static getThisQuarter(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const start_search_date = new Date(Date.UTC(now.getFullYear(), quarter * 3, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), start_search_date.getMonth() + 3, 0));
    return { start_search_date, end_search_date };
  }

  static getYearToDate(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear(), 0, 1));
    return { start_search_date, end_search_date: now };
  }

  static getLastMonth(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth() - 1, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 0));
    return { start_search_date, end_search_date };
  }

  static getLastQuarter(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const start_search_date = new Date(Date.UTC(now.getFullYear(), (quarter - 1) * 3, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), start_search_date.getMonth() + 3, 0));
    return { start_search_date, end_search_date };
  }

  static getLastYear(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear() - 1, 0, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear() - 1, 11, 31));
    return { start_search_date, end_search_date };
  }

  static getThisYear(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth())) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear(), 0, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), 11, 31));
    return { start_search_date, end_search_date };
  }

  static getNextMonth(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 1)) : new Date();
    const start_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 2, 0));
    return { start_search_date, end_search_date };
  }

  static getNextQuarter(utc = false): ILaunchpointSearchFilterDates {
    const now = utc ? new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() + 3)) : new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const start_search_date = new Date(Date.UTC(now.getFullYear(), quarter * 3, 1));
    const end_search_date = new Date(Date.UTC(now.getFullYear(), start_search_date.getMonth() + 3, 0));
    return { start_search_date, end_search_date };
  }
}
