import { LaunchpointMediaAudiotracksService } from './media-audiotracks.service';
import { LaunchpointMediaBaseHTTPService } from './media-base.service';
import { LaunchpointMediaConvertService } from './media-convert.service';
import { LaunchpointMediaDownloadService } from './media-download.service';
import { LaunchpointMediaSubtitlesService } from './media-subtitles.service';
import { LaunchpointMediaTagsService } from './media-tags.service';
import { LaunchpointMediaUploadService } from './media-upload.service';
import { LaunchpointMediaService } from './media.service';

export const CORE_MEDIA_SERVICES = [
  //
  LaunchpointMediaAudiotracksService,
  LaunchpointMediaBaseHTTPService,
  LaunchpointMediaConvertService,
  LaunchpointMediaDownloadService,
  LaunchpointMediaSubtitlesService,
  LaunchpointMediaTagsService,
  LaunchpointMediaUploadService,
  LaunchpointMediaService,
];

export * from './media-audiotracks.service';
export * from './media-base.service';
export * from './media-convert.service';
export * from './media-download.service';
export * from './media-subtitles.service';
export * from './media-tags.service';
export * from './media-upload.service';
export * from './media.service';
