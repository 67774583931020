import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

export class IEllipsisMenuOption {
  label: string;
  /**
   * Full class for an icon
   * @example `bi bi-pencil` or `fas fa-trash`
   */
  iconClass?: string;
  action?: (...values: any[]) => void;
  ngIf?: any;
  disabled?: boolean;
  tooltip?: string;
  subOptions?: IEllipsisMenuOption[];
  // hover?: boolean;
  // securityRoles?: any[];
}

@Component({
  selector: 'launchpoint-ellipsis-menu',
  templateUrl: './ellipsis-menu.component.html',
  styleUrls: ['./ellipsis-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, NgbTooltipModule],
})
export class LaunchpointCoreWebEllipsisMenuComponent {
  @Input() options: IEllipsisMenuOption[] = [];
  @Input() cssClass = 'me-0 d-inline-block dropdown';
  @Input() disabled = false;
  @Input() tooltip;
  /**
   * label for the ellipsis menu instead of the '...'
   */
  @Input() label;

  @Output() selected = new EventEmitter();

  callAction(option, event) {
    event?.stopPropagation();

    if (option.disabled) {
      return;
    }
    if (option.action) {
      option.action();
    }
  }
}
