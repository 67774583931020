import { Component } from '@angular/core';
import { LaunchpointCoreClientProfilePhoneSmartUserBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-phone-smart-user',
  templateUrl: './profile-phone-smart-user.component.html',
})
export class LaunchpointCoreWebProfilePhoneSmartUserComponent extends LaunchpointCoreClientProfilePhoneSmartUserBaseComponent {
  // constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig) {
  //   super(_config,);
  // }
}
