<div class="px-10 pt-10">
  <label class="form-label fw-semibold">Saved Filters</label>

  <div class="d-flex align-items-center position-relative mt-2 mb-10 border rounded">
    <i class="bi bi-search fs-3 position-absolute mx-4 rounded"><span class="path1"></span><span class="path2"></span></i>
    <input
      type="text"
      [formControl]="form_control"
      type="text"
      class="form-control form-control-sm border-body bg-body w-250px ps-10 search-bar rounded"
      placeholder="Search Filter"
    />
  </div>
</div>

<div class="px-10 table-responsive">
  <table class="table border rounded">
    <tbody>
      <div *ngFor="let entity of viewEntities$ | async; let i = index">
        <tr class="border pb-0" [ngClass]="viewId === entity.view._id ? 'bg-light' : ''">
          <div class="d-flex justify-content-between w-100 px-10">
            <td class="cursor-pointer fw-semibold" (click)="rowSelected(entity.view)">{{ entity.view.title }}</td>
            <td ngbDropdown class="d-inline-block" #myDrop="ngbDropdown">
              <a class="nav-link cursor-pointer text-hover-primary" ngbDropdownToggle><i class="bi bi-three-dots"></i></a>
              <div ngbDropdownMenu>
                <button (click)="renameView(entity.view)" ngbDropdownItem>Rename</button>
                <!-- <button ngbDropdownItem>Edit</button> -->
                <button (click)="deleteViewConfirmation(entity.view)" ngbDropdownItem>Delete</button>
              </div>
            </td>
          </div>
          <div *ngIf="viewId === entity.view._id" class="bg-secondary">
            <!-- <ng-container *ngFor="let item of entity?.view?.query | keyvalue">
              <div class="mt-4 mb-2">
                <label>{{ item.key }}</label>
              </div>
              <span [ngSwitch]="item.value">
                <p *ngSwitchCase="'string'">
                  <span class="badge badge-pill badge-white">{{ item.value | json }}</span>
                </p>
                <p *ngSwitchCase="'array'">
                  <span class="badge badge-pill badge-white">{{ item.value | json }}</span>
                </p>
                <p *ngSwitchDefault>
                  <span class="badge badge-pill badge-white">{{ item.value | json }}</span>
                </p>
              </span>
            </ng-container> -->
          </div>
        </tr>
      </div>
    </tbody>
  </table>
  <div class="d-flex justify-content-end p-10">
    <button type="button" class="btn btn-sm btn-primary btn-active-light-primary me-2" (click)="apply()">
      Apply <strong>{{ selectedView?.title }}</strong> filter
    </button>
  </div>
</div>
