<div class="row gx-6 gx-xl-9">
  <div class="col-lg-12 mb-10" *ngIf="companyAccountOwner$ | async as owner">
    <!--begin::Card-->
    <div class="card card-flush h-lg-100">
      <!--begin::Card header-->
      <div class="card-header mt-6">
        <!--begin::Card title-->
        <div class="card-title flex-column">
          <h3 class="fw-bold mb-1">Account Owner</h3>

          <!-- <div class="fs-6 text-gray-400">From total 482 Participants</div> -->
        </div>
        <!--end::Card title-->

        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-bg-light btn-active-color-primary btn-sm" id="dropdownMenu1" ngbDropdownToggle>Change
              Owner</button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenu1"
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-350px py-3">
              <div class="menu-item px-3">
                <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Search New Owner</div>
              </div>
              <!--begin::Menu item-->
              <div class="menu-item px-3">
                <launchpoint-user-search-ahead [selected]="owner?._id"
                  (selectedUser)="confirmAccountOwnerChange($event)"></launchpoint-user-search-ahead>
              </div>
              <!--end::Menu item-->
            </div>
          </div>
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Card toolbar-->

      <!--begin::Card body-->
      <div class="card-body d-flex flex-column p-9 pt-3">
        <!--begin::Item-->
        <div class="d-flex align-items-center mb-5">
          <!--begin::Avatar-->
          <div class="me-5 position-relative">
            <!--begin::Image-->
            <div class="symbol symbol-35px symbol-circle">
              <span class="symbol-label bg-light-danger text-danger fw-semibold"> {{ owner?.profile?.first_name ?? 'O' |
                slice: 0:1 }} </span>
            </div>
            <!--end::Image-->

            <!--begin::Online-->
            <div
              class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1">
            </div>
            <!--end::Online-->
          </div>
          <!--end::Avatar-->

          <!--begin::Details-->
          <div class="fw-semibold">
            <span class="fs-6 fw-bold">{{ owner?.profile?.full_name ?? 'TBD' }}</span>

            <div class="text-muted">{{ owner?.email }}</div>
          </div>
          <!--end::Details-->

          <!--begin::Badge-->
          <div class="badge badge-light ms-auto"></div>
          <!--end::Badge-->
        </div>
        <!--end::Item-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
  <div class="col-lg-12 mb-10">
    <launchpoint-company-update></launchpoint-company-update>
  </div>

  <div class="col-lg-12">
    <ng-container *ngIf="company$ | async as company">
      <launchpoint-core-web-address-autocomplete [address]="company.address"
        (addressChange)="updateAddress($event)"></launchpoint-core-web-address-autocomplete>
    </ng-container>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>