<form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework px-5" novalidate="novalidate" [formGroup]="form"
  (ngSubmit)="submit()">

  <input type="text" autofocus class="d-none" autocomplete />

  <!--begin::Form group-->
  <div class="fv-row mb-5" *ngIf="show_company_name">
    <label class="form-label fw-bold text-gray-900 fs-6 required">Company Name</label>
    <input class="form-control form-control-md form-control-solid" type="text" formControlName="company_name"
      placeholder="Company Name" name="company_name" autocomplete="off" [ngClass]="{
            'is-invalid': form.controls.company_name.invalid,
            'is-valid': form.controls.company_name.valid
          }" />

    <ng-container *ngIf="form.controls.company_name.valid || form.controls.company_name.pristine"
      class="fv-plugins-message-container">
      <!-- <span class="fv-help-block"> &nbsp;</span> -->
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Company Name is required',
            control: form.controls.company_name
          }">
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Company Name should have maximum 100 characters',
            control: form.controls.company_name
          }">
    </ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="fv-row mb-5" *ngIf="show_email">
    <label class="form-label fw-bold text-gray-900 fs-6 required">Email</label>
    <input class="form-control form-control-md form-control-solid" type="email" formControlName="email"
      placeholder="Company Email" name="email" autocomplete="off" [ngClass]="{
            'is-invalid': form.controls.email.invalid,
            'is-valid': form.controls.email.valid
          }" />

    <ng-container *ngIf="form.controls.email.valid || form.controls.email.pristine"
      class="fv-plugins-message-container">
      <!-- <span class="fv-help-block"> &nbsp;</span> -->
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Email is required',
            control: form.controls.email
          }">
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'email',
            message: 'Email should have maximum 100 characters',
            control: form.controls.email
          }">
    </ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="fv-row mb-5" *ngIf="show_account_type">
    <label class="form-label fw-bold text-gray-900 fs-6 required">Account Type</label>
    <select class="form-select form-select-solid form-select-lg fw-semibold" formControlName="account_type"
      name="account_type">
      <option value="">Select One...</option>
      <option *ngFor="let type of account_types" [value]="type">{{ type }}</option>
    </select>

    <ng-container *ngIf="form.controls.account_type?.valid || form.controls.account_type?.pristine"
      class="fv-plugins-message-container">
      <!-- <span class="fv-help-block"> &nbsp;</span> -->
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Account Type is required',
            control: form.controls.account_type
          }">
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Account Type should have maximum 100 characters',
            control: form.controls.account_type
          }">
    </ng-container>
  </div>
  <!--end::Form group-->

  <!--begin::Form group-->
  <div class="fv-row mb-5" *ngIf="show_phone">
    <label class="form-label fw-bold text-gray-900 fs-6 required">Phone</label>
    <input class="form-control form-control-md form-control-solid" type="tel" formControlName="phone"
      placeholder="Company Phone" name="phone" autocomplete="off" [ngClass]="{
            'is-invalid': form.controls.phone.invalid,
            'is-valid': form.controls.phone.valid
          }" />

    <ng-container *ngIf="form.controls.phone.valid || form.controls.phone.pristine"
      class="fv-plugins-message-container">
      <!-- <span class="fv-help-block"> &nbsp;</span> -->
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Phone is required',
            control: form.controls.phone
          }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'minlength',
            message: 'Phone should have at least 3 characters',
            control: form.controls.phone
          }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Phone should have maximum 100 characters',
            control: form.controls.phone
          }">
    </ng-container>
  </div>
  <!--end::Form group-->
  <!--begin::Form group-->
  <div class="fv-row mb-5" *ngIf="show_account_owner">
    <label class="form-label fw-bold text-gray-900 fs-6 required">Account Owner</label>

    <launchpoint-user-search-ahead formControlName="account_owner"></launchpoint-user-search-ahead>

    <ng-container *ngIf="form.controls.account_owner.valid || form.controls.account_owner.pristine"
      class="fv-plugins-message-container">
      <!-- <span class="fv-help-block"> &nbsp;</span> -->
    </ng-container>

    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Account owner is required',
            control: form.controls.account_owner
          }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'minlength',
            message: 'Account owner should have at least 3 characters',
            control: form.controls.account_owner
          }">
    </ng-container>
    <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Account owner should have maximum 100 characters',
            control: form.controls.account_owner
          }">
    </ng-container>
  </div>
  <!--end::Form group-->

  <div class="d-flex flex-wrap modal-footer justify-content-end px-0 pb-0">
    <button type="button" (click)="close()" class="btn btn-lg btn-light fw-bold me-4">
      Close
    </button>
    <button type="submit" id="create_submit" [disabled]="form.invalid" class="btn btn-lg btn-primary fw-bold">
      <span class="indicator-label" *ngIf="(loading$ | async ) === false">Submit</span>
      <ng-container>
        <span class="indicator-label" *ngIf="(loading$ | async)">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>
    </button>
  </div>
  <!--end::Form group-->
</form>


<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control?.dirty || control?.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">{{ message }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>