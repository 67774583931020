import { GetChannelsCommandInput } from '@aws-sdk/client-pinpoint';
import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ILaunchpointCorePinpointADMChannelParamsUpdate } from './adm/pinpoint-adm-channel.interface';
import { ILaunchpointCorePinpointAPNSChannelParamsUpdate } from './apns/pinpoint-apns-channel.interface';
import { ILaunchpointCorePinpointBaiduChannelParamsUpdate } from './baidu/pinpoint-baidu-channel.interface';
import { ILaunchpointCorePinpointEmailChannelParamsUpdate } from './email/pinpoint-email-channel.interface';
import { ILaunchpointCorePinpointGCMChannelParamsUpdate } from './gcm/pinpoint-gcm-channel.interface';
import { ILaunchpointCorePinpointSMSChannelParamsUpdate } from './sms/pinpoint-sms-channel.interface';
import { ILaunchpointCorePinpointVoiceChannelParamsUpdate } from './voice/pinpoint-voice-channel.interface';
import { EPinpointChannelType } from '../index';

export type ILaunchpointCorePinpointChannel = ILaunchpointCorePinpointChannelBase &
  (
    | ILaunchpointCorePinpointSMSChannel
    | ILaunchpointCorePinpointEmailChannel
    | ILaunchpointCorePinpointAPNSChannel
    | ILaunchpointCorePinpointGCMChannel
    | ILaunchpointCorePinpointBaiduChannel
    | ILaunchpointCorePinpointVoiceChannel
    | ILaunchpointCorePinpointADMChannel
  );

export class ILaunchpointCorePinpointChannelBase {
  id: string;
  application_id: string;
  is_archived: boolean;
  creation_date: string;
  version: number;
  enabled: boolean;
  last_modified_by: string;
  last_modified_date: string;
  has_credential: boolean;
  platform: string;
  //
  _id: string;
  type: EPinpointChannelType;
  deleted: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  users: string[];
  accounts: string[];
}

export class ILaunchpointCorePinpointSMSChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.SMS;
  short_code: string;
  sender_id: string;
  promotional_messages_per_second: number;
  transactional_messages_per_second: number;
}

export class ILaunchpointCorePinpointEmailChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.EMAIL;
  configuration_set: string;
  from_address: string;
  identity: string;
  messages_per_second: number;
  role_arn: string;
}

export class ILaunchpointCorePinpointAPNSChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.APNS;
  has_token_key: boolean;
  default_authentication_method: string;
}

export class ILaunchpointCorePinpointGCMChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.GCM;
  credential: string;
  has_fcm_service_credentials: boolean;
  default_authentication_method: string;
}

export class ILaunchpointCorePinpointBaiduChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.BAIDU;
  credential: string;
}

export class ILaunchpointCorePinpointVoiceChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.VOICE;
}
export class ILaunchpointCorePinpointADMChannel extends ILaunchpointCorePinpointChannelBase {
  type: EPinpointChannelType.ADM;
}

export class ILaunchpointCorePinpointChannelSearchQuery {
  search?: string;
  id?: string[];
  type?: EPinpointChannelType[];
  archived?: boolean;
  //  creation_date?: ILaunchpointSearchFilterDates;
  // last_modified_date?: ILaunchpointSearchFilterDates;
}

export class ILaunchpointCorePinpointChannelSearchPayload {
  query: ILaunchpointCorePinpointChannelSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ILaunchpointCorePinpointChannelSearchResults {
  data: ILaunchpointCorePinpointChannel[];
  pagination: IQueryPageination;
}

export class ILaunchpointCorePinpointChannelParamsList implements GetChannelsCommandInput {
  ApplicationId: string;
}

export class ILaunchpointCorePinpointChannelParamsRetrieve {
  ApplicationId: string;
}

export class ILaunchpointCorePinpointChannelParamsDelete {
  _id: string;
  ApplicationId: string;
  /**
   * required for switch case but not needed in services
   */
  type?: EPinpointChannelType;
}

export type ILaunchpointCorePinpointChannelParamsUpdate = { type: EPinpointChannelType } & (
  | ILaunchpointCorePinpointSMSChannelParamsUpdate
  | ILaunchpointCorePinpointEmailChannelParamsUpdate
  | ILaunchpointCorePinpointAPNSChannelParamsUpdate
  | ILaunchpointCorePinpointGCMChannelParamsUpdate
  | ILaunchpointCorePinpointBaiduChannelParamsUpdate
  | ILaunchpointCorePinpointVoiceChannelParamsUpdate
  | ILaunchpointCorePinpointADMChannelParamsUpdate
);
