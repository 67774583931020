<div class="modal-content rounded">
  <div class="modal-header d-flex flex-row justify-content-between align-items-center bd-highlight py-4 flex-wrap">
    <div class="d-flex flex-stack">
      <div class="fw-bold fs-3">
        Add {{ type }}
      </div>
    </div>
    <div class="btn btn-sm btn-icon" (click)="activeModal.dismiss()">
      <i class="bi bi-x-lg m-3"></i>
    </div>
  </div>
  <div class="modal-body scroll-y p-10">
    <div class="pe-5">

      <div>
        <div class="mb-0">

          <input autofocus style="display: none" />

          <label class="form-label fw-bolder">Select Media</label>
          <launchpoint-media-search-ahead cssClass="form-control form-control-sm form-control-solid"
            (selectedData)="setMedia($event)" (selectedChange)="setMedia($event)" [query]="query" />

          <div class="d-flex align-items-center justify-content-between my-8 mx-10">
            <div class="divider" style="margin-top: 1rem; margin-bottom: 1rem;"></div>
            <span class="fw-semibold mx-10">OR</span>
            <div class="divider" style="margin-top: 1rem; margin-bottom: 1rem;"></div>
          </div>

          <div class="d-flex justify-content-center my-10">
            <launchpoint-media-upload class="d-flex align-items-center justify-content-center" [multiple]="'false'"
              [accept]="accept" description="Click or drag to upload a new file" (fileAdded)="uploadOne()"
              (filesAdded)="uploadOne()" />
          </div>

          <div class="text-end mt-10">
            <button type="button" (click)="close()" class="btn btn-transparent me-5"><u>Cancel</u></button>
            <button type="button" (click)="submit()" [disabled]="disabled" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>