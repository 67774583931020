import { StateUserReducer } from '@launchpoint/core-client';
import * as fromRouter from '@ngrx/router-store';
import { RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import * as fromUser from './user.reducer';

export interface RootModuleState {
  routerReducer: RouterReducerState;
  userReducer: StateUserReducer;
}

export const USER_REDUCERS: ActionReducerMap<RootModuleState> = {
  routerReducer: fromRouter.routerReducer,
  userReducer: fromUser.userReducer,
};

export * from './user.reducer';
