import { LAUNCHPOINT_DB_USERS } from '../user/model/user.constants';

export const AUTH_CONFIG_PROVIDER = 'AuthConfig';
export const AUTH_HEADER = 'authorization';
export const AUTH_REFRESH = 'x-refresh-token';
export const AUTH_BEARER_ACCESS = 'Bearer access-token-';
export const AUTH_BEARER_REFRESH = 'Bearer refresh-token-';
export const AUTH_API_KEY = 'x-api-key';

export const AUTH_USER_DB = LAUNCHPOINT_DB_USERS;

export type IEnvironmentNames = 'local' | 'development' | 'staging' | 'production';
