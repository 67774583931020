export interface ICirclePagination {
  page: number;
  per_page: number;
  has_next_page: boolean;
  count: number;
  sort: 'string';
}

export interface ICircleEvent {
  id: number;
  name: string;
  display_title: string;
  slug: string;
  is_comments_closed: boolean;
  is_comments_enabled: boolean;
  is_liking_enabled: boolean;
  is_pinned_at_top_of_space: boolean;
  comment_count: number;
  hide_meta_info: boolean;
  user_likes_count: number;
  is_liked: boolean;
  is_truncation_disabled: boolean;
  bookmark_id: number | null;
  first_liked_by: any[];
  editor: string;
  body: ICircleEventBody;
  tiptap_body: string | null;
  body_plain_text: string;
  body_plain_text_without_attachments: string;
  url: string;
  created_at: string;
  updated_at: string;
  space: ICircleEventSpace;
  post_follower_id: number | null;
  topic_follower_id: number | null;
  author: ICircleEventAuthor;
  topics: any[];
  action: ICircleEventAction;
  custom_html: string | null;
  event_setting_attributes: ICircleEventSettingAttributes;
  event_type: string;
  policies: {
    can_manage_post: boolean;
    can_update_post: boolean;
    can_destroy_post: boolean;
    can_duplicate_post: boolean;
    can_report_post: boolean;
  };
  rsvped_event: boolean;
  rsvp_status: string | null;
  invited_attendee: boolean;
  event_attendees: ICircleEventAttendees;
}

export interface ICircleEventBody {
  attachments: Record<string, unknown>;
  attachments_array: any[];
  html: string;
}

export interface ICircleEventSpace {
  id: number;
  slug: string;
  name: string;
  emoji: string | null;
  custom_emoji_url: string;
  custom_emoji_dark_url: string;
}

export interface ICircleEventAuthor {
  id: number;
  community_member_id: number;
  name: string;
  headline: string;
  avatar_url: string | null;
  roles: string[];
  rich_text_field_sgid: string;
  member_tags: any[];
}

export interface ICircleEventAction {
  user_name: string;
  created_at: string;
  type: string;
}

export interface ICircleEventSettingAttributes {
  starts_at: string;
  ends_at: string;
  in_person_location?: string | null;
  hide_location_from_non_attendees: boolean;
  duration_in_seconds: number;
  rsvp_disabled: boolean;
  hide_attendees: boolean;
  send_email_reminder: boolean;
  send_in_app_notification_reminder: boolean;
  send_email_confirmation: boolean;
  send_in_app_notification_confirmation: boolean;
  enable_custom_thank_you_message: boolean;
  confirmation_message_title: string;
  confirmation_message_description: string;
  confirmation_message_button_title: string;
  confirmation_message_button_link: string;
  location_type: string;
  virtual_location_url?: string;
  rsvp_limit: number;
  rsvp_count: number;
}

export interface ICircleEventSettingAttributesInPersonLocation {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  name: string;
  place_id: string;
  url: string;
  website: string;
  html_attributions: string[];
}

export interface ICircleEventAttendee {
  id: number;
  community_member_id: number;
  avatar_url: string;
  rsvp_date: string;
  rsvp_status: string;
  name: string;
}

export interface ICircleEventAttendees {
  records: ICircleEventAttendee[];
  count: number;
}

export interface ICircleEventRecurringEvent {
  id: number;
  minimum: 1;
  name: string;
  starts_at: string;
  ends_at: string;
  start_date: string;
  time_zone_abbreviations: string;
  time_zone: string;
  rsvped_event: boolean;
  rsvp_count: number;
  rsvp_limit: number;
}

export type ICircleEventRecurringEventListResponse = {
  records: ICircleEventRecurringEvent[];
} & ICirclePagination;

export interface ICircleEventParamsList {
  // Page Number
  page?: number;
  // Number of records per page
  per_page?: number;
  filter_date_params?: {
    start_date: string; // YYYY-MM-DD format date string
    end_date: string; // YYYY-MM-DD format date string
  };
  status?: string;
  past_events?: boolean;
}

export type ICircleEventListResponse = {
  records: ICircleEvent[];
} & ICirclePagination;

export interface ICircleEventAttendeeParamsCreate {
  event_id: number;
}

export interface ICircleEventAttendeeParamsList {
  event_id: number;
  page?: number;
  per_page?: number;
}

export type ICircleEventAttendeeListResponse = {
  records: ICircleEventAttendee[];
} & ICirclePagination;

export interface ICircleEventRecurringEventParamsList {
  event_id: number;
  space_id: number;
  page?: number;
  per_page?: number;
}
export interface ICircleEventRecurringEventParamsUpdateRSVP {
  event_id: number;
  space_id: number;
}
