import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivityHistoryEntityAdaptor, ActivityHistoryFeatureKey, StateActivityHistoryReducer } from '../reducers/activity-history.reducer';

const selectAllActivityHistoryState = createFeatureSelector<StateActivityHistoryReducer>(ActivityHistoryFeatureKey);

/**
 * ROOT STATE
 */
export const getAllActivityHistoryState = createSelector(selectAllActivityHistoryState, (state: StateActivityHistoryReducer) => state);

export const selectActivityHistoryPagination = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.pagination);
export const selectActivityHistoryQuerySort = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.querySort);
export const selectActivityHistoryQuery = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.query);
export const selectActivityHistorySuccess = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.success_message);
export const selectActivityHistoryLoading = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.loading);
export const selectActivityHistoryLoaded = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.loaded);
export const selectActivityHistoryError = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state.error);

export const getAllActivityHistoryStateEntities = createSelector(
  selectAllActivityHistoryState,
  (state: StateActivityHistoryReducer) => state.entities
);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = ActivityHistoryEntityAdaptor.getSelectors(selectAllActivityHistoryState);

// select the array of user ids
export const selectActivityHistoryIds = selectIds;

// select the dictionary of user entities
export const selectActivityHistoryEntities = selectEntities;

// select the array of users
export const selectActivityHistory = selectAll;

// select the total entity count
export const selectActivityHistoryTotal = selectTotal;

export const selectActivityHistoryId = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => state?.selected_history_id);

export const selectSelectedActivityHistory = createSelector(
  getAllActivityHistoryState,
  selectActivityHistoryId,
  (state: StateActivityHistoryReducer, selected_history_id) => state.entities[selected_history_id]?.activity_history
);

export const selectSelectedActivityHistoryLoading = createSelector(
  getAllActivityHistoryState,
  selectActivityHistoryId,
  (state: StateActivityHistoryReducer, selected_history_id) => state.entities[selected_history_id]?.loading
);

export const selectSelectedActivityHistoryError = createSelector(
  getAllActivityHistoryState,
  selectActivityHistoryId,
  (state: StateActivityHistoryReducer, selected_history_id) => state.entities[selected_history_id]?.error
);

export const selectSelectedActivityHistorySuccess = createSelector(
  getAllActivityHistoryState,
  selectActivityHistoryId,
  (state: StateActivityHistoryReducer, selected_history_id) => state.entities[selected_history_id]?.success_message
);

export const selectActivityHistorySuccessQueryCount = createSelector(getAllActivityHistoryState, (state: StateActivityHistoryReducer) => {
  let count = 0;
  try {
    count += state?.query?.account_ids?.length ?? 0;
    count += state?.query?.event_name?.length ?? 0;
    count += state?.query?.method?.length ?? 0;
    count += state?.query?.status?.length ?? 0;
    count += state?.query?.user_ids?.length ?? 0;
    count += state?.query?.original_url?.length ?? 0;

    count += state?.query?.created_at ? 1 : 0;
  } catch (error) {
    // console.log(error);
  }
  return count;
});
