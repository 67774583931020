<!-- begin::Brand -->
<div class="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8" id="kt_aside_logo">
  <!-- begin::Logo -->
  <a routerLink="/dashboard">
    <ng-container *ngIf="asideTheme === 'dark'">
      <img alt="Logo" class="h-55px" [src]="menuConfig.logo_dark" />
    </ng-container>
    <ng-container *ngIf="asideTheme === 'light'">
      <img alt="Logo" class="h-55px" [src]="menuConfig.logo_light" />
    </ng-container>
  </a>
  <!-- end::Logo -->

  <!-- begin::Aside toggle -->
  <ng-container *ngIf="asideMinimize">
    <div id="kt_aside_toggle" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
      data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
      data-kt-toggle-name="aside-minimize"></div>
  </ng-container>
  <!-- end::Aside toggler -->
</div>
<!-- end::Brand  -->

<!-- begin::Aside menu -->
<div #ktAsideScroll id="kt_aside_nav"
  class="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0">
  <ng-content select="#kt_aside_menu"></ng-content>
</div>
<!-- end::Aside menu -->

<!-- begin::Footer -->
<div class="aside-footer d-flex flex-column align-items-center flex-column-auto" id="kt_aside_footer"
  *ngFor="let footer of menuConfig.footers">
  <a target="_blank" class="btn btn-custom btn-primary w-100" [href]="appPreviewDocsUrl" data-bs-toggle="tooltip"
    data-bs-trigger="hover" data-bs-dismiss-="click"
    ngbDropdown="Check out the complete documentation with over 100 components">
    <span [inlineSVG]="footer.svg" class="svg-icon btn-icon svg-icon-2"></span>
  </a>
</div>
<!-- end::Footer -->