import { on } from '@ngrx/store';
import { LaunchpointDynamicFormEntityAdaptor, LaunchpointDynamicFormState } from '../reducer';
import { setLaunchpointDynamicFormGroupById } from '../../actions/groups/actions';

export const DYNAMIC_FORM_GROUP_REDUCER = [
  on(setLaunchpointDynamicFormGroupById, (state: LaunchpointDynamicFormState, { _id, group_id }) => {
    // TODO: check if the group id is in the form before setting it?
    // console.log('setLaunchpointDynamicFormGroupById', _id, group_id);
    return LaunchpointDynamicFormEntityAdaptor.updateOne(
      {
        id: _id,
        changes: {
          loading: false,
          selected_group_id: group_id,
        },
      },
      {
        ...state,
      }
    );
  }),
];
