<!-- Search toolbar -->
<div *ngIf="topbarConfig.search" class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
    data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
    data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen021.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <app-search-result-inner></app-search-result-inner>
</div>

<!-- Activities -->
<div *ngIf="topbarConfig.activities" class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <!-- begin::Drawer toggle -->
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" id="kt_activities_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen032.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <!-- end::Drawer toggle -->
</div>

<!-- NOTIFICATIONS -->
<div *ngIf="topbarConfig.notifications" class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div id="notifications-bell-alert" class="btn btn-icon btn-active-light-primary position-relative"
    [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
    data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen007.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <app-notifications-inner />
</div>

<!-- CHAT -->
<div *ngIf="topbarConfig.chat" class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <!-- begin::Menu wrapper -->
  <div class="btn btn-icon btn-active-light-primary position-relative" [ngClass]="toolbarButtonHeightClass"
    id="kt_drawer_chat_toggle">
    <span [inlineSVG]="'./assets/media/icons/duotune/communication/com012.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
    <span
      class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
  </div>
  <!-- end::Menu wrapper -->
</div>

<!-- Quick links -->
<div *ngIf="topbarConfig.quick_links" class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div class="btn btn-icon btn-active-light-primary" [ngClass]="toolbarButtonHeightClass" data-kt-menu-trigger="click"
    data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
    <span [inlineSVG]="'./assets/media/icons/duotune/general/gen025.svg'" class="svg-icon"
      [ngClass]="toolbarButtonIconSizeClass"></span>
  </div>
  <launchpoint-app-quick-links-inner></launchpoint-app-quick-links-inner>
</div>

<!-- begin::Theme mode -->
<app-theme-mode-switcher *ngIf="topbarConfig.theme_switch" class="d-flex align-items-center"
  [ngClass]="toolbarButtonMarginClass"
  toggleBtnClass="{`btn-active-light-primary btn-custom ${toolbarButtonHeightClass}`}">
</app-theme-mode-switcher>
<!-- end::Theme mode -->

<!-- begin::User -->
<div class="d-flex align-items-center" [ngClass]="toolbarButtonMarginClass">
  <div ngbDropdown container="body">
    <button class="btn btn-icon" ngbDropdownToggle>
      <div class="cursor-pointer symbol">
        <launchpoint-web-circle-initials [first_initial]="(user.profile?.first_name ?? user?.email) | slice: 0:1"
          [second_initial]="user.profile?.last_name | slice: 0:1"
          [background_class]="circle_background_class"></launchpoint-web-circle-initials>
        <!-- <img [src]="topbarConfig.user_inner?.default_image" alt="default_image" /> -->
      </div>
    </button>
    <div ngbDropdownMenu>
      <ng-container *ngIf="user">
        <div class="menu-item px-3">
          <div class="menu-content d-flex align-items-center px-3">
            <div class="symbol symbol-50px me-5">
              <launchpoint-web-circle-initials [first_initial]="(user?.profile?.first_name ?? user?.email) | slice: 0:1"
                [second_initial]="user?.profile?.last_name | slice: 0:1"
                [background_class]="circle_background_class"></launchpoint-web-circle-initials>
              <!-- <img alt="User Profile Pic" [src]="user.profile.profile_photo_url || topbarConfig.user_inner?.default_image" /> -->
            </div>

            <div class="d-flex flex-column">
              <div class="fw-bolder d-flex align-items-center fs-5" *ngIf="user?.profile?.full_name">
                {{ user?.profile?.full_name }}
              </div>
              <span class="fw-bold fs-7 cursor-pointer" [ngClass]="{
                'text-gray-600': user?.profile?.full_name
              }">
                {{ user?.email ?? user?.demographics?.phone?.phone_number_long }}
              </span>
            </div>
          </div>
        </div>
        <ng-content select="[menu_items_from_header]"></ng-content>
        <ng-container *ngFor="let item of topbarConfig.user_inner?.menu?.items">
          <!-- begin::Separator -->
          <ng-container *ngIf="item.separator" [ngTemplateOutlet]="menuItemSeparatorTemplate"></ng-container>
          <!-- end::3 Separator -->

          <!-- begin::1 Level Link && ICON setup Assuming no submenu-->
          <ng-container *ngIf="item.external_link" [ngTemplateOutlet]="menu1LevelTemplateIconLinkExternal"
            [ngTemplateOutletContext]="{ item: item }"></ng-container>
          <!-- begin::1 Level Link && ICON setup Assuming no submenu-->

          <!-- begin::1 Level -->
          <ng-container *ngIf="item.title && item.page && !item.external_link" [ngTemplateOutlet]="menu1LevelTemplate"
            [ngTemplateOutletContext]="{ item: item }"></ng-container>
          <!-- end::1 Level -->
        </ng-container>
      </ng-container>

      <!-- begin::Separator -->
      <ng-template #menuItemSeparatorTemplate let-item="item">
        <div class="dropdown-divider"></div>
      </ng-template>
      <!-- end::3 Separator -->

      <!-- begin::1 Level Link && ICON setup Assuming no submenu-->
      <ng-template #menu1LevelTemplateIconLinkExternal let-item="item">
        <!-- begin::if don't have submenu -->
        <ng-container *ngIf="item.external_link">
          <div class="menu-item px-5 my-1">
            <a class="menu-link px-5 cursor-pointer" target="_blank" [href]="item.external_link"> {{ item.title }} </a>
          </div>
        </ng-container>
        <!-- end::if don't have submenu -->
      </ng-template>
      <!-- end::1 Level -->

      <!-- begin::1 Level -->
      <ng-template #menu1LevelTemplate let-item="item">
        <ng-container *ngIf="!item.submenu">
          <div class="menu-item px-5">
            <a [routerLink]="item.page" class="menu-link px-5 cursor-pointer">
              {{ item.title }}
              <span *ngIf="item.badge" class="menu-badge">
                <span class="badge badge-light-danger badge-circle fw-bolder fs-7">{{ item.badge }}</span>
              </span>
            </a>
          </div>
        </ng-container>
        <ng-container *ngIf="item?.submenu">
          <div ngbDropdown class="menu-item px-10">
            <button class="btn btn-link btn-sm menu-item" ngbDropdownToggle>{{ item.title }}</button>
            <div ngbDropdownMenu class="sub-menu">
              <button ngbDropdownItem *ngFor="let sub of item.submenu">
                {{ sub.title }}
                <span *ngIf="item.badge" class="menu-badge">
                  <span class="badge badge-light-danger badge-circle fw-bolder fs-7">{{ item.badge }}</span>
                </span>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-template>
      <!-- end::1 Level -->
    </div>
  </div>
</div>
<!-- end::User -->

<ng-container *ngIf="headerLeft === 'menu'">
  <div class="d-flex align-items-center d-lg-none ms-2 me-n3" title="Show header menu">
    <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
      id="kt_header_menu_mobile_toggle">
      <span [inlineSVG]="'./assets/media/icons/duotune/text/txt001.svg'" class="svg-icon svg-icon-1"></span>
    </div>
  </div>
</ng-container>