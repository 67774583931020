import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-tables-widget12',
  templateUrl: './tables-widget12.component.html',
})
export class TablesWidget12Component {
  // constructor() {}
  // ngOnInit(): void {}
}
