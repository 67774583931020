import { Injectable } from '@angular/core';
import { IHttpException, RequireAtLeastOne } from '@launchpoint/core-types';
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export type ISwalAlertParamsAlert = Omit<SweetAlertOptions, 'title'> & {
  icon: SweetAlertIcon;
} & RequireAtLeastOne<{
    title?: string | Error | IHttpException;
    text?: string | Error | IHttpException;
    html?: string;
  }>;

export interface ISwalAlertParamsToast extends Omit<SweetAlertOptions, 'title'> {
  title: string | Error | IHttpException;
  icon: SweetAlertIcon;
}

export type ISweetAlertIcon = 'warning' | 'error' | 'success' | 'info' | 'question';

@Injectable({ providedIn: 'root' })
export abstract class LaunchpointSweetAlertService {
  get Swal() {
    return Swal;
  }

  /**
   * Wrapper for SweetAlert2 Alert
   *
   * @default showCancelButton: true
   * @default cancelButtonText: 'Cancel'
   * @default customClass: { confirmButton: 'btn btn-danger', cancelButton: 'btn btn-light' }
   */
  public fireAlert(params: ISwalAlertParamsAlert): Promise<SweetAlertResult> {
    const { title, showCancelButton = true, cancelButtonText = 'Close', customClass, ...rest } = params;

    const alert: SweetAlertOptions = {
      cancelButtonText,
      showCancelButton,
      customClass: {
        confirmButton: `btn btn-sm btn-${this.formatColor(rest?.icon)}`,
        cancelButton: 'btn btn-sm btn-light',
        ...customClass,
      },
    };

    if (title) {
      const message = typeof title === 'string' ? title : title?.message;
      alert.title = message;
    }

    if (rest?.text && !title) {
      alert.title = rest.text;
      delete rest.text;
    }

    return Swal.fire({ ...alert, ...rest } as SweetAlertOptions);
  }

  /**
   * Wrapper for SweetAlert2 Toast
   *
   * @default icon: 'warning'
   * @default timer: 5000
   */
  public fireToast(params: ISwalAlertParamsToast): Promise<SweetAlertResult> {
    const { title, icon, timer = 8000, showCloseButton = true, showConfirmButton = false, position = 'top-end', ...rest } = params;

    const toast = Swal.mixin({
      timer,
      toast: true,
      position,
      showConfirmButton,
      showCloseButton,
    });

    const alert: SweetAlertOptions = {
      title: this.getTitle(title),
      icon,
    };

    // console.log('alert 1', alert);
    if (rest?.text && !title) {
      alert.title = rest.text;
      delete rest.text;
    }

    if (!alert.title && !rest.text) {
      console.warn('SweetAlert2: Missing title or text in toast');
      return;
    }
    // console.log({ ...alert, ...rest });
    return toast.fire({ ...alert, ...rest } as SweetAlertOptions);
  }

  private getTitle = (message: string | Error | IHttpException) => {
    if (!message) {
      return;
    }
    if (typeof message === 'string') {
      return message;
    }
    if (message instanceof Error) {
      return message?.message ?? message?.name;
    }

    const text = message?.error ?? message?.message;

    if (typeof text !== 'string') {
      return this.getTitle(text);
    } else {
      return text;
    }
  };

  private formatColor(icon: SweetAlertIcon): string {
    switch (icon) {
      case 'success':
        return 'success';
      case 'error':
        return 'danger';
      case 'warning':
        return 'warning';
      case 'info':
        return 'info';
      default:
        return 'primary';
    }
  }
}
