/**
 * Interface for conditions check
 *
 * @param T - action
 * @param U - item type
 *
 * todo -- deep flatten U for string dot notation but need help with circular depth
 */
export interface ILaunchpointActions<T = any, U = any> {
  action: T;
  title: string;
  // conditions?: Record<string, string | boolean | null | Array<string | boolean | null>>;
  conditions?: {
    [K in keyof U]?: string | boolean | null | Array<string | boolean | null>;
  } & {
    [key: string]: string | boolean | null | Array<string | boolean | null>;
  };
  active: boolean;
  admin?: boolean;
}
