import { InjectionToken } from '@angular/core';

export interface ICoreUserProfileConfigRoutes {
  base: string;
}

export interface LaunchpointCoreClientProfileConfig {
  default_image: string;
  badge?: string;
  icon_svg?: string;
  icon?: string;

  profile_details: {
    title: string;
    fields: {
      first_name: boolean;
      last_name: boolean;
      birthday: boolean;
      gender: boolean;
      marital_status: boolean;
    };
  };
  base_url: string;
  /**
   * Optional route for client side controller for user search
   */
  search?: {
    /**
     * The url to search for users.
     * @example /api/backend/
     */
    base_url: string;
    /**
     * The version of the route
     */
    version: number;
    /**
     * The route to search
     * @example /user/search
     */
    route: string;
  };
  security_roles: string[];
  account_security_roles: string[];
}

export interface LaunchpointCoreConfig {
  default_image: string;
  badge?: string;
  icon_svg?: string;
  icon?: string;
  buttons: {
    title: string;
    page: string;
  }[];
  menu_items: {
    title: string;
    page: string;
  }[];
  profile_completion?: {
    title: string;
    value: number;
  };
  profile_blocks?: {
    title: string;
    value: string;
    icon?: string;
    icon_svg?: string;
    icon_svg_class?: 'svg-icon-success' | 'svg-icon-danger';
  }[];
}

export interface LaunchpointCoreWebProfileHOLDINGConfig {
  default_image: string;
  badge?: string;
  icon_svg?: string;
  icon?: string;
  buttons: {
    title: string;
    page: string;
  }[];
  menu_items: {
    title: string;
    page: string;
  }[];
  profile_completion?: {
    title: string;
    value: number;
  };
  profile_blocks?: {
    title: string;
    value: string;
    icon?: string;
    icon_svg?: string;
    icon_svg_class?: 'svg-icon-success' | 'svg-icon-danger';
  }[];
}

export const PROFILE_CONFIG_TOKEN = new InjectionToken<LaunchpointCoreClientProfileConfig>('PROFILE_CONFIG_TOKEN');
