/**
 * 1. How to use the data, have to know what answer is to what question, and it's type?
 * 2. How to allow flexibility of questions, add or remove questions.
 */

export enum ELaunchpointFormsLogicType {
  EQUALS = 'equals',
  NOT_EQUALS = 'not equals',
  GREATER_THAN = 'greater than',
  LESS_THAN = 'less than',
  GREATER_THAN_OR_EQUAL = 'greater than or equal',
  LESS_THAN_OR_EQUAL = 'less than or equal',
  CONTAINS = 'contains', // For text responses
  DOES_NOT_CONTAIN = 'does not contain', // For text responses
  SELECTED = 'selected', // For multi-select or checkboxes
  NOT_SELECTED = 'not selected', // For multi-select or checkboxes
}

export enum ELaunchpointFormsConditionAction {
  SHOW = 'Show',
  HIDE = 'Hide',
}

export enum ELaunchpointFormsInputType {
  TEXT = 'Text',
  NUMBER = 'Number',
  CURRENCY = 'Currency',
  PERCENTAGE = 'Percentage',
  DATE = 'Date',
  YEAR = 'Year',
  SELECT = 'Select',
  EMAIL = 'Email',
  PHONE = 'Phone',
  FILE = 'File',
  RATING = 'Rating',
  MULTIPLE_CHOICE = 'Multiple Choice', // Similar to 'select' but implies radio buttons or checkboxes
  TEXTAREA = 'Textarea', // For longer text responses
  SLIDER = 'Slider', // For numeric values within a range
  YES_NO = 'Yes/No', // For simple yes/no questions
}

export enum ELaunchpointFormStatus {
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived',
  BETA = 'Beta',
  DRAFT = 'Draft',
}
