import { Directive, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { ICoreAlertBannerFE } from '@launchpoint/core-types';
import { takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { CoreAlertBannerHTTPService } from '../services/alert-banner-http.service';

@Directive()
export abstract class LaunchpointCoreClientAlertBannerStandaloneBaseComponent
  extends LaunchpointCoreClientBaseComponent
  implements OnInit, OnDestroy
{
  _CoreAlertBannerHTTPService = inject(CoreAlertBannerHTTPService);

  @Input() private = true;
  @Input() cssClass = '';

  banners$ = signal<ICoreAlertBannerFE[]>([]);
  currentBanner$ = signal<ICoreAlertBannerFE>(this.banners$()[0]);
  currentIndex$ = signal<number>(0);

  intervalId: any;

  ngOnInit(): void {
    this.getMessages();
    this.startAutoRotate();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  getMessages(): void {
    if (this.private) {
      this._CoreAlertBannerHTTPService
        .getAllForDate()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => this.setBanners(data),
        });
    } else {
      this._CoreAlertBannerHTTPService
        .getAllForDatePublic()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => this.setBanners(data),
        });
    }
  }

  setBanners(data: ICoreAlertBannerFE[]): void {
    this.banners$.set(data);
    this.currentBanner$.set(this.banners$()[this.currentIndex$()]);
  }

  // Function to start the auto-rotate with a timer
  startAutoRotate(): void {
    if (this.banners$().length > 1) {
      // console.log('Rotating to next message...');
      this.intervalId = setInterval(() => {
        this.nextMessage();
      }, 5000);
    }
  }

  // Function to reset the timer
  resetTimer(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Stop the current interval
    }
    this.startAutoRotate(); // Restart the timer
  }

  // Function to move to the next message
  nextMessage(): void {
    const index = this.currentIndex$();
    this.currentIndex$.set((index + 1) % this.banners$().length);
    this.currentBanner$.set(this.banners$()[this.currentIndex$()]);
  }

  // Function to move to the previous message
  previousMessage(): void {
    const index = this.currentIndex$();
    const newIndex = index === 0 ? this.banners$().length - 1 : index - 1; // Go to last item if index is 0
    this.currentIndex$.set(newIndex);
    this.currentBanner$.set(this.banners$()[newIndex]);
  }

  // Called when the user clicks next arrow
  onNext(event: Event): void {
    this.nextMessage();
    this.resetTimer(); // Reset the timer when user manually changes the message
  }

  // Called when the user clicks previous arrow
  onPrevious(event: Event): void {
    this.previousMessage();
    this.resetTimer(); // Reset the timer when user manually changes the message
  }

  get getBannerClass() {
    const { banner_color = 'light-danger', text_color = 'danger' } = this.currentBanner$() ?? { banner_color: 'light-danger', text_color: 'danger' };

    return 'bg-' + banner_color + ' text-' + text_color + ' ' + this.cssClass;
  }

  get getArrowClass() {
    const { banner_color = 'light-danger', text_color = 'danger' } = this.currentBanner$() ?? { banner_color: 'light-danger', text_color: 'danger' };

    let arrowColor = '';
    if (!banner_color.includes('light-')) {
      arrowColor = 'text-white';
    }

    arrowColor += ' text-hover-' + text_color;

    return arrowColor;
  }

  get getDotClass() {
    const { banner_color = 'light-danger', text_color = 'danger' } = this.currentBanner$() ?? { banner_color: 'light-danger', text_color: 'danger' };

    let arrowColor = '';
    if (!banner_color.includes('light-')) {
      arrowColor = 'text-white';
    }

    arrowColor += ' text-' + text_color;

    return arrowColor;
  }
}
