<ng-container *ngFor="let option of allOptions">
  <div [ngClass]="cssClass">
    <div>
      <input [ngClass]="cssClassInput" type="checkbox" [ngModel]="checkIfIncluded(option)" (ngModelChange)="adjustValue($event, option)" />
      <label [ngClass]="cssClassLabel" for="flexCheckChecked">
        {{ option }}
      </label>
    </div>
  </div>
</ng-container>
