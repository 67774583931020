import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ELaunchpointGeo, ICoreUserAddressParamsCreate, ICoreUserAddressParamsUpdate, ICoreUserAddresses } from '@launchpoint/core-types';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { EUserAddressTitle } from '../../../core-address/interfaces/core-address.interface';
import { GoogleAddressService } from '../../../core-address/services/autocomplete/address-autocomplete.service';
import { AppSettingsCountriesService, ICountries } from '../../../core-address/services/autocomplete/app-settings-countries.service';
import { IUSState, UsStates } from '../../../core-address/services/autocomplete/states.constants';

interface IProfileAddressConfig {
  title: string;
  fields: {
    address: boolean;
    google_maps_api_key: string; // I don't think this is needed since each app while have in their index.html their api key for google maps
  };
}

export interface GoogleAddress {
  line_one: string;
  line_two: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  long: number;
  lat: number;
  location: {
    type: ELaunchpointGeo.POINT;
    /**
     * list the longitude first, and then latitude.
     */
    coordinates: [number, number] | [];
  };
}

export type ICoreUserAddressParamsUpdateNoUserID = Omit<ICoreUserAddressParamsUpdate, 'user_id'>;
export type ICoreUserAddressParamsCreateNoUserID = Omit<ICoreUserAddressParamsCreate, 'user_id'>;

@Directive()
export class LaunchpointCoreClientProfileAddressAutocompleteBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnChanges {
  @Input() _config: IProfileAddressConfig = {
    title: 'Billing Address',
    fields: {
      address: true,
      google_maps_api_key: '', // I don't think this is needed since each app while have in their index.html their api key for google maps
    },
  };
  @Input() address: ICoreUserAddresses;
  @Input() address_type: EUserAddressTitle;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('receive_address_text') receive_address_text: any;

  place!: unknown;
  country_invalid = false;
  formatted_address: string;
  address_form!: FormGroup; //<ControlsOf<GoogleAddress>>;
  google_address!: GoogleAddress;
  unitedStatesStates: IUSState[] = UsStates;
  countryListFull: ICountries[];
  countryList = ['United States', 'Mexico', 'Type for more options'];
  isLoading = false;
  countryObject;

  @Output() profileAddressUpdate = new EventEmitter<ICoreUserAddressParamsUpdateNoUserID>();
  @Output() profileAddressCreate = new EventEmitter<ICoreUserAddressParamsCreateNoUserID>();

  constructor(
    public googleAddressService: GoogleAddressService,
    public formBuilder: FormBuilder,
    public _AppSettingsCountriesService: AppSettingsCountriesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeAddressForm();
    this.countryListFull = this._AppSettingsCountriesService.getCountries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.address.previousValue != changes.address.currentValue) {
      this.initializeAddressForm();
    }
  }

  initializeAddressForm() {
    const initialAddress: GoogleAddress = {
      line_one: null,
      line_two: null,
      city: null,
      state: null,
      country: null,
      zip_code: null,
      lat: null,
      long: null,
      location: null,
    };
    this.address_form = this.formBuilder.group({
      line_one: new FormControl(this.address?.line_one ?? '', [Validators.required, Validators.maxLength(200)]),
      line_two: new FormControl(this.address?.line_two ?? ''),
      city: new FormControl(this.address?.city ?? '', [Validators.required, Validators.maxLength(100)]),
      state: new FormControl(this.address?.state, [Validators.required]),
      zip_code: new FormControl(this.address?.zip_code, [Validators.required, Validators.maxLength(15)]),
      country: new FormControl(this.address?.country, [Validators.required, Validators.maxLength(2)]),
      long: new FormControl(this.address?.long),
      lat: new FormControl(this.address?.lat),
      location: new FormControl(this.address?.location),
    });
    this.google_address = initialAddress;
    if (this.address) {
      this.address_form.patchValue(this.address);
    }
  }
  get state() {
    return this.address_form.get('state');
  }
  get country() {
    return this.address_form.get('country');
  }
  get line_one() {
    return this.address_form.get('line_one');
  }
}
