import { Directive } from '@angular/core';
import { ICoreUserParamsChangeEmail } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { setUserEmail, updateUserPassword } from '../../../../../users/+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';
import { ICoreUserParamsChangePasswordNOUserId } from './update-email-password-base.component';

@Directive()
export class LaunchpointCoreClientUpdateEmailPasswordSmartAdminBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
  async dispatchEmailChange(event: ICoreUserParamsChangeEmail) {
    const selected_user = await firstValueFrom(this.selected_user$);

    this._Store.dispatch(setUserEmail({ data: { ...event, user_id: selected_user.user._id, verified: false } }));
  }

  async dispatchPasswordChange(event: ICoreUserParamsChangePasswordNOUserId) {
    const selected_user = await firstValueFrom(this.selected_user$);
    this._Store.dispatch(updateUserPassword({ data: { ...event, user_id: selected_user.user._id } }));
  }
}
