import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { RouterActions } from '../../../router/+state';
import { LaunchpointBlogHTTPService } from '../../services/blog-http.service';
import { LaunchpointBlogPublicHTTPService } from '../../services/blog-public.http.service';
import * as BlogsActions from '../actions/blogs.actions';
import * as BlogsSelectors from '../selectors/blogs.selectors';
import { LaunchpointBlogTagsHTTPService } from '../../services/blog-http-tags.service';
import { LaunchpointBlogSeoHTTPService } from '../../services/blog-http-seo.service';
import { LaunchpointBlogRelatedPostsHTTPService } from '../../services/blog-http-related-posts.service';
import { LaunchpointBlogCommentsHTTPService } from '../../services/blog-http-comments.service';
import { LaunchpointBlogCommentRepliesHTTPService } from '../../services/blog-http-comment-replies.service';

@Injectable()
export class BlogsEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _LaunchpointBlogHTTPService: LaunchpointBlogHTTPService,
    private _LaunchpointBlogTagsHTTPService: LaunchpointBlogTagsHTTPService,
    private _LaunchpointBlogPublicHTTPService: LaunchpointBlogPublicHTTPService,
    private _LaunchpointBlogSeoHTTPService: LaunchpointBlogSeoHTTPService,
    private _LaunchpointBlogRelatedPostsHTTPService: LaunchpointBlogRelatedPostsHTTPService,
    private _LaunchpointBlogCommentsHTTPService: LaunchpointBlogCommentsHTTPService,
    private _LaunchpointBlogCommentRepliesHTTPService: LaunchpointBlogCommentRepliesHTTPService
  ) {}

  // ------------- PUBLIC -------------

  findOne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.findOneBlog),
      switchMap((action) => {
        return this._LaunchpointBlogPublicHTTPService.findOnePublic({ title_url: action.title }).pipe(
          map((data) => {
            return BlogsActions.findOneBlogSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.findOneBlogFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.findOneBlogFailure(error)))
    );
  });

  // ------------- PRIVATE -------------

  getAllBlogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        BlogsActions.searchAllBlogs,
        BlogsActions.updateAllBlogsQuery,
        BlogsActions.updateAllBlogsSorting,
        BlogsActions.updateAllBlogsPagination
      ),
      withLatestFrom(this._Store.select(BlogsSelectors.getAllBlogsState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._LaunchpointBlogHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((data) => {
            return BlogsActions.searchAllBlogsSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.searchAllBlogsFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.searchAllBlogsFailure(error)))
    );
  });

  findById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.getBlogById),
      switchMap((action) => {
        return this._LaunchpointBlogHTTPService.findById(action.blog_id).pipe(
          map((data) => {
            return BlogsActions.getBlogByIdSuccess({ data });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.getBlogByIdFailure({ blog_id: action.blog_id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.getBlogByIdFailure(error)))
    );
  });

  setFeatured$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.setFeatured),
      switchMap((action) => {
        return this._LaunchpointBlogHTTPService.markFeatured(action.blog_id).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Blog Marked as Featured',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.blog_id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });

  createBlog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.createBlog),
      switchMap((action) => {
        return this._LaunchpointBlogHTTPService.create(action.data).pipe(
          map((data) => {
            return BlogsActions.createBlogSuccess({
              data,
              success_message: 'Blog Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.createBlogFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.createBlogFailure(error)))
    );
  });

  updateBlog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.updateBlog),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._LaunchpointBlogHTTPService.update(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Blog Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });

  deleteBlog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.deleteBlog),
      switchMap((action) => {
        // console.log('action prof', action);
        return this._LaunchpointBlogHTTPService.delete(action.blog_id).pipe(
          map((data) => {
            return BlogsActions.deleteBlogSuccess({
              data,
              success_message: 'Blog Deleted',
              redirectPath: action.redirectPath,
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.deleteBlogFailure({ blog_id: action.blog_id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.deleteBlogFailure(error)))
    );
  });

  deleteBlogSuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(BlogsActions.deleteBlogSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: data.redirectPath } }));
          }
          return of();
        }),
        catchError((error) => of(BlogsActions.deleteBlogFailure(error)))
      );
    }
    // { dispatch: false }
  );

  ///
  setBlogTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.setTags),
      switchMap((action) => {
        return this._LaunchpointBlogTagsHTTPService.set(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Tags Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });

  updateBlogSEO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.updateBlogSEO),
      switchMap((action) => {
        return this._LaunchpointBlogSeoHTTPService.update(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'SEO Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });

  // ----- Related Posts -----

  addRelatedPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.addRelatedPosts),
      switchMap((action) => {
        return this._LaunchpointBlogRelatedPostsHTTPService.add(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Related Posts Added',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  removeRelatedPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.removeRelatedPosts),
      switchMap((action) => {
        return this._LaunchpointBlogRelatedPostsHTTPService.remove(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Related Posts Removed',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  setRelatedPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.setRelatedPosts),
      switchMap((action) => {
        return this._LaunchpointBlogRelatedPostsHTTPService.set(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Related Posts Updated',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  // ----- Comments -----

  addComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.addComment),
      switchMap((action) => {
        return this._LaunchpointBlogCommentsHTTPService.add(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Added',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  removeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.removeComment),
      switchMap((action) => {
        return this._LaunchpointBlogCommentsHTTPService.remove(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Removed',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  likeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.likeComment),
      switchMap((action) => {
        return this._LaunchpointBlogCommentsHTTPService.like(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Liked',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  dislikeComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.dislikeComment),
      switchMap((action) => {
        return this._LaunchpointBlogCommentsHTTPService.dislike(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Disliked',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  // ----- Comment Replies -----

  addCommentReply$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.addCommentReply),
      switchMap((action) => {
        return this._LaunchpointBlogCommentRepliesHTTPService.add(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Reply Added',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  removeCommentReply$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.removeCommentReply),
      switchMap((action) => {
        return this._LaunchpointBlogCommentRepliesHTTPService.remove(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Reply Removed',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  likeCommentReply$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.likeCommentReply),
      switchMap((action) => {
        return this._LaunchpointBlogCommentRepliesHTTPService.like(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Reply Liked',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
  dislikeCommentReply$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BlogsActions.dislikeCommentReply),
      switchMap((action) => {
        return this._LaunchpointBlogCommentRepliesHTTPService.dislike(action.data).pipe(
          map((data) => {
            return BlogsActions.updateBlogSuccess({
              data,
              success_message: 'Comment Reply Disliked',
            });
          }),
          catchError((error: IHttpRequestError) => of(BlogsActions.updateBlogFailure({ blog_id: action.data._id, error: error.error })))
        );
      }),
      catchError((error) => of(BlogsActions.updateBlogFailure(error)))
    );
  });
}
