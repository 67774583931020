import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LaunchpointCoreWebAuthV2Component } from './components/base/auth-v2.component';
import { LaunchpointCoreWebAuthV2LoginComponent } from './components/login/auth-v2-login.component';
import { LaunchpointCoreWebAuthV2LogoutComponent } from './components/logout/auth-v2-logout.component';
import { LaunchpointCoreWebAuthV2RegistrationComponent } from './components/registration/auth-v2-registration.component';
import { LaunchpointCoreWebAuthV2SetPasswordComponent } from './components/set-password/auth-v2-set-password.component';
import { LaunchpointCoreWebAuthV2VerifyComponent } from './components/verify/auth-v2-verify.component';

const routes: Routes = [
  {
    path: '',
    component: LaunchpointCoreWebAuthV2Component,
    children: [
      {
        path: '',
        redirectTo: 'registration',
        pathMatch: 'full',
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'login',
        component: LaunchpointCoreWebAuthV2LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'verify',
        component: LaunchpointCoreWebAuthV2VerifyComponent,
        data: { returnUrl: window.location.pathname },
      },
      {
        path: 'registration',
        component: LaunchpointCoreWebAuthV2RegistrationComponent,
      },
      // {
      //   path: 'forgot-password',
      //   component: LaunchpointForgotPasswordV2Component,
      // },
      {
        path: 'set-password',
        component: LaunchpointCoreWebAuthV2SetPasswordComponent,
        // canActivate: [ResetPasswordGuard],
      },
      {
        path: 'logout',
        component: LaunchpointCoreWebAuthV2LogoutComponent,
      },
      { path: '**', redirectTo: 'login', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingV2Module {}
