import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreCompany,
  ICoreCompanyController,
  ICoreCompanyParamsCreate,
  ICoreCompanyParamsCreateResults,
  ICoreCompanyParamsSignUp,
  ICoreCompanyParamsSignUpResults,
  ICoreCompanyParamsUpdate,
  ICoreCompanySearchPayload,
  ICoreCompanySearchResults,
} from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../../user/auth/interfaces/auth-storage-service.interface';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '../../security/header-services/account-id-security-header-v2.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyV2Service extends LaunchpointSecurityAccountIdHeaderServiceV2 implements ICoreCompanyController {
  API_URL = '/api/backend/';

  routes = {
    search: 'company/search',
    getById: 'company',
    create: 'company',
    update: 'company',
    delete: 'company',
    deleteInfo: 'company/delete-info',
    getByEmail: 'company/email',
    signUp: 'company/sign-up',
    deactivate: 'company/deactivate',
  };

  constructor(
    private http: HttpClient,
    @Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig
  ) {
    super();
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 2,
    });
    if (_CoreAuthConfig?.routes) {
      for (const key in _CoreAuthConfig.routes) {
        if (Object.prototype.hasOwnProperty.call(_CoreAuthConfig.routes, key)) {
          if (this.routes[key]) {
            this.routes[key] = _CoreAuthConfig.routes[key];
          } else {
            console.error('The route you are trying to set does not exist in the service. Please check your version if there have been updates');
          }
        }
      }
    }
  }

  public search(params: ICoreCompanySearchPayload): Observable<ICoreCompanySearchResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreCompanySearchResults>(this.API_URL + this.routes.search, params, { headers });
      })
    );
  }

  public getById(id: string): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreCompany>(this.API_URL + this.routes.getById + '/' + id, { headers });
      })
    );
  }

  public searchOneByEmail(email: string): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<ICoreCompany>(this.API_URL + this.routes.getByEmail + '/' + email, { headers });
      })
    );
  }

  public create(params: ICoreCompanyParamsCreate): Observable<ICoreCompanyParamsCreateResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreCompanyParamsCreateResults>(this.API_URL + this.routes.create, params, { headers });
      })
    );
  }

  public signUp(params: ICoreCompanyParamsSignUp): Observable<ICoreCompanyParamsSignUpResults> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreCompanyParamsSignUpResults>(this.API_URL + this.routes.signUp, params, { headers });
      })
    );
  }

  public update(params: ICoreCompanyParamsUpdate): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreCompany>(this.API_URL + this.routes.update, params, { headers });
      })
    );
  }

  public deactivate(params: ICoreCompanyParamsUpdate): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreCompany>(this.API_URL + this.routes.deactivate, params, { headers });
      })
    );
  }

  public delete(id: string): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<ICoreCompany>(this.API_URL + this.routes.delete + '/' + id, { headers });
      })
    );
  }

  public deleteInfo(id: string): Observable<ICoreCompany> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreCompany>(this.API_URL + this.routes.deleteInfo + '/' + id, { headers });
      })
    );
  }
}
