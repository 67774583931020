<div
  class="card flex w-[300px] flex-col border"
  [ngClass]="{
    'border-white text-white': type === 'default',
    'border-black text-black': type === 'inverted',
  }"
>
  <div class="p-4">
    @if (card?.image) {
      <img [src]="card.image" [alt]="card.title" class="h-[150px] w-full object-cover" />
    } @else {
      <div class="flex h-[150px] items-center justify-center bg-white font-bold text-black">COMING SOON</div>
    }
  </div>

  <div
    class="border"
    [ngClass]="{
      'border-white': type === 'default',
      'border-black': type === 'inverted',
    }"
  ></div>

  <div class="text-md p-4 font-semibold">
    {{ card?.title }}
    <div class="flex items-center pt-4 text-sm">
      Read More
      <i class="fa-light fa-circle-arrow-right ms-3"></i>
    </div>
  </div>
</div>
