/* eslint-disable no-prototype-builtins */
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserSelectors } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { layoutSelectors } from '../+state';
import { AppBaseComponent } from '../../../app-base.component';
import { LayoutInitService } from '../../core/layout-init.service';
import { LayoutService } from '../../core/layout.service';
import { LaunchpointTopbarConfig } from '../../../interfaces/topbar.interface';

@Component({
  selector: 'launchpoint-base-layout',
  template: '',
})
export class AppLaunchpointBaseLayoutComponent extends AppBaseComponent implements OnInit, AfterViewInit {
  // Public variables
  selfLayout = 'default';
  asideSelfDisplay: true;
  asideMenuStatic: true;
  contentClasses = '';
  contentContainerClasses = '';
  toolbarDisplay = true;
  contentExtended: false;
  asideCSSClasses: string;
  asideHTMLAttributes: any = {};
  headerMobileClasses = '';
  headerMobileAttributes = {};
  footerDisplay: boolean;
  footerCSSClasses: string;
  headerCSSClasses: string;
  headerHTMLAttributes: any = {};
  // offcanvases
  extrasSearchOffcanvasDisplay = false;
  extrasNotificationsOffcanvasDisplay = false;
  extrasQuickActionsOffcanvasDisplay = false;
  extrasCartOffcanvasDisplay = false;
  extrasUserOffcanvasDisplay = false;
  extrasQuickPanelDisplay = false;
  extrasScrollTopDisplay = false;
  asideDisplay: boolean;
  @ViewChild('ktAside', { static: true }) ktAside: ElementRef;
  @ViewChild('ktHeaderMobile', { static: true }) ktHeaderMobile: ElementRef;
  @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;
  user$: Observable<ICoreUser | null | undefined>;
  topBarConfig$: Observable<LaunchpointTopbarConfig>;

  constructor(public initService: LayoutInitService, public layout: LayoutService, public _Store: Store) {
    super();
    this.user$ = this._Store.select(UserSelectors.selectUser) as Observable<ICoreUser | null | undefined>;
    this.topBarConfig$ = this._Store.select(layoutSelectors.getTopbarConfig);
    this.initService.init();
  }

  ngOnInit(): void {
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.toolbarDisplay = this.layout.getProp('toolbar.display') as boolean;
    this.contentContainerClasses = this.layout.getStringCSSClasses('contentContainer');
    this.asideCSSClasses = this.layout.getStringCSSClasses('aside');
    this.headerCSSClasses = this.layout.getStringCSSClasses('header');
    this.headerHTMLAttributes = this.layout.getHTMLAttributes('headerMenu');
    this.initMethods();
  }

  ngAfterViewInit(): void {
    if (this.ktHeader) {
      for (const key in this.headerHTMLAttributes) {
        if (this.headerHTMLAttributes.hasOwnProperty(key)) {
          this.ktHeader.nativeElement.attributes[key] = this.headerHTMLAttributes[key];
        }
      }
    }
  }

  /**
   * Extend this
   * @returns
   */
  initMethods() {
    return;
  }
}
