import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  LaunchpointUserViewsConfig,
  USER_VIEWS_CONFIG_TOKEN,
  UserViewV1Service,
  VIEW_EFFECTS,
  ViewFeatureKey,
  ViewReducer,
} from '@launchpoint/core-client';
import { EffectsModule } from '@ngrx/effects';
import { UserViewsComponent } from './user-views/user-views.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserViewsSearchAheadComponent } from './user-views-search-ahead/user-views-search-ahead.component';
import { TagifyModule } from 'ngx-tagify';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserViewSaveComponent } from './user-view-save/user-view-save.component';
import { UserViewDeleteComponent } from './user-view-delete/user-view-delete.component';

@NgModule({
  declarations: [UserViewsComponent, UserViewSaveComponent, UserViewDeleteComponent, UserViewsSearchAheadComponent],
  imports: [
    CommonModule,
    NgbModule,
    TagifyModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(ViewFeatureKey, ViewReducer),
    EffectsModule.forFeature(VIEW_EFFECTS),
  ],
  exports: [UserViewsComponent, UserViewSaveComponent, UserViewDeleteComponent, UserViewsSearchAheadComponent],
})
export class UserViewsModule {
  static forRoot(_viewConfig?: LaunchpointUserViewsConfig): ModuleWithProviders<UserViewsModule> {
    return {
      ngModule: UserViewsModule,
      providers: [
        {
          provide: USER_VIEWS_CONFIG_TOKEN,
          useValue: _viewConfig,
        },
        UserViewV1Service,
      ],
    };
  }
}
