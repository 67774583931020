import { Observable } from 'rxjs';
import { ICoreUserJWTDecorator } from '../auth/auth.user.interface';
import {
  IMedia,
  IMediaMetadataACLParamsUpdate,
  IMediaParamsCreate,
  IMediaParamsDelete,
  IMediaParamsUpdate,
  IMediaParamsUpdateMetadata,
  IMediaSearchPayload,
  IMediaSearchResults,
} from './media.interface';

export interface IMediaController {
  /**
   * @Post('search')
   */
  search(body: IMediaSearchPayload): Promise<IMediaSearchResults> | Observable<IMediaSearchResults>;
  /**
   * @Get(':id')
   */
  getById(id: string): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Post()
   */
  create(body: IMediaParamsCreate, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Put()
   */
  update(body: IMediaParamsUpdate, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   * @Put('delete')
   */
  delete(id: IMediaParamsDelete, user: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   *  @Post('acl')
   */
  putACL(body: IMediaMetadataACLParamsUpdate, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
  /**
   *  @Post('metadata')
   */
  metadata(body: IMediaParamsUpdateMetadata, user?: ICoreUserJWTDecorator): Promise<IMedia> | Observable<IMedia>;
}
