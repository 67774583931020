import { ITopic } from '../../notifications/topics/topics.interface';

export class ICoreUserNotificationPreferences {
  _id?: string;
  topic: string | ITopic;
  subscribed?: boolean;
  created_at?: Date;
}

export class ICoreUserNotificationPreferencesParamsUpsert implements Omit<ICoreUserNotificationPreferences, '_id'> {
  /**
   * _id of the topic
   */
  topic: string;
  subscribed?: boolean;
}

export class ICoreUserNotificationTokens {
  created_at?: Date;
  updated_at?: Date;
  /**
   * Firebase Cloud Messaging
   */
  token: string;
  /**
   * Apple notification token
   */
  apns?: string;
}

export class ICoreUserNotificationEnpoints {
  created_at?: Date;
  updated_at?: Date;
  /**
   * Firebase Cloud Messaging
   */
  token: string;
  /**
   * Apple notification token
   */
  apns?: string;
}

export class ICoreUserNotificationTokensParamsUpsert implements Pick<ICoreUserNotificationTokens, 'token' | 'apns'> {
  token: string;
  apns?: string;
}
