import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ENotificationChannelType, ENotificationMessageType } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientNotificationsStateBaseComponent } from '../+state/notifications-state-base.component';

@Directive()
export abstract class LaunchpointCoreClientNotificationCreateBaseComponent
  extends LaunchpointCoreClientNotificationsStateBaseComponent
  implements OnInit
{
  public fb = inject(FormBuilder);
  @Input() title: string;

  NotificationChannelType = ENotificationChannelType;

  messageTypes = [
    { id: 'Text', checked: false, active: true, icon: 'chat', value: ENotificationChannelType.SMS },
    { id: 'Push Notification', checked: false, active: true, icon: 'bullseye', value: ENotificationChannelType.PUSH },
    { id: 'Email', checked: true, active: true, icon: 'envelope', value: ENotificationChannelType.EMAIL },
    { id: 'Slack', checked: false, active: true, icon: 'slack', value: ENotificationChannelType.SLACK },
  ];
  selectAll = false;

  form: FormGroup<{
    job_type: FormControl<ENotificationMessageType>;
    channels: FormArray<FormControl<ENotificationChannelType>>;
    title: FormControl<string>;
    description: FormControl<string>;
  }>;
  emailForm: FormGroup<{
    topic: FormControl<string>;
    cc: FormControl<string>;
    bcc: FormControl<string>;
    from: FormControl<string>;
    replyTo: FormControl<string>;
    subject: FormControl<string>;
    text: FormControl<string>;
  }>;
  smsForm: FormGroup;
  pushForm: FormGroup;
  selectedChannel: ENotificationChannelType;
  isBccVisible = false;
  isCcVisible = false;

  smsFormComplete = false;
  pushFormComplete = false;

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      job_type: [ENotificationMessageType.MESSAGE],
      channels: this.fb.array([ENotificationChannelType.EMAIL]),
      title: [''],
      description: [''],
    });

    this.emailForm = this.fb.group({
      topic: [''],
      cc: [''],
      bcc: [''],
      from: [''],
      replyTo: [''],
      subject: [''],
      text: [''],
    });

    this.emailForm.controls.text.valueChanges.subscribe({
      next: (value) => {
        console.log({ value });
      },
    });

    this.smsForm = this.fb.group({
      topic: ['', Validators.compose([Validators.required])],
      message: ['', Validators.compose([Validators.required])],
    });
  }

  activeChannel(value: ENotificationChannelType) {
    this.selectedChannel = value;
    console.log(this.selectedChannel);
  }

  submitTest() {
    const selectedChannels = this.messageTypes.filter((messageType) => messageType.checked).map((messageType) => messageType.value);
    const message = {
      job_type: ENotificationMessageType.MESSAGE,
      channels: [selectedChannels],
      title: this.form.controls.title.value,
      description: this.form.controls.description.value,
    };
    console.log(message);
    this.selectedChannel = selectedChannels[0];
  }

  smsTest() {
    const data = {
      title: this.smsForm.controls.topic.value,
      message: this.smsForm.controls.message.value,
    };
    if (data.title && data.message) {
      this.smsFormComplete = true;
    }
    console.log(data);
  }

  selectAllChanged(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.messageTypes.forEach((messageType) => (messageType.checked = isChecked));
  }

  checkboxChanged(event): void {
    console.log({ event });
    const allChecked = this.messageTypes.every((messageType) => messageType.checked);
    if (allChecked) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  getSelectedMessageTypes() {
    return this.messageTypes.filter((messageType) => messageType.checked);
  }

  atLeastOneMessageTypeSelected(): boolean {
    return this.messageTypes.some((messageType) => messageType.checked);
  }
}
