<div class="lauchpoint-launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <launchpoint-toolbar [options]="options">
      <launchpoint-notifications-filter />
    </launchpoint-toolbar>


    <launchpoint-notifications-table />

  </div>
</div>