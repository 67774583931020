import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { LaunchpointCoreClientMediaUploadBaseComponent } from '@launchpoint/core-client';
import { EMediaMimeTypes, IMedia, IMediaSearchQuery } from '@launchpoint/core-types';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'launchpoint-media-video-settings-modal',
  templateUrl: 'media-video-settings-modal.component.html',
  styles: `
    .divider {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;
      border-bottom: 1px solid #ccc; /* Adjust the border styles as needed */
      margin: 10px 0; /* Adjust the margin as needed */
    }

    .divider-text {
      background-color: #fff; /* Adjust the background color as needed */
      padding: 0 10px; /* Adjust the padding as needed */
    }
  `,
})
export class LaunchpointCoreWebMediaVideoSettingsModalComponent extends LaunchpointCoreClientMediaUploadBaseComponent {
  createModal: NgbModalRef;
  public activeModal = inject(NgbActiveModal);
  public modalService = inject(NgbModal);

  media: IMedia;
  media_id: string;

  @Input() type: 'Subtitle' | 'Audio' | 'Poster';

  accept = '*';

  @Output() selected = new EventEmitter<string>();

  get query(): IMediaSearchQuery {
    switch (this.type) {
      case 'Poster': {
        this.accept = 'image/*';
        return { types: [EMediaMimeTypes.IMAGE] };
      }
      case 'Audio': {
        this.accept = 'audio/*';
        return { types: [EMediaMimeTypes.AUDIO] };
      }
      case 'Subtitle': {
        this.accept = 'text/vtt';
        return { types: [EMediaMimeTypes.TEXT] };
      }
      default: {
        return;
      }
    }
  }

  submit() {
    this.selected.emit(this.media_id);

    this.close();
  }

  setMedia(media: IMedia | string) {
    if (typeof media === 'string') {
      this.media_id = media;
      return;
    }
    this.media_id = media._id;
  }

  async uploadOne() {
    try {
      await this.upload();
    } catch (error) {
      console.log('error', error);
    }

    if (!this.files.length) {
      // console.log('no files uploaded');
      return;
    }

    this.media_id = this.files[0].media._id;
  }

  close() {
    this.activeModal.close();
  }

  get disabled() {
    return this.media_id ? false : true;
  }
}
