import { Component } from '@angular/core';
import { CompanyConnectionsBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-connections',
  templateUrl: 'company-connections.component.html',
})
export class CompanyConnectionsComponent extends CompanyConnectionsBaseComponent {
  constructor() {
    super();
  }
}
