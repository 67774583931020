import { Observable } from 'rxjs';
import { ILaunchpointDynamicForm } from '../../form.interface';
import {
  ILaunchpointDynamicFormGroupQuestionParamsChangeGroup,
  ILaunchpointDynamicFormGroupQuestionParamsCreate,
  ILaunchpointDynamicFormGroupQuestionParamsDelete,
  ILaunchpointDynamicFormGroupQuestionParamsReorder,
  ILaunchpointDynamicFormGroupQuestionParamsUpdate,
} from './form-groups-questions.interface';

/**
 * @route @Controller('questions')
 */
export interface ILaunchpointDynamicFormGroupQuestionController {
  add(params: ILaunchpointDynamicFormGroupQuestionParamsCreate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  update(params: ILaunchpointDynamicFormGroupQuestionParamsUpdate): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
  reorder(params: ILaunchpointDynamicFormGroupQuestionParamsReorder): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
  changeGroup(params: ILaunchpointDynamicFormGroupQuestionParamsChangeGroup): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;

  remove(params: ILaunchpointDynamicFormGroupQuestionParamsDelete): Promise<ILaunchpointDynamicForm> | Observable<ILaunchpointDynamicForm>;
}
