import { IMedia, IHttpException, IQueryPageination, IQuerySort, IMediaSearchQuery } from '@launchpoint/core-types';
import { EntityState } from '@ngrx/entity';

export const coreMediaFeatureKey = 'coreMediaFeatureKey';

export interface IMediaEntity {
  media_id: string;
  media: IMedia;
  loaded: boolean;
  loading: boolean;
  error: IHttpException;
  success_message: string;
}

export interface IMediaEntityState extends EntityState<IMediaEntity> {
  selected_media_id: string;
  pagination: IQueryPageination;
  querySort: IQuerySort;
  query: IMediaSearchQuery; // IMediaQueryParams;
  loaded: boolean;
  loading: boolean;
  error: IHttpException;
  success_message: string;
}
