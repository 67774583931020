<div class="card">
  <div class="card-body">
    <ng-container *ngIf="loading$ | async; else showTable">
      <div class="d-flex align-items-center">
        <div class="mr-2 text-muted">Loading...</div>
        <div class="spinner spinner-primary mr-10"></div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #showTable>
  <div *ngIf="companyEntity$ | async as companyEntity">
    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
      <div class="table-responsive">
        <table class="table align-middle table-hover table-row-dashed fs-6 gy-5 dataTable no-footer">
          <thead>
            <tr class="fw-bold text-gray-700 text-uppercase fs-7">
              <th class="min-w-125px">Name</th>
              <th class="min-w-125px">Email</th>
              <th class="min-w-120px">Email Verified</th>
              <th class="min-w-100px">Security Roles</th>
              <th class="min-w-120px">Location</th>
              <th class="min-w-120px text-end">Actions</th>
            </tr>
          </thead>
          <tbody class="fw-semibold fs-6">
            <tr *ngFor="let user of companyEntity?.company_users" [routerLink]="'/admin/users/' + user?._id"
              routerLinkActive="active">
              <td>
                {{ user?.profile?.full_name }}
              </td>

              <td>
                {{ user?.email }}
              </td>

              <td>
                <i class="bi bi-check-circle text-success" *ngIf="user?.email_verification?.email_verified"></i>
                <i class="bi bi-x-circle text-danger" *ngIf="!user?.email_verification?.email_verified"></i>
              </td>
              <!-- Security Roles -->
              <td>
                {{ accountSecurityRoles(user?.accounts, companyEntity?.company_id) }}
              </td>
              <td>
                {{ user?.addresses?.[0]?.state }}
              </td>

              <td class="text-end">
                <button class="btn btn-icon text-hover-primary btn-sm" ngbTooltip="Remove User"
                  (click)="deleteConfirm(companyEntity?.company_id, user, $event)">
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</ng-template>