import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { CompanyProfileBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-profile',
  templateUrl: 'company-profile.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class CompanyProfileComponent extends CompanyProfileBaseComponent {
  // constructor(fb: UntypedFormBuilder, _Store: Store, _route: ActivatedRoute) {
  //   super(fb, _Store, _route);
  // }
}
