<div class="app-content flex-column-fluid container-xxl px-xxl-20">
  <div class="app-container container-fluid px-md-20">
    <div class="card mb-5 mb-xl-10 p-20">
      <div class="d-flex justify-content-between mb-20">
        <div>
          <div><h3>LaunchPoint Updates</h3></div>
          <div class="w-75">Receive Updates on new programs and things coming to Launchpoint</div>
        </div>
        <div>
          <div class="form-check form-switch ms-2">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-20">
        <div>
          <div><h3>Marketing Email</h3></div>
          <div class="w-75">Receive marketing emails from LaunchPoint</div>
        </div>
        <div>
          <div class="form-check form-switch ms-2">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mb-20">
        <div>
          <div><h3>Features</h3></div>
          <div class="w-75">Stay up to date with new LaunchPoint features.</div>
        </div>
        <div>
          <div class="form-check form-switch ms-2">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center">
  <div class="card p-15" style="width: 30rem">
    <div class="d-flex justify-content-center flex-column">
      <h4 class="text-center">Don't want any notifications?</h4>
      <a class="text-center cursor-pointer">Unsubscribe</a>
    </div>
  </div>
</div>
