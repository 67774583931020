import { Observable } from 'rxjs';
import { ICoreUser } from '../../../user-company-account.interface';
import { ICoreUserSecurityRoleParamsTransfer, ICoreUserSecurityRoleParamsUpdate } from './core-security-roles.interface';

/**
 * @Controller('user/security-roles')
 */
export interface ICoreUserSecurityRoleController {
  /**
   * Post()
   * @param body
   */
  addRole(body: ICoreUserSecurityRoleParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put('remove')
   * @param body
   */
  removeRole(body: ICoreUserSecurityRoleParamsUpdate): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Put('transfer')
   * @param body
   */
  transferRole(body: ICoreUserSecurityRoleParamsTransfer): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
