import { IQueryPageination, IQuerySort } from '../../../query.interfaces';

export enum ECoreUserInvitationStatus {
  ACCEPTED = 'Accepted',
  PENDING = 'Pending',
  REJECTED = 'Rejected',
}

export enum ECoreUserInvitationType {
  PLATFORM_INVITE = 'Platform Invite',
  ACCOUNT_INVITED = 'Account Invite',
}

export interface ICoreUserInvitation {
  _id?: string;
  created_at: Date;
  updated_at: Date;

  sender_email: string;
  sender_user_id: string;
  recipient_email: string;
  recipient_user_id: string;

  accepted_date?: Date;
  rejected_date?: Date;

  status: ECoreUserInvitationStatus;
  type: ECoreUserInvitationType;

  account_id?: string;
}

export class ICoreUserInvitationsSearchQuery {
  /**
   * Will match sender or recipient emails
   */
  search?: string;
}

export class ICoreUserInvitationsSearchPayload {
  query: ICoreUserInvitationsSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ICoreUserInvitationsSearchResults {
  data: ICoreUserInvitation[];
  pagination: IQueryPageination;
}

export class ICoreUserInvitationParamsCreate
  implements Omit<Partial<ICoreUserInvitation>, '_id' | 'created_at' | 'updated_at' | 'accepted_date' | 'rejected_date'>
{
  sender_email: string;
  sender_user_id: string;
  recipient_email: string;
  recipient_user_id: string;
  type: ECoreUserInvitationType;
}

export class ICoreUserInvitationParamsUpdate implements Omit<Partial<ICoreUserInvitation>, 'created_at' | 'updated_at'> {
  _id: string;
  sender_email?: string;
  sender_user_id?: string;
  recipient_email?: string;
  recipient_user_id?: string;

  accepted_date?: Date;
  rejected_date?: Date;

  status?: ECoreUserInvitationStatus;
  type?: ECoreUserInvitationType;

  account_id?: string;
}

export class ICoreUserInvitationParamsDelete {
  _id: string;
}
