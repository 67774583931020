import { Directive, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  CORE_ACTIVITY_HISTORY_EVENT_TYPES,
  ICoreActivityHistoryWebhook,
  ICoreActivityHistoryWebhookParamsCreate,
  ICoreActivityHistoryWebhookParamsUpdate,
  ICoreUserAccount,
  ITagifyTag,
} from '@launchpoint/core-types';
import { createWebhook, updateWebhook } from '../+state/actions/webhooks.actions';
import { LaunchpointCoreClientWebhooksStateBaseComponent } from '../+state/webhooks-state-base.component';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TagifySettings } from 'ngx-tagify';
import { ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN } from '../../activity-history/interface/interface';
import { UserSelectors } from '../../../user/auth/+state';

@Directive()
export abstract class LaunchpointCoreClientWebhookModalBaseComponent extends LaunchpointCoreClientWebhooksStateBaseComponent implements OnInit {
  fb = inject(UntypedFormBuilder);
  _config = inject(ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN);

  form_control = new FormControl<string>('');

  @Input() webhook: ICoreActivityHistoryWebhook;

  account: ICoreUserAccount;

  public form: FormGroup<{
    url: FormControl<string>;
    events: FormControl<ITagifyTag[]>;
    mode: FormControl<string>;
    enabled: FormControl<boolean>;
    description: FormControl<string>;
  }>;

  public settings: TagifySettings = {
    placeholder: 'Search...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: 'tagify__inline__suggestions', // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistEvents$ = new BehaviorSubject<string[]>(null);

  ngOnInit(): void {
    this.buildForm();
    this.whitelistEvents$ = new BehaviorSubject<string[]>(Object.values(this._config?.events ?? CORE_ACTIVITY_HISTORY_EVENT_TYPES));

    this._Store
      .select(UserSelectors.selectSelectedUserAccount)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (account) => {
          if (account) {
            this.account = account;
          }
        },
      });
  }

  buildForm() {
    this.form = this.fb.group({
      events: [],
      enabled: [false],
      description: [],
      mode: ['test', Validators.required],
      url: [
        '',
        [
          //
          Validators.required,
          // https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
          Validators.pattern(
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
          ),
        ],
      ],
    });

    if (this.webhook) {
      const { events, ...rest } = this.webhook;
      this.form.patchValue(rest);
    }
  }

  submit() {
    if (!this.form.valid) {
      console.log('Form is invalid');
      return;
    }

    const form = this.form.value;

    if (this.webhook) {
      const data: ICoreActivityHistoryWebhookParamsUpdate = {
        _id: this.webhook._id,
        enabled: form.enabled,
        events: form.events?.map((event) => event?.value) ?? [],
        url: form.url,
        description: form.description,
      };

      this._Store.dispatch(updateWebhook({ data }));
    } else {
      const data: ICoreActivityHistoryWebhookParamsCreate = {
        account_id: this.account._id,
        enabled: form.enabled,
        events: (form.events?.map((event) => event?.value) ?? []) as any,
        url: form.url,
        mode: form.mode as any,
        description: form.description,
      };

      this._Store.dispatch(createWebhook({ data }));
    }

    this.close();
  }

  close() {
    //override me
  }
}
