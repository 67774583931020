import { Directive, Inject, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { loggedInUserUpdateProfile } from '../../../../auth/+state/actions/user/user.actions';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../../../../auth/+state/auth-base.component';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../interfaces/core-profile.interface';

@Directive()
export class LaunchpointCoreClientProfileDetailsSmartUserBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent {
  @Input() optional_user_id: string;
  @Input() showSeparators = true;

  constructor(@Inject(PROFILE_CONFIG_TOKEN) public _profileConfig: LaunchpointCoreClientProfileConfig) {
    super();
  }
  async profileChanges(event) {
    const user = await firstValueFrom(this.user$);
    const user_being_edited_id = this.optional_user_id ? this.optional_user_id : user._id;
    if (user) {
      this._Store.dispatch(loggedInUserUpdateProfile({ data: { ...event, user_id: user_being_edited_id } }));
    }
  }
}
