import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscore',
})
export class UnderscorePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    const words = value.split('_');
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(' ');
  }
}
