<ng-container *ngIf="selected_user$ | async">
  <launchpoint-core-web-accounts-table
    [user_id]="(selected_user$ | async).user._id"
    [users_accounts]="(selected_user$ | async).user.accounts"
    [isAdmin]="true"
    [loading]="selected_user_loading$ | async"
    [pagination]="pagination$ | async"
    (paginate)="pageChange($event)"
    (queryUsers)="runQuery($event)"
    [querySort]="querySort$ | async"
    [query]="query$ | async"
    [error]="error$ | async"
    (emitRemoveAccount)="removeAccount($event)"
    (emitResendAccountInvite)="resendAccountInvite($event)"
    (emitAcceptAccountInvitation)="acceptAccountInvite($event)"
    (emitChangeAccountRoles)="changeAccountRoles($event)"
  >
  </launchpoint-core-web-accounts-table>
</ng-container>
