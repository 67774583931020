<div>
  <!-- HERO -->
  <div class="bg-background relative flex justify-center py-24">
    <div class="w-[1200px] max-w-full px-6 text-white">
      <div class="my-20 grid grid-cols-1 gap-5 md:my-20 md:grid-cols-2 md:gap-20">
        <div class="font-oswald text-5xl uppercase text-white">the community</div>

        <div class="text-sm">
          Not just another network—we’re your advantage to out-study, outwork, and outlast. Designed for leaders who play to win, this community
          grants you exclusive access to real strategies, relentless support, and a network of top achievers driven by results.
        </div>

        <div class="flex flex-col items-start justify-between">
          @for (item of items; track item) {
            <div class="my-4">
              <div class="font-oswald text-2xl uppercase">{{ item.title }}</div>
              <div class="text-sm">{{ item.description }}</div>
            </div>
          }
        </div>

        <div class="flex items-center justify-center">
          <img src="assets/img/community.png" alt="Community" />
        </div>
      </div>
    </div>
  </div>

  <!-- NEWSLETTER -->
  <sd-newsletter></sd-newsletter>

  <!-- WHAT THEY SAY -->
  <sd-success-stories />
</div>
