<div class="menu-item px-3">
  <div class="menu-content fs-6 text-dark fw-bolder px-3 py-4">
    Quick Actions
  </div>
</div>

<div class="separator mb-3 opacity-75"></div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer"> New Ticket </a>
</div>
<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer"> New Customer </a>
</div>

<div
  class="menu-item px-3"
  data-kt-menu-trigger="hover"
  data-kt-menu-placement="right-start"
  data-kt-menu-flip="left-start, top"
>
  <a class="menu-link px-3 cursor-pointer">
    <span class="menu-title">New Group</span>
    <span class="menu-arrow"></span>
  </a>

  <div class="menu-sub menu-sub-dropdown w-175px py-4">
    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Admin Group </a>
    </div>

    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Staff Group </a>
    </div>

    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Member Group </a>
    </div>
  </div>
</div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer"> New Contact </a>
</div>

<div class="separator mt-3 opacity-75"></div>

<div class="menu-item px-3">
  <div class="menu-content px-3 py-3">
    <a class="btn btn-primary btn-sm px-4 cursor-pointer"> Generate Reports </a>
  </div>
</div>
