import { Directive } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LaunchpointCoreClientBaseComponent } from '../../../components/base/base.component';
import { CompanyStateBaseComponent } from '../+state/company-state.base-component';

@Directive()
export abstract class CompanyProfileBaseComponent extends CompanyStateBaseComponent {
  // form: UntypedFormGroup;
  // id: string;
  // constructor(public fb: UntypedFormBuilder, public _Store: Store, public _route: ActivatedRoute) {
  //   super();
  // }
}
