<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">
    <div class="app-container d-flex flex-stack my-6 container-xxl">
      <!--begin::Page title-->
      <div class="page-title d-flex justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Accounts</h1>
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1 mx-4">
          <i class="bi bi-search fs-3 position-absolute ms-4"><span class="path1"></span><span class="path2"></span></i>
          <input type="text" class="form-control form-control-sm border-body bg-body w-250px ps-10"
            [formControl]="form_control" placeholder="Search Accounts" />

          <ng-container class="px-10" *ngIf="loading === true">
            <span class="indicator-label px-10" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
        </div>
        <!--end::Search-->
        <!--end::Title-->
      </div>

      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3">
        <button class="btn btn-sm fw-bold btn-primary" (click)="addAccount()">Add Account</button>
        <!-- <button class="btn btn-icon btn-info"><i class="fas fa-filter"></i></button> -->

        <!-- <div ngbDropdown class="d-inline-block">
          <button class="btn btn-sm btn-icon btn-color btn-active-light-primary" id="dropdownMenu1" ngbDropdownToggle>
            <i class="bi bi-three-dots-vertical"></i>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownMenu1">
            <button class="dropdown-item" (click)="sync()">Sync</button>
          </div>
        </div> -->
      </div>

      <!--end::Actions-->
    </div>

    <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
      <div class="d-flex flex-column flex-column-fluid container-xxl">
        <div *ngIf="users_accounts.length >= 1; else noAccountsMessage" class="card card-flush">
          <div class="card-body">
            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="table-responsive">
                <table class="table align-middle table-row-dashed table-hover gy-5 dataTable no-footer">
                  <thead>
                    <tr class="fw-bold text-gray-700 text-uppercase fs-7">
                      <th class="min-w-100px px-10">User/Company</th>
                      <th class=" min-w-100px">Account Type</th>
                      <th class=" min-w-70px">Status</th>
                      <th class="min-w-100px text-center">Invited</th>
                      <th class=" min-w-100px">Security Roles</th>
                      <th class=" min-w-100px text-end">Actions</th>
                    </tr>
                  </thead>

                  <tbody class="fs-6 fw-semibold">

                    <tr *ngIf="users_accounts?.length === 0">
                      <td>No User Accounts</td>
                    </tr>

                    <tr *ngFor="let account of users_accounts" [routerLink]="['../../' + account?.user_id?._id]"
                      routerLinkActive="router-link-active">
                      <ng-container *ngIf="account">
                        <td>
                          <a class="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                            <!-- <span>{{acount}}</span> -->
                            <span>
                              <ng-container *ngIf="account.user_id?.profile?.full_name; else showUsername">
                                {{ account.user_id.profile.full_name }}
                              </ng-container>
                              <ng-template #showUsername>
                                {{ account?.name }}
                              </ng-template>
                            </span>
                          </a>
                        </td>

                        <td>
                          {{ account?.account_type }}
                        </td>

                        <td>

                          {{ account?.status }}

                        </td>

                        <td>
                          {{ account?.invited }}
                        </td>

                        <td>
                          <div class="d-flex align-items-center justify-content-between mx-25 px-5">
                            <span *ngFor="let role of account.security_roles" class="badge badge-light">
                              {{ role | removeUnderscore | titlecase}}
                            </span>
                          </div>
                        </td>

                        <td>
                          <div class="d-flex align-items-center justify-content-end mx-25 px-5">
                            <ng-container *ngIf="isAdmin; else showUserActions">
                              <button class="btn btn-icon btn-icon-danger" (click)="removeAccountV1(account)"><i
                                  class="fas fa-trash"></i></button>
                              <ng-container *ngIf="account.status === ENUMCoreUserAccountStatus.PENDING_INVITATION">
                                <button class="btn btn-icon btn-icon-info" (click)="resendInvite(account._id)">
                                  <i class="fas fa-envelope"></i>
                                </button>
                                <button class="btn btn-icon btn-icon-success"
                                  (click)="immediatelyAddAccount(account._id)">
                                  <i class="fas fa-plus"></i>
                                </button>
                              </ng-container>
                              <button class="btn btn-icon btn-icon-success"
                                (click)="changeAccountSecurityRoles(account)">
                                <i class="fas fa-lock"></i>
                              </button>
                            </ng-container>
                            <ng-template #showUserActions>
                              <!-- <button class="btn btn-icon btn-icon-danger" (click)="removeAccountV1(account)"><i class="fas fa-trash"></i></button>
                                <ng-container *ngIf="account.status === ENUMCoreUserAccountStatus.PENDING_INVITATION">
                                  <button class="btn btn-icon btn-icon-info" (click)="resendInvite(account._id)"><i class="fas fa-envelope"></i></button>
                                  <button class="btn btn-icon btn-icon-success" (click)="immediatelyAddAccount(account._id)">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </ng-container>
                                <button class="btn btn-icon btn-icon-success" (click)="changeAccountSecurityRoles(account)">
                                  <i class="fas fa-lock"></i>
                                </button> -->
                            </ng-template>
                          </div>
                        </td>

                      </ng-container>
                    </tr>
                  </tbody>


                </table>
              </div>
              <!---: Pagination Begins -->
              <!-- <div class="row" *ngIf="pagination$ | async as pagination">
                  <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    <div>
                      <label
                        ><select class="form-select form-select-sm form-select-solid" [ngModel]="pagination.limit" (ngModelChange)="limit($event)">
                          <option [value]="10">10</option>
                          <option [value]="25">25</option>
                          <option [value]="50">50</option>
                          <option [value]="100">100</option>
                        </select></label
                      >
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <ngb-pagination
                      [collectionSize]="pagination.count"
                      [page]="pagination.pageIndex + 1"
                      [pageSize]="pagination.limit"
                      (pageChange)="paginate($event)"
                      [maxSize]="5"
                      [rotate]="true"
                      [ellipses]="false"
                      [boundaryLinks]="true"
                    ></ngb-pagination>
                  </div>
                </div> -->
              <!---: Pagination Ends -->
            </div>
          </div>
        </div>
        <ng-template #noAccountsMessage>
          <div class="card card-flush">
            <div class="card-body">
              <span class="fw-semibold fs-6">No accounts for this user currently</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>