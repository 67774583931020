import { IHttpException, ICoreBlog } from '@launchpoint/core-types';

export interface BlogsEntityState {
  blog_id: string;
  blog: ICoreBlog;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string;
}
