import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationFeatureKey, NotificationsEntityAdaptor, NotificationsState } from '../reducers/notifications.reducer';

const selectAllNotificationsState = createFeatureSelector<NotificationsState>(NotificationFeatureKey);

/**
 * ROOT STATE
 */
export const getAllNotificationsState = createSelector(selectAllNotificationsState, (state: NotificationsState) => state);

export const selectNotificationsPagination = createSelector(getAllNotificationsState, (state: NotificationsState) => state.pagination);
export const selectNotificationsQuerySort = createSelector(getAllNotificationsState, (state: NotificationsState) => state.querySort);
export const selectNotificationsQuery = createSelector(getAllNotificationsState, (state: NotificationsState) => state.query);
export const selectNotificationsSuccess = createSelector(getAllNotificationsState, (state: NotificationsState) => state.success_message);
export const selectNotificationsLoading = createSelector(getAllNotificationsState, (state: NotificationsState) => state.loading);
export const selectNotificationsLoaded = createSelector(getAllNotificationsState, (state: NotificationsState) => state.loaded);
export const selectNotificationsError = createSelector(getAllNotificationsState, (state: NotificationsState) => state.error);

export const getAllNotificationssStateEntities = createSelector(selectAllNotificationsState, (state: NotificationsState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = NotificationsEntityAdaptor.getSelectors(selectAllNotificationsState);

// select the array of Notifications ids
export const selectNotificationsIds = selectIds;

// select the dictionary of Notifications entities
export const selectNotificationsEntities = selectEntities;

// select the array of Notificationss
export const selectNotifications = selectAll;

// select the total entity count
export const selectNotificationsTotal = selectTotal;

export const selectNotificationsId = createSelector(getAllNotificationsState, (state: NotificationsState) => state?.selected_notification_id);

export const selectSelectedNotifications = createSelector(
  getAllNotificationsState,
  selectNotificationsId,
  (state: NotificationsState, selected_notification_id) => state.entities[selected_notification_id]
);
