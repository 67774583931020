<div class="dt-bootstrap4 no-footer">
  <div class="table-responsive">
    <table class="table-row-dashed table-hover fs-6 gy-5 dataTable no-footer table align-middle">
      <ng-container *ngIf="(loading$ | async) === true">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>
      <thead>
        <tr class="fw-bold fs-7 text-uppercase gs-0 text-start text-gray-700">
          <th class="min-w-50px">Type</th>
          <th class="min-w-10px">Name</th>
          <th class="min-w-100px">Category</th>
          <th class="min-w-100px">Attachments</th>
          <th class="min-w-30px">Tags</th>
          <th class="min-w-50px">Size</th>
          <th class="max-w-50px text-center">References</th>
          <th class="max-w-50px text-center">Active</th>
          <th class="max-w-75px">Created</th>
          <th class="min-w-50px text-end">Actions</th>
        </tr>
      </thead>

      <tbody class="fw-semibold fs-6">
        <tr *ngIf="(loading$ | async) === false && !(mediaEntities$ | async)?.length">
          <td>No Media</td>
        </tr>

        <tr *ngFor="let entity of mediaEntities$ | async" [routerLink]="'./' + entity?.media_id" routerLinkActive="router-link-active">
          <td class="text-center">
            <i class="fs-2" [Mimetype]="entity?.media?.meta_data?.mimetype" [ngbTooltip]="entity?.media?.meta_data?.mimetype"></i>
          </td>

          <td>
            <a [routerLink]="'./' + entity?.media_id" routerLinkActive="router-link-active" class="text-dark text-hover-primary">
              {{ entity?.media?.title }}
            </a>
            <div class="fs-7 text-muted">
              {{ entity?.media?.description }}
            </div>
          </td>

          <td>
            {{ entity?.media?.category?.title }}
          </td>

          <td>
            @if (entity?.media?.poster) {
              <i class="fs-4 fas fa-image me-3" ngbTooltip="Poster"></i>
            }
            @if (entity?.media?.subtitles?.length) {
              <i class="fs-4 fas fa-closed-captioning me-3" ngbTooltip="Subtitles"></i>
            }
            @if (entity?.media?.audiotracks?.length) {
              <i class="fs-4 fas fa-headphones" ngbTooltip="Audio Tracks"></i>
            }
          </td>

          <td>
            <div class="d-flex align-items-center flex-wrap">
              <span *ngFor="let tag of entity?.media?.tags" class="badge badge-light-primary me-2">
                {{ tag }}
              </span>
            </div>
          </td>

          <td>
            {{ formatFileSize(entity?.media?.meta_data?.size) }}
          </td>

          <td class="text-center">
            {{ entity?.media?.usage?.length ?? 0 }}
          </td>

          <td class="text-center">
            <i
              class="bi fs-4"
              [ngClass]="{
                'bi-check-circle text-success': !entity?.media?.is_archived,
                'bi-x-circle text-danger': entity?.media?.is_archived,
              }"
            ></i>
          </td>

          <td>
            {{ entity?.media?.created_at | date }}
          </td>

          <td class="text-end">
            <launchpoint-ellipsis-menu [options]="options(entity?.media)"></launchpoint-ellipsis-menu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />
</div>
