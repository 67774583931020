import { Observable } from 'rxjs';
import {
  IUserNotification,
  IUserNotificationBase,
  IUserNotificationParamsCreate,
  IUserNotificationParamsUpdate,
  IUserNotificationSearchPayload,
  IUserNotificationSearchResults,
} from './user-notifications.interface';

/**
 * Controller('notifications/user-notifications')
 */
export interface IUserNotificationController {
  /**
   * Get(':id')
   * @param id
   */
  getById(id: string): Promise<IUserNotification> | Observable<IUserNotification>;
  /**
   * Get('unread/:user_id')
   * @param user_id
   */
  getUserUnread(user_id: string): Promise<number> | Observable<number>;
  /**
   * Post('find-one')
   * @param params Partial<IUserNotification>
   */
  findOne(params: Partial<IUserNotification>): Promise<IUserNotification> | Observable<IUserNotification>;
  /**
   * Post('search')
   * @param body
   */
  search(body: IUserNotificationSearchPayload): Promise<IUserNotificationSearchResults> | Observable<IUserNotificationSearchResults>;
  /**
   * Post()
   * @param body
   */
  create(body: IUserNotificationParamsCreate): Promise<IUserNotification> | Observable<IUserNotification>;
  /**
   * Put()
   * @param body
   */
  update(body: IUserNotificationParamsUpdate): Promise<IUserNotification> | Observable<IUserNotification>;
  /**
   * Delete(':id')
   * @param id
   */
  delete(id: string): Promise<IUserNotificationBase> | Observable<IUserNotificationBase>;
}
