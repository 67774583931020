<div class="d-flex justify-content-between flex-column flex-lg-row-fluid">
  <div class="card shadow min-h-500px w-lg-500px w-md-400px w-300px p-15 p-md-20 p-lg-20">
    <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form" (ngSubmit)="submit()">

      <div class="text-center mb-10">
        <h1 class="fw-bold mb-3">{{ _config?.registration?.title || 'Sign Up' }}</h1>

        <div class="text-muted fw-semibold fs-6" *ngIf="_config?.registration?.description">{{
          _config?.registration?.description }}</div>
      </div>


      <ng-container *ngIf="error$ | async as error">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">{{ error?.error.message }}</div>
        </div>
      </ng-container>

      <launchpoint-alert-banner-message-standalone [private]="false" cssClass="mb-15" />

      <div class="fv-row mb-8 fv-plugins-icon-container">
        <div class="form-floating mb-4">
          <input type="text" class="form-control" formControlName="email" />
          <span class="position-absolute translate-middle top-50 end-0 me-2" *ngIf="form.controls.email.valid">
            <i class="fs-3 bi bi-check-circle-fill text-primary"></i>
          </span>
          <label for="floatingInput">{{ _config?.registration?.input_label || 'Email' }}</label>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'required',
          message: (_config?.registration?.input_label | titlecase) + ' is required',
          control: form.controls.email
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'minlength',
          message: (_config?.registration?.input_label | titlecase) + ' should have at least 3 symbols',
          control: form.controls.email
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'maxlength',
          message: (_config?.registration?.input_label | titlecase) + ' should have maximum 360 symbols',
          control: form.controls.email
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
          validation: 'pattern',
          message: 'Please enter a valid ' + (_config?.registration?.input_label | lowercase),
          control: form.controls.email
        }"></ng-container>
      </div>

      <div class="fv-row mb-8 fv-plugins-icon-container register-check"
        *ngIf="_config.registration.primary_agreement_href ">
        <input type="checkbox" name="agreement" id="agreement" formControlName="agreement" style="margin-right: 8px;" />
        <span>
          {{_config.registration.prefix_agreement_text}}
          <a [href]="_config.registration.primary_agreement_href"
            target="_blank">{{_config.registration.primary_language}}</a>
          <span *ngIf="_config.registration.transition_text_1"> {{_config.registration.transition_text_1}}</span>
          <a [href]="_config.registration.secondary_agreement_href"
            target="_blank">{{_config.registration.secondary_language}}</a>

          <span *ngIf="_config.registration.transition_text_2"> {{_config.registration.transition_text_2}}</span>
          <a [href]="_config.registration.terciary_agreement_href"
            target="_blank">{{_config.registration.terciary_language}}</a>
        </span>
      </div>


      <div class="d-grid mb-10">
        <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-primary w-100 mb-5">
          <ng-container *ngIf="(loading$ | async) === false">
            <span class="indicator-label text-white">{{ _config?.registration?.button ?? 'Sign Up'}}</span>
          </ng-container>
          <ng-container *ngIf="loading$ | async">
            <span class="indicator-label" [style.display]="'block'">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </ng-container>
        </button>
      </div>
    </form>
  </div>


  <div class="text-muted text-center fw-semibold fs-6 my-20" [animateCss]="'fadeInDown'">
    Already have an account?
    <a routerLink="/auth/login" class="text-black text-hover-primary fw-semibold text-decoration-underline ">
      Sign In
    </a>
  </div>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>