import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';

import {
  configureURL,
  ELaunchpointDynamicForm,
  ELPDefaultActions,
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormComputedFieldController,
  ILaunchpointDynamicFormComputedFieldParamsCreate,
  ILaunchpointDynamicFormComputedFieldParamsDelete,
  ILaunchpointDynamicFormComputedFieldParamsReorder,
  ILaunchpointDynamicFormComputedFieldParamsUpdate,
} from '@launchpoint/core-types';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityAccountIdHeaderServiceV2 } from '../../../security/header-services/account-id-security-header-v2.service';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../../../user/auth/interfaces/auth-storage-service.interface';

@Injectable() //ILaunchpointDynamicFormGroupQuestionController
export class LaunchpointDynamicFormComputedFieldService
  extends LaunchpointSecurityAccountIdHeaderServiceV2
  implements ILaunchpointDynamicFormComputedFieldController
{
  http = inject(HttpClient);
  API_URL = '/api/backend/';

  constructor(@Inject(APP_AUTH_CONFIG_TOKEN) public _CoreAuthConfig: ICoreAuthConfig) {
    super();

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _CoreAuthConfig.auth?.base_url,
      version: 1,
      route: ELaunchpointDynamicForm.COMPUTED_FIELDS,
    });
  }

  add(params: ILaunchpointDynamicFormComputedFieldParamsCreate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }
  update(params: ILaunchpointDynamicFormComputedFieldParamsUpdate): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
      })
    );
  }
  reorder(params: ILaunchpointDynamicFormComputedFieldParamsReorder): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.REORDER, params, { headers });
      })
    );
  }
  remove(params: ILaunchpointDynamicFormComputedFieldParamsDelete): Observable<ILaunchpointDynamicForm> {
    return this.getSelectedAccountIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ILaunchpointDynamicForm>(this.API_URL + '/' + ELPDefaultActions.DELETE, params, { headers });
      })
    );
  }

  // public add(params: ILaunchpointDynamicFormGroupParamsCreate): Observable<ILaunchpointDynamicForm> {
  // return this.getSelectedAccountIdHeaders.pipe(
  //   switchMap((headers) => {
  //     return this.http.post<ILaunchpointDynamicForm>(this.API_URL, params, { headers });
  //   })
  // );
  // }
}
