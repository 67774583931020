<div ngbDropdown [class]="cssClass" #myDrop="ngbDropdown" autoClose="outside">
  <button class="btn btn-sm btn-icon btn-color text-hover-primary" id="ellipsisMenuDropdown" [disabled]="disabled"
    ngbDropdownToggle [ngbTooltip]="tooltip" (click)="$event.stopPropagation()" *ngIf="!label">
    <i class="bi bi-three-dots-vertical fs-5"></i>
  </button>

  <a class="menu-link px-3" ngbDropdownToggle *ngIf="label">
    {{ label }}
  </a>

  <div ngbDropdownMenu aria-labelledby="ellipsisMenuDropdown"
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3">
    <div class="menu-item px-3">
      <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
        Options
      </div>
    </div>

    <div *ngFor="let option of options" class="menu-item px-3">
      <div *ngIf="option?.ngIf ?? true && !option?.subOptions?.length">
        <a class="d-flex align-items-center justify-content-between menu-link px-3" (click)="callAction(option, $event)"
          [ngClass]="{
            'text-dark text-hover-primary': !option?.disabled,
            'text-muted': option?.disabled,
            'cursor-not-allowed': option?.disabled
            }" [ngbTooltip]="option?.tooltip">
          {{ option?.label }}
          <i *ngIf="option?.iconClass" [class]="'fs-4'+ option?.iconClass"></i>
        </a>
      </div>

      <div ngbDropdown class="d-inline-block w-100" *ngIf="option?.ngIf ?? true && option?.subOptions?.length > 0">

        <launchpoint-ellipsis-menu [options]="option?.subOptions" [tooltip]="option?.tooltip" [cssClass]="'w-80'"
          [label]="option?.label" />
      </div>
    </div>
  </div>