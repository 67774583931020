<div class="card mb-xl-10 mb-5">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Tags</h3>
    </div>
  </div>

  <div class="card-body border-top p-9">
    <launchpoint-blog-tags-tagify [tags]="tags$()" (selectedTags)="setTags($event)" />
  </div>

  <div class="card-footer d-flex justify-content-end px-9 py-6" let some="false">
    <button class="btn btn-secondary me-3" (click)="resetTags()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="(loading$ | async) || (blogLoading$ | async)" (click)="saveTags()">
      <ng-container *ngIf="((loading$ | async) || (blogLoading$ | async)) === false">Save</ng-container>

      <ng-container *ngIf="(loading$ | async) || (blogLoading$ | async)">
        <span class="indicator-label" [style.display]="'block'" loading="true">
          Please wait...
          <span class="spinner-border spinner-border-sm ms-2 align-middle"></span>
        </span>
      </ng-container>
    </button>
  </div>
</div>
