import { Injectable } from '@angular/core';
import { LaunchpointCoreClientProfileConfig } from '@launchpoint/core-client';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LaunchpointCoreWebProfileService {
  private configBS = new BehaviorSubject<LaunchpointCoreClientProfileConfig | null>(null);
  public _config$ = this.configBS.asObservable();
  // constructor() {}

  public next(config: LaunchpointCoreClientProfileConfig) {
    this.configBS.next(config);
  }

}
