import { ADMChannelRequest, UpdateAdmChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointADMChannelParamsUpdate implements UpdateAdmChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.ADM;
  ADMChannelRequest: IADMChannelRequest;
}

class IADMChannelRequest implements ADMChannelRequest {
  ClientId: string | undefined;
  ClientSecret: string | undefined;
  Enabled?: boolean | undefined;
}
