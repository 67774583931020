import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ILaunchpointJobs } from '../../redis/bullmq-job.interface';
import { ENotificationChannelType } from '../notifications/notifications.constants';
import { INotification, INotificationChannel } from '../notifications/notifications.interface';
import { ENotificationMessageType } from './notifications-message.constants';

export class INotificationMessageBase {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
  title?: string;
  description?: string;
  user_count?: number;

  /**
   * Discriminator
   */
  job_type: ENotificationMessageType;
  data?: Record<string, any>;

  job?: ILaunchpointJobs;
  jobs?: ILaunchpointJobs[];
}

export class INotificationMessageTypeNotification {
  job_type: ENotificationMessageType.NOTIFICATION;
  notification: string | INotification;
  event: string;
  user_conditions?: Record<string, any>;
  account_conditions?: Record<string, any>;
  users?: string[];
  accounts?: string[];
}

export class INotificationMessageTypeMessage {
  job_type: ENotificationMessageType.MESSAGE;
  channels: INotificationChannel[];
  user_conditions?: Record<string, any>;
  account_conditions?: Record<string, any>;
  users?: string[];
  accounts?: string[];
}
export type INotificationMessageTypeMessageParamsCreate = Pick<INotificationMessageBase, 'job_type' | 'data' | 'title' | 'description'> &
  Omit<INotificationMessageTypeNotification, 'type' | 'notification'> & { notification: string };

export class INotificationMessageTopic {
  job_type: ENotificationMessageType.TOPIC;
  channels: INotificationChannel[];
}

export type INotificationMessage = INotificationMessageBase &
  (INotificationMessageTypeNotification | INotificationMessageTypeMessage | INotificationMessageTopic);

export class INotificationMessageParamsCreate extends INotificationMessageBase implements INotificationMessageTypeMessage {
  job_type: ENotificationMessageType.MESSAGE;
  channels: INotificationChannel[];
  title?: string;
  description?: string;
  // data: Record<string, any>;
}

export class INotificationMessageParamsUpdate extends INotificationMessageParamsCreate {
  _id: string;
  job_type: ENotificationMessageType.MESSAGE;

  // job: ILaunchpointJobs;
  // jobs: ILaunchpointJobs[];
}

export class INotificationMessageSearchQuery {
  search?: string;
  job_types?: ENotificationMessageType[];
  channels?: ENotificationChannelType[];
  users?: string[];
  accounts?: string[];
  created_at?: ILaunchpointSearchFilterDates;
}

export class INotificationMessageSearchPayload {
  query: INotificationMessageSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class INotificationMessageSearchResults {
  data: INotificationMessage[];
  pagination: IQueryPageination;
}
