import { EmailChannelRequest, UpdateEmailChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointEmailChannelParamsUpdate implements UpdateEmailChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.EMAIL;
  EmailChannelRequest: IEmailChannelRequest;
}

class IEmailChannelRequest implements EmailChannelRequest {
  Enabled?: boolean;
  ConfigurationSet?: string | undefined;
  FromAddress: string | undefined;
  Identity: string | undefined;
  RoleArn?: string | undefined;
}
