import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ECoreUserControlType, ICoreUserSearchQuery, IHttpException, ITagifyTag, IUserView } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { updateAllUsersQuery } from '../../../+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../+state/users-state-base.component';
import { FormControlDateRange } from '../../../../../forms/from-control/form-control-convert.interface';

@Directive()
export abstract class UsersTableFilterBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent implements OnInit {
  @Input() error: IHttpException;
  @Input() loading = false;
  @Input() options = {
    actions: true,
  };
  view: IUserView;
  form_control = new FormControl<string>('');
  isCollapsed = false;
  selectedViewTitle: string;

  form: FormGroup<{
    security_role: FormControl<ITagifyTag[]>;
    control_types: FormControl<ITagifyTag<ECoreUserControlType, ECoreUserControlType>[]>;
    created_at: FormControlDateRange;
    last_login: FormControlDateRange;
  }>;
  @Input() currentView: string;

  @Output() queryUsers = new EventEmitter<string>();
  @Output() queryView = new EventEmitter<string>();
  @Output() query = new EventEmitter<ICoreUserSearchQuery>();

  constructor(
    public _Store: Store,
    public fb: UntypedFormBuilder
  ) {
    super(_Store);
  }

  ngOnInit() {
    this.form_control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200), distinctUntilChanged()).subscribe({
      next: (search) => this.runQuery(search),
      error: (error) => console.log('ERROR', error),
    });
    this.form = this.fb.group({
      security_role: [],
      control_types: [],
      created_at: [],
      last_login: [],
    });
    this.query$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (query) => this.buildForm(query),
    });
  }

  onSelectedViewTitle(title: string) {
    this.selectedViewTitle = title;
  }

  buildForm(query: ICoreUserSearchQuery) {
    if (query) {
      const formValue = {
        security_role: query.security_role, //.map((f) => ({ value: f, data: f })),
        control_types: query?.control_types?.map((f) => ({ value: f, data: f })) ?? [],
        created_at: query.created_at,
        last_login: query.last_login,
      };
      setTimeout(() => {
        this.form.patchValue(formValue);
      }, 100);
    }
  }

  apply() {
    const value = this.form.value;
    const query: ICoreUserSearchQuery = {
      security_role: value.security_role,
      control_types: value.control_types.map((f) => f.value),
      created_at: value.created_at,
      last_login: value.last_login,
    };
    this._Store.dispatch(updateAllUsersQuery({ query: query }));
  }

  runQuery(search: string) {
    this.queryUsers.emit(search);
  }

  setView(view: IUserView) {
    this.view = view;
  }
}
