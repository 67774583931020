<div class="d-flex justify-content-between align-items-center flex-column flex-lg-row-fluid"
  [style]="confirmEmail && _config.registration.active ? 'padding-top: 25%' : 'padding-top: 30%'">
  <div class="w-lg-500px p-10">
    <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" [formGroup]="form">
      <div class="text-center mb-11">
        <h1 class="text-dark fw-bolder mb-3">{{ _config?.login?.title || 'Sign In' }}</h1>
      </div>

      <ng-container *ngIf="error$ | async as error">
        <div class="mb-lg-15 alert alert-danger">
          <div class="alert-text font-weight-bold">{{ error?.error.message }}</div>
        </div>
      </ng-container>

      <launchpoint-alert-banner-message-standalone [private]="false" cssClass="mb-15" />

      <!-- <ng-container *ngIf="success$ | async as success">
        <div class="mb-lg-15 alert alert-success">
          <div class="alert-text font-weight-bold">{{ success?.message }}</div>
        </div>
      </ng-container> -->

      <div class="row g-3 mb-9" *ngIf="_config.third_party?.google?.active || _config.third_party?.apple?.active">
        <div class="col-md-6" *ngIf="_config.third_party?.google?.active">
          <a [href]="_config.third_party?.google?.href"
            class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/google-icon.svg"
              class="h-15px me-3" />Sign in with Google</a>
        </div>
        <div class="col-md-6" *ngIf="_config.third_party?.apple?.active">
          <a [href]="_config.third_party?.apple?.href"
            class="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/apple-black.svg"
              class="theme-light-show h-15px me-3" />
            <img alt="Logo" src="/metronic8/demo1/assets/media/svg/brand-logos/apple-black-dark.svg"
              class="theme-dark-show h-15px me-3" />
            Sign in with Apple
          </a>
        </div>
      </div>

      <div class="fv-row mb-8 fv-plugins-icon-container">
        <div class="form-floating mb-4">
          <input class="form-control" type="text" formControlName="email" [ngClass]="{
              'is-valid': form.controls.email.valid
            }" />
          <label for="floatingInput">{{ _config?.registration?.input_label || 'Email' }}</label>
        </div>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Email is required',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'pattern',
            message: 'Email is invalid',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'minlength',
            message: 'Email should have at least 3 symbols',
            control: form.controls.email
          }"></ng-container>
        <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
            validation: 'maxlength',
            message: 'Email should have maximum 360 symbols',
            control: form.controls.email
          }"></ng-container>
      </div>

      <div class="d-grid mb-10" *ngIf="!confirmEmail">
        <button type="submit" (click)="confirm()" [disabled]="form.controls.email.invalid" class="btn btn-primary">
          <span class="indicator-label">Submit</span>
        </button>
      </div>

      <ng-container *ngIf="confirmEmail">
        <div class="fv-row mb-3 fv-plugins-icon-container" [animateCss]="'fadeInDown'">
          <div class="form-floating mb-4">
            <input #password [type]="pVisible ? 'text' : 'password'" placeholder="Password" formControlName="password"
              class="form-control" />
            <span class="text-hover-primary position-absolute translate-middle top-50 end-0 me-2"
              (click)="togglePass()">
              <i class="fs-5 bi" [ngClass]="{ 'bi-eye-slash': !pVisible, 'bi-eye': pVisible }"></i>
            </span>
            <label for="floatingInput">Password</label>
          </div>
          <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
              validation: 'required',
              message: 'Password is required',
              control: form.controls.password
            }"></ng-container>
        </div>

        <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold my-8" [animateCss]="'fadeInDown'">
          <div></div>
          <a (click)="sendLoginCode(true)" class="text-gray-600 cursor-pointer">Forgot Password?</a>
        </div>

        <div class="d-grid mb-10" [animateCss]="'fadeInDown'">
          <button type="submit" (click)="usernamePassword()" class="btn btn-primary">
            <ng-container *ngIf="(loading$ | async) === false">
              <span class="indicator-label">Sign In</span>
            </ng-container>
            <ng-container *ngIf="loading$ | async">
              <span class="indicator-label" [style.display]="'block'">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>

        <div class="separator separator-content my-14" [animateCss]="'fadeInDown'">
          <span class="min-w-250px text-gray-800 fw-semibold"><ng-container>Or</ng-container>
            <a (click)="sendLoginCode()" class="link-primary cursor-pointer fw-semibold ms-2">
              {{ _config?.login?.code_text ?? 'Log In with One Time Code' }}
            </a>
          </span>
        </div>
      </ng-container>
    </form>
  </div>

  <ng-container *ngIf="_config.registration.active">
    <div class=" my-20 text-gray-600 text-center fw-semibold fs-6" [animateCss]="'fadeInDown'">
      Don't have an account?
      <a [routerLink]="['/auth/registration']" queryParamsHandling="preserve"
        class="cursor-pointer text-dark fw-bold text-hover-gray-800">
        Sign Up
      </a>
    </div>
  </ng-container>
</div>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container text-danger ps-2">
      <span role="alert">
        {{ message }}
      </span>
    </div>
  </ng-container>
</ng-template>