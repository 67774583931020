import { Inject, Injectable, inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { ISEOToken, ISEOData } from '@launchpoint/core-types';
import { SEO_CONFIG_TOKEN } from './seo.interface';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointSEORouterService {
  titleService = inject(Title);
  metaService = inject(Meta);
  router = inject(Router);
  debug = true;
  seoOptions: ISEOToken = inject(SEO_CONFIG_TOKEN);

  private canonicalLink: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) public document: Document) {
    this.debug = this.seoOptions.debug ?? false;
    this.canonicalLink = this.document.createElement('link');
    this.canonicalLink.setAttribute('rel', 'canonical');
    // this.canonicalLink.setAttribute('href', 'https://launchpoint.dev/');

    // Add it to the DOM
    this.document.head.append(this.canonicalLink);
  }

  public setCanonicalUrl(url: string) {
    this.canonicalLink.setAttribute('href', url);
  }

  public register() {
    // seoOptions?: ISEOOptions
    if (this.debug) console.log('LaunchpointSEOService Registered');
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
      next: () => this.updateTitleAndMeta(),
      error: (error) => console.error(error),
    });
  }

  findSEODataByRoute(route: string): ISEOData {
    for (const key in this.seoOptions.seo) {
      if (this.seoOptions.seo[key].route === route) {
        return this.seoOptions.seo[key];
      }
    }
    return this.seoOptions.seo['home'];
  }

  updateTitleAndMeta(seoDataOverride?: Partial<ISEOData>): void {
    const route = this.router.url.split('?')[0].substring(1) || 'home';
    this.canonicalLink.setAttribute('href', this.document.URL);
    if (this.debug) console.log('canonicalLink', this.document.URL);
    const defaultData = this.seoOptions.seo['home'];
    const routeData = this.findSEODataByRoute(route);
    const seoData = { ...defaultData, ...routeData, ...seoDataOverride };

    if (this.debug) {
      console.log('Route:', route);
      if (seoDataOverride) {
        console.log('SEO Data Override:');
      }
      console.log('SEO Data:', seoData);
    }

    this.titleService.setTitle(seoData.title);
    this.metaService.updateTag({ name: 'description', content: seoData.description });

    if (seoData.keywords) {
      this.metaService.updateTag({ name: 'keywords', content: seoData.keywords });
    }

    // if (seoData.canonical_url) {
    //   this.metaService.updateTag({ rel: 'canonical', href: seoData.canonical_url });
    // }

    if (seoData?.og?.title) {
      this.metaService.updateTag({ property: 'og:title', content: seoData.og.title });
    }
    if (seoData?.og?.description) {
      this.metaService.updateTag({ property: 'og:description', content: seoData.og.description });
    }
    if (seoData?.og?.image) {
      this.metaService.updateTag({ property: 'og:image', content: seoData.og.image });
    }
    if (seoData?.og?.url) {
      this.metaService.updateTag({ property: 'og:url', content: seoData.og.url });
    }
    if (seoData?.og?.type) {
      this.metaService.updateTag({ property: 'og:type', content: seoData.og.type });
    }
    if (seoData?.og?.site_name) {
      this.metaService.updateTag({ property: 'og:site_name', content: seoData.og.site_name });
    }
    if (seoData?.og?.locale) {
      this.metaService.updateTag({ property: 'og:locale', content: seoData.og.locale });
    }

    // Update Twitter Card tags
    if (seoData?.twitter?.card) {
      this.metaService.updateTag({ name: 'twitter:card', content: seoData.twitter.card });
    }
    if (seoData?.twitter?.site) {
      this.metaService.updateTag({ name: 'twitter:site', content: seoData.twitter.site });
    }
    if (seoData?.twitter?.title) {
      this.metaService.updateTag({ name: 'twitter:title', content: seoData.twitter.title });
    }
    if (seoData?.twitter?.description) {
      this.metaService.updateTag({ name: 'twitter:description', content: seoData.twitter.description });
    }
    if (seoData?.twitter?.image) {
      this.metaService.updateTag({ name: 'twitter:image', content: seoData.twitter.image });
    }

    if (seoData.author) {
      this.metaService.updateTag({ name: 'author', content: seoData.author });
    }

    if (seoData.robots) {
      this.metaService.updateTag({ name: 'robots', content: seoData.robots });
    }

    if (seoData.published_time) {
      this.metaService.updateTag({ property: 'article:published_time', content: seoData.published_time });
    }

    if (seoData.modified_time) {
      this.metaService.updateTag({ property: 'article:modified_time', content: seoData.modified_time });
    }

    if (seoData.language) {
      this.metaService.updateTag({ name: 'language', content: seoData.language });
    }

    if (seoData.theme_color) {
      this.metaService.updateTag({ name: 'theme-color', content: seoData.theme_color, media: '(prefers-color-scheme: light)' });
      this.metaService.updateTag({ name: 'theme-color', content: seoData.theme_color, media: '(prefers-color-scheme: dark)' });
    }

    if (seoData.ms_tile_color) {
      this.metaService.updateTag({ name: 'msapplication-TileColor', content: seoData.ms_tile_color });
    }
  }
}
