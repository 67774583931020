import { Directive, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { updateAllNotificationsPagination } from './+state/actions/notifications.actions';
import { LaunchpointCoreClientNotificationsStateBaseComponent } from './+state/notifications-state-base.component';
import { ENotificationMessageType, INotificationMessageSearchQuery, INotificationSearchQuery } from '@launchpoint/core-types';

@Directive()
export abstract class LaunchpointCoreClientNotificationsBaseComponent extends LaunchpointCoreClientNotificationsStateBaseComponent {
  @Input() query: INotificationSearchQuery = {};
  @Input() messageQuery: INotificationMessageSearchQuery = { job_types: [ENotificationMessageType.MESSAGE] };
  @Input() pageLimit: number;

  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(
      updateAllNotificationsPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } })
    );
  }

  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllNotificationsPagination({ pagination: { ...pagination, limit } }));
  }
}
