export const LAUNCHPOINT_NOTIFICATION_MESSAGES_SCHEMA_NAME = 'LAUNCHPOINT_NOTIFICATION_MESSAGES_SCHEMA_NAME';

export enum ENotificationMessageType {
  /**
   * Notifications are system/event message. These will pull information from users and properties from the notification to send unique messages to users.
   * Channels are required
   */
  NOTIFICATION = 'Notification',
  /**
   * Messages are custom build fire and forget/scheduled messages for groups of users based on a query of users traits.
   * Individual users can also be added instead.
   * Channels are required
   */
  MESSAGE = 'Message',
  /**
   * Topics
   */
  TOPIC = 'Topic',
}
