import { ICoreAlertBanner, IHttpException } from '@launchpoint/core-types';

export interface AlertBannerEntityState {
  banner_id: string;
  banner: ICoreAlertBanner;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  success_message?: string;
}
