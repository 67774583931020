import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ViewEntityAdaptor, ViewFeatureKey, StateViewReducer } from '../reducers/views.reducer';

const selectAllViewState = createFeatureSelector<StateViewReducer>(ViewFeatureKey);

/**
 * ROOT STATE
 */
export const getAllViewState = createSelector(selectAllViewState, (state: StateViewReducer) => state);

export const selectViewPagination = createSelector(getAllViewState, (state: StateViewReducer) => state.pagination);
export const selectViewQuerySort = createSelector(getAllViewState, (state: StateViewReducer) => state.querySort);
export const selectViewQuery = createSelector(getAllViewState, (state: StateViewReducer) => state.query);
export const selectViewSuccess = createSelector(getAllViewState, (state: StateViewReducer) => state.success_message);
export const selectViewLoading = createSelector(getAllViewState, (state: StateViewReducer) => state.loading);
export const selectViewLoaded = createSelector(getAllViewState, (state: StateViewReducer) => state.loaded);
export const selectViewError = createSelector(getAllViewState, (state: StateViewReducer) => state.error);

export const getAllViewStateEntities = createSelector(selectAllViewState, (state: StateViewReducer) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = ViewEntityAdaptor.getSelectors(selectAllViewState);

// select the array of user ids
export const selectViewsIds = selectIds;

// select the dictionary of user entities
export const selectViewsEntities = selectEntities;

// select the array of users
export const selectViews = selectAll;

// select the total entity count
export const selectViewsTotal = selectTotal;

export const selectViewId = createSelector(getAllViewState, (state: StateViewReducer) => state?.selected_view_id);

export const selectSelectedView = createSelector(
  getAllViewState,
  selectViewId,
  (state: StateViewReducer, selected_view_id) => state.entities[selected_view_id]
);

export const selectViewByTableId = (table_id: string) => {
  return createSelector(selectViews, (entities) =>
    entities.filter((entity) => entity.view.table_id === table_id).map((f) => ({ value: f.view.title, data: f.view }))
  );
};
