import { on } from '@ngrx/store';
import * as FormYearActions from '../../actions/years-actions';
import { LaunchpointDynamicFormResponseState } from '../reducer';

export const DYNAMIC_FORM_RESPONSES_YEAR_REDUCER = [
  on(FormYearActions.yearsSetFormComparison, (state: LaunchpointDynamicFormResponseState, { year }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
      // error: null,
      success_message: null,
      year_1: year,
    };
  }),
  on(FormYearActions.yearsSetFormComparisonV2, (state: LaunchpointDynamicFormResponseState, { years }) => {
    return {
      ...state,
      loaded: false,
      loading: true,
      // error: null,
      success_message: null,
      year_1: Math.min(...years) || null,
      year_2: Math.max(...years) || null,
    };
  }),
  on(FormYearActions.yearsAddMoreYears, (state: LaunchpointDynamicFormResponseState, { number_of_years }) => {
    // Determine the lowest year
    const lowest_year = Math.min(...state.years);

    // Generate an array with the three new years preceding the lowest year
    const new_years = Array.from({ length: number_of_years }, (_, index) => lowest_year - index - 1);
    const yearsSet = new Set([...state.years, ...new_years]);
    const years = Array.from(yearsSet).sort((a, b) => b - a);

    // Return a new state object with the updated years array
    return {
      ...state,
      years,
    };
  }),
];
