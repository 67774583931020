import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  ICoreUser,
  ICoreUserAccountController,
  ICoreUserAccountsParamsAcceptInvitations,
  ICoreUserAccountsParamsAddMainUserAccount,
  ICoreUserAccountsParamsAddSubUserAccount,
  ICoreUserAccountsParamsFindWhereUserInAccounts,
  ICoreUserAccountsParamsGetPendingInvitations,
  ICoreUserAccountsParamsRemove,
  ICoreUserAccountsParamsResendInvite,
  ICoreUserAccountsParamsSwapAccount,
  ICoreUserAccountsParamsUpgradeSubUserToMainUser,
  ICoreUserAccountsUserPendingInvitations,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap, take } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { ICoreUserAccountsConfigRoutes } from '../../core/core-account/interfaces';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../core/core-profile/interfaces/core-profile.interface';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointCoreClientAccountsHTTPService extends LaunchpointSecurityUserIdHeaderService implements ICoreUserAccountController {
  /**
   * Only works for web.
   */
  API_URL = '/api/backend/';

  routes: ICoreUserAccountsConfigRoutes = {
    base: 'user/account',
    addSubUserAccount: '/sub-user',
    addMainUserAccount: '/main-user',
    removeAccount: '/remove',
    acceptInvites: '/accept-invitation',
    getPendingInvites: '/get-invitation',
    selectAccount: '/select',
    deselectAccount: '/deselect',
    resendInvite: '/resend-invite',
    findWhereUserInAccounts: '/user-in-accounts',
    upgradeSubUser: '/upgrade-sub-user',
  };

  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _LaunchpointCoreClientProfileConfig?.base_url,
      version: 1,
      route: this.routes.base,
    });
  }
  /**
   * This is the route to find the other users that a user has an account on. It only returns the emails of the found users
   * because we don't want to give all of their information to this user making the request.
   * Kasey,  if the return type needs to change just lmk and I can rework it
   */

  findWhereUserIsInAccounts(body: ICoreUserAccountsParamsFindWhereUserInAccounts): Observable<string[]> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<string[]>(this.API_URL + this.routes.findWhereUserInAccounts, body, { headers });
      })
    );
  }

  selectAccount(body: ICoreUserAccountsParamsSwapAccount): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.selectAccount, body, { headers });
      })
    );
  }
  deselectAccount(body: ICoreUserAccountsParamsSwapAccount): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.deselectAccount, body, { headers });
      })
    );
  }

  getAccountInvitations(body: ICoreUserAccountsParamsGetPendingInvitations): Observable<ICoreUserAccountsUserPendingInvitations[]> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUserAccountsUserPendingInvitations[]>(this.API_URL + this.routes.getPendingInvites, body, { headers });
      })
    );
  }
  acceptInvitation(body: ICoreUserAccountsParamsAcceptInvitations): Observable<{ message: string }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<{ message: string }>(this.API_URL + this.routes.acceptInvites, body, { headers });
      })
    );
  }

  addSubUserAccount(body: ICoreUserAccountsParamsAddSubUserAccount): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      take(1),
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.addSubUserAccount, body, { headers });
      })
    );
  }

  addMainUserAccount(body: ICoreUserAccountsParamsAddMainUserAccount): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<ICoreUser>(this.API_URL + this.routes.addMainUserAccount, body, { headers });
      })
    );
  }

  removeAccount(body: ICoreUserAccountsParamsRemove): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.removeAccount, body, { headers });
      })
    );
  }

  resendInvite(body: ICoreUserAccountsParamsResendInvite): Observable<{ message: string }> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<{ message: string }>(this.API_URL + this.routes.resendInvite, body, { headers });
      })
    );
  }

  upgradeSubUser(body: ICoreUserAccountsParamsUpgradeSubUserToMainUser): Observable<ICoreUser> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<ICoreUser>(this.API_URL + this.routes.upgradeSubUser, body, { headers });
      })
    );
  }
}
