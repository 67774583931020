import { Component } from '@angular/core';
import { CompanyHeaderBaseComponent } from '@launchpoint/core-client';
import { IEllipsisMenuOption } from '../../../../components/ellipsis-menu/ellipsis-menu.component';

@Component({
  selector: 'launchpoint-company-header',
  templateUrl: 'company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
})
export class CompanyHeaderComponent extends CompanyHeaderBaseComponent {
  options: IEllipsisMenuOption[] = [
    {
      label: 'Add Access',
      disabled: true,
    },
    {
      label: 'Create Invoice',
      disabled: true,
    },
    {
      label: 'Create Payment',
      disabled: true,
    },
    {
      label: 'Generate Bill',
      disabled: true,
    },
    {
      label: 'Settings',
      disabled: true,
    },
    {
      label: 'Delete',
      disabled: true,
    },
  ];
}
