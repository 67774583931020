<div>
  <!-- HERO -->
  <div class="bg-background relative flex justify-center py-24">
    <div class="w-[1200px] max-w-full px-6 text-white">
      <div class="my-20 grid grid-cols-1 gap-5 md:my-20 md:grid-cols-2 md:gap-20">
        <div class="flex flex-col items-center justify-between md:me-20 md:items-start">
          <div class="font-oswald text-5xl uppercase text-white">let's work<br />together</div>

          <div class="my-5 w-[80%] border md:my-0 md:w-[100%]"></div>

          <div class="mb-5 text-center text-sm md:mb-0 md:text-left">
            <div>
              Looking to scale your business? Seeking expert guidance or mentorship? Want to uncover hidden
              inefficiencies or explore custom
              solutions?
            </div>

            <div class="mt-5">Book a call with our team.</div>
            <div class="mt-5">If you’re ready to get to work, I’m ready to take you to the top.</div>
          </div>

          <div class="mb-5 md:mb-0">
            <sd-social-icons></sd-social-icons>
          </div>

          <div class="text-primary font-courierNew mb-5 md:mb-0">Text Me!</div>

          <div class="mb-5 md:mb-0">
            @for (item of contactInfo; track item) {
            <div class="my-1 flex flex-row justify-start gap-4">
              <i [class]="item.icon" class="text-primary text-center"></i>
              <div class="hover:text-primary text-sm text-white">{{ item.text }}</div>
            </div>
            }
          </div>
        </div>

        <div class="flex justify-center">
          <div class="calendly-inline-widget rounded-lg" data-resize="true"
            style="min-width: 320px; min-height: 600px; height: 600px; width: 100%; max-width: 600px; border-radius: 20px !important">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- BOOKED -->

  <div *ngIf="booked$()" class="bg-light relative flex justify-center text-black">
    <div class="w-[1200px] max-w-full px-6 text-black">
      <div class="flex items-center justify-center">
        <div class="grid max-w-full grid-cols-1 items-start justify-center p-10 md:grid-cols-2 md:p-12">
          <div class="font-oswald my-5 text-start text-5xl uppercase">What's Next?</div>

          <div>
            <div class="mb-5">
              <div class="text-md font-bold">What to Expect</div>
              <ul class="custom-list list-inside list-disc space-y-1 text-gray-500">
                <li>We'll explore your business challenges, operations, and areas of inefficiency.</li>
                <li>Identify where Launchpoint can help improve processes, boost revenue, or optimize systems.</li>
              </ul>
            </div>
            <div class="mb-5">
              <div class="text-md font-bold">What to Prepare</div>
              <ul class="custom-list list-inside list-disc space-y-1 text-gray-500">
                <li>Bring any reports or documentation highlighting pain points (e.g., financials, process documents, or
                  current tools).</li>
                <li>Note specific issues, like bottlenecks or tech gaps.</li>
                <li>If relevant, have login credentials or access to systems ready for demonstration.</li>
              </ul>
            </div>
            <div class="mb-5">
              <div class="text-md font-bold">How to Get the Most from the Call</div>
              <ul class="custom-list list-inside list-disc space-y-1 text-gray-500">
                <li>
                  Reflect on what you want to achieve—whether it's cutting costs, scaling faster, or improving customer
                  experience. Clear objectives
                  will ensure a productive call
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- NEWSLETTER -->
  <sd-newsletter></sd-newsletter>
</div>