import { Component } from '@angular/core';
import { CompanyUsersAdminBaseComponent, LaunchpointCompanyEntityState } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'launchpoint-company-users',
  templateUrl: 'company-users.component.html',
})
export class CompanyUsersAdminComponent extends CompanyUsersAdminBaseComponent {
  createModal: NgbModalRef;

  constructor(private modalService: NgbModal, private _swalService: LaunchpointSweetAlertService, public router: Router) {
    super();
  }

  routeToUser(user: ICoreUser) {
    this.router.navigate([`/admin/users/${user._id}`]);
  }

  open(selectedCompany: LaunchpointCompanyEntityState) {
    // this.createModal = this.modalService.open(UserCreateAccountComponent, {
    //   size: 'xl',
    // });
    // this.createModal.componentInstance.selectedCompany = selectedCompany;
  }

  deleteConfirm(account_id: string, user: ICoreUser, event?: Event) {
    event?.stopPropagation();

    this._swalService
      .fireAlert({
        text: `Are you sure you want to remove ${user.profile?.full_name}`,
        icon: 'error',
        buttonsStyling: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, close this!',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-light',
        },
      })
      .then((res) => {
        if (res.isConfirmed) {
          this.removeUserFromCompany(account_id, user);
        }
      });
  }
}
