import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { DeepFlatten } from '../../deep-flatten';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';
import { ETopicType, ITopic } from '../topics';
import { ENotificationChannelType } from './notifications.constants';

export interface INotificationProperty<T = undefined> {
  data_path: keyof DeepFlatten<T>;
  description: string;
  default_value: string;
}

export interface SMSChannel {
  channel: ENotificationChannelType.SMS;
  topic: string;
  message: string;
}

export enum EEmailChannel {
  TEXT = 'text/plain',
  HTML = 'text/html',
}

interface EmailChannelRecipient {
  email: string;
  name: string;
}

export interface IEmailChannel {
  channel: ENotificationChannelType.EMAIL;
  topic?: string;
  cc?: EmailChannelRecipient[];
  bcc?: EmailChannelRecipient[];
  from?: EmailChannelRecipient;
  replyTo?: EmailChannelRecipient;
  subject?: string;
  text?: string;
  html?: string;
  templateId?: string;
  dynamicTemplateData?: Record<string, any>;
}

export type AndroidConfigPriority = 'high' | 'normal';

export type AndroidNotificationVisibility = 'private' | 'public' | 'secret';

export enum ENotificationAlertType {
  ALERT = 'alert',
  TOAST = 'toast',
}

type ENotificationToast = {
  type: ENotificationAlertType.TOAST;
  icon: 'success' | 'error' | 'warning' | 'info' | 'question';
  timer?: number;
  position?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'top-left'
    | 'top-right'
    | 'center'
    | 'center-start'
    | 'center-end'
    | 'center-left'
    | 'center-right'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'bottom-left'
    | 'bottom-right';
};
type ENotificationAlert = {
  type: ENotificationAlertType.ALERT;
  icon: 'success' | 'error' | 'warning' | 'info' | 'question';
  showConfirmButton?: 'true' | 'false';
  showCloseButton?: 'true' | 'false';
  showCancelButton?: 'true' | 'false';
  confirmButtonText?: string;
  cancelButtonText?: string;
  customClass?: {
    container?: string;
    popup?: string;
    title?: string;
    closeButton?: string;
    icon?: string;
    image?: string;
    htmlContainer?: string;
    input?: string;
    inputLabel?: string;
    validationMessage?: string;
    actions?: string;
    confirmButton?: string;
    denyButton?: string;
    cancelButton?: string;
    loader?: string;
    footer?: string;
    timerProgressBar?: string;
  };
};

export interface IPushChannel {
  channel: ENotificationChannelType.PUSH;
  notification: {
    title: string;
    imageUrl?: string;
    body: string;
  };
  data?: (ENotificationToast | ENotificationAlert) & Record<string, any>;
  apns?: {
    headers: {
      apns_priority?: string;
      apns_topic?: string;
      apns_push_type?: 'alert' | 'background';
      apns_expiration?: string;
      apns_id?: string;
    };
    payload: {
      aps: {
        alert?: {
          title?: string;
          subtitle?: string;
          body?: string;
        };
        badge?: number;
        sound?: string;
        contentAvailable?: boolean;
        mutableContent?: boolean;
        category?: string;
        threadId?: string;
      };
      data?: any;
    };
    fcmOptions?: {
      imageUrl: string;
      analyticsLabel: string;
    };
  };
  android?: {
    collapseKey?: string;
    priority?: AndroidConfigPriority;
    ttl?: number;
    restrictedPackageName?: string;
    data?: Record<string, string>;
    notification?: {
      title?: string;
      body?: string;
      icon?: string;
      color?: string;
      sound?: string;
      tag?: string;
      clickAction?: string;
      bodyLocKey?: string;
      bodyLocArgs?: string[];
      titleLocKey?: string;
      titleLocArgs?: string[];
      channelId?: string;
      image?: string;
      ticker?: string;
      sticky?: boolean;
      eventTime?: string;
      localOnly?: boolean;
      defaultVibrateTimings?: boolean;
      defaultSound?: boolean;
      lightSettings?: {
        color: string;
        lightOnDurationMillis: number;
        lightOffDurationMillis: number;
      };
      defaultLightSettings?: boolean;
      visibility?: AndroidNotificationVisibility;
    };
    fcmOptions?: {
      analyticsLabel?: string;
    };
  };
  webpush?: {
    headers: {
      Urgency?: 'very-low' | 'low' | 'normal' | 'high';
    };
    notification: {
      title: string;
      body: string;
      icon?: string;
      image?: string;
      badge?: string;
      vibrate?: number[];
      timestamp?: number;
      renotify?: boolean;
      silent?: boolean;
      requireInteraction?: boolean;
      sticky?: boolean;
      dir?: 'auto' | 'ltr' | 'rtl';
      tag?: string;
      data?: any;
      actions?: {
        action: string;
        title: string;
        icon?: string;
      }[];
    };
    fcmOptions?: {
      link?: string;
      analyticsLabel?: string;
    };
    data?: any;
  };
  fcmOptions?: {
    analyticsLabel: string;
  };
}

interface ISlackAttachmentField {
  title?: string;
  value?: string;
  short?: boolean;
}

interface ISlackAttachmentAction {
  name?: string;
  text?: string;
  type?: string;
  value?: string;
  style?: string;
  confirm?: {
    title?: string;
    text?: string;
    ok_text?: string;
    dismiss_text?: string;
  };
}

interface ISlackAttachment {
  fallback?: string;
  color?: string;
  pretext?: string;
  author_name?: string;
  author_link?: string;
  author_icon?: string;
  title?: string;
  title_link?: string;
  text?: string;
  fields?: ISlackAttachmentField[];
  actions?: ISlackAttachmentAction[];
  image_url?: string;
  thumb_url?: string;
  footer?: string;
  footer_icon?: string;
  ts?: number;
}

export interface SlackChannel {
  channel: ENotificationChannelType.SLACK;
  text: string;
  attachments?: ISlackAttachment[];
}

export interface ISystemChannel {
  channel: ENotificationChannelType.SYSTEM;
  title: string;
  body: string;
  icon?: string;
  click_action?: string;
}

export type INotificationChannel = SMSChannel | IEmailChannel | IPushChannel | SlackChannel | ISystemChannel;

export class INotificationBase<T = undefined> {
  _id: string;
  created_at: Date;
  updated_at: Date;
  last_updated_at: Date;
  version: number;
  is_active: boolean;
  topic: string | ITopic;
  security_roles: string[];
  event: string;
  subject: string;
  subtitle: string | null;
  message: string | null;
  channels: INotificationChannel[];
  properties: INotificationProperty<T>[];
}

export class INotification<T = undefined> extends INotificationBase<T> {
  topic: ITopic;
}

export class INotificationEvent {
  event: string;
  users?: string[];
  accounts?: string[];
  user_conditions?: any;
  account_conditions?: any;
  event_data: Record<string, any>;
  title?: string;
  description?: string;
}

// export class INotificationEventFire {
//   event: string;
//   subject: string;
//   subtitle: string | null;
//   channels: INotificationChannel[];
//   users: string[];
//   accounts?: string[];
// }

type INotificationEventRegisterNewTopic = {
  /**
   * the NAME of the topic
   *
   * *NOT THE ID*
   */
  topic: string;
  topic_type: ETopicType;
  topic_id?: never;
};

type INotificationEventRegisterExistingTopic = {
  topic_id: string;
  topic?: never;
  topic_type?: never;
};

export type INotificationEventRegister<T = undefined> = {
  // topic_id: string;
  event: string;
  security_roles: string[];
  channels: INotificationChannel[];
  properties: INotificationProperty<T>[];
  /**
   * If this property is set to true it will wipe out any channel message customization already completed and reset it to this value.
   */
  channel_override?: boolean;
} & (INotificationEventRegisterNewTopic | INotificationEventRegisterExistingTopic);

export class INotificationParamsCreate<T = undefined> {
  event: string;
  is_active: boolean;
  topic: string;
  subject: string;
  channels: INotificationChannel[];
  properties: INotificationProperty<T>[];
  version?: number;
  subtitle?: string;
  message?: string;
  security_roles?: string[];
}

export class INotificationParamsUpdate {
  _id: string;
  event?: string;
  is_active?: boolean;
  version?: number;
  topic?: string;
  subject?: string;
  subtitle?: string;
  message?: string;
  security_roles?: string[];
}

export class INotificationSearchQuery {
  search?: string;
  exclude?: string;
  is_active?: boolean;
  created_at?: ILaunchpointSearchFilterDates;
  security_roles?: string[];
  channels?: ENotificationChannelType[];
  events?: string[];
}

export class INotificationSearchPayload {
  query: INotificationSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class INotificationSearchResults<T = undefined> {
  data: INotification<T>[];
  pagination: IQueryPageination;
}
