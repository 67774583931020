/**
 * Workspace shared environment properties
 */
export interface IEnvironmentApp {
  production?: boolean;
  appVersion: string;
  USERDATA_KEY: string;
  isMockEnabled: boolean;
  apiUrl: string;
  /**
   * ENDS WITH A SLASH!!
   * @example '/api/media/'
   */
  mediaApiUrl: string;
  notificationsApiUrl?: string;
  firebase?: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  vapidKey?: string;
}

import { InjectionToken } from '@angular/core';

export interface ApplicationServiceConfig {
  environment: IEnvironmentApp;
  layoutConfig: {
    logo_light: string;
    logo_dark: string;
  };
}

export const APPLICATION_CONFIG_TOKEN = new InjectionToken<ApplicationServiceConfig>('APPLICATION_TOKEN');
