<div class="card mb-xl-10 mb-5">
  <div class="card-header border-0">
    <div class="card-title m-0">
      <h3 class="fw-bolder m-0">Media</h3>
    </div>
  </div>

  <div class="card-body border-top p-9">
    <label class="col-lg-4 col-form-label fw-bold fs-6">Search Media</label>
    <div class="row mb-6">
      <div class="col-lg-6">
        <launchpoint-media-search-ahead [placeholder]="blog?.featured_image?.title" [query]="query" (selectedData)="receiveMediaSelection($event)" />
      </div>
    </div>

    @if (blog?.featured_image) {
      <label class="form-label fw-bold fs-6 mt-8">Featured Image</label>
      <div class="col-12">
        <div class="row align-items-center mb-3">
          <div class="col-3">
            <img [src]="blog?.featured_image?.meta_data?.url" style="max-height: 75px" imageStyle="max-height: 75px;" [alt]="blog?.title" />
          </div>

          <div class="col d-flex align-items-center flex-row">
            <button type="button" class="btn btn-icon text-hover-danger ms-5" ngbTooltip="Remove" (click)="deleteMediaPopup()">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </div>
    }
  </div>
</div>
