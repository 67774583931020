<div class="launchpoint-app-content flex-column-fluid container-xxl px-0">
  <div class="app-container container-fluid">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header border-0">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ _config.profile_details.title }}</h3>
        </div>
      </div>
      <form [formGroup]="form" class="form">
        <div class="card-body border-top p-9">
          <!-- START: Name -->
          <label class="col-lg-4 col-form-label fw-bold fs-6">Name</label>
          <div class="row mb-6">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <input type="text" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="First name" name="first_name" formControlName="first_name" [ngClass]="{
                      'is-invalid': form.controls.first_name.invalid && (form.controls.first_name.dirty || form.controls.first_name.touched),
                      'is-valid': form.controls.first_name.valid && (form.controls.first_name.dirty || form.controls.first_name.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'First name is required',
                      control: form.controls.first_name
                    }">
                  </ng-container>
                </div>
                <div class="col-lg-6 fv-row">
                  <input type="text" class="form-control form-control-lg form-control-solid" placeholder="Last name"
                    name="last_name" formControlName="last_name" [ngClass]="{
                      'is-invalid': form.controls.last_name.invalid && (form.controls.last_name.dirty || form.controls.last_name.touched),
                      'is-valid': form.controls.last_name.valid && (form.controls.last_name.dirty || form.controls.last_name.touched)
                    }" />
                  <ng-container [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                      validation: 'required',
                      message: 'Last name is required',
                      control: form.controls.last_name
                    }">
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- START: Birthday -->
          <ng-container *ngIf="_config.profile_details.fields.birthday">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Date of Birth</label>
            <div class="row mb-6">
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-6 fv-row input-group">
                    <input type="text" onkeydown="return false"
                      class="form-control form-control-lg form-control-solid mb-3 mb-lg-0 disCaret" name="birthday"
                      formControlName="birthday" ngbDatepicker #d="ngbDatepicker" [maxDate]="maxDate"
                      [minDate]="minDate" />
                    <div class="input-group-append eye-icon">
                      <button type="button" class="btn bg-none" (click)="d.toggle()">
                        <i class="fas fa-calendar text-hover-primary"></i>
                      </button>
                    </div>
                  </div>
                  <div class="col-lg-6 fv-row input-group"></div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- START: Gender -->
          <ng-container *ngIf="_config.profile_details.fields.gender">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Gender </label>
            <div class="row mb-6">
              <div class="col-lg-8 fv-row">
                <select class="form-select form-select-solid form-select-lg fw-bold" formControlName="gender"
                  name="gender">
                  <option value="">Select One...</option>
                  <option *ngFor="let gender of genderOptions" [value]="gender">{{ gender }}</option>
                </select>
              </div>
            </div>
          </ng-container>
          <!-- START: Marital Status -->
          <ng-container *ngIf="_config.profile_details.fields.marital_status">
            <label class="col-lg-4 col-form-label fw-bold fs-6">Marital Status</label>
            <div class="row mb-6">
              <div class="col-lg-8 fv-row">
                <select class="form-select form-select-solid form-select-lg fw-bold" formControlName="marital_status"
                  name="marital_status">
                  <option value="">Select One...</option>
                  <option *ngFor="let mstatus of maritalStatusOptions" [value]="mstatus">{{ mstatus }}</option>
                </select>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- START: Footer -->
        <div class="card-footer d-flex justify-content-end py-6 px-9" let some="false">
          <button class="btn btn-secondary me-3" (click)="resetForm()">Cancel</button>
          <button type="button" class="btn btn-primary" [disabled]="(loading$ | async) || form.invalid || form.pristine"
            (click)="saveSettings()">
            <ng-container *ngIf="(loading$ | async ) === false">Save</ng-container>

            <ng-container *ngIf="(loading$ | async)">
              <span class="indicator-label" [style.display]="'block'" loading="true">
                Please wait...{{ ' ' }}
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </ng-container>
          </button>
        </div>
      </form>
    </div>
    <!-- START: FormError-->
    <ng-template #formError let-control="control" let-message="message" let-validation="validation">
      <ng-container
        *ngIf="(control?.hasError(validation) && (control?.dirty || control?.touched)) || (control.invalid && control.touched)">
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <span role="alert">{{ message }}</span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>