<input
  type="text"
  class="style-input"
  [class]="cssClass"
  [placeholder]="formatter(selected) ?? 'Search categories...'"
  (focus)="onFocus($event)"
  [ngbTypeahead]="search"
  [resultFormatter]="formatter"
  [inputFormatter]="formatter"
  [editable]="true"
  (selectItem)="selectData($event)"
/>
