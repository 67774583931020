import { Directive, EventEmitter, Output } from '@angular/core';
import { IQuerySort } from '@launchpoint/core-types';
import { updateAllWebhooksPagination, updateAllWebhooksQuery } from '../+state/actions/webhooks.actions';
import { LaunchpointCoreClientWebhooksBaseComponent } from './webhooks.base-component';
import { firstValueFrom } from 'rxjs';

@Directive()
export abstract class LaunchpointCoreClientWebhooksTableBaseComponent extends LaunchpointCoreClientWebhooksBaseComponent {
  @Output() sortDataEmitter = new EventEmitter<IQuerySort>();
  @Output() delete = new EventEmitter<string>();

  updateQuery(search: string) {
    console.log('updateQuery', search, this.query);
    this._Store.dispatch(
      updateAllWebhooksQuery({
        query: {
          ...this.query,
          search,
        },
      })
    );
  }
  async paginate(page: number) {
    const pagination = await firstValueFrom(this.pagination$);
    if (pagination.pageIndex === page - 1) {
      return;
    }
    this._Store.dispatch(updateAllWebhooksPagination({ pagination: { ...pagination, pageIndex: page - 1, skip: pagination.limit * (page - 1) } }));
  }
  async limit(limit: number) {
    const pagination = await firstValueFrom(this.pagination$);
    this._Store.dispatch(updateAllWebhooksPagination({ pagination: { ...pagination, limit } }));
  }

  sortData(event) {
    const sortedData: IQuerySort = {
      active: event.active,
      direction: event.direction,
    };
    this.sortDataEmitter.emit(sortedData);
  }

  deleteById(id: string) {
    this.delete.emit(id);
  }
}
