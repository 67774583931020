import { InjectionToken } from '@angular/core';

export interface LaunchpointCoreClientActivityHistoryConfig<T = any> {
  base_url: string;
  events: T[];
}

export const ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN = new InjectionToken<LaunchpointCoreClientActivityHistoryConfig>(
  'ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN'
);
