import { Component, Input } from '@angular/core';
import { LaunchpointLayoutMenu } from '../../../../../../interfaces/menu.interfaces';

@Component({
  selector: 'launchpoint-demo-4-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class LaunchpointDemo4AsideMenuComponent {
  appAngularVersion = '';
  appPreviewChangelogUrl = '';
  @Input() menuConfig: LaunchpointLayoutMenu;

  // constructor() {}
  // ngOnInit(): void {}
}
