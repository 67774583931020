import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  LaunchpointCoreClientNotificationsDashboardBaseComponent,
  updateAllMessagesPagination,
  updateAllNotificationsPagination,
} from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../components/swal.service';
import { IToolbarConfig } from '../../../../components/toolbar/toolbar.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreWebNotificationMessageCreateComponent } from '../messages/notifications-message-create/notifications-message-create.component';
import { LaunchpointCoreWebNotificationCreateComponent } from '../notifications/notification-create/notification-create.component';

@Component({
  selector: 'launchpoint-notifications-dashboard',
  templateUrl: './notifications-dashboard.component.html',
  styleUrls: ['./notifications-dashboard.component.scss'],
})
export class LaunchpointCoreWebNotificationDashboardComponent extends LaunchpointCoreClientNotificationsDashboardBaseComponent implements OnInit {
  private _swal = inject(LaunchpointSweetAlertService);
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  notificationOptions: IToolbarConfig = {
    title: 'Notifications',
    create: true,
    searchPlaceholder: 'Search notifications',
    filter: false,
  };

  messageOptions: IToolbarConfig = {
    title: this.selectedChannel ?? 'Messages',
    create: true,
    searchPlaceholder: 'Search messages',
    filter: false,
  };

  ngOnInit() {
    this.success();
    this.error();
  }

  onMessageCreate() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebNotificationMessageCreateComponent, { size: 'lg', scrollable: true });
  }
  onNotificationCreate() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebNotificationCreateComponent, { size: 'lg', scrollable: true });
  }

  onMessageView() {
    this._Store.dispatch(updateAllMessagesPagination({ pagination: this.pagination }));
  }
  onNotificationView() {
    this._Store.dispatch(updateAllNotificationsPagination({ pagination: this.pagination }));
  }

  override toast({ icon, title }) {
    this._swal.fireToast({ icon, title });
  }
}
