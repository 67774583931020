import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILayoutState, LAYOUT_FEATURE_KEY } from '../reducers';

// Lookup the 'Menu' feature state managed by NgRx
export const getlayoutState = createFeatureSelector<ILayoutState>(LAYOUT_FEATURE_KEY);
export const menuReducer = createSelector(getlayoutState, (state: ILayoutState) => state.menuReducer);

export const getAsideMenu = createSelector(menuReducer, (state: ILayoutState['menuReducer']) => state.asideMenu);

export const getHeaderMenu = createSelector(getlayoutState, (state: ILayoutState) => state.menuReducer.headerMenu);

export const getTopbarConfig = createSelector(getlayoutState, (state: ILayoutState) => state.menuReducer.topbar);

export const getFooterConfig = createSelector(getlayoutState, (state: ILayoutState) => state.menuReducer.footer);
