import { Component } from '@angular/core';
import { UsersTableBaseComponent } from '@launchpoint/core-client';
import { IToolbarConfig } from '../../../../components/toolbar/toolbar.component';

@Component({
  selector: 'launchpoint-users-table',
  templateUrl: './users-table.component.html',
  // styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent extends UsersTableBaseComponent {
  active = 1;

  toolbarOptions: IToolbarConfig = {
    title: 'Users',
    searchPlaceholder: 'Search by name or email',
    formControl: this.form_control,
    create: false,
    queryCount: this.queryFilterCount$,
  };
}
