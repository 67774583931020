import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FileEntity, LaunchpointCoreClientMediaUploadBaseComponent, updateMedia } from '@launchpoint/core-client';
import { IMedia, IMediaCategory, IMediaParamsUpdate } from '@launchpoint/core-types';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'launchpoint-media-detail-modal',
  templateUrl: 'media-detail-modal.component.html',
})
export class LaunchpointCoreWebMediaDetailModalComponent extends LaunchpointCoreClientMediaUploadBaseComponent implements OnInit {
  createModal: NgbModalRef;

  activeModal = inject(NgbActiveModal);
  fb = inject(UntypedFormBuilder);
  _swal = inject(LaunchpointSweetAlertService);
  router = inject(Router);

  category: IMediaCategory;

  @Input() media: IMedia;

  public form: FormGroup<{
    title: FormControl<string>;
    description: FormControl<string>;
    active: FormControl<boolean>;
  }>;

  ngOnInit(): void {
    this.buildForm();
    super.ngOnInit();
  }

  buildForm() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      active: [true, Validators.required],
    });

    if (this.media) {
      const { is_archived, title, description } = this.media;
      this.form.setValue({
        title,
        description,
        active: !is_archived,
      });
    }
  }

  setFormCategory(category: IMediaCategory) {
    this.category = category;

    if (this.category?._id !== this.media.category._id) {
      this.form.markAsDirty();
    }
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    const form = this.form.value;

    const data: IMediaParamsUpdate = {
      _id: this.media._id,
      title: form.title,
      category: this.category?._id,
      description: form.description,
      is_archived: form.active ? false : true,
      poster: this.media.poster,
      social: this.media.social,
    };

    this.removeNullUndefined(data);
    this._Store.dispatch(updateMedia({ data }));
    this.close();
  }

  uploadOne(media: IMedia, file: File | File[]) {
    if (Array.isArray(file)) {
      file = file[0];
    }

    const fileEntity: FileEntity = {
      file: file,
      uploadPercentage: null,
      loading: false,
      error: null,
      category: media.category,
      description: media.description,
      media: media,
    };

    const res = this.uploadServiceV2(fileEntity, 0);

    return res;
  }

  deleteOne(media: IMedia) {
    this._swal
      .fireAlert({
        title: 'Are you sure you want to delete this media?',
        text: `This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      })
      .then((result) => {
        if (result.isConfirmed) {
          const url = this.router.url.split('/').slice(0, -1);

          this.deleteMedia({ _id: media._id }, url);
        }
      });
  }

  close() {
    this.activeModal.close();
  }
}
