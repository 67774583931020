import { Directive, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../interfaces/core-profile.interface';

@Directive()
export class LaunchpointCoreClientProfileHeaderCardComponentBaseComponent extends LaunchpointCoreClientBaseComponent {
  @Input() primary_header: string;
  @Input() secondary_header: string;

  constructor(public fb: FormBuilder, @Inject(PROFILE_CONFIG_TOKEN) public _config: LaunchpointCoreClientProfileConfig) {
    super();
  }
}
