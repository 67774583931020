import { Directive, OnInit, inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICoreCompanyParamsSignUp, ICoreUserAccount, IHttpException } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { COMPANY_ACTIONS } from '../../+state';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { CompanySignUpStateBaseComponent } from './base/company-sign-up-state.base-component';

@Directive()
export abstract class CompanySignUpBaseComponent extends CompanySignUpStateBaseComponent implements OnInit {
  form: FormGroup<ControlsOf<ICoreCompanyParamsSignUp>>;
  _Store = inject(Store);
  _router = inject(Router);

  steps = [
    {
      title: 'Complete Profile',
      description: 'Setup Your Account Details',
    },
    {
      title: 'Company Details',
      description: 'Setup Your Account Details',
    },
  ];

  ngOnInit() {
    this._stepper.init({ totalSteps: this.steps.length, params: true });
    this.form = this.fb.group({
      account_owner: [],
      company_name: ['', Validators.required],
      phone: [],
      account_type: [],
      email: [],
      year_established: [],
      industry: [],
      business_scale: [],
    });
    // this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => this.onSuccess(accounts));
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        this.onError(error);
      },
    });
  }

  onSuccess(accounts: ICoreUserAccount[]) {
    if (accounts?.length > 0) {
      this._router.navigate(['']);
    }
  }
  /**
   * subscripts to errors for toast
   */
  onError(error: IHttpException) {
    console.log('Override this onError, error, in the nativescript or web component');
  }
  // constructor() {}

  // ngOnInit(): void {}

  /**
   * This is because in order for the user to then update/interacte with the company they created, they need to have the account ID on their JWT
   * @param data
   */
  createClientSuccess(data: ICoreCompanyParamsSignUp) {
    this._Store.dispatch(COMPANY_ACTIONS.createCompanySignUp({ data }));
  }

  get currentStep$() {
    return this._stepper.currentStep$;
  }

  get totalSteps() {
    return this._stepper.totalSteps;
  }

  // nextStep() {
  //   this._stepper.nextStep();
  //   this._stepper.action.set({ action: 'saveAndNext' });
  // }

  submit() {
    console.log('Submitted');
  }
}
