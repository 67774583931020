import { Directive, Inject, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { adminAddAccountRoles, adminRemoveAccountRoles } from '../../../../../users/+state/actions/users.actions';
import { ICoreUserAccount } from '@launchpoint/core-types';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../../core-profile/interfaces/core-profile.interface';
import { ICoreUserSecurityRoleParamsUpdateNoUserID } from '../../../core-security-roles/components/profile-security-roles/profile-security-roles-base.component';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';

@Directive()
export class LaunchPointAccountsChangeSecurityRolesBaseSmartAdminComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  @Input() account: ICoreUserAccount;
  @Input() user_id: string;
  constructor(public _Store: Store, @Inject(PROFILE_CONFIG_TOKEN) public _LaunchpointCoreClientProfileConfig: LaunchpointCoreClientProfileConfig) {
    super(_Store);
  }

  async profileSecurityAdd(role: ICoreUserSecurityRoleParamsUpdateNoUserID, account_id: string) {
    // user_id // id of the user who has the accounts
    //account_id // id of the account to add the roles to user.accounts[index]._id
    this._Store.dispatch(adminAddAccountRoles({ data: { user_id: this.user_id, account_id, security_roles: role.security_roles } }));
  }
  async profileSecurityRemove(role: ICoreUserSecurityRoleParamsUpdateNoUserID, account_id: string) {
    // user_id // id of the user who has the accounts
    //account_id // id of the account to add the roles to user.accounts[index]._id
    this._Store.dispatch(adminRemoveAccountRoles({ data: { user_id: this.user_id, account_id, security_roles: role.security_roles } }));
  }
}
