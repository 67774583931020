import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientMediaCategoriesBaseComponent } from '@launchpoint/core-client';
import { IMediaCategory, IQuerySort } from '@launchpoint/core-types';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';
import { LaunchpointCoreWebMediaCategoriesModalComponent } from '../media-categories-modal/media-categories-modal.component';

@Component({
  selector: 'launchpoint-media-categories-table',
  templateUrl: './media-categories-table.component.html',
})
export class LaunchpointCoreWebMediaCategoriesTableComponent extends LaunchpointCoreClientMediaCategoriesBaseComponent {
  createModal: NgbModalRef;
  modalService = inject(NgbModal);
  _swal = inject(LaunchpointSweetAlertService);

  open(category?: IMediaCategory) {
    this.createModal = this.modalService.open(LaunchpointCoreWebMediaCategoriesModalComponent, { size: 'md' });

    if (category) {
      this.createModal.componentInstance.category = category;
    }
  }
}
