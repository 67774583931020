import { Component, inject, OnInit } from '@angular/core';
import { BaseComponent } from '@launchpoint/xplat/core';
import { LaunchpointSEORouterService } from '@launchpoint/xplat/web/features';
import { SEO_CONFIG } from '../../config/seo';
import { LaunchpointBlogService } from './data/blog.service';

@Component({
  selector: 'sd-blog',
  templateUrl: 'blog.component.html',
  styleUrls: ['blog.component.scss'],
})
export class BlogPageComponent extends BaseComponent implements OnInit {
  _LaunchpointSEORouterService = inject(LaunchpointSEORouterService);
  _LaunchpointBlogService = inject(LaunchpointBlogService);

  ngOnInit(): void {
    const blogSEO = SEO_CONFIG['blog'];
    this._LaunchpointSEORouterService.updateTitleAndMeta(blogSEO);
    this._LaunchpointBlogService.getBlogs();
    // this._LaunchpointBlogService.getFeatured();
  }
}
