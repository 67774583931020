import { Component, Inject } from '@angular/core';
import { APP_ERROR_CONFIG_TOKEN, IErrorModuleConfig } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
})
export class Error404Component {
  constructor(@Inject(APP_ERROR_CONFIG_TOKEN) public _config: IErrorModuleConfig) {}
}
