import { Component, inject, OnInit } from '@angular/core';
import { ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN, LaunchpointCoreClientWebhooksBaseComponent, updateAllWebhooksQuery } from '@launchpoint/core-client';
import { CORE_ACTIVITY_HISTORY_EVENT_TYPES, ICoreActivityHistoryWebhookSearchQuery, ICoreCompany } from '@launchpoint/core-types';
import { TagifyService, TagifySettings } from 'ngx-tagify';
import { BehaviorSubject } from 'rxjs';
import { LaunchpointFormControlDateRangeComponent } from '../../../../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';

@Component({
  selector: 'launchpoint-webhooks-filter',
  templateUrl: './webhooks-table-filter.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebWebhooksTableFilterComponent extends LaunchpointCoreClientWebhooksBaseComponent implements OnInit {
  _config = inject(ACTIVITY_HISTORY_CLIENT_CONFIG_TOKEN);
  tagifyService = inject(TagifyService);
  _LaunchpointFormControlDateRangeComponent = inject(LaunchpointFormControlDateRangeComponent);

  active = 1;
  settings: TagifySettings = {
    placeholder: 'Start typing...',
    blacklist: ['fucking', 'shit'],
    dropdown: {
      maxItems: 20,
      classname: 'tagify__inline__suggestions',
      enabled: 0,
      closeOnSelect: false,
    },
    enforceWhitelist: false,
    keepInvalidTags: false,
  };

  whitelistEvents$ = new BehaviorSubject<string[]>(null);
  whitelistModes$ = new BehaviorSubject<string[]>(['test', 'live']);

  getTypesLength() {
    return this.form?.controls?.events?.value?.length;
  }
  clearTypes() {
    this.form?.controls?.events?.setValue([]);
  }
  getModesLength() {
    return this.form?.controls?.url?.value?.length;
  }
  clearModes() {
    this.form?.controls?.url?.setValue([]);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.whitelistEvents$ = new BehaviorSubject<string[]>(Object.values(this._config?.events ?? CORE_ACTIVITY_HISTORY_EVENT_TYPES));
    this.settings.enforceWhitelist = true;
  }

  setAccount(event: ICoreCompany[]) {
    this.accounts = event;
  }
  clearAccounts() {
    this.accounts = [];
    this.tagifyService.get('accounts')?.removeAllTags();
  }

  apply() {
    const form = this.form.value;
    const query: ICoreActivityHistoryWebhookSearchQuery = {
      ...form,
      url: form.url?.map((f) => f?.value) ?? [],
      events: form.events?.map((f) => f?.value) ?? [],
    } as ICoreActivityHistoryWebhookSearchQuery;

    if (this.accounts?.length) {
      query.account_ids = this.accounts.map((a) => a._id);
    }

    this._Store.dispatch(updateAllWebhooksQuery({ query }));
  }

  clearAll() {
    this.form.reset();

    if (this.admin) {
      this.tagifyService.get('accounts')?.removeAllTags();
    }

    this.tagifyService.get('events')?.removeAllTags();
    this.tagifyService.get('url')?.removeAllTags();
    this._LaunchpointFormControlDateRangeComponent.resetDates();

    // console.log('this.query', this.query);

    this._Store.dispatch(
      updateAllWebhooksQuery({
        query: this.query,
      })
    );
  }
}
