import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  IMediaCategory,
  IMediaCategoryController,
  IMediaCategoryParamsCreate,
  IMediaCategoryParamsUpdate,
  IMediaCategorySearchPayload,
  IMediaCategorySearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig } from '../../media/interface/media-service.interface';

@Injectable()
export class LaunchpointMediaCategoriesService extends LaunchpointSecurityUserIdHeaderService implements IMediaCategoryController {
  API_URL = 'api/media/';
  constructor(
    public _Store: Store,
    private http: HttpClient,
    @Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig
  ) {
    super(_Store);

    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.environment?.mediaApiUrl,
      route: 'media-categories',
    });
  }

  search(search: IMediaCategorySearchPayload): Observable<IMediaCategorySearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMediaCategorySearchResults>(this.API_URL + `/search`, search, { headers });
      })
    );
  }

  getById(id: string): Observable<IMediaCategory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<IMediaCategory>(this.API_URL + `/${id}`, { headers });
      })
    );
  }

  create(data: IMediaCategoryParamsCreate): Observable<IMediaCategory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMediaCategory>(this.API_URL, data, { headers });
      })
    );
  }

  update(data: IMediaCategoryParamsUpdate): Observable<IMediaCategory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IMediaCategory>(this.API_URL, data, { headers });
      })
    );
  }

  delete(id: string): Observable<IMediaCategory> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.delete<IMediaCategory>(this.API_URL + `/${id}`, { headers });
      })
    );
  }
}
