import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'launchpoint-app-dropdown-menu3',
  templateUrl: './dropdown-menu3.component.html',
})
export class DropdownMenu3Component {
  @HostBinding('class') class =
    'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3';
  @HostBinding('attr.data-kt-menu') dataKtMenu = 'true';

  // constructor() {}

  // ngOnInit(): void {}
}
