import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  configureURL,
  IMedia,
  IMediaController,
  IMediaMetadataACLParamsUpdate,
  IMediaParamsCreate,
  IMediaParamsDelete,
  IMediaParamsUpdate,
  IMediaParamsUpdateMetadata,
  IMediaSearchPayload,
  IMediaSearchResults,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { LaunchpointSecurityUserIdHeaderService } from '../../../security/header-services/user-id-security-header.service';
import { APP_MEDIA_CONFIG_TOKEN, ICoreMediaConfig } from '../interface/media-service.interface';

@Injectable()
export class LaunchpointMediaBaseHTTPService extends LaunchpointSecurityUserIdHeaderService implements IMediaController {
  sources: EventSource[] = [];
  API_URL = 'api/media/';
  url: string;

  constructor(
    public _Store: Store,
    public http: HttpClient,
    @Inject(APP_MEDIA_CONFIG_TOKEN) public _config: ICoreMediaConfig
  ) {
    super(_Store);
    this.url = `${location.origin}/${_config.environment.mediaApiUrl}content/${_config?.route}/`;
    this.API_URL = configureURL({
      baseUrl: this.API_URL,
      configUrl: _config?.environment?.mediaApiUrl,
      route: 'media',
    });
  }

  getById(id: string): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.get<IMedia>(this.API_URL + '/' + id, { headers });
      })
    );
  }

  search(search: IMediaSearchPayload): Observable<IMediaSearchResults> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMediaSearchResults>(this.API_URL + '/search', search, { headers });
      })
    );
  }

  create(data: IMediaParamsCreate): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.post<IMedia>(this.API_URL, data, { headers });
      })
    );
  }

  putACL(data: IMediaMetadataACLParamsUpdate): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IMedia>(this.API_URL + '/acl', data, { headers });
      })
    );
  }

  metadata(data: IMediaParamsUpdateMetadata): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IMedia>(this.API_URL + '/metadata', data, { headers });
      })
    );
  }

  update(asset: IMediaParamsUpdate): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IMedia>(this.API_URL, asset, { headers });
      })
    );
  }

  delete(data: IMediaParamsDelete): Observable<IMedia> {
    return this.getSelectedUserIdHeaders.pipe(
      switchMap((headers) => {
        return this.http.put<IMedia>(this.API_URL + '/delete', data, { headers });
      })
    );
  }
}
