import {
  IHttpException,
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormParamsArchive,
  ILaunchpointDynamicFormParamsArchiveBulk,
  ILaunchpointDynamicFormParamsCreate,
  ILaunchpointDynamicFormParamsDelete,
  ILaunchpointDynamicFormParamsUpdate,
  ILaunchpointDynamicFormParamsUpdateBulk,
  ILaunchpointDynamicFormSearchQuery,
  ILaunchpointDynamicFormSearchResults,
  IQueryArchiveBulkResult,
  IQueryDeleteBulkResult,
  IQueryPageination,
  IQuerySort,
  IQueryUpdateBulkResult,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// Search
export const searchAllLaunchpointDynamicForm = createAction('[f-LaunchpointDynamicForm] searchAllLaunchpointDynamicForm');
export const searchAllLaunchpointDynamicFormSuccess = createAction(
  '[f-LaunchpointDynamicForm] searchAllLaunchpointDynamicFormSuccess',
  props<{ data: ILaunchpointDynamicFormSearchResults; success_message?: string }>()
);

export const searchAllLaunchpointDynamicFormFailure = createAction(
  '[f-LaunchpointDynamicForm] searchAllLaunchpointDynamicFormFailure',
  props<{ error: IHttpException }>()
);

// Mechanics
export const updateAllLaunchpointDynamicFormPagination = createAction(
  '[f-LaunchpointDynamicForm] updateAllLaunchpointDynamicFormPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllLaunchpointDynamicFormPaginationScroll = createAction(
  '[f-LaunchpointDynamicForm] updateAllLaunchpointDynamicFormPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllLaunchpointDynamicFormSorting = createAction(
  '[f-LaunchpointDynamicForm] updateAllLaunchpointDynamicFormSorting',
  props<{ querySort: IQuerySort }>()
);
export const updateAllLaunchpointDynamicFormQuery = createAction(
  '[f-LaunchpointDynamicForm] updateAllLaunchpointDynamicFormQuery',
  props<{ query: ILaunchpointDynamicFormSearchQuery }>()
);

// getById
export const getLaunchpointDynamicFormById = createAction('[f-LaunchpointDynamicForm] getLaunchpointDynamicFormById', props<{ data_id: string }>());
export const getLaunchpointDynamicFormByIdSuccess = createAction(
  '[f-LaunchpointDynamicForm] getLaunchpointDynamicFormByIdSuccess',
  props<{ data: ILaunchpointDynamicForm; success_message?: string }>()
);
export const getLaunchpointDynamicFormByIdFailure = createAction(
  '[f-LaunchpointDynamicForm] getLaunchpointDynamicFormByIdFailure',
  props<{ data_id: string; error: IHttpException }>()
);

export const getLaunchpointDynamicFormByIdSetId = createAction(
  '[f-LaunchpointDynamicForm] getLaunchpointDynamicFormByIdSetId',
  props<{ data_id: string }>()
);

// CREATE
export const createLaunchpointDynamicForm = createAction(
  '[f-LaunchpointDynamicForm] createLaunchpointDynamicForm',
  props<{ data: ILaunchpointDynamicFormParamsCreate }>()
);
export const createLaunchpointDynamicFormSuccess = createAction(
  '[f-LaunchpointDynamicForm] createLaunchpointDynamicFormSuccess',
  props<{ data: ILaunchpointDynamicForm; success_message?: string }>()
);
export const createLaunchpointDynamicFormFailure = createAction(
  '[f-LaunchpointDynamicForm] createLaunchpointDynamicFormFailure',
  props<{ error: IHttpException }>()
);

// UPDATE
export const updateLaunchpointDynamicForm = createAction(
  '[f-LaunchpointDynamicForm] updateLaunchpointDynamicForm',
  props<{ data: ILaunchpointDynamicFormParamsUpdate }>()
);
export const updateLaunchpointDynamicFormSuccess = createAction(
  '[f-LaunchpointDynamicForm] updateLaunchpointDynamicFormSuccess',
  props<{ data: ILaunchpointDynamicForm; success_message?: string }>()
);
export const updateLaunchpointDynamicFormFailure = createAction(
  '[f-LaunchpointDynamicForm] updateLaunchpointDynamicFormFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// UPDATE BULK
export const updateLaunchpointDynamicFormBulk = createAction(
  '[f-LaunchpointDynamicFormBulk] updateLaunchpointDynamicFormBulk',
  props<{ data: ILaunchpointDynamicFormParamsUpdateBulk }>()
);
export const updateLaunchpointDynamicFormSuccessBulk = createAction(
  '[f-LaunchpointDynamicForm] updateLaunchpointDynamicFormSuccessBulk',
  props<{ data: IQueryUpdateBulkResult; success_message?: string }>()
);
export const updateLaunchpointDynamicFormFailureBulk = createAction(
  '[f-LaunchpointDynamicForm] updateLaunchpointDynamicFormFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

// ARCHIVE
export const archiveLaunchpointDynamicForm = createAction(
  '[f-LaunchpointDynamicForm] archiveLaunchpointDynamicForm',
  props<{ data: ILaunchpointDynamicFormParamsArchive }>()
);
export const archiveLaunchpointDynamicFormSuccess = createAction(
  '[f-LaunchpointDynamicForm] archiveLaunchpointDynamicFormSuccess',
  props<{ data: IQueryArchiveBulkResult; success_message?: string }>()
);
export const archiveLaunchpointDynamicFormFailure = createAction(
  '[f-LaunchpointDynamicForm] archiveLaunchpointDynamicFormFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// ARCHIVE BULK
export const archiveLaunchpointDynamicFormBulk = createAction(
  '[f-LaunchpointDynamicFormBulk] archiveLaunchpointDynamicFormBulk',
  props<{ data: ILaunchpointDynamicFormParamsArchiveBulk }>()
);
export const archiveLaunchpointDynamicFormSuccessBulk = createAction(
  '[f-LaunchpointDynamicForm] archiveLaunchpointDynamicFormSuccessBulk',
  props<{ data: IQueryArchiveBulkResult; success_message?: string }>()
);
export const archiveLaunchpointDynamicFormFailureBulk = createAction(
  '[f-LaunchpointDynamicForm] archiveLaunchpointDynamicFormFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

// DELETE
export const deleteLaunchpointDynamicForm = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicForm',
  props<{ data: ILaunchpointDynamicFormParamsDelete }>()
);
export const deleteLaunchpointDynamicFormSuccess = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicFormSuccess',
  props<{ data: ILaunchpointDynamicForm; success_message?: string }>()
);
export const deleteLaunchpointDynamicFormFailure = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicFormFailure',
  props<{ data_id: string; error: IHttpException }>()
);

// DELETE BULK
export const deleteLaunchpointDynamicFormBulk = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicFormBulk',
  props<{ data: ILaunchpointDynamicFormParamsDelete }>()
);
export const deleteLaunchpointDynamicFormSuccessBulk = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicFormSuccessBulk',
  props<{ data: IQueryDeleteBulkResult; success_message?: string }>()
);
export const deleteLaunchpointDynamicFormFailureBulk = createAction(
  '[f-LaunchpointDynamicForm] deleteLaunchpointDynamicFormFailureBulk',
  props<{ data_id: string; error: IHttpException }>()
);

/**
 * CLOSE ALERT MESSAGES
 */
export const closeLaunchpointDynamicFormSuccessMessage = createAction('[f-LaunchpointDynamicForm] closeLaunchpointDynamicFormSuccessMessage');

export const closeLaunchpointDynamicFormErrorMessage = createAction('[f-LaunchpointDynamicForm] closeLaunchpointDynamicFormErrorMessage');
