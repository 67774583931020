<div>
  <!-- HERO -->
  <div class="bg-background relative flex justify-center pb-10 pt-24 text-white">
    <div class="w-[1200px] max-w-full px-6 pt-20 text-white">
      <div class="mb-20 flex flex-col items-center gap-4">
        <div class="flex w-full flex-col items-center justify-center md:max-w-[50%]">
          <h1 class="text-primary font-oswald text-center text-3xl font-medium md:text-[45px]/[50px]">LESSONS</h1>
          <h2 class="my-4 text-center">Gain the knowledge you need—your first step starts here</h2>
        </div>

        <sd-button text="Join Now" [routerLink]="['/get-started']" class="hidden md:block" />
        <sd-button text="Join Now" [routerLink]="['/get-started']" class="md:hidden" size="small" />
      </div>

      <div class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
        @for (card of cards; track card; let i = $index) {
          <div
            class="flex min-w-[250px] max-w-[300px] flex-1 flex-col p-8"
            [ngClass]="{
              'border-b md:border-b-0 md:border-e': i !== cards?.length - 1,
            }"
          >
            <div class="text-primary font-oswald text-3xl">{{ card?.title }}</div>
            <div class="font-light text-white">{{ card?.description }}</div>
          </div>
        }
      </div>

      <!-- FREE -->
      <div class="my-20">
        <div class="font-oswald mb-10 text-center text-3xl uppercase md:text-left">Free</div>

        <div class="no-scrollbar flex flex-col items-center gap-10 md:flex-row md:overflow-x-scroll">
          @for (card of essentials; track card.title) {
            <sd-training-card [card]="card" />
          }
        </div>
      </div>
    </div>
  </div>

  <!-- PAID -->
  <div class="bg-medium relative flex justify-center text-black">
    <div class="w-[1200px] max-w-full px-6 text-black">
      <div class="my-20">
        <div class="font-oswald mb-10 text-center text-3xl uppercase md:text-left">Paid</div>

        <div class="no-scrollbar flex flex-col items-center gap-10 md:flex-row md:overflow-x-scroll">
          @for (card of essentials; track card.title) {
            <sd-training-card [card]="card" type="inverted" />
          }
        </div>
      </div>
    </div>
  </div>

  <!-- WHAT THEY SAY -->
  <sd-success-stories />
</div>
