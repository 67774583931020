<div class="lauchpoint-launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">


    <launchpoint-toolbar [options]="options" (create)="create()">
      <launchpoint-notifications-messages-filter />
    </launchpoint-toolbar>

    <launchpoint-notifications-messages-table />


  </div>
</div>