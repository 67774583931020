import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { layoutSelectors } from '../../../+state';
import { AppBaseComponent } from '../../../../../app-base.component';
import { MenuComponent } from '../../../../core/kt/components';
import { LayoutService } from '../../../../core/layout.service';
import { LaunchpointLayoutMenu } from '../../../../../interfaces/menu.interfaces';
import { LaunchpointTopbarConfig } from '../../../../../interfaces/topbar.interface';

@Component({
  selector: 'launchpoint-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppLaunchpointBaseHeaderComponent extends AppBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  headerContainerCssClasses = '';
  asideDisplay = true;
  headerLeft = 'menu';
  pageTitleCssClasses = '';
  pageTitleAttributes: {
    [attrName: string]: string | boolean;
  };
  menuConfig$: Observable<LaunchpointLayoutMenu>;
  @Input() user: ICoreUser;
  @Input() circle_background_class: string;
  topBarConfig$: Observable<LaunchpointTopbarConfig>;
  @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;

  // private unsubscribe: Subscription[] = [];

  constructor(private layout: LayoutService, private router: Router, private _Store: Store) {
    super();
    this.routingChanges();
    this.menuConfig$ = this._Store.select(layoutSelectors.getHeaderMenu);
    this.topBarConfig$ = this._Store.select(layoutSelectors.getTopbarConfig);
  }

  ngOnInit(): void {
    this.headerContainerCssClasses = this.layout.getStringCSSClasses('headerContainer');
    this.asideDisplay = this.layout.getProp('aside.display') as boolean;
    this.headerLeft = this.layout.getProp('header.left') as string;
    this.pageTitleCssClasses = this.layout.getStringCSSClasses('pageTitle');
    this.pageTitleAttributes = this.layout.getHTMLAttributes('pageTitle');
  }

  ngAfterViewInit() {
    if (this.ktPageTitle) {
      for (const key in this.pageTitleAttributes) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.pageTitleAttributes.hasOwnProperty(key)) {
          this.ktPageTitle.nativeElement.attributes[key] = this.pageTitleAttributes[key];
        }
      }
    }
  }

  routingChanges() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        MenuComponent.reinitialization();
      }
    });
  }
}
