import {
  IHttpException,
  INotification,
  INotificationParamsCreate,
  INotificationParamsUpdate,
  INotificationSearchQuery,
  INotificationSearchResults,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';

// getById
export const getNotificationById = createAction('[LP-Notifications-Notifications] getNotificationById', props<{ notification_id: string }>());
export const getNotificationByIdSuccess = createAction(
  '[LP-Notifications-Notifications] getNotificationsByIdSuccess',
  props<{ notification: INotification; success_message?: string }>()
);
export const getNotificationByIdFailure = createAction(
  '[LP-Notifications-Notifications] getNotificationsByIdFailure',
  props<{ notification_id: string; error: IHttpException }>()
);

// Search
export const searchAllNotifications = createAction('[LP-Notifications-Notifications] searchAllNotifications');
export const searchAllNotificationsSuccess = createAction(
  '[LP-Notifications-Notifications] searchAllNotificationsSuccess',
  props<{ data: INotificationSearchResults; success_message?: string }>()
);
export const searchAllNotificationsFailure = createAction(
  '[LP-Notifications-Notifications] searchAllNotificationsFailure',
  props<{ error: IHttpException }>()
);

// CREATE
export const createNotification = createAction(
  '[LP-Notifications-Notifications] createNotification',
  props<{ data: INotificationParamsCreate; redirectPath?: string[] }>()
);

export const createNotificationSuccess = createAction(
  '[LP-Notifications-Notifications] createNotificationSuccess',
  props<{ data: INotification; redirectPath?: string[]; success_message?: string }>()
);
export const createNotificationFailure = createAction(
  '[LP-Notifications-Notifications] createNotificationsFailure',
  props<{ error: IHttpException }>()
);

// UPDATE
export const updateNotification = createAction('[LP-Notifications-Notifications] updateNotification', props<{ data: INotificationParamsUpdate }>());
export const updateNotificationSuccess = createAction(
  '[LP-Payments-Notifications] updateNotificationSuccess',
  props<{ notification: INotification; success_message?: string }>()
);
export const updateNotificationFailure = createAction(
  '[LP-Payments-Notifications] updateNotificationFailure',
  props<{ notification_id: string; error: IHttpException }>()
);

// DELETE
export const deleteNotification = createAction('[LP-Notifications-Notifications] deleteNotification', props<{ id: string }>());
export const deleteNotificationSuccess = createAction(
  '[LP-Notifications-Notifications] deleteNotificationSuccess',
  props<{ data: INotification; success_message?: string }>()
);
export const deleteNotificationFailure = createAction(
  '[LP-Notifications-Notifications] deleteNotificationFailure',
  props<{ notification_id: string; error: IHttpException }>()
);

// Mechanics
export const updateAllNotificationsPagination = createAction(
  '[LP-Notifications-Notifications] updateAllNotificationsPagination',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllNotificationsPaginationScroll = createAction(
  '[LP-Notifications-Notifications] updateAllNotificationsPaginationScroll',
  props<{ pagination: IQueryPageination }>()
);
export const updateAllNotificationsSorting = createAction(
  '[LP-Notifications-Notifications] updateAllNotificationsSorting',
  props<{ querySort: IQuerySort }>()
);
export const updateAllNotificationsQuery = createAction(
  '[LP-Notifications-Notifications] updateAllNotificationsQuery',
  props<{ query: INotificationSearchQuery }>()
);
