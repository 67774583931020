import { Directive, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EUserProfileGender, EUserProfileMaritalStatus, ICoreUserProfile, ICoreUserProfileParamsUpdate } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../../../components/base/base.component';
import { UserSelectors } from '../../../../auth';
import { LaunchpointCoreClientProfileConfig, PROFILE_CONFIG_TOKEN } from '../../interfaces/core-profile.interface';

type ICoreUserProfileParamsUpdateNoUserID = Omit<ICoreUserProfileParamsUpdate, 'user_id'>;

// type ICoreUserProfileParamsUpdateNoUserID = Omit<ICoreUserProfileParamsUpdate, 'user_id'>;

@Directive()
export class LaunchpointCoreClientProfileDetailsBaseComponent extends LaunchpointCoreClientBaseComponent implements OnInit, OnChanges {
  //todo KASEY: COURTNEY: did any to get the card to show up, todo figure out how to get form typeset to work
  // public form: FormGroup<ControlsOf<ICoreUserProfileParamsUpdateNoUserID>>;

  public form: FormGroup<any>;
  @Input() user_profile: ICoreUserProfile;
  @Input() isLoading = false;
  @Input() genderOptions: EUserProfileGender[] | string[] = Object.values(EUserProfileGender);
  @Input() maritalStatusOptions: EUserProfileMaritalStatus[] | string[] = Object.values(EUserProfileMaritalStatus);

  @Input() showSeparators = true;

  @Output() profileChanges = new EventEmitter<ICoreUserProfileParamsUpdateNoUserID>();

  loading$: Observable<boolean>;
  loading = false;
  maxDate = { year: new Date().getUTCFullYear() - 16, month: 12, day: 31 };
  minDate = { year: new Date().getUTCFullYear() - 100, month: 12, day: 31 };
  constructor(
    public fb: FormBuilder,
    @Inject(PROFILE_CONFIG_TOKEN) public _config: LaunchpointCoreClientProfileConfig,
    public _Store: Store
  ) {
    super();
    this.loading$ = this._Store.select(UserSelectors.selectUserUpdateAddressLoading);
  }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.user_profile.previousValue != changes.user_profile.currentValue) {
      this.initForm();
    }
  }
  initForm() {
    this.form = this.fb.group({
      first_name: [''],
      last_name: [''],
      profile_photo_url: [''],
      gender: [''],
      marital_status: [EUserProfileMaritalStatus.SINGLE],
      birthday: [''],
    });
    if (this.user_profile) {
      this.form.patchValue({
        first_name: this.user_profile?.first_name ?? null,
        last_name: this.user_profile?.last_name ?? null,
        profile_photo_url: this.user_profile.profile_photo_url ?? null,
        gender: this.user_profile.gender ?? null,
        marital_status: this.user_profile.marital_status ?? null,
      });

      const dateified = new Date(this.user_profile.birthday);

      this.form.controls.birthday.patchValue({
        day: dateified.getUTCDate(),
        month: dateified.getUTCMonth() + 1,
        year: dateified.getUTCFullYear(),
      });
      this.form.updateValueAndValidity();
    }
  }

  saveSettings() {
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((loading) => {
      if (loading) {
        this.loading = loading;
      } else {
        this.loading = false;
      }
    });
    if (this.form.invalid) {
      return;
    } else {
      const profile_data: ICoreUserProfileParamsUpdateNoUserID = {
        first_name: this.form.controls.first_name.value,
        last_name: this.form.controls.last_name.value,
        gender: this.form.controls.gender.value,
        marital_status: this.form.controls.marital_status.value,
        birthday: this.determineBirthday(this.form.controls.birthday.value),
        profile_photo_url: this.form.controls.profile_photo_url.value,
      };
      this.loading = false;
      return this.profileChanges.emit(profile_data);
    }
  }

  determineBirthday(birthday_form_value): Date {
    // web and mobile handle differently for web date picker
    return;
  }

  // clear the form and reset to the original values
  resetForm() {
    // this.form.reset();
    // reset to original values
    this.initForm();
  }
}
