import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  /**
   * Check matching password with confirm password
   * @param control AbstractControl
   */
  static MatchPassword(control: AbstractControl): void {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('cPassword')?.value;

    if (password !== confirmPassword) {
      control.get('cPassword')?.setErrors({ ConfirmPassword: true });
    }
  }

  static MatchUpdatePassword(control: AbstractControl): void {
    const password = control.get('new_password').value;
    const confirmPassword = control.get('confirm_new_password').value;

    if (password !== confirmPassword) {
      control.get('confirm_new_password').setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }
}
