import { Directive, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICoreUserParamsCreateV3, ICoreUserParamsLoginPasswordV3 } from '@launchpoint/core-types';
import { UserAuthActions } from '../+state';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../+state/auth-base.component';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { ILaunchpointAuth } from '../interfaces/auth.interface';
import { LaunchpointCoreClientAuthFormService } from '../services/auth-form-service';

@Directive()
export abstract class LaunchpointCoreClientAuthLoginBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  form: FormGroup<ControlsOf<ILaunchpointAuth>>;
  returnUrl = ['/'];
  confirmEmail = false;

  _LaunchpointCoreClientAuthFormService = inject(LaunchpointCoreClientAuthFormService);
  _route = inject(ActivatedRoute);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.form = this._LaunchpointCoreClientAuthFormService.setLoginForm;
    // this.returnUrl = this._route.snapshot.queryParams['returnUrl'.toString()]?.split('/').filter((e) => e) || ['/'];
    this._route.queryParams.subscribe({
      next: (data) => {
        // console.log('queryParams', data);
        this.returnUrl = data.returnUrl?.split('/')?.filter((e) => e) || ['/'];
        // console.log('returnUrl', this.returnUrl);
        if (data?.email) {
          /**
           * This ia a common problem. The + character is used by the URL to separate two words.
           *
           * Here I'm checking for spaces in the email  and putting the + back.
           *
           * I'm hoping other url safe will just work.
           */
          let email = data.email;
          if (data.email.includes(' ')) {
            email = data.email.replace(' ', '+');
          }
          this.form.controls.email.setValue(email);
          this.confirmEmail = true;
          // console.log(this.form.controls.email);
        }
      },
    });
  }
  async usernamePassword() {
    if (this.form.controls.email.invalid) {
      return;
    }
    // check there is an @
    const isEmail = this.form.value.email.match(/@/);

    if (!isEmail && this.formatPhone(this.form.value.email).length < 10) {
      this._Store.dispatch(
        UserAuthActions.userCreateWarning({
          error: { message: 'Please enter a valid phone number.', error: 'Phone form control is invalid' },
        })
      );
      return;
    }

    const data: ICoreUserParamsLoginPasswordV3 = {
      password: this.form.value.password,
      ...(isEmail ? { email: this.form.value.email } : { phone: +this.formatPhone(this.form.value.email) }),
    };

    // console.log('DISPATCH LOGIN', { data, returnUrl: this.returnUrl });
    this._Store.dispatch(UserAuthActions.login({ data, returnUrl: this.returnUrl }));
  }

  async sendLoginCode(reset = false) {
    // email
    if (this.form.controls.email.invalid) {
      this._Store.dispatch(
        UserAuthActions.userCreateWarning({
          error: {
            message: `Please enter a valid email${this?._config?.registration?.allow_phone ? ' or phone number' : ''} to send a login code.`,
            error: 'Email form control is invalid',
          },
        })
      );
      return;
    }

    const isEmail = this.form.value.email.match(/@/);

    if (this._config.registration.allow_phone) {
      if (!isEmail && this.formatPhone(this.form.value.email).length < 10) {
        this._Store.dispatch(
          UserAuthActions.userCreateWarning({
            error: { message: 'Please enter a valid phone number.', error: 'Phone form control is invalid' },
          })
        );
        return;
      }
    }

    const data: ICoreUserParamsCreateV3 = {
      admin: {
        electronic_consent: this.form.value.agreement,
        electronic_consent_ts: new Date(),
      },
      ...(isEmail ? { email: this.form.value.email } : { phone: +this.formatPhone(this.form.value.email) }),
      ...(reset ? { reset_password_force: true } : {}),
    };

    // console.log('ON CLICK', { reset });

    this._Store.dispatch(
      UserAuthActions.userCreate({
        data,
        reset,
        path: ['/auth', 'verify'],
      })
    );
  }
}
