import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { RouterModule } from '@angular/router';
import { SHARED_LAYOUT_COMPONENTS } from './components/index';

@NgModule({
  declarations: [...SHARED_LAYOUT_COMPONENTS],
  imports: [CommonModule, RouterModule, InlineSVGModule],
  exports: [...SHARED_LAYOUT_COMPONENTS],
})
export class LaunchpointDemoSharedLayoutModule {}
