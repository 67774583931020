import { Component, inject, OnInit } from '@angular/core';
import { deleteTopic, LaunchpointCoreClientNotificationsTopicModalBaseComponent } from '@launchpoint/core-client';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-notifications-topics-modal',
  templateUrl: './notifications-topics-modal.component.html',
  styleUrls: ['./notifications-topics-modal.component.scss'],
})
export class LaunchpointCoreWebNotificationTopicModalComponent extends LaunchpointCoreClientNotificationsTopicModalBaseComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  _swal = inject(LaunchpointSweetAlertService);

  override close() {
    this.activeModal.close();
  }

  remove() {
    this._swal
      .fireAlert({
        icon: 'warning',
        title: 'Are you sure you want to delete this topic?',
        text: "You won't be able to revert this!",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this._Store.dispatch(deleteTopic({ id: this.topic._id }));
          this.close();
        }
      });
  }
}
