/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { APP_AUTH_CONFIG_TOKEN, ApplicationServiceConfig } from '@launchpoint/core-client';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { getAsideMenu } from '../../../+state/selectors/menu.selectors';
import { AppBaseComponent } from '../../../../../app-base.component';
import { DrawerComponent, MenuComponent, ScrollComponent, ToggleComponent } from '../../../../core/kt/components';
import { LayoutService } from '../../../../core/layout.service';
import { LaunchpointLayoutMenu } from '../../../../../interfaces/menu.interfaces';

@Component({
  selector: 'launchpoint-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AppLaunchpointBaseAsideComponent extends AppBaseComponent implements OnInit, OnDestroy {
  asideTheme = '';
  asideMinimize: boolean = false;
  asideMenuCSSClasses = '';
  @Input() user: ICoreUser;
  menuConfig$: Observable<LaunchpointLayoutMenu>;
  @ViewChild('ktAsideScroll', { static: true }) ktAsideScroll: ElementRef;

  constructor(
    private layout: LayoutService,
    private _Store: Store,
    private router: Router,
    @Inject(APP_AUTH_CONFIG_TOKEN) public layoutConfig: ApplicationServiceConfig
  ) {
    super();
    this.menuConfig$ = this._Store.select(getAsideMenu);
  }

  ngOnInit(): void {
    this.asideTheme = this.layout.getProp('aside.theme') as string;
    this.asideMinimize = this.layout.getProp('aside.minimize') as boolean;
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('asideMenu');
    this.routingChanges();
  }

  routingChanges() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.menuReinitialization();
      }
    });
  }

  minimize() {
    const asideRef = document.getElementById('kt_aside');
    asideRef?.classList.add('animating');

    setTimeout(() => {
      asideRef?.classList.remove('animating');
    }, 300);
  }

  menuReinitialization() {
    setTimeout(() => {
      MenuComponent.reinitialization();
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
      ScrollComponent.reinitialization();
      if (this.ktAsideScroll && this.ktAsideScroll.nativeElement) {
        this.ktAsideScroll.nativeElement.scrollTop = 0;
      }
    }, 50);
  }
}
