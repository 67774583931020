import { Component } from '@angular/core';
import { LaunchpointCoreClientProfileDetailsSmartUserBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-core-web-profile-details-smart-user',
  templateUrl: './profile-details-smart-user.component.html',
})
export class LaunchpointCoreWebProfileDetailsSmartUserComponent extends LaunchpointCoreClientProfileDetailsSmartUserBaseComponent {
  // constructor(
  //   @Inject(PROFILE_CONFIG_TOKEN) public _profileConfig: LaunchpointCoreClientProfileConfig,
  //   @Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig,
  //   public _Store: Store
  // ) {
  //   super(_profileConfig, _config, _Store);
  // }
}
