import { Directive, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LaunchpointFormControlCheckBoxesBaseComponent } from '../../../forms/from-control/form-control-checkboxes-base.component';

@Directive({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserFormControlSecurityRolesBaseComponent),
      multi: true,
    },
  ],
})
export abstract class UserFormControlSecurityRolesBaseComponent
  extends LaunchpointFormControlCheckBoxesBaseComponent
  implements ControlValueAccessor
{
  /**
   * The selected checkboxes.
   */
  @Input() selected: string[] = [];
  /**
   * The list of checkbox items.
   *
   * As an input it also allows the values to be writted over by to parent component
   */
  @Input() allOptions: string[];

  /**
   * This is not required for the form control BUT it does give the parent access to more data from the selected object if needed.
   * Pattern is to add `Changed` to the end of the value
   */
  @Output() selectedChanged = new EventEmitter<string[]>();

  constructor() {
    super();
  }

  checkIfIncluded(stage: string): boolean {
    return this.selected.includes(stage);
  }

  writeValue(value: string) {
    // console.log('selector:::::writeValue', value);
    if (value) {
      this.selected.push(value);
    }
  }

  adjustValue(isChecked: boolean, value: string) {
    if (isChecked) {
      this.selected.push(value);
    } else {
      this.selected.splice(this.selected.indexOf(value), 1);
    }
    this.onChanged(this.selected);
    this.onTouched();
    this.selectedChanged.emit(this.selected);
  }
}
