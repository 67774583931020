import { on } from '@ngrx/store';
import * as UserProfileSwapActions from '../actions/user/profile-swap/profile-swap.actions';
import { StateUserReducer } from './user.reducer';

export const USER_PROFILE_SWAP_REDUCERS = [
  // * Profile Swap * //
  on(UserProfileSwapActions.swapAccount, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.swapAccountSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  on(UserProfileSwapActions.swapAccountFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      // action: , // is this correct?
      error: error,
    },
    success: null,
  })),
  on(UserProfileSwapActions.deselectAccount, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.deselectAccountSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  on(UserProfileSwapActions.deselectAccountFailure, (state: StateUserReducer, { error }) => ({
    ...state,
    loading: false,
    error: {
      error: error,
    },
    success: null,
  })),
  // get account invitations
  on(UserProfileSwapActions.getAccountInvitations, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.getAccountInvitationsSuccess, (state: StateUserReducer, { pending_invitations, success_message }) => ({
    ...state,
    pending_invitations,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  // accept invitation
  on(UserProfileSwapActions.acceptInvitation, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.acceptInvitationSuccess, (state: StateUserReducer, { message, success_message }) => ({
    ...state,
    message,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  // resend invitation
  on(UserProfileSwapActions.resendInvite, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.resendInviteSuccess, (state: StateUserReducer, { message, success_message }) => ({
    ...state,
    message,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  // add sub user
  on(UserProfileSwapActions.addSubUserAccount, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.addSubUserAccountSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  // add main user
  on(UserProfileSwapActions.addMainUserAccount, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.addMainUserAccountSuccess, (state: StateUserReducer, { user, success_message }) => ({
    ...state,
    user,
    loaded: true,
    userLoading: false,
    userError: null,
    success_message: success_message ?? null,
  })),
  // remove account
  on(UserProfileSwapActions.removeAccount, (state: StateUserReducer) => ({
    ...state,
    userLoading: true,
    userError: null,
    success_message: null,
  })),
  on(UserProfileSwapActions.removeAccountSuccess, (state: StateUserReducer, { user, success_message }) => {
    return {
      ...state,
      user,
      loaded: true,
      userLoading: false,
      userError: null,
      success_message: success_message ?? null,
    };
  }),
];
