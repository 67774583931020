import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ICoreUserAccount } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { selectSelectedUserAccount } from '../../user/auth/+state/selectors/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class LaunchpointSecurityAccountIdHeaderServiceV2 {
  public _Store = inject(Store);

  get getSelectedAccountIdHeaders(): Observable<HttpHeaders> {
    return this._Store.select(selectSelectedUserAccount).pipe(
      map((account: ICoreUserAccount) => {
        if (typeof account.account_id === 'string') return this.createAccountIdHeader(account.account_id as string);
        else if (typeof account.account_id !== 'string') return this.createAccountIdHeader((account.account_id as { _id: string })._id);
        else throw new Error('Could not get the account ID to assign to the headers');
      })
    );
  }

  getSelectedAccountIdHeadersOptions(options: { [key: string]: string }): Observable<HttpHeaders> {
    return this._Store
      .select(selectSelectedUserAccount)
      .pipe(map((account: ICoreUserAccount) => this.createAccountIdHeader(account.account_id as string, options)));
  }

  public createAccountIdHeader(account_id: string, options?: { [key: string]: string }): HttpHeaders {
    const headers = new HttpHeaders({
      'account-id': account_id,
    });
    for (const key in options) {
      if (Object.prototype.hasOwnProperty.call(options, key)) {
        headers.set(key, options[key]);
      }
    }
    return headers;
  }
}
