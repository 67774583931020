import { Observable } from 'rxjs';
import { ICoreUserSearchPayload, ICoreUserSearchResults } from './core-user.interface';
import { ICoreUser } from '../../user-company-account.interface';
/**
 * @Controller('user')
 */
export interface ICoreUserController {
  /**
   * Get()
   * @param id
   */
  getById(id: string): Promise<ICoreUser> | Observable<ICoreUser>;
  /**
   * Post('search')
   * @param id
   */
  search(body: ICoreUserSearchPayload): Promise<ICoreUserSearchResults> | Observable<ICoreUserSearchResults>;
}
