import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientAlertBannerBaseComponent } from '@launchpoint/core-client';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IToolbarConfig } from '../../../components/toolbar/toolbar.component';
import { LaunchpointCoreWebAlertBannerModalComponent } from '../alert-banner-modal/alert-banner-modal.component';

@Component({
  selector: 'launchpoint-alert-banner-toolbar',
  templateUrl: 'alert-banner-toolbar.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebAlertBannerToolbarComponent extends LaunchpointCoreClientAlertBannerBaseComponent {
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  options: IToolbarConfig = {
    title: 'Alert Banners',
    searchPlaceholder: 'Search...',
    formControl: this.form_control,
    create: true,
    queryCount: this.queryFilterCount$,
  };

  create() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebAlertBannerModalComponent, { size: 'lg' });
  }
}
