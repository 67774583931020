import { Injectable } from '@angular/core';
import { IHttpRequestError } from '@launchpoint/core-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import * as UserNotificationActions from '../actions/user-notification.actions';
import { RouterActions } from '../../../../../router/+state';
import { UserNotificationsHTTPService } from '../../../../services/user-notifications-http.service';
import { getAllUserNotificationsState } from '../selectors/user-notification.selectors';

@Injectable()
export class UserNotificationEffects {
  constructor(
    private actions$: Actions,
    private _Store: Store,
    private _UserNotificationsHTTPService: UserNotificationsHTTPService
  ) {}

  getAllUserNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        UserNotificationActions.searchAllUserNotifications,
        UserNotificationActions.updateAllUserNotificationPagination,
        UserNotificationActions.updateAllUserNotificationQuery,
        UserNotificationActions.updateAllUserNotificationSorting
      ),
      withLatestFrom(this._Store.select(getAllUserNotificationsState)),
      switchMap(([action, state]) => {
        const pagination = state.pagination;
        const querySort = state.querySort;
        const query = state.query;
        return this._UserNotificationsHTTPService.search({ pagination: pagination, querySort: querySort, query: query }).pipe(
          map((results) => {
            return UserNotificationActions.searchAllUserNotificationsSuccess({
              data: results,
            });
          }),
          catchError((error: IHttpRequestError) => of(UserNotificationActions.searchAllUserNotificationsFailure(error)))
        );
      }),
      catchError((error) => of(UserNotificationActions.searchAllUserNotificationsFailure(error)))
    );
  });

  getUserNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNotificationActions.getUserNotificationById),
      switchMap((action) => {
        return this._UserNotificationsHTTPService.getById(action.user_notification_id).pipe(
          map((data) => {
            return UserNotificationActions.getUserNotificationByIdSuccess({
              user_notification: data,
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(UserNotificationActions.getUserNotificationByIdFailure({ user_notification_id: action.user_notification_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(UserNotificationActions.getUserNotificationByIdFailure(error)))
    );
  });

  createUserNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNotificationActions.createUserNotification),
      switchMap((action) => {
        return this._UserNotificationsHTTPService.create(action.data).pipe(
          map((data) => {
            return UserNotificationActions.createUserNotificationSuccess({
              data: data,
              redirectPath: action.redirectPath,
              success_message: 'User Notification Created',
            });
          }),
          catchError((error: IHttpRequestError) => of(UserNotificationActions.createUserNotificationFailure({ error: error.error })))
        );
      }),
      catchError((error) => of(UserNotificationActions.getUserNotificationByIdFailure(error)))
    );
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createUserNotificationuccessRouting$: any = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserNotificationActions.createUserNotificationSuccess),
        switchMap((data) => {
          if (data?.redirectPath) {
            return of(RouterActions.Go({ payload: { path: [...data.redirectPath, data.data._id] } }));
          }
          return of();
        }),
        catchError((error) => of(UserNotificationActions.getUserNotificationByIdFailure(error)))
      );
    }
    // { dispatch: false }
  );

  updateUserNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNotificationActions.updateUserNotification),
      switchMap((action) => {
        return this._UserNotificationsHTTPService.update(action.data).pipe(
          map((data) => {
            return UserNotificationActions.updateUserNotificationSuccess({
              user_notification: data,
              success_message: 'User Notification Updated',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(UserNotificationActions.updateUserNotificationFailure({ user_notification_id: action.data._id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(UserNotificationActions.getUserNotificationByIdFailure(error)))
    );
  });

  deleteUserNotificationById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNotificationActions.deleteUserNotification),
      switchMap((action) => {
        return this._UserNotificationsHTTPService.delete(action.id).pipe(
          map((data) => {
            return UserNotificationActions.deleteUserNotificationSuccess({
              data: data,
              success_message: 'User Notification Deleted',
            });
          }),
          catchError((error: IHttpRequestError) =>
            of(UserNotificationActions.deleteUserNotificationFailure({ user_notification_id: action.id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(UserNotificationActions.deleteUserNotificationFailure(error)))
    );
  });

  getUserUnread$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserNotificationActions.getUserNotificationUnread),
      switchMap((action) => {
        return this._UserNotificationsHTTPService.getUserUnread(action.user_id).pipe(
          map((data) => {
            return UserNotificationActions.getUserNotificationUnreadSuccess({ data: data });
          }),
          catchError((error: IHttpRequestError) =>
            of(UserNotificationActions.getUserNotificationUnreadFailure({ user_id: action.user_id, error: error.error }))
          )
        );
      }),
      catchError((error) => of(UserNotificationActions.getUserNotificationUnreadFailure(error)))
    );
  });
}
