import { Directive } from '@angular/core';
import { ICoreUserDemographicsParamsUpdatePhone } from '@launchpoint/core-types';
import { firstValueFrom } from 'rxjs';
import { updateUserPhone } from '../../../../../users/+state/actions/users.actions';
import { LaunchpointCoreClientUsersStateBaseComponent } from '../../../../../users/+state/users-state-base.component';
import { Store } from '@ngrx/store';

@Directive()
export class LaunchpointCoreClientProfilePhoneSmartAdminBaseComponent extends LaunchpointCoreClientUsersStateBaseComponent {
  constructor(public _Store: Store) {
    super(_Store);
  }
  async dispatchSave(event: Omit<ICoreUserDemographicsParamsUpdatePhone, 'user_id'>) {
    const user = await firstValueFrom(this.selected_user$);
    this._Store.dispatch(updateUserPhone({ data: { ...event, user_id: user.user._id } }));
  }
}
