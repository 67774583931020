import { InjectionToken } from '@angular/core';
import { IHttpException, IUserView } from '@launchpoint/core-types';

export interface ViewEntityState {
  view_id: string;
  view: IUserView;
  loaded: boolean;
  loading: boolean;
  error: IHttpException | null;
  // profile_config?: LaunchpointCoreClientProfileConfig;
  success_message?: string;
}

export interface LaunchpointUserViewsConfig {
  base_url: string;
}
export const USER_VIEWS_CONFIG_TOKEN = new InjectionToken<LaunchpointUserViewsConfig>('USER_VIEWS_CONFIG_TOKEN');
