import { Injectable } from '@angular/core';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import * as MomentTZ from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class UtilityDatesService {
  moment = extendMoment(Moment);

  getUserOffset() {
    const moment_offset = this.moment().utcOffset();
    return moment_offset;
  }
  // done because daylight savings time is a thing, and offset will change
  getUserOffsetFromDate(date) {
    try {
      const offset = this.moment(date).utcOffset();
      return offset;
    } catch (error) {
      console.error('No offset found', error);
      return null;
    }
  }
  factorOffsetToDate(date: Date, offset: number) {
    return this.moment(date).utcOffset(Number(offset)).format('M/DD/YY, h:mm a');
  }
  factorOffsetToDateOnly(date: Date, offset: number) {
    return this.moment(date).utcOffset(Number(offset)).format('YYYY-MM-DD');
  }

  habitApplyTimezone(user_habit_date, user_habit_offset) {
    return MomentTZ.tz(new Date(user_habit_date).getTime() + user_habit_offset * 60000, 'UTC')
      .startOf('day')
      .format('M/DD/YY');
  }
  addToDate(date: Date | number, amount: number, timePeriod) {
    return this.moment(date).add(amount, timePeriod);
  }
  addToDateNumber(date: number, amount: number, timePeriod) {
    return this.moment(date).add(amount, timePeriod);
  }

  //
  addToDateReturnUTC(date: Date | number, amount: number, timePeriod) {
    return this.moment(date).add(amount, timePeriod).utc().format();
  }
  setSeconds(date) {
    return this.moment(date).seconds(0).toDate();
  }
  getDateFromDateString(dateString: string) {
    return this.moment(dateString).toDate();
  }
  addToDateReturnUnix(date: Date, amount: number, timePeriod) {
    return this.moment(date).add(amount, timePeriod).unix();
  }
  unixDateToUsa(unix_date) {
    return this.moment.unix(unix_date).format('MM/DD/YYYY');
  }
  subtractFromDate(date: Date, amount: number, timePeriod) {
    return this.moment(date).subtract(amount, timePeriod);
  }
  subtractFromDateAsDate(date: Date, amount: number, timePeriod) {
    return this.moment(date).subtract(amount, timePeriod).toDate();
  }

  getArrayOfDays(date1: Date, date2: any) {
    const range = this.moment.range(date1, date2);

    const seven_day_range = Array.from(range.by('day'));
    const formatted_seven_day_range = seven_day_range.map((day) => {
      const container = {};
      container['day_of_week'] = day.format('ddd');
      container['month'] = day.format('MM');
      container['day'] = day.format('DD');
      container['year'] = day.format('YYYY');
      return container;
    });
    return formatted_seven_day_range as { day_of_week: string; month: string; day: string; year: string }[];
  }
  // Takes the timezone off & puts to m-d-y
  parseZoneFormatMMMDoYY(date: Date): string {
    const parseMoment = this.moment.parseZone(date);
    return parseMoment.format('MMM Do YY');
  }
  formatDateToYYYYMMDD(date) {
    return this.moment(date).format('YYYY-MM-DD');
  }
  toUnix(date: Date | number): number {
    return this.moment(date).unix();
  }
  toUTC(date: Date) {
    return this.moment(date).utc().format();
  }
  todayToISO() {
    return this.moment().toISOString(true);
  }
  todayUTCtoISO() {
    return this.moment.utc().toISOString();
  }
  removeTimeFromUTC(date) {
    if (date) {
      return date.split('T')[0];
    }
  }

  /**
   *
   * @returns moment object date for today
   */
  getTodayAsMoment() {
    const today = this.moment().toDate();
    return today;
  }

  todayPlusFiveMinIsToDate() {
    return this.moment().add(5, 'minutes').toDate();
  }

  getTomorrowAsDate() {
    const tomorrow = this.moment().add(1, 'day').toDate();
    return tomorrow;
  }

  doesDayExist(month, day, year) {
    // eslint-disable-next-line no-self-assign
    day < 10 ? (day = `0${day}`) : (day = day);
    const monthNumber = this.getMonthNumberFromAbbreviation(month);
    const isDate = this.moment(`${monthNumber}-${day}-${year}`, 'MM-DD-YYYY', true);
    return isDate.isValid();
  }

  getDateISO(month, day, year) {
    const dateObject = this.moment(new Date(`${month} ${day} ${year}`)).toISOString();
    return dateObject;
  }
  dateToYears(date) {
    return this.moment().diff(date, 'years');
  }

  dateToDays(date) {
    return this.moment().diff(date, 'days');
  }

  formatDateToUSA(date) {
    return this.moment(date).format('MM-DD-YYYY');
  }
  formatDateToEurope(date) {
    return this.moment(date).format('YYYY-MM-DD');
  }
  formatDateToISO(date) {
    return this.moment(date).format();
  }
  formatDateToUTCtoISO(date) {
    const result = this.moment(date).utc().toISOString();
    return result;
  }

  getAllMonthNames() {
    return this.moment.months();
  }

  getAllMonthNamesAbbreviation() {
    return this.moment.monthsShort();
  }

  /**
   *
   * @param numbers Array of numbers to get abbreviation of ex [1,2,3] will return first three months
   */
  getMonthNamesAbbrByNumbers(numbers: Array<number>) {
    const monthAbbreviation = [];
    numbers.forEach((num) => {
      while (num < 13) {
        const abbrv = this.moment.monthsShort(num);
        monthAbbreviation.push(abbrv);
      }
    });
    return monthAbbreviation;
  }
  /**
   *
   * @param monthAbbreviation
   * @returns
   */
  getMonthNumberFromAbbreviation(monthAbbreviation: string) {
    return this.moment().month(monthAbbreviation).format('MM');
  }

  getMonthAbbreviationFromNumber(monthNumber: string) {
    const arrayAdjusted = Number(monthNumber) - 1;
    return this.moment().month(arrayAdjusted).format('MMM');
  }
  getmonthNameFromNumber(monthNumber: number): string {
    return this.moment.months(monthNumber);
  }
  getMonthDayYearFromISO(date) {
    const dateSeperated = {
      month: this.moment(date).format('MM'),
      day: this.moment(date).format('DD'),
      year: this.moment(date).format('YYYY'),
    };
    return dateSeperated;
  }
  daysInMonthByDateReturnNumber(date) {
    return this.moment(date, 'YYYY-MM').daysInMonth();
  }

  getDaysOfMonthByYYYYMMReturnArray(dateYYYYMM: string) {
    const daysInMonth = this.moment(dateYYYYMM).daysInMonth();
    return [...Array(daysInMonth).keys()].map((x) => x + 1);
  }
  getYearsFromMinToMaxAgo(min: number, max: number) {
    const years = [];
    const dateStart = this.moment().subtract(min, 'y');
    const dateEnd = this.moment().subtract(max, 'y');
    while (dateEnd.diff(dateStart, 'years') <= 0) {
      years.push(dateStart.format('YYYY'));
      dateStart.subtract(1, 'year');
    }
    return years as Array<number>;
  }

  getYearsFromMinToMaxFuture(min: number, max: number) {
    const years = [];
    const dateStart = this.moment().add(min, 'y');
    const dateEnd = this.moment().add(Number(max), 'y');
    while (dateStart.diff(dateEnd, 'years') <= 0) {
      years.push(dateStart.format('YYYY'));
      dateStart.add(1, 'year');
    }
    return years as Array<number>;
  }

  isDate1AfterDate2(date1, date2) {
    return this.moment(date2).isAfter(date1);
  }

  isDate1SameOrBeforeDate2(date1, date2) {
    return this.moment(date1).isSameOrBefore(date2);
  }
  isDate1SameOrAfterDate2(date1, date2): boolean {
    return this.moment(date1).isSameOrAfter(date2);
  }
  isDateWithinPastWeek(date: Date): boolean {
    if (this.subtractFromDate(new Date(), 7, 'days').utc().utc().format() <= this.moment(date).utc().format()) {
      return true;
    } else {
      return false;
    }
  }
  isDateInCurrentMonth(date) {
    return this.moment(date).isSame(new Date(), 'month'); //true if dates are in the same month
  }
  isDate1InSameMonthAsDate2(date1, date2) {
    return this.moment(date1).isSame(date2, 'month'); //true if dates are in the same month
  }
  isDateTodayOrYesterday(date: string): boolean {
    const today = this.moment(date).isSame(this.moment(), 'day');
    const yesterday = this.moment(date).isSame(this.moment().subtract(1, 'day'), 'day');
    if (today || yesterday) {
      return true;
    } else {
      return false;
    }
  }
  isDateTodayOrFuture(date: Date): boolean {
    const isDateToday = this.moment(this.moment()).isSame(date);
    const isDateFuture = this.moment(date).isAfter(this.moment().toDate());
    if (isDateToday || isDateFuture) {
      return true;
    } else {
      return false;
    }
  }
  isDateFuture(date) {
    return this.moment(date).isAfter(this.moment());
  }
  isDatePast(date) {
    return this.moment(date).isBefore(this.moment());
  }
  isDateTodayOrPast(date: Date | number): boolean {
    const isDateToday = this.moment(this.moment()).isSame(date);
    const isDatePast = this.moment(date).isBefore(this.moment().toDate());
    if (isDateToday || isDatePast) {
      return true;
    } else {
      return false;
    }
  }
  isDateThisYear(date) {
    return this.moment(date).isSame(new Date(), 'year');
  }
  isTodayInRange(rangeStart: Date, rangeEnd: Date) {
    const range = this.moment.range(rangeStart, rangeEnd);
    return range.contains(this.moment().toDate());
  }

  isTodayAnyInArray(dateArray): boolean {
    let found = false;
    dateArray.forEach((date) => {
      if (this.moment(date).isSame(this.moment(), 'day')) {
        found = true;
      }
    });
    return found;
  }

  dateMinusDaysReturnToDate(date: Date, daysMinus: any) {
    return this.moment(date).subtract(daysMinus, 'days').toDate();
  }

  monthCountFromDate1toDate2(date1: Date, date2: Date) {
    const monthCount = Math.abs(this.moment(date1).diff(this.moment(date2), 'months'));
    return monthCount + 1;
  }

  dayCountFromTodaytoDate(date: Date) {
    const dayCount = Math.abs(this.moment().diff(this.moment(date), 'days'));
    return dayCount + 1;
  }

  has24HoursPastFromDate(date: Date) {
    const hours = this.moment().diff(this.moment(date), 'hours');
    if (hours > 24) {
      return true;
    } else {
      return false;
    }
  }

  endOfToDate(timePeriod: any) {
    return this.moment().endOf(timePeriod).toDate();
  }
  startOfToDate(timePeriod: any) {
    return this.moment().startOf(timePeriod).toDate();
  }
  endOfMDateToDate(timePeriod: any, date: any) {
    return date.endOf(timePeriod).toDate();
  }
  startOfMDateToDate(timePeriod: any, date: any) {
    return date.startOf(timePeriod).toDate();
  }
  startOfMonth(date): Date {
    return this.moment(date).startOf('month').toDate();
  }
  endOfMonth(date): Date {
    return this.moment(date).endOf('month').toDate();
  }
  startOfWeek(date): Date {
    return this.moment(date).startOf('week').toDate();
  }
  endOfWeek(date): Date {
    return this.moment(date).endOf('week').toDate();
  }
  /**
   * Gets the full month name as a string from the month number
   *
   * @param monthNumber - the number of the month (1-12)
   * @returns - string representation of a full this.moment month name
   */
  getFullMonthNameByNumber(monthNumber: number): string {
    return this.moment().month(monthNumber).format('MMMM');
  }

  getFulMonthByDate(date: Date): string {
    return this.moment(date).format('MMMM');
  }

  getFullYearByDate(date: Date): string {
    return this.moment(date).format('YYYY');
  }

  /**
   * Gets the week number out of the year of the date (x out of 52 weeks)
   *
   * @param date - the date given to check
   * @returns - the number of the week in the year that the date exists in
   */
  getWeekNumberByDate(date: Date): number {
    return this.moment(date).week();
  }
  getStartOfTimePeriodByDate(timePeriod, date) {
    return this.moment(date).startOf(timePeriod).format('YYYY-MM-DD');
  }
  getEndOfTimePeriodByDate(timePeriod, date) {
    return this.moment(date).endOf(timePeriod).format('YYYY-MM-DD');
  }
  isDateASunday(date) {
    return this.moment(date).weekday();
  }
  // gets the date in the current week of the day you pass it (0-6 sun-sat)
  getDayOfWeek(day_number: number) {
    // console.log('service', day_number); //fyi on sunday it prints as 6 because 6-0 is 6
    return this.moment().isoWeekday(day_number).toDate();
  }
  /**
   * Gets the date at the start of the week
   *
   * @param date - the date given to check
   * @returns- this.moment date start of week
   */
  getStartOfWeekByDate(date: Date): moment.Moment {
    return this.moment(date).startOf('week');
  }

  getStartOfWeekasEuropeFormat(date: Date): string {
    return this.moment(date).startOf('week').format('YYYY-MM-DD');
  }

  /**
   * Gets the date at the end of the week
   *
   * @param date - the date given to check
   * @returns - this.moment date end of week
   */
  getEndOfWeekByDate(date: Date): moment.Moment {
    return this.moment(date).endOf('week');
  }

  getEndOfWeekByDateToUTC(date: Date) {
    return this.toUTC(this.moment(date).endOf('week').toDate());
  }

  getEndOfMonthByDate(date): moment.Moment {
    return this.moment(date).endOf('month');
  }
  getStartOfMonthByDate(date): moment.Moment {
    return this.moment(date).startOf('month');
  }

  /**
   * Gets the week using the start date and end date
   *
   * @param startWeekDate - date at the start of the week
   * @param endWeekDate - date at the end of the week
   * @returns - an array of days
   */
  getWeekArrayFromStartAndEndDate(startWeekDate, endWeekDate): Array<string> {
    const days = [];
    let day = startWeekDate;

    while (day <= endWeekDate) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  // Listing the months and weekdays of the current Moment.js locale
  //you can pass a bool as the first parameter of the weekday functions. If true, the weekdays will be returned in locale specific order.
  getWeekdaysFullArray() {
    return this.moment.weekdays(true);
  }
  getWeekdaysAbbreviationArray(): Array<string> {
    return this.moment.weekdaysShort(true);
  }
  getWeekdaysInitialArray() {
    return this.moment.weekdaysMin();
  }
  getMonthsArray() {
    return this.moment.months();
  }
  getMonthsAbbreviationArray() {
    return this.moment.monthsShort();
  }

  getArrayOfDates(start_date, end_date) {
    const dateArray = [];
    for (let date = this.moment(start_date); date.isSameOrBefore(this.moment(end_date)); date.add(1, 'day')) {
      dateArray.push({
        date_string: date.format('YYYY-MM-DD'),
        day_of_month: date.date(),
        month: date.month(),
      });
    }
    return dateArray;
  }

  getMilliseconds(number) {
    return this.moment().milliseconds(number);
  }
  getSeconds(number) {
    return this.moment().seconds(number);
  }
  getMinutes(number) {
    return this.moment().minutes(number);
  }
  getHours(number) {
    return this.moment().hours(number);
  }
  applyTimeToDate(date: string, hours: any, minutes: any) {
    return this.moment(date).set({ hour: hours, minute: minutes }).format();
  }
  factorTimezoneToDate(date, timezone: string, keepLocalTime: boolean) {
    if (timezone != 'null' && timezone != null) {
      return MomentTZ(date).tz(timezone, keepLocalTime).toDate();
    } else {
      return MomentTZ(date).tz('UTC', true).toDate();
    }
  }
  currentTimeWithDateString(date_string: string) {
    return this.moment(date_string).format();
  }
  //
  //TIMEZONES
  //
  usersTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
