import { Observable } from 'rxjs';
import { ICoreBlog } from '../blog.interface';
import { ICoreUserJWTDecorator } from '../../auth';
import { ICoreBlogRelatedPostParams } from './blog-related-posts.interface';

/**
 * @Controller('blog-related-posts')
 */
export interface ICoreBlogRelatedPostsController {
  /**
   * @Post('add')
   * @param params
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_ADD, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_ADD, LAUNCHPOINT_BLOG_SCHEMA)
   */
  add(params: ICoreBlogRelatedPostParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('remove')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_REMOVE, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_REMOVE, LAUNCHPOINT_BLOG_SCHEMA)
   */
  remove(params: ICoreBlogRelatedPostParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;

  /**
   * @Put('set')
   * @SecurityPermissionsGuard(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_SET, [])
   * @CreateActivityHistory(ESecurityLaunchpointBlogResources.BLOG_RELATED_POST_SET, LAUNCHPOINT_BLOG_SCHEMA)
   */
  set(body: ICoreBlogRelatedPostParams, decorator?: ICoreUserJWTDecorator): Promise<ICoreBlog> | Observable<ICoreBlog>;
}
