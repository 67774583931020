import { Observable } from 'rxjs';
import { IQueryPageination, IQuerySort } from '../query.interfaces';
import { ELPDefaultActions } from '../utils/default-actions.interface';
import { ICoreUserJWTDecorator } from '../auth';

/**
 * Store the column name that the user wants to save, can also be the default column names if
 * they don't wish to rename any.
 *
 * Store the data path to the data for that column so that we know where it is even if the column
 * is named differently. For example given this schema I could have a column to show like this
 * {
 *  column_name: "Currently Sorted",
 *  data_path: 'querySort.active'
 * }
 */
export interface IUserViewColumnsToShow {
  column_name: string;
  data_path: string;
}

export class IUserViewColumns {
  /** True if the column is hidden */
  hide?: boolean | null;
  /** Width of the column in pixels */
  width?: number;
  /** Column's flex if flex is set */
  flex?: number | null;
  /** Sort applied to the column */
  sort?: 'asc' | 'desc' | null;
  /** The order of the sort, if sorting by many columns */
  sortIndex?: number | null;
  /** The aggregation function applied */
  aggFunc?: string | unknown | null;
  /** True if pivot active */
  pivot?: boolean | null;
  /** The order of the pivot, if pivoting by many columns */
  pivotIndex?: number | null;
  /** Set if column is pinned */
  pinned?: 'left' | 'right' | boolean | null | undefined;
  /** True if row group active */
  rowGroup?: boolean | null;
  /** The order of the row group, if grouping by many columns */
  rowGroupIndex?: number | null;
}
export class IUserView {
  _id: string;

  title: string;
  /**
   * Matches a specific table since the query params are specific to table
   */
  table_id: string;
  user_id: string;
  account_id: string;
  // TODO: Add ability to share with account or just be for the user
  /**
   * Whether or not this preference document is the default one for this table.
   */
  default: boolean;

  /**
   * The last time this preference was actually used as params
   */
  last_used: Date;

  /**
   * The amount of times this preference was actually used as params
   */
  times_used: number;

  /**
   * Holds the actual quick filter query that the user has stored as a preference
   */
  query: Record<string, any>;

  pagination: IQueryPageination;
  querySort: IQuerySort;
  columns: IUserViewColumns[];
  /**
   * Store the column name that the user wants to save, can also be the default column names if
   * they don't wish to rename any.
   *
   * Store the data path to the data for that column so that we know where it is even if the column
   * is named differently. For example given this schema I could have a column to show like this
   * {
   *  column_name: "Currently Sorted",
   *  data_path: 'querySort.active'
   * }
   */
  /**
   * @deprecated
   * use columns
   */
  columns_to_show: IUserViewColumnsToShow[];
}

export class IUserViewQueryParams {
  table_id?: string;
  search?: string;
  isDefault?: boolean;
}

export class IUserViewParamsSearch {
  query: IUserViewQueryParams & Record<string, any>;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class IQueryUserView {
  pagination: IQueryPageination;
  data: IUserView[];
}

export class IUserViewParamsCreate implements Pick<Partial<IUserView>, '_id' | 'columns' | 'columns_to_show' | 'pagination' | 'querySort' | 'query'> {
  table_id: string;
  title: string;
  query?: Record<string, any>;
  pagination?: {
    count: number;
    limit: number;
    pageIndex: number;
    skip: number;
    previousPageIndex: number;
  };
}

export class IUserViewParamsUpdate implements Pick<Partial<IUserView>, '_id' | 'columns' | 'columns_to_show' | 'pagination' | 'querySort' | 'query'> {
  _id: string;
  title?: string;
  table_id?: string;
  query?: Record<string, any>;
  /**
   * Whether or not this preference document is the default one for this table.
   */
  default?: boolean;
  columns?: IUserViewColumns[];
}

/**
 * @Controller('user-views')
 */
export interface IUserViewsController {
  search(body: IUserViewParamsSearch, user?: ICoreUserJWTDecorator): Promise<IQueryUserView> | Observable<IQueryUserView>;
  findById(_id: string, user?: ICoreUserJWTDecorator): Promise<IUserView> | Observable<IUserView>;
  create(body: IUserViewParamsCreate, user?: ICoreUserJWTDecorator): Promise<IUserView> | Observable<IUserView>;
  update(body: IUserViewParamsUpdate, user?: ICoreUserJWTDecorator): Promise<IUserView> | Observable<IUserView>;
  delete(_id: string, user?: ICoreUserJWTDecorator): Promise<IUserView> | Observable<IUserView>;
}

export abstract class UserTablePreferenceController implements IUserViewsController {
  search(body: IUserViewParamsSearch, user?: ICoreUserJWTDecorator | undefined): Promise<IQueryUserView> | Observable<IQueryUserView> {
    throw new Error('Method not implemented.');
  }
  findById(_id: string, user?: ICoreUserJWTDecorator | undefined): Promise<IUserView> | Observable<IUserView> {
    throw new Error('Method not implemented.');
  }
  create(body: IUserViewParamsCreate, user?: ICoreUserJWTDecorator | undefined): Promise<IUserView> | Observable<IUserView> {
    throw new Error('Method not implemented.');
  }
  update(body: IUserViewParamsUpdate, user?: ICoreUserJWTDecorator | undefined): Promise<IUserView> | Observable<IUserView> {
    throw new Error('Method not implemented.');
  }
  delete(_id: string, user?: ICoreUserJWTDecorator | undefined): Promise<IUserView> | Observable<IUserView> {
    throw new Error('Method not implemented.');
  }
}

export enum ELaunchpointUserTableView {
  USER_VIEW = 'user-table-view',
}

export enum ELaunchpointUserViewResources {
  USER_VIEW_SEARCH = ELaunchpointUserTableView.USER_VIEW + '/' + ELPDefaultActions.SEARCH,
  USER_VIEW_CREATE = ELaunchpointUserTableView.USER_VIEW + ELPDefaultActions.CREATE,
  USER_VIEW_FIND_BY_ID = ELaunchpointUserTableView.USER_VIEW + '/' + ELPDefaultActions.GET_BY_ID,
  USER_VIEW_UPDATE = ELaunchpointUserTableView.USER_VIEW + '/' + ELPDefaultActions.UPDATE,
  USER_VIEW_DELETE = ELaunchpointUserTableView.USER_VIEW + '/' + ELPDefaultActions.DELETE,
}
