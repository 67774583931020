import {
  IAuthenticationResponse,
  ICoreUser,
  ICoreUserAddressParamsCreate,
  ICoreUserAddressParamsUpdate,
  ICoreUserDemographicsParamsUpdatePhone,
  ICoreUserParamsChangeEmail,
  ICoreUserParamsChangePassword,
  ICoreUserParamsCreateV3,
  ICoreUserParamsLoginPasswordV3,
  ICoreUserParamsResetPassword,
  ICoreUserParamsSetPassword,
  ICoreUserParamsVerifyV3,
  ICoreUserProfileParamsUpdate,
  IHttpException,
} from '@launchpoint/core-types';
import { createAction, props } from '@ngrx/store';
/**
 * The authentication only returns a JWT and a refresh token
 * This is the process for using the token from either local storage or from login/create to actual return the user
 */
export const getUserByToken = createAction('[User] getUserByToken');

// export const getUserByToken = createAction('[User] getUserByToken', props<{ path?: string[] }>());
export const getUserByTokenSuccess = createAction('[User] getUserByTokenSuccess', props<{ user: ICoreUser; returnUrl?: string[] }>());
export const getUserByTokenFailure = createAction('[User] getUserByTokenFailure', props<{ error: IHttpException; path?: string[] }>());

// >>>>>>>>>>>> SIGN UP

/**
 * SIGN UP UN AND PASSWORD
 */
// CREATE =>  POST: add a new user to the server
export const createUser = createAction('[User] createUser', props<{ user: ICoreUserParamsCreateV3 }>());
export const createUserSuccess = createAction('[User] createUserSuccess', props<{ auth: IAuthenticationResponse; returnUrl?: string[] }>());
export const createUserFailure = createAction('[User] createUserFailure', props<{ error: IHttpException }>());
export const createUserLogin = createAction('[User] createUserLogin', props<{ user: ICoreUser }>());

/**
 * USER SET PASSWORD
 */
export const loggedInUserSetNewPassword = createAction('[User] loggedInUserSetNewPassword', props<{ data: ICoreUserParamsSetPassword }>());
export const loggedInUserSetNewPasswordSuccess = createAction(
  '[User] loggedInUserSetNewPasswordSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserSetNewPasswordFailure = createAction('[User] loggedInUserSetNewPasswordFailure', props<{ error: IHttpException }>());

/**
 * Sends user an email to complete sign up.
 */
export const userCreate = createAction('[User] userCreate', props<{ data: ICoreUserParamsCreateV3; path?: string[]; reset?: boolean }>());
export const userCreateSuccess = createAction('[User] userCreateSuccess', props<{ message: string; path?: string[]; reset?: boolean }>());
export const userCreateFailure = createAction('[User] userCreateFailure', props<{ error: IHttpException }>());
export const userCreateWarning = createAction('[User] userCreateWarning', props<{ error: { message: string; error: string } }>());

/**
 * Sends user an email to complete sign up.
 */
export const userCodeVerify = createAction('[User] userCodeVerify', props<{ data: ICoreUserParamsVerifyV3; returnUrl?: string[] }>());
export const userCodeVerifySuccess = createAction('[User] userCodeVerifySuccess', props<{ auth: IAuthenticationResponse; returnUrl?: string[] }>());
export const userCodeVerifyFailure = createAction('[User] userCodeVerifyFailure', props<{ error: IHttpException }>());

// >>>>>>>>>>>> VERIFY
/**
 * VERIFY EMAIL
 */
export const verifyEmailSSE = createAction('[User] verifyEmailSSE', props<{ _id: string }>());
export const verifyEmailSSEFailure = createAction('[User] verifyEmailSSEFailure', props<{ error: IHttpException }>());

// >>>>>>>>>>>> LOGIN
/**
 * Sends User an email to login
 */
export const loginMagicSendEmail = createAction('[User] loginMagicSendEmail', props<{ data: ICoreUserParamsCreateV3; path?: string[] }>());
export const loginMagicSendEmailSuccess = createAction('[User] loginMagicSendEmailSuccess', props<{ message: string; path?: string[] }>());
export const loginMagicSendEmailFailure = createAction('[User] loginMagicSendEmailFailure', props<{ error: IHttpException }>());

/**
 * Sends User an email to login
 */
export const loginMagic = createAction('[User] loginMagic', props<{ user: ICoreUserParamsVerifyV3; path?: string[] }>());
export const loginMagicSuccess = createAction('[User] loginMagicSuccess', props<{ message: string; path?: string[] }>());
export const loginMagicFailure = createAction('[User] loginMagicFailure', props<{ error: IHttpException }>());

/**
 * login with username and password
 */
export const login = createAction('[User] login', props<{ data: ICoreUserParamsLoginPasswordV3; returnUrl?: string[] | null | undefined }>());
export const loginSuccess = createAction(
  '[User] loginSuccess',
  props<{ auth: IAuthenticationResponse | { message: string }; returnUrl?: string[] | null | undefined }>()
);
export const loginFailure = createAction('[User] loginFailure', props<{ error: IHttpException }>());

// >>>>>>>>>>>> LOGOUT
export const logout = createAction('[User] logout', props<{ path?: string[] }>());
export const logoutSuccess = createAction('[User] logoutSuccess', props<{ path?: string[] }>());

/**
 * The authentication only returns a JWT and a refresh token
 * This is the process for using the token from either local storage or from login/create to actual return the user
 */
export const getUserNewUserFromRefreshToken = createAction('[User] getUserNewUserFromRefreshToken');
export const getUserNewUserFromRefreshTokenSuccess = createAction(
  '[User] getUserNewUserFromRefreshTokenSuccess',
  props<{ auth: IAuthenticationResponse; path?: string[] }>()
);
export const getUserNewUserFromRefreshTokenFailure = createAction(
  '[User] getUserNewUserFromRefreshTokenFailure',
  props<{ error: IHttpException; path?: string[] }>()
);

/**
 * FORGOT PASSWORD
 */
export const forgotPassword = createAction('[User] forgotPassword', props<{ email: string }>());
export const forgotPasswordSuccess = createAction('[User] forgotPasswordSuccess');
export const forgotPasswordFailure = createAction('[User] forgotPasswordFailure', props<{ error: IHttpException }>());
/**
 * RESET PASSWORD
 */
export const resetPassword = createAction('[User] resetPassword', props<{ data: ICoreUserParamsResetPassword; success_redirect?: string[] }>());
export const resetPasswordSuccess = createAction('[User] resetPasswordSuccess', props<{ success_redirect?: string[] }>());
export const resetPasswordFailure = createAction('[User] resetPasswordFailure', props<{ error: IHttpException }>());

/**
 * USER CHANGE PASSWORD
 */
export const loggedInUserChangePassword = createAction('[User] loggedInUserChangePassword', props<{ data: ICoreUserParamsChangePassword }>());
export const loggedInUserChangePasswordSuccess = createAction(
  '[User] loggedInUserChangePasswordSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserChangePasswordFailure = createAction('[User] loggedInUserChangePasswordFailure', props<{ error: IHttpException }>());

/**
 * UPDATE USER IN STORE
 */

export const updateUserSuccess = createAction('[User] updateUserSuccess', props<{ user: ICoreUser; success_message?: string }>());

/**
 * User Reset Email
 */
export const UserSetNewEmail = createAction('[User] UserSetNewEmail', props<{ data: ICoreUserParamsChangeEmail }>());

/**
 * USER CHANGE EMAIL
 */
export const loggedInUserChangeEmail = createAction('[User] loggedInUserChangeEmail', props<{ data: ICoreUserParamsChangeEmail }>());
export const loggedInUserChangeEmailSuccess = createAction(
  '[User] loggedInUserChangeEmailSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserChangeEmailFailure = createAction('[User] loggedInUserChangeEmailFailure', props<{ error: IHttpException }>());

// START: User Profile/Address/Phone:
/**
 * Update User Profile
 */
export const loggedInUserUpdateProfile = createAction('[User] loggedInUserUpdateProfile', props<{ data: ICoreUserProfileParamsUpdate }>());
export const loggedInUserUpdateProfileSuccess = createAction(
  '[User] loggedInUserUpdateProfileSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserUpdateProfileFailure = createAction('[User] loggedInUserUpdateProfileFailure', props<{ error: IHttpException }>());

/**
 * Update User Phone
 */
export const loggedInUserUpdatePhone = createAction('[User] loggedInUserUpdatePhone', props<{ data: ICoreUserDemographicsParamsUpdatePhone }>());
export const loggedInUserUpdatePhoneSuccess = createAction(
  '[User] loggedInUserUpdatePhoneSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserUpdatePhoneFailure = createAction('[User] loggedInUserUpdatePhoneFailure', props<{ error: IHttpException }>());

/**
 * Update User Address
 */
export const loggedInUserUpdateAddress = createAction('[User] loggedInUserUpdateAddress', props<{ data: ICoreUserAddressParamsUpdate }>());
export const loggedInUserUpdateAddressSuccess = createAction(
  '[User] loggedInUserUpdateAddressSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserUpdateAddressFailure = createAction('[User] loggedInUserUpdateAddressFailure', props<{ error: IHttpException }>());
/**
 * Update User Address
 */
export const loggedInUserCreateAddress = createAction('[User] loggedInUserCreateAddress', props<{ data: ICoreUserAddressParamsCreate }>());
export const loggedInUserCreateAddressSuccess = createAction(
  '[User] loggedInUserCreateAddressSuccess',
  props<{ user: ICoreUser; success_message?: string }>()
);
export const loggedInUserCreateAddressFailure = createAction('[User] loggedInUserCreateAddressFailure', props<{ error: IHttpException }>());

/**
 * CLOSE ALERT MESSAGES
 */
export const closeSuccessMessage = createAction('[User] closeSuccessMessage');

export const closeErrorMessage = createAction('[User] closeErrorMessage');
