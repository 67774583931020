import { ILaunchpointDynamicFormResponseResponses } from '../response/responses/response-answer.interface';
import { ILaunchpointDynamicForm } from './form.interface';
import { ILaunchpointDynamicFormGroup } from './groups/form-groups.interface';

export class LaunchpointDynamicFormResponseProcessor {
  private form: ILaunchpointDynamicForm; // The form structure
  private responses: ILaunchpointDynamicFormResponseResponses[]; // Array of responses

  constructor(form: ILaunchpointDynamicForm, responses: ILaunchpointDynamicFormResponseResponses[]) {
    this.form = form;
    this.responses = responses;
  }

  // Process a single group and return a new group object
  public processGroup(group: ILaunchpointDynamicFormGroup): ILaunchpointDynamicFormGroup {
    if (!group) {
      return group;
    }
    if (!group.questions) {
      return group;
    }

    const updatedQuestions = group?.questions?.map((question) => {
      const placeholders = this.extractPlaceholders(question.question_text);
      let updatedText = question.question_text;

      placeholders.forEach((placeholder) => {
        // console.log({ placeholder });
        const mergeField = placeholder.slice(2, -2); // Removes the surrounding {{ and }}
        const mergeQuestion = this.findQuestionByMerge(mergeField);
        // console.log({ mergeQuestion });
        if (mergeQuestion) {
          const response = this.findResponseByQuestionId(mergeQuestion._id);
          // console.log({ response });
          if (response) {
            updatedText = updatedText.replace(placeholder, response?.answer_value ?? '');
          } else {
            // console.log(`No response found for question ID: ${mergeQuestion._id}`);
          }
          // console.log({ updatedText });
        } else {
          // console.log(`No question found with merge field: ${mergeField}`);
        }
      });

      return {
        ...question,
        question_text: updatedText,
      };
    });

    return {
      ...group,
      questions: updatedQuestions,
    };
  }

  // Process all groups in the form and return a new form object
  public processAllGroups(): any {
    const updatedGroups = this.form?.groups?.map((group) => this.processGroup(group));
    return {
      ...this.form,
      groups: updatedGroups,
    };
  }

  // Helper method to extract placeholders like {{year}} from a string
  private extractPlaceholders(text: string): string[] {
    const regex = /\{\{[^{}]+\}\}/g;
    return text.match(regex) || [];
  }

  // Helper method to find a question by its merge tag
  private findQuestionByMerge(mergeTag: string): any {
    for (const group of this.form.groups) {
      const question = group.questions?.find((q) => q.question_merge === mergeTag);
      if (question) {
        return question;
      }
    }
    return null;
  }

  // Helper method to find a response by question ID
  private findResponseByQuestionId(questionId: string): any {
    return this.responses?.find((response) => response.question_id === questionId);
  }
}
