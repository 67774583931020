import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-tables-widget10',
  templateUrl: './tables-widget10.component.html',
})
export class TablesWidget10Component {
  // constructor() {}
  // ngOnInit(): void {}
}
