import { Observable } from 'rxjs';
import {
  ILaunchpointCorePinpointApplication,
  ILaunchpointCorePinpointApplicationParamsCreate,
  ILaunchpointCorePinpointApplicationParamsDelete,
  ILaunchpointCorePinpointApplicationParamsList,
  ILaunchpointCorePinpointApplicationParamsUpdateSettings,
  ILaunchpointCorePinpointApplicationSearchPayload,
  ILaunchpointCorePinpointApplicationSearchResults,
} from './pinpoint-application.interface';

/**
 * Controller('pinpoint/application')
 */
export interface ILaunchpointCorePinpointApplicationController {
  /**
   * Post(search)
   * @param body
   */
  search(
    body: ILaunchpointCorePinpointApplicationSearchPayload
  ): Promise<ILaunchpointCorePinpointApplicationSearchResults> | Observable<ILaunchpointCorePinpointApplicationSearchResults>;
  /**
   * Get(:id)
   * @param _id
   */
  getById(_id: string): Promise<ILaunchpointCorePinpointApplication> | Observable<ILaunchpointCorePinpointApplication>;
  /**
   * Post()
   * @param body
   */
  create(
    body: ILaunchpointCorePinpointApplicationParamsCreate
  ): Promise<ILaunchpointCorePinpointApplication> | Observable<ILaunchpointCorePinpointApplication>;
  /**
   * Post()
   * @param body
   */
  update(
    body: ILaunchpointCorePinpointApplicationParamsUpdateSettings
  ): Promise<ILaunchpointCorePinpointApplication> | Observable<ILaunchpointCorePinpointApplication>;
  /**
   * Put('delete;)
   * @param body
   */
  delete(
    body: ILaunchpointCorePinpointApplicationParamsDelete
  ): Promise<ILaunchpointCorePinpointApplication> | Observable<ILaunchpointCorePinpointApplication>;
  /**
   * Post('sync')
   * @param body
   */
  sync(body: ILaunchpointCorePinpointApplicationParamsList): Promise<{ success: boolean }> | Observable<{ success: boolean }>;
}
