import { Component } from '@angular/core';

@Component({
  selector: 'launchpoint-app-upgrade-plan-modal',
  templateUrl: './upgrade-plan-modal.component.html',
})
export class UpgradePlanModalComponent {
  // constructor() {}
  // ngOnInit(): void {}
}
