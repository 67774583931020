import { Inject, Injectable, Optional } from '@angular/core';
import { VAPID_KEY } from '@angular/fire/compat/messaging';
import { Messaging, deleteToken, getToken, onMessage } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import {
  CoreUserNotificationV1Service,
  IMessagingBaseService,
  LaunchpointCoreClientMessagingBaseService,
  updateUserNotification,
} from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { LaunchpointSweetAlertService } from '../../components/swal.service';
import { ENotificationAlertType } from '@launchpoint/core-types';

@Injectable({
  providedIn: 'root',
})
export class MessagingService extends LaunchpointCoreClientMessagingBaseService implements IMessagingBaseService {
  constructor(
    public _CoreUserNotificationV1Service: CoreUserNotificationV1Service,
    @Optional() public messaging: Messaging,
    @Inject(VAPID_KEY) public vapidKey: string,
    public router: Router,
    private _swalService: LaunchpointSweetAlertService,
    public _Store: Store
  ) {
    super();
  }

  init(debug = false) {
    this.debug = debug;
    this.log('messaging service available: ', !!this.messaging);

    if (this.initialized) {
      this.log('Messaging service already initialized');
      return;
    }

    if (this.messaging) {
      this.log('MESSAGING INIT SERVICE');

      this.onMessageInit();
      this.onTokenInit();
      this.serviceWorker();

      this.initialized = true;
    } else {
      console.log('NO MESSAGING?');
    }
  }

  async removeToken() {
    if (this.messaging) {
      try {
        const token = await getToken(this.messaging, { vapidKey: this.vapidKey });
        this.log('Removing token:', token.slice(0, 15), '...');
        await deleteToken(this.messaging);
        this.log('firebase removed token');
        await this.removeTokenFromUser(token);
        this.log('Removed token from user');

        this.initialized = false;
      } catch (error) {
        console.log(error);
      }
    }
  }

  public onMessageInit() {
    this.log('onMessage event listener set.');

    onMessage(this.messaging, (payload) => {
      function truncateString(str, num) {
        if (str.length <= num) {
          return str;
        }
        return str.slice(0, num) + '...';
      }

      this.log('Payload:', payload);

      const route = payload?.data?.route ?? payload?.data?.link ?? payload?.data?.url;

      this.log({ route });

      if (payload?.data?.type === ENotificationAlertType.TOAST) {
        this._swalService
          .fireToast({
            // set default values for toasts
            icon: 'info',
            showCloseButton: true,
            timer: 10000,
            /**
             * spread payload?.data if any swal options are passed
             * Parse it to get boolean / number values incase they are strings
             */
            ...JSON.parse(JSON.stringify(payload?.data)),
            // truncate title and body
            title: truncateString(payload.notification.title, 75),
            text: truncateString(payload.notification.body, 250),
          })
          .then((value) => {
            if (value.isConfirmed) {
              this.log('routing!');
              this.router.navigate([route]);

              // todo ?? -- dont have the data we need to update db
              // this._Store.dispatch(
              //   updateUserNotification({
              //     data: {
              //       _id: payload?.data?.notification_id,
              //       is_read: true,
              //       delivered: true,
              //     },
              //   })
              // );
            }
          });
      } else {
        this._swalService
          .fireAlert({
            // set default values for alerts
            icon: 'info',
            confirmButtonText: 'View',
            /**
             * spread payload?.data if any swal options are passed
             * Parse it to get boolean / number values incase they are strings
             */
            ...JSON.parse(JSON.stringify(payload?.data)),
            // truncate title and body
            title: truncateString(payload.notification.title, 75),
            text: truncateString(payload.notification.body, 250),
          })
          .then((value) => {
            if (value.isConfirmed) {
              this.log('routing!');
              this.router.navigate([route]);

              // todo ?? -- dont have the data we need to update db
              // this._Store.dispatch(
              //   updateUserNotification({
              //     data: {
              //       _id: payload?.data?.notification_id,
              //       is_read: true,
              //       delivered: true,
              //     },
              //   })
              // );
            }
          });
      }

      this.messagesBS.next(payload);
    });
  }

  public onTokenInit() {
    setTimeout(() => {
      getToken(this.messaging, { vapidKey: this.vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            this.log('adding token to user');
            this.addTokenToUser(currentToken);
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    }, 1000);
  }

  public serviceWorker() {
    this.log('Setting service worker');
    this.token$ = from(
      navigator.serviceWorker
        .register('firebase-messaging-sw.js', { type: 'module', scope: '__' })
        .then((serviceWorkerRegistration) =>
          getToken(this.messaging, {
            serviceWorkerRegistration,
            vapidKey: this.vapidKey,
          })
        )
        .catch((err) => console.error('Service Worker Error', err))
    ).pipe(
      tap((token) => this.addTokenToUser(token)),
      share()
    );
  }

  request() {
    Notification.requestPermission();
  }

  private log(...args: any[]) {
    if (this.debug) {
      console.log(...args);
    }
  }
}
