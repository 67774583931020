<div class="dataTables_wrapper dt-bootstrap4 no-footer">
  <div class="table-responsive">

    <table class="table table-row-dashed table-hover table-row-gray-100 align-middle gs-0 gy-5">

      <ng-container *ngIf="loading$ | async">
        <span class="indicator-label m-5" [style.display]="'block'">
          Please wait...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </ng-container>

      <thead>
        <tr class="fw-bold text-gray-700 text-uppercase fs-7">
          <th class="max-w-70px">Status</th>
          <th class="min-w-150px">Event</th>
          <th class="min-w-120px">Created</th>
        </tr>
      </thead>

      <tbody class="fs-6 fw-semibold">

        <tr *ngIf="(loading$ | async) === false && (webhookEventsEntities$ | async)?.length === 0">
          <td>No Events</td>
        </tr>

        <tr [ngClass]="{ 'bg-light': event?._id === entity?.event_id }"
          *ngFor="let entity of (webhookEventsEntities$ | async)" (click)="setSelected(entity?.webhook_event)">
          <ng-container *ngIf="entity">
            <td>
              <span class="badge" [ngClass]="{
                'badge-light-success': entity?.webhook_event?.status < 400,
                'badge-light-danger': entity?.webhook_event?.status >= 400
              }">
                {{ entity?.webhook_event?.status }}
              </span>
            </td>

            <td>
              {{ entity?.webhook_event?.history?.event_name }}
            </td>

            <td>
              {{ entity?.webhook_event?.created_at | date }}
            </td>

          </ng-container>
        </tr>
      </tbody>
    </table>

    <launchpoint-pagination [pagination]="pagination$ | async" (paginate)="paginate($event)" (limit)="limit($event)" />

  </div>
</div>