import { Component, Input } from '@angular/core';
import { ICoreBlog, ICoreBlogAuthor } from '@launchpoint/core-types';

@Component({
  selector: 'sd-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrl: './blog-card.component.scss',
})
export class BlogCardComponent {
  @Input() blog: ICoreBlog;

  getAuthor(user: ICoreBlogAuthor) {
    if (user?.profile?.full_name) {
      return user.profile.full_name;
    }

    const name = `${user?.profile?.first_name} ${user?.profile?.last_name}`;

    return name.trim();
  }
}
