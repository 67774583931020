import { INotificationProperty } from '../notifications.interface';

export class INotificationPropertyParamsCreate {
  _id: string;
  property: INotificationProperty;
}

export class INotificationPropertyParamsUpdate {
  _id: string;
  property_id: string;
  property: INotificationProperty;
}

export class INotificationPropertyParamsDelete {
  _id: string;
  property_id: string;
}
