<button
  class="font-courierNew group relative flex cursor-pointer items-center justify-center overflow-hidden font-semibold no-underline"
  [ngClass]="{
    'w-40 px-4 py-2 text-sm': size === 'small',
    'w-60 px-6 py-3 text-base': size === 'medium',
    'w-80 px-8 py-4 text-lg': size === 'large',
    'border-primary text-primary border-2': type === 'primary',
    'bg-black': type === 'dark',
  }"
>
  <span
    class="relative z-10 transition-all duration-500 ease-in-out group-hover:-translate-x-8"
    [ngClass]="{
      'text-primary': type === 'primary',
      'text-white': type === 'dark',
    }"
  >
    {{ text }}
  </span>
  <span
    class="absolute inset-y-0 right-full flex items-center justify-center transition-all duration-500 ease-in-out group-hover:right-0"
    [ngClass]="{
      'bg-primary': type === 'primary',
      'bg-white': type === 'dark',
      'w-[20%] text-xs': size === 'small',
      'w-[28%] text-sm': size === 'medium',
      'w-[36%] text-base': size === 'large',
    }"
  >
    <i class="fa-solid fa-arrow-right text-black"></i>
  </span>
</button>
