import { Component } from '@angular/core';
import { ResourcesBaseComponent } from './components/resources-base.component';
import { COMMUNITY_DATA, TRAINING_CARDS } from './data/resources-data';

@Component({
  selector: 'sd-resources',
  templateUrl: 'resources.component.html',
  styleUrls: ['resources.component.scss'],
})
export class ResourcesComponent extends ResourcesBaseComponent {
  items = COMMUNITY_DATA;
  trainingCards = TRAINING_CARDS;
}
