import { Component, HostBinding, inject } from '@angular/core';
import { LaunchpointCoreClientAuthSetPasswordBaseComponent } from '@launchpoint/core-client';
import { LaunchpointSweetAlertService } from '../../../../../components/swal.service';

@Component({
  selector: 'launchpoint-core-web-auth-v2-set-password',
  templateUrl: './auth-v2-set-password.component.html',
  styleUrls: ['./auth-v2-set-password.component.scss'],
})
export class LaunchpointCoreWebAuthV2SetPasswordComponent extends LaunchpointCoreClientAuthSetPasswordBaseComponent {
  _swalService = inject(LaunchpointSweetAlertService);
  pVisible = false;
  cVisible = false;

  override toast(params) {
    this._swalService.fireToast(params);
  }

  togglePass() {
    this.pVisible = !this.pVisible;
  }

  toggleConfirm() {
    this.cVisible = !this.cVisible;
  }

  get passwordMeterType() {
    let type = '';

    if (this.passwordMeterValue < 33) {
      type += 'danger';
    } else if (this.passwordMeterValue >= 33 && this.passwordMeterValue < 66) {
      type += 'warning';
    } else if (this.passwordMeterValue >= 66) {
      type += 'success';
    }

    return `${type} rounded`;
  }

  @HostBinding('class.d-flex')
  @HostBinding('class.flex-center')
  @HostBinding('class.flex-column')
  @HostBinding('class.flex-lg-row-fluid')
  showPassword(event) {
    const x = document.getElementById('input-password') as HTMLElement;
    event.target.classList.contains('fa-eye')
      ? event.target.classList.replace('fa-eye', 'fa-eye-slash')
      : event.target.classList.replace('fa-eye-slash', 'fa-eye');
    event.target.classList.contains('fas') && x['type'] === 'password' ? (x['type'] = 'text') : (x['type'] = 'password');
  }
  // convenience getter for easy access to form fields
}
