import { VoiceChannelRequest, UpdateVoiceChannelCommandInput } from '@aws-sdk/client-pinpoint';
import { EPinpointChannelType } from '../..';

export class ILaunchpointCorePinpointVoiceChannelParamsUpdate implements UpdateVoiceChannelCommandInput {
  _id: string;
  ApplicationId: string;
  type?: EPinpointChannelType.VOICE;
  VoiceChannelRequest: IVoiceChannelRequest;
}

class IVoiceChannelRequest implements VoiceChannelRequest {
  Enabled?: boolean | undefined;
}
