<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <launchpoint-toolbar [options]="options" (create)="create()" (refresh)="refresh()">
      <launchpoint-alert-banner-filter />
    </launchpoint-toolbar>

    <div class="card card-flush">
      <div class="card-body">
        <launchpoint-alert-banner-table />
      </div>
    </div>
  </div>
</div>