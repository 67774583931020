import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LibCoreClientPipesModule, USERS_EFFECTS, UsersFeatureKey, UsersReducer } from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TagifyModule } from 'ngx-tagify';
import { LaunchpointCoreWebToolbarComponent } from '../../components/toolbar/toolbar.component';
import { LaunchpointFormControlDateRangeComponent } from '../components/from-control/launchpoint-date-range-form-control/launchpoint-date-range-form-control.component';
import { UserViewsModule } from '../views/user-views.module';
import { UsersTableFilterCardComponent } from './users-table/users-table-filter-card/users-table-filter-card.component';
import { UsersTableFilterComponent } from './users-table/users-table-filter/users-table-filter.component';
import { UsersTableSmartAdminComponent } from './users-table/users-table-smart-admin/users-table-smart-admin.component';
import { UsersTableSmartUserComponent } from './users-table/users-table-smart-user/users-table-smart-user.component';
import { UsersTableComponent } from './users-table/users-table/users-table.component';
import { LaunchpointCoreWebPaginationComponent } from '../../components/pagination/pagination.component';

const USERS_COMPONENTS = [
  UsersTableSmartUserComponent,
  UsersTableSmartAdminComponent,
  UsersTableComponent,
  UsersTableFilterCardComponent,
  UsersTableFilterComponent,
];

@NgModule({
  declarations: [...USERS_COMPONENTS],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LibCoreClientPipesModule,
    LaunchpointCoreWebToolbarComponent,
    LaunchpointFormControlDateRangeComponent,
    LaunchpointCoreWebPaginationComponent,
    UserViewsModule,
    TagifyModule,
    // State
    StoreModule.forFeature(UsersFeatureKey, UsersReducer),
    EffectsModule.forFeature(USERS_EFFECTS),
  ],
  providers: [LaunchpointFormControlDateRangeComponent],
  exports: [...USERS_COMPONENTS],
})
export class LaunchpointCoreWebUsersModule {}
