<div class="bg-medium py-10 md:py-20">
  <div class="mx-auto max-w-[1700px] px-4">
    <div class="mb-6 inline-flex items-end justify-between md:mb-20">
      <div class="space-y-4 md:space-y-10 xl:w-[75%] 2xl:w-[60%]">
        <h3 class="font-oswald md_ text-[30px] font-semibold uppercase md:text-[45px] lg:text-[54px]">
          Built on Purpose: <br />How My Passions Fuel My Business
        </h3>
        <p class="text-light text-sm text-black md:pr-40 md:text-[16px] lg:text-[20px]">
          I believe in living a life driven by values and purpose. Each of my passions—faith, family, fitness, cars, business, and AI—fuels my work
          and inspires the way I build solutions for businesses. Here's how they shape who I am.
        </p>
      </div>
      <div class="mt-10 hidden justify-end gap-2 text-white md:flex">
        <button (click)="scrollLeft()" class="bg-[#010101] px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#01010160]">&larr;</button>
        <button (click)="scrollRight()" class="bg-[#010101] px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#01010160]">&rarr;</button>
      </div>
    </div>
    <div class="relative">
      <div
        #scrollContainer
        class="flex snap-x snap-mandatory gap-2 overflow-x-auto scroll-smooth md:space-x-10"
        style="-ms-overflow-style: none; scrollbar-width: none; overflow: -moz-scrollbars-none"
      >
        @for (item of purposeData; track $index) {
          <div class="relative h-[384px] w-[277px] flex-shrink-0 overflow-hidden border-[1px] border-black p-2 md:h-[972px] md:w-[700px] md:p-4">
            <div class="relative h-full w-full">
              <img src="{{ item.image }}" alt="purpose image 1" class="h-full w-full object-cover" />

              <div class="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent"></div>

              <div class="absolute bottom-4 left-4 right-4 space-y-4 px-4 text-center text-white md:bottom-12 md:px-24">
                <h3 class="font-oswald text-[23px] font-semibold uppercase md:text-[40px]">{{ item.title }}</h3>
                <p class="text-[12px] leading-tight md:text-[30px]">{{ item.description }}</p>
              </div>
            </div>
          </div>
        }
      </div>
      <div class="from-light pointer-events-none absolute right-0 top-0 h-full w-24 bg-gradient-to-l to-transparent"></div>
    </div>
    <div class="mt-10 flex justify-end gap-2 text-white md:hidden">
      <button (click)="scrollLeft()" class="bg-[#010101] px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#01010160]">&larr;</button>
      <button (click)="scrollRight()" class="bg-[#010101] px-4 py-2 transition-colors duration-300 ease-in-out hover:bg-[#01010160]">&rarr;</button>
    </div>
  </div>
</div>
