import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { LaunchpointFormControlDateRangeBaseComponent } from '@launchpoint/core-client';
import { ELaunchpointPresetDateRange, ILaunchpointSearchFilterDates, LaunchpointDateClass } from '@launchpoint/core-types';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

export interface ILaunchpointDateRangeOptions {
  maxDate?: NgbDate;
  minDate?: NgbDate;
  startDate?: NgbDate;
  autoClose?: boolean | 'inside' | 'outside';
  displayMonths?: number;
  outsideDays?: 'visible' | 'collapsed' | 'hidden';
  utc?: boolean;
  debug?: boolean;
  preset?: boolean;
  readonly?: boolean;
}

@Component({
  selector: 'launchpoint-date-range',
  templateUrl: 'launchpoint-date-range-form-control.component.html',
  styleUrls: ['./launchpoint-date-range-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LaunchpointFormControlDateRangeComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbModule],
})
export class LaunchpointFormControlDateRangeComponent extends LaunchpointFormControlDateRangeBaseComponent implements ControlValueAccessor {
  /**
   * The selected checkboxes.
   */
  @Input() selected: ILaunchpointSearchFilterDates;
  /**
   * The list of checkbox items.
   *
   * As an input it also allows the values to be written over by to parent component
   */
  @Input() allOptions: ILaunchpointDateRangeOptions = {
    maxDate: null,
    minDate: null,
    startDate: new NgbDate(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate()),
    autoClose: 'outside',
    displayMonths: 2,
    outsideDays: 'hidden',
    utc: false,
    debug: false,
    preset: true,
    readonly: false,
  };

  @Input() preSetQueries: ELaunchpointPresetDateRange[] = Object.values(ELaunchpointPresetDateRange);
  @Input() fromDate: NgbDate | null;
  @Input() toDate: NgbDate | null;

  presetSelection: ELaunchpointPresetDateRange = null;

  hoveredDate: NgbDate | null = null;

  maxDate: NgbDate | null;
  minDate: NgbDate | null;
  // startDate = { year: new Date().getFullYear() - 21, month: 1 };

  /**
   * This is not required for the form control BUT it does give the parent access to more data from the selected object if needed.
   * Pattern is to add `Changed` to the end of the value
   */
  @Output() selectedChanged = new EventEmitter<ILaunchpointSearchFilterDates>();

  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter) {
    super();
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  resetDates() {
    this.fromDate = null;
    this.toDate = null;
  }

  onDateSelection(date: NgbDate | null) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.allOptions.debug) {
      console.log('fromDate', this.fromDate);
      console.log('toDate', this.toDate);
    }
    this.presetSelection = null;
    if (this.toDate && this.fromDate) {
      if (this.allOptions.utc) {
        this.allOptions.debug && console.log('utc');
        this.adjustValue({
          start_search_date: new Date(Date.UTC(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, 0, 0, 0,0)),
          end_search_date: new Date(Date.UTC(this.toDate.year, this.toDate.month - 1, this.toDate.day, 23, 59, 59, 999)),
        });
      } else {
        this.allOptions.debug && console.log('not utc');

        const fromDate = new Date(`${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`);
        fromDate.setHours(0, 0, 0, 0);

        const toDate = new Date(`${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`);
        toDate.setHours(23, 59, 59, 999);


        this.adjustValue({
          start_search_date: fromDate,
          end_search_date: toDate,
        });
      }
    }
  }

  writeValue(value: ILaunchpointSearchFilterDates) {
    if (this.allOptions.debug) {
      console.log('selector:::::writeValue', value);
    }
    if (value) {
      this.selected = value;
    } else {
      this.selected = value;
      this.fromDate = null;
      this.toDate = null;
    }
  }

  adjustValue(value: ILaunchpointSearchFilterDates) {
    if (this.allOptions.debug) {
      console.log('selector:::::adjustValue', value);
    }
    if (!value) {
      this.fromDate = null;
      this.toDate = null;
    }
    this.selected = value;
    this.onChanged(this.selected);
    this.onTouched();
    this.selectedChanged.emit(this.selected);
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  defaultQueries(value: ELaunchpointPresetDateRange) {
    const dates = LaunchpointDateClass;
    this.presetSelection = value;
    switch (value) {
      case ELaunchpointPresetDateRange.THIS_MONTH:
        this.adjustValue(dates.getThisMonth(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.THIS_QUARTER:
        this.adjustValue(dates.getThisQuarter(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.THIS_YEAR:
        this.adjustValue(dates.getThisYear(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.NEXT_MONTH:
        this.adjustValue(dates.getNextMonth(this.allOptions.utc));
        break;
      // case ELaunchpointPresetDateRange.NEXT_QUARTER:
      //   this.adjustValue(dates.getNextQuarter(this.allOptions.utc));
      //   break;
      case ELaunchpointPresetDateRange.LAST_MONTH:
        this.adjustValue(dates.getLastMonth(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.LAST_YEAR:
        this.adjustValue(dates.getLastYear(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.LAST_QUARTER:
        this.adjustValue(dates.getLastQuarter(this.allOptions.utc));
        break;
      case ELaunchpointPresetDateRange.YEAR_TO_DATE:
        this.adjustValue(dates.getYearToDate(this.allOptions.utc));
        break;

      default:
        break;
    }
  }
}
