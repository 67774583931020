import { Inject, Injectable } from '@angular/core';
import { AbilityBuilder, AnyMongoAbility, MongoQuery, PureAbility, buildMongoQueryMatcher } from '@casl/ability';
import { ICoreUser, ISecurityConfigACLRole, LaunchpointSecurity } from '@launchpoint/core-types';
import { ILaunchpointSecurityModuleConfig, LAUNCHPOINT_SECURITY_CONFIG_TOKEN } from '../interfaces/security.interface';

function createDynamicClass(className: string, jsonData: any) {
  const dynamicClass = class {
    constructor() {
      Object.assign(this, jsonData);
    }
  };

  Object.defineProperty(dynamicClass, 'name', { value: className });

  return dynamicClass;
}
@Injectable()
export class LaunchpointAbilitySecurityService {
  private security = new LaunchpointSecurity();
  builder = new AbilityBuilder<AnyMongoAbility>(PureAbility);

  // private aclRegistrationBS = new BehaviorSubject<IACLRegistration[]>([])
  // public aclRegistration$ = this.aclRegistrationBS.asObservable()
  public aclRegistration: any[];

  constructor(@Inject(LAUNCHPOINT_SECURITY_CONFIG_TOKEN) public securityConfig: ILaunchpointSecurityModuleConfig) {}

  checkAbility(user: Partial<ICoreUser> | unknown, action: string, subject: string, data?: any) {
    // console.log({ securityConfig: this.securityConfig.security_modules });
    let Subject = subject as any;
    if (data) {
      const classObject = createDynamicClass(subject, data);
      // if(this.securityConfig)
      // console.log('CLASS NAME', classObject.name);
      Subject = new classObject();
    }
    const ability = this.getAbility(user);
    // console.log(ability.rules);
    // console.log({ Subject });
    return ability.can(action, Subject);
  }

  getRolesAndActions() {
    const allRolesAndActions: {
      role: string;
      actions: any[];
    }[] = [];
    // const roles = this.securityConfig.security_modules.flatMap((f) => Object.keys(f.acl) as string[]);
    // for (const role of roles) {
    //   const roleActions = {
    //     role,
    //     actions: [],
    //   };
    //   for (const module of this.securityConfig.security_modules) {
    //     if (module.acl[role]) {
    //       const actions = module.acl[role].map((roleAction) => roleAction.permissions).flatMap((permission) => permission.map((p) => p.actions));
    //       if (typeof actions === 'string') {
    //         roleActions.actions.push(actions);
    //       } else {
    //         roleActions.actions.push(...actions);
    //       }
    //     }
    //   }
    //   allRolesAndActions.push(roleActions);
    // }
    return allRolesAndActions;
  }

  private getAbility(user: Partial<ICoreUser>) {
    this.builder = new AbilityBuilder<AnyMongoAbility>(PureAbility);
    const account = user?.accounts.find((f) => f?.selected);
    const SECURITY_ROLES = this.security.securityGetUserRoles(user, {
      account_id: account?.account_id as string,
      user_id: account?.user_id as string,
    });
    // console.log({ SECURITY_ROLES });
    for (let i = 0; i < SECURITY_ROLES.length; i++) {
      const role = SECURITY_ROLES[i];
      /**
       * Current security role.
       * @type {string}
       */

      // this.log('role', element);

      // Retrieve role permissions based on the current security role
      const rolePermissions: ISecurityConfigACLRole[] = [];
      for (const security of this.securityConfig.security_modules) {
        if (security?.acl[role]) {
          rolePermissions.push(...security.acl[role]);
        }
      }
      // rolePermissions.forEach((rolePermission) => {
      //   console.log({ rolePermission });
      // });
      // Check if role permissions exist
      if (!rolePermissions) {
        continue;
      }

      // Iterate over role permissions and perform permission checks
      for (let rpi = 0; rpi < rolePermissions.length; rpi++) {
        let accountData = {};
        if (account) {
          const { _id, ...account_data } = account;
          accountData = account_data;
        }
        /**
         * Extract account_data from the account object, excluding the _id field.
         * @type {object}
         */

        const user_id = account?.user_id ?? user?._id;
        const account_id = account?.account_id;

        // console.log({ ...user, ...accountData });
        this.permissionCheck(rolePermissions[rpi], { ...user, ...accountData, user_id, account_id });
        /**
         * Perform permission check using the role permission, combined user and account data, and the builder object.
         */
      }
    }
    return this.builder.build({ conditionsMatcher: buildMongoQueryMatcher() });
  }

  private permissionCheck(role: ISecurityConfigACLRole, data: any) {
    if (role.effect === 'can') {
      role.permissions.forEach((p) => {
        if (Array.isArray(role.conditions)) {
          role.conditions.forEach((condition) => {
            const con: MongoQuery = this.security.securityReplace(condition, data);
            // this.log('QUERY', con);
            if (role.fields) {
              this.builder.can(p.actions, p.subject, role.fields, con);
            } else {
              // this.log('p.subject', p.subject);
              this.builder.can(p.actions, p.subject, con);
            }
          });
        } else {
          const con: MongoQuery = this.security.securityReplace(role.conditions, data);
          // this.log('QUERY', con);
          if (role.fields) {
            this.builder.can(p.actions, p.subject, role.fields, con);
          } else {
            this.builder.can(p.actions, p.subject, con);
          }
        }
      });
    } else {
      role.permissions.forEach((p) => {
        if (Array.isArray(role.conditions)) {
          role.conditions.forEach((condition) => {
            const con: MongoQuery = this.security.securityReplace(condition, data);
            // this.log('QUERY', con);
            if (role.fields) {
              this.builder.cannot(p.actions, p.subject, role.fields, con);
            } else {
              this.builder.cannot(p.actions, p.subject, con);
            }
          });
        } else {
          const con: MongoQuery = this.security.securityReplace(role.conditions, data);
          // this.log('QUERY', con);
          if (role.fields) {
            this.builder.cannot(p.actions, p.subject, role.fields, con);
          } else {
            this.builder.cannot(p.actions, p.subject, con);
          }
        }
      });
    }
    return this.builder;
  }
}
