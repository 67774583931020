import { Directive } from '@angular/core';
import { LaunchpointCoreClientBaseComponent } from '@launchpoint/core-client';


@Directive()
export abstract class AppBaseComponent extends LaunchpointCoreClientBaseComponent {
  /**
   * Define common root app component behavior for all web apps here
   */
}
