import { Type } from 'class-transformer';
import { IsBoolean, IsOptional, IsString, ValidateNested } from 'class-validator';
import 'reflect-metadata';
import { ICoreUserAdmin, ICoreUserProfile } from '../../../user-company-account.interface';

export class ICoreUserAccountsParamsAddSubUserAccountProfile implements Partial<ICoreUserProfile> {
  first_name: string;
  birthday: Date;
  last_name?: string;
  full_name?: string;
}

export class ICoreUserAccountsParamsSwapAccount {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;

  /**
   * The account._id we want to swap to
   * If this is null, it will deselect all accounts
   */
  @IsString()
  account_id: string;
}

export class ICoreUserAccountsParamsAddSubUserAccount {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;

  /**
   * The display name of the account
   */
  @IsString()
  account_name: string;

  /**
   * we leave in the requirement for consent forms
   */
  @ValidateNested()
  @Type(() => ICoreUserAdmin)
  admin: ICoreUserAdmin;
  /**
   * Each client can have different account types
   * so just a string here but may be passed from
   * an emum in the client.
   */
  @IsString()
  account_type: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ICoreUserAccountsParamsAddSubUserAccountProfile)
  profile?: ICoreUserAccountsParamsAddSubUserAccountProfile;
}

export class ICoreUserAccountsParamsAddMainUserAccount {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;

  /**
   * email of the user to add to the accounts array
   */
  @IsString()
  user_to_add_email: string;

  /**
   * The display name of the account
   */
  @IsString()
  account_name: string;

  /**
   * Each client can have different account types
   * so just a string here but may be passed from
   * an emum in the client.
   */
  @IsString()
  account_type: string;

  @IsOptional()
  @ValidateNested()
  @Type(() => ICoreUserAccountsParamsAddSubUserAccountProfile)
  profile?: ICoreUserAccountsParamsAddSubUserAccountProfile;
}

export class ICoreUserAccountsParamsGetPendingInvitations {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;
}
export class ICoreUserAccountsParamsRemove {
  /**
   * _id of the user MAKING the request
   */
  @IsString()
  requesting_user_id: string;

  /**
   * _id of the ACCOUNT OBJECT
   * It should be account._id
   * NOT account.account_id
   */
  @IsString()
  _id: string;
}

export class ICoreUserAccountsUserPendingInvitations {
  @IsString()
  inviting_user_email: string;

  @IsString()
  inviting_user_id: string;

  @IsString()
  @IsOptional()
  inviting_user_first_name?: string;

  @IsString()
  @IsOptional()
  inviting_user_last_name?: string;
  /**
   * this is user.accounts._id
   */
  @IsString()
  account_id: string;

  /**
   * _id of the invitation object in the collection
   */
  @IsString()
  invitation_id: string;
}

export class ICoreUserAccountsUserInvitationsDecision extends ICoreUserAccountsUserPendingInvitations {
  @IsBoolean()
  invitation_accepted: boolean;
}

export class ICoreUserAccountsParamsAcceptInvitations {
  /**
   * _id of the user who wants to accept the invitations
   */
  @IsString()
  _id: string;

  /**
   * A list of only the invitations we want
   */
  @ValidateNested({ each: true })
  @Type(() => ICoreUserAccountsUserInvitationsDecision)
  invitations: ICoreUserAccountsUserInvitationsDecision[];
}

export class ICoreUserAccountsParamsResendInvite {
  /**
   * _id of the PARENT user, the person who originally
   * invited the other person.
   */
  @IsString()
  user_id: string;

  /**
   * account._id NOT account.account_id, this is so I
   * know which account from the parent user to resend
   * the invite for
   */
  @IsString()
  account_id: string;
}

export class ICoreUserAccountsParamsFindWhereUserInAccounts {
  /**
   * _id of the user we want to find on other users' accounts
   */
  @IsString()
  user_id: string;
}

export class ICoreUserAccountsParamsUpgradeSubUserToMainUser {
  /**
   * _id of the PARENT user, the person who originally
   * invited the other person.
   */
  @IsString()
  user_id: string;

  /**
   * account._id NOT account.account_id
   */
  @IsString()
  account_id: string;

  /**
   * The email to assign for the user going from a sub account to a main account.
   */
  @IsString()
  user_email: string;
}
