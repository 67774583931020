import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';

import { CompanyCreateModalBaseComponent } from '@launchpoint/core-client';

@Component({
  selector: 'launchpoint-company-create-modal',
  templateUrl: 'company-create-modal.component.html',
})
export class CompanyCreateModalComponent extends CompanyCreateModalBaseComponent {
  constructor(private activeModal: NgbActiveModal) {
    super();
  }

  close() {
    this.activeModal.close();
  }
}
