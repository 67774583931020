export interface ILayoutMenuItem {
  section: string;
  separator: string;
  title: string;
  root: boolean;
  bullet: string;
  svg: string;
  icon: string;
  page?: string;
  // membership_status: string[];
  /**
   * This is an array of actions
   */
  security?: { action: string; resource: string };
  external_url?: string;
  submenu: ILayoutMenuSubMenu[];
}

type ILayoutMenuSubMenu =
  | {
      title: string;
      page?: string;
      /**
       * This is an array of actions
       */
      security?: { action: string; resource: string };
      submenu?: ILayoutMenuSubMenuLevel2[];
      svg?: string;
      icon?: string;
      external_url?: string;
      separator?: never;
      section?: never;
    }
  | { separator: string }
  | { section: string };

type ILayoutMenuSubMenuLevel2 =
  | {
      title: string;
      page?: string;
      svg?: string;
      icon?: string;
      external_url?: string;
      security?: { action: string; resource: string };
      separator?: never;
      section?: never;
    }
  | { separator: string }
  | { section: string };

export interface LaunchpointLayoutMenu {
  logo_dark: string;
  logo_light: string;
  items: Partial<ILayoutMenuItem>[];
  footers?: {
    title: string;
    page: string;
    svg: string;
    membership_status?: string[];
    /**
     * This is an array of actions
     */
    security?: string[];
  }[];
}
