<tagify
  class="me-5"
  [ngModel]="categoriesModel"
  (ngModelChange)="selectData($event)"
  [whitelist]="whitelistCategories$"
  [settings]="settings"
  [inputClass]="inputClass"
  name="categories"
/>

