import { Directive } from '@angular/core';
import {
  ICoreActivityHistoryWebhook,
  ICoreActivityHistoryWebhookParamsCreate,
  ICoreActivityHistoryWebhookParamsUpdate,
  ICoreActivityHistoryWebhookSearchQuery,
  IHttpException,
  IQueryPageination,
  IQuerySort,
} from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { Observable, takeUntil } from 'rxjs';
import { LaunchpointCoreClientBaseComponent } from '../../../../components/base/base.component';
import { WebhooksAction } from './actions';
import { WebhooksEntityState } from './interfaces/webhooks.interface';
import {
  selectSelectedWebhooks,
  selectSelectedWebhooksError,
  selectSelectedWebhooksLoading,
  selectSelectedWebhooksSuccess,
  selectWebhooks,
  selectWebhooksError,
  selectWebhooksLoading,
  selectWebhooksPagination,
  selectWebhooksQuery,
  selectWebhooksQuerySort,
  selectWebhooksSuccess,
  selectWebhooksSuccessQueryCount,
} from './selectors/webhooks.selectors';

@Directive()
export abstract class LaunchpointCoreClientWebhooksStateBaseComponent extends LaunchpointCoreClientBaseComponent {
  loading$: Observable<boolean>;
  pagination$: Observable<IQueryPageination>;
  querySort$: Observable<IQuerySort>;
  query$: Observable<ICoreActivityHistoryWebhookSearchQuery>;
  queryFilterCount$: Observable<number>;

  error$: Observable<IHttpException | null>;
  success$: Observable<string | null>;

  webhooksEntities$: Observable<WebhooksEntityState[]>;

  webhook$: Observable<ICoreActivityHistoryWebhook>;
  webhookLoading$: Observable<boolean>;
  webhookError$: Observable<IHttpException>;
  webhookSuccess$: Observable<string>;

  constructor(public _Store: Store) {
    super();
    this.loading$ = this._Store.select(selectWebhooksLoading);
    this.pagination$ = this._Store.select(selectWebhooksPagination);
    this.querySort$ = this._Store.select(selectWebhooksQuerySort);
    this.query$ = this._Store.select(selectWebhooksQuery);
    this.queryFilterCount$ = this._Store.select(selectWebhooksSuccessQueryCount);

    this.error$ = this._Store.select(selectWebhooksError);
    this.success$ = this._Store.select(selectWebhooksSuccess);

    this.webhooksEntities$ = this._Store.select(selectWebhooks);

    // Entity
    this.webhook$ = this._Store.select(selectSelectedWebhooks);
    this.webhookLoading$ = this._Store.select(selectSelectedWebhooksLoading);
    this.webhookError$ = this._Store.select(selectSelectedWebhooksError);
    this.webhookSuccess$ = this._Store.select(selectSelectedWebhooksSuccess);
  }

  getWebhooks(webhook_id: string) {
    this._Store.dispatch(WebhooksAction.getWebhookById({ webhook_id }));
  }
  createWebhook(data: ICoreActivityHistoryWebhookParamsCreate) {
    this._Store.dispatch(WebhooksAction.createWebhook({ data }));
  }
  updateWebhook(data: ICoreActivityHistoryWebhookParamsUpdate) {
    this._Store.dispatch(WebhooksAction.updateWebhook({ data }));
  }
  deleteWebhook(webhook_id: string, redirectPath?: string[]) {
    this._Store.dispatch(WebhooksAction.deleteWebhook({ webhook_id, redirectPath }));
  }

  success() {
    this.success$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
    this.webhookSuccess$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (success) => {
        if (success) {
          this.toast({ title: success, icon: 'success' });
        }
      },
    });
  }

  error() {
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
    this.webhookError$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        if (error) {
          this.toast({ title: error, icon: 'error' });
        }
      },
    });
  }

  toast(params) {
    //
  }
}
