import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EIDMeScopes } from '@launchpoint/core-types';
import { AdminCoreUserIDMeComponent } from './id-me-admin-override/id-me-admin-override.component';
import { CoreUserIDMeComponent } from './id-me.component';

export const APP_ID_ME_CONFIG = new InjectionToken<EIDMeScopes[]>('APP_ID_ME_CONFIG');

@NgModule({
  declarations: [CoreUserIDMeComponent, AdminCoreUserIDMeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [CoreUserIDMeComponent, AdminCoreUserIDMeComponent],
})
export class CoreUserIDMeModule {}
