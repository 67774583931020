import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { COMPANY_SERVICES } from '@launchpoint/core-client';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreWebAddressAutoCompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { LaunchpointCoreWebProfileModule } from '../user/profile/profile.module';
import { LaunchpointCoreWebUserSearchAheadComponent } from '../users/user-search-ahead-standalone/user-search-ahead.component';
import { LaunchpointCoreWebUsersModule } from '../users/users-core.module';
import { LaunchpointCompanyWebModule } from './company.module';
import { COMPANY_SIGNUP_COMPONENTS } from './features/sign-up';

const MODULES = [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, NgbModule];

const IMPORTS = [LaunchpointCoreWebAddressAutoCompleteComponent, LaunchpointCoreWebProfileModule];
@NgModule({
  imports: [...MODULES, ...IMPORTS, LaunchpointCoreWebUserSearchAheadComponent, LaunchpointCoreWebUsersModule, LaunchpointCompanyWebModule],
  exports: [...COMPANY_SIGNUP_COMPONENTS],
  declarations: [...COMPANY_SIGNUP_COMPONENTS],
  providers: [...COMPANY_SERVICES],
})
export class LaunchpointCompanyWebSignUpWebModule {}
