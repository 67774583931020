import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { animate_css } from '../interfaces/animate-css.type';
import { AnimateCssService } from '../services/animate-css.service';

/**
 * EXAMPLE: animateCssClick="animate__flipInX"
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[animateCssClick]',
})
export class AnimatecssclickDirective implements OnDestroy {
  @Input() animateCssClick: animate_css | animate_css[];
  constructor(private elementRef: ElementRef, private _AnimateCssService: AnimateCssService) {}

  @HostListener('click') onClick() {
    this._AnimateCssService.addAnimation(this.elementRef, this.animateCssClick);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.elementRef.nativeElement.removeEventListener('animationend', this._AnimateCssService.removeAnimation(this.elementRef, this.animateCssClick));
  }
}
