import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ICoreUser } from '@launchpoint/core-types';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserSelectors } from '../user/auth/+state';

export const LaunchpointCompanyCreatedGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  // your  logic goes here
  const _Store = inject(Store);
  const router = inject(Router);
  return _Store.select(UserSelectors.selectUserInitCheckComplete).pipe(
    filter((value) => !!value),
    mergeMap(() => _Store.select(UserSelectors.selectUser)),
    // skipUntil(this._Store.select(UserSelectors.selectUserInitCheckComplete)),
    map((user: ICoreUser) => {
      if (user?.accounts?.length > 0 && user.accounts[0]?.account_id) {
        return true;
      } else {
        router.navigate(['sign-up'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    })
  );
};
