import { ILaunchpointPermissions, ILaunchpointPermissionsParent, ILaunchpointSecuriyRoles } from './acl.constants.config';

/**
 * Generic interface for the non account based access control list.
 * use `ILaunchpointACLSecurityRoles` for a typed default list.
 */
export interface ILaunchpointACL {
  [key: string]: {
    // CAN VIEW ALL BY DEFAULT
    [key: string]: string | string[] | null;
  };
}

// export type IACLSecurityType = { [key in string]: IACLSecurityRoles };
/**
 * This can be used as a default TYPED ACL
 */
export type ILaunchpointACLSecurityRoles = {
  [key in ILaunchpointSecuriyRoles]: IACLSecurityPermissions & IACLSecurityPermissionsParent;
} & ILaunchpointACL;

export type IACLSecurityPermissions = {
  [key in ILaunchpointPermissions]: string | string[];
};

export type IACLSecurityPermissionsParent = {
  [key in ILaunchpointPermissionsParent]: ILaunchpointSecuriyRoles | ILaunchpointSecuriyRoles[] | null;
};

export const LAUNCHPOINT_ACCESS_CONTROL_LIST: ILaunchpointACLSecurityRoles = {
  super_admin: {
    parent_of: 'owner',
    search: '*',
    view: '*',
    create: '*',
    update: '*',
    add: '*',
    remove: '*',
    delete: '*',
  },
  owner: {
    parent_of: 'admin',
    search: '*',
    view: '*',
    create: '*',
    add: '*',
    update: '*',
    remove: '*',
    delete: '*',
  },
  analyst: {
    parent_of: 'team',
    search: '*',
    view: '*',
    create: '*',
    add: '*',
    update: '*',
    remove: '*',
    delete: '*',
  },
  admin: {
    search: '*',
    parent_of: 'team',
    view: '*',
    create: '*',
    add: '*',
    update: '*',
    remove: '*',
    delete: '*',
  },
  team: {
    parent_of: null,
    search: '*',
    view: '*',
    create: '*',
    add: '*',
    update: '*',
    remove: '*',
    delete: '*',
  },
};
