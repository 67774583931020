<form [formGroup]="form">
  <div class="px-7 py-5 d-flex align-items-center justify-content-between">
    <div class="fs-5 text-dark fw-bold">Filter Options</div>
  </div>

  <div class="separator border-gray-200"></div>

  <div class="px-7 mt-5">

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Events</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form?.controls?.events?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all types." (click)="clearEvents()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <tagify formControlName="events" name="events" class="me-5" [settings]="settings" [whitelist]="whitelistEvents$"
          inputClass="form-control form-control-sm ps-4 bg-white"></tagify>
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Users</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form?.controls?.users?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all types." (click)="clearUsers()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-users-tagify (selectedUser)="setUser($event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Notifications</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form?.controls?.notifications?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all notifications." (click)="clearNotifications()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-notifications-tagify (selectedNotification)="setNotification($event)" />
      </div>
    </div>

    <div class="mb-2 px-3">
      <div class="d-flex justify-content-between me-4">
        <label class="form-label fw-semibold">Messages</label>
        <div>
          <i class="fs-4 bi bi-x-square cursor-pointer" *ngIf="form?.controls?.messages?.value?.length > 0"
            animateCss="tada" ngbTooltip="Clear all messages." (click)="clearMessages()"></i>
        </div>
      </div>
      <div class="menu-item mb-3">
        <launchpoint-notifications-messages-tagify (selectedMessage)="setMessage($event)" />
      </div>
    </div>

    <div class="menu-item px-3">
      <label class="form-label fw-semibold">Read</label>
      <launchpoint-boolean-filter [options]="options" [active]="form?.controls?.is_read?.value"
        (selected)="setRead($event)" />
    </div>

    <div class="menu-item px-3">
      <label class="form-label fw-semibold">Delivered</label>
      <launchpoint-boolean-filter [options]="options" [active]="form?.controls?.is_read?.value"
        (selected)="setDelivered($event)" />
    </div>

    <div class="menu-item px-3">
      <label class="form-label fw-semibold">Created At</label>
      <launchpoint-date-range formControlName="created_at" [allOptions]="{ debug: false }" />
    </div>
  </div>


  <div class="rounded-bottom pb-3">
    <div class="d-flex justify-content-between align-items-center m-5 px-4">
      <div>
        <a class="text-dark cursor-pointer text-hover-primary text-decoration-underline" (click)="reset()">
          Clear All
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-sm btn-primary ms-4" (click)="submit()">Apply</button>
      </div>
    </div>
  </div>
</form>