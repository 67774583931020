import { Directive, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICoreCompany, ICoreCompanyParamsSignUp, ICoreCompanyParamsUpdate, IHttpException } from '@launchpoint/core-types';
import { ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs';
import { COMPANY_ACTIONS } from '../../+state';
import { CompanyV2Service } from '../../services/company.service';
import { CompanySignUpStateBaseComponent } from './base/company-sign-up-state.base-component';

@Directive()
export abstract class CompanyCreateSmartBaseComponent extends CompanySignUpStateBaseComponent implements OnInit {
  _CompanyV1Service = inject(CompanyV2Service);

  form: FormGroup<{
    account_id?: FormControl<string | null>;
    account_owner: FormControl<string>;
    phone: FormControl<string>;
    account_type: FormControl<string | unknown>;
    company_name: FormControl<string>;
    first_name: FormControl<string>;
    last_name: FormControl<string>;
    email: FormControl<string>;
    year_established: FormControl<number>;
    industry: FormControl<string>;
    business_scale: FormControl<number>;
  }>;

  minYear = 1900;
  maxYear = new Date().getFullYear();

  user_id: string;

  ngOnInit() {
    this.buildForm();

    this.actions$.pipe(ofType(COMPANY_ACTIONS.createCompanySignUpSuccess, COMPANY_ACTIONS.updateCompanySuccess), takeUntil(this.destroy$)).subscribe({
      next: () => {
        // updateUserSuccess;
        this._stepper.nextStep();
      },
    });
    this.error$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (error) => {
        this.onError(error);
      },
    });

    this.user_id$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (user) => {
        if (user) {
          this.user_id = user;
          this.form.patchValue({ account_owner: user });
        }
      },
    });

    this.selectedUserAccount$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (account) => {
        // console.log({ account });
        const company = account?.account_id as ICoreCompany;
        if (company) {
          this.form.patchValue({
            account_id: company?._id ?? null,
            company_name: company?.company_name,
            phone: company?.phone,
            account_type: company?.account_type ?? 'Default',
            email: company?.email,
            year_established: company?.year_established,
            industry: company?.industry,
            business_scale: company?.business_scale,
          });
        }
      },
    });
  }

  onError(error: IHttpException) {
    console.log('Override this onError, error, in the nativescript or web component');
  }

  buildForm() {
    this.form = this.fb.group({
      account_id: [null], // User ID
      account_owner: ['', Validators.required], // User ID
      company_name: ['', [Validators.required, Validators.minLength(3)]],
      phone: [],
      account_type: ['Default'],
      email: [],
      year_established: [null, [Validators.minLength(4), Validators.maxLength(4), Validators.min(this.minYear), Validators.max(this.maxYear)]],
      industry: [],
      business_scale: [],
    });
  }

  submit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.user_id) {
      // console.log('No user_id');
      return;
    }

    const data = this.form.value;

    if (!data?.account_id) {
      // console.log('Creating a company');
      const obj: ICoreCompanyParamsSignUp = {
        account_owner: this.user_id,
        account_type: data?.account_type,
        company_name: data.company_name,
        email: data?.email,
        phone: data?.phone,
        year_established: data?.year_established,
        industry: data?.industry,
        business_scale: data?.business_scale,
      };
      this._Store.dispatch(COMPANY_ACTIONS.createCompanySignUp({ data: obj }));
    } else {
      // console.log('Updating a company');
      const obj: ICoreCompanyParamsUpdate = {
        account_id: data.account_id,
        account_type: data?.account_type as string,
        company_name: data.company_name,
        email: data?.email,
        phone: data?.phone,
        year_established: data?.year_established,
        industry: data?.industry,
        business_scale: data?.business_scale,
      };
      this._Store.dispatch(COMPANY_ACTIONS.updateCompany({ data: obj }));
    }
  }
}
