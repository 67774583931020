import { LaunchpointCoreWebWebhookEventsTableFilterComponent } from './webhook-events-table-filter/webhook-events-table-filter.component';
import { LaunchpointCoreWebWebhookEventsTableComponent } from './webhook-events-table/webhook-events-table.component';
import { LaunchpointCoreWebWebhookEventsToolbarComponent } from './webhook-events-toolbar/webhook-events-toolbar.component';
import { LaunchpointCoreWebWebhookEventDetailComponent } from './webhook-event/webhook-event.component';

export const WEBHOOK_EVENTS_COMPONENTS = [
  //
  LaunchpointCoreWebWebhookEventsToolbarComponent,
  LaunchpointCoreWebWebhookEventsTableComponent,
  LaunchpointCoreWebWebhookEventDetailComponent,
  LaunchpointCoreWebWebhookEventsTableFilterComponent,
];

export * from './webhook-events-table-filter/webhook-events-table-filter.component';
export * from './webhook-events-table/webhook-events-table.component';
export * from './webhook-events-toolbar/webhook-events-toolbar.component';
export * from './webhook-event/webhook-event.component';
