import { Component, inject } from '@angular/core';
import { LaunchpointCoreClientWebhooksBaseComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';
import { IToolbarConfig } from '../../../../../components/toolbar/toolbar.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LaunchpointCoreWebWebhookModalComponent } from '../webhook-modal/webhook-modal.component';

@Component({
  selector: 'launchpoint-webhooks-toolbar',
  templateUrl: 'webhooks-toolbar.component.html',
  styles: [
    `
      .dropdown-toggle::after {
        display: none;
      }
    `,
  ],
})
export class LaunchpointCoreWebWebhooksToolbarComponent extends LaunchpointCoreClientWebhooksBaseComponent {
  modalRef: NgbModalRef;
  modalService = inject(NgbModal);

  options: IToolbarConfig = {
    title: 'Webhooks',
    searchPlaceholder: 'Search by URL',
    formControl: this.form_control,
    create: true,
    queryCount: this.queryFilterCount$,
  };

  constructor(public _Store: Store) {
    super(_Store);
  }

  paginate(page) {
    this.pageChange({ pageSize: this.pagination.limit, pageIndex: page, previousPageIndex: page - 1 });
  }

  create() {
    this.modalRef = this.modalService.open(LaunchpointCoreWebWebhookModalComponent, { size: 'lg' });
  }
}
