import { Directive, effect, inject, OnInit, signal } from '@angular/core';
import { FormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ELaunchpointResponseStatus,
  ILaunchpointDynamicForm,
  ILaunchpointDynamicFormGroup,
  ILaunchpointDynamicFormGroupQuestion,
  ILaunchpointDynamicFormResponseBase,
  LaunchpointDynamicFormResponseProcessor,
} from '@launchpoint/core-types';
import { combineLatest, firstValueFrom, takeUntil } from 'rxjs';
import { LaunchpointDynamicFormResponseFacadeComponent } from '../+state/facade.component';
import { LaunchpointDynamicFormV1Service } from '../../forms/services/forms.service';
import { LaunchpointDynamicFormResponseResponsesV1Service } from '../services/responses-response.service';
import { LaunchpointDynamicFormResponseV1Service } from '../services/responses.service';

@Directive()
export abstract class LaunchpointDynamicFormResponseBaseComponent extends LaunchpointDynamicFormResponseFacadeComponent implements OnInit {
  form: UntypedFormGroup;
  dynamic_form = signal<ILaunchpointDynamicForm>(null);
  selected_group = signal<ILaunchpointDynamicFormGroup>(null);
  response = signal<ILaunchpointDynamicFormResponseBase>(null);
  loading = signal(false);
  returnUrl = signal<string>('');
  summaryView = signal(false);

  summaryViewed = false;

  fb = inject(FormBuilder);
  route = inject(ActivatedRoute);
  router = inject(Router);

  _LaunchpointDynamicFormV1Service = inject(LaunchpointDynamicFormV1Service);
  _LaunchpointDynamicFormResponseV1Service = inject(LaunchpointDynamicFormResponseV1Service);
  _LaunchpointDynamicFormResponseResponsesV1Service = inject(LaunchpointDynamicFormResponseResponsesV1Service);

  ngOnInit(): void {
    // console.log({
    //   summaryView: this.summaryView(),
    //   viewed: this.summaryViewed,
    // });

    combineLatest([this.route.queryParams, this.route.params])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (params) => this.processForm(params),
        error: (err) => this.onError(err),
      });
    this.init();
  }

  init() {
    // override as needed
  }

  /**
   * Gets the form by id from the route if it hasn't already fetched it.
   * @param param0
   */
  async processForm([queryParams, params]: [Params, Params]) {
    try {
      const { form_id } = params;
      const { group_id, response_id, returnUrl, complete, ...rest } = queryParams;
      // console.log({ queryParams });

      // If new form or we don't have it get get the form
      if (!form_id) {
        // TODO: throw error
      }

      // console.log({ group_id });
      // console.log({ response_id });
      // console.log({ returnUrl: decodeURIComponent(returnUrl) });
      if (returnUrl) {
        this.returnUrl.set(returnUrl);
      }
      // console.log({ returnUrl: this.returnUrl() });
      if (!this.dynamic_form() || form_id !== this.dynamic_form()._id) {
        // GET FORM
        const form = await firstValueFrom(this._LaunchpointDynamicFormV1Service.getById(form_id));
        if (form) {
          this.dynamic_form.set(form);
        } else {
          // TODO: throw error
          // this.onError('Form not found');
        }
      } else {
        // console.log('Form already loaded');
      }
      if (!response_id) {
        // TODO:  Query all responses not complete and allow user to select?
      } else if (!this.response() || this.response()._id !== response_id) {
        this.loading.set(true);
        const response = await firstValueFrom(this._LaunchpointDynamicFormResponseV1Service.getById(response_id));
        this.response.set(response);
        this.loading.set(false);
      } else {
        // console.log('response already set');
      }
      const processor = new LaunchpointDynamicFormResponseProcessor(this.dynamic_form(), this.response()?.responses);

      for (let i = 0; i < this.dynamic_form().groups.length; i++) {
        const group = this.dynamic_form().groups[i];
        const updatedGroup = processor.processGroup(group);
        const from = this.dynamic_form();
        from.groups[i] = updatedGroup;
        this.dynamic_form.set(from);
      }
      if (!this.selected_group() || this.selected_group()._id !== group_id) {
        if (group_id) {
          const group = this.dynamic_form().groups.find((group) => group._id === group_id);
          this.selected_group.set(group);
        } else {
          const group = this.dynamic_form().groups[0];
          this.selected_group.set(group);
        }
        this.createFormFromGroup(this.selected_group(), rest);
      }
      if (this.summaryViewed) {
        this.summaryView.set(false);
      }
      if (this.response()?.completed_date || this.getProgress(this.response() as any) === 100) {
        // console.log('response is complete or 100% and summary not viewed yet');
        // console.log('BEFORE', {
        //   summaryView: this.summaryView(),
        //   viewed: this.summaryViewed,
        // });

        if (this.summaryViewed) {
          this.summaryView.set(false);
        } else {
          this.summaryView.set(true);
          this.summaryViewed = true;
          this.selected_group.set(null);
        }

        // console.log('AFTER', {
        //   summaryView: this.summaryView(),
        //   viewed: this.summaryViewed,
        // });
      }
      if (typeof complete === 'boolean') {
        this.summaryView.set(complete);
      }
    } catch (error) {
      console.log(error);
      this.loading.set(false);
    }
  }

  formatFormLabel(question: ILaunchpointDynamicFormGroupQuestion, index: number): string {
    return `${index + 1}. ${question.question_text}`;
  }

  getValueFromResponse(question: ILaunchpointDynamicFormGroupQuestion) {
    const { _id } = question;
    if (!_id) {
      return null;
    }

    return this.response().responses.find((f) => f.question_id === _id)?.answer_value ?? null;
  }

  createFormFromGroup(
    group: ILaunchpointDynamicFormGroup,
    data: {
      [key: string]: any;
    }
  ) {
    // console.log('Setting Group/Form');
    try {
      if (this.dynamic_form() && group) {
        // console.log({ group });
        // console.log('Setting Answers');
        const processor = new LaunchpointDynamicFormResponseProcessor(this.dynamic_form(), this.response()?.responses);
        const newGroup = processor.processGroup(group);
        // console.log({ newGroup });
        this.selected_group.set(newGroup);
      }
    } catch (error) {
      console.log(error);
    }

    const formControls = {};
    for (let i = 0; i < group?.questions.length; i++) {
      const question = group?.questions[i];
      const validators: ValidatorFn[] = [];
      // console.log({ validations: question.validations });
      // console.log({ validations_keys: Object.keys(question.validations) });
      // console.log({ 'question.validations.min_length': question.validations?.min_length });
      if (question.validations.required === true) {
        validators.push(Validators.required);
      }
      if (question.validations.min !== null) {
        validators.push(Validators.min(question.validations.min));
      }
      if (question.validations.max !== null) {
        validators.push(Validators.max(question.validations.max));
      }
      if (question.validations.min_length !== null) {
        // console.log({ 'question.validations.min_length !== NULL': question.validations?.min_length });
        validators.push(Validators.minLength(question.validations.min_length));
      }
      if (question.validations.max_length !== null) {
        // console.log({ 'question.validations.max_length !== NULL': question.validations?.max_length });
        const FN = Validators.maxLength(question.validations.max_length);
        // console.log(FN);
        validators.push(FN);
      }
      if (question.validations.pattern !== null) {
        validators.push(Validators.pattern(question.validations.pattern));
      }
      let answer_value = null;

      const answer = this.response()?.responses.find((f) => f.question_id === question._id);
      if (answer?.answer_value) {
        answer_value = answer.answer_value;
      } else if (data[question._id]) {
        answer_value = data[question._id];
      }
      if (!answer_value) {
        switch (question.question_type) {
          case this.LaunchpointFormsInputType.NUMBER:
            answer_value = 0;
            break;
          case this.LaunchpointFormsInputType.CURRENCY:
            answer_value = 0;
            break;
          case this.LaunchpointFormsInputType.PERCENTAGE:
            answer_value = 0;
            break;
          case this.LaunchpointFormsInputType.SELECT:
            // answer_value = question.options[0].value; // Commented so there is not a default value and instead it shows "Select"
            break;
          case this.LaunchpointFormsInputType.YEAR:
            answer_value = new Date().getFullYear() - 1;
            break;
          case this.LaunchpointFormsInputType.MULTIPLE_CHOICE:
            answer_value = question.options[0].value;
            break;
          case this.LaunchpointFormsInputType.YES_NO:
            answer_value = false;
            break;
          default:
            answer_value = '';
            break;
        }
      }
      const formQuestion: any = [answer_value ?? null];

      // console.log({ validators });
      if (validators.length > 0) {
        formQuestion.push(Validators.compose(validators));
      }
      // console.log({ formQuestion });
      formControls[question._id] = formQuestion;
    }
    // console.log({ formControls });
    this.form = this.fb.group<{ [key: string]: any }>(formControls);

    // console.log({ form });
    // console.log({ dynamic_form: entity.dynamic_form_id });
    // this.dynamicForm.set(entity.dynamic_form);
  }

  getOptions(question: ILaunchpointDynamicFormGroupQuestion) {
    return question.options.map((option) => option.value);
  }

  nextGroup() {
    // Not the last group, navigate to the next one
    if (this.indexOfGroup < this.dynamic_form()?.groups.length - 1) {
      const nextGroupIndex = this.indexOfGroup + 1;
      this.routeNavigate(this.dynamic_form()?.groups[nextGroupIndex]._id, this.response()._id);
    }
  }

  previousGroup() {
    this.summaryView.set(false);
    if (this.indexOfGroup >= 0) {
      const nextGroupIndex = this.indexOfGroup - 1;
      this.routeNavigate(this.dynamic_form()?.groups[nextGroupIndex]._id, this.response()._id);
    }
  }

  async navigateToIndex(index: number) {
    this.summaryView.set(false);

    const nextGroupIndex = index;
    const group = this.dynamic_form()?.groups[nextGroupIndex];
    if (group) {
      const group_id = group._id;
      this.routeNavigate(group_id, this.response()._id);
    }
  }

  routeNavigate(group_id: string, response_id: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group_id, response_id },
      queryParamsHandling: 'merge',
    });
  }

  // getQuestionText(question_id: string){
  //   const question = this.selected_group()?.questions.find((q) => q._id === question_id);

  // }

  async swipeEvent(event) {
    // console.log(Object.keys(event));
    // console.log(event?.direction);
    // indexOfGroup;
    switch (event?.direction) {
      case 2:
        if (this.indexOfGroup - 1 < this.dynamic_form()?.groups.length) {
          // 2= right
          this.submit();
        }
        break;
      case 1:
        if (this.indexOfGroup > 0) {
          // 2= right
          this.previousGroup();
        }
        break;

      default:
        break;
    }
    // this.submit()
  }

  get indexOfGroup() {
    if (!this.dynamic_form() || !this.selected_group()) {
      return -1;
    }
    const index = this.dynamic_form()?.groups.findIndex((g) => g?._id === this.selected_group()?._id);

    // console.log({ indexOfGroup: index });
    return index;
  }

  async submit() {
    // if on the summary page and its the first one (viewing from 100% or complete)
    if (this.summaryView() && this.indexOfGroup === 0) {
      this.summaryView.set(false);
    }

    try {
      await this.save();
      this.nextGroup();
    } catch (error) {
      console.log(error);
      if (error?.message) {
        this.onError(error.error.message);
      }
    }
    this.loading.set(false);
  }

  async viewSummary() {
    this.submit(); //important! We need to make sure the values on the last form screen are submitted before viewing the summary, otherwise the last form questions will be 0 or default values on summary screen
    this.summaryView.set(true);
  }

  async complete() {
    try {
      await this.save();
    } catch (error) {
      console.log(error);
      if (error?.message) {
        this.onError(error.error.message);
      }
      this.loading.set(false);
      return;
    }

    try {
      await firstValueFrom(
        this._LaunchpointDynamicFormResponseV1Service.update({
          _id: this.response()._id,
          response_status: ELaunchpointResponseStatus.COMPLETE,
        })
      );
    } catch (error) {
      console.log(error);
      if (error?.message) {
        this.onError(error.error.message);
      }
      this.loading.set(false);
      return;
    }

    // TODO: return URL Doesn't work befause of named router outlets. Need a more complex system

    //  this.routerExtensions.navigate([{ outlets }], {
    //    relativeTo: this.activeRoute,
    //  });

    this.router.navigate([''], {
      // relativeTo: this.route,
      // queryParams: { response_id: this.response()?._id },
      // queryParamsHandling: 'merge',
    });
    this.loading.set(false);
    // this.selected_group.set(this.dynamic_form().groups[0]);
  }

  async saveAndClose() {
    await this.save();
    this.close();
  }

  async exit() {
    // TODO: BUILD ARE YOU SURE POP UP?
    this.close();
  }

  close() {
    // if (this.returnUrl()) {
    //   this.router.navigateByUrl(this.returnUrl(), {});
    // } else {
    //   this.router.navigate([''], {});
    // }

    this.router.navigate([''], {});
  }

  back() {
    this.router.navigateByUrl(this.returnUrl(), {});
  }

  async save() {
    try {
      this.loading.set(true);
      const responses = Object.keys(this.form.value).map((key) => {
        return {
          question_id: key,
          answer_value: this.form.value[key],
        };
      });
      if (responses.length > 0) {
        if (!this.response()) {
          const response = await firstValueFrom(
            this._LaunchpointDynamicFormResponseV1Service.create({
              form: this.dynamic_form()._id,
              responses,
            })
          );
          this.response.set(response);
        } else {
          const response = await firstValueFrom(
            this._LaunchpointDynamicFormResponseResponsesV1Service.add({
              _id: this.response()._id,
              responses,
              options: {
                upsert: true,
              },
            })
          );
          this.response.set(response);
        }
      }
      return;
    } catch (error) {
      this.loading.set(false);
      this.onError(error?.error?.message);
      return;
    }
  }

  onError(message: string) {
    // Override
  }
}
