import { InjectionToken } from '@angular/core';
import { IAuthenticationResponse } from '@launchpoint/core-types';
export interface ICoreAuthConfigRoutes {
  public: {
    getUserByToken: string;
    login: string;
    loginMagic?: string;
    logout: string;
    createUser: string;
    createUserWithoutEmail: string;
    forgotPassword: string;
    refreshUserToken: string;
    verify: string;
  };
  protected: {
    resetPassword: string;
    changePassword: string;
    setPassword: string;
    changeEmail: string;
    setEmail: string;
    verifyEmail: string;
  };
}

export interface ICoreAuthConfig {
  auth: {
    /**
     * This field should have logic to switch on the environment. It will allow the nativescript applications and others to provide the full url.
     */
    base_url: string;
    /**
     * Image used on the background of the auth module pages
     *
     * only required on WEB
     */
    background_image?: string;
    /**
     * color of the background for v2
     */
    background_color?: string;
    /**
     * path or link to the logo of the application
     */
    logo?: string;
    /**
     * header text
     */
    header?: string;
    /**
     * description of the application
     */
    description?: string;
    /**
     * side of the image
     * @default right
     */
    image_side?: 'left' | 'right';
  };
  /**
   * web only
   */
  logo_light?: string;
  /**
   * web only
   */
  logo_dark?: string;
  /**
   * registration page configuration
   */
  registration: {
    /**
     * turns the link to the page on/off
     */
    active: boolean;
    /**
     * title on the registration page
     * @default 'Sign Up'
     */
    title?: string;
    /**
     * description on the registration page
     */
    description?: string;
    /**
     * text you want on the registration button
     */
    button?: string;
    /**
     * register text box label
     */
    input_label: string;
    /**
     * If you want this auth module to require a password to be set for the user before allowing them to login.
     */
    require_password: boolean;
    /**
     * allows user to sign up with phone
     */
    allow_phone?: boolean;
    /**
     * text that appears prior to links, defaults to I agree to in html
     */
    prefix_agreement_text?: string;
    /**
     * link for the third link on the line if needed Probably Terms of service
     */
    primary_agreement_href?: string;
    /**
     * text for the the third link on the line if needed Probably Terms of service
     */
    primary_language?: string;
    /**
     * words to connect primary and secondary agreements often 'and' or some form of such
     */
    transition_text_1?: string;
    /**
     * link for the second link on the line if needed probably privacy policy
     */
    secondary_agreement_href?: string;
    /**
     * text for the the second link on the line if needed probably privacy policy
     */
    secondary_language?: string;
    /**
     * words to connect the secondary and an tircary link
     */
    transition_text_2?: string;
    /**
     * link for the third link on the line if needed
     */
    terciary_agreement_href?: string;
    /**
     * text for the the third link on the line if needed
     */
    terciary_language?: string;
    /**
     * does the checkmark need to be clicked to go forward
     */
    agreements_required?: boolean;
  };
  // todo -- figure out a way to reset password after login with code
  // password_reset: {
  //   /**
  //    * enable if you want the user to be able to reset their password after requesting a code to
  //    * `LOGIN` not register
  //    *
  //    * will redirect you to /set-password after successfully verifying the code
  //    */
  //   enabled: boolean;
  //   /**
  //    * allow user to be automatically logged in after resetting their password
  //    */
  //   auto_login_after_reset_password: boolean;
  // };
  set_password?: {
    /**
     * title of the set password page
     * @default 'Create Password'
     */
    title?: string;
    /**
     * description of the set password page
     */
    description?: string;
    /**
     * text you want on the set password button
     * @default 'Sign Up'
     */
    button?: string;
    /**
     * configurable password settings for setting a password
     */
    password_settings: {
      /**
       * minimum password length
       * @default 8
       */
      min_length: number;
      /**
       * maximum password length
       * @default 100
       */
      max_length: number;
      /**
       * require at least one uppercase letter
       * @default false
       */
      require_uppercase: boolean;
      /**
       * require at least one number
       * @default false
       */
      require_number: boolean;
      /**
       * require at least one special character
       * @default false
       */
      require_special: boolean;
    };
  };
  login: {
    /**
     * title of the login page
     * @default 'Sign In'
     */
    title?: string;
    /**
     * description of the login page
     */
    description?: string;
    /**
     * text you want on the login button
     * @default 'Sign In'
     */
    button?: string;
    /**
     * text you want on the Log in with Code button to say
     * @default 'Log in with Code'
     */
    code_text?: string;
  };
  verify: {
    /**
     * title of the verify page
     * @default 'Verify'
     */
    title?: string;
    /**
     * description of the verify page
     */
    description?: string;
    /**
     * text you want on the login button
     */
    button?: string;
    /**
     * auto submit the code after the user has entered the correct number of characters
     * @default false
     */
    auto_submit?: boolean;
    /**
     * length of the verify code (configured in the backend Auth Config: AuthConfigOptions)
     */
    code_length: number;
  };
  /**
   * third party login options
   */
  third_party?: {
    google?: {
      active: boolean;
      href: string;
    };
    apple?: {
      active: boolean;
      href: string;
    };
  };
  /**
   * redirects for the module, if not provided the module will use the default redirects
   */
  redirects?: {
    /**
     * redirect after setting a password
     * @default '/'
     */
    set_password?: string[];
    logout?: string[];
  };
  /**
   * footer links
   *
   * WEB ONLY
   */
  footers?: {
    title: string;
    href: string;
  }[];
  /**
   * Optionally adjust all the routes this module will send http requests to
   */
  routes?: ICoreAuthConfigRoutes;
}

export const APP_AUTH_CONFIG_TOKEN = new InjectionToken<ICoreAuthConfig>('APP_AUTH_CONFIG_TOKEN');

export interface IAuthStorageService {
  tokens: IAuthenticationResponse;
  setTokens(auth: IAuthenticationResponse): any;
  removeTokens(): void;
}

export interface IAuthInterceptorConfig {
  debug?: boolean;
}
export const AUTH_INTERCEPTOR_PROVIDER = new InjectionToken<IAuthInterceptorConfig>('AUTH_INTERCEPTOR_PROVIDER');
