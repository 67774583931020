import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SOCIAL_LINKS } from '../../../../config/config';

@Component({
  selector: 'sd-tools-tips',
  templateUrl: './tools-tips.component.html',
  styleUrl: './tools-tips.component.scss',
})
export class ToolsTipsComponent implements OnInit, AfterViewInit {
  social_links = SOCIAL_LINKS;

  ngOnInit() {
    return;
  }

  ngAfterViewInit(): void {
    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
  }
}
