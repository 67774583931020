import {
  CampaignHook,
  CampaignLimits,
  CreateCampaignCommandInput,
  CustomDeliveryConfiguration,
  DeleteCampaignCommandInput,
  GetCampaignCommandInput,
  GetCampaignsCommandInput,
  MessageConfiguration,
  Schedule,
  TemplateConfiguration,
  UpdateCampaignCommandInput,
  WriteCampaignRequest,
  WriteTreatmentResource,
} from '@aws-sdk/client-pinpoint';
import {
  ICampaignHook,
  ICampaignLimits,
  ICampaignState,
  ICustomDeliveryConfiguration,
  IMessageConfiguration,
  ISchedule,
  ITemplateConfiguration,
  ITreatmentResource,
} from './aws-campaign.interface';
import { RequireAtLeastOne } from '../../require-one';
import { ILaunchpointUserAccountIds } from '../../security.interface';
import { ILaunchpointSearchFilterDates } from '../../dates.interface';
import { IQueryPageination, IQuerySort } from '../../query.interfaces';

// implements CampaignResponse
export class ILaunchpointCorePinpointCampaign {
  additional_treatments?: ITreatmentResource[];
  application_id: string;
  arn: string;
  creation_date: string;
  custom_delivery_configuration?: ICustomDeliveryConfiguration;
  default_state?: ICampaignState;
  description?: string;
  holdout_percent?: number;
  hook?: ICampaignHook;
  id: string;
  is_paused?: boolean;
  last_modified_date: string;
  limits?: ICampaignLimits;
  message_configuration?: IMessageConfiguration;
  name?: string;
  schedule?: ISchedule;
  segment_id: string;
  segment_version: number;
  state?: ICampaignState;
  tags?: Record<string, string>;
  template_configuration?: ITemplateConfiguration;
  treatment_description?: string;
  treatment_name?: string;
  version?: number;
  priority?: number;
  //
  _id: string;
  created_at: Date;
  updated_at: Date;
  users: string[];
  accounts: string[];
}

class IWriteCampaignRequest implements WriteCampaignRequest {
  AdditionalTreatments?: WriteTreatmentResource[];
  CustomDeliveryConfiguration?: CustomDeliveryConfiguration;
  Description?: string;
  HoldoutPercent?: number;
  Hook?: CampaignHook;
  IsPaused?: boolean;
  Limits?: CampaignLimits;
  MessageConfiguration?: MessageConfiguration;
  Name?: string;
  Priority?: number;
  Schedule?: Schedule;
  SegmentId?: string;
  SegmentVersion?: number;
  TemplateConfiguration?: TemplateConfiguration;
  TreatmentDescription?: string;
  TreatmentName?: string;
  tags?: Record<string, string>;
}

class PinpointCampaignCreate implements CreateCampaignCommandInput {
  ApplicationId: string;
  WriteCampaignRequest: RequireAtLeastOne<IWriteCampaignRequest>;
}

export type ILaunchpointCorePinpointCampaignParamsCreate = ILaunchpointUserAccountIds & PinpointCampaignCreate;

class PinpointUpdateCampaign implements UpdateCampaignCommandInput {
  ApplicationId: string;
  CampaignId: string;
  WriteCampaignRequest: RequireAtLeastOne<IWriteCampaignRequest>;
}

export type ILaunchpointCorePinpointCampaignParamsUpdate = {
  _id: string;
} & PinpointUpdateCampaign;

class PinpointDeleteCampaign implements DeleteCampaignCommandInput {
  ApplicationId: string;
  CampaignId: string;
}

export type ILaunchpointCorePinpointCampaignParamsDelete = {
  _id: string;
} & PinpointDeleteCampaign;

class PinpointListCampaign implements GetCampaignsCommandInput {
  ApplicationId: string;
  PageSize?: string;
  Token?: string;
}

export type ILaunchpointCorePinpointCampaignParamsList = PinpointListCampaign;

class PinpointRetrieveCampaign implements GetCampaignCommandInput {
  ApplicationId: string;
  CampaignId: string;
}

export type ILaunchpointCorePinpointCampaignParamsRetrieve = PinpointRetrieveCampaign;

export class ILaunchpointCorePinpointCampaignSearchQuery {
  search?: string;
  segment_id?: string[];
  is_paused?: boolean;
  state?: ICampaignState[];
  //  creation_date?: ILaunchpointSearchFilterDates;
}

export class ILaunchpointCorePinpointCampaignSearchPayload {
  query: ILaunchpointCorePinpointCampaignSearchQuery;
  pagination: IQueryPageination;
  querySort: IQuerySort;
}

export class ILaunchpointCorePinpointCampaignSearchResults {
  data: ILaunchpointCorePinpointCampaign[];
  pagination: IQueryPageination;
}
