<div class="launchpoint-app-content flex-column-fluid">
  <div class="app-container container-xxl container-fluid">

    <launchpoint-toolbar [options]="options" (create)="open()" (refresh)="refresh()">
      <launchpoint-companies-filter></launchpoint-companies-filter>
    </launchpoint-toolbar>

    <!-- <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
      <div class="d-flex flex-column flex-column-fluid container-xxl"> -->
    <div class="card card-flush">
      <div class="card-body">
        <launchpoint-company-table (sortDataEmitter)="sortData($event)"></launchpoint-company-table>
      </div>
    </div>
    <!-- </div>
    </div> -->
  </div>
</div>