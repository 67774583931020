<div class="card card-custom" [ngClass]="cssClass">
  <!-- Header -->
  <div class="card-header border-0 pt-5">
    <div class="card-title">
      <div class="card-label">
        <div class="font-weight-bolder">Weekly Sales Stats</div>
        <div class="font-size-sm text-muted mt-2">890,344 Sales</div>
      </div>
    </div>
    <div class="card-toolbar">
      <div ngbDropdown placement="bottom-right" class="dropdown dropdown-inline" title="Quick actions">
        <a ngbDropdownToggle class="btn btn-clean btn-sm btn-icon">
          <i class="ki ki-bold-more-hor"></i>
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-md dropdown-menu-right">
          <launchpoint-app-dropdown-menu3></launchpoint-app-dropdown-menu3>
        </div>
      </div>
    </div>
  </div>
  <!-- Body -->
  <div class="card-body d-flex flex-column px-0">
    <!--begin::Chart-->
    <div id="kt_tiles_widget_1_chart" [style.height]="'height: 125px'">
      <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis" [dataLabels]="chartOptions.dataLabels" [stroke]="chartOptions.stroke"
        [legend]="chartOptions.legend" [fill]="chartOptions.fill" [states]="chartOptions.states"
        [tooltip]="chartOptions.tooltip" [colors]="chartOptions.colors" [markers]="chartOptions.markers"
        [plotOptions]="chartOptions.plotOptions"></apx-chart>
    </div>
    <!--end::Chart-->
    <!--begin::Items-->
    <div class="flex-grow-1 card-spacer-xflex-grow-1 card-spacer-x">
      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between mb-10">
        <div class="d-flex align-items-center mr-2">
          <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
            <div class="symbol-label">
              <span [inlineSVG]="'./assets/media/svg/brand-logos/plurk.svg'" class="svg-icon h-50"></span>
            </div>
          </div>
          <div>
            <a href="#" class="
                font-size-h6
                text-dark-75 text-hover-primary
                font-weight-bolder
              ">Top Authors</a>
            <div class="font-size-sm text-muted font-weight-bold mt-1">
              Ricky Hunt, Sandra Trepp
            </div>
          </div>
        </div>
        <div class="
            label label-light label-inline
            font-weight-bold
            text-dark-50
            py-4
            px-3
            font-size-base
          ">
          +105$
        </div>
      </div>
      <!--end::Item-->
      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between mb-10">
        <div class="d-flex align-items-center mr-2">
          <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
            <div class="symbol-label">
              <span [inlineSVG]="'./assets/media/svg/brand-logos/telegram.svg'" class="svg-icon h-50"></span>
            </div>
          </div>
          <div>
            <a href="#" class="
                font-size-h6
                text-dark-75 text-hover-primary
                font-weight-bolder
              ">Bestsellers</a>
            <div class="font-size-sm text-muted font-weight-bold mt-1">
              Pitstop Email Marketing
            </div>
          </div>
        </div>
        <div class="
            label label-light label-inline
            font-weight-bold
            text-dark-50
            py-4
            px-3
            font-size-base
          ">
          +60$
        </div>
      </div>
      <!--end::Item-->
      <!--begin::Item-->
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center mr-2">
          <div class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
            <div class="symbol-label">
              <span [inlineSVG]="'./assets/media/svg/misc/puzzle.svg'" class="svg-icon h-50"></span>
            </div>
          </div>
          <div>
            <a href="#" class="
                font-size-h6
                text-dark-75 text-hover-primary
                font-weight-bolder
              ">Top Engagement</a>
            <div class="font-size-sm text-muted font-weight-bold mt-1">
              KT.com solution provider
            </div>
          </div>
        </div>
        <div class="
            label label-light label-inline
            font-weight-bold
            text-dark-50
            py-4
            px-3
            font-size-base
          ">
          +75$
        </div>
      </div>
      <!--end::Item-->
    </div>
    <!--end::Stats-->
  </div>
</div>