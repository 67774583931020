export enum ELaunchpointTopbarActions {
  SIGN_OUT = 'Sign Out',
}

export interface LaunchpointTopbarConfig {
  logo_dark: string;
  logo_light: string;
  search: boolean;
  activities: boolean;
  notifications: boolean;
  chat: boolean;
  quick_links: boolean;
  theme_switch: boolean;
  explore_drawer: boolean;
  user_inner?: LaunchpointTopbarConfigUserInner;
}

export interface LaunchpointTopbarConfigUserInner {
  active: boolean;
  default_image: string;
  menu: LaunchpointUserInnerMenuItems;
}

export interface LaunchpointUserInnerMenu {
  separator: string | null;
  title: string;
  badge: string | null;
  page: string | null;
  // membership_status: string[];
  security?: { action: string; resource: string };
  external_link: string;
  submenu: ILayoutMenuSubMenu[];
}

interface ILayoutMenuSubMenu {
  title: string;
  page: string;
  badge: string;
}

export interface LaunchpointUserInnerMenuItems {
  items: Partial<LaunchpointUserInnerMenu>[];
}
