import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICoreUserAccountOwner } from '@launchpoint/core-types';
import { LaunchpointCompanyState, LaunchpointCompanyFeatureKey, LaunchpointCompanyEntityAdaptor } from '../reducers/interface';

const selectAllCompanyState = createFeatureSelector<LaunchpointCompanyState>(LaunchpointCompanyFeatureKey);

/**
 * ROOT STATE
 */
export const getAllLaunchpointCompanyState = createSelector(selectAllCompanyState, (state: LaunchpointCompanyState) => state);

export const selectLaunchpointCompanyPagination = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.pagination);
export const selectLaunchpointCompanyQuerySort = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.querySort);
export const selectLaunchpointCompanyQuery = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.query);
export const selectLaunchpointCompanyLoading = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.loading);
export const selectLaunchpointCompanyLoaded = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.loaded);
export const selectLaunchpointCompanyError = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => state.error);
export const selectLaunchpointCompanySuccess = createSelector(
  getAllLaunchpointCompanyState,
  (state: LaunchpointCompanyState) => state.success_message
);

export const getAllLaunchpointCompanyStateEntities = createSelector(selectAllCompanyState, (state: LaunchpointCompanyState) => state.entities);

/**
 * ENTITIES
 */
const { selectIds, selectEntities, selectAll, selectTotal } = LaunchpointCompanyEntityAdaptor.getSelectors(selectAllCompanyState);

// select the array of company ids
export const selectLaunchpointCompaniesIds = selectIds;

// select the dictionary of companies entities
export const selectLaunchpointCompaniesEntities = selectEntities;

// select the array of companies
export const selectLaunchpointCompanies = selectAll;

// select the total entity count
export const selectLaunchpointCompaniesTotal = selectTotal;

export const selectLaunchpointCompanyId = createSelector(
  getAllLaunchpointCompanyState,
  (state: LaunchpointCompanyState) => state?.selected_company_id
);

export const selectLaunchpointSelectedCompanyEntity = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company]
);

export const selectLaunchpointSelectedCompanyUsersEntity = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company].company_users
);
export const selectLaunchpointSelectedCompany = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company]?.company as any
);

export const selectLaunchpointSelectedCompanyAddress = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company]?.company?.address
);

export const selectLaunchpointSelectedCompanyAccountOwner = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company]?.company?.account_owner as ICoreUserAccountOwner
);

export const selectLaunchpointSelectedCompanyLoading = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company].loading
);

export const selectLaunchpointSelectedCompanyLoaded = createSelector(
  getAllLaunchpointCompanyState,
  selectLaunchpointCompanyId,
  (state: LaunchpointCompanyState, selected_company) => state.entities[selected_company].loaded
);

export const selectLaunchpointCompanyQueryCount = createSelector(getAllLaunchpointCompanyState, (state: LaunchpointCompanyState) => {
  let count = 0;
  try {
    count = count + (state?.query?.created_at ? 1 : 0);
    count = count + (state?.query?.account_owner?.length ?? 0);
    count = count + (state?.query?.account_type?.length ?? 0);
  } catch (error) {
    // console.log(error);
  }
  return count;
});
