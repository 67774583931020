import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersTableBaseAdminSmartComponent } from '@launchpoint/core-client';
import { Store } from '@ngrx/store';

@Component({
  selector: 'launchpoint-users-table-smart-admin',
  templateUrl: './users-table-smart-admin.component.html',
})
export class UsersTableSmartAdminComponent extends UsersTableBaseAdminSmartComponent {
  constructor(public _router: Router, public _route: ActivatedRoute, public _Store: Store) {
    super(_router, _route, _Store);
  }
}
