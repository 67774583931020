import { Directive, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICoreUserParamsCreateV3 } from '@launchpoint/core-types';
import { UserAuthActions } from '../+state';
import { LaunchpointCoreClientAuthStateBaseComponent } from '../+state/auth-base.component';
import { ControlsOf } from '../../../../forms/from-control/form-control-convert.interface';
import { APP_AUTH_CONFIG_TOKEN, ICoreAuthConfig } from '../interfaces/auth-storage-service.interface';
import { ILaunchpointAuth } from '../interfaces/auth.interface';
import { LaunchpointCoreClientAuthFormService } from '../services/auth-form-service';

@Directive()
export abstract class LaunchpointCoreClientAuthRegisterBaseComponent extends LaunchpointCoreClientAuthStateBaseComponent implements OnInit {
  form: FormGroup<ControlsOf<ILaunchpointAuth>>;

  constructor(
    @Inject(APP_AUTH_CONFIG_TOKEN) public _config: ICoreAuthConfig,
    public _LaunchpointCoreClientAuthFormService: LaunchpointCoreClientAuthFormService,
    public _route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this._LaunchpointCoreClientAuthFormService.setRegistrationForm(true, this._config.registration.agreements_required);
    this._route.queryParams.subscribe({
      next: (data) => {
        if (data?.email) {
          /**
           * This ia a common problem. The + character is used by the URL to separate two words.
           *
           * Here I'm checking for spaces in the email  and putting the + back.
           *
           * I'm hoping other url safe will just work.
           */
          let email = data.email;
          if (data.email.includes(' ')) {
            email = data.email.replace(' ', '+');
          }
          this.form.controls.email.setValue(email);
        }
      },
    });
  }

  async submit() {
    if (this.form.invalid) {
      return;
    }
    if (!this.form.value.email) {
      return;
    }

    const isEmail = this.form.value.email.match(/@/);

    if (!isEmail && !this._config.registration?.allow_phone) {
      this._Store.dispatch(
        UserAuthActions.userCreateWarning({
          error: { message: 'Phone number registration is not allowed.', error: 'Phone form control is invalid' },
        })
      );
      return;
    }

    if (!isEmail && this.formatPhone(this.form.value.email).length < 10) {
      this._Store.dispatch(
        UserAuthActions.userCreateWarning({
          error: { message: 'Please enter a valid phone number.', error: 'Phone form control is invalid' },
        })
      );
      return;
    }

    const data: ICoreUserParamsCreateV3 = {
      admin: {
        electronic_consent_ts: new Date(),
        electronic_consent: this.form.value.agreement,
      },
      ...(isEmail ? { email: this.form.value.email } : { phone: +this.formatPhone(this.form.value.email) }),
    };

    try {
      this._Store.dispatch(
        UserAuthActions.userCreate({
          data,
          path: ['/auth', 'verify'],
        })
      );
    } catch (error) {
      console.log(error);
    }
  }
}
